import { CommonModule } from "@angular/common";

import { Component, OnInit, ElementRef, ViewChild } from '@angular/core'
import { FormGroup, FormControl } from '@angular/forms'
import { ArticleService, CustomerService, WarehouseService } from '../_services'
import { ArticleTemplate, Customer, Warehouse, WarehouseBinCombined, BulkList } from '../_models'
import { environment } from '../../environments/environment'

@Component({
  imports: [CommonModule],
    selector: 'hawk-warehouse-bulk-list',
    templateUrl: './warehouse-bulk-list.component.html',
    styleUrls: ['./warehouse-bulk-list.component.scss'],
})
export class WarehouseBulkListComponent implements OnInit {

  bulkJobs: BulkList[] = []

  constructor(
    private warehouseService: WarehouseService,
  ) { }

  ngOnInit(): void {

    this.warehouseService.loadBulkJobs().subscribe(
      (data) => {
        this.bulkJobs = data
      },
      (err) => {
        console.error(err)
      },
    )
  }

  getUrl(id: number): string {
    return environment.apiURL + 'bulk_rma/' + id + '.pdf'
  }
}