import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { FaIconComponent, FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { Component, OnInit } from '@angular/core'
import { Article, DeliveryNote, StorageBin, Warehouse } from 'src/app/_models'
import { ArticleService, WarehouseService } from 'src/app/_services'
import { environment } from 'src/environments/environment'

@Component({
  imports: [CommonModule, FormsModule, RouterModule, FontAwesomeModule],
    selector: 'hawk-article-splitter-page',
    templateUrl: './article-splitter-page.component.html',
    styleUrls: ['./article-splitter-page.component.scss'],
})
export class ArticleSplitterPageComponent implements OnInit {

  public warehouses: Warehouse[]
  public selectedWarehouse: Warehouse
  public selectedBin: StorageBin
  public selectedTab: string = 'as'

  public searchTermArticle: string
  public foundArticles: Article[]
  public articleSpinner: boolean = false

  public searchTermDeliveryNote: string
  public foundDeliveryNote: DeliveryNote

  public selectedArticles: Article[] = []

  public createdDeliveryNotes: DeliveryNote[]

  constructor(
    private warehouseService: WarehouseService,
    private articleService: ArticleService,
  ) { }

  ngOnInit(): void {
    this.loadWarehouses()
  }

  /**
   * loadWarehouses
   */
  public loadWarehouses() {
    this.warehouseService.loadWarehouses().subscribe(
      (data) => {
        this.warehouses = data
        this.selectedWarehouse = this.warehouses[0]
        this.selectFirstBin()
      },
      (err) => {
        console.error(err)
      },
    )
  }

  public searchArticles(keyword: string) {
    this.articleSpinner = true
    this.articleService.searchArticles(keyword).subscribe(
      (data) => {
        this.foundArticles = data
        this.articleSpinner = false
      },
      (err) => {
        console.error(err)
      },
    )
  }

  public searchDeliveryNote(keyword: string) {
    this.articleService.findDeliveryNote(keyword).subscribe(
      (data) => {
        this.foundDeliveryNote = data
      },
      (err) => {
        console.error(err)
      },
    )
  }

  public splitArticles() {
    const selectedIds = this.selectedArticles.map((article) => article.id)
    this.warehouseService.splitArticles(this.selectedBin.id, this.selectedWarehouse.id, selectedIds).subscribe(
      (data) => {
        this.createdDeliveryNotes = data
      },
      (err) => {
        console.error(err)
      },
    )
  }

  public selectFirstBin() {
    this.selectedBin = this.selectedWarehouse.bins[0]
  }

  public selectArticle(article: Article) {
    if (!this.isArticleSelected(article)) {
      this.selectedArticles.push(article)
    }
  }

  public unselectArticle(article: Article) {
    const filtered = this.selectedArticles.filter((a) => a.id !== article.id)
    this.selectedArticles = filtered
  }

  public isArticleSelected(article: Article): boolean {
    return this.selectedArticles.map((a) => a.id).indexOf(article.id) >= 0
  }

  public articleStatus(article: Article): string {
    if(article.location == 'CONSUMED'){
      return 'verbraucht'
    }

    switch (article.status) {
      case 'NEW': {
        return 'neu'
        break
      }
      case 'USED_UNTESTED': {
        return 'gebraucht, ungetestet'
        break
      }
      case 'USED_TESTED': {
        return 'gebraucht, getestet'
        break
      }
      default: {
        return '??'
      }
   }
  }

  public hasDifferentWarehouse(article: Article, deliveryNote: DeliveryNote): boolean {
    return article.warehouse_id !== deliveryNote.recipient_warehouse_id
  }

  public hasCreatedDeliveryNotes(): boolean {
    return this.createdDeliveryNotes && this.createdDeliveryNotes.length > 0
  }
}