import { Injectable } from '@angular/core'
import { HttpClient, HttpParams } from '@angular/common/http'

import { environment } from '../../environments/environment'
import { Store, StoreUnit } from '../_models'
import { APStore, PermittedStoreParams, StoreUpdateParams } from '../_models/store.interface'
import { Opening, PermittedOpeningParams } from '../_models/opening.interface'
import { APCustomer } from "../_models/customer.interface";
import { APTicket } from "../_models/ticket.interface";

@Injectable({
  providedIn: 'root'
})
export class StoreService {

  constructor(private http: HttpClient) {}

  public all(params: any = {}) {
    const path = environment.apiURL + 'stores.json'
    return this.http.get<any>(path, { params: params })
  }

  public create<HashType=Store>(store: PermittedStoreParams, init_for: {project_ids?: number[], customer_ids?: number[]}, hash_type?: 'admin_page_hash') {
    const hash_type_extension = `?hash_type=${hash_type}` || ''
    const path = environment.apiURL + `stores/create.json${hash_type_extension}`

    let params = new HttpParams()
    if (init_for?.project_ids) {
      init_for.project_ids.forEach(id => {
        params = params.append('project_ids[]', id.toString())
      })
    }
    if (init_for?.customer_ids) {
      init_for.customer_ids.forEach(id => {
        params = params.append('customer_ids[]', id.toString())
      })
    }

    return this.http.post<HashType>(path, store, {params: params})
  }

  public createUpdateUnit(unit: StoreUnit) {

    if(!unit.id) {
      const path = environment.apiURL + 'stores/create_unit.json'

      return this.http.post<StoreUnit>(path, unit)
    } else {
      const path = environment.apiURL + 'stores/create_unit/' + unit.id + '/.json'

      return this.http.patch<StoreUnit>(path, unit)
    }

  }

  public getUnits(store_id: number) {
    const path = environment.apiURL + 'stores/' + store_id + '/units.json'

    return this.http.get<StoreUnit[]>(path)
  }

  public createStoreOpening(opening_params: PermittedOpeningParams) {
    let opening = { opening: opening_params }
    const path = environment.apiURL + 'stores/create_opening.json'

    return this.http.post<Opening>(path, opening)
  }

  public updateStoreOpening(id: number, opening_id: number, opening_params: PermittedOpeningParams) {
    let opening = { opening: opening_params }
    const path = environment.apiURL + `stores/${id}/update_opening/${opening_id}/`

    return this.http.patch<Opening>(path, opening)
  }

  public destroyStoreOpening(id: number, opening_id: number) {
    const path = environment.apiURL + `stores/${id}/destroy_opening/${opening_id}/`

    return this.http.delete<Opening[]>(path)
  }

  public getStoresInCity(city: string, zip_code?: string) {
    const zip_code_extension = zip_code ? `/${zip_code}` : ''
    const path = environment.apiURL + `stores/in_city/${city}${zip_code_extension}.json`

    return this.http.get<Store[]>(path)
  }

  public getStoresWithLocalRestrictions(restrictions: {city?: string, zip_code?: string, street?: string}) {
    let params = new HttpParams()

    if (restrictions?.city) params = params.append('city', restrictions.city)
    if (restrictions?.zip_code) params = params.append('zip_code', restrictions.zip_code)
    if (restrictions?.street) params = params.append('street', restrictions.street)

    const path = environment.apiURL + `stores`

    return this.http.get<Store[]>(path, {params: params})
  }

  public getStoresWithStoreNr(store_nr: string) {
    const path = environment.apiURL + `stores/with_store_nr/${store_nr}.json`

    return this.http.get<Store[]>(path)
  }

  public getCustomerStores(customer_id: number, limit?: number) {
    const path = environment.apiURL + `stores/customer/${customer_id}.json`
    let params = new HttpParams()
    if (limit) {
      params = params.append('limit', limit)
    }

    return this.http.get<Store[]>(path, {params})
  }

  public getCustomerStoresWithSearch(customer_id: number, params:any ={}) {
    const path = environment.apiURL + `stores/customer/${customer_id}/search.json`

    return this.http.get<{
      stores: Store[],
      total_count: number,
      per_page: number,
      total_pages: number
    }>(path, {params})
  }

  public getProjectStores(project_id: number, limit?: number) {
    const path = environment.apiURL + `stores/project/${project_id}.json`
    let params = new HttpParams()
    if (limit) {
      params = params.append('limit', limit)
    }

    return this.http.get<Store[]>(path, {params})
  }

  public getStoreOpenings(store_id: string | number) {
    const path = environment.apiURL + `stores/${store_id}/openings.json`

    return this.http.get<Opening[]>(path)
  }

  public addStoreProject(store_id: number, project_id: number) {
    const path = environment.apiURL + `stores/${store_id}/add_project/${project_id}.json`

    return this.http.get<Store>(path)
  }

  public updateStore<HashType=Store>(store_id: number, data: StoreUpdateParams, hash_type?: 'admin_page_hash') {
    const hash_type_extension = `?hash_type=${hash_type}` || ''
    const path = environment.apiURL + `stores/${store_id}${hash_type_extension}`

    return this.http.patch<HashType>(path, { store: data })
  }

  public getStoresAPHashes() {
    const path = environment.apiURL + 'stores.json'
    return this.http.get<APStore[]>(path, { params: { hash_type: 'admin_page_hash' } })
  }

  public getAPStore(id: number | string) {
    const path = environment.apiURL + `stores/${id}/`
    return this.http.get<APStore>(path, { params: { hash_type: 'admin_page_hash' } })
  }

  public getStoreCustomers(id: number | string){
    const path = environment.apiURL + `stores/${id}/customers`
    return this.http.get<APCustomer[]>(path)
  }

  public getStoreTickets(id: number | string){
    const path = environment.apiURL + `stores/${id}/tickets`
    return this.http.get<APTicket[]>(path)
  }

  public changeCustomers(id: number, add_customer_ids: number[], remove_customer_ids: number[]) {
    const path = environment.apiURL + `stores/${id}/customers`
    return this.http.patch<APCustomer[]>(path, {customer_changes: {add_customer_ids, remove_customer_ids}})
  }

  public storeIsDeletable(id: string | number) {
    const path = environment.apiURL + `stores/${id}/deletable`
    return this.http.get<{deletable: boolean}>(path)
  }

  public deleteStore(id: string | number) {
    const path = environment.apiURL + `stores/${id}`
    return this.http.delete<APStore>(path)
  }
}
