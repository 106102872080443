
<div class="row justify-content-center">
  <div class="col-sm-12 col-lg-4">
    <div class="card">
      <div class="card-body">
        <h5 class="card-title">Login</h5>
        <!-- <h6 class="card-subtitle mb-2 text-muted">Hawk Login erneut notwe</h6> -->
        <form [formGroup]="loginForm" (ngSubmit)="onLoginClick()">
          <div class="form-group">
            <label for="email">E-Mail</label>
            <input type="email" class="form-control" id="email" formControlName="email"  placeholder="E-Mail-Adresse">
          </div>
          <div class="form-group">
            <label for="password">Passwort</label>
            <input type="password" class="form-control" id="password" formControlName="password"  placeholder="Passwort">
          </div>
          <button type="submit" class="btn btn-primary">Login</button>
        </form>
        <!-- <a href="#" class="card-link">Another link</a> -->
      </div>
    </div>
  </div>
</div>
<div class="row justify-content-center">
  <div class="col-sm-12 col-lg-4">
    <div class="card">
      <div class="card-body">
        {{ error | json }}
      </div>
    </div>
  </div>
</div>
