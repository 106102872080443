<div class="d-flex align-items-center" *ngIf="articleTemplateId && articleTemplate">
    <div class="col t-header d-flex align-items-center justify-content-start">
        <h4 class="mb20 d-flex align-items-center gap-1">
            <i class="fa fa-cubes"></i>
            <ul class="list-group list-group-horizontal b-custom">
                <li class="list-group-item bg-transparent border-0">
                    <a
                    class="g-link"
                    [routerLink]="['/', 'artikelvorlagen', 'uebersicht']"
                    >
                        Artikelvorlagen
                    </a>
                </li>
                <li class="list-group-item bg-transparent border-0">
                    <span>{{articleTemplate.description}}</span>
                </li>
            </ul>
        </h4>
    </div>
</div>
<div class="d-flex align-items-center" *ngIf="newArticleTemplate">
    <div class="col t-header d-flex align-items-center justify-content-start">
        <h4 class="mb20 d-flex align-items-center gap-1">
            <i class="fa fa-cubes"></i>
            <ul class="list-group list-group-horizontal b-custom">
                <li class="list-group-item bg-transparent border-0">
                    <a
                    class="g-link"
                    [routerLink]="['/', 'artikelvorlagen', 'uebersicht']"
                    >
                        Artikelvorlagen
                    </a>
                </li>
                <li class="list-group-item bg-transparent border-0">
                    <span>Neue Artikelvorlage erstellen</span>
                </li>
            </ul>
        </h4>
    </div>
</div>
<div class="ticketPage" *ngIf="articleTemplateId && articleTemplate">
    <aside>
        <div class="d-flex gap-2 flex-column align-items-center justify-content-center">
            <img src="https://placehold.co/300x200?text=Kein+Bild+hochgeladen" />
            <button class="btn d-flex align-items-center gap-1 g-link">
                <i class="fas fa-upload"></i>Artikelbild hochladen
            </button>
            <button class="btn d-flex align-items-center gap-1 g-link">
                <i class="fas fa-trash-alt"></i>Bild löschen
            </button>
        </div>
    </aside>
    <section class="main-content">
        <div class="row">
            <div class="col-xxl-6 col-xl-6 col-md-6 col-sm-12 col-xs-12">
                <section>
                    <h2>Artikel</h2>
                    <table class="ticketPageTable base mt-3">
                        <tr>
                            <th class="editable tableLable" (click)="editingProps['description'] = !editingProps['description']">Name</th>
                            <td *ngIf="!editingProps['description']">{{articleTemplate.description}}</td>
                            <td *ngIf="editingProps['description']">
                                <hawk-shared-edit-input [textValue]="articleTemplate.description" [modus]="'text'"
                                                (saveClicked)="updateKey('description', $event)" (cancelClicked)="editingProps['description'] = false"></hawk-shared-edit-input>
                            </td>
                        </tr>
                        <tr>
                            <th class="editable tableLable" (click)="editingProps['product_category'] = !editingProps['product_category']">Product</th>
                            <td *ngIf="!editingProps['product_category']">{{articleTemplate.product_category}}</td>
                            <td *ngIf="editingProps['product_category']">
                                <hawk-shared-edit-input [textValue]="articleTemplate.product_category" [modus]="'text'"
                                                (saveClicked)="updateKey('product_category', $event)" (cancelClicked)="editingProps['product_category'] = false"></hawk-shared-edit-input>
                            </td>
                        </tr>
                        <tr>
                            <th class="editable tableLable" (click)="editingProps['vendor'] = !editingProps['vendor']">Vendor</th>
                            <td *ngIf="!editingProps['vendor']">{{articleTemplate.vendor}}</td>
                            <td *ngIf="editingProps['vendor']">
                                <hawk-shared-edit-input [textValue]="articleTemplate.vendor" [modus]="'text'"
                                                (saveClicked)="updateKey('vendor', $event)" (cancelClicked)="editingProps['vendor'] = false"></hawk-shared-edit-input>
                            </td>
                        </tr>
                        <tr>
                            <th class="editable tableLable" (click)="editingProps['part_nr'] = !editingProps['part_nr']">Part-Nr</th>
                            <td *ngIf="!editingProps['part_nr']">{{articleTemplate.part_nr}}</td>
                            <td *ngIf="editingProps['part_nr']">
                                <hawk-shared-edit-input [textValue]="articleTemplate.part_nr" [modus]="'text'"
                                                (saveClicked)="updateKey('part_nr', $event)" (cancelClicked)="editingProps['part_nr'] = false"></hawk-shared-edit-input>
                            </td>
                        </tr>
                        <tr>
                            <th class="editable tableLable" (click)="editingProps['end_of_life'] = !editingProps['end_of_life']">End of life</th>
                            <td *ngIf="!editingProps['end_of_life']"><span *ngIf="articleTemplate.end_of_life" class="fa fa-check"></span></td>
                            <td *ngIf="editingProps['end_of_life']">
                                <hawk-shared-edit-input [boolValue]="articleTemplate.end_of_life" [modus]="'checkbox'"
                                                (saveClicked)="updateKey('end_of_life', $event)" (cancelClicked)="editingProps['end_of_life'] = false"></hawk-shared-edit-input>
                            </td>
                        </tr>
                        <tr>
                            <th class="editable tableLable" (click)="editingProps['sn'] = !editingProps['sn']">Mit SN</th>
                            <td *ngIf="!editingProps['sn']"><span *ngIf="articleTemplate.sn" class="fa fa-check"></span></td>
                            <td *ngIf="editingProps['sn']">
                                <hawk-shared-edit-input [boolValue]="articleTemplate.sn" [modus]="'checkbox'"
                                                (saveClicked)="updateKey('sn', $event ? true : false)" (cancelClicked)="editingProps['sn'] = false"></hawk-shared-edit-input>
                            </td>
                        </tr>
                        <tr>
                            <th class="editable tableLable" (click)="editingProps['comment1'] = !editingProps['comment1']">Kommentarfeld 1</th>
                            <td *ngIf="!editingProps['comment1']">{{articleTemplate.comment1}}</td>
                            <td *ngIf="editingProps['comment1']">
                                <hawk-shared-edit-input [textValue]="articleTemplate.comment1" [modus]="'text'"
                                                (saveClicked)="updateKey('comment1', $event)" (cancelClicked)="editingProps['comment1'] = false"></hawk-shared-edit-input>
                            </td>
                        </tr>
                        <tr>
                            <th class="editable tableLable" (click)="editingProps['comment2'] = !editingProps['comment2']">ProjektID Zugehörigkeit (Komma)</th>
                            <td *ngIf="!editingProps['comment2']">{{articleTemplate.comment2}}</td>
                            <td *ngIf="editingProps['comment2']">
                                <hawk-shared-edit-input [textValue]="articleTemplate.comment2" [modus]="'text'"
                                                (saveClicked)="updateKey('comment2', $event)" (cancelClicked)="editingProps['comment2'] = false"></hawk-shared-edit-input>
                            </td>
                        </tr>
                        <tr>
                            <th class="editable tableLable" (click)="editingProps['comment3'] = !editingProps['comment3']">Kunden-ID</th>
                            <td *ngIf="!editingProps['comment3']">{{articleTemplate.comment3}}</td>
                            <td *ngIf="editingProps['comment3']">
                                <hawk-shared-edit-input [textValue]="articleTemplate.comment3" [modus]="'text'"
                                                (saveClicked)="updateKey('comment3', $event)" (cancelClicked)="editingProps['comment3'] = false"></hawk-shared-edit-input>
                            </td>
                        </tr>
                        <tr>
                            <th class="editable tableLable" (click)="editingProps['active'] = !editingProps['active']">Vorlage aktiviert</th>
                            <td *ngIf="!editingProps['active']"><span *ngIf="articleTemplate.active" class="fa fa-check"></span></td>
                            <td *ngIf="editingProps['active']">
                                <hawk-shared-edit-input [boolValue]="articleTemplate.active" [modus]="'checkbox'"
                                                (saveClicked)="updateKey('active', $event)" (cancelClicked)="editingProps['active'] = false"></hawk-shared-edit-input>
                            </td>
                        </tr>
                    </table>
                </section>
                <section>
                    <h2 (click)="editingProps['info'] = !editingProps['info']">Weitere Info</h2>
                    <p>{{articleTemplate.info}}</p>
                    <hawk-shared-edit-input *ngIf="editingProps['info']" [textValue]="articleTemplate.info" [modus]="'textarea'"
                                                (saveClicked)="updateKey('info', $event)" (cancelClicked)="editingProps['info'] = false"></hawk-shared-edit-input>
                </section>
            </div>
            <div class="col-xxl-6 col-xl-6 col-md-6 col-sm-12 col-xs-12">
                <section>
                    <h2>Preise</h2>
                    <table class="ticketPageTable base mt-3">
                        <tr>
                            <th class="editable tableLable" (click)="editingProps['purchase_value'] = !editingProps['purchase_value']">Einkauf</th>
                            <td *ngIf="!editingProps['purchase_value'] && articleTemplate.purchase_value > 0">{{articleTemplate.purchase_value| currency:'€'}}</td>
                            <td *ngIf="!editingProps['purchase_value'] && articleTemplate.purchase_value <= 0">-</td>
                            <td *ngIf="editingProps['purchase_value']">
                                <hawk-shared-edit-input [numberValue]="articleTemplate.purchase_value" [modus]="'onlyNumber'"
                                                (saveClicked)="updateKey('purchase_value', $event)" (cancelClicked)="editingProps['purchase_value'] = false"></hawk-shared-edit-input>
                            </td>
                        </tr>
                        <tr>
                            <th class="editable tableLable" (click)="editingProps['selling_value'] = !editingProps['selling_value']">Verkauf</th>
                            <td *ngIf="!editingProps['selling_value'] && articleTemplate.selling_value > 0">{{articleTemplate.selling_value| currency:'€'}}</td>
                            <td *ngIf="!editingProps['selling_value'] && articleTemplate.selling_value <= 0">-</td>
                            <td *ngIf="editingProps['selling_value']">
                                <hawk-shared-edit-input [numberValue]="articleTemplate.selling_value" [modus]="'onlyNumber'"
                                                (saveClicked)="updateKey('selling_value', $event)" (cancelClicked)="editingProps['selling_value'] = false"></hawk-shared-edit-input>
                            </td>
                        </tr>
                    </table>
                </section>
                <section>
                    <h2>Sollwerte</h2>
                    <table class="ticketPageTable base mt-3">
                        <tr>
                            <th class="editable tableLable" (click)="editingProps['minimum_stock'] = !editingProps['minimum_stock']">Minimum</th>
                            <td *ngIf="!editingProps['minimum_stock']">{{articleTemplate.minimum_stock > 0 ? articleTemplate.minimum_stock : '-' }}</td>
                            <td *ngIf="editingProps['minimum_stock']">
                                <hawk-shared-edit-input [numberValue]="articleTemplate.minimum_stock" [modus]="'onlyNumber'"
                                                (saveClicked)="updateKey('minimum_stock', $event)" (cancelClicked)="editingProps['minimum_stock'] = false"></hawk-shared-edit-input>
                            </td>
                        </tr>
                        <tr>
                            <th class="editable tableLable" (click)="editingProps['warning_threshold'] = !editingProps['warning_threshold']">Warnung bei</th>
                            <td *ngIf="!editingProps['warning_threshold']">{{articleTemplate.warning_threshold > 0 ? articleTemplate.warning_threshold : '-'}}</td>
                            <td *ngIf="editingProps['warning_threshold']">
                                <hawk-shared-edit-input [numberValue]="articleTemplate.warning_threshold" [modus]="'onlyNumber'"
                                                (saveClicked)="updateKey('warning_threshold', $event)" (cancelClicked)="editingProps['warning_threshold'] = false"></hawk-shared-edit-input>
                            </td>
                        </tr>
                    </table>
                </section>
                <section>
                    <h2>CQC-Felder für UniData-Projekt</h2>
                    <table class="ticketPageTable base mt-3">
                        <tr>
                            <th class="editable tableLable" (click)="editingProps['unidata_report'] = !editingProps['unidata_report']">Instandsetzerbericht notwendig</th>
                            <td *ngIf="!editingProps['unidata_report']"><span *ngIf="articleTemplate.unidata_report" class="fa fa-check"></span><span *ngIf="!articleTemplate.unidata_report">-</span></td>
                            <td *ngIf="editingProps['unidata_report']">
                                <hawk-shared-edit-input [boolValue]="articleTemplate.unidata_report" [modus]="'checkbox'"
                                                (saveClicked)="updateKey('unidata_report', $event)" (cancelClicked)="editingProps['unidata_report'] = false"></hawk-shared-edit-input>
                            </td>
                        </tr>
                        <tr>
                            <th class="editable tableLable" (click)="editingProps['unidatahdd'] = !editingProps['unidatahdd']">HDD ausgebaut</th>
                            <td *ngIf="!editingProps['unidatahdd']"><span *ngIf="articleTemplate.unidatahdd" class="fa fa-check"></span><span *ngIf="!articleTemplate.unidatahdd">-</span></td>
                            <td *ngIf="editingProps['unidatahdd']">
                                <hawk-shared-edit-input [boolValue]="articleTemplate.unidatahdd" [modus]="'checkbox'"
                                                (saveClicked)="updateKey('unidatahdd', $event)" (cancelClicked)="editingProps['unidatahdd'] = false"></hawk-shared-edit-input>
                            </td>
                        </tr>
                        <tr>
                            <th class="editable tableLable" (click)="editingProps['unidata_reinstall'] = !editingProps['unidata_reinstall']">Reinstallation</th>
                            <td *ngIf="!editingProps['unidata_reinstall']"><span *ngIf="articleTemplate.unidata_reinstall" class="fa fa-check"></span><span *ngIf="!articleTemplate.unidata_reinstall">-</span></td>
                            <td *ngIf="editingProps['unidata_reinstall']">
                                <hawk-shared-edit-input [boolValue]="articleTemplate.unidata_reinstall" [modus]="'checkbox'"
                                                (saveClicked)="updateKey('unidata_reinstall', $event)" (cancelClicked)="editingProps['unidata_reinstall'] = false"></hawk-shared-edit-input>
                            </td>
                        </tr>
                    </table>
                </section>
            </div>
        </div>
        <div class="d-flex justify-content-between ms-2">
            <a [routerLink]="['/', 'artikelvorlagen', 'uebersicht']" class="btn btn-primary d-flex align-items-center gap-1"><i class="fa fa fa-angle-double-left"></i>Zurück</a>
            <button (click)="onDelete()"  class="btn d-flex align-items-center gap-1 g-link">
                <i class="fas fa-trash-alt"></i>Artikelvorlage löschen
            </button>
        </div>
    </section>
</div>
<form *ngIf="newArticleTemplate" [formGroup]="articleTemplateForm" (submit)="createArticleTemplate()">
    <div class="ticketPage">
        <section class="main-content">
            <header class="row">
                <section>
                    <h2>Stammdaten</h2>
                    <div class="form-group">
                        <mat-form-field appearance="outline" class="col-6">
                            <mat-label>Bezeichnung</mat-label>
                            <input
                                matInput
                                type="text"
                                placeholder="Bezeichnung"
                                formControlName="description"
                            />
                        </mat-form-field>
                    </div>
                    <div class="form-group">
                        <mat-form-field appearance="outline" class="col-6">
                            <mat-label>Produktkategorie</mat-label>
                            <input
                                matInput
                                type="text"
                                placeholder="Produktkategorie"
                                formControlName="product_category"
                            />
                        </mat-form-field>
                    </div>
                    <div class="form-group">
                        <mat-form-field appearance="outline" class="col-6">
                            <mat-label>Hersteller</mat-label>
                            <input
                                matInput
                                type="text"
                                placeholder="Hersteller"
                                formControlName="vendor"
                            />
                        </mat-form-field>
                    </div>
                    <div class="form-group">
                        <mat-form-field appearance="outline" class="col-6">
                            <mat-label>Part-Nr</mat-label>
                            <input
                                matInput
                                type="text"
                                placeholder="Part-Nr"
                                formControlName="part_nr"
                            />
                        </mat-form-field>
                    </div>
                    <div class="form-group">
                        <mat-checkbox formControlName="sn">Mit SN</mat-checkbox>
                    </div>
                    <div class="form-group">
                        <mat-form-field appearance="outline" class="col-6">
                            <mat-label>Kommentarfeld 1</mat-label>
                            <input
                                matInput
                                type="text"
                                placeholder="Kommentarfeld 1"
                                formControlName="comment1"
                            />
                        </mat-form-field>
                    </div>
                    <div class="form-group">
                        <mat-form-field appearance="outline" class="col-6">
                            <mat-label>ProjektID Zugehörigkeit (Komma)</mat-label>
                            <input
                                matInput
                                type="text"
                                placeholder="ProjektID Zugehörigkeit (Komma)"
                                formControlName="comment2"
                            />
                        </mat-form-field>
                    </div>
                    <div class="form-group">
                        <mat-form-field appearance="outline" class="col-6">
                            <mat-label>Kunden-ID</mat-label>
                            <input
                                matInput
                                type="text"
                                placeholder="Kunden-ID"
                                formControlName="comment3"
                            />
                        </mat-form-field>
                    </div>
                    <div class="form-group" class="col-6">
                        <mat-form-field class="col-6" appearance="outline">
                            <mat-label>Weitere Info</mat-label>
                            <textarea formControlName="info" matInput></textarea>
                        </mat-form-field>
                    </div>
                </section>
                <div class="d-flex mx-2 align-items-center justify-content-between">
                    <button mat-raised-button class="button" [disabled]="articleTemplateForm.invalid || submittingTemplate" type="submit">
                        Artikelvorlage erstellen
                    </button>
                    <a class="g-link" [routerLink]="['/', 'artikelvorlagen', 'uebersicht']" >Abbrechen</a>
                </div>
            </header>
        </section>
        <aside>
            <section>
                <h2>MengenSumme <small class="text-muted">aller Lager</small></h2>
                <div class="form-group">
                    <mat-form-field appearance="outline">
                        <mat-label>Minimum</mat-label>
                        <input
                            matInput
                            type="text"
                            placeholder="Minimum"
                            formControlName="minimum_stock"
                        />
                    </mat-form-field>
                </div>
                <div class="form-group">
                    <mat-form-field appearance="outline">
                        <mat-label>Warnung bei</mat-label>
                        <input
                            matInput
                            type="text"
                            placeholder="Warnung bei"
                            formControlName="warning_threshold"
                        />
                    </mat-form-field>
                </div>
            </section>
            <section>
                <h2>Preise</h2>
                <div class="form-group">
                    <mat-form-field appearance="outline">
                        <mat-label>Einkaufswert</mat-label>
                        <input
                            matInput
                            type="number"
                            placeholder="Einkaufswert"
                            formControlName="purchase_value"
                        />
                        <span matTextPrefix>€&nbsp;</span>
                    </mat-form-field>
                </div>
                <div class="form-group">
                    <mat-form-field appearance="outline">
                        <mat-label>Verkaufswert</mat-label>
                        <input
                            matInput
                            type="number"
                            placeholder="Verkaufswert"
                            formControlName="selling_value"
                        />
                        <span matTextPrefix>€&nbsp;</span>
                    </mat-form-field>
                </div>
            </section>
        </aside>
    </div>
</form>