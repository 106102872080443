import { Component, inject, OnInit } from '@angular/core';
import { faArrowLeft, faTrash } from "@fortawesome/free-solid-svg-icons";
import { APStore, StoreUpdateParams } from "../../_models/store.interface";
import { AddressOutput, EditAddressComponent } from "../_shared/edit-address/edit-address.component";
import { CustomerService, StoreService } from "../../_services";
import { ActivatedRoute, Router, RouterLink } from "@angular/router";
import { Opening } from "../../_models/opening.interface";
import { APCustomer } from "../../_models/customer.interface";
import { APTicket } from "../../_models/ticket.interface";
import { AG_GRID_LOCALE_DE } from "../../_helpers/aggrid.locale.de";
import {
  GridApi,
  RowClassRules,
  SideBarDef, SizeColumnsToContentStrategy,
  SizeColumnsToFitGridStrategy,
  SizeColumnsToFitProvidedWidthStrategy
} from "ag-grid-enterprise";
import { ColDef, GridReadyEvent } from "ag-grid-community";
import { EditInputComponent } from "../_shared/edit-input/edit-input.component";
import { NgxMapboxGLModule } from "ngx-mapbox-gl";
import { FaIconComponent } from "@fortawesome/angular-fontawesome";
import { MatButton } from "@angular/material/button";
import { AgGridAngular } from "ag-grid-angular";
import { MatTooltip } from "@angular/material/tooltip";
import { MatCheckbox } from "@angular/material/checkbox";
import { FormsModule } from "@angular/forms";
import { MatFormField, MatLabel } from "@angular/material/form-field";
import { NgForOf, NgIf } from "@angular/common";
import { MatInput } from "@angular/material/input";
import { AgGridThemeService } from "../../_services/ag-grid-theme.service";
import { RecordNavigationComponent } from "../_shared/record-navigation/record-navigation.component";

@Component({
  selector: 'hawk-store-show',
  templateUrl: './store-show.component.html',
  standalone: true,
  imports: [
    EditAddressComponent,
    RecordNavigationComponent,
    EditInputComponent,
    RouterLink,
    NgxMapboxGLModule,
    FaIconComponent,
    MatButton,
    AgGridAngular,
    MatTooltip,
    MatCheckbox,
    FormsModule,
    MatFormField,
    MatLabel,
    NgIf,
    MatInput,
    NgForOf
  ],
  styleUrls: ['./store-show.component.scss', '../_shared/styles/common-styles.scss']
})
export class StoreShowComponent implements OnInit {
  public hawkTheme = inject(AgGridThemeService).getTheme();

  protected readonly faArrowLeft = faArrowLeft;
  protected readonly faTrash = faTrash;

  store: APStore
  tickets: APTicket[] = [];
  customers: APCustomer[] = []
  storeCustomers: APCustomer[] = []
  selectedStoreCustomerIds: number[] = []

  storeIsDeletable = false
  editNr: boolean = false;
  editName: boolean = false;
  editCustomer: boolean = false;
  editMail: boolean = false;
  editPhone1: boolean = false;
  editPhone2: boolean = false;
  editComment: boolean = false;
  editRegister: boolean = false;
  editIP: boolean = false;
  showModal: 'address' | 'openings' = null;

  mondayOpenings: Opening[] = []
  tuesdayOpenings: Opening[] = []
  wednesdayOpenings: Opening[] = []
  thursdayOpenings: Opening[] = []
  fridayOpenings: Opening[] = []
  saturdayOpenings: Opening[] = []
  sundayOpenings: Opening[] = []

  //OpeningModal
  setMonday = true
  setTuesday = true
  setWednesday = true
  setThursday = true
  setFriday = true
  setSaturday = false
  setSunday = false
  timeFrom: string = ''
  timeUntil: string = ''

  protected readonly locale = AG_GRID_LOCALE_DE;
  private gridApi!: GridApi;

  public rowClassRules: RowClassRules = {}
  autoSizeStrategy: SizeColumnsToFitGridStrategy | SizeColumnsToFitProvidedWidthStrategy | SizeColumnsToContentStrategy = {
    type: "fitGridWidth",
  }

  public colDefs: ColDef[] = [
    {
      field: "order_nr",
      headerName: "Nr.",
      width: 160,
      cellClass: 'hover_link_cell',
      cellRenderer: this.orderNrRenderer,
    },
    {
      field: "priority.name",
      headerName: "Priorität",
      width: 240,
    },
    {
      field: "project",
      headerName: "Projekt",
      width: 360,
      cellClass: 'hover_link_cell',
      cellRenderer: this.projectRenderer,
    },
    {
      field: "customer",
      headerName: "Kunde",
      width: 360,
      cellClass: 'hover_link_cell',
      cellRenderer: this.customerRenderer,
    },
  ]

  public sideBarOptions: SideBarDef = {
    hiddenByDefault: false,
    toolPanels: ['columns', 'filters']
  }

  public defaultColDef: ColDef = {
    filter: true
  }

  public rowSelection: "single" | "multiple" = "single"

  constructor(
    private storeService: StoreService,
    private customerService: CustomerService,
    private route: ActivatedRoute,
    private router: Router
  ) {
  }

  ngOnInit(): void {
    const id = this.route.snapshot.paramMap.get('id')
    this.loadStore(id);
    this.updateDeletable(id)
    this.loadStoreOpenings(id)
    this.loadStoreCustomers(id)
    this.loadStoreTickets(id)
    this.loadAllCustomers()
  }

  loadStoreOpenings(id: number | string) {
    this.storeService.getStoreOpenings(id).subscribe(
      data => {
        this.updateOpenings(data);
      },
      error => {
        console.log(error)
      }
    )
  }

  private updateOpenings(data: Opening[]) {
    this.mondayOpenings = this.filterSortedOpeningsForDay(data, 0)
    this.tuesdayOpenings = this.filterSortedOpeningsForDay(data, 1)
    this.wednesdayOpenings = this.filterSortedOpeningsForDay(data, 2)
    this.thursdayOpenings = this.filterSortedOpeningsForDay(data, 3)
    this.fridayOpenings = this.filterSortedOpeningsForDay(data, 4)
    this.saturdayOpenings = this.filterSortedOpeningsForDay(data, 5)
    this.sundayOpenings = this.filterSortedOpeningsForDay(data, 6)
  }

  loadAllCustomers(): void {
    this.customerService.loadAPCustomers().subscribe(
      data => {
        this.customers = data;
      },
      error => {
        console.log(error)
      }
    )
  }

  loadStoreCustomers(id: string): void {
    this.storeService.getStoreCustomers(id).subscribe(
      data => {
        this.updateStoreCustomerData(data);
      },
      error => {
        console.log(error)
      }
    )
  }

  loadStoreTickets(id: string): void {
    this.storeService.getStoreTickets(id).subscribe(
      data => {
        this.tickets = data;
      },
      error => {
        console.log(error)
      }
    )
  }

  private updateStoreCustomerData(data: APCustomer[]) {
    this.storeCustomers = data
    this.selectedStoreCustomerIds = data.map(customer => customer.id);
  }

  filterSortedOpeningsForDay(openings: Opening[], day_idx: number): Opening[] {
    return openings.filter(item => item.day == day_idx).sort((a, b) => a.open == b.open ? (a.close < b.close ? -1 : 1) : a.open < b.open ? -1 : 1)
  }

  private loadStore(id: string) {
    this.storeService.getAPStore(id).subscribe(
      data => {
        this.store = data
      },
      error => {
        console.log(error);
      }
    )
  }

  saveNr($event: string) {
    this.saveStoreValues({nr: $event}).then(success => {
      if (success) this.editNr = null
    })
  }

  saveName($event: string) {
    this.saveStoreValues({name: $event}).then(success => {
      if (success) this.editName = null
    })
  }

  saveCustomers(customer_ids_after: number[]) {
    const customer_ids_before = this.storeCustomers.map(c => c.id)
    const add_customer_ids = customer_ids_after.filter(id => !customer_ids_before.includes(id))
    const remove_customer_ids = customer_ids_before.filter(id => !customer_ids_after.includes(id))
    this.updateStoreCustomers(add_customer_ids, remove_customer_ids).then(success => {
      if (success) this.editCustomer = null
    })
  }

  saveMail($event: string) {
    this.saveStoreValues({email: $event}).then(success => {
      if (success) this.editMail = null
    })
  }

  savePhone1($event: string) {
    this.saveStoreValues({phone1: $event}).then(success => {
      if (success) this.editPhone1 = null
    })
  }

  savePhone2($event: string) {
    this.saveStoreValues({phone2: $event}).then(success => {
      if (success) this.editPhone2 = null
    })
  }

  saveComment($event: string) {
    this.saveStoreValues({comment: $event}).then(success => {
      if (success) this.editComment = null
    })
  }

  saveAddress($event: AddressOutput) {
    this.saveStoreValues($event).then(success => {
      if (success) this.showModal = null
    })
  }

  saveRegister($event: number) {
    this.saveStoreValues({register: $event}).then(success => {
      if (success) this.editRegister = null
    })
  }

  saveIP($event: string) {
    this.saveStoreValues({ip: $event}).then(success => {
      if (success) this.editIP = null
    })
  }

  async saveStoreValues(store_params: StoreUpdateParams): Promise<boolean> {
    return await new Promise<boolean>(resolve => {
      this.storeService.updateStore<APStore>(this.store.id, store_params, 'admin_page_hash').subscribe(
        data => {
          this.store = data
          resolve(true)
        },
        error => {
          console.log(error)
          resolve(false)
        }
      )
    })
  }

  async updateStoreCustomers(add_customer_ids: number[], remove_customer_ids: number[]): Promise<boolean> {
    return await new Promise<boolean>(resolve => {
      this.storeService.changeCustomers(this.store.id, add_customer_ids, remove_customer_ids).subscribe(
        data => {
          this.updateStoreCustomerData(data)
          resolve(true)
        },
        error => {
          console.log(error)
          resolve(false)
        }
      )
    })
  }

  updateDeletable(id: string): void {
    this.storeService.storeIsDeletable(id).subscribe(
      data => {
        this.storeIsDeletable = data.deletable
      },
      err => {
        console.log(err)
      }
    )
  }

  deleteStore() : void {
    this.storeService.deleteStore(this.store.id).subscribe(
      _ => {
        this.router.navigate(['/filiale'])
      },
      error => {
        console.log(error)
      }
    )
  }

  deleteOpening(opening: Opening) {
    this.storeService.destroyStoreOpening(this.store.id, opening.id).subscribe(
      data => {
        this.updateOpenings(data)
      },
      error => {
        console.log(error)
      }
    )
  }

  addOpenings() {
    const promises: Promise<void>[] = []
    if (this.timeFrom && this.timeUntil) {
      if (this.setMonday) promises.push(this.createOpeningForDayIdx(0))
      if (this.setTuesday) promises.push(this.createOpeningForDayIdx(1))
      if (this.setWednesday) promises.push(this.createOpeningForDayIdx(2))
      if (this.setThursday) promises.push(this.createOpeningForDayIdx(3))
      if (this.setFriday) promises.push(this.createOpeningForDayIdx(4))
      if (this.setSaturday) promises.push(this.createOpeningForDayIdx(5))
      if (this.setSunday) promises.push(this.createOpeningForDayIdx(6))
    }

    Promise.all(promises).then(_ => {
      this.loadStoreOpenings(this.store.id)
      this.showModal = null
    })
  }

  createOpeningForDayIdx(idx: number): Promise<void> {
    return new Promise(resolve => {
      this.storeService.createStoreOpening({ store_id: this.store.id, day: idx, open: this.timeFrom, close: this.timeUntil }).subscribe(
        _ => {
          resolve()
        },
        error => {
          console.log(error)
          resolve()
        }
      )
    })
  }

  openOpeningModal() {
    this.resetOpeningModal()
    this.showModal = 'openings'
  }

  resetOpeningModal() {
    this.setMonday = true
    this.setTuesday = true
    this.setWednesday = true
    this.setThursday = true
    this.setFriday = true
    this.setSaturday = false
    this.setSunday = false
    this.timeFrom = '08:00'
    this.timeUntil = '18:00'
  }

  onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api
  }

  public quickSearchChanged(e: Event) {
    const newValue = (e.target as HTMLInputElement).value
    this.gridApi.setGridOption(
      "quickFilterText",
      newValue,
    )
  }

  orderNrRenderer(params: any): string {
    const id = params.data.id
    return `<a class="hover_link" href="/a/ticket/${id}" rel="noopener">${params.value}</a>`
  }

  projectRenderer(params: any): string {
    const id = params.value.id
    return `<a class="hover_link" href="/a/projekt/${id}" rel="noopener">${params.value.name}</a>`
  }

  customerRenderer(params: any): string {
    const id = params.value.id
    return `<a class="hover_link" href="/a/kunde/${id}" rel="noopener">${params.value.name}</a>`
  }
}
