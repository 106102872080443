import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ExpandBarHeaderComponent } from '../expand-bar-header/expand-bar-header.component';
import { NgClass } from '@angular/common';
import { CommonModule } from "@angular/common";

@Component({
  selector: 'hawk-icon-expand-bar',
  templateUrl: './icon-expand-bar.component.html',
  styleUrls: ['./icon-expand-bar.component.scss'],
  standalone: true,
  imports: [ExpandBarHeaderComponent, NgClass, CommonModule]
})
export class IconExpandBarComponent implements OnInit {

  @Input() description: string
  @Input() isExpanded: boolean
  @Input() leftAligned: boolean = true
  @Input() fontSize: string = '.8rem'
  @Input() height: string = '100%'
  @Input() fontColor: string = 'white'
  @Output() toggle = new EventEmitter<boolean>()

  constructor() {
  }

  ngOnInit(): void {
  }

  click(event: boolean): void {
    this.isExpanded = event
    this.toggle.emit(this.isExpanded)
  }
}