
<ng-container *ngIf="store">
  <h6>{{store.address_company}}</h6>
  <hawk-icon-expand-bar *ngFor="let project of projects"
  [description]="project.project_name"
  [isExpanded]="expandedProjects.includes(project)">
    <div content class="operations_list">
      <div *ngFor="let op of getStoresProjectOperations(project)" class="store_operation_container">
        <div class="operation_value" style="flex: 1;">
          <div style="line-height: 14px; font-size: 12px;">
            T:
            <ng-container *ngIf="op.ticket.id >= 0">
              <a href="/a/ticket/{{op.ticket.id}}?operation_id={{op.id}}" target="_blank"> {{op.ticket.id}}  </a>
            </ng-container>
            <ng-container *ngIf="op.ticket.id < 0">
              NEU
            </ng-container> <br>
            {{op.ticket.priority.name}}
          </div>
        </div>
        <div class="operation_value" style="width: 160px;" [tooltip]="op.description">
          <div style="line-height: 14px; font-size: 12px;">
            {{op.description.slice(0, 40)}}
            <ng-container *ngIf="op.description.length > 40">...</ng-container>
          </div>
        </div>
        <div style="margin: 0; position: relative;">
          <ng-container *ngIf="op.isUnassigned()">
            <ng-container *ngIf="op.isInPlanningProcess() || (PATourPlannerControl.Instance.tourPlannerExtraOperationCollection.includes(op) && (!this.technicianDate || this.technicianDate.hasOperationWithIdInChangedTour(op.id))); else elseBlock">
              <div class="icon_container" style="padding: 0 2px; background-color: #f44336; color: white; cursor: pointer;" (click)="removeOperationFromTourPlanning(op)" >
                <fa-icon [icon]="faMinus" style="line-height: 10px; font-size: 10px"></fa-icon>
                <div style="line-height: 9px; font-size: 10px">
                  {{op.calculateOperationTimeMilliseconds({use_technician: technicianDate?.technician}) / 60000}}min
                </div>
              </div>
            </ng-container>
            <ng-template #elseBlock>
              <div class="black_gradient_background icon_container" style="padding: 0 2px;" (click)="addOperationToTourPlanning(op)">
                <fa-icon [icon]="faPlus" style="height: 14px; line-height: 18px;"></fa-icon>
                <div style="line-height: 9px; font-size: 10px">
                  {{op.calculateOperationTimeMilliseconds({use_technician: technicianDate?.technician}) / 60000}}min
                </div>
              </div>
            </ng-template>
            <div *ngIf="technicianDate && !op.checkGeneralTechnicianDoability(technicianDate.technician)" style="position: absolute; top: 0; right: 0; left: 0; bottom: 0; display: inline-flex; background-color: rgba(53,53,53,0.6)" [tooltip]="op.nonFeasibilityReasonsForTechnician(technicianDate.technician).join(' | ')">
              <div style="margin: auto; font-size: 1.2rem;">
                &#x274C;
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="!op.isUnassigned()">
            <div class="black_gradient_background_no_hover icon_container" [tooltip]="op.getDateString()" style="padding: 0 6px; display: inline-flex;">
              <fa-icon [icon]="faCalendarCheck"></fa-icon>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
    <div icon style="margin: auto; display: inline-flex;">
      <div [style.background-color]="project.color" class="project_color_circle" style="color: black; display: inline-flex; line-height: 0;">
        <div style="margin: auto; line-height: 0;"></div>
      </div>
    </div>
  </hawk-icon-expand-bar>
</ng-container>
