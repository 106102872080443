import { Component, OnInit, HostBinding, Input } from '@angular/core';
import { NgClass } from '@angular/common';
import { CommonModule } from "@angular/common";

@Component({
  selector: 'hawk-popup-menu',
  templateUrl: './popup-menu.component.html',
  styleUrls: ['./popup-menu.component.scss'],
  standalone: true,
  imports: [NgClass, CommonModule]
})
export class PopupMenuComponent implements OnInit {
  @HostBinding("style.bottom") y = "50%"
  @HostBinding("style.left") x = "50%"
  @HostBinding("style.visibility") visibility = "hidden"

  @Input() anchor: 'left' | 'bottom' = 'bottom';

  constructor() {
  }

  ngOnInit() {
  }

  open() {
    if (this.visibility != 'visible') {
      this.visibility = "visible"
    }
  }

  close() {
    this.visibility = "hidden"
  }

  public onClick(event: MouseEvent) {
    event.stopPropagation()
  }
}