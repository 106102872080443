import { RouterModule } from "@angular/router";

import { Component, inject, OnInit } from '@angular/core'
import { ColDef } from 'ag-grid-community'
import { GridApi, GridState, RowClassRules, SideBarDef } from 'ag-grid-enterprise'
import { GridReadyEvent } from 'ag-grid-community'
import { ArticleTemplateService } from '../../_services'
import { AG_GRID_LOCALE_DE } from 'src/app/_helpers/aggrid.locale.de'
import { AgGridGenericLinkRenderer } from '../_shared/ag-grid-renderer/ag-grid-generic-link.component'
import { AgGridAngular } from "ag-grid-angular";
import { AgGridThemeService } from "src/app/_services/ag-grid-theme.service";

@Component({
  imports: [RouterModule, AgGridAngular],
    selector: 'hawk-article-templates',
    templateUrl: './article-templates.component.html',
    styleUrls: ['./article-templates.component.scss'],
})
export class ArticleTemplatesComponent implements OnInit {
  public hawkTheme = inject(AgGridThemeService).getTheme();
  // For now lets go without a model. Checking with signkey
  articleTemplates: any[]
  public locale = AG_GRID_LOCALE_DE
  public sideBarOptions: SideBarDef = {
    hiddenByDefault: false,
    toolPanels: ['columns', 'filters']
  }
  public paginationPageSize = 50;
  public paginationPageSizeSelector = [50,100,300,1000,9000]
  public storageKey = 'ticketSearchStateSettings'

  public rowSelection: "single" | "multiple" = "multiple"
  public initialState?: GridState;
  // public rowModelType = 'serverSide';
  public rowClassRules: RowClassRules = {
    // if tech on site, color row
    'tech-on-site': (params) => {
      if (params?.data === undefined) return "...";
      return params.data.tech_on_site
    },
  }

  public defaultColDef: ColDef = {
    filter: true,
    flex: 1
  }

  // Column Definitions: Defines the columns to be displayed.
  public colDefs: ColDef[] = [
    {
      field: "id",
      headerName: 'ID',
      minWidth: 70,
      width: 70,
      maxWidth: 100
    },
    {
      field: "description",
      headerName: 'Bezeichnung',
      cellRenderer: AgGridGenericLinkRenderer,
      cellRendererParams: {
        labelFromData: true,
        dataKey: 'description',
        routerLink: ['/', 'artikelvorlagen', '@id']
      },
      filter: "agTextColumnFilter",
    },
    {
      field: "product_category",
      headerName: 'Produktkategorie',
      filter: "agTextColumnFilter",
    },
    {
      field: "vendor",
      headerName: 'Hersteller',
      filter: "agTextColumnFilter",
    },
    {
      field: "comment1",
      headerName: 'Kommentar 1',
      filter: "agTextColumnFilter",
    },
    {
      field: "articles_count",
      headerName: 'Bestand',
      cellRenderer: (params: any) => (`${params.data.articles_count > 0 ? params.data.articles_count : '-' }`)
    },
    {
      field: "warning_threshold",
      headerName: 'Min (Warn)',
      cellRenderer: (params: any) => {
        const minimum_stock = params.data.minimum_stock;
        const warning_threshold = params.data.warning_threshold;
        return `${minimum_stock > 0 ? minimum_stock : '-' }(${warning_threshold > 0 ? warning_threshold : '-'})`
      }
    }
  ]

  private gridApi!: GridApi;

  constructor(
    private articleTemplateService: ArticleTemplateService
  ) { }

  onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api;
  }

  public quicksearchChanged(e: Event) {
    const newValue = (e.target as HTMLInputElement).value;
    this.gridApi.setGridOption(
      "quickFilterText",
      newValue,
    )
  }

  ngOnInit(): void {
    this.articleTemplateService.all({ detail_data: true }).subscribe({
      next: (data) => {
        this.articleTemplates = data;
      },
      error: (err) => {
        console.log('err', err);
      }
    })
  }

}