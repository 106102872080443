<div class="d-flex align-items-center" *ngIf="deliveryNote">
    <div class="col t-header d-flex align-items-center justify-content-start">
        <h4 class="mb20 d-flex align-items-center gap-1">
            <i class="fa fa-truck mr6" aria-hidden="true"></i>
            <ul class="list-group list-group-horizontal b-custom">
                <li class="list-group-item bg-transparent border-0">
                    <a
                    class="g-link"
                    [routerLink]="['/', 'lieferscheine', 'suche', 'index']"
                    >
                        Lieferscheine
                    </a>
                </li>
                <li class="list-group-item bg-transparent border-0">
                    <a
                    class="g-link"
                    [routerLink]="['/', 'lieferscheine', deliveryNote.id]"
                    >
                        <span>{{ deliveryNote.number }}</span>
                    </a>
                </li>
                <li class="list-group-item bg-transparent border-0">
                    Abholzeit festlegen
                </li>
            </ul>
        </h4>
    </div>
</div>
<form [formGroup]="deliveryNotePickup" (submit)="savePickup()" *ngIf="formReady">
    <div class="ticketPage" *ngIf="deliveryNote">
        <section class="main-content">
            <header class="d-flex gap-2">
                <div class="col-6 d-flex flex-column gap-2">
                    <section>
                        <h2>Pakete</h2>
                        <div class="d-flex align-items-center gap-2">
                            <mat-checkbox [checked]="true" [disabled]="true"/>
                            <span>{{ deliveryNote.number }}</span>
                        </div>
                        <div class="clearfix text-muted" *ngIf="false">
                            Weitere mögliche Pakete für diese Abholung:
                            <hr class="m-1"/>
                        </div>
                        <div formGroupName="other_deliveries" *ngIf="false">
                            <div *ngFor="let other_note of otherDeliveries.controls; let i=index">
                                <div class="d-flex align-items-center gap-1" [formGroupName]="i">
                                    <mat-checkbox formControlName="is_checked" />
                                    <a target="_blank" [routerLink]="['/', 'lieferscheine', other_note.value.id]" class="g-link d-flex align-items-center gap-1">
                                        <i class="fa fa-truck mr6"></i>
                                        <span>{{ other_note.value.number }}</span>
                                    </a>
                                    <i class="fa fa-external-link text-muted"></i>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section>
                        <h2>Abholung</h2>
                        <div class="form-group d-flex gap-1">
                            <label class="control-label">Carrier:</label>
                            <p class="form-control-static bold">{{deliveryNote.shipcloud_carrier}}</p>
                        </div>
                        <div class="form-group" *ngIf="deliveryNote.shipcloud_carrier == 'UPS'">
                            <mat-form-field class="col-6" appearance="outline">
                                <mat-label>Telefon</mat-label>
                                <input
                                    matInput
                                    type="text"
                                    placeholder="Telefon"
                                    formControlName="phone"
                                />
                            </mat-form-field>
                        </div>
                        <div class="form-group">
                            <mat-form-field appearance="outline">
                                <input
                                    matInput
                                    [matDatepicker]="picker"
                                    formControlName="pickup_day"
                                />
                                <mat-hint>MM/DD/YYYY</mat-hint>
                                <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                                <mat-datepicker #picker></mat-datepicker>
                            </mat-form-field>
                        </div>
                        <div class="form-group d-flex align-items-center">
                            <mat-form-field appearance="outline">
                                <input
                                    matInput
                                    type="time"
                                    formControlName="pickup_start"
                                />
                            </mat-form-field>
                            <label for="pickup_end" class="col-sm-1 control-label pl-0 pr-0" style="text-align: center;"><i class="fa fa-long-arrow-right" aria-hidden="true"></i></label>
                            <mat-form-field appearance="outline">
                                <input
                                    matInput
                                    type="time"
                                    formControlName="pickup_end"
                                />
                            </mat-form-field>
                        </div>
                        <small class="text-muted">Mit der Abholung experimentieren bis keine Fehlermeldung kommt.</small>
                    </section>
                </div>
                <section class="col-6">
                    <h2>Abholung unter folgender Adresse</h2>
                    <hawk-address-form [formGroup]="deliveryNotePickup" prefix="sender" [countries]="countries"></hawk-address-form>
                </section>
            </header>
            <div class="d-flex col-auto my-2 ms-1 me-2 align-items-center justify-content-between">
                <span class="d-flex col-auto gap-2 align-items-center">
                    <button [disabled]="deliveryNotePickup.invalid || submitting" mat-raised-button class="button" type="submit">Abholzeit festlegen</button>
                    <mat-checkbox formControlName="test">Nur Test</mat-checkbox>
                </span>
                <a class="col-auto col-xs-12" [routerLink]="['/', 'lieferscheine', deliveryNote.id]" >Abbrechen</a>
            </div>
        </section>
    </div>
</form>