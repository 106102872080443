import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { PATimeControl } from "../../singletons/pa-time-control";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { OpeningTimeTemplate } from "../../map/map.component";
import { PAUtil } from "../../classes/util";
import { NgFor, NgClass, NgIf } from '@angular/common';

import { MatCheckbox } from '@angular/material/checkbox';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { CommonModule } from "@angular/common";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { FaIconComponent, FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@Component({
  selector: 'hawk-opening-time-editor',
  templateUrl: './opening-time-editor.component.html',
  styleUrls: ['./opening-time-editor.component.scss', './../../styles/common_styles.scss'],
  standalone: true,
  imports: [NgFor, NgClass, FaIconComponent, NgIf, MatCheckbox, ReactiveFormsModule, FormsModule, CommonModule, MatCheckboxModule, FontAwesomeModule]
})
export class OpeningTimeEditorComponent implements OnChanges {
  @Input() openingTimeTemplates: OpeningTimeTemplate[]

  protected readonly PATimeControl = PATimeControl;
  protected readonly faTrash = faTrash;
  protected readonly Util = PAUtil

  selectedOpeningTimeTemplate: OpeningTimeTemplate
  newOpeningTemplate: OpeningTimeTemplate = null

  ngOnChanges(_: SimpleChanges): void {
    this.resetNewOpeningTemplate()
    this.selectedOpeningTimeTemplate = null
  }

  allOpeningTimeDaysUsed(): boolean {
    let used_day_idxs = this.openingTimeTemplates.reduce((idxs: number[], opening_template: OpeningTimeTemplate) => idxs.concat(opening_template.day_idxs), [])
    return used_day_idxs.length == 7
  }

  selectNewOpeningTemplate(): void {
    this.resetNewOpeningTemplate()
    this.selectedOpeningTimeTemplate = this.newOpeningTemplate
  }

  resetNewOpeningTemplate(): void {
    this.newOpeningTemplate = {
      open: '07:00',
      close: '21:00',
      day_idxs: []
    }
  }

  saveOpeningTimeTemplate(opening_template: OpeningTimeTemplate): void {
    if (!this.openingTimeTemplates.includes(opening_template)) {
      this.openingTimeTemplates.push(opening_template)
      this.updateOpeningTimeTemplateSorting()
    }
    this.resetNewOpeningTemplate()
    this.selectedOpeningTimeTemplate = null
  }

  updateOpeningTimeTemplateSorting(): void {
    this.openingTimeTemplates.sort((template_a, template_b) => Math.min(...template_a.day_idxs) < Math.min(...template_b.day_idxs) ? -1 : 1)
  }

  openingDayIdxIsUsedByOtherOpeningTemplate(idx: number, current_template: OpeningTimeTemplate): boolean {
    let used_day_idxs = this.openingTimeTemplates.reduce((idxs: number[], opening_template: OpeningTimeTemplate) => idxs.concat(opening_template == current_template ? [] : opening_template.day_idxs), [])
    return used_day_idxs.includes(idx)
  }

  toggleOpeningTimeDayTemplateDayIdx(opening_template: OpeningTimeTemplate, day_idx: number): void {
    let day_idxs = opening_template.day_idxs
    if (day_idxs.includes(day_idx)) {
      PAUtil.removeElementFromList(day_idxs, day_idx)
    } else {
      day_idxs.push(day_idx)
    }
    this.updateOpeningTimeTemplateSorting()
  }

  isOnlySelectedDayIdxInOpeningDayTemplate(day_idx: number, opening_template: OpeningTimeTemplate) {
    if (this.openingTimeTemplates.includes(opening_template)) {
      return (opening_template.day_idxs.includes(day_idx) && opening_template.day_idxs.length == 1)
    } else {
      return false
    }
  }

  removeOpeningTimeTemplate(opening_template: OpeningTimeTemplate, event: MouseEvent): void {
    event.stopPropagation()
    PAUtil.removeElementFromList(this.openingTimeTemplates, opening_template)
  }
}