import { Component, Input } from '@angular/core';
import { faIndustry, faShoppingCart, faUser } from "@fortawesome/free-solid-svg-icons";
import { FaIconComponent } from "@fortawesome/angular-fontawesome";
import { NgForOf, NgIf } from "@angular/common";

type AdminRoot = 'user' | 'customer' | 'store' //  add more if needed

@Component({
  selector: 'hawk-record-navigation',
  templateUrl: './record-navigation.component.html',
  standalone: true,
  imports: [
    FaIconComponent,
    NgIf,
    NgForOf
  ],
  styleUrls: ['./record-navigation.component.scss', '../styles/common-styles.scss']
})
export class RecordNavigationComponent {

  @Input() breadcrumbLinkList: { link: string, description: string }[] = []
  @Input() leafDescription: string = ''
  @Input() root: AdminRoot = 'user'

  icons = {
    user: faUser,
    customer: faIndustry,
    store: faShoppingCart
  }

}
