<hawk-record-navigation class="common_style" [breadcrumbLinkList]="[{description: 'Filialen', link: '/a/filiale'}]" [root]="'store'" [leafDescription]="'Neue Filiale'">
  <form [formGroup]="storeForm" class="normal-form" (submit)="onSubmit()">
    <div style="width: 100%;" class="responsive_split">
      <div class="flex_col">
        <section>
          <h2>Stammdaten</h2>
          <div class="input_list label_20">
            <div class="input_row">
              <div class="input_label">
                Store-Nr*
              </div>
              <div style="flex: 1;" class="flex_col">
                <div class="input_container">
                  <mat-form-field class="outline-small no-subscript" appearance="outline">
                    <input matInput formControlName="nr">
                  </mat-form-field>
                </div>
                <div class="input_description">
                  Die Store-Nr darf nicht mehrfach verwendet werden.
                </div>
              </div>
            </div>
            <div class="input_row">
              <div class="input_label">
                Name*
              </div>
              <div class="input_container">
                <mat-form-field class="outline-small no-subscript" appearance="outline">
                  <input matInput formControlName="name">
                </mat-form-field>
              </div>
            </div>
            <div class="input_row">
              <div class="input_label">
                Kunden
              </div>
              <div style="flex: 1;" class="flex_col">
                <div class="input_container">
                  <mat-form-field class="outline-small no-subscript" appearance="outline">
                    <mat-select formControlName="customer_ids" multiple>
                      <mat-option *ngFor="let customer of customers" [value]="customer.id">{{customer.name}}</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
            </div>
            <div class="input_row">
              <div class="input_label">
                E-Mail
              </div>
              <div class="input_container">
                <div class="icon_wrapper">
                  <div class="icon_container">
                    <fa-icon [icon]="faMailBulk"></fa-icon>
                  </div>
                  <mat-form-field class="outline-small no-subscript" appearance="outline">
                    <input matInput formControlName="email">
                  </mat-form-field>
                </div>
              </div>
            </div>
            <div class="input_row">
              <div class="input_label">
                Telefon 1
              </div>
              <div class="input_container">
                <div class="icon_wrapper">
                  <div class="icon_container">
                    <fa-icon [icon]="faPhone"></fa-icon>
                  </div>
                  <mat-form-field class="outline-small no-subscript" appearance="outline">
                    <input matInput formControlName="phone1">
                  </mat-form-field>
                </div>
              </div>
            </div>
            <div class="input_row">
              <div class="input_label">
                Telefon 2
              </div>
              <div class="input_container">
                <div class="icon_wrapper">
                  <div class="icon_container">
                    <fa-icon [icon]="faPhone"></fa-icon>
                  </div>
                  <mat-form-field class="outline-small no-subscript" appearance="outline">
                    <input matInput formControlName="phone2">
                  </mat-form-field>
                </div>
              </div>
            </div>
            <div class="input_row">
              <div class="input_label">
                Kommentar
              </div>
              <div class="input_container">
                <mat-form-field class="outline-small no-subscript" appearance="outline">
                  <input matInput formControlName="comment">
                </mat-form-field>
              </div>
            </div>
          </div>
        </section>
        <section class="flex_col">
          <h2 style="margin-bottom: 0!important">Ausstattung</h2>
          <div class="input_list label_20">
            <div class="input_row">
              <div class="input_label">
                Anzahl Kassen
              </div>
              <div style="flex: 1;" class="flex_col">
                <div class="input_container">
                  <mat-form-field class="outline-small no-subscript" appearance="outline">
                    <input matInput type="number" min="0" formControlName="register">
                  </mat-form-field>
                </div>
              </div>
            </div>
            <div class="input_row">
              <div class="input_label">
                IP
              </div>
              <div style="flex: 1;" class="flex_col">
                <div class="input_container">
                  <mat-form-field class="outline-small no-subscript" appearance="outline">
                    <input matInput formControlName="ip">
                  </mat-form-field>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <div class="flex_col">
        <section class="flex_col">
          <h2 style="margin-bottom: 0!important">Adresse</h2>
          <div style="margin: 8px 8px 16px auto;" id="address_geocoder"></div>
          <div class="input_list label_15">
            <div class="input_row">
              <div class="input_label">
                Firma
              </div>
              <div class="input_container">
                <mat-form-field class="outline-small no-subscript" appearance="outline">
                  <input matInput formControlName="company">
                </mat-form-field>
              </div>
            </div>
            <div class="input_row">
              <div class="input_label">
                Name
              </div>
              <div class="input_container">
                <mat-form-field class="outline-small no-subscript" appearance="outline" style="margin-right: 12px;">
                  <input matInput formControlName="address_lastname" placeholder="Vorname">
                </mat-form-field>
                <mat-form-field class="outline-small no-subscript" appearance="outline">
                  <input matInput formControlName="address_lastname" placeholder="Nachname">
                </mat-form-field>
              </div>
            </div>
            <div class="input_row">
              <div class="input_label">
                Straße
              </div>
              <div class="input_container">
                <mat-form-field class="outline-small no-subscript" appearance="outline">
                  <input matInput formControlName="street">
                </mat-form-field>
              </div>
            </div>
            <div class="input_row">
              <div class="input_label">
                Ort
              </div>
              <div class="input_container">
                <mat-form-field class="outline-small no-subscript" appearance="outline" style="max-width: 33%; margin-right: 12px;">
                  <input matInput formControlName="zip" placeholder="PLZ">
                </mat-form-field>
                <mat-form-field class="outline-small no-subscript" appearance="outline">
                  <input matInput formControlName="city">
                </mat-form-field>
              </div>
            </div>
            <div class="input_row">
              <div class="input_label">
                Land
              </div>
              <div class="input_container">
                <mat-form-field class="outline-small no-subscript" appearance="outline">
                  <input matInput formControlName="country">
                </mat-form-field>
              </div>
            </div>
            <div class="input_row">
              <div class="input_label">
                Staat
              </div>
              <div class="input_container">
                <mat-form-field class="outline-small no-subscript" appearance="outline">
                  <input matInput formControlName="state">
                </mat-form-field>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
    <div style="flex-wrap: wrap;" class="flex_row footer_row">
      <button mat-raised-button color="primary" class="primary_bg_color_button" [disabled]="!storeForm.valid">
        Filiale erstellen
      </button>
      <a [routerLink]="'/filiale/'">
        <button type="button" mat-button>
          Abbrechen
        </button>
      </a>
    </div>
  </form>
</hawk-record-navigation>
