<div id="tour_planner_container" [ngClass]="externalWindow?.isPoppedOut ? 'external_window' : ''">
  
  <div style="width: 100%; height: 100%; display: inline-flex; flex-direction: column;" class="planning_container" [ngClass]="externalWindow?.isPoppedOut ? 'dark_background' : ''" >
    <div style="width: 100%; height: 100%; overflow-y: auto; display: inline-flex; flex-direction: column;">

      <div style="color: black; background-color: white; display: inline-flex; padding: 4px; border: 1px solid black;">
        <mat-form-field style="margin-top: auto; margin-bottom: auto;" class="no_bottom_padding outline-small no-subscript" appearance="outline">
          <mat-label>Planen</mat-label>
          <select matNativeControl name="" id="plan_instance_select" (change)="updatePlanningMode($event)">
            <option [selected]="planningMode == 'operation'" value="operation">Aufträge</option>
            <option [selected]="planningMode == 'technician'" value="technician">Techniker</option>
          </select>
        </mat-form-field>
        <div style="margin-left: 4px; margin-top: auto; margin-bottom: auto; padding: 1px;">
          <mat-button-toggle-group style="height: 34px;" [(ngModel)]="PASettingsControl.Instance.projectMode" [value]="PASettingsControl.Instance.projectMode">
            <mat-button-toggle value="free" (change)="update()">Freie Planung</mat-button-toggle>
            <mat-button-toggle value="service" (change)="update()">Service Planung</mat-button-toggle>
          </mat-button-toggle-group>
        </div>
        <button class="primary_bg_color_button" style="margin: auto 0 auto auto;" mat-raised-button color="primary" (click)="deselect()">reset</button>
      </div>

      <div *ngIf="planningMode == 'operation'">
        <hawk-expand-bar-header style="width: 100%; margin-top: 4px;"
          [description]="(selectedOperationID ? PADataControl.Instance.getOperation(selectedOperationID).ticket.infoString() : 'Auftrags-Auswahl' )"
          [isExpanded]="showInstanceSelection"
          (toggle)="showInstanceSelection = $event">
        </hawk-expand-bar-header>

        <div class="toggle_body" [ngClass]="showInstanceSelection ? '' : 'hidden'">
          <div style="border: 1px solid black; position: relative;">
            <input class="search" type="search" placeholder="suche Auftrag..." [(ngModel)]="instanceSearch" (search)="update()" (keyup)="update()">
            <table class="mat-elevation-z8" mat-table [dataSource]="operationDataSource" matSort matSortActive="service" matSortDirection="asc" (matSortChange)="announceSortChange($event)" style="width: 100%;">
          
              <ng-container matColumnDef="id">
                <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by id"> ID. </th>
                <td mat-cell *matCellDef="let element"> {{element.ticket.id > 0 ? element.ticket.id : 'NEU'}} </td>
              </ng-container>
          
              <ng-container matColumnDef="address_company">
                <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by address_company"> Name </th>
                <td mat-cell *matCellDef="let element"> {{element.ticket.address_company}} </td>
              </ng-container>
          
              <ng-container matColumnDef="project_name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by project_name"> Projekt </th>
                <td mat-cell *matCellDef="let element"> {{element.ticket.project.project_name}} </td>
              </ng-container>
          
              <ng-container matColumnDef="city">
                <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by city"> Stadt </th>
                <td mat-cell *matCellDef="let element"> {{element.ticket.address_city}} </td>
              </ng-container>

              <ng-container matColumnDef="service">
                <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by service"> Service </th>
                <td mat-cell *matCellDef="let element"> {{element.ticket.isServiceTicket() ? 'Ja' : 'Nein'}} </td>
              </ng-container>
          
              <tr mat-header-row *matHeaderRowDef="displayedOperationColumns"></tr>
              <tr mat-row 
              (click)="changeSelectedOperation(row.id, true)" 
              [class.demo-row-is-clicked]="" 
              [ngClass]="selectedOperationID == row.id ? 'selected' : ''"
              *matRowDef="let row; columns: displayedOperationColumns;"></tr>
              <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" [attr.colspan]="displayedOperationColumns.length" style="text-align: center;">
                  No data matching the filter.
                </td>
              </tr>
            </table>
          
            <mat-paginator [pageSizeOptions]="[5]"
                            showFirstLastButtons
                            aria-label="Select page">
            </mat-paginator>
          </div>
        </div>

        <ng-container *ngIf="selectedOperationID">
          <div id="selected_instance_container">
            <div id="ticket_description_container" style="width: 100%;">
              <hawk-expand-bar-header style="width: 100%;"
              [description]="'Auftrags-Daten'"
              [isExpanded]="showData"
              (toggle)="showData = $event">
              </hawk-expand-bar-header>
              <div class="toggle_body" [ngClass]="showData ? '' : 'hidden'">
                <table class="ticket_info" style="width: 100%; background-color: white; color: black">
                  <tbody>
                    <tr>
                      <td style="width: 100px;">Info:</td>
                      <td>
                        <div style="max-height: 80px; overflow: auto;">
                          {{PADataControl.Instance.getOperation(selectedOperationID).description}}
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>Adresse:</td>
                      <td>
                        {{PADataControl.Instance.getOperation(selectedOperationID).address()}}
                      </td>
                    </tr>
                    <tr>
                      <td>Prio/Status:</td>
                      <td>
                        {{PADataControl.Instance.getOperation(selectedOperationID).ticket.priority?.name}} / {{PADataControl.Instance.getOperation(selectedOperationID).ticket.status?.name}}
                      </td>
                    </tr>
                    <tr>
                      <td>Dauer:</td>
                      <td>{{PADataControl.Instance.getOperation(selectedOperationID).ticket.time_estimation}}min</td>
                    </tr>
                    <tr *ngIf="PADataControl.Instance.getOperation(selectedOperationID).ticket.appointment_date">
                      <td><fa-icon style="margin: auto; padding: 0 4px; font-size: .75rem;" [icon]="faPhone"></fa-icon></td>
                      <td>{{PADataControl.Instance.getOperation(selectedOperationID).ticket.appointmentDateInformation()}}</td>
                    </tr>
                    <tr *ngIf="PADataControl.Instance.getOperation(selectedOperationID).ticket.datesla">
                      <td>SLA-Datum:</td>
                      <td>{{PADataControl.Instance.getOperation(selectedOperationID).ticket.slaDateInformation()}}</td>
                    </tr>
                    <tr>
                      <td>Material:</td>
                      <td>
                        <hawk-material-selection
                        [materialContainer]="PADataControl.Instance.getOperation(selectedOperationID).ticket"
                        [forProjectID]="PADataControl.Instance.getOperation(selectedOperationID).ticket.project.id"
                        (updatedEvent)="update()">
                        </hawk-material-selection>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="filter_container" style="width: 100%;">
              <hawk-expand-bar-header style="width: 100%;"
              [description]="'Techniker-Filter (' + filteredTechnicians.length.toString() + '/' + technicians.length.toString() + ')'"
              [isExpanded]="showInstanceFilter"
              (toggle)="showInstanceFilter = $event">
              </hawk-expand-bar-header>
              <div class="toggle_body instance_list" [ngClass]="showInstanceFilter ? '' : 'hidden'">
                <div style="display: inline-flex; flex-direction: row; flex-wrap: wrap; padding: 4px 8px; padding-top: 1.25em; background-color: white; color: black; border: 1px solid black; width: 100%;">
                  <mat-form-field style="margin: auto; padding: 2px 4px; width: 25%!important;">
                    <mat-label>Zugehörigkeit</mat-label>
                    <select matNativeControl name="technician_company_filter" id="technician_company_filter" (change)="updateTechnicianOwnerFilter($event)">
                      <option [selected]="selectedTechnicianOwnerFilter == 'bentomax'" value="bentomax">Bentomax Techniker</option>
                      <option [selected]="selectedTechnicianOwnerFilter == 'external'" value="external">Externe Techniker</option>
                      <option [selected]="selectedTechnicianOwnerFilter == 'all'" value="all">Alle Techniker</option>
                    </select>
                  </mat-form-field>
                  <mat-form-field style="margin: auto; padding: 2px 4px; width: 25%!important;">
                    <mat-label>Projekte</mat-label>
                    <select matNativeControl name="technician_project_filter" id="technician_project_filter" (change)="updateTechnicianProjectFilter($event)">
                      <option [selected]="selectedTechnicianProjectFilter == 'ticket_project'" value="ticket_project">Techniker aus Auftrags-Verbund-Projekten </option>
                      <option *ngIf="getSelectedProjects().length" [selected]="selectedTechnicianProjectFilter == 'selected_project'" value="selected_project">Techniker aus ausgewählten Projekten ({{getSelectedProjects().length}})</option>
                      <option [selected]="selectedTechnicianProjectFilter == 'all'" value="all">Alle Techniker</option>
                    </select>
                  </mat-form-field>
                  <mat-form-field style="margin: auto; padding: 2px 4px; width: 25%!important;">
                    <mat-label>Material</mat-label>
                    <select matNativeControl name="technician_material_filter" id="technician_material_filter" (change)="updateMaterialFilter($event)">
                      <option [selected]="selectedMaterialFilter == 'true'" value="true">Hat Auftrags-Material</option>
                      <option [selected]="selectedMaterialFilter == 'off'" value="off">Aus</option>
                    </select> 
                  </mat-form-field>
                  <mat-form-field style="margin: auto; padding: 2px 4px; width: 25%!important;">
                    <mat-label>Max. Distanz (km):</mat-label>
                    <input matInput type="number" min="0" (change)="updateFilteredTechnicians()" [(ngModel)]="PATourPlannerControl.Instance.selectedDistanceFilter">
                  </mat-form-field>
                </div>
                <div *ngIf="!shownTechnicians.length" style="width:100%; text-align: center; font-size: 1rem;">
                  Kein Techniker erfüllt die Filtereigenschaften. Bitte passen Sie die Filter an!
                </div>
                <table *ngIf="shownTechnicians.length" style="width: 100%; border: 1px solid black;" class="black_gradient_background_no_hover">
                  <thead>
                    <tr>
                      <th></th>
                      <th style="cursor: pointer;" (click)="toggleTechnicianDistanceType()">
                        <div style="padding: 2px 4px; text-align: center;" class="primary round_border">
                          <ng-container *ngIf="PATourPlannerControl.Instance.technicianDistanceType == 'air_distance'">
                            Luftlinie
                          </ng-container>
                          <ng-container *ngIf="PATourPlannerControl.Instance.technicianDistanceType == 'street_distance'">
                            Fahrt
                          </ng-container>
                        </div>
                      </th>
                      <th>Techniker</th>
                      <th>Erfahrung (#Aufträge)</th>
                      <th>Durchschnittszeit</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let technician of shownTechnicians" (click)="clickTechnicianWithOperationDeadline(technician.id, selectedOperationID)" [ngClass]="showTechnicianDatesForTechnicians.includes(technician) ? 'selected' : ''">
                      <td>
                        <div style="height: 16px; width: 16px; border-radius: 50%; border: 1px solid black;" [style.background-color]="technician.getColorString()">
      
                        </div>
                      </td>
                      <td>
                        <ng-container *ngIf="PATourPlannerControl.Instance.technicianDistanceType == 'air_distance'">
                          {{technician.location.distanceToLocationAsTheCrowFliesString(PADataControl.Instance.getOperation(this.selectedOperationID).ticket.client.location)}}
                        </ng-container>
                        <ng-container *ngIf="PATourPlannerControl.Instance.technicianDistanceType == 'street_distance'">
                          {{technician.location.getSyncStreetDistanceToLocation(PADataControl.Instance.getOperation(this.selectedOperationID).ticket.client.location)}}km
                        </ng-container>
                      </td>
                      <td>
                        {{technician.getFullName(20)}}  
                      </td>
                      <td>
                        <ng-container *ngIf="PADataControl.Instance.getOperation(selectedOperationID) && technician.operationCounts">
                          Projekt: {{technician.operationCounts.project.get(PADataControl.Instance.getOperation(selectedOperationID).ticket.project.id)}},
                          Priorität: {{technician.operationCounts.priority.get(PADataControl.Instance.getOperation(selectedOperationID).ticket.priority.id)}}
                        </ng-container>
                      </td>
                      <td>
                        <ng-container *ngIf="technician.getPriorityBasedAverageOperationTime(PADataControl.Instance.getOperation(selectedOperationID).ticket.priority.id) >= 0; else noAverageTime ">
                          {{technician.getPriorityBasedAverageOperationTime(PADataControl.Instance.getOperation(selectedOperationID).ticket.priority.id)}}min
                        </ng-container>
                        <ng-template #noAverageTime>
                          <ng-container *ngIf="technician.getPriorityBasedAverageOperationTime(PADataControl.Instance.getOperation(selectedOperationID).ticket.priority.id) == -1 ; else averageTimeError">
                            keine Daten
                          </ng-container>
                          <ng-template #averageTimeError>
                            Daten inkonsistent!
                          </ng-template>
                        </ng-template>
                      </td>
                    </tr>
                    <tr *ngIf="filteredTechnicians.length > shownTechnicians.length" (click)="increaseMaxShownTechniciansAmount()">
                      <td colspan="5" style="text-align: center; padding: 2px 4px;">
                        <div style="width: 100%;">
                          Mehr Techniker anzeigen (noch {{filteredTechnicians.length - shownTechnicians.length}} weitere)
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <hawk-expand-bar-header style="width: 100%;"
          [description]="'Auftrags-Verbund'"
          [isExpanded]="showExtraOperationCollection"
          (toggle)="showExtraOperationCollection = $event">
          </hawk-expand-bar-header>
          <div class="toggle_body" style="background-color: #292929;" [ngClass]="showExtraOperationCollection ? '' : 'hidden'">
            <hawk-operation-collection
            [operations]="operationCollectionOperations"
            [technicians]="showTechnicianDatesForTechnicians"
            [planningInstructions]="planningInstructions"
            [shownCalendarWeeksData]="shownCalendarWeeksData"
            [selectedCalendarWeekData]="selectedCalendarWeekData"
            [selectedCalendarDay]="selectedCalendarDay"
            [filteredWeekdays]="filteredWeekdays"
            [operationTimeFilter]="operationTimeFilter"
            [showOverviewForTechnicianDateCollections]="showOverviewForTechnicianDateCollections"
            [externalWindow]="externalWindow"
            [scrollToStore]="Store.selectedStoreInMap"
            (deselectOperationEvent)="deselectExtraOperation($event)"
            (deselectTechnicianEvent)="clickTechnicianWithOperationDeadline($event.id, this.selectedOperationID)"
            (nextCWEvent)="clickNextWeek()"
            (prevCWEvent)="clickPreviousWeek()"
            (clickTechnicianDateEvent)="clickShownTechnicianDates($event)"></hawk-operation-collection>
          </div>
          <ng-container *ngIf="PASettingsControl.Instance.selectedSettingConfig.data.tour_planning.operation_planning.show_close_technician_tours">
            <hawk-expand-bar-header style="width: 100%;"
                                    [description]="'Techniker in der Nähe (' + PASettingsControl.Instance.selectedSettingConfig.data.tour_planning.operation_planning.show_close_technician_tours_distance + 'km)'"
                                    [isExpanded]="showExtraTechnicianTours"
                                    (toggle)="showExtraTechnicianTours = $event">
            </hawk-expand-bar-header>
            <div class="toggle_body" style="background-color: #292929;" [ngClass]="showExtraTechnicianTours ? '' : 'hidden'">
              <hawk-close-tours style="display: inline-flex; flex-direction: column; width: 100%;"
                                [referenceOperation]="PADataControl.Instance.getOperation(selectedOperationID)"
                                [selectedCalendarWeekData]="selectedCalendarWeekData" [shownCalendarWeeksData]="shownCalendarWeeksData"
                                [kmRadius]="PASettingsControl.Instance.selectedSettingConfig.data.tour_planning.operation_planning.show_close_technician_tours_distance" [showOverviewForTechnicianDateCollections]="showOverviewForTechnicianDateCollections"
                                [lastOperationChangeTimeStamp]="PATourPlannerControl.Instance.lastOperationChangeTimeStamp" [lastMainRouteLocationChangeTimestamp]="PATourPlannerControl.Instance.lastMainRouteLocationChangeTimestamp"
                                (clickTechnicianDate)="clickTechnicianWithOperationDeadline($event.technician.id, selectedOperationID, true); clickShownTechnicianDates({technician_id: $event.technician.id, technician_dates: [$event]})">
              </hawk-close-tours>
            </div>
          </ng-container>
        </ng-container>
        <ng-container *ngIf="!selectedOperationID">
          <br>
          Bitte wählen Sie einen Auftrag aus!
        </ng-container>
      </div>
    
      <div *ngIf="planningMode == 'technician'" style="flex:1; display: inline-flex; flex-direction: column;">
        <hawk-expand-bar-header style="width: 100%;"
          [description]="(selectedTechnicianID ? 'Techniker: ' + PADataControl.Instance.getTechnician(selectedTechnicianID).getFullName(20) : 'Techniker-Auswahl' )"
          [isExpanded]="showTechnicianSelection"
          (toggle)="showTechnicianSelection = $event">
        </hawk-expand-bar-header>

        <div class="toggle_body" [ngClass]="showTechnicianSelection ? '' : 'hidden'">
          <div style="border: 1px solid black; position: relative;">
            <input class="search" type="search" placeholder="suche Techniker..." [(ngModel)]="instanceSearch" (search)="update()" (keyup)="update()">
            <table class="mat-elevation-z8" mat-table [dataSource]="technicianDataSource" matSort (matSortChange)="announceSortChange($event)" style="width: 100%;">
          
              <ng-container matColumnDef="full_name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by full_name"> Name </th>
                <td mat-cell *matCellDef="let element"> {{element.getFullName(20)}} </td>
              </ng-container>
          
              <ng-container matColumnDef="company">
                <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by company"> Zugehörigkeit </th>
                <td mat-cell *matCellDef="let element"> {{element.isBentomaxTechnician() ? 'Bentomax' : 'Extern'}} </td>
              </ng-container>

              <ng-container matColumnDef="workload">
                <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by workload"> Auslastung </th>
                <td mat-cell *matCellDef="let element">
                  <ng-container *ngIf="!this.selectedCalendarWeekData && this.shownCalendarWeeksData && this.shownCalendarWeeksData.length">
                    <div [ngClass]="(25 > element.calendarWeekWorkloadInPercent(this.shownCalendarWeeksData[0].calendar_week) ? 'green' : element.calendarWeekWorkloadInPercent(this.shownCalendarWeeksData[0].calendar_week) > 75 ? 'red' : 'yellow')">
                      {{element.calendarWeekWorkloadInPercent(this.shownCalendarWeeksData[0].calendar_week)}}%
                    </div>
                  </ng-container>
                  <ng-container *ngIf="this.selectedCalendarWeekData">
                    <div [ngClass]="(25 > element.calendarWeekWorkloadInPercent(this.selectedCalendarWeekData.calendar_week) ? 'green' : element.calendarWeekWorkloadInPercent(this.selectedCalendarWeekData.calendar_week) > 75 ? 'red' : 'yellow')">
                      {{element.calendarWeekWorkloadInPercent(this.selectedCalendarWeekData.calendar_week)}}%
                    </div>
                  </ng-container>
                </td>
              </ng-container>
          
              <tr mat-header-row *matHeaderRowDef="displayedTechnicianColumns"></tr>
              <tr mat-row 
              (click)="changeSelectedTechnician(row.id, true)" 
              [class.demo-row-is-clicked]="" 
              [ngClass]="selectedTechnicianID == row.id ? 'selected' : ''"
              *matRowDef="let row; columns: displayedTechnicianColumns;"></tr>
              <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" [attr.colspan]="displayedTechnicianColumns.length" style="text-align: center;">
                  No data matching the filter.
                </td>
              </tr>
            </table>
            <mat-paginator [pageSizeOptions]="[5]"
                           showFirstLastButtons
                           (page)="onPageChange($event)"
                           aria-label="Select page">
            </mat-paginator>
          </div>
        </div>

        <div *ngIf="selectedTechnicianID" style="display: inline-flex; flex-direction: column; flex: 1;">
          <div class="technician_days_wrapper" [ngClass]="externalWindow?.isPoppedOut ? 'popped_out' : 'popped_in'">
            <div class="technician_days" [ngClass]="externalWindow?.isPoppedOut ? 'popped_out' : 'popped_in'">
              <hawk-expand-bar-header style="width: 100%;"
              [description]="'Auftrags-Verbund '+ '(KW ' + getShownCalendarWeekData().calendar_week.number + ')'"
              [isExpanded]="showExtraOperationCollection"
              (toggle)="showExtraOperationCollection = $event">
              </hawk-expand-bar-header>
              <div class="toggle_body" style="background-color: #292929;" [ngClass]="showExtraOperationCollection ? '' : 'hidden'">
                <hawk-operation-collection
                [operations]="extraOperationCollection"
                [technicians]="[PADataControl.Instance.getTechnician(selectedTechnicianID)]"
                [planningInstructions]="planningInstructions"
                [shownCalendarWeeksData]="shownCalendarWeeksData"
                [selectedCalendarWeekData]="selectedCalendarWeekData"
                [selectedCalendarDay]="selectedCalendarDay"
                [filteredWeekdays]="filteredWeekdays"
                [operationTimeFilter]="operationTimeFilter"
                [showOverviewForTechnicianDateCollections]="showOverviewForTechnicianDateCollections"
                [externalWindow]="externalWindow"
                [scrollToStore]="Store.selectedStoreInMap"
                (deselectOperationEvent)="deselectExtraOperation($event)"
                (nextCWEvent)="clickNextWeek()"
                (prevCWEvent)="clickPreviousWeek()"
                (clickTechnicianDateEvent)="clickShownTechnicianDates($event)"></hawk-operation-collection>
              </div>
            </div>
          </div>
          
          <div class="filter_container">
            <hawk-expand-bar-header style="width: 100%;"
            [description]="'Filter'"
            [isExpanded]="showTechnicianFilter"
            (toggle)="showTechnicianFilter = $event">
            </hawk-expand-bar-header>
            <div class="toggle_body" [ngClass]="showTechnicianFilter ? '' : 'hidden'" style="background-color: white; color: black;">
              <div style="display: inline-flex; flex-direction: row; flex-wrap: wrap; padding: 1.25em 8px 4px;width: 100%;">
                <mat-form-field style="margin: auto; padding: 2px 4px;">
                  <mat-label>Priorität beschränken</mat-label>
                  <mat-select *ngIf="!externalWindow.isPoppedOut" [(ngModel)]="selectedPriorityIds" (ngModelChange)="updateFilteredOperationsAndTickets()" multiple>
                    <mat-optgroup *ngFor="let project of PADataControl.Instance.getTechnician(selectedTechnicianID).getLoadedActiveProjects(true)" [label]="project.project_name">
                      <mat-option *ngFor="let priority of project.priorities" [value]="priority.id">{{priority?.name}}</mat-option>
                    </mat-optgroup>
                  </mat-select>
                  <mat-select *ngIf="externalWindow.isPoppedOut" [(ngModel)]="selectedPriorityIds" (ngModelChange)="updateFilteredOperationsAndTickets()" multiple>
                    <mat-optgroup  *ngFor="let project of PADataControl.Instance.getTechnician(selectedTechnicianID).getLoadedActiveProjects(true)" [label]="project.project_name">
                      <mat-option *ngFor="let priority of project.priorities" [value]="priority.id">{{priority?.name}}</mat-option>
                    </mat-optgroup>
                  </mat-select>
                </mat-form-field>
                <mat-form-field style="margin: auto; padding: 2px 4px;">
                  <mat-label>Status beschränken</mat-label>
                  <mat-select *ngIf="!externalWindow.isPoppedOut" [(ngModel)]="selectedStatusIds" (ngModelChange)="updateFilteredOperationsAndTickets()" multiple>
                    <mat-optgroup  *ngFor="let project of PADataControl.Instance.getTechnician(selectedTechnicianID).getLoadedActiveProjects(true)" [label]="project.project_name">
                      <mat-option *ngFor="let status of project.status" [value]="status.id">{{status?.name}}</mat-option>
                    </mat-optgroup>
                  </mat-select>
                  <mat-select *ngIf="externalWindow.isPoppedOut" [(ngModel)]="selectedStatusIds" (ngModelChange)="updateFilteredOperationsAndTickets()" multiple>
                    <mat-optgroup *ngFor="let project of PADataControl.Instance.getTechnician(selectedTechnicianID).getLoadedActiveProjects(true)" [label]="project.project_name">
                      <mat-option *ngFor="let status of project.status" [value]="status.id">{{status?.name}}</mat-option>
                    </mat-optgroup>
                  </mat-select>
                </mat-form-field>
                <mat-form-field style="margin: auto; padding: 2px 4px;">
                  <mat-label>"Nahe Aufträge" Distanz (km):</mat-label>
                  <input matInput type="number" min="0" (change)="updateFilteredOperationsAndTickets()" [(ngModel)]="PATourPlannerControl.Instance.selectedDistanceFilter">
                </mat-form-field>
                <mat-form-field style="margin: auto; padding: 2px 4px;">
                  <mat-label>Material</mat-label>
                  <select matNativeControl name="instance_material_filter" id="instance_material_filter" (change)="updateInstanceMaterialFilter($event)">
                    <option [selected]="selectedInstanceMaterialFilter == 'true'" value="true">Material vorhanden</option>
                    <option [selected]="selectedInstanceMaterialFilter == 'off'" value="off">Aus</option>
                  </select> 
                </mat-form-field>
                <mat-form-field style="margin: auto; padding: 2px 4px;">
                  <mat-label>Deadline</mat-label>
                  <select matNativeControl (change)="updateDeadlineTypeSelection($event)">
                    <option [selected]="selectedDeadlineTypeSelection == 'sla_or_appointment'" value="sla_or_appointment">SLA oder Termin</option>
                    <option [selected]="selectedDeadlineTypeSelection == 'sla'" value="sla">SLA</option>
                    <option [selected]="selectedDeadlineTypeSelection == 'appointment'" value="appointment">Termin</option>
                    <option [selected]="selectedDeadlineTypeSelection == 'off'" value="off">Aus</option>
                  </select>
                </mat-form-field>
                <mat-form-field *ngIf="selectedDeadlineTypeSelection != 'off'" style="margin: auto; padding: 2px 4px;">
                  <mat-label>bis zum:</mat-label>
                  <input matInput type="datetime-local" [(ngModel)]="selectedDeadlineDateSelection" (change)="updateFilteredOperationsAndTickets()">
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>
        <ng-container *ngIf="!selectedTechnicianID">
          <br>
          Bitte wählen Sie einen Techniker aus!
        </ng-container>
      </div>
    </div>
  </div>
</div>