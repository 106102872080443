import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'

import { environment } from '../../environments/environment'
import { Priority, ProjectMetadata, Status, Store, Ticket } from '../_models'
import { PAActiveProject, Project, ProjectWithPrioStatus } from '../_models/project.interface'

@Injectable({
  providedIn: 'root'
})
export class ProjectService {

  constructor(private http: HttpClient) { }

  public getAll(params: any = {}) {
    const path = environment.apiURL + 'projects.json'

    return this.http.get<Project[]>(path, { params })
  }

  public getProject(id: number) {
    const path = environment.apiURL + 'projects/' + id + '.json'

    return this.http.get<Project>(path)
  }

  public getActiveWithPrioStatus() {
    const path = environment.apiURL + 'projects/active_with_prio_status.json'

    return this.http.get<ProjectWithPrioStatus[]>(path)
  }

  public getMetadata(id: string) {
    const path = environment.apiURL + 'projects/' + id + '/metadata.json'

    return this.http.get<ProjectMetadata>(path)
  }

  public updateProject(id: string, params: { id?: string, logo?: string, pdf_show_footer?: boolean, additional_documentation?: string, color?: string }) {
    const path = environment.apiURL + 'projects/update_metadata.json'

    params.id = id

    console.log("updateProject", params)

    return this.http.post<ProjectMetadata>(path, params)
  }

  public getPAActiveProjects() {
    const path = environment.apiURL + 'projects/planning_assistant/active.json'

    return this.http.get<PAActiveProject[]>(path)
  }

  public getPAActiveProject(id: number) {
    const path = environment.apiURL + `project/planning_assistant/${id}/active`

    return this.http.get<PAActiveProject>(path)
  }

  public getStatuses(id: number | string | String) {
    const path = environment.apiURL + `projects/${id}/statuses`

    return this.http.get<Status[]>(path)
  }

  public getPriorities(id: number | string | String) {
    const path = environment.apiURL + `projects/${id}/priorities`

    return this.http.get<Priority[]>(path)
  }

  public getStores(id: number | string | String) {
    const path = environment.apiURL + `projects/${id}/stores`

    return this.http.get<Store[]>(path)
  }

  public getTicketFields(id: number | string | String) {
    const path = environment.apiURL + `projects/${id}/ticket_fields`

    return this.http.get<any[]>(path)
  }

  public getProjectTicketStatuses(project_id: number) {
    const path = environment.apiURL + 'projects/' + project_id + '/ticket_statuses.json'

    return this.http.get<{
      ticket: Ticket
      status_id: number,
      status_name: string,
      status_color: string
    }[]>(path)
  }
}
