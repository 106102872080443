import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'

import { environment } from '../../environments/environment'
import { Customer } from '../_models'
import { APCustomer, APCustomerProject, CustomerLetter, PermittedCustomerParams } from '../_models/customer.interface'
import { APStore, StoreBulkImportParams } from "../_models/store.interface";

@Injectable({
  providedIn: 'root'
})
export class CustomerService {

  constructor(
      private http: HttpClient,
    ) {
  }

  public loadCustomers(params: any = {}) {
    const path = environment.apiURL + 'customers.json'
    return this.http.get<Customer[]>(path, { params: params })
  }

  // Load customers in admin page hash format
  public loadAPCustomers() {
    const path = environment.apiURL + 'customers.json'
    return this.http.get<APCustomer[]>(path, { params: { hash_type: 'admin_page_hash'} })
  }

  public loadAPCustomer(id: string) {
    const path = environment.apiURL + `customers/${id}`
    return this.http.get<APCustomer>(path, { params: { hash_type: 'admin_page_hash'} })
  }

  public loadAPCustomerStores(id: string | number) {
    const path = environment.apiURL + `customers/${id}/stores`
    return this.http.get<APStore[]>(path)
  }

  public loadAPCustomerProjects(id: string) {
    const path = environment.apiURL + `customers/${id}/projects`
    return this.http.get<APCustomerProject[]>(path)
  }

  public customerIsDeletable(id: string | number) {
    const path = environment.apiURL + `customers/${id}/deletable`
    return this.http.get<{deletable: boolean}>(path)
  }

  public loadCustomerLetters() {
    const path = environment.apiURL + 'customers/letters.json'
    return this.http.get<CustomerLetter[]>(path)
  }

  public createCustomer(customer: PermittedCustomerParams) {
    const path = environment.apiURL + 'customers/create'
    return this.http.post<APCustomer>(path, customer)
  }

  public updateCustomer(id: number | string, customer: PermittedCustomerParams) {
    const path = environment.apiURL + `customers/${id}`
    return this.http.patch<APCustomer>(path, customer)
  }

  public deleteCustomer(id: number | string) {
    const path = environment.apiURL + `customers/${id}`
    return this.http.delete<APCustomer>(path)
  }

  public bulkImportStores(id: number, store_bulk: StoreBulkImportParams[], add_project_relations: number[]=[]) {
    const path = environment.apiURL + `customers/${id}/store_import`
    return this.http.post<APStore[]>(path, { store_bulk, add_project_relations })
  }
}
