
import * as mapboxgl from "mapbox-gl";
import { OpeningTimeTemplate } from "../map/map.component";
import { countryListAlpha3, countryListAlpha3German } from "../data/iso-3-codes";
import { GeocoderEvent } from "../../../_models/mapbox.interface";

export class PAUtil {

  static distinctColors = [
    '#53aae8',
    '#7052f4',
    '#f032e6',
    '#e81416',
    '#ffa500',
    '#55a106',
    '#019579'
  ]

  static compareObjects(object_1: any, object_2: any) {
    return JSON.stringify(object_1) == JSON.stringify(object_2)
  }

  /**
   * Returns an ordered Array of Integers between `start` and `end`.
   *
   * @param start - The first input number
   * @param end - The second input number
   *
   */
  static range(start: number, end: number): number[] {
    start = Math.ceil(start)
    end = Math.floor(end)
    return Array.from({length: end - start + 1}, (_, i) => i + start)
  }

  static cutString(string: string, cut_idx: number): string {
    if (string.length <= cut_idx) {
      return string
    } else {
      return string.substring(0, cut_idx) + "..."
    }
  }

  static getIndexColorRGBA(index: number): { r: number, g: number, b: number, a: number } {
    return this.hexToRgba(this.distinctColors[index % this.distinctColors.length])
  }

  static getIndexColorString(index: number): string{
    return this.rgbaToString(this.getIndexColorRGBA(index))
  }

  static chunkArray<T>(array: T[], chunk_size: number): T[][] {
    return Array.from({length: Math.ceil(array.length / chunk_size)}, (v, i) =>
      array.slice(i * chunk_size, i * chunk_size + chunk_size)
    );
  }

  static permutator<T>(inputArr: T[]): T[][] {
    let result: T[][] = [];

    const permute = (arr, m: T[] = []) => {
      if (arr.length === 0) {
        result.push(m)
      } else {
        for (let i = 0; i < arr.length; i++) {
          let curr = arr.slice();
          let next = curr.splice(i, 1);
          permute(curr.slice(), m.concat(next))
        }
      }
    }

    permute(inputArr)

    return result;
  }

  /**
   * removes a specific element from a specific list
   */
  static removeElementFromList<T>(list: T[], elem: T): void {
    if (list.indexOf(elem) >= 0) {
      list.splice(list.indexOf(elem), 1)
    }
  }

  static sleep(milliseconds: number) {
    return new Promise(resolve => setTimeout(resolve, milliseconds));
  }

  static hexToRgba(hex: string, change_alpha?: number): { r: number, g: number, b: number, a: number } {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})?$/i.exec(hex);
    let alpha = result && result[4] ? parseInt(result[4]) : 1 || 1
    if (typeof change_alpha !== undefined && 0 <= change_alpha && change_alpha <= 1) {
      alpha = change_alpha
    }
    return result ? {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16),
      a: alpha
    } : null;
  }

  static rgbaToString(rgb: { r: number, g: number, b: number, a?: number }): string {
    if (rgb) {
      if (rgb.a) {
        return `rgba(${rgb.r},${rgb.g},${rgb.b},${rgb.a})`
      } else {
        return `rgb(${rgb.r},${rgb.g},${rgb.b})`
      }
    } else {
      return ('rgb(255, 255, 255)')
    }
  }

  static calcDistanceAsTheCrowFlies(lat1: number, lon1: number, lat2: number, lon2: number) {
    const R = 6371e3; // metres
    const φ1 = lat1 * Math.PI / 180; // φ, λ in radians
    const φ2 = lat2 * Math.PI / 180;
    const Δφ = (lat2 - lat1) * Math.PI / 180;
    const Δλ = (lon2 - lon1) * Math.PI / 180;

    const a = Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
      Math.cos(φ1) * Math.cos(φ2) *
      Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    const d = R * c; // in metres
    return d / 1000 // return in kilometres
  }

  static averageTime(times: number[]): number {
    let max_avg_values = 10

    // Case 1: no operation data with given priority for that user in database
    if (times.length == 0) {
      return -1
    }

    // Case 2: data exists
    if (times.length > max_avg_values) {
      times = times.slice(0, max_avg_values)
    }
    let time_sum = times.reduce((a, b) => a + b, 0)
    let avg_time_in_seconds = time_sum / times.length
    return Math.ceil(avg_time_in_seconds / 60)
  }

  static equalSets<T>(xs: Set<T>, ys: Set<T>): boolean {
    return xs.size === ys.size && [...xs].every((x) => ys.has(x));
  }

  static setLocalStorageItem(key: string, value: string): void {
    try {
      localStorage.setItem(key, value)
    } catch (e) {
      console.log('LocalStorage if full. Clearing...')
      localStorage.clear()
      this.setLocalStorageItem(key, value)
    }
  }

  static searchKeyByValue(object, value) {
    return Object.keys(object).find(key => object[key].includes(value));
  }

  static idxListToRangeString(idxs: number[]): string {
    let sorted_idxs = idxs.sort((idx_a: number, idx_b: number) => idx_a < idx_b ? -1 : 1)

    let ranges: number[][] = []

    let current_range_list: number[] = []
    for (let idx of sorted_idxs) {

      let idx_idx = sorted_idxs.indexOf(idx)
      if (idx_idx > 0) {
        let last_idx = sorted_idxs[idx_idx - 1]
        if (last_idx + 1 == idx) {
          current_range_list.push(idx)
        } else {
          current_range_list = []
          current_range_list.push(idx)
          ranges.push(current_range_list)
        }
      } else {
        current_range_list.push(idx)
        ranges.push(current_range_list)
      }

    }

    return ranges.map(range => range.length > 0 ? (range.length == 1 ? `${range[0]}` : `${range[0]}&#x2011;${range[range.length - 1]}`) : '').join(',')
  }

  static getGeocoderEventAddress(event: GeocoderEvent, only_number_zip_code_format?: boolean) {
    let full_context = event.result.context.concat({
      id: event.result.id,
      text: event.result.text,
      mapbox_id: event.result.mapbox_id
    })
    let country_context = full_context.filter(context => context.id.startsWith('country.'))[0]
    let country = ((PAUtil.searchKeyByValue(countryListAlpha3German, country_context?.text)) || PAUtil.searchKeyByValue(countryListAlpha3, country_context?.text) || country_context?.text || '').toLowerCase()
    let region = full_context.filter(context => context.id.startsWith('region.'))[0]?.short_code?.toLowerCase() || ''
    let city = full_context.filter(context => context.id.startsWith('place.'))[0]?.text || ''
    let post_code = (only_number_zip_code_format ? '' : ((country_context['short_code'] || '').toUpperCase() + '-')) + (full_context.filter(context => context.id.startsWith('postcode.'))[0]?.text || '')
    let street = (full_context.filter(context => context.id.startsWith('address.'))[0]?.text) || (event.result.id.includes('poi') ? event.result.properties?.address || '' : '')
    let street_no = event.result.address || ''
    let center = event.result.center
    let name = event.result.text
    return {country, region, city, post_code, street, street_no, center, name};
  }

  static createGeoJSONCircle(center: [number, number], radiusInKm: number, points?: number): mapboxgl.GeoJSONSourceRaw {

    if (!points) points = 64;

    var coords = {
      latitude: center[1],
      longitude: center[0]
    };

    var km = radiusInKm;

    var ret = [];
    var distanceX = km / (111.320 * Math.cos(coords.latitude * Math.PI / 180));
    var distanceY = km / 110.574;

    var theta, x, y;
    for (var i = 0; i < points; i++) {
      theta = (i / points) * (2 * Math.PI);
      x = distanceX * Math.cos(theta);
      y = distanceY * Math.sin(theta);

      ret.push([coords.longitude + x, coords.latitude + y]);
    }
    ret.push(ret[0]);

    let data: GeoJSON.Feature<GeoJSON.Geometry> = {
      geometry: {
        "type": "Polygon",
        "coordinates": [ret]
      },
      type: "Feature",
      properties: {}
    }

    return {
      type: "geojson",
      data: data
    }
  }

  static openingTimeTemplateDayRangeString(opening_template: OpeningTimeTemplate): string {
    let sorted_day_idxs = opening_template.day_idxs.sort()
    let comma_seperated_groups: number[][] = []
    let current_group = []
    for (let day_idx of sorted_day_idxs) {
      current_group.push(day_idx)
      if (!sorted_day_idxs.includes(day_idx + 1)) {
        comma_seperated_groups.push(current_group)
        current_group = []
      }
    }
    let weekdays = ['Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa', 'So']
    return comma_seperated_groups.map(group => group.length > 1 ? `${weekdays[group[0]]} - ${weekdays[group[group.length - 1]]}` : `${weekdays[group[0]]}`).join(', ')
  }

}

export type WeekDay = 'monday' | 'tuesday' | 'wednesday' | 'thursday' | 'friday' | 'saturday' | 'sunday'