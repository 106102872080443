import { Component } from '@angular/core';
import { TechnicianPosition } from "../../classes/technician-live-position";
import { faCar, faCheck, faHome, faWrench } from "@fortawesome/free-solid-svg-icons";
import { PAUtil } from "../../classes/util";
import { TechnicianLivePositionMarker } from "../../classes/mapbox-marker";
import { PATimeControl } from "../../singletons/pa-time-control";
import { NgIf } from '@angular/common';

import { CommonModule } from "@angular/common";
import { FaIconComponent, FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@Component({
  selector: 'hawk-technician-live-position',
  templateUrl: './technician-live-position.component.html',
  styleUrls: ['./technician-live-position.component.scss', './../../styles/common_styles.scss'],
  standalone: true,
  imports: [NgIf, FaIconComponent, CommonModule, FontAwesomeModule]
})
export class TechnicianLivePositionComponent {

  private _technicianLivePosition: TechnicianPosition
  livePositionMarker: TechnicianLivePositionMarker
  protected readonly faCar = faCar;
  protected readonly faHome = faHome;
  protected readonly faWrench = faWrench;
  protected readonly faCheck = faCheck;
  protected readonly Util = PAUtil

  conicBackground: string

  constructor(
  ) {
  }

  get technicianLivePosition() {
    return this._technicianLivePosition
  }

  set technicianLivePosition(tlp: TechnicianPosition) {
    this._technicianLivePosition = tlp
    this.updateConicBackground()
  }

  updateConicBackground(): void {

    let background_string: string
    let gray = 'rgba(120, 120, 120, 1)';

    if (this._technicianLivePosition.action_end) {
      let accent_color = 'rgb(255,0,153)';
      let percentage = this._technicianLivePosition.action_end?.finish_percentage
      background_string = `conic-gradient(from 0deg at 50% 50%, ${accent_color} 0%, ${accent_color} ${percentage}%, ${gray} ${percentage}%, ${gray} 100%)`;
    } else {
      let current_action = this._technicianLivePosition.current_action
      let accent_color = current_action == 'finished' ? 'rgb(0,255,13)' : 'rgb(68, 68, 68)';
      let percentage = 100
      background_string = `conic-gradient(from 0deg at 50% 50%, ${accent_color} 0%, ${accent_color} ${percentage}%, ${gray} ${percentage}%, ${gray} 100%)`;
    }

    this.conicBackground = background_string
  }

  protected readonly PATimeControl = PATimeControl;
}