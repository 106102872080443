import { RouterLink, RouterOutlet } from "@angular/router";

import { Component, inject, OnInit } from '@angular/core'
import { SessionService } from './_services'
import { NgbCollapseModule, NgbDropdownModule } from "@ng-bootstrap/ng-bootstrap";
import { CommonModule } from "@angular/common";
import { FaIconLibrary } from "@fortawesome/angular-fontawesome";
import { far } from "@fortawesome/pro-regular-svg-icons";
import { fas } from '@fortawesome/pro-solid-svg-icons'
import { LicenseManager } from "ag-grid-enterprise";
import { flatpickrFactory } from "./app.config";

@Component({
    selector: 'hawk-root',
    imports: [RouterOutlet, NgbCollapseModule, NgbDropdownModule, CommonModule, RouterLink],
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'hawk-angular'

  navbarCollapsed = true

  components = []

  sessionService: SessionService = inject(SessionService)

  constructor(
    library: FaIconLibrary,
  ) {

    library.addIconPacks(
          fas,
          far,
        )

     LicenseManager.setLicenseKey("Using_this_{AG_Grid}_Enterprise_key_{AG-059031}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Bentomax_GmbH}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{Hawk}_only_for_{1}_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_{Hawk}_need_to_be_licensed___{Hawk}_has_not_been_granted_a_Deployment_License_Add-on___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{24_April_2025}____[v3]_[01]_MTc0NTQ0OTIwMDAwMA==4079b61df2ecc21a6f919091ae6dd419")
   }

  ngOnInit(): void {
    flatpickrFactory()
  }

  public dontCollapseNavbar() {
    this.navbarCollapsed = false
  }

  public collapseNavbar() {
    this.navbarCollapsed = true
  }

  public toggleNavbar() {
    this.navbarCollapsed = !this.navbarCollapsed
  }

  public isLoggedIn() {
    return this.sessionService.loggedIn()
  }

  public handleLogout() {
    this.sessionService.logout()
  }
}