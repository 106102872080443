import { Injectable } from '@angular/core'
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http'

import { environment } from '../../environments/environment'
import { Article, ArticleTemplate, BulkArticle, DeliveryNote, Ticket } from '../_models'
import { TicketSubhashArticles } from '../_models/trip_subhash_articles'

@Injectable({
  providedIn: 'root'
})
export class ArticleService {

  constructor(
    private http: HttpClient,
  ) {
  }

  public all(params: any = {}) {
    const path = environment.apiURL + 'articles.json'

    return this.http.get<Article[]>(path, { params: params })
  }

  public show(id: string | String | number, params: any = {}) {
    let path = environment.apiURL + `articles/${id}.json`;
    return this.http.get<Article>(path, { params });
  }

  public update(id: string | String | number, params: any) {
    const path = environment.apiURL + 'articles/'+ id +'.json'
    return this.http.patch<Article>(path, params)
  }

  public create(params: any) {
    const path = environment.apiURL + 'articles.json'
    return this.http.post<Article>(path, params)
  }

  public delete(id: String | string | number) {
    const path = environment.apiURL + 'articles/' + id + '.json'

    return this.http.delete<any>(path)
  }

  public loadArticleTemplates(params: any = {}) {
    const path = environment.apiURL + 'article_templates.json'
    return this.http.get<ArticleTemplate[]>(path, { params })
  }

  public sendBulkArticles(bulkArticle: BulkArticle) {
    const path = environment.apiURL + 'bulk_article_import.json'

    return this.http.post<BulkArticle>(path, bulkArticle)
  }

  public getArticleTemplate(id: number) {
    const path = environment.apiURL + 'article_templates/' + id + '.json'

    return this.http.get<ArticleTemplate>(path)
  }

  public getArticle(id: number) {
    const path = environment.apiURL + 'articles/' + id + '.json'

    return this.http.get<Article>(path)
  }

  public getArticlesByOperation(id: number) {
    const path = environment.apiURL + 'articles/by_operation/' + id + '.json'

    return this.http.get<Article[]>(path)
  }

  public getArticlesByTemplate(id: number) {
    const path = environment.apiURL + 'articles/by_template/' + id + '.json'

    return this.http.get<Article[]>(path)
  }

  public getArticlesByTemplateFilterless(ids: number[]) {
    let params = new HttpParams();

    ids.forEach((id: number) => {
      params = params.append(`template_ids[]`, id);
    })
    const path = environment.apiURL + 'articles/by_template_filterless.json'

    return this.http.get<Article[]>(path, { params: params })
  }

  public getArticlesTemplates(id: number) {
    const path = environment.apiURL + 'article_templates/customer/' + id + '.json'

    return this.http.get<ArticleTemplate[]>(path)
  }

  public getArticlesTemplatesByProject(id: number) {
    const path = environment.apiURL + 'article_templates/project/' + id + '.json'

    return this.http.get<ArticleTemplate[]>(path)
  }

  public searchArticles(keyword: string, params: any = {}) {
    const path = environment.apiURL + 'articles/search/' + keyword + '.json'

    return this.http.get<Article[]>(path, { params: params })
  }

  public findDeliveryNote(keyword: string) {
    const path = environment.apiURL + 'delivery_notes/with_articles/' + keyword + '.json'

    return this.http.get<DeliveryNote>(path)
  }

  public getDeliveryNotes(id: string | String | number) {
    const path = environment.apiURL + 'articles/' + id + '/delivery_notes.json'

    return this.http.get<DeliveryNote[]>(path)
  }

  public getTickets(id: string | String | number) {
    const path = environment.apiURL + 'articles/' + id + '/tickets.json'

    return this.http.get<TicketSubhashArticles[]>(path)
  }

  public getHistories(id: number | string | String) {
    const path = environment.apiURL + 'articles/' + id + '/histories.json'

    return this.http.get<any[]>(path)
  }

}
