
<div class="flex_col" style="user-select: none; max-width: 100%; width: 100%;">
  <div id="label_container" class="flex_row" *ngIf="labels?.length">
    <div class="label" *ngFor="let label of labels">
      <div class="color_circle" [style.background-color]="label.color"></div>
      <div style="margin: auto;">
        {{label.description}}
      </div>
    </div>
  </div>
  <div style="width: 100%; overflow-x: auto;">
    <div style="width: fit-content; position: relative;">
      <div style="width: fit-content; overflow-x: auto;position: relative; border: 1px solid lightgray; border-radius: 4px;" class="flex_col">
        <mat-expansion-panel *ngIf="options.length" style="margin: 1px;" [togglePosition]="'before'">
          <mat-expansion-panel-header>
            <mat-panel-title> Optionen </mat-panel-title>
            <div style="margin: auto 6px auto auto; padding: 4px 0;">
              <mat-form-field class="outline-small no-subscript" [appearance]="'outline'" (click)="$event.stopPropagation()">
                <mat-label>Filter</mat-label>
                <mat-select [(ngModel)]="activeFilter">
                  <mat-option [value]="''">
                    -
                  </mat-option>
                  <mat-option *ngFor="let filter_option of filter" [value]="filter_option">
                    {{filter_option}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </mat-expansion-panel-header>
          <table>
            <tbody>
              <tr *ngFor="let option of options" style="border-top: 1px solid lightgray">
                <td style="max-width: 160px; min-width: 160px; font-size: .9rem;">
                  {{option.title}}
                  <fa-icon *ngIf="option.tooltip" [icon]="faInfoCircle" [tooltip]="option.tooltip"></fa-icon>
                </td>
                <ng-container *ngFor="let bar of bars">
                  <td *ngIf="barIsShown(bar)" style="max-width: 48px; min-width: 48px; text-align: center;">
                    <mat-checkbox color="primary" [(ngModel)]="bar.data.options[option.title]" (change)="changeOptionEvent.emit({idx: bars.indexOf(bar), option: option.title, new_value: $event.checked})"></mat-checkbox>
                  </td>
                </ng-container>
              </tr>
            </tbody>
          </table>
        </mat-expansion-panel>
        <div id="bars_container" class="flex_row">
          <div id="bars_container_overlay"></div>
          <div style="position: relative; min-height: 100px; width: 50px; overflow-y: visible; border-right: 1px solid lightgray;" class="flex_col">
            <div *ngFor="let unit_amount_step of unitAmountSteps" class="unit_amount_step" [style.bottom]="unit_amount_step.percentage + '%'">
              <div>{{unit_amount_step.description}}</div>
              <div style="width: 14px; height: 0;"></div>
            </div>
          </div>
          <div class="flex_row" style="position:relative;">
            <ng-container *ngFor="let bar of bars">
              <div *ngIf="barIsShown(bar)" class="bar">
                <div class="sub_bar" *ngFor="let percentages of bar.percentages"
                     [tooltip]="$any(barTooltipTemplate)" contentType="template"
                     [ngStyle]="{
                     'bottom': percentages.from_percent + '%',
                     'top': (100 - percentages.to_percent) + '%',
                     'background-color': bar.data.style == 'filled' ? percentages.color : 'transparent',
                   }"
                     [style.border]="'2px solid '+ percentages.color + '!important'"
                     (click)="clickBarEvent.emit(bars.indexOf(bar))"
                >
                  <fa-icon class="bar_icon" *ngIf="percentages.icon" [icon]="percentages.icon"></fa-icon>

                  <ng-template #barTooltipTemplate>
                    <div style="text-align: left;" *ngIf="percentages.tooltip.infos?.length">
                      <h6>Info</h6>
                      <ul>
                        <li *ngFor="let info of percentages.tooltip.infos">
                          {{info}}
                        </li>
                      </ul>
                    </div>
                    <div style="text-align: left;" *ngIf="percentages.tooltip.errors?.length">
                      <h6>Fehler</h6>
                      <ul>
                        <li *ngFor="let error of percentages.tooltip.errors">
                          {{error}}
                        </li>
                      </ul>
                    </div>
                  </ng-template>
                </div>
                <div class="bar_description">{{bar.description}}</div>
              </div>
            </ng-container>

            <div *ngFor="let deadline of deadlines" class="deadline" style="border-width: 2px!important; right: -50px!important; z-index: 15;"
                 [style.bottom]="((deadline.at_value - unitAmountStart) / (unitAmountEnd - unitAmountStart)) * 100 + '%'"
                 [style.color]="deadline.color"
                 [style.border-color]="deadline.color + '!important'"
            >
              <div *ngIf="deadline.description" class="deadline_description">
                {{deadline.description}}
              </div>
            </div>

            <div *ngFor="let unit_amount_step of unitAmountSteps" class="deadline" style="left: -14px;"
                 [style.bottom]="unit_amount_step.percentage + '%'">
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</div>
