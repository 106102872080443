import { Pipe, PipeTransform } from '@angular/core'
import { QmJob } from '../_models'

@Pipe({
    name: 'qmJobType',
})
export class QmJobTypePipe implements PipeTransform {
  transform(allJobs: QmJob[], jobStatus?: string): QmJob[] {
    if (!allJobs) {
      return allJobs
    }

    return allJobs.filter(job => job.job_type === jobStatus)
  }
}
