import * as mapboxgl from "mapbox-gl";
import { PAStore, StoreGeometry, StoreProperties } from "./store";
import { GeoJSONSource } from "mapbox-gl";
import { DynamicComponentService } from "../../../_services/dynamic-component-service";
import { StorePopupComponent } from "../reuseable-components/store-popup/store-popup.component";
import { PATechnicianDate } from "./technician-date";
import { PADataControl } from "../singletons/pa-data-control";
import { PAStoreControl } from "../singletons/pa-store-control";

export class PAMapStoreClusterer {

  private _stores: PAStore[] = []
  private _features: GeoJSON.Feature<StoreGeometry, StoreProperties>[] = []
  private _technicianDate: PATechnicianDate = null

  constructor(
    public map: mapboxgl.Map,
    private dynamicComponentService: DynamicComponentService,
  ) {
    this.initMapLayers()
  }

  private initMapLayers(): void {
    this.map.addSource('stores', {
      type: "geojson",
      data: {
        type: "FeatureCollection",
        features: this._features
      },
      cluster: true,
      clusterMaxZoom: 14,
      clusterRadius: 30
    })

    this.map.addLayer({
      id: 'store_clusters',
      type: "circle",
      source: 'stores',
      filter: ['has', 'point_count'],
      paint: {
        'circle-color': [
          'step',
          ['get', 'point_count'],
          '#51bbd6',
          50,
          '#f1f075',
          200,
          '#f28cb1'
        ],
        'circle-radius': [
          'step',
          ['get', 'point_count'],
          25,
          100,
          35,
          750,
          45
        ],
        "circle-stroke-width": 1,
        "circle-stroke-color": '#000',
        "circle-opacity": .8
      }
    })

    this.map.addLayer({
      id: 'cluster-count',
      type: 'symbol',
      source: 'stores',
      filter: ['has', 'point_count'],
      layout: {
        'text-field': ['get', 'point_count_abbreviated'],
        'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
        'text-size': 12,
        "text-allow-overlap": false
      }
    });

    this.map.addLayer({
      id: 'unclustered-point',
      type: 'circle',
      source: 'stores',
      filter: ['!', ['has', 'point_count']],
      paint: {
        'circle-color': ['get', 'color'],
        'circle-radius': 20,
        'circle-stroke-width': 1,
        'circle-stroke-color': '#000',
      }
    });

    this.map.addLayer({
      'id': 'unclustered-clabel',
      'type': 'symbol',
      'source': 'stores',
      'filter': ['!', ['has', 'point_count']],
      'layout': {
        'text-field': ['get', 'short_name'],
        'text-font': ['Open Sans Semibold', 'Arial Unicode MS Bold'],
        'text-size': 10,
        'text-allow-overlap': true,
        'text-offset': [0, 1]
      },
      'paint': {
        'text-color': '#000',
      }
    });

    let controls_radius = 1.1
    let circle_radius = 10
    let rad_factor = Math.PI / 180

    this.map.addLayer({
      id: 'store-operation-count-circle',
      type: 'circle',
      source: 'stores',
      filter: ['!', ['has', 'point_count']],
      paint: {
        'circle-color': 'rgba(80,80,80,0.6)',
        'circle-radius': .9 * circle_radius,
        "circle-translate": [circle_radius * controls_radius * Math.cos(205 * rad_factor), circle_radius * controls_radius * Math.sin(205 * rad_factor)],
      }
    });

    this.map.addLayer({
      'id': 'store-operation-count-clabel',
      'type': 'symbol',
      'source': 'stores',
      'filter': ['!', ['has', 'point_count']],
      'layout': {
        'text-field': ['get', 'done_operations'],
        'text-font': ['Open Sans Semibold', 'Arial Unicode MS Bold'],
        'text-size': 10,
        "text-offset": [controls_radius * Math.cos(205 * rad_factor), controls_radius * Math.sin(205 * rad_factor)],
        'text-allow-overlap': true
      },
      'paint': {
        'text-color': ['get', 'done_operations_color'],
      }
    });

    let change_operations_label_size_factor = 1.5
    /*this.map.addLayer({
      id: 'store-remove-operation-circle',
      type: 'circle',
      source: 'stores',
      filter: ['!', ['has', 'point_count']],
      paint: {
        'circle-color': 'rgba(80,80,80,0.7)',
        'circle-radius': circle_radius,
        'circle-stroke-width': 1,
        'circle-stroke-color': '#fff',
        "circle-translate": [circle_radius * controls_radius * Math.cos(162 * rad_factor), circle_radius * controls_radius * Math.sin(162 * rad_factor)],
      }
    });

    this.map.addLayer({
      'id': 'store-remove-operation-label',
      'type': 'symbol',
      'source': 'stores',
      'filter': ['!', ['has', 'point_count']],
      'layout': {
        'text-field': '−',
        'text-size': circle_radius * change_operations_label_size_factor,
        "text-offset": [controls_radius * Math.cos(162 * rad_factor) / change_operations_label_size_factor, controls_radius * Math.sin(162 * rad_factor) / change_operations_label_size_factor],
        'text-allow-overlap': true
      },
      'paint': {
        'text-color': '#fff',
      }
    });*/

    this.map.addLayer({
      id: 'store-add-operation-circle',
      type: 'circle',
      source: 'stores',
      filter: ['all', ['!', ['has', 'point_count']], ['get', 'show_add_operation_button']],
      paint: {
        'circle-color': 'rgba(80,80,80,0.9)',
        'circle-radius': circle_radius,
        'circle-stroke-width': 1,
        'circle-stroke-color': '#fff',
        "circle-translate": [2 * circle_radius * controls_radius * Math.cos(0 * rad_factor), 2 * circle_radius * controls_radius * Math.sin(0 * rad_factor)],
      }
    });

    this.map.addLayer({
      'id': 'store-add-operation-clabel',
      'type': 'symbol',
      'source': 'stores',
      'filter': ['all', ['!', ['has', 'point_count']], ['get', 'show_add_operation_button']],
      'layout': {
        'text-field': '+',
        'text-size': change_operations_label_size_factor * circle_radius,
        "text-offset": [2 * controls_radius * Math.cos(0 * rad_factor) / change_operations_label_size_factor, 2 * controls_radius * Math.sin(0 * rad_factor) / change_operations_label_size_factor],
        'text-allow-overlap': true
      },
      'paint': {
        'text-color': '#fff',
      }
    });

    this.map.addLayer({
      id: 'store-letter-circle',
      type: 'circle',
      source: 'stores',
      filter: ['all', ['!', ['has', 'point_count']], ['get', 'has_letter']],
      paint: {
        'circle-color': '#673ab7',
        'circle-radius': .8 * circle_radius,
        'circle-stroke-width': 1,
        'circle-stroke-color': '#fff',
        "circle-translate": [circle_radius * controls_radius * Math.cos(335 * rad_factor), circle_radius * controls_radius * Math.sin(335 * rad_factor)],
      }
    });

    this.map.addLayer({
      'id': 'store-letter-clabel',
      'type': 'symbol',
      'source': 'stores',
      'filter': ['all', ['!', ['has', 'point_count']], ['get', 'has_letter']],
      'layout': {
        'text-field': ['get', 'letter'],
        'text-size': circle_radius,
        "text-offset": [controls_radius * Math.cos(335 * rad_factor), controls_radius * Math.sin(335 * rad_factor)],
        'text-allow-overlap': true
      },
      'paint': {
        'text-color': '#fff',
      }
    });

    this.map.addLayer({
      'id': 'next-addable-operation-time-clabel',
      'type': 'symbol',
      'source': 'stores',
      'filter': ['all', ['!', ['has', 'point_count']], ['get', 'show_add_operation_button']],
      'layout': {
        'text-field': ['get', 'next_addable_operation_time'],
        'text-size': circle_radius,
        "text-offset": [2.5 * controls_radius * Math.cos(90 * rad_factor), 2.5 * controls_radius * Math.sin(90 * rad_factor)],
        'text-allow-overlap': true
      },
      'paint': {
        'text-color': 'black',
      }
    });

    this.map.on('click', 'store_clusters', (e) => {
      const features = this.map.queryRenderedFeatures(e.point, {
        layers: ['store_clusters']
      });
      const clusterId = features[0].properties.cluster_id;
      let source = this.map.getSource('stores') as GeoJSONSource
      source.getClusterExpansionZoom(
        clusterId,
        (err, zoom) => {
          if (err) return;

          this.map.easeTo({
            center: features[0].geometry['coordinates'],
            zoom: zoom
          });
        }
      );
    })

    this.map.on('mouseenter', 'store_clusters', () => {
      this.map.getCanvas().style.cursor = 'pointer';
    });
    this.map.on('mouseleave', 'store_clusters', () => {
      this.map.getCanvas().style.cursor = '';
    });
    this.map.on('mouseenter', 'store-add-operation-circle', () => {
      this.map.getCanvas().style.cursor = 'pointer';
    });
    this.map.on('mouseleave', 'store-add-operation-circle', () => {
      this.map.getCanvas().style.cursor = '';
    });

    this.map.on('click', (e) => {
      let f = this.map.queryRenderedFeatures(e.point, {layers: ['store-add-operation-circle']});
      if (f.length) {
        this.clickAddStoreOperationButton(f);
        return;
      }
      f = this.map.queryRenderedFeatures(e.point, {layers: ['unclustered-point']});
      if (f.length) {
        this.clickUnclusteredStore(f, e.lngLat);
        return;
      }
    });
  }

  private clickAddStoreOperationButton(features: mapboxgl.MapboxGeoJSONFeature[]) {
    let store_id = features[0].properties.id
    let store = PADataControl.Instance.getStore(store_id)
    store.addNextDoableUnplannedOperationToTourPlanning(this._technicianDate)
    this.updateFeatures()
  }

  private clickUnclusteredStore(features: mapboxgl.MapboxGeoJSONFeature[], lng_lat: mapboxgl.LngLat) {
    const coordinates = features[0].geometry['coordinates'].slice();

    while (Math.abs(lng_lat.lng - coordinates[0]) > 180) {
      coordinates[0] += lng_lat.lng > coordinates[0] ? 360 : -360;
    }

    let store = PADataControl.Instance.getStore(features[0].properties.id)
    let projects = [...new Set(store.shownMapOperations.map(op => op.ticket.project))]

    let injected_component = this.dynamicComponentService.injectComponent(StorePopupComponent, x => {
      x.store = store
      x.projects = projects
      x.expandedProjects = projects
      x.technicianDate = this._technicianDate
    })

    let popup = new mapboxgl.Popup({'offset': 20, 'maxWidth': '400px'})
      .setLngLat(coordinates)
      .setDOMContent(injected_component.div_element)
      .addTo(this.map);
    PAStoreControl.Instance.selectStore(store)

    popup.on('close', function (_) {
      injected_component.component_ref.destroy()
    })
  }

  get stores(): PAStore[] {
    return this._stores
  }

  set stores(stores: PAStore[]) {
    this._stores = stores
    this._features = stores.map(s => s.toMapboxFeature(this._technicianDate))
    this.updateFeatures()
  }

  set technicianDate(td: PATechnicianDate) {
    this._technicianDate = td
    this._features = this._stores.map(s => s.toMapboxFeature(td))
    this.updateFeatures()
  }

  get technicianDate(): PATechnicianDate {
    return this._technicianDate
  }

  updateFeatures() {
    let source = this.map.getSource('stores') as GeoJSONSource
    if (source) source.setData({
      type: 'FeatureCollection',
      features: this._features
    })
  }

  findStoreFeaturesBetweenCoordinates(start_coords: mapboxgl.LngLat, end_coords: mapboxgl.LngLat): GeoJSON.Feature<StoreGeometry, StoreProperties>[] {
    return this._features.filter(feature => {
      let feature_coords = feature.geometry.coordinates
      let lat_matches = start_coords.lat < end_coords.lat ? (start_coords.lat <= feature_coords[1] && feature_coords[1] <= end_coords.lat) : (start_coords.lat >= feature_coords[1] && feature_coords[1] >= end_coords.lat)
      let lng_matches = start_coords.lng < end_coords.lng ? (start_coords.lng <= feature_coords[0] && feature_coords[0] <= end_coords.lng) : (start_coords.lng >= feature_coords[0] && feature_coords[0] >= end_coords.lng)
      return lat_matches && lng_matches
    })
  }
}