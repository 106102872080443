<div class="row">
  <div class="col-sm-12">
    <h3>Ticket fixer
    </h3>

    <div class="card">
      <div class="card-body">
        <p>
          Achtung. Das benutzen dieses Uploads ist SEHR gefährlich und ändert Daten sofort ohne weitere Nachfrage!
        </p>
        <p>
          gültige Felder: <br>
          - id (z.B: MI-20-044100)<br>
          - operation_id (z.B: 62345, INTERNE ID aus dem EXPORT!, nur pflicht, wenn unten benötigt.)<br>
          - time_estimation (z.B: 15)<br>
          - description (Ändert den Fehlertext nur im Ticket)<br>
          - description_op (Ändert den Fehlertext nurim ausgewählten Einsätz - operation_id benötigt!)<br>
          - description_all (Ändert den Fehlertext nur im Ticket und allen Einsätzen des Tickets.)<br>
          - invoice_number (Rechnungsnummer - operation_id benötigt!)<br>
          - invoice_amount (Rechnungssumme - operation_id benötigt!)<br>
          - invoice_number_partner (Rechnungsnummer Partner - operation_id benötigt!)<br>
          - invoice_amount_partner (Rechnungssumme Partner - operation_id benötigt!)<br>
          - invoice_po_order_amount (Rechnungssumme PO/Bestellung - operation_id benötigt!)<br>
          - invoice_pox_order_amount (Rechnungssumme POX/Bestellung - operation_id benötigt!)<br>
          - invoice_po15_order_amount (Rechnungssumme PO15/Bestellung - operation_id benötigt!)<br>
          - operation_date (Ändert das Datum des ausgewählten Einsatzes im Ticket - operation_id benötigt!)<br>
          - technician_ids (Ändert die Techniker des ausgewählten Einsatzes im Ticket, Ids durch | getrennt:
          13|15|167|900 oder nur 5 - operation_id benötigt!)<br>
          - status_id (ticket status id, wie vom hawk vergeben, integer)<br>
          <br><br>

          Upload im xlsx Format<br>
          Import bricht bei erster leerer Zeile (id nicht gesetzt) ab!
        </p>
      </div>
    </div>
  </div>
</div>

<div class="row">
  <div class="col-12">
    <div class="card">
      <div class="card-body" *ngIf="status == 'new'">

        <h3>Import Datei auswählen</h3>

        <form [formGroup]="form" (ngSubmit)="onSubmit()">

          <input type="file" name="importfile" (change)="onFileChange($event)" />
          <button type="submit">Upload</button>

        </form>
      </div>

      <div class="card-body" *ngIf="status == 'running'">

        <h3>Import läuft, bitte warten!</h3>
      </div>

      <div class="card-body" *ngIf="status == 'done'">

        <h3>Abgeschlossen!</h3>
        <p>Anzahl Fehler: {{errorItems.length}}</p>
        <p>Fehler: {{errorItems | json}}
          <ng-container *ngFor="let e of errorItems">
            <b>Zeile {{e[0]}}</b>: Ticket {{e[1]}}, Fehler: {{e[2]}}<br>
          </ng-container>
        </p>
        <p>
          <button class="btn btn-primary" (click)="status = 'new'">nochmal benutzen</button>
        </p>
      </div>

      <div class="card-body" *ngIf="status == 'error'">

        <h3>Fehler!</h3>
        <p>{{errorText}}</p>
        <p>
          <button class="btn btn-primary" (click)="status = 'new'">nochmal benutzen</button>
        </p>
      </div>
    </div>

  </div>
</div>