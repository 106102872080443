import { Component, inject, OnInit } from '@angular/core';
import { AG_GRID_LOCALE_DE } from "../../_helpers/aggrid.locale.de";
import { ColDef, GridReadyEvent } from "ag-grid-community";
import { UserService } from "../../_services";
import { APTechnicianHash } from "../../_models/technician.interface";
import {
  GridApi,
  RowClassRules,
  SideBarDef, SizeColumnsToContentStrategy,
  SizeColumnsToFitGridStrategy,
  SizeColumnsToFitProvidedWidthStrategy
} from "ag-grid-enterprise";
import { faMapMarker, faMarker, faUser } from "@fortawesome/free-solid-svg-icons";
import { MatCheckbox } from '@angular/material/checkbox';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { AgGridAngular } from 'ag-grid-angular';
import { MatButton } from '@angular/material/button';
import { MatCheckboxModule } from "@angular/material/checkbox";
import { AgGridThemeService } from 'src/app/_services/ag-grid-theme.service';
import { RecordNavigationComponent } from "../_shared/record-navigation/record-navigation.component";

@Component({
  selector: 'hawk-user-index',
  templateUrl: './user-index.component.html',
  styleUrls: ['./user-index.component.scss', '../_shared/styles/common-styles.scss'],
  standalone: true,
  imports: [RecordNavigationComponent, MatCheckbox, ReactiveFormsModule, FormsModule, AgGridAngular, MatButton, MatCheckboxModule, AgGridAngular]
})
export class UserIndexComponent implements OnInit{
  public hawkTheme = inject(AgGridThemeService).getTheme();

  protected readonly locale = AG_GRID_LOCALE_DE;

  public users: APTechnicianHash[] = []
  public shownUsers: APTechnicianHash[] = []
  public onlyActiveUsers: boolean = true

  private gridApi!: GridApi;

  public rowClassRules: RowClassRules = {}
  autoSizeStrategy: SizeColumnsToFitGridStrategy | SizeColumnsToFitProvidedWidthStrategy | SizeColumnsToContentStrategy = {
    type: "fitGridWidth",
  }

  public colDefs: ColDef[] = [
    {
      field: "id",
      headerName: "ID",
      width: 70,
    },
    {
      field: "full_name",
      headerName: "Name",
      width: 180,
      cellRenderer: this.nameRenderer,
      cellClass: 'hover_link_cell'
    },
    {
      field: "company",
      headerName: "Firma",
    },
    {
      field: "repairer",
      headerName: "Instandsetzer",
      width: 160,
    },
    {
      field: "email1",
      headerName: "E-Mail",
      width: 180,
      cellRenderer: this.emailRenderer,
    },
    {
      field: "phone1",
      headerName: "Telefon1",
      width: 200,
      cellRenderer: this.telephoneRenderer
    },
    {
      field: "street",
      headerName: "Straße"
    },
    {
      field: "zip",
      headerName: "PLZ",
      width: 100,
    },
    {
      field: "city",
      headerName: "Ort"
    },
    {
      field: "active",
      headerName: "Aktiv",
      width: 80,
    }
  ]

  public sideBarOptions: SideBarDef = {
    hiddenByDefault: false,
    toolPanels: ['columns', 'filters']
  }

  public defaultColDef: ColDef = {
    filter: true
  }

  public rowSelection: "single" | "multiple" = "multiple"

  constructor(
    public userService: UserService
  ) {
  }

  ngOnInit(): void {
    this.userService.getUsersAPHashes().subscribe(
      data => {
        this.users = data
        this.updateShownUsers()
      },
      err => {
        console.log(err)
      }
    )
  }

  updateShownUsers(): void {
    this.shownUsers = this.users.filter(u => !this.onlyActiveUsers || u.active)
  }

  onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api
  }

  public quickSearchChanged(e: Event) {
    const newValue = (e.target as HTMLInputElement).value
    this.gridApi.setGridOption(
      "quickFilterText",
      newValue,
    )
  }

  telephoneRenderer(params) {
    const phone = params.value
    return `<a href="tel:${phone ? phone.replaceAll(' ', '') : ''}" target="_blank" rel="noopener">${phone || ''}</a>`
  }

  emailRenderer(params) {
    const mail = params.value
    return `<a href="mailto:${mail}" target="_blank" rel="noopener">${mail || ''}</a>`
  }

  nameRenderer(params) {
    const id = params.data.id
    return `<a class="hover_link" href="/a/benutzer/${id}" rel="noopener">${params.value}</a>`
  }

  protected readonly faMarker = faMarker;
  protected readonly faMapMarker = faMapMarker;
  protected readonly faUser = faUser;
}