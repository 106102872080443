<div class="d-flex align-items-center" *ngIf="deliveryNote">
    <div class="col t-header d-flex align-items-center justify-content-start">
        <h4 class="mb20 d-flex align-items-center gap-1">
            <i class="fa fa-truck mr6" aria-hidden="true"></i>
            <ul class="list-group list-group-horizontal b-custom">
                <li class="list-group-item bg-transparent border-0">
                    <a
                    class="g-link"
                    [routerLink]="['/', 'lieferscheine', 'suche', 'index']"
                    >
                    Lieferscheine
                </a>
            </li>
            <li class="list-group-item bg-transparent border-0">
                <a
                class="g-link"
                [routerLink]="['/', 'lieferscheine', deliveryNote.id]"
                >
                <span>{{ deliveryNote.number }}</span>
            </a>
            <span
            *ngIf="
            deliveryNote.shipcloud_retoure_carrier &&
            deliveryNote.related_note_id
            "
            >
            <span class="ml20 fs14 white normal">Retoure:</span>
            <a class="g-link" href="/unknown-for-now">
                {{ deliveryNote.related_note_number }}
            </a>
        </span>
    </li>
    <li class="list-group-item bg-transparent border-0">
        Label über Shipcloud erstellen
    </li>
</ul>
</h4>
</div>
</div>
<form [formGroup]="deliveryNoteShippingForm" (submit)="createShipping()" *ngIf="deliveryNote">
    <div class="ticketPage">
        <section class="main-content">
            <header class="columns">
                <section>
                    <h2>Empfänger</h2>
                    <table class="ticketPageTable base mt-3">
                        <tr>
                            <th class="tableLable">Company</th>
                            <td>{{ deliveryNote.recipient_address_company || '-' }}</td>
                        </tr>
                        <tr>
                            <th class="tableLable">Name</th>
                            <td>
                                {{ deliveryNote.recipient_address_firstname }}
                                {{ deliveryNote.recipient_address_lastname }}
                            </td>
                        </tr>
                        <tr>
                            <th class="tableLable">Street</th>
                            <td>{{ deliveryNote.recipient_address_street || '-' }}</td>
                        </tr>
                        <tr>
                            <th class="tableLable">Zipcode and city</th>
                            <td>
                                {{ deliveryNote.recipient_address_zip }}
                                {{ deliveryNote.recipient_address_city }}
                            </td>
                        </tr>
                        <tr>
                            <th class="tableLable">Country (state)</th>
                            <td>
                                {{ deliveryNote.recipient_address_country }}
                                <span *ngIf="deliveryNote.recipient_address_state">
                                    ({{ deliveryNote.recipient_address_state }})
                                </span>
                            </td>
                        </tr>
                    </table>
                </section>
            </header>
            <section>
                <h2>Versand</h2>
                <div class="form-group">
                    <ng-select
                    [items]="carriersList"
                    formControlName="carrier"
                    placeholder="carrier"
                    [required]="true"
                    >
                    <ng-template ng-label-tmp let-item="item">
                        {{ item }}
                    </ng-template>
                    <ng-template
                        ng-option-tmp
                        let-item="item"
                        let-search="searchTermStatus"
                    >
                        <div
                        *ngIf="
                            item
                            .toLowerCase()
                            .includes(searchTerm.toLowerCase())
                        "
                        >
                        {{ item }}
                        </div>
                    </ng-template>
                    </ng-select>

                </div>
                <div class="form-group my-2">
                    <ng-select
                    [items]="shippingServices"
                    formControlName="service"
                    placeholder="service"
                    bindLabel="name" bindValue="id"
                    required
                    >
                    <ng-template ng-label-tmp let-item="item">
                        {{ item.name }}
                    </ng-template>
                    <ng-template
                        ng-option-tmp
                        let-item="item"
                        let-search="searchTermStatus"
                    >
                        <div
                        *ngIf="
                            item.name
                            .toLowerCase()
                            .includes(searchTerm.toLowerCase())
                        "
                        >
                        {{ item.name }}
                        </div>
                    </ng-template>
                    </ng-select>
                </div>
                <div class="form-group my-2">
                    <div class="d-flex justify-content-between">
                        <span class="d-flex gap-1 align-items-center">
                            <span>Preis</span>
                            <span id="price_element">-</span>
                        </span>
                        <button [disabled]="deliveryNoteShippingForm.invalid || loadingPricing" type="button" class="btn button d-flex gap-1 align-items-center" (click)="calculatePrirce()">
                            <i class="fa fa-refresh" aria-hidden="true"></i>Preis ermitteln
                        </button>
                    </div>
                </div>
                <div class="form-group">
                    <mat-form-field class="col-6" appearance="outline">
                        <mat-label>Telefonnummer Sender (Express)</mat-label>
                        <input
                            matInput
                            type="text"
                            placeholder="Telefonnummer Sender (Express)"
                            formControlName="sender_phone"
                        />
                    </mat-form-field>
                </div>
                <div class="form-group">
                    <mat-form-field class="col-6" appearance="outline">
                        <mat-label>Telefonnummer Empfänger (Express)</mat-label>
                        <input
                            matInput
                            type="text"
                            placeholder="Telefonnummer Empfänger (Express)"
                            formControlName="receiver_phone"
                        />
                    </mat-form-field>
                </div>
                <div class="form-group">
                    <mat-form-field class="col-6" appearance="outline">
                        <mat-label>Benachrichtigung</mat-label>
                        <input
                            matInput
                            type="email"
                            placeholder="Benachrichtigung"
                            formControlName="email"
                        />
                    </mat-form-field>
                </div>
                <div class="form-group">
                    <mat-form-field class="col-6" appearance="outline">
                        <mat-label>Paketbeschreibung</mat-label>
                        <textarea formControlName="description" matInput></textarea>
                        <mat-hint>Bei DHL-Express erforderlich, Preisauskunft aber nicht möglich</mat-hint>
                    </mat-form-field>
                </div>
                <div class="form-group">
                    <label>Pickup (Direktbuchung):</label>
                    <mat-checkbox formControlName="direct_pickup">Abholung direkt buchen (DHL Express)</mat-checkbox>
                </div>
                <div class="form-group">
                    <mat-form-field appearance="outline">
                        <input
                            matInput
                            [matDatepicker]="picker"
                            formControlName="pickup_day"
                        />
                        <mat-hint>MM/DD/YYYY</mat-hint>
                        <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field>
                </div>
                <div class="form-group d-flex align-items-center">
                    <mat-form-field appearance="outline">
                        <input
                            matInput
                            type="time"
                            formControlName="pickup_start"
                        />
                    </mat-form-field>
                    <label for="pickup_end" class="col-sm-1 control-label pl-0 pr-0" style="text-align: center;"><i class="fa fa-long-arrow-right" aria-hidden="true"></i></label>
                    <mat-form-field appearance="outline">
                        <input
                            matInput
                            type="time"
                            formControlName="pickup_end"
                        />
                    </mat-form-field>
                </div>
                <div class="form-group">
                    <label>Sonderwünsche</label>
                    <mat-checkbox formControlName="saturday_delivery">Samstagszustellung (DHL Express)</mat-checkbox>
                </div>
            </section>
            <div class="d-flex col-auto my-2 align-items-center justify-content-between">
                <span class="d-flex col-auto gap-2 align-items-center">
                    <button mat-raised-button class="button" [disabled]="deliveryNoteShippingForm.invalid || submittingLabel" type="submit" >
                        Versandmarke erstellen und drucken
                    </button>
                    <mat-checkbox formControlName="test">Nur Test</mat-checkbox>
                </span>
                <a class="text-right" [routerLink]="['/', 'lieferscheine', deliveryNote.id]" >Abbrechen</a>
            </div>
        </section>
        <aside>
            <section>
                <h2>Paket</h2>
                <div class="form-group">
                    <mat-form-field appearance="outline">
                        <mat-label>Höhe</mat-label>
                        <input
                            matInput
                            type="number"
                            placeholder="Höhe"
                            formControlName="height"
                            required
                        />
                        <span matTextPrefix>cm&nbsp;</span>
                    </mat-form-field>
                </div>
                <div class="form-group">
                    <mat-form-field appearance="outline">
                        <mat-label>Breite</mat-label>
                        <input
                            matInput
                            type="number"
                            placeholder="Breite"
                            formControlName="width"
                            required
                        />
                        <span matTextPrefix>cm&nbsp;</span>
                    </mat-form-field>
                </div>
                <div class="form-group">
                    <mat-form-field appearance="outline">
                        <mat-label>Länge</mat-label>
                        <input
                            matInput
                            type="number"
                            placeholder="Länge"
                            formControlName="length"
                            required
                        />
                        <span matTextPrefix>cm&nbsp;</span>
                    </mat-form-field>
                </div>
                <div class="form-group">
                    <mat-form-field appearance="outline">
                        <mat-label>Gewicht</mat-label>
                        <input
                            matInput
                            type="number"
                            placeholder="Gewicht"
                            formControlName="weight"
                            required
                        />
                        <span matTextPrefix>kg&nbsp;</span>
                    </mat-form-field>
                </div>
            </section>
        </aside>
    </div>
</form>