

<div style="display: inline-flex; flex-direction: column; color: rgb(255, 255, 255); border-right: none; overflow: visible;">

  <div style="display: inline-flex; flex-direction: row; flex:1; user-select: none; overflow: visible;">

    <div style="display: inline-flex; width: 32px; cursor: pointer; border: 1px solid black; border-right: none;" class="primary" [ngClass]="Progress.runningProgresses.length ? 'animated' : ''" (click)="showProgressInfo = !showProgressInfo">
      <div style="margin: auto;">
        <fa-icon [icon]="faUpload"></fa-icon>
      </div>
    </div>

    <div class="rpa" [ngClass]="showProgressInfo ? 'shown' : ''" style="position: relative;">
      <div class="black_gradient_background" (click)="toggleShowProgresses()" style="color:#fafafa; flex:1; display: inline-flex; border: 1px solid black; padding: 0 4px; flex-direction: row; border-right: none; width: fit-content; min-width: 180px; white-space: nowrap; flex-wrap: nowrap;">
        <ng-container *ngIf="Progress.runningProgresses.length">
          <div *ngIf="Progress.runningProgresses.length == 1" style="margin: auto; display: inline-flex; flex-direction: column; flex-wrap: nowrap;">
            <div style="text-align: center; font-size: .78rem;">
              {{Progress.runningProgresses[0].name}}
            </div>
            <div style="padding: 1px 1px; background-color: #838383;">
              <mat-progress-bar color="primary" mode="determinate" [value]="Progress.runningProgresses[0].completionPercentage"></mat-progress-bar>
            </div>
          </div>
          <ng-container *ngIf="Progress.runningProgresses.length > 1" >
            <div style="margin: auto;">
              {{Progress.runningProgresses.length}} laufende Prozesse...
            </div>
            <div style="height: 32px; width: 32px; margin: auto;">
              <hawk-throbber
              [backgroundColor]="'rgba(0,0,0,0)'"></hawk-throbber>
            </div>
          </ng-container>
        </ng-container>
        <ng-container *ngIf="!Progress.runningProgresses.length">
          <div style="margin: auto;">
            Keine laufenden Prozesse
          </div>
        </ng-container>
      </div>
      <div style="position: absolute; bottom: 100%; left: 0; right: 0; z-index: 50;">
        <div class="progresses" [ngClass]="showProgresses ? 'shown' : ''">
          <div style="height: fit-content; border-left: 2px solid black; border-top: 2px solid black;">
            <ng-container *ngIf="Progress.runningProgresses.length">
              <hawk-expand-bar-header style="width: 100%;"
              [description]="'Laufende Prozesse: ' + Progress.runningProgresses.length"
              [isExpanded]="showRunningProgresses"
              [fontSize]="'.8rem'"
              [leftAligned]="true"
              (toggle)="showRunningProgresses = $event">
              </hawk-expand-bar-header>
              <div class="toggle_body" [ngClass]="showRunningProgresses ? '' : 'hidden'">
                <div class="progress_list">
                  <div *ngFor="let progress of Progress.runningProgresses" class="pa_progress" [ngClass]="progress.subProgressAmount == 1 ? '' : 'white_hover'" (click)="toggleShowSubProgressesForProgress(progress)">
                    <div>
                      {{progress.name}}
                    </div>
                    <div>
                      <mat-progress-bar color="primary" mode="determinate" [value]="progress.completionPercentage"></mat-progress-bar>
                    </div>
                    <ng-container *ngIf="progress.subProgressAmount > 1">
                      <div *ngIf="showSubProgressesForProgresses.includes(progress) && progress.subProgressAmount > 1">
                        <ng-container *ngIf="progress.runningSubProgresses.length">
                          <div class="sub_progress_list" *ngFor="let sub_progress of progress.runningSubProgresses">
                            <div class="sub_progress" style="display: inline-flex; flex-direction: column; text-align: center;" >
                              <div>
                                {{sub_progress.name}}
                              </div>
                              <div>
                                <mat-progress-bar color="primary" mode="indeterminate"></mat-progress-bar>
                              </div>
                            </div>
                          </div>
                        </ng-container>
                        <ng-container *ngIf="progress.errorSubProgresses.length">
                          <div class="sub_progress_list" *ngFor="let sub_progress of progress.errorSubProgresses">
                            <div class="sub_progress">
                              {{sub_progress.name}}
                              &#10060;
                            </div>
                          </div>
                        </ng-container>
                        <ng-container *ngIf="progress.successSubProgresses.length">
                          <div class="sub_progress_list" *ngFor="let sub_progress of progress.successSubProgresses">
                            <div class="sub_progress">
                              {{sub_progress.name}}
                              <fa-icon [icon]="faCheck"></fa-icon>
                            </div>
                          </div>
                        </ng-container>
                      </div>
                    </ng-container>
                  </div>
                </div>
              </div>
            </ng-container>
            <ng-container *ngIf="Progress.finishedProgresses.length">
              <hawk-expand-bar-header style="width: 100%;"
              [description]="'Fertige Prozesse: ' + Progress.finishedProgresses.length"
              [isExpanded]="showFinishedProgresses"
              [fontSize]="'.8rem'"
              [leftAligned]="true"
              (toggle)="showFinishedProgresses = $event">
              </hawk-expand-bar-header>
            </ng-container>
            <div class="toggle_body" [ngClass]="showFinishedProgresses ? '' : 'hidden'">
              <div class="progress_list">
                <div *ngFor="let progress of Progress.finishedProgresses" class="pa_progress" [ngClass]="progress.subProgressAmount == 1 ? '' : 'white_hover'" (click)="toggleShowSubProgressesForProgress(progress)">
                  <div>
                    {{progress.name}}
                    <ng-container *ngIf="progress.subProgressAmount == 1">
                      <ng-container *ngIf="progress.successSubProgresses.length == 1">
                        <fa-icon [icon]="faCheck"></fa-icon>
                      </ng-container>
                      <ng-container *ngIf="progress.errorSubProgresses.length == 1">
                        &#10060;
                      </ng-container>
                    </ng-container>
                  </div>
                  <ng-container *ngIf="progress.subProgressAmount > 1">
                    <div *ngIf="showSubProgressesForProgresses.includes(progress)">
                      <ng-container *ngIf="progress.errorSubProgresses.length">
                        <div class="sub_progress_list" *ngFor="let sub_progress of progress.errorSubProgresses">
                          <div class="sub_progress">
                            {{sub_progress.name}}
                            &#10060;
                          </div>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="progress.successSubProgresses.length">
                        <div class="sub_progress_list" *ngFor="let sub_progress of progress.successSubProgresses">
                          <div class="sub_progress">
                            {{sub_progress.name}}
                            <fa-icon [icon]="faCheck"></fa-icon>
                          </div>
                        </div>
                      </ng-container>
                    </div>
                  </ng-container>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- <table id="progress_table">
  <tbody>
    <tr *ngFor="let progress of Progress.runningProgresses">
      <td>
        {{progress.name}}
      </td>
      <td style="width: 120px;">
        <mat-progress-bar color="primary" mode="determinate" [value]="progress.completionPercentage"></mat-progress-bar>
      </td>
    </tr>
  </tbody>
</table> -->