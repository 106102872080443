import { Component, ElementRef, Input, OnChanges, OnInit, SimpleChange, SimpleChanges, ViewChild } from '@angular/core';
import { PAUtil } from '../../classes/util';
import { PATechnicianDate } from '../../classes/technician-date';
import { ListsSimpleChange } from '../../map/map.component';
import { PAOperation } from '../../classes/operation';
import { PAStore } from '../../classes/store';
import { faPlusCircle, faWindowClose, faWindowMaximize, faWindowMinimize } from '@fortawesome/free-solid-svg-icons';
import { TechnicianLivePositionMarker, TourStopoverMapboxMarker } from '../../classes/mapbox-marker';
import { PAPlanningInstructions } from '../../classes/planning-instructions';
import { PAMap } from "../../map/pa-map";
import { PATour } from "../../classes/tour";
import { DynamicComponentService } from "../../../../_services/dynamic-component-service";
import { TechnicianPosition } from "../../classes/technician-live-position";
import { PADataControl } from "../../singletons/pa-data-control";
import { PAFilterControl } from "../../singletons/pa-filter-control";
import { PATourPlannerControl } from "../../singletons/pa-tourplanner-control";
import { PASettingsControl } from "../../singletons/pa-settings-control";
import { TechnicianDateCollection } from "../../classes/technician-date-collection";
import { Subscription } from "rxjs";
import { NgClass, NgIf } from '@angular/common';

import { TourTimelineComponent } from '../../tourplanner-menu/tour-timeline/tour-timeline.component';
import { CommonModule } from "@angular/common";
import { FaIconComponent, FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import * as mapboxgl from 'mapbox-gl';

@Component({
  selector: 'hawk-tour-map',
  templateUrl: './tour-map.component.html',
  styleUrls: ['./tour-map.component.scss', './../../styles/common_styles.scss'],
  standalone: true,
  imports: [NgClass, FaIconComponent, NgIf, TourTimelineComponent, CommonModule, FontAwesomeModule]
})
export class TourMapComponent extends PAMap implements OnInit, OnChanges {

  faWindowClose = faWindowClose

  @Input() mapBoxAccessToken: string
  @Input() technicianDateCollection: TechnicianDateCollection
  @Input() shownTechnicianDates: PATechnicianDate[]
  @Input() shownTours: PATour[]
  @Input() currentSelectedTour: PATour
  @Input() operations: PAOperation[] = []
  @Input() selectedToursUnfittingOperations: PAOperation[] = []
  @Input() tourPlannerExtraOperations: PAOperation[]
  @Input() id: number
  @Input() planningInstructions: PAPlanningInstructions
  @Input() tourPlannerPlanningMode: string
  @Input() technicianLivePosition: TechnicianPosition
  @Input() selectedTourMode: 'base' | 'new' = 'new'
  public shownTourStores: PAStore[] = []

  public previousSelectedTour: PATour = null
  public livePositionMarker: TechnicianLivePositionMarker

  public tourChangeSubscriptionMap = new Map<PATour, Subscription>()

  @ViewChild('select_multiple_stores_container') multipleStoresContainer: ElementRef<HTMLDivElement>;

  Operation = PAOperation

  constructor(
    dynamicComponentService: DynamicComponentService
  ) {
    super(dynamicComponentService)
  }

  async processChanges(changes: SimpleChanges) {

    console.log(`updating ${this.mapContainer?.name}`)

    let update_map = false
    let move_selected_tour_animation_layers_up = false
    let technician_date_collection_changes = changes['technicianDateCollection']
    let operation_changes = changes['operations'] ? new ListsSimpleChange<PAOperation>(changes['operations']) : null
    let tour_planner_extra_operation_changes = changes['tourPlannerExtraOperations'] ? new ListsSimpleChange<PAOperation>(changes['tourPlannerExtraOperations']) : null

    let shown_tour_changes = changes['shownTours'] ? new ListsSimpleChange<PATour>(changes['shownTours']) : null
    let selected_tour_changes = changes['currentSelectedTour']
    let selected_tour_mode_changes = changes['selectedTourMode']
    let selected_tours_unfitting_operation_changes = changes['selectedToursUnfittingOperations'] ? new ListsSimpleChange<PAOperation>(changes['selectedToursUnfittingOperations']) : null
    let live_position_changes = changes['technicianLivePosition']

    let technician_date_changes = changes['shownTechnicianDates'] ? new ListsSimpleChange<PATechnicianDate>(changes['shownTechnicianDates']) : null

    let previous_selected_tour: PATour = this.previousSelectedTour
    let current_selected_tour: PATour = this.currentSelectedTour

    if (selected_tour_changes && selected_tour_changes.currentValue) {
      current_selected_tour = selected_tour_changes.currentValue
      if (selected_tour_changes.previousValue?.technicianDate != selected_tour_changes.currentValue.technicianDate) {
        move_selected_tour_animation_layers_up = true
        update_map = true
      }
    }

    if (technician_date_collection_changes) {
      let previous_technician = technician_date_collection_changes?.previousValue?.technician
      let current_technician = technician_date_collection_changes?.currentValue?.technician
      this.updateMapTechnicianMarker(previous_technician ? [previous_technician] : [], current_technician ? [current_technician] : [])
    }

    if (operation_changes?.listValuesChanged() || selected_tours_unfitting_operation_changes?.listValuesChanged() || tour_planner_extra_operation_changes?.listValuesChanged() || shown_tour_changes?.listValuesChanged() || selected_tour_changes || selected_tour_mode_changes) {
      this.updateOperations(operation_changes, current_selected_tour, current_selected_tour != previous_selected_tour, this.shownTours);
    }

    if (shown_tour_changes?.listValuesChanged()) {

      shown_tour_changes.getRemovedValues().map(tour => {
        tour.technicianDate.getPreviousTechnicianDate().removeTourPlanningDestinationMarkerFromMap()
        tour.technicianDate.removeTourPlanningDestinationMarkerFromMap()
        tour.stopMapTourAnimation(this.mapContainer)
        const subscription = this.tourChangeSubscriptionMap.get(tour)
        if (subscription) {
          subscription.unsubscribe()
          this.tourChangeSubscriptionMap.delete(tour)
        }
      })

      shown_tour_changes.getAddedValues().map(tour => {
        tour.technicianDate.getPreviousTechnicianDate().displayTourPlanningDestinationMarkerOnMap(this.mapContainer.map)
        tour.technicianDate.displayTourPlanningDestinationMarkerOnMap(this.mapContainer.map)
        const subscription = tour.subscribeToChanges(change => {
          tour.initMapTourAnimation(this.mapContainer)
          if (change == 'start_location_changed') {
            tour.technicianDate.getPreviousTechnicianDate().displayTourPlanningDestinationMarkerOnMap(this.mapContainer.map)
          }
          if (change == 'end_location_changed') {
            tour.technicianDate.displayTourPlanningDestinationMarkerOnMap(this.mapContainer.map)
          }
        })
        this.tourChangeSubscriptionMap.set(tour, subscription)
      })
      await this.updateDisplayedRoutes(shown_tour_changes.getAddedValues())
    }

    if (live_position_changes) {
      this.handleLivePositionChanges(live_position_changes);
    }

    if (move_selected_tour_animation_layers_up) {
      current_selected_tour.moveTourAnimationLayersUp(this.mapContainer)
    }

    this.previousSelectedTour = current_selected_tour

    if (technician_date_changes && technician_date_changes.listValuesChanged()) {
      update_map = true
    }

    if (update_map) {
      this.updateMapBounds(1000)
    }
  }

  private handleLivePositionChanges(live_position_changes: SimpleChange) {
    if (live_position_changes.currentValue) {
      if (!this.livePositionMarker) {
        this.livePositionMarker = new TechnicianLivePositionMarker(
          live_position_changes.currentValue,
          this.mapContainer,
          this.dynamicComponentService
        )
        this.livePositionMarker.active = true
      } else {
        this.livePositionMarker.livePosition = live_position_changes.currentValue
        this.livePositionMarker.active = true
      }
    } else {
      if (this.livePositionMarker) {
        this.livePositionMarker.active = false
      }
    }
  }

  private updateOperations(operation_changes: ListsSimpleChange<PAOperation>, selected_tour: PATour, selected_tour_changed: boolean, shown_tours: PATour[]) {
    let current_operations: PAOperation[] = operation_changes ? operation_changes.currentValue : this.operations
    current_operations = [...new Set(current_operations)]

    const shown_tour_operations = (this.shownTours || []).reduce((operations: PAOperation[], tour) => operations.concat(tour.operations), [])
    current_operations = current_operations.concat(shown_tour_operations)

    let current_operation_stores = [...new Set(current_operations.map(operation => operation.getStore()))].filter(store => store && store.shownMapOperations.length > 0)

    let shown_tour_stores = (shown_tours || []).reduce(
      (stores: PAStore[], tour: PATour) => {
        let tmp_stores = stores.concat([...new Set(tour.operations.map(operation => operation.getStore()))])
        if (this.selectedTourMode == 'new') {
          tmp_stores = tmp_stores.concat(tour.technicianDate.changedTourContainer.unfittingOperations.concat(tour.technicianDate.changedTourContainer.deletingOperations).map(op => op.getStore()))
        }
        return tmp_stores
      },
      []
    )

    const shown_tour_stores_before = [...this.shownTourStores]
    this.shownTourStores = [...shown_tour_stores]

    this.mapStoreClusterer.stores = [...new Set(current_operation_stores.concat(shown_tour_stores))].filter(store => store)

    if (selected_tour_changed) {
      this.mapStoreClusterer.technicianDate = selected_tour?.technicianDate
    }

    this.updateMapTourStoreMarker(shown_tour_stores_before, this.shownTourStores, shown_tours, selected_tour)
  }

  ngOnInit(): void {
    this.waitAndInitMap(this.mapBoxAccessToken, this.id)
  }

  async updateMapBounds(wait_ms?: number): Promise<void> {
    if (wait_ms) await PAUtil.sleep(wait_ms)
    let bounds = new mapboxgl.LngLatBounds();

    let bound_tours: PATour[] = this.previousSelectedTour ? [this.previousSelectedTour] : (this.shownTours || []);
    bound_tours.length ? bound_tours.reduce((operations: PAOperation[], tour) => operations.concat(tour.operations), []).map(operation => bounds.extend([operation.ticket.coordinates.longitude, operation.ticket.coordinates.latitude])) : {};
    bound_tours.length ? bound_tours.map(tour => {
      bounds.extend([tour.startLocation.coordinates.longitude, tour.startLocation.coordinates.latitude]);
      bounds.extend([tour.endLocation.coordinates.longitude, tour.endLocation.coordinates.latitude])
    }) : {};
    if (!bounds.isEmpty()) {
      this.mapContainer.map.fitBounds(bounds, {
        padding: {top: 50, bottom: 50, left: 50, right: 50},
        speed: 1,
        maxZoom: 12,
        minZoom: this.mapContainer.map.getZoom()
      })
    }
  }

  public override updateMapTourStoreMarker(marked_stores_before: PAStore[], marked_stores_after: PAStore[], current_tours: PATour[], selected_tour?: PATour): void {

    super.updateMapTourStoreMarker(marked_stores_before, marked_stores_after, current_tours, selected_tour)

    for (let store of marked_stores_after) {
      this.updateStoreMarkerTourDependency(store, current_tours, selected_tour)
    }
  }

  private updateStoreMarkerTourDependency(store: PAStore, tours: PATour[], selected_tour?: PATour): void {
    if (!this.storeMarkerMap.has(store)) {
      this.storeMarkerMap.set(store, new TourStopoverMapboxMarker(tours, store, this.mapContainer, this.dynamicComponentService))
    }
    let marker = this.storeMarkerMap.get(store)
    marker.tourDependencies = tours
    marker.selectedTour = selected_tour || null
  }

  async afterTourSave(): Promise<void> {

    PATourPlannerControl.Instance.tourPlannerExtraOperationCollection = PATourPlannerControl.Instance.tourPlannerExtraOperationCollection.filter(op => PADataControl.Instance.getOperation(op.id).isUnassigned())
    if (PATourPlannerControl.Instance.operationToPlanID && !PADataControl.Instance.getOperation(PATourPlannerControl.Instance.operationToPlanID).isUnassigned()) {
      if (PATourPlannerControl.Instance.tourPlannerExtraOperationCollection.length) {
        PATourPlannerControl.Instance.operationToPlanID = PATourPlannerControl.Instance.tourPlannerExtraOperationCollection.splice(0, 1)[0].id
      } else {
        PATourPlannerControl.Instance.operationToPlanID = null
      }
    }
  }

  protected readonly faWindowMaximize = faWindowMaximize;
  protected readonly faWindowMinimize = faWindowMinimize;
  protected readonly faPlusCircle = faPlusCircle;
  protected readonly setTimeout = setTimeout;
  protected readonly PATourPlannerControl = PATourPlannerControl;
  protected readonly PASettingsControl = PASettingsControl;
  protected readonly PAFilterControl = PAFilterControl;
}