
  <div *ngIf="showStoreModal" id="temporary_operation_modal" class="pa-modal" (mousedown)="closeTemporaryOperationModal($event)">
    <!-- Modal content -->
    <div class="modal-content dark_scrollbar" style="min-width: 820px; background: #ede8e0!important">
      <span id="temporary_operation_modal_close_button" class="close" (click)="closeTemporaryOperationModal($event)">&times;</span>
      <div>
        <h5 style="margin-bottom: 4px;">
          Store suchen 
        </h5>
      </div>
      <div class="horizontal_flex" style="padding: 8px 0;">
        <mat-form-field style="margin-right: 4px; height: 34px;" class="outline-small" appearance="outline">
          <mat-label>Straße</mat-label>
          <input [(ngModel)]="geoCodeStreet" matInput (change)="updateStoresCloseToGeoCodeCity()">
          @if (geoCodeStreet) {
            <button matSuffix mat-icon-button aria-label="Clear" (click)="geoCodeStreet='';updateStoresCloseToGeoCodeCity()">
              <mat-icon>close</mat-icon>
            </button>
          }
        </mat-form-field>
        <mat-form-field style="margin-right: 4px; height: 34px;" class="outline-small" appearance="outline">
          <mat-label>PLZ</mat-label>
          <input [(ngModel)]="geoCodeZipCode" matInput (change)="updateStoresCloseToGeoCodeCity()">
          @if (geoCodeZipCode) {
            <button matSuffix mat-icon-button aria-label="Clear" (click)="geoCodeZipCode='';updateStoresCloseToGeoCodeCity()">
              <mat-icon>close</mat-icon>
            </button>
          }
        </mat-form-field>
        <mat-form-field style="margin-right: 4px; height: 34px;" class="outline-small" appearance="outline">
          <mat-label>Stadt</mat-label>
          <input [(ngModel)]="geoCodeCity" matInput (change)="updateStoresCloseToGeoCodeCity()">
          @if (geoCodeCity) {
            <button matSuffix mat-icon-button aria-label="Clear" (click)="geoCodeCity='';updateStoresCloseToGeoCodeCity()">
              <mat-icon>close</mat-icon>
            </button>
          }
        </mat-form-field>
      </div>

      <hawk-expand-bar-header style="width: 100%; margin-top: 4px;"
        [description]="(shownNewStoreForm ? 'Neuer Store' : selectedTemporaryOperationStore ? 
        'Im Store: ' + selectedTemporaryOperationStore.address_company + ' ' + selectedTemporaryOperationStore.address_city :
        'Store-Auswahl' )"
        [isExpanded]="showTemporaryOperationStoreSelection"
        (toggle)="showTemporaryOperationStoreSelection = $event">
      </hawk-expand-bar-header>
      <div class="toggle_body dark_scrollbar" [ngClass]="showTemporaryOperationStoreSelection && !shownNewStoreForm ? '' : 'hidden'" >
        <div style="border: 1px solid black; position: relative;">

          <div id="store_selection_footer">
            <input class="search" type="search" placeholder="suche Store..." [(ngModel)]="storeSearchString" (search)="updateStoreDataSource()" (keyup)="updateStoreDataSource()">
            <button class="new_store" (click)="addNewStoreForm()">Neuer Store</button>
          </div>

          <table class="mat-elevation-z8" mat-table [dataSource]="storesCloseToGeocodeCityDataSource" matSort (matSortChange)="announceSortChange($event)" style="width: 100%;">
            
            <ng-container matColumnDef="address_company">
              <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by address_company"> Name </th>
              <td mat-cell *matCellDef="let element"> {{element.address_company}} </td>
            </ng-container>
  
            <ng-container matColumnDef="address_street">
              <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by address_street"> Straße </th>
              <td mat-cell *matCellDef="let element"> {{element.address_street}} </td>
            </ng-container>   
            
            <ng-container matColumnDef="address_street_no">
              <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by address_street_no"> Zusatz </th>
              <td mat-cell *matCellDef="let element"> {{element.address_street_no}} </td>
            </ng-container>

            <ng-container matColumnDef="address_zip">
              <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by address_zip"> PLZ </th>
              <td mat-cell *matCellDef="let element"> {{element.address_zip}} </td>
            </ng-container>    
            
            <ng-container matColumnDef="address_city">
              <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by address_city"> Stadt </th>
              <td mat-cell *matCellDef="let element"> {{element.address_city}} </td>
            </ng-container>

            <ng-container matColumnDef="projects">
              <th mat-header-cell *matHeaderCellDef> Projekte </th>
              <td mat-cell *matCellDef="let element"> {{getStoreProjectNames(element)}} </td>
            </ng-container>

            <ng-container matColumnDef="customers">
              <th mat-header-cell *matHeaderCellDef> Kunde </th>
              <td mat-cell *matCellDef="let element"> {{getStoreCustomerNames(element)}} </td>
            </ng-container>
        
            <tr mat-header-row *matHeaderRowDef="displayedStoreColumns"></tr>
            <tr mat-row 
            (click)="changeSelectedStore(row)" 
            [class.demo-row-is-clicked]="" 
            [ngClass]="selectedTemporaryOperationStore?.id == row.id ? 'selected' : ''"
            *matRowDef="let row; columns: displayedStoreColumns;"></tr>
            <tr class="mat-row" *matNoDataRow>
              <td class="mat-cell" [attr.colspan]="displayedStoreColumns.length" style="text-align: center;">
                No data matching the filter.
              </td>
            </tr>
          </table>
        
          <mat-paginator [pageSizeOptions]="[7]"
                         showFirstLastButtons
                         aria-label="Select page">
          </mat-paginator>
        </div>
      </div>

      <div class="toggle_body dark_scrollbar" style="max-height: 800px;" [ngClass]="showTemporaryOperationStoreSelection && shownNewStoreForm ? '' : 'hidden'" >
        <form [formGroup]="storeForm" class="normal-form" (submit)="onStoreSubmit()">
          <h6>
            Kundendaten
          </h6>
          <mat-grid-list cols="2" rowHeight="65px">
            <mat-grid-tile>
              <div class="controles-container">
                <mat-form-field class="outline-small" appearance="outline">
                  <mat-label>Kunde auswählen</mat-label>
                  <mat-select formControlName="customer_id">
                    <input type="search" style="width: 100%" (search)="filterCustomerLetters($event)" (keyup)="filterCustomerLetters($event)" placeholder="Suche Kunden...">
                    <mat-option>None</mat-option>
                    <ng-container *ngFor="let customer_letter of filteredCustomerLetters">
                      <mat-option [value]="customer_letter.id">{{customer_letter.name}}</mat-option>
                    </ng-container>
                  </mat-select>
                  <mat-error>Bitte wählen Sie einen Kunden aus.</mat-error>
                </mat-form-field>
              </div>
            </mat-grid-tile>
            <mat-grid-tile>
              <div class="controles-container" *ngIf="storeForm.controls['customer_id'].value && PADataControl.Instance.getCustomerProjects(storeForm.controls['customer_id'].value).length">
                <mat-form-field class="outline-small" appearance="outline">
                  <mat-label>Projekte</mat-label>
                  <mat-select [disabled]="!storeForm.controls['customer_id'].value" formControlName="project_id" >
                    <mat-option *ngFor="let project of PADataControl.Instance.getCustomerProjects(storeForm.controls['customer_id'].value)" [value]="project.id">{{project.project_name}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </mat-grid-tile>
          </mat-grid-list>
          <mat-expansion-panel [expanded]="true" style="margin: 4px 0;">
            <mat-expansion-panel-header>
              <mat-panel-title> Adresse </mat-panel-title>
              <mat-panel-description>
                {{getAddressDescription()}}
              </mat-panel-description>
            </mat-expansion-panel-header>
            Store-Adresse suchen:
            <mat-grid-list cols="3" rowHeight="40px">
              <mat-grid-tile style="overflow: visible;">
                <div class="controles-container">
                  <div style="border: 1px solid greenyellow; width: fit-content;" id="store_address_geocoder"></div>
                </div>
              </mat-grid-tile>
            </mat-grid-list>
            <br>
            <mat-grid-list cols="2" rowHeight="80px">
              <mat-grid-tile>
                <div class="controles-container">
                  <mat-form-field class="outline-small" appearance="outline" floatLabel="always">
                    <mat-label>Filial-Name</mat-label>
                    <input formControlName="address_name" matInput placeholder="z.B.: JYSK DE2222" [matAutocomplete]="auto">
                    <mat-autocomplete #auto="matAutocomplete">
                      <mat-optgroup [label]="'Mustervorschläge'">
                        <mat-option *ngFor="let name of autocompleteNames | async" [value]="name">
                          {{name}}
                        </mat-option>
                      </mat-optgroup>
                    </mat-autocomplete>
                    <mat-error>Bitte geben Sie einen Namen an</mat-error>
                  </mat-form-field>
                </div>
              </mat-grid-tile>
              <mat-grid-tile>
                <div class="controles-container" style="position: relative;">
                  <mat-form-field class="outline-small" appearance="outline" floatLabel="always">
                    <mat-label>Filial-Nummer</mat-label>
                    <input formControlName="store_nr" matInput placeholder="z.B.: 2222" [disabled]="storeForm.get('store_nr_valid').value != 'pending'" (change)="updateStoreNr()">
                    <mat-error>Bitte geben Sie eine gültige Filialen-Nummer an</mat-error>
                  </mat-form-field>
                  <div style="position: absolute; right: 10px; top: 50%; width: 40px; height: 40px; transform: translate(0px, -50%);">
                    <hawk-throbber *ngIf="storeForm.get('store_nr_valid').value == 'pending'"
                                   [backgroundColor]="'rgba(0, 0, 0, 0)'">
                    </hawk-throbber>
                    <div style="width: 40px; display: inline-flex;" *ngIf="storeForm.get('store_nr_valid').value == true">
                      <fa-icon style="margin: auto; font-size: 1.4rem; color: rgb(28, 173, 28);" [icon]="faCheck"></fa-icon>
                    </div>
                    <div style="width: 40px; display: inline-flex; font-size: 1.2rem;" *ngIf="storeForm.get('store_nr_valid').value == false">
                      <div style="margin: auto;" tooltip="Für diesen Kunden existiert bereits ein Store mit dieser Nummer">
                        &#x274C;
                      </div>
                    </div>
                  </div>
                </div>
              </mat-grid-tile>
            </mat-grid-list>
            <mat-grid-list cols="3" rowHeight="130px">
              <mat-grid-tile>
                <div class="controles-container">
                  <mat-form-field class="outline-small" appearance="outline">
                    <input formControlName="address_street" matInput placeholder="Straße">
                    <mat-error>Keine Straße gefunden</mat-error>
                  </mat-form-field>
                  <mat-form-field class="outline-small" appearance="outline">
                    <input formControlName="address_city" matInput placeholder="Stadt">
                    <mat-error>Keine Stadt gefunden</mat-error>
                  </mat-form-field>
                </div>
              </mat-grid-tile>
              <mat-grid-tile>
                <div class="controles-container">
                  <mat-form-field class="outline-small" appearance="outline">
                    <input formControlName="address_zip" matInput placeholder="Postleitzahl">
                    <mat-error>Keine gültige PLZ</mat-error>
                  </mat-form-field>
                  <mat-form-field class="outline-small" appearance="outline">
                    <input formControlName="address_country" matInput placeholder="Land">
                    <mat-error>Kein Land gefunden</mat-error>
                  </mat-form-field>
                </div>
              </mat-grid-tile>
              <mat-grid-tile>
                <div class="controles-container">
                  <mat-form-field class="outline-small" appearance="outline">
                    <input formControlName="address_state" matInput placeholder="Region/Provinz">
                  </mat-form-field>
                </div>
              </mat-grid-tile>
            </mat-grid-list>
          </mat-expansion-panel>

          <mat-expansion-panel style="margin-bottom: 4px;">
            <mat-expansion-panel-header>
              <mat-panel-title> Koordinaten </mat-panel-title>
              <mat-panel-description>
                <div [style.color]="storeForm.controls['address_latitude'].value && storeForm.controls['address_longitude'].value ? 'green' : 'red'">
                  {{storeForm.controls['address_latitude'].value && storeForm.controls['address_longitude'].value ? 'Gefunden' : 'Nicht gefunden'}}
                </div>
              </mat-panel-description>
            </mat-expansion-panel-header>
            <mat-grid-list cols="2" rowHeight="65px">
              <mat-grid-tile>
                <div class="controles-container">
                  <mat-form-field class="outline-small" appearance="outline">
                    <input formControlName="address_longitude" matInput placeholder="Länge">
                    <mat-error>Keinen Längengrad gefunden</mat-error>
                  </mat-form-field>
                </div>
              </mat-grid-tile>
              <mat-grid-tile>
                <div class="controles-container">
                  <mat-form-field class="outline-small" appearance="outline">
                    <input formControlName="address_latitude" matInput placeholder="Breite">
                    <mat-error>Keinen Breitengrad gefunden</mat-error>
                  </mat-form-field>
                </div>
              </mat-grid-tile>
            </mat-grid-list>
          </mat-expansion-panel>
          <mat-expansion-panel style="margin-bottom: 4px;">
            <mat-expansion-panel-header>
              <mat-panel-title> Kontakt </mat-panel-title>
              <mat-panel-description>
                {{getContactDescription()}}
              </mat-panel-description>
            </mat-expansion-panel-header>
            <mat-grid-list cols="2" rowHeight="130px">
              <mat-grid-tile>
                <div class="controles-container">
                  <mat-form-field class="outline-small" appearance="outline">
                    <input formControlName="firstname" matInput placeholder="Vorname">
                  </mat-form-field>
                  <mat-form-field class="outline-small" appearance="outline">
                    <input formControlName="lastname" matInput placeholder="Nachname">
                  </mat-form-field>
                </div>
              </mat-grid-tile>
              <mat-grid-tile>
                <div class="controles-container">
                  <mat-form-field class="outline-small" appearance="outline">
                    <input formControlName="email" matInput placeholder="Email">
                    <mat-error>Bitte geben Sie eine gültige Email an.</mat-error>
                  </mat-form-field>
                  <mat-form-field class="outline-small" appearance="outline">
                    <input formControlName="phone" matInput placeholder="Telefon">
                    <mat-error>Bitte geben Sie eine gültige Telefonnummer an.</mat-error>
                  </mat-form-field>
                </div>
              </mat-grid-tile>
            </mat-grid-list>
            <mat-grid-list cols="1" rowHeight="75px">
              <mat-grid-tile>
                <div class="controles-container">
                  <mat-form-field class="outline-small" appearance="outline" floatLabel="always">
                    <mat-label>Kommentar/Landkreis hinzufügen</mat-label>
                    <input formControlName="comment" matInput placeholder="z.B. 'Kreisfreie Stadt Kiel' oder 'Kreis Schleswig-Flensburg'">
                  </mat-form-field>
                </div>
              </mat-grid-tile>
            </mat-grid-list>
          </mat-expansion-panel>
          <mat-expansion-panel style="margin-bottom: 4px;">
            <mat-expansion-panel-header>
              <mat-panel-title> Öffnungszeiten </mat-panel-title>
              <mat-panel-description>
                {{getOpeningTimeDescription()}}
              </mat-panel-description>
            </mat-expansion-panel-header>
            <hawk-opening-time-editor [openingTimeTemplates]="openingTimeTemplates" style="margin-bottom: 36px;">

            </hawk-opening-time-editor>
          </mat-expansion-panel>
          <mat-grid-list cols="2" rowHeight="60px" >
            <mat-grid-tile>
              <div class="button-row" style="margin-left: auto; margin-right: 8px;">
                <button mat-raised-button color="warn" type="button" (click)="removeStoreForm()">Abbrechen</button>
              </div>
            </mat-grid-tile>
            <mat-grid-tile>
              <div class="button-row" style="margin-right: auto; margin-left: 8px;">
                <button mat-raised-button color="primary" type="submit" [disabled]="storeForm.invalid" style="font-size: 1rem;">Speichern</button>
              </div>
            </mat-grid-tile>
          </mat-grid-list>
        </form>
      </div>
      <div *ngIf="selectedTemporaryOperationStore && !shownNewStoreForm" style="width: 100%; padding: 2px 0;">
        <mat-expansion-panel [expanded]="!showNewTicketForm" style="margin-bottom: 4px;">
          <mat-expansion-panel-header>
            <mat-panel-title> Store-Daten </mat-panel-title>
            <mat-panel-description>
              Kunde / Projekte / Adresse / Kontakt / Öffnungzeiten
            </mat-panel-description>
          </mat-expansion-panel-header>
          <hawk-store-data
                  [store]="selectedTemporaryOperationStore"
                  [customerLetters]="getCustomerLetters()"
                  [mapBoxAccessToken]="mapBoxAccessToken"
                  [mapContainer]="mapContainer"
          >
          </hawk-store-data>
        </mat-expansion-panel>
        <mat-expansion-panel [expanded]="!showNewTicketForm" style="margin-bottom: 4px;">
          <mat-expansion-panel-header>
            <mat-panel-title> Store-Funktionen </mat-panel-title>
            <mat-panel-description>
              Tickets anlegen / Projekte hinzufügen / Kopien anlegen
            </mat-panel-description>
          </mat-expansion-panel-header>
          <div style="display: inline-flex; flex-direction: row; width: 100%;">
            <div style="margin-right: 20px;" class="store_action_button black_gradient_background" [ngClass]="showNewTicketForm ? 'selected' : ''" (click)="clickAddTicket()">
              <h6>
                Neues Ticket
              </h6>
              Legt ein neues Ticket in diesem Store an
            </div>
            <div style="margin-right: 20px;" class="store_action_button black_gradient_background" [ngClass]="showAddProjectForm ? 'selected' : ''" (click)="clickAddProject()">
              <h6>
                Zum Projekt hinzufügen
              </h6>
              Verknüpft diesen Store mit einem aktiven Projekt
            </div>
            <div class="store_action_button black_gradient_background" (click)="addNewStoreForm(selectedTemporaryOperationStore)">
              <h6>
                Store Kopie anderer Kunde
              </h6>
              Erstellt eine Kopie des Stores für einen neuen Kunden
            </div>
          </div>
        </mat-expansion-panel>
        <div class="toggle_body dark_scrollbar" [ngClass]="showAddProjectForm ? '' : 'hidden'" style="max-height: 400px;">
          <form [formGroup]="addProjectForm" (submit)="onAddProjectSubmit()" style="padding: 10px; border: 1px solid black; border-radius: 4px;">
            <h6>
              Projekt hinzufügen
            </h6>
            <mat-grid-list cols="2" rowHeight="80px" >
              <mat-grid-tile>
                <div class="controles-container">
                  <mat-form-field class="outline-small" appearance="outline">
                    <mat-label>Projekt auswählen</mat-label>
                    <mat-select formControlName="project_id">
                      <input type="search" style="width: 100%;" (search)="filterAddStoreProject($event)" (keyup)="filterAddStoreProject($event)" placeholder="Suche Projekt...">
                      <mat-option>None</mat-option>
                      <ng-container *ngFor="let project of filteredAddStoreProjects">
                        <mat-option [value]="project.id">{{project.project_name}}</mat-option>
                      </ng-container>
                    </mat-select>
                    <mat-error>Bitte wählen Sie ein Projekt aus.</mat-error>
                  </mat-form-field>
                </div>
              </mat-grid-tile>
              <mat-grid-tile></mat-grid-tile>
            </mat-grid-list>
            <mat-grid-list cols="2" rowHeight="60px" >
              <mat-grid-tile>
                <div class="button-row" style="margin-left: auto; margin-right: 8px;">
                  <button mat-raised-button color="warn" type="button" (click)="showAddProjectForm = false">Abbrechen</button>
                </div>
              </mat-grid-tile>
              <mat-grid-tile>
                <div class="button-row" style="margin-right: auto; margin-left: 8px;">
                  <button mat-raised-button color="primary" type="submit" [disabled]="addProjectForm.invalid" style="font-size: 1rem;">Speichern</button>
                </div>
              </mat-grid-tile>
            </mat-grid-list>
          </form>
        </div>
        <div class="toggle_body dark_scrollbar" [ngClass]="showNewTicketForm ? '' : 'hidden'" style="max-height: 1000px;">
          <form [formGroup]="ticketForm" (submit)="onTicketSubmit()" style="padding: 10px; border: 1px solid black; border-radius: 4px; background: #3c4b83;">
            <h6 style="color: white;">
              Neues Ticket anlegen
            </h6>
            <mat-expansion-panel [expanded]="true" style="margin-bottom: 4px;">
              <mat-expansion-panel-header>
                <mat-panel-title> Ticket-Daten </mat-panel-title>
                <mat-panel-description>
                  {{getTicketDataDescription()}}
                </mat-panel-description>
              </mat-expansion-panel-header>
              <mat-grid-list cols="2" rowHeight="180px" >
                <mat-grid-tile>
                  <div class="controles-container">
                    <mat-form-field class="outline-small" appearance="outline">
                      <mat-label>Projekt auswählen</mat-label>
                      <mat-select formControlName="project_id" (selectionChange)="resetTicketFormPriorityAndStatus()">
                        <input type="search" style="width: 100%;" (search)="filterTemporaryOperationProject($event)" (keyup)="filterTemporaryOperationProject($event)" placeholder="Suche Projekt...">
                        <mat-option>None</mat-option>
                        <ng-container *ngFor="let project of filteredTemporaryOperationProjects">
                          <mat-option [value]="project.id">{{project.project_name}}</mat-option>
                        </ng-container>
                      </mat-select>
                      <mat-error>Bitte wählen Sie ein Projekt aus.</mat-error>
                    </mat-form-field>
                    <mat-form-field class="outline-small" appearance="outline">
                      <mat-label>Priorität auswählen</mat-label>
                      <mat-select formControlName="priority_id" [disabled]="!ticketForm.get('project_id').value">
                        <mat-option>None</mat-option>
                        <ng-container *ngFor="let priority of PADataControl.Instance.getProject(ticketForm.get('project_id').value)?.priorities">
                          <mat-option [value]="priority.id">{{priority.name}}</mat-option>
                        </ng-container>
                      </mat-select>
                      <mat-error>Bitte wählen Sie eine Priorität aus.</mat-error>
                    </mat-form-field>
                    <mat-form-field class="outline-small" appearance="outline">
                      <mat-label>Status auswählen</mat-label>
                      <mat-select formControlName="status_id" [disabled]="!ticketForm.get('project_id').value">
                        <mat-option>None</mat-option>
                        <ng-container *ngFor="let status of PADataControl.Instance.getProject(ticketForm.get('project_id').value)?.status">
                          <mat-option [value]="status.id">{{status.name}}</mat-option>
                        </ng-container>
                      </mat-select>
                      <mat-error>Bitte wählen Sie einen Status aus.</mat-error>
                    </mat-form-field>
                  </div>
                </mat-grid-tile>
                <mat-grid-tile>
                  <div class="controles-container">
                    <mat-form-field class="outline-small" appearance="outline">
                      <input formControlName="time_estimation" matInput type="number" placeholder="Auftragszeit (in Minuten)">
                      <mat-error>Bitte schätzen Sie die benötigte Auftragszeit.</mat-error>
                    </mat-form-field>
                    <mat-form-field class="outline-small" appearance="outline">
                      <input formControlName="external_order_nr" matInput placeholder="Externe Auftragsnummer?">
                    </mat-form-field>
                    <div style="position: relative;">
                      <mat-form-field class="outline-small" appearance="outline" style="width: 100%;">
                        <input formControlName="sla_date" matInput type="datetime-local" placeholder="SLA-Datum?" style="cursor: pointer;" (change)="updateSLAHolidayWarning()">
                        @if (ticketForm.get('sla_date').value) {
                          <button matSuffix mat-icon-button aria-label="Clear" (click)="ticketForm.controls['sla_date'].setValue(''); updateSLAHolidayWarning()">
                            <mat-icon>close</mat-icon>
                          </button>
                        }
                      </mat-form-field>
                      <div *ngIf="slaHolidayWarning" [style.color]="slaHolidayWarning.type == 'public' ? 'red' : 'orange'" style="position: absolute; bottom: -6px; left: 0;" class="horizontal_flex">
                        <fa-icon style="margin: auto 2px auto auto;" [icon]="faExclamationTriangle"></fa-icon>
                        <div style="margin: auto auto auto 2px;">
                          {{slaHolidayWarning.type == 'public' ? 'Bundesweiter Feiertag:' : 'Regionaler Feiertag:'}}
                          {{slaHolidayWarning.name}}
                        </div>
                      </div>
                    </div>
                  </div>
                </mat-grid-tile>
              </mat-grid-list>
            </mat-expansion-panel>

            <mat-expansion-panel [expanded]="true" style="margin-bottom: 4px;">
              <mat-expansion-panel-header>
                <mat-panel-title> Beschreibung </mat-panel-title>
                <mat-panel-description>
                  {{getTicketDescriptionDescription()}}
                </mat-panel-description>
              </mat-expansion-panel-header>
              <mat-grid-list cols="1" rowHeight="140px">
                <mat-grid-tile class="normal_alignment">
                  <div class="controles-container" style="border: 1px solid rgba(0, 0, 0, 0.295); border-radius: 4px;">
                    <mat-form-field class="outline-small" appearance="outline">
                      <textarea spellcheck="false" formControlName="description" matInput placeholder="Ticket-Beschreibung hinzufügen..."
                                cdkTextareaAutosize
                                #autosize="cdkTextareaAutosize"
                                cdkAutosizeMinRows="1"
                                cdkAutosizeMaxRows="5">
                      </textarea>
                      <mat-error>Bitte erstellen Sie eine kurze Beschreibung für dieses Ticket.</mat-error>
                    </mat-form-field>
                  </div>
                </mat-grid-tile>
              </mat-grid-list>
            </mat-expansion-panel>

            <mat-expansion-panel [expanded]="false" style="margin-bottom: 4px;">
              <mat-expansion-panel-header>
                <mat-panel-title> Material </mat-panel-title>
                <mat-panel-description>
                  {{getTicketMaterialDescription()}}
                </mat-panel-description>
              </mat-expansion-panel-header>
              <hawk-material-selection [materialContainer]="ticketMaterialContainer"
                                       [showHeader]="false"
                                       [forProjectID]="ticketForm.get('project_id').value"
              >
              </hawk-material-selection>
            </mat-expansion-panel>

            <mat-expansion-panel [expanded]="false" style="margin-bottom: 4px;">
              <mat-expansion-panel-header>
                <mat-panel-title> ASP im Standort </mat-panel-title>
                <mat-panel-description>
                  {{getTicketContactDescription()}}
                </mat-panel-description>
              </mat-expansion-panel-header>
              <mat-grid-list cols="3" rowHeight="100px" >
                <mat-grid-tile>
                  <div class="controles-container">
                    <mat-form-field class="outline-small" appearance="outline">
                      <input formControlName="contact_name" matInput placeholder="Name">
                    </mat-form-field>
                  </div>
                </mat-grid-tile>
                <mat-grid-tile>
                  <div class="controles-container">
                    <mat-form-field class="outline-small" appearance="outline">
                      <input formControlName="contact_phone" matInput placeholder="Telefon">
                      <mat-error>Bitte geben Sie eine gültige Telefonnummer an.</mat-error>
                    </mat-form-field>
                  </div>
                </mat-grid-tile>
                <mat-grid-tile>
                  <div class="controles-container">
                    <mat-form-field class="outline-small" appearance="outline">
                      <input formControlName="contact_email" matInput placeholder="Email">
                      <mat-error>Bitte geben Sie eine gültige Email an.</mat-error>
                    </mat-form-field>
                  </div>
                </mat-grid-tile>
              </mat-grid-list>
            </mat-expansion-panel>

            <mat-grid-list cols="3" rowHeight="60px" >
              <mat-grid-tile>
                <div class="button-row" style="margin-left: auto; margin-right: 8px;">
                  <button mat-raised-button color="warn" type='button' (click)="showNewTicketForm = false">Abbrechen</button>
                </div>
              </mat-grid-tile>
              <mat-grid-tile>
                <div class="button-row" style="margin-right: auto; margin-left: 8px;">
                  <button mat-raised-button color="primary" type="submit" [disabled]="ticketForm.invalid">Speichern</button>
                </div>
              </mat-grid-tile>
              <mat-grid-tile>
                <div style="margin: auto; padding: 4px 8px; font-style: italic; font-size: .9rem; cursor: pointer; border-radius: 4px; background: whitesmoke; border: 1px solid black;" (click)="saveTicketInDB = !saveTicketInDB">
                  <mat-checkbox class="black-label" [(ngModel)]="saveTicketInDB" [ngModelOptions]="{standalone: true}" (click)="$event.stopPropagation()">Ticket in Datenbank speichern</mat-checkbox>
                </div>
              </mat-grid-tile>
            </mat-grid-list>
          </form>
        </div>
      </div>
    </div>
  </div>

  <div style="display: inline-flex; flex-direction: column; flex-wrap: nowrap; position: absolute; top: 0; bottom: 0; left: 0; right: 0;">
    <div style="flex: 1;">
      <div id="tour_map_window_0" class="map_window" [ngClass]="PASettingsControl.Instance.selectedSettingConfig.data.general.map.show_operation_times ? 'show_time_info' : ''">
        <div #select_multiple_stores_container class="select_multiple_stores_container" style="display: none">
          <fa-icon style="position: absolute; top: 0; left: 4px; color: green" [icon]="faPlusCircle"></fa-icon>
        </div>
      </div>
    </div>
  </div>
  

  <div *ngIf="updating" style="position: absolute; z-index: 2; width: 100%; height: 100%;">
    <hawk-throbber
          [description]="'Lade Daten...'">
    </hawk-throbber>
  </div>
    

