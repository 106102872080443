import { Component, inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ColDef, RowClassRules } from 'ag-grid-enterprise';
import { AG_GRID_LOCALE_DE } from 'src/app/_helpers/aggrid.locale.de';
import { DeliveryNote } from 'src/app/_models';
import { DeliveryNoteService } from 'src/app/_services';
import { environment } from 'src/environments/environment';
import { AgGridGenericLinkRenderer } from '../_shared/ag-grid-renderer/ag-grid-generic-link.component';
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { AgGridAngular } from 'ag-grid-angular';
import { AgGridThemeService } from 'src/app/_services/ag-grid-theme.service';

@Component({
  imports: [CommonModule, RouterModule, AgGridAngular],
    selector: 'hawk-retoure-show',
    templateUrl: './retoure-show.component.html',
    styleUrls: ['./retoure-show.component.scss'],
})
export class RetoureShowComponent implements OnInit {
  constructor(
    private deliveryNoteService: DeliveryNoteService,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  public hawkTheme = inject(AgGridThemeService).getTheme();
  public deliveryNoteId: number | String | string;
  public deliveryNote: DeliveryNote;
  public articles: any[];
  env = environment;

  public locale = AG_GRID_LOCALE_DE;

  public rowSelection: "single" | "multiple" = "multiple"
  public rowClassRules: RowClassRules = {
    // if tech on site, color row
    'tech-on-site': (params) => {
      if (params?.data === undefined) return "...";
      return params.data.tech_on_site
    },
  }

  public defaultColDef: ColDef = {
    filter: true,
    flex: 1
  }

  public colDefs: ColDef[] = [
    {
      field: "rma",
      headerName: 'RMA',
      cellRenderer: AgGridGenericLinkRenderer,
      cellRendererParams: {
        routerLink: ['/', 'artikel', '@id'],
        labelFromData: true,
        dataKey: 'rma'
      }
    },
    { 
      field: "bad_part",
      headerName: 'BadPart?',
    },
    { 
      field: "description",
      headerName: 'Bezeichnung', 
    },
    { 
      field: "serial_number",
      headerName: 'Seriennummer', 
    },
    { 
      field: "product_category", 
      headerName: 'Produkt' 
    },
    { field: "vendor", headerName: 'Hersteller' }
  ]

  ngOnInit(): void {
    this.deliveryNoteId = parseInt(this.route.snapshot.paramMap.get('id'));
    this.loadDeliveryNote();
    this.loadArticles();
  }

  private loadArticles() {
    this.deliveryNoteService.articles(this.deliveryNoteId).subscribe(
      {
        next: (response: any) => {
          this.articles = response;
        },
        error: (error) => {
          console.log('error', error);
        }
      }
    )
  }

  public createRetoure() {
    this.deliveryNoteService.createRetoure(this.deliveryNoteId).subscribe(
      {
        next: (response: any) => {
          this.router.navigateByUrl(`/retoure/${response.id}`);
        },
        error: (error) => {
          console.log('error', error);
        }
      }
    )
  }

  private loadDeliveryNote() {
    this.deliveryNoteService.show(this.deliveryNoteId).subscribe(
      {
        next: (response: DeliveryNote) => {
          this.deliveryNote = response;
        },
        error: (error) => {
          console.log('error', error);
        }
      }
    )
  }

  public onDelete() {
    if(this.deliveryNote.status != 'PREPARED') return;

    if(confirm('Möchten Sie das Lager wirklich löschen?')) {
      this.deliveryNoteService.delete(this.deliveryNoteId).subscribe(
        {
          next: () => {
            this.router.navigateByUrl('/lieferscheine/suche/index');
          },
          error: (error) => {
            console.log('errors', error);
          }
        }
      )
    }
  }

}