import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormsModule, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Article, ArticleTemplate, Customer, StorageBin, Warehouse } from 'src/app/_models';
import { ArticleService, CustomerService, SupplierService, WarehouseService } from 'src/app/_services';
import { CommonModule } from "@angular/common";
import { ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { NgSelectModule } from "@ng-select/ng-select";
import { MatDatepickerModule, MatDatepickerToggle } from '@angular/material/datepicker';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MatNativeDateModule } from '@angular/material/core';

@Component({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    MatDatepickerModule,
    MatDatepickerToggle,
    MatCheckboxModule,
    MatRadioModule,
    NgSelectModule,
    FontAwesomeModule,
    MatNativeDateModule,
    NgbModule
  ],
    selector: 'hawk-article-form',
    templateUrl: './article-form.component.html',
    styleUrls: ['./article-form.component.scss'],
})
export class ArticleFormComponent implements OnInit {
  constructor(
    private warehouseService: WarehouseService,
    private customerService: CustomerService,
    private articleService: ArticleService,
    private supplierService: SupplierService,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  public formLoaded: boolean = false;
  public suppliers: any[] = [];
  public loadingSuppliers: boolean = false;
  supplierPage: number = 1;
  supplierTotalPages: number;
  public warehouses: Warehouse[] = [];
  public loadingWarehouses: boolean = false;
  warehousePage: number = 1;
  warehouseTotalPages: number;
  public bins: StorageBin[] = [];
  public loadingBins: boolean = false;
  binPage: number = 1;
  binTotalPages: number;
  public customers: Customer[] = [];
  public loadingCustomers: boolean = false;
  customerPage: number = 1;
  customerTotalPages: number;
  public templates: ArticleTemplate[] = [];
  public loadingTemplates: boolean = false;
  templatePage: number = 1;
  templateTotalPages: number;
  public articleId: number | String | string;
  public copyArticleId: number | String | string;
  public loadingPricing: boolean = false;
  public submittingArticle: boolean = false;
  public searchTerm: string = ''
  //angel_de, asendia, cargo_international, dhl, dhl_express, dpag, dpd, gls, go, hermes, iloxx, parcel_one, ups
  public statusesList = [
    {
      id: 'NEW', name: 'Neu'
    },
    {
      id: 'USED_TESTED', name: 'Gebraucht und getestet'
    },
    {
      id: 'USED_UNTESTED', name: 'Gebraucht und NICHT getestet'
    }
    ];

  public articleForm: FormGroup;

  public createOrUpdateArticle() {
    const formData = this.articleForm.value;
    this.submittingArticle = true;
    const action = this.articleId ? this.articleService.update(this.articleId, { article: formData }) : this.articleService.create({ article: formData });
    action.subscribe(
      {
        next: (response: any) => {
          this.router.navigate(['/', 'artikel', response.id]);
        },
        error: (error) => {
          console.log('error', error);
          alert(`Please fix errors: ${error}`);
          this.submittingArticle = false;
        }
      }
    )
  }

  public fetchMoreCustomers() {
    if(this.customerPage < this.customerTotalPages) {
      this.customerPage += 1;
      this.loadCustomers();
    }
  }

 public fetchMoreBins() {
    if(this.binPage < this.binTotalPages) {
      this.binPage += 1;
      this.loadBins();
    }
  }

  public fetchMoreSuppliers() {
    if(this.supplierPage < this.supplierTotalPages) {
      this.supplierPage += 1;
      this.loadSuppliers();
    }
  }

  loadSuppliers(id = null): Promise<void> {
    this.loadingSuppliers = true;
    return new Promise((resolve, reject) => {
      this.supplierService.all({
        page: this.supplierPage,
        per_page: 9999,
        ...(id && { id: id })
      }).subscribe({
        next: (response: any) => {
          this.suppliers = this.suppliers.concat(response.suppliers);
          this.supplierTotalPages = response.total_pages;
          this.loadingSuppliers = false;
          resolve();
        },
        error: (error) => {
          console.log('error', error);
          this.loadingSuppliers = false;
          reject(error);
        }
      })
    })
  }

  loadBins(id = null, not_full = true): Promise<void> {
    const warehouseId = this.articleForm.controls.warehouse_id.value;
    if(!warehouseId || parseInt(warehouseId) <= 0) return;

    this.loadingBins = true;
    return new Promise((resolve, reject) => {
      this.warehouseService.loadStorageBins(
        warehouseId,
        {
          page: this.binPage,
          per_page: 9999,
          ...(not_full && { not_full }),
          ...(id && { bin_id: id })
        }
      ).subscribe({
        next: (response: any) => {
          this.bins = this.bins.concat(response.bins);
          this.binTotalPages = response.total_pages;
          this.loadingBins = false;
          resolve();
        },
        error: (error) => {
          console.log('error', error);
          this.loadingBins = false;
          reject(error);
        }
      })
    })
  }

  loadWarehouses(): Promise<void> {
    this.loadingWarehouses = true;
    return new Promise((resolve, reject) => {
      this.warehouseService.loadWarehouses({
        per_page: 2000,
      }).subscribe(
        {
          next: (response: any) => {
            this.warehouses = response;
            this.loadingWarehouses = false;
            resolve();
          },
          error: (error) => {
            this.loadingWarehouses = false;
            console.log('error', error);
            reject(error);
          }
        }
      )
    })
  }

  loadCustomers(id = null): Promise<void> {
    this.loadingCustomers = true;
    return new Promise((resolve, reject) => {
      this.customerService.loadCustomers(
        {
          address_detail: true,
          page: this.customerPage,
          per_page: 9999,
          ...(id && { id: id })
        }
      ).subscribe(
        {
          next: (response: any) => {
            this.customers = this.customers.concat(response.customers);
            this.customerTotalPages = response.total_pages;
            this.loadingCustomers = false;
            resolve();
          }, error: (error) => {
            console.log('error', error);
            this.loadingCustomers = false;
            reject(error);
          }
        }
      )
    });
  }

  loadTemplates(): Promise<void> {
    this.loadingTemplates = true;
    return new Promise((resolve, reject) => {
      this.articleService.loadArticleTemplates(
        {
          end_of_life: false,
          per_page: 2000
        }
      ).subscribe(
        {
          next: (response: any) => {
            this.templates = response;
            this.loadingTemplates = false;
            resolve();
          },
          error: (error) => {
            console.log('error', error);
            this.loadingTemplates = false;
            reject(error);
          }
        }
      )
    })
  }

  loadArticle(id: number): Promise<Article> {
    return new Promise((resolve, reject) => {
      this.articleService.getArticle(id).subscribe({
        next: (response: Article) => {
          resolve(response);
        },
        error: (error) => {
          reject(error);
        }
      })
    });
  }

  initForm() {
    this.articleForm = new FormGroup({
      template_id: new FormControl(Validators.required),
      serial_number: new FormControl(),
      status: new FormControl('NEW', Validators.required),
      owner: new FormControl('BENTOMAX', Validators.required),
      owner_customer_id: new FormControl(),
      warehouse_id: new FormControl(Validators.required),
      bin_id: new FormControl(Validators.required),
      supplier: new FormControl('other'),
      supplier_id: new FormControl(),
      supplier_other: new FormControl(),
      ext_delivery_note_number: new FormControl(),
      purchase_date: new FormControl(),
      delivery_date: new FormControl(new Date(), Validators.required),
      is_full: new FormControl()
    });

    this.articleForm.controls.warehouse_id.valueChanges.subscribe((x) => {
      // Let's ignore it for the first time load, manage manually.
      if(!this.formLoaded) return;

      this.bins = [];
      this.articleForm.controls.bin_id.setValue(null);
      if(!x) {
        this.articleForm.controls.bin_id.disable()
        return;
      }

      this.articleForm.controls.bin_id.enable()
      this.loadBins();
    });
  }

  initValues(article = null, loadDefault = true) {
    if(article) {
      this.articleForm.controls.template_id.setValue(article.template_id);
      this.articleForm.controls.serial_number.setValue(article.serial_number);
      this.articleForm.controls.status.setValue(article.status);
      this.articleForm.controls.owner.setValue(article.owner);
      this.articleForm.controls.owner_customer_id.setValue(article.owner_customer_id);
      this.articleForm.controls.warehouse_id.setValue(article.warehouse_id);
      this.articleForm.controls.bin_id.setValue(article.bin_id);
      this.articleForm.controls.supplier_id.setValue(article.supplier_id);
      this.articleForm.controls.supplier.setValue(article.supplier_id ? 'supplier' : 'other');
      this.articleForm.controls.supplier_other.setValue(article.supplier_other);
      this.articleForm.controls.ext_delivery_note_number.setValue(article.ext_delivery_note_number);
      this.articleForm.controls.purchase_date.setValue(article.purchase_date);
      this.articleForm.controls.delivery_date.setValue(article.delivery_date);
    }

    Promise.all(
      [
        this.loadTemplates(),
        this.loadCustomers(article?.owner_customer_id),
        this.loadWarehouses(),
        this.loadSuppliers(article?.supplier_id)
      ]
    ).then(() => {
      if(loadDefault) {
        this.articleForm.controls.template_id.setValue(null);
        this.articleForm.controls.warehouse_id.setValue(this.warehouses[0]?.id);
        this.articleForm.controls.supplier_id.setValue(this.suppliers[0]?.id);
      }
      this.formLoaded = true;
      console.log("ARTIKEL", article)
      const warehouseId = this.articleForm.controls.warehouse_id.value;
      if(warehouseId && parseInt(warehouseId) > 0) {
        this.loadBins(article?.bin_id, article?.bin_id ? null : true)
        .then(() => {
          this.articleForm.controls.bin_id.setValue(article?.bin_id || this.bins[0]?.id);
        })
        .catch((error) => {
          console.log('could not loadded bins', error);
        });
      }
    })
    .catch((error) => {
      console.log('error', error);
      alert(`errors: ${error}`);
    })
  }

  ngOnInit(): void {
    this.articleId = parseInt(this.route.snapshot.paramMap.get('id'));
    this.copyArticleId = parseInt(this.route.snapshot.paramMap.get('old_article_id'));
    this.initForm();
    console.log('this.articleId', this.articleId);
    console.log('this.copyArticleId', this.copyArticleId);
    if((this.articleId || this.copyArticleId)) {
      this.loadArticle(this.articleId as number || this.copyArticleId as number)
      .then((article) => {
        this.initValues(article, false);
      })
      .catch((error) => {
        console.log('error', error);
        alert(`errors: ${error}`);
      })
    } else {
      this.initValues();
    }
  }

}