<div class="timeline_container" *ngIf="technicianDates" style="width: 100%; min-height: 60px; display: inline-flex; position: relative; overflow: visible;">
  <div style="display: inline-flex; flex-direction: column; padding: 4px 8px; width: 100%; overflow: visible;">
    <div style="display: inline-flex; flex-direction: row; margin-bottom: 8px; flex-wrap: wrap; font-size: .7rem;">
      <div *ngIf="showHeader" style="flex: 1; margin-top: auto; margin-bottom: auto; display: inline-flex; flex-direction: row;">
        <div style="margin-top: auto; margin-bottom: auto;">
          <b style="white-space: nowrap;">{{technicianDateCollection.technician.getFullName(20)}} </b>
        </div>
        <div style="margin: auto auto auto 4px; width: 16px; height: 16px; border-radius: 8px; border: 1px solid black; " [style.background-color]="technicianDateCollection.technician.getColorString()"></div>
      </div>
      <ng-container>
        <!--<div style="display: inline-flex; flex-direction: row; width: fit-content;" class="config_button">
          <div style="color: white; padding: 3px 4px; white-space: nowrap; display: inline-flex;" class="black_gradient_background" (click)="toggleLunchBreakCalculation()">
            <div style="margin: auto;">
              Pause: {{technicianDates.automaticLunchBreakCalculation ? 'Automatisch' : 'Nach Auftrag'}}
            </div>
          </div>
          <input *ngIf="!technicianDates.automaticLunchBreakCalculation" style="margin: auto; width: 40px;" type="number" [(ngModel)]="technicianDates.lunchBreakAfterOperationNumber" min="1" (ngModelChange)="technicianDates.updateNewTourOperations()">
        </div>-->
        <!--<div *ngIf="technicianDates" style="display: inline-flex; flex-direction: row; width: fit-content;" class="config_button black_gradient_background_no_hover">
          <div style="color: white; margin: auto; padding: 3px 4px; white-space: nowrap;">
            Max. Tour-Zeit (Minuten):
          </div>
          <input style="margin: auto; width: 45px;" type="number" [(ngModel)]="technicianDates.changedTourContainer.max_tour_minutes" min="1" (ngModelChange)="technicianDates.updateNewTourOperations()">
        </div>-->
        <div (click)="chainOperations = !chainOperations" style="padding: 2px 2px 2px 10px; display: inline-flex;" class="black_gradient_background config_button">
          <ng-container *ngIf="chainOperations">
            <fa-icon [icon]="faLink" style="font-size: .7rem; margin: auto;"></fa-icon>
            <sup style="margin: auto;" [tooltip]="'Ausgewählt: Alle Aufträge werden zusammen verschoben, wenn eine Auftragszeit im Inputfeld oder per Drag&Drop verschoben wird.'">&#9432;</sup>
          </ng-container>
          <ng-container *ngIf="!chainOperations">
            <fa-icon [icon]="faUnlink" style="font-size: .7rem; margin: auto;"></fa-icon>
            <sup style="margin: auto;" [tooltip]="'Ausgewählt: Nur ausgewählte Aufträge werden zusammen verschoben, wenn eine Auftragszeit im Inputfeld oder per Drag&Drop verschoben wird (halten Sie STRG gedrückt, um mehrere Aufträge auszuwählen).'">&#9432;</sup>
          </ng-container>
        </div>
      </ng-container>
    </div>
    <div class="vertical_flex" style="padding-bottom: 44px;">
      <ng-container *ngFor="let technician_date of technicianDates">
        <!--<hawk-timeline *ngIf="technicianDates" class="horizontal_flex"
                       [tour]="technicianDates.tour"
                       [tourIsUpdating]="technicianDates.tour.updating"
                       [label]="technicianDates.isPastTechnicianDate() ? '' : 'vorher'"
                       [showHours]="technicianDates.isPastTechnicianDate()"
                       [showTimeMarker]="false"
                       [showStatistics]="true"
                       [timeFilter]="timeFilter"
                       [chainOperations]="false"
                       [showOperationIndexes]="true"
                       [externalWindow]="externalWindow"
                       [maxTourTime]="technicianDates.changedTourContainer.max_tour_minutes"
                       (selectTourEvent)="selectOldTour()">
        </hawk-timeline>-->
        <hawk-timeline *ngIf="technician_date.isPastTechnicianDate()" class="horizontal_flex"
                       [tour]="technician_date.tour"
                       [tourIsUpdating]="technician_date.tour.updating"
                       [showLabel]="true"
                       [editable]="false"
                       [showHours]="technicianDates.indexOf(technician_date) == technicianDates.length - 1"
                       [showStatistics]="true"
                       [showWarnings]="true"
                       [showTimeMarker]="false"
                       [addBottomPaddingPxAmount]="technicianDates.indexOf(technician_date) == technicianDates.length - 1 ? 0 : 4"
                       [timeFilter]="timeFilter"
                       [showOperationIndexes]="true"
                       [chainOperations]="chainOperations"
                       [externalWindow]="externalWindow"
                       [unfittingOperations]="technician_date.changedTourContainer.unfittingOperations"
                       [maxTourTime]="technician_date.changedTourContainer.max_tour_minutes"
                       [mapContainer]="mapContainer"
                       [height]="22"
                       [selected]="technicianDateCollection?.selectedTour?.technicianDate.day.utc_timestamp == technician_date.day.utc_timestamp"
                       (selectTourEvent)="technicianDateCollection.selectTourWithDayTimestamp(technician_date.day.utc_timestamp)"
                       (removeOperationsEvent)="technician_date.removeOperationsFromNewTour($event)"
                       (updateNewTourEvent)="technician_date.updateNewTourOperations({insert_operations: getConstrainedInsertOperationsForTechnicianDate(technician_date)})">
        </hawk-timeline>
        <hawk-timeline *ngIf="technician_date.changedTourContainer.tour && !technician_date.isPastTechnicianDate()" class="horizontal_flex"
                       [tour]="technician_date.changedTourContainer.tour"
                       [compareTour]="tourHasChanged(technician_date) ? technician_date.tour : null"
                       [tourIsUpdating]="technician_date.changedTourContainer.tour.updating"
                       [showLabel]="true"
                       [editable]="true"
                       [showHours]="technicianDates.indexOf(technician_date) == technicianDates.length - 1"
                       [showStatistics]="true"
                       [showTimeMarker]="true"
                       [showWarnings]="true"
                       [addBottomPaddingPxAmount]="technicianDates.indexOf(technician_date) == technicianDates.length - 1 ? 0 : 4"
                       [timeFilter]="timeFilter"
                       [showOperationIndexes]="true"
                       [chainOperations]="chainOperations"
                       [externalWindow]="externalWindow"
                       [unfittingOperations]="technician_date.changedTourContainer.unfittingOperations"
                       [maxTourTime]="technician_date.changedTourContainer.max_tour_minutes"
                       [mapContainer]="mapContainer"
                       [height]="22"
                       [selected]="technicianDateCollection?.selectedTour?.technicianDate.day.utc_timestamp == technician_date.day.utc_timestamp"
                       (selectTourEvent)="technicianDateCollection.selectTourWithDayTimestamp(technician_date.day.utc_timestamp)"
                       (removeOperationsEvent)="technician_date.removeOperationsFromNewTour($event)"
                       (updateNewTourEvent)="technician_date.updateNewTourOperations({insert_operations: getConstrainedInsertOperationsForTechnicianDate(technician_date)})">
        </hawk-timeline>
      </ng-container>
    </div>

    <!--<ng-container *ngIf="!technicianDates.isPastTechnicianDate()">
      <div style="padding-top: 38px; display: inline-flex; flex-direction: row; flex-wrap: wrap; max-width: calc(100% - 60px);">
        <div *ngIf="technicianDates?.changedTourContainer.unfittingOperations.length" style="display: inline-flex; flex-direction: row; width: fit-content;">
          <div style="margin: 0; white-space: nowrap; background-color: #bd7301; display: inline-flex; cursor: pointer; border: 1px solid black; padding: 2px 4px;">
            <div style="margin: auto; padding: 2px 4px; font-size: .7rem; line-height: .7rem;">
              Nicht eingefügt: {{technicianDates.changedTourContainer.unfittingOperations.length}}
            </div>
          </div>
          <div class="horizontal_flex wrap" style="padding: 2px 0 2px 2px; background-color: #bd7301; border: 1px solid black;">
            <div *ngFor="let operation of technicianDates.changedTourContainer.unfittingOperations" class="black_gradient_background_no_hover operation_container horizontal_flex" [ngClass]="PAStore.selectedStoreInMap == operation.getStore() ? 'selected_store' : ''">
              <div style="margin: auto; width: 100px; text-align: center; cursor: pointer; padding: 2px 4px;" (click)="PAStoreControl.Instance.selectStore(operation.getStore())">
                {{operation.ticket.address_company.slice(0, 16)}}
              </div>
              <div *ngIf="technicianDates.insertionConfigMap.has(operation.id)" class="black_gradient_background" style="border: 1px solid white; display: inline-flex; padding: 2px;" (click)="technicianDates.insertionConfigMap.get(operation.id).removable = !technicianDates.insertionConfigMap.get(operation.id).removable; technicianDates.updateNewTourOperations()">
                <fa-icon style="margin: auto;" [icon]="technicianDates.insertionConfigMap.get(operation.id).removable ? faLockOpen : faLock "></fa-icon>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="technicianDates.newTourOperationChanges?.delete_operations?.length" style="display: inline-flex; flex-direction: row; width: fit-content;">
          <div style="margin: 0; white-space: nowrap; background-color: red; display: inline-flex; cursor: pointer; border: 1px solid black; padding: 2px 4px;">
            <div style="margin: auto; padding: 2px 4px; font-size: .7rem; line-height: .7rem;">
              Entfernt: {{technicianDates.newTourOperationChanges.delete_operations.length}}
            </div>
          </div>
          <div class="horizontal_flex wrap" style="padding: 2px 0 2px 2px; background-color: red; border: 1px solid black;">
            <div *ngFor="let operation of technicianDates.newTourOperationChanges.delete_operations" tooltip="wieder hinzufügen" class="black_gradient_background operation_container horizontal_flex" (click)="technicianDates.restoreOperationToNewTour(operation)" [ngClass]="PAStore.selectedStoreInMap == operation.getStore() ? 'selected_store' : ''">
              <div style="margin: auto; width: 100px; text-align: center; cursor: pointer; padding: 2px 4px;" (click)="PAStoreControl.Instance.selectStore(operation.getStore())">
                {{operation.ticket.address_company.slice(0, 16)}}
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>-->
  </div>

  <div style="display: inline-flex; flex-direction: column; padding: 6px; position: absolute; right: 0; bottom: 0;">
    <button mat-raised-button style="margin: auto auto 0;" *ngIf="enableSave" [disabled]="!atLeastOnTourHasChanged()" (click)="saveNewTours()">speichern</button>
  </div>

  <!--<div *ngIf="technicianDates.getPublicHolidays().length && !ignoreWarning" style="position: absolute; width: 100%; height: 100%; background-color: rgba(0, 0, 0, 0.849); display: inline-flex; overflow-x: auto;">
    <div style="color: white; width: 100%; height: fit-content; margin: auto; text-align: center;">
      Der ausgewählte Auftrag kann nicht an diesem Tag eingefügt werden (Grund: {{technicianDates.getPublicHolidays()[0].name}}) <br>
      <button style="margin-top: 8px; margin-left: 8px;" class="blue_gradient_background" (click)="ignoreWarning = true">
        trotzdem einfügen!
      </button>
    </div>
  </div>
  <div *ngIf="getSLAWarnings().length && !ignoreWarning" style="position: absolute; width: 100%; height: 100%; background-color: rgba(0, 0, 0, 0.849); display: inline-flex; overflow-x: auto;">
    <div style="color: white; width: 100%; height: fit-content; margin: auto; text-align: center;">
      Der ausgewählte Auftrag kann nicht an diesem Tag eingefügt werden (Grund: {{getSLAWarnings().join(', ')}}) <br>
      <button style="margin-top: 8px; margin-left: 8px;" class="blue_gradient_background" (click)="ignoreWarning = true">
        trotzdem einfügen!
      </button>
    </div>
  </div>
  <div *ngIf="technicianDates.absences.length && !ignoreWarning" style="position: absolute; width: 100%; height: 100%; background-color: rgba(0, 0, 0, 0.849); display: inline-flex; overflow-x: auto;">
    <div style="color: white; width: 100%; height: fit-content; margin: auto; text-align: center;">
      Der ausgewählte Auftrag kann nicht an diesem Tag eingefügt werden (Grund: {{technicianDates.absences[0].type}}) <br>
      <button style="margin-top: 8px; margin-left: 8px;" class="blue_gradient_background" (click)="ignoreWarning = true">
        trotzdem einfügen!
      </button>
    </div>
  </div>-->
</div>

<hawk-popup-modal [shown]="showDBChangeModal" (closeEvent)="showDBChangeModal = false">
  <hawk-database-conflicts [DBChanges]="DBChanges"
                           (saveNewTourEvent)="saveNewTours(true)"
                           (closeEvent)="showDBChangeModal = false"
  >
  </hawk-database-conflicts>
</hawk-popup-modal>

