<div class="tour_stopover_container">
  <div *ngIf="tourIdxs.length" class="tour_stopover_child">
    <div class="idxs_container" [style.min-width]="Math.min(tourIdxs.length * 24, 144) + 'px'">
      <div *ngFor="let tour_idx of tourIdxs" class="idx" style="color: white; font-weight: bold; font-size: 12px;" [style.background-color]="tour_idx.color">
        {{tour_idx.idx}}
        <div class="visible_on_idx_hover" style="overflow: visible;" [style.background-color]="tour_idx.color">
          <ng-container *ngIf="isMainMapContainer()">
            <div class="horizontal_flex" style="width: 100%;" (click)="clickIdx(tour_idx)">
              <div style="margin: auto;">
                <fa-icon [icon]="faEye" [tooltip]="'Tour öffnen'"></fa-icon>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="!isMainMapContainer()">
            <div class="vertical_flex show_on_parent_hover" style="position: absolute; z-index: 5; bottom: 0; left: 50%; transform: translate(-50%, 0); overflow: visible;">
              <div class="horizontal_flex" style="border: 1px solid black; background-color: white; color: white; overflow: visible;">
                <div *ngIf="tour_idx.tour.technicianDate.insertionConfigMap.has(tour_idx.operation.id)" class="black_gradient_background" style="border: 1px solid black; display: inline-flex; padding: 2px;" (click)="tour_idx.tour.technicianDate.insertionConfigMap.get(tour_idx.operation.id).removable = !tour_idx.tour.technicianDate.insertionConfigMap.get(tour_idx.operation.id).removable; tour_idx.tour.technicianDate.updateNewTourOperations()">
                  <fa-icon style="margin: auto;" [icon]="tour_idx.tour.technicianDate.insertionConfigMap.get(tour_idx.operation.id).removable ? faLockOpen : faLock "></fa-icon>
                </div>
                <mat-form-field style="width: 100px; padding: 2px 4px;" class="no_bottom_padding outline-small no-subscript" appearance="outline">
                  <mat-select [value]="tour_idx.tour.technicianDate.insertionConfigMap.get(tour_idx.operation.id)?.tour_index" (selectionChange)="tour_idx.tour.technicianDate.insertionConfigMap.get(tour_idx.operation.id).tour_index = Number.parseInt($event.value); tour_idx.tour.technicianDate.updateNewTourOperations()">
                    <mat-option [value]="-1">Opt</mat-option>
                    @for (idx of tour_idx.tour.technicianDate.freeTourIndexes; track idx) {
                      <mat-option [value]="idx" [disabled]="tour_idx.tour.technicianDate.insertionConfigIndexIsDisabled(idx)">{{idx + 1}}</mat-option>
                    }
                  </mat-select>
                </mat-form-field>
                <div class="horizontal_flex black_gradient_background" style="border: 1px solid black;" (click)="clickIdx(tour_idx)">
                  <div style="margin: auto;">
                    <div style="font-size: .9rem; padding: 2px;" [tooltip]="'Aus Tour entfernen'">
                      <fa-icon [icon]="faTrash"></fa-icon>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
          <!--<ng-container *ngIf="hasSingleTourInput() && !isChangedTour(tour_idx.tour)">
            <div (click)="clickIdx(tour_idx)" class="horizontal_flex" style="width: 100%;">
              <div style="margin: auto;">
                <fa-icon [icon]="faCalendarCheck"></fa-icon>
              </div>
            </div>
          </ng-container>-->
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="notInsertedOperations.length && !isMainMapContainer()" class="not_in_tour not_inserted tour_stopover_child">
    <div class="idxs_container" style="min-width: 24px;">
      <div class="idx" style="border: 1px solid black;" [style.background-color]="'rgba(255, 255, 255, .5)'">
        &#x2716;
        <div class="visible_on_idx_hover" [style.background-color]="'#1f1f1f'" [tooltip]="'Fest in Tour setzen'"
             (click)="makeNotInsertedOperationsUnremovable()">
          <div style="margin: auto;">
            <div style="font-size: 1rem; color: white;"><fa-icon [icon]="faLock"></fa-icon></div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="deletedOperations.length && !isMainMapContainer()" class="not_in_tour deleted tour_stopover_child">
    <div class="idxs_container" style="min-width: 24px;">
      <div class="idx" style="border: 1px solid black;" [style.background-color]="'rgba(255, 255, 255, .8)'">
        &#x2716;
        <div class="visible_on_idx_hover" [style.background-color]="'#1f1f1f'" (click)="addStoreOperationsToNewTour()">
          <div style="margin: auto;">
            <div style="font-size: 1rem; color: greenyellow" [tooltip]="'Entfernen rückgängig machen'"><fa-icon [icon]="faPlus"></fa-icon></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
