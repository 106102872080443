import { Subject } from "rxjs";
import { PACoordinates } from "./coordinates";
import { TravelTechnicianDate as TravelTechnicianDateHash } from "src/app/_models/planning-assistant.interface";
import { PATechnicianDate } from "./technician-date";
import { PALocation } from "./location";
import { DraggableDestinationMarker } from "./draggable-marker";
import { PADataControl } from "../singletons/pa-data-control";
import { PATimeControl } from "../singletons/pa-time-control";

export class TravelTechnicianDate {

  changedSubject = new Subject<void>()
  private _dbID?: number
  public location?: PALocation

  public draggableDestinationMarker: DraggableDestinationMarker

  constructor(
    readonly technicianID: number,
    readonly timestamp: number,
    config?: { dbID?: number, coordinates?: PACoordinates }
  ) {
    this._dbID = config?.dbID
    this.initMarker(config?.coordinates)
  }

  get coordinates() {
    return this.draggableDestinationMarker?.coordinates
  }

  set coordinates(coordinates: PACoordinates) {
    this.initMarker(coordinates)
    this.draggableDestinationMarker.coordinates = coordinates
    this.updateInDB()
  }

  initMarker(coordinates: PACoordinates): void {
    if (!this.draggableDestinationMarker) {
      this.draggableDestinationMarker = new DraggableDestinationMarker(null, this, coordinates)
      this.updateLocation()
    }
  }

  isActive(): boolean {
    return !!this._dbID
  }

  updateLocation(): void {
    if (this.location?.coordinates?.latitude != this.draggableDestinationMarker?.coordinates?.latitude && this.location?.coordinates?.longitude != this.draggableDestinationMarker?.coordinates?.longitude) {
      this.location = this.draggableDestinationMarker?.coordinates ? new PALocation(this.draggableDestinationMarker.coordinates, PADataControl.Instance.generateNextLocationId(), '', '', '') : null
      this.updateInDB()
    }
  }

  async updateInDB(): Promise<TravelTechnicianDateHash> {
    if (this.isActive()) {
      let travel_technician_date_data = {
        location_name: this.coordinates?.name || null,
        location_address_latitude: this.coordinates?.latitude || null,
        location_address_longitude: this.coordinates?.longitude || null
      }

      return new Promise((resolve) => {
        PATechnicianDate.travelTechnicianDateService.updateTravelTechnicianDate(this._dbID, {travel_technician_date: travel_technician_date_data}).subscribe(
          async (data) => {
            let map = PATechnicianDate.travelTechnicianDates.get(this.timestamp) as Map<number, TravelTechnicianDate>
            map.set(this.technicianID, this)
            resolve(data)
          },
          (err) => {
            console.error(err)
          },
        )
      })
    }
  }

  async createInDB(): Promise<TravelTechnicianDateHash> {
    if (!this.isActive()) {
      let travel_technician_date_data: {
        user_id: number,
        date: string,
        location_name?: string,
        location_address_latitude?: number,
        location_address_longitude?: number
      }
      if (this.coordinates) {
        travel_technician_date_data = {
          user_id: this.technicianID,
          date: PATimeControl.Instance.formatDate(new Date(this.timestamp)),
          location_name: this.coordinates?.name,
          location_address_latitude: this.coordinates?.latitude,
          location_address_longitude: this.coordinates?.longitude
        }
      } else {
        travel_technician_date_data = {
          user_id: this.technicianID,
          date: PATimeControl.Instance.formatDate(new Date(this.timestamp))
        }
      }

      return new Promise((resolve) => {
        PATechnicianDate.travelTechnicianDateService.createTravelTechnicianDate(travel_technician_date_data).subscribe(
          async (data) => {
            this._dbID = data.id
            resolve(data)
          },
          (err) => {
            console.error(err)
          },
        )
      })
    }
  }

  async deleteInDb(): Promise<boolean> {
    if (this.isActive()) {
      return new Promise((resolve) => {
          PATechnicianDate.travelTechnicianDateService.deleteTravelTechnicianDate(this._dbID).subscribe(
            async _ => {
              this._dbID = null
              this.coordinates = null
              this.draggableDestinationMarker.remove()
              this.draggableDestinationMarker.map = null
              resolve(true)
            },
            (err) => {
              console.error(err)
              resolve(false)
            },
          )
        }
      )
    }
  }

  hasChanged(): void {
    this.changedSubject.next()
  }

}