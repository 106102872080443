<div class="">
    <div class="d-flex align-items-center">
      <div class="col t-header d-flex align-items-center justify-content-start">
        <h4 class="mb20 d-flex align-items-center gap-1"><i class="fa fa-building-o"></i>
          <ul class="list-group list-group-horizontal b-custom">
            <li class="list-group-item bg-transparent border-0" *ngFor="let item of breadCrumb">
              <span *ngIf="!item.path">{{item.title}}</span>
              <a *ngIf="item.path" class="g-link" [routerLink]="item.path">
                <span>{{item.title}}</span>
              </a>
            </li>
          </ul>
        </h4>
      </div>
      <div class="quicksettings">
        <input type="text" (input)="quicksearchChanged($event)" placeholder="Schnellfilter">
      </div>
    </div>
    <ag-grid-angular class="ag-theme-quartz" style="height: 700px;width: 100%;"
      [theme]="hawkTheme" [loadThemeGoogleFonts]="true"
      [defaultColDef]="defaultColDef"
      [columnDefs]="colDefs"
      [rowClassRules]="rowClassRules"
      [rowSelection]="rowSelection"
      [detailRowAutoHeight]="true"
      [alwaysShowVerticalScroll]="true"
      [localeText]="locale"
      [masterDetail]="true"
      [detailCellRendererParams]="detailCellRendererParams"
      (firstDataRendered)="onFirstDataRendered($event)"
      [pagination]="true"
      [rowData]="articleTemplates"
      [paginationPageSize]="paginationPageSize"
      [paginationPageSizeSelector]="paginationPageSizeSelector"
      (gridReady)="onGridReady($event)">
    </ag-grid-angular>
    <div class="g-actions">
      <a href="/a/artikel/neu" target="_blank" class="btn btn-primary">
        Artikel hinzufügen
      </a>
    </div>
  </div>