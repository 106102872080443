import { Pipe, PipeTransform } from '@angular/core'
import { isParameter } from 'typescript'
import { NotePageFilterParameter, TicketNote } from '../_models'

@Pipe({
    name: 'ticketNoteFilter',
})
export class TicketNoteFilterPipe implements PipeTransform {
  transform(allNotes: TicketNote[], filterparameters: NotePageFilterParameter): TicketNote[] {
    if (!allNotes) {
      return allNotes
    }

    let result = allNotes

    // jobtype: string[]
    if (filterparameters.jobtype.length > 0) {
      result = result.filter(note => {

        let check = false

        // if at least one box is true use item!
        filterparameters.jobtype.forEach((element) => {
          if (note[element]) {
            check = true
          }
        })

        return check
      })
    }

    // destination: string[]
    if (filterparameters.destination.length > 0) {
      result = result.filter(note => {

        let check = false

        // if at least one box is true use item!
        filterparameters.destination.forEach((element) => {
          if (note[element]) {
            check = true
          }
        })

        return check
      })
    }

    // technician: string
    if (filterparameters.technician.length > 0) {
      result = result.filter(note => note.technician_id === null || note.technician_id.toString() === filterparameters.technician)
    }

    // source: string
    if (filterparameters.source.length > 0) {
      result = result.filter(note => note.source === null || note.source === filterparameters.source)
    }

    return result
  }
}
