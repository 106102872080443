import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbCalendar, NgbDate, NgbDateParserFormatter, NgbDatepickerModule, NgbInputDatepicker } from '@ng-bootstrap/ng-bootstrap';
import { Technician } from 'src/app/_models';
import { TechnicianService } from 'src/app/_services/technician.service';
import { ProjectWithPrioStatus } from 'src/app/_models/project.interface';
import { TechnicianCompany } from 'src/app/_models/technician.interface';
import { MatInputModule } from '@angular/material/input';
import { NgSelectComponent, NgLabelTemplateDirective, NgOptionTemplateDirective } from '@ng-select/ng-select';
import { NgIf } from '@angular/common';

import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { NgSelectModule } from "@ng-select/ng-select";
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { Component, OnInit, inject } from '@angular/core'
import { ProjectService } from 'src/app/_services'
import { Router, RouterLink } from '@angular/router'

@Component({
    selector: 'hawk-ticket-search-form',
    templateUrl: './ticket-search-form.component.html',
    styleUrls: ['./ticket-search-form.component.scss'],
    imports: [
      ReactiveFormsModule, 
      MatFormFieldModule,
      MatInputModule, 
      FormsModule,
      NgSelectComponent, 
      NgLabelTemplateDirective, 
      NgOptionTemplateDirective, 
      NgIf, 
      NgbInputDatepicker,
      MatCheckboxModule, 
      CommonModule, 
      RouterModule, 
      MatCheckboxModule, 
      NgSelectModule, 
      FontAwesomeModule,
      NgbDatepickerModule
    ]
})
export class TicketSearchFormComponent implements OnInit {

  public projects: ProjectWithPrioStatus[] = []
  public technicians: Technician[] = []
  public companies: TechnicianCompany[] = []
  public selectedTechnicians: number[] = []
  public searchTerm: string = ''
  public searchTermProject: string = ''
  public searchTermStatus: string = ''
  public searchTermPrio: string = ''
  public currentProject: ProjectWithPrioStatus = null

  public ticketSearchForm = new FormGroup({
    order_number: new FormControl(),
    external_order_number: new FormControl(),
    fault_description: new FormControl(),
    invoice_number: new FormControl(),
    invoice_number_partner: new FormControl(),
    project_id: new FormControl(),
    status_id: new FormControl(),
    priority_id: new FormControl(),
    technician_ids: new FormControl([]),
    technician_company_names: new FormControl([]),
    is_open: new FormControl(),
    not_invoiced: new FormControl(),
    not_invoiced_partner: new FormControl(),
    has_date: new FormControl(),
  })

  constructor(
    public formatter: NgbDateParserFormatter,
    private router: Router,
    private projectService: ProjectService,
    private technicianService: TechnicianService,
  ) { }

  ngOnInit(): void {
    this.technicianService.getAll().subscribe({
      next: (data) => {
        this.technicians = data
        const uniqueFilter = ((value, index, self) => self.indexOf(value) === index)
        this.companies = this.technicians.map((technician) => technician.company).filter((companyName) => !!companyName).filter(uniqueFilter).sort().map((companyName) => ({ company: companyName} as TechnicianCompany))
      },
      error: (err) => {
        console.error(err)
      }
    })

    this.projectService.getActiveWithPrioStatus().subscribe({
      next: (data) => {
        this.projects = data
      },
      error: (err) => {
        console.error(err)
      }
    })

    this.onFormChanges()
  }

  public search() {
    const formdata = this.ticketSearchForm.value

    let searchParams = {
      order_number: formdata.order_number,
      external_order_number: formdata.external_order_number,
      fault_description: formdata.fault_description,
      invoice_number: formdata.invoice_number,
      invoice_number_partner: formdata.invoice_number_partner,
      project_id: formdata.project_id,
      priority_id: formdata.priority_id,
      status_id: formdata.status_id,
      operation_date_from: this.formatter.format(this.operation_date_from),
      operation_date_to: this.formatter.format(this.operation_date_to),
      create_date_from: this.formatter.format(this.create_date_from),
      create_date_to: this.formatter.format(this.create_date_to),
      technician_ids: formdata.technician_ids,
      technician_company_names: formdata.technician_company_names,
      is_open: formdata.is_open,
      not_invoiced: formdata.not_invoiced,
      not_invoiced_partner: formdata.not_invoiced_partner,
      has_date: formdata.has_date,
    }
    this.router.navigate(['ticket', 'suche'], { queryParams: searchParams })
    return false
  }

  public onFormChanges(): void {
    this.ticketSearchForm.get('project_id').valueChanges.subscribe(val => {
      this.currentProject = this.projects.filter((project) => project.id == val)[0]

      this.ticketSearchForm.patchValue({ status_id: null, priority_id: null })
    })
  }

  calendar = inject(NgbCalendar);

  hoveredDateOperation: NgbDate | null = null;
  operation_date_from: NgbDate | null //= this.calendar.getToday();
  operation_date_to: NgbDate | null //= this.calendar.getNext(this.calendar.getToday(), 'd', 7);

  onDateSelectionOperation(date: NgbDate) {
    if (!this.operation_date_from && !this.operation_date_to) {
      this.operation_date_from = date;
    } else if (this.operation_date_from && !this.operation_date_to && date && (date.after(this.operation_date_from) || date.equals(this.operation_date_from))) {
      this.operation_date_to = date;
    } else {
      this.operation_date_to = null;
      this.operation_date_from = date;
    }
  }

  isHoveredOperation(date: NgbDate) {
    return (
      this.operation_date_from && !this.operation_date_to && this.hoveredDateOperation && date.after(this.operation_date_from) && date.before(this.hoveredDateOperation)
    );
  }

  isInsideOperation(date: NgbDate) {
    return this.operation_date_to && date.after(this.operation_date_from) && date.before(this.operation_date_to);
  }

  isRangeOperation(date: NgbDate) {
    return (
      date.equals(this.operation_date_from) ||
      (this.operation_date_to && date.equals(this.operation_date_to)) ||
      this.isInsideOperation(date) ||
      this.isHoveredOperation(date)
    );
  }

  hoveredDateCreation: NgbDate | null = null;
  create_date_from: NgbDate | null //= this.calendar.getToday();
  create_date_to: NgbDate | null //= this.calendar.getNext(this.calendar.getToday(), 'd', 7);

  onDateSelectionCreation(date: NgbDate) {
    if (!this.create_date_from && !this.create_date_to) {
      this.create_date_from = date;
    } else if (this.create_date_from && !this.create_date_to && date && (date.after(this.create_date_from) || date.equals(this.create_date_from))) {
      this.create_date_to = date;
    } else {
      this.create_date_to = null;
      this.create_date_from = date;
    }
  }

  isHoveredCreation(date: NgbDate) {
    return (
      this.create_date_from && !this.create_date_to && this.hoveredDateCreation && date.after(this.create_date_from) && date.before(this.hoveredDateCreation)
    );
  }

  isInsideCreation(date: NgbDate) {
    return this.create_date_to && date.after(this.create_date_from) && date.before(this.create_date_to);
  }

  isRangeCreation(date: NgbDate) {
    return (
      date.equals(this.create_date_from) ||
      (this.create_date_to && date.equals(this.create_date_to)) ||
      this.isInsideCreation(date) ||
      this.isHoveredCreation(date)
    );
  }

  validateInput(currentValue: NgbDate | null, input: string): NgbDate | null {
    const parsed = this.formatter.parse(input);
    return parsed && this.calendar.isValid(NgbDate.from(parsed)) ? NgbDate.from(parsed) : currentValue;
  }

  public setRange(target, range) {
    const today = this.calendar.getToday()
    const weekday = this.calendar.getWeekday(today)

    const startOfWeekDiff = weekday - 1
    const endOfWeekDiff = 7 - weekday

    if (target == 'created') {

      if (range == 'today') {
        this.create_date_from = today
        this.create_date_to = today
      }

      if (range == 'thisweek') {
        this.create_date_from = this.calendar.getPrev(today, 'd', startOfWeekDiff)
        this.create_date_to = this.calendar.getNext(today, 'd', endOfWeekDiff)
      }

      if (range == 'nextweek') {
        this.create_date_from = this.calendar.getNext(today, 'd', endOfWeekDiff + 1)
        this.create_date_to = this.calendar.getNext(today, 'd', endOfWeekDiff + 7)
      }

    }

    if (target == 'operation') {

      if (range == 'today') {
        this.operation_date_from = today
        this.operation_date_to = today
      }

      if (range == 'thisweek') {
        this.operation_date_from = this.calendar.getPrev(today, 'd', startOfWeekDiff)
        this.operation_date_to = this.calendar.getNext(today, 'd', endOfWeekDiff)
      }

      if (range == 'nextweek') {
        this.operation_date_from = this.calendar.getNext(today, 'd', endOfWeekDiff + 1)
        this.operation_date_to = this.calendar.getNext(today, 'd', endOfWeekDiff + 7)
      }

    }

    return false
  }

  public deleteFromDate(target): boolean {
    if (target == 'created') {
      this.create_date_from = null
    }

    if (target == 'operation') {
      this.operation_date_from = null
    }

    return false
  }

}