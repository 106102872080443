import { Component, Input, OnInit } from '@angular/core';
import { NgStyle } from '@angular/common';
import { CommonModule } from "@angular/common";

@Component({
  selector: 'hawk-throbber',
  templateUrl: './throbber.component.html',
  styleUrls: ['./throbber.component.scss'],
  standalone: true,
  imports: [NgStyle, CommonModule]
})
export class ThrobberComponent implements OnInit {

  @Input() description: string
  @Input() backgroundColor: string = "rgba(0, 0, 0, 0.849)"
  @Input() direction: 'horizontal' | 'vertical' = 'vertical'

  constructor() {
  }

  ngOnInit(): void {
  }

}