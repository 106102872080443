import { PACoordinates } from "./coordinates"
import { CalculatesDistance, PALocation } from "./location"
import { PADataControl } from "../singletons/pa-data-control";

export class PAClient implements CalculatesDistance {

  public location: PALocation

  constructor(
    public client_id: number,
    public location_id: number,
    public coordinates: PACoordinates,
    public client_name: string,
    public zip_code: string
  ) {

    if (PADataControl.Instance.locationMap.has(location_id)) {
      this.location = PADataControl.Instance.locationMap.get(location_id)
    } else {
      this.location = new PALocation(coordinates, location_id, zip_code, '', '')
    }

    PADataControl.Instance.clientMap.set(client_id, this)
  }

  getDistanceToCoordinatesAsTheCrowFlies(lat: number, lon: number): number {
    return this.location.getDistanceToCoordinatesAsTheCrowFlies(lat, lon)
  }

  getStreetDistanceToCoordinates(lat: number, lon: number): Promise<{ distance: number; duration: number }> {
    return this.location.getStreetDistanceToCoordinates(lat, lon)
  }

  getDistanceToLocation(to_location: PALocation): Promise<{ distance: number; duration: number }> {
    return this.location.getDistanceToLocation(to_location)
  }

  getDistanceToLocationAsTheCrowFlies(location: PALocation): number {
    return this.location.getDistanceToCoordinatesAsTheCrowFlies(location.coordinates.latitude, location.coordinates.longitude)
  }

}