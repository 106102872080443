import { FireUpdateOnChange } from "./util-classes/fire-update-on-change";
import { MarkerInstance } from "./mapbox-marker";
import { PACoordinates } from "./coordinates";
import { PATour } from "./tour";
import { PATimeControl } from "../singletons/pa-time-control";

export interface ChangePositionConfig {
  active: boolean,
  coordinates?: PACoordinates,
  current_action?: TechnicianPostionAction
  action_start?: {is_estimated?: boolean, time_minutes: number}
  action_end?: {finish_percentage: number, time_minutes: number}
}

export type TechnicianPostionAction = 'home_start' | 'driving' | 'working' | 'finished' | 'home_end'

export class TechnicianPosition extends FireUpdateOnChange<void> implements MarkerInstance {

  private _active: boolean = false
  private _coordinates: PACoordinates
  private _current_action: TechnicianPostionAction
  private _action_start: {is_estimated?: boolean, time_minutes: number}
  private _action_end: {finish_percentage: number, time_minutes: number}

  constructor(
    public tour: PATour,
  ) {
    super();
    this.update()
  }

  get active(): boolean {
    return this._active
  }

  get coordinates(): PACoordinates {
    return this._coordinates
  }

  get current_action(): TechnicianPostionAction {
    return this._current_action
  }

  get action_start(): {is_estimated?: boolean, time_minutes: number} {
    return this._action_start
  }

  get action_end(): {finish_percentage: number, time_minutes: number} {
    return this._action_end
  }

  executeBeforeChange(): void {
    return
  }

  getColorString(): string {
    return this.tour.technicianDate.technician.getColorString()
  }

  getCoordinates(): PACoordinates {
    return this._coordinates || this.tour.startLocation.coordinates
  }

  update(): void {
    let changed_position = false
    let current_day_minutes = (PATimeControl.Instance.getCurrentTimestamp() - PATimeControl.Instance.dateToTimestamp(new Date(), true, false)) / (60 * 1000)

    if (
      !this.tour.operations.length
      || !this.tour.technicianDate.isTodaysTechnicianDate()
    ) {
      changed_position = this.changePosition(
        {
          active: false
        }
      )
    } else {

      let operations_with_driving_start = this.tour.operations.filter(op => op.date_travel_start)
      if (operations_with_driving_start.length) {
        let last_operation_with_driving_start = operations_with_driving_start[operations_with_driving_start.length - 1]
        let last_route_operation = this.tour.operations[this.tour.operations.length - 1]

        if (last_operation_with_driving_start.date_finished) {
          let date_finished_day_minutes = PATimeControl.Instance.timeStringToMinutes(PATimeControl.Instance.dateToTimestring(new Date(last_operation_with_driving_start.date_finished), false))

          if (last_operation_with_driving_start == last_route_operation) {
            let driving_time = this.tour.route.driving_times[this.tour.route.driving_times.length - 1]
            let travel_home_minutes = driving_time.duration
            let expected_home_day_minutes = date_finished_day_minutes + travel_home_minutes
            let technician_is_expected_home = expected_home_day_minutes >= current_day_minutes

            if (technician_is_expected_home) {
              changed_position = this.changePosition(
                {
                  active: true,
                  coordinates: this.tour.endLocation.coordinates,
                  current_action: 'home_end',
                  action_start: {is_estimated: true, time_minutes: expected_home_day_minutes}
                }
              )

            } else {
              let finish_percentage = Math.min(Math.max(((current_day_minutes - date_finished_day_minutes) / (expected_home_day_minutes - date_finished_day_minutes)) * 100, 0), 100)
              let route_coordinates = driving_time.geometry.coordinates
              if (route_coordinates.length) {
                let expected_technician_coordinates = route_coordinates[Math.min(Math.floor(route_coordinates.length * (finish_percentage / 100)), route_coordinates.length - 1)]

                changed_position = this.changePosition(
                  {
                    active: true,
                    coordinates: {
                      latitude: expected_technician_coordinates[1],
                      longitude: expected_technician_coordinates[0]
                    },
                    current_action: 'driving',
                    action_start: {
                      is_estimated: true,
                      time_minutes: date_finished_day_minutes
                    },
                    action_end: {
                      finish_percentage: finish_percentage,
                      time_minutes: expected_home_day_minutes
                    }
                  }
                )
              } else {
                console.error('Error: No Tour Coordinates for Technician Live Position')
              }
            }

          } else {
            changed_position = this.changePosition(
              {
                active: true,
                coordinates: last_operation_with_driving_start.ticket.location.coordinates,
                current_action: 'finished',
                action_start: {
                  is_estimated: false,
                  time_minutes: date_finished_day_minutes
                }
              }
            )
          }

        } else if (last_operation_with_driving_start.date_on_site) {

          let expected_operation_minutes = last_operation_with_driving_start.calculateOperationTimeMilliseconds({use_technician: this.tour.technicianDate.technician}) / (60 * 1000)
          let on_site_day_minutes = PATimeControl.Instance.timeStringToMinutes(PATimeControl.Instance.dateToTimestring(new Date(last_operation_with_driving_start.date_on_site), false))
          let expected_end_minutes = on_site_day_minutes + expected_operation_minutes
          let finish_percentage = Math.min(Math.max(((current_day_minutes - on_site_day_minutes) / expected_operation_minutes) * 100, 0), 100)

          changed_position = this.changePosition(
            {
              active: true,
              coordinates: last_operation_with_driving_start.ticket.location.coordinates,
              current_action: 'working',
              action_start: {
                is_estimated: false,
                time_minutes: on_site_day_minutes
              },
              action_end: {
                time_minutes: expected_end_minutes,
                finish_percentage: finish_percentage
              }
            }
          )

        } else {
          let driving_time = this.tour.route.driving_times[this.tour.operations.indexOf(last_operation_with_driving_start)]
          let travel_start_minutes = PATimeControl.Instance.timeStringToMinutes(PATimeControl.Instance.dateToTimestring(new Date(last_operation_with_driving_start.date_travel_start), false))
          let expected_driving_end_minutes = travel_start_minutes + driving_time.duration

          let route_coordinates = driving_time.geometry.coordinates
          if (route_coordinates.length) {
            let finish_percentage = Math.min(Math.max(((current_day_minutes - travel_start_minutes) / (expected_driving_end_minutes - travel_start_minutes)) * 100, 0), 100)
            let expected_technician_coordinates = route_coordinates[Math.min(Math.floor(route_coordinates.length * (finish_percentage / 100)), route_coordinates.length - 1)]

            changed_position = this.changePosition(
              {
                active: true,
                coordinates: {
                  latitude: expected_technician_coordinates[1],
                  longitude: expected_technician_coordinates[0]
                },
                current_action: 'driving',
                action_start: {
                  is_estimated: false,
                  time_minutes: travel_start_minutes
                },
                action_end: {
                  finish_percentage: finish_percentage,
                  time_minutes: expected_driving_end_minutes
                }
              }
            )
          } else {
            console.error('Error: No Tour Coordinates for Technician Live Position')
          }
        }

      } else {
        changed_position = this.changePosition(
          {
            active: true,
            coordinates: this.tour.startLocation.coordinates,
            current_action: 'home_start',
            action_start: {is_estimated: true, time_minutes: this.tour.operations[0].getStartMinutes() - this.tour.route.driving_times[0].duration}
          }
        )
      }
    }

    if (changed_position) {
      this.fireUpdate()
    }
  }

  changePosition(config: ChangePositionConfig): boolean {
    let changed_position = false

    if (!config.active && this._active) {
      this._active = false
      changed_position = true

    } else if (config.active) {
      if (!this._active) {
        this._active = config.active
        changed_position = true
      }

      if (config.current_action && config.current_action != this._current_action) {
        this._current_action = config.current_action
        changed_position = true
      }

      if (config.coordinates && (!this._coordinates || this._coordinates.longitude != config.coordinates.longitude || this._coordinates.latitude != config.coordinates.latitude)) {
        this._coordinates = config.coordinates
        changed_position = true
      }

      if (config.action_start && (!this._action_start || this._action_start.is_estimated != config.action_start.is_estimated || this._action_start.time_minutes != config.action_start.time_minutes)) {
        this._action_start = config.action_start
        changed_position = true
      }

      if (config.action_end && (!this._action_end || this._action_end.finish_percentage != config.action_end.finish_percentage || this._action_end.time_minutes != config.action_end.time_minutes)) {
        this._action_end = config.action_end
        changed_position = true
      } else if (!config.action_end && this._action_end) {
        this._action_end = null
        changed_position = true
      }
    }

    return changed_position
  }

}