import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { PAOperation } from '../classes/operation';
import { PATechnician } from '../classes/technician';
import { PAUtil } from '../classes/util';
import { TicketService } from 'src/app/_services';
import { NgIf } from '@angular/common';
import { CommonModule } from "@angular/common";

import * as mapboxgl from 'mapbox-gl';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';

@Component({
  selector: 'hawk-insufficient-data-popup',
  templateUrl: './insufficient-data-popup.component.html',
  styleUrls: ['./insufficient-data-popup.component.scss'],
  standalone: true,
  imports: [NgIf, CommonModule]
})
export class InsufficientDataPopupComponent implements OnInit, OnChanges {

  @Input() operationsWithoutLngLat: PAOperation[] = []
  @Input() techniciansWithoutLngLat: PATechnician[] = []
  @Input() mapboxAccessToken: string

  selectedInstance: { type: string, instance: PAOperation | PATechnician }
  geocoder: MapboxGeocoder
  searchedAddress: {
    address_latitude: number,
    address_longitude: number,
    address_street_no?: string,
    address_street?: string,
    address_zip_code?: string,
    address_city?: string,
    address_country?: string
  }
  insufficientInstanceAmount: number;

  hawkLink: string

  constructor(
    private ticketService: TicketService
  ) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.updateInsufficientInstanceAmount()
  }

  ngOnInit(): void {
    this.geocoder = new MapboxGeocoder(
      {
        accessToken: this.mapboxAccessToken,
        mapboxgl: mapboxgl,
        placeholder: "Adresse suchen...",
      }
    )
    this.initGeocoder()
    this.selectNextInstance();
  }

  private selectNextInstance() {
    if (this.operationsWithoutLngLat.length) {
      this.selectedInstance = {type: 'operation', instance: this.operationsWithoutLngLat[0]};
    } else if (this.techniciansWithoutLngLat.length) {
      this.selectedInstance = {type: 'technician', instance: this.techniciansWithoutLngLat[0]};
    }
    this.updateHawkLink()
    this.updateInsufficientInstanceAmount()
  }

  async initGeocoder(): Promise<void> {
    while (!document.getElementById('ins_data_geocoder')) {
      await PAUtil.sleep(100)
    }
    this.geocoder.addTo(document.getElementById('ins_data_geocoder'))
    const insDataObj = this
    this.geocoder.on('result', function (event) {
      insDataObj.parseSearchedAddress(event)
    })
  }

  parseSearchedAddress(event: {
    result: {
      center: number[],
      id: string,
      text: string,
      mapbox_id: string,
      address: string,
      place_name: string,
      context: { id: string, mapbox_id: string, text: string }[]
    }
  }): void {
    let searched_address = {
      address_latitude: null,
      address_longitude: null,
      address_street_no: null,
      address_street: null,
      address_zip_code: null,
      address_city: null,
      address_country: null
    }
    searched_address.address_latitude = event.result.center[1]
    searched_address.address_longitude = event.result.center[0]
    let full_context = event.result.context.concat({
      id: event.result.id,
      text: event.result.text + (event.result.address ? ' ' + event.result.address : ''),
      mapbox_id: event.result.mapbox_id
    })
    let possible_country = full_context.filter(context => context.id.startsWith('country.'))
    let possible_place = full_context.filter(context => context.id.startsWith('place.'))
    if (possible_place.length) {
      searched_address.address_city = possible_place[0].text
    }
    let possible_post_code = full_context.filter(context => context.id.startsWith('postcode.'))
    if (possible_post_code.length) {
      searched_address.address_zip_code = possible_post_code[0].text
    }
    let possible_address = full_context.filter(context => context.id.startsWith('address.'))
    if (possible_address.length) {
      searched_address.address_street = possible_address[0].text
    }
    if (possible_country[0].text) {
      searched_address.address_country = possible_country[0].text
    }
    this.searchedAddress = searched_address
  }

  resetSearchedAddress(): void {
    this.searchedAddress = null
    this.geocoder.clear()
  }

  selectedInstanceIsReadyToUpdate(): boolean {
    return !!(this.searchedAddress && this.searchedAddress.address_latitude && this.searchedAddress.address_longitude)
  }

  updateSelectedInstance(): void {
    if (this.searchedAddress.address_latitude && this.searchedAddress.address_longitude) {
      if (this.selectedInstance.type == 'operation') {
        this.updateOperation(this.selectedInstance.instance as PAOperation)
      }
      if (this.selectedInstance.type == 'technician') {
        this.updateTechnician(this.selectedInstance.instance as PATechnician)
      }
    }
    this.resetSearchedAddress()
    this.selectNextInstance()
  }

  updateTechnician(technician: PATechnician): void {
    technician.coordinates.latitude = technician.location.coordinates.latitude = this.searchedAddress.address_latitude
    technician.coordinates.longitude = technician.location.coordinates.longitude = this.searchedAddress.address_longitude
    PAUtil.removeElementFromList(this.techniciansWithoutLngLat, technician)
  }

  updateOperation(operation: PAOperation): void {
    operation.ticket.coordinates.latitude = operation.ticket.coordinates.latitude = operation.ticket.client.coordinates.latitude = operation.ticket.client.location.coordinates.latitude = this.searchedAddress.address_latitude
    operation.ticket.coordinates.longitude = operation.ticket.coordinates.longitude = operation.ticket.client.coordinates.longitude = operation.ticket.client.location.coordinates.longitude = this.searchedAddress.address_longitude
    this.searchedAddress.address_city ? operation.ticket.address_city = operation.ticket.address_city = this.searchedAddress.address_city : {}
    this.searchedAddress.address_street ? operation.ticket.address_street = this.searchedAddress.address_street : {}
    this.searchedAddress.address_street_no ? operation.ticket.address_street_no = this.searchedAddress.address_street_no : {}
    this.searchedAddress.address_zip_code ? operation.ticket.address_zip = operation.ticket.address_zip = this.searchedAddress.address_zip_code : {}

    operation.getStore().fireUpdateManually()

    PAUtil.removeElementFromList(this.operationsWithoutLngLat, operation)
    this.updateOperationAddressInDB(operation, this.searchedAddress)
  }

  updateOperationAddressInDB(operation: PAOperation, address_data: {
    address_latitude: number,
    address_longitude: number,
    address_street_no?: string,
    address_street?: string,
    address_zip?: string,
    address_city?: string,
    address_country?: string
  }) {
    this.ticketService.updateTicket(operation.ticket.id.toString(), {ticket: address_data}).subscribe(
      _ => {
      },
      error => {
        console.log(error)
      }
    )
  }

  getSelectedInstanceName(): string {
    if (this.selectedInstance.type == 'operation') {
      let operation = this.selectedInstance.instance as PAOperation
      return `Ticket: ID-${operation.ticket.id}${operation.ticket.address_company ? ' - ' + operation.ticket.address_company : ''}`
    }
    if (this.selectedInstance.type == 'technician') {
      let technician = this.selectedInstance.instance as PATechnician
      return `Techniker: ID-${technician.id}${(technician.firstname && technician.lastname) ? ' - ' : ''}${technician.firstname} ${technician.lastname}`
    }
  }

  updateInsufficientInstanceAmount() {
    this.insufficientInstanceAmount = this.operationsWithoutLngLat.length + this.techniciansWithoutLngLat.length
  }

  updateHawkLink() {
    if (this.selectedInstance.type == 'operation') {
      let operation = this.selectedInstance.instance as PAOperation
      if (operation.ticket && operation.ticket.id)
        this.hawkLink = `/a/ticket/${operation.ticket.id}?operation_id=${operation.id}`
    } else {
      this.hawkLink = ''
    }
  }

}