<div class="">
    <div class="quicksettings">
        <button class="btn btn-inverted" (click)="saveState()">
        Meine config speichern
        </button>
        <button class="btn" (click)="deleteState()">config löschen</button>
        <input
        type="text"
        (input)="quicksearchChanged($event)"
        placeholder="Schnellfilter"
        />
    </div>
    <ag-grid-angular
    class="ag-theme-quartz"
    [theme]="hawkTheme" [loadThemeGoogleFonts]="true"
    style="height: 800px; width: 100%"
    [rowData]="deliveryNotes"
    [defaultColDef]="defaultColDef"
    [columnDefs]="colDefs"
    [rowClassRules]="rowClassRules"
    [pagination]="true"
    [rowSelection]="rowSelection"
    [initialState]="initialState"
    [paginationPageSize]="50"
    [localeText]="locale"
    [sideBar]="sideBarOptions"
    [paginationPageSizeSelector]="[50, 100, 300, 1000, 9000]"
    (gridReady)="onGridReady($event)"
    ></ag-grid-angular>
</div>
