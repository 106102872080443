import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { Role } from "../_models/role.interface";

@Injectable({
  providedIn: 'root'
})
export class RoleService {

  constructor(private http: HttpClient) {
  }

  getRoles() {
    const path = environment.apiURL + 'roles'
    return this.http.get<Role[]>(path)
  }

}