<div class="storeSubbox" >
  <section>
    <h2>Filialdoku bearbeiten</h2>
    <p>hier kann die Filialdoku bearbeitet werden.</p>

    <section>
      <h2>Untereinheit auswählen</h2>
      <p>Hier die Abteilung/Kasse auswählen</p>
      <p>
        ausgewählt:
        <span *ngIf="selectedStoreUnit">{{selectedStoreUnit.label}}</span>
        <span *ngIf="!selectedStoreUnit">(keine)</span>
        <button (click)="toggleUnitSelection()" *ngIf="!storeUnitSelection">Auswahl öffnen</button>
        <button (click)="toggleUnitSelection()" *ngIf="storeUnitSelection">Auswahl schließen</button>
      </p>
      <ng-container *ngIf="storeUnitSelection">
        <button (click)="createUnit()">Einheit anlegen</button>
        <p>
          <ng-container *ngFor="let unit of storeUnits">
            <button (click)="selectStoreUnit(unit)">{{unit.label}} auswählen</button>
          </ng-container>
        </p>

      </ng-container>

    </section>

    <section *ngIf="selectedStoreUnit && selectedStep">
      <p style="font-weight: bold;">Info in Abteilung übernehmen</p>
      <button (click)="transformInput()" *ngIf="!tempStoreUnit">Alles übernehmen</button>
      <button (click)="unsetTempStore()" *ngIf="!!tempStoreUnit">zurücksetzen</button>

      <p *ngIf="!selectedStoreUnit">Keine Abteilung ausgewählt</p>

      <ng-container *ngIf="selectedStoreUnit && selectedStep">
        <div class="columns">
          <div style="height: 100%;">
            <p style="font-weight: bold;">Überblick</p>
            <table class="storeUnitTable">
              <ng-container *ngTemplateOutlet="stepRowValue; context: {title: 'Store Id', key: 'store_id', mode: 'global'}"></ng-container>
              <ng-container *ngTemplateOutlet="stepRowValue; context: {title: 'Bezeichnung', key: 'label', mode: 'global'}"></ng-container>
              <ng-container *ngTemplateOutlet="stepRowValue; context: {title: 'Typ', key: 'unit_type', mode: 'global'}"></ng-container>
              <ng-container *ngTemplateOutlet="stepRowValue; context: {title: 'Nummer', key: 'unit_id', mode: 'global'}"></ng-container>
            </table>

            <p style="font-weight: bold;">Kasse</p>
            <table class="storeUnitTable">
              <ng-container *ngTemplateOutlet="stepRowValue; context: {title: 'Hersteller', key: 'vendor', mode: 'checkout'}"></ng-container>
              <ng-container *ngTemplateOutlet="stepRowValue; context: {title: 'Typ', key: 'checkout_type', mode: 'checkout'}"></ng-container>
              <ng-container *ngTemplateOutlet="stepRowValue; context: {title: 'SN', key: 'sn', mode: 'checkout'}"></ng-container>
            </table>

            <p style="font-weight: bold;">Scanner</p>
            <table class="storeUnitTable">
              <ng-container *ngTemplateOutlet="stepRowValue; context: {title: 'Hersteller', key: 'vendor', mode: 'scanner'}"></ng-container>
              <ng-container *ngTemplateOutlet="stepRowValue; context: {title: 'Typ', key: 'scanner_type', mode: 'scanner'}"></ng-container>
              <ng-container *ngTemplateOutlet="stepRowValue; context: {title: 'SN', key: 'sn', mode: 'scanner'}"></ng-container>
            </table>

            <p style="font-weight: bold;">Waage</p>
            <table class="storeUnitTable">
              <ng-container *ngTemplateOutlet="stepRowValue; context: {title: 'Hersteller', key: 'vendor', mode: 'scale'}"></ng-container>
              <ng-container *ngTemplateOutlet="stepRowValue; context: {title: 'Typ', key: 'scale_type', mode: 'scale'}"></ng-container>
              <ng-container *ngTemplateOutlet="stepRowValue; context: {title: 'SN', key: 'sn', mode: 'scale'}"></ng-container>

              <ng-container *ngTemplateOutlet="stepRowValue; context: {title: 'letzte Eichung', key: 'last_calibration', mode: 'global'}"></ng-container>
              <tr>
                <td style="width:50%">Eichungen</td>
                <td style="width:50%" *ngIf="tempStoreUnit">
                  <span *ngFor="let calibration of tempStoreUnit.additional_information?.scale_calibrations">{{calibration.year}} ({{translateCalibrationType(calibration.calibration_type)}}), </span></td>
              </tr>
            </table>

            <p style="font-weight: bold;">Telefonanlage</p>
            <table class="storeUnitTable">
              <ng-container *ngTemplateOutlet="stepRowValue; context: {title: 'Hersteller', key: 'vendor', mode: 'phone'}"></ng-container>
              <ng-container *ngTemplateOutlet="stepRowValue; context: {title: 'Typ', key: 'ps_type', mode: 'phone'}"></ng-container>
              <ng-container *ngTemplateOutlet="stepRowValue; context: {title: 'SN', key: 'sn', mode: 'phone'}"></ng-container>
            </table>

            <p style="font-weight: bold;">Bilder</p>
            <p *ngIf="combineImages().length === 0">noch keine Bilder hinterlegt</p>
            <table class="storeUnitTable">
              <ng-container *ngFor="let image of combineImages()">
                <ng-container *ngTemplateOutlet="stepImage; context: {image: image}"></ng-container>
              </ng-container>
            </table>
          </div>
          <div>
            <p style="font-weight: bold;">Schrittinfo für: {{selectedStep.name}}</p>
            <table >
              <tr *ngFor="let step of selectedStep.steps">
                <td>{{step.name}}</td>
                <td>{{step.value}}</td>
              </tr>
            </table>
            <button (click)="debugggg(selectedStep)">debug</button>
          </div>
        </div>
        <div class="columns">
          <p><button (click)="saveToServer()">Auf Server speichern</button></p>
          <p><button (click)="unselectStoreUnit()">Ansicht schließen</button></p>
        </div>
        <div class="columns">
          <pre>
            selectedStoreUnit:<br>
            {{selectedStoreUnit | json}}
          </pre>
          <pre>
            tempStoreUnit:<br>
            {{tempStoreUnit | json}}
          </pre>
        </div>
      </ng-container>

    </section>
  </section>
</div>

<ng-template #stepRowValue let-key='key' let-title='title' let-mode='mode'>
  <tr>
    <td style="width:50%">{{title}}</td>
    <td style="width:50%">
      <ng-container *ngIf="docuValueDiffers(mode, key); else noChange" >
        <ng-container *ngIf="!docuHasNoValue; else noValueDelete">
          <span class="deleted">{{docuGetStoreUnitValue(mode,key)}}</span>
        </ng-container>
        <br><span class="updated">{{docuGetTmpStoreUnitValue(mode,key)}}</span>
      </ng-container>
      <ng-template #noChange>
        <ng-container *ngIf="!docuHasNoValue(mode, key); else noValue">
          <span class="unchanged">{{docuGetStoreUnitValue(mode,key)}}</span>
        </ng-container>
      </ng-template >
      <ng-template #noValue>
        <span class="unchanged">keine Angabe</span>
      </ng-template >
      <ng-template #noValueDelete>
        <span class="deleted">keine Angabe</span>
      </ng-template >
    </td>
  </tr>
</ng-template>

<ng-template #stepImage let-image='image'>
  <tr>
    <td colspan="2">{{image.title}}</td>
  </tr>
  <tr>
    <td style="width:50%" *ngIf="image.current_path">
      <a [href]="image.current_path" target="_blank"><img style="width: 50%" [attr.src]="image.current_path" /> </a>
    </td>
    <td style="width:50%" *ngIf="!image.current_path" class="deleted">
      kein Bild
    </td>
    <td style="width:50%" *ngIf="image.new_path">
      <a [href]="image.new_path" target="_blank"><img style="width: 50%" [attr.src]="image.new_path" /> </a>
    </td>
    <td style="width:50%" *ngIf="!image.new_path" class="updated">
      kein Bild
    </td>
  </tr>
</ng-template>
