import { Component } from '@angular/core';
import { APTechnicianHash } from "../../_models/technician.interface";
import { UserService } from "../../_services";
import { ActivatedRoute, Router, RouterLink } from "@angular/router";
import { faArrowLeft, faEdit, faIndustry, faKey, faPhone, faTrash, faUser } from '@fortawesome/free-solid-svg-icons';
import { faSignIn } from "@fortawesome/pro-solid-svg-icons";
import { faProductHunt } from "@fortawesome/free-brands-svg-icons";
import { NgIf, NgFor } from '@angular/common';

import { NgxMapboxGLModule } from 'ngx-mapbox-gl';
import { MatTabGroup, MatTab } from '@angular/material/tabs';
import { MatSlideToggle } from '@angular/material/slide-toggle';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatTooltip } from '@angular/material/tooltip';
import { CommonModule } from "@angular/common";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { FaIconComponent, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { RecordNavigationComponent } from "../_shared/record-navigation/record-navigation.component";

@Component({
  selector: 'hawk-user-show',
  templateUrl: './user-show.component.html',
  styleUrls: ['./user-show.component.scss', '../_shared/styles/common-styles.scss'],
  standalone: true,
  imports: [NgIf, RecordNavigationComponent, FaIconComponent, NgFor, NgxMapboxGLModule, MatTabGroup, MatTab, MatSlideToggle, ReactiveFormsModule, FormsModule, MatButton, MatTooltip, CommonModule, MatSlideToggleModule, FontAwesomeModule, RouterLink]
})
export class UserShowComponent {

  protected readonly faUser = faUser;

  user: APTechnicianHash
  ctrlIsPressed: boolean = false

  showOnlyActiveProjects: boolean = true
  userIsDeletable: boolean = false

  constructor(
    public userService: UserService,
    public route: ActivatedRoute,
    private router: Router
  ) {
    document.body.onkeydown = (event) => {
      if (event.ctrlKey) this.ctrlIsPressed = event.ctrlKey;
    }
    document.body.onkeyup = (event) => {
      if (!event.ctrlKey) this.ctrlIsPressed = event.ctrlKey;
    }
  }

  ngOnInit(): void {
    const id = Number.parseInt(this.route.snapshot.paramMap.get('id'))
    this.userService.getAPUser(id).subscribe(
      user => {
        this.user = user
      },
      err => {
        console.log(err)
      }
    )

    this.userService.userIsDeletable(id).subscribe(
      data => {
        this.userIsDeletable = data.deletable
      },
      err => {
        console.log(err)
      }
    )
  }

  toggleUserActivation() {
    this.setUserActivation(!this.user.active)
  }

  setUserActivation(active: boolean): void {
    this.userService.updateUser(this.user.id, {active}).subscribe(
      user => {
        this.user = user
      },
      err => {
        console.log(err)
      }
    )
  }

  deleteUser(): void {
    this.userService.deleteUser(this.user.id).subscribe(
      _ => {
        this.router.navigate(['/benutzer'])
      },
      err => {
        console.log(err)
      }
    )
  }

  protected readonly faProductHunt = faProductHunt;
  protected readonly faPhone = faPhone;
  protected readonly faEdit = faEdit;
  protected readonly faKey = faKey;
  protected readonly faArrowLeft = faArrowLeft;
  protected readonly faSignIn = faSignIn;
  protected readonly faTrash = faTrash;
  protected readonly faIndustry = faIndustry;
}