<div class="d-flex align-items-center" *ngIf="isLoaded">
    <div class="col t-header d-flex align-items-center justify-content-start">
        <h4 class="mb20 d-flex align-items-center gap-1">
            <i class="fa fa-truck mr6" aria-hidden="true"></i>
            <ul class="list-group list-group-horizontal b-custom">
                <li class="list-group-item bg-transparent border-0">
                    <a
                    class="g-link"
                    [routerLink]="['/', 'lieferscheine', 'suche', 'index']"
                    >
                    Lieferscheine
                    </a>
                </li>
                <li class="list-group-item bg-transparent border-0">
                    <span>Neuer Lieferschein</span>
                </li>
            </ul>
        </h4>
    </div>
</div>
<div class="ticketPage" *ngIf="isLoaded && wareHouses && countries">
    <section class="main-content full-width">
        <form [formGroup]="addressesForm" >
            <header class="columns">
                <section>
                    <h2>Absender</h2>
                    <hawk-address-auto-fill
                    [countries]="countries"
                    [wareHouses]="wareHouses"
                    [customers]="senderCustomers"
                    [technicians]="technicians"
                    [loadingCustomers]="senderCustomersLoading"
                    [loading]="senderLoading"
                    [filteredStores]="senderStores"
                    [formGroup]="addressesForm"
                    prefix="sender_"
                    [senderRecieverTypes]="senderRecieverTypes"
                    (onAddressTypeChangeClicked)="onAddressTypeChange($event, 'sender_')"
                    (onAddressChangeClicked)="onAddressChange($event, 'sender_')"
                    (onChangeCustomerClicked)="onChangeCustomer($event, 'sender_')"
                    (onFetchMoreStores)="onFetchMoreStores('sender_')"
                    (onFetchMoreCustomers)="onFetchMoreCustomers('sender_')"
                    ></hawk-address-auto-fill>
                </section>
                <section>
                    <h2>Empfänger</h2>
                    <hawk-address-auto-fill
                    [countries]="countries"
                    [wareHouses]="wareHouses"
                    [customers]="recipientCustomers"
                    [technicians]="technicians"
                    [filteredStores]="recipientStores"
                    [loadingCustomers]="recipientCustomersLoading"
                    [loading]="recipientLoading"
                    [formGroup]="addressesForm"
                    prefix="recipient_"
                    [senderRecieverTypes]="senderRecieverTypes"
                    (onAddressTypeChangeClicked)="onAddressTypeChange($event, 'recipient_')"
                    (onAddressChangeClicked)="onAddressChange($event, 'recipient_')"
                    (onChangeCustomerClicked)="onChangeCustomer($event, 'recipient_')"
                    (onFetchMoreStores)="onFetchMoreStores('recipient_')"
                    (onFetchMoreCustomers)="onFetchMoreCustomers('recipient_')"
                    ></hawk-address-auto-fill>
                </section>
            </header>
        </form>
    </section>
    <section class="main-content full-width">
        <form [formGroup]="deliveryNoteForm" (submit)="saveDeliveryNote()">
            <header class="columns">
                <!-- Sender Form -->
                <section>
                    <hawk-address-form [formGroup]="deliveryNoteForm" prefix="sender" [countries]="countries"></hawk-address-form>
                </section>
                <!-- Recipient Form -->
                <section>
                    <hawk-address-form [formGroup]="deliveryNoteForm" prefix="recipient" [countries]="countries"></hawk-address-form>
                </section>
            </header>
            <section>
                <h2>RMAs einscannen <small class="ml6"><kbd>Enter</kbd> für nächsten Artikel</small></h2>
                <div formGroupName="rma">
                    <div class="form-group gap-1 d-flex align-items-center" *ngFor="let rma of rmas.controls; let i=index">
                        <div class="d-flex gap-2 align-items-center col-4" [formGroupName]="i">
                            <mat-form-field appearance="outline">
                                <mat-label>RMA</mat-label>
                                <input
                                    #rmaInput
                                    matInput
                                    type="text"
                                    [value]="setRmasDefaultValue && rma.value && rma.value.id > 0 ? rma.value.rma : ''"
                                    placeholder="RMA"
                                    (keydown.enter)="onRmaKeyDown($event, i)"
                                />
                            </mat-form-field>
                            <div *ngIf="rmas.controls.length > 1 && rmas.controls[i + 1] != null">
                                <a (click)="onDeleteRma(i)" style="padding-bottom: 1.34375em;" class="d-flex deleteRMA"><i class="fas fa-trash-alt"></i></a>
                            </div>
                        </div>
                        <div style="padding-bottom: 1.34375em;" class="d-flex gap-1 align-items-center col-6" *ngIf="rma.value && rma.value.id && rma.value.id > 0">
                            {{rma.value.rma}}, {{rma.value.template_description}}, {{rma.value.product_category}}, {{rma.value.vendor}}, {{rma.value.serial_number}}
                            <span class='label label-danger label-own ml10' *ngIf="rma.value.location == 'SHIPPING'">
                                Im Versand
                            </span>
                            <span class='label label-danger label-own ml10' *ngIf="rma.value.location == 'CONSUMED'">
                                Verbraucht
                            </span>
                            <span class='label label-success label-own ml10' *ngIf="!['SHIPPING', 'CONSUMED'].includes(rma.value.location)">
                                OK
                            </span>
                        </div>
                        <div style="padding-bottom: 1.34375em;" class="d-flex gap-1 align-items-center col-6" *ngIf="rma.value && rma.value.id && rma.value.id == -1">
                            <span class='label label-danger label-own'>Nicht gefunden</span>
                        </div>
                    </div>
                </div>
            </section>
            <section>
                <div class="form-group">
                    <mat-form-field class="col-6" appearance="outline">
                        <mat-label>Infofeld</mat-label>
                        <textarea formControlName="info" matInput></textarea>
                    </mat-form-field>
                </div>
                <div class="form-group">
                    <mat-form-field class="col-6" appearance="outline">
                        <mat-label>Auftragsnummer</mat-label>
                        <input
                            matInput
                            type="text"
                            placeholder="Auftragsnummer"
                            formControlName="order_number"
                        />
                    </mat-form-field>
                </div>
            </section>
            <div class="d-flex mx-2 align-items-center justify-content-between">
                <button mat-raised-button class="button" [disabled]="deliveryNoteForm.invalid || submittingNote" type="submit">
                    Speichern und Lieferschein ausdrucken
                </button>
                <a *ngIf="!deliveryNoteId" class="g-link text-right" [routerLink]="['/', 'lieferscheine', 'suche', 'index']" >Abbrechen</a>
                <a *ngIf="deliveryNoteId" class="g-link text-right" [routerLink]="['/', 'lieferscheine', deliveryNoteId]" >Abbrechen</a>
            </div>
        </form>
    </section>
</div>