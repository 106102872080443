
import { PAOperation } from "../classes/operation";
import { PAStore } from "../classes/store";
import * as mapboxgl from "mapbox-gl";
import { PAMapControl } from "./pa-map-control";

export class PAStoreControl {

  private static _instance: PAStoreControl
  public static get Instance()
  {
    return this._instance || (this._instance = new this());
  }

  private constructor(
  ) {
  }

  public selectOperationsStore(operation: PAOperation): void {
    let store = PAStore.getOperationsStore(operation)
    if (store) {
      this.selectStore(store, { show_operation: operation })
    }
  }

  public updateSelectedStore(store: PAStore): void {
    if (store) {
      this.selectStore(store, { show_operation: store.selectedStoreOperation })
    }
  }

  public selectStoreOperation(operation: PAOperation): void {
    PAStore.selectedStoreInMap.selectedStoreOperation = operation
  }

  public selectStore(store: PAStore, options?: {show_operation?: PAOperation, center_in_map?: mapboxgl.Map } ): void {

    let show_operation = options?.show_operation

    let same_store = PAStore.selectedStoreInMap == store
    if (same_store && !show_operation) {
      show_operation = store.selectedStoreOperation
    }

    this.deselectSelectedStore()
    PAStore.selectedStoreInMap = store
    store.fireUpdateManually()
    const has_operations_to_show = store.shownMapOperations.length > 0

    if (has_operations_to_show) {
      if (!show_operation) {
        show_operation = store.shownMapOperations[0]
      }
    }

    store.selectedStoreOperation = show_operation
    PAMapControl.Instance.setStoresDrivingIso(store)
    if (options?.center_in_map) store.centerInMap(options.center_in_map)
  }

  public closeStoreMenu(): void {
    this.deselectSelectedStore()
  }

  public deselectSelectedStore(): void {
    const selected_store_before = PAStore.selectedStoreInMap
    if (PAStore.selectedStoreInMap) {
      PAStore.selectedStoreInMap = null
      selected_store_before.fireUpdateManually()
    }
  }
}