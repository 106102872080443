import { inject, Injectable } from '@angular/core'
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http'
import { Observable, throwError } from 'rxjs'
import { catchError } from 'rxjs/operators'

import { SessionService } from '../_services'

@Injectable({
  providedIn: 'root'
})
export class ErrorInterceptor implements HttpInterceptor {
  
  sessionService: SessionService = inject(SessionService)
  
  constructor() {}

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(catchError((err) => {
      if (err.status === 401) {
        // auto logout if 401 response returned from api
        this.sessionService.logout()
      }

      const error = err.error.message || err.statusText
      return throwError(error)
    }))
  }
}
