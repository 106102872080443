import { Observable } from "rxjs"
import { PAUtil } from "./util"
import { share } from "rxjs/operators";

export class PAProgress {

  static runningProgresses: PAProgress[] = []
  static finishedProgresses: PAProgress[] = []

  runningSubProgresses: PASubProgress<any>[] = []
  successSubProgresses: PASubProgress<any>[] = []
  errorSubProgresses: PASubProgress<any>[] = []

  completionPercentage = 0

  constructor(public name: string, public subProgressAmount: number) {
    PAProgress.runningProgresses.push(this)
  }

  public addSubProgress<T>(observable: Observable<T>, name: string): Observable<T> {
    let shared_obs = observable.pipe(share())
    new PASubProgress<T>(shared_obs, this, name)
    return shared_obs
  }

  async removeProgress(delay_ms: number): Promise<void> {
    await PAUtil.sleep(delay_ms)
    const index = PAProgress.runningProgresses.indexOf(this)
    if (index >= 0) {
      PAProgress.runningProgresses.splice(index, 1)
    }
  }

  async updateCompletionStatus(): Promise<void> {
    this.completionPercentage = ((this.successSubProgresses.length + this.errorSubProgresses.length) / this.subProgressAmount) * 100
    if (this.completionPercentage == 100) {
      await this.removeProgress(200)
      PAProgress.finishedProgresses.push(this)
    }
  }

}

export class PASubProgress<T> {

  constructor(public observable: Observable<T>, public progress: PAProgress, public name: string) {
    progress.runningSubProgresses.push(this)
    observable.subscribe(
      _ => {
        this.markSubProgressAsSuccessAndRemoveAfterDuration(200)
      },
      _ => {
        this.markSubProgressAsErrorAndRemoveAfterDuration(200)
      }
    )
  }

  removeFromParentProgress(): void {
    const index = this.progress.runningSubProgresses.indexOf(this)
    if (index >= 0) {
      this.progress.runningSubProgresses.splice(index, 1)
    }
  }

  async markSubProgressAsSuccessAndRemoveAfterDuration(delay_ms: number) {
    await PAUtil.sleep(delay_ms)
    this.removeFromParentProgress()
    this.progress.successSubProgresses.push(this)
    this.progress.updateCompletionStatus()
  }

  async markSubProgressAsErrorAndRemoveAfterDuration(delay_ms: number) {
    await PAUtil.sleep(delay_ms)
    this.removeFromParentProgress()
    this.progress.errorSubProgresses.push(this)
    this.progress.updateCompletionStatus()
  }

}