import { Component } from '@angular/core';
import { CustomerService, UserService } from "../../../_services";
import { RoleService } from "../../../_services/role.service";
import { ActivatedRoute, Router, RouterLink } from "@angular/router";
import { AbstractControl, FormBuilder, UntypedFormControl, UntypedFormGroup, ValidatorFn, Validators, ReactiveFormsModule, FormsModule } from "@angular/forms";
import { NgIf } from '@angular/common';
import { MatFormField, MatLabel, MatError } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatButton } from '@angular/material/button';
import { CommonModule } from "@angular/common";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { APTechnicianHash } from "../../../_models/technician.interface";
import { RecordNavigationComponent } from "../../_shared/record-navigation/record-navigation.component";

@Component({
  selector: 'hawk-user-change-password',
  templateUrl: './user-change-password.component.html',
  styleUrls: ['./user-change-password.component.scss', '../../_shared/styles/common-styles.scss'],
  standalone: true,
  imports: [NgIf, RecordNavigationComponent, ReactiveFormsModule, MatFormField, MatLabel, MatInput, MatError, MatCheckbox, FormsModule, MatButton, CommonModule, MatFormFieldModule, MatCheckboxModule, RouterLink]
})
export class UserChangePasswordComponent {

  user: APTechnicianHash

  passwordForm: UntypedFormGroup
  sendEmail: boolean = false

  constructor(
    public userService: UserService,
    public customerService: CustomerService,
    public roleService: RoleService,
    public route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private router: Router
  ) {
  }

  ngOnInit(): void {

    this.passwordForm = this.formBuilder.group(
      {
        new_password: new UntypedFormControl({value: ''}, Validators.required),
        password_confirm: new UntypedFormControl({value: ''}, Validators.required),
      },
      {
        validators: this.matchValidator('new_password', 'password_confirm')
      }
    );

    const id = Number.parseInt(this.route.snapshot.paramMap.get('id'))
    this.loadUser(id)
    this.resetForm()
  }

  matchValidator(controlName: string, matchingControlName: string): ValidatorFn {
    return (abstractControl: AbstractControl) => {
      const control = abstractControl.get(controlName);
      const matchingControl = abstractControl.get(matchingControlName);

      if (matchingControl!.errors && !matchingControl!.errors?.['confirmedValidator']) {
        return null;
      }

      if (control!.value !== matchingControl!.value) {
        const error = { confirmedValidator: 'Passwords do not match.' };
        matchingControl!.setErrors(error);
        return error;
      } else {
        matchingControl!.setErrors(null);
        return null;
      }
    }
  }

  loadUser(id: number): void {
    this.userService.getAPUser(id).subscribe(
      user => {
        this.user = user
      },
      err => {
        console.log(err)
      }
    )
  }

  resetForm():void {
    this.passwordForm.setValue({
      new_password: '',
      password_confirm: '',
    });
  }

  onSubmit(): void {
    this.userService.updateUser(this.user.id, { password: this.passwordForm.controls['new_password'].value }, this.sendEmail).subscribe(
      user => {
        this.user = user
        this.router.navigate(['/benutzer', this.user.id])
      },
      err => {
        console.log(err)
      }
    )
  }
}