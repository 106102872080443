<div id="settings_container">
  <div id="setting_selection_container">
    <ul style="flex: 1;">
      <li *ngFor="let setting of PASettingsControl.Instance.settingTabs" class="setting" [ngClass]="PASettingsControl.Instance.activeSettingsTab == setting ? 'selected' : ''" (click)="PASettingsControl.Instance.activeSettingsTab = setting">
        <div>{{setting}}</div>
      </li>
    </ul>
    <div class="vertical_flex center_content_horizontally">
      <button mat-raised-button color="primary"
              [disabled]="!PASettingsControl.Instance.settingsHaveChanged()"
              (click)="PASettingsControl.Instance.createOrUpdateSettingsInDatabase()"
      >
        Als Standard speichern
      </button>
    </div>
  </div>
  <div id="selected_setting_container">
    <ng-container *ngIf="PASettingsControl.Instance.activeSettingsTab == 'Allgemein'">
      <div style="display: inline-flex; flex-direction: column;">
        <h6>Wochentage ({{selectedWeekDaysToString()}})</h6>

        <div style="overflow: visible; display: inline-flex; flex-direction: row;">
          <div class="expand black_gradient_background_no_hover horizontal_flex">
            <div class="weekday black_gradient_background" [ngClass]="PASettingsControl.Instance.selectedSettingConfig.data.general.show_weekdays.monday ? 'selected' : ''" (click)="toggleWeekday('mo')">
              Mo
            </div>
            <div class="weekday black_gradient_background" [ngClass]="PASettingsControl.Instance.selectedSettingConfig.data.general.show_weekdays.tuesday ? 'selected' : ''" (click)="toggleWeekday('di')">
              Di
            </div>
            <div class="weekday black_gradient_background" [ngClass]="PASettingsControl.Instance.selectedSettingConfig.data.general.show_weekdays.wednesday ? 'selected' : ''" (click)="toggleWeekday('mi')">
              Mi
            </div>
            <div class="weekday black_gradient_background" [ngClass]="PASettingsControl.Instance.selectedSettingConfig.data.general.show_weekdays.thursday ? 'selected' : ''" (click)="toggleWeekday('do')">
              Do
            </div>
            <div class="weekday black_gradient_background" [ngClass]="PASettingsControl.Instance.selectedSettingConfig.data.general.show_weekdays.friday ? 'selected' : ''" (click)="toggleWeekday('fr')">
              Fr
            </div>
            <div class="weekday black_gradient_background" [ngClass]="PASettingsControl.Instance.selectedSettingConfig.data.general.show_weekdays.saturday ? 'selected' : ''" (click)="toggleWeekday('sa')">
              Sa
            </div>
            <div class="weekday black_gradient_background" [ngClass]="PASettingsControl.Instance.selectedSettingConfig.data.general.show_weekdays.sunday ? 'selected' : ''" (click)="toggleWeekday('so')">
              So
            </div>
          </div>
        </div>
      </div>

      <div style="display: inline-flex; flex-direction: column; margin-top: 24px;">
        <h6>Karte</h6>

        <div style="overflow: visible; display: inline-flex; flex-direction: column; width: fit-content">
          <div style="display: inline-flex; flex-direction: row; background-color: black; flex-wrap: wrap; width: fit-content;">
            <div class="black_gradient_background" (click)="PASettingsControl.Instance.selectedSettingConfig.data.general.map.show_operation_times = !PASettingsControl.Instance.selectedSettingConfig.data.general.map.show_operation_times" style="font-size: .8rem; border-radius: 2px; padding: 8px; color: white; flex: 1; margin: 1px; display: inline-flex;">
              <mat-slide-toggle style="margin: auto auto auto 0;"
                                [(ngModel)]="PASettingsControl.Instance.selectedSettingConfig.data.general.map.show_operation_times"
                                (click)="$event.stopPropagation()">
                <div style="white-space: nowrap;">
                  Zeige Auftragszeiten
                </div>
              </mat-slide-toggle>
            </div>
            <div class="black_gradient_background" (click)="PASettingsControl.Instance.selectedSettingConfig.data.general.map.seperated_tour_view = !PASettingsControl.Instance.selectedSettingConfig.data.general.map.seperated_tour_view" style="font-size: .8rem; border-radius: 2px; padding: 8px; color: white; flex: 1; margin: 1px; display: inline-flex;">
              <mat-slide-toggle style="margin: auto auto auto 0;"
                                [(ngModel)]="PASettingsControl.Instance.selectedSettingConfig.data.general.map.seperated_tour_view"
                                (click)="$event.stopPropagation()">
                <div style="white-space: nowrap;">
                  {{PASettingsControl.Instance.selectedSettingConfig.data.general.map.seperated_tour_view ? 'Tourenansicht: Einzeln' : 'Tourenansicht: Kombiniert'}}
                </div>
              </mat-slide-toggle>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="PASettingsControl.Instance.activeSettingsTab == 'Planung'">
      <div style="display: inline-flex; flex-direction: column;">
        <h6>Auftragsplanung</h6>
        <div class="horizontal_flex center_content_vertically">
          <div class="black_gradient_background" (click)="PASettingsControl.Instance.selectedSettingConfig.data.tour_planning.operation_planning.show_close_technician_tours = !PASettingsControl.Instance.selectedSettingConfig.data.tour_planning.operation_planning.show_close_technician_tours" style="font-size: .8rem; border-radius: 2px; width: fit-content; border: 1px solid black; padding: 8px; color: white; margin: 1px; display: inline-flex;">
            <mat-slide-toggle style="margin: auto 0 auto 0;" [(ngModel)]="PASettingsControl.Instance.selectedSettingConfig.data.tour_planning.operation_planning.show_close_technician_tours"
                              (click)="$event.stopPropagation()">
              <div style="white-space: nowrap;">
                Zeige Techniker-Touren in der Nähe
              </div>
            </mat-slide-toggle>
          </div>
          <div style="background: white; color: black; margin-left: 32px; width: 180px; padding: 8px; border-radius: 4px;"
               [style.opacity]="PASettingsControl.Instance.selectedSettingConfig.data.tour_planning.operation_planning.show_close_technician_tours ? '1' : '.6'"
          >
            <mat-form-field style="width: 100%;" class="outline-small no-subscript" appearance="outline">
              <mat-label>Entfernung (km)</mat-label>
              <input type="number" matInput min="0" [disabled]="!PASettingsControl.Instance.selectedSettingConfig.data.tour_planning.operation_planning.show_close_technician_tours" [(ngModel)]="PASettingsControl.Instance.selectedSettingConfig.data.tour_planning.operation_planning.show_close_technician_tours_distance">
            </mat-form-field>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="PASettingsControl.Instance.activeSettingsTab == 'Reisetechniker'">
      <div style="display: inline-flex; flex-direction: column;">
        <h6>Hotelübernachtung - Auftragsgenerierung</h6>
        <div style="background-color: white; color: black; padding: 8px; border-radius: 4px; width: fit-content;">
          <mat-form-field class="outline-small no-subscript" appearance="outline">
            <mat-label>Standard Priorität</mat-label>
            <mat-select [(ngModel)]="PASettingsControl.Instance.selectedSettingConfig.data.travel_technicians.hotel_overnight_stay.default_priority_id">
              <mat-option *ngFor="let priority of PADataControl.Instance.getProject(133).priorities" [value]="priority.id">
                {{priority.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="PASettingsControl.Instance.activeSettingsTab == 'Rampups (extra Zeit)'">
      <hawk-additional-priority-time style="height: 100%;"
              [technicians]="PAFilterControl.Instance.globalFilteredTechnicians"
              [projects]="PADataControl.Instance.loadedProjects"
              [config]="PASettingsControl.Instance.currentPriorityTimeRuleConfig"></hawk-additional-priority-time>
    </ng-container>
    <ng-container *ngIf="PASettingsControl.Instance.activeSettingsTab == 'Projekte'">

    </ng-container>
  </div>
</div>
