
import { MapBoxService, Route } from "src/app/_services/mapbox.service";
import { MapRoute as AirRoute } from "src/app/_models/planning-assistant.interface"
import { PACoordinates } from "./coordinates";
import { PADistance } from "./distance";
import { PAOperation } from "./operation";
import { PAUtil } from "./util";
import { PADataControl } from "../singletons/pa-data-control";

export class PALocation implements CalculatesDistance {

  static mapBoxService: MapBoxService

  public distancesToLocations = new Map<PALocation, Route>()
  public loadingRoutesToLocations: PALocation[] = []

  constructor(
    public coordinates: PACoordinates,
    public location_id: number,
    public zip_code: string,
    public street: string,
    public city: string
  ) {
    PADataControl.Instance.locationMap.set(location_id, this)
  }

  public getDistanceToCoordinatesAsTheCrowFlies(lat: number, lon: number): number {
    return PAUtil.calcDistanceAsTheCrowFlies(this.coordinates.latitude, this.coordinates.longitude, lat, lon)
  }

  getDistanceToLocationAsTheCrowFlies(location: PALocation): number {
    return this.getDistanceToCoordinatesAsTheCrowFlies(location.coordinates.latitude, location.coordinates.longitude)
  }

  public async getStreetDistanceToCoordinates(lat: number, lon: number): Promise<{
    distance: number,
    duration: number
  }> {
    const distance_key = PADistance.coordinatesToDistanceKey(this.coordinates.latitude, this.coordinates.longitude, lat, lon)
    if (!PADistance.realPositionDistances.has(distance_key)) {
      await this.loadDistancesToCoordinates(lat, lon)
    }
    return PADistance.realPositionDistances.get(distance_key)
  }

  public async getDistanceToLocation(to_location: PALocation, ignore_loaded_geometry_check?: boolean): Promise<Route> {

    if (this == to_location || (to_location.coordinates.latitude == this.coordinates.latitude && to_location.coordinates.longitude == this.coordinates.longitude)) {
      return {distance: 0, duration: 0, geometry: {coordinates: []}}
    }

    let use_route: Route = null

    let local_storage_key = PALocation.routeLocalStorageKey(this, to_location)
    let local_storage_value = localStorage.getItem(local_storage_key)
    let stored_route = (local_storage_value ? JSON.parse(local_storage_value) as Route : null)
    let session_route = this.distancesToLocations.get(to_location)
    if (stored_route && !session_route) {
      this.setDistanceToLocation(to_location, stored_route)
      use_route = stored_route
    } else if (session_route) {
      use_route = session_route
    }

    const invalid_route = !Number.isFinite(use_route?.distance) || !Number.isFinite(use_route?.duration)
    const route_has_loaded_geometry = use_route?.geometry

    return (use_route && !invalid_route && (ignore_loaded_geometry_check || route_has_loaded_geometry))? use_route : await this.loadDistanceToLocation(to_location)
  }

  public async loadDistanceToLocation(to_location: PALocation): Promise<Route> {
    if (this.loadingRoutesToLocations.includes(to_location)) {
      while (!this.distancesToLocations.has(to_location) && this.loadingRoutesToLocations.includes(to_location)) {
        await PAUtil.sleep(100)
      }
      if (this.distancesToLocations.has(to_location)) {
        return this.distancesToLocations.get(to_location)
      } else {
        return {distance: -1, duration: -1, geometry: {coordinates: []}}
      }
    } else {
      this.loadingRoutesToLocations.push(to_location)
      return new Promise(resolve => {
        PALocation.mapBoxService.directionWaypoints(this.coordinates, to_location.coordinates).subscribe(
          (data) => {
            if (data.routes[0]) {
              const route: Route = {
                distance: Math.round(data.routes[0].distance / 1000),
                duration: Math.round(data.routes[0].duration / 60),
                geometry: {
                  coordinates: data.routes[0].geometry.coordinates
                }
              }
              this.saveRouteToLocation(to_location, route, true);
              PAUtil.removeElementFromList(this.loadingRoutesToLocations, to_location)
              resolve(route)
            } else {
              PAUtil.removeElementFromList(this.loadingRoutesToLocations, to_location)
              resolve({distance: -1, duration: -1, geometry: {coordinates: []}})
            }
          },
          (err) => {
            console.log(err)
            PAUtil.removeElementFromList(this.loadingRoutesToLocations, to_location)
            resolve({distance: -1, duration: -1, geometry: {coordinates: []}})
          }
        )
      })
    }

  }

  private saveRouteToLocation(to_location: PALocation, route: Route, override_route?: boolean) {
    let local_storage_key = PALocation.routeLocalStorageKey(this, to_location)
    if (override_route || !(this.distancesToLocations.has(to_location) || localStorage.getItem(local_storage_key))) {
      this.setDistanceToLocation(to_location, route)
      to_location.setDistanceToLocation(this, route)
      let inverse_local_storage_key = PALocation.routeLocalStorageKey(this, to_location)
      const route_string = JSON.stringify(route)
      PAUtil.setLocalStorageItem(local_storage_key, route_string)
      PAUtil.setLocalStorageItem(inverse_local_storage_key, route_string)
    }
  }

  public setDistanceToLocation(to_location: PALocation, distance: Route) {
    this.distancesToLocations.set(to_location, distance)
  }

  public distanceToLocationAsTheCrowFliesString(to_location: PALocation): string {
    let distance = this.getDistanceToLocationAsTheCrowFlies(to_location)
    return `${Math.round(distance)}km`
  }

  async loadDistancesToCoordinates(lat: number, long: number): Promise<boolean> {
    const distance_key = PADistance.coordinatesToDistanceKey(this.coordinates.latitude, this.coordinates.longitude, lat, long)
    const inverse_distance_key = PADistance.coordinatesToDistanceKey(lat, long, this.coordinates.latitude, this.coordinates.longitude)
    if (typeof localStorage[distance_key] != 'undefined') {
      let storage_string: String = localStorage[distance_key]
      let distance_string = storage_string.split(';')
      PADistance.realPositionDistances.set(distance_key, {
        distance: Number.parseFloat(distance_string[0]),
        duration: Number.parseFloat(distance_string[1])
      })
      PADistance.realPositionDistances.set(inverse_distance_key, {
        distance: Number.parseFloat(distance_string[0]),
        duration: Number.parseFloat(distance_string[1])
      })
    } else {
      return new Promise((resolve) => {
        PAOperation.operationService.getDistanceBetweenCoordinates(this.coordinates.latitude, this.coordinates.longitude, lat, long).subscribe(
          (data) => {
            let operation_to_coordinates_key = PADistance.coordinatesToDistanceKey(lat, long, this.coordinates.latitude, this.coordinates.longitude)
            let inverse_operation_to_coordinates_key = PADistance.coordinatesToDistanceKey(this.coordinates.latitude, this.coordinates.longitude, lat, long)
            const distance = Math.round(data.distance)
            const duration = Math.round(data.duration)
            PADistance.realPositionDistances.set(operation_to_coordinates_key, {distance: distance, duration: duration})
            PADistance.realPositionDistances.set(inverse_operation_to_coordinates_key, {
              distance: distance,
              duration: duration
            })
            localStorage[operation_to_coordinates_key] = distance.toString() + ';' + duration.toString()
            localStorage[inverse_operation_to_coordinates_key] = distance.toString() + ';' + duration.toString()
            resolve(true)
          },
          (err) => {
            console.error(err)
            resolve(false)
          }
        )
      })
    }
  }

  public getAirDistanceRouteToLocation(location: PALocation, color: string): AirRoute {
    return {
      from: this,
      to: location,
      color: color,
      line_width: 4,
      dashed: true,
      popup_text: ''
    }
  }

  public getSyncStreetDistanceToLocation(location: PALocation): number {
    if (this.distancesToLocations.has(location)) {
      return this.distancesToLocations.get(location).distance
    } else {
      return -1
    }
  }

  public getSyncStreetDurationToLocation(location: PALocation, multiply_factor?: number): number {
    if (this.distancesToLocations.has(location)) {
      return Math.round(this.distancesToLocations.get(location).duration * (multiply_factor || 1))
    } else {
      return -1
    }
  }

  isInTourRadius(coordinates: number[][], radius: number, route_search_depth?: number): boolean {

    for (let coordinate of [coordinates[0], coordinates[coordinates.length - 1]]) {
      let distance = PAUtil.calcDistanceAsTheCrowFlies(coordinate[1], coordinate[0], this.coordinates.latitude, this.coordinates.longitude)
      if (distance <= radius) {
        return true
      }
    }

    // binary search like algorithm until specified depth
    let findCoordinates = (coordinates: number[][], depth?: number): boolean => {
      if (((Number.isInteger(depth) && depth) || !Number.isInteger(depth)) && coordinates.length) {
        let middle_idx = Math.ceil(coordinates.length / 2) - 1
        let middle_element = coordinates[middle_idx]
        if (PAUtil.calcDistanceAsTheCrowFlies(middle_element[1], middle_element[0], this.coordinates.latitude, this.coordinates.longitude) <= radius) {
          return true
        }
        let before_coordinates = coordinates.slice(0, middle_idx + 1)
        let after_coordinates = coordinates.slice(middle_idx + 2)

        return findCoordinates(before_coordinates, Number.isInteger(depth) ? depth - 1 : null) || findCoordinates(after_coordinates, Number.isInteger(depth) ? depth - 1 : null)
      } else {
        return false
      }
    }

    return findCoordinates(coordinates, route_search_depth)
  }

  distanceWasRequested(to_location: PALocation): boolean {
    let local_storage_key = PALocation.routeLocalStorageKey(this, to_location)
    let local_storage_value = localStorage.getItem(local_storage_key)

    const same_coordinates = this.coordinates.latitude == to_location.coordinates.latitude && this.coordinates.longitude == to_location.coordinates.longitude

    return !!(this.distancesToLocations.has(to_location) || this.loadingRoutesToLocations.includes(to_location) || local_storage_value || same_coordinates)
  }

  static routeLocalStorageKey(from_location: PALocation, to_location: PALocation): string {
    return `route:${from_location.coordinates.latitude},${from_location.coordinates.longitude};${to_location.coordinates.latitude},${to_location.coordinates.longitude}`
  }

  static async preloadLocationDistanceMatrix(source_locations: PALocation[], destination_locations: PALocation[], efficiency: 'min_credits' | 'min_time'): Promise<void> {
    const incomplete_source_locations = source_locations.filter(source_loc => !destination_locations.every(dest_loc => source_loc.distanceWasRequested(dest_loc)))
    const incomplete_destination_locations = destination_locations.filter(dest_loc => !source_locations.every(source_loc => source_loc.distanceWasRequested(dest_loc)))

    let requests: {matrix_locations: PALocation[], source_indices: number[], destination_indices: number[]}[] = []

    // gather requests
    if (efficiency == 'min_time') {
      const matrix_locations = [... new Set(incomplete_source_locations.concat(incomplete_destination_locations))]
      const source_indices = incomplete_source_locations.map(source_loc => matrix_locations.indexOf(source_loc))
      const destination_indices = incomplete_destination_locations.map(source_loc => matrix_locations.indexOf(source_loc))
      requests.push({matrix_locations: matrix_locations, source_indices: source_indices, destination_indices: destination_indices})
    } else {
      const partially_incomplete_source_locations = incomplete_source_locations.filter(source_loc => destination_locations.find(dest_loc => source_loc.distanceWasRequested(dest_loc)))
      for (let source_loc of partially_incomplete_source_locations) {
        const incomplete_source_destinations = incomplete_destination_locations.filter(dest_loc => !source_loc.distanceWasRequested(dest_loc))
        const matrix_locations = [... new Set([source_loc].concat(incomplete_source_destinations))]
        const source_indices = [matrix_locations.indexOf(source_loc)]
        const destination_indices = incomplete_source_destinations.map(dest_loc => matrix_locations.indexOf(dest_loc))
        requests.push({matrix_locations: matrix_locations, source_indices: source_indices, destination_indices: destination_indices})
      }
    }

    // send requests
    await Promise.all(requests.map(async request => {
      const matrix_coordinates = request.matrix_locations.map(loc => {
        return {latitude: loc.coordinates.latitude, longitude: loc.coordinates.longitude};
      })
      if (request.source_indices.length > 1 || request.destination_indices.length > 1) {
        return await new Promise<void>((resolve) => {
          PALocation.mapBoxService.matrixWaypoints(matrix_coordinates, request.source_indices, request.destination_indices).subscribe(
            data => {
              // process answers
              for (let source_index of request.source_indices) {
                const source_location = request.matrix_locations[source_index];
                for (let destination_index of request.destination_indices) {
                  const destination_location = request.matrix_locations[destination_index];
                  let route: Route = {
                    distance: data.distances[request.source_indices.indexOf(source_index)][request.destination_indices.indexOf(destination_index)] / 1000,
                    duration: data.durations[request.source_indices.indexOf(source_index)][request.destination_indices.indexOf(destination_index)] / 60,
                    geometry: null
                  }
                  source_location.saveRouteToLocation(destination_location, route)
                }
              }
              resolve()
            },
            err => {
              console.log(err)
              resolve()
            }
          )
        })
      } else {
        return
      }
    }))
  }
}

export declare interface CalculatesDistance {
  getDistanceToCoordinatesAsTheCrowFlies(lat: number, lon: number): number;

  getStreetDistanceToCoordinates(lat: number, lon: number): Promise<{ distance: number, duration: number }>

  getDistanceToLocation(to_location: PALocation): Promise<{ distance: number; duration: number; }>
}
