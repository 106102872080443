import { CommonModule } from "@angular/common";
import { ReactiveFormsModule } from "@angular/forms";
import { NgSelectModule } from "@ng-select/ng-select";

import { Component, OnInit, ElementRef, ViewChild } from '@angular/core'
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms'
import { ArticleService, CustomerService, WarehouseService } from '../_services'
import { ArticleTemplate, Customer, Warehouse, WarehouseBinCombined } from '../_models'
import { Router } from '@angular/router'
import { NgbDatepickerModule } from "@ng-bootstrap/ng-bootstrap";

@Component({
  imports: [CommonModule, ReactiveFormsModule, NgSelectModule, NgbDatepickerModule],
    selector: 'hawk-warehouse-inbound',
    templateUrl: './warehouse-inbound.component.html',
    styleUrls: ['./warehouse-inbound.component.scss'],
})
export class WarehouseInboundComponent implements OnInit {

  @ViewChild('serialNumberInput') serialInputElement: ElementRef

  disabled: boolean
  serialNumbers: string[]
  templates: ArticleTemplate[]
  owner: Customer[]
  warehouses: WarehouseBinCombined[]
  selectedTemplate: ArticleTemplate

  serialForm: UntypedFormGroup
  metaForm: UntypedFormGroup
  endForm: UntypedFormGroup

  constructor(
    private articleService: ArticleService,
    private customerService: CustomerService,
    private warehouseService: WarehouseService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.disabled = false
    this.serialNumbers = []

    this.serialForm = new UntypedFormGroup({
      serialInput: new UntypedFormControl(null),
    })

    this.metaForm = new UntypedFormGroup({
      metaType: new UntypedFormControl(null),
      metaOwner: new UntypedFormControl(null),
      ownerSelect: new UntypedFormControl(null),
      metaAmount: new UntypedFormControl(null),
      deliveryNoteNumber: new UntypedFormControl(null),
      deliveryDate: new UntypedFormControl(null),
      productionDate: new UntypedFormControl(null),
    })

    this.metaForm.get('ownerSelect').setValue('bentomax')

    this.endForm = new UntypedFormGroup({
      startWarehouse: new UntypedFormControl(null),
      endWarehouse: new UntypedFormControl(null),
    })

    this.articleService.loadArticleTemplates().subscribe(
      (data) => {
        this.templates = data
      },
      (err) => {
        console.error(err)
      },
    )

    this.customerService.loadCustomers().subscribe(
      (data) => {
        this.owner = data
      },
      (err) => {
        console.error(err)
      },
    )

    this.warehouseService.loadWarehouses().subscribe(
      (data) => {
        // tslint:disable-next-line:prefer-const
        let warehouses: WarehouseBinCombined[] = []
        data.forEach((i) => {
          i.bins.forEach((b) => {
            const item: WarehouseBinCombined = {
                id: i.id,
                owner: i.owner,
                name: i.name,
                bin_id: b.id,
                bin_label: b.name,
                combined_id: `${i.id},${b.id}`,
                combined_label: `${b.name} - ${i.name}`,
              }

            warehouses.push(item)
          })
        })

        this.warehouses = warehouses
      },
      (err) => {
        console.error(err)
      },
    )
  }

  serialNumberSize() {
    return this.serialNumbers.length
  }

  public showCustomer(): boolean {
    return this.metaForm.get('ownerSelect').value !== 'bentomax'
  }

  onSNSubmit() {
    const snInput = this.serialForm.get('serialInput')
    this.serialNumbers.push(snInput.value)
    snInput.reset('')
  }

  openSN(event) {
    if (event.panelId === 'ngb-panel-1' && event.nextState) {

      setTimeout(() => {
        this.serialInputElement.nativeElement.focus()
      }, 100)
    }
  }

  useSerialNumbers(): boolean {
    if (this.metaForm.get('metaType').value === null) {
      return false
    }
    return this.metaForm.get('metaType').value.has_serial_number
  }

  expectedSNCount(): number {
    if (this.metaForm.get('metaAmount').value === undefined) {
      return null
    }
    return this.metaForm.get('metaAmount').value
  }

  createArticles() {
    const result = {
      type_id: this.metaForm.get('metaType').value.id,
      owned_by_bentomax: this.metaForm.get('ownerSelect').value === 'bentomax',
      owner_id: this.metaForm.get('metaOwner').value,
      external_delivery_note: this.metaForm.get('deliveryNoteNumber').value,
      external_delivery_date: this.metaForm.get('deliveryDate').value,
      external_production_date: this.metaForm.get('productionDate').value,

      amount: this.metaForm.get('metaAmount').value,

      serial_numbers: this.serialNumbers,

      startWarehouse: this.endForm.get('startWarehouse').value,
      endWarehouse: this.endForm.get('endWarehouse').value,
    }

    if (this.useSerialNumbers()) {
      result.amount = this.metaForm.get('metaAmount').value
      result.serial_numbers = this.serialNumbers
    } else {
      result.amount = this.metaForm.get('metaAmount').value
      result.serial_numbers = []
    }
    this.articleService.sendBulkArticles(result).subscribe(
      (data) => {
        this.router.navigate(['/lager'])
      },
      (err) => {
        console.error(err)
      },
    )
  }

}