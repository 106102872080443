<div id="timeline" #timeline [style.padding-bottom]="addBottomPaddingPxAmount + 'px'">
  <div *ngIf="tour" class="selectable horizontal_flex" style="flex: 1; position: relative;" [ngClass]="selected ? 'selected ' : ''">
    <div style="position: relative; z-index: 50;" class="horizontal_flex">
      <div *ngIf="showLabel" (click)=selectTourEvent.emit(tour) style="width: 46px; font-size: .75rem; text-align: center;" class="black_gradient_background horizontal_flex">
        <div [style.background-color]="PAUtil.getIndexColorString(PATimeControl.Instance.weekDays.indexOf(tour.technicianDate.day.week_day))" style="width: 6px;"></div>
        <div *ngIf="tour.technicianDate.isTodaysTechnicianDate()" style="margin: auto auto auto 2px; font-size: .8rem">
          Heute
        </div>
        <div *ngIf="!tour.technicianDate.isTodaysTechnicianDate()" style="flex: 1; position: relative">
          <div class="horizontal_flex" style="position: absolute; top: 0; left: 2px; bottom: 0; text-align: center; right: 60%; font-size: .8rem;">
            <div style="margin: auto auto auto 0;">
              {{PATimeControl.Instance.weekDayToGermanName(tour.technicianDate.day.week_day, true)}}
            </div>
          </div>
          <div class="horizontal_flex" style="position: absolute; bottom: 0; top: 0; right: 4px; text-align: center; left: 40%; font-size: .55rem; line-height: .6rem">
            <div style="margin: auto 0 auto auto; color: lightgray;">
              <i>KW <br> {{PATimeControl.Instance.timestampToCalendarWeek(tour.technicianDate.day.utc_timestamp).number}}</i>
            </div>
          </div>
        </div>
      </div>
      <hawk-travel-technician-menu style="display: inline-flex; width: 24px;" *ngIf="mapContainer"
                                   [technicianDate]="tour.technicianDate.getPreviousTechnicianDate(true, true)"
                                   [mapBoxAccessToken]="mapBoxLoadingService.accessToken"
                                   [mapContainer]="mapContainer"
                                   [tooltip]="'Reisestart festlegen'"
                                   [anchor]="'left'"
                                   [travelTechnicianDate]="tour.technicianDate.getPreviousTechnicianDate(true, true).travelTechnicianDate"
      ></hawk-travel-technician-menu>
    </div>
    <div class="vertical_flex" style="flex: 1; background-color: white; position: relative;" (click)=selectTourEvent.emit(tour) [ngClass]="tour.technicianDate.isPastTechnicianDate() ? 'striped_background' : ''">
      <div class="edit_timeline" *ngFor="let timestamp of timelineTimestamps" style="position: absolute; transform: translate(-50%, 0); top: 0; display: inline-flex; flex-direction: column;" [style.left]="timestamp.offset_percent">
        <div style="margin: auto; width: 1px; background-color: #adadad;" [style.height]="showHours ? ((showCompareTour ? 2 : 1 ) * height) + 6 + 'px' : ((showCompareTour ? 2 : 1 ) * height) + 'px'"></div>
        <div *ngIf="showHours" style="font-size: .7rem; color: white;">
          {{timestamp.time_string}}
        </div>
      </div>
      <div *ngIf="showCompareTour" style="position: relative;" [style.height]="height + 'px'">
        <div class="route_container" style="border-bottom: 1px solid black;" *ngIf="compareTour">
          <ng-container *ngFor="let operation of compareTour.operations" >
            <div
                    [style.background-color]="Util.rgbaToString(Util.hexToRgba(PADataControl.Instance.getProject(operation.ticket.project.id).color, .7))"
                    [style.left]="compareTour.route.time_specific_data[PAFilterControl.Instance.selectedOperationTimeFilter]?.operation_data[compareTour.operations.indexOf(operation)]?.timeline_positions.operation.left_percent + '%'"
                    [style.right]="compareTour.route.time_specific_data[PAFilterControl.Instance.selectedOperationTimeFilter]?.operation_data[compareTour.operations.indexOf(operation)]?.timeline_positions.operation.right_percent + '%'"
                    class="operation"
                    [tooltip]="$any(ctNotEditableTemplate)" contentType="template">
              <div *ngIf="showOperationIndexes && compareTour.route.time_specific_data[PAFilterControl.Instance.selectedOperationTimeFilter]?.operation_data[compareTour.operations.indexOf(operation)]?.timeline_positions.operation.additional_time_percent"
                   class="operation_stats_right_container"
                   [style.width]="100 - 10000 / (100 + compareTour.route.time_specific_data[PAFilterControl.Instance.selectedOperationTimeFilter]?.operation_data[compareTour.operations.indexOf(operation)]?.timeline_positions.operation.additional_time_percent) + '%'">
                <div style="margin: auto; font-size: .6rem; position: relative">
                  <div style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%)">
                    +{{compareTour.route.time_specific_data[PAFilterControl.Instance.selectedOperationTimeFilter]?.operation_data[compareTour.operations.indexOf(operation)]?.timeline_positions.operation.additional_time_percent}}%
                  </div>
                </div>
              </div>
            </div>
            <div *ngIf="showOperationIndexes" class="operation_index"
                 [style.left]="compareTour.route.time_specific_data[PAFilterControl.Instance.selectedOperationTimeFilter]?.operation_data[compareTour.operations.indexOf(operation)]?.timeline_positions.operation.middle_percent + '%'">
              <div class="operation_index_content" [style.background-color]="PAUtil.getIndexColorString(PATimeControl.Instance.weekDays.indexOf(compareTour.technicianDate.day.week_day))">
                <div style="margin: auto; line-height: .85rem;">
                  <ng-container *ngIf="tour.getTourIndexForOperationWithId(operation.id) + 1">
                    {{tour.getTourIndexForOperationWithId(operation.id) + 1}}
                  </ng-container>
                  <ng-container *ngIf="!(tour.getTourIndexForOperationWithId(operation.id) + 1)">
                    &#x2716;
                  </ng-container>
                  <ng-container *ngIf="operation.getStore().letter">|{{operation.getStore().letter}}</ng-container>
                </div>
              </div>
              <div *ngIf="operation.date_travel_start && !operation.date_on_site && ! operation.date_finished"
                   style="position: absolute; left: 50%; transform: translateX(-50%); bottom: 100%; width: 1px; background: gray; height: 4px;">
                <div class="operation_stats_top_container" [tooltip]="'Anfahrt'">
                  <fa-icon style="color: dodgerblue; position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%)" [icon]="faCar"></fa-icon>
                </div>
              </div>
              <div *ngIf="operation.date_on_site && ! operation.date_finished"
                   style="position: absolute; left: 50%; transform: translateX(-50%); bottom: 100%; width: 1px; background: gray; height: 4px;">
                <div class="operation_stats_top_container" [tooltip]="'In Bearbeitung'">
                  <fa-icon style="color: darkorange; position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%)" [icon]="faWrench"></fa-icon>
                </div>
              </div>
              <div *ngIf="operation.date_finished"
                   style="position: absolute; left: 50%; transform: translateX(-50%); bottom: 100%; width: 1px; background: gray; height: 4px;">
                <div class="operation_stats_top_container" [tooltip]="'Erledigt'">
                  <fa-icon style="color: greenyellow; position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%)" [icon]="faCheck"></fa-icon>
                </div>
              </div>
            </div>
            <div  [style.background-color]="'gray'"
                  [style.left]="compareTour.route.time_specific_data[PAFilterControl.Instance.selectedOperationTimeFilter]?.operation_data[compareTour.operations.indexOf(operation)]?.timeline_positions.driving.left_percent + '%'"
                  [style.right]="compareTour.route.time_specific_data[PAFilterControl.Instance.selectedOperationTimeFilter]?.operation_data[compareTour.operations.indexOf(operation)]?.timeline_positions.driving.right_percent + '%'"
                  [ngClass]="[compareTour.operations.indexOf(operation) == 0 ? 'first_driving' : 'inter_driving'].join(' ')">
              <div *ngIf="compareTour.operations.indexOf(operation) == 0 && (compareTour.technicianDate.technician.location.coordinates.latitude != compareTour.startLocation.coordinates.latitude || compareTour.technicianDate.technician.location.coordinates.longitude != compareTour.startLocation.coordinates.longitude)"
                   style="background-size: 100%; width: 18px; height: 18px; position: absolute; top: 50%; left: 0; transform: translate(0, -50%)" class="destination_background_container">
                <div class="destination_background" [style.background-color]="PAUtil.getIndexColorString((PATimeControl.Instance.weekDays.indexOf(compareTour.technicianDate.day.week_day) + 6) % 7)"></div>
                <div class="destination_background_border"></div>
              </div>
            </div>
            <ng-template #ctNotEditableTemplate>
              <div style="display: inline-flex; flex: 1; flex-direction: column;">
                <div style="width: 100%; text-align: center;">
                  {{operation.ticket.address_company}}
                </div>
                <div style="margin: auto; display: inline-flex; flex-direction: row; flex-wrap: wrap; font-size: .8rem;">
                  <div style="margin-top: auto; margin-bottom: auto; border: 1px solid black; display: inline-flex; margin-right: 4px;">
                    <div style="display: inline-flex; flex-direction: row; margin: auto; padding: 4px 8px;" class="black_gradient_background">
                      <fa-icon style="font-size: .8rem; margin-right: 4px;" [icon]="faCar"></fa-icon>
                      {{compareTour?.route?.time_specific_data[PAFilterControl.Instance.selectedOperationTimeFilter]?.operation_data[compareTour?.operations?.indexOf(operation)]?.operation_time_strings?.travel_start}}
                    </div>
                  </div>
                  <div style="margin-top: auto; margin-bottom: auto; border: 1px solid black; display: inline-flex; margin-right: 4px;">
                    <div style="display: inline-flex; flex-direction: row; margin: auto; padding: 4px 8px;" class="black_gradient_background">
                      <fa-icon style="font-size: .8rem; margin-right: 4px;" [icon]="faWrench"></fa-icon>
                      {{compareTour?.route?.time_specific_data[PAFilterControl.Instance.selectedOperationTimeFilter]?.operation_data[compareTour?.operations?.indexOf(operation)]?.operation_time_strings?.on_site}}
                    </div>
                  </div>
                  <div style="margin-top: auto; margin-bottom: auto; border: 1px solid black; display: inline-flex;">
                    <div style="display: inline-flex; flex-direction: row; margin: auto; padding: 4px 8px;" class="black_gradient_background">
                      <fa-icon style="font-size: .8rem; margin-right: 4px;" [icon]="faCheck"></fa-icon>
                      {{compareTour?.route?.time_specific_data[PAFilterControl.Instance.selectedOperationTimeFilter]?.operation_data[compareTour?.operations?.indexOf(operation)]?.operation_time_strings?.finished}}
                    </div>
                  </div>
                </div>
              </div>
            </ng-template>
          </ng-container>
          <div  [style.background-color]="'gray'" *ngIf="compareTour.route.time_specific_data[PAFilterControl.Instance.selectedOperationTimeFilter]?.home_driving"
                [style.left]="compareTour.route.time_specific_data[PAFilterControl.Instance.selectedOperationTimeFilter]?.home_driving.timeline_position.left_percent + '%'"
                [style.right]="compareTour.route.time_specific_data[PAFilterControl.Instance.selectedOperationTimeFilter]?.home_driving.timeline_position.right_percent + '%'"
                class="home_driving" [ngClass]="!compareTour.operations.length ? 'first_driving' : ''">
            <div *ngIf="compareTour.technicianDate.technician.location.coordinates.latitude != compareTour.endLocation.coordinates.latitude || compareTour.technicianDate.technician.location.coordinates.longitude != compareTour.endLocation.coordinates.longitude"
                 style="background-size: 100%; width: 20px; height: 20px; position: absolute; top: 50%; right: 0; transform: translate(0, -50%)" class="destination_background_container">
              <div class="destination_background" [style.background-color]="PAUtil.getIndexColorString(PATimeControl.Instance.weekDays.indexOf(compareTour.technicianDate.day.week_day))"></div>
              <div class="destination_background_border"></div>
            </div>
            <div *ngIf="!compareTour.operations.length && (compareTour.technicianDate.technician.location.coordinates.latitude != compareTour.startLocation.coordinates.latitude || compareTour.technicianDate.technician.location.coordinates.longitude != compareTour.startLocation.coordinates.longitude)"
                 style="background-size: 100%; width: 20px; height: 20px; position: absolute; top: 50%; left: 0; transform: translate(0, -50%)" class="destination_background_container">
              <div class="destination_background" [style.background-color]="PAUtil.getIndexColorString((PATimeControl.Instance.weekDays.indexOf(compareTour.technicianDate.day.week_day) + 6) % 7)"></div>
              <div class="destination_background_border"></div>
            </div>
          </div>
        </div>
      </div>
      <div style="position: relative;" [style.height]="height + 'px'">
        <div class="route_container" *ngIf="tour">
          <ng-container *ngFor="let operation of tour.operations" >
            <div
                    [style.background-color]="Util.rgbaToString(Util.hexToRgba(PADataControl.Instance.getProject(operation.ticket.project.id).color, .7))"
                    [style.left]="tour.route.time_specific_data[PAFilterControl.Instance.selectedOperationTimeFilter]?.operation_data[tour.operations.indexOf(operation)]?.timeline_positions.operation.left_percent + ((chainOperations || dragAndDropOperations.includes(operation)) ? dragAndDropOffsetPercent * operation.dragDropOffsetFactor : 0) + '%'"
                    [style.right]="tour.route.time_specific_data[PAFilterControl.Instance.selectedOperationTimeFilter]?.operation_data[tour.operations.indexOf(operation)]?.timeline_positions.operation.right_percent - ((chainOperations || dragAndDropOperations.includes(operation)) ? dragAndDropOffsetPercent * operation.dragDropOffsetFactor : 0) + '%'"
                    class="operation"
                    [ngClass]="[dragAndDropOperations.includes(operation) ? 'drag_and_drop_operation' : '', operation.hasStarted() ? '' : 'draggable'].join(' ')"
                    [tooltip]="editable ? '' : $any(notEditableTemplate)" contentType="template"
                    (mousedown)="calculateDragAndDropOffset($event, operation)">
              <hawk-popup-menu #OperationPopups>
                <div class="content" style="position:relative; background: #2d2d2d;">
                  <fa-icon style="position:absolute; top: 0; right: 4px; color: white" [icon]="faWindowClose" (click)="closeOperationMenu(tour.operations.indexOf(operation))"></fa-icon>
                  <div style="display: inline-flex; cursor: default; flex: 1; flex-direction: column; padding: 0;" (click)="$event.stopPropagation()" (mousedown)="$event.stopPropagation()" (keydown)="$event.stopPropagation()">
                    <div style="display: inline-flex; flex-direction: column; padding: 0; font-size: .8rem">
                      <div style="flex: 1; text-align: left; font-size: .85rem; line-height: .8rem;">
                        <div *ngIf="tour.technicianDate.insertionConfigMap && tour.technicianDate.insertionConfigMap.has(operation.id)" class="black_gradient_background_no_hover operation_container horizontal_flex" style="width: 100%">
                          <div *ngIf="tour.technicianDate.insertionConfigMap.has(operation.id)" class="black_gradient_background" style="border: 1px solid white; display: inline-flex; padding: 2px;" (click)="tour.technicianDate.insertionConfigMap.get(operation.id).removable = !tour.technicianDate.insertionConfigMap.get(operation.id).removable; updateNewTourEvent.emit()">
                            <fa-icon style="margin: auto;" [icon]="tour.technicianDate.insertionConfigMap.get(operation.id).removable ? faLockOpen : faLock "></fa-icon>
                          </div>
                          <div class="horizontal_flex" style="border: 1px solid black; background-color: #673ab7; color: white;">
                            <label style="padding: 0 2px; margin: auto;">#</label>
                            <select (change)="tour.technicianDate.insertionConfigMap.get(operation.id).tour_index = eventToNumber($event); updateNewTourEvent.emit()">
                              <option [selected]="tour.technicianDate.insertionConfigMap.get(operation.id).tour_index == -1" [value]="-1">Opt</option>
                              <option [selected]="tour.technicianDate.insertionConfigMap.get(operation.id).tour_index == idx" [disabled]="tour.technicianDate.insertionConfigIndexIsDisabled(idx)" *ngFor="let idx of tour.technicianDate.freeTourIndexes" [value]="idx">{{idx + 1}}</option>
                            </select>
                          </div>
                          <div style="margin: auto; flex:1; padding-left: 2px;">
                            {{(operation.ticket.address_company || '').slice(0, 26)}}
                          </div>
                        </div>
                        <ng-container *ngIf="!tour.technicianDate.insertionConfigMap || !tour.technicianDate.insertionConfigMap.has(operation.id)">
                          {{!operation.ticket.address_company || operation.ticket.address_company?.length < 23 ? operation.ticket.address_company : operation.ticket.address_company.slice(0, 21) + '...'}}
                        </ng-container>
                      </div>
                      <div style="text-align: left; font-size: .65rem; line-height: 1rem; color: white; padding-left: 2px;">
                        {{operation.ticket.priority.name}} ({{getNewToursOperationPriorityCountsBeforeOperation(operation) + 1}}. Auftrag dieser Priorität)
                      </div>
                    </div>
                    <div>
                      <div style="width: 100%; display: inline-flex; flex-direction: row;">
                        <div style="display: inline-flex; flex-direction: column; font-size: .8rem; margin: auto 2px auto auto;">
                          <div style="margin-top: auto; margin-bottom: auto; border: 1px solid black; display: inline-flex;">
                            <div style="display: inline-flex; flex-direction: row; margin: auto; padding: 2px;" class="black_gradient_background_no_hover">
                              <fa-icon style="font-size: .9rem; margin-right: 2px;" [icon]="faCar"></fa-icon>
                              <input type="time"
                                     [disabled]="operation.date_travel_start"
                                     [value]="tour.route?.time_specific_data[PAFilterControl.Instance.selectedOperationTimeFilter]?.operation_data[tour.operations?.indexOf(operation)]?.operation_time_strings?.travel_start"
                                     (change)="shiftSelectedOperationTimes($event, operation, 'travel_start')">
                            </div>
                          </div>
                          <div style="margin-top: auto; margin-bottom: auto; border: 1px solid black; display: inline-flex;">
                            <div style="display: inline-flex; flex-direction: row; margin: auto; padding: 2px;" class="black_gradient_background_no_hover">
                              <fa-icon style="font-size: .9rem; margin-right: 2px;" [icon]="faWrench"></fa-icon>
                              <input type="time"
                                     [disabled]="operation.date_travel_start"
                                     [value]="tour.route?.time_specific_data[PAFilterControl.Instance.selectedOperationTimeFilter]?.operation_data[tour.operations?.indexOf(operation)]?.operation_time_strings?.on_site"
                                     (change)="shiftSelectedOperationTimes($event, operation, 'on_site')">
                            </div>
                          </div>
                          <div style="margin-top: auto; margin-bottom: auto; border: 1px solid black; display: inline-flex;">
                            <div style="display: inline-flex; flex-direction: row; margin: auto; padding: 2px;" class="black_gradient_background_no_hover">
                              <fa-icon style="font-size: .9rem; margin-right: 0;" [icon]="faCheck"></fa-icon>
                              <input type="time"
                                     [disabled]="operation.date_travel_start"
                                     [value]="tour.route?.time_specific_data[PAFilterControl.Instance.selectedOperationTimeFilter]?.operation_data[tour.operations?.indexOf(operation)]?.operation_time_strings?.finished"
                                     (change)="shiftSelectedOperationTimes($event, operation, 'finished')">
                            </div>
                          </div>
                        </div>
                        <div style="font-size: .7rem; flex:1; display: inline-flex; flex-direction: column;">
                          <div style="display: inline-flex; flex-direction: row; flex: 1; background: #517b4a; border: 1px solid black; margin: auto;">
                            <div style="display: inline-flex; flex-direction: column; line-height: 15px; font-size: 11px; padding: 3px; margin: auto;">
                              <div>
                                <b style="white-space: nowrap;">Berechnete Zeit:</b> <br>
                              </div>
                              <div>
                                <ng-container *ngIf="operation.hasApplyableRampUpTimeRuleForTechnicianAndPriorityIndex(tour.technicianDate.technician, getNewToursOperationPriorityCountsBeforeOperation(operation) + 1); else noRule">
                                  Rampup Zeit <br>
                                  <div class="horizontal_flex" style="flex-wrap: nowrap;">
                                    <fa-icon [icon]="faArrowRight" style="margin-right: 2px;"></fa-icon>
                                    <div style="white-space: nowrap;">
                                      {{Math.round(operation.hasApplyableRampUpTimeRuleForTechnicianAndPriorityIndex(tour.technicianDate.technician, getNewToursOperationPriorityCountsBeforeOperation(operation) + 1).timePredictionFunctionWrapper.predictFunction(getNewToursOperationPriorityCountsBeforeOperation(operation) + 1, operation.ticket.time_estimation))}}min
                                      (+{{Math.round(operation.hasApplyableRampUpTimeRuleForTechnicianAndPriorityIndex(tour.technicianDate.technician, getNewToursOperationPriorityCountsBeforeOperation(operation) + 1).timePredictionFunctionWrapper.getAdditionalValue(getNewToursOperationPriorityCountsBeforeOperation(operation) + 1))}}%)
                                    </div>
                                  </div>
                                </ng-container>
                                <ng-template #noRule>
                                  <div *ngIf="PAFilterControl.Instance.selectedOperationTimeFilter == 'planned'">
                                    Hinterlegte Zeit <br>
                                    <div class="horizontal_flex wrap">
                                      <fa-icon [icon]="faArrowRight" style="margin-right: 2px;"></fa-icon> {{operation.ticket.time_estimation}}min
                                    </div>
                                  </div>
                                  <div *ngIf="PAFilterControl.Instance.selectedOperationTimeFilter == 'average'">
                                    Durchschnittszeit <br>
                                    <div class="horizontal_flex wrap">
                                      <fa-icon [icon]="faArrowRight" style="margin-right: 2px;"></fa-icon>
                                      <ng-container *ngIf="tour.technicianDate.technician.getPriorityBasedAverageOperationTime(operation.ticket.priority.id) >= 0; else noAverageTime ">
                                        {{tour.technicianDate.technician.getPriorityBasedAverageOperationTime(operation.ticket.priority.id)}}min
                                      </ng-container>
                                      <ng-template #noAverageTime>
                                        <ng-container *ngIf="tour.technicianDate.technician.getPriorityBasedAverageOperationTime(operation.ticket.priority.id) == -1 ; else averageTimeError">
                                          keine Daten
                                        </ng-container>
                                        <ng-template #averageTimeError>
                                          Daten inkonsistent!
                                        </ng-template>
                                      </ng-template>
                                    </div>
                                  </div>
                                </ng-template>
                              </div>
                            </div>
                            <button class="rampup_button" (click)="PASettingsControl.Instance.addAdditionalTechnicianPriorityOperationTimeOnDay(tour.technicianDate.technician, operation.ticket.project.id, operation.ticket.priority.id, 20)">
                              Rampup
                              <div class="horizontal_flex"> Zeit <fa-icon style="margin-left: 4px" [icon]="faCog"></fa-icon></div>
                            </button>
                          </div>
                          <div style="display: inline-flex; flex-direction: row; margin: 4px auto auto;">
                            <button style="margin: auto;" mat-raised-button color="primary" type="button" (click)="closeOperationMenu(tour.operations.indexOf(operation))">ok</button>
                            <button style="margin: auto auto auto 8px;" mat-raised-button color="warn" type="button" (click)="removeOperation(operation, tour.operations.indexOf(operation))" [disabled]="!!operation.date_travel_start">entfernen</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </hawk-popup-menu>
              <div *ngIf="showOperationIndexes && tour.route.time_specific_data[PAFilterControl.Instance.selectedOperationTimeFilter]?.operation_data[tour.operations.indexOf(operation)]?.timeline_positions.operation.additional_time_percent"
                   class="operation_stats_right_container"
                   [style.width]="100 - 10000 / (100 + tour.route.time_specific_data[PAFilterControl.Instance.selectedOperationTimeFilter]?.operation_data[tour.operations.indexOf(operation)]?.timeline_positions.operation.additional_time_percent) + '%'">
                <div style="margin: auto; font-size: .6rem; position: relative">
                  <div style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%)">
                    +{{tour.route.time_specific_data[PAFilterControl.Instance.selectedOperationTimeFilter]?.operation_data[tour.operations.indexOf(operation)]?.timeline_positions.operation.additional_time_percent}}%
                  </div>
                </div>
              </div>
            </div>
            <div *ngIf="showOperationIndexes" class="operation_index"
                 [style.left]="tour.route.time_specific_data[PAFilterControl.Instance.selectedOperationTimeFilter]?.operation_data[tour.operations.indexOf(operation)]?.timeline_positions.operation.middle_percent + ((chainOperations || dragAndDropOperations.includes(operation)) ? dragAndDropOffsetPercent * operation.dragDropOffsetFactor : 0)  + '%'">
              <div class="operation_index_content" [style.background-color]="PAUtil.getIndexColorString(PATimeControl.Instance.weekDays.indexOf(tour.technicianDate.day.week_day))">
                <div style="margin: auto; line-height: .85rem;">
                  {{tour.operations.indexOf(operation) + 1}}<ng-container *ngIf="operation.getStore().letter">|{{operation.getStore().letter}}</ng-container>
                </div>
              </div>
              <div *ngIf="operation.date_travel_start && !operation.date_on_site && ! operation.date_finished"
                   style="position: absolute; left: 50%; transform: translateX(-50%); bottom: 100%; width: 1px; background: gray; height: 4px;">
                <div class="operation_stats_top_container" [tooltip]="'Anfahrt'">
                  <fa-icon style="color: dodgerblue; position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%)" [icon]="faCar"></fa-icon>
                </div>
              </div>
              <div *ngIf="operation.date_on_site && ! operation.date_finished"
                   style="position: absolute; left: 50%; transform: translateX(-50%); bottom: 100%; width: 1px; background: gray; height: 4px;">
                <div class="operation_stats_top_container" [tooltip]="'In Bearbeitung'">
                  <fa-icon style="color: darkorange; position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%)" [icon]="faWrench"></fa-icon>
                </div>
              </div>
              <div *ngIf="operation.date_finished"
                   style="position: absolute; left: 50%; transform: translateX(-50%); bottom: 100%; width: 1px; background: gray; height: 4px;">
                <div class="operation_stats_top_container" [tooltip]="'Erledigt'">
                  <fa-icon style="color: greenyellow; position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%)" [icon]="faCheck"></fa-icon>
                </div>
              </div>
            </div>
            <div  [style.background-color]="'gray'"
                  [style.left]="tour.route.time_specific_data[PAFilterControl.Instance.selectedOperationTimeFilter]?.operation_data[tour.operations.indexOf(operation)]?.timeline_positions.driving.left_percent + ((chainOperations || dragAndDropOperations.includes(operation)) ? dragAndDropOffsetPercent * operation.dragDropOffsetFactor : 0)  + '%'"
                  [style.right]="tour.route.time_specific_data[PAFilterControl.Instance.selectedOperationTimeFilter]?.operation_data[tour.operations.indexOf(operation)]?.timeline_positions.driving.right_percent - ((chainOperations || dragAndDropOperations.includes(operation)) ? dragAndDropOffsetPercent * operation.dragDropOffsetFactor : 0)  + '%'"
                  [ngClass]="[tour.operations.indexOf(operation) == 0 ? 'first_driving' : 'inter_driving', operation.hasStarted() ? '' : 'draggable'].join(' ')"
                  (mousedown)="calculateDragAndDropOffset($event, operation)">
              <div *ngIf="tour.operations.indexOf(operation) == 0 && (tour.technicianDate.technician.location.coordinates.latitude != tour.startLocation.coordinates.latitude || tour.technicianDate.technician.location.coordinates.longitude != tour.startLocation.coordinates.longitude)"
                   style="background-size: 100%; width: 18px; height: 18px; position: absolute; top: 50%; left: 0; transform: translate(0, -50%)" class="destination_background_container">
                <div class="destination_background" [style.background-color]="PAUtil.getIndexColorString((PATimeControl.Instance.weekDays.indexOf(tour.technicianDate.day.week_day) + 6) % 7)"></div>
                <div class="destination_background_border"></div>
              </div>
            </div>
            <ng-template #notEditableTemplate>
              <div style="display: inline-flex; flex: 1; flex-direction: column;">
                <div style="width: 100%; text-align: center;">
                  {{operation.ticket.address_company}}
                </div>
                <div style="margin: auto; display: inline-flex; flex-direction: row; flex-wrap: wrap; font-size: .8rem;">
                  <div style="margin-top: auto; margin-bottom: auto; border: 1px solid black; display: inline-flex; margin-right: 4px;">
                    <div style="display: inline-flex; flex-direction: row; margin: auto; padding: 4px 8px;" class="black_gradient_background">
                      <fa-icon style="font-size: .8rem; margin-right: 4px;" [icon]="faCar"></fa-icon>
                      {{tour?.route?.time_specific_data[PAFilterControl.Instance.selectedOperationTimeFilter]?.operation_data[tour?.operations?.indexOf(operation)]?.operation_time_strings?.travel_start}}
                    </div>
                  </div>
                  <div style="margin-top: auto; margin-bottom: auto; border: 1px solid black; display: inline-flex; margin-right: 4px;">
                    <div style="display: inline-flex; flex-direction: row; margin: auto; padding: 4px 8px;" class="black_gradient_background">
                      <fa-icon style="font-size: .8rem; margin-right: 4px;" [icon]="faWrench"></fa-icon>
                      {{tour?.route?.time_specific_data[PAFilterControl.Instance.selectedOperationTimeFilter]?.operation_data[tour?.operations?.indexOf(operation)]?.operation_time_strings?.on_site}}
                    </div>
                  </div>
                  <div style="margin-top: auto; margin-bottom: auto; border: 1px solid black; display: inline-flex;">
                    <div style="display: inline-flex; flex-direction: row; margin: auto; padding: 4px 8px;" class="black_gradient_background">
                      <fa-icon style="font-size: .8rem; margin-right: 4px;" [icon]="faCheck"></fa-icon>
                      {{tour?.route?.time_specific_data[PAFilterControl.Instance.selectedOperationTimeFilter]?.operation_data[tour?.operations?.indexOf(operation)]?.operation_time_strings?.finished}}
                    </div>
                  </div>
                </div>
              </div>
            </ng-template>
          </ng-container>
          <div  [style.background-color]="'gray'" *ngIf="tour.route.time_specific_data[PAFilterControl.Instance.selectedOperationTimeFilter]?.home_driving"
                [style.left]="tour.route.time_specific_data[PAFilterControl.Instance.selectedOperationTimeFilter]?.home_driving.timeline_position.left_percent + (tour.operations?.length && (chainOperations || dragAndDropOperations.includes(tour.operations[tour.operations?.length - 1])) ? dragAndDropOffsetPercent * tour.operations[tour.operations?.length - 1].dragDropOffsetFactor : 0)  + '%'"
                [style.right]="tour.route.time_specific_data[PAFilterControl.Instance.selectedOperationTimeFilter]?.home_driving.timeline_position.right_percent - (tour.operations?.length && (chainOperations || dragAndDropOperations.includes(tour.operations[tour.operations?.length - 1])) ? dragAndDropOffsetPercent * tour.operations[tour.operations?.length - 1].dragDropOffsetFactor : 0)  + '%'"
                class="home_driving" [ngClass]="!tour.operations.length ? 'first_driving' : ''">
            <div *ngIf="tour.technicianDate.technician.location.coordinates.latitude != tour.endLocation.coordinates.latitude || tour.technicianDate.technician.location.coordinates.longitude != tour.endLocation.coordinates.longitude"
                 style="background-size: 100%; width: 20px; height: 20px; position: absolute; top: 50%; right: 0; transform: translate(0, -50%)" class="destination_background_container">
              <div class="destination_background" [style.background-color]="PAUtil.getIndexColorString(PATimeControl.Instance.weekDays.indexOf(tour.technicianDate.day.week_day))"></div>
              <div class="destination_background_border"></div>
            </div>
            <div *ngIf="!tour.operations.length && (tour.technicianDate.technician.location.coordinates.latitude != tour.startLocation.coordinates.latitude || tour.technicianDate.technician.location.coordinates.longitude != tour.startLocation.coordinates.longitude)"
                 style="background-size: 100%; width: 20px; height: 20px; position: absolute; top: 50%; left: 0; transform: translate(0, -50%)" class="destination_background_container">
              <div class="destination_background" [style.background-color]="PAUtil.getIndexColorString((PATimeControl.Instance.weekDays.indexOf(tour.technicianDate.day.week_day) + 6) % 7)"></div>
              <div class="destination_background_border"></div>
            </div>
          </div>
          <div class="lunch_break" *ngIf="tour.lunchBreak && tour.lunchBreak.after_operation_idx < tour.operations?.length" (click)="openLunchBreakMenu()"
               [style.left]="tour.getLunchBreakTimelinePosition({offset_percent: lunchBreakOffsetPercent()}).left_percent + '%'"
               [style.right]="tour.getLunchBreakTimelinePosition({offset_percent: lunchBreakOffsetPercent()}).right_percent + '%'">
            <div style="margin: auto">
              <fa-icon [icon]="faUtensils"></fa-icon>
            </div>
            <hawk-popup-menu #LunchBreakPopup>
              <div class="horizontal_flex content">
                <div style="display: inline-flex; flex-direction: row; width: fit-content;" class="config_button">
                  <div style="color: white; padding: 3px 4px; white-space: nowrap; display: inline-flex;" class="black_gradient_background" (click)="toggleLunchBreakCalculation(tour.technicianDate)">
                    <div style="margin: auto;">
                      Pause: {{tour.technicianDate.automaticLunchBreakCalculation ? 'Automatisch' : 'Nach Auftrag'}}
                    </div>
                  </div>
                  <input *ngIf="!tour.technicianDate.automaticLunchBreakCalculation" style="margin: auto; width: 40px;" type="number" [(ngModel)]="tour.technicianDate.lunchBreakAfterOperationNumber" min="1" [max]="tour.operations.length - 1" (ngModelChange)="tour.technicianDate.updateNewTourOperations()">
                </div>
                <fa-icon style="margin: auto 2px;" (click)="lunchBreakPopup.close()" [icon]="faWindowClose"></fa-icon>
              </div>
            </hawk-popup-menu>
          </div>

          <ng-container *ngFor="let outside_opening_zone of outsideOpeningZones">
            <div class="outside_opening_zone" [tooltip]="outside_opening_zone.info" (mousedown)="calculateDragAndDropOffset($event, outside_opening_zone.operation)"
                 [style.left]="outside_opening_zone.left_percent + '%'" [style.right]="outside_opening_zone.right_percent + '%'">
            </div>
          </ng-container>
        </div>

        <ng-container *ngIf="showTimeMarker">
          <ng-container *ngFor="let opening_timeline_position of tour.route.marker_collection">
            <div class="time_marker_root" [style.left]="opening_timeline_position.percent + '%'">
              <div class="time_marker_content">
                <ng-container *ngFor="let marker of opening_timeline_position.markers">
                  <div style="margin: auto;">
                    {{marker.type}}
                  </div>
                  <div style="margin: auto;">
                    <sub>{{marker.index}}</sub>
                  </div>
                  <div style="margin: auto;" *ngIf="opening_timeline_position.markers?.indexOf(marker) != opening_timeline_position.markers?.length - 1">, </div>
                </ng-container>
              </div>
            </div>
          </ng-container>
        </ng-container>
        <ng-container *ngIf="minuteMarkerPercentage">
          <div class="time_marker_root" style="background-color: yellowgreen!important; width: 2px;" [style.left]="minuteMarkerPercentage + '%'">
            <div class="time_marker_content" style="background-color: yellowgreen!important; color: black!important;">
              <div style="margin: auto; padding: 2px;">
                Jetzt
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
    <div *ngIf="showStatistics" class="tour_statistic">
      <div class="horizontal_flex">
        <hawk-travel-technician-menu style="display: inline-flex; width: 24px;" *ngIf="mapContainer"
                                     [technicianDate]="tour.technicianDate"
                                     [mapBoxAccessToken]="mapBoxLoadingService.accessToken"
                                     [mapContainer]="mapContainer"
                                     [travelTechnicianDate]="tour.technicianDate.travelTechnicianDate"
        ></hawk-travel-technician-menu>
        <div style="color: orange;" class="horizontal_flex tour_button_container" [ngClass]="tour.technicianDate.changedTourContainer.unfittingOperations.length + tour.technicianDate.changedTourContainer.deletingOperations.length > 0 ? 'black_gradient_background' : 'black_gradient_background_no_hover disabled'" (click)="openWarningsMenu()">
          <fa-icon style="margin: auto" [style.margin-left]="tour.technicianDate.changedTourContainer.unfittingOperations.length + tour.technicianDate.changedTourContainer.deletingOperations.length > 0 ? '2px' : 'auto'" [icon]="faExclamationTriangle"></fa-icon>
          <div style="position: absolute; bottom: 0; right: 0; font-size: .6rem; line-height: .6rem;">
            {{(tour.technicianDate.changedTourContainer.unfittingOperations.length + tour.technicianDate.changedTourContainer.deletingOperations.length)  || ''}}
          </div>
          <hawk-popup-menu #WarningsPopup>
            <div class="content">
              <div style="position: absolute; top: 0; right: 0; transform: translateY(-50%);" (click)="closeWarningsMenu()">
                <fa-icon [icon]="faWindowClose"></fa-icon>
              </div>
              <div style="display: inline-flex; flex-direction: column; width: 250px;">
                <div *ngIf="tour.technicianDate.changedTourContainer.unfittingOperations.length" style="display: inline-flex; flex-direction: row; flex-wrap: wrap;">
                  <div class="operation_warning_header" style="background-color: #bd7301;">
                    <div style="margin: auto; font-size: .7rem; line-height: .7rem;">
                      Nicht eingefügt: {{tour.technicianDate.changedTourContainer.unfittingOperations.length}}
                    </div>
                  </div>
                  <div class="horizontal_flex wrap" style="padding: 2px 0 2px 2px; background-color: #bd7301; border: 1px solid black; width: 100%;">
                    <div *ngFor="let operation of tour.technicianDate.changedTourContainer.unfittingOperations" class="black_gradient_background_no_hover operation_container horizontal_flex" [ngClass]="PAStore.selectedStoreInMap == operation.getStore() ? 'selected_store' : ''">
                      <div style="margin: auto; width: 100px; text-align: center; cursor: pointer; padding: 2px 4px;" [tooltip]="operation.ticket.address_company" (click)="PAStoreControl.Instance.selectStore(operation.getStore())">
                        {{operation.ticket.address_company.length <= 16 ? operation.ticket.address_company : (operation.ticket.address_company.slice(0, 16) + '...')}}
                      </div>
                      <div *ngIf="tour.technicianDate.insertionConfigMap.has(operation.id)" class="black_gradient_background" style="border: 1px solid white; display: inline-flex; padding: 2px;" (click)="tour.technicianDate.insertionConfigMap.get(operation.id).removable = !tour.technicianDate.insertionConfigMap.get(operation.id).removable; tour.technicianDate.updateNewTourOperations()" tooltip="Fest in Tour setzen">
                        <fa-icon style="margin: auto;" [icon]="tour.technicianDate.insertionConfigMap.get(operation.id).removable ? faLockOpen : faLock "></fa-icon>
                      </div>
                    </div>
                  </div>
                </div>
                <div *ngIf="tour.technicianDate.newTourOperationChanges?.delete_operations?.length" style="display: inline-flex; flex-direction: row; flex-wrap: wrap;">
                  <div class="operation_warning_header" style="background-color: red;">
                    <div style="margin: auto; font-size: .7rem; line-height: .7rem;">
                      Entfernt: {{tour.technicianDate.newTourOperationChanges.delete_operations.length}}
                    </div>
                  </div>
                  <div class="horizontal_flex wrap" style="padding: 2px 0 2px 2px; width: 100%; background-color: red; border: 1px solid black;">
                    <div *ngFor="let operation of tour.technicianDate.newTourOperationChanges.delete_operations" [tooltip]="'wieder hinzufügen: ' + operation.ticket.address_company" class="black_gradient_background operation_container horizontal_flex" (click)="tour.technicianDate.restoreOperationToNewTour(operation)" [ngClass]="PAStore.selectedStoreInMap == operation.getStore() ? 'selected_store' : ''">
                      <div style="margin: auto; width: 100px; text-align: center; cursor: pointer; padding: 2px 4px;" (click)="PAStoreControl.Instance.selectStore(operation.getStore())">
                        {{operation.ticket.address_company.length <= 16 ? operation.ticket.address_company : (operation.ticket.address_company.slice(0, 16) + '...')}}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </hawk-popup-menu>
        </div>
        <div style="color: #337ab7;" class="tour_button_container horizontal_flex" (click)="toggleShowCompareTour()" [ngClass]="compareTour ? 'black_gradient_background' : 'black_gradient_background_no_hover disabled'" [tooltip]="compareTour ? 'Vorherige Tour anzeigen (Diese Tour wurde durch die Optimierung geändert)' : null">
          <fa-icon style="margin: auto" [icon]="faCodeBranch"></fa-icon>
        </div>
        <div style="color: white;" class="horizontal_flex tour_button_container" (click)="deleteTour()" [ngClass]="!tour.technicianDate.isPastTechnicianDate() ? 'black_gradient_background' : 'black_gradient_background_no_hover disabled'" [tooltip]="'Tour löschen: Entfernt alle Aufträge und Reiseziele aus dieser Tour'">
          <fa-icon style="margin: auto" [icon]="faTrash"></fa-icon>
        </div>
      </div>
      <div style="min-width: 80px;" class="horizontal_flex">
        <div style="margin: auto;"><i>{{tour.route.driving_kms}}<sub>km</sub></i></div>
        <div style="margin: auto;" [style.color]="tour.getTimeColor(timeFilter)"><i>{{tour.route.time_specific_data[timeFilter].total_time}}<sub>min</sub></i></div>
      </div>
    </div>
    <div *ngIf="showWarnings && warning" class="horizontal_flex warning" (click)="$event.stopPropagation()">
      <div style="flex: 1; margin: auto 0; text-align: center;">
        {{warning.info}}
      </div>
      <fa-icon style="margin: auto 2px auto auto; color: white; cursor: pointer" [icon]="faWindowClose" (click)="showWarnings = false"></fa-icon>
    </div>
    <div *ngIf="tour.technicianDate.latestNewOperationUpdateTimestamp" style="position: absolute; width: 100%; height: 100%; z-index: 200; background-color: rgba(0, 0, 0, 0.849); display: inline-flex; overflow-x: auto;">
      <div style="color: white; width: 100%; height: fit-content; margin: auto; text-align: center;">
        <hawk-throbber [direction]="'horizontal'" [description]="'Berechne optimierte Route...'"></hawk-throbber>
      </div>
    </div>
  </div>
</div>