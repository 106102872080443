import { CalendarWeek } from "src/app/_models/planning-assistant.interface"
import { PAOperation } from "./operation"
import { PATechnician } from "./technician"
import { PALocation } from "./location"
import { PATicket } from "./ticket"
import { UserService } from "src/app/_services"
import { PATour } from "./tour";
import { PATimeControl } from "../singletons/pa-time-control";

export class PAFilter {

  static filterTechniciansForName(technicians: PATechnician[], name: string): PATechnician[] {
    return technicians.filter(technician => (technician.firstname + ' ' + technician.lastname).toLowerCase().includes(name.toLowerCase()) || (technician.lastname + ' ' + technician.firstname).toLowerCase().includes(name.toLowerCase()))
  }

  static filterOutPseudoTechnicians(technicians: PATechnician[]): PATechnician[] {
    return technicians.filter(technician => PATechnician.pseudoTechnicianNames.indexOf(technician.firstname + ' ' + technician.lastname) < 0)
  }

  static filterTechniciansForBentomaxTechnicians(technicians: PATechnician[]): PATechnician[] {
    return technicians.filter(technician => technician.isBentomaxTechnician())
  }

  static filterTechniciansForExternalTechnicians(technicians: PATechnician[]): PATechnician[] {
    return technicians.filter(technician => !technician.isBentomaxTechnician())
  }

  static filterTechniciansForProjectInclusiveTechnicians(technicians: PATechnician[], project_id: number): PATechnician[] {
    return technicians.filter(technician => technician.isProjectTechnician(project_id))
  }

  static filterTechniciansForProjectsInclusiveTechnicians(technicians: PATechnician[], project_ids: number[]): PATechnician[] {
    return technicians.filter(technician => project_ids.filter(project_id => technician.isProjectTechnician(project_id)).length)
  }

  static filterTechniciansForOperations(technicians: PATechnician[]): PATechnician[] {
    return technicians.filter(technician => [...technician.technicianDates].reduce((sum, technician_date_pair) => sum + technician_date_pair[1].tour.operations.length, 0) > 0)
  }

  static async filterTechniciansForOperationsInCalendarWeekRange(technicians: PATechnician[], calendar_weeks: CalendarWeek[], userService: UserService): Promise<PATechnician[]> {
    let valid_technician_ids: number[] = []
    for (let cwd of calendar_weeks.map(cw => PATimeControl.Instance.getCalendarWeekData(cw))) {
      const date_from = PATimeControl.Instance.dateToDatestring(new Date(cwd.weekdays['monday'].timestamp), true, false) + " 00:00:00"
      const date_until = PATimeControl.Instance.dateToDatestring(new Date(cwd.weekdays['sunday'].timestamp), true, false) + " 23:59:59"
      if (technicians.length) {
        valid_technician_ids = valid_technician_ids.concat(await new Promise<number[]>(resolve => {
          userService.getUserIdsWithOperationsBetweenDates(date_from, date_until, technicians.map(technician => technician.id)).subscribe(
            data => {
              resolve(data)
            },
            err => {
              console.log(err)
              resolve([])
            }
          )
        }))
      }
    }
    return technicians.filter(technician => valid_technician_ids.includes(technician.id))
  }

  static async filterTechniciansForAbsentOperationsInCalendarWeekRange(technicians: PATechnician[], calendar_weeks: CalendarWeek[], userService: UserService): Promise<PATechnician[]> {
    let invalid_technician_ids: number[] = []
    for (let cwd of calendar_weeks.map(cw => PATimeControl.Instance.getCalendarWeekData(cw))) {
      const date_from = PATimeControl.Instance.dateToDatestring(new Date(cwd.weekdays['monday'].timestamp), true, false) + " 00:00:00"
      const date_until = PATimeControl.Instance.dateToDatestring(new Date(cwd.weekdays['sunday'].timestamp), true, false) + " 23:59:59"
      if (technicians.length) {
        invalid_technician_ids = invalid_technician_ids.concat(await new Promise<number[]>(resolve => {
          userService.getUserIdsWithOperationsBetweenDates(date_from, date_until, technicians.map(technician => technician.id)).subscribe(
            data => {
              resolve(data)
            },
            err => {
              console.log(err)
              resolve([])
            }
          )
        }))
      }
    }
    return technicians.filter(technician => !invalid_technician_ids.includes(technician.id))
  }

  static filterTechniciansForTicketMaterial(technicians: PATechnician[], ticket: PATicket): PATechnician[] {
    return technicians.filter(technician => technician.hasTicketMaterial(ticket))
  }

  static filterTechniciansForTicketsMaterial(technicians: PATechnician[], tickets: PATicket[]): PATechnician[] {
    return technicians.filter(technician => tickets.filter(ti => technician.hasTicketMaterial(ti)).length > 0)
  }

  static filterTechniciansForOperationMaterial(technicians: PATechnician[], operation: PAOperation): PATechnician[] {
    return technicians.filter(technician => technician.hasOperationMaterial(operation))
  }

  static filterTechniciansForDistanceToLocation(technicians: PATechnician[], location: PALocation, distance: number): PATechnician[] {
    return technicians.filter(technician => technician.getDistanceToCoordinatesAsTheCrowFlies(location.coordinates.latitude, location.coordinates.longitude) < distance)
  }

  static filterTicketsForDistanceToLocation(tickets: PATicket[], location: PALocation, distance: number): PATicket[] {
    return tickets.filter(ticket => ticket.getDistanceToCoordinatesAsTheCrowFlies(location.coordinates.latitude, location.coordinates.longitude) < distance)
  }

  static filterOperationsForDistanceToLocation(operations: PAOperation[], location: PALocation, distance: number): PAOperation[] {
    return operations.filter(operation => operation.getDistanceToCoordinatesAsTheCrowFlies(location.coordinates.latitude, location.coordinates.longitude) < distance)
  }

  static async filterOperationsForDistanceToTour(operations: PAOperation[], tour: PATour, distance: number, search_depth?: number): Promise<PAOperation[]> {
    let coordinates = await tour.getMBRouteCoordinates()
    let validity_list = operations.map(op => op.ticket.client.location.isInTourRadius(coordinates.length ? coordinates : [[tour.startLocation.coordinates.longitude, tour.startLocation.coordinates.latitude]], distance, search_depth))
    return operations.filter(operation => validity_list[operations.indexOf(operation)])
  }

  static filterOperationsForProjectInclusiveOperations(operations: PAOperation[], project_id: number) {
    return operations.filter(operation => operation.belongsToProject(project_id))
  }

  static filterOperationsForProjectsInclusiveOperations(operations: PAOperation[], project_ids: number[]) {
    return operations.filter(operation => project_ids.filter(project_id => operation.belongsToProject(project_id)).length > 0)
  }

  static filterTicketsForProjectsInclusiveTickets(tickets: PATicket[], project_ids: number[]) {
    return tickets.filter(ticket => project_ids.filter(project_id => ticket.belongsToProject(project_id)).length > 0)
  }

  static filterOperationsForPriority(operations: PAOperation[], priority_id: number) {
    return operations.filter(operation => operation.ticket.priority.id == priority_id)
  }

  static filterOperationsForPriorities(operations: PAOperation[], priority_ids: number[]) {
    return operations.filter(operation => priority_ids.find(pid => operation.ticket.priority.id == pid))
  }

  static filterOperationsForStatuses(operations: PAOperation[], status_ids: number[]) {
    return operations.filter(operation => status_ids.find(sid => operation.ticket.status?.id && operation.ticket.status.id == sid))
  }

  static async filterTicketsForPriority(tickets: PATicket[], priority_id: number) {
    return tickets.filter(ticket => ticket.priority && ticket.priority.id == priority_id)
  }

  static filterTicketsForPriorities(tickets: PATicket[], priority_ids: number[]) {
    return tickets.filter(ticket => priority_ids.find(pid => ticket.priority && ticket.priority.id == pid))
  }

  static filterTicketsForStatuses(tickets: PATicket[], status_ids: number[]) {
    return tickets.filter(ticket => status_ids.find(sid => ticket.status?.id && ticket.status.id == sid))
  }

  static filterOperationsForPriorityInclusiveOperations(operations: PAOperation[], priority_id: number) {
    return operations.filter(operation => operation.belongsToPriority(priority_id))
  }

  static filterTicketsForSLABeforeDeadline(tickets: PATicket[], deadline_timestamp: number) {
    return tickets.filter(ticket => ticket.datesla && (new Date(ticket.datesla).getTime() <= deadline_timestamp))
  }

  static filterOperationsForSLABeforeDeadline(operations: PAOperation[], deadline_timestamp: number) {
    return operations.filter(operation => operation.ticket.datesla && (new Date(operation.ticket.datesla).getTime() <= deadline_timestamp))
  }

  static filterOperationsForSLAOrAppointmentBeforeDeadline(operations: PAOperation[], deadline_timestamp: number) {
    return operations.filter(operation => (operation.ticket.datesla && (new Date(operation.ticket.datesla).getTime() <= deadline_timestamp)) || (operation.ticket.appointment_date && (new Date(operation.ticket.appointment_date).getTime() <= deadline_timestamp)))
  }

  static filterTicketsForAppointmentBeforeDeadline(tickets: PATicket[], deadline_timestamp: number) {
    return tickets.filter(ticket => ticket.appointment_date && (new Date(ticket.appointment_date).getTime() <= deadline_timestamp))
  }

  static filterOperationsForAppointmentBeforeDeadline(operations: PAOperation[], deadline_timestamp: number) {
    return operations.filter(operation => operation.ticket.appointment_date && (new Date(operation.ticket.appointment_date).getTime() <= deadline_timestamp))
  }

  static filterTicketsForSLAOrAppointmentBeforeDeadline(tickets: PATicket[], deadline_timestamp: number) {
    return tickets.filter(ticket => (ticket.datesla && (new Date(ticket.datesla).getTime() <= deadline_timestamp)) || (ticket.appointment_date && (new Date(ticket.appointment_date).getTime() <= deadline_timestamp)))
  }

  static filterOperationsForTechnicianArticles(operations: PAOperation[], technician: PATechnician) {
    return operations.filter(operation => PAFilter.filterTechniciansForOperationMaterial([technician], operation).length > 0)
  }

  static filterTicketsForTechnicianArticles(tickets: PATicket[], technician: PATechnician) {
    return tickets.filter(ticket => PAFilter.filterTechniciansForTicketMaterial([technician], ticket).length > 0)
  }

  static filterOperationsForNeededMaterial(operations: PAOperation[]) {
    return operations.filter(operation => operation.ticket && operation.ticket.materials && operation.ticket.materials.length)
  }

  static filterOperationsForNoNeededMaterial(operations: PAOperation[]) {
    return operations.filter(operation => !(operation.ticket && operation.ticket.materials && operation.ticket.materials.length))
  }

  static filterOperationsForSlaOrAppointment(operations: PAOperation[]) {
    return operations.filter(operation => operation.ticket.datesla || operation.ticket.appointment_date)
  }

  static filterOperationsForSla(operations: PAOperation[]) {
    return operations.filter(operation => operation.ticket.datesla)
  }

  static filterOperationsForAppointment(operations: PAOperation[]) {
    return operations.filter(operation => operation.ticket.appointment_date)
  }

  static filterTicketsForSlaOrAppointment(tickets: PATicket[]) {
    return tickets.filter(ticket => ticket.datesla || ticket.appointment_date)
  }

  static filterTicketsForSla(tickets: PATicket[]) {
    return tickets.filter(operation => operation.datesla)
  }

  static filterTicketsForAppointment(tickets: PATicket[]) {
    return tickets.filter(ticket => ticket.appointment_date)
  }

  static filterTicketsForNeededMaterial(tickets: PATicket[]) {
    return tickets.filter(ticket => ticket.materials && ticket.materials.length)
  }

  static filterTicketsForNoNeededMaterial(tickets: PATicket[]) {
    return tickets.filter(ticket => !(ticket.materials && ticket.materials.length))
  }
}