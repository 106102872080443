import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { CalendarWeekData, PlanningAssistantOperation } from "../../../../_models/planning-assistant.interface";
import { PAOperation } from "../../classes/operation";
import { PATechnicianDate } from "../../classes/technician-date";
import { PAUtil } from "../../classes/util";
import { PlanningAssistantService } from "../../../../_services/planning-assistant.service";
import { PAFilterControl } from "../../singletons/pa-filter-control";
import { PATimeControl } from "../../singletons/pa-time-control";
import { PADataControl } from "../../singletons/pa-data-control";
import { TechnicianDateCollection } from "../../classes/technician-date-collection";
import { NgIf, NgFor, NgClass } from '@angular/common';
import { TooltipModule } from '@cloudfactorydk/ng2-tooltip-directive';
import { CommonModule } from "@angular/common";
import { ThrobberComponent } from "../../../_shared/throbber/throbber.component";

@Component({
  selector: 'hawk-close-tours',
  templateUrl: './close-tours.component.html',
  styleUrls: ['./close-tours.component.scss', './../../styles/common_styles.scss'],
  standalone: true,
  imports: [NgIf, ThrobberComponent, NgFor, NgClass, TooltipModule, CommonModule]
})
export class CloseToursComponent implements OnChanges {

  readonly Operation = PAOperation

  @Input() shownCalendarWeeksData: CalendarWeekData[]
  @Input() selectedCalendarWeekData: CalendarWeekData
  @Input() referenceOperation: PAOperation
  @Input() kmRadius: number
  @Input() showOverviewForTechnicianDateCollections: TechnicianDateCollection[] = []
  @Input() lastOperationChangeTimeStamp: number = 0
  @Input() lastMainRouteLocationChangeTimestamp: number  = 0

  @Output() clickTechnicianDate = new EventEmitter<PATechnicianDate>()

  currentCalendarWeekData: CalendarWeekData = null
  closeTechnicianDates: CloseTechnicianDate[] = []
  closeTravelTechnicianDates: CloseTechnicianDate[] = []
  shownCloseTechnicianDates: CloseTechnicianDate[] = []

  updatingTechnicianDates: boolean = false
  updatingTravelTechnicianDates: boolean = false

  constructor(
    public planningAssistantService: PlanningAssistantService
  ) {
  }

  ngOnChanges(changes: SimpleChanges): void {

    let update_technician_dates = false
    let update_travel_technician_dates = false

    if (changes['shownCalendarWeeksData'] || changes['selectedCalendarWeekData']) {
      this.currentCalendarWeekData = this.getShownCalendarWeekData()
      update_technician_dates = true
      update_travel_technician_dates = true
    }

    if (changes['referenceOperation'] || changes['kmRadius']) {
      update_technician_dates = true
      update_travel_technician_dates = true
    }

    if (changes['lastOperationChangeTimeStamp']) {
      update_technician_dates = true
    }

    if (changes['lastMainRouteLocationChangeTimestamp']) {
      update_travel_technician_dates = true
    }

    if (update_technician_dates) {
      this.updatingTechnicianDates = true
      this.updateCloseTechnicianDates().then( _ => this.updatingTechnicianDates = false)
    }

    if (update_travel_technician_dates) {
      this.updatingTravelTechnicianDates = true
      this.updateCloseTravelTechnicianDates().then( _ => this.updatingTravelTechnicianDates = false)
    }
  }

  getShownCalendarWeekData(): CalendarWeekData {
    if (this.selectedCalendarWeekData) {
      return this.selectedCalendarWeekData
    } else {
      return this.shownCalendarWeeksData[0]
    }
  }

  async updateCloseTechnicianDates(): Promise<void> {
    let technician_dates_with_new_planned_operations = this.getCalendarWeeksCloseTechnicianDatesWithNewPlannedOperations()
    let technician_dates_with_close_operations_from_db = this.getOperationHashesTechnicianDates(await this.getClosePlannedOperationHashes())

    this.closeTechnicianDates = [...new Set(technician_dates_with_close_operations_from_db.concat(technician_dates_with_new_planned_operations))].filter(
      td => !td.isPastTechnicianDate()
    ).map(td => {
      return {technician_date: td, distance_km: td.closestDistanceToLocation(this.referenceOperation.ticket.location)}
    })
    this.updateShownCloseTechnicianDates()
  }

  async updateCloseTravelTechnicianDates(): Promise<void> {
    this.closeTravelTechnicianDates = (await this.getCloseTravelTechnicianDateTypes()).filter(
      tdt => !tdt.td.isPastTechnicianDate()
    ).map(tdt => {
      return {technician_date: tdt.td, distance_km: tdt.td.closestDistanceToLocation(this.referenceOperation.ticket.location), travel_technician_date_type: tdt.type}
    })
    this.updateShownCloseTechnicianDates()
  }

  updateShownCloseTechnicianDates(): void {
    let all_close_technician_dates = this.closeTechnicianDates.concat(this.closeTravelTechnicianDates)
    let shown_technician_dates: CloseTechnicianDate[] = []
    for (let week_day of PATimeControl.Instance.weekDays) {
      let ts = this.currentCalendarWeekData.weekdays[week_day].timestamp
      let distance_sorted_day_filtered_close_technician_dates = all_close_technician_dates.filter(ctd => ctd.technician_date.day.utc_timestamp == ts).sort(
        (ctd_a, ctd_b)=> {
          return ctd_a.distance_km < ctd_b.distance_km ? -1 : 1
        }
      )
      for (let close_technician_date of distance_sorted_day_filtered_close_technician_dates) {
        if (!shown_technician_dates.find(std => std.technician_date == close_technician_date.technician_date)) {
          shown_technician_dates.push(close_technician_date)
        }
      }
    }

    this.shownCloseTechnicianDates = shown_technician_dates
  }

  getCalendarWeeksCloseTechnicianDatesWithNewPlannedOperations(): PATechnicianDate[] {
    let changed_operations: PAOperation[] = PADataControl.Instance.operationChanges.map(oc => oc.operation_change_map.get(PAOperation.operationChangePlanningID).new_operation)
    let close_changed_operations = changed_operations.filter(op => PAUtil.calcDistanceAsTheCrowFlies(op.ticket.location.coordinates.latitude, op.ticket.location.coordinates.longitude, this.referenceOperation.ticket.location.coordinates.latitude, this.referenceOperation.ticket.location.coordinates.longitude) <= this.kmRadius)
    return [... new Set(close_changed_operations.map(co => co.getTechnicianDate(false)))].filter(td => PATimeControl.Instance.equalCalendarWeeks(td.getCalendarWeek(), this.getShownCalendarWeekData().calendar_week))
  }

  getOperationHashesTechnicianDates(operations: PlanningAssistantOperation[]): PATechnicianDate[] {
    let res: PATechnicianDate[] = []

    let technicians = operations.map(op => PADataControl.Instance.getTechnician(op.user_ids[0]))
    for (let technician of technicians) {
      let technician_operations = operations.filter(op => op.user_ids[0] == technician.id)
      let technician_day_timestamps: number[] = [... new Set(technician_operations.map(op => PATimeControl.Instance.dateStringToTimestamp(op.operation_date, true, true)))]
      for (let timestamp of technician_day_timestamps) {
        res.push(technician.getTechnicianDate(timestamp, true, true))
      }
    }

    return res
  }

  async getCloseTravelTechnicianDateTypes(): Promise<{td: PATechnicianDate, type: 'Anfahrt' | 'Abfahrt'}[]> {
    let ref_location = this.referenceOperation.ticket.location
    let res: {td: PATechnicianDate, type: 'Anfahrt' | 'Abfahrt'}[] = []
    for (let week_day of PATimeControl.Instance.weekDays) {
      let wd_timestamp = this.currentCalendarWeekData.weekdays[week_day].timestamp
      let travel_technician_dates = await Promise.all(
        PADataControl.Instance.allTechnicians.filter(
          t => t.isProjectTechnician(this.referenceOperation.ticket.project.id)
        ).map(
          async t => await PATechnicianDate.getTravelTechnicianDate(t.id, wd_timestamp)
        )
      );
      let active_ttds = travel_technician_dates.filter(ttd => ttd.isActive())
      let active_tds: PATechnicianDate[] = active_ttds.map(
        active_ttd => {
          let technician = PADataControl.Instance.getTechnician(active_ttd.technicianID)
          return technician.getTechnicianDate(active_ttd.timestamp, true, true)
        }
      );
      await Promise.all(active_tds.map(async td => await td.tour.initializeTravelTechnicianDate()))
      let close_tds = active_tds.filter(
        td => {
          let location = td.tour.endLocation
          return PAUtil.calcDistanceAsTheCrowFlies(location.coordinates.latitude, location.coordinates.longitude, ref_location.coordinates.latitude, ref_location.coordinates.longitude) <= this.kmRadius;
        }
      )
      res = res.concat(
        close_tds.map(td => { return {td: td, type: 'Anfahrt'} }),
        close_tds.map(td => {
          return {td: td.technician.getTechnicianDate(td.day.utc_timestamp + 24 * 60 * 60 * 1000, true, true), type: 'Abfahrt'}
        })
      )
    }
    return res
  }

  tourComparisonsIncludeTechnicianDate(technician_date: PATechnicianDate): boolean {
    return !!this.showOverviewForTechnicianDateCollections.find(tdc => tdc.technicianDates.includes(technician_date))
  }

  async getClosePlannedOperationHashes(): Promise<PlanningAssistantOperation[]> {
    let reference_coordinates =  this.referenceOperation.ticket.location.coordinates
    let planned_calendar_week_operations = await this.getPlannedOperationsForSelectedCalendarWeekFromDatabase()
    return planned_calendar_week_operations.filter(op => PAUtil.calcDistanceAsTheCrowFlies(op.ticket.address_latitude, op.ticket.address_longitude, reference_coordinates.latitude, reference_coordinates.longitude) <= this.kmRadius)
  }

  async getPlannedOperationsForSelectedCalendarWeekFromDatabase(): Promise<PlanningAssistantOperation[]> {
    let ts_now = Date.now()
    let cw_start_timestamp = this.currentCalendarWeekData.weekdays.monday.timestamp
    let until_timestamp = this.currentCalendarWeekData.weekdays.sunday.timestamp
    if (ts_now < until_timestamp) {
      let from_timestamp = ts_now > cw_start_timestamp ? ts_now : cw_start_timestamp
      return await new Promise(resolve => {
        this.planningAssistantService.getOperationUserInfo(
          PADataControl.Instance.allTechnicians.filter(t => t.isProjectTechnician(this.referenceOperation.ticket.project.id)).map(t => t.id),
          PATimeControl.Instance.timestampToDatestring(from_timestamp, true) + ' 00:00:00',
          PATimeControl.Instance.timestampToDatestring(until_timestamp, true) + ' 23:59:59',
          { exclude_material_return: true }
        ).subscribe(
          data => {
            resolve(data.reduce((operations: PlanningAssistantOperation[], item) => operations.concat(item.operations) , []))
          },
          error => {
            console.log(error)
          }
        )
      })
    } else {
      return []
    }
  }

  protected readonly Util = PAUtil;
  protected readonly PAFilterControl = PAFilterControl;
  protected readonly PATimeControl = PATimeControl;
}

interface CloseTechnicianDate {
  technician_date: PATechnicianDate,
  distance_km: number,
  travel_technician_date_type?: 'Anfahrt' | 'Abfahrt'
}