import { Component, OnInit } from '@angular/core';
import { faMailBulk, faPhone, faSms } from "@fortawesome/free-solid-svg-icons";
import { ReactiveFormsModule, UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import * as mapboxgl from "mapbox-gl";
import { PAUtil } from "../planning-assistant/classes/util";
import { GeocoderEvent } from "../../_models/mapbox.interface";
import { MapBoxService } from "../../_services/mapbox.service";
import { CustomerService } from "../../_services";
import { Router, RouterLink } from "@angular/router";
import { PermittedCustomerParams } from "../../_models/customer.interface";
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import { MatFormField } from "@angular/material/form-field";
import { MatInput } from "@angular/material/input";
import { MatButton } from "@angular/material/button";
import { RecordNavigationComponent } from "../_shared/record-navigation/record-navigation.component";

@Component({
  selector: 'hawk-customer-create',
  templateUrl: './customer-create.component.html',
  standalone: true,
  imports: [
    RouterLink,
    ReactiveFormsModule,
    RecordNavigationComponent,
    MatFormField,
    MatInput,
    MatButton
  ],
  styleUrls: ['./customer-create.component.scss', '../_shared/styles/common-styles.scss']
})
export class CustomerCreateComponent implements OnInit {

  protected readonly faSms = faSms;
  protected readonly faPhone = faPhone;
  protected readonly faMailBulk = faMailBulk;

  addressGeocoder?: MapboxGeocoder

  customerForm: UntypedFormGroup = new UntypedFormGroup({
    letter: new UntypedFormControl({value: ''}, [Validators.required, Validators.maxLength(3), Validators.pattern('[-_a-zA-Z]*')]),
    name: new UntypedFormControl({value: ''}, [Validators.required]),
    url: new UntypedFormControl({value: ''}),
    xml_id: new UntypedFormControl({value: ''}),
    company: new UntypedFormControl({value: ''}),
    street: new UntypedFormControl({value: '', disabled: true}),
    zip: new UntypedFormControl({value: '', disabled: true}, [Validators.pattern('^([a-zA-Z]{2}-)?[0-9]{2,5}( [a-zA-Z]{0,2})?$')]),
    city: new UntypedFormControl({value: '', disabled: true}),
    country: new UntypedFormControl({value: '', disabled: true}),
    state: new UntypedFormControl({value: '', disabled: true}),
    lat: new UntypedFormControl({value: ''}),
    lng: new UntypedFormControl({value: ''}),
  });
  
  constructor(
    private mapBoxService: MapBoxService,
    private customerService: CustomerService,
    private router: Router
  ) {
  }

  ngOnInit(): void {
    this.resetForm()
    this.setGeocoder()
  }

  resetForm():void {
    this.customerForm.setValue({
      letter: '',
      name: '',
      url: '',
      xml_id: '',
      company: '',
      street: '',
      zip: '',
      city: '',
      country: '',
      state: '',
      lat: 0,
      lng: 0,
    });
  }
  
  public async setGeocoder(): Promise<void> {
    this.addressGeocoder = new MapboxGeocoder({
      accessToken: this.mapBoxService.accessToken,
      marker: false,
      language: 'de-DE',
      mapboxgl: mapboxgl,
      placeholder: 'Adresse suchen'
    })
    while (!document.getElementById('address_geocoder')) {
      await PAUtil.sleep(100)
    }
    const address_geocoder_elem = document.getElementById('address_geocoder')
    this.addressGeocoder.addTo(address_geocoder_elem);

    this.addressGeocoder.on('result', (event) => {
      this.onAddressGeocoderEvent(event)
    })
  }

  private onAddressGeocoderEvent(event: GeocoderEvent) {
    let {country, region, city, post_code, street, street_no, center, name} = PAUtil.getGeocoderEventAddress(event);

    let address_country_control = this.customerForm.controls['country']
    address_country_control.setValue(country)
    address_country_control.markAsTouched();
    address_country_control.updateValueAndValidity()
    let address_state_control = this.customerForm.controls['state']
    address_state_control.setValue(region)
    address_state_control.markAsTouched();
    address_state_control.updateValueAndValidity()
    let address_city_control = this.customerForm.controls['city']
    address_city_control.setValue(city)
    address_city_control.markAsTouched();
    address_city_control.updateValueAndValidity()
    let address_zip_control = this.customerForm.controls['zip']
    address_zip_control.setValue(post_code)
    address_zip_control.markAsTouched();
    address_zip_control.updateValueAndValidity()
    let address_street_control = this.customerForm.controls['street']
    address_street_control.setValue(street + ' ' + street_no)
    address_street_control.markAsTouched();
    address_street_control.updateValueAndValidity()

    let latitude_control = this.customerForm.controls['lat']
    latitude_control.setValue(center[1])
    latitude_control.markAsTouched();
    latitude_control.updateValueAndValidity()
    let longitude_control = this.customerForm.controls['lng']
    longitude_control.setValue(center[0])
    longitude_control.markAsTouched();
    longitude_control.updateValueAndValidity()

    this.customerForm.updateValueAndValidity()
  }

  async onSubmit() {
    const customer_id = await this.createCustomer()
    if (customer_id) {
      this.router.navigate(['/kunde', customer_id])
    }
  }

  createCustomer(): Promise<number> {
    return new Promise<number>(resolve => {
      this.customerService.createCustomer(this.getCreateHash()).subscribe(
        data => {
          resolve(data.id)
        },
        err => {
          console.log(err)
          resolve(null)
        }
      )
    })
  }

  getCreateHash(): PermittedCustomerParams {
    const controls = this.customerForm.controls

    let street = controls['street'].value
    let zip_code = controls['zip'].value
    let city = controls['city'].value
    let country = controls['country'].value

    return {
      name: controls['name'].value,
      letter: controls['letter'].value.toUpperCase(),
      url: controls['url'].value,
      xml_id: controls['xml_id'].value,
      address_company: controls['company'].value,
      address_street: street,
      address_city: city,
      address_zip: zip_code,
      address_country: country,
      address_state: controls['state'].value,
      address_latitude: controls['lat'].value,
      address_longitude: controls['lng'].value,
      address_used_google_maps_parameter: `${street}, ${zip_code} ${city}, ${country}`
    }
  }
}
