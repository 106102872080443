import { PATechnician } from "./technician";
import { Priority } from "../../../_models";
import { PriorityRampUpRuleHash } from "../../../_models/planning-assistant.interface";
import { PAProject } from "./project";
import { PADataControl } from "../singletons/pa-data-control";

export class TimePredictionRule {

  private _technician?: PATechnician | 'default'
  private _timePredictionFunctionWrapper: TimePredictionFunctionWrapper
  private _id: number

  constructor(
    public priority: Priority,
    private _step_start: number,
    private _step_end: number,
    config?: {
      technician?: PATechnician | 'default',
      function_type?: 'absolute' | 'linear', // | 'logarithmic'
      additional_time_percent?: number,
      id?: number
    }
  ) {
    this._technician = config?.technician
    if (config?.function_type == 'absolute' || !config?.function_type) {
      this._timePredictionFunctionWrapper = new AbsoluteTimePredictionFunctionWrapper(this, config?.additional_time_percent)
    } else if (config.function_type == "linear") {
      this._timePredictionFunctionWrapper = new LinearTimePredictionFunctionWrapper(this, config?.additional_time_percent)
    }
    this._id = config?.id
  }

  get timePredictionFunctionWrapper(): TimePredictionFunctionWrapper {
    return this._timePredictionFunctionWrapper
  }

  set timePredictionFunctionWrapper(f_wrapper: TimePredictionFunctionWrapper) {
    this._timePredictionFunctionWrapper = f_wrapper
  }

  setAbsoluteTimePredictionFunctionWrapper(): void {
    this.timePredictionFunctionWrapper = new AbsoluteTimePredictionFunctionWrapper(this)
  }

  setLinearTimePredictionFunctionWrapper(): void {
    this.timePredictionFunctionWrapper = new LinearTimePredictionFunctionWrapper(this)
  }

  setTimePredictionFunctionWrapper(type: string): void {
    if (type == 'absolute') {
      this.setAbsoluteTimePredictionFunctionWrapper()
    }
    if (type == 'linear') {
      this.setLinearTimePredictionFunctionWrapper()
    }
  }

  get technician(): PATechnician | 'default' {
    return this._technician
  }

  set technician(technician: PATechnician | 'default') {
    this._technician = technician
  }

  get step_start(): number {
    return this._step_start
  }

  set step_start(step_start: number) {
    this._step_start = step_start
  }

  get step_end(): number {
    return this._step_end
  }

  set step_end(step_end: number) {
    this._step_end = step_end
  }

  public updateValues(config_hash: PriorityRampUpRuleHash) {
    this._step_start = config_hash.step_from
    this._step_end = config_hash.step_until
    this._technician = config_hash.user_id ? PADataControl.Instance.getTechnician(config_hash.user_id) : 'default'
    this.setTimePredictionFunctionWrapper(config_hash.function_type)
    this._timePredictionFunctionWrapper.y_offset_value = config_hash.additional_time_percent
  }

  async saveChanges() {
    await this._saveChangesInDb()
  }

  toUpdateHash(): PriorityRampUpRuleHash {
    return {
      priority_id: this.priority.id,
      user_id: this.technician == 'default' ? null : this.technician.id,
      step_from: this.step_start,
      step_until: this.step_end,
      function_type: this._timePredictionFunctionWrapper.type,
      additional_time_percent: this._timePredictionFunctionWrapper.y_offset_value
    }
  }

  private async _saveChangesInDb() {
    let update_hash = this.toUpdateHash()
    return new Promise<void>(resolve => {
      if (!this._id) {
        PAProject.planningAssistantService.createPriorityRampUpRule(update_hash).subscribe(
          data => {
            this._id = data.id
            resolve()
          },
          error => {
            console.log(error)
            resolve()
          }
        )
      } else {
        PAProject.planningAssistantService.updatePriorityRampUpRule(this._id, update_hash).subscribe(
          _ => {
            resolve()
          },
          error => {
            console.log(error)
            resolve()
          }
        )
      }
    })
  }

  public isDeletable(): boolean {
    return !!this._id
  }

  public deleteInDb() {
    return new Promise<void>(resolve => {
      if (this._id) {
        PAProject.planningAssistantService.deletePriorityRampUpRule(this._id).subscribe(
          _ => {
            this._id = null
            resolve()
          },
          error => {
            console.log(error)
            resolve()
          }
        )
      } else {
        resolve()
      }
    })
  }

}

export abstract class TimePredictionFunctionWrapper {


  constructor(
    protected _timePredictionRule: TimePredictionRule,
    private _y_offset_value: number = 50
  ) {
  }

  get y_offset_value() {
    return this._y_offset_value
  }

  set y_offset_value(y_offset_value: number) {
    this._y_offset_value = y_offset_value
  }

  getAdditionalValue(x: number): number {
    if (!this.indexIsInBounds(x)) return 0
    return this.y_offset_value - ((x - this._timePredictionRule.step_start) * this.getAcceleration())
  }

  indexIsInBounds(x: number): boolean {
    return !(x < this._timePredictionRule.step_start || x > this._timePredictionRule.step_end)
  }

  abstract type: 'absolute' | 'linear' | 'logarithmic'

  abstract predictFunction(x: number, time_estimation: number): number

  abstract getAcceleration(): number
}

export class AbsoluteTimePredictionFunctionWrapper extends TimePredictionFunctionWrapper {

  public type: 'absolute' | 'linear' | 'logarithmic' = 'absolute'

  public predictFunction(x: number, time_estimation: number): number {
    return time_estimation * (1 + (this.getAdditionalValue(x) / 100));
  }

  getAcceleration(): number {
    return 0
  }
}

export class LinearTimePredictionFunctionWrapper extends TimePredictionFunctionWrapper {

  public type: 'absolute' | 'linear' | 'logarithmic' = 'linear'

  public predictFunction(x: number, time_estimation: number): number {
    return time_estimation * (1 + (this.getAdditionalValue(x) / 100));
  }

  getAcceleration(): number {
    let steps = this._timePredictionRule.step_end - this._timePredictionRule.step_start
    return steps ? this.y_offset_value / steps : 0
  }

}
