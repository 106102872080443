import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'

import { environment } from '../../environments/environment'
import { Supplier } from '../_models'

@Injectable({
  providedIn: 'root'
})
export class SupplierService {

  constructor(
    private http: HttpClient
  ) {
  }

  public all(params: any = {}) {
    const path = environment.apiURL + 'suppliers.json'

    return this.http.get<Supplier[]>(path, { params: params })
  }
}
