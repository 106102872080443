import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { NgStyle, NgIf } from '@angular/common';

import { CommonModule } from "@angular/common";
import { FaIconComponent, FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@Component({
  selector: 'hawk-expand-bar-header',
  templateUrl: './expand-bar-header.component.html',
  styleUrls: ['./expand-bar-header.component.scss', './../../styles/common_styles.scss'],
  standalone: true,
  imports: [NgStyle, NgIf, FaIconComponent, CommonModule, FontAwesomeModule]
})
export class ExpandBarHeaderComponent implements OnInit {

  constructor() {
  }

  faChevronDown = faChevronDown
  faChevronUp = faChevronUp

  @Input() description: string
  @Input() isExpanded: boolean
  @Input() leftAligned: boolean = false
  @Input() fontSize: string = '1rem'
  @Input() height: string = 'fit-content'
  @Input() fontColor: string = 'white'
  @Output() toggle = new EventEmitter<boolean>()

  ngOnInit(): void {
  }

  click(): void {
    this.isExpanded = !this.isExpanded
    this.toggle.emit(this.isExpanded)
  }

}