import { Subscription } from "rxjs"

/**
 * Unsubscribe a list of Subcribers.
 * @param subcriptions
 */
export function unsubscribeAll(subcriptions: Subscription[]) {
  if (subcriptions) {
    subcriptions.forEach((sub) => {
      if (sub && typeof sub.unsubscribe === "function") {
        sub.unsubscribe()
      }
    })
  }
}
