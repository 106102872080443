import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'

import { environment } from '../../environments/environment'
import { WorkflowTemplate } from '../_models'
import { Observable } from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class WorkflowService {

  constructor(private http: HttpClient) {}

  public uploadOperationWorkflow(operation_id: number, files: FileList): Observable<WorkflowTemplate>  {
    const formData = new FormData()
    formData.append("operation_id", operation_id.toString())

    return this.metaUploadWorkflow(formData, files)
  }

  public uploadTicketWorkflow(ticket_id: number, files: FileList): Observable<WorkflowTemplate>  {
    const formData = new FormData()
    formData.append("ticket_id", ticket_id.toString())

    return this.metaUploadWorkflow(formData, files)
  }

  public uploadProjectPriorityWorkflow(project_id: number, priority_id: number, files: FileList): Observable<WorkflowTemplate>  {
    const formData = new FormData()
    formData.append("project_id", project_id.toString())
    formData.append("priority_id", priority_id.toString())

    return this.metaUploadWorkflow(formData, files)
  }

  public uploadProjectWorkflow(project_id: number, files: FileList): Observable<WorkflowTemplate>  {
    const formData = new FormData()
    formData.append("project_id", project_id.toString())

    return this.metaUploadWorkflow(formData, files)
  }

  public metaUploadWorkflow(formData: FormData, files: FileList): Observable<WorkflowTemplate> {
    const path = environment.apiURL + 'operation_metadata/upload_workflow'

    const file:File = files[0];

    if (file) {
        formData.append("file", file)

        return this.http.post<WorkflowTemplate>(path, formData)
    }
  }

  public getOperationWorkflowsPresent(operationId: number): Observable<WorkflowTemplate>  {
    let path = environment.apiURL + "operation_metadata/" + operationId + "/show_workflows.json"

    return this.http.get<any>(path)
  }
}
