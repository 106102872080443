import { Pipe, PipeTransform } from '@angular/core'
import { Technician } from '../_models'

@Pipe({
    name: 'technicianFilter',
})
export class TechnicianFilterPipe implements PipeTransform {
  transform(allTechnicians: Technician[], searchText?: string): Technician[] {
    if (!allTechnicians) {
      return allTechnicians
    }

    return allTechnicians.filter(technician => `${technician.name}${technician.company}`.toLowerCase().includes(searchText.toLowerCase()))
  }
}
