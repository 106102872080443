import { CommonModule } from "@angular/common";

import { Component, Input, OnInit } from '@angular/core'
import { Article } from 'src/app/_models'
import { ArticleService } from 'src/app/_services'

@Component({
  imports: [CommonModule],
    selector: 'hawk-operation-subpage-step-article',
    templateUrl: './operation-subpage-step-article.component.html',
    styleUrls: ['./operation-subpage-step-article.component.scss'],
})
export class OperationSubpageStepArticleComponent implements OnInit {
  @Input() articleId: number

  public article: Article
  constructor(
    private articleService: ArticleService,
  ) { }

  ngOnInit(): void {
    this.articleService.getArticle(this.articleId).subscribe(
      (data) => {
        this.article = data
      },
      (err) => {
        console.error(err)
      }
    )
  }
}