import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { ArticleTemplate } from 'src/app/_models';
import { NgbCalendar, NgbDate, NgbDateParserFormatter, NgbInputDatepicker } from '@ng-bootstrap/ng-bootstrap';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { NgSelectComponent, NgLabelTemplateDirective, NgOptionTemplateDirective } from '@ng-select/ng-select';
import { NgIf } from '@angular/common';
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { MatFormFieldModule } from "@angular/material/form-field";
import { NgSelectModule } from "@ng-select/ng-select";
import { FaIconComponent, FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { Component, OnInit, inject } from '@angular/core'
import { ArticleService } from 'src/app/_services'
import { Router, RouterLink } from '@angular/router'
@Component({
    selector: 'hawk-delivery-note-search-form',
    templateUrl: './delivery-note-search-form.component.html',
    styleUrls: ['./delivery-note-search-form.component.scss'],
    imports: [ReactiveFormsModule, MatFormField, MatLabel, MatInput, NgSelectComponent, NgLabelTemplateDirective, NgOptionTemplateDirective, NgIf, NgbInputDatepicker, FaIconComponent, RouterLink, CommonModule, RouterModule, MatFormFieldModule, NgSelectModule, FontAwesomeModule]
})
export class DeliveryNoteSearchFormComponent implements OnInit {
  public productCategories: any[] = []
  public products: ArticleTemplate[] = []
  public statusList: any[] = [
    {id: 'PREPARED', description: "Vorbereitet"},
    {id: 'SHIPPED', description: "Versandt"},
    {id: 'DELIVERED', description: "Zugestellt"},
    {id: 'RECEIPT', description: "Quittiert"}
  ]
  public searchTerm: string = ''

  public deliveryNoteSearchForm = new FormGroup({
    number: new FormControl(),
    product_category: new FormControl(),
    status_id: new FormControl(),
    product_id: new FormControl(),
    sender_city: new FormControl(),
    rma: new FormControl(),
    serial_number: new FormControl(),
    recipient_city: new FormControl(),
    create_date_from: new FormControl(),
    create_date_to: new FormControl()
  })

  constructor(
    private router: Router,
    private articleService: ArticleService,
    public formatter: NgbDateParserFormatter
  ) { }

  ngOnInit(): void {
    this.articleService.loadArticleTemplates().subscribe({
      next: (data) => {
        this.products = data.sort((a, b) => (a.description.localeCompare(b.description)));
        this.productCategories = [
          ...new Set(
            this.products.map((p) => (p.product_category))
            .filter(c => c)
          )
        ].sort((a, b) => (a.localeCompare(b)));
      },
      error: (err) => {
        console.error(err)
      }
    });
  }

  calendar = inject(NgbCalendar);
  hoveredDateCreation: NgbDate | null = null;
  create_date_from: NgbDate | null //= this.calendar.getToday();
  create_date_to: NgbDate | null //= this.calendar.getNext(this.calendar.getToday(), 'd', 7);

  onDateSelectionCreation(date: NgbDate) {
    if (!this.create_date_from && !this.create_date_to) {
      this.create_date_from = date;
    } else if (this.create_date_from && !this.create_date_to && date && (date.after(this.create_date_from) || date.equals(this.create_date_from))) {
      this.create_date_to = date;
    } else {
      this.create_date_to = null;
      this.create_date_from = date;
    }
  }

  isHoveredCreation(date: NgbDate) {
    return (
      this.create_date_from && !this.create_date_to && this.hoveredDateCreation && date.after(this.create_date_from) && date.before(this.hoveredDateCreation)
    );
  }

  isInsideCreation(date: NgbDate) {
    return this.create_date_to && date.after(this.create_date_from) && date.before(this.create_date_to);
  }

  isRangeCreation(date: NgbDate) {
    return (
      date.equals(this.create_date_from) ||
      (this.create_date_to && date.equals(this.create_date_to)) ||
      this.isInsideCreation(date) ||
      this.isHoveredCreation(date)
    );
  }

  validateInput(currentValue: NgbDate | null, input: string): NgbDate | null {
    const parsed = this.formatter.parse(input);
    return parsed && this.calendar.isValid(NgbDate.from(parsed)) ? NgbDate.from(parsed) : currentValue;
  }

  public setRange(target, range) {
    const today = this.calendar.getToday()
    const weekday = this.calendar.getWeekday(today)

    const startOfWeekDiff = weekday - 1
    const endOfWeekDiff = 7 - weekday

    if (target == 'created') {

      if (range == 'today') {
        this.create_date_from = today
        this.create_date_to = today
      }

      if (range == 'thisweek') {
        this.create_date_from = this.calendar.getPrev(today, 'd', startOfWeekDiff)
        this.create_date_to = this.calendar.getNext(today, 'd', endOfWeekDiff)
      }

      if (range == 'nextweek') {
        this.create_date_from = this.calendar.getNext(today, 'd', endOfWeekDiff + 1)
        this.create_date_to = this.calendar.getNext(today, 'd', endOfWeekDiff + 7)
      }

    }
    return false
  }

  public deleteFromDate(target): boolean {
    if (target == 'created') {
      this.create_date_from = null
    }

    return false
  }

  public search() {
    const formdata = this.deliveryNoteSearchForm.value

    let params = {
      number: formdata.number,
      product_category: formdata.product_category,
      status_id: formdata.status_id,
      product_id: formdata.product_id,
      sender_city: formdata.sender_city,
      rma: formdata.rma,
      serial_number: formdata.serial_number,
      recipient_city: formdata.recipient_city,
      create_date_from: this.formatter.format(this.create_date_from),
      create_date_to: this.formatter.format(this.create_date_to),
    }

    this.router.navigate(['lieferscheine', 'suche'], { queryParams: params });
  }
}