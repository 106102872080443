import { Component } from "@angular/core";
import { ICellRendererAngularComp } from "ag-grid-angular";
import { ICellRendererParams } from "ag-grid-enterprise";

@Component({
    selector: 'ag-grid-delivery-notes-link-renderer',
    template: `
    @for(dn of params?.data?.delivery_note_numbers; track dn) {
      <a href="/a/lieferscheine/{{cutDeliveryNoteNumber(dn)}}" 
        target="_blank" 
        style="color: #337ab7; text-decoration: none; font-weight: normal;">
        {{dn}}
      </a>
    }
              `,
})
export class AgGridDeliveryNotesLinkRenderer implements ICellRendererAngularComp {
  params!: ICellRendererParams;

  agInit(params: ICellRendererParams) {
    this.params = params;
  }

  refresh(params: ICellRendererParams) {
    this.params = params;
    // As we have updated the params we return true to let AG Grid know we have handled the refresh.
    // So AG Grid will not recreate the cell renderer from scratch.
    return true;
  }

  cutDeliveryNoteNumber(dn: string): string {
    // split string at '-' and return last element
    return dn.split('-').pop() || dn;
  }
}