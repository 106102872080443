import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'

import { environment } from "../../../../environments/environment";
import { PASettingsConfig } from "../singletons/pa-settings-control";

@Injectable({
  providedIn: 'root'
})
export class PlanningAssistantSettingsService {

  constructor(private http: HttpClient) {
  }

  public index() {
    const path = environment.apiURL + 'planning_assistant_settings'

    return this.http.get<PASettingsConfig[]>(path)
  }

  public create(data: { setting: PASettingsConfig }) {
    const path = environment.apiURL + 'planning_assistant_settings'

    return this.http.post<PASettingsConfig>(path, data)
  }

  public update(id: number, data: { setting: PASettingsConfig }) {
    const path = environment.apiURL + 'planning_assistant_settings/' + id

    return this.http.patch<PASettingsConfig>(path, data)
  }

  public delete(id: number){
    const path = environment.apiURL + 'planning_assistant_settings/' + id

    return this.http.delete<PASettingsConfig>(path)
  }

}
