import { RouterModule } from "@angular/router";

import { Component, OnInit } from '@angular/core'
import { NgbDatepickerModule } from "@ng-bootstrap/ng-bootstrap";

@Component({
  imports: [RouterModule, NgbDatepickerModule],
    selector: 'hawk-warehouse-index',
    templateUrl: './warehouse-index.component.html',
    styleUrls: ['./warehouse-index.component.scss'],
})
export class WarehouseIndexComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}