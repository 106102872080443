<!DOCTYPE html>
<div *ngIf="showLoadingScreen" id="loading_screen">
  <div style="margin: auto; display: inline-flex; flex-direction: column; height: 600px;">
    <div style="margin: auto; display: inline-flex; flex-direction: row; width: 1100px;">
      <div style="display: inline-flex; flex: 1 1 auto; margin: auto;">
        <div class="loading_option_container black_gradient_background" (click)="loadData('fast')" [ngClass]="loadDataConfig == 'fast' ? 'selected' : ''">
          <div class="loading_option">
            <div class="loading_option_header">
              <h4 style="margin: auto;">Schnelle Planung</h4>
            </div>
            <hr style="border-color: white; width: 100%; margin: 0;">
            <div class="loading_option_footer">
              <div style="margin:auto;">
                Es werden keine offenen Aufträge und Tickets geladen.
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style="display: inline-flex; flex: 1 1 auto; margin: auto;">
        <div class="loading_option_container black_gradient_background" (click)="loadDataConfig = 'projects'" [ngClass]="loadDataConfig == 'projects' ? 'selected' : ''">
          <div class="loading_option">
            <div class="loading_option_header">
              <h4 style="margin: auto;">Projektauswahl</h4>
            </div>
            <hr style="border-color: white; width: 100%; margin: 0;">
            <div class="loading_option_footer">
              <div style="margin:auto;">
                Es werden projektspezifische Aufträge und Tickets geladen.
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style="display: inline-flex; flex: 1 1 auto; margin: auto;">
        <div class="loading_option_container black_gradient_background" (click)="loadData('all_except_internal_project')" [ngClass]="loadDataConfig == 'all' ? 'selected' : ''">
          <div class="loading_option">
            <div class="loading_option_header">
              <h4 style="margin: auto;">Alles laden</h4>
            </div>
            <hr style="border-color: white; width: 100%; margin: 0;">
            <div class="loading_option_footer">
              <div style="margin:auto;">
                Alle offenen Aufträge und Tickets werden geladen.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div style="margin: auto; display: inline-flex; flex-direction: row; width: 1100px;">
      <div id="selected_projects_container" [ngClass]="loadDataConfig == 'projects' ? 'shown' : ''">
        <div style="width: 100%; border: 1px solid black; background-color: #444444; display: inline-flex; flex-direction: row; flex-wrap: nowrap; padding: 2px 4px;">
          <div style="margin: auto;">
            <input style="float: left;" type="text" placeholder="Projekt suchen..." [(ngModel)]="projectNameFilter">
          </div>
          <div style="flex: 1; margin-top: auto; margin-bottom: auto;">
            <div style="float: right;">
              <mat-button-toggle-group [(ngModel)]="serviceProjectsToggleGroup">
                <mat-button-toggle value="service" (change)="selectAllServiceProjects()">Service</mat-button-toggle>
                <mat-button-toggle value="non_service" (change)="selectAllNonServiceProjects()">Nicht-Service</mat-button-toggle>
              </mat-button-toggle-group>
            </div>
          </div>
          <div style="height: 100%; display: inline-flex; margin: auto;">
            <div style="margin: auto; color: white; padding: 2px 4px;">
              Projekte ausgewählt: {{selectedProjectsToLoad.length}}
            </div>
          </div>
        </div>
        <div style="height: 100%; max-width: 100%; max-height: 300px; display: flex; flex-direction: row; flex-wrap: wrap; overflow-y: auto; background-color: #444444;">
          <div *ngFor="let project of PADataControl.Instance.loadedProjectFilteredByName(projectNameFilter)"
          (click)="toggleProjectToLoad(project)" 
          class="black_gradient_background project" 
          [ngClass]="selectedProjectsToLoad.includes(project) ? 'selected' : ''">
            {{project.project_name}}
          </div>
          <div *ngIf="!PADataControl.Instance.loadedProjectFilteredByName(projectNameFilter).length" style="padding: 2px 4px; margin: auto; color: white;">
            Keine Projekte gefunden
          </div>
        </div>
      </div>
      <div id="start_button" *ngIf="projectLoadingConfigSelected() && projectsLoaded" class="black_gradient_background" (click)="loadData('projects')" style="margin: auto; border: 1px solid black">
        <h4 style="margin-bottom: 0; padding: 8px 32px; border-radius: 8px;">
          weiter
        </h4>
      </div>
    </div>
  </div>
</div>

<div id="planning_assistant_container" [ngClass]="screenfull.isFullscreen ? 'fullscreen' : ''">

  <div id="main_window" [ngClass]="screenfull.isFullscreen ? 'fullscreen' : ''">
    <hawk-popup-modal [shown]="PASettingsControl.Instance.showSettings" (closeEvent)="PASettingsControl.Instance.showSettings = false">
      <hawk-pa-settings
      (updateWeekdaysEvent)="updateWeekdaysInCalendar($event)">
      </hawk-pa-settings>
    </hawk-popup-modal>
    <div style="flex: 1; position: relative; display: inline-flex; flex-direction: row-reverse; overflow: visible;">
      <pa-popout-window #popoutWindow1 style="overflow: visible; display: inline-flex; flex-direction: column;" >
        <div class="tour_planner_container transparent_black_gradient_background" [ngClass]="popoutWindow1.isPoppedOut ? 'external_window' : ''">
          <div class="planning_tabs black_gradient_background_no_hover" [ngClass]="popoutWindow1?.isPoppedOut ? 'dark_background' : ''" style="overflow: visible;">
            <div class="tab-header">
              <div [ngClass]="selectedPlanningTab == 'manual' ? 'active' : ''" (click)="selectedPlanningTab = 'manual'">
                <i class="fa fa-pen"></i>
                Manuell
              </div>
              <div [ngClass]="selectedPlanningTab == 'automatic' ? 'active' : ''" (click)="selectedPlanningTab = 'automatic'">
                <i class="fa fa-network-wired"></i>
                Optiwae
              </div>
              <div [ngClass]="selectedPlanningTab == 'changes' ? 'active' : ''" (click)="selectedPlanningTab = 'changes'">
                <i class="fa fa-code-merge"></i>
                Änderungen {{PADataControl.Instance.operationChanges.length ? '(' + PADataControl.Instance.operationChanges.length + ')' : ''}}
              </div>
            </div> 
            <div [ngClass]="selectedPlanningTab == 'manual' ? 'shift_0' : (selectedPlanningTab == 'automatic' ? 'shift_1' : 'shift_2')" class="tab-indicator"></div>
            <div class="tab-body" style="overflow: visible;">
              <div [ngClass]="selectedPlanningTab == 'manual' ? 'active ' : ''" style="overflow: visible;">
                <hawk-tourplanner-menu
                style="overflow: visible;"
                [planningMode]="PATourPlannerControl.Instance.tourPlannerPlanningMode"
                [planningInstructions]="PATourPlannerControl.Instance.planningInstructions"
                [operations]="PAFilterControl.Instance.filteredUnplannedOperations"
                [technicians]="PAFilterControl.Instance.globalFilteredTechnicians"
                [shownCalendarWeeksData]="PATimeControl.Instance.shownCalendarWeeksData"
                [selectedCalendarWeekData]="PATimeControl.Instance.selectedCalendarWeekData"
                [selectedCalendarDay]="PATimeControl.Instance.selectedCalendarDay"
                [selectedOperationID]="PATourPlannerControl.Instance.operationToPlanID"
                [selectedTechnicianID]="PATourPlannerControl.Instance.technicianToPlanID"
                [filteredWeekdays]="PAFilterControl.Instance.filteredWeekDays"
                [operationTimeFilter]="PAFilterControl.Instance.selectedOperationTimeFilter"
                [showTechnicianDatesForTechnicians]="PAFilterControl.Instance.preferredCalendarTechnicians"
                [showOverviewForTechnicianDateCollections]="PATourPlannerControl.Instance.tourPlannerOverviewTechnicianDateCollections"
                [extraOperationCollection]="PATourPlannerControl.Instance.tourPlannerExtraOperationCollection"
                [externalWindow]="popoutWindow1"
                [projectMode]="PASettingsControl.Instance.projectMode"
                [filteredProjects]="PAProject.selectedProjects"
                (updatePreferredTechniciansEvent)="updatePreferredTechnicians($event)">
                </hawk-tourplanner-menu>
              </div>
              <div [ngClass]="selectedPlanningTab == 'automatic' ? 'active' : ''">
                <hawk-optiwae-connector style="user-select: none;"
                [technicians]="PADataControl.Instance.loadedTechnicians"></hawk-optiwae-connector>
              </div>
              <div [ngClass]="selectedPlanningTab == 'changes' ? 'active ' : ''">
                <hawk-save-menu [ngClass]="popoutWindow1?.isPoppedOut ? 'horizontal_flex' : 'vertical_flex'"
                                [externalWindow]="popoutWindow1"
                                [operationChanges]="PADataControl.Instance.operationChanges"
                                [lastChangeTimeStamp]="PATourPlannerControl.Instance.lastOperationChangeTimeStamp">
                </hawk-save-menu>
              </div>
            </div>
          </div>
        </div>
      </pa-popout-window>
      <div style="position: relative; flex: 1; display: inline-flex; flex-direction: column; flex-wrap: nowrap; overflow: hidden;">
        <div style="display: inline-flex; flex-direction: column; flex: 1;">
          <div style="display: inline-flex; flex-direction: row;">
            <div id="store_operation_view_container"  *ngIf="Store.selectedStoreInMap && Store.selectedStoreInMap.selectedStoreOperation">
              <!-- <div class="store_overview_section" [tooltip]="'zeige erledigte Aufträge'">
                <div style="margin: auto;">
                  <fa-icon 
                  (click)="toggleSelectedStoreHideDoneOperation()" 
                  class="clickable_symbol" style="font-size: 1rem; line-height: 0px;" 
                  [ngClass]="!Store.selectedStoreInMap.hideDoneOperations? 'selected' : ''"
                  [icon]="faCheck"></fa-icon>
                </div>
              </div> -->
              <div style="text-align: center; position: relative; display: inline-flex; font-size: .8rem;">
                <div style="display: inline-flex; flex-direction: row; flex-wrap: nowrap;">
                  <hawk-expand-bar-header
                          [description]="Store.selectedStoreInMap?.operationTree?.header || 'Keine Aufträge'"
                          [isExpanded]="showStoreOperations"
                          [fontSize]="'.8rem'"
                          [height]="'100%'"
                          [fontColor]="'#18b9f8'"
                          (toggle)="showStoreOperations = $event">
                  </hawk-expand-bar-header>
                  <div class="store_overview_section black_gradient_background_no_hover" style="flex: 1;" [tooltip]="Store.selectedStoreInMap.address">
                    <div style="margin: auto; white-space: nowrap;">
                      {{Store.selectedStoreInMap.address_company}}
                    </div>
                  </div>
                </div>
                  
                <div class="expand black_gradient_background_no_hover" style="position: absolute; z-index: 50; left: 0; top: 100%; overflow-x: hidden; min-width: 420px;" [ngClass]="showStoreOperations ? '' : 'hidden'">
                  
                  <hawk-icon-expand-bar *ngIf="Store.selectedStoreInMap?.operationTree?.unplanned?.length"
                  [description]="'Ungeplant'"
                  [isExpanded]="Store.selectedStoreInMap?.expandUnplannedProjects"
                  [fontSize]="'.8rem'"
                  [height]="'100%'"
                  [leftAligned]="true"
                  [fontColor]="'#ffffff'"
                  (toggle)="Store.selectedStoreInMap.expandUnplannedProjects = $event">
                    <div *ngFor="let project_subtree of Store.selectedStoreInMap.operationTree.unplanned" content>
                      <hawk-icon-expand-bar
                      [description]="project_subtree.project.project_name + ' (' + project_subtree.project_operations?.length + ')'"
                      [isExpanded]="Store.selectedStoreInMap?.expandStoreProjects.includes(project_subtree.project)"
                      (toggle)="Store.selectedStoreInMap.toggleExpandProject(project_subtree.project, $event)">
                        <hawk-store-operations content
                        [operations]="project_subtree.project_operations"
                        [selectedOperation]="Store.selectedStoreInMap.selectedStoreOperation"
                        (selectStoreOperationEvent)="PAStoreControl.Instance.selectStoreOperation($event)">
                        </hawk-store-operations>
                        <div icon style="margin: auto; display: inline-flex;">
                          <div [style.background-color]="project_subtree.project.color" class="project_color_circle" style="color: black; display: inline-flex; line-height: 0;">
                            <div style="margin: auto; line-height: 0;"></div>
                          </div>
                        </div>
                      </hawk-icon-expand-bar>
                    </div>
                    <div icon style="margin: auto; display: inline-flex;">
                      <div [style.background-color]="'white'" class="project_color_circle" style="color: black; display: inline-flex; line-height: 0;">
                        <div style="margin: auto; line-height: 0;">N</div>
                      </div>
                    </div>
                  </hawk-icon-expand-bar>
                  <hawk-icon-expand-bar *ngIf="Store.selectedStoreInMap?.operationTree?.planned?.length"
                  [description]="'Geplant'"
                  [isExpanded]="Store.selectedStoreInMap?.expandPlannedProjects"
                  [fontSize]="'.8rem'"
                  [height]="'100%'"
                  [leftAligned]="true"
                  [fontColor]="'#ffffff'"
                  (toggle)="Store.selectedStoreInMap.expandPlannedProjects = $event">
                    <div *ngFor="let project_subtree of Store.selectedStoreInMap.operationTree.planned" content>
                      <hawk-icon-expand-bar
                      [description]="project_subtree.project.project_name + ' (' + project_subtree.project_operations.length + ')'"
                      [isExpanded]="Store.selectedStoreInMap?.expandStoreProjects.includes(project_subtree.project)"
                      (toggle)="Store.selectedStoreInMap.toggleExpandProject(project_subtree.project, $event)">
                        <hawk-store-operations content
                        [operations]="project_subtree.project_operations"
                        [selectedOperation]="Store.selectedStoreInMap.selectedStoreOperation"
                        (selectStoreOperationEvent)="PAStoreControl.Instance.selectStoreOperation($event)">
                        </hawk-store-operations>
                        <div icon style="margin: auto; display: inline-flex;">
                          <div [style.background-color]="project_subtree.project.color" class="project_color_circle" style="color: black; display: inline-flex; line-height: 0;">
                            <div style="margin: auto; line-height: 0;"></div>
                          </div>
                        </div>
                      </hawk-icon-expand-bar>
                    </div>
                    <div icon style="margin: auto; display: inline-flex;">
                      <fa-icon [icon]="faCalendar"></fa-icon>
                    </div>
                  </hawk-icon-expand-bar>
                  <hawk-icon-expand-bar *ngIf="Store.selectedStoreInMap?.operationTree?.done?.length"
                  [description]="'Erledigt'"
                  [isExpanded]="Store.selectedStoreInMap?.expandDoneProjects"
                  [fontSize]="'.8rem'"
                  [height]="'100%'"
                  [leftAligned]="true"
                  [fontColor]="'#ffffff'"
                  (toggle)="Store.selectedStoreInMap.expandDoneProjects = $event">
                    <div *ngFor="let project_subtree of Store.selectedStoreInMap.operationTree.done" content>
                      <hawk-icon-expand-bar
                      [description]="project_subtree.project.project_name + ' (' + project_subtree.project_operations.length + ')'"
                      [isExpanded]="Store.selectedStoreInMap?.expandStoreProjects.includes(project_subtree.project)"
                      (toggle)="Store.selectedStoreInMap.toggleExpandProject(project_subtree.project, $event)">
                        <hawk-store-operations content
                        [operations]="project_subtree.project_operations"
                        [selectedOperation]="Store.selectedStoreInMap.selectedStoreOperation"
                        (selectStoreOperationEvent)="PAStoreControl.Instance.selectStoreOperation($event)">
                        </hawk-store-operations>
                        <div icon style="margin: auto;display: inline-flex;">
                          <div [style.background-color]="project_subtree.project.color" class="project_color_circle" style="color: black; display: inline-flex; line-height: 0;">
                            <div style="margin: auto; line-height: 0;"></div>
                          </div>
                        </div>
                      </hawk-icon-expand-bar>
                    </div>
                    <div icon style="display: inline-flex; margin: auto;">
                      <div>
                        &#10004;	
                      </div>
                    </div>
                  </hawk-icon-expand-bar>
                </div>
              </div>
              <div style="display: inline-flex; flex-direction: row; flex: 1 1 auto;">
                <div *ngIf="Store.selectedStoreInMap?.selectedStoreOperation" class="store_overview_section black_gradient_background_no_hover" style="flex: 1 1 auto; flex-direction: row;">
                  <div style="display: inline-flex; flex-direction: row; padding: 2px 4px; flex-wrap: wrap; white-space: nowrap; font-size: .8rem; height: 100%;">
                    <div style="padding: 2px; margin: auto;">
                      T:
                      <ng-container *ngIf="Store.selectedStoreInMap.selectedStoreOperation.ticket.id >= 0">
                        <a href="/a/ticket/{{Store.selectedStoreInMap.selectedStoreOperation.ticket.id}}?operation_id={{Store.selectedStoreInMap.selectedStoreOperation.id}}" target="_blank">{{Store.selectedStoreInMap.selectedStoreOperation.ticket.id}}</a>
                      </ng-container>
                      <ng-container *ngIf="Store.selectedStoreInMap.selectedStoreOperation.ticket.id < 0">
                        NEU
                      </ng-container>
                    </div>
                    <div style="margin: auto; white-space: nowrap; padding: 2px;">
                      {{Store.selectedStoreInMap.selectedStoreOperation.ticket.project.project_name}}
                    </div>
                    <div style="margin: auto; white-space: nowrap; padding: 2px;" [style.color]="Store.selectedStoreInMap.selectedStoreOperation.ticket.priority.color">
                      {{Store.selectedStoreInMap.selectedStoreOperation.ticket.priority.name}}
                    </div>
                    <div style="margin: auto; white-space: nowrap; padding: 2px;" [style.color]="Store.selectedStoreInMap.selectedStoreOperation.ticket.status.color">
                      ({{Store.selectedStoreInMap.selectedStoreOperation.ticket.status.name}})
                    </div>
                    <div *ngIf="Store.selectedStoreInMap.selectedStoreOperation.slaDateInformation() || Store.selectedStoreInMap.selectedStoreOperation.ticket.memos?.length" style="display: inline-flex; flex-direction: row; padding: 2px;">
                      <div *ngIf="Store.selectedStoreInMap.selectedStoreOperation.slaDateInformation()" class="info_marker"  [tooltip]="Store.selectedStoreInMap.selectedStoreOperation.slaDateInformation()">
                        <b>SLA!</b>
                      </div>
                      <div *ngIf="Store.selectedStoreInMap.selectedStoreOperation.ticket.memos?.length" class="info_marker" [tooltip]="Store.selectedStoreInMap.selectedStoreOperation.ticket.memos.join( ' | ')">
                        <b>Memos!</b>
                      </div>
                    </div>
                  </div>
                  <div style="padding: 2px; border-left: 1px solid black; flex: 1; white-space: normal; font-size: .8rem; display: inline-flex;" [tooltip]="Store.selectedStoreInMap.selectedStoreOperation.description.length > 100 ? Store.selectedStoreInMap.selectedStoreOperation.description : null">
                    <div style="margin: auto">
                      {{Store.selectedStoreInMap.selectedStoreOperation.description.length > 100 ? (Store.selectedStoreInMap.selectedStoreOperation.description.slice(0, 100) + '...') : Store.selectedStoreInMap.selectedStoreOperation.description}}
                    </div>
                  </div>
                </div>
              </div>
        
              <div class="store_overview_section black_gradient_background_no_hover" style="position: relative; width: 25px;">
                <button (click)="PAStoreControl.Instance.closeStoreMenu()" class="cancel_button" style="text-align: center; z-index: 10;">
                  <fa-icon [icon]="faWindowClose" style="color: white;"></fa-icon>
                </button>
              </div>
            </div>
            <div *ngIf="popoutWindow1?.isPoppedOut" class="store_overview_section" style="width: 304px; background-color: #444444;"></div>
          </div>
          <div style="display: inline-flex; flex-direction: row; flex: 1;">

            <div style="position: relative; flex: 1; border: 1px solid black;">
              <hawk-map
              [technicians]="PATourPlannerControl.Instance.shownMapTechnicians"
              [operations]="PATourPlannerControl.Instance.shownMapOperations"
              [tourPlannerExtraOperations]="PATourPlannerControl.Instance.tourPlannerExtraOperationCollection"
              [selectedStore]="Store.selectedStoreInMap"
              [selectedTourPlannerTours]="PATourPlannerControl.Instance.selectedTourPlannerTours"
              [operationTimeFilter]="PAFilterControl.Instance.selectedOperationTimeFilter"
              [drivingIsoDistance]="PAMapControl.Instance.isochroneDistance"
              [shownRoutes]="PATourPlannerControl.Instance.shownRoutes"
              [selectedIsoCoordinates]="PAMapControl.Instance.selectedIsoCoordinates"
              [mapBoxAccessToken]="mapBoxLoadingService.accessToken"
              [externalWindow]="popoutWindow1"
              [showTravelMarkerCoordinates]="!PASettingsControl.Instance.selectedSettingConfig.data.general.map.seperated_tour_view"
              [technicianDistanceType]="PATourPlannerControl.Instance.technicianDistanceType">
              </hawk-map>
            </div>
            <div class="side_menu" [ngClass]="PATourPlannerControl.Instance.tourPlannerOverviewTechnicianDateCollections.length && PASettingsControl.Instance.selectedSettingConfig.data.general.map.seperated_tour_view ? 'visible' : ''">
              <hawk-tour-comparison style="display: inline-flex; flex: 1; flex-direction: column; user-select: none;"
              [mapBoxAccessToken]="mapBoxLoadingService.accessToken"
              [planningInstructions]="PATourPlannerControl.Instance.planningInstructions"
              [operations]="PAFilterControl.Instance.filteredUnplannedOperations"
              [filteredWeekdays]="PAFilterControl.Instance.filteredWeekDays"
              [technicianDateCollections]="PATourPlannerControl.Instance.tourPlannerOverviewTechnicianDateCollections">
              </hawk-tour-comparison>
            </div>
          </div>
        </div>
        

        <div style="display: inline-flex; flex-direction: row; overflow: visible;">
          <hawk-filter #filterComponent style="flex: 1; display: inline-flex;" id="filter_component"
                       [technicians]="PADataControl.Instance.loadedTechnicians"
                       [operations]="PADataControl.Instance.loadedOperations">
          </hawk-filter>
          <div id="progress_section_container" [ngClass]="popoutWindow1?.isPoppedOut ? 'popped_out_mode' : ''">
            <hawk-loading-container style="display: inline-flex; flex: 1;"></hawk-loading-container>
          </div>
        </div>
      </div>

      <button *ngIf="!(popoutWindow1?.isPoppedOut)"  [tooltip]="'Planungsmenü in neuem Fenster öffnen'" class="popout_button black_gradient_background"  (click)="popOut(popoutWindow1)">
        <fa-icon [icon]="faExternalLinkAlt"></fa-icon>
      </button>

      <button [ngClass]="popoutWindow1.isPoppedOut ? 'popped_out_mode' : ''" [tooltip]="'Einstellungen'" class="settings_button black_gradient_background" (click)="openSettings()">
        <fa-icon [icon]="faCog"></fa-icon>
      </button>
      
      <button [tooltip]="'Vollbild'" class="fullscreen_button black_gradient_background" [ngClass]="popoutWindow1.isPoppedOut ? 'popped_out_mode' : ''" (click)="toggleFullscreen()">
        <fa-icon *ngIf="screenfull.isFullscreen" [icon]="faCompress"></fa-icon>
        <fa-icon *ngIf="!screenfull.isFullscreen" [icon]="faExpand"></fa-icon>
      </button>

      <div id="geocoder" class="geocoder" [ngClass]="popoutWindow1.isPoppedOut ? 'popped_out_mode' : ''"></div>
    </div>
    
    <div id="calendar_container">
      <hawk-calendar
        style="overflow: visible;"
        [technicians]="PAFilterControl.Instance.globalFilteredTechnicians"
        [operationTimeFilter]="PAFilterControl.Instance.selectedOperationTimeFilter"
        [filteredWeekdays]="PAFilterControl.Instance.filteredWeekDays"
        [shownCalendarWeeksData]="PATimeControl.Instance.shownCalendarWeeksData"
        [selectedCalendarWeekData]="PATimeControl.Instance.selectedCalendarWeekData"
        [selectedCalendarDay]="PATimeControl.Instance.selectedCalendarDay"
        [preferredTechnicians]="PAFilterControl.Instance.preferredCalendarTechnicians"
        (addPreferredTechnicianEvent)="addPreferredTechnician($event)"
        (removePreferredTechnicianEvent)="removePreferredTechnician($event)">
      </hawk-calendar>
    </div>
    
  </div>
  
</div>
  

<div style="position: absolute; top: 0; left: 0; z-index: 200; width: 100%; height: 100%; background-color: rgba(94, 0, 0, 0.849);" *ngIf="PADataControl.Instance.operationsWithoutLngLat.length || PADataControl.Instance.techniciansWithoutLngLat.length ">
  <hawk-insufficient-data-popup
  [operationsWithoutLngLat]="PADataControl.Instance.operationsWithoutLngLat"
  [techniciansWithoutLngLat]="PADataControl.Instance.techniciansWithoutLngLat"
  [mapboxAccessToken]="mapBoxLoadingService.accessToken"
  ></hawk-insufficient-data-popup>
</div>

