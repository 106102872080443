import {
  Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, QueryList, SimpleChanges, ViewChild,
  ViewChildren
} from '@angular/core';
import { PAOperation } from "../../../classes/operation";
import {
  faArrowRight,
  faCar,
  faCheck, faCodeBranch,
  faCog, faExclamationTriangle, faLock, faLockOpen, faTrash,
  faUtensils,
  faWindowClose,
  faWrench
} from "@fortawesome/free-solid-svg-icons";
import { PAUtil } from "../../../classes/util";
import { PAProject } from "../../../classes/project";
import { PATour } from "../../../classes/tour";
import { PopupMenuComponent } from "../../../reuseable-components/popup-menu-component/popup-menu.component";
import { PAStore } from "../../../classes/store";
import { PopoutWindowComponent } from "../../../reuseable-components/popout-window/popout-window.component";
import { PADataControl } from "../../../singletons/pa-data-control";
import { PAStoreControl } from "../../../singletons/pa-store-control";
import { PATimeControl } from "../../../singletons/pa-time-control";
import { PAFilterControl } from "../../../singletons/pa-filter-control";
import { PASettingsControl } from "../../../singletons/pa-settings-control";
import { MapBoxService } from "../../../../../_services/mapbox.service";
import { MapContainer } from "../../../map/pa-map";
import { PATechnicianDate } from "../../../classes/technician-date";
import { NgClass, NgIf, NgFor } from '@angular/common';
import { TravelTechnicianMenuComponent } from '../../../reuseable-components/travel-technician-menu/travel-technician-menu.component';
import { TooltipModule } from '@cloudfactorydk/ng2-tooltip-directive';

import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { CommonModule } from "@angular/common";
import { FaIconComponent, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ThrobberComponent } from "../../../../_shared/throbber/throbber.component";

type WarningType = 'Holiday' | 'Absence'

@Component({
  selector: 'hawk-timeline',
  templateUrl: './timeline.component.html',
  styleUrls: ['./timeline.component.scss', './../../../styles/common_styles.scss'],
  standalone: true,
  imports: [NgClass, NgIf, TravelTechnicianMenuComponent, TooltipModule, NgFor, FaIconComponent, PopupMenuComponent, ReactiveFormsModule, MatButton, FormsModule, ThrobberComponent, CommonModule, FontAwesomeModule]
})
export class TimelineComponent implements OnInit, OnChanges {

  @Input() tour?: PATour
  @Input() compareTour: PATour
  @Input() tourIsUpdating: boolean
  @Input() selected: boolean = false
  @Input() showHours: boolean = false
  @Input() showLabel: boolean = false
  @Input() showStatistics: boolean = false
  @Input() showWarnings: boolean = false
  @Input() showTimeMarker: boolean = false
  @Input() editable: boolean = false
  @Input() timeFilter: string = ''
  @Input() externalWindow: PopoutWindowComponent
  @Input() chainOperations: boolean
  @Input() height: number = 20
  @Input() showOperationIndexes = true
  @Input() showEverySecondHour = false
  @Input() unfittingOperations: PAOperation[] = []
  @Input() maxTourTime: number
  @Input() addBottomPaddingPxAmount = 0
  @Input() mapContainer: MapContainer

  @Output() selectTourEvent: EventEmitter<PATour> = new EventEmitter<PATour>()
  @Output() removeOperationsEvent: EventEmitter<PAOperation[]> = new EventEmitter<PAOperation[]>()
  @Output() updateNewTourEvent: EventEmitter<void> = new EventEmitter<void>()

  @ViewChildren('OperationPopups') popupMenus: QueryList<PopupMenuComponent>;
  @ViewChild('LunchBreakPopup') lunchBreakPopup: PopupMenuComponent;
  @ViewChild('WarningsPopup') warningsPopup: PopupMenuComponent;
  @ViewChild('timeline') timeline: ElementRef<HTMLDivElement>;

  protected readonly faCar = faCar;
  protected readonly faCheck = faCheck;
  protected readonly Operation = PAOperation;
  protected readonly faWrench = faWrench;
  protected readonly faArrowRight = faArrowRight;
  protected readonly faWindowClose = faWindowClose;
  protected readonly faUtensils = faUtensils;
  protected readonly faCog = faCog;
  protected readonly Math = Math;
  protected readonly Util = PAUtil;
  protected readonly Project = PAProject;

  timelineTimestamps: { time_string: string, offset_percent: string }[]
  outsideOpeningZones: { left_percent: number, right_percent: number, info: string, operation: PAOperation }[]

  dragAndDropOperations: PAOperation[] = []
  dragAndDropOffsetPercent: number = 0
  selectedOperation: PAOperation

  minuteMarkerPercentage = null

  warning: {type: WarningType, info: string} = null
  showCompareTour: boolean;

  constructor(
    public mapBoxLoadingService: MapBoxService
  ) {
  }

  ngOnInit(): void {
    this.updateTimelineTimestamps()
    setInterval(() => {
      this.updateMinuteMarker()
    }, 500);
    this.updateOutsideOpeningZones()
    this.generateWarning()
  }

  ngOnChanges(changes: SimpleChanges): void {
    let tour_changes = changes['tour']
    let updating_changes = changes['tourIsUpdating']
    if ((tour_changes && tour_changes.currentValue) || (updating_changes && !updating_changes.currentValue)) {
      this.updateOutsideOpeningZones()
    }

    let compare_tour_changes = changes['compareTour']
    if (compare_tour_changes && !compare_tour_changes.currentValue) {
      this.showCompareTour = false
    }
  }

  generateWarning(): void {
    if (this.tour) {
      const holidays = this.tour.technicianDate.getPublicHolidays()
      const absences = this.tour.technicianDate.absences
      if (holidays.length) {
        this.warning = {
          type: 'Holiday',
          info: 'Feiertag: ' + holidays[0].name
        }
      } else if (absences.length) {
        this.warning = {
          type: "Absence",
          info: 'Abwesenheit: ' + absences[0].type
        }
      }
    }
  }

  openOperationMenu(idx: number): void {
    let popup_components = this.popupMenus.toArray()
    for (let popup_component of popup_components) {
      if (popup_components.indexOf(popup_component) == idx) {
        popup_component.open()
      } else {
        popup_component.close()
      }
    }
  }

  openLunchBreakMenu(): void {
    this.lunchBreakPopup.open()
  }

  openWarningsMenu(): void {
    if (this.tour) {
      let has_warning_operations = this.tour.technicianDate.changedTourContainer.deletingOperations.length + this.tour.technicianDate.changedTourContainer.unfittingOperations.length
      if (!this.tour.technicianDate.isPastTechnicianDate() && has_warning_operations) {
        this.warningsPopup.open()
      }
    }
  }

  closeWarningsMenu(): void {
    this.warningsPopup.close()
  }

  closeOperationMenu(idx: number): void {
    this.popupMenus.toArray()[idx].close();
  }

  updateTimelineTimestamps(): void {
    let res: { time_string: string, offset_percent: string }[] = []
    let start_int = Math.floor(PATour.timelineStart)
    let end_int = Math.ceil(PATour.timelineEnd)
    let total_hours = end_int - start_int
    let hourly_offset = 100 / total_hours
    let shown_hours = Array.from(Array(end_int - start_int + 1).keys()).map(x => x + start_int)
    for (let hour of shown_hours) {
      let hour_index = shown_hours.indexOf(hour)
      if (hour_index % 2 == 0 || !this.showEverySecondHour) {
        res.push({time_string: hour.toString(), offset_percent: `${hour_index * hourly_offset}%`})
      }
    }
    this.timelineTimestamps = res
  }

  calculateDragAndDropOffset(event: MouseEvent, clicked_operation: PAOperation): void {
    if (!clicked_operation.date_travel_start && !clicked_operation.date_on_site && !clicked_operation.date_finished && this.editable) {
      var start = 0, diff = 0;
      if (event.pageX) start = event.pageX;
      else if (event.clientX) start = event.clientX;

      if (!event.ctrlKey) {
        if (!this.dragAndDropOperations.includes(clicked_operation)) {
          this.dragAndDropOperations = [clicked_operation]
          this.selectedOperation = clicked_operation
          let base_operation = PADataControl.Instance.getOperation(clicked_operation.id)
          PAStoreControl.Instance.selectStore(base_operation.getStore(), {show_operation: base_operation})
        }
        if (this.dragAndDropOperations.length <= 1) {
          this.openOperationMenu(this.tour.operations.indexOf(clicked_operation))
        }
      } else {
        if (!this.dragAndDropOperations.includes(clicked_operation)) {
          this.dragAndDropOperations.push(clicked_operation)
          this.openOperationMenu(this.tour.operations.indexOf(clicked_operation))
        } else if (this.dragAndDropOperations.length > 1) {
          PAUtil.removeElementFromList(this.dragAndDropOperations, clicked_operation)
        }
      }

      if (!event.ctrlKey) {
        let timeline_elem = this.timeline.nativeElement
        let mousemove_elem = document
        if (timeline_elem) {
          let timeline_width = timeline_elem.offsetWidth
          mousemove_elem.onmousemove = (e) => {
            var end = 0;
            if (e.pageX) end = e.pageX;
            else if (e.clientX) end = e.clientX;

            diff = end - start;
            this.dragAndDropOffsetPercent = (diff / timeline_width) * 100
            this.updateOutsideOpeningZones()
          };
          mousemove_elem.onmouseup = () => {
            if (!this.dragAndDropOffsetPercent) {
              this.dragAndDropOperations = [clicked_operation]
              this.selectedOperation = clicked_operation
              let base_operation = PADataControl.Instance.getOperation(clicked_operation.id)
              PAStoreControl.Instance.selectStore(base_operation.getStore(), {show_operation: base_operation})
              this.openOperationMenu(this.tour.operations.indexOf(clicked_operation))
            }
            if (this.dragAndDropOffsetPercent) {
              this.applyDragAndDropOffsetToOperations(this.chainOperations ? [...this.tour.operations] : this.dragAndDropOperations)
            }
            mousemove_elem.onmousemove = mousemove_elem.onmouseup = null;
          };
        }
      }
    }
  }

  async applyDragAndDropOffsetToOperations(operations: PAOperation[]): Promise<void> {
    let start_int = Math.floor(PATour.timelineStart)
    let end_int = Math.ceil(PATour.timelineEnd)
    let total_milliseconds = (end_int - start_int) * 60 * 60 * 1000
    let offset_in_milliseconds = (this.dragAndDropOffsetPercent / 100) * total_milliseconds
    await this.applyMillisecondShiftToOperations(offset_in_milliseconds, operations)
  }

  async applyMillisecondShiftToOperations(ms: number, operations: PAOperation[]): Promise<void> {
    let new_tour_route_before = [...this.tour.operations || []]
    let tours_to_update: PATour[] = []
    for (let operation of operations) {
      await operation.setOffOperationByMilliseconds(ms)
      operation.dragDropOffsetFactor = 0
      let tour = operation.getTour()
      if (tour) {
        tours_to_update.push(tour)
      }
    }
    this.dragAndDropOffsetPercent = 0
    operations.map(operation => operation.dragDropOffsetFactor = 1)
    for (let tour of [...new Set(tours_to_update)]) {
      tour.updateTourMarkers()
      if (tour == this.tour && !tour.hasEqualRoute(new_tour_route_before)) {
        tour.fireUpdateManually('route_changed')
      }
    }

    this.updateOutsideOpeningZones()
    // TODO: check if Map updates
  }

  updateOutsideOpeningZones(): void {
    if (this.tour) {
      this.outsideOpeningZones = this.tour.getOutsideOpeningZonesTimelinePositions({
        for_user_with_id: this.tour.technicianDate.technician.id,
        offset_percent: this.dragAndDropOffsetPercent,
        offset_operations: this.chainOperations ? [...this.tour.operations] : this.dragAndDropOperations
      })
    }
  }

  updateMinuteMarker(): void {
    let new_marker_percentage = null
    if (this.tour?.technicianDate.isTodaysTechnicianDate() && this.dayMinutesAreInTimeLineRange()) {
      new_marker_percentage = this.getDayMinutesTimeLinePercent()
    }
    this.minuteMarkerPercentage = new_marker_percentage
  }

  dayMinutesAreInTimeLineRange(): boolean {
    let day_minutes_start = PATour.timelineStart * 60
    let day_minutes_end = PATour.timelineEnd * 60

    let local_day_timestamp = PATimeControl.Instance.dateToTimestamp(new Date(), true, false)
    let local_day_minutes = (Date.now() - local_day_timestamp) / (1000 * 60)
    return day_minutes_start <= local_day_minutes && local_day_minutes <= day_minutes_end
  }

  getDayMinutesTimeLinePercent(): number {
    let day_minutes_start = PATour.timelineStart * 60
    let day_minutes_end = PATour.timelineEnd * 60
    let total_minutes = day_minutes_end - day_minutes_start
    let local_day_timestamp = PATimeControl.Instance.dateToTimestamp(new Date(), true, false)
    let local_day_minutes = (Date.now() - local_day_timestamp) / (1000 * 60)

    return ((local_day_minutes - day_minutes_start) / total_minutes) * 100
  }

  async shiftSelectedOperationTimes(event: Event, reference_operation: PAOperation, reference_time_category: string): Promise<void> {
    const input = event.target as HTMLInputElement
    const time: string = input.value

    let new_tour = this.tour
    let operation_index = new_tour ? new_tour.route.operations.indexOf(reference_operation) : -1
    let time_strings = new_tour.route.time_specific_data[PAFilterControl.Instance.selectedOperationTimeFilter].operation_data[operation_index].operation_time_strings
    const minutes_before = PATimeControl.Instance.timeStringToMinutes(time_strings[reference_time_category])
    const minutes_now = PATimeControl.Instance.timeStringToMinutes(time)
    const delta_minutes = minutes_now - minutes_before

    this.applyMillisecondShiftToOperations(delta_minutes * 60 * 1000, this.chainOperations ? [...this.tour.operations] : this.dragAndDropOperations)
  }

  getNewToursOperationPriorityCountsBeforeOperation(operation: PAOperation) {
    let found_count = this.tour.operationPriorityCountsBeforeOperation.find(pcb => pcb.operation.id == operation.id)
    return found_count ? found_count.count : -1
  }

  lunchBreakOffsetPercent(): number {
    return (this.chainOperations ? this.tour.operations : this.dragAndDropOperations).find(
      op => this.tour.operations.findIndex(
        tour_op => tour_op.id == op.id
      ) == this.tour.lunchBreak?.after_operation_idx
    ) ? this.dragAndDropOffsetPercent : 0
  }

  toggleLunchBreakCalculation(technician_date: PATechnicianDate): void {
    if (technician_date.automaticLunchBreakCalculation) {
      technician_date.lunchBreakAfterOperationNumber = (technician_date.tour.lunchBreak?.after_operation_idx ?? 0) + 1
      technician_date.automaticLunchBreakCalculation = false
    } else {
      technician_date.lunchBreakAfterOperationNumber = null
      technician_date.automaticLunchBreakCalculation = true
    }
    this.updateNewTourEvent.emit()
  }

  removeOperation(remove_operation: PAOperation, menu_idx: number): void {
    this.closeOperationMenu(menu_idx)
    this.removeOperationsEvent.emit([remove_operation])
  }

  protected readonly faLock = faLock;
  protected readonly faLockOpen = faLockOpen;
  protected readonly PAStore = PAStore;
  protected readonly Number = Number;
  protected readonly PAFilterControl = PAFilterControl;
  protected readonly PASettingsControl = PASettingsControl;
  protected readonly PADataControl = PADataControl;
  protected readonly PAUtil = PAUtil;
  protected readonly PATimeControl = PATimeControl;
  protected readonly faExclamationTriangle = faExclamationTriangle;
  protected readonly faTrash = faTrash;

  async deleteTour() {
    if (this.tour) {
      const technician_date = this.tour.technicianDate
      if (!technician_date.isPastTechnicianDate()) {
        if (technician_date.travelTechnicianDate && technician_date.travelTechnicianDate.isActive()) {
          await technician_date.travelTechnicianDate.deleteInDb()
        }
      }
      this.removeOperationsEvent.emit(this.tour.operations.concat(this.tour.technicianDate.changedTourContainer.unfittingOperations))
    }
  }

  protected readonly PAStoreControl = PAStoreControl;
  protected readonly faCodeBranch = faCodeBranch;

  toggleShowCompareTour() {
    if (this.compareTour) {
      this.showCompareTour = !this.showCompareTour
    } else {
      this.showCompareTour = false
    }
  }

  public eventToNumber(event: Event): number {
    const input = event.target as HTMLInputElement

    return Number.parseInt(input.value)
  }
}