<div class="row">
  <div class="col-sm-3">
    <h3>Weiterverarbeitung
    </h3>
  </div>
</div>

<div class="row">
  <div class="col-sm-12">
    <div class="card">
      <div class="card-body">
        <h5 class="card-title">Offene Vorgänge</h5>
        <ul>
          <li *ngFor="let item of bulkJobs">
            {{item.id}} {{item.created_at}} <a href="{{getUrl(item.id)}}" target="_new">Label öffnen</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
