<div class="workflowUpload">
  <table class="base">
    <tr *ngIf="!projectMode">
      <th>hinterlegen in:</th>
      <td>
        <select [(ngModel)]="workflowGeneratorType">
          <option *ngFor="let typ of workflowGeneratorTypes" [ngValue]="typ.key">
              {{typ.title}}
          </option>
        </select>
      </td>
    </tr>

    <tr *ngIf="projectMode && metadata">
      <th>hinterlegen für Priorität:</th>
      <td>
        <select [(ngModel)]="selectedPriority">
          <option [ngValue]="0">
              Für alle Prioritäten (Fallback)
          </option>
          <option *ngFor="let prio of metadata.priorities" [ngValue]="prio.id">
              {{prio.name}}
          </option>
        </select>
      </td>
    </tr>
    <tr>
      <th>Dateiauswahl (aus Workflowgenerator!)</th>
      <td>
        <hawk-shared-file-input (saveClicked)="saveWorkflow($event)" acceptedFiletypes="application/json"></hawk-shared-file-input>
      </td>
    </tr>

    <tr *ngIf="projectMode && !metadata">
      <th>lade</th>
      <td>
    </tr>
  </table>
</div>
