import * as mapboxgl from "mapbox-gl"
import { PACoordinates } from "./coordinates"
import { PAOperation } from "./operation"
import { PAProject } from "./project"
import { PAUtil } from "./util"
import { MarkerInstance } from "./mapbox-marker"
import { PATechnicianDate } from "./technician-date";
import { FireUpdateOnChange } from "./util-classes/fire-update-on-change";
import { PADataControl } from "../singletons/pa-data-control";
import { PATourPlannerControl } from "../singletons/pa-tourplanner-control";
import { PASettingsControl } from "../singletons/pa-settings-control";
import { PALocation } from "./location";

export interface StoreProperties {
  id: number;
  operation_count: number;
  short_name: string;
  color: string;
  done_operations: string;
  done_operations_color: string;
  letter: string;
  has_letter: boolean;
  show_add_operation_button: boolean;
  next_addable_operation_time: string;
}

export interface StoreGeometry {
  type: "Point";
  coordinates: [number, number];
}

export class PAStore extends FireUpdateOnChange<void> implements MarkerInstance {

  static storeMap = new Map<string, PAStore>()
  static allStores: PAStore[] = []
  static selectedStoreInMap: PAStore

  public shownMapOperations: PAOperation[] = []
  private _color?: { r: number, g: number, b: number, a: number }
  public letter: string = ''
  private _selectedStoreOperation: PAOperation
  public id: number

  public expandStoreProjects: PAProject[] = []
  public expandUnplannedProjects = true
  public expandPlannedProjects = false
  public expandDoneProjects = false

  public operationTree: OperationTree = {
    unplanned: [],
    planned: [],
    done: [],
    header: ''
  }

  constructor(
    public address_company: string,
    public address: string,
    public location: PALocation,
    public operation_ids: number[]
  ) {
    super()
    this.id = PADataControl.Instance.generateNextStoreId()
    PADataControl.Instance.storeMap.set(this.id, this)
    this.color = null
  }

  get color(): { r: number, g: number, b: number, a: number } {
    return this._color
  }

  set color(color: { r: number, g: number, b: number, a: number }) {
    this._color = color
  }

  get operations(): PAOperation[] {
    return this.operation_ids?.length ? this.operation_ids.map(id => PADataControl.Instance.getOperation(id)) : []
  }

  getCoordinates(): PACoordinates {
    return this.location.coordinates
  }

  public getColorString(change_alpha?: number): string {
    let rgba = this.getColor()
    return change_alpha ? PAUtil.rgbaToString({
      r: rgba.r,
      g: rgba.g,
      b: rgba.b,
      a: change_alpha
    }) : PAUtil.rgbaToString(rgba)
  }

  public getColor(): { r: number, g: number, b: number, a: number } {
    let project_ids = [...new Set(this.shownMapOperations.map(op => op.ticket.project.id))]
    return this.color || PAUtil.hexToRgba((project_ids.length == 1 ? PADataControl.Instance.getProject(project_ids[0]).color : '#ffffff'), .9)
  }

  get selectedStoreOperation(): PAOperation {
    return this._selectedStoreOperation
  }

  set selectedStoreOperation(operation: PAOperation) {
    this._selectedStoreOperation = operation
    this.expandSelectedStoreOperation(true)
  }

  public executeBeforeChange() {
    this.updateShownMapOperations()
  }

  toggleExpandProject(project: PAProject, status: boolean): void {
    if (status && !this.expandStoreProjects.includes(project)) {
      this.expandStoreProjects.push(project)
    } else if (!status && this.expandStoreProjects.includes(project)) {
      PAUtil.removeElementFromList(this.expandStoreProjects, project)
    }
  }

  public updateShownMapOperations(): void {
    this.shownMapOperations = this.operations
    this.updateOperationTree()
    this.updateStoreLetter()
    if (!this.shownMapOperations.includes(this.selectedStoreOperation)) {
      if (this.shownMapOperations.length) {
        this.selectedStoreOperation = this.shownMapOperations[0]
      } else {
        this.selectedStoreOperation = null
      }
    }
  }

  public updateStoreLetter(): void {
    let operations_in_planning_process = PATourPlannerControl.Instance.tourPlannerExtraOperationCollection
    let main_operation_in_planning_process = PATourPlannerControl.Instance.getOperationInPlanningProcess()
    if (main_operation_in_planning_process) operations_in_planning_process = [main_operation_in_planning_process].concat(operations_in_planning_process)

    let stores_in_planning_process = [...new Set(operations_in_planning_process.map(op => op.getStore()))]
    let store_idx = stores_in_planning_process.indexOf(this)
    this.letter = this.indexToLetter(store_idx)
  }

  private indexToLetter(idx: number): string {
    if (idx >= 0) {
      let letter = ''
      if (idx > 25) {
        let div = Math.floor(idx / 26)
        letter += this.indexToLetter(div - 1)
      }
      let mod = (idx) % 26
      return letter + String.fromCharCode(mod + 65)
    } else {
      return ''
    }
  }

  public centerInMap(map: mapboxgl.Map): void {
    let bounds = new mapboxgl.LngLatBounds();
    bounds.extend([this.location.coordinates.longitude, this.location.coordinates.latitude])
    map.fitBounds(
      bounds,
      {
        padding: {top: 200, bottom: 200, left: 200, right: 200},
        speed: 1,
        maxZoom: map.getZoom(),
        minZoom: map.getZoom()
      }
    )
  }

  public updateOperationTree(): void {
    let unplanned_operations = this.shownMapOperations.filter(operation => operation.isUnassigned() && operation.isGloballyVisible())
    let planned_operations = this.operations.filter(operation => !operation.isUnassigned() && !operation.isDone())
    let done_operations = this.operations.filter(operation => operation.isDone())

    let operation_count = unplanned_operations.length + planned_operations.length + done_operations.length
    let planned_operation_count = operation_count - unplanned_operations.length

    let header = `Geplant: ${planned_operation_count}/${operation_count}`

    this.operationTree = {
      unplanned: PAStore.generateProjectSubTrees(unplanned_operations, true),
      planned: PAStore.generateProjectSubTrees(planned_operations),
      done: PAStore.generateProjectSubTrees(done_operations),
      header: header
    }
    this.expandSelectedStoreOperation()
  }

  public toMapboxFeature(reference_td?: PATechnicianDate): GeoJSON.Feature<StoreGeometry, StoreProperties> {
    let planned_operation_count = this.shownMapOperations.filter(op => !op.isUnassigned()).length

    let operations_to_add: PAOperation[]
    if (reference_td) {
      operations_to_add = this.shownMapOperations.filter(op => op.isUnassigned() && !reference_td.hasOperationWithIdInChangedTour(op.id) && op.checkGeneralTechnicianDoability(reference_td?.technician))
    } else {
      operations_to_add = this.shownMapOperations.filter(op => op.isUnassigned() && !(PATourPlannerControl.Instance.tourPlannerExtraOperationCollection.find(extra_op => extra_op.id == op.id) || op.isInPlanningProcess()))
    }
    let show_add_operation_button = !!operations_to_add.length

    let show_memo_symbol: boolean = false
    for (let operation of this.shownMapOperations) {
      if (operation.ticket.memos.length) show_memo_symbol = true
    }

    return {
      type: "Feature",
      properties: {
        id: this.id,
        operation_count: this.shownMapOperations.length,
        short_name: (this.address_company ? this.address_company.slice(0, 3).toUpperCase() : '?') + (show_memo_symbol ? '!' : ''),
        color: this.getColorString(),
        done_operations: `${planned_operation_count}/${this.shownMapOperations.length}`,
        done_operations_color: planned_operation_count == this.shownMapOperations.length ? 'rgb(0,255,51)' : 'rgb(255,251,0)',
        letter: this.letter || '',
        has_letter: !!this.letter,
        show_add_operation_button: show_add_operation_button,
        next_addable_operation_time: operations_to_add.length && PASettingsControl.Instance.selectedSettingConfig.data.general.map.show_operation_times ? `+${operations_to_add[0].calculateOperationTimeMilliseconds({use_technician: reference_td?.technician}) / 60000}min` : '',
      },
      geometry: {
        type: "Point",
        coordinates: [this.location.coordinates.longitude, this.location.coordinates.latitude]
      }
    }
  }

  static insertOperation(operation: PAOperation, skip_update?: boolean): PAStore {
    const store = this.getOperationsStore(operation)
    if (store) {
      if (!store.operation_ids.find(id => id == operation.id)) {
        store.operation_ids.push(operation.id)
        if (!skip_update) {
          store.fireUpdateManually()
        }
      }
    }
    return store
  }

  static generateProjectSubTrees(operations: PAOperation[], apply_global_filter?: boolean): ProjectSubtree[] {
    let subtrees: ProjectSubtree[] = []
    let allProjects = [...new Set(operations.map(operation => operation.getProject()))]
    if (apply_global_filter) allProjects = allProjects.filter(p => PAProject.selectedProjects.includes(p))
    for (let project of allProjects) {
      subtrees.push({project: project, project_operations: operations.filter(op => op.belongsToProject(project.id))})
    }
    return subtrees
  }

  private expandSelectedStoreOperation(collapse_others?: boolean): void {
    if (this.selectedStoreOperation) {
      if (PADataControl.Instance.unassignedOperationUserIds.includes(this.selectedStoreOperation.user_ids[0])) {
        this.expandUnplannedProjects = true
        if (collapse_others) {
          this.expandPlannedProjects = false
          this.expandDoneProjects = false
        }

      } else {
        if (this.selectedStoreOperation.isDone()) {
          this.expandDoneProjects = true
          if (collapse_others) {
            this.expandPlannedProjects = false
            this.expandUnplannedProjects = false
          }
        } else {
          this.expandPlannedProjects = true
          if (collapse_others) {
            this.expandUnplannedProjects = false
            this.expandDoneProjects = false
          }
        }
      }

      let project = this.selectedStoreOperation.getProject()
      if (project) {
        if (collapse_others) {
          this.expandStoreProjects = [project]
        } else {
          if (!this.expandStoreProjects.includes(project)) this.expandStoreProjects.push(project)
        }
      }
    }
  }

  static getOperationsStore(operation: PAOperation): PAStore {
    if (operation.ticket.coordinates.latitude != null && operation.ticket.coordinates.longitude != null) {
      let store_key = operation.ticket.coordinates.latitude.toString() + operation.ticket.coordinates.longitude.toString()
      if (!this.storeMap.has(store_key)) {
        let store = new PAStore(
          operation.ticket.address_company,
          operation.address(),
          operation.ticket.client.location,
          [operation.id]
        )
        this.storeMap.set(store_key, store)
        store.fireUpdateManually()
        this.allStores.push(store)
        return store
      } else {
        return this.storeMap.get(store_key)
      }
    } else {
      return null
    }
  }

  addNextDoableUnplannedOperationToTourPlanning(technician_date?: PATechnicianDate) {
    let unassigned_operations = this.getUnassignedOperations()

    if (technician_date) {
      let doable_operations = this.getDoableOperationsForTechnicianDate(technician_date)
      if (doable_operations.length) {
        PATourPlannerControl.Instance.addOperationsToTourPlanning(doable_operations);
        PATourPlannerControl.Instance.planningInstructions.addOperationsTechnicianConstraints([doable_operations[0]], [technician_date.technician], [technician_date.day.utc_timestamp])
      }
    } else {
      if (unassigned_operations.length) {
        if (PATourPlannerControl.Instance.planningMenuIsInPlanningProcess()) {
          PATourPlannerControl.Instance.addOperationsToTourPlanning(unassigned_operations);
        } else {
          PATourPlannerControl.Instance.planOperation(unassigned_operations.splice(0, 1)[0])
          PATourPlannerControl.Instance.addOperationsToTourPlanning(unassigned_operations);
        }
      }
    }
  }

  getUnassignedOperations(): PAOperation[] {
    return this.shownMapOperations.filter(op => op.isUnassigned())
  }

  getDoableOperationsForTechnicianDate(technician_date: PATechnicianDate): PAOperation[] {
    return this.getUnassignedOperations().filter(op => op.checkGeneralTechnicianDoability(technician_date.technician) && !technician_date.hasOperationWithIdInChangedTour(op.id))
  }
}

export interface OperationTree {
  'unplanned': ProjectSubtree[],
  'planned': ProjectSubtree[],
  'done': ProjectSubtree[],
  header: string
}

export interface ProjectSubtree {
  project: PAProject,
  project_operations: PAOperation[]
}
