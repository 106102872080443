import * as mapboxgl from "mapbox-gl"
import { PAStore } from "./store"
import { PAUtil } from "./util"
import { Subscription } from "rxjs"
import { PACoordinates } from "./coordinates"
import { PATechnician } from "./technician"
import { PATour } from "./tour";
import { MapContainer } from "../map/pa-map";
import { DynamicComponentService } from "../../../_services/dynamic-component-service";
import { TourStopoverComponent } from "../reuseable-components/tour-stopover/tour-stopover.component";
import { TechnicianPosition } from "./technician-live-position";
import {
  TechnicianLivePositionComponent
} from "../reuseable-components/technician-live-position/technician-live-position.component";
import { PATourPlannerControl } from "../singletons/pa-tourplanner-control";
import { PAFilterControl } from "../singletons/pa-filter-control";
import { PAOperation } from "./operation";

export abstract class MapboxMarker {

  private _selected: boolean
  public markerElement: mapboxgl.Marker
  private _active: boolean = false
  private _activeUpdateSubscription: Subscription

  public markerConfig = {
    small: {
      width: '40px',
      height: '40px',
      padding_top: '5px',
      font_size: '.72rem',
      line_height: '.88rem',
      border_radius: '20px',
      marker_size: '16px',
      marker_border_radius: '8px'
    },
    big: {
      width: '56px',
      height: '56px',
      padding_top: '9px',
      font_size: '.9rem',
      line_height: '1.06rem',
      border_radius: '28px',
      marker_size: '20px',
      marker_border_radius: '10px'
    }
  }

  protected constructor(
    public mapContainer: MapContainer,
    public markerInstance: PATechnician | PAStore | TechnicianPosition
  ) {
  }

  get active() {
    return this._active
  }

  set active(active: boolean) {
    this._active = active
    if (active) {
      this.showMarker()
    } else {
      this.hideMarker()
    }
  }

  get selected() {
    return this._selected
  }

  abstract initElement(): void

  getMarkerElement() {
    if (!this.markerElement) {
      this.initElement()
    }
    return this.markerElement
  }

  public hideMarker(): void {
    if (this.markerElement) {
      this.markerElement.remove()
    }
    this.clearChangeSubscription()
  }

  public clearChangeSubscription(): void {
    if (this._activeUpdateSubscription) {
      this._activeUpdateSubscription.unsubscribe()
      this._activeUpdateSubscription = null
    }
  }

  public initChangeSubscription(): void {
    if (!this._activeUpdateSubscription) {
      this._activeUpdateSubscription = this.markerInstance.subscribeToChanges(() => {
        this.update()
      })
    }
  }

  public showMarker(): void {
    try {
      this.getMarkerElement().addTo(this.mapContainer.map)
    } catch (err) {
      console.warn('show marker: ' + err)
    }
    this.initChangeSubscription()

    this.update()
  }

  abstract update(): void
}

export class TechnicianMapboxMarker extends MapboxMarker {

  constructor(
    public technician: PATechnician,
    mapContainer: MapContainer,
  ) {
    super(mapContainer, technician)
  }

  update(): void {
    let marker = this.getMarkerElement()
    let coordinates = this.markerInstance.getCoordinates()
    let element_width = '20px'
    let element_height = '20px'
    let background_color = this.markerInstance.getColorString()

    marker.setLngLat([coordinates.longitude, coordinates.latitude])
    let html_element = marker.getElement()
    html_element.style.backgroundColor = background_color
    html_element.style.border = '1px solid black'
    html_element.style.width = element_width
    html_element.style.height = element_height
  }

  initElement(): void {
    let background_color = this.markerInstance.getColorString()
    let coordinates = this.markerInstance.getCoordinates()
    let text = this.technician.firstname + " " + this.technician.lastname
    const popup = new mapboxgl.Popup().setHTML(text)

    const el = document.createElement('div')
    el.style.backgroundColor = background_color
    el.style.width = '20px'
    el.style.height = '20px'
    el.style.backgroundSize = '100%'
    el.style.border = '1px solid black'
    el.style.fontSize = `.65rem`
    el.style.display = 'inline-flex'
    el.style.borderRadius = '4px'
    let inner_el = document.createElement('b')
    inner_el.style.margin = 'auto'
    inner_el.style.color = 'black'
    inner_el.style.lineHeight = '.7rem'
    inner_el.innerHTML = `${this.technician.firstname[0]}${this.technician.lastname[0]}`

    el.appendChild(inner_el)

    el.addEventListener('dblclick', () => PATechnician.calendarComponent.togglePreferredTechnician(this.technician))
    el.addEventListener('click', () => {
      if (PATourPlannerControl.Instance.tourPlannerPlanningMode == 'technician') {
        PATourPlannerControl.Instance.technicianToPlanID = this.technician.id
      }
      if (PATourPlannerControl.Instance.tourPlannerPlanningMode == 'operation' && PATourPlannerControl.Instance.operationToPlanID) {
        if (!PAFilterControl.Instance.preferredCalendarTechnicians.includes(this.technician)) {
          PAFilterControl.Instance.preferredCalendarTechnicians = PAFilterControl.Instance.preferredCalendarTechnicians.concat([this.technician])
        }
      }
    })

    this.markerElement = new mapboxgl.Marker(el).setLngLat([coordinates.longitude, coordinates.latitude])
    this.markerElement.setPopup(popup)
  }

}

export class TourStopoverMapboxMarker extends MapboxMarker {

  injectedTourStopoverComponent: TourStopoverComponent
  private _selectedTour: PATour;

  constructor(
    private _tourDependencies: PATour[],
    public store: PAStore,
    mapContainer: MapContainer,
    private _dynamicComponentService: DynamicComponentService
  ) {
    super(mapContainer, store)
  }

  get tourDependencies() {
    return this._tourDependencies
  }

  set tourDependencies(tours: PATour[]) {
    let old_tour_deps = this._tourDependencies
    this._tourDependencies = tours
    if (!PAUtil.equalSets(new Set(old_tour_deps), new Set(tours))) this.update()
  }

  get selectedTour() {
    return this._selectedTour
  }

  set selectedTour(selected_tour: PATour) {
    let old_tour = this._selectedTour
    this._selectedTour = selected_tour
    if (old_tour != selected_tour) this.update()
  }

  update() {
    let unfitting_operations: PAOperation[] = []
    let deleting_operations: PAOperation[] = []
    for (let tour of this._tourDependencies) {
      const unfitting_tour_operations = tour.technicianDate.changedTourContainer.unfittingOperations
      const deleting_tour_operations = tour.technicianDate.changedTourContainer.deletingOperations
      unfitting_operations = unfitting_operations.concat(unfitting_tour_operations.filter(unfitting_op => this.store.shownMapOperations.find(shown_op => shown_op.id == unfitting_op.id)))
      deleting_operations = deleting_operations.concat(deleting_tour_operations.filter(deleting_op => this.store.shownMapOperations.find(shown_op => shown_op.id == deleting_op.id)))
    }
    this.injectedTourStopoverComponent.referenceTours = this._tourDependencies
    this.injectedTourStopoverComponent.selectedTechnicianDate = this.selectedTour?.technicianDate
    this.injectedTourStopoverComponent.store = this.store
    this.injectedTourStopoverComponent.notInsertedOperations = unfitting_operations
    this.injectedTourStopoverComponent.deletedOperations = deleting_operations
  }

  initElement(): void {
    if (!this.injectedTourStopoverComponent) {
      let component = this._dynamicComponentService.injectComponent(TourStopoverComponent, x => {
        x.store = this.markerInstance as PAStore
        x.referenceTours = this._tourDependencies
        x.notInsertedOperations = []
        x.deletedOperations = []
        x.selectedTechnicianDate = null
        x.mapContainer = this.mapContainer
      })

      this.injectedTourStopoverComponent = component.component_ref.instance

      this.markerElement = new mapboxgl.Marker({element: component.div_element})
      let coordinates = this.markerInstance.getCoordinates()
      this.markerElement.setLngLat([coordinates.longitude, coordinates.latitude])
    }
  }
}

export class TechnicianLivePositionMarker extends MapboxMarker {

  injectedTechnicianLivePositionComponent: TechnicianLivePositionComponent

  constructor(
    private _technicianLivePosition: TechnicianPosition,
    mapContainer: MapContainer,
    private _dynamicComponentService: DynamicComponentService
  ) {
    super(mapContainer, _technicianLivePosition)
    setInterval((_: any) => {
      console.log('updating technician live position tour (scheduled)')
      this._technicianLivePosition.tour.updateRoute(true).then(
        _ => this.update()
      )
    }, 1000 * 20)
  }

  get livePosition() {
    return this._technicianLivePosition
  }

  set livePosition(position: TechnicianPosition) {
    let old_position = this._technicianLivePosition
    this._technicianLivePosition = position
    if (position != old_position) {
      this.clearChangeSubscription()
      this.initChangeSubscription()
      this.update()
    }
  }

  update() {
    if (this.injectedTechnicianLivePositionComponent.technicianLivePosition != this._technicianLivePosition) {
      this.injectedTechnicianLivePositionComponent.technicianLivePosition = this._technicianLivePosition
    }
    let coordinates = this._technicianLivePosition.getCoordinates()
    this.markerElement.setLngLat([coordinates.longitude, coordinates.latitude])
  }

  initElement(): void {
    if (!this.injectedTechnicianLivePositionComponent) {
      let component = this._dynamicComponentService.injectComponent(TechnicianLivePositionComponent, x => {
        x.technicianLivePosition = this._technicianLivePosition
        x.livePositionMarker = this
      })

      this.injectedTechnicianLivePositionComponent = component.component_ref.instance

      this.markerElement = new mapboxgl.Marker({element: component.div_element})
      let coordinates = this.markerInstance.getCoordinates()
      this.markerElement.setLngLat([coordinates.longitude, coordinates.latitude])
    }
  }
}

export interface MarkerInstance extends HasColor, HasCoordinates {
}

export interface HasColor {
  getColorString(): string
}

export interface HasCoordinates {
  getCoordinates(): PACoordinates
}