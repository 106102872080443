import { Pipe, PipeTransform } from '@angular/core'
import { Store } from '../_models'

@Pipe({
    name: 'storeFilter',
})
export class StoreFilterPipe implements PipeTransform {
  transform(allStores: Store[], searchText: string): Store[] {
    if (!allStores) {
      return allStores
    }

    return allStores.filter(store => `${store.nr} ${store.name} ${store.address_firstname} ${store.address_lastname} ${store.address_street} ${store.address_zip} ${store.address_city} ${store.address_country}`.toLowerCase().includes(searchText.toLowerCase()))
  }
}

