<ng-container *ngIf="materialContainer && materialContainer.materials.length">
  <div style="display: inline-flex; flex-direction: column;">
    <div style="margin: auto; display: inline-flex; flex-direction: column;">
      <div *ngFor="let material of materialContainer.materials">
        {{materialToString(material)}} 
        <button style="margin-left: 4px;" (click)="deleteTicketMaterial(material)">
          <fa-icon [icon]="faTrash"></fa-icon>
        </button>
      </div>
    </div>
  </div>
  <br>
</ng-container>
<ng-container *ngIf="!showAddMaterialDialog">
  <button style="border: 1px solid black;" class="black_gradient_background" (click)="addMaterial()">
    <fa-icon style="margin: auto; padding: 0 4px; font-size: .75rem;" [icon]="faPlus"></fa-icon>
  </button>
</ng-container>
<div *ngIf="showAddMaterialDialog" style="background-color: white; color: black;">
  <div *ngIf="showHeader" style="margin-left: 4px;">
    Material hinzufügen:
  </div>
  <form [formGroup]="newMaterialForm" class="normal-form" (submit)="saveMaterial()">
    <mat-grid-list cols="3" rowHeight="65px">
      <mat-grid-tile>
        <div class="controles-container">
          <mat-form-field class="outline-small" appearance="outline">
            <mat-label>Template auswählen</mat-label>
            <mat-select formControlName="article_template_id">
              <input type="search" style="width: 100%" (search)="filterArticleTemplates($event)" (keyup)="filterArticleTemplates($event)" placeholder="Suche Template...">
              <mat-option>None</mat-option>
              <ng-container *ngFor="let article_template of filteredArticleTemplates">
                <mat-option [value]="article_template.id">{{formatArticleTemplate(article_template)}}</mat-option>
              </ng-container>
            </mat-select>
            <mat-error>Bitte wählen Sie ein Template aus.</mat-error>
          </mat-form-field>
        </div>
      </mat-grid-tile>
      <mat-grid-tile>
        <div class="controles-container">
          <mat-form-field class="outline-small" appearance="outline">
            <input formControlName="amount" matInput type="number" placeholder="Anzahl">
            <mat-error>Bitte geben Sie eine Zahl &#8805; 1 an.</mat-error>
          </mat-form-field>
        </div>
      </mat-grid-tile>
      <mat-grid-tile>
        <div class="controles-container">
          <mat-form-field class="outline-small" appearance="outline">
            <input formControlName="serial_number" type="text" matInput placeholder="Seriennummer">
          </mat-form-field>
        </div>
      </mat-grid-tile>
    </mat-grid-list>

    <mat-grid-list cols="3" rowHeight="60px" >
      <mat-grid-tile>
        <div class="controles-container horizontal_flex" style="border: 1px solid black; border-radius: 6px;">
          <fa-icon [icon]="faFilter" style="margin: auto 0; width: 36px; text-align: center"></fa-icon>
          <mat-form-field class="outline-small no-subscript" appearance="outline" style="flex: 1;">
            <mat-label>Templates anzeigen</mat-label>
            <mat-select [value]="selectedTemplateFilter" (valueChange)="updateSelectedTemplateFilter($event)">
              <mat-option [value]="'all'">Alle</mat-option>
              <mat-option [value]="'exclusive_project'">Nur Projekt-Material</mat-option>
              <mat-option [value]="'inclusive_project'">Projekt/Unspezifiziertes Material</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </mat-grid-tile>
      <mat-grid-tile>
        <div class="button-row" style="margin-left: auto; margin-right: 8px;">
          <button mat-raised-button color="warn" type="button" (click)="showAddMaterialDialog = false" style="font-size: 1rem;">abbrechen</button>
        </div>
      </mat-grid-tile>
      <mat-grid-tile>
        <div class="button-row" style="margin-right: auto; margin-left: 8px;">
          <button mat-raised-button color="primary" type="submit" [disabled]="newMaterialForm.invalid" style="font-size: 1rem;">hinzufügen</button>
        </div>
      </mat-grid-tile>
    </mat-grid-list>
  </form>
</div>
