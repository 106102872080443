import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ReactiveFormsModule, UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import * as mapboxgl from "mapbox-gl";
import { PAUtil } from "../../planning-assistant/classes/util";
import { GeocoderEvent } from "../../../_models/mapbox.interface";
import { MapBoxService } from "../../../_services/mapbox.service";
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import { MatFormField } from "@angular/material/form-field";
import { MatInput } from "@angular/material/input";
import { MatButton } from "@angular/material/button";
import { NgIf } from "@angular/common";

export interface AddressOutput {
  address_company: string;
  address_street: string;
  address_zip: string;
  address_city: string;
  address_country: string;
  address_state: string;
  address_latitude: number;
  address_longitude: number;
  address_firstname?: string;
  address_lastname?: string;
  address_used_google_maps_parameter: string;
}

@Component({
  selector: 'hawk-shared-edit-address',
  templateUrl: './edit-address.component.html',
  standalone: true,
  imports: [
    MatFormField,
    MatInput,
    ReactiveFormsModule,
    MatButton,
    NgIf
  ],
  styleUrls: ['./edit-address.component.scss', './../styles/common-styles.scss']
})
export class EditAddressComponent {

  addressGeocoder: MapboxGeocoder

  @Input() company: string = '';
  @Input() street: string = '';
  @Input() zip: string = '';
  @Input() city: string = '';
  @Input() country: string = '';
  @Input() state: string = '';
  @Input() lat: number = 0;
  @Input() lng: number = 0;
  @Input() firstname: string = '';
  @Input() lastname: string = '';
  @Input() editName: boolean = false;
  @Output() onSubmitEvent = new EventEmitter<AddressOutput>();
  @Output() onAbortEvent = new EventEmitter<void>();

  addressForm: UntypedFormGroup = new UntypedFormGroup({
    company: new UntypedFormControl({value: ''}),
    street: new UntypedFormControl({value: '', disabled: true}),
    zip: new UntypedFormControl({value: '', disabled: true}, [Validators.pattern('^([a-zA-Z]{2}-)?[0-9]{2,5}( [a-zA-Z]{0,2})?$')]),
    city: new UntypedFormControl({value: '', disabled: true}),
    country: new UntypedFormControl({value: '', disabled: true}),
    state: new UntypedFormControl({value: '', disabled: true}),
    lat: new UntypedFormControl({value: ''}),
    lng: new UntypedFormControl({value: ''}),
    firstname: new UntypedFormControl({value: ''}),
    lastname: new UntypedFormControl({value: ''}),
  });

  constructor(
    private mapBoxService: MapBoxService
  ) {
  }

  ngOnInit(): void {
    this.resetForm()
    this.setGeocoder()
  }

  resetForm():void {
    this.addressForm.setValue({
      company: this.company,
      street: this.street,
      zip: this.zip,
      city: this.city,
      country: this.country,
      state: this.state,
      lat: this.lat,
      lng: this.lng,
      firstname: this.firstname,
      lastname: this.lastname
    });
  }

  public async setGeocoder(): Promise<void> {
    this.addressGeocoder = new MapboxGeocoder({
      accessToken: this.mapBoxService.accessToken,
      marker: false,
      language: 'de-DE',
      mapboxgl: mapboxgl,
      placeholder: 'Adresse suchen'
    })
    while (!document.getElementById('address_geocoder')) {
      await PAUtil.sleep(100)
    }
    const address_geocoder_elem = document.getElementById('address_geocoder')
    this.addressGeocoder.addTo(address_geocoder_elem);

    this.addressGeocoder.on('result', (event) => {
      this.onAddressGeocoderEvent(event)
    })
  }

  private onAddressGeocoderEvent(event: GeocoderEvent) {
    let {country, region, city, post_code, street, street_no, center, name} = PAUtil.getGeocoderEventAddress(event);

    let address_country_control = this.addressForm.controls['country']
    address_country_control.setValue(country)
    address_country_control.markAsTouched();
    address_country_control.updateValueAndValidity()
    let address_state_control = this.addressForm.controls['state']
    address_state_control.setValue(region)
    address_state_control.markAsTouched();
    address_state_control.updateValueAndValidity()
    let address_city_control = this.addressForm.controls['city']
    address_city_control.setValue(city)
    address_city_control.markAsTouched();
    address_city_control.updateValueAndValidity()
    let address_zip_control = this.addressForm.controls['zip']
    address_zip_control.setValue(post_code)
    address_zip_control.markAsTouched();
    address_zip_control.updateValueAndValidity()
    let address_street_control = this.addressForm.controls['street']
    address_street_control.setValue(street + ' ' + street_no)
    address_street_control.markAsTouched();
    address_street_control.updateValueAndValidity()

    let latitude_control = this.addressForm.controls['lat']
    latitude_control.setValue(center[1])
    latitude_control.markAsTouched();
    latitude_control.updateValueAndValidity()
    let longitude_control = this.addressForm.controls['lng']
    longitude_control.setValue(center[0])
    longitude_control.markAsTouched();
    longitude_control.updateValueAndValidity()

    this.addressForm.updateValueAndValidity()
  }

  onSubmit(): void {
    const controls = this.addressForm.controls

    let street = controls['street'].value
    let zip_code = controls['zip'].value
    let city = controls['city'].value
    let country = controls['country'].value

    const emit_hash = this.editName ?
      {
        address_company: controls['company'].value,
        address_street: street,
        address_city: city,
        address_zip: zip_code,
        address_country: country,
        address_state: controls['state'].value,
        address_latitude: controls['lat'].value,
        address_longitude: controls['lng'].value,
        address_used_google_maps_parameter: `${street}, ${zip_code} ${city}, ${country}`,
        address_firstname: controls['firstname'].value,
        address_lastname: controls['lastname'].value,
      } : {
        address_company: controls['company'].value,
        address_street: street,
        address_city: city,
        address_zip: zip_code,
        address_country: country,
        address_state: controls['state'].value,
        address_latitude: controls['lat'].value,
        address_longitude: controls['lng'].value,
        address_used_google_maps_parameter: `${street}, ${zip_code} ${city}, ${country}`,
      }

    this.onSubmitEvent.emit(emit_hash)
  }
}
