import { Injectable } from '@angular/core'
import { HttpClient, HttpHeaders, HttpParams, HttpEventType } from '@angular/common/http'

import { environment } from '../../environments/environment'
import { TicketNote, TicketLegacyNote } from '../_models'

@Injectable({
  providedIn: 'root'
})
export class TicketNoteService {

  constructor(private http: HttpClient) {}

  public getNotes(id: string) {
    const path = environment.apiURL + 'ticket_notes/index/' + id + '.json'

    return this.http.get<TicketNote[]>(path)
  }

  public getLegacyNotes(id: string) {
    const path = environment.apiURL + 'ticket_notes/legacy/' + id + '.json'

    return this.http.get<TicketLegacyNote[]>(path)
  }

  public createNote(note: TicketNote) {
    const path = environment.apiURL + 'ticket_notes.json'

    return this.http.post<TicketNote>(path, note)
  }

  public deleteNote(id: string) {
    const path = environment.apiURL + 'ticket_notes/' + id + '.json'

    return this.http.delete<TicketNote>(path)
  }
}
