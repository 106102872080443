import { PATechnician } from "./technician";
import { PATechnicianDate } from "./technician-date";
import { FireUpdateOnChange } from "./util-classes/fire-update-on-change";
import { Subscription } from "rxjs";
import { PATour } from "./tour";
import { PAUtil } from "./util";

export class TechnicianDateCollection extends FireUpdateOnChange<{route_changed_for_technician_date_with_timestamp: number} | 'selected_tour' | 'changed_technician_dates' | 'changed_tours'> {

  private _includeTechnicianDatesForDayTimestamps: number[] = []
  private _hideTechnicianDatesForDayTimestamps: number[] = []

  private _activeSubscriptionMap = new Map<number, {new_tour_location_changes: Subscription, new_tour_changes: Subscription}>()

  private _showTourMode: 'base' | 'new' = 'new'

  private _selectedTourDayTimestamp: number
  public technicianDates: PATechnicianDate[] = []
  public tours: PATour[] = []

  constructor(
    public technician: PATechnician
  ) {
    super()
  }

  executeBeforeChange(): void {}

  get hiddenTechnicianDates(): PATechnicianDate[] {
    return this.technicianDates.filter(td => !this._hideTechnicianDatesForDayTimestamps.includes(td.day.utc_timestamp))
  }

  get showTourMode(): 'base' | 'new' {
    return this._showTourMode
  }

  set showTourMode(show_tour_mode: 'base' | 'new') {
    this._showTourMode = show_tour_mode
  }

  get selectedTour(): PATour {
    if (this._selectedTourDayTimestamp) {
      let technician_date = this.technician.getTechnicianDate(this._selectedTourDayTimestamp, true, true)
      return this._showTourMode == 'new' ? technician_date.changedTourContainer.tour : technician_date.tour
    } else {
      return null
    }
  }

  public addTechnicianDatesForDayTimestamps(day_tss: number[]): void {
    for (let day_ts of day_tss) {
      if (!this._includeTechnicianDatesForDayTimestamps.includes(day_ts)) {
        this._includeTechnicianDatesForDayTimestamps.push(day_ts)

        let technician_date = this.technician.getTechnicianDate(day_ts, true, true)
        let new_location_subscription = this._getNewTourSubscriptionForTimestamp(day_ts);
        const new_tour_changed_subscription = technician_date.subscribeToChanges(
          _ => {
            this._activeSubscriptionMap.get(day_ts).new_tour_location_changes.unsubscribe()
            this._activeSubscriptionMap.get(day_ts).new_tour_location_changes = this._getNewTourSubscriptionForTimestamp(day_ts);
            this._updateTours()
          }
        )
        this._activeSubscriptionMap.set(day_ts, {new_tour_location_changes: new_location_subscription, new_tour_changes: new_tour_changed_subscription})
      }
    }

    this._includeTechnicianDatesForDayTimestamps.sort()
    this._updateTechnicianDates()
    this._updateTours()
  }

  private _getNewTourSubscriptionForTimestamp(day_ts: number): Subscription {
    let technician_date = this.technician.getTechnicianDate(day_ts, true, true)
    return technician_date.getNewTourChangeSubject().subscribe(
      _ => {
        this.fireUpdate({route_changed_for_technician_date_with_timestamp: day_ts})
      }
    )
  }

  public removeTechnicianDateForDayTimestamp(day_ts: number): void {
    if (this._includeTechnicianDatesForDayTimestamps.includes(day_ts)) {
      this._includeTechnicianDatesForDayTimestamps.splice(this._includeTechnicianDatesForDayTimestamps.indexOf(day_ts), 1)
      this.removeSubscriptionsForDayTimestamp(day_ts);

      if (this._selectedTourDayTimestamp == day_ts) {
        this._selectedTourDayTimestamp = null
        this.fireUpdate('selected_tour')
      }

      this._updateTechnicianDates()
      this._updateTours()
    }
  }

  private removeSubscriptionsForDayTimestamp(day_ts: number) {
    this._activeSubscriptionMap.get(day_ts).new_tour_location_changes.unsubscribe()
    this._activeSubscriptionMap.get(day_ts).new_tour_changes.unsubscribe()
    this._activeSubscriptionMap.delete(day_ts)
  }

  public hideTechnicianDateForDayTimestamp(day_ts: number): void {
    if (!this._hideTechnicianDatesForDayTimestamps.includes(day_ts)) {
      this._hideTechnicianDatesForDayTimestamps.push(day_ts)
    }
  }

  public showTechnicianDateForDayTimestamp(day_ts: number): void {
    if (this._hideTechnicianDatesForDayTimestamps.includes(day_ts)) {
      this._hideTechnicianDatesForDayTimestamps.splice(this._hideTechnicianDatesForDayTimestamps.indexOf(day_ts), 1)
    }
  }

  public clear(): void {
    for (let timestamp of [...this._includeTechnicianDatesForDayTimestamps]) {
      this.removeTechnicianDateForDayTimestamp(timestamp)
    }
  }

  public selectTourWithDayTimestamp(ts: number): void{
    if (this._includeTechnicianDatesForDayTimestamps.includes(ts)) {
      this._selectedTourDayTimestamp = ts
      this.fireUpdate('selected_tour')
    }
  }

  public unselectTour(): void {
    this._selectedTourDayTimestamp = null
    this.fireUpdate('selected_tour')
  }

  private _updateTechnicianDates(): void {
    let technician_dates_before = this.technicianDates
    this.technicianDates = this._includeTechnicianDatesForDayTimestamps.map(ts => this.technician.getTechnicianDate(ts, true, true)).filter(td => td)
    if (!PAUtil.equalSets(new Set(technician_dates_before), new Set(this.technicianDates))) {
      this.fireUpdate('changed_technician_dates')
    }
  }

  private _updateTours(): void {
    let tours_before = this.tours
    this.tours = this._includeTechnicianDatesForDayTimestamps.map(ts => this.technician.getTechnicianDate(ts, true, true)).filter(td => td).map(td => (this._showTourMode == 'base' || td.isPastTechnicianDate()) ? td.tour : td.changedTourContainer.tour)
    if (!PAUtil.equalSets(new Set(tours_before), new Set(this.tours))) {
      this.fireUpdate('changed_tours')
      if (tours_before.find(tour => tour.technicianDate.day.utc_timestamp == this._selectedTourDayTimestamp)) {
        if (this.tours.find(tour => tour.technicianDate.day.utc_timestamp == this._selectedTourDayTimestamp)) {
          this.fireUpdate('selected_tour')
        } else {
          this.unselectTour()
        }
      }
    }
  }

  get technicianDateWithLivePosition(): PATechnicianDate {
    return this.technicianDates.find(td => td.isTodaysTechnicianDate())
  }

  isEmpty() {
    return !this._includeTechnicianDatesForDayTimestamps.length
  }

  getBaseTours(): PATour[] {
    return this.technicianDates.map(td => td.tour)
  }

  hasTechnicianDateWithTimestamp(ts: number): boolean {
    return this._includeTechnicianDatesForDayTimestamps.includes(ts);
  }
}