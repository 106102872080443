import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RowClassRules } from 'ag-grid-enterprise';
import { AG_GRID_LOCALE_DE } from 'src/app/_helpers/aggrid.locale.de';
import { Article, DeliveryNote, Ticket } from 'src/app/_models';
import { ArticleService } from 'src/app/_services';
import { environment } from 'src/environments/environment';
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { TicketSubhashArticles } from 'src/app/_models/trip_subhash_articles';

@Component({
  imports: [CommonModule, RouterModule],
    selector: 'hawk-article-show',
    templateUrl: './article-show.component.html',
    styleUrls: ['./article-show.component.scss'],
})
export class ArticleShowComponent {
  constructor(
    private articleService: ArticleService,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  public articleId: number | String | string;
  public article: Article;
  public articles: any[];
  public deliveryNotes: DeliveryNote[];
  public tickets: TicketSubhashArticles[];
  public histories: any[] = []
  env = environment;

  public locale = AG_GRID_LOCALE_DE;
  public isCheckingShipcloudTrackingStatus: boolean = false;
  public rowSelection: "single" | "multiple" = "multiple"
  public rowClassRules: RowClassRules = {
    // if tech on site, color row
    'tech-on-site': (params) => {
      if (params?.data === undefined) return "...";
      return params.data.tech_on_site
    },
  }

  ngOnInit(): void {
    this.articleId = parseInt(this.route.snapshot.paramMap.get('id'));
    // this.loadarticle();
    this.loadArticle();
    this.loadDeliveryNotes();
    this.loadTickets();
    this.loadHistories();
  }

  private loadHistories() {
    this.articleService.getHistories(this.articleId).subscribe(
      {
        next: (response) => {
          this.histories = response;
          console.log('response', response);
        }, error: (error) => {
          console.log('error', error);
        }
      }
    )
  }

  private loadTickets() {
    this.articleService.getTickets(this.articleId).subscribe(
      {
        next: (response) => {
          this.tickets = response;
        }, error: (error) => {
          console.log('error', error);
        }
      }
    )
  }

  private loadDeliveryNotes() {
    this.articleService.getDeliveryNotes(this.articleId).subscribe(
      {
        next: (response) => {
          this.deliveryNotes = response;
        }, error: (error) => {
          console.log('error', error);
        }
      }
    )
  }

  private loadArticle() {
    this.articleService.show(this.articleId).subscribe(
      {
        next: (response: any) => {
          this.article = response;
        },
        error: (error) => {
          console.log('error', error);
        }
      }
    )
  }

  public onDelete() {
    if(confirm('Möchten Sie den Artikel wirklich löschen?')) {
      this.articleService.delete(this.articleId).subscribe(
        {
          next: () => {
            this.router.navigate(['/', 'artikel', 'suche', 'index']);
          },
          error: (error) => {
            console.log('errors', error);
          }
        }
      )
    }
  }

}