import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'

import { environment } from '../../environments/environment'

@Injectable({
  providedIn: 'root'
})
export class CountryService {

  constructor(
      private http: HttpClient,
    ) {
  }

  public list() {
    const path = environment.apiURL + 'countries'

    return this.http.get<{id: string, name: string}[]>(path)
  }

}
