import { AgGridAngular } from 'ag-grid-angular';
import { RouterModule } from "@angular/router";
import { CommonModule } from "@angular/common";

import { Component, inject, OnInit } from '@angular/core'
import { ColDef, ISetFilterParams } from 'ag-grid-community'
import { GridApi, GridState, RowClassRules, SideBarDef } from 'ag-grid-enterprise'
import { GridReadyEvent } from 'ag-grid-community'

import { Article, Ticket, TicketSearchParams } from 'src/app/_models'

import { AG_GRID_LOCALE_DE } from 'src/app/_helpers/aggrid.locale.de'
import { TicketService } from 'src/app/_services'
import { dateFormatter } from 'src/app/_helpers/aggrid-renderer'
import { ActivatedRoute } from '@angular/router'
import { AgGridThemeService } from 'src/app/_services/ag-grid-theme.service';

@Component({
    selector: 'hawk-ticket-search',
    templateUrl: './ticket-search.component.html',
    styleUrls: ['./ticket-search.component.scss'],
    imports: [AgGridAngular, RouterModule, CommonModule]
})
export class TicketSearchComponent implements OnInit {
  public tickets: Ticket[] = []
  public articles: Article[] = []

  public hawkTheme = inject(AgGridThemeService).getTheme();

  public searchParams: TicketSearchParams = {}

  public sideBarOptions: SideBarDef = {
    hiddenByDefault: false,
    toolPanels: ['columns', 'filters']
  }

  public locale = AG_GRID_LOCALE_DE
  public storageKey = 'ticketSearchStateSettings'

  public rowSelection: "single" | "multiple" = "multiple"
  public initialState?: GridState;

  public rowClassRules: RowClassRules = {
    // if tech on site, color row
    'tech-on-site': (params) => { return params.data.tech_on_site },
  }

  public defaultColDef: ColDef = {
    filter: true,
    filterParams: {
      excelMode: "windows",
    } as ISetFilterParams,
  }

  // Column Definitions: Defines the columns to be displayed.
  public colDefs: ColDef[] = [
    {
      field: "order_nr",
      headerName: "AuftragsNr",
      cellRenderer: this.ticketLinkRenderer,
      width: 135
    },
    {
      field: "icon_hash",
      headerName: "Icons",
      cellRenderer: this.statusCellRenderer,
      width: 135,
      filter: false,
      hide: false
    },
    { field: "project_name", headerName: "Projekt", filter: true, hide: true },
    {
      field: "date_created",
      headerName: "Erstellt",
      valueFormatter: dateFormatter(),
      width: 140,
    },
    {
      field: "external_order_nr",
      headerName: "Ext-Nr",
      width: 110,
      hide: true
    },
    {
      field: "store_name",
      headerName: "Store",
      hide: true
    },
    {
      field: "zip",
      headerName: "PLZ",
      hide: true
    },
    {
      field: "city",
      headerName: "Ort",
      filterParams: {
        excelMode: "mac",
      } as ISetFilterParams,
    },
    {
      field: "prority_hash.name",
      headerName: "Prio",
      cellRenderer: this.prioCellRenderer2,
      width: 75,
    },
    {
      field: "status_text",
      headerName: "Status",
      width: 130,
    },
    {
      field: "appointment_date",
      headerName: "Telefontermin",
      valueFormatter: dateFormatter(),
      width: 140,
    },
    {
      field: "date_sla",
      headerName: "SLA",
      valueFormatter: dateFormatter(),
      width: 140,
    },
    {
      field: "operation_time",
      headerName: "Einsatzdatum",
      valueFormatter: dateFormatter(),
      width: 140,
    },
    {
      field: "operation_date",
      headerName: "Einsatztag",
      valueFormatter: dateFormatter("dd.MM.YYYY"),
      width: 140,
      hide: true,
    },
    {
      field: "operation_date_cw",
      headerName: "KW",
      width: 100,
    },
    {
      field: "operation_technicians",
      headerName: "Einsatztechniker",
      hide: true,
    },
    {
      field: "operation_date_travel_start",
      headerName: "Reisebeginn",
      valueFormatter: dateFormatter(),
      width: 140,
      hide: true
    },
    {
      field: "operation_date_on_site",
      headerName: "Ankunft",
      valueFormatter: dateFormatter(),
      width: 140,
    },
    {
      field: "operation_date_repaired",
      headerName: "Repariert",
      valueFormatter: dateFormatter(),
      width: 140,
      hide: true
    },
    {
      field: "operation_date_finished",
      headerName: "Fertig",
      valueFormatter: dateFormatter(),
      width: 140,
      hide: true
    },
    { field: "operation_worktime", headerName: "Arbeitszeit (min)", width: 85 },
    { field: "operation_status", headerName: "Erledigt", width: 85 },
    { field: "operation_invoiced", headerName: "RG", width: 85, hide: true },
    { field: "operation_partner_invoice", headerName: "RG Partner", width: 85 },
  ]

  private gridApi!: GridApi;

  constructor(
    private ticketService: TicketService,
    private route: ActivatedRoute,
  ) { }

  onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api
    this.gridApi.setGridOption("loading", true);
  }

  ngOnInit(): void {
    this.initialState = this.getState()

    this.route.queryParams.subscribe(params => {
      console.log("params", params)

      if (params['operation_date_from']) {
        this.searchParams['operation_date_from'] = params['operation_date_from']
      }
      if (params['operation_date_to']) {
        this.searchParams['operation_date_to'] = params['operation_date_to']
      }
      if (params['create_date_from']) {
        this.searchParams['create_date_from'] = params['create_date_from']
      }
      if (params['create_date_to']) {
        this.searchParams['create_date_to'] = params['create_date_to']
      }
      if (params['invoice_number']) {
        this.searchParams['invoice_number'] = params['invoice_number']
      }
      if (params['invoice_number_partner']) {
        this.searchParams['invoice_number_partner'] = params['invoice_number_partner']
      }
      if (params['project_id']) {
        this.searchParams['project_id'] = params['project_id']
      }
      if (params['priority_id']) {
        this.searchParams['priority_id'] = params['priority_id']
      }
      if (params['status_id']) {
        this.searchParams['status_id'] = params['status_id']
      }
      if (params['technician_ids']) {
        this.searchParams['technician_id'] = params['technician_ids']
      }
      if (params['technician_company_names']) {
        this.searchParams['technician_company_names'] = params['technician_company_names']
      }
      if (params['order_number']) {
        this.searchParams['order_number'] = params['order_number']
      }
      if (params['fault_description']) {
        this.searchParams['fault_description'] = params['fault_description']
      }
      if (params['external_order_number']) {
        this.searchParams['external_order_number'] = params['external_order_number']
      }
      if (params['is_open']) {
        this.searchParams['is_open'] = params['is_open']
      }
      if (params['not_invoiced']) {
        this.searchParams['not_invoiced'] = params['not_invoiced']
      }
      if (params['not_invoiced_partner']) {
        this.searchParams['not_invoiced_partner'] = params['not_invoiced_partner']
      }
      if (params['has_date']) {
        this.searchParams['has_date'] = params['has_date']
      }

      this.ticketService.searchTicket(this.searchParams).subscribe({
        next: (data) => {
          this.tickets = data
          this.gridApi.setGridOption("loading", false);
        },
        error: (err) => {
          console.error(err)
          this.gridApi.setGridOption("loading", false);
        }
      })
    })
  }

  public quicksearchChanged(e: Event) {
    const newValue = (e.target as HTMLInputElement).value
    console.log("newValue", newValue)
    this.gridApi.setGridOption(
      "quickFilterText",
      newValue,
    )
  }

  public saveState() {
    let state = this.gridApi.getState()
    delete state['filter']
    delete state.pagination.page
    const serializedState = JSON.stringify(state)
    localStorage.setItem(this.storageKey, serializedState)
  }

  public deleteState() {
    localStorage.removeItem(this.storageKey)
  }

  public getState(): GridState {
    return JSON.parse(localStorage.getItem(this.storageKey))
  }

  public printState() {
    const stateJson = this.getState()
    console.log(localStorage.getItem(this.storageKey), stateJson)
  }

  private statusCellRenderer(params): string {
    let result = `<img src="/r/icons/sla/${params.value.sla_icon}/${params.value.sla_icon_class}.svg" 
                      height="15" width="15" 
                      aria-hidden="true" data-toggle="tooltip"
                      title="${params.value.sla_text}">
                  <img src="/r/icons/phone/${params.value.phone_icon_class}.svg" 
                      height="15" width="15" 
                      aria-hidden="true" data-toggle="tooltip"
                      title="${params.value.phone_text}">
                  <img src="/r/icons/car/${params.value.travel_start_icon_class}.svg" 
                      height="15" width="15" 
                      aria-hidden="true" data-toggle="tooltip"
                      title="${params.value.travel_start_text}">
                  <img src="/r/icons/work/${params.value.on_site_icon_class}.svg" 
                      height="15" width="15" 
                      aria-hidden="true" data-toggle="tooltip"
                      title="${params.value.on_site_text}">`

    if (params.value.has_shipments) {
      result = result.concat(`
        <img src="/r/api/v1/tickets/${params?.data?.id}/shipment_indicator.png" height="18" width="18">
      `)
    } else {
      result = result.concat(`<span style="color:#d8d8d8;" > -</span>`)
    }

    result = result.concat(`<img src="/r/api/v1/tickets/${params?.data?.id}/step_indicator.png" height="18" width="18">`)

    return result
  }

  private prioCellRenderer2(params): string {
    return `<img src="/r/icons/priority/${params?.data?.prority_hash?.color?.replace('#', '')}.svg" 
              height="18" width="18"
              aria-hidden="true" data-toggle="tooltip"
              title="${params.value}">`
  }

  private ticketLinkRenderer(params): string {
    return `<a href="/a/ticket/${params?.data?.id}" 
                target="_blank" 
                style="color: #337ab7; text-decoration: none; font-weight: bold;">
                ${params.value}
              </a>`
  }

}