import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Priority } from "../../../../_models";
import { PATechnician } from "../../classes/technician";
import { TimePredictionRule } from "../../classes/time-prediction-rule";
import { PAProject } from "../../classes/project";
import { UntypedFormControl, UntypedFormGroup, Validators, ReactiveFormsModule } from "@angular/forms";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { PAUtil } from "../../classes/util";
import { PriorityRampUpRuleHash } from "../../../../_models/planning-assistant.interface";
import { PASettingsControl } from "../../singletons/pa-settings-control";
import { NgFor, NgIf, NgClass } from '@angular/common';
import { ExpandBarHeaderComponent } from '../expand-bar-header/expand-bar-header.component';
import { MatFormField, MatLabel, MatError } from '@angular/material/form-field';
import { _DeepPartialObject } from 'node_modules/chart.js/dist/types/utils';
import Chart, { CartesianScaleTypeRegistry, ChartType, ScaleOptionsByType } from 'chart.js/auto';
import { MatSelect } from '@angular/material/select';
import { MatOption } from '@angular/material/core';

import { MatButton } from '@angular/material/button';
import { MatInput } from '@angular/material/input';
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatSelectModule } from "@angular/material/select";
import { FaIconComponent, FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@Component({
  selector: 'hawk-additional-priority-time',
  templateUrl: './additional-priority-time.component.html',
  styleUrls: ['./additional-priority-time.component.scss', './../../styles/common_styles.scss', './../../../_shared/styles/common-styles.scss'],
  standalone: true,
  imports: [NgFor, NgIf, ExpandBarHeaderComponent, NgClass, ReactiveFormsModule, MatFormField, MatLabel, MatSelect, MatOption, MatError, FaIconComponent, MatButton, MatInput, CommonModule, FormsModule, MatFormFieldModule, MatSelectModule, FontAwesomeModule]
})
export class AdditionalPriorityTimeComponent implements OnChanges {

  @Input() technicians: PATechnician[]
  @Input() projects: PAProject[]
  @Input() config: {
    technician?: PATechnician | 'default',
    project_priority: { project: PAProject, priority?: Priority },
    extra_percent?: number
  } = {project_priority: {project: null, priority: null}}

  editTimePredictionRule: TimePredictionRule
  preEditValues: PriorityRampUpRuleHash

  expandProjects: PAProject[] = []
  searchProjects: PAProject[]

  shownPriorityRules: TimePredictionRule[] = []

  public chart: Chart<ChartType, string[], string>

  public timePredictionRuleForm: UntypedFormGroup = new UntypedFormGroup({
    step_from: new UntypedFormControl({value: 0}, Validators.required),
    step_until: new UntypedFormControl({value: 10}, Validators.required),
    technician: new UntypedFormControl({value: 'default'}),
    function_type: new UntypedFormControl({value: 'absolute'}, Validators.required),
    function_y_value: new UntypedFormControl({value: 50}, Validators.required),
  });

  constructor(
  ) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.update()
  }

  public update(priority_rule_changed?: boolean) {
    this.updatePriorityRules()
    this.updateChart()
    priority_rule_changed && this.config?.project_priority?.priority ? PASettingsControl.Instance.priorityRuleChangedSubject.next(this.config.project_priority.priority) : {}
  }

  addTimePredictionRuleForPriority(priority: Priority) {
    this.editTimePredictionRule = new TimePredictionRule(priority, 1, 10, {
      technician: "default",
      function_type: "absolute"
    })
    this.updateChart()
  }

  editRule(rule: TimePredictionRule): void {
    this.preEditValues = rule.toUpdateHash()
    this.editTimePredictionRule = rule;
    this.update()
  }

  addToExpandProjects(project: PAProject): void {
    this.expandProjects = [...new Set(this.expandProjects.concat([project]))]
  }

  removeFromExpandProjects(project: PAProject): void {
    this.expandProjects = this.expandProjects.filter(p => p != project)
  }

  filterSearchProjects(event: Event): void {
    const input = event.target as HTMLInputElement
    const search_string: string = input.value

    this.searchProjects = search_string ? this.projects.filter(project => project.project_name.toLowerCase().includes(search_string.toLowerCase())) : null
  }

  selectProject(project: PAProject): void {
    this.config.project_priority = {project: project, priority: null}
    this.editTimePredictionRule = null
    this.update()
  }

  selectPriority(priority: Priority): void {
    let config_project_priority = this.config.project_priority
    if (config_project_priority) {
      config_project_priority.priority = priority
    }
    this.editTimePredictionRule = null
    this.update()
  }

  updatePriorityRules() {
    this.shownPriorityRules = this.config?.project_priority?.priority ? PASettingsControl.Instance.getAdditionalPriorityTimeRules(this.config?.project_priority?.priority) : []
  }

  selectProjectAndPriority(project: PAProject, priority: Priority): void {
    this.config.project_priority = {project: project, priority: priority}
    this.editTimePredictionRule = null
    this.update()
  }

  saveTimePredictionRuleForPriority(): void {
    PASettingsControl.Instance.addAdditionalTimePriorityRule(this.editTimePredictionRule)
    this.editTimePredictionRule = null
    this.update(true)
  }

  updateChart(): void {
    if (this.editTimePredictionRule || this.shownPriorityRules?.length) {

      let all_rules = this.editTimePredictionRule && !this.shownPriorityRules.includes(this.editTimePredictionRule) ? this.shownPriorityRules.concat([this.editTimePredictionRule]) : this.shownPriorityRules

      let step_start = 1
      let step_end = Math.max(...all_rules.map(rule => rule.step_end))

      let labels = [];
      for (let i = step_start; i <= step_end; i++) {
        labels.push(i);
      }

      let datasets = []
      let technicians_with_priority_rules = [...new Set(all_rules.map(rule => rule.technician))]
      for (let technician of technicians_with_priority_rules) {
        let technician_rules = all_rules.filter(r => r.technician == technician)
        let tpf_wrappers = technician_rules.map(r => r.timePredictionFunctionWrapper)
        let color = PAUtil.rgbaToString(PAUtil.getIndexColorRGBA(technicians_with_priority_rules.indexOf(technician)))
        datasets.push(
          {
            label: `${technician == 'default' ? 'default (für alle Techniker)' : (technician.getFullName(30))}`,
            data: labels.map(idx => {
              let found_wrapper = tpf_wrappers.find(wrapper => wrapper.indexIsInBounds(idx));
              return found_wrapper ? found_wrapper.getAdditionalValue(idx) : 0
            }),
            borderColor: color,
            backgroundColor: color,
          }
        )
      }

      let data = {
        labels: labels,
        datasets: datasets
      }

      let scales: _DeepPartialObject<{ [key: string]: ScaleOptionsByType<keyof CartesianScaleTypeRegistry>; }> = {
        y: {
          beginAtZero: true,
          title: {
            text: `Zusätzliche Zeit (%)`,
            display: true,
            align: 'end'
          }
        },
        x: {
          beginAtZero: true,
          title: {
            text: `# Erledigte Aufträge (${this.config?.project_priority?.priority?.name})`,
            display: true,
            align: 'center'
          }
        }
      }

      let tooltip = {
        callbacks: {
          label: (context) => {
            return `Zusätzliche Zeit: ${Math.round(context.parsed.y)}%}`;
          },
          title: (context) => {
            return `Für Auftrag Nummer ${context[0].parsed.x + step_start}:`;
          },
        }
      }

      if (!this.chart) {
        this.chart = new Chart("MyChart", {
          type: 'line',
          data: data,
          options: {
            backgroundColor: 'white',
            aspectRatio: 2,
            responsive: true,
            scales: scales,
            plugins: {
              tooltip: tooltip
            }
          }

        });
      } else {
        this.chart.data = data
        this.chart.options.scales = scales
        this.chart.update()
      }
    }
  }

  priorityHasRule(priority: Priority): boolean {
    return PASettingsControl.Instance.getAdditionalPriorityTimeRules(priority).length > 0
  }

  saveTimePredictionRule() {
    this.preEditValues = null
    this.editTimePredictionRule.saveChanges().then(
      _ => {
        if (this.editTimePredictionRule.isDeletable()) {
          this.saveTimePredictionRuleForPriority()
          this.resetForm()
        }
      }
    )
  }

  deleteRule(rule: TimePredictionRule): void {
    PASettingsControl.Instance.removeAdditionalTimePriorityRule(rule).then(
      _ => {
        if (rule == this.editTimePredictionRule) this.editTimePredictionRule = this.preEditValues = null;
        this.update(true)
      }
    );
  }

  abortRuleChange() {
    this.preEditValues ? this.editTimePredictionRule.updateValues(this.preEditValues) : {}
    this.editTimePredictionRule = this.preEditValues = null;
    this.resetForm()
    this.update()
  }

  resetForm(): void {
    this.timePredictionRuleForm.setValue({
      step_from: 0,
      step_until: 10,
      technician: null,
      function_type: null,
      function_y_value: 50
    });
  }

  protected readonly faTrash = faTrash;
  protected readonly Project = PAProject;
  protected readonly PASettingsControl = PASettingsControl;
}