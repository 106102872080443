import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { PATechnicianDate } from '../../classes/technician-date';
import { faCheck, faWindowClose } from '@fortawesome/free-solid-svg-icons';
import { TravelTechnicianDate } from '../../classes/travel-technician-date';
import { PopupMenuComponent } from "../popup-menu-component/popup-menu.component";
import { MapContainer } from "../../map/pa-map";
import { PACoordinates } from "../../classes/coordinates";
import { UntypedFormControl, UntypedFormGroup, Validators, ReactiveFormsModule } from "@angular/forms";
import { PAProject } from "../../classes/project";
import { PAUtil } from "../../classes/util";
import { PATicket } from "../../classes/ticket";
import { PADataControl } from "../../singletons/pa-data-control";
import { PAFilterControl } from "../../singletons/pa-filter-control";
import { PATourPlannerControl } from "../../singletons/pa-tourplanner-control";
import { MatDialog } from "@angular/material/dialog";
import { DialogComponent } from "./dialog/dialog.component";
import { PASettingsControl } from "../../singletons/pa-settings-control";
import { PATimeControl } from "../../singletons/pa-time-control";
import { GeocoderEvent } from "../../../../_models/mapbox.interface";
import { NgIf, NgClass, NgFor } from '@angular/common';
import { TooltipModule } from '@cloudfactorydk/ng2-tooltip-directive';

import { MatButton } from '@angular/material/button';
import { PopupModalComponent } from '../popup-modal/popup-modal.component';
import { MatGridList, MatGridTile } from '@angular/material/grid-list';
import { MatFormField, MatLabel, MatError } from '@angular/material/form-field';
import { MatSelect } from '@angular/material/select';
import { MatOption } from '@angular/material/core';
import { MatInput } from '@angular/material/input';
import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { CommonModule } from "@angular/common";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatSelectModule } from "@angular/material/select";
import { FaIconComponent, FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import * as mapboxgl from "mapbox-gl";

@Component({
  selector: 'hawk-travel-technician-menu',
  templateUrl: './travel-technician-menu.component.html',
  styleUrls: ['./travel-technician-menu.component.scss', './../../styles/common_styles.scss'],
  standalone: true,
  imports: [NgIf, NgClass, TooltipModule, PopupMenuComponent, FaIconComponent, MatButton, PopupModalComponent, ReactiveFormsModule, MatGridList, MatGridTile, MatFormField, MatLabel, MatSelect, MatOption, NgFor, MatError, MatInput, CdkTextareaAutosize, CommonModule, MatFormFieldModule, MatSelectModule, FontAwesomeModule]
})
export class TravelTechnicianMenuComponent implements OnInit, OnChanges, AfterViewInit {

  @Input() technicianDate: PATechnicianDate
  @Input() travelTechnicianDate: TravelTechnicianDate
  @Input() mapBoxAccessToken: string
  @Input() mapContainer: MapContainer
  @Input() tooltip = 'Reiseziel festlegen'
  @Input() anchor: 'left' | 'bottom' = 'bottom'

  protected readonly faWindowClose = faWindowClose
  protected readonly Project = PAProject;
  protected readonly faCheck = faCheck;

  @ViewChild('TravelTechnicianPopupMenuComponent') popupMenu: PopupMenuComponent;
  @ViewChild('TargetGeocoder') targetGeocoderRef: ElementRef<HTMLDivElement>;
  @ViewChild('TicketFormGeocoder') ticketFormGeocoderRef: ElementRef<HTMLDivElement>;
  
  private targetGeoCoder: MapboxGeocoder;
  private ticketFormGeoCoder: MapboxGeocoder;
  public showGenerateOperationModal: boolean = false

  public waitForDialogAnswer = false

  lastGeocodeSearchEvent: GeocoderEvent

  public ticketForm: UntypedFormGroup = new UntypedFormGroup({
    project_id: new UntypedFormControl({value: null, disabled: true}, Validators.required),
    priority_id: new UntypedFormControl(null, Validators.required),
    status_id: new UntypedFormControl(null, Validators.required),
    description: new UntypedFormControl('', Validators.required),
    contact_name: new UntypedFormControl(null),
    contact_email: new UntypedFormControl(null, Validators.email),
    contact_phone: new UntypedFormControl(null, Validators.pattern('[- +()0-9]+')),
    address_name: new UntypedFormControl({value: ''}, Validators.required),
    address_latitude: new UntypedFormControl({value: ''}, Validators.required),
    address_longitude: new UntypedFormControl({value: ''}, Validators.required),
    address_city: new UntypedFormControl({value: ''}, Validators.required),
    address_country: new UntypedFormControl({value: ''}, Validators.required),
    address_street: new UntypedFormControl({value: ''}, Validators.required),
    address_street_no: new UntypedFormControl({value: ''}),
    address_zip: new UntypedFormControl({value: ''}, [Validators.required, Validators.pattern('^([a-zA-Z]{2}-)?[0-9]{2,5}( [a-zA-Z]{0,2})?$')]),
    address_state: new UntypedFormControl({value: ''}),
  });

  constructor(
    public dialog: MatDialog
  ) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    const td_changes = changes['technicianDate']
    if (td_changes && td_changes.currentValue && !td_changes.currentValue.travelTechnicianDate) {
      td_changes.currentValue.setTravelTechnicianDate()
    }
  }

  getDestination(): string {
    if (this.travelTechnicianDate && this.travelTechnicianDate.isActive() && this.travelTechnicianDate.coordinates) {
      if (this.travelTechnicianDate.coordinates.name) {
        return this.travelTechnicianDate.coordinates.name
      } else {
        let lat = this.travelTechnicianDate.coordinates.latitude.toString().slice(0, 7)
        let lng = this.travelTechnicianDate.coordinates.longitude.toString().slice(0, 7)
        return `${lat};${lng}`
      }
    } else {
      return ''
    }
  }

  async createTravelTechnicianDate() {
    if (this.travelTechnicianDate && !this.travelTechnicianDate.isActive()) {
      await this.travelTechnicianDate.createInDB()
      this.travelTechnicianDate.coordinates = this.technicianDate.tour.startLocation.coordinates

    }
    this.openPopup()
  }

  deleteTravelTechnicianDate() {
    this.popupMenu.close()
    if (this.travelTechnicianDate && this.travelTechnicianDate.isActive()) {
      this.travelTechnicianDate.deleteInDb()
    }
  }

  ngOnInit(): void {

  }

  ngAfterViewInit(): void {
    this.targetGeoCoder =
      new MapboxGeocoder({
        accessToken: this.mapBoxAccessToken,
        marker: false,
        mapboxgl: mapboxgl,
        placeholder: 'Suche Ziel-Adresse...',
        flyTo: {
          bearing: 0,
          speed: 0.8,
          curve: 1,
          zoom: 9,
          easing: function (t) {
            return t;
          }
        },
      })

    this.ticketFormGeoCoder =
      new MapboxGeocoder({
        accessToken: this.mapBoxAccessToken,
        marker: false,
        mapboxgl: mapboxgl,
        placeholder: 'Bitte hier Adresse suchen...'
      })

    this.setOnTargetGeocoderResult()
    this.setOnTicketFormGeocoderResult()

    this.waitForTargetGeocoder().then(_=> {
      this.targetGeocoderRef.nativeElement.appendChild(this.targetGeoCoder.onAdd(this.mapContainer.map));
    })

    this.waitForTicketFormGeocoder().then(_=> {
      this.ticketFormGeocoderRef.nativeElement.appendChild(this.ticketFormGeoCoder.onAdd(this.mapContainer.map));
    })
  }

  async waitForTargetGeocoder(): Promise<void> {
    while (!this.targetGeocoderRef?.nativeElement) {
      await PAUtil.sleep(100)
    }
  }

  async waitForTicketFormGeocoder(): Promise<void> {
    while (!this.ticketFormGeocoderRef?.nativeElement) {
      await PAUtil.sleep(100)
    }
  }

  public setOnTargetGeocoderResult(){
    this.targetGeoCoder.on('result', event => {
      this.onTargetGeocoderResult(event)
    })
  }

  public setOnTicketFormGeocoderResult(){
    this.ticketFormGeoCoder.on('result', event => {
      this.onTicketFormGeocoderResult(event)
    })
  }

  public onTargetGeocoderResult(event: GeocoderEvent){
    let center = event.result.center
    this.travelTechnicianDate.coordinates = new PACoordinates(center[1], center[0], event.result.text)
    this.lastGeocodeSearchEvent = event
  }

  public onTicketFormGeocoderResult(event: GeocoderEvent){
    let {country, region, city, post_code, street, street_no, center, name} = PAUtil.getGeocoderEventAddress(event);

    let address_name_control = this.ticketForm.controls['address_name']
    address_name_control.setValue(name)
    address_name_control.markAsTouched();
    address_name_control.updateValueAndValidity()

    let address_country_control = this.ticketForm.controls['address_country']
    address_country_control.setValue(country)
    address_country_control.markAsTouched();
    address_country_control.updateValueAndValidity()
    let address_state_control = this.ticketForm.controls['address_state']
    address_state_control.setValue(region)
    address_state_control.markAsTouched();
    address_state_control.updateValueAndValidity()
    let address_city_control = this.ticketForm.controls['address_city']
    address_city_control.setValue(city)
    address_city_control.markAsTouched();
    address_city_control.updateValueAndValidity()
    let address_zip_control = this.ticketForm.controls['address_zip']
    address_zip_control.setValue(post_code)
    address_zip_control.markAsTouched();
    address_zip_control.updateValueAndValidity()
    let address_street_control = this.ticketForm.controls['address_street']
    address_street_control.setValue(street)
    address_street_control.markAsTouched();
    address_street_control.updateValueAndValidity()
    let street_no_control = this.ticketForm.controls['address_street_no']
    street_no_control.setValue(street_no)
    street_no_control.markAsTouched();
    street_no_control.updateValueAndValidity()

    let latitude_control = this.ticketForm.controls['address_latitude']
    latitude_control.setValue(center[1])
    latitude_control.markAsTouched();
    latitude_control.updateValueAndValidity()
    let longitude_control = this.ticketForm.controls['address_longitude']
    longitude_control.setValue(center[0])
    longitude_control.markAsTouched();
    longitude_control.updateValueAndValidity()

    this.ticketForm.updateValueAndValidity()
    this.onTargetGeocoderResult(event)
  }

  openPopup() {
    this.targetGeoCoder.clear()
    this.popupMenu.open()
  }

  initializeTicketFormGroup() {
    let default_priority_id = PASettingsControl.Instance.selectedSettingConfig?.data?.travel_technicians?.hotel_overnight_stay?.default_priority_id || null
    this.ticketForm.setValue({
      project_id: 133,
      priority_id: default_priority_id,
      status_id: null,
      description: 'Hotelübernachtung',
      contact_name: null,
      contact_email: null,
      contact_phone: null,
      address_name: this.travelTechnicianDate.coordinates?.name || '',
      address_latitude: this.travelTechnicianDate.coordinates?.latitude || '',
      address_longitude: this.travelTechnicianDate.coordinates?.longitude || '',
      address_city: '',
      address_country: '',
      address_street: '',
      address_street_no: '',
      address_zip: '',
      address_state: '',
    });
    if (this.lastGeocodeSearchEvent) this.onTicketFormGeocoderResult(this.lastGeocodeSearchEvent)
    this.ticketFormGeoCoder.clear()
    this.ticketForm.markAsUntouched()
  }

  async onTicketSubmit(): Promise<void> {

    let ticket = this.createTicket()

    if (!PAFilterControl.Instance.internalProjectIsActivated()) {
      this.openInternalProjectDialog()
      while (this.waitForDialogAnswer) {
        await PAUtil.sleep(100)
      }
    }

    await PAFilterControl.Instance.updateOperationFilter(true)
    PATourPlannerControl.Instance.addOperationsToTourPlanning([ticket.temporaryOperation]);
    PATourPlannerControl.Instance.planningInstructions.addOperationsTechnicianConstraints([ticket.temporaryOperation], [this.technicianDate.technician], [this.technicianDate.day.utc_timestamp])

    // TODO: Standard Hotel Prio speichern, wenn gefordert

    this.showGenerateOperationModal = false
  }

  createTicket(): PATicket {
    if (this.ticketForm.valid) {
      let project = PADataControl.Instance.getProject(this.ticketForm.get('project_id').value)
      let id = PADataControl.Instance.generateNextTemporaryTicketId()
      let ticket = new PATicket(
        id,
        PADataControl.Instance.generateNextLocationId(),
        PADataControl.Instance.generateNextClientId(),
        null,
        null,
        project,
        new PACoordinates(this.ticketForm.get('address_latitude').value, this.ticketForm.get('address_longitude').value),
        0,
        this.ticketForm.get('address_name').value,
        this.ticketForm.get('address_street').value,
        this.ticketForm.get('address_street_no').value,
        this.ticketForm.get('address_zip').value,
        this.ticketForm.get('address_city').value,
        this.ticketForm.get('address_country').value,
        [],
        {},
        null,
        this.ticketForm.get('contact_name').value,
        this.ticketForm.get('contact_email').value,
        this.ticketForm.get('contact_phone').value,
        PADataControl.Instance.getPriority(this.ticketForm.get('priority_id').value),
        project.getStatus(this.ticketForm.get('status_id').value),
        [],
        this.ticketForm.get('description').value
      )
      ticket.store = null
      ticket.generateTemporaryTicketOperation()

      return ticket
    }
  }

  openInternalProjectDialog() {
    this.waitForDialogAnswer = true
    const dialogRef = this.dialog.open(DialogComponent,{
      data:{
        message: "Das Projekt '[Intern] - Bentomax' wird durch die aktuellen Filtereigenschaften ausgeschlossen. Möchten Sie das Projekt aktivieren? (Ansonsten wird der erstellte interne Auftrag nicht angezeigt)",
        buttonText: {
          ok: 'Ja',
          cancel: 'Nein'
        }
      }
    });

    dialogRef.afterClosed().subscribe(async (confirmed: boolean) => {
      if (confirmed) {
        await PAFilterControl.Instance.toggleProjectSelection(PADataControl.Instance.getProject(133))
      }
      this.waitForDialogAnswer = false
    });
  }

  protected readonly PADataControl = PADataControl;
  protected readonly PAUtil = PAUtil;
  protected readonly PATimeControl = PATimeControl;
}