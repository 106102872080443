<div class="opening_container">
  <div class="opening_day_selection_container">
    <div *ngFor="let opening_template of openingTimeTemplates"
         class="opening_day_selection black_gradient_background"
         [ngClass]="opening_template == selectedOpeningTimeTemplate ? 'selected' : ''"
         (click)="selectedOpeningTimeTemplate = opening_template"
         style="position: relative; user-select: none;">
      <div style="position: absolute; top: 4px; right: 4px; cursor: pointer;" (click)="removeOpeningTimeTemplate(opening_template, $event)">
        <fa-icon [icon]="faTrash"></fa-icon>
      </div>
      <div style="margin: auto; text-align: center;">
        {{Util.openingTimeTemplateDayRangeString(opening_template)}}
        <br>
        {{opening_template.open}} - {{opening_template.close}}
      </div>
    </div>
    <div *ngIf="newOpeningTemplate && !allOpeningTimeDaysUsed()"
         class="opening_day_selection black_gradient_background"
         [ngClass]="newOpeningTemplate == selectedOpeningTimeTemplate ? 'selected' : ''"
         (click)="selectNewOpeningTemplate()">
      <div style="margin: auto; text-align: center;">
        Öffnungszeiten <br>
        hinzufügen
      </div>
    </div>
  </div>
  <div *ngIf="selectedOpeningTimeTemplate" style="background-color: white;">
    <div style="margin-top: 0.5rem; width: 100%; display: inline-flex; flex-direction: row;">
      <ng-container *ngFor="let day_idx of [0,1,2,3,4,5,6]">
        <mat-checkbox style="margin: 0 12px; flex: 1;" class="black-label"
                      [checked]="selectedOpeningTimeTemplate.day_idxs.includes(day_idx)"
                      [disabled]="openingDayIdxIsUsedByOtherOpeningTemplate(day_idx, selectedOpeningTimeTemplate) || isOnlySelectedDayIdxInOpeningDayTemplate(day_idx, selectedOpeningTimeTemplate)"
                      (change)="toggleOpeningTimeDayTemplateDayIdx(selectedOpeningTimeTemplate, day_idx)">
          {{PATimeControl.Instance.weekDayToGermanName(PATimeControl.Instance.weekDays[day_idx], true)}}
        </mat-checkbox>
      </ng-container>
    </div>
    <div class="opening_time_container">
      <div style="flex: 1; display: inline-flex; flex-direction: row; margin-top: auto; margin-bottom: auto; padding: 0 .5rem;">
        <div style="margin: auto 4px auto auto;">
          Von:
        </div>
        <input type="time" [(ngModel)]="selectedOpeningTimeTemplate.open" [ngModelOptions]="{standalone: true}" style="width: fit-content; height: 25px; cursor: pointer; font-size: 1rem; margin: auto .5rem auto 4px;">
        <div style="margin: auto 4px auto auto;">
          Bis:
        </div>
        <input type="time" [(ngModel)]="selectedOpeningTimeTemplate.close" [ngModelOptions]="{standalone: true}" style="width: fit-content; height: 25px; cursor: pointer; font-size: 1rem; margin: auto auto auto 4px;">
      </div>
      <div style="display: inline-flex; flex-direction: row; flex: 1; margin-top: auto; margin-bottom: auto; padding: 0 .5rem;">
        <ng-container *ngIf="!openingTimeTemplates.includes(selectedOpeningTimeTemplate)">
          <button style="margin: auto 4px auto auto;"
                  type='button'
                  (click)="saveOpeningTimeTemplate(selectedOpeningTimeTemplate)"
                  [disabled]="!selectedOpeningTimeTemplate.day_idxs.length">hinzufügen</button>
          <button style="margin: auto auto auto 4px;" type='button' (click)="selectedOpeningTimeTemplate = null">abbrechen</button>
        </ng-container>
      </div>
    </div>
  </div>
</div>
