<header class="row bg-white mb-3 py-2">
    <h2 class="col-sm-2" style="margin-bottom: 0;">Filter</h2>

    <div class="col-sm-4">
        <label style="font-size: 12px; text-transform: uppercase;">Kalenderwoche:</label>
        <ng-multiselect-dropdown
        [placeholder]="'Auswählen'"
        [data]="weekNumbers"
        [(ngModel)]="selectedWeekNumbers"
        [settings]="weekNumberSelectionSettings"
        (onSelect)="loadStoreData()"
        (onSelect)="loadStoreData()"
        >
        </ng-multiselect-dropdown>
    </div>

    <div class="col-sm-6">
        <label style="font-size: 12px; text-transform: uppercase;">Techniker:</label>
        <ng-multiselect-dropdown
        [placeholder]="'Auswählen'"
        [data]="technicians"
        [(ngModel)]="selectedTechnicians"
        [settings]="technicianSelectionSettings"
        (onSelect)="onSelectTechnician()"
        (onDeSelect)="onSelectTechnician()"
        style="display: block;"
        >
        </ng-multiselect-dropdown>
    </div>
</header>

<div class="row">
    <div class="col-md">
        <mgl-map [style]="'mapbox://styles/mapbox/streets-v9'" [zoom]="plannedSelected[0] ? [12] : [6]" [center]="plannedSelected[0] ? [plannedSelected[0].store_lng, plannedSelected[0].store_lat] : [9.993682, 53.551086]">
            <div *ngFor="let row of filteredPlannedAssignments.concat(filteredUnplannedAssignments)">
                <mgl-marker *ngIf="row.store_lat && row.store_lng" [lngLat]="[row.store_lng, row.store_lat]">
                    <div (click)="handleMarkerClick(row)" class="marker" [style.borderColor]="plannedSelected[0] && plannedSelected[0].store_lat === row.store_lat && plannedSelected[0].store_lng === row.store_lng ? 'black' : 'white'" [style.backgroundColor]="row.status_color" title="{{row.store_name}}"></div>
                </mgl-marker>
            </div>
        </mgl-map>
    </div>

    <div class="col-md mt-5-sm mt-0-md">
        <h5>Geplante Einsätze</h5>
        <ngx-datatable
            class="bg-white rounded shadow-sm p2"
            [rows]="filteredPlannedAssignments"
            [columns]="columns"
            columnMode="force"
            [scrollbarV]="true"
            [headerHeight]="20"
            [footerHeight]="0"
            [rowHeight]="35"
            [selected]="plannedSelected"
            [selectionType]="selectionType"
            (activate)="onActivate($event)"
            (select)="onSelect($event)">
        </ngx-datatable>
    </div>
</div>

<div class="">
    <div class="row mb-3 mt-5">
        <div class="col-sm-6 col-md-4"><h5>Ungeplante Einsätze</h5></div>
        <div class="col-sm-6 col-md-8 text-right">
            <button class="btn btn-outline-danger mr-3" (click)="destroyAll()">Alle Löschen</button>
            <a class="btn btn-outline-success" [href]="env.apiURL + 'operations/assignment_planning_unplanned.xlsx'">Export (excel)</a>
        </div>
    </div>
    <ngx-datatable
        class="bg-white rounded shadow-sm p2"
        [rows]="filteredUnplannedAssignments"
        [columns]="columnsTechnicians"
        columnMode="force"
        [scrollbarV]="true"
        [headerHeight]="20"
        [footerHeight]="0"
        [rowHeight]="35">
    </ngx-datatable>
</div>

<div class="my-5">
    <h5>Ungeplante Einsätze importieren</h5>
    <form [formGroup]="form" (ngSubmit)="onSubmit()">
        <input type="file" name="importfile" (change)="onFileChange($event)" />
        <button class="btn btn-secondary" type="submit">Upload</button>
    </form>
</div>

<ng-template #statusTemplate let-row="row" let-value="value" let-i="index">
    <strong> **{{ row.status }}** </strong>
</ng-template>

<ng-template #statusTpl let-row="row" let-value="value" let-column="column">
    <div class="flex items-center justify-center h-full w-full">
        <div class="badge" [style.backgroundColor]="row.status_color" title={{value}}>{{value.split(" ")[0]}}</div>
    </div>
</ng-template>

<ng-template #dateTpl let-row="row" let-value="value" let-column="column">
    <div class="">
        {{formatDate(value)}}
    </div>
</ng-template>

<ng-template #weekTpl let-row="row" let-value="value" let-column="column">
    <div class="">
        {{calendarWeek(value)}}
    </div>
</ng-template>