<span class="inline">
  <input type="text" [(ngModel)]="value" [maxlength]="maxInputLength" [ngClass]="upperCaseMode ? 'upper_case' : ''" (keydown.enter)="save()" (keydown.esc)="cancel()" *ngIf="modus=='text'">
  <input type="number" [(ngModel)]="value" [maxlength]="maxInputLength" (keydown.enter)="save()" (keydown.esc)="cancel()" *ngIf="modus=='number'">

  <textarea [(ngModel)]="value" [maxlength]="maxInputLength" [ngClass]="upperCaseMode ? 'upper_case' : ''" (keydown.esc)="cancel()" *ngIf="modus=='textarea'">
    Text
  </textarea>

  <input
    type="text"
    mwlFlatpickr
    [(ngModel)]="value"
    [locale]="'de'"
    [options]="datePickerConfig"
    [convertModelValue]="true"
    *ngIf="modus=='date'"
    style="width: 150px;">

  <select class="selectbox" [(ngModel)]="value" *ngIf="modus=='select'">
    <option [value]="option.id" *ngFor="let option of selectOptions">
      {{option.name}}
    </option>
  </select>

  <mat-select style="border: 1px solid lightgray; font-size: 12px; padding: 3px;" class="selectbox" [(ngModel)]="value" *ngIf="modus=='multiselect'" multiple>
    <mat-option [value]="option.id" *ngFor="let option of selectOptions">
      {{option.name}}
    </mat-option>
  </mat-select>

  <input
  type="checkbox"
  [(ngModel)]="value" (keydown.esc)="cancel()"
  *ngIf="modus=='checkbox'"
  >
  <input type="number"
  [(ngModel)]="value" (keydown.esc)="cancel()"
  *ngIf="modus=='onlyNumber'">
  <button (click)="save()"><fa-icon [icon]="['far','save']"></fa-icon></button>
  <button (click)="cancel()"><fa-icon [icon]="['far','times']"></fa-icon></button>
</span>
