<div class="d-flex align-items-center">
    <div class="col t-header d-flex align-items-center justify-content-start">
        <h4 class="mb20 d-flex align-items-center gap-1">
            <i class="fa fa-cube"></i>
            <ul class="list-group list-group-horizontal b-custom">
                <li class="list-group-item bg-transparent border-0">
                    <a
                    class="g-link"
                    [routerLink]="['/', 'artikel', 'suche', 'index']"
                    >
                    Artikel
                </a>
            </li>
            <li class="list-group-item bg-transparent border-0">
                <span>Wareneingang</span>
            </li>
            </ul>
        </h4>
    </div>
</div>
<form [formGroup]="articleForm" (submit)="createOrUpdateArticle()">
    <div class="ticketPage">
        <section class="main-content">
            <header class="columns">
                <section>
                    <div class="form-group col-6">
                        <mat-label>Typ *</mat-label>
                        <ng-select
                        [items]="templates"
                        bindLabel="description"
                        bindValue="id"
                        formControlName="template_id"
                        [loading]="loadingTemplates"
                        [virtualScroll]="true"
                        [required]="true"
                        >
                        <ng-template ng-label-tmp let-item="item">
                            {{ item.description }}
                        </ng-template>
                        <ng-template
                            ng-option-tmp
                            let-item="item"
                            let-search="searchTermStatus"
                        >
                            <div
                            *ngIf="
                                item.description
                                .toLowerCase()
                                .includes(searchTerm.toLowerCase())
                            "
                            >
                            {{ item.description }}
                            </div>
                        </ng-template>
                        </ng-select>
                    </div>
                    <mat-hint>
                        <p class="help-block m-0">Mit Tippen beginnen, um Vorschläge anzeigen zu lassen. Es sind nur Typen aus der Liste gültig.</p>
                    </mat-hint>
                    <div class="form-group">
                        <mat-form-field appearance="outline">
                            <mat-label>Seriennummer</mat-label>
                            <input
                                matInput
                                type="text"
                                placeholder="Seriennummer"
                                formControlName="serial_number"
                            />
                        </mat-form-field>
                    </div>
                    <div class="form-group col-6">
                        <mat-label>Status</mat-label>
                        <ng-select
                        [items]="statusesList"
                        formControlName="status"
                        placeholder="Status"
                        [clearable]="false"
                        >
                        <ng-template ng-label-tmp let-item="item">
                            {{ item }}
                        </ng-template>
                        <ng-template
                            ng-option-tmp
                            let-item="item"
                            let-search="searchTerm"
                        >
                            <div
                            *ngIf="
                                item.toLowerCase()
                                .includes(searchTerm.toLowerCase())
                            "
                            >
                            {{ item }}
                            </div>
                        </ng-template>
                        </ng-select>
                    </div>
                    <div class="form-group col-6 mt-1">
                        <mat-label>Besitzer</mat-label>
                        <div class="form-check">
                            <input class="form-check-input" type="radio" id="radioValueTrue" value="BENTOMAX" formControlName="owner">
                            <label class="form-check-label" for="radioValueTrue">Bentomax GmbH</label>
                        </div>
                        <div class="form-check">
                            <input class="form-check-input" type="radio" id="radioValueFalse" value="CUSTOMER" formControlName="owner">
                            <label class="form-check-label" for="radioValueFalse">Kunde:</label>
                        </div>
                    </div>
                    <div class="form-group col-6">
                        <ng-select
                        [items]="customers"
                        bindLabel="name"
                        bindValue="id"
                        formControlName="owner_customer_id"
                        placeholder="Kunde"
                        [loading]="loadingCustomers"
                        [virtualScroll]="true"
                        (scrollToEnd)="fetchMoreCustomers()"
                        >
                        <ng-template ng-label-tmp let-item="item">
                            {{ item.name }}
                        </ng-template>
                        <ng-template
                            ng-option-tmp
                            let-item="item"
                            let-search="searchTermStatus"
                        >
                            <div
                            *ngIf="
                                item.name
                                .toLowerCase()
                                .includes(searchTerm.toLowerCase())
                            "
                            >
                            {{ item.name }}
                            </div>
                        </ng-template>
                        </ng-select>
                    </div>
                    <div class="form-group col-6 mt-2">
                        <mat-label>Lager *</mat-label>
                        <ng-select
                        [items]="warehouses"
                        bindLabel="name"
                        bindValue="id"
                        formControlName="warehouse_id"
                        placeholder="Lager"
                        [loading]="loadingWarehouses"
                        [virtualScroll]="true"
                        >
                        <ng-template ng-label-tmp let-item="item">
                            {{ item.label }}
                        </ng-template>
                        <ng-template
                            ng-option-tmp
                            let-item="item"
                            let-search="searchTermStatus"
                        >
                            <div
                            *ngIf="
                                item.label
                                .toLowerCase()
                                .includes(searchTerm.toLowerCase())
                            "
                            >
                            {{ item.label }}
                            </div>
                        </ng-template>
                        </ng-select>
                    </div>
                    <div class="form-group col-6 mt-2">
                        <mat-label>Platz *</mat-label>
                        <ng-select
                        [items]="bins"
                        bindLabel="label"
                        bindValue="id"
                        formControlName="bin_id"
                        placeholder="Platz"
                        [loading]="loadingBins"
                        [virtualScroll]="true"
                        (scrollToEnd)="fetchMoreBins()"
                        >
                        <ng-template ng-label-tmp let-item="item">
                            {{ item.label }}
                        </ng-template>
                        <ng-template
                            ng-option-tmp
                            let-item="item"
                            let-search="searchTermStatus"
                        >
                            <div
                            *ngIf="
                                item.label
                                .toLowerCase()
                                .includes(searchTerm.toLowerCase())
                            "
                            >
                            {{ item.label }}
                            </div>
                        </ng-template>
                        </ng-select>
                    </div>
                    <div class="form-group">
                        <mat-checkbox formControlName="is_full">Platz anschließend als voll markieren</mat-checkbox>
                    </div>
                </section>
            </header>
            <div class="d-flex col-auto my-2 align-items-center justify-content-between">
                <span class="d-flex col-auto gap-2 align-items-center" *ngIf="formLoaded">
                    <button mat-raised-button  [disabled]="articleForm.invalid || submittingArticle" class="button" type="submit" >
                        Speichern
                    </button>
                </span>
                <a class="text-right" [routerLink]="['/', 'artikel', 'suche', 'index']">Abbrechen</a>
            </div>
        </section>
        <aside>
            <section>
                <div class="form-group col-6 mt-1">
                    <div class="form-check">
                        <input class="form-check-input" type="radio" id="radioValueSupplierSupplier" value="supplier" formControlName="supplier">
                        <label class="form-check-label" for="radioValueSupplierSupplier">Auswahl Lieferant</label>
                        <ng-select
                        [items]="suppliers"
                        bindLabel="name"
                        bindValue="id"
                        formControlName="supplier_id"
                        placeholder="Supplier"
                        [loading]="loadingSuppliers"
                        [virtualScroll]="true"
                        (scrollToEnd)="fetchMoreSuppliers()"
                        >
                        <ng-template ng-label-tmp let-item="item">
                            {{ item.name }}
                        </ng-template>
                        <ng-template
                            ng-option-tmp
                            let-item="item"
                            let-search="searchTermStatus"
                        >
                            <div
                            *ngIf="
                                item.name
                                .toLowerCase()
                                .includes(searchTerm.toLowerCase())
                            "
                            >
                            {{ item.name }}
                            </div>
                        </ng-template>
                        </ng-select>
                    </div>
                    <div class="form-check">
                        <input class="form-check-input" type="radio" id="radioValueSupplierOther" value="other" formControlName="supplier">
                        <label class="form-check-label" for="radioValueSupplierOther">Anderer</label>
                        <div class="form-group">
                            <mat-form-field appearance="outline">
                                <mat-label>Anderer</mat-label>
                                <input
                                    matInput
                                    type="text"
                                    placeholder="Anderer"
                                    formControlName="supplier_other"
                                />
                            </mat-form-field>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <mat-form-field appearance="outline">
                        <mat-label>Lieferscheinnummer (extern)</mat-label>
                        <input
                            matInput
                            type="text"
                            placeholder="Breite"
                            formControlName="ext_delivery_note_number"
                        />
                    </mat-form-field>
                </div>
                <div class="form-group">
                    <mat-form-field appearance="outline">
                        <mat-label>Kaufdatum</mat-label>
                        <input
                            matInput
                            [matDatepicker]="purchase_date"
                            formControlName="purchase_date"
                        />
                        <mat-datepicker-toggle matIconSuffix [for]="purchase_date"></mat-datepicker-toggle>
                        <mat-datepicker #purchase_date></mat-datepicker>
                    </mat-form-field>
                </div>
                <div class="form-group">
                    <mat-form-field appearance="outline">
                        <mat-label>Lieferdatum</mat-label>
                        <input
                            matInput
                            [matDatepicker]="delivery_date"
                            formControlName="delivery_date"
                        />
                        <mat-datepicker-toggle matIconSuffix [for]="delivery_date"></mat-datepicker-toggle>
                        <mat-datepicker #delivery_date></mat-datepicker>
                    </mat-form-field>
                </div>
            </section>
        </aside>
    </div>
</form>