import { PopoutWindowComponent } from "./reuseable-components/popout-window/popout-window.component";
import { PAFilterControl } from "./singletons/pa-filter-control";
import { PATourPlannerControl } from "./singletons/pa-tourplanner-control";
import { PASettingsControl } from "./singletons/pa-settings-control";
import { PADataControl } from "./singletons/pa-data-control";
import { PATimeControl } from "./singletons/pa-time-control";
import { PAStoreControl } from "./singletons/pa-store-control";
import { PAMapControl } from "./singletons/pa-map-control";
import { PlanningAssistantSettingsService } from "./services/planning-assistant-settings.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { NgIf, NgClass, NgFor } from "@angular/common";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { MatButtonToggleGroup, MatButtonToggle, MatButtonToggleModule } from "@angular/material/button-toggle";
import { PopupModalComponent } from "./reuseable-components/popup-modal/popup-modal.component";
import { PaSettingsComponent } from "./pa-settings/pa-settings.component";
import { TourplannerMenuComponent } from "./tourplanner-menu/tourplanner-menu.component";
import { OptiwaeConnectorComponent } from "./optiwae-connector/optiwae-connector.component";
import { SaveMenuComponent } from "./save-menu/save-menu.component";
import { ExpandBarHeaderComponent } from "./reuseable-components/expand-bar-header/expand-bar-header.component";
import { TooltipModule } from "@cloudfactorydk/ng2-tooltip-directive";
import { IconExpandBarComponent } from "./reuseable-components/icon-expand-bar/icon-expand-bar.component";
import { StoreOperationsComponent } from "./reuseable-components/store-operations/store-operations.component";
import { MapComponent } from "./map/map.component";
import { TourComparisonComponent } from "./tour-comparison/tour-comparison.component";
import { LoadingContainerComponent } from "./reuseable-components/loading-container/loading-container.component";
import { CalendarComponent } from "./calendar/calendar.component";
import { InsufficientDataPopupComponent } from "./insufficient-data-popup/insufficient-data-popup.component";
import { CommonModule } from "@angular/common";
import { MatButtonModule } from "@angular/material/button";
import { FaIconComponent, FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { Component, ElementRef, inject, OnInit, ViewChild } from "@angular/core"
import { ActivatedRoute } from '@angular/router'
import {
  ArticleService,
  OperationService,
  ProjectService, StoreService,
  TicketNoteService,
  TicketService,
  UserService
} from 'src/app/_services'
import {
  PlanningAssistantOperation as PAOperationHash,
} from "src/app/_models/planning-assistant.interface"

import { PlanningAssistantService } from "src/app/_services/planning-assistant.service"
import { TravelTechnicianDateService } from "src/app/_services/travel_technician_date.service"
import screenfull from "screenfull"
import {
  faBookOpen,
  faCalendar,
  faCheck, faCog,
  faCompress,
  faExpand,
  faExternalLinkAlt,
  faTrash,
  faWindowClose
} from "@fortawesome/free-solid-svg-icons"
import { PATechnician } from "./classes/technician"
import { PAUtil, WeekDay } from "./classes/util"
import { PAProject } from "./classes/project"
import { PAOperation } from "./classes/operation"
import { PAStore } from "./classes/store"
import { PATechnicianDate } from "./classes/technician-date"
import { PATicket } from "./classes/ticket"
import { FilterComponent } from "./filter/filter.component"
import { PALocation } from "./classes/location"
import { MapBoxService } from "src/app/_services/mapbox.service"

@Component({
  selector: 'hawk-planning-assistant',
  templateUrl: './planning-assistant.component.html',
  styleUrls: ['./planning-assistant.component.scss', './styles/common_styles.scss'],
  host: {
    '(document:click)': 'onClick($event)',
  },
  standalone: true,
  imports: [NgIf, NgClass, ReactiveFormsModule, FormsModule, MatButtonToggleGroup, MatButtonToggle, NgFor, PopupModalComponent, PaSettingsComponent, PopoutWindowComponent, TourplannerMenuComponent, OptiwaeConnectorComponent, SaveMenuComponent, ExpandBarHeaderComponent, TooltipModule, IconExpandBarComponent, StoreOperationsComponent, FaIconComponent, MapComponent, TourComparisonComponent, FilterComponent, LoadingContainerComponent, CalendarComponent, InsufficientDataPopupComponent, CommonModule, MatButtonModule, FontAwesomeModule, MatButtonToggleModule]
})

export class PlanningAssistantComponent implements OnInit {

  @ViewChild('filterComponent') filterComponent: FilterComponent;
  @ViewChild('filterComponent', {read: ElementRef}) filterComponentEref: ElementRef;

  // font awesome
  faTrash = faTrash
  faExpand = faExpand
  faCompress = faCompress
  faCheck = faCheck
  faCalendar = faCalendar
  faExternalLinkAlt = faExternalLinkAlt
  faWindowClose = faWindowClose

  public Operation = PAOperation
  public Ticket = PATicket
  public Project = PAProject
  public Util = PAUtil
  public Technician = PATechnician
  public Store = PAStore
  public PlanningAssistantComponent = PlanningAssistantComponent
  public FilterComponent = FilterComponent

  // Ticket Data
  public paramTicketId: string
  public paramPlanTechnicianId: string
  public autoSelectTicketIds: number[]
  public routeTicketOperations: PAOperationHash[]
  public routeTicket: PATicket
  public routeOperations: PAOperation[]

  // Dates
  static fromDate: string
  static untilDate: string

  // Operation Time
  static work_window_start: number = 6

  // UI
  public screenfull = screenfull
  public viewFullscreen: boolean = false

  public selectedPlanningTab = 'manual'

  //load data selection
  public showLoadingScreen = false
  public loadDataConfig = ''
  public selectedProjectsToLoad: PAProject[] = []
  public projectNameFilter = ''
  projectsLoaded: boolean = false
  serviceProjectsToggleGroup: string = ''

  public showStoreOperations: boolean = false

  public snackBar = inject(MatSnackBar);

  async ngOnInit(): Promise<void> {
    this.paramTicketId = this.route.snapshot.queryParamMap.get('ticket_id')
    this.paramPlanTechnicianId = this.route.snapshot.queryParamMap.get('plan_technician_id')

    await this.downloadBentomaxCalendar()

    const auto_select = this.route.snapshot.queryParamMap.getAll('auto_select')
    this.autoSelectTicketIds = auto_select ? auto_select.map(s => Number.parseInt(s)) : []
    this.showLoadingScreen = !this.paramTicketId && !this.paramPlanTechnicianId
    this.setInstanceServices()
    await this.loadProjects()
    PADataControl.Instance.loadArticleTemplates()
    PATourPlannerControl.Instance.snackBar = this.snackBar

    await PASettingsControl.Instance.loadSettingsFromDatabase()

    if (this.paramPlanTechnicianId) {
      const id = Number.parseInt(this.paramPlanTechnicianId)

      const technician_date = PADataControl.Instance.getTechnician(id).getTechnicianDate(PATimeControl.Instance.dateToTimestamp(new Date(), true, true), true, true)
      while (technician_date.loadingStatus != 'loaded') {
        await PAUtil.sleep(100)
      }
      await technician_date.tour.updateRoute(true)
      PATourPlannerControl.Instance.planTechnicianDate(technician_date)

      await this.loadData('all_except_internal_project')
    } else if (this.paramTicketId) {
      await this.loadPlanningInstanceFromRoute()
      this.showLoadingScreen = !(this.routeTicket || this.routeOperations?.length)
      if (!this.showLoadingScreen) {
        PASettingsControl.Instance.projectMode = 'service'
        await this.loadData('service_projects')
      }
    }
  }

  onClick(event) {
    if (!this.filterComponentEref.nativeElement.contains(event.target)) {
      this.filterComponent.closeAllFilters()
    }
  }

  async loadPlanningInstanceFromRoute(): Promise<void> {
    await new Promise<void>(resolve => {
      this.planningAssistantService.getTicketOperations(Number.parseInt(this.paramTicketId)).subscribe(
        data => {
          this.routeTicketOperations = data
          resolve()
        },
        err => {
          console.log(err)
          resolve()
        }
      )
    });
    if (this.routeTicketOperations) {
      if (this.routeTicketOperations.length) {
        let unplanned_operations = this.routeTicketOperations.filter(operation => PADataControl.Instance.unassignedOperationUserIds.includes(operation.user_ids[0]))
        if (unplanned_operations.length) {
          // case: ticket has unplanned operations -> set first unplanned operation to plan
          this.routeOperations = unplanned_operations.map(hash => PADataControl.Instance.operationHashToOperation(hash))
          PADataControl.Instance.addOperationsToLoadedOperations(this.routeOperations)

          let loading_coordinates = this.routeOperations[0].ticket.location.coordinates
          PADataControl.Instance.loadingCoordinates = {
            lat: loading_coordinates.latitude,
            lng: loading_coordinates.longitude
          }
        }
      } else {
        // case: ticket doesn't have any operations -> load and set ticket to plan
        await new Promise<void>(resolve => {
          this.planningAssistantService.getPlanningAssistantTicket(this.paramTicketId).subscribe(
            async data => {
              this.routeTicket = PADataControl.Instance.ticketHashToTicket(data, PADataControl.Instance.generateNextLocationId(), PADataControl.Instance.generateNextClientId())
              let loading_coordinates = this.routeTicket.location.coordinates
              PADataControl.Instance.loadingCoordinates = {
                lat: loading_coordinates.latitude,
                lng: loading_coordinates.longitude
              }
              resolve()
            },
            err => {
              console.log(err)
              resolve()
            }
          );
        })
      }
    }
  }

  constructor(
    private route: ActivatedRoute,
    private ticketService: TicketService,
    private ticketNoteService: TicketNoteService,
    private articleService: ArticleService,
    private userService: UserService,
    private operationService: OperationService,
    private projectService: ProjectService,
    private planningAssistantService: PlanningAssistantService,
    private travelTechnicianDateService: TravelTechnicianDateService,
    public mapBoxLoadingService: MapBoxService,
    public settingsService: PlanningAssistantSettingsService,
    public storeService: StoreService
  ) {
    PALocation.mapBoxService = this.mapBoxLoadingService
  };

  async ngAfterViewInit(): Promise<void> {
    setInterval(() => {
      if (this.viewFullscreen && !this.screenfull.isFullscreen) {
        this.disableFullscreen()
      }
    }, 100);
  }

  async loadProjects() {
    await PADataControl.Instance.loadActiveProjects()
    await PADataControl.Instance.loadAllActiveTechnicians()
    await PADataControl.Instance.loadActivePriorityRules()

    PAFilterControl.Instance.updateShownProjects()
    this.projectsLoaded = true
  }

  setInstanceServices(): void {
    PAOperation.setPlanningAssistantService(this.planningAssistantService)
    PAOperation.setTicketService(this.ticketService)
    PAOperation.setOperationService(this.operationService)
    PAProject.setProjectService(this.projectService)
    PAProject.setPlanningAssistantService(this.planningAssistantService)
    PATechnician.setPlanningAssistantService(this.planningAssistantService)
    PATechnician.setUserService(this.userService)
    PATechnicianDate.setTravelTechnicianDateService(this.travelTechnicianDateService)
    PATicket.setTicketService(this.ticketService)
    PATicket.setTicketNoteService(this.ticketNoteService)
    PATicket.setArticleService(this.articleService)

    PADataControl.Instance.articleService = this.articleService
    PADataControl.Instance.planningAssistantService = this.planningAssistantService
    PADataControl.Instance.storeService = this.storeService
    PAFilterControl.Instance.userService = this.userService
    PAMapControl.Instance.mapBoxService = this.mapBoxLoadingService
    PASettingsControl.Instance.settingsService = this.settingsService
  }

  toggleFullscreen(): void {
    if (!this.screenfull.isFullscreen) {
      if (screenfull.isEnabled) {
        this.enableFullscreen()
      }
    } else {
      if (screenfull.isEnabled) {
        this.disableFullscreen()
      }
    }
  }

  enableFullscreen(): void {
    screenfull.request();
    this.viewFullscreen = true
  }

  disableFullscreen(): void {
    screenfull.exit();
    this.viewFullscreen = false
  }

  public updateWeekdaysInCalendar(weekdays: WeekDay[]): void {
    if (weekdays.length) {
      PAFilterControl.Instance.filteredWeekDays = weekdays
    }
  }

  async downloadBentomaxCalendar() {
    PADataControl.Instance.absences = await this.userService.downloadUserAbsenceCalendar() || []
  }

  toggleProjectToLoad(project: PAProject): void {
    if (!this.selectedProjectsToLoad.includes(project)) {
      this.selectedProjectsToLoad.push(project)
    } else {
      PAUtil.removeElementFromList(this.selectedProjectsToLoad, project)
    }
  }

  projectLoadingConfigSelected(): boolean {
    return (this.loadDataConfig == 'projects' && this.selectedProjectsToLoad.length > 0)
  }

  async loadData(load_data_config: string): Promise<void> {

    this.showLoadingScreen = false

    while (!this.projectsLoaded) {
      await PAUtil.sleep(100)
    }

    let projects: PAProject[]
    switch (load_data_config) {
      case 'fast': {
        projects = []
        break
      }
      case 'projects': {
        projects = [...this.selectedProjectsToLoad]
        break
      }
      case 'all_except_internal_project': {
        projects = PADataControl.Instance.loadedProjects.filter(p => p.id != 133)
        break
      }
      case 'service_projects': {
        projects = PADataControl.Instance.loadedProjects.filter(project => project.isServiceProject())
        break
      }
      default: {
        projects = [...PADataControl.Instance.loadedProjects]
        break
      }
    }

    PAProject.selectedProjects = [...projects]
    this.handleRouteOperationsAndTickets();

    this.loadProjectData(projects)
  }

  private handleRouteOperationsAndTickets() {
    if (this.routeOperations?.length) {
      this.routeOperations.map(op => PAStore.insertOperation(op))
      PAFilterControl.Instance.updateUnassignedOperations()
      PATourPlannerControl.Instance.tourPlannerPlanningMode = 'operation'
      PATourPlannerControl.Instance.operationToPlanID = this.routeOperations[0].id
    }

    if (this.routeTicket) {
      this.routeTicket.generateTemporaryTicketOperation()
      PAFilterControl.Instance.updateFilteredUnplannedOperations()
      PATourPlannerControl.Instance.tourPlannerPlanningMode = 'operation'
      PATourPlannerControl.Instance.operationToPlanID = this.routeTicket.temporaryOperation.id
    }
  }

  async loadProjectData(projects: PAProject[]): Promise<void> {
    if (projects.length) {
      for (let project of projects) {
        Promise.all(
          [
            project.loadUnplannedOperationsForProject(),
            project.loadUnplannedTicketsForProject()
          ]
        ).then(
          _ => {
            const add_operations = PADataControl.Instance.loadedOperations.filter(op => this.autoSelectTicketIds.includes(op.ticket.id) && op.ticket.project.id == project.id)
            if (add_operations.length) {
              PATourPlannerControl.Instance.addOperationsToTourPlanning(add_operations)
            }
          }
        )
      }
    }
  }

  addPreferredTechnician(technician: PATechnician): void {
    if (!PAFilterControl.Instance.preferredCalendarTechnicians.includes(technician)) {
      PAFilterControl.Instance.preferredCalendarTechnicians = PAFilterControl.Instance.preferredCalendarTechnicians.concat([technician])
      technician.fireUpdateManually()
    }
  }

  removePreferredTechnician(technician: PATechnician): void {
    if (PAFilterControl.Instance.preferredCalendarTechnicians.includes(technician)) {
      PAFilterControl.Instance.preferredCalendarTechnicians = PAFilterControl.Instance.preferredCalendarTechnicians.filter(preferred_technician => preferred_technician != technician)
      technician.fireUpdateManually()
    }
  }

  updatePreferredTechnicians(technicians: PATechnician[]): void {
    let preferred_technicians_before = PAFilterControl.Instance.preferredCalendarTechnicians
    PAFilterControl.Instance.preferredCalendarTechnicians = [...technicians];
    [...new Set(preferred_technicians_before.concat(technicians))].map(technician => technician.fireUpdateManually())
  }

  popOut(window: PopoutWindowComponent): void {
    window.open()
  }

  selectAllServiceProjects(): void {
    this.selectedProjectsToLoad = PADataControl.Instance.loadedProjects.filter(project => project.isServiceProject())
  }

  selectAllNonServiceProjects(): void {
    this.selectedProjectsToLoad = PADataControl.Instance.loadedProjects.filter(project => project.isNotServiceProject())
  }

  openSettings() {
    PASettingsControl.Instance.showSettings = true
  }

  protected readonly PAProject = PAProject;
  protected readonly faCog = faCog;
  protected readonly faBookOpen = faBookOpen;
  protected readonly PADataControl = PADataControl;
  protected readonly PAFilterControl = PAFilterControl;
  protected readonly PATimeControl = PATimeControl;
  protected readonly PASettingsControl = PASettingsControl;
  protected readonly PATourPlannerControl = PATourPlannerControl;
  protected readonly PAMapControl = PAMapControl;
  protected readonly PAStoreControl = PAStoreControl;
}