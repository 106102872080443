
<div class="vertical_flex" style="padding: 2px 4px; background-color: #24292e; width: 600px;">
  <h6>Warnung: Datenkonflikte</h6>
  <p>Möchten Sie diese Tour wirklich speichern? Folgende Aufträge wurden seit dem letzten Ladevorgang in der Datenbank geändert:</p>
  <div class="vertical_flex" style="padding: 2px 4px; max-height: 500px; overflow-y: auto; margin-bottom: 8px;" *ngFor="let db_change of DBChanges">
    <mat-expansion-panel [expanded]="expandedOperations.includes(db_change.base_operation)" style="margin-bottom: 4px;">
      <mat-expansion-panel-header>
        <mat-panel-title>Ticket {{db_change.base_operation.ticket.id}} - {{db_change.base_operation.ticket.address_company}}</mat-panel-title>
        <mat-panel-description> <i>{{db_change.base_operation.ticket.priority.name}}</i> </mat-panel-description>
      </mat-expansion-panel-header>
      <div class="vertical_flex"></div>
      <a href="/a/ticket/{{db_change.base_operation.ticket.id}}?operation_id={{db_change.base_operation.id}}" target="_blank">Hawk-Link</a>
      <div *ngIf="db_change.date_changed">
        Zeitliche Änderung - Session: {{dateTimeString(db_change.date_changed.current_operation_date)}}, DB: {{dateTimeString(db_change.date_changed.db_operation_date)}}
      </div>
      <div *ngIf="db_change.uid_changed">
        Techniker Änderung - Session: {{uidNameString(db_change.uid_changed.current_uid)}}, DB: {{uidNameString(db_change.uid_changed.db_uid)}}
      </div>
    </mat-expansion-panel>
  </div>
  <div class="horizontal_flex">
    <button mat-raised-button color="warn" style="margin-right: 4px;" (click)="closeEvent.emit(); $event.stopPropagation()">Abbrechen</button>
    <button mat-raised-button color="primary" (click)="saveNewTourEvent.emit(); $event.stopPropagation()">Tour speichern!</button>
    <div style="flex: 1;"></div>
  </div>
</div>