<div class="projectPage" *ngIf="project">
  <section class="main-content">
      <header class="columns">
          <section>
              <h2>Stammdaten</h2>
              <table class="projectPageTable base">
                  <tr>
                      <th class="tableLable">ID</th>
                      <td>{{ project.id }}</td>
                  </tr>
                  <tr>
                      <th>Name</th>
                      <td>{{ project.name }}</td>
                  </tr>
              </table>
          </section>
          <section>
              <h2>leer</h2>

          </section>
          <section>
              <h2>leer</h2>
          </section>
      </header>



      <section>
          <h2>Workflow Upload</h2>
          <hawk-workflow-upload-applet [projectId]="projectId"></hawk-workflow-upload-applet>
      </section>
  </section>
  <aside>
      <section>
          <h3>Karte</h3>
      </section>
  </aside>
</div>
