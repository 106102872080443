import { PALocation } from "./location";
import { PAOperation } from "./operation";
import { PATechnicianDate } from "./technician-date";
import { PAUtil } from "./util";
import { PAProject } from "./project";
import { MapRoute, ProjectDayPercentage } from "../../../_models/planning-assistant.interface";
import { DayOpening } from "../../../_models/opening-times.interface";
import { TourAnimation } from "./tour-animation";
import { MapContainer } from "../map/pa-map";
import { FireUpdateOnChange } from "./util-classes/fire-update-on-change";
import * as mapboxgl from "mapbox-gl";
import { Route as MapboxRoute } from "src/app/_services/mapbox.service";
import { TechnicianPosition } from "./technician-live-position";
import { PAMapControl } from "../singletons/pa-map-control";
import { PADataControl } from "../singletons/pa-data-control";
import { PATimeControl } from "../singletons/pa-time-control";
import { PATourPlannerControl } from "../singletons/pa-tourplanner-control";

export interface DrivingTime {
  duration: number,
  distance: number,
  geometry: {
    coordinates: number[][]
  }
}

export interface Tour {
  start_location: PALocation
  end_location: PALocation
  operations: PAOperation[],
  driving_times: DrivingTime[],
  time_specific_data: {
    planned: TimeData,
    average: TimeData
  },
  marker_collection: { markers: { type: string, index: string }[], percent: number }[]
  driving_kms: number
}

export interface TimeData {
  project_work_times: { project: PAProject, amount: number }[]
  operation_work_time: number,
  driving_time: number,
  total_time: number,
  workload_percent: number,
  project_percentages: ProjectDayPercentage[]
  operation_data: OperationData[],
  home_driving: {
    timeline_position: TimeLinePosition,
  }
}

export interface OperationData {
  operation_time_strings: { travel_start: string, on_site: string, finished: string },
  timeline_positions: {
    driving: TimeLinePosition,
    operation: TimeLinePosition
  }
}

export interface TimeLinePosition {
  left_percent: number,
  right_percent: number,
  middle_percent?: number,
  additional_time_percent?: number
}

export class PATour extends FireUpdateOnChange<'start_location_changed' | 'end_location_changed' | 'route_changed'> {

  static timelineStart = 6 // 6AM
  static timelineEnd = 22 // 10PM
  private _maxWorkTime = 480

  private _color: { r: number, g: number, b: number, a?: number } = {r: 255, g: 120, b: 31, a: 1}
  private _startLocation: PALocation
  private _endLocation: PALocation
  private _operations: PAOperation[] = []
  public lunchBreak: { after_operation_idx: number, duration: number }
  public operationPriorityCountsBeforeOperation: { operation: PAOperation, count: number }[] = []
  public mapTourAnimations = new Map<MapContainer, TourAnimation>()
  public liveTechnicianPosition: TechnicianPosition

  public route: Tour

  public initializedLocations = {
    start: false,
    end: false
  }

  public updating: boolean = false
  private queueUpdate: boolean = false

  constructor(
    readonly technicianDate: PATechnicianDate
  ) {
    super()
    this.resetRoute()
    this.liveTechnicianPosition = new TechnicianPosition(this)
  }

  get operations(): PAOperation[] {
    return this.route.operations
  }

  get startLocation(): PALocation {
    return this._startLocation
  }

  set startLocation(location: PALocation) {
    this._startLocation = location
    this.fireUpdateManually('start_location_changed')
  }

  get endLocation(): PALocation {
    return this._endLocation
  }

  set endLocation(location: PALocation) {
    this._endLocation = location
    this.fireUpdateManually('end_location_changed')
  }

  executeBeforeChange(): void {
  }

  updateLocations(): void {
    this.updateStartLocation()
    this.updateEndLocation()
  }

  async waitForFirstLocationUpdate(): Promise<void> {
    while (!this.initializedLocations.start || !this.initializedLocations.end) {
      await PAUtil.sleep(100)
    }
  }

  public async getMBRoutes(): Promise<MapboxRoute[]> {
    return this.operations.length ? [
        await this.startLocation.getDistanceToLocation(this.operations[0].ticket.client.location),
        ...await Promise.all(
          this.operations.map(
            async op => {
              let next_op_idx = this.operations.indexOf(op) + 1
              let next_location = this.operations.length <= next_op_idx ? this.endLocation : this.operations[next_op_idx].ticket.client.location
              return await op.ticket.client.location.getDistanceToLocation(next_location)
            }
          )
        )
      ] : [
        await this.startLocation.getDistanceToLocation(this.endLocation)
      ];
  }

  public async getMBRouteCoordinates(): Promise<number[][]> {
    let mb_routes = await this.getMBRoutes()
    return mb_routes.reduce((coordinates: number[][], route) => { return coordinates.concat(route.geometry.coordinates)}, [])
  }

  public updateStartLocation() {
    let current_start_location = this.getStartLocation()
    if (current_start_location != this.startLocation) {
      this.startLocation = current_start_location
      if (this == this.technicianDate.tour) {
        PATourPlannerControl.Instance.lastMainRouteLocationChangeTimestamp = Date.now()
      }
    }
    this.initializedLocations.start = true
  }

  public updateEndLocation() {
    let current_end_location = this.getEndLocation()
    if (current_end_location != this.endLocation) {
      this.endLocation = current_end_location
      if (this == this.technicianDate.tour) {
        PATourPlannerControl.Instance.lastMainRouteLocationChangeTimestamp = Date.now()
      }
    }
    this.initializedLocations.end = true
  }

  private getStartLocation(): PALocation {
    let previous_technician_date = this.technicianDate.getPreviousTechnicianDate()
    if (this.technicianDate.previousTravelTechnicianDate?.isActive() && previous_technician_date) {
      return this.technicianDate.previousTravelTechnicianDate.location || (previous_technician_date.tour?.operations?.length ? previous_technician_date.tour.operations[previous_technician_date.tour.operations.length - 1].ticket.client.location : this.technicianDate.technician.location)
    } else {
      return this.technicianDate.technician.location
    }
  }

  private getEndLocation(): PALocation {
    if (this.technicianDate.travelTechnicianDate?.isActive()) {
      return this.technicianDate.travelTechnicianDate.location || (this._operations.length ? this._operations[this._operations.length - 1].ticket.client.location : this.technicianDate.technician.location)
    } else {
      return this.technicianDate.technician.location
    }
  }

  public async removeOperationWithId(id: number, wait_for_all_queued_updates?: boolean): Promise<void> {
    this._operations = this._operations.filter(operation => operation.id != id)
    await this.updateRoute(wait_for_all_queued_updates)
  }

  public async insertOperations(insert_operations: PAOperation[], config?: {
    wait_for_all_queued_updates?: boolean,
    skip_route_update?: boolean
  }): Promise<void> {

    if (!this.startLocation || !this.endLocation) {
      this.updateLocations()
    }

    for (let insert_operation of insert_operations) {
      if (this._operations.filter(operation => operation.id == insert_operation.id).length == 0) {
        let idx = 0
        for (let i of PAUtil.range(0, this._operations.length - 1)) {
          if (this._operations[idx].startsBeforeOperation(insert_operation)) {
            idx = i + 1
          } else {
            break
          }
        }

        this._operations.splice(idx, 0, insert_operation)
      }
    }

    if (!config?.skip_route_update) {
      await this.updateRoute(config?.wait_for_all_queued_updates)
    }
  }

  public getRouteIdxForOperationWithId(id: number): number {
    return this.operations.findIndex(op => op.id == id)
  }

  public async updateOperationSorting(wait_for_all_queued_updates?: boolean): Promise<void> {
    this._operations.sort((op_a, op_b) => op_a.getStartTime() < op_b.getStartTime() ? -1 : 1)
    await this.updateRoute(wait_for_all_queued_updates)
  }

  async updateRoute(wait_for_all_queued_updates?: boolean) {

    if (!this.queueUpdate) {
      if (this.updating) {
        this.queueUpdate = true
        while (this.updating) {
          await PAUtil.sleep(50)
        }
        this.queueUpdate = false
      }
    }

    this.updating = true
    await this.updateRouteData()
    this.updating = false

    if (wait_for_all_queued_updates) {
      while (this.updating) {
        await PAUtil.sleep(50)
      }
    }
  }

  async updateRouteData(): Promise<void> {

    let driving_kms: number

    if (this.technicianDate.loadingStatus == 'init') {
      await this.technicianDate.loadData()
    }
    await this.initializeTravelTechnicianDate();

    let travel_data = await this.initTechnicianDateTravelData()
    driving_kms = this.getTotalDrivingKms()
    let project_work_times = await this.getProjectWorkTimes(travel_data.route_operations)

    this.technicianDate.technician.updateTotalTechnicianWorkTimeOfCalendarWeekInHours(this.technicianDate.getCalendarWeek())

    let time_specific_data: { planned: TimeData, average: TimeData } = {
      planned: await this.getTimeSpecificData(travel_data, 'planned', project_work_times.planned),
      average: await this.getTimeSpecificData(travel_data, 'average', project_work_times.average)
    }

    let marker_collection = this.getSummarizedOpeningMarkerTimelinePositions(travel_data.route_operations)

    this.route.driving_kms = driving_kms
    this.route.driving_times = travel_data.driving_times
    this.route.operations = travel_data.route_operations
    this.route.time_specific_data = time_specific_data
    this.route.marker_collection = marker_collection

    this.liveTechnicianPosition.update()
  }

  public async initializeTravelTechnicianDate() {
    if (!this.technicianDate.initializedTravelTechnicianDate) {
      await this.technicianDate.setTravelTechnicianDate()
      this.technicianDate.initializedTravelTechnicianDate = true
    }
  }

  async getTimeSpecificData(travel_data: {
    driving_times: { duration: number; distance: number }[];
    route_operations: PAOperation[]
  }, time_filter: 'planned' | 'average', project_work_times: {
    project: PAProject,
    amount: number
  }[]): Promise<TimeData> {

    let group_times = this.getTechnicianDateWorkTime(project_work_times)
    let project_day_percentages: ProjectDayPercentage[] = this.getProjectDayPercentages(project_work_times)

    return {
      driving_time: group_times.driving_time,
      operation_work_time: group_times.work_time,
      total_time: group_times.total_time,
      project_work_times: project_work_times,
      project_percentages: project_day_percentages,
      workload_percent: this.getWorkloadInPercent(group_times.total_time, true),
      operation_data: await this.getOperationData(travel_data, time_filter),
      home_driving: {
        timeline_position: this.getHomeDrivingTimelinePosition()
      }
    }
  }

  async getOperationData(travel_data: {
    driving_times: { duration: number; distance: number }[];
    route_operations: PAOperation[]
  }, filter: string): Promise<OperationData[]> {
    let operations_time_strings = await PATour.getOperationTimeStrings(travel_data, filter, PATimeControl.Instance)
    return travel_data.route_operations.map(op => {
      let idx = travel_data.route_operations.indexOf(op)
      return {
        operation_time_strings: operations_time_strings[idx],
        timeline_positions: {
          driving: op.getDrivingTimelinePosition(travel_data.driving_times[idx].duration, this.technicianDate.day.local_timestamp),
          operation: op.getTimelinePosition(this.technicianDate.day.local_timestamp)
        }
      }
    })
  }

  private async getProjectWorkTimes(operations: PAOperation[]) {
    let planned_work_times: { project: PAProject, amount: number }[] = []
    let average_work_times: { project: PAProject, amount: number }[] = []

    let operations_in_past = operations.filter(operation => operation.date_repaired && operation.date_on_site)
    let operations_in_future = operations.filter(operation => !(operation.date_repaired && operation.date_on_site))

    // Calc work_time for technician dates from the past
    if (operations_in_past.length) {
      let operation_with_travel_start_date = [...operations_in_past].filter(operation => operation.date_travel_start)
      let operations_sorted_by_travel_start = [...operation_with_travel_start_date].sort(
        (op_a, op_b) => (
          PATimeControl.Instance.dateTimeToDayMinutes(op_a.date_travel_start) >
          PATimeControl.Instance.dateTimeToDayMinutes(op_b.date_travel_start)
        ) ? 1 : -1)

      for (let operation of operations_sorted_by_travel_start) {
        let work_time = (PATimeControl.Instance.dateStringToTimestamp(operation.date_repaired, false, false) - PATimeControl.Instance.dateStringToTimestamp(operation.date_on_site, false, false)) / 60000
        let project = operation.getProject()
        planned_work_times.push({project: project, amount: work_time})
        average_work_times.push({project: project, amount: work_time})
      }
    }

    // Calc planned and average work_time for technician dates from the future
    if (operations_in_future.length > 0) {
      for (let operation of operations_in_future) {
        let technician = this.technicianDate.technician

        let priority_rule_time = 0
        let time_rule = technician ? operation.getAdditionalPriorityTimeRule({use_technician: technician}) : null
        if (time_rule) {
          priority_rule_time = Math.round(operation.ticket.time_estimation * operation.getAdditionalPriorityTimeRuleTimeFactor({use_technician: technician}))
        }

        let estimated_operation_time = operation.ticket.time_estimation
        let average_operation_time: number
        if (!technician.operationTimeEntryForPriorityWasLoaded(operation.ticket.priority.id)) {
          await technician.loadPriorityBasedOperationTimes(operation.ticket.priority.id)
        }
        let project = operation.getProject()
        average_operation_time = technician.getPriorityBasedAverageOperationTime(operation.ticket.priority.id)
        planned_work_times.push({project: project, amount: priority_rule_time || estimated_operation_time})
        average_work_times.push({
          project: project,
          amount: priority_rule_time || (average_operation_time >= 0 ? average_operation_time : estimated_operation_time)
        })
      }
    }

    return {'planned': planned_work_times, 'average': average_work_times}
  }

  public getProjectWorkTimesOfTechnicianDate(sort_by_size: boolean, project_work_times: {
    project: PAProject,
    amount: number
  }[]) {
    let work_times: { project: PAProject, time: number }[] = []
    for (let single_work_time of project_work_times) {
      let project = single_work_time.project
      let add_project_to_work_times = true
      for (let accumulated_work_time of work_times) {
        if (accumulated_work_time.project == project) {
          accumulated_work_time.time += single_work_time.amount
          add_project_to_work_times = false
          break
        }
      }
      if (add_project_to_work_times) {
        work_times.push({project: project, time: single_work_time.amount})
      }
    }
    if (sort_by_size) {
      work_times.sort((work_time_a, work_time_b) => (
        work_time_a.time <
        work_time_b.time
      ) ? 1 : -1)
    }
    return work_times
  }

  public getTotalDrivingTime() {
    let driving_times = [...this.route.driving_times]
    return driving_times.reduce((sum, driving_time) => sum + driving_time.duration, 0)
  }

  public getTotalDrivingKms() {
    let driving_times = [...this.route.driving_times]
    return driving_times.reduce((sum, driving_time) => sum + driving_time.distance, 0)
  }

  public getTotalWorkTime(with_driving_time: boolean, operation_time_filter: 'average' | 'planned'): number {
    let time_data = this.route.time_specific_data[operation_time_filter]
    return with_driving_time ? time_data.total_time : time_data.operation_work_time
  }

  public getProjectDayPercentages(project_work_time: { project: PAProject, amount: number }[]) {
    let max_work_time = this._maxWorkTime
    let project_work_times = this.getProjectWorkTimesOfTechnicianDate(true, project_work_time)
    let driving_time = this.getTotalDrivingTime()
    let accumulated_work_times = project_work_times.reduce((sum, work_time) => sum + work_time.time, 0)

    let time_off = Math.max(max_work_time - (driving_time + accumulated_work_times), 0)
    let off_percentage = (time_off / max_work_time) * 100

    let day_percentages: ProjectDayPercentage[] = []

    for (let project_work_time of project_work_times) {
      let project_percentage = (project_work_time.time / accumulated_work_times) * (100 - off_percentage)
      if (project_percentage > 0) {
        let project = project_work_time.project
        day_percentages.push({
          project: project,
          percentage: project_percentage,
          tooltip: project.project_name
        })
      }
    }

    return day_percentages
  }

  getTechnicianDateWorkTime(project_work_times: { project: PAProject, amount: number }[]): {
    work_time: number,
    driving_time: number,
    total_time: number
  } {
    let absence_time = (this.technicianDate.absences.length || this.technicianDate.day.holidays.filter(holiday => holiday.type == 'public').length) ? PATechnicianDate.defaultMaxWorkTime : 0
    let selected_work_time = project_work_times.reduce((sum: number, time_data) => sum + time_data.amount, 0) + absence_time
    let total_driving_time = this.getTotalDrivingTime()
    return {
      work_time: selected_work_time,
      driving_time: total_driving_time,
      total_time: selected_work_time + total_driving_time
    }
  }

  public toMapRoutes(): MapRoute[] {
    let routes: MapRoute[] = []
    let start_coordinates = this.startLocation.coordinates
    let end_coordinates = this.endLocation.coordinates
    let technician = this.technicianDate.technician
    let technician_color = technician.color ? technician.getColorString(1) : ''

    if (this._operations.length) {
      let first_operation = this._operations[0]
      let color = technician_color || PAUtil.rgbaToString(this._color)
      let from_location = this.startLocation
      let to_location = first_operation.ticket.client.location
      let popup_text = `From Home to ${first_operation.ticket.address_company} (${first_operation.ticket.address_city})`
      routes.push({from: from_location, to: to_location, color: color, line_width: 4, dashed: false, popup_text})
      for (let operation of this._operations) {
        let idx = this._operations.indexOf(operation)
        from_location = operation.ticket.client.location
        if (idx == this._operations.length - 1) {
          to_location = this.endLocation
          popup_text = `From ${operation.ticket.address_company} (${operation.ticket.address_city}) to Home`
        } else {
          let next_operation = this._operations[idx + 1]
          to_location = next_operation.ticket.client.location
          popup_text = `From ${operation.ticket.address_company} (${operation.ticket.address_city}) to ${next_operation.ticket.address_company} (${next_operation.ticket.address_city})`
        }
        if (!(from_location.coordinates.latitude == to_location.coordinates.latitude && from_location.coordinates.longitude == to_location.coordinates.longitude)) {
          routes.push({from: from_location, to: to_location, color: color, line_width: 4, dashed: false, popup_text})
        }
      }
    } else if (start_coordinates.latitude != end_coordinates.latitude || start_coordinates.longitude != end_coordinates.longitude) {
      let popup_text = `From Home to Destination`
      routes.push({
        from: this.startLocation,
        to: this.endLocation,
        color: technician_color || PAUtil.rgbaToString(this._color),
        line_width: 4,
        dashed: false,
        popup_text: popup_text
      })
    }
    return routes
  }

  async initTechnicianDateTravelData(): Promise<{
    driving_times: DrivingTime[];
    route_operations: PAOperation[]
  }> {
    let travel_data = await this.updateTravelDurationsForTechnicianDate()
    await this.updatePriorityOperationCountsBeforeOperations(travel_data.route_operations)
    return travel_data
  }

  public async updateTravelDurationsForTechnicianDate() {
    this.resetRoute()

    let operations_changed = this.route.operations.length != this._operations.length || !this.route.operations.every(op => this.route.operations.indexOf(op) == this._operations.indexOf(op));

    let route_operations = operations_changed ? [...this._operations] : this.route.operations;
    let driving_times: DrivingTime[] = []

    if (route_operations.length) {
      for (let operation of route_operations) {
        let travel_data = await operation.getTravelDataToOperation(route_operations, this.startLocation)
        let driving_time = {duration: travel_data.duration, distance: travel_data.distance, geometry: travel_data.geometry}
        driving_times.push(driving_time)
      }

      // fill in time to drive home for last operation
      let home_travel_data = await route_operations[route_operations.length - 1].ticket.client.location.getDistanceToLocation(this.endLocation)
      driving_times.push({duration: Math.round(home_travel_data.duration * this.technicianDate.technician.driving_time_factor), distance: home_travel_data.distance, geometry: home_travel_data.geometry})
    } else if (
      this.startLocation.coordinates.latitude != this.technicianDate.technician.location.coordinates.latitude ||
      this.startLocation.coordinates.longitude != this.technicianDate.technician.location.coordinates.longitude ||
      this.endLocation.coordinates.longitude != this.technicianDate.technician.location.coordinates.longitude ||
      this.endLocation.coordinates.longitude != this.technicianDate.technician.location.coordinates.longitude
    ) {
      let home_travel_data = await this.startLocation.getDistanceToLocation(this.endLocation)
      driving_times.push({duration: Math.round(home_travel_data.duration * this.technicianDate.technician.driving_time_factor), distance: home_travel_data.distance, geometry: home_travel_data.geometry})
    }

    this.route.operations = route_operations
    this.route.driving_times = driving_times

    return {route_operations, driving_times}
  }

  public resetRoute(): void {
    if (!this.route) {
      this.route = {
        start_location: this._startLocation,
        end_location: this._endLocation,
        driving_times: [],
        operations: [],
        driving_kms: 0,
        time_specific_data: {
          planned: {
            driving_time: 0,
            total_time: 0,
            operation_work_time: 0,
            project_percentages: [],
            project_work_times: [],
            workload_percent: 0,
            operation_data: [],
            home_driving: null
          },
          average: {
            driving_time: 0,
            total_time: 0,
            operation_work_time: 0,
            project_percentages: [],
            project_work_times: [],
            workload_percent: 0,
            operation_data: [],
            home_driving: null
          }
        },
        marker_collection: []
      }
    }
  }

  public getWorkloadInPercent(workload_time: number, cut_at_100_percent?: boolean): number {
    let percentage = (workload_time / this._maxWorkTime) * 100
    return cut_at_100_percent ? Math.round(Math.min(percentage, 100)) : percentage
  }

  public updateTourMarkers(): void {
    this.technicianDate.technician.fireUpdateManually()
    this._operations.map(operation => operation.getStore() ? operation.getStore().fireUpdateManually() : {})
  }

  public async onPreviousTravelTechnicianDateChange(): Promise<void> {
    this.updateStartLocation()
    await this.updateRoute()
  }

  public async onTravelTechnicianDateChange(): Promise<void> {
    await this.updateTravelStatus()
  }

  async updateTravelStatus(skip_route_update?: boolean): Promise<void> {
    this.updateEndLocation()
    if (!skip_route_update) {
      await this.updateRoute()
    }
  }

  public centerInMap(): void {
    let map = PAMapControl.Instance.mainMapContainer.map
    let padding = 100
    let bounds = new mapboxgl.LngLatBounds();

    this._operations.map(operation => bounds.extend([operation.ticket.coordinates.longitude, operation.ticket.coordinates.latitude]))
    bounds.extend([this.startLocation.coordinates.longitude, this.startLocation.coordinates.latitude])
    bounds.extend([this.endLocation.coordinates.longitude, this.endLocation.coordinates.latitude])
    if (!bounds.isEmpty()) {
      map.fitBounds(
        bounds,
        {
          padding: {top: padding, bottom: padding, left: padding, right: padding},
          speed: 1,
          maxZoom: 13,
          minZoom: map.getZoom()
        }
      )
    }
  }

  public async updatePriorityOperationCountsBeforeOperations(operations: PAOperation[]): Promise<void> {
    this.operationPriorityCountsBeforeOperation = await Promise.all(operations.map(async op => {
      return {
        operation: op,
        count: await this.technicianDate.technician.getPriorityOperationCountUntilTimestamp(op.ticket.priority, op.getPlannedTimestamp())
      }
    }))
  }

  getPriorityOperationCountsBeforeOperation(operation: PAOperation) {
    let pcbo = this.operationPriorityCountsBeforeOperation.find(pcbo => pcbo.operation.id == operation.id)
    return pcbo ? pcbo.count : 0
  }

  getHomeDrivingTimelinePosition(): TimeLinePosition {

    let timeline_start = PATour.timelineStart
    let timeline_end = PATour.timelineEnd
    let timeline_start_ms = Math.floor(timeline_start) * 60 * 60 * 1000
    let timeline_end_ms = Math.ceil(timeline_end) * 60 * 60 * 1000
    let total_mss = timeline_end_ms - timeline_start_ms

    if (this.operations.length) {
      let last_operation = this.operations[this.operations.length - 1]

      let delay_for_lunch_break_minutes = 0
      if (Number.isInteger(this.lunchBreak?.after_operation_idx) && this.lunchBreak.after_operation_idx == this.operations.length - 1) {
        delay_for_lunch_break_minutes = this.lunchBreak.duration
      }

      if (last_operation.operation_date && last_operation.user_ids.length && !PADataControl.Instance.unassignedOperationUserIds.includes(last_operation.user_ids[0])) {

        let calculated_driving_time = this.route.driving_times[this.route.driving_times.length - 1].duration

        let driving_start_ms = last_operation.calculateOperationDayEndMilliseconds({use_day_timestamp: this.technicianDate.day.local_timestamp}) + delay_for_lunch_break_minutes * 60 * 1000
        let driving_end_ms = driving_start_ms + calculated_driving_time * 60 * 1000

        let driving_start_since_day_start_ms = driving_start_ms - timeline_start_ms
        let driving_end_since_day_start_ms = driving_end_ms - timeline_start_ms

        let left_percent = (driving_start_since_day_start_ms / total_mss) * 100
        let right_percent = 100 - (driving_end_since_day_start_ms / total_mss) * 100

        return {left_percent: left_percent, right_percent: right_percent}
      } else {
        return {left_percent: 100, right_percent: 100}
      }
    } else if (this.route.driving_times.length) {
      let calculated_driving_time = this.route.driving_times[0].duration
      let driving_start_ms = 1000 * 60 * 60 * 8
      let driving_end_ms = driving_start_ms + calculated_driving_time * 60 * 1000
      let driving_start_since_day_start_ms = driving_start_ms - timeline_start_ms
      let driving_end_since_day_start_ms = driving_end_ms - timeline_start_ms
      let left_percent = (driving_start_since_day_start_ms / total_mss) * 100
      let right_percent = 100 - (driving_end_since_day_start_ms / total_mss) * 100
      return {left_percent: left_percent, right_percent: right_percent}
    } else {
      return {left_percent: 100, right_percent: 100}
    }

  }

  getOpeningMarkerTimelinePositions(operations: PAOperation[]): {
    operation_idx: number,
    op_percent: number,
    cl_percent: number
  }[] {

    let timeline_start = PATour.timelineStart
    let timeline_end = PATour.timelineEnd

    let weekday = this.technicianDate.day.week_day
    let timeline_start_ms = Math.floor(timeline_start) * 60 * 60 * 1000
    let timeline_end_ms = Math.ceil(timeline_end) * 60 * 60 * 1000
    let total_mss = timeline_end_ms - timeline_start_ms

    let res: { operation_idx: number, op_percent: number, cl_percent: number }[] = []

    for (const operation of operations) {
      let weekday_opening = (operation.ticket.store_openings[weekday]) as DayOpening
      if (weekday_opening) {
        if (weekday_opening.validity == 'db') {
          let {
            open_percent,
            close_percent
          } = getWeekdayOpeningsOpenClosePercent(weekday_opening, timeline_start_ms, total_mss, PATimeControl.Instance);
          res.push({
            operation_idx: operations.indexOf(operation),
            op_percent: open_percent,
            cl_percent: close_percent
          })
        }
      }
    }

    return res
  }

  getSLAMarkerTimelinePositions(operations: PAOperation[]): { operation_idx: number, sla_percent: number }[] {

    let timeline_start = PATour.timelineStart
    let timeline_end = PATour.timelineEnd

    let timeline_start_ms = Math.floor(timeline_start) * 60 * 60 * 1000
    let timeline_end_ms = Math.ceil(timeline_end) * 60 * 60 * 1000
    let total_mss = timeline_end_ms - timeline_start_ms

    let res: { operation_idx: number, sla_percent: number }[] = []

    for (const operation of operations) {
      if (operation.ticket.datesla) {
        let day_timestamp = PATimeControl.Instance.dateStringToTimestamp(operation.ticket.datesla, true, true)
        if (this.technicianDate.day.utc_timestamp == day_timestamp) {
          let local_day_timestamp = PATimeControl.Instance.dateStringToTimestamp(operation.ticket.datesla, true, false)
          let sla_ms = Date.parse(operation.ticket.datesla) - local_day_timestamp
          let sla_since_day_start_ms = sla_ms - timeline_start_ms
          let sla_percent = (sla_since_day_start_ms / total_mss) * 100

          res.push({
            operation_idx: operations.indexOf(operation),
            sla_percent: sla_percent
          })
        }
      }
    }

    return res
  }

  getOutsideOpeningZonesTimelinePositions(config?: {
    for_user_with_id?: number,
    offset_percent?: number,
    offset_operations?: PAOperation[]
  }): { left_percent: number, right_percent: number, info: string, operation: PAOperation }[] {

    let timeline_start = PATour.timelineStart
    let timeline_end = PATour.timelineEnd
    let offset_percent = config?.offset_percent || 0
    let offset_operations = config?.offset_operations || []

    const res: { left_percent: number, right_percent: number, info: string, operation: PAOperation }[] = []

    for (let operation of this.operations) {

      const is_drag_and_drop_operation = offset_operations.includes(operation)
      let drag_and_drop_extra_percent = is_drag_and_drop_operation ? offset_percent : 0

      let operation_position = operation.getTimelinePosition(this.technicianDate.day.local_timestamp)
      let timeline_start_ms = Math.floor(timeline_start) * 60 * 60 * 1000
      let timeline_end_ms = Math.ceil(timeline_end) * 60 * 60 * 1000
      let total_mss = timeline_end_ms - timeline_start_ms

      if (operation.operation_date && operation.user_ids.length && !PADataControl.Instance.unassignedOperationUserIds.includes(operation.user_ids[0])) {

        let weekday_opening = (operation.ticket.store_openings[this.technicianDate.day.week_day]) as DayOpening
        if (weekday_opening) {
          if (weekday_opening.validity == 'db') {
            let {
              open_percent,
              close_percent
            } = getWeekdayOpeningsOpenClosePercent(weekday_opening, timeline_start_ms, total_mss, PATimeControl.Instance);

            if (operation_position.left_percent + drag_and_drop_extra_percent < open_percent) {
              res.push({
                left_percent: operation_position.left_percent + drag_and_drop_extra_percent,
                right_percent: Math.max(100 - open_percent, operation_position.right_percent - drag_and_drop_extra_percent),
                info: `Der Auftrag ${operation.ticket.address_company} liegt außerhalb der Öffnungszeiten`,
                operation: operation
              })
            }

            if (operation_position.right_percent - drag_and_drop_extra_percent < 100 - close_percent) {
              res.push({
                left_percent: Math.max(close_percent, operation_position.left_percent + drag_and_drop_extra_percent),
                right_percent: operation_position.right_percent - drag_and_drop_extra_percent,
                info: `Der Auftrag ${operation.ticket.address_company} liegt außerhalb der Öffnungszeiten`,
                operation: operation
              })
            }

          }
        } else {
          if (operation.hasOpenings()) {
            res.push({
              left_percent: operation_position.left_percent + drag_and_drop_extra_percent,
              right_percent: operation_position.right_percent - drag_and_drop_extra_percent,
              info: `Der Store ${operation.ticket.address_company} hat am ${PATimeControl.Instance.weekDayToGermanName(this.technicianDate.day.week_day, false)} nicht geöffnet.`,
              operation: operation
            })
          }
        }

        if (operation.ticket.datesla) {
          let day_timestamp = PATimeControl.Instance.dateStringToTimestamp(operation.ticket.datesla, true, true)
          if (this.technicianDate.day.utc_timestamp == day_timestamp) {
            let local_day_timestamp = PATimeControl.Instance.dateStringToTimestamp(operation.ticket.datesla, true, false)
            let sla_ms = Date.parse(operation.ticket.datesla) - local_day_timestamp
            let sla_since_day_start_ms = sla_ms - timeline_start_ms
            let sla_percent = (sla_since_day_start_ms / total_mss) * 100

            if (operation_position.right_percent - drag_and_drop_extra_percent < 100 - sla_percent) {
              res.push({
                left_percent: Math.max(sla_percent, operation_position.left_percent + drag_and_drop_extra_percent),
                right_percent: operation_position.right_percent - drag_and_drop_extra_percent,
                info: `Der Auftrag ${operation.ticket.address_company} liegt außerhalb der SLA-Zeit`,
                operation: operation
              })
            }
          }
        }
      }
    }

    return res
  }

  getSummarizedOpeningMarkerTimelinePositions(operations: PAOperation[]): {
    markers: { type: string, index: string }[],
    percent: number
  }[] {

    let percentMap = new Map<number, { type: string, index: string }[]>()
    for (let opening_marker of this.getOpeningMarkerTimelinePositions(operations)) {
      let index = `${(opening_marker.operation_idx + 1).toString()}`

      if (!percentMap.has(opening_marker.op_percent)) {
        percentMap.set(opening_marker.op_percent, [{type: 'O', index: index}])
      } else {
        percentMap.get(opening_marker.op_percent).push({type: 'O', index: index})
      }
      if (!percentMap.has(opening_marker.cl_percent)) {
        percentMap.set(opening_marker.cl_percent, [{type: 'C', index: index}])
      } else {
        percentMap.get(opening_marker.cl_percent).push({type: 'C', index: index})
      }
    }

    for (let opening_marker of this.getSLAMarkerTimelinePositions(operations)) {
      let index = `${(opening_marker.operation_idx + 1).toString()}`

      if (!percentMap.has(opening_marker.sla_percent)) {
        percentMap.set(opening_marker.sla_percent, [{type: 'SLA', index: index}])
      } else {
        percentMap.get(opening_marker.sla_percent).push({type: 'SLA', index: index})
      }
    }

    return [...percentMap.keys()].map(key => {
      return {markers: percentMap.get(key), percent: key}
    })
  }

  hasEqualRoute(route: PAOperation[], check_times?: boolean): boolean {

    const tour_length = this.operations.length
    const compare_tour_length = route.length
    const tour_length_changed = tour_length != compare_tour_length

    if (!tour_length_changed) {
      for (const idx of Array.from({length: this.operations.length}, (_, i) => i)) {
        const old_operation = this.operations[idx]
        const new_operation = route[idx]

        const ids_match = old_operation.id == new_operation.id
        const operation_date_match = Date.parse(old_operation.operation_date) == Date.parse(new_operation.operation_date)
        const operation_time_match = old_operation.calculateOperationTimeMilliseconds() == new_operation.calculateOperationTimeMilliseconds()

        if (!ids_match || (check_times && (!operation_date_match || !operation_time_match))) return false
      }
      return true
    } else {
      return false
    }
  }

  addLunchBreak(after_operation_idx: number, duration: number): void {
    this.lunchBreak = {after_operation_idx: after_operation_idx, duration: duration}
  }

  removeLunchBreak(): void {
    this.lunchBreak = null
  }

  public async initMapTourAnimation(map_container: MapContainer): Promise<void> {
    if (!this.mapTourAnimations.has(map_container)) {
      this.mapTourAnimations.set(map_container, new TourAnimation(this, map_container))
    }
    await this.mapTourAnimations.get(map_container).initAnimation()
    console.log(`Started tour animation ${this.technicianDate.day.week_day}-${this.technicianDate.technician.getFullName()}-${map_container.name}`)
  }

  public stopMapTourAnimation(map_container: MapContainer): void {
    let tour_animation = this.mapTourAnimations.get(map_container)
    if (tour_animation) tour_animation.deleteCurrentLayers()
    console.log(`Stopped tour animation ${this.technicianDate.day.week_day}-${this.technicianDate.technician.getFullName()}-${map_container.name}`)
  }

  public moveTourAnimationLayersUp(map_container: MapContainer): void {
    let tour_animation = this.mapTourAnimations.get(map_container)
    if (tour_animation) tour_animation.moveLastTourLayersUp()
  }

  private static async getOperationTimeStrings(travel_data: {
    driving_times: { duration: number; distance: number }[];
    route_operations: PAOperation[]
  }, filter: string, time_service: PATimeControl) {
    let time_strings: { travel_start: string, on_site: string, finished: string }[] = []

    for (let operation of travel_data.route_operations) {
      let technician_date = operation.getTechnicianDate()
      if (technician_date) {
        let op_idx = travel_data.route_operations.indexOf(operation)
        let operation_time_data_promise = operation.getOperationTimeData(technician_date.technician, travel_data.driving_times[op_idx].duration)
        let local_day_timestamp = time_service.dateToTimestamp(new Date(operation.operation_date), true, false)

        let travel_start = ''
        let on_site = ''
        let finished = ''

        if (operation.date_travel_start) {
          travel_start = operation.travelStartTimestring()
        } else {
          let operation_time_data = await operation_time_data_promise
          travel_start = time_service.dateToTimestring(new Date(local_day_timestamp + operation_time_data.driving_start_time * 60 * 1000), false)
        }

        if (operation.date_on_site) {
          on_site = operation.onSiteTimestring()
        } else {
          let operation_time_data = await operation_time_data_promise
          on_site = time_service.dateToTimestring(new Date(local_day_timestamp + operation_time_data.operation_start_time * 60 * 1000), false)
        }

        if (operation.date_finished) {
          finished = operation.finishedTimestring()
        } else {
          let operation_time_data = await operation_time_data_promise
          let operation_end_time = filter == 'average' ? operation_time_data.average_end_time : operation_time_data.estimated_end_time
          finished = time_service.dateToTimestring(new Date(local_day_timestamp + operation_end_time * 60 * 1000), false)
        }
        time_strings.push({travel_start: travel_start, on_site: on_site, finished: finished})
      }
    }
    return time_strings;
  }

  getLunchBreakTimelinePosition(config?: { offset_percent?: number }): {
    left_percent: number,
    right_percent: number,
    middle_percent: number
  } {

    let timeline_start = PATour.timelineStart
    let timeline_end = PATour.timelineEnd

    if (this.lunchBreak && this.operations.length) {

      let timeline_start_ms = Math.floor(timeline_start) * 60 * 60 * 1000
      let timeline_end_ms = Math.ceil(timeline_end) * 60 * 60 * 1000
      let total_mss = timeline_end_ms - timeline_start_ms

      let after_operation = this.operations[this.lunchBreak.after_operation_idx]

      let start_ms = after_operation.calculateOperationDayEndMilliseconds({use_day_timestamp: this.technicianDate.day.local_timestamp})
      let end_ms = start_ms + this.lunchBreak.duration * 60000

      let operation_start_since_day_start_ms = start_ms - timeline_start_ms
      let operation_end_since_day_start_ms = end_ms - timeline_start_ms

      let offset_percent = config?.offset_percent || 0

      let left_percent = (operation_start_since_day_start_ms / total_mss) * 100 + offset_percent
      let right_percent = 100 - (operation_end_since_day_start_ms / total_mss) * 100 - offset_percent
      let middle_percent = (left_percent + (100 - right_percent)) / 2

      return {left_percent: left_percent, right_percent: right_percent, middle_percent: middle_percent}
    } else {
      return {left_percent: 100, right_percent: 100, middle_percent: 50}
    }

  }

  static getLunchBreakTimeConfig(tour_minutes: number): { minutes: number, after_tour_minute: number } {
    if (tour_minutes > 6 * 60) {
      if (tour_minutes > 9 * 60) {
        return {minutes: 45, after_tour_minute: 270}
      } else {
        return {minutes: 30, after_tour_minute: 180}
      }
    }
  }

  getTimeColor(time_filter: string): string {
    let total_time = this.route.time_specific_data[time_filter].total_time
    if (total_time <= 420) {
      return 'orange'
    } else if (total_time <= 540) {
      return 'greenyellow'
    } else if (total_time <= 600) {
      return 'orange'
    } else {
      return 'red'
    }
  }

  getTourIndexForOperationWithId(id: number) {
    return this.operations.findIndex(op => op.id == id)
  }
}

function getWeekdayOpeningsOpenClosePercent(weekday_opening: DayOpening, timeline_start_ms: number, total_mss: number, time_service: PATimeControl) {
  let open_ms = time_service.timeStringToMinutes(weekday_opening.open) * 60 * 1000
  let close_ms = time_service.timeStringToMinutes(weekday_opening.close) * 60 * 1000

  let open_since_day_start_ms = open_ms - timeline_start_ms
  let close_since_day_start_ms = close_ms - timeline_start_ms

  let open_percent = (open_since_day_start_ms / total_mss) * 100
  let close_percent = (close_since_day_start_ms / total_mss) * 100
  return {open_percent, close_percent};
}