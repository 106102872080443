<div class="flex_col" style="width: 100%; height: 100%;">
  <div style="overflow-y: auto; flex: 1;">
    <div id="nodes_container">
      <ng-container *ngFor="let node of nodes">
        <div class="node">
          <div class="node_icon_container" [ngClass]="node.status">
            <fa-icon class="icon" [icon]="icons[node.icon]"></fa-icon>
          </div>
          <div class="node_info" [style.z-index]="expandedNode == node ? '10' : '5'">
            <div class="node_info_connection"></div>
            <mat-expansion-panel style="flex: 1;"
                                 [hideToggle]="!node.main_description"
                                 [style.pointer-events]="node.main_description ? 'default' : 'none'"
                                 [expanded]="expandedNode == node"
                                 (opened)="expandedNode = node"
            >
              <mat-expansion-panel-header>
                <mat-panel-title> {{node.header}} </mat-panel-title>
                <mat-panel-description>
                  <div style="text-align: right; width: 100%;">
                    <ng-container *ngIf="node.day_start_time >= 0">
                      <i *ngIf="node.icon == 'working'">{{dayMinutesToTimeString(node.day_start_time)}} - {{dayMinutesToTimeString(node.day_start_time + node.duration)}}Uhr</i>
                      <div style="font-size: .85rem;" *ngIf="node.icon == 'driving'">
                        <b style="margin-right: 2px;">
                          <u *ngIf="node.status == 'done'">
                            {{Math.round(node.duration)}}min
                          </u>
                        </b>
                        ({{Math.round(node.estimated_duration)}}min EST)
                      </div>
                    </ng-container>
                  </div>
                </mat-panel-description>
              </mat-expansion-panel-header>
              <div class="flex_col">
                <div class="flex_row">
                  <i>Dauer: {{dayMinutesToTimeString(node.duration, true)}}h</i>
                  <a *ngIf="node.link" style="margin-left: 4px; padding-left: 4px; border-left: 1px solid black;" [href]="node.link" target="_blank">hawk-link</a>
                </div>
                <div>
                  {{node.main_description}}
                </div>
              </div>
            </mat-expansion-panel>
          </div>
        </div>
        <div *ngIf="nodes.indexOf(node) != nodes.length - 1" class="node_connection"></div>
      </ng-container>
    </div>
  </div>
  <div style="width: 100%; border-top: 1px solid lightgray; padding-top: 4px;" class="flex_row">
    <div *ngFor="let icon of ['driving', 'working']" class="flex_row" style="margin: auto">
      <fa-icon style="margin-right: 4px;" [icon]="icons[icon]"></fa-icon>
      {{dayMinutesToTimeString(durationSums[icon], true)}}h
    </div>
    <div style="margin: auto;">Gesamt: {{dayMinutesToTimeString(durationSums.total, true)}}h</div>
  </div>
</div>
