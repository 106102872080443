export const AG_GRID_LOCALE_DE = {
  // Set Filter
  selectAll: '(alle)',
  selectAllSearchResults: '(alle Suchergebnisse)',
  addCurrentSelectionToFilter: 'aktuelle Auswahl zu Filter hinzufügen',
  searchOoo: 'Suche...',
  blanks: '(leer)',
  noMatches: 'keine Treffer',

  // WareHouse Grid
  warehouseId: 'ID',
  warehouseDesignation: 'Bezeichnung',
  warehouseAllArticle: 'Alle Artikel',
  warehouseArticle: 'Artikel',
  warehouseBadParts: 'Bad parts',
  warehouseCity: 'Stadt',
  warehouseTechnician: 'Techniker',
  warehousePlaces: 'Plätze',

  // Number Filter & Text Filter
  filterOoo: 'Filter...',
  equals: 'gleich',
  notEqual: 'ungleich',
  blank: 'leer',
  notBlank: 'nicht leer',
  empty: 'bitte wählen',

  // Number Filter
  lessThan: 'kleiner als',
  greaterThan: 'größer als',
  lessThanOrEqual: 'kleinergleich',
  greaterThanOrEqual: 'größergleich',
  inRange: 'Bereich',
  inRangeStart: 'von',
  inRangeEnd: 'bis',

  // Text Filter
  contains: 'enthält',
  notContains: 'enthält nicht',
  startsWith: 'beginnt mit',
  endsWith: 'endet mit',

  // Date Filter
  dateFormatOoo: 'dd.mm.yyyy',
  before: 'vor',
  after: 'nach',

  // Filter Conditions
  andCondition: 'UND',
  orCondition: 'ODER',

  // Filter Buttons
  applyFilter: 'anwenden',
  resetFilter: 'Reset',
  clearFilter: 'Clear',
  cancelFilter: 'Abbruch',

  // Filter Titles
  textFilter: 'Textfilter',
  numberFilter: 'Zahlenfilter',
  dateFilter: 'Datumsfilter',
  setFilter: 'Set Filter',

  // Group Column Filter
  groupFilterSelect: 'Select field:',

  // Advanced Filter
  advancedFilterContains: 'contains',
  advancedFilterNotContains: 'does not contain',
  advancedFilterTextEquals: 'equals',
  advancedFilterTextNotEqual: 'does not equal',
  advancedFilterStartsWith: 'begins with',
  advancedFilterEndsWith: 'ends with',
  advancedFilterBlank: 'is blank',
  advancedFilterNotBlank: 'is not blank',
  advancedFilterEquals: '=',
  advancedFilterNotEqual: '!=',
  advancedFilterGreaterThan: '>',
  advancedFilterGreaterThanOrEqual: '>=',
  advancedFilterLessThan: '<',
  advancedFilterLessThanOrEqual: '<=',
  advancedFilterTrue: 'is true',
  advancedFilterFalse: 'is false',
  advancedFilterAnd: 'AND',
  advancedFilterOr: 'OR',
  advancedFilterApply: 'Apply',
  advancedFilterBuilder: 'Builder',
  advancedFilterValidationMissingColumn: 'Column is missing',
  advancedFilterValidationMissingOption: 'Option is missing',
  advancedFilterValidationMissingValue: 'Value is missing',
  advancedFilterValidationInvalidColumn: 'Column not found',
  advancedFilterValidationInvalidOption: 'Option not found',
  advancedFilterValidationMissingQuote: 'Value is missing an end quote',
  advancedFilterValidationNotANumber: 'Value is not a number',
  advancedFilterValidationInvalidDate: 'Value is not a valid date',
  advancedFilterValidationMissingCondition: 'Condition is missing',
  advancedFilterValidationJoinOperatorMismatch: 'Join operators within a condition must be the same',
  advancedFilterValidationInvalidJoinOperator: 'Join operator not found',
  advancedFilterValidationMissingEndBracket: 'Missing end bracket',
  advancedFilterValidationExtraEndBracket: 'Too many end brackets',
  advancedFilterValidationMessage: 'Expression has an error. ${variable} - ${variable}.',
  advancedFilterValidationMessageAtEnd: 'Expression has an error. ${variable} at end of expression.',
  advancedFilterBuilderTitle: 'Advanced Filter',
  advancedFilterBuilderApply: 'Apply',
  advancedFilterBuilderCancel: 'Cancel',
  advancedFilterBuilderAddButtonTooltip: 'Add Filter or Group',
  advancedFilterBuilderRemoveButtonTooltip: 'Remove',
  advancedFilterBuilderMoveUpButtonTooltip: 'Move Up',
  advancedFilterBuilderMoveDownButtonTooltip: 'Move Down',
  advancedFilterBuilderAddJoin: 'Add Group',
  advancedFilterBuilderAddCondition: 'Add Filter',
  advancedFilterBuilderSelectColumn: 'Select a column',
  advancedFilterBuilderSelectOption: 'Select an option',
  advancedFilterBuilderEnterValue: 'Enter a value...',
  advancedFilterBuilderValidationAlreadyApplied: 'Current filter already applied.',
  advancedFilterBuilderValidationIncomplete: 'Not all conditions are complete.',
  advancedFilterBuilderValidationSelectColumn: 'Must select a column.',
  advancedFilterBuilderValidationSelectOption: 'Must select an option.',
  advancedFilterBuilderValidationEnterValue: 'Must enter a value.',

  // Side Bar
  columns: 'Spalten',
  filters: 'Filter',

  // columns tool panel
  pivotMode: 'Pivot Modus',
  groups: 'Zeilengruppen',
  rowGroupColumnsEmptyMessage: 'Hierher ziehen, um Zeilen zu gruppieren.',
  values: 'Werte',
  valueColumnsEmptyMessage: 'Hierher ziehen zum Zusammenrechnen',
  pivots: 'Spaltenlabel',
  pivotColumnsEmptyMessage: 'Hierher ziehen, um Spaltenlabel zu setzen',

  // Header of the Default Group Column
  group: 'Gruppe',

  // Row Drag
  rowDragRow: 'Zeile',
  rowDragRows: 'Zeilen',

  // Other
  loadingOoo: 'Daten werden geladen...',
  loadingError: 'Fehler',
  noRowsToShow: 'keine Daten vorhanden',
  enabled: 'aktiviert',

  // Menu
  pinColumn: 'Spalte anheften',
  pinLeft: 'links anheften',
  pinRight: 'rechts anheften',
  noPin: 'Nicht angeheftet',
  valueAggregation: 'Value Aggregation',
  noAggregation: 'None',
  autosizeThiscolumn: 'Autosize This Column',
  autosizeAllColumns: 'Autosize All Columns',
  groupBy: 'Group by',
  ungroupBy: 'Un-Group by',
  ungroupAll: 'Un-Group All',
  addToValues: 'Add ${variable} to values',
  removeFromValues: 'Remove ${variable} from values',
  addToLabels: 'Add ${variable} to labels',
  removeFromLabels: 'Remove ${variable} from labels',
  resetColumns: 'Spalten zurücksetzen',
  expandAll: 'Expand All Row Groups',
  collapseAll: 'Close All Row Groups',
  copy: 'Kopieren',
  ctrlC: 'Strg+C',
  ctrlX: 'Strg+X',
  copyWithHeaders: 'Mit Kopfzeile kopieren',
  copyWithGroupHeaders: 'Mit Gruppentiteln kopieren',
  cut: 'Ausschneiden',
  paste: 'Einfügen',
  ctrlV: 'Strg+V',
  export: 'Export',
  csvExport: 'CSV Export',
  excelExport: 'Excel Export',
  columnFilter: 'Spaltenfilter',
  columnChooser: 'Spalten auswählen',
  sortAscending: 'Sortiere aufsteigend',
  sortDescending: 'Sortiere absteigend',
  sortUnSort: 'Sortierung zurücksetzen',

  // Enterprise Menu Aggregation and Status Bar
  sum: 'Summe',
  first: 'First',
  last: 'Last',
  min: 'Min',
  max: 'Max',
  none: 'None',
  count: 'Count',
  avg: 'Average',
  filteredRows: 'Filtered',
  selectedRows: 'Selected',
  totalRows: 'Total Rows',
  totalAndFilteredRows: 'Rows',
  more: 'Mehr',
  to: 'bis',
  of: 'von',
  page: 'Seite',
  pageLastRowUnknown: '?',
  nextPage: 'Nächste Seite',
  lastPage: 'Letzte Seite',
  firstPage: 'Erste Seite',
  previousPage: 'Vorherige Seite',
  pageSizeSelectorLabel: 'Anzahl Seiten:',
  footerTotal: 'Summe',

  // Pivoting
  pivotColumnGroupTotals: 'Summe',

  // Enterprise Menu (Charts)
  pivotChartAndPivotMode: 'Pivot Chart & Pivot Mode',
  pivotChart: 'Pivot Chart',
  chartRange: 'Chart Range',

  columnChart: 'Spalte',
  groupedColumn: 'Grouped',
  stackedColumn: 'Stacked',
  normalizedColumn: '100% Stacked',

  barChart: 'Bar',
  groupedBar: 'Grouped',
  stackedBar: 'Stacked',
  normalizedBar: '100% Stacked',

  pieChart: 'Pie',
  pie: 'Pie',
  donut: 'Donut',

  line: 'Line',

  xyChart: 'X Y (Scatter)',
  scatter: 'Scatter',
  bubble: 'Bubble',

  areaChart: 'Area',
  area: 'Area',
  stackedArea: 'Stacked',
  normalizedArea: '100% Stacked',

  histogramChart: 'Histogram',
  histogramFrequency: "Frequency",

  polarChart: 'Polar',
  radarLine: 'Radar Line',
  radarArea: 'Radar Area',
  nightingale: 'Nightingale',
  radialColumn: 'Radial Column',
  radialBar: 'Radial Bar',

  statisticalChart: 'Statistical',
  boxPlot: 'Box Plot',
  rangeBar: 'Range Bar',
  rangeArea: 'Range Area',

  hierarchicalChart: 'Hierarchical',
  treemap: 'Treemap',
  sunburst: 'Sunburst',

  specializedChart: 'Specialized',
  waterfall: 'Waterfall',
  heatmap: 'Heatmap',

  combinationChart: 'Combination',
  columnLineCombo: 'Column & Line',
  AreaColumnCombo: 'Area & Column',

  // Charts
  pivotChartTitle: 'Pivot Chart',
  rangeChartTitle: 'Range Chart',
  settings: 'Settings',
  data: 'Data',
  format: 'Format',
  categories: 'Categories',
  defaultCategory: '(None)',
  series: 'Series',
  xyValues: 'X Y Values',
  paired: 'Paired Mode',
  axis: 'Axis',
  radiusAxis: 'Radius Axis',
  navigator: 'Navigator',
  color: 'Color',
  thickness: 'Thickness',
  preferredLength: 'Preferred Length',
  xType: 'X Type',
  automatic: 'Automatic',
  category: 'Category',
  number: 'Number',
  time: 'Time',
  autoRotate: 'Auto Rotate',
  xRotation: 'X Rotation',
  yRotation: 'Y Rotation',
  labelRotation: 'Rotation',
  circle: 'Circle',
  polygon: 'Polygon',
  orientation: 'Orientation',
  fixed: 'Fixed',
  parallel: 'Parallel',
  perpendicular: 'Perpendicular',
  radiusAxisPosition: 'Position',
  ticks: 'Ticks',
  width: 'Width',
  height: 'Height',
  length: 'Length',
  padding: 'Padding',
  spacing: 'Spacing',
  chart: 'Chart',
  title: 'Title',
  titlePlaceholder: 'Chart title - double click to edit',
  background: 'Background',
  font: 'Font',
  top: 'Top',
  right: 'Right',
  bottom: 'Bottom',
  left: 'Left',
  labels: 'Labels',
  calloutLabels: 'Callout Labels',
  sectorLabels: 'Sector Labels',
  positionRatio: 'Position Ratio',
  size: 'Size',
  shape: 'Shape',
  minSize: 'Minimum Size',
  maxSize: 'Maximum Size',
  legend: 'Legend',
  position: 'Position',
  markerSize: 'Marker Size',
  markerStroke: 'Marker Stroke',
  markerPadding: 'Marker Padding',
  itemSpacing: 'Item Spacing',
  itemPaddingX: 'Item Padding X',
  itemPaddingY: 'Item Padding Y',
  layoutHorizontalSpacing: 'Horizontal Spacing',
  layoutVerticalSpacing: 'Vertical Spacing',
  strokeWidth: 'Stroke Width',
  offset: 'Offset',
  offsets: 'Offsets',
  tooltips: 'Tooltips',
  callout: 'Callout',
  markers: 'Markers',
  shadow: 'Shadow',
  blur: 'Blur',
  xOffset: 'X Offset',
  yOffset: 'Y Offset',
  lineWidth: 'Line Width',
  lineDash: 'Line Dash',
  lineDashOffset: 'Dash Offset',
  normal: 'Normal',
  bold: 'Bold',
  italic: 'Italic',
  boldItalic: 'Bold Italic',
  predefined: 'Predefined',
  fillOpacity: 'Fill Opacity',
  strokeColor: 'Line Color',
  strokeOpacity: 'Line Opacity',
  histogramBinCount: 'Bin count',
  connectorLine: 'Connector Line',
  seriesItems: 'Series Items',
  seriesItemType: 'Item Type',
  seriesItemPositive: 'Positive',
  seriesItemNegative: 'Negative',
  seriesItemLabels: 'Item Labels',
  columnGroup: 'Spalte',
  barGroup: 'Bar',
  pieGroup: 'Pie',
  lineGroup: 'Line',
  scatterGroup: 'X Y (Scatter)',
  areaGroup: 'Area',
  polarGroup: 'Polar',
  statisticalGroup: 'Statistical',
  hierarchicalGroup: 'Hierarchical',
  specializedGroup: 'Specialized',
  combinationGroup: 'Combination',
  groupedColumnTooltip: 'Grouped',
  stackedColumnTooltip: 'Stacked',
  normalizedColumnTooltip: '100% Stacked',
  groupedBarTooltip: 'Grouped',
  stackedBarTooltip: 'Stacked',
  normalizedBarTooltip: '100% Stacked',
  pieTooltip: 'Pie',
  donutTooltip: 'Donut',
  lineTooltip: 'Line',
  groupedAreaTooltip: 'Area',
  stackedAreaTooltip: 'Stacked',
  normalizedAreaTooltip: '100% Stacked',
  scatterTooltip: 'Scatter',
  bubbleTooltip: 'Bubble',
  histogramTooltip: 'Histogram',
  radialColumnTooltip: 'Radial Column',
  radialBarTooltip: 'Radial Bar',
  radarLineTooltip: 'Radar Line',
  radarAreaTooltip: 'Radar Area',
  nightingaleTooltip: 'Nightingale',
  rangeBarTooltip: 'Range Bar',
  rangeAreaTooltip: 'Range Area',
  boxPlotTooltip: 'Box Plot',
  treemapTooltip: 'Treemap',
  sunburstTooltip: 'Sunburst',
  waterfallTooltip: 'Waterfall',
  heatmapTooltip: 'Heatmap',
  columnLineComboTooltip: 'Column & Line',
  areaColumnComboTooltip: 'Area & Column',
  customComboTooltip: 'Custom Combination',
  innerRadius: 'Inner Radius',
  startAngle: 'Start Angle',
  endAngle: 'End Angle',
  reverseDirection: 'Reverse Direction',
  groupPadding: 'Group Padding',
  seriesPadding: 'Series Padding',
  //group: 'Group',
  tile: 'Tile',
  whisker: 'Whisker',
  cap: 'Cap',
  capLengthRatio: 'Length Ratio',
  labelPlacement: 'Placement',
  inside: 'Inside',
  outside: 'Outside',
  noDataToChart: 'No data available to be charted.',
  pivotChartRequiresPivotMode: 'Pivot Chart requires Pivot Mode enabled.',
  chartSettingsToolbarTooltip: 'Menu',
  chartLinkToolbarTooltip: 'Linked to Grid',
  chartUnlinkToolbarTooltip: 'Unlinked from Grid',
  chartDownloadToolbarTooltip: 'Download Chart',
  seriesChartType: 'Series Chart Type',
  seriesType: 'Series Type',
  secondaryAxis: 'Secondary Axis',

  // ARIA
  ariaAdvancedFilterBuilderItem: '${variable}. Level ${variable}. Press ENTER to edit.',
  ariaAdvancedFilterBuilderItemValidation: '${variable}. Level ${variable}. ${variable} Press ENTER to edit.',
  ariaAdvancedFilterBuilderList: 'Advanced Filter Builder List',
  ariaAdvancedFilterBuilderFilterItem: 'Filter Condition',
  ariaAdvancedFilterBuilderGroupItem: 'Filter Group',
  ariaAdvancedFilterBuilderColumn: 'Spalte',
  ariaAdvancedFilterBuilderOption: 'Option',
  ariaAdvancedFilterBuilderValueP: 'Value',
  ariaAdvancedFilterBuilderJoinOperator: 'Join Operator',
  ariaAdvancedFilterInput: 'Advanced Filter Input',
  ariaChecked: 'checked',
  ariaColumn: 'Spalte',
  ariaColumnGroup: 'Spaltengruppe',
  ariaColumnFiltered: 'gefilterte Spalte',
  ariaColumnSelectAll: 'alle Spalten whählen',
  ariaDateFilterInput: 'Date Filter Input',
  ariaDefaultListName: 'List',
  ariaFilterColumnsInput: 'Spalteneingabe filtern',
  ariaFilterFromValue: 'Filter from value',
  ariaFilterInput: 'Filter Input',
  ariaFilterList: 'Filter List',
  ariaFilterToValue: 'Filter to value',
  ariaFilterValue: 'Filter Value',
  ariaFilterMenuOpen: 'Open Filter Menu',
  ariaFilteringOperator: 'Filtering Operator',
  ariaHidden: 'hidden',
  ariaIndeterminate: 'indeterminate',
  ariaInputEditor: 'Input Editor',
  ariaMenuColumn: 'Press ALT DOWN to open column menu',
  ariaFilterColumn: 'Press CTRL ENTER to open filter',
  ariaRowDeselect: 'Press SPACE to deselect this row',
  ariaRowSelectAll: 'Press Space to toggle all rows selection',
  ariaRowToggleSelection: 'Press Space to toggle row selection',
  ariaRowSelect: 'Press SPACE to select this row',
  ariaSearch: 'Search',
  ariaSortableColumn: 'Press ENTER to sort',
  ariaToggleVisibility: 'Press SPACE to toggle visibility',
  ariaToggleCellValue: 'Press SPACE to toggle cell value',
  ariaUnchecked: 'unchecked',
  ariaVisible: 'visible',
  ariaSearchFilterValues: 'Search filter values',
  ariaPageSizeSelectorLabel: 'Seitengröße',

  // ARIA Labels for Drop Zones
  ariaRowGroupDropZonePanelLabel: 'Row Groups',
  ariaValuesDropZonePanelLabel: 'Values',
  ariaPivotDropZonePanelLabel: 'Column Labels',
  ariaDropZoneColumnComponentDescription: 'Press DELETE to remove',
  ariaDropZoneColumnValueItemDescription: 'Press ENTER to change the aggregation type',
  ariaDropZoneColumnGroupItemDescription: 'Press ENTER to sort',
  // used for aggregate drop zone, format: {aggregation}{ariaDropZoneColumnComponentAggFuncSeparator}{column name}
  ariaDropZoneColumnComponentAggFuncSeparator: ' of ',
  ariaDropZoneColumnComponentSortAscending: 'ascending',
  ariaDropZoneColumnComponentSortDescending: 'descending',

  // ARIA Labels for Dialogs
  ariaLabelColumnMenu: 'Spaltenmenü',
  ariaLabelColumnFilter: 'Spaltenfilter',
  ariaLabelCellEditor: 'Cell Editor',
  ariaLabelDialog: 'Dialog',
  ariaLabelSelectField: 'Select Field',
  ariaLabelRichSelectField: 'Rich Select Field',
  ariaLabelTooltip: 'Tooltip',
  ariaLabelContextMenu: 'Context Menu',
  ariaLabelSubMenu: 'SubMenu',
  ariaLabelAggregationFunction: 'Aggregation Function',
  ariaLabelAdvancedFilterAutocomplete: 'Advanced Filter Autocomplete',
  ariaLabelAdvancedFilterBuilderAddField: 'Advanced Filter Builder Add Field',
  ariaLabelAdvancedFilterBuilderColumnSelectField: 'Advanced Filter Builder Column Select Field',
  ariaLabelAdvancedFilterBuilderOptionSelectField: 'Advanced Filter Builder Option Select Field',
  ariaLabelAdvancedFilterBuilderJoinSelectField: 'Advanced Filter Builder Join Operator Select Field',

  // ARIA Labels for the Side Bar
  ariaColumnPanelList: 'Spaltenliste',
  ariaFilterPanelList: 'Filterliste',

  // Number Format (Status Bar, Pagination Panel)
  thousandSeparator: ' ',
  decimalSeparator: ',',

  // Data types
  true: 'wahr',
  false: 'falsch',
  invalidDate: 'ungültiges Datum',
  invalidNumber: 'ungültige Nummer',
  january: 'Januar',
  february: 'Februar',
  march: 'März',
  april: 'April',
  may: 'Mai',
  june: 'Juni',
  july: 'Juli',
  august: 'August',
  september: 'September',
  october: 'Oktober',
  november: 'November',
  december: 'Dezember',
}