import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { PAOperation } from '../../classes/operation';
import { faWindowClose } from '@fortawesome/free-regular-svg-icons';
import { PATechnician } from '../../classes/technician';
import { faArrowRight, faCheck, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { PAStore } from '../../classes/store';
import { PAPlanningInstructions, TechnicianConstraint } from '../../classes/planning-instructions';
import { CalendarWeekData } from 'src/app/_models/planning-assistant.interface';
import { PAUtil, WeekDay } from '../../classes/util';
import { PATechnicianDate } from '../../classes/technician-date';
import { PALocation } from '../../classes/location';
import { PopoutWindowComponent } from "../../reuseable-components/popout-window/popout-window.component";
import { PAStoreControl } from "../../singletons/pa-store-control";
import { PATourPlannerControl } from "../../singletons/pa-tourplanner-control";
import { PAMapControl } from "../../singletons/pa-map-control";
import { PATimeControl } from "../../singletons/pa-time-control";
import { PAFilterControl } from "../../singletons/pa-filter-control";
import { TechnicianDateCollection } from "../../classes/technician-date-collection";
import { NgIf, NgFor, NgClass } from '@angular/common';

import { TooltipModule } from '@cloudfactorydk/ng2-tooltip-directive';
import { CommonModule } from "@angular/common";
import { FaIconComponent, FontAwesomeModule } from '@fortawesome/angular-fontawesome';

interface TechnicianOperationField {
  operation: PAOperation;
  technician: PATechnician;
}

interface WeekdayOperationField {
  weekday: WeekDay;
  operation: PAOperation;
}

interface StoreOperations {
  store: PAStore;
  operations: PAOperation[];
  location: PALocation;
}

@Component({
  selector: 'hawk-operation-collection',
  templateUrl: './operation-collection.component.html',
  styleUrls: ['./operation-collection.component.scss', './../../styles/common_styles.scss'],
  standalone: true,
  imports: [NgIf, NgFor, FaIconComponent, NgClass, TooltipModule, CommonModule, FontAwesomeModule]
})
export class OperationCollectionComponent implements OnInit, OnChanges, AfterViewInit {

  faWindowClose = faWindowClose
  faCheck = faCheck

  @Input() operations: PAOperation[]
  @Input() technicians: PATechnician[]
  @Input() planningInstructions: PAPlanningInstructions
  @Input() shownCalendarWeeksData: CalendarWeekData[]
  @Input() selectedCalendarWeekData: CalendarWeekData
  @Input() selectedCalendarDay: string
  @Input() filteredWeekdays: WeekDay[] = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday']
  @Input() operationTimeFilter: 'planned' | 'average'
  @Input() showOverviewForTechnicianDateCollections: TechnicianDateCollection[] = []
  @Input() externalWindow: PopoutWindowComponent
  @Input() scrollToStore: PAStore

  @Output() deselectTechnicianEvent = new EventEmitter<PATechnician>()
  @Output() deselectOperationEvent = new EventEmitter<PAOperation>()
  @Output() nextCWEvent = new EventEmitter<void>()
  @Output() prevCWEvent = new EventEmitter<void>()
  @Output() clickTechnicianDateEvent = new EventEmitter<{technician_id: number, technician_dates: PATechnicianDate[], override_shown_data?: boolean}>()

  Util = PAUtil

  mouseDownTOFieldAction: { field: TechnicianOperationField, action: 'add' | 'remove' } = null
  mouseDownWOFieldAction: { field: WeekdayOperationField, action: 'add' | 'remove' } = null
  lastHoveredTOField: TechnicianOperationField = null
  lastHoveredWOField: WeekdayOperationField = null
  currentFieldActionElement: HTMLDivElement = null

  storeOperations: StoreOperations[]

  constructor(
  ) {
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    let operation_changes = changes['operations']
    let operations_changed = !PAUtil.equalSets(new Set(operation_changes?.previousValue), new Set(operation_changes?.currentValue))
    if (operation_changes && operations_changed) {
      let all_changed_operations: PAOperation[] = (operation_changes.previousValue || []).concat(operation_changes.currentValue || []);
      let all_changed_stores = [...new Set(all_changed_operations.map(op => op.getStore()))]
      all_changed_stores.map(store => store.updateStoreLetter())
      this.updateStoreOperations()
    }

    let technician_changes = changes['technicians']
    let technicians_changed = technician_changes && !PAUtil.equalSets(new Set(technician_changes.previousValue), new Set(technician_changes.currentValue))
    if (technicians_changed || operations_changed) {
      let operation_locations = [...new Set(this.operations.map(o => o.ticket.client.location))]
      for (let technician of this.technicians) {
        for (let location of operation_locations) {
          location.getDistanceToLocation(technician.location)
        }
      }
    }

    let scroll_to_store_changes = changes['scrollToStore']
    if (scroll_to_store_changes?.currentValue) {
      this.scrollToStoreAction(scroll_to_store_changes.currentValue)
    }

    let planning_instruction_changes = changes['planningInstruction']
    if (planning_instruction_changes && planning_instruction_changes.currentValue) {
      let pi = planning_instruction_changes.currentValue as PAPlanningInstructions
      pi.resetPlanningInstructions()
    }
  }

  scrollToStoreAction(store: PAStore): void {
    if (this.storeOperations && this.storeOperations.find(store_operations => store_operations.store == store)) {
      let store_elem = this.getDocument().getElementById(store.address_company)
      store_elem.scrollIntoView({behavior: "smooth", block: "start"})
    }
  }

  tourComparisonsIncludeTechnicianDate(technician_date: PATechnicianDate): boolean {
    return !!this.showOverviewForTechnicianDateCollections.find(tdc => tdc.technicianDates.includes(technician_date))
  }

  selectStore(store: PAStore) {
    PAStoreControl.Instance.selectStore(store, {center_in_map: PAMapControl.Instance.mainMapContainer.map})
  }

  getPlanningInstructionClass(operation: PAOperation, technician: PATechnician): string {

    let constraint_technician_amount = 0
    let technician_constraints = this.planningInstructions.operationConstraintMap.get(operation.id)
    if (technician_constraints) {
      constraint_technician_amount = technician_constraints.filter(tc => this.technicians.includes(tc.technician)).length
    }

    let otc = this.planningInstructions.getOperationsTechnicianConstraint(operation, technician)
    if (otc) {
      if (!this.planningInstructions.checkGeneralDoability(operation, technician)) {
        return 'not_doable'
      }
      if (constraint_technician_amount > 1) {
        return 'multiple_selection'
      } else {
        return 'only_selection'
      }
    } else {
      if (constraint_technician_amount >= 1) {
        return 'not_selected'
      } else {
        return ''
      }
    }
  }

  technicianDateHasUnfittingOperationWithId(technician_date: PATechnicianDate, operation_id: number): boolean {
    return !!technician_date.changedTourContainer.unfittingOperations.find(op => op.id == operation_id)
  }

  getWeekdayPlanningInstructionClass(operation: PAOperation, weekday: string): string {

    if (this.getShownCalendarWeekData().weekdays[weekday].timestamp < PATimeControl.Instance.dateToTimestamp(new Date(), true, true)) {
      return 'unselectable striped_background'
    }

    let constraint_amount = 0
    let technician_constraint: TechnicianConstraint
    let technician_constraints = this.planningInstructions.operationConstraintMap.get(operation.id)
    if (technician_constraints) {
      technician_constraint = technician_constraints.find(tc => tc.technician == this.technicians[0])
      constraint_amount = technician_constraint?.day_timestamps?.length
    }

    if (technician_constraint?.day_timestamps && technician_constraint.day_timestamps.includes(this.getShownCalendarWeekData().weekdays[weekday].timestamp)) {
      if (constraint_amount > 1) {
        return 'multiple_selection'
      } else {
        return 'only_selection'
      }
    } else {
      if (constraint_amount >= 1) {
        return 'not_selected'
      } else {
        return ''
      }
    }
  }

  getTechniciansSelectedAdditionalTime(technician: PATechnician): number {
    let selected_operations: PAOperation[] = []
    for (let store_operations of this.storeOperations || []) {
      selected_operations = selected_operations.concat(store_operations.operations.filter(op => this.planningInstructions.getOperationsTechnicianConstraint(op, technician)))
    }
    return selected_operations.reduce((sum, operation) => {
      let avg = technician.getPriorityBasedAverageOperationTime(operation.ticket.priority.id)
      return sum + (avg >= 0 ? avg : operation.ticket.time_estimation)
    }, 0)
  }

  getDocument(): Document {
    return this.externalWindow?.isPoppedOut ? this.externalWindow.getDocument() : document
  }

  onFieldMouseDown(field: TechnicianOperationField): void {
    let action: 'remove' | 'add' = this.planningInstructions.getOperationsTechnicianConstraint(field.operation, field.technician) ? 'remove' : 'add'
    this.mouseDownTOFieldAction = {field: field, action: action}
    let oc_elem = this.getDocument().getElementById('operation_collection')
    oc_elem.onmouseup = () => {
      this.mouseUpOrLeave()
    }
    oc_elem.onmouseleave = () => {
      this.mouseUpOrLeave()
    }
    this.updateFieldActionElement(field);
  }

  onWOFieldMouseDown(field: WeekdayOperationField): void {
    if (this.weekdayOperationFieldIsSelectable(field)) {
      let technician_constraint = this.planningInstructions.getOperationsTechnicianConstraint(field.operation, this.technicians[0])
      let constraint_timestamps = technician_constraint?.day_timestamps
      let action: 'remove' | 'add' = constraint_timestamps && constraint_timestamps.includes(this.getShownCalendarWeekData().weekdays[field.weekday].timestamp) ? 'remove' : 'add'
      this.mouseDownWOFieldAction = {field: field, action: action}
      let oc_elem = this.getDocument().getElementById('operation_collection')
      oc_elem.onmouseup = () => {
        this.mouseUpOrLeaveWOField()
      }
      oc_elem.onmouseleave = () => {
        this.mouseUpOrLeaveWOField()
      }
      this.updateWOFieldActionElement(field);
    }
  }

  mouseUpOrLeave(): void {
    if (this.mouseDownTOFieldAction && this.lastHoveredTOField) {
      let diff_data = this.getFieldsDiffData(this.lastHoveredTOField, this.mouseDownTOFieldAction.field)
      if (this.mouseDownTOFieldAction.action == 'add') {
        this.planningInstructions.addOperationsTechnicianConstraints(diff_data.operations, diff_data.technicians)
      } else {
        this.planningInstructions.removeOperationsTechnicianConstraints(diff_data.operations, diff_data.technicians)
      }
    }
    this.mouseDownTOFieldAction = null;
    if (this.currentFieldActionElement) {
      this.currentFieldActionElement.remove()
      this.currentFieldActionElement = null
      this.lastHoveredTOField = null
    }
  }

  mouseUpOrLeaveWOField(): void {
    if (this.mouseDownWOFieldAction && this.lastHoveredWOField) {
      let diff_data = this.getWOFieldsDiffData(this.lastHoveredWOField, this.mouseDownWOFieldAction.field)
      if (this.mouseDownWOFieldAction.action == 'add') {
        let timestamps = diff_data.weekdays.map(wd => this.getShownCalendarWeekData().weekdays[wd].timestamp)
        this.planningInstructions.addOperationsTechnicianConstraints(diff_data.operations, this.technicians, timestamps)
        if (diff_data.weekdays.length && this.technicians.length == 1) {
          for (let timestamp of timestamps) {
            for (let technician of this.technicians) {
              let technician_date = technician.getTechnicianDate(timestamp, true, true)
              if (!this.tourComparisonsIncludeTechnicianDate(technician_date)) {
                this.clickTechnicianDateEvent.emit({technician_id: technician_date.id, technician_dates: [technician_date]})
              }
            }
          }
        }
      } else {
        this.planningInstructions.removeOperationsTechnicianConstraints(diff_data.operations, this.technicians, diff_data.weekdays.map(wd => this.getShownCalendarWeekData().weekdays[wd].timestamp))
      }
    }
    this.mouseDownWOFieldAction = null;
    if (this.currentFieldActionElement) {
      this.currentFieldActionElement.remove()
      this.currentFieldActionElement = null
      this.lastHoveredWOField = null
    }
  }

  onFieldMouseEnter(field: TechnicianOperationField): void {
    if (this.mouseDownTOFieldAction) {
      this.updateFieldActionElement(field);
    }
  }

  onWOFieldMouseEnter(field: WeekdayOperationField): void {
    if (this.mouseDownWOFieldAction && this.weekdayOperationFieldIsSelectable(field)) {
      this.updateWOFieldActionElement(field);
    }
  }

  private updateFieldActionElement(field: TechnicianOperationField) {
    let current_document = this.getDocument();

    let from_field = this.mouseDownTOFieldAction.field;
    let idx_diffs = this.getFieldsDiffData(field, this.mouseDownTOFieldAction.field);
    let elem_from = current_document.getElementById(this.fieldToElementID(from_field));
    let elem_to = current_document.getElementById(this.fieldToElementID(field));
    let field_action_container = current_document.getElementById('field_action_container');

    if (!this.currentFieldActionElement) {
      this.currentFieldActionElement = current_document.createElement('div');
      this.currentFieldActionElement.className = `field_action_element ${this.mouseDownTOFieldAction.action}`;

      let icon_element = current_document.createElement('div')
      icon_element.className = 'field_action_icon_element'
      icon_element.innerHTML = this.mouseDownTOFieldAction.action == 'add' ? '&plus;' : '&minus;'
      this.currentFieldActionElement.insertBefore(icon_element, null)

      field_action_container.insertBefore(this.currentFieldActionElement, null);
    }

    this.currentFieldActionElement.style.left = `${Math.min(elem_from.offsetLeft, elem_to.offsetLeft)}px`;
    this.currentFieldActionElement.style.top = `${Math.min(elem_from.offsetTop, elem_to.offsetTop)}px`;

    this.currentFieldActionElement.style.width = `${(Math.abs(idx_diffs.t_diff) + 1) * elem_from.offsetWidth}px`;
    let elem_height = (Math.abs(idx_diffs.o_diff) + 1) * elem_from.offsetHeight + Math.abs(idx_diffs.s_diff) * 16;

    this.currentFieldActionElement.style.height = `${elem_height}px`;
    this.lastHoveredTOField = field
  }

  private updateWOFieldActionElement(field: WeekdayOperationField) {
    let current_document = this.getDocument();

    let from_field = this.mouseDownWOFieldAction.field;
    let idx_diffs = this.getWOFieldsDiffData(field, this.mouseDownWOFieldAction.field);
    let elem_from = current_document.getElementById(this.woFieldToElementID(from_field));
    let elem_to = current_document.getElementById(this.woFieldToElementID(field));
    let field_action_container = current_document.getElementById('field_action_container');

    if (!this.currentFieldActionElement) {
      this.currentFieldActionElement = current_document.createElement('div');
      this.currentFieldActionElement.className = `field_action_element ${this.mouseDownWOFieldAction.action}`;

      let icon_element = current_document.createElement('div')
      icon_element.className = 'field_action_icon_element'
      icon_element.innerHTML = this.mouseDownWOFieldAction.action == 'add' ? '&plus;' : '&minus;'
      this.currentFieldActionElement.insertBefore(icon_element, null)

      field_action_container.insertBefore(this.currentFieldActionElement, null);
    }

    this.currentFieldActionElement.style.left = `${Math.min(elem_from.offsetLeft, elem_to.offsetLeft)}px`;
    this.currentFieldActionElement.style.top = `${Math.min(elem_from.offsetTop, elem_to.offsetTop)}px`;

    this.currentFieldActionElement.style.width = `${(Math.abs(idx_diffs.w_diff) + 1) * elem_from.offsetWidth}px`;
    let elem_height = (Math.abs(idx_diffs.o_diff) + 1) * elem_from.offsetHeight + Math.abs(idx_diffs.s_diff) * 16;

    this.currentFieldActionElement.style.height = `${elem_height}px`;
    this.lastHoveredWOField = field
  }

  fieldToElementID(field: TechnicianOperationField): string {
    return `o:${field.operation.id}_t:${field.technician.id}`
  }

  woFieldToElementID(field: WeekdayOperationField): string {
    return `o:${field.operation.id}_w:${field.weekday}`
  }

  getFieldsDiffData(to_field: TechnicianOperationField, from_field: TechnicianOperationField): {
    t_diff: number,
    s_diff: number,
    o_diff: number,
    technicians: PATechnician[],
    operations: PAOperation[]
  } {
    let to_technician_idx = this.technicians.indexOf(to_field.technician)
    let to_operation_store = this.storeOperations.find(store => store.operations.includes(to_field.operation))
    let to_operation_store_idx = this.storeOperations.indexOf(to_operation_store)
    let to_operation_idx = this.storeOperations[to_operation_store_idx].operations.indexOf(to_field.operation)

    let from_technician_idx = this.technicians.indexOf(from_field.technician)
    let from_operation_store = this.storeOperations.find(store => store.operations.includes(from_field.operation))
    let from_operation_store_idx = this.storeOperations.indexOf(from_operation_store)
    let from_operation_idx = this.storeOperations[from_operation_store_idx].operations.indexOf(from_field.operation)

    let technician_idx_diff = to_technician_idx - from_technician_idx
    let store_idx_diff = to_operation_store_idx - from_operation_store_idx
    let operation_idx_diff: number = 0

    let t_start_idx = Math.min(from_technician_idx, to_technician_idx)
    let t_end_idx = Math.max(from_technician_idx, to_technician_idx)

    let technicians: PATechnician[] = this.technicians.slice(t_start_idx, t_end_idx + 1)
    let operations: PAOperation[] = [];

    ({
      operation_idx_diff,
      operations
    } = this.getOperationIdxDiff(store_idx_diff, operation_idx_diff, to_operation_idx, from_operation_idx, operations, to_operation_store, from_operation_store, to_operation_store_idx, from_operation_store_idx));

    return {
      t_diff: technician_idx_diff,
      s_diff: store_idx_diff,
      o_diff: operation_idx_diff,
      technicians: technicians,
      operations: operations
    }
  }

  getWOFieldsDiffData(to_field: WeekdayOperationField, from_field: WeekdayOperationField): {
    w_diff: number,
    s_diff: number,
    o_diff: number,
    weekdays: string[],
    operations: PAOperation[]
  } {
    let to_weekday_idx = this.filteredWeekdays.indexOf(to_field.weekday)
    let to_operation_store = this.storeOperations.find(store => store.operations.includes(to_field.operation))
    let to_operation_store_idx = this.storeOperations.indexOf(to_operation_store)
    let to_operation_idx = this.storeOperations[to_operation_store_idx].operations.indexOf(to_field.operation)

    let from_weekday_idx = this.filteredWeekdays.indexOf(from_field.weekday)
    let from_operation_store = this.storeOperations.find(store => store.operations.includes(from_field.operation))
    let from_operation_store_idx = this.storeOperations.indexOf(from_operation_store)
    let from_operation_idx = this.storeOperations[from_operation_store_idx].operations.indexOf(from_field.operation)

    let weekday_idx_diff = to_weekday_idx - from_weekday_idx
    let store_idx_diff = to_operation_store_idx - from_operation_store_idx
    let operation_idx_diff: number = 0

    let t_start_idx = Math.min(from_weekday_idx, to_weekday_idx)
    let t_end_idx = Math.max(from_weekday_idx, to_weekday_idx)

    let weekdays: string[] = this.filteredWeekdays.slice(t_start_idx, t_end_idx + 1)
    let operations: PAOperation[] = [];

    ({
      operation_idx_diff,
      operations
    } = this.getOperationIdxDiff(store_idx_diff, operation_idx_diff, to_operation_idx, from_operation_idx, operations, to_operation_store, from_operation_store, to_operation_store_idx, from_operation_store_idx));

    return {
      w_diff: weekday_idx_diff,
      s_diff: store_idx_diff,
      o_diff: operation_idx_diff,
      weekdays: weekdays,
      operations: operations
    }
  }

  private getOperationIdxDiff(store_idx_diff: number, operation_idx_diff: number, to_operation_idx: number, from_operation_idx: number, operations: PAOperation[], to_operation_store: StoreOperations, from_operation_store: StoreOperations, to_operation_store_idx: number, from_operation_store_idx: number) {
    if (store_idx_diff == 0) {
      operation_idx_diff = to_operation_idx - from_operation_idx;
      let o_start_idx = Math.min(from_operation_idx, to_operation_idx);
      let o_end_idx = Math.max(from_operation_idx, to_operation_idx);
      operations = to_operation_store.operations.slice(o_start_idx, o_end_idx + 1);
    } else if (store_idx_diff < 0) {

      operations = operations.concat(from_operation_store.operations.slice(0, from_operation_idx + 1));
      operations = operations.concat(to_operation_store.operations.slice(to_operation_idx));

      let from_operation_count = from_operation_idx + 1;
      let to_operation_count = to_operation_store.operations.length - to_operation_idx;
      let store_operations_between_count = 0;

      if (Math.abs(store_idx_diff) > 1) {
        let store_operations_between = this.storeOperations.slice(to_operation_store_idx + 1, to_operation_store_idx + (Math.abs(store_idx_diff)));
        let all_store_operations_between = store_operations_between.reduce((all_operations, store_operations) => all_operations.concat(store_operations.operations), []);
        store_operations_between_count = all_store_operations_between.length;
        operations = operations.concat(all_store_operations_between);
      }
      operation_idx_diff = (-from_operation_count) + (-to_operation_count) + (-store_operations_between_count) + 1;

    } else if (store_idx_diff > 0) {

      operations = operations.concat(from_operation_store.operations.slice(from_operation_idx));
      operations = operations.concat(to_operation_store.operations.slice(0, to_operation_idx + 1));

      let from_operation_count = from_operation_store.operations.length - from_operation_idx;
      let to_operation_count = to_operation_idx + 1;
      let store_operations_between_count = 0;

      if (store_idx_diff > 1) {
        let store_operations_between = this.storeOperations.slice(from_operation_store_idx + 1, from_operation_store_idx + store_idx_diff);
        let all_store_operations_between = store_operations_between.reduce((all_operations, store_operations) => all_operations.concat(store_operations.operations), []);
        store_operations_between_count = all_store_operations_between.length;
        operations = operations.concat(all_store_operations_between);
      }
      operation_idx_diff = from_operation_count + to_operation_count + store_operations_between_count - 1;
    }
    return {operation_idx_diff, operations};
  }

  getShownCalendarWeekData(): CalendarWeekData {
    if (this.selectedCalendarWeekData) {
      return this.selectedCalendarWeekData
    } else {
      return this.shownCalendarWeeksData[0]
    }
  }

  updateStoreOperations(): void {
    let store_operations: { store: PAStore, operations: PAOperation[], location: PALocation }[] = []
    let stores = [...new Set(this.operations.map(op => op.getStore()))]
    for (let store of stores) {
      let operations = this.operations.filter(op => op.getStore() == store)
      store_operations.push({store: store, operations: operations, location: operations[0].ticket.client.location})
    }
    this.storeOperations = store_operations
  }

  weekdayOperationFieldIsSelectable(wo_field: WeekdayOperationField): boolean {
    return this.getShownCalendarWeekData().weekdays[wo_field.weekday].timestamp >= PATimeControl.Instance.dateToTimestamp(new Date(), true, true)
  }

  protected readonly PAOperation = PAOperation;
  protected readonly faArrowRight = faArrowRight;
  protected readonly faExclamationTriangle = faExclamationTriangle;

  showNewTourTimeForTechnicianDate(technician_date: PATechnicianDate) {
    return this.tourComparisonsIncludeTechnicianDate(technician_date) && !technician_date.isPastTechnicianDate()
  }

  protected readonly PATimeControl = PATimeControl;
  protected readonly PATourPlannerControl = PATourPlannerControl;
  protected readonly PAFilterControl = PAFilterControl;

  openCompleteCalendarWeekForTechnician(technician: PATechnician) {
    this.clickTechnicianDateEvent.emit({technician_id: technician.id, technician_dates: this.filteredWeekdays.map(wd => technician.getTechnicianDate(this.getShownCalendarWeekData().weekdays[wd].timestamp)), override_shown_data: true})
  }
}