<div class="form-group" [formGroup]="formGroup">
    <ng-select
    [items]="senderRecieverTypes"
    formControlName="{{prefix}}type"
    placeholder="Absender"
    [clearable]="false"
    bindLabel="name"
    bindValue="id"
    (change)="onAddressTypeChange($event)"
    >
    <ng-template ng-label-tmp let-item="item">
        {{ item.name }}
    </ng-template>
    <ng-template
        ng-option-tmp
        let-item="item"
        let-search="searchTermStatus"
    >
        <div
        *ngIf="
            item.name
            .toLowerCase()
            .includes(searchTerm.toLowerCase())
        "
        >
        {{ item.name }}
        </div>
    </ng-template>
    </ng-select>
</div>
<span>
</span>
<div [formGroup]="formGroup" class="form-group my-2" *ngIf="formGroup.controls[prefix + 'type'].value == 'TECHNICIAN' && technicians">
    <ng-select
    [items]="technicians"
    bindLabel="name"
    bindValue="id"
    formControlName="{{prefix}}technician"
    placeholder="Techniker"
    [clearable]="false"
    (change)="onAddressChange($event)"
    >
    <ng-template ng-label-tmp let-item="item">
        {{ item.name }}
    </ng-template>
    <ng-template
        ng-option-tmp
        let-item="item"
        let-search="searchTermStatus"
    >
        <div
        *ngIf="
            item.name
            .toLowerCase()
            .includes(searchTerm.toLowerCase())
        "
        >
        {{ item.name }}
        </div>
    </ng-template>
    </ng-select>
</div>
<div [formGroup]="formGroup" class="form-group my-2" *ngIf="formGroup.controls[prefix + 'type'].value == 'WAREHOUSE' && wareHouses">
    <ng-select
    [items]="wareHouses"
    bindLabel="label"
    bindValue="id"
    formControlName="{{prefix}}warehouse"
    placeholder="Lager"
    [clearable]="false"
    (change)="onAddressChange($event)"
    >
    <ng-template ng-label-tmp let-item="item">
        {{ item.label }}
    </ng-template>
    <ng-template
        ng-option-tmp
        let-item="item"
        let-search="searchTermStatus"
    >
        <div
        *ngIf="
            item.label
            .toLowerCase()
            .includes(searchTerm.toLowerCase())
        "
        >
        {{ item.label }}
        </div>
    </ng-template>
    </ng-select>
</div>
<div [formGroup]="formGroup" class="form-group my-2 d-flex gap-2" *ngIf="formGroup.controls[prefix + 'type'].value == 'CUSTOMER' && customers">
    <div class="form-group col">
        <ng-select
        [items]="customers"
        bindLabel="name"
        bindValue="id"
        formControlName="{{prefix}}customer"
        placeholder="Kunde"
        [clearable]="false"
        (change)="onChangeCustomer($event);"
        [loading]="loadingCustomers"
        [virtualScroll]="true"
        (scrollToEnd)="fetchMoreCustomers()"
        >
        <ng-template ng-label-tmp let-item="item">
            {{ item.name }}
        </ng-template>
        <ng-template
            ng-option-tmp
            let-item="item"
            let-search="searchTermStatus"
        >
            <div
            *ngIf="
                item.name
                .toLowerCase()
                .includes(searchTerm.toLowerCase())
            "
            >
            {{ item.name }}
            </div>
        </ng-template>
        </ng-select>
    </div>
    <div class="form-group col">
        <ng-select
        [items]="filteredStores"
        bindLabel="name"
        bindValue="id"
        formControlName="{{prefix}}store"
        placeholder="Store"
        [clearable]="false"
        (change)="onAddressChange($event)"
        [loading]="loading"
        [virtualScroll]="true"
        (scrollToEnd)="fetchMoreStores()"
        >
        <ng-template ng-label-tmp let-item="item">
            {{ item.name }} - {{ item.nr }}
        </ng-template>
        <ng-template
            ng-option-tmp
            let-item="item"
            let-search="searchTermStatus"
        >
            <div
            *ngIf="
                item.name
                .toLowerCase()
                .includes(searchTerm.toLowerCase())
            "
            >
            {{ item.name }} - {{ item.nr }}
            </div>
        </ng-template>
        </ng-select>
    </div>
</div>