import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { CalendarComponent } from '../calendar/calendar.component';
import { PAOperation } from '../classes/operation';
import { PAProject } from '../classes/project';
import { PATechnician } from '../classes/technician';
import { PAUtil } from '../classes/util';
import { PAFilterControl } from "../singletons/pa-filter-control";
import { PATimeControl } from "../singletons/pa-time-control";
import { PAMapControl } from "../singletons/pa-map-control";
import { CommonModule, NgClass, NgFor, NgIf } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonToggle, MatButtonToggleGroup, MatButtonToggleModule } from '@angular/material/button-toggle';
import { ExpandBarHeaderComponent } from '../reuseable-components/expand-bar-header/expand-bar-header.component';
import { MatButtonModule } from "@angular/material/button";

@Component({
  selector: 'hawk-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss', './../styles/common_styles.scss'],
  standalone: true,
  imports: [NgClass, ReactiveFormsModule, FormsModule, MatButtonToggleGroup, MatButtonToggle, NgFor, ExpandBarHeaderComponent, NgIf, CommonModule, MatButtonModule, MatButtonToggleModule]
})
export class FilterComponent implements OnInit {

  protected readonly faTrash = faTrash

  protected readonly Operation = PAOperation
  protected readonly Project = PAProject
  protected readonly Technician = PATechnician
  protected readonly Util = PAUtil
  protected readonly FilterComponent = FilterComponent

  @Input() technicians: PATechnician[]
  @Input() operations: PAOperation[]

  public fromCalendarWeekNumber: number
  public fromCalendarWeekYear: number
  public untilCalendarWeekNumber: number
  public untilCalendarWeekYear: number

  showTechnicianFilter: boolean = false
  technicianFilterString = ''

  showOperationFilter: boolean = false
  operationFilterString = ''

  // Calendar Week Filter
  public temporaryOperationUpdateFinished = true
  public abortLastTemporaryOperationUpdateCalculation: boolean = false

  // Projects
  public showProjectFilter = false

  constructor(
  ) {
  }

  ngOnInit(): void {
    this.setCalendarWeekLoadRange()
    this.updateOperationFilterString()
    this.updateTechnicianFilterString()
  }

  ngOnChanges(changes: SimpleChanges): void {

    let technician_changes = changes['technicians']
    if (technician_changes) {
      PAFilterControl.Instance.updateTechnicianFilter()
    }

    let operation_changes = changes['operations']
    if (operation_changes && operation_changes.currentValue) {
      if (!operation_changes.previousValue || !(PAUtil.equalSets(new Set(operation_changes.currentValue.map(operation => operation.id)), new Set(operation_changes.previousValue.map(operation => operation.id))))) {
        PAFilterControl.Instance.updateOperationFilter()
      }
    }
  }

  public updateOperationHasMaterialFilter(event: Event): void {
    const input = event.target as HTMLInputElement
    PAFilterControl.Instance.operationHasMaterialFilter = input.value
    this.updateOperationFilterString()
    PAFilterControl.Instance.updateOperationFilter()
  }

  public updateOperationHasDeadlineFilter(event: Event): void {
    const input = event.target as HTMLInputElement
    PAFilterControl.Instance.operationHasDeadlineFilter = input.value
    this.updateOperationFilterString()
    PAFilterControl.Instance.updateOperationFilter()
  }

  public updateTechnicianOwnerFilter(event: Event) {
    const input = event.target as HTMLInputElement
    PAFilterControl.Instance.selectedTechnicianOwnerFilter = input.value
    this.updateTechnicianFilterString()
    PAFilterControl.Instance.updateTechnicianFilter()
  }

  public updateTechnicianOperationsInCalendarWeekRangeFilter(event: Event) {
    const input = event.target as HTMLInputElement
    PAFilterControl.Instance.selectedTechnicianOperationsInCalendarWeekRangeFilter = input.value
    this.updateTechnicianFilterString()
    PAFilterControl.Instance.updateTechnicianFilter()
  }

  public setCalendarWeekLoadRange() {
    this.fromCalendarWeekNumber = PATimeControl.Instance.getCurrentCalendarWeek().number
    this.fromCalendarWeekYear = PATimeControl.Instance.getCurrentCalendarWeek().year
    this.untilCalendarWeekNumber = PATimeControl.Instance.getCalendarWeekInNWeeks(CalendarComponent.defaultInitializedWeekAmount - 1).number
    this.untilCalendarWeekYear = PATimeControl.Instance.getCalendarWeekInNWeeks(CalendarComponent.defaultInitializedWeekAmount - 1).year
  }

  selectTimeFilter(time_filter: 'planned' | 'average') {
    if (['planned', 'average'].indexOf(time_filter) >= 0) {
      PAFilterControl.Instance.selectedOperationTimeFilter = time_filter
    }
  }

  closeAllFilters() {
    this.showOperationFilter = this.showProjectFilter = this.showTechnicianFilter = false
  }

  updateStoreIsoDistance(event: Event) {
    const input = event.target as HTMLInputElement
    const distance: string = input.value

    let distance_number = Number.parseInt(distance)
    if (distance_number || distance_number == 0) {
      PAMapControl.Instance.isochroneDistance = distance_number
    }
  }

  toggleSelectedTimeFilter(): void {
    if (PAFilterControl.Instance.selectedOperationTimeFilter == 'planned') {
      this.selectTimeFilter('average')
    } else {
      this.selectTimeFilter('planned')
    }
  }

  updateTechnicianFilterString(): void {
    this.technicianFilterString = PAFilterControl.Instance.getTechnicianFilterString()
  }

  updateOperationFilterString(): void {
    this.operationFilterString = PAFilterControl.Instance.getOperationFilterString()
  }

  protected readonly PAMapControl = PAMapControl;
  protected readonly PAFilterControl = PAFilterControl;
}