import { CommonModule } from "@angular/common"
import { Component, OnInit, OnDestroy } from "@angular/core"
import { ReactiveFormsModule, FormsModule } from "@angular/forms"
import { RouterModule, ActivatedRoute, Router } from "@angular/router"
import { Subscription } from "rxjs"
import { unsubscribeAll } from "src/app/_helpers/unsubscribe.helper"
import { ProjectService, SessionService, TicketService } from "src/app/_services"
import { Project } from "src/app/_models"
import { WorkflowUploadComponent } from "src/app/workflow-upload/workflow-upload.component"

@Component({
  imports: [CommonModule, ReactiveFormsModule, FormsModule, RouterModule, WorkflowUploadComponent],
    selector: 'hawk-project-temp',
    templateUrl: './project-temp.component.html',
    styleUrls: ['./project-temp.component.scss'],
})
export class ProjectTempComponent implements OnInit, OnDestroy {


  public project: Project
  public projectId: number
  private subscriptions: Subscription[] = []


constructor(
    private route: ActivatedRoute,
    private projectService: ProjectService,
    private sessionService: SessionService,
  ) { }

  ngOnInit(): void {
    this.projectId = parseInt(this.route.snapshot.paramMap.get('id'))

    this.loadProject()
    this.loadPermissions()

  }

  public ngOnDestroy() {
    unsubscribeAll(this.subscriptions)
  }

  public loadProject() {
    const id = this.projectId
    this.subscriptions.push(
      this.projectService.getProject(id).subscribe(
        (data) => {
          this.updateProject(data)
        },
        (err) => {
          console.error(err)
        },
      )
    )
  }

  public updateProject(projectData: Project) {
    this.project = projectData
  }

  public loadPermissions() {
    this.subscriptions.push(
      this.sessionService.rolePermissions().subscribe(
        (data) => {
          if (this.checkPermission(["ACCOUNTANT"], data.roles)) {
            //this.isBookkeeper = true
          }
          
        },
        (err) => {
          console.error(err)
        },
      )
    )
  }

  private checkPermission(validPermissions: string[], userPermissions: string[]): boolean {
    for (let role of userPermissions) {
      if (validPermissions.includes(role)) {
        return true
      }
    }
    return false
  }
}