import { Component, OnInit } from '@angular/core';
import { ArticleTemplate, EditEventType } from '../../_models';
import { FormControl, FormGroup, FormsModule, Validators } from '@angular/forms';
import { CommonModule } from "@angular/common";
import { ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatCheckboxModule } from "@angular/material/checkbox";

import { ActivatedRoute, Router } from '@angular/router'
import { ArticleTemplateService } from '../../_services'
import { EditInputComponent } from '../_shared/edit-input/edit-input.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatTableModule } from '@angular/material/table';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgSelectModule } from '@ng-select/ng-select';
@Component({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    MatCheckboxModule,
    MatTableModule,
    EditInputComponent,
    NgSelectModule,
    FontAwesomeModule
  ],
    selector: 'hawk-article-template-show',
    templateUrl: './article-template-show.component.html',
    styleUrls: ['./article-template-show.component.scss'],
})
export class ArticleTemplateShowComponent implements OnInit {
  public articleTemplateId: String | string | number
  public newArticleTemplate: boolean = false
  public submittingTemplate: boolean = false
  public articleTemplate: ArticleTemplate;
  public articleTemplateForm: FormGroup;
  public editingProps = {
    description: false,
    product_category: false,
    vendor: false,
    part_nr: false,
    end_of_life: false,
    sn: false,
    comment1: false,
    comment2: false,
    comment3: false,
    info: false,
    purchase_value: false,
    selling_value: false,
    warning_threshold: false,
    minimum_stock: false,
    unidata_report: false,
    unidatahdd: false,
    unidata_reinstall: false,
    active: false
  }

  // Column Definitions: Defines the columns to be displayed.
  constructor(
    private articleTemplateService: ArticleTemplateService,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe((params) => {
      this.articleTemplateId = params.get('id')
      if(Number(this.articleTemplateId) > 0) {
        this.newArticleTemplate = false;
        this.loadArticleTemplate()
      } else {
        this.newArticleTemplate = true;
        this.articleTemplateForm = new FormGroup({
          description: new FormControl(null, Validators.required),
          product_category: new FormControl(null, Validators.required),
          vendor: new FormControl(null, Validators.required),
          part_nr: new FormControl(),
          end_of_life: new FormControl(false),
          sn: new FormControl(true),
          comment1: new FormControl(''),
          comment2: new FormControl(''),
          comment3: new FormControl(''),
          info: new FormControl(''),
          purchase_value: new FormControl(),
          selling_value: new FormControl(),
          warning_threshold: new FormControl(),
          minimum_stock: new FormControl(),
          unidata_report: new FormControl(false),
          unidatahdd: new FormControl(false),
          unidata_reinstall: new FormControl(false)
        });
      }
    });
  }

  private loadArticleTemplate() {
    this.articleTemplateService.getArticleTemplate(this.articleTemplateId).subscribe(
      {
        next: (response) => {
          this.articleTemplate = response;
        },
        error: (error) => {
          console.log('error', error);
        }
      }
    )
  }

  public onDelete() {
    if(confirm('Möchten Sie die Artikelvorlage wirklich löschen?')) {
      this.articleTemplateService.delete(this.articleTemplateId).subscribe(
        {
          next: () => {
            this.router.navigate(['/', 'artikelvorlagen', 'uebersicht']);
          },
          error: (error) => {
            alert(error?.message || 'Something wrong went');
          }
        }
      )
    }
    return false;
  }

  public updateKey(field: string, event: EditEventType) {
    this.metaUpdate(field, event)
  }

  private metaUpdate(key = null, value = null, data = {}) {
    const changeData = key === null ? data : {}
    if(key !== null)
      changeData[key] = value
    this.articleTemplateService.update(this.articleTemplateId, { article_template: changeData })
    .subscribe(
      {
        next: (response) => {
          this.articleTemplate = response;
          if(key)
            this.editingProps[key] = false;
        },
        error: (error) => {
          console.log('error', error);
        }
      }
    )
  }

  // New template

  public createArticleTemplate() {
    const params = this.articleTemplateForm.value;
    this.submittingTemplate = true;
    this.articleTemplateService.create({ article_template: params }).subscribe(
      {
        next: (response) => {
          this.submittingTemplate = false;
          this.router.navigate(['/', 'artikelvorlagen', response.id]);
        },
        error: (error) => {
          console.log('error', error);
          alert(`Please fix errors: ${error}`);
          this.submittingTemplate = false;
        }
      }
    )
  }

}