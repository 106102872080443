import { Component } from "@angular/core";
import { RouterModule } from "@angular/router";
import { ICellRendererAngularComp } from "ag-grid-angular";
import { ICellRendererParams } from "ag-grid-enterprise";

@Component({
    selector: 'ag-grid-warehouse-link-renderer',
    imports: [RouterModule],
    template: `<a class='g-link' [routerLink]="['/', 'lager', params.data?.id]">
              {{params.data?.label}}
            </a>`
})
export class AgGridWarehouseLinkRenderer implements ICellRendererAngularComp {
  params!: ICellRendererParams;

  agInit(params: ICellRendererParams) {
    this.params = params;
  }

  refresh(params: ICellRendererParams) {
    this.params = params;
    // As we have updated the params we return true to let AG Grid know we have handled the refresh.
    // So AG Grid will not recreate the cell renderer from scratch.
    return true;
  }
}