import { ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";

import { Component, inject, OnInit } from '@angular/core'
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms'
import { SessionService } from '../_services'
import { ActivatedRoute, Router } from '@angular/router'
import { CommonModule } from "@angular/common";

@Component({
  imports: [ReactiveFormsModule, RouterModule, CommonModule],
    selector: 'hawk-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {

  loginForm: UntypedFormGroup
  returnUrl: string

  error: string
  sessionService: SessionService = inject(SessionService)

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    ) { }

  ngOnInit(): void {

    this.loginForm = new UntypedFormGroup({
      email: new UntypedFormControl(null),
      password: new UntypedFormControl(null),
    })
    ,

    this.returnUrl = this.route.snapshot.queryParams.returnUrl || '/'
  }
  public async onLoginClick() {
    const form = this.loginForm.value

   await this.sessionService.loginRedirect(form.email, form.password, this.returnUrl)  
   if (this.sessionService.loginError) {
     this.error = this.sessionService.loginError
   } else {
      this.error = null
   }
  }

}