import { CommonModule } from "@angular/common";
import { ReactiveFormsModule } from "@angular/forms";
import { FormsModule } from "@angular/forms";

import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core'
import { ArticleTemplate, JobAssignment, Technician } from '../_models'
import { TicketService } from '../_services'
import { IDropdownSettings, NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown'
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms'
import { NgxDatatableModule, SelectionType } from '@swimlane/ngx-datatable'
import { environment } from 'src/environments/environment'
import dayjs from 'dayjs'
import weekOfYear from 'dayjs/plugin/weekOfYear'
import 'dayjs/locale/de'
import { NgxMapboxGLModule } from "ngx-mapbox-gl";

dayjs.locale('de')
dayjs.extend(weekOfYear)

@Component({
  imports: [CommonModule, ReactiveFormsModule, FormsModule, NgxDatatableModule, NgMultiSelectDropDownModule, NgxMapboxGLModule],
    selector: 'hawk-tour-planner',
    templateUrl: './tour-planner.component.html',
    styleUrls: ['./tour-planner.component.scss'],
})
export class TourPlannerComponent implements OnInit {
  @ViewChild('statusTpl', { static: true }) statusTpl: TemplateRef<any>
  @ViewChild('dateTpl', { static: true }) dateTpl: TemplateRef<any>
  @ViewChild('weekTpl', { static: true }) weekTpl: TemplateRef<any>

  selectionType = SelectionType.single
  form: UntypedFormGroup
  env = environment

  columns = []
  plannedAssignments: JobAssignment[] = []
  filteredPlannedAssignments: JobAssignment[] = []
  plannedSelected: JobAssignment[] = []

  unplannedAssignments: JobAssignment[] = []
  filteredUnplannedAssignments: JobAssignment[] = []

  columnsTechnicians = []
  technicians: Technician[] = []
  selectedTechnicians: Technician[] = []
  selectedTechnicianIds: Set<number> = new Set()
  technicianSelectionSettings: IDropdownSettings = {
    limitSelection: 5,
    allowSearchFilter: true,
    enableCheckAll: false,
    idField: 'id',
    textField: 'name',
  }

  weekNumbers: string[] = [...Array(52).keys()].map((i) => {
    const week = i + 1

    return week < 10 ? `0${week}` : week.toString()
  })
  selectedWeekNumbers: number[] = []
  weekNumberSelectionSettings: IDropdownSettings = {
    limitSelection: 5,
    allowSearchFilter: true,
    enableCheckAll: false,
  }

  constructor(
    private ticketService: TicketService,
    private formBuilder: UntypedFormBuilder
  ) {}

  ngOnInit(): void {
    this.columns = [
      { prop: 'status', name: 'St.', cellTemplate: this.statusTpl, width: 30 },
      { prop: 'store_name', name: 'Filiale', width: 120 },
      { prop: 'store_postcode', name: 'Plz', width: 80 },
      { prop: 'store_city', name: 'Ort', width: 100 },
      { prop: 'estimated_time', name: 'Zeit', width: 50 },
      {
        prop: 'operation_date',
        name: 'Datum',
        width: 100,
        cellTemplate: this.dateTpl,
      },
      {
        prop: 'operation_date',
        name: 'Woche',
        width: 100,
        cellTemplate: this.weekTpl,
      },
    ]

    this.columnsTechnicians = [
      { prop: 'status', name: 'St.', cellTemplate: this.statusTpl },
      { prop: 'store_name', name: 'Filiale' },
      { prop: 'store_postcode', name: 'Plz' },
      { prop: 'store_city', name: 'Ort' },
      { prop: 'estimated_time', name: 'Zeit' },
      { prop: 'operation_date', name: 'Datum', cellTemplate: this.dateTpl },
      {
        prop: 'operation_date',
        name: 'Woche',
        width: 100,
        cellTemplate: this.weekTpl,
      },
    ]

    this.form = this.formBuilder.group({
      importfile: [''],
    })

    this.ticketService.getTechnicians().subscribe(
      (data) => {
        this.technicians = data
      },
      (err) => {
        console.error(err)
      }
    )
  }

  //
  // Is mainly called by the week number dropdown
  //
  loadStoreData() {
    this.ticketService.getJobAssignments(this.selectedWeekNumbers).subscribe(
      (data) => {
        this.plannedAssignments = data
        this.onSelectTechnician()
      },
      (err) => {
        console.error(err)
      }
    )

    this.ticketService
      .getUnplannedAssignments(this.selectedWeekNumbers)
      .subscribe(
        (data) => {
          this.unplannedAssignments = data
        },
        (err) => {
          console.error(err)
        }
      )
  }

  onSelectTechnician() {
    const set = new Set(
      this.selectedTechnicians.map((technician: Technician) => {
        return technician.id
      })
    )

    this.filterByTechnicians(set)
  }

  filterByTechnicians(selectedTechnicianIds: Set<number>) {
    if (selectedTechnicianIds.size) {
      const assignments = this.plannedAssignments.filter((assignment) => this.filterTechnician(assignment, selectedTechnicianIds))
      const unplanned = this.unplannedAssignments.filter((assignment) => this.filterTechnician(assignment, selectedTechnicianIds))

      this.filteredPlannedAssignments = assignments
      this.filteredUnplannedAssignments = unplanned
    } else {
      this.filteredPlannedAssignments = this.plannedAssignments
      this.filteredUnplannedAssignments = this.unplannedAssignments
    }
  }

  /**
   * this filter method is used to decide if a job assignment needs to be filtered based on the technician ids
   */
  private filterTechnician(assignment: JobAssignment, selectedTechnicianIds: Set<number>): boolean {
    // we dont want to filter assignments with empty technicians (empty === null)
    return assignment.technicians == null || assignment.technicians.reduce((acc, technician) => {
      return acc || selectedTechnicianIds.has(technician.id)
    }, false)
  }

  onSelect({ selected }) {
    // console.log('Select Event', selected, this.plannedSelected);
  }

  onActivate(event) {
    // console.log('Activate Event', event);
  }

  handleMarkerClick = (a: JobAssignment) => {
    this.plannedSelected = [a]
  }

  getRowHeight(row) {
    if (!row) {
      return 50
    }
    if (row.height === undefined) {
      return 50
    }
    return row.height
  }

  formatDate(datetime) {
    return datetime ? dayjs(datetime).format('DD.MM.YYYY HH:mm') : '-'
  }

  calendarWeek(datetime) {
    return datetime ? dayjs(datetime).week() : '-'
  }

  destroyAll() {
    this.ticketService.deleteAll().subscribe(
      (res) => {
        this.unplannedAssignments = []
        this.filteredUnplannedAssignments = []
      },
      (err) => console.error(err)
    )
  }

  exportXLSX() {
    this.ticketService.getUnplannedAssignmentsExport([]).subscribe(
      (res) => {
        console.log(res)
      },
      (err) => console.error(err)
    )
  }

  onFileChange(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0]
      this.form.get('importfile').setValue(file)
    }
  }

  onSubmit() {
    const formData = new FormData()
    formData.append('file', this.form.get('importfile').value)

    this.ticketService.uploadUnplannedAssignments(formData).subscribe(
      () => {
        this.ticketService
        .getUnplannedAssignments(this.selectedWeekNumbers)
        .subscribe(
          (data) => {
            this.unplannedAssignments = data
            this.onSelectTechnician()
          },
          (err) => {
            console.error(err)
          }
        )
      },
      (err) => console.error(err)
    )
  }
}