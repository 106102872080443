import { Material, Priority, Status, Store, Ticket as TicketHash, TicketNote } from "src/app/_models";
import { PACoordinates } from "./coordinates";
import { ArticleService, TicketNoteService, TicketService } from "src/app/_services";
import { PAUtil } from "./util";
import { CalculatesDistance, PALocation } from "./location";
import { PAOperation } from "./operation";
import { OpeningTimes } from "src/app/_models/opening-times.interface";
import { PAStore } from "./store";
import { MaterialContainer } from "./material-container/material-container.interface";
import { NewMaterial } from "src/app/_models/material.interface";
import { TicketMaterialContainer } from "./material-container/material-container";
import { PAProject } from "./project";
import { PAClient } from "./client";
import { PADataControl } from "../singletons/pa-data-control";
import { PATimeControl } from "../singletons/pa-time-control";
import { PermittedTicketParams } from "../../../_models/ticket.interface";
import { PATourPlannerControl } from "../singletons/pa-tourplanner-control";

export class PATicket implements CalculatesDistance, MaterialContainer {

  static ticketService: TicketService
  static ticketNoteService: TicketNoteService
  static articleService: ArticleService

  static nextTemporaryTicketId: number = -1

  public location: PALocation
  public client: PAClient
  public temporaryOperation?: PAOperation
  store?: Store

  private afterStorageTasks: AfterTicketStorageTasks = {}

  private _id: number

  constructor(
    id: number,
    public location_id: number,
    public client_id: number,
    public datesla: string,
    public appointment_date: string,
    public project: PAProject,
    public coordinates: PACoordinates,
    public time_estimation: number,
    public address_company: string,
    public address_street: string,
    public address_street_no: string,
    public address_zip: string,
    public address_city: string,
    public address_country: string,
    public materials: Material[],
    public store_openings: OpeningTimes,
    public external_order_nr: string,
    public contact_name: string,
    public contact_email: string,
    public contact_phone: string,
    public priority: Priority,
    public status: Status,
    public memos: string[],
    public description: string
  ) {
    this._id = id
    if (id <= 0) {
      this.afterStorageTaskMaterialContainer = new TicketMaterialContainer()
    }
    if (PADataControl.Instance.clientMap.has(client_id)) {
      this.client = PADataControl.Instance.clientMap.get(client_id)
    } else {
      this.client = new PAClient(client_id, location_id, coordinates, address_company, address_zip)
    }
    if (PADataControl.Instance.locationMap.has(location_id)) {
      this.location = PADataControl.Instance.locationMap.get(location_id)
    } else {
      this.location = new PALocation(coordinates, location_id, address_zip, '', '')
    }
    !PADataControl.Instance.ticketMap.has(this.id) ? PADataControl.Instance.ticketMap.set(this.id, this) : {}
  }

  set afterStorageTaskMaterialContainer(m_container: MaterialContainer) {
    this.afterStorageTasks.addMaterialsFromContainer = m_container
  }

  get afterStorageTaskMaterialContainer(): MaterialContainer {
    return this.afterStorageTasks.addMaterialsFromContainer
  }

  isServiceTicket(): boolean {
    return this.project.project_name.toLowerCase().includes('service')
  }

  getMaterialString(): string {
    return this.materials.map(mat => `${mat.amount}x ${mat.title}`).join(' | ')
  }

  async executeAfterStorageTasks(): Promise<void> {
    return new Promise<void>(async resolve => {
      if (this.id > 0) {
        let promises: Promise<boolean>[] = []
        if (this.afterStorageTasks.addMaterialsFromContainer && this.afterStorageTasks.addMaterialsFromContainer.materials.length) {
          let materials = this.afterStorageTaskMaterialContainer.materials
          for (let material of materials) {
            let promise = this.saveMaterial(material)
            promise.then(material_saved => {
              if (material_saved) this.afterStorageTaskMaterialContainer.deleteMaterial(material)
            })
            promises.push(promise)
          }
        }
        await Promise.all(promises)
        this.afterStorageTasks = {}
        resolve()
      } else {
        console.error(`could not execute after storage tasks for ticket with id ${this.id}`)
        this.afterStorageTasks = {}
        resolve()
      }
    })
  }

  getCurrentMaterials(): Material[] {
    if (this.afterStorageTaskMaterialContainer) {
      return this.afterStorageTaskMaterialContainer.materials
    } else {
      return this.materials
    }
  }

  saveMaterial(material: Material): Promise<boolean> {
    let new_material: NewMaterial = {
      amount: material.amount,
      article_template_id: material.template_id,
      description: material.description,
      item_nr: material.item_nr || '',
      sn: material.sn || '',
      status: material.status || ''
    }
    return new Promise<boolean>(resolve => {
      PATicket.ticketService.createMaterial(this.id, new_material).subscribe(
        (data) => {
          console.log(data)
          this.materials.push(data)
          resolve(true)
        },
        (err) => {
          console.error(err)
          resolve(false)
        },
      )
    })
  }

  deleteMaterial(material: Material): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      PATicket.ticketService.deleteMaterial(material.id).subscribe(
        (data) => {
          console.log(data)
          resolve(true)
          PAUtil.removeElementFromList(this.materials, material)
        },
        (error) => {
          console.error(error)
          resolve(false)
        }
      )
    })
  }

  get id(): number {
    return this._id;
  }

  set id(value: number) {
    PADataControl.Instance.ticketMap.delete(this._id)
    this._id = value;
    PADataControl.Instance.ticketMap.set(this._id, this)
  }

  getDistanceToCoordinatesAsTheCrowFlies(lat: number, lon: number): number {
    return this.location.getDistanceToCoordinatesAsTheCrowFlies(lat, lon)
  }

  getDistanceToLocationAsTheCrowFlies(location: PALocation): number {
    return this.location.getDistanceToLocationAsTheCrowFlies(location)
  }

  getStreetDistanceToCoordinates(lat: number, lon: number): Promise<{ distance: number; duration: number }> {
    return this.location.getStreetDistanceToCoordinates(lat, lon)
  }

  getDistanceToLocation(to_location: PALocation): Promise<{ distance: number; duration: number }> {
    return this.location.getDistanceToLocation(to_location)
  }

  belongsToProject(project_id: number): boolean {
    return this.project.id == project_id || this.project.id == -1
  }

  address(): string {
    return `${this.address_street ? (this.address_street + (this.address_street_no ? (' ' + this.address_street_no) : '') + ',') : ''}${this.address_zip ? (' ' + this.address_zip) : ''} ${this.address_city ? this.address_city : ''}`
  }

  public slaDateInformation(): string {
    if (this.datesla) {
      let date_string = PATimeControl.Instance.dateToDatestring(new Date(this.datesla), false, true, '.')
      let time_string = PATimeControl.Instance.dateToTimestring(new Date(this.datesla), false)
      return date_string + ' ' + time_string + 'Uhr'
    }
    return ''
  }

  public appointmentDateInformation(): string {
    if (this.appointment_date) {
      let date_string = PATimeControl.Instance.dateToDatestring(new Date(this.appointment_date), false, true, '.')
      let time_string = PATimeControl.Instance.dateToTimestring(new Date(this.appointment_date), false)
      return date_string + ' ' + time_string + 'Uhr'
    }
    return ''
  }

  infoString(): string {
    return `T-${this.id > 0 ? this.id : 'NEU'} ${this.address_company} ${this.project.project_name} ${this.address_city}`
  }

  generateTemporaryTicketOperation(): PAOperation {

    this.temporaryOperation = new PAOperation(
      PAOperation.generateTemporaryOperationId(),
      PADataControl.Instance.generateNextClientId(),
      null,
      this,
      [283],
      null,
      null,
      null,
      null,
      this.description
    )

    PAStore.insertOperation(this.temporaryOperation)
    PADataControl.Instance.addOperationsToLoadedOperations([this.temporaryOperation])

    return this.temporaryOperation
  }

  async createTicketNote(ticket_note: TicketNote): Promise<void> {
    return new Promise(resolve => {
      PATicket.ticketNoteService.createNote(ticket_note).subscribe(
        _ => {
          resolve()
        },
        err => {
          console.log(err)
          resolve()
        }
      )
    })
  }

  static setTicketService(service: TicketService): void {
    this.ticketService = service
  }

  static setTicketNoteService(service: TicketNoteService): void {
    this.ticketNoteService = service
  }

  static setArticleService(service: ArticleService): void {
    this.articleService = service
  }

  generateSaveParams(description: string): PermittedTicketParams {
    if (this.store) {
      return {
        time_estimation: this.time_estimation,
        description: description,
        priority_id: this.priority.id,
        project_id: this.project.id,
        status_id: this.status.id,
        store_id: this.store.id,
        datesla: this.datesla,
        external_order_nr: this.external_order_nr,
        contact_name: this.contact_name,
        contact_email: this.contact_email,
        contact_phone: this.contact_phone
      }
    } else {
      return {
        time_estimation: this.time_estimation,
        description: description,
        priority_id: this.priority.id,
        project_id: this.project.id,
        status_id: this.status.id,
        address_company: this.address_company,
        address_city: this.address_city,
        address_country: this.address_country,
        address_zip: this.address_zip,
        address_street: this.address_street + this.address_street_no ? ' ' + this.address_street_no : '',
        datesla: this.datesla,
        external_order_nr: this.external_order_nr,
        contact_name: this.contact_name,
        contact_email: this.contact_email,
        contact_phone: this.contact_phone
      }
    }
  }

  async save(description: string): Promise<TicketHash | null> {
    return await new Promise<TicketHash>(resolve => {
        PATicket.ticketService.createTicket(this.generateSaveParams(description)).subscribe(
          data => {
            let snackbar = PATourPlannerControl.Instance.snackBar
            snackbar.open(
              'Ticket wurde in Datenbank gespeichert',
              'Ok'
            )._dismissAfter(3000)
            this.id = data.id
            console.log(`Neue Ticket ID: ${this.id}`)
            let ticket_note = this.temporaryOperation ? this.temporaryOperation.afterStorageTaskTicketNote : null
            ticket_note ? ticket_note.ticket_id = data.id : {}
            this.executeAfterStorageTasks()
            resolve(data)
          },
          err => {
            console.log(err)
            resolve(null)
          }
        )
      }
    )
  }
}

export interface AfterTicketStorageTasks {
  addMaterialsFromContainer?: TicketMaterialContainer
}