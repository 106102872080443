import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { FlatpickrDefaultsInterface, FlatpickrModule } from 'angularx-flatpickr'
import { EditEventType } from "src/app/_models";
import { MatOption, MatSelect } from "@angular/material/select";

@Component({
  imports: [CommonModule, FormsModule, FlatpickrModule, FontAwesomeModule, MatSelect, MatOption],
  selector: 'hawk-shared-edit-input',
  templateUrl: './edit-input.component.html',
  styleUrls: ['./edit-input.component.scss'],
  standalone: true
})
export class EditInputComponent implements OnInit {

  @Input() textValue: string
  @Input() numberValue: number
  @Input() dateValue: Date
  @Input() boolValue: boolean
  @Input() selectValue: number
  @Input() multiSelectValue: number[]
  @Input() selectOptions: {id: number|string, name: string}[]
  @Input() modus: 'text' | 'number' | 'textarea' | 'date' | 'select' | 'multiselect' | 'checkbox' | 'onlyNumber' = 'text'
  @Input() maxInputLength: number
  @Input() upperCaseMode: boolean
  @Output() saveClicked = new EventEmitter<EditEventType>()
  @Output() cancelClicked = new EventEmitter<boolean>()

  value: EditEventType

  public datePickerConfig: FlatpickrDefaultsInterface = {
    enableTime: true,
    convertModelValue: true,
    altInput: true,
    allowInput: true,
    altFormat: 'd.m.Y H:i',
    dateFormat: 'Y-m-d H:i',
    defaultHour: 10,
    defaultMinute: 0,
    minuteIncrement: 5,
    time24hr: true,
    enable: [{ from: new Date(1985, 1), to: new Date(new Date().getFullYear() + 50, 12) }]
  }

  constructor(
  ) { }

  ngOnInit(): void {

    if (this.textValue) {
      this.value = this.textValue
    }

    if (this.numberValue) {
      this.value = this.numberValue
    }

    if (this.dateValue) {
      this.value = this.dateValue
    }

    if (this.selectValue) {
      this.value = this.selectValue
    }

    if (this.multiSelectValue) {
      this.value = this.multiSelectValue
    }

    if (this.boolValue) {
      this.value = this.boolValue
    }
  }

  public save() {
    const output_is_uppercase_text = (this.modus == 'text' || this.modus == 'textarea') && this.upperCaseMode
    const value = output_is_uppercase_text ? this.value.toString().toUpperCase() : this.value
    this.saveClicked.emit(value)
  }

  public cancel() {
    this.cancelClicked.emit(true)
  }
}
