import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { HasCoordinates } from '../_models/planning-assistant.interface'

@Injectable({
  providedIn: 'root'
})
export class MapBoxService {

  accessToken: string = 'pk.eyJ1IjoibWFwYm94cmFka2UiLCJhIjoiY2o5YmRqYXB0MDJvdDJwbzc5Z2FwZ2lucyJ9._yiQpupY4qWmb72XuF3-dA'

  constructor(
      private http: HttpClient,
    ) {
  }

  public directionWaypoints(location_1: HasCoordinates, location_2: HasCoordinates) {
    const path = 'https://api.mapbox.com/directions/v5/mapbox/driving/' + 
    location_1.longitude + ',' + location_1.latitude + ';' + location_2.longitude + ',' + location_2.latitude +
      '?geometries=geojson&access_token=' + this.accessToken + '&annotations=distance,duration'
    return this.http.get<DirectionAnswer>(path)
  }

  public matrixWaypoints(coordinates: HasCoordinates[], source_indices: number[], destination_indices: number[]) {
    const coordinates_string = coordinates.map(coords => `${coords.longitude},${coords.latitude}`).join(';')
    const path = 'https://api.mapbox.com/directions-matrix/v1/mapbox/driving/' + coordinates_string +
      '?access_token=' + this.accessToken + '&annotations=distance,duration&' +
      `sources=${source_indices.join(';')}&destinations=${destination_indices.join(';')}`

    return this.http.get<MatrixAnswer>(path)
  }

  public getDrivingIso(lat: number, long: number, minutes: number) {
    const path = 'https://api.mapbox.com/isochrone/v1/mapbox/driving/' + 
    long.toString() + ',' + lat.toString() + '?contours_minutes=' + minutes.toString() + '&polygons=true&access_token=' + this.accessToken
    return this.http.get<GeoJSON.FeatureCollection<GeoJSON.Geometry>>(path)
  }

}

export interface DirectionAnswer {
  routes: Route[]
}

export interface MatrixAnswer {
  code: string
  durations: number[][]
  distances: number[][]
}

export interface Route {
  duration: number,
  distance: number,
  geometry?: {
    coordinates: number[][]
  }
}
