import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";

import { Component, OnInit, Input } from '@angular/core'

import { ProjectService, WorkflowService } from 'src/app/_services'
import { ProjectMetadata } from '../_models'
import { FileInputComponent } from '../_components/_shared/file-input/file-input.component'

@Component({
    selector: 'hawk-workflow-upload-applet',
    templateUrl: './workflow-upload.component.html',
    styleUrls: ['./workflow-upload.component.scss'],
    imports: [CommonModule, FormsModule, FileInputComponent]
})
export class WorkflowUploadComponent implements OnInit {

  @Input() ticketId: number = 1
  @Input() projectId: number = 1
  @Input() operationId: number = 1
  @Input() id: string = "1"

  public workflowGeneratorType = "ticket"
  public selectedPriority: number = 0
  public projectMode = false
  public metadata?: ProjectMetadata

  public workflowGeneratorTypes = [
    { title: "Ticket", key: "ticket"},
    { title: "Einsatz", key: "operation"},
  ]

  constructor(
    private workflowService: WorkflowService,
    private projectService: ProjectService,
  ) { }

  ngOnInit(): void {
    if (!!this.projectId) {
      this.projectMode = true

      this.projectService.getMetadata(this.projectId.toString()).subscribe(
        (data) => {
          this.metadata = data
          console.log(data)
        },
        (err) => {
          console.error(err)
        },
      )
    }
  }

  public saveWorkflow(files: FileList) {
    console.log(files, this.workflowGeneratorType, this.selectedPriority)
    const doit = window.confirm("Soll der Workflow hochgeladen und hinterlegt werden?")

    if (doit) {
      if (this.projectMode){
        if (this.selectedPriority == 0 ) {
          this.workflowService.uploadProjectWorkflow(this.projectId, files).subscribe(
            (data) => {
              console.log(data)
              window.alert("Erfolgreich im Projekt hinterlegt!")
            },
            (err) => {
              console.error(err)
            },
          )
        } else {
          this.workflowService.uploadProjectPriorityWorkflow(this.projectId, this.selectedPriority, files).subscribe(
            (data) => {
              console.log(data)
              window.alert("Erfolgreich im Projekt mit Priorität hinterlegt!")
            },
            (err) => {
              console.error(err)
            },
          )
        }

      } else {
        switch (this.workflowGeneratorType ) {
          case "ticket":
            this.workflowService.uploadTicketWorkflow(this.ticketId, files).subscribe(
              (data) => {
                console.log(data)
              },
              (err) => {
                console.error(err)
              },
            )
            break
          case "operation":
            this.workflowService.uploadOperationWorkflow(this.operationId, files).subscribe(
              (data) => {
                console.log(data)
              },
              (err) => {
                console.error(err)
              },
            )
            break
        }
      }
    }
  }
}