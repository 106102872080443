<div *ngIf="showProjectModal" id="project_modal" class="pa-modal" (click)="closeProjectModal($event)">
  <!-- Modal content -->
  <div class="modal-content">
    <span id="project_modal_close_button" class="close" (click)="closeProjectModal($event)">&times;</span>
    <table id="project_table">
      <thead>
        <tr>
          <th class="centered_text">
            Projekt: {{modalProject.project_name}}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            <label style="margin: 10px; font-size: 1.3rem;">
              Projektfarbe: 
            </label>
            <input style="margin: 10px;" name="project_color" type="color" [value]="modalProjectColor" (change)="changeModalProjectColor($event)">
          </td>
        </tr>
        <tr>
          <td class="centered_text">
            <button id="update_project_button" (click)="modalProject.updateColorMeta(modalProjectColor)">
              übernehmen
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

<div style="position: relative; overflow: visible;" class="vertical_flex;">
  <hawk-expand-bar-header
  [description]="'Kalender'"
  [isExpanded]="isExpanded"
  (toggle)="isExpanded = $event">

  </hawk-expand-bar-header>
  <div class="toggle_body" [ngClass]="isExpanded ? 'shown' : ''">
    <table id="table_section_table" class="calendar_table">
      <tbody>
        <tr>
          <td style="border-top: 1px solid black; padding: 0;" colspan="2">
            <div id="table_section_tablist" role="tablist" class="calendar_tablist">
              <button id="calendar_tab" role="tab" class="tab" (click)="deselectCalendarWeek()">Kalender</button>
              <button *ngIf="PATimeControl.Instance.selectedCalendarWeekData" id="week_tab" role="tab" class="tab" (click)="deselectCalendarDay()">KW {{PATimeControl.Instance.selectedCalendarWeekData.calendar_week.number}}</button>
              <button *ngIf="PATimeControl.Instance.selectedCalendarDay" id="day_tab" role="tab" class="tab">{{PATimeControl.Instance.weekDayToGermanName(PATimeControl.Instance.selectedCalendarDay, true)}}</button>
            </div>
          </td>
          <td class="black_gradient_background" style="color: white; border: 1px solid black;"
              [attr.rowspan]="(showFilteredTechnicians ? (shownTechnicians.length ? shownTechnicians.length + 2 : 0) : 1) + preferredTechnicians.length + 2"
              (click)="decrementTimeRange()">
            &#10092;
          </td>
          <ng-container *ngIf="!PATimeControl.Instance.selectedCalendarWeekData && !PATimeControl.Instance.selectedCalendarDay?.length">
            <ng-container *ngFor="let calendar_week_data of PATimeControl.Instance.shownCalendarWeeksData">
              <td [attr.colspan]="filteredWeekdays.length + 1" class="black_gradient_background bold_text" style="color: white; border: 1px solid black;" (click)="selectCalendarWeek(calendar_week_data)">
                KW {{calendar_week_data.calendar_week.number}}
              </td>
            </ng-container>
          </ng-container>
          <ng-container *ngIf="PATimeControl.Instance.selectedCalendarWeekData && !PATimeControl.Instance.selectedCalendarDay?.length">
            <td [attr.colspan]="2 * filteredWeekdays.length" class="black_gradient_background" style="color: white; border: 1px solid black;">
              KW {{PATimeControl.Instance.selectedCalendarWeekData.calendar_week.number}}
            </td>
          </ng-container>
          <ng-container *ngIf="PATimeControl.Instance.selectedCalendarWeekData && PATimeControl.Instance.selectedCalendarDay?.length">
            <td [attr.colspan]="4" class="black_gradient_background" style="color: white; border: 1px solid black;">
              KW {{PATimeControl.Instance.selectedCalendarWeekData.calendar_week.number}}
            </td>
          </ng-container>
          <td class="black_gradient_background" style="color: white; border: 1px solid black;"
              [attr.rowspan]="(showFilteredTechnicians ? (shownTechnicians.length ? shownTechnicians.length + 2 : 0) : 1) + preferredTechnicians.length + 2"
              (click)="incrementTimeRange()">
            &#10093;
          </td>
        </tr>

        <tr>
          <td style="border-top: 1px solid black; padding: 0;" colspan="2"><input class="fill_table_cell" type="search" placeholder="Techniker suchen" [(ngModel)]="technicianSearchName" (search)="updateShownTechnicians()" (keyup)="updateShownTechnicians()"></td>
          <ng-container *ngIf="!PATimeControl.Instance.selectedCalendarWeekData && !PATimeControl.Instance.selectedCalendarDay?.length">
            <ng-container *ngFor="let calendar_week_data of PATimeControl.Instance.shownCalendarWeeksData">
              <td *ngFor="let week_day of filteredWeekdays" class="black_gradient_background" style="width: 100px; color: white; border: 1px solid black;" (click)="selectCalendarWeekAndDay(calendar_week_data, week_day)">
              <span style="font-size: .75rem; line-height: 0;">
                {{PATimeControl.Instance.weekDayToGermanName(week_day, true)}}
                {{PATimeControl.Instance.timestampToDatestring(calendar_week_data.weekdays[week_day].timestamp, false, '.').slice(0, 5)}}
                <ng-container *ngFor="let holiday of calendar_week_data.weekdays[week_day].holidays">
                  <span [tooltip]="holiday.tooltip_text" [show-delay]="100" style=" line-height: 0">
                    &#8505;
                  </span>
                </ng-container>
              </span>
              </td>
              <td class="black_gradient_background_no_hover white_text" style="padding: 2px; border: 1px solid black">Std</td>
            </ng-container>
          </ng-container>
          <ng-container *ngIf="PATimeControl.Instance.selectedCalendarWeekData && !PATimeControl.Instance.selectedCalendarDay?.length">
            <ng-container *ngFor="let week_day of filteredWeekdays">
              <td class="black_gradient_background" style="border: 1px solid black;" (click)="selectCalendarDay(week_day)">
                <span>
                  {{PATimeControl.Instance.weekDayToGermanName(week_day, true)}}
                  {{PATimeControl.Instance.timestampToDatestring(PATimeControl.Instance.selectedCalendarWeekData.weekdays[week_day].timestamp, false).split('-').join('.').slice(0, 5)}}
                </span>
                <ng-container *ngFor="let holiday of PATimeControl.Instance.selectedCalendarWeekData.weekdays[week_day].holidays">
                  <span [tooltip]="holiday.tooltip_text" [show-delay]="100" class="holiday_warning">
                    &#8505;
                  </span>
                </ng-container>
              </td>
              <td class="black_gradient_background_no_hover" style="border: 1px solid black;">Min</td>
            </ng-container>
          </ng-container>
          <ng-container *ngIf="PATimeControl.Instance.selectedCalendarWeekData && PATimeControl.Instance.selectedCalendarDay?.length">
            <td class="black_gradient_background" style="border: 1px solid black;" (click)="selectCalendarDay(PATimeControl.Instance.selectedCalendarDay)">
                <span>
                  {{PATimeControl.Instance.weekDayToGermanName(PATimeControl.Instance.selectedCalendarDay, true)}}
                  {{PATimeControl.Instance.timestampToDatestring(PATimeControl.Instance.selectedCalendarWeekData.weekdays[PATimeControl.Instance.selectedCalendarDay].timestamp, false).split('-').join('.').slice(0, 5)}}
                </span>
              <ng-container *ngFor="let holiday of PATimeControl.Instance.selectedCalendarWeekData.weekdays[PATimeControl.Instance.selectedCalendarDay].holidays">
                  <span [tooltip]="holiday.tooltip_text" [show-delay]="100" class="holiday_warning">
                    &#8505;
                  </span>
              </ng-container>
            </td>
            <td class="black_gradient_background_no_hover" style="border: 1px solid black; padding: 2px;">Fahrt</td>
            <td class="black_gradient_background_no_hover" style="border: 1px solid black; padding: 2px;">Aufträge</td>
            <td class="black_gradient_background_no_hover" style="border: 1px solid black; padding: 2px; white-space: nowrap;">Hawk-Link</td>
          </ng-container>
        </tr>

        <ng-container *ngFor="let technician of preferredTechnicians.concat(shownTechnicians)">
          <tr *ngIf="shownTechnicians.indexOf(technician) == 0 && showFilteredTechnicians">
            <td style="text-align: center;" colspan="2" class="black_gradient_background" (click)="decreaseShowTechnicianIndex()"><div style="width: 20px; margin: auto;"><div class="rotate">&#10093;</div></div></td>
            <ng-container *ngIf="!PATimeControl.Instance.selectedCalendarWeekData && !PATimeControl.Instance.selectedCalendarDay?.length">
              <td [attr.colspan]="(filteredWeekdays.length + 1) * PATimeControl.Instance.shownCalendarWeeksData.length" class="black_gradient_background_no_hover" style="color: white; border: 1px solid black;"></td>
            </ng-container>
            <ng-container *ngIf="PATimeControl.Instance.selectedCalendarWeekData && !PATimeControl.Instance.selectedCalendarDay?.length">
              <td [attr.colspan]="(filteredWeekdays.length * 2)" class="black_gradient_background_no_hover" style="color: white; border: 1px solid black;"></td>
            </ng-container>
            <ng-container *ngIf="PATimeControl.Instance.selectedCalendarWeekData && PATimeControl.Instance.selectedCalendarDay?.length">
              <td [attr.colspan]="4" class="black_gradient_background_no_hover" style="color: white; border: 1px solid black;"></td>
            </ng-container>
          </tr>
          <tr *ngIf="(shownTechnicians.includes(technician) && showFilteredTechnicians) || (preferredTechnicians.includes(technician) && showPreferredTechnicians)">
            <td *ngIf="preferredTechnicians.indexOf(technician) == 0" (click)="showPreferredTechnicians = !showPreferredTechnicians" style="text-align: center;" class="black_gradient_background" [attr.rowspan]="showPreferredTechnicians ? preferredTechnicians.length : 1">
              <fa-icon style="color: white;" [icon]="faEye"></fa-icon>
            </td>
            <td *ngIf="shownTechnicians.indexOf(technician) == 0" (click)="showFilteredTechnicians = !showFilteredTechnicians" style="text-align: center;" class="black_gradient_background" [attr.rowspan]="showFilteredTechnicians ? shownTechnicians.length : 1">
              <fa-icon style="color: white;" [icon]="faEye"></fa-icon>
            </td>
            <td style="min-width: 170px; display: flex; flex-direction: row; color: white; height: 28px; border: 1px solid black" class="black_gradient_background" (click)="togglePreferredTechnician(technician)">
              <fa-icon [icon]="faStar" [style.color]="preferredTechnicians.includes(technician) ? 'rgb(0, 127, 211)' : 'gray'" style="margin: auto 2px;"></fa-icon>
              <div style="margin: auto 0; font-size: .8rem;">
                {{Util.cutString(technician.firstname + ' ' +  technician.lastname, 20)}}
              </div>
            </td>

            <ng-container *ngIf="!PATimeControl.Instance.selectedCalendarWeekData && !PATimeControl.Instance.selectedCalendarDay?.length">
              <ng-container *ngFor="let calendar_week_data of PATimeControl.Instance.shownCalendarWeeksData">
                <ng-container *ngFor="let week_day of filteredWeekdays">
                  <td style="border: 1px solid #afafaf; position: relative;" (click)="PATourPlannerControl.Instance.planTechnicianDate(technician.getTechnicianDate(calendar_week_data.weekdays[week_day].timestamp, true))">
                    <ng-container *ngIf="technician.getTechnicianDate(calendar_week_data.weekdays[week_day].timestamp, true).loadingStatus == 'loaded'">
                      <div *ngFor="let percentages of getTimeFilteredCalendarDataOfTechnicianDate(technician.getTechnicianDate(calendar_week_data.weekdays[week_day].timestamp, true)).project_percentages"
                           [style.width]="percentages.percentage + '%'"
                           [style.background-color]="percentages.project ? percentages.project.color : 'white'"
                           [style.border]="percentages.percentage == 100 ? '1px solid black' : 'none'"
                           style="height: 22px; float: left;"
                      >
                      </div>
                    </ng-container>
                    <ng-container *ngIf="technician.getTechnicianDate(calendar_week_data.weekdays[week_day].timestamp, true).loadingStatus == 'init'">
                      <div style="width: 100%; background-color: white; font-size: .7rem;">
                        Lade Daten...
                      </div>
                    </ng-container>
                    <ng-container *ngIf="technician.getTechnicianDate(calendar_week_data.weekdays[week_day].timestamp, true).absences.length" >
                      <div style="position: absolute; top: 0; background-color: rgba(109,0,0,0.62); color: white; left: 0; right: 0; bottom: 0; padding: 0 4px; display: inline-flex;">
                        <div style="margin: auto; font-size: .6rem; line-height: .6rem;">
                          {{technician.getTechnicianDate(calendar_week_data.weekdays[week_day].timestamp, true).absences[0].type}}
                        </div>
                      </div>
                    </ng-container>
                    <ng-container *ngIf="!technician.getTechnicianDate(calendar_week_data.weekdays[week_day].timestamp, true).absences.length && technician.getTechnicianDate(calendar_week_data.weekdays[week_day].timestamp, true).getPublicHolidays().length" >
                      <div style="position: absolute; top: 0; background-color: rgba(109,0,0,0.62); color: white; left: 0; right: 0; bottom: 0; padding: 0 4px; display: inline-flex;">
                        <div style="margin: auto; font-size: .6rem; line-height: .6rem;">
                          Feiertag
                        </div>
                      </div>
                    </ng-container>
                  </td>
                </ng-container>
                <td class="black_gradient_background_no_hover white_text" style="padding: 2px; border: 1px solid black">{{technician.getWeekWorktimeForCalendarWeekData(calendar_week_data, operationTimeFilter)}}</td>
              </ng-container>
            </ng-container>

            <ng-container *ngIf="PATimeControl.Instance.selectedCalendarWeekData && !PATimeControl.Instance.selectedCalendarDay?.length">
              <ng-container *ngFor="let week_day of filteredWeekdays">
                <td style=" width: 2000px; padding: 0; position: relative; overflow-y: visible; overflow-x: clip;">
                  <ng-container *ngIf="technician.getTechnicianDate(PATimeControl.Instance.selectedCalendarWeekData.weekdays[week_day].timestamp, true).loadingStatus == 'loaded'">
                    <hawk-timeline class="horizontal_flex absolute"
                                   [tour]="technician.getTechnicianDate(PATimeControl.Instance.selectedCalendarWeekData.weekdays[week_day].timestamp, true).tour"
                                   [tourIsUpdating]="technician.getTechnicianDate(PATimeControl.Instance.selectedCalendarWeekData.weekdays[week_day].timestamp, true).tour.updating"
                                   [selected]="false"
                                   [showHours]="(shownTechnicians.length && shownTechnicians.indexOf(technician) == shownTechnicians.length - 1) || (preferredTechnicians.length && preferredTechnicians.indexOf(technician) == preferredTechnicians.length - 1)"
                                   [showTimeMarker]="false"
                                   [showEverySecondHour]="true"
                                   [showStatistics]="false"
                                   [showOperationIndexes]="false"
                                   [timeFilter]="PAFilterControl.Instance.selectedOperationTimeFilter"
                                   [chainOperations]="false"
                                   [height]="28"
                                   (selectTourEvent)="PATourPlannerControl.Instance.planTechnicianDate(technician.getTechnicianDate(PATimeControl.Instance.selectedCalendarWeekData.weekdays[week_day].timestamp, true))">
                    </hawk-timeline>
                  </ng-container>
                  <ng-container *ngIf="technician.getTechnicianDate(PATimeControl.Instance.selectedCalendarWeekData.weekdays[week_day].timestamp, true).loadingStatus == 'init'">
                    <div style="width: 100%; background-color: white; font-size: .7rem;">
                      Lade Daten...
                    </div>
                  </ng-container>
                  <ng-container *ngIf="technician.getTechnicianDate(PATimeControl.Instance.selectedCalendarWeekData.weekdays[week_day].timestamp, true).absences.length" >
                    <div style="position: absolute; top: 0; background-color: rgba(109,0,0,0.62); color: white; left: 0; right: 0; bottom: 0; padding: 0 4px; display: inline-flex;">
                      <div style="margin: auto; font-size: .6rem; line-height: .6rem;">
                        {{technician.getTechnicianDate(PATimeControl.Instance.selectedCalendarWeekData.weekdays[week_day].timestamp, true).absences[0].type}}
                      </div>
                    </div>
                  </ng-container>
                  <ng-container *ngIf="!technician.getTechnicianDate(PATimeControl.Instance.selectedCalendarWeekData.weekdays[week_day].timestamp, true).absences.length && technician.getTechnicianDate(PATimeControl.Instance.selectedCalendarWeekData.weekdays[week_day].timestamp, true).getPublicHolidays().length" >
                    <div style="position: absolute; top: 0; background-color: rgba(109,0,0,0.62); color: white; left: 0; right: 0; bottom: 0; padding: 0 4px; display: inline-flex;">
                      <div style="margin: auto; font-size: .6rem; line-height: .6rem;">
                        Feiertag
                      </div>
                    </div>
                  </ng-container>
                </td>
                <td class="black_gradient_background_no_hover" style="border: 1px solid black;">
                  {{technician.getTechnicianDate(PATimeControl.Instance.selectedCalendarWeekData.weekdays[week_day].timestamp, true).tour.route.time_specific_data[operationTimeFilter].total_time}}
                </td>
              </ng-container>
            </ng-container>
            <ng-container *ngIf="PATimeControl.Instance.selectedCalendarWeekData && PATimeControl.Instance.selectedCalendarDay?.length">
              <td style=" width: 2000px; padding: 0; position: relative; overflow-y: visible; overflow-x: clip;">
                <ng-container *ngIf="technician.getTechnicianDate(PATimeControl.Instance.selectedCalendarWeekData.weekdays[PATimeControl.Instance.selectedCalendarDay].timestamp, true).loadingStatus == 'loaded'">
                  <hawk-timeline class="horizontal_flex absolute"
                                 [tour]="technician.getTechnicianDate(PATimeControl.Instance.selectedCalendarWeekData.weekdays[PATimeControl.Instance.selectedCalendarDay].timestamp, true).tour"
                                 [tourIsUpdating]="technician.getTechnicianDate(PATimeControl.Instance.selectedCalendarWeekData.weekdays[PATimeControl.Instance.selectedCalendarDay].timestamp, true).tour.updating"
                                 [selected]="false"
                                 [showHours]="(shownTechnicians.length && shownTechnicians.indexOf(technician) == shownTechnicians.length - 1) || (preferredTechnicians.length && preferredTechnicians.indexOf(technician) == preferredTechnicians.length - 1)"
                                 [showTimeMarker]="false"
                                 [showStatistics]="false"
                                 [showOperationIndexes]="false"
                                 [timeFilter]="PAFilterControl.Instance.selectedOperationTimeFilter"
                                 [chainOperations]="false"
                                 [height]="28"
                                 (selectTourEvent)="PATourPlannerControl.Instance.planTechnicianDate(technician.getTechnicianDate(PATimeControl.Instance.selectedCalendarWeekData.weekdays[PATimeControl.Instance.selectedCalendarDay].timestamp, true))">
                  </hawk-timeline>
                </ng-container>
                <ng-container *ngIf="technician.getTechnicianDate(PATimeControl.Instance.selectedCalendarWeekData.weekdays[PATimeControl.Instance.selectedCalendarDay].timestamp, true).loadingStatus == 'init'">
                  <div style="width: 100%; background-color: white; font-size: .7rem;">
                    Lade Daten...
                  </div>
                </ng-container>
                <ng-container *ngIf="technician.getTechnicianDate(PATimeControl.Instance.selectedCalendarWeekData.weekdays[PATimeControl.Instance.selectedCalendarDay].timestamp, true).absences.length" >
                  <div style="position: absolute; top: 0; background-color: rgba(109,0,0,0.62); color: white; left: 0; right: 0; bottom: 0; padding: 0 4px; display: inline-flex;">
                    <div style="margin: auto; font-size: .6rem; line-height: .6rem;">
                      {{technician.getTechnicianDate(PATimeControl.Instance.selectedCalendarWeekData.weekdays[PATimeControl.Instance.selectedCalendarDay].timestamp, true).absences[0].type}}
                    </div>
                  </div>
                </ng-container>
                <ng-container *ngIf="!technician.getTechnicianDate(PATimeControl.Instance.selectedCalendarWeekData.weekdays[PATimeControl.Instance.selectedCalendarDay].timestamp, true).absences.length && technician.getTechnicianDate(PATimeControl.Instance.selectedCalendarWeekData.weekdays[PATimeControl.Instance.selectedCalendarDay].timestamp, true).getPublicHolidays().length" >
                  <div style="position: absolute; top: 0; background-color: rgba(109,0,0,0.62); color: white; left: 0; right: 0; bottom: 0; padding: 0 4px; display: inline-flex;">
                    <div style="margin: auto; font-size: .6rem; line-height: .6rem;">
                      Feiertag
                    </div>
                  </div>
                </ng-container>
              </td>
              <td class="black_gradient_background_no_hover" style="border: 1px solid black;">
                {{technician.getTechnicianDate(PATimeControl.Instance.selectedCalendarWeekData.weekdays[PATimeControl.Instance.selectedCalendarDay].timestamp, true).tour.route.time_specific_data[operationTimeFilter].driving_time}}min
              </td>
              <td class="black_gradient_background_no_hover" style="border: 1px solid black;">
                {{technician.getTechnicianDate(PATimeControl.Instance.selectedCalendarWeekData.weekdays[PATimeControl.Instance.selectedCalendarDay].timestamp, true).tour.route.time_specific_data[operationTimeFilter].operation_work_time}}min
              </td>
              <td class="black_gradient_background_no_hover" style="border: 1px solid black;">
                <a target="_blank" [href]="technician.getTechnicianDate(PATimeControl.Instance.selectedCalendarWeekData.weekdays[PATimeControl.Instance.selectedCalendarDay].timestamp, true).hawk_link">link</a>
              </td>
            </ng-container>
          </tr>
          <tr *ngIf="!showPreferredTechnicians && preferredTechnicians.indexOf(technician) == 0" (click)="showPreferredTechnicians = !showPreferredTechnicians">
            <td *ngIf="preferredTechnicians.indexOf(technician) == 0" style="text-align: center;" class="black_gradient_background" [attr.rowspan]="showPreferredTechnicians ? preferredTechnicians.length : 1">
              <fa-icon style="color: white;" [icon]="faEyeSlash"></fa-icon>
            </td>
            <td class="black_gradient_background" style="white-space: nowrap;">Techniker-Vergleich</td>
            <ng-container *ngIf="!PATimeControl.Instance.selectedCalendarWeekData && !PATimeControl.Instance.selectedCalendarDay?.length">
              <td [attr.colspan]="(filteredWeekdays.length + 1) * PATimeControl.Instance.shownCalendarWeeksData.length" class="black_gradient_background" style="color: white; border: 1px solid black;"></td>
            </ng-container>
            <ng-container *ngIf="PATimeControl.Instance.selectedCalendarWeekData && !PATimeControl.Instance.selectedCalendarDay?.length">
              <td [attr.colspan]="(filteredWeekdays.length * 2)" class="black_gradient_background" style="color: white; border: 1px solid black;"></td>
            </ng-container>
            <ng-container *ngIf="PATimeControl.Instance.selectedCalendarWeekData && PATimeControl.Instance.selectedCalendarDay?.length">
              <td [attr.colspan]="4" class="black_gradient_background" style="color: white; border: 1px solid black;"></td>
            </ng-container>
          </tr>
          <tr *ngIf="!showFilteredTechnicians && shownTechnicians.indexOf(technician) == 0" (click)="showFilteredTechnicians = !showFilteredTechnicians">
            <td *ngIf="shownTechnicians.indexOf(technician) == 0" style="text-align: center;" class="black_gradient_background" [attr.rowspan]="showFilteredTechnicians ? shownTechnicians.length : 1">
              <fa-icon style="color: white;" [icon]="faEyeSlash"></fa-icon>
            </td>
            <td class="black_gradient_background" style="white-space: nowrap;">Gefilterte Techniker</td>
            <ng-container *ngIf="!PATimeControl.Instance.selectedCalendarWeekData && !PATimeControl.Instance.selectedCalendarDay?.length">
              <td [attr.colspan]="(filteredWeekdays.length + 1) * PATimeControl.Instance.shownCalendarWeeksData.length" class="black_gradient_background" style="color: white; border: 1px solid black;"></td>
            </ng-container>
            <ng-container *ngIf="PATimeControl.Instance.selectedCalendarWeekData && !PATimeControl.Instance.selectedCalendarDay?.length">
              <td [attr.colspan]="(filteredWeekdays.length * 2)" class="black_gradient_background" style="color: white; border: 1px solid black;"></td>
            </ng-container>
            <ng-container *ngIf="PATimeControl.Instance.selectedCalendarWeekData && PATimeControl.Instance.selectedCalendarDay?.length">
              <td [attr.colspan]="4" class="black_gradient_background" style="color: white; border: 1px solid black;"></td>
            </ng-container>
          </tr>
          <tr *ngIf="shownTechnicians.length && shownTechnicians.indexOf(technician) == shownTechnicians.length - 1 && showFilteredTechnicians">
            <td style="text-align: center;" colspan="2" class="black_gradient_background" (click)="increaseShowTechnicianIndex()"><div style="width: 20px; margin: auto;"><div class="rotate">&#10092;</div></div></td>
            <ng-container *ngIf="!PATimeControl.Instance.selectedCalendarWeekData && !PATimeControl.Instance.selectedCalendarDay?.length">
              <td [attr.colspan]="(filteredWeekdays.length + 1) * PATimeControl.Instance.shownCalendarWeeksData.length" class="black_gradient_background_no_hover" style="color: white; border: 1px solid black;"></td>
            </ng-container>
            <ng-container *ngIf="PATimeControl.Instance.selectedCalendarWeekData && !PATimeControl.Instance.selectedCalendarDay?.length">
              <td [attr.colspan]="(filteredWeekdays.length * 2)" class="black_gradient_background_no_hover" style="color: white; border: 1px solid black;"></td>
            </ng-container>
            <ng-container *ngIf="PATimeControl.Instance.selectedCalendarWeekData && PATimeControl.Instance.selectedCalendarDay?.length">
              <td [attr.colspan]="4" class="black_gradient_background_no_hover" style="color: white; border: 1px solid black;"></td>
            </ng-container>
          </tr>
          <tr *ngIf="preferredTechnicians.length && preferredTechnicians.indexOf(technician) == preferredTechnicians.length - 1 && showPreferredTechnicians && !shownTechnicians.length">
            <ng-container *ngIf="!PATimeControl.Instance.selectedCalendarWeekData && !PATimeControl.Instance.selectedCalendarDay?.length">
              <td [attr.colspan]="(filteredWeekdays.length + 1) * PATimeControl.Instance.shownCalendarWeeksData.length + 4" class="black_gradient_background_no_hover" style="color: white; border: 1px solid black; height: 24px;"></td>
            </ng-container>
            <ng-container *ngIf="PATimeControl.Instance.selectedCalendarWeekData && !PATimeControl.Instance.selectedCalendarDay?.length">
              <td [attr.colspan]="(filteredWeekdays.length * 2) + 4" class="black_gradient_background_no_hover" style="color: white; border: 1px solid black; height: 24px;"></td>
            </ng-container>
            <ng-container *ngIf="PATimeControl.Instance.selectedCalendarWeekData && PATimeControl.Instance.selectedCalendarDay?.length">
              <td [attr.colspan]="8" class="black_gradient_background_no_hover" style="color: white; border: 1px solid black; height: 24px;"></td>
            </ng-container>
          </tr>
        </ng-container>

      </tbody>

    </table>
  </div>

</div>