import { AgGridAngular } from 'ag-grid-angular';
import { RouterModule } from "@angular/router";

import { Component, inject, OnInit, ViewEncapsulation } from '@angular/core'
import { ColDef } from 'ag-grid-community'
import { GridApi, GridState, RowClassRules, SideBarDef } from 'ag-grid-enterprise'
import { GridReadyEvent } from 'ag-grid-community'

import { DeliveryNote, DeliveryNoteSearchParams } from 'src/app/_models'

import { AG_GRID_LOCALE_DE } from 'src/app/_helpers/aggrid.locale.de'
import { DeliveryNoteService } from 'src/app/_services'
import { ActivatedRoute } from '@angular/router'
import { dateFormatter } from 'src/app/_helpers/aggrid-renderer'
import { AgGridGenericLinkRenderer } from '../_shared/ag-grid-renderer/ag-grid-generic-link.component'
import { AgGridThemeService } from 'src/app/_services/ag-grid-theme.service';

@Component({
    selector: 'hawk-delivery-note-search',
    templateUrl: './delivery-note-search.component.html',
    styleUrls: ['./delivery-note-search.component.scss'],
    imports: [AgGridAngular, RouterModule]
})
export class DeliveryNoteSearchComponent implements OnInit {
  public hawkTheme = inject(AgGridThemeService).getTheme();
  public deliveryNotes: DeliveryNote[] = []

  public searchParams: DeliveryNoteSearchParams = {}

  public sideBarOptions: SideBarDef = {
    hiddenByDefault: false,
    toolPanels: ['columns', 'filters']
  }

  public locale = AG_GRID_LOCALE_DE
  public storageKey = 'deliveryNoteSearchStateSettings'

  public rowSelection: "single" | "multiple" = "multiple"
  public initialState?: GridState;

  public rowClassRules: RowClassRules = {
    // if tech on site, color row
    'tech-on-site': (params) => { return params.data.tech_on_site },
  }

  public defaultColDef: ColDef = {
    filter: true,
    flex: 1
  }

  // Column Definitions: Defines the columns to be displayed.
  public colDefs: ColDef[] = [
    {
      field: "number",
      headerName: "Nr",
      cellRenderer: AgGridGenericLinkRenderer,
      cellRendererParams: {
        labelFromData: true,
        dataKey: 'number',
        routerLink: ['/', 'lieferscheine', '@id'],
        icon: ['fa', 'fa-truck']
      },
    },
    {
      field: "sender_address_city",
      headerName: "Absenderort",
      hide: true
    },
    {
      field: "recipient_address_city",
      headerName: "Zielort",
      hide: true
    },
    {
      field: "recipient_store_data",
      headerName: "Empfänger-Store",
      cellRenderer: ({data}) => {
        if(!data.recipient_store_data) return '-';

        return `${data.recipient_store_data.nr} ${data.recipient_store_data.name}`
      },
      hide: true
    },
    {
      field: "shipping_date",
      headerName: "Versand",
      valueFormatter: dateFormatter('dd.MM.YYYY')
    },
    {
      field: "receive_date",
      headerName: "Empfang",
      valueFormatter: dateFormatter()
    },
    {
      field: "carrier",
      headerName: "Carrier",
      cellRenderer: ({data}) => {
        if(data.status !== 'PREPARED') {
          if(data.shipping_by === 'SHIPCLOUD') {
            return `<span class="bold">${data.shipcloud_carrier}</span> ${data.shipcloud_service}`
          } else if(data.shipping_by === 'OTHER') {
            return `<span class="bold">${data.shipping_other_carrier}</span> ${data.shipping_other_service}`
          }
        }
        return '-';
      }
    },
    {
      field: "articles_count",
      headerName: "Artikel",
    },
    {
      field: "status",
      headerName: "Status",
      cellRenderer: ({data}) => {
        if (data.status === 'PREPARED') {
          return "<span class='label label-own label-default'>Vorbereitet</span>"
        } else if (data.status === 'SHIPPED') {
          return "<span class='label label-own label-warning'>Versandt</span>"
        } else if (data.status === 'DELIVERED') {
          return "<span class='label label-own label-success'>Zugestellt</span>"
        } else if (data.status === 'RECEIPT') {
          return "<span class='label label-own label-success'>Quittiert</span>"
        }
        return '';
      }
    }
  ]

  private gridApi!: GridApi;

  constructor(
    private deliveryNoteService: DeliveryNoteService,
    private route: ActivatedRoute,
  ) { }

  onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api
  }

  ngOnInit(): void {
    this.initialState = this.getState()

    this.route.queryParams.subscribe(params => {
      if (params['number']) {
        this.searchParams['number'] = params['number']
      }
      if (params['product_category']) {
        this.searchParams['product_category'] = params['product_category']
      }
      if (params['status_id']) {
        this.searchParams['status_id'] = params['status_id']
      }
      if (params['product_id']) {
        this.searchParams['product_id'] = params['product_id']
      }
      if (params['sender_city']) {
        this.searchParams['sender_city'] = params['sender_city']
      }
      if (params['rma']) {
        this.searchParams['rma'] = params['rma']
      }
      if (params['recipient_city']) {
        this.searchParams['recipient_city'] = params['recipient_city']
      }
      if(params['serial_number']) {
        this.searchParams['serial_number'] = params['serial_number']
      }
      if(params['create_date_from']) {
        this.searchParams['create_date_from'] = params['create_date_from']
      }
      if(params['create_date_to']) {
        this.searchParams['create_date_to'] = params['create_date_to']
      }

      this.deliveryNoteService.search(this.searchParams).subscribe({
        next: (data) => {
          this.deliveryNotes = data;
        },
        error: (err) => {
          console.error(err)
        }
      })
    })
  }

  public quicksearchChanged(e: Event) {
    const newValue = (e.target as HTMLInputElement).value
    console.log("newValue", newValue)
    this.gridApi.setGridOption(
      "quickFilterText",
      newValue,
    )
  }

  public saveState() {
    let state = this.gridApi.getState()
    delete state['filter']
    delete state.pagination.page
    const serializedState = JSON.stringify(state)
    localStorage.setItem(this.storageKey, serializedState)
  }

  public deleteState() {
    localStorage.removeItem(this.storageKey)
  }

  public getState(): GridState {
    return JSON.parse(localStorage.getItem(this.storageKey))
  }

  public printState() {
    const stateJson = this.getState()
    console.log(localStorage.getItem(this.storageKey), stateJson)
  }
}