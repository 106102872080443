<div class="d-flex align-items-center" *ngIf="deliveryNote">
    <div class="col t-header d-flex align-items-center justify-content-start">
        <h4 class="mb20 d-flex align-items-center gap-1">
            <i class="fa fa-truck mr6" aria-hidden="true"></i>
            <ul class="list-group list-group-horizontal b-custom">
                <li class="list-group-item bg-transparent border-0">
                    <a
                    class="g-link"
                    [routerLink]="['/', 'lieferscheine', 'suche', 'index']"
                    >
                    Lieferscheine
                    </a>
                </li>
                <li class="list-group-item bg-transparent border-0">
                    <a
                    class="g-link"
                    [routerLink]="['/', 'lieferscheine', deliveryNote.id]"
                    >
                        <span>{{ deliveryNote.number }}</span>
                    </a>
                </li>
                <li class="list-group-item bg-transparent border-0">
                    Retoure-Label erstellen
                </li>
            </ul>
        </h4>
    </div>
</div>
<form [formGroup]="shipcloudRetoureForm" (submit)="saveShipcloudRetoure()" *ngIf="deliveryNote">
    <div class="ticketPage">
        <section class="main-content">
            <section>
                <h2>Versand</h2>
                <div class="form-group col-6">
                    <ng-select
                    [items]="carriersList"
                    formControlName="carrier"
                    placeholder="carrier"
                    [required]="true"
                    >
                    <ng-template ng-label-tmp let-item="item">
                        {{ item }}
                    </ng-template>
                    <ng-template
                        ng-option-tmp
                        let-item="item"
                        let-search="searchTermStatus"
                    >
                        <div
                        *ngIf="
                            item
                            .toLowerCase()
                            .includes(searchTerm.toLowerCase())
                        "
                        >
                        {{ item }}
                        </div>
                    </ng-template>
                    </ng-select>

                </div>
                <div class="form-group my-2">
                    <mat-form-field class="col-6" appearance="outline">
                        <mat-label>Paketbeschreibung</mat-label>
                        <textarea formControlName="description" matInput></textarea>
                        <mat-hint>Bei UPS erforderlich, keine Umlaute, nicht zu lang</mat-hint>
                    </mat-form-field>
                </div>
                <div class="form-group">
                    <mat-checkbox formControlName="create_retoure_note">Für die Retoure einen neuen Lieferschein erstellen</mat-checkbox>
                </div>
            </section>
            <div class="d-flex col-auto my-2 align-items-center justify-content-between">
                <span class="d-flex col-auto gap-2 align-items-center">
                    <button mat-raised-button class="button" [disabled]="shipcloudRetoureForm.invalid || submittingLabel" type="submit" >
                        Retoure-Label erstellen
                    </button>
                    <mat-checkbox formControlName="test">Nur Test</mat-checkbox>
                </span>
                <a class="text-right" [routerLink]="['/', 'lieferscheine', deliveryNote.id]" >Abbrechen</a>
            </div>
        </section>
        <aside>
            <section>
                <h2>Paket</h2>
                <div class="form-group">
                    <mat-form-field appearance="outline">
                        <mat-label>Höhe</mat-label>
                        <input
                            matInput
                            type="number"
                            placeholder="Höhe"
                            formControlName="height"
                            required
                        />
                        <span matTextPrefix>cm&nbsp;</span>
                    </mat-form-field>
                </div>
                <div class="form-group">
                    <mat-form-field appearance="outline">
                        <mat-label>Breite</mat-label>
                        <input
                            matInput
                            type="number"
                            placeholder="Breite"
                            formControlName="width"
                            required
                        />
                        <span matTextPrefix>cm&nbsp;</span>
                    </mat-form-field>
                </div>
                <div class="form-group">
                    <mat-form-field appearance="outline">
                        <mat-label>Länge</mat-label>
                        <input
                            matInput
                            type="number"
                            placeholder="Länge"
                            formControlName="length"
                            required
                        />
                        <span matTextPrefix>cm&nbsp;</span>
                    </mat-form-field>
                </div>
                <div class="form-group">
                    <mat-form-field appearance="outline">
                        <mat-label>Gewicht</mat-label>
                        <input
                            matInput
                            type="number"
                            placeholder="Gewicht"
                            formControlName="weight"
                            required
                        />
                        <span matTextPrefix>kg&nbsp;</span>
                    </mat-form-field>
                </div>
            </section>
        </aside>
    </div>
</form>