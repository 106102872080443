<div class="d-flex align-items-center">
    <div class="col t-header d-flex align-items-center justify-content-start">
        <h4 class="mb20 d-flex align-items-center gap-1">
            <i class="fa fa-ticket"></i>
            <ul class="list-group list-group-horizontal b-custom">
                <li class="list-group-item bg-transparent border-0">
                    <a
                    class="g-link"
                    [routerLink]="['/', 'ticket', 'suche', 'index']"
                    >
                    Tickets
                </a>
            </li>
            <li class="list-group-item bg-transparent border-0">
                <span>Ein neues Ticket erstellen</span>
            </li>
            </ul>
        </h4>
    </div>
</div>
<form [formGroup]="ticketForm" (submit)="createOrUpdateArticle()">
    <div class="ticketPage">
        <section class="main-content">
            <header class="columns">
                <section>
                    <h2>Projektauswahl</h2>
                    <div class="form-group col-6">
                        <mat-label>Projekt *</mat-label>
                        <ng-select
                        [items]="projects"
                        bindLabel="name"
                        bindValue="id"
                        formControlName="project_id"
                        [loading]="loadingProjects"
                        [virtualScroll]="true"
                        (scrollToEnd)="fetchMoreProjects()"
                        [required]="true"
                        >
                        <ng-template ng-label-tmp let-item="item">
                            {{ item.name }}
                        </ng-template>
                        <ng-template
                            ng-option-tmp
                            let-item="item"
                            let-search="searchTermStatus"
                        >
                            <div
                            *ngIf="
                                item.name
                                .toLowerCase()
                                .includes(searchTerm.toLowerCase())
                            "
                            >
                            {{ item.name }}
                            </div>
                        </ng-template>
                        </ng-select>
                    </div>

                    <div class="form-group col-6 mb-2">
                        <mat-label>Status</mat-label>
                        <ng-select
                        [items]="projectStatuses"
                        bindLabel="name"
                        bindValue="id"
                        formControlName="status_id"
                        [loading]="loadingProjectStatuses"
                        [required]="true"
                        >
                        <ng-template ng-label-tmp let-item="item">
                            <div class="d-flex align-items-center gap-1">
                                <span *ngIf="item.closed" class="fa fa-check-circle text-success"></span>
                                <span *ngIf="item.canceled" class="fa fa-ban text-danger"></span>
                                <span *ngIf="item.report_closed" class="fa fa-hand-paper-o text-muted"></span>
                                <span>{{ item.name }}</span>
                            </div>
                        </ng-template>
                        <ng-template
                            ng-option-tmp
                            let-item="item"
                            let-search="searchTermStatus"
                        >
                            <div
                            *ngIf="
                                item.name
                                .toLowerCase()
                                .includes(searchTerm.toLowerCase())
                            "
                            class="d-flex align-items-center gap-1"
                            >
                                <span *ngIf="item.closed" class="fa fa-check-circle text-success"></span>
                                <span *ngIf="item.canceled" class="fa fa-ban text-danger"></span>
                                <span *ngIf="item.report_closed" class="fa fa-hand-paper-o text-muted"></span>
                                <span>{{ item.name }}</span>
                            </div>
                        </ng-template>
                        </ng-select>
                    </div>

                    <div class="form-group col-6 mb-2">
                        <mat-label>Priorität</mat-label>
                        <ng-select
                            [items]="priorities"
                            bindLabel="name"
                            bindValue="id"
                            formControlName="priority_id"
                            [loading]="loadingPriorities"
                            [required]="true"
                            >
                            <ng-template ng-label-tmp let-item="item">
                                <div class="d-flex align-items-center gap-1">
                                    <span *ngIf="item.color" class="fa-stack" style="font-size: 60%;">
                                        <i class="fa fa-circle fa-stack-2x" style="color: #333"></i>
                                        <i class="fa fa-circle fa-stack-2x" style="color: {{item.color}};"></i>
                                    </span>
                                    <span *ngIf="!item.color">&nbsp;</span>
                                    <span>{{ item.name }}</span>
                                </div>
                            </ng-template>
                            <ng-template
                                ng-option-tmp
                                let-item="item"
                                let-search="searchTermStatus"
                            >
                                <div
                                *ngIf="
                                    item.name
                                    .toLowerCase()
                                    .includes(searchTerm.toLowerCase())
                                "
                                class="d-flex align-items-center gap-1"
                                >
                                <span *ngIf="item.color"  class="fa-stack" style="font-size: 60%;">
                                    <i class="fa fa-circle fa-stack-2x" style="color: #333"></i>
                                    <i class="fa fa-circle fa-stack-2x" style="color: {{item.color}}"></i>
                                </span>
                                <span *ngIf="!item.color">&nbsp;</span>
                                <span>{{ item.name }}</span>
                                </div>
                            </ng-template>
                        </ng-select>
                    </div>

                    <div class="form-group col-6 mb-2">
                      <mat-label>Store</mat-label>
                      <ng-select
                      class="store-select"
                      [items]="stores"
                      bindLabel="name"
                      bindValue="id"
                      formControlName="store_id"
                      [searchFn]="storeSearchFn"
                      [loading]="loadingStores"
                      [required]="true"
                      >
                      <ng-template ng-label-tmp let-item="item">
                          {{ item.name }}<br />
                          <small>{{ item.address_city }}, {{ item.address_street }}</small> 
                      </ng-template>
                      <ng-template
                          ng-option-tmp
                          let-item="item"
                          let-search="searchTermStatus"
                      >
                          <div
                          *ngIf="
                              item.name
                              .toLowerCase()
                              .includes(searchTerm.toLowerCase())
                          "
                          >
                          {{ item.name }}<br />
                          <small>{{ item.address_zip }} {{ item.address_city }}, {{ item.address_street }}</small> 
                          </div>
                      </ng-template>
                      </ng-select>
                  </div>
                </section>
            </header>
            <header class="columns">
                <section>
                    <h2>Stammdaten</h2>
                    <div class="form-group">
                        <mat-form-field class="col-12" appearance="outline">
                            <mat-label>Fehlertext</mat-label>
                            <textarea formControlName="description" matInput></textarea>
                        </mat-form-field>
                    </div>
                    <div class="form-group">
                        <mat-form-field class="col-3 mr-2" appearance="outline">
                            <mat-label>SLA-Datum</mat-label>
                            <input
                                matInput
                                [matDatepicker]="datesla"
                                formControlName="datesla_date"
                            />
                            <mat-datepicker-toggle matIconSuffix [for]="datesla"></mat-datepicker-toggle>
                            <mat-datepicker #datesla></mat-datepicker>
                        </mat-form-field>

                        <mat-form-field class="col-2 offset-1" appearance="outline">
                            <mat-label>Uhrzeit</mat-label>
                            <input matInput
                              [matTimepicker]="timepicker"
                              formControlName="datesla_time"
                              >
                            <mat-timepicker #timepicker/>
                            <mat-timepicker-toggle [for]="timepicker" matSuffix/>
                        </mat-form-field>

                        @if (dateSLAPresent()) {
                          <p (click)="deleteSLA()">{{ ticketForm.value['datesla']  }} gesetzt. SLA löschen.</p>
                        }
                    </div>
                </section>
            </header>
            <header class="columns">
                <section>
                    <h2>Zuordnung</h2>
                    <div class="form-group">
                        <mat-form-field appearance="outline" class="col-6">
                            <mat-label>Externe Nummer</mat-label>
                            <input
                                matInput
                                type="text"
                                placeholder="Externe Nummer"
                                formControlName="external_order_nr"
                            />
                        </mat-form-field>
                    </div>
                </section>
            </header>
            <header class="columns" *ngIf="ticketFields.length > 0">
                <section>
                    <h2>Weitere Felder</h2>
                    <span *ngIf="loadingticketFields">
                        <i class="fa fa-spinner fa-spin"></i>
                    </span>
                    <div *ngIf="!loadingticketFields">
                        <div formGroupName="fields">
                            <div class="form-group gap-1 d-flex align-items-center" *ngFor="let field of fields.controls; let i=index">
                                <div class="d-flex gap-2 align-items-center col-4" [formGroupName]="i">
                                    <mat-form-field appearance="outline">
                                        <mat-label>{{field.value.name || field.value.description}}</mat-label>
                                        <textarea
                                        formControlName="field_value"
                                        *ngIf="field.value.field_type == 'LONGTEXT'" matInput></textarea>
                                        <input
                                            *ngIf="field.value.field_type == 'TEXT'"
                                            formControlName="field_value"
                                            matInput
                                            type="text"
                                            [value]="field.value && field.value.id > 0 ? field.value.field_value : ''"
                                            [placeholder]="field.value.name || field.value.description"
                                        />
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </header>
            <div class="d-flex col-auto my-2 align-items-center justify-content-between">
                <span class="d-flex col-auto gap-2 align-items-center" *ngIf="formLoaded">
                    <button mat-raised-button  [disabled]="ticketForm.invalid || submittingTicket" class="button" type="submit" >
                        Ticket erstellen
                    </button>
                </span>
                <a class="text-right" [routerLink]="['/', 'ticket', 'suche', 'index']">Abbrechen</a>
            </div>
        </section>
        <aside>&nbsp;</aside>
    </div>
</form>