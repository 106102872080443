import { CommonModule } from "@angular/common";

import { Component, OnInit } from '@angular/core'
import { QmJob } from 'src/app/_models'
import { QmJobService } from 'src/app/_services'

import * as webNotification from 'simple-web-notification'
import { QmPageTableComponent } from "./qm-page-table/qm-page-table.component";

@Component({
  imports: [CommonModule, QmPageTableComponent],
    selector: 'hawk-qm-page',
    templateUrl: './qm-page.component.html',
    styleUrls: ['./qm-page.component.scss'],
})
export class QmPageComponent implements OnInit {

  public jobs: QmJob[] = []

  constructor(
    private qmJobService: QmJobService,
  ) { }

  ngOnInit(): void {
    this.getJobs()
    this.startWebsocket()

    webNotification.requestPermission((granted) => {
      if (granted) {
        console.log('Notification erlaubt')
      } else {
        console.log('Notification nicht erlaubt')
      }
    })
  }

  public getJobs(): void {
    this.qmJobService.getUndone().subscribe(
      (data) => {
        this.jobs = data
      },
      (err) => {
        console.error(err)
      },
    )
  }

  public getNotification(): void {

    webNotification.showNotification('QM-Job geändert', {
      body: 'Es wurden Änderungen an den QM-Jobs geladen',
      icon: 'my-icon.ico',
      onClick: () => {
          console.log('Notification clicked.')
          window.focus()
      },
      autoClose: 10000 // auto close the notification after 4 seconds (you can manually close it via hide function)
  },  (error, hide) => {
      if (error) {
          window.alert('Unable to show notification: ' + error.message)
      } else {
          console.log('Notification Shown.')
      }
  })
  }

  public startWebsocket(): void {
    const meth = {
      connected: () =>{
        console.log('QMPage connectQMUpdate Verbindung hergestellt')
      },
      disconnected: () =>{
        console.log('QMPage connectQMUpdate Verbindung getrennt')
      },
      received: (data) => {
        this.getJobs()
        console.log('QMPage connectQMUpdate: ', data)
        this.getNotification()
      }
    }
    this.qmJobService.connectQMUpdate(meth)
  }

  public jobUpdated(): void {
    this.getJobs()
  }
}