<div id="isd_container">
  <div *ngIf="insufficientInstanceAmount > 0" class="inner_container">
    Fehler
    <div *ngIf="selectedInstance" id="selectedInstance">
      <h3>Ungenügend Daten für {{getSelectedInstanceName()}}</h3>
      <p *ngIf="hawkLink"> Hawk-Link: <a [href]=hawkLink target="_blank">hier</a></p>
      <p> Bitte geben Sie eine gültige Adresse (inkl. Koordinaten) an!</p>
      <div id="ins_data_geocoder"></div>
      <br>
      <button [disabled]="!selectedInstanceIsReadyToUpdate()" (click)="updateSelectedInstance()">
        Adresse updaten
      </button>
    </div>
  </div>
  
  <div *ngIf="insufficientInstanceAmount == 0" class="inner_container">
    Alles erledigt!
  </div>
</div>