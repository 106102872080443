<div class="d-flex align-items-center" *ngIf="deliveryNote">
    <div class="col t-header d-flex align-items-center justify-content-start">
        <h4 class="mb20 d-flex align-items-center gap-1">
            <i class="fa fa-truck mr6" aria-hidden="true"></i>
            <ul class="list-group list-group-horizontal b-custom">
                <li class="list-group-item bg-transparent border-0">
                    <a
                    class="g-link"
                    [routerLink]="['/', 'lieferscheine', 'suche', 'index']"
                    >
                        Lieferscheine
                    </a>
                </li>
                <li class="list-group-item bg-transparent border-0">
                    <span>{{deliveryNote.number}}</span>
                    <!-- <span *ngIf="deliveryNote.shipcloud_retoure_carrier && deliveryNote.related_note_id">
                        <a class="g-link"
                        [routerLink]="['/', 'lieferscheine', deliveryNote.id]"
                        >
                            {{deliveryNote.related_note_number}}
                        </a>
                    </span> -->
                </li>
            </ul>
        </h4>
    </div>
</div>
<div class="ticketPage" *ngIf="deliveryNote">
    <section class="main-content">
        <ul class="note-list columns">
            <li class="note note-success">
                <h4>Lieferschein</h4>
                <a class="btn btn-link white btn-block" [routerLink]="['/', 'lieferscheine', deliveryNote.id, 'edit']">
                    Bearbeiten
                </a>
                <a
                class="btn bottom-item bg-white text-black btn-secondary d-flex gap-1 align-items-center justify-content-center col-12"
                [href]="env.apiURL + 'delivery_notes/' + deliveryNote.id + '.pdf'"
                target="_blank">
                    <i class="fa fa-print mr6"></i>Drucken
                </a>
            </li>
            <!-- Block: Label START Here -->
            <li *ngIf="deliveryNote.retoure; else conditionL1ElseIf" class="note note-disabled">
                <h4>Label</h4>
                <div class="text-center white small mb12" style="margin-top: 18px">-</div>
            </li>
            <ng-template #conditionL1ElseIf>
                <li *ngIf="deliveryNote.status == 'PREPARED'; else conditionL2Else" class="note note-warning">
                    <h4>Label</h4>
                    <div class="btn-group btn-block">
                        <span class="dropdown" ngbDropdown>
                            <a class="btn btn-link btn-block white nav-link dropdown-toggle" id="dropdown01" ngbDropdownToggle>
                                Erstellen
                            </a>
                            <div class="dropdown-menu" aria-labelledby="dropdown01" ngbDropdownMenu>
                                <a class="dropdown-item" [routerLink]="['/', 'lieferscheine', deliveryNote.id, 'shipping']">
                                    Shipcloud
                                </a>
                                <a class="dropdown-item" href="/warehouse/deliveryNote/{{deliveryNote.id}}/shippingOther">
                                    Andere
                                </a>
                            </div>
                        </span>
                    </div>
                </li>
            </ng-template>
            <ng-template #conditionL2Else>
                <li *ngIf="!deliveryNote.retoure && deliveryNote.status != 'PREPARED'" class="note note-success">
                    <h4>Label</h4>
                    <div class="text-center mb12" style="margin-top: 18px">
                        <span class="label label-default label-own" *ngIf="deliveryNote.shipping_by == 'SHIPCLOUD'">
                            {{deliveryNote.shipcloud_carrier}} - {{deliveryNote.shipcloud_service}}
                        </span>
                        <span class="label label-default label-own" *ngIf="deliveryNote.shipping_by == 'OTHER'">
                            {{deliveryNote.shipping_other_carrier}} - {{deliveryNote.shipping_other_service}}
                        </span>
                        <span  *ngIf="deliveryNote.shipping_by != 'SHIPCLOUD' && deliveryNote.shipping_by != 'OTHER'" class="white">-</span>
                    </div>
                    <a href="{{deliveryNote.shipcloud_label_url}}" class="btn btn-default btn-block btn-secondary bg-white text-black d-flex gap-1 align-items-center justify-content-center col-12" style="margin-top: 31px" target="_blank" *ngIf="deliveryNote.shipping_by == 'SHIPCLOUD'">
                        <i class="fa fa-print mr6"></i>Drucken
                    </a>
                </li>
            </ng-template>
            <!-- Block: Label END Here -->

            <!-- Block: Retoure START Here -->
            <li *ngIf="deliveryNote.related_note_id" class="note note-success">
                <h4>Retoure</h4>
                <div class="text-center">
                    <a [routerLink]="['/', 'retoure', deliveryNote.related_note_id]" class="white">
                        {{deliveryNote.related_note_number}}
                    </a>
                </div>
                <a *ngIf="deliveryNote.status == 'PREPARED'" (click)="createRetoure()" class="btn btn-link white btn-block">
                    Neu erstellen
                </a>
                <div class="btn-group btn-block" *ngIf="deliveryNote.status != 'PREPARED'">
                    <span class="dropdown" ngbDropdown>
                        <a class="btn btn-link btn-block white nav-link dropdown-toggle" id="dropdown02" ngbDropdownToggle>
                            Neu erstellen
                        </a>
                        <div class="dropdown-menu" aria-labelledby="dropdown02" ngbDropdownMenu>
                            <a class="dropdown-item" [routerLink]="['/', 'lieferscheine', deliveryNote.id, 'shipping-retoure']">
                                Shipcloud
                            </a>
                            <a class="dropdown-item" href="javascript:;" (click)="createRetoure()">
                                Ohne Label
                            </a>
                        </div>
                    </span>
                </div>
                <a *ngIf="deliveryNote.shipcloud_retoure_shipcloud_id"
                    href="{{deliveryNote.shipcloud_retoure_label_url}}"
                    class="btn bg-white text-black btn-secondary d-flex gap-1 align-items-center justify-content-center col-12"
                    [ngStyle]="{'margin-top': deliveryNote.related_note_id ? '19px' : '24px' }"
                    target="_blank"
                >
                    <i class="fa fa-print mr6"></i>Drucken
                </a>
            </li>
            <li *ngIf="!deliveryNote.related_note_id" class="note note-warning">
                <h4>Retoure</h4>
                <a
                    *ngIf="deliveryNote.status == 'PREPARED'"
                    class="btn btn-link white btn-block"
                    (click)="createRetoure()"
                >
                    Erstellen
                </a>
                <div class="btn-group btn-block" *ngIf="deliveryNote.status != 'PREPARED'">
                    <span class="dropdown" ngbDropdown>
                        <a class="btn btn-link btn-block white nav-link dropdown-toggle" id="dropdown03" ngbDropdownToggle>
                            Erstellen
                        </a>
                        <div class="dropdown-menu" aria-labelledby="dropdown03" ngbDropdownMenu>
                            <a class="dropdown-item" [routerLink]="['/', 'lieferscheine', deliveryNote.id, 'shipping-retoure']">
                                Shipcloud
                            </a>
                            <a class="dropdown-item"  href="javascript:;" (click)="createRetoure()">
                                Ohne Label
                            </a>
                        </div>
                    </span>
                </div>
            </li>
            <!-- Block: Retoure END Here -->

            <!-- Block: Pickup START Here -->
            <li *ngIf="deliveryNote.retoure; else conditionPElse" class="note note-disabled">
                <h4>Pickup</h4>
                <div class="text-center btn-white small mb12" style="margin-top: 18px">-</div>
            </li>
            <ng-template #conditionPElse>
                <li *ngIf="deliveryNote.status == 'PREPARED'; else conditionP1Else" class="note note-warning">
                    <h4>Pickup</h4>
                    <div class="text-center btn-white mb12" style="margin-top: 18px">-</div>
                </li>
                <ng-template #conditionP1Else>
                    <li *ngIf="deliveryNote.shipcloud_pickup_id; else conditionP2Else" class="note note-success">
                        <h4>Pickup</h4>
                        <div class="text-center text-white mb12 small" style="margin-top: 18px">
                            <div *ngIf="deliveryNote.shipcloud_pickup_data.earliest">
                                {{deliveryNote.shipcloud_pickup_data.earliest | date: 'dd.MM.yyyy HH:mm'}}
                            </div>
                            <div *ngIf="deliveryNote.shipcloud_pickup_data.latest">
                                {{deliveryNote.shipcloud_pickup_data.latest | date: 'dd.MM.yyyy HH:mm'}}
                            </div>
                        </div>
                    </li>
                    <ng-template #conditionP2Else>
                        <li *ngIf="['UPS', 'DPD'].includes(deliveryNote.shipcloud_carrier); else conditionP3Else" class="note note-warning">
                            <h4>Pickup</h4>
                            <a [routerLink]="['/', 'lieferscheine', deliveryNote.id, 'pickup']"
                            class="btn btn-default btn-block btn-secondary bg-white text-black d-flex gap-1 align-items-center justify-content-center col-12">Zeit festlegen</a>
                        </li>
                        <ng-template #conditionP3Else>
                            <li class="note note-disabled">
                                <h4>Pickup</h4>
                                <div class="text-center mb12" style="margin-top: 18px">
                                    <span class="btn-white small">Funktioniert nur mit Shipcloud (UPS & DPD)</span>
                                </div>
                            </li>
                        </ng-template>
                    </ng-template>
                </ng-template>
            </ng-template>
            <!-- Block: Pickup END Here -->
            <!-- Block: Sendung START Here -->
            <li *ngIf="deliveryNote.retoure; else conditionS1ElseIf" class="note note-warning">
                <h4>Sendung</h4>
                <div class="text-center mb12 d-flex gap-2" style="margin-top: 18px">
                    <span class="label label-default label-own d-flex gap-2">
                        {{deliveryNote.related_note_id && deliveryNote.related_note_data.shipcloud_retoure_tracking_status}}
                        <span *ngIf="!deliveryNote.related_note_id">
                            Unbekannt
                        </span>
                    </span>
                    <button
                    *ngIf="!isCheckingShipcloudTrackingStatus"
                    (click)="checkShipcloudTrackingStatus()"
                    class="btn btn-sm white ml4 d-flex text-white">
                        <i class="fa fa-refresh"></i>
                    </button>
                    <button
                    *ngIf="isCheckingShipcloudTrackingStatus"
                    [disabled]="true"
                    class="btn btn-sm white ml4 d-flex text-white">
                        <i class="fa fa-refresh fa-spin"></i>
                    </button>
                </div>
                <a href="{{deliveryNote.shipcloud_retoure_tracking_url}}" class="btn btn-default btn-block" style="margin-top: 41px"  target="_blank">Tracking</a>
            </li>
            <ng-template #conditionS1ElseIf>
                <li *ngIf="deliveryNote.status == 'PREPARED'; else conditionS2ElseIf" class="note note-warning">
                    <h4>Sendung</h4>
                    <div class="text-center btn-white mb12" style="margin-top: 18px">-</div>
                </li>
            </ng-template>
            <ng-template #conditionS2ElseIf>
                <li *ngIf="deliveryNote.shipping_by == 'SHIPCLOUD'; else conditionS3ElseIf" class="note note-success">
                    <h4>Sendung</h4>
                    <div class="text-center mb12 d-flex gap-2 justify-content-center align-items-center">
                        <span class="label label-default label-own text-white">
                            {{deliveryNote.shipcloud_tracking_status ? deliveryNote.shipcloud_tracking_status : 'Unbekannt' }}
                        </span>
                        <button
                        *ngIf="!isCheckingShipcloudTrackingStatus"
                        (click)="checkShipcloudTrackingStatus()"
                        class="btn btn-sm white ml4 d-flex text-white">
                            <i class="fa fa-refresh"></i>
                        </button>
                        <button
                        *ngIf="isCheckingShipcloudTrackingStatus"
                        [disabled]="true"
                        class="btn btn-sm white ml4 d-flex text-white">
                            <i class="fa fa-refresh fa-spin"></i>
                        </button>
                    </div>
                    <a href="{{deliveryNote.shipcloud_tracking_url}}" class="btn btn-default btn-block btn-secondary bg-white text-black d-flex gap-1 align-items-center justify-content-center col-12" style="margin-top: 41px"  target="_blank">Tracking</a>
                </li>
            </ng-template>
            <ng-template #conditionS3ElseIf>
                <li *ngIf="deliveryNote.shipping_by == 'OTHER'; else conditionS4Else" class="note note-success">
                    <h4>Sendung</h4>
                    <div class="text-center text-white white mb12" style="margin-top: 18px">
                        No: {{deliveryNote.shipping_other_carrier_tracking_no}}
                    </div>
                </li>
            </ng-template>
            <ng-template #conditionS4Else>
                <li class="note note-success">
                    <h4>Sendung</h4>
                    <div class="text-center text-white mb12" style="margin-top: 18px">-</div>
                </li>
            </ng-template>
            <!-- Block: Sendung END Here -->
            <!-- Block: Empfang START Here -->
            <li *ngIf="deliveryNote.status != 'RECEIPT'; else conditionE1Else" class="note note-warning">
                <h4>Empfang</h4>
                <div class="text-center btn-white mb12" style="margin-top: 18px">-</div>
                <a [routerLink]="['/', 'lieferscheine', deliveryNote.id, 'quittieren']" class="btn btn-secondary bg-white text-black btn-block" style="margin-top: 31px;width: 100%" >Quittieren</a>
            </li>
            <ng-template #conditionE1Else>
                <li class="note note-success">
                    <h4>Empfang</h4>
                    <div class="text-center text-white mb12" style="margin-top: 18px">
                        quittiert am <br>{{deliveryNote.receipt_date| date: 'dd.MM.yyyy'}}
                    </div>
                </li>
            </ng-template>
            <!-- Block: Empfang END Here -->
        </ul>
        <header class="columns">
            <section>
                <h2>Absender</h2>
                <table class="ticketPageTable base mt-3">
                    <tr>
                        <th class=" tableLable">Firma</th>
                        <td>{{deliveryNote.sender_address_company || '-'}}</td>
                    </tr>
                    <tr>
                        <th class=" tableLable">Name</th>
                        <td>{{deliveryNote.sender_address_firstname}} {{deliveryNote.sender_address_lastname}}</td>
                    </tr>
                    <tr>
                        <th class=" tableLable">Straße</th>
                        <td>{{deliveryNote.sender_address_street || '-'}} {{deliveryNote.sender_address_street_no}}</td>
                    </tr>
                    <tr>
                        <th class=" tableLable">PLZ und Ort</th>
                        <td>{{deliveryNote.sender_address_zip}} {{deliveryNote.sender_address_city}}</td>
                    </tr>
                    <tr>
                        <th class=" tableLable">Land (Staat)</th>
                        <td>{{deliveryNote.sender_address_country}} <span *ngIf="deliveryNote.sender_address_state">({{deliveryNote.sender_address_state}})</span></td>
                    </tr>
                </table>
            </section>
            <section>
                <h2>Empfänger</h2>
                <table class="ticketPageTable base mt-3">
                    <tr>
                        <th class=" tableLable">Firma</th>
                        <td>{{deliveryNote.recipient_address_company || '-'}}</td>
                    </tr>
                    <tr>
                        <th class=" tableLable">Name</th>
                        <td>{{deliveryNote.recipient_address_firstname}} {{deliveryNote.recipient_address_lastname}}</td>
                    </tr>
                    <tr>
                        <th class=" tableLable">Straße</th>
                        <td>{{deliveryNote.recipient_address_street || '-'}} {{deliveryNote.recipient_address_street_no}}</td>
                    </tr>
                    <tr>
                        <th class=" tableLable">PLZ und Ort</th>
                        <td>{{deliveryNote.recipient_address_zip}} {{deliveryNote.recipient_address_city}}</td>
                    </tr>
                    <tr>
                        <th class=" tableLable">Land (Staat)</th>
                        <td>{{deliveryNote.recipient_address_country}} <span *ngIf="deliveryNote.recipient_address_state">({{deliveryNote.recipient_address_state}})</span></td>
                    </tr>
                </table>
            </section>
            <section>
                <h2>Lieferung</h2>
                <table class="ticketPageTable base mt-3">
                    <tr>
                        <th class=" tableLable">Nummer</th>
                        <td>{{deliveryNote.number}}</td>
                    </tr>
                    <tr>
                        <th class=" tableLable">Erstellt</th>
                        <td>{{deliveryNote.note_date| date: 'dd.MM.yyyy'}}</td>
                    </tr>
                    <tr>
                        <th class=" tableLable">Auftrag (altes Feld)</th>
                        <td>{{deliveryNote.order_number || '-'}}</td>
                    </tr>
                    <tr>
                        <th class=" tableLable">Tickets</th>
                        <td>
                            <div *ngFor="let ticket of (deliveryNote.tickets_data || [])">
                                <a [routerLink]="['/', 'ticket', ticket.id]" class="g-link d-flex align-items-center gap-2" target="_blank"><i class="fa fa-ticket mr6"></i>{{ticket.external_order_nr || ticket.order_nr}}</a>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <th class=" tableLable">Info</th>
                        <td>{{deliveryNote.info}}</td>
                    </tr>
                </table>
            </section>
            <section>
                <h2>Paket</h2>
                <table class="ticketPageTable base mt-3">
                    <tr>
                        <th class=" tableLable">Status</th>
                        <td>
                            <span *ngIf="deliveryNote.status == 'PREPARED'" class='label label-own label-default'>Vorbereitet</span>
                            <span *ngIf="deliveryNote.status == 'SHIPPED'" class='label label-own label-warning'>Versandt</span>
                            <span *ngIf="deliveryNote.status == 'DELIVERED'" class='label label-own label-success'>Zugestellt</span>
                            <span *ngIf="deliveryNote.status == 'RECEIPT'" class='label label-own label-success'>Quittiert</span>
                        </td>
                    </tr>
                    <tr>
                        <th class=" tableLable">Paketmaße</th>
                        <td>
                            <span *ngIf="deliveryNote.parcel_height || deliveryNote.parcel_width || deliveryNote.parcel_length">
                                {{deliveryNote.parcel_height}} cm / {{deliveryNote.parcel_width}} cm / {{deliveryNote.parcel_length}} cm
                                <span class="pull-right text-muted">
                                    (H/B/L)
                                </span>
                            </span>
                        </td>
                    </tr>
                    <tr>
                        <th class=" tableLable">Gewicht</th>
                        <td>
                            <span *ngIf="deliveryNote.parcel_weight">
                                {{deliveryNote.parcel_weight}} kg
                            </span>
                        </td>
                    </tr>
                    <tr>
                        <th class=" tableLable">E-Mail</th>
                        <td>
                            {{deliveryNote.notification_email || '-'}}
                        </td>
                    </tr>
                </table>
            </section>
        </header>
        <header class="columns">
            <section *ngIf="deliveryNote.shipping_by == 'SHIPCLOUD'">
                <h2>Shipcloud</h2>
                <table class="ticketPageTable base mt-3">
                    <tr>
                        <th class=" tableLable">Carrier</th>
                        <td>
                            <span class="f-bold bold">{{deliveryNote.shipcloud_carrier}}</span> {{deliveryNote.shipcloud_service}}
                            <span *ngIf="deliveryNote.shipcloud_test" class='label label-own label-default pull-right'>Nur Test</span>
                        </td>
                    </tr>
                    <tr>
                        <th class=" tableLable">Sendung erstellt</th>
                        <td>
                            {{deliveryNote.shipping_date | date: 'dd.MM.yyyy'}}
                        </td>
                    </tr>
                    <tr>
                        <th class=" tableLable">Carrier-Tracking</th>
                        <td>{{deliveryNote.shipcloud_carrier_tracking_no}}</td>
                    </tr>
                    <tr>
                        <th class=" tableLable">Tracking-URL</th>
                        <td><a href="{{deliveryNote.shipcloud_tracking_url}}" target="_blank">Tracking</a></td>
                    </tr>
                    <tr>
                        <td class="text-right text-muted">Preis:</td>
                        <td>
                            <span>{{deliveryNote.shipcloud_price| currency:'€'}}</span>
                        </td>
                    </tr>
                    <tr *ngIf="deliveryNote.shipcloud_retoure_shipcloud_id">
                        <td class="text-right text-muted">Retoure-Label:</td>
                        <td>
                            Wurde erstellt
                        </td>
                    </tr>
                    <tr *ngIf="!deliveryNote.shipcloud_retoure_shipcloud_id">
                        <td class="text-right text-muted">Retoure-Label:</td>
                        <td>
                            <a [routerLink]="['/', 'lieferscheine', deliveryNote.id, 'shipping-retoure']">
                                Retoure-Label erstellen
                            </a>
                        </td>
                    </tr>
                </table>
            </section>
            <section *ngIf="deliveryNote.shipping_by == 'SHIPCLOUD'">
                <h2>Abholung</h2>
                <table class="ticketPageTable base mt-3">
                    <tbody *ngIf="deliveryNote.shipcloud_pickup_id">
                        <tr>
                            <td class="text-right text-muted">Zeit:</td>
                            <td>
                                <span *ngIf="deliveryNote.shipcloud_test" class="label label-default label-own pull-right">Nur Test</span>
                                {{deliveryNote.shipcloud_pickup_id && deliveryNote.shipcloud_pickup_data.earliest | date: 'dd.MM.yyyy HH:mm'}} - <br>
                                {{deliveryNote.shipcloud_pickup_id && deliveryNote.shipcloud_pickup_data.latest | date: 'dd.MM.yyyy HH:mm'}}
                            </td>
                        </tr>
                        <tr>
                            <td class="text-right text-muted">Adresse:</td>
                            <td>
                                <div class="d-flex gap-1">
                                    <span *ngIf="deliveryNote.shipcloud_pickup_data.pickup_address_company">
                                        {{deliveryNote.shipcloud_pickup_data.pickup_address_company}},
                                    </span>
                                    <span *ngIf="deliveryNote.shipcloud_pickup_data.pickup_address_firstname || deliveryNote.shipcloud_pickup_data.pickup_address_lastname">
                                        {{deliveryNote.shipcloud_pickup_data.pickup_address_firstname}} {{deliveryNote.shipcloud_pickup_data.pickup_address_lastname}}
                                    </span>
                                </div>
                                <div class="d-flex gap-1">
                                    <span *ngIf="deliveryNote.shipcloud_pickup_data.pickup_address_street || deliveryNote.shipcloud_pickup_data.pickup_address_street_no">
                                        {{[deliveryNote.shipcloud_pickup_data.pickup_address_street, deliveryNote.shipcloud_pickup_data.pickup_address_street_no].join(' ')}},
                                    </span>
                                    <span *ngIf="deliveryNote.shipcloud_pickup_data.pickup_address_city || deliveryNote.shipcloud_pickup_data.pickup_address_zip">
                                        {{[deliveryNote.shipcloud_pickup_data.pickup_address_city,deliveryNote.shipcloud_pickup_data.pickup_address_zip].join(' ')}},
                                    </span>
                                    <span *ngIf="deliveryNote.shipcloud_pickup_data.pickup_address_country">
                                        {{deliveryNote.shipcloud_pickup_data.pickup_address_country}}
                                    </span>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td class="text-right text-muted">Telefon:</td>
                        <td>
                            {{deliveryNote.shipcloud_pickup_data.phone || '-'}}
                        </td>
                        </tr>
                    </tbody>
                    <tbody *ngIf="!deliveryNote.shipcloud_pickup_id">
                        <tr>
                            <td class="text-right text-muted">Abholung:</td>
                            <td *ngIf="['UPS', 'DPD'].includes(deliveryNote.shipcloud_carrier)">
                                <div>-</div>
                                <div class="mt8 mb4 d-flex">
                                    <a class="btn btn-default btn-block bg-white text-black btn-secondary col d-flex align-items-center gap-2 justify-content-center" [routerLink]="['/', 'lieferscheine', deliveryNote.id, 'pickup']">
                                        <i class="fa fa-clock-o mr6"></i>Abholzeit festlegen
                                    </a>
                                </div>
                            </td>
                            <td *ngIf="!['UPS', 'DPD'].includes(deliveryNote.shipcloud_carrier)">
                                Abholung nur mit UPS oder DPD möglich.
                            </td>
                        </tr>
                    </tbody>
                </table>
            </section>
            <section *ngIf="deliveryNote.shipping_by == 'OTHER'">
                <h2>Anderer Versand</h2>
                <table class="ticketPageTable base mt-3">
                    <tr>
                        <td class="text-right text-muted" style="width: 35%">Carrier:</td>
                        <td><span class="bold">{{deliveryNote.shipping_other_carrier}}</span> {{deliveryNote.shipping_other_service}}</td>
                    </tr>
                    <tr>
                        <td class="text-right text-muted">Sendung erstellt:</td>
                        <td>
                            {{deliveryNote.shipping_date | date: 'dd.MM.yyyy'}}
                        </td>
                    </tr>
                    <tr>
                        <td class="text-right text-muted">Carrier-Tracking:</td>
                        <td>{{deliveryNote.shipping_other_carrier_tracking_no}}</td>
                    </tr>
                    <tr>
                        <td class="text-right text-muted">info:</td>
                        <td>{{deliveryNote.shipping_other_info}}</td>
                    </tr>
                    <tr>
                        <td class="text-right text-muted">Preis:</td>
                        <td>
                            <span *ngIf="deliveryNote.shipping_other_price && deliveryNote.shipping_other_price >= 0">{{deliveryNote.shipping_other_price| currency:'€'}}</span>
                            <span *ngIf="!deliveryNote.shipping_other_price || deliveryNote.shipping_other_price < 0">-</span>
                        </td>
                    </tr>
                </table>
            </section>
        </header>
        <section>
            <h2>Artikelliste</h2>
            <ag-grid-angular class="ag-theme-quartz" style="height: 300px;width: 100%;"
            [theme]="hawkTheme" [loadThemeGoogleFonts]="true"
            [defaultColDef]="defaultColDef"
            [columnDefs]="colDefs"
            [rowClassRules]="rowClassRules"
            [rowSelection]="rowSelection"
            [localeText]="locale"
            [rowData]="articles"
            [alwaysShowVerticalScroll]="true"
            >
            </ag-grid-angular>
        </section>
        <div class="d-flex col-auto ms-2 justify-content-between">
            <div class="d-flex gap-2 align-items-center">
                <a
                    *ngIf="deliveryNote.status !== 'RECEIPT'"
                    [routerLink]="['/', 'lieferscheine', deliveryNote.id, 'edit']"
                    class="btn btn-primary d-flex align-items-center gap-1"
                >
                    <i class="fa fa-pencil"></i>
                    Bearbeiten
                </a>
                <a
                    *ngIf="deliveryNote.status === 'RECEIPT'"
                    class="btn btn-primary d-flex align-items-center gap-1 disabled"
                >
                    <i class="fa fa-pencil"></i>
                    Bearbeiten
                </a>
                <a class="g-link" [routerLink]="['/', 'lieferscheine', 'suche', 'index']">Zurück</a>
            </div>
            <button [disabled]="deliveryNote.status != 'PREPARED'" (click)="onDelete()"  class="btn d-flex align-items-center gap-1 g-link pull-right">
                <i class="fas fa-trash-alt"></i>Lieferschein löschen
            </button>
        </div>
</section>
</div>