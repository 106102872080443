<div class="d-flex align-items-center" *ngIf="article">
    <div class="col t-header d-flex align-items-center justify-content-start">
        <h4 class="mb20 d-flex align-items-center gap-1">
            <i class="fa fa-cube"></i>
            <ul class="list-group list-group-horizontal b-custom">
                <li class="list-group-item bg-transparent border-0">
                    <a
                    class="g-link"
                    [routerLink]="['/', 'artikel', 'suche', 'index']"
                    >
                        Artikel
                    </a>
                </li>
                <li class="list-group-item bg-transparent border-0">
                    <span>{{article.rma}}</span>
                </li>
            </ul>
        </h4>
    </div>
</div>
<div class="ticketPage" *ngIf="article">
    <div class="main-content w-100">
        <div class="row">
            <div class="col-6 d-flex gap-2 flex-column">
                <section>
                    <h2>Stammdaten</h2>
                    <table class="ticketPageTable base mt-3">
                        <tr>
                            <th class="tableLable">RMA</th>
                            <td>
                                <span class="fw-bold">{{article.rma}}</span>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="2">
                                <div class="d-flex align-items-center justify-content-start mt-2">
                                    <a target="_blank"
                                        [href]="env.apiURL + 'articles/' + article.id + '.pdf?' + 'rma=' + article.rma + '&sn=' + article.serial_number + '&template=' + article.description + '&partNr=' + article.part_nr + '&purchaseDate=' + (article.purchase_date | date: 'dd.MM.yyyy') + '&deliveryDate=' + (article.delivery_date | date: 'dd.MM.yyyy') + '&storageBin=' + article.storage_bin_label"
                                        class="btn btn-default text-center custom-button gap-1">
                                        <i class="fa fa-print mr-2"></i> Etikett drucken
                                    </a>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <th class="tableLable">Typ</th>
                            <td>
                                <div class="d-flex flex-column gap-1">
                                    <a class="g-link d-flex gap-2" [routerLink]="['/', 'artikelvorlagen', article.template_id]">
                                        <i class="fa fa-cubes mr6"></i>{{article.description}}
                                    </a>
                                    {{article.product_category}}
                                    {{article.vendor}}
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <th class="tableLable">Status</th>
                            <td>
                                <span *ngIf="article.status == 'NEW'">Neu</span>
                                <span *ngIf="article.status == 'USED_TESTED'">Gebraucht und getestet</span>
                                <span *ngIf="article.status == 'USED_UNTESTED'">Gebraucht und NICHT getestet</span>
                            </td>
                        </tr>
                        <tr>
                            <th class="tableLable">Seriennummer</th>
                            <td>
                                {{article.serial_number}}
                            </td>
                        </tr>
                        <tr>
                            <th class="tableLable">BadPart</th>
                            <td>
                                {{ article.bad_part ? 'Ja' : '-' }}
                            </td>
                        </tr>
                        <tr>
                            <th class="tableLable">BadPart-Beschreibung:</th>
                            <td>
                                {{ article.bad_part_description || '-' }}
                            </td>
                        </tr>
                        <tr>
                            <th class="tableLable">Besitzer</th>
                            <td>
                                <span *ngIf="article.owner == 'BENTOMAX'">Bentomax GmbH</span>
                                <span *ngIf="article.owner == 'CUSTOMER' && article.owner_customer_id">
                                    <a target="_blank" href="/admin/customer/{{article.owner_customer_id}}/show">{{article.owner_name}}</a>
                                </span>
                            </td>
                        </tr>
                    </table>
                </section>
                <section>
                    <h2>Standort</h2>
                    <table class="ticketPageTable base mt-3">
                        <tr>
                            <th class="tableLable">Status</th>
                            <td>
                                <span *ngIf="article.location == 'WAREHOUSE'">Im Lager</span>
                                <span *ngIf="article.location == 'SHIPPING'">Im Versand</span>
                                <span *ngIf="article.location == 'CONSUMED'">Verbraucht</span>
                            </td>
                        </tr>
                        <tr>
                            <th class="tableLable">Lager</th>
                            <td>
                                <a *ngIf="article.warehouse_id" [routerLink]="['/', 'lager', article.warehouse_id]">
                                    {{article.warehouse_name}}
                                </a>
                            </td>
                        </tr>
                        <tr>
                            <th class="tableLable">Platz</th>
                            <td>
                                {{article.storage_bin_label}}
                            </td>
                        </tr>
                    </table>
                </section>
            </div>
            <div class="col-6 d-flex gap-2 flex-column">
                <section>
                    <h2>Anlieferung</h2>
                    <table class="ticketPageTable base mt-3">
                        <tr>
                            <th class="tableLable">Lieferant:</th>
                            <td>
                                <span *ngIf="article.supplier_id">
                                    <a target="_blank" href="/admin/supplier/{{article.supplier_id}}/show">{{article.supplier_name}}</a>
                                </span>
                                <span *ngIf="!article.supplier_id">
                                    {{article.supplier_other}}
                                </span>
                            </td>
                        </tr>
                        <tr>
                            <th class="tableLable">Kaufdatum:</th>
                            <td>{{article.ext_delivery_note_number}}</td>
                        </tr>
                        <tr>
                            <th class="tableLable">Kaufdatum:</th>
                            <td>
                                {{ article.purchase_date | date: 'dd.MM.yyyy' }}

                            </td>

                        </tr>
                        <tr>
                            <th class="tableLable">Lieferdatum:</th>
                            <td>
                                {{ article.delivery_date | date: 'dd.MM.yyyy' }}
                            </td>
                        </tr>
                    </table>
                </section>
                <section>
                    <h2>Lieferscheine</h2>
                    <table style="width: 100%" *ngIf="deliveryNotes">
                        <tr *ngFor="let deliveryNote of deliveryNotes">
                            <td>
                                <a class="g-link d-flex align-items-center gap-1" [routerLink]="['/', 'lieferscheine', deliveryNote.id]">
                                    <span class="fa fa-truck"></span>{{deliveryNote.number}}
                                </a>
                            </td>
                            <td [align]="'right'">{{deliveryNote.note_date| date: 'dd.MM.yyyy'}}</td>
                        </tr>
                    </table>
                </section>
                <section>
                    <h2>Tickets</h2>
                    <table *ngIf="tickets">
                        <tr *ngFor="let ticket of tickets">
                            <td>
                                {{ticket.project_name}}
                            </td>
                            <td>{{ticket.first_working_day| date: 'dd.MM.yyyy'}}</td>
                        </tr>
                    </table>
                </section>
                <section>
                    <h2>Letzten Änderungen</h2>
                    <table *ngIf="histories" class="col-12">
                        <tr>
                            <th>Event</th>
                            <th>Date</th>
                            <th>User</th>
                            <th>Field: Changes</th>
                        </tr>
                        <tr *ngFor="let history of histories">
                            <td>{{history.event}}</td>
                            <td>
                                {{history.created_at | date: 'MMM dd, yyyy'}}
                            </td>
                            <td>{{history.user}}</td>
                            <td>
                                <div class="d-flex align-items-center gap-2" *ngFor="let change of history.changes">
                                    <span>{{change[0]}}</span>
                                    <span>{{(change[1] || []).join(', ')}}</span>
                                </div>
                            </td>
                        </tr>
                    </table>
                </section>
            </div>
        </div>
        <div class="d-flex justify-content-between align-items-center mt-2 my-2">
            <div class="d-flex gap-2">
                <a [routerLink]="['/', 'artikel', 'neu', article.id]"
                    class="btn btn-primary d-flex align-items-center gap-1">
                    <i class="fa fa-arrow-right"></i> Vorlage für Wareneingang
                </a>
                <a [routerLink]="['/', 'artikel', article.id, 'edit']"
                    class="btn btn-primary d-flex align-items-center gap-1">
                    <i class="fa fa-pencil"></i> Bearbeiten
                </a>

                <a class="g-link d-flex align-items-center gap-2"
                    [routerLink]="['/', 'artikel', 'suche', 'index']">
                    Zurück
                </a>
            </div>
            <button (click)="onDelete()" class="btn d-flex align-items-center gap-1 g-link">
                <i class="fas fa-trash-alt"></i> Artikel löschen
            </button>
        </div>
    </div>
</div>