
import { PATicket } from "../classes/ticket";
import { PATechnician } from "../classes/technician";
import { PAOperation } from "../classes/operation";
import { PALocation } from "../classes/location";
import { PAClient } from "../classes/client";
import { PAStore } from "../classes/store";
import {
  CalendarWeekData,
  OperationChanges,
  PlanningAssistantOperation,
  PlanningAssistantTicket
} from "../../../_models/planning-assistant.interface";
import { PAFilterControl } from "./pa-filter-control";
import { PACoordinates } from "../classes/coordinates";
import { PAProject } from "../classes/project";
import { ArticleTemplate, Priority } from "../../../_models";
import { ArticleService, StoreService } from "../../../_services";
import { PAProgress } from "../classes/progress";
import { TimePredictionRule } from "../classes/time-prediction-rule";
import { PASettingsControl } from "./pa-settings-control";
import { UserInfo } from "../../../_models/user-info.interface";
import { PlanningAssistantService } from "../../../_services/planning-assistant.service";
import { PATimeControl } from "./pa-time-control";
import { PATechnicianDate } from "../classes/technician-date";
import { Opening } from "../../../_models/opening.interface";
import { OpeningTimeTemplate } from "../map/map.component";
import { UserAbsence } from "../../../_models/technician.interface";

export class PADataControl {

  private static _instance: PADataControl
  public static get Instance()
  {
    return this._instance || (this._instance = new this());
  }

  public ticketMap = new Map<number, PATicket>()
  public technicianMap = new Map<number, PATechnician>()
  public operationMap = new Map<number, PAOperation>()
  public locationMap = new Map<number, PALocation>()
  public clientMap = new Map<number, PAClient>()
  public storeMap = new Map<number, PAStore>()
  public usedOperatorDayIds = []
  public usedLocationIds = []
  public usedClientIds = []
  public usedStoreIds = []
  public usedTemporaryTicketIds = []
  public lastUsedLocationID = -1
  public lastUsedStoreID = -1
  public lastUsedOperatorDayID = -1
  public lastUsedClientID = -1
  public lastUsedTemporaryTicketID = 0

  public allTechnicians: PATechnician[] = []
  public loadedTechnicians: PATechnician[] = []
  public loadedOperations: PAOperation[] = []
  public loadedProjects: PAProject[] = []
  public operationChanges: OperationChanges[] = []

  public unassignedOperationUserIds: number[] = [283]

  public operationsWithoutLngLat: PAOperation[] = []
  public techniciansWithoutLngLat: PATechnician[] = []

  public articleTemplates: ArticleTemplate[] = []

  public absences: UserAbsence[] = []
  public loadingCoordinates = { lat: 50.5, lng: 10.4476 }

  private _articleService: ArticleService
  private _storeService: StoreService
  private _planningAssistantService: PlanningAssistantService

  private constructor(
  ) {
  }

  set articleService(as: ArticleService) {
    this._articleService = as
  }

  set planningAssistantService(pas: PlanningAssistantService) {
    this._planningAssistantService = pas
  }

  set storeService(sts: StoreService) {
    this._storeService = sts
  }

  addOperationsToLoadedOperations(operations: PAOperation[]) {
    let not_added_operations = operations.filter(op => !this.loadedOperations.find(lop => lop.id == op.id))
    if (not_added_operations.length) {
      PADataControl.Instance.loadedOperations = PADataControl.Instance.loadedOperations.concat(not_added_operations)
      PAFilterControl.Instance.updateUnassignedOperations()
    }
  }

  public getOperation(id: number, get_latest_change_id='manual'): PAOperation {
    if (this.operationMap.has(id)) {
      let job = this.operationMap.get(id)
      if (get_latest_change_id) {
        return job.getLatestOperationChange(get_latest_change_id)
      } else {
        return job
      }
    } else {
      console.log("error: Operation with id " + id + " not found")
    }
  }

  public getTechnician(id: number): PATechnician {
    if (this.technicianMap.has(id)) {
      return this.technicianMap.get(id)
    } else {
      console.log('Can not find Operator with id ' + id.toString())
      return null
    }
  }

  public getStore(id: number): PAStore {
    if (this.storeMap.has(id)) {
      return this.storeMap.get(id)
    } else {
      console.log('Can not find Store with id ' + id.toString())
      return null
    }
  }

  public getTicket(id: number): PATicket {
    if (this.ticketMap.has(id)) {
      return this.ticketMap.get(id)
    } else {
      console.log('Can not find Ticket with id ' + id.toString())
      return null
    }
  }

  public getLocationByCoordinates(coordinates: PACoordinates): PALocation {
    for (let location of this.locationMap.values()) {
      if (location.coordinates.latitude == coordinates.latitude && location.coordinates.longitude == coordinates.longitude) {
        return location
      }
    }
  }

  public generateNextOperatorDayId(): number {
    this.lastUsedOperatorDayID += 1
    while (this.usedOperatorDayIds.indexOf(this.lastUsedOperatorDayID) >= 0) {
      this.lastUsedOperatorDayID += 1
    }
    this.usedOperatorDayIds.push(this.lastUsedOperatorDayID)
    return this.lastUsedOperatorDayID
  }

  public generateNextLocationId(): number {
    this.lastUsedLocationID += 1
    while (this.usedLocationIds.indexOf(this.lastUsedLocationID) >= 0) {
      this.lastUsedLocationID += 1
    }
    this.usedLocationIds.push(this.lastUsedLocationID)
    return this.lastUsedLocationID
  }

  public generateNextStoreId(): number {
    this.lastUsedStoreID += 1
    while (this.usedStoreIds.indexOf(this.lastUsedStoreID) >= 0) {
      this.lastUsedStoreID += 1
    }
    this.usedStoreIds.push(this.lastUsedStoreID)
    return this.lastUsedStoreID
  }

  public generateNextClientId(): number {
    this.lastUsedClientID += 1
    while (this.usedClientIds.indexOf(this.lastUsedClientID) >= 0) {
      this.lastUsedClientID += 1
    }
    this.usedClientIds.push(this.lastUsedClientID)
    return this.lastUsedClientID
  }

  public generateNextTemporaryTicketId(): number {
    this.lastUsedTemporaryTicketID -= 1
    while (this.usedTemporaryTicketIds.indexOf(this.lastUsedTemporaryTicketID) >= 0) {
      this.lastUsedTemporaryTicketID -= 1
    }
    this.usedTemporaryTicketIds.push(this.lastUsedTemporaryTicketID)
    return this.lastUsedTemporaryTicketID
  }

  public getLoadedOperations(): PAOperation[] {
    return [...this.operationMap].map(operation_kv => operation_kv[1])
  }

  public getUnassignedOperations(change_id?: string): PAOperation[] {
    let operations = this.getChangedLoadedOperations(change_id || 'manual')
    return operations.filter(operation => operation.isUnassigned())
  }

  public getChangedLoadedOperations(change_id?: string): PAOperation[] {
    return this.getLoadedOperations().map(operation => operation.getLatestOperationChange(change_id || 'manual'))
  }

  processOperationHashes(hashes: PlanningAssistantOperation[]): void {
    const operations = hashes.map(hash => this.operationHashToOperation(hash))
    operations.map(op => PAStore.insertOperation(op))
    PADataControl.Instance.addOperationsToLoadedOperations(operations)
  }

  operationHashToOperation(operation_hash: PlanningAssistantOperation, get_loaded_operation_if_exists?: boolean): PAOperation {
    if (get_loaded_operation_if_exists) {
      let possible_loaded_operation = this.getChangedLoadedOperations().filter(operation => operation.id == operation_hash.id)
      if (possible_loaded_operation.length > 0) {
        return possible_loaded_operation[0]
      }
    }
    let coordinates = new PACoordinates(operation_hash.ticket.address_latitude, operation_hash.ticket.address_longitude)
    let location = this.getLocationByCoordinates(coordinates)
    let location_id = location ? location.location_id : this.generateNextLocationId()
    let client_id = this.generateNextClientId()

    return new PAOperation(
      operation_hash.id,
      client_id,
      operation_hash.operation_date,
      this.ticketHashToTicket(operation_hash.ticket, location_id, client_id),
      operation_hash.user_ids,
      operation_hash.date_on_site,
      operation_hash.date_travel_start,
      operation_hash.date_repaired,
      operation_hash.date_finished,
      operation_hash.description,
    )
  }

  ticketHashToTicket(hash: PlanningAssistantTicket, location_id: number, client_id: number): PATicket {
    let project = this.getProject(hash.project_id)
    return new PATicket(
      hash.id,
      location_id,
      client_id,
      hash.datesla,
      hash.appointment_date,
      project,
      new PACoordinates(hash.address_latitude, hash.address_longitude, 'Ticket - ' + hash.id.toString()),
      hash.time_estimation,
      hash.address_company || '',
      hash.address_street,
      hash.address_street_no,
      hash.address_zip,
      hash.address_city,
      hash.address_country,
      hash.materials,
      PAOperation.openingsToOpeningTimes(hash.openings),
      hash.external_order_nr,
      hash.contact_name,
      hash.contact_email,
      hash.contact_phone,
      this.getPriority(hash.priority_id),
      project.getStatus(hash.status_id),
      hash.memos,
      hash.description
    )
  }

  async updateOperations(operations: PAOperation[]): Promise<void> {

    async function update_technician_date_for_new_operation(new_operation: PAOperation) {
      const new_technician_date = new_operation.getTechnicianDate()
      if (new_technician_date.loadingStatus == 'init') {
        await new_technician_date.loadData()
      } else {
        PAStore.insertOperation(new_operation)
        const operation_store = PAStore.getOperationsStore(new_operation)
        if (operation_store) {
          operation_store.fireUpdateManually()
        }
        await new_technician_date.tour.insertOperations([new_operation])
      }
    }

    let not_added_operations: PAOperation[] = []

    for (let new_operation of operations) {
      let old_operation = this.getOperation(new_operation.id, null)
      if (old_operation) {
        if (!this.equalOperations(new_operation, old_operation)) {
          const possible_operation_change = this.operationChanges.filter(change => change.operation_id == new_operation.id)
          if (possible_operation_change.length) {
            possible_operation_change[0].base_operation = new_operation
          }
          this.operationMap.set(new_operation.id, new_operation)
          const old_technician_date = old_operation.getTechnicianDate()
          await old_technician_date.tour.removeOperationWithId(old_operation.id)
          await update_technician_date_for_new_operation(new_operation);
        }
      } else {
        await update_technician_date_for_new_operation(new_operation)
        PAStore.insertOperation(new_operation)
        const operation_store = PAStore.getOperationsStore(new_operation)
        if (operation_store) {
          operation_store.fireUpdateManually()
        }
        not_added_operations.push(new_operation)
      }
    }

    this.addOperationsToLoadedOperations(not_added_operations)
  }

  equalOperations(operation_1: PAOperation, operation_2: PAOperation) {
    let compare_attributes_1 = {
      operation_id: operation_1.id,
      operation_date: operation_1.operation_date,
      appointment_date: operation_1.ticket.appointment_date,
      time_estimation: operation_1.ticket.time_estimation,
      user_ids: operation_1.user_ids,
      date_on_site: operation_1.date_on_site,
      date_travel_start: operation_1.date_travel_start,
      date_repaired: operation_1.date_repaired,
      date_finished: operation_1.date_finished,
    }
    let compare_attributes_2 = {
      operation_id: operation_2.id,
      operation_date: operation_2.operation_date,
      appointment_date: operation_2.ticket.appointment_date,
      time_estimation: operation_2.ticket.time_estimation,
      user_ids: operation_2.user_ids,
      date_on_site: operation_2.date_on_site,
      date_travel_start: operation_2.date_travel_start,
      date_repaired: operation_2.date_repaired,
      date_finished: operation_2.date_finished,
    }
    return JSON.stringify(compare_attributes_1) === JSON.stringify(compare_attributes_2);
  }

  loadArticleTemplates(): void {
    this._articleService.loadArticleTemplates().subscribe(
      (data) => {
        this.articleTemplates = data
      },
      (err) => {
        console.error(err)
      },
    )
  }

  hasLoadedProjectWithId(id: number): boolean {
    const possible_project = this.loadedProjects.filter(project => project.id == id)
    return possible_project.length > 0
  }

  getProject(id: number): PAProject {
    const possible_project = this.loadedProjects.filter(project => project.id == id)
    if (possible_project.length) {
      return possible_project[0]
    }
  }

  async loadActiveProjects(): Promise<void> {
    return new Promise((resolve) => {
      let progress = new PAProgress('Lade Projekte', 1)
      progress.addSubProgress(PAProject.projectService.getPAActiveProjects(), '').subscribe(
        async (data) => {
          let projects = []
          for (let project of data) {
            let sorted_status = project.status.sort((status_a, status_b) => (
              status_a.name <
              status_b.name
            ) ? -1 : 1)
            projects.push(
              new PAProject(
                project.id,
                project.project_name,
                project.customer_id,
                project.active_technician_ids,
                project.experienced_technician_ids,
                project.color,
                project.priorities,
                sorted_status
              )
            )
          }
          this.loadedProjects = projects
          for (let project of projects) {
            if (!project.color) {
              project.color = '#007FD3'
            }
          }
          resolve()
        },
        (err) => {
          console.error(err)
          resolve()
        },
      )
    })
  }

  async loadActivePriorityRules(): Promise<void> {
    return new Promise((resolve) => {
      let progress = new PAProgress('Lade Rampup Regeln', 1)
      progress.addSubProgress(this._planningAssistantService.getPriorityRampUpRules(), '').subscribe(
        async (data) => {
          for (let rule_hash of data) {
            let priority = this.getPriority(rule_hash.priority_id)
            if (priority) {
              let technician: 'default' | PATechnician = rule_hash.user_id ? this.getTechnician(rule_hash.user_id) : 'default'
              if (rule_hash.function_type == 'absolute' || rule_hash.function_type == 'linear') {
                let time_prediction_rule = new TimePredictionRule(priority, rule_hash.step_from, rule_hash.step_until, {
                  technician: technician,
                  function_type: rule_hash.function_type,
                  id: rule_hash.id,
                  additional_time_percent: rule_hash.additional_time_percent
                })
                PASettingsControl.Instance.addAdditionalTimePriorityRule(time_prediction_rule)
              }
            }
          }
          resolve()
        },
        (err) => {
          console.error(err)
          resolve()
        },
      )
    })
  }

  getPriority(priority_id: number): Priority {
    for (let project of this.loadedProjects) {
      const possible_priority = project.priorities.filter(priority => priority.id == priority_id)
      if (possible_priority.length > 0) {
        return possible_priority[0]
      }
    }
    console.log("No priority with id: " + priority_id + " found")
  }

  loadedProjectFilteredByName(name: string): PAProject[] {
    return this.loadedProjects.filter(project => project.project_name.toLowerCase().includes(name.toLowerCase()))
  }

  getCustomerProjects(customer_id: number): PAProject[] {
    return this.loadedProjects.filter(project => project.customer_id == customer_id)
  }

  async loadAllActiveTechnicians(): Promise<void> {
    return new Promise((resolve, reject) => {
      let progress = new PAProgress('Lade Techniker', 1)
      progress.addSubProgress(this._planningAssistantService.getAllTechnicians(), '').subscribe(
        async (data: UserInfo[]) => {
          let technicians: PATechnician[] = []
          for (let user_info of data.filter(u => !u.real_user_id)) {
            technicians.push(
              new PATechnician(
                user_info.id,
                this.generateNextLocationId(),
                user_info.firstname,
                user_info.lastname,
                new PACoordinates(user_info.address_latitude, user_info.address_longitude, user_info.firstname + ' ' + user_info.lastname + ' home'),
                user_info.address_company,
                user_info.address_zip,
                user_info.company_address_company,
                [user_info.telephone1, user_info.telephone2].filter(phone => phone),
                user_info.roles,
                [],
                user_info.driving_time_factor
              )
            )
          }
          technicians.map(technician => technician.fireUpdate())
          this.loadedTechnicians = this.loadedTechnicians.concat(technicians).sort((technician_a: PATechnician, technician_b: PATechnician) => technician_a.lastname > technician_b.lastname ? -1 : 1)

          let placeholder_technicians = data.filter(u => u.real_user_id)
          for (let placeholder of placeholder_technicians) {
            let real_technician = technicians.find((technician) => technician.id == placeholder.real_user_id)
            if (real_technician) {
              real_technician.placeholderTechnicianIds = real_technician.placeholderTechnicianIds.concat([placeholder.id])
              this.unassignedOperationUserIds = this.unassignedOperationUserIds.concat([placeholder.id])
            }
          }
          resolve()
        },
        (err) => {
          console.error(err)
          reject()
        },
      )
    })
  }

  async loadTechnicianDatesDataForCalendarWeekData(technicians: PATechnician[], calendar_week_data: CalendarWeekData): Promise<void> {
    let request_start_ts = Date.now()
    let update_technicians = technicians.filter(technician => technician.getLastCalendarWeekDataUpdateTimestamp(calendar_week_data.calendar_week) < request_start_ts - PATechnicianDate.skipOperationUpdatesForMilliseconds)
    update_technicians.map(technician => technician.getCalendarWeekDataUpdate(calendar_week_data.calendar_week).last_update = request_start_ts)
    if (update_technicians.length) {
      let progress = new PAProgress('Lade Aufträge für KW ' + calendar_week_data.calendar_week.number.toString(), 1)
      const date_from = PATimeControl.Instance.dateToDatestring(new Date(calendar_week_data.weekdays['monday'].timestamp), true, false)
      const date_until = PATimeControl.Instance.dateToDatestring(new Date(calendar_week_data.weekdays['sunday'].timestamp), true, false)
      return new Promise((resolve) => {
        progress.addSubProgress(this._planningAssistantService.getOperationUserInfo(update_technicians.map(technician => technician.id), date_from + " 00:00:00", date_until + " 23:59:59", {exclude_material_return: true}), '').subscribe(
          async (data) => {
            let operations_to_add = []
            for (let technician_entry of data) {
              let technician = this.getTechnician(technician_entry.user_id)
              let operations = technician_entry.operations.map(operation_hash => this.operationHashToOperation(operation_hash))
              for (let week_day of PATimeControl.Instance.weekDays) {
                let timestamp = calendar_week_data.weekdays[week_day].timestamp
                const technician_date = technician.getTechnicianDate(timestamp, true, false)
                const day_operations = operations.filter(operation => operation.getPlannedDayTimestamp() == timestamp)
                if (technician_date.loadingStatus == 'init') {
                  technician_date.loadingStatus = 'loaded'
                  operations_to_add = operations_to_add.concat(day_operations)
                  await PAOperation.insertOperationsInTechnicianDateAndStore(day_operations)
                } else {
                  await this.updateOperations(day_operations)
                  if (technician_date.loadingStatus == 'loaded') {
                    await technician_date.tour.updateRoute()
                  }
                }
              }
              PADataControl.Instance.addOperationsToLoadedOperations(operations)
            }
            resolve()
          },
          (err) => {
            console.error(err)
            resolve()
          },
        )
      })
    }
  }

  getStoreOpenings(store_id: number): Promise<Opening[]> {
    return new Promise<Opening[]>(
      resolve => {
        this._storeService.getStoreOpenings(store_id).subscribe(
          data => {
            resolve(data)
          },
          err => {
            console.log(err)
            resolve([])
          }
        )
      }
    )
  }

  async getStoresOpeningTimeTemplates(store_id: number): Promise<{
    templates: OpeningTimeTemplate[];
    store_openings: Opening[]
  }> {
    const templates: OpeningTimeTemplate[] = []
    const store_openings = await PADataControl.Instance.getStoreOpenings(store_id)
    let remaining_openings = [...store_openings]
    while(remaining_openings.length) {
      let current_opening = remaining_openings.splice(0, 1)[0]
      let openings_at_the_same_time = remaining_openings.filter(opening => opening.open == current_opening.open && opening.close == current_opening.close)
      remaining_openings = remaining_openings.filter(opening => opening.open != current_opening.open || opening.close != current_opening.close)
      templates.push({
        open: current_opening.open,
        close: current_opening.close,
        day_idxs: [current_opening.day].concat(openings_at_the_same_time.map(opening => opening.day)).sort()
      })
    }
    return { templates, store_openings }
  }

}