import { Injectable } from '@angular/core'
import { HttpClient, HttpHeaders, HttpParams, HttpEventType } from '@angular/common/http'
import * as ActionCable from '@rails/actioncable'

import { environment } from '../../environments/environment'
import { QmJob } from '../_models'

@Injectable({
  providedIn: 'root'
})
export class QmJobService {
  private consumer: any

  constructor(private http: HttpClient) {
    this.consumer = ActionCable.createConsumer(environment.wsURL)
  }

  public connectQMUpdate(method) {
    this.consumer.subscriptions.create('QmUpdateChannel', method)
  }

  public getUndone() {
    const path = environment.apiURL + 'qm_jobs/undone.json'

    return this.http.get<QmJob[]>(path)
  }

  public setDone(qmJobId: number) {
    const path = environment.apiURL + 'qm_jobs/' + qmJobId + '/done.json'

    return this.http.post<QmJob>(path, null)
  }

  public setFollowUp(qmJobId: number) {
    const path = environment.apiURL + 'qm_jobs/' + qmJobId + '/follow_up.json'

    return this.http.post<QmJob>(path, null)
  }

  public setLocked(qmJobId: number) {
    const path = environment.apiURL + 'qm_jobs/' + qmJobId + '/lock.json'

    return this.http.post<QmJob>(path, null)
  }

  public setUnlocked(qmJobId: number) {
    const path = environment.apiURL + 'qm_jobs/' + qmJobId + '/unlock.json'

    return this.http.post<QmJob>(path, null)
  }

  public setDeleted(qmJobId: number) {
    const path = environment.apiURL + 'qm_jobs/' + qmJobId + '/delete.json'

    return this.http.delete<QmJob>(path, null)
  }
}
