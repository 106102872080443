<div>
  <div class="ticketSearch">
    <h2>Neue Abfrage</h2>
    <form (submit)="search()" [formGroup]="deliveryNoteSearchForm" style="width: 95%">
      <table style="width: 100%">
        <tr>
          <td style="width: 33%">
            <mat-form-field appearance="outline">
              <mat-label>Lieferscheinnummer</mat-label>
              <input
                matInput
                type="text"
                placeholder="Auftragsnummer"
                formControlName="number"
              />
            </mat-form-field>
          </td>
          <td style="width: 33%">
            <mat-label>Produktkategorie</mat-label>
            <ng-select
              [items]="productCategories"
              bindLabel="name"
              bindValue="id"
              formControlName="product_category"
              placeholder="Projekt auswählen"
            >
              <ng-template ng-label-tmp let-item="item">
                {{ item }}
              </ng-template>
              <ng-template
                ng-option-tmp
                let-item="item"
                let-search="searchTerm"
              >
                <div
                  *ngIf="
                    item.toLowerCase()
                      .includes(searchTerm.toLowerCase())
                  "
                >
                  {{ item }}
                </div>
              </ng-template>
            </ng-select>
          </td>
        </tr>
        <tr>
          <td *ngIf="statusList">
            <mat-label>Status</mat-label>
            <ng-select
              [items]="statusList"
              bindLabel="description"
              bindValue="id"
              formControlName="status_id"
              placeholder="Status auswählen"
            >
              <ng-template ng-label-tmp let-item="item">
                {{ item.description }}
              </ng-template>
              <ng-template
                ng-option-tmp
                let-item="item"
                let-search="searchTermStatus"
              >
                <div
                  *ngIf="
                    item.description
                      .toLowerCase()
                      .includes(searchTerm.toLowerCase())
                  "
                >
                  {{ item.description }}
                </div>
              </ng-template>
            </ng-select>
          </td>
          <td>
            <mat-label>Produkt</mat-label>
            <ng-select
              [items]="products"
              bindLabel="description"
              bindValue="id"
              formControlName="product_id"
              placeholder="Produkt"
            >
              <ng-template ng-label-tmp let-item="item">
                {{ item.description }}
              </ng-template>
              <ng-template
                ng-option-tmp
                let-item="item"
                let-search="searchTermStatus"
              >
                <div
                  *ngIf="
                    item.description
                      .toLowerCase()
                      .includes(searchTerm.toLowerCase())
                  "
                >
                  {{ item.description }}
                </div>
              </ng-template>
            </ng-select>
          </td>
        </tr>
        <tr>
          <td>
            <mat-form-field appearance="outline">
              <mat-label>Absenderort</mat-label>
              <input
                matInput
                type="text"
                placeholder="Absenderort"
                formControlName="sender_city"
              />
            </mat-form-field>
          </td>
          <td>
            <mat-form-field appearance="outline">
              <mat-label>RMA</mat-label>
              <input
                matInput
                type="text"
                placeholder="RMA"
                formControlName="rma"
              />
            </mat-form-field>
          </td>
        </tr>
        <tr>
          <td>
            <mat-form-field appearance="outline">
              <mat-label>Empfängerort</mat-label>
              <input
                matInput
                type="text"
                placeholder="Empfängerort"
                formControlName="recipient_city"
              />
            </mat-form-field>
          </td>
          <td>
            <mat-form-field appearance="outline">
              <mat-label>SN</mat-label>
              <input
                matInput
                type="text"
                placeholder="SN"
                formControlName="serial_number"
              />
            </mat-form-field>
          </td>
        </tr>
        <tr>
          <td>
            <mat-label>Erstellungsdatum von</mat-label>

            <div class="dp-hidden position-absolute">
              <div class="input-group">
                <input name="datepickerCreation" class="form-control" ngbDatepicker #datepickerCreation="ngbDatepicker"
                  [autoClose]="'outside'" (dateSelect)="onDateSelectionCreation($event)" [displayMonths]="2"
                  [dayTemplate]="tCre" outsideDays="hidden" [startDate]="create_date_from!" tabindex="-1" />

                <ng-template #tCre let-date let-focused="focused">
                  <span class="custom-day" [class.focused]="focused" [class.range]="isRangeCreation(date)"
                    [class.faded]="isHoveredCreation(date) || isInsideCreation(date)"
                    (mouseenter)="hoveredDateCreation = date" (mouseleave)="hoveredDateCreation = null">
                    {{ date.day }}
                  </span>
                </ng-template>
              </div>
            </div>

            <div class="input-group">
              <input #dpFromDate class="form-control" placeholder="tt.mm.jjjj" name="dpFromDate"
                [value]="formatter.format(create_date_from)"
                (input)="create_date_from = validateInput(create_date_from, dpFromDate.value)" />
              <button class="btn btn-outline-secondary" (click)="datepickerCreation.toggle()" type="button">
                <fa-icon [icon]="['far','calendar']"></fa-icon>
              </button>
            </div>
          </td>
          <td><mat-label>Erstellungsdatum bis</mat-label>
            <div class="input-group">
              <input #dpToDate class="form-control" placeholder="tt.mm.jjjj" name="dpToDate"
                [value]="formatter.format(create_date_to)"
                (input)="create_date_to = validateInput(create_date_to, dpToDate.value)" />
              <button class="btn btn-outline-secondary" (click)="datepickerCreation.toggle()" type="button">
                <fa-icon [icon]="['far','calendar']"></fa-icon>
              </button>
            </div>
          </td>
        </tr>
        <tr>
          <td>
            <div class="links">
              <a href="" (click)="setRange('created', 'today')">heute</a>&nbsp;
              <a href="" (click)="setRange('created', 'thisweek')">diese Woche</a>&nbsp;
              <a href="" (click)="setRange('created', 'nextweek')">nächste Woche</a>&nbsp;
              <a href="" (click)="deleteFromDate('created')">von löschen</a>
            </div>
          </td>
        </tr>
        <tr>
          <td>
            <button class="button mt-3">Abfrage starten</button>
          </td>
          <td style="text-align: right">
            <a class="button" [routerLink]="['/', 'lieferscheine', 'neu']">
              Neuer Lieferschein
            </a>
          </td>
        </tr>
      </table>
    </form>
  </div>
</div>
