<div class="d-flex align-items-center" *ngIf="deliveryNote">
    <div class="col t-header d-flex align-items-center justify-content-start">
        <h4 class="mb20 d-flex align-items-center gap-1">
            <i class="fa fa-truck mr6" aria-hidden="true"></i>
            <ul class="list-group list-group-horizontal b-custom">
                <li class="list-group-item bg-transparent border-0">
                    <a
                    class="g-link"
                    [routerLink]="['/', 'lieferscheine', 'suche', 'index']"
                    >
                        Lieferscheine
                    </a>
                </li>
                <li class="list-group-item bg-transparent border-0">
                    <a
                    class="g-link"
                    [routerLink]="['/', 'lieferscheine', deliveryNote.id]"
                    >
                        <span>{{ deliveryNote.number }}</span>
                    </a>
                </li>
                <li class="list-group-item bg-transparent border-0">
                    Lieferung quittieren
                </li>
            </ul>
        </h4>
    </div>
</div>
<div class="callout mb-2">
    <h4>Hinweis</h4>
    Sie bestätigen hiermit den Empfang der Lieferung und müssen festlegen, was mit den gelieferten Artikeln passieren soll.
</div>
<form [formGroup]="deliveryNoteReceipt" (submit)="saveReceipt()" *ngIf="deliveryNote">
    <div class="ticketPage" *ngIf="deliveryNote">
        <section class="main-content">
            <header class="columns">
                <section>
                    <h2>Ort</h2>
                    <div class="form-group">
                        <ng-select
                        [items]="locations"
                        formControlName="location"
                        placeholder="Ort"
                        [required]="true"
                        [clearable]="false"
                        >
                        <ng-template ng-label-tmp let-item="item">
                            {{ item }}
                        </ng-template>
                        <ng-template
                            ng-option-tmp
                            let-item="item"
                            let-search="searchTermStatus"
                        >
                            <div
                            *ngIf="
                                item
                                .toLowerCase()
                                .includes(searchTerm.toLowerCase())
                            "
                            >
                            {{ item }}
                            </div>
                        </ng-template>
                        </ng-select>
                    </div>
                </section>
                <section>
                    <h2>Lager</h2>
                    <div *ngIf="!showTargetSelection">
                        keine Auswahl möglich
                    </div>
                    <div class="form-group" *ngIf="showTargetSelection">
                        <ng-select
                        [items]="warehouses"
                        formControlName="warehouse_id"
                        placeholder="Lager"
                        [required]="true"
                        [loading]="loadingWarehouses"
                        bindLabel="label"
                        bindValue="id"
                        [virtualScroll]="true"
                        >
                        <ng-template ng-label-tmp let-item="item">
                            {{ item.label }}
                        </ng-template>
                        <ng-template
                            ng-option-tmp
                            let-item="item"
                            let-search="searchTermStatus"
                        >
                            <div
                            *ngIf="
                                item.label
                                .toLowerCase()
                                .includes(searchTerm.toLowerCase())
                            "
                            >
                            {{ item.label }}
                            </div>
                        </ng-template>
                        </ng-select>
                    </div>
                </section>
                <section>
                    <h2>Platz</h2>
                    <div *ngIf="!showTargetSelection">
                        keine Auswahl möglich
                    </div>
                    <div class="form-group" *ngIf="showTargetSelection">
                        <ng-select
                        [items]="bins"
                        formControlName="storage_bin_id"
                        placeholder="Platz"
                        [required]="true"
                        [loading]="loadingbins"
                        bindLabel="label"
                        bindValue="id"
                        [virtualScroll]="true"
                        >
                        <ng-template ng-label-tmp let-item="item">
                            {{ item.label }}
                        </ng-template>
                        <ng-template
                            ng-option-tmp
                            let-item="item"
                            let-search="searchTermStatus"
                        >
                            <div
                            *ngIf="
                                item.label
                                .toLowerCase()
                                .includes(searchTerm.toLowerCase())
                            "
                            >
                            {{ item.label }}
                            </div>
                        </ng-template>
                        </ng-select>
                    </div>
                </section>
            </header>
            <section>
                <small>Artikelliste</small>
                <ag-grid-angular class="ag-theme-quartz" style="height: 300px;width: 100%;"
                [theme]="hawkTheme" [loadThemeGoogleFonts]="true"
                [defaultColDef]="defaultColDef"
                [columnDefs]="colDefs"
                [rowClassRules]="rowClassRules"
                [rowSelection]="rowSelection"
                [localeText]="locale"
                [rowData]="articles"
                [alwaysShowVerticalScroll]="true"
                >
                </ag-grid-angular>
            </section>
            <div class="row justify-content-between align-items-center mx-1">
                <span class="col-auto d-flex flex-column gap-1" *ngIf="deliveryNoteReceipt.controls.location.value == 'Lager'">
                    <small class="text-danger">Wichtig. Dieser Vorgang ist nicht umkehrbar. Der Knopfdruck bucht die Artikel sofort um</small>
                    <button [disabled]="deliveryNoteReceipt.invalid || submitting" mat-raised-button class="button col-4 col-x-12 col-md-6" type="submit">Artikel umbuchen</button>
                </span>
                <span class="col-auto d-flex flex-column gap-1" *ngIf="deliveryNoteReceipt.controls.location.value == 'Verbraucht'">
                    <small class="text-danger">Wichtig. Dieser Vorgang ist nicht umkehrbar. Der Knopfdruck setzt alle Artikel sofort auf verbraucht!</small>
                    <button [disabled]="deliveryNoteReceipt.invalid || submitting" mat-raised-button class="button col-4 col-x-12 col-md-6" type="submit">Artikel als verbraucht markieren</button>
                </span>
                <a class="col-auto col-xs-12" [routerLink]="['/', 'lieferscheine', deliveryNote.id]" >Abbrechen</a>
            </div>
        </section>
    </div>
</form>