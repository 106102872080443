
<div style="width: 100%;">
  <div style="width: 100%; display: inline-flex; flex-direction: row;">
    <div style="display: inline-flex; flex-direction: column; background-color: #444444; width: 22px;">
      <div style="display: inline-flex; border: 1px solid black; flex: 1;">
        <div style="margin: auto; padding: 2px; display: inline-flex;">
          <ng-content select="[icon]"></ng-content>
        </div>
      </div>
    </div>
    <div style="flex: 1;">
      <div style="height: 28px;">
        <hawk-expand-bar-header style="height: 100%;"
        [description]="description"
        [isExpanded]="isExpanded"
        [fontSize]="fontSize"
        [leftAligned]="leftAligned"
        [height]="height"
        [fontColor]="fontColor"
        (toggle)="click($event)">
        </hawk-expand-bar-header>
      </div>
      <div style="max-height: 300px; overflow-x: hidden;" class="expand" [ngClass]=" isExpanded ? '' : 'hidden'">
        <ng-content select="[content]"></ng-content>
      </div>
    </div>
  </div>
</div>