import { Injectable, signal } from '@angular/core'
import { iconSetAlpine, themeQuartz } from 'ag-grid-community';

@Injectable({ providedIn: 'root' })
export class AgGridThemeService {
  theme = signal(
    themeQuartz
    .withPart(iconSetAlpine)
    .withParams({
      fontFamily: {
        googleFont: "Open Sans"
      },

      headerFontSize: 14,
      iconSize: 14,
      borderRadius: 2,
      spacing: 5,
      wrapperBorderRadius: 2,

      headerVerticalPaddingScale: 0.75,
      rowVerticalPaddingScale: 0.75,
      cellHorizontalPaddingScale: 0.75,

      browserColorScheme: "light",
      oddRowBackgroundColor: "#F8F8F8",


      columnBorder: false,
      headerRowBorder: true,
      rowBorder: true,
      wrapperBorder: true,


    })
  )

  oldTheme = signal(
    themeQuartz.withParams({
      oddRowBackgroundColor: "#F8F8F8",
    })
  )

  getTheme() {
    return this.theme()
  }

  getOldTheme() {
    return this.oldTheme()
  }
}
