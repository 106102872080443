import { RouterModule } from "@angular/router";

import { Component, inject, OnInit } from '@angular/core'
import { ColDef } from 'ag-grid-community'
import { GridApi, GridState, RowClassRules, SideBarDef, IServerSideDatasource } from 'ag-grid-enterprise'
import { GridReadyEvent } from 'ag-grid-community'
import { WarehouseService } from '../_services'
import { AG_GRID_LOCALE_DE } from 'src/app/_helpers/aggrid.locale.de'
import { Router } from '@angular/router'
import { AgGridWarehouseLinkRenderer } from '../_components/_shared/ag-grid-renderer'
import { AgGridGenericLinkRenderer } from '../_components/_shared/ag-grid-renderer/ag-grid-generic-link.component'
import { AgGridExternalLinkRenderer } from '../_components/_shared/ag-grid-renderer/ag-grid-extranl-link.component'
import { AgGridAngular } from "ag-grid-angular";
import { AgGridThemeService } from "../_services/ag-grid-theme.service";

@Component({
    imports: [RouterModule, AgGridAngular],
    selector: 'hawk-warehouse-main',
    templateUrl: './warehouse-main.component.html',
    styleUrls: ['./warehouse-main.component.scss'],
})
export class WarehouseMainComponent implements OnInit {
  public hawkTheme = inject(AgGridThemeService).getTheme();
  // For now lets go without a model. Checking with signkey
  warehouses: any[]
  public locale = AG_GRID_LOCALE_DE
  public sideBarOptions: SideBarDef = {
    hiddenByDefault: false,
    toolPanels: ['columns', 'filters']
  }
  public paginationPageSize = 50;
  public paginationPageSizeSelector = [50,100,300,1000,9000]
  public storageKey = 'ticketSearchStateSettings'

  public rowSelection: "single" | "multiple" = "multiple"
  public initialState?: GridState;
  // public rowModelType = 'serverSide';
  public rowClassRules: RowClassRules = {
    // if tech on site, color row
    'tech-on-site': (params) => {
      if (params?.data === undefined) return "...";
      return params.data.tech_on_site
    },
  }

  public defaultColDef: ColDef = {
    filter: true,
    flex: 1
  }

  // Column Definitions: Defines the columns to be displayed.
  public colDefs: ColDef[] = [
    {
      field: "id",
      headerName: this.locale.warehouseId,
      minWidth: 70,
      width: 70,
      maxWidth: 100
    },
    {
      field: "label",
      headerName: this.locale.warehouseDesignation,
      cellRenderer: AgGridWarehouseLinkRenderer,
      filter: "agTextColumnFilter",
    },
    {
      field: 'all_articles_count',
      headerName: this.locale.warehouseAllArticle,
      cellRenderer: AgGridGenericLinkRenderer,
      cellRendererParams: {
        labelFromData: true,
        dataKey: 'all_articles_count',
        routerLink: ['/', 'lager', '@id', 'alle'],
        icon: ['fa', 'fa-cubes']
      },
      minWidth: 100,
      width: 100,
      maxWidth: 120
    },
    {
      field: "articles_count",
      headerName: this.locale.warehouseArticle,
      cellRenderer: AgGridGenericLinkRenderer,
      cellRendererParams: {
        labelFromData: true,
        dataKey: 'articles_count',
        routerLink: ['/', 'lager', '@id', 'articles'],
        icon: ['fa', 'fa-cube']
      },
      minWidth: 80,
      width: 80,
      maxWidth: 100
    },
    {
      field: "bad_parts_count",
      headerName: this.locale.warehouseBadParts,
      cellRenderer: AgGridGenericLinkRenderer,
      cellRendererParams: {
        labelFromData: true,
        dataKey: 'bad_parts_count',
        routerLink: ['/', 'lager', '@id', 'bad-parts'],
        icon: ['fa', 'fa-bug']
      },
      minWidth: 105,
      width: 105,
      maxWidth: 120
    },
    {
      field: "address_city",
      headerName: this.locale.warehouseCity
    },
    {
      field: "owner",
      headerName: this.locale.warehouseTechnician,
      // cellRenderer: ({data}) => (data.owner ? data.owner : '-'),
      cellRenderer: AgGridGenericLinkRenderer,
      cellRendererParams: {
        labelFromData: true,
        dataKey: 'owner',
        routerLink: ['/', 'admin', 'user', '@owner_id'],
        icon: ['fa', 'fa-user']
      }
    },
    {
      field: "bins_count",
      headerName: this.locale.warehousePlaces,
      flex: 1,
      minWidth: 70,
      width: 70,
      maxWidth: 100
    },
    {
      field: 'actions',
      headerName: '',
      filter: false,
      sortable: false,
      cellRenderer: ({data}) => {
        return `<div class="d-flex gap-1 align-items-center">
              <a class='g-link' href="/a/lager/${data.id}/retoure_international" target="_new">`+
                '<i class="fas fa-warehouse-alt mr6"></i> Ret.'+
              '</a>';
      }
    }
  ]

  private gridApi!: GridApi;

  constructor(
    private warehouseService: WarehouseService,
    private router: Router
  ) { }

  onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api;
    // const dataSource = this.dataSource();
  }

  // dataSource(): IServerSideDatasource {
  //   const warehouse = this.warehouseService;
  //   return {
  //     getRows(params) {
  //       const extraParams = JSON.stringify(params.request);
  //       warehouse.loadWarehouses({ grid_params: extraParams, include_counts: true }).subscribe({
  //         next: (data) => {
  //           params.success({rowData: data, rowCount: data.length});
  //         },
  //         error: (err) => {
  //           console.log('err', err);
  //           params.fail();
  //         }
  //       })
  //     }
  //   }
  // }

  public quicksearchChanged(e: Event) {
    const newValue = (e.target as HTMLInputElement).value;
    this.gridApi.setGridOption(
      "quickFilterText",
      newValue,
    )
  }

  ngOnInit(): void {
    this.warehouseService.loadWarehouses({ include_counts: true }).subscribe({
      next: (data) => {
        this.warehouses = data;
      },
      error: (err) => {
        console.log('err', err);
      }
    })
  }

}