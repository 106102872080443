import { AfterViewInit, Directive, ElementRef, OnChanges, SimpleChanges } from "@angular/core";
import { PAStore } from "../classes/store";
import { PATechnician } from "../classes/technician";
import { TourStopoverMapboxMarker, TechnicianMapboxMarker } from "../classes/mapbox-marker";
import * as mapboxgl from "mapbox-gl";
import { PAUtil } from "../classes/util";
import { PATour } from "../classes/tour";
import { PAMapStoreClusterer } from "../classes/map-store-clusterer";
import { DynamicComponentService } from "../../../_services/dynamic-component-service";
import { LngLat } from "mapbox-gl";
import { PAOperation } from "../classes/operation";
import { PADataControl } from "../singletons/pa-data-control";
import { PATourPlannerControl } from "../singletons/pa-tourplanner-control";
import { PAMapControl } from "../singletons/pa-map-control";

@Directive()
export abstract class PAMap implements OnChanges, AfterViewInit {

  public mapContainer: MapContainer = {name: '', map: null,}
  public changesQueue: SimpleChanges[] = []
  public runningChange = false

  storeMarkerMap = new Map<PAStore, TourStopoverMapboxMarker>()
  technicianMarkerMap = new Map<PATechnician, TechnicianMapboxMarker>()

  mapStoreClusterer: PAMapStoreClusterer
  abstract multipleStoresContainer: ElementRef<HTMLDivElement>;
  multipleStoresContainerPosition: {p1_top: number, p1_left: number, p2_top: number, p2_left: number, hidden: boolean} = {
    p1_top: 0,
    p1_left: 0,
    p2_top: 0,
    p2_left: 0,
    hidden: true
  }

  selectMultipleStoresMode = false
  selectMultipleStoresStartCoordinates: LngLat | null = null
  selectMultipleStoresEndCoordinates: LngLat | null = null

  protected constructor(
    public dynamicComponentService: DynamicComponentService
  ) {
    addEventListener("keydown", (event) => {
      if (event.key == 'Control') {
        this.pressControl();
      }
    });

    addEventListener("keyup", (event) => {
      if (event.key == 'Control') {
        console.log('Control released')
        this.releaseControl();
      }
    });
  }

  private releaseControl() {
    this.selectMultipleStoresMode = false
    this.mapContainer.map.getCanvas().style.cursor = ''
    this.mapContainer.map.dragPan.enable()
    this.mapContainer.map.dragRotate.enable()
    this.mapContainer.map.scrollZoom.enable()
  }

  private pressControl() {
    console.log('Control pressed')
    this.selectMultipleStoresMode = true
    this.mapContainer.map.getCanvas().style.cursor = 'crosshair'
    this.mapContainer.map.dragPan.disable()
    this.mapContainer.map.dragRotate.disable()
    this.mapContainer.map.scrollZoom.disable()
  }

  public updateMapTourStoreMarker(marked_stores_before: PAStore[], marked_stores_after: PAStore[], current_tours: PATour[], selected_tour?: PATour): void {

    let stores_to_add: PAStore[]
    let stores_to_remove: PAStore[]
    stores_to_add = marked_stores_after.filter(store => marked_stores_before.indexOf(store) < 0)
    stores_to_remove = marked_stores_before.filter(store => marked_stores_after.indexOf(store) < 0)

    for (let store of stores_to_remove) {
      this.removeStoreMarkerFromView(store)
    }

    for (let store of stores_to_add) {
      this.addStoreMarkerToView(store, current_tours, selected_tour)
    }
  }

  public updateMapTechnicianMarker(marked_technicians_before: PATechnician[], marked_technicians_after: PATechnician[]): void {

    let technicians_to_add: PATechnician[]
    let technicians_to_remove: PATechnician[]
    technicians_to_add = marked_technicians_after.filter(technician => marked_technicians_before.indexOf(technician) < 0)
    technicians_to_remove = marked_technicians_before.filter(technician => marked_technicians_after.indexOf(technician) < 0)

    for (let technician of technicians_to_remove) {
      this.removeTechnicianMarkerFromView(technician)
    }

    for (let technician of technicians_to_add) {
      this.addTechnicianMarkerToView(technician)
    }
  }

  private removeStoreMarkerFromView(store: PAStore): void {
    if (!this.storeMarkerMap.has(store)) {
      return
    }
    let marker = this.storeMarkerMap.get(store)
    marker.active = false
  }

  private addStoreMarkerToView(store: PAStore, current_tours: PATour[], selected_tour?: PATour): void {
    if (!this.storeMarkerMap.has(store)) {
      this.storeMarkerMap.set(store, new TourStopoverMapboxMarker(current_tours, store, this.mapContainer, this.dynamicComponentService))
    }
    let marker = this.storeMarkerMap.get(store)
    marker.active = true
    marker.tourDependencies = current_tours
    marker.selectedTour = selected_tour || null
  }

  private removeTechnicianMarkerFromView(technician: PATechnician): void {
    if (!this.technicianMarkerMap.has(technician)) {
      this.technicianMarkerMap.set(technician, new TechnicianMapboxMarker(technician, this.mapContainer))
    }
    let marker = this.technicianMarkerMap.get(technician)
    marker.active = false
  }

  private addTechnicianMarkerToView(technician: PATechnician): void {
    if (!this.technicianMarkerMap.has(technician)) {
      this.technicianMarkerMap.set(technician, new TechnicianMapboxMarker(technician, this.mapContainer))
    }
    let marker = this.technicianMarkerMap.get(technician)
    marker.active = true
  }

  async updateDisplayedRoutes(tours: PATour[]) {
    await Promise.all(tours.map(async tour => await tour.initMapTourAnimation(this.mapContainer)))
  }

  async waitForMapStyle() {
    let done = false
    while (!done) {
      await PAUtil.sleep(50)
      try {
        if (this.mapContainer?.map) {
          this.mapContainer.map.getStyle()
          done = true
        }
      } catch (err) {
        console.log(err)
        await this.waitForMapStyle()
        done = true
      }
    }
  }

  async waitAndUpdateMapSize(wait_ms: number): Promise<void> {
    await PAUtil.sleep(wait_ms)
    this.mapContainer?.map ? this.mapContainer.map.resize() : {}
  }

  async waitAndInitMap(access_token: string, id: number) {
    while (!document.getElementById('tour_map_window_' + id)) {
      await PAUtil.sleep(50)
    }
    this.mapContainer.map = new mapboxgl.Map({
      accessToken: access_token,
      container: 'tour_map_window_' + id,
      style: 'mapbox://styles/mapbox/navigation-day-v1?optimize=true',
      center: [PADataControl.Instance.loadingCoordinates.lng, PADataControl.Instance.loadingCoordinates.lat],
      zoom: 5
    })
    if (id == 0) PAMapControl.Instance.mainMapContainer = this.mapContainer
    this.mapContainer.name = 'map_' + id
    this.mapContainer.map.dragRotate.disable()
    this.mapContainer.map.touchZoomRotate.disable()
    this.mapContainer.map.on('mousedown', down_event => {
      console.log('mousedown')

      let mousemove = (move_event: mapboxgl.MapMouseEvent & mapboxgl.EventData) => {
        console.log('mousemove')
        this.selectMultipleStoresEndCoordinates = move_event.lngLat

        this.multipleStoresContainerPosition.p2_left = move_event.originalEvent.clientX
        this.multipleStoresContainerPosition.p2_top = move_event.originalEvent.clientY
        this.multipleStoresContainerPosition.hidden = false
        this.updateMultipleStoresContainerPosition()
      }

      let mouseup = (_: any) => {
        console.log('mouseup')
        this.mapContainer.map.off('mousemove', mousemove)
        this.mapContainer.map.off('mouseup', mouseup)
        this.multipleStoresContainerPosition.hidden = true
        this.updateMultipleStoresContainerPosition()
        let features = this.mapStoreClusterer.findStoreFeaturesBetweenCoordinates(this.selectMultipleStoresStartCoordinates, this.selectMultipleStoresEndCoordinates)
        let stores = features.map(feature => PADataControl.Instance.getStore(feature.properties.id))
        this.addStoreOperationsToTourPlanning(stores)
      }

      if (this.selectMultipleStoresMode) {
        this.selectMultipleStoresStartCoordinates = down_event.lngLat
        this.multipleStoresContainerPosition.p1_left = down_event.originalEvent.clientX
        this.multipleStoresContainerPosition.p1_top = down_event.originalEvent.clientY
        this.multipleStoresContainerPosition.hidden = true
        this.updateMultipleStoresContainerPosition()

        this.mapContainer.map.on('mousemove', mousemove)
        this.mapContainer.map.on('mouseup', mouseup)
      }
    })
  }

  addStoreOperationsToTourPlanning(stores: PAStore[]){
    if (this.mapStoreClusterer.technicianDate) {
      let doable_operations = stores.reduce((ops: PAOperation[],store) => ops.concat(store.getDoableOperationsForTechnicianDate(this.mapStoreClusterer.technicianDate)), [])
      if (doable_operations.length) {
        PATourPlannerControl.Instance.addOperationsToTourPlanning(doable_operations);
        PATourPlannerControl.Instance.planningInstructions.addOperationsTechnicianConstraints(doable_operations, [this.mapStoreClusterer.technicianDate.technician], [this.mapStoreClusterer.technicianDate.day.utc_timestamp])
      }
    } else {
      let operations = stores.reduce((ops: PAOperation[],store) => ops.concat(store.getUnassignedOperations()), [])
      if (operations.length) {
        if (PATourPlannerControl.Instance.planningMenuIsInPlanningProcess()) {
          PATourPlannerControl.Instance.addOperationsToTourPlanning(operations);
        } else {
          PATourPlannerControl.Instance.planOperation(operations.splice(0, 1)[0])
          PATourPlannerControl.Instance.addOperationsToTourPlanning(operations);
        }
      }
    }
  }

  updateMultipleStoresContainerPosition(): void {
    let style = this.multipleStoresContainer.nativeElement.style
    let msc_position = this.multipleStoresContainerPosition

    style.top = `${Math.min(msc_position.p1_top, msc_position.p2_top)}px`
    style.left = `${Math.min(msc_position.p1_left, msc_position.p2_left)}px`
    style.right = `calc(100vw - ${Math.max(msc_position.p1_left, msc_position.p2_left)}px)`
    style.bottom = `calc(100vh - ${Math.max(msc_position.p1_top, msc_position.p2_top)}px)`
    style.display = this.multipleStoresContainerPosition.hidden ? 'none' : 'flex'
  }

  async waitForMapAndProcessChanges() {
    if (!this.runningChange) {
      this.runningChange = true
      await this.waitForMapStyle()
      if (!this.mapStoreClusterer) {
        this.mapStoreClusterer = new PAMapStoreClusterer(this.mapContainer.map, this.dynamicComponentService)
      }
      while (this.changesQueue.length) {
        await this.processChanges(this.changesQueue.splice(0, 1)[0])
      }
      this.runningChange = false
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.changesQueue.push(this.cloneChanges(changes))
    this.waitForMapAndProcessChanges()
  }

  cloneChanges(changes: SimpleChanges): SimpleChanges {
    // cloning changes to prevent first changes object values being overridden with later changes object values by angular source code for some reason
    return  {
      ...changes,
    }
  }

  async ngAfterViewInit(): Promise<void> {
    setInterval(() => {
      this.waitAndUpdateMapSize(10)
    }, 50);
  }

  abstract processChanges(changes: SimpleChanges): Promise<void>

}

export interface MapContainer {
  name: string,
  map: mapboxgl.Map
}
