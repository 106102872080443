import { Opening } from "src/app/_models/opening.interface";
import { OWDate, OWDateRange, OWPlannedJobInput } from "src/app/_models/optiwae.interface";
import {
  OperationChanges,
  OperationTimeData,
} from "src/app/_models/planning-assistant.interface";
import { OperationService, TicketService } from "src/app/_services";
import { PlanningAssistantService } from "src/app/_services/planning-assistant.service";
import { PADistance } from "./distance";
import { CalculatesDistance, PALocation } from "./location";
import { PAStore } from "./store";
import { PATechnician } from "./technician";
import { PATechnicianDate } from "./technician-date";
import { PAUtil, WeekDay } from "./util";
import { DayOpening, OpeningTimes } from "src/app/_models/opening-times.interface";
import { PATicket } from "./ticket";
import { Route } from "src/app/_services/mapbox.service";
import { PAProject } from "./project";
import { Priority, Status, TicketNote } from "src/app/_models";
import { Operation, PermittedOperationParams } from "src/app/_models/operation.interface";
import { TimePredictionRule } from "./time-prediction-rule";
import { PATour } from "./tour";
import { PAFilterControl } from "../singletons/pa-filter-control";
import { PADataControl } from "../singletons/pa-data-control";
import { PATourPlannerControl } from "../singletons/pa-tourplanner-control";
import { PASettingsControl } from "../singletons/pa-settings-control";
import { PATimeControl } from "../singletons/pa-time-control";

export class PAOperation implements CalculatesDistance {
  static planningAssistantService: PlanningAssistantService
  static ticketService: TicketService
  static operationService: OperationService
  static operationChangePlanningID: string = 'manual'
  static nextTemporaryOperationId: number = -1
  static defaultWeekDayStoreOpening: DayOpening = {
    open: '08:00',
    close: '20:00',
    validity: 'default'
  }
  static defaultWeekEndStoreOpening: DayOpening = {
    open: '00:00',
    close: '00:00',
    validity: 'default'
  }

  public tour: PATour
  private afterStorageTasks: AfterOperationStorageTasks = {}
  private _id: number
  public dragDropOffsetFactor = 1

  constructor(
    id: number,
    public client_id: number,
    public operation_date: string,
    public ticket: PATicket,
    public user_ids: number[],
    public date_on_site: string,
    public date_travel_start: string,
    public date_repaired: string,
    public date_finished: string,
    public description: string
  ) {
    this._id = id
    if (!PADataControl.Instance.operationMap.has(id)) {
      PADataControl.Instance.operationMap.set(id, this)
    }

    if (this.ticket.coordinates.latitude == null || this.ticket.coordinates.longitude == null) {
      console.log('Cant insert operation with id ' + this.id.toString() + ' - no lat / lng')
      PADataControl.Instance.operationsWithoutLngLat.push(this)
    }
  }

  set afterStorageTaskTicketNote(t_note: TicketNote) {
    this.afterStorageTasks.addTicketNote = t_note
  }

  get afterStorageTaskTicketNote(): TicketNote {
    return this.afterStorageTasks.addTicketNote
  }

  calculateOperationTimeMilliseconds(config?: { use_technician?: PATechnician, use_priority_idx?: number }): number {
    if (this.date_on_site && this.date_finished) {
      return (Date.parse(this.date_finished) - Date.parse(this.date_on_site))
    }
    let technician = config?.use_technician || this.getTechnician()

    let time_rule = technician ? this.getAdditionalPriorityTimeRule(config) : null
    if (time_rule) {
      return Math.round(this.ticket.time_estimation * this.getAdditionalPriorityTimeRuleTimeFactor(config)) * 60 * 1000
    } else {
      if (PAFilterControl.Instance.selectedOperationTimeFilter == 'planned' || !technician) {
        return this.ticket.time_estimation * 60 * 1000
      } else {
        return technician.getAverageOperationTime(this) * 60 * 1000
      }
    }
  }

  calculateOperationDayStartMilliseconds(config?: { use_day_timestamp?: number }): number {
    let date = this.date_on_site || this.operation_date
    let day_timestamp = config?.use_day_timestamp || PATimeControl.Instance.dateStringToTimestamp(date, true, false)
    return Date.parse(date) - day_timestamp
  }

  calculateOperationDayEndMilliseconds(config?: { use_technician?: PATechnician, use_priority_idx?: number, use_day_timestamp?: number }): number {
    let date = this.date_finished || this.operation_date
    let day_timestamp = PATimeControl.Instance.dateStringToTimestamp(date, true, false)
    return this.date_finished ? Date.parse(date) - day_timestamp : (this.calculateOperationDayStartMilliseconds(config) + this.calculateOperationTimeMilliseconds(config))
  }

  plannedTechnicianDateTime(short_name?: boolean): string {
    let td = this.getTechnicianDate()
    if (td) {
      return `${short_name ? td.technician.firstname[0] + '.' + td.technician.lastname[0] + '.' : td.technician.getFullName()} - ${this.getDateTimeString('.')}`
    } else {
      return 'Ungeplant'
    }
  }

  async executeAfterStorageTasks(): Promise<void> {
    return new Promise<void>(async resolve => {
      let promises: Promise<void>[] = []
      if (this.afterStorageTaskTicketNote) {
        promises.push(this.ticket.createTicketNote(this.afterStorageTaskTicketNote))
      }
      await Promise.all(promises)
      this.afterStorageTasks = {}
      resolve()
    })
  }

  get id(): number {
    return this._id;
  }

  set id(value: number) {
    PADataControl.Instance.operationMap.delete(this._id)
    this._id = value;
    PADataControl.Instance.operationMap.set(this._id, this)
    PAFilterControl.Instance.updateUnassignedOperations()
  }

  getStreetDistanceToCoordinates(lat: number, lon: number): Promise<{ distance: number; duration: number; }> {
    return this.ticket.client.getStreetDistanceToCoordinates(lat, lon)
  }

  getDistanceToLocation(to_location: PALocation): Promise<{ distance: number; duration: number; }> {
    return this.ticket.client.getDistanceToLocation(to_location)
  }

  getDistanceToLocationAsTheCrowFlies(location: PALocation): number {
    return this.ticket.client.location.getDistanceToCoordinatesAsTheCrowFlies(location.coordinates.latitude, location.coordinates.longitude)
  }

  checkGeneralTechnicianDoability(technician: PATechnician): boolean {
    return !this.nonFeasibilityReasonsForTechnician(technician).length
  }

  nonFeasibilityReasonsForTechnician(technician: PATechnician): string[] {
    let res: string[] = []
    if (!technician.hasOperationMaterial(this)) {
      res.push('Der Techniker besitzt nicht das benötigte Material')
    }
    if (!technician.isProjectTechnician(this.ticket.project.id)) {
      res.push('Der Techniker gehört nicht zum Auftragsprojekt')
    }

    return res
  }

  address(): string {
    if (this.ticket) {
      return this.ticket.address()
    } else {
      return 'Fehler: Kein Ticket'
    }
  }

  hasOpenings(): boolean {
    for (let week_day of PATimeControl.Instance.weekDays) {
      if (this.ticket.store_openings[week_day]) return true
    }
    return false
  }

  public centerInMap(map: mapboxgl.Map): void {
    this.getStore().centerInMap(map)
  }

  isUnassigned(): boolean {
    return PADataControl.Instance.unassignedOperationUserIds.includes(this.user_ids[0])
  }

  isGloballyVisible(): boolean {
    return !!PAFilterControl.Instance.globalFilteredOperations.find(o => this.id == o.id)
  }

  getPlannedDate(): string {
    if (this.date_on_site) {
      return this.date_on_site
    }
    return this.operation_date
  }

  getPlannedDayTimestamp() {
    return PATimeControl.Instance.dateStringToTimestamp(this.getPlannedDate(), true, true)
  }

  getPlannedTimestamp() {
    return PATimeControl.Instance.dateStringToTimestamp(this.getPlannedDate(), false, true)
  }

  getDistanceToCoordinatesAsTheCrowFlies(address_latitude: any, address_longitude: any): number {
    return PADistance.getDistanceToCoordinatesAsTheCrowFlies(this.ticket.coordinates.latitude, this.ticket.coordinates.longitude, address_latitude, address_longitude)
  }

  belongsToPriority(priority_id: number): unknown {
    return this.ticket.priority.id == priority_id
  }

  belongsToProject(project_id: number): unknown {
    return this.ticket.project.id == project_id || this.ticket.project.id == -1
  }

  getProject(): PAProject {
    return this.ticket.project
  }

  getTechnicianDate(load_data?: boolean): PATechnicianDate {
    if (PADataControl.Instance.unassignedOperationUserIds.includes(this.user_ids[0])) return null
    let technician = PADataControl.Instance.getTechnician(this.user_ids[0])
    return technician ? technician.getTechnicianDate(PATimeControl.Instance.dateStringToTimestamp(this.getPlannedDate(), true, true), true, load_data) : null
  }

  getTour(load_data?: boolean): PATour {
    if (this.tour) {
      return this.tour
    } else {
      let technician_date = this.getTechnicianDate(load_data)
      return technician_date ? technician_date.tour : null
    }
  }

  getTechnician(): PATechnician {
    let id = this.user_ids[0]
    if (id && !this.isUnassigned()) {
      return PADataControl.Instance.getTechnician(id)
    }
  }

  getStartTime(): string {
    let date = new Date(Date.parse(this.getPlannedDate()))
    return PATimeControl.Instance.dateToTimestring(date, false)
  }

  getStartMinutes(): number {
    return PATimeControl.Instance.timeStringToMinutes(this.getStartTime())
  }

  async getTravelDataToOperation(use_tour: PAOperation[], start_location: PALocation): Promise<Route> {

    let from_location: PALocation
    let tour_index = use_tour.indexOf(this)

    if (tour_index == 0) {
      from_location = start_location
    } else {
      const from_operation = use_tour[tour_index - 1]
      from_location = from_operation.ticket.client.location
    }

    const distance_data = await from_location.getDistanceToLocation(this.ticket.client.location)

    let duration: number = distance_data.duration

    if (this.date_travel_start && this.date_on_site) {
      duration = PATimeControl.Instance.dateTimeToDayMinutes(this.date_on_site) - PATimeControl.Instance.dateTimeToDayMinutes(this.date_travel_start)
    } else if (!this.isUnassigned()) {
      duration = Math.round(distance_data.duration * this.getTechnician().driving_time_factor)
    }

    return {distance: distance_data.distance, duration: duration, geometry: distance_data.geometry}
  }

  public getDateString(): string {
    let date = new Date(this.getPlannedDate())
    let week_day = PATimeControl.Instance.weekDayToGermanName(PATimeControl.Instance.getTimeStampsWeekDay(date.getTime()), true)
    let date_string = PATimeControl.Instance.dateToDatestring(date, false, false, '.')
    let time_string = PATimeControl.Instance.dateToTimestring(date, false)
    return week_day + ' ' + date_string + " " + time_string + "Uhr"
  }

  public getTimeString(): string {
    let date = new Date(this.getPlannedDate())
    return PATimeControl.Instance.dateToTimestring(date, false)
  }

  public getDateTimeString(connector?: string): string {
    if (this.operation_date) {
      return PATimeControl.Instance.dateToDatestring(new Date(this.operation_date), false, false, connector) + ' ' + this.getTimeString()
    } else {
      return '-'
    }
  }

  public getStore(): PAStore {
    if (this.ticket.coordinates.latitude != null && this.ticket.coordinates.longitude != null) {
      let store_key = this.ticket.coordinates.latitude.toString() + this.ticket.coordinates.longitude.toString()
      if (!PAStore.storeMap.has(store_key)) {
        let store = new PAStore(
          this.ticket.address_company,
          this.address(),
          this.ticket.client.location,
          [this.id]
        )
        PAStore.storeMap.set(store_key, store)
        store.fireUpdateManually()
        PAStore.allStores.push(store)
        return store
      } else {
        return PAStore.storeMap.get(store_key)
      }
    } else {
      return null
    }
  }

  public isInPlanningProcess(): boolean {
    return PATourPlannerControl.Instance.operationToPlanID == this.id && PATourPlannerControl.Instance.tourPlannerPlanningMode == 'operation'
  }

  public hasStarted(): boolean {
    return !!(this.date_travel_start || this.date_on_site || this.date_finished)
  }

  public getTimeFilteredWorkTime(filter: 'planned' | 'average', technician: PATechnician): number {
    let rule = this.getAdditionalPriorityTimeRule()
    if (filter === 'planned') {
      return rule ? this.getAdditionalPriorityTimeRuleTimeFactor() * this.ticket.time_estimation : this.ticket.time_estimation
    } else {
      return rule ? this.getAdditionalPriorityTimeRuleTimeFactor() * this.ticket.time_estimation : technician.getPriorityBasedAverageOperationTime(this.ticket.priority.id)
    }
  }

  async getOperationTimeData(technician: PATechnician, calculated_driving_time: number): Promise<OperationTimeData> {

    // prefilter for date errors
    let errors: string[] = []
    let set_dates = [this.date_travel_start, this.date_on_site, this.date_repaired, this.operation_date, this.ticket.appointment_date].filter(date => date)
    let day_timestamps = set_dates.map(date => PATimeControl.Instance.dateStringToTimestamp(date, true, false))
    if ([...new Set(day_timestamps)].length > 1) {
      errors.push('different_dates_error')
      return {
        estimated_worktime: 0,
        average_worktime: 0,
        operation_start_time: 0,
        driving_start_time: 0,
        driving_time: 0,
        estimated_end_time: 0,
        average_end_time: 0,
        errors: errors
      }
    }

    if (!technician.operationTimeEntryForPriorityWasLoaded(this.ticket.priority.id)) {
      await technician.loadPriorityBasedOperationTimes(this.ticket.priority.id)
    }

    let driving_start_time: number
    let driving_time: number
    let operation_start_time: number
    let estimated_worktime: number = this.getTimeFilteredWorkTime('planned', technician)
    let average_worktime: number = this.getTimeFilteredWorkTime('average', technician)
    let average_end_time: number
    let estimated_end_time: number

    if (average_worktime < 0) {
      average_worktime = estimated_worktime
    }

    if (this.date_travel_start) {
      driving_start_time = PATimeControl.Instance.dateTimeToDayMinutes(this.date_travel_start)
      if (this.date_on_site) {
        operation_start_time = PATimeControl.Instance.dateTimeToDayMinutes(this.date_on_site)
        driving_time = operation_start_time - driving_start_time
        if (this.date_finished) {
          estimated_worktime = average_worktime = PATimeControl.Instance.dateTimeToDayMinutes(this.date_finished) - operation_start_time
          estimated_end_time = average_end_time = PATimeControl.Instance.dateTimeToDayMinutes(this.date_finished)
        } else {
          estimated_end_time = operation_start_time + estimated_worktime
          average_end_time = operation_start_time + average_worktime
        }
      } else {
        driving_time = calculated_driving_time
        operation_start_time = driving_start_time + driving_time
        estimated_end_time = operation_start_time + estimated_worktime
        average_end_time = operation_start_time + average_worktime
      }
    } else {
      operation_start_time = PATimeControl.Instance.dateTimeToDayMinutes(this.operation_date)
      driving_time = calculated_driving_time
      driving_start_time = operation_start_time - driving_time
      estimated_end_time = operation_start_time + estimated_worktime
      average_end_time = operation_start_time + average_worktime
    }

    return {
      estimated_worktime: estimated_worktime,
      average_worktime: average_worktime,
      operation_start_time: operation_start_time,
      driving_start_time: driving_start_time,
      driving_time: driving_time,
      estimated_end_time: estimated_end_time,
      average_end_time: average_end_time,
      errors: errors
    }
  }

  public clone(): PAOperation {
    return new PAOperation(
      this.id,
      this.client_id,
      this.operation_date,
      this.ticket,
      this.user_ids,
      this.date_on_site,
      this.date_travel_start,
      this.date_repaired,
      this.date_finished,
      this.description
    )
  }

  public initOperationChange(id: string, changes?: { new_date?: Date, new_uid?: number }): OperationChanges {
    const possible_operation_change = PADataControl.Instance.operationChanges.filter(change => change.operation_id == this.id)
    let operation_changes: OperationChanges
    let base_copy: PAOperation
    if (possible_operation_change.length == 0) {
      base_copy = this.clone()
      let operation_change_map = new Map<string, { old_operation: PAOperation, new_operation: PAOperation }>()
      operation_change_map.set(id, {old_operation: this, new_operation: base_copy})
      operation_changes = {operation_id: this.id, base_operation: this, operation_change_map: operation_change_map}
      PADataControl.Instance.operationChanges = PADataControl.Instance.operationChanges.concat([operation_changes])
    } else {
      operation_changes = possible_operation_change[0]
      if (!operation_changes.operation_change_map.has(id)) {
        base_copy = this.clone()
        operation_changes.operation_change_map.set(id, {old_operation: this, new_operation: base_copy})
      } else {
        let operation_change = operation_changes.operation_change_map.get(id)
        base_copy = operation_change.new_operation = operation_change.new_operation.clone()
      }
    }
    if (changes?.new_date) {
      base_copy.operation_date = PATimeControl.Instance.formatDate(changes.new_date)
    }
    if (changes?.new_uid) {
      base_copy.user_ids = [changes.new_uid]
    }
    if (base_copy.operation_date && !PADataControl.Instance.unassignedOperationUserIds.includes(base_copy.user_ids[0])) {
      base_copy.setStatusMeta(base_copy.getProject().getPlannedStatus().id.toString())
    } else {
      base_copy.setStatusMeta(base_copy.getProject().getUnplannedStatus().id.toString())
    }

    return operation_changes
  }

  public isDone(): boolean {
    return !!this.date_repaired
  }

  public getBaseOperation(): PAOperation {
    const possible_change = PADataControl.Instance.operationChanges.filter(change => change.operation_id == this.id)
    if (possible_change.length) {
      return possible_change[0].base_operation
    } else {
      return this
    }
  }

  public getLatestOperationChange(change_id: string): PAOperation {
    const possible_change = PADataControl.Instance.operationChanges.filter(change => change.operation_id == this.id)
    if (possible_change.length && possible_change[0].operation_change_map.has(change_id)) {
      return possible_change[0].operation_change_map.get(change_id).new_operation
    } else {
      return this
    }
  }

  async applyOperationChange(id: string, skip_unassigned_operation_update?: boolean): Promise<{
    changed_technician_dates: PATechnicianDate[];
    changed_stores: PAStore[];
  }> {
    let possible_operation_change = PADataControl.Instance.operationChanges.filter(change => change.operation_id == this.id && change.operation_change_map.has(id))

    let changed_technician_dates: PATechnicianDate[] = []
    let changed_stores: PAStore[] = []

    if (possible_operation_change.length) {
      const operation_change = possible_operation_change[0]
      const old_operation = operation_change.operation_change_map.get(id).old_operation
      const new_operation = operation_change.operation_change_map.get(id).new_operation

      if (old_operation != new_operation) {

        const old_tour = old_operation.getTour()
        if (old_tour) {
          changed_stores = changed_stores.concat(old_tour.operations.map(op => op.getStore()))
          await old_tour.removeOperationWithId(old_operation.id)
          changed_technician_dates.push(old_tour.technicianDate)
        }

        const new_tour = new_operation.getTour()
        if (new_tour) {
          await PAOperation.insertOperationsInTechnicianDateAndStore([new_operation], true)
          changed_technician_dates.push(new_tour.technicianDate)
          changed_stores = changed_stores.concat(new_tour.operations.map(op => op.getStore()))
        }

        operation_change.operation_change_map.get(id).old_operation = new_operation

        if ((PADataControl.Instance.unassignedOperationUserIds.includes(old_operation.user_ids[0]) || PADataControl.Instance.unassignedOperationUserIds.includes(new_operation.user_ids[0])) && skip_unassigned_operation_update) {
          PAFilterControl.Instance.updateUnassignedOperations()
        }
      }
    }
    return {changed_stores: changed_stores, changed_technician_dates: changed_technician_dates}
  }

  async revertOperationChange(id: string): Promise<{
    changed_technician_dates: PATechnicianDate[];
    changed_stores: PAStore[];
  }> {
    let possible_operation_change = PADataControl.Instance.operationChanges.filter(change => change.operation_id == this.id && change.operation_change_map.has(id))

    let changed_technician_dates: PATechnicianDate[] = []
    let changed_stores: PAStore[] = []

    if (possible_operation_change.length) {
      const operation_change = possible_operation_change[0]

      const new_operation = operation_change.operation_change_map.get(id).new_operation
      const new_tour = new_operation.getTour()

      const old_operation = operation_change.base_operation
      const old_tour = old_operation.getTour()

      if (new_tour) {
        changed_stores = changed_stores.concat(new_tour.operations.map(op => op.getStore()))
        await new_tour.removeOperationWithId(new_operation.id)
        changed_technician_dates.push(new_tour.technicianDate)
      }

      if (old_tour) {
        await PAOperation.insertOperationsInTechnicianDateAndStore([old_operation])
        //old_tour.updateTourMarkers()
        //await old_tour.updateRoute()
        changed_technician_dates.push(old_tour.technicianDate)
        changed_stores = changed_stores.concat(old_tour.operations.map(op => op.getStore()))
      }

      operation_change.operation_change_map.get(id).old_operation = old_operation

      operation_change.operation_change_map.delete(id)
      if (operation_change.operation_change_map.size == 0) {
        PADataControl.Instance.operationChanges = PADataControl.Instance.operationChanges.filter(change => change != operation_change)
      }

      let store = PAStore.getOperationsStore(new_operation)
      if (store) {
        //store.fireUpdateManually()
      }

      if (PADataControl.Instance.unassignedOperationUserIds.includes(old_operation.user_ids[0]) || PADataControl.Instance.unassignedOperationUserIds.includes(new_operation.user_ids[0])) {
        PAFilterControl.Instance.updateUnassignedOperations()
      }
    }

    return {changed_stores: changed_stores, changed_technician_dates: changed_technician_dates}
  }

  public async saveOperationChange(id: string): Promise<void> {

    console.log(`Speichere Auftrag ${this.id}`)
    let possible_operation_change = PADataControl.Instance.operationChanges.filter(change => change.operation_id == this.id && change.operation_change_map.has(id))
    if (possible_operation_change.length) {
      const operation_change = possible_operation_change[0]

      const new_operation = operation_change.operation_change_map.get(id).new_operation
      const base_operation = operation_change.base_operation

      const operation_date_changed = new_operation.operation_date != base_operation.operation_date
      const technician_changed = !PAUtil.equalSets(new Set(new_operation.user_ids), new Set(base_operation.user_ids))

      let new_status = await this.saveTicketStatus()

      let update_operation: Operation
      let delete_old_operation_id_from_store: number = 0
      let store = new_operation.getStore()

      if (this.id <= 0) { // create Operation

        let operation_params: PermittedOperationParams = {
          date_created: PATimeControl.Instance.formatDate(new Date()),
          description: this.description,
          operation_date: new_operation.operation_date,
          technician_ids: new_operation.user_ids
        }

        update_operation = await new Promise<Operation>(resolve => {
          PAOperation.ticketService.createTicketOperation(new_operation.ticket.id, {operation: operation_params}).subscribe(
            async (data) => {
              PATourPlannerControl.Instance.snackBar.open(
                'Auftrag in der Datenbank angelegt',
                'Ok'
              )._dismissAfter(3000)
              console.log(`Neue Auftrags ID: ${data.id}`)
              delete_old_operation_id_from_store = this.id
              resolve(data)
            },
            (err) => {
              PATourPlannerControl.Instance.snackBar.open(
                'Fehler: Auftrag konnte nicht in der Datenbank angelegt werden',
                'Ok'
              )._dismissAfter(3000)
              console.log(err)
              resolve(null)
            }
          )
        })

      } else { // change Operation

        let changeData = {}
        operation_date_changed ? changeData['operation_date'] = new_operation.operation_date : {}
        technician_changed ? changeData['technician_ids'] = new_operation.user_ids : {}

        update_operation = await new Promise<Operation>(resolve => {
          PAOperation.operationService.updateOperation(new_operation.id.toString(), {operation: changeData}).subscribe(
            async (data) => {
              PATourPlannerControl.Instance.snackBar.open(
                'Auftrag erfolgreich in der Datenbank geändert',
                'Ok'
              )._dismissAfter(3000)
              console.log(`Änderung am Auftrag ${data.id} erfolgreich gespeichert`)
              resolve(data)
            },
            (err) => {
              PATourPlannerControl.Instance.snackBar.open(
                'Fehler: Auftrag konnte nicht in der Datenbank geändert werden',
                'Ok'
              )._dismissAfter(3000)
              console.log(err)
              resolve(null)
            }
          )
        })
      }

      if (update_operation) {
        await this.executeAfterStorageTasks()

        base_operation.id = update_operation.id
        base_operation.user_ids = update_operation.technicians.map(technician => technician.id)
        base_operation.operation_date = update_operation.operation_date
        base_operation.ticket.status = new_status || base_operation.ticket.status

        const new_tour = new_operation.getTour()
        if (new_tour) {
          await new_tour.removeOperationWithId(new_operation.id)
        }

        if (delete_old_operation_id_from_store) {
          store.operation_ids = store.operation_ids.filter(id => id != delete_old_operation_id_from_store)
        }

        const base_tour = base_operation.getTour()

        if (base_tour) {
          await PAOperation.insertOperationsInTechnicianDateAndStore([base_operation])
          base_tour.updateTourMarkers()
          await base_tour.updateRoute()
        }

        operation_change.operation_change_map.delete(id)
        PADataControl.Instance.operationChanges = PADataControl.Instance.operationChanges.filter(change => change != operation_change)

        store.fireUpdateManually()
      }

    }
  }

  public updateJobWithOWPlannedJobInput(ow_planned_job_input: OWPlannedJobInput): void {
    this.initOperationChange(
      'manual',
      {
        new_date: new Date(ow_planned_job_input.dt.dt_str),
        new_uid: ow_planned_job_input.operator.operator_id
      }
    )
  }

  generateJobOWDateRanges(time_range?: { start_timestamp: number, end_timestamp: number }): OWDateRange[] {

    const ow_date_ranges: OWDateRange[] = []
    if (this.ticket.appointment_date) {
      let appointment_date_start = new Date(this.ticket.appointment_date)
      let appointment_date_end = new Date(appointment_date_start.getTime() + this.ticket.time_estimation * 60000);
      const ow_date_from: OWDate = {
        type: 'SerializableDateTime',
        year: appointment_date_start.getFullYear(),
        month: appointment_date_start.getMonth() + 1,
        day: appointment_date_start.getDate(),
        hour: appointment_date_start.getHours(),
        minute: appointment_date_start.getMinutes(),
        second: 0,
        microsecond: 0,
        tzinfo: null
      }

      const ow_date_until: OWDate = {
        type: 'SerializableDateTime',
        year: appointment_date_end.getFullYear(),
        month: appointment_date_end.getMonth() + 1,
        day: appointment_date_end.getDate(),
        hour: appointment_date_end.getHours(),
        minute: appointment_date_end.getMinutes(),
        second: 0,
        microsecond: 0,
        tzinfo: null
      }

      ow_date_ranges.push({
        type: 'JobDateRange',
        begin: ow_date_from,
        end: ow_date_until,
        repeat_frequency: -1,
        repeats: 1
      })

    } else if (time_range) {

      const start_timestamp = time_range.start_timestamp
      const end_timestamp = time_range.end_timestamp
      let current_timestamp = start_timestamp
      while (current_timestamp <= end_timestamp) {

        const current_date = new Date(current_timestamp)
        const current_week_day = PATimeControl.Instance.getTimeStampsWeekDay(current_timestamp)
        const day_opening = this.getWeekDaysOpeningTime(current_week_day)
        let time_start = PATimeControl.Instance.timeStringToMinutes(day_opening.open)
        let time_end = PATimeControl.Instance.timeStringToMinutes(day_opening.close)
        let hours_start = Math.floor(time_start / 60)
        let minutes_start = Math.floor(time_start % 60)
        let hours_end = Math.floor(time_end / 60)
        let minutes_end = Math.floor(time_end % 60)

        const ow_date_from: OWDate = {
          type: 'SerializableDateTime',
          year: current_date.getFullYear(),
          month: current_date.getMonth() + 1,
          day: current_date.getDate(),
          hour: hours_start,
          minute: minutes_start,
          second: 0,
          microsecond: 0,
          tzinfo: null
        }

        const ow_date_until: OWDate = {
          type: 'SerializableDateTime',
          year: current_date.getFullYear(),
          month: current_date.getMonth() + 1,
          day: current_date.getDate(),
          hour: hours_end,
          minute: minutes_end,
          second: 0,
          microsecond: 0,
          tzinfo: null
        }

        ow_date_ranges.push({
          type: 'JobDateRange',
          begin: ow_date_from,
          end: ow_date_until,
          repeat_frequency: -1,
          repeats: 1
        })

        current_timestamp += 24 * 60 * 60 * 1000
      }

    } else {

      const current_timestamp = PATimeControl.Instance.getCurrentTimestamp()
      for (let following_weekday_idx_offsets of [0, 1, 2, 3, 4, 5, 6]) {
        let weekday_timestamp = current_timestamp + following_weekday_idx_offsets * 24 * 60 * 60 * 1000
        const date = new Date(weekday_timestamp)
        const week_day = PATimeControl.Instance.getTimeStampsWeekDay(weekday_timestamp)
        const day_opening = this.getWeekDaysOpeningTime(week_day)
        let time_start = PATimeControl.Instance.timeStringToMinutes(day_opening.open)
        let time_end = PATimeControl.Instance.timeStringToMinutes(day_opening.close)
        let hours_start = Math.floor(time_start / 60)
        let minutes_start = Math.floor(time_start % 60)
        let hours_end = Math.floor(time_end / 60)
        let minutes_end = Math.floor(time_end % 60)

        const ow_date_from: OWDate = {
          type: 'SerializableDateTime',
          year: date.getFullYear(),
          month: date.getMonth() + 1,
          day: date.getDate(),
          hour: hours_start,
          minute: minutes_start,
          second: 0,
          microsecond: 0,
          tzinfo: null
        }

        const ow_date_until: OWDate = {
          type: 'SerializableDateTime',
          year: date.getFullYear(),
          month: date.getMonth() + 1,
          day: date.getDate(),
          hour: hours_end,
          minute: minutes_end,
          second: 0,
          microsecond: 0,
          tzinfo: null
        }

        ow_date_ranges.push({
          type: 'JobDateRange',
          begin: ow_date_from,
          end: ow_date_until,
          repeat_frequency: 7,
          repeats: -1
        })
      }

    }

    return ow_date_ranges
  }

  getAdditionalPriorityTimeRuleTimeFactor(config?: {
    use_technician?: PATechnician,
    use_priority_idx?: number
  }): number {
    let additional_time_percent = this.getAdditionalPriorityTimeRuleTimePercent(config)
    return 1 + (additional_time_percent / 100)
  }

  getAdditionalPriorityTimeRuleTimePercent(config?: {
    use_technician?: PATechnician,
    use_priority_idx?: number
  }): number {
    let priority_idx = config?.use_priority_idx || this.getPriorityIdx()
    let additional_priority_time_rule = this.getAdditionalPriorityTimeRule(config)
    return additional_priority_time_rule ? (additional_priority_time_rule.timePredictionFunctionWrapper.getAdditionalValue(priority_idx)) : 0
  }

  getAdditionalPriorityTimeRule(config?: {
    use_technician?: PATechnician,
    use_priority_idx?: number
  }): TimePredictionRule {
    return PASettingsControl.Instance.getAdditionalPriorityTimeRuleForTechnicianAndPriorityIndex(this.ticket.priority, config?.use_technician || this.getTechnician(), config?.use_priority_idx || this.getPriorityIdx())
  }

  getPriorityIdx(): number {
    let tour = this.getTour()
    return tour ? tour.getPriorityOperationCountsBeforeOperation(this) + 1 : null
  }

  getWeekDaysOpeningTime(week_day: WeekDay): DayOpening {
    switch (PATimeControl.Instance.weekDays.indexOf(week_day)) {
      case 0:
        return this.ticket.store_openings.monday || PAOperation.defaultWeekDayStoreOpening
      case 1:
        return this.ticket.store_openings.tuesday || PAOperation.defaultWeekDayStoreOpening
      case 2:
        return this.ticket.store_openings.wednesday || PAOperation.defaultWeekDayStoreOpening
      case 3:
        return this.ticket.store_openings.thursday || PAOperation.defaultWeekDayStoreOpening
      case 4:
        return this.ticket.store_openings.friday || PAOperation.defaultWeekDayStoreOpening
      case 5:
        return this.ticket.store_openings.saturday || PAOperation.defaultWeekEndStoreOpening
      case 6:
        return this.ticket.store_openings.sunday || PAOperation.defaultWeekEndStoreOpening
      default:
        return PAOperation.defaultWeekEndStoreOpening
    }
  }

  slaDateInformation(): string {
    if (this.ticket) {
      return this.ticket.slaDateInformation()
    } else {
      return this.ticket.datesla
    }
  }

  getTimelinePosition(for_day_timestamp: number): {
    left_percent: number,
    right_percent: number,
    middle_percent: number,
    additional_time_percent: number
  } {
    let timeline_start = PATour.timelineStart
    let timeline_end = PATour.timelineEnd
    if (this.operation_date && this.user_ids.length && !PADataControl.Instance.unassignedOperationUserIds.includes(this.user_ids[0])) {

      let timeline_start_ms = Math.floor(timeline_start) * 60 * 60 * 1000
      let timeline_end_ms = Math.ceil(timeline_end) * 60 * 60 * 1000
      let total_mss = timeline_end_ms - timeline_start_ms

      let operation_start_ms = this.calculateOperationDayStartMilliseconds({use_day_timestamp: for_day_timestamp})
      let operation_end_ms = this.calculateOperationDayEndMilliseconds({use_day_timestamp: for_day_timestamp})

      let operation_start_since_day_start_ms = operation_start_ms - timeline_start_ms
      let operation_end_since_day_start_ms = operation_end_ms - timeline_start_ms

      let left_percent = (operation_start_since_day_start_ms / total_mss) * 100
      let right_percent = 100 - (operation_end_since_day_start_ms / total_mss) * 100
      let middle_percent = (left_percent + (100 - right_percent)) / 2

      return {
        left_percent: left_percent,
        right_percent: right_percent,
        middle_percent: middle_percent,
        additional_time_percent: Math.round(this.getAdditionalPriorityTimeRuleTimePercent())
      }
    } else {
      return {left_percent: 100, right_percent: 100, middle_percent: 50, additional_time_percent: 0}
    }

  }

  getDrivingTimelinePosition(driving_duration: number, for_day_timestamp: number, config?: { offset_percent?: number }): {
    left_percent: number,
    right_percent: number
  } {
    let timeline_start = PATour.timelineStart
    let timeline_end = PATour.timelineEnd
    if (this.operation_date && this.user_ids.length && !PADataControl.Instance.unassignedOperationUserIds.includes(this.user_ids[0])) {

      let timeline_start_ms = Math.floor(timeline_start) * 60 * 60 * 1000
      let timeline_end_ms = Math.ceil(timeline_end) * 60 * 60 * 1000
      let total_mss = timeline_end_ms - timeline_start_ms

      let calculated_driving_time = driving_duration

      let driving_end_ms = (this.date_on_site ? Date.parse(this.date_on_site) : Date.parse(this.operation_date)) - for_day_timestamp
      let driving_start_ms = this.date_travel_start ? Date.parse(this.date_travel_start) - for_day_timestamp : (driving_end_ms - calculated_driving_time * 60 * 1000)

      let driving_start_since_day_start_ms = driving_start_ms - timeline_start_ms
      let driving_end_since_day_start_ms = driving_end_ms - timeline_start_ms

      let offset_percent = config?.offset_percent || 0

      let left_percent = (driving_start_since_day_start_ms / total_mss) * 100 + offset_percent
      let right_percent = 100 - (driving_end_since_day_start_ms / total_mss) * 100 - offset_percent

      return {left_percent: left_percent, right_percent: right_percent}
    } else {
      return {left_percent: 100, right_percent: 100}
    }

  }

  travelStartTimestring(): string {
    if (this.date_travel_start) {
      return PATimeControl.Instance.dateToTimestring(new Date(this.date_travel_start), false)
    } else {
      return '00:00'
    }
  }

  onSiteTimestring(): string {
    if (this.date_on_site) {
      return PATimeControl.Instance.dateToTimestring(new Date(this.date_on_site), false)
    } else {
      return '00:00'
    }
  }

  finishedTimestring(): string {
    if (this.date_finished) {
      return PATimeControl.Instance.dateToTimestring(new Date(this.date_finished), false)
    } else {
      return '00:00'
    }
  }

  setStatus(event: Event): void {
    const input = event.target as HTMLInputElement
    const id: string = input.value

    this.setStatusMeta(id)
  }

  setStatusMeta(id: string): void {
    let possible_status = this.getProject().status.filter(status => status.id == Number.parseInt(id))
    if (possible_status.length) {
      this.ticket.status = possible_status[0]
    }
  }

  async saveTicketStatus(): Promise<Status> {
    return new Promise(resolve => {
      let changeData = {'status_id': this.ticket.status.id}
      PATicket.ticketService.updateTicketStatus(this.ticket.id.toString(), changeData).subscribe(
        _ => {
          console.log(`Ticket ${this.ticket.id}: Status aktualisiert`)
          this.getBaseOperation().ticket.status = this.ticket.status
          resolve(this.ticket.status)
        },
        err => {
          console.log(err)
          resolve(null)
        }
      )
    })
  }

  async setOffOperationByMilliseconds(ms: number): Promise<void> {
    if (!this.date_travel_start) {
      let tour_before = this.getTour()
      this.operation_date = PATimeControl.Instance.formatDate(new Date(PATimeControl.Instance.dateStringToTimestamp(this.operation_date, false, false) + ms))
      let tour_after = this.getTour()
      if (tour_before && tour_after) {
        if (tour_before == tour_after) {
          await tour_before.updateOperationSorting(true)
        } else {
          await tour_before.removeOperationWithId(this.id, true)
          await tour_after.insertOperations([this], {wait_for_all_queued_updates: true})
        }
      }
    }
  }

  isAffectedByChangedPriorityCount(cpc: { priority: Priority, start_ts: number }) {
    return !this.date_finished && this.ticket.priority.id == cpc.priority.id && cpc.start_ts < PATimeControl.Instance.dateStringToTimestamp(this.operation_date, false, false)
  }

  hasApplyableRampUpTimeRuleForTechnicianAndPriorityIndex(technician: PATechnician, priority_idx: number): TimePredictionRule {
    return PASettingsControl.Instance.getAdditionalPriorityTimeRuleForTechnicianAndPriorityIndex(this.ticket.priority, technician, priority_idx)
  }

  startsBeforeOperation(operation: PAOperation): boolean {
    if (this.date_travel_start) {
      return !operation.date_travel_start || new Date(this.date_travel_start) < new Date(operation.date_travel_start)
    } else if (operation.date_travel_start) {
      return false
    } else {
      return this.getPlannedTimestamp() < operation.getPlannedTimestamp()
    }
  }

  static setPlanningAssistantService(service: PlanningAssistantService): void {
    this.planningAssistantService = service
  }

  static setTicketService(service: TicketService): void {
    this.ticketService = service
  }

  static setOperationService(service: OperationService): void {
    this.operationService = service
  }

  static openingsToOpeningTimes(openings: Opening[]): OpeningTimes {
    let opening_times: OpeningTimes = {}

    if (openings.length > 0) {
      // case openings from db
      for (let opening of openings) {
        switch (opening.day) {
          case 6 : {
            opening_times.sunday = {
              open: opening.open,
              close: opening.close,
              validity: 'db'
            }
            break
          }
          case 0 : {
            opening_times.monday = {
              open: opening.open,
              close: opening.close,
              validity: 'db'
            }
            break
          }
          case 1 : {
            opening_times.tuesday = {
              open: opening.open,
              close: opening.close,
              validity: 'db'
            }
            break
          }
          case 2 : {
            opening_times.wednesday = {
              open: opening.open,
              close: opening.close,
              validity: 'db'
            }
            break
          }
          case 3 : {
            opening_times.thursday = {
              open: opening.open,
              close: opening.close,
              validity: 'db'
            }
            break
          }
          case 4 : {
            opening_times.friday = {
              open: opening.open,
              close: opening.close,
              validity: 'db'
            }
            break
          }
          case 5 : {
            opening_times.saturday = {
              open: opening.open,
              close: opening.close,
              validity: 'db'
            }
            break
          }
        }
      }
    } else {
      // case default openings
      opening_times.monday = null
      opening_times.tuesday = null
      opening_times.wednesday = null
      opening_times.thursday = null
      opening_times.friday = null
      opening_times.saturday = null
      opening_times.sunday = null
    }

    return opening_times
  }

  static async insertOperationsInTechnicianDateAndStore(operations: PAOperation[], skip_store_updates?: boolean) {
    let used_technician_dates: PATechnicianDate[] = []
    let used_stores: PAStore[] = []
    for (let operation of operations) {
      let technician_date = operation.getTechnicianDate()
      await technician_date.tour.insertOperations([operation], {skip_route_update: true})
      used_technician_dates.push(technician_date)
      used_stores.push(PAStore.insertOperation(operation, true))
    }

    [...new Set(used_technician_dates)].map(td => td.tour.updateRoute())
    if (!skip_store_updates) {
      [...new Set(used_stores)].map(s => s.fireUpdateManually())
    }
  }

  static generateTemporaryOperationId(): number {
    const res = this.nextTemporaryOperationId
    this.nextTemporaryOperationId -= 1
    return res
  }

}

export interface AfterOperationStorageTasks {
  addTicketNote?: TicketNote
}