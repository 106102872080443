import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'

import { environment } from '../../environments/environment'
import { ArticleTemplate } from '../_models'

@Injectable({
  providedIn: 'root'
})
export class ArticleTemplateService {

  constructor(
    private http: HttpClient,
  ) {
  }

  public all(params: any = {}) {
    const path = environment.apiURL + 'article_templates.json'

    return this.http.get<ArticleTemplate[]>(path, { params: params })
  }

  public update(id: string | String | number, params: any) {
    const path = environment.apiURL + 'article_templates/'+ id +'.json'
    return this.http.patch<ArticleTemplate>(path, params)
  }

  public create(params: any) {
    const path = environment.apiURL + 'article_templates.json'
    return this.http.post<ArticleTemplate>(path, params)
  }

  public delete(id: string | String | number) {
    const path = environment.apiURL + 'article_templates/'+ id +'.json'
    return this.http.delete<any>(path)
  }

  public getArticleTemplate(id: number | String | string) {
    const path = environment.apiURL + 'article_templates/' + id + '.json'

    return this.http.get<ArticleTemplate>(path)
  }
}
