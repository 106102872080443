
import { CalendarWeek, CalendarWeekData } from "../../../_models/planning-assistant.interface";
import { PAUtil, WeekDay } from "../classes/util";
import { CalendarComponent } from "../calendar/calendar.component";
import { PlanningAssistantComponent } from "../planning-assistant.component";
import { PAFilterControl } from "./pa-filter-control";
import Holidays, { HolidaysTypes } from "date-holidays";

export class PATimeControl {

  private static _instance: PATimeControl
  public static get Instance()
  {
    return this._instance || (this._instance = new this());
  }

  holidays: Holidays = new Holidays('DE')
  holidayRegion: string[] = ['BB', 'BE', 'BW', 'BY', 'HB', 'HE', 'HH', 'MV', 'NI', 'NW', 'RP', 'SH', 'SL', 'SN', 'ST', 'TH']
  weekDays: WeekDay[] = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday']

  public currentCalendarWeeksData: CalendarWeekData
  public selectedCalendarWeekData: CalendarWeekData
  public selectedCalendarDay: WeekDay = null
  public shownCalendarWeeksData: CalendarWeekData[]
  public currentDate = ''

  private constructor(
  ) {
    this.currentDate = this.calcCurrentDate()
  }

  public initCalendar() {
    this.calcCurrentCalendarWeeksData()
    this.addDefaultCalendarWeeks()
  }

  private calcCurrentCalendarWeeksData(): void {
    let current_date = new Date(Date.parse(this.currentDate))
    let current_day_timestamp = current_date.getTime()
    let current_calendar_week = this.timestampToCalendarWeek(current_day_timestamp)

    this.currentCalendarWeeksData = this.calculateCalendarWeekData(current_calendar_week.number, current_calendar_week.year)
  }

  public addDefaultCalendarWeeks(): void {
    this.shownCalendarWeeksData = this.getCalendarWeekDataRange(this.currentCalendarWeeksData, CalendarComponent.defaultInitializedWeekAmount - 1)
    PlanningAssistantComponent.fromDate = this.timestampToDatestring(this.shownCalendarWeeksData[0].weekdays['monday'].timestamp, true)
    PlanningAssistantComponent.untilDate = this.timestampToDatestring(this.shownCalendarWeeksData[this.shownCalendarWeeksData.length - 1].weekdays['sunday'].timestamp, true)
  }

  getCurrentCalendarWeekDayFilteredTimestamps(): number[] {
    let current_cwd = this.selectedCalendarWeekData || this.shownCalendarWeeksData[0]
    if (current_cwd) {
      return PAFilterControl.Instance.filteredWeekDays.map(week_day => current_cwd.weekdays[week_day].timestamp)
    } else {
      return []
    }
  }

  incrementShownCalendarWeeks(): void {
    this.shiftShownCalendarWeeksByValue(1)
  }

  decrementShownCalendarWeeks(): void {
    this.shiftShownCalendarWeeksByValue(-1)
  }

  shiftShownCalendarWeeksByValue(value: number) {
    let shiftedCalendarWeeks = this.shownCalendarWeeksData.map( cwd => this.getCalendarWeekInNWeeks(value, cwd.weekdays.monday.timestamp))
    this.shownCalendarWeeksData = shiftedCalendarWeeks.map(cw => this.calculateCalendarWeekData(cw.number, cw.year))
  }

  incrementSelectedCalendarWeek(): void {
    this.shiftSelectedCalendarWeekByValue(1)
  }

  decrementSelectedCalendarWeek(): void {
    this.shiftSelectedCalendarWeekByValue(-1)
  }

  shiftSelectedCalendarWeekByValue(value: number) {
    this.shiftShownCalendarWeeksByValue(value)
    let shiftedCalendarWeek = this.getCalendarWeekInNWeeks(value, this.selectedCalendarWeekData.weekdays.monday.timestamp)
    this.selectedCalendarWeekData = this.calculateCalendarWeekData(shiftedCalendarWeek.number, shiftedCalendarWeek.year)
  }

  getCalendarWeekData(calendar_week: CalendarWeek): CalendarWeekData {
    let possible_calendar_week_data = this.shownCalendarWeeksData.filter(calendar_week_data => this.equalCalendarWeeks(calendar_week_data.calendar_week, calendar_week))
    if (possible_calendar_week_data.length == 0) {
      let loaded_calendar_weeks = this.shownCalendarWeeksData.concat([this.calculateCalendarWeekData(calendar_week.number, calendar_week.year)])
      let shown_calendar_weeks_data = loaded_calendar_weeks.sort((cw_1, cw_2) => (
        cw_1.calendar_week.year < cw_2.calendar_week.year || (cw_1.calendar_week.year == cw_2.calendar_week.year && cw_1.calendar_week.number < cw_2.calendar_week.number)
      ) ? -1 : 1)
      return shown_calendar_weeks_data.filter(calendar_week_data => this.equalCalendarWeeks(calendar_week_data.calendar_week, calendar_week))[0]
    } else {
      return this.shownCalendarWeeksData.filter(calendar_week_data => this.equalCalendarWeeks(calendar_week_data.calendar_week, calendar_week))[0]
    }
  }

  equalCalendarWeeks(calendar_week_1: CalendarWeek, calendar_week_2: CalendarWeek) {
    return calendar_week_1.number == calendar_week_2.number && calendar_week_1.year == calendar_week_2.year;
  }

  getTimeStampsCalendarWeekData(timestamp: number): CalendarWeekData {
    return this.getCalendarWeekData(this.timestampToCalendarWeek(timestamp))
  }

  getTimeStampsWeekDay(timestamp: number): WeekDay {
    let date = new Date(timestamp)
    let day_idx = date.getDay()
    if (day_idx == 0) {
      return this.weekDays[6]
    }
    return this.weekDays[day_idx - 1]

  }

  getCurrentCalendarWeek(): CalendarWeek {
    let current_timestamp = Date.parse(this.calcCurrentDate() + 'T00:00:00.000Z')
    return this.timestampToCalendarWeek(current_timestamp)
  }

  getCalendarWeekInNWeeks(n: number, timestamp_base?: number): CalendarWeek {
    return this.getCalendarWeekInNDays(7 * n, timestamp_base)
  }

  getCalendarWeekInNDays(n: number, timestamp_base?: number): CalendarWeek {
    let date = timestamp_base ? new Date(timestamp_base) : new Date(this.calcCurrentDate() + 'T00:00:00.000Z')
    let timestamp_in_n_weeks = date.getTime() + (n * 1000 * 60 * 60 * 24)
    return this.timestampToCalendarWeek(timestamp_in_n_weeks)
  }

  calcCurrentDate(): string {
    let current_timestamp = this.getCurrentTimestamp()
    let date = new Date(current_timestamp)
    date.setHours(0, 0, 0, 0);
    return this.dateToDatestring(date, true, false)
  }

  calculateCalendarWeekData(calendar_week_number: number, calendar_week_year: number): CalendarWeekData {
    let nth_monday_timestamp = this.calendarWeekToTimestamp(calendar_week_year, calendar_week_number)

    let weekdays = {
      'monday': {
        timestamp: 0,
        holidays: []
      },
      'tuesday': {
        timestamp: 0,
        holidays: []
      },
      'wednesday': {
        timestamp: 0,
        holidays: []
      },
      'thursday': {
        timestamp: 0,
        holidays: []
      },
      'friday': {
        timestamp: 0,
        holidays: []
      },
      'saturday': {
        timestamp: 0,
        holidays: []
      },
      'sunday': {
        timestamp: 0,
        holidays: []
      }
    }

    for (let weekday of this.weekDays) {
      let day_offset_to_monday = this.weekDays.indexOf(weekday)
      weekdays[weekday].timestamp = nth_monday_timestamp + (day_offset_to_monday * 24 * 3600 * 1000)
      let holidays = this.getTimestampsHolidays(weekdays[weekday].timestamp)
      if (holidays && holidays.length) {
        for (let holiday of holidays) {
          weekdays[weekday].holidays.push(
            {
              holiday: holiday,
              tooltip_text: this.getHolidayTooltipText(holiday)
            }
          )
        }
      }
    }

    return {
      calendar_week: {
        number: calendar_week_number,
        year: calendar_week_year
      },
      weekdays: weekdays,
      operation_data_was_loaded: false
    }
  }

  getHolidayTooltipText(holiday: HolidaysTypes.Holiday): string {
    let res = holiday.name
    res += ' <br> '
    if (holiday.type === 'public') {
      res += 'Gesetzlicher Feiertag'
    } else {
      res += 'Feiertag'
    }
    return res
  }

  calendarWeekToTimestamp(calendar_week_year: number, calendar_week_number: number): number {
    let first_january = new Date(Date.UTC(calendar_week_year, 0, 1))
    let first_january_day = (first_january.getDay() - 1) % 7
    let day_offset_to_first_monday = -first_january_day
    if (first_january_day > 3) {
      day_offset_to_first_monday += 7
    }
    let first_monday_time_stamp = first_january.getTime() + day_offset_to_first_monday * 24 * 3600 * 1000

    return first_monday_time_stamp + (calendar_week_number - 1) * 7 * 24 * 3600 * 1000
  }

  getCalendarWeekDataRange(calendar_week_data: CalendarWeekData, amount_of_weeks_to_add: number): CalendarWeekData[] {

    let result = [calendar_week_data]

    for (let week_offset of PAUtil.range(1, amount_of_weeks_to_add)) {

      let weekdays = {
        'monday': {
          timestamp: 0,
          holidays: []
        },
        'tuesday': {
          timestamp: 0,
          holidays: []
        },
        'wednesday': {
          timestamp: 0,
          holidays: []
        },
        'thursday': {
          timestamp: 0,
          holidays: []
        },
        'friday': {
          timestamp: 0,
          holidays: []
        },
        'saturday': {
          timestamp: 0,
          holidays: []
        },
        'sunday': {
          timestamp: 0,
          holidays: []
        }
      }

      for (let weekday of this.weekDays) {
        weekdays[weekday].timestamp = calendar_week_data.weekdays[weekday].timestamp + (week_offset * 7 * 24 * 3600000)
        let holidays = this.holidays.isHoliday(this.timestampToDatestring(weekdays[weekday].timestamp, true))
        if (holidays && holidays.length) {
          for (let holiday of holidays) {
            weekdays[weekday].holidays.push(
              {
                holiday: holiday,
                tooltip_text: this.getHolidayTooltipText(holiday)
              }
            )
          }
        }
      }

      let calendar_week = this.timestampToCalendarWeek(weekdays['monday'].timestamp)

      result.push({
        calendar_week: {
          number: calendar_week.number,
          year: calendar_week.year
        },
        weekdays: weekdays,
        operation_data_was_loaded: false
      })
    }

    return result
  }
  dateToDatestring(date: Date, reversed: boolean, utc_time: boolean, connector?: string): string {
    let day: string
    let month: string
    let year: string

    if (typeof connector == 'undefined') {
      connector = '-'
    }

    if (utc_time) {
      day = date.getUTCDate().toString()
      month = (date.getUTCMonth() + 1).toString()
      year = date.getUTCFullYear().toString()
    } else {
      day = date.getDate().toString()
      month = (date.getMonth() + 1).toString()
      year = date.getFullYear().toString()
    }

    if (day.length == 1) {
      day = "0" + day
    }
    if (month.length == 1) {
      month = "0" + month
    }
    let date_string: string
    if (reversed) {
      date_string = year + connector + month + connector + day
    } else {
      date_string = day + connector + month + connector + year
    }
    return date_string
  }

  timestampToDatestring(timestamp: number, reversed: boolean, connector?: string): string {
    return this.dateToDatestring(new Date(timestamp), reversed, false, connector)
  }

  /**
   * Takes a `date` and returns a time string.
   *
   * @param date - The input Date
   * @param get_seconds - Boolean, it returns the seconds additionally to the hours and minutes
   *
   * @returns "Hour:Minute" if get_seconds is False, "Hour:Minute:Second" if get_seconds is True
   *
   */
  dateToTimestring(date: Date, get_seconds: boolean): string {
    let hours = date.getHours().toString()
    let minutes = date.getMinutes().toString()
    let secs: string
    if (hours.length == 1) {
      hours = "0" + hours
    }
    if (minutes.length == 1) {
      minutes = "0" + minutes
    }
    let res = hours + ":" + minutes
    if (get_seconds) {
      secs = date.getSeconds().toString()
      if (secs.length == 1) {
        secs = "0" + secs
        res = res + ":" + secs
      }
    }
    return res
  }

  dateToDateTimeString(date: Date, utc_time: boolean): string {
    return this.dateToDatestring(date, false, utc_time, '.') + ' ' + this.dateToTimestring(date, false) + 'Uhr'
  }

  minutesToTimeString(minutes: number) {
    let hour_string = Math.floor(minutes / 60).toString()
    if (hour_string.length == 1) {
      hour_string = '0' + hour_string
    }
    let minute_string = (minutes % 60).toString()
    if (minute_string.length == 1) {
      minute_string = '0' + minute_string
    }
    return hour_string + ':' + minute_string
  }

  /**
   * returns the Calendar-Week to a specific `time_stamp`
   */
  timestampToCalendarWeek(time_stamp: number): CalendarWeek {
    let date = new Date()
    date.setTime(time_stamp)
    let first_january = new Date(Date.UTC(date.getFullYear(), 0, 1))

    let first_january_day = (first_january.getDay() - 1) % 7

    let day_offset_to_first_monday = -first_january_day
    if (first_january_day > 3) {
      day_offset_to_first_monday += 7
    }

    let first_monday_time_stamp = first_january.getTime() + day_offset_to_first_monday * 24 * 3600 * 1000

    let number = Math.floor(((date.getTime() - first_monday_time_stamp) / 86400000) / 7) + 1

    return {
      number: number,
      year: date.getFullYear()
    }
  }

  /**
   * returns a timestamp to a given `date_string`
   */
  dateStringToTimestamp(date_string: string, set_hours_to_zero: boolean, use_utc_time: boolean): number {
    let date = new Date(Date.parse(date_string))
    return this.dateToTimestamp(date, set_hours_to_zero, use_utc_time)
  }

  dateToTimestamp(date: Date, set_hours_to_zero?: boolean, use_utc_time?: boolean): number {
    if (set_hours_to_zero) {
      if (use_utc_time) {
        date.setUTCHours(0, 0, 0, 0)
      } else {
        date.setHours(0, 0, 0, 0)
      }
    }
    return date.getTime()
  }

  getCurrentTimestamp() {
    return Date.now()
  }

  dateTimeToDayMinutes(date_time: string) {
    let travel_start_date = new Date(Date.parse(date_time))
    if (typeof travel_start_date == 'undefined') {
      console.error("Can not read from Date:" + date_time)
    }
    return this.timeStringToMinutes(this.dateToTimestring(travel_start_date, false))
  }

  /**
   * takes a time string and returns the amount of minutes which have passed since 00:00
   */
  timeStringToMinutes(time: string): number {
    let splitted = time.split(':')
    return 60 * Number.parseInt(splitted[0]) + Number.parseInt(splitted[1])
  }

  formatDate(date: Date, cut_time?: boolean) {
    if (date) {
      let res = date.getFullYear() + '-' +
        ((date.getMonth() + 1).toString().padStart(2, '0')) + '-' +
        date.getDate().toString().padStart(2, '0')
      if (!cut_time) {
        res = res + 'T' +
          date.getHours().toString().padStart(2, '0') + ':' +
          date.getMinutes().toString().padStart(2, '0') + ':00'
      }
      return res
    }

    return
  }

  getTimestampsHolidays(timestamp: number, ignore_regional_holidays?: boolean): HolidaysTypes.Holiday[] {
    const holidays = this.holidays.isHoliday(this.timestampToDatestring(timestamp, true))
    if (!holidays) {
      return []
    } else if (ignore_regional_holidays) {
      return holidays.filter(holiday => holiday.type == 'public')
    } else {
      return holidays
    }
  }

  getTimestampsWeekday(timestamp: number): string {
    const date_day_idx = new Date(timestamp).getDay()
    return date_day_idx != 0 ? this.weekDays[date_day_idx - 1] : 'sunday'
  }

  datesAreOnSameDay(first: Date, second: Date): boolean {
    return first.getFullYear() === second.getFullYear() && first.getMonth() === second.getMonth() && first.getDate() === second.getDate();
  }

  dateOneIsBeforeDateTwo(first: Date, second: Date): boolean {
    return first.getTime() < second.getTime();
  }

  weekDayToGermanName(week_day: WeekDay, short_name: boolean) {
    let ger_weekdays = ['Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag', 'Sonntag']

    let day_index = this.weekDays.indexOf(week_day)

    if (day_index >= 0) {
      let weekday_string = ger_weekdays[day_index]
      if (short_name) {
        weekday_string = weekday_string.slice(0, 2)
      }
      return weekday_string
    }

    return week_day
  }

  getGermanWeekDay(idx: number): string {
    return this.weekDayToGermanName(this.weekDays[idx], false)
  }
}