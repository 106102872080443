import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ArticleTemplate, Material } from 'src/app/_models';
import { TicketService } from 'src/app/_services';
import { faFilter, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { UntypedFormControl, UntypedFormGroup, Validators, ReactiveFormsModule } from '@angular/forms';
import { MaterialContainer } from '../../classes/material-container/material-container.interface';
import { PADataControl } from "../../singletons/pa-data-control";
import { NgIf, NgFor } from '@angular/common';

import { MatGridList, MatGridTile } from '@angular/material/grid-list';
import { MatFormField, MatLabel, MatError } from '@angular/material/form-field';
import { MatSelect } from '@angular/material/select';
import { MatOption } from '@angular/material/core';
import { MatInput } from '@angular/material/input';
import { MatButton } from '@angular/material/button';
import { CommonModule } from "@angular/common";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatSelectModule } from "@angular/material/select";
import { FaIconComponent, FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@Component({
  selector: 'hawk-material-selection',
  templateUrl: './material-selection.component.html',
  styleUrls: ['./material-selection.component.scss', './../../styles/common_styles.scss', './../../../_shared/styles/common-styles.scss'],
  standalone: true,
  imports: [NgIf, NgFor, FaIconComponent, ReactiveFormsModule, MatGridList, MatGridTile, MatFormField, MatLabel, MatSelect, MatOption, MatError, MatInput, MatButton, CommonModule, MatFormFieldModule, MatSelectModule, FontAwesomeModule]
})
export class MaterialSelectionComponent implements OnInit, OnChanges {

  faTrash = faTrash
  faPlus = faPlus

  showAddMaterialDialog = false
  materialSearchString = ''

  @Input() materialContainer: MaterialContainer
  @Input() showHeader: boolean = true
  @Input() forProjectID: number
  @Output() updatedEvent = new EventEmitter<void>()

  public newMaterialForm: UntypedFormGroup = new UntypedFormGroup({
    article_template_id: new UntypedFormControl({value: null}, Validators.required),
    amount: new UntypedFormControl({value: 1}, Validators.min(1)),
    serial_number: new UntypedFormControl({value: ''}),
  });

  projectArticleTemplates: ArticleTemplate[] = []
  filteredArticleTemplates: ArticleTemplate[] = [];
  selectedTemplateFilter: 'all' | 'exclusive_project' | 'inclusive_project' = 'exclusive_project';

  constructor(
    public ticketService: TicketService
  ) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.updateProjectArticles()
  }

  ngOnInit(): void {
    this.resetForm()
  }

  updateProjectArticles(): void {
    if (this.forProjectID && this.selectedTemplateFilter != 'all') {
      this.projectArticleTemplates = PADataControl.Instance.articleTemplates.filter(
        at => {

          if (!at.comment2) {
            return this.selectedTemplateFilter == 'inclusive_project'
          }

          let possible_project_ids = at.comment2.split(',')
          let parsed_ids = possible_project_ids.map(possible_id => Number.parseInt(possible_id))
          if (parsed_ids.every(parsed_id => Number.isInteger(parsed_id))) {
            return (parsed_ids.includes(this.forProjectID))
          } else {
            return false
          }
        }
      )
    } else {
      this.projectArticleTemplates = PADataControl.Instance.articleTemplates
    }

    this.filterArticleTemplatesMeta(this.materialSearchString)
  }

  public async deleteTicketMaterial(material: Material): Promise<void> {
    await this.materialContainer.deleteMaterial(material)
    this.triggerUpdate()
  }

  public addMaterial(): void {
    this.resetForm()
    this.materialSearchString = ''
    this.showAddMaterialDialog = true
  }

  public formatArticleTemplate(articleTemplate: ArticleTemplate): string {
    return [articleTemplate.description, articleTemplate.product_category, articleTemplate.vendor, articleTemplate.id].join(', ')
  }

  public async saveMaterial() {
    let article_template_id = this.newMaterialForm.controls['article_template_id'].value
    let article_template = PADataControl.Instance.articleTemplates.find(at => at.id == article_template_id)
    let new_material: Material = {
      amount: this.newMaterialForm.controls['amount'].value,
      template_id: article_template_id,
      description: '',
      item_nr: '',
      sn: this.newMaterialForm.controls['serial_number'].value,
      status: '',
      title: `${article_template.description} ${article_template.vendor}`,
      date_created: null,
      id: null,
      ticket_id: null,
      version: null
    }
    await this.materialContainer.saveMaterial(new_material)
    this.showAddMaterialDialog = false
    this.triggerUpdate()
  }

  public materialToString(material: Material): string {
    return `${material.amount}x ${material.title}`
  }

  triggerUpdate(): void {
    this.updatedEvent.emit()
  }

  filterArticleTemplates(event: Event): void {
    const input = event.target as HTMLInputElement
    const search_string: string = input.value
    
    this.filterArticleTemplatesMeta(search_string)
  }

  filterArticleTemplatesMeta(search_string: string): void {
    this.materialSearchString = search_string
    this.filteredArticleTemplates = this.projectArticleTemplates.filter(at => `${at.description} ${at.product_category} ${at.vendor} ${at.id}`.toLowerCase().includes(search_string.toLowerCase()))
  }

  resetForm(): void {
    this.newMaterialForm.setValue({
      article_template_id: null,
      amount: 1,
      serial_number: '',
    });
    this.newMaterialForm.markAsUntouched()
  }

  updateSelectedTemplateFilter(event: 'all' | 'exclusive_project' | 'inclusive_project' = 'exclusive_project') {
    this.selectedTemplateFilter = event
    this.updateProjectArticles()
  }

  protected readonly faFilter = faFilter;
}