<div id="operation_changes_container" [ngClass]="externalWindow?.isPoppedOut ? 'popped_out' : ''">
  <div class="changed_technician_dates_container">
    <h6 style="text-align: left;">Geänderte Touren:</h6>
    <div style="position: absolute; right: 0; top: 0;">
      <label for="check"> Auftragsliste nach speichern öffnen
        <input id="check" [(ngModel)]="openDayOperationsAfterSave" type="checkbox">
      </label>
    </div>
    <div class="changed_technician_date" *ngFor="let technician_date_changes of technicianDateOperationChanges">
      <div class="header" (click)="toggleTechnicianDate(technician_date_changes.technician_date)">
        <div style="flex: 1; margin: auto 0; padding: 2px 4px; font-weight: bold;">
          <fa-icon [icon]="selectedTechnicianDates.includes(technician_date_changes.technician_date) ? faArrowDown : faArrowRight"></fa-icon>
          <ng-container *ngIf="!technician_date_changes.technician_date">
            Entfernte Aufträge
          </ng-container>
          <ng-container *ngIf="technician_date_changes.technician_date">
            {{technician_date_changes.technician_date.technician.getFullName()}} - {{technician_date_changes.technician_date.day.date_string}}
          </ng-container>
        </div>
        <div style="padding: 2px 4px;">
          Änderungen: {{technician_date_changes.operation_changes.length}}
        </div>
        <div *ngIf="technician_date_changes.technician_date">
          <button [tooltip]="'Tour in Karte öffnen'" class="black_gradient_background" style="font-size: .7rem; cursor: pointer; height: 100%;" (click)="PATourPlannerControl.Instance.planTechnicianDate(technician_date_changes.technician_date);$event.stopPropagation()">
            <fa-icon [icon]="faMap"></fa-icon>
          </button>
        </div>
        <div *ngIf="technician_date_changes.technician_date && technician_date_changes.technician_date.technician.telephones?.length">
          <a style="text-decoration: none; color: #18b9f8;" [href]="'tel:' + technician_date_changes.technician_date.technician.telephones[0]">
            <button [tooltip]="'Techniker anrufen: ' + technician_date_changes.technician_date.technician.telephones[0]" class="black_gradient_background" style="font-size: .7rem; cursor: pointer; height: 100%;">
              <fa-icon [icon]="faPhone"></fa-icon>
            </button>
          </a>
        </div>
        <div>
          <button [tooltip]="'Änderungen speichern'" class="black_gradient_background" style="font-size: .7rem; cursor: pointer; height: 100%;" (click)="saveTechnicianDateChanges(technician_date_changes, {$event})">
            <fa-icon [icon]="faSave"></fa-icon>
          </button>
        </div>

        <hawk-popup-modal [shown]="showDBChangeModal" (closeEvent)="showDBChangeModal = false">
          <hawk-database-conflicts [DBChanges]="DBChanges"
                                   (saveNewTourEvent)="saveTechnicianDateChanges(technician_date_changes, {skip_check_db_changes: true})"
                                   (closeEvent)="showDBChangeModal = false"
          >
          </hawk-database-conflicts>
        </hawk-popup-modal>
      </div>
      <div class="body" [ngClass]="selectedTechnicianDates.includes(technician_date_changes.technician_date) ? 'shown' : ''">
        <table id="operation_changes_table">
          <thead>
            <th style="width: 60px;" (click)="showTicketId = !showTicketId">
              <div style="padding: 4px 8px; margin: auto; cursor: pointer; background-color: #673ab7; color: white; border: 1px solid white; border-radius: 4px;">
                {{showTicketId ? 'Ti-ID' : 'Op-ID'}}
              </div>
            </th>
            <th style="width: 50px;">
              Uhrzeit
            </th>
            <th style="width: 0">
              Auftrag
            </th>
            <th>
              Status
            </th>
            <th>
              Vorherige Planung
            </th>
            <th style="width: 0"></th>
          </thead>
          <tbody>
            <ng-container *ngFor="let change of technician_date_changes.operation_changes">
              <tr>
                <td class="operation_change_subfield">
                  <a *ngIf="(showTicketId ? change.base_operation.ticket.id : change.base_operation.id) >= 0" href="/a/ticket/{{change.base_operation.ticket.id}}?operation_id={{change.operation_id}}" target="_blank">
                    {{showTicketId ? change.base_operation.ticket.id : change.base_operation.id}}
                  </a>
                  <ng-container *ngIf="(showTicketId ? change.base_operation.ticket.id : change.base_operation.id) < 0">
                    NEU
                  </ng-container>
                </td>
                <td class="operation_change_subfield">{{change.operation_change_map.get(Operation.operationChangePlanningID).new_operation.getTimeString()}}</td>
                <td class="operation_change_subfield" style="cursor: help; text-align: left; white-space: nowrap;" [tooltip]="change.base_operation.description">
                  {{change.base_operation.ticket.client.client_name}} ({{change.base_operation.ticket.address_city}})
                </td>
                <td>
                  <select name="" style="width: 100%; height: 25px;" (change)="change.operation_change_map.get(Operation.operationChangePlanningID).new_operation.setStatus($event)">
                    <option
                      [value]="status.id"
                      *ngFor="let status of PADataControl.Instance.getProject(change.operation_change_map.get(Operation.operationChangePlanningID).new_operation.ticket.project.id).status"
                      [selected]="status.id == change.operation_change_map.get(Operation.operationChangePlanningID).new_operation.ticket.status.id"
                    >
                      {{status.name}}
                    </option>
                  </select>
                </td>
                <td class="operation_change_subfield" style="cursor: help;" [tooltip]="change.base_operation.plannedTechnicianDateTime()">{{change.base_operation.plannedTechnicianDateTime(true)}}</td>
                <td style="padding: 0; height: 28px; display: inline-flex; flex-direction: row; width: fit-content;">
                  <button style="font-size: .7rem; height: 100%; transition: background-color .4s ease-in;" [ngClass]="showTicketNotesForChanges == change ? 'blue_background' : 'black_gradient_background'"  [tooltip]="'Logbuch eintrag'"  (click)="toggleOperationChange(change)"><fa-icon [icon]="faBookOpen"></fa-icon></button>
                  <button style="font-size: .7rem; height: 100%;" class="black_gradient_background" [tooltip]="'Änderung rückgängig machen'"  (click)="revertOperationChange(change)"><fa-icon [icon]="faTrash"></fa-icon></button>
                </td>
              </tr>
              <tr class="no_border" style="color: white; transition: background-color .4s ease-in;" [style.background-color]="showTicketNotesForChanges == change ? 'rgb(0 86 121)' : 'black'">
                <td colspan="6">
                  <div class="ticket_note" [ngClass]="showTicketNotesForChanges == change ? 'shown' : ''">
                    <div *ngIf="!change.operation_change_map.get(Operation.operationChangePlanningID).new_operation.afterStorageTaskTicketNote"
                    class="vertical_flex"
                    style="padding: 2px 4px; width: 100%; text-align: left;">
                      <h6>
                        Logbuch-Eintrag hinzufügen
                      </h6>
                      <div>
                        <textarea [(ngModel)]="ticketNoteText" placeholder="Informationen/Hinweise..." style="width:100%"></textarea>
                      </div>
                      <div class="horizontal_flex">
                        <div style="padding: 2px 4px;">
                          Quelle:
                        </div>
                        <select style="margin-left: 4px; margin-right: 4px;" name="" (change)="updateTicketNoteSourceType($event)">
                          <option [selected]="ticketNoteSourceType == 'email'" value="email">Email</option>
                          <option [selected]="ticketNoteSourceType == 'phone'" value="phone">Telefon</option>
                          <option [selected]="ticketNoteSourceType == 'qmjob'" value="qmjob">QM-Aufgabe</option>
                          <option [selected]="ticketNoteSourceType == 'system'" value="system">System</option>
                        </select>
                        <input type="text" placeholder="Telefonnummer/E-Mail" [(ngModel)]="ticketNoteSourceText">
                      </div>
                      <div class="horizontal_flex">
                        <div style="padding: 2px 4px; margin: auto;">
                          Teilen mit:
                        </div>
                        <div class="horizontal_flex" style="flex: 1; margin: auto; flex-wrap: wrap;">
                          <div style="white-space: nowrap; padding: 2px 4px;">
                            <input type="checkbox" [(ngModel)]="ticketNoteShareInternal"> Interne Informationen
                          </div>
                          <div style="white-space: nowrap; padding: 2px 4px;">
                            <input type="checkbox" [(ngModel)]="ticketNoteShareTechnician"> Techniker
                          </div>
                          <div style="white-space: nowrap; padding: 2px 4px;">
                            <input type="checkbox" [(ngModel)]="ticketNoteSharePartner"> Partner
                          </div>
                          <div style="white-space: nowrap; padding: 2px 4px;">
                            <input type="checkbox" [(ngModel)]="ticketNoteShareCustomer"> Kunde
                          </div>
                          <div style="white-space: nowrap; padding: 2px 4px;">
                            <input type="checkbox" [(ngModel)]="ticketNoteShareBookkeeper"> Buchhaltung (Sonderk.)
                          </div>
                        </div>
                      </div>
                      <div class="horizontal_flex center_content">
                        <div>
                          <button
                            (click)="addCurrentTicketNoteToOperation(change.operation_change_map.get(Operation.operationChangePlanningID).new_operation)"
                            class="black_gradient_background"
                            style="padding: 4px 8px; border-radius: 2px; height: 35px;"
                            [disabled]="!ticketNoteText"
                          >speichern</button>
                        </div>
                      </div>
                    </div>
                    <div *ngIf="change.operation_change_map.get(Operation.operationChangePlanningID).new_operation.afterStorageTaskTicketNote">
                      <div style="padding: 2px 4px; position: relative; border: 1px solid black; text-align: left;" *ngFor="let note of [change.operation_change_map.get(Operation.operationChangePlanningID).new_operation.afterStorageTaskTicketNote]">
                        <h6>
                          Logbuch-Eintrag
                        </h6>
                        <div class="noteRow" style="padding-right: 10px;">
                          <section>{{note.note}}</section>
                        </div>
                        <hr style="margin: 2px 4px; border-color: gray;">
                        <div class="noteRow">
                          <section class="noteType">
                            <div class="noteRow">
                              <section [className]="nodeClass(note.share_internal)">Intern</section>
                              <section [className]="nodeClass(note.share_partner)">Partner</section>
                              <section [className]="nodeClass(note.share_technician)">Techniker</section>
                              <section [className]="nodeClass(note.share_customer)">Kunde</section>
                              <section [className]="nodeClass(note.share_bookkeeper)">Buchhaltung (Sonderk.)</section>
                            </div>
                          </section>
                        </div>
                        <div style="position: absolute; top: 0; right: 2px; cursor: pointer;" (click)="deleteTicketNoteFromOperation(change.operation_change_map.get(Operation.operationChangePlanningID).new_operation)">
                          <fa-icon [icon]="faTrash"></fa-icon>
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            </ng-container>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <div *ngIf="operationChangeSaveProgress" style="position: absolute; top: 0; bottom: 0; left: 0; right: 0; ">
    <hawk-throbber
            [description]="'Änderungen speichern...'">
    </hawk-throbber>
  </div>
</div>
