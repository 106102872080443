<form [formGroup]="addressForm" class="normal-form common_style" style="width: 100%;" (submit)="onSubmit()">
  <div class="flex_col" style="width: 100%;">
    <div style="width: 100%; margin-bottom: 0;" class="flex_row">
      <h2 style="margin-bottom: 0!important">Adresse</h2>
      <div style="margin: auto 8px auto auto;" id="address_geocoder"></div>
    </div>
    <hr>
    <div class="input_list label_20">
      <div class="input_row">
        <div class="input_label">
          Firma
        </div>
        <div class="input_container">
          <mat-form-field class="outline-small no-subscript" appearance="outline">
            <input matInput formControlName="company">
          </mat-form-field>
        </div>
      </div>
      <div *ngIf="editName" class="input_row">
        <div class="input_label">
          Name
        </div>
        <div class="input_container">
          <mat-form-field class="outline-small no-subscript" appearance="outline" style="margin-right: 12px;">
            <input matInput formControlName="firstname" placeholder="Vorname">
          </mat-form-field>
          <mat-form-field class="outline-small no-subscript" appearance="outline">
            <input matInput formControlName="lastname" placeholder="Nachname">
          </mat-form-field>
        </div>
      </div>
      <div class="input_row">
        <div class="input_label">
          Straße
        </div>
        <div class="input_container">
          <mat-form-field class="outline-small no-subscript" appearance="outline">
            <input matInput formControlName="street">
          </mat-form-field>
        </div>
      </div>
      <div class="input_row">
        <div class="input_label">
          Ort
        </div>
        <div class="input_container">
          <mat-form-field class="outline-small no-subscript" appearance="outline" style="max-width: 33%; margin-right: 12px;">
            <input matInput formControlName="zip" placeholder="PLZ">
          </mat-form-field>
          <mat-form-field class="outline-small no-subscript" appearance="outline">
            <input matInput formControlName="city">
          </mat-form-field>
        </div>
      </div>
      <div class="input_row">
        <div class="input_label">
          Land
        </div>
        <div class="input_container">
          <mat-form-field class="outline-small no-subscript" appearance="outline">
            <input matInput formControlName="country">
          </mat-form-field>
        </div>
      </div>
      <div class="input_row">
        <div class="input_label">
          Staat
        </div>
        <div class="input_container">
          <mat-form-field class="outline-small no-subscript" appearance="outline">
            <input matInput formControlName="state">
          </mat-form-field>
        </div>
      </div>
    </div>
    <div style="flex-wrap: wrap;" class="flex_row footer_row">
      <button mat-raised-button color="primary" class="primary_bg_color_button" [disabled]="!addressForm.valid">
        Adresse speichern
      </button>
      <button type="button" mat-button (click)="onAbortEvent.emit()">
        Abbrechen
      </button>
    </div>
  </div>
</form>
