import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  ViewChild,
  ViewChildren
} from '@angular/core';
import { DBOperationChange, PATechnicianDate } from '../../classes/technician-date';
import { PAOperation } from '../../classes/operation';
import { PAUtil } from '../../classes/util';
import { PAProject } from '../../classes/project';
import {
  faCheck,
  faLink, faLock, faLockOpen,
  faUnlink,
  faWindowClose,
} from '@fortawesome/free-solid-svg-icons';
import { PAStore } from '../../classes/store';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatMenu } from '@angular/material/menu';
import { PAPlanningInstructions } from '../../classes/planning-instructions';
import { Subscription } from 'rxjs';
import { Priority } from "../../../../_models";
import { PopoutWindowComponent } from "../../reuseable-components/popout-window/popout-window.component";
import { MapContainer } from "../../map/pa-map";
import { PADataControl } from "../../singletons/pa-data-control";
import { PATimeControl } from "../../singletons/pa-time-control";
import { PAFilterControl } from "../../singletons/pa-filter-control";
import { PASettingsControl } from "../../singletons/pa-settings-control";
import { MapBoxService } from "../../../../_services/mapbox.service";
import { PAStoreControl } from "../../singletons/pa-store-control";
import { PATourPlannerControl } from "../../singletons/pa-tourplanner-control";
import { TechnicianDateCollection } from "../../classes/technician-date-collection";
import { ListsSimpleChange } from "../../map/map.component";
import { NgIf, NgFor } from '@angular/common';

import { TooltipModule } from '@cloudfactorydk/ng2-tooltip-directive';
import { TimelineComponent } from './timeline/timeline.component';
import { MatButton } from '@angular/material/button';
import { PopupModalComponent } from '../../reuseable-components/popup-modal/popup-modal.component';
import { DatabaseConflictsComponent } from '../../reuseable-components/database-conflicts/database-conflicts.component';
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { FaIconComponent, FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@Component({
  selector: 'hawk-tour-timeline',
  templateUrl: './tour-timeline.component.html',
  styleUrls: ['./tour-timeline.component.scss', './../../styles/common_styles.scss'],
  standalone: true,
  imports: [NgIf, FaIconComponent, TooltipModule, NgFor, TimelineComponent, MatButton, PopupModalComponent, DatabaseConflictsComponent, CommonModule, FormsModule, FontAwesomeModule]
})
export class TourTimelineComponent implements OnInit, OnChanges, OnDestroy {

  @Input() technicianDates: PATechnicianDate[]
  @Input() technicianDateCollection: TechnicianDateCollection
  @Input() insertOperations: PAOperation[]
  @Input() timeFilter: string = ''
  @Input() showHeader: boolean = true

  @Input() enableSave: boolean = false
  @Input() externalWindow: PopoutWindowComponent
  @Input() mapContainer: MapContainer

  @Input() planningInstructions: PAPlanningInstructions

  @Output() tourSavedEvent = new EventEmitter<PATechnicianDate[]>()

  @ViewChildren(MatMenuTrigger) operationMenuTrigger: QueryList<MatMenuTrigger>;
  @ViewChild('menu') currentMatMenu: MatMenu

  faCheck = faCheck
  faLink = faLink
  faUnlink = faUnlink

  chainOperations: boolean = true

  opt: 'time' | 'distance' = 'time'

  Project = PAProject
  Util = PAUtil
  Math = Math
  Number = Number

  ignoreWarning = false

  planningInstructionSubscription: Subscription
  priorityCountsChangedSubscription: Subscription
  priorityRuleChangedSubscription: Subscription
  DBChanges: DBOperationChange[] = [];
  showDBChangeModal = false

  constructor(
    public mapBoxLoadingService: MapBoxService
  ) {
  }

  ngOnDestroy(): void {
    if (this.planningInstructionSubscription) {
      this.planningInstructionSubscription.unsubscribe()
      this.priorityCountsChangedSubscription.unsubscribe()
      this.priorityRuleChangedSubscription.unsubscribe()
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    let pi_changes = changes['planningInstructions']

    if (pi_changes && pi_changes.currentValue) {
      if (this.planningInstructionSubscription) this.planningInstructionSubscription.unsubscribe()
      let planning_instructions = pi_changes.currentValue as PAPlanningInstructions
      this.planningInstructionSubscription = planning_instructions.hasChangedSubject.subscribe(
        ctts => {
          if (ctts == 'all') {
            this.technicianDates.map(td => td.updateNewTourOperations({insert_operations: this.getConstrainedInsertOperationsForTechnicianDate(td)}));
          } else {
            const affected_technician_dates = this.technicianDates.filter(td => ctts.find(ctt => ctt.changed_technician == td.technician && (ctt.changed_timestamps == 'all' || ctt.changed_timestamps.includes(td.day.utc_timestamp))))
            affected_technician_dates.map(td => td.updateNewTourOperations({insert_operations: this.getConstrainedInsertOperationsForTechnicianDate(td)}))
          }
        }
      )
    }

    this.updateComponent(changes)
  }

  ngOnInit(): void {
    this.priorityCountsChangedSubscription = this.technicianDateCollection.technician.currentTourPlannings.priorityCountsChangedSubject.subscribe(
      changed_priority_counts => {
        for (let technician_date of this.technicianDates) {
          if (this.changedPriorityCountsAffectsTechnicianDate(changed_priority_counts, technician_date)) {
            technician_date.updateNewTourOperations({insert_operations: this.getConstrainedInsertOperationsForTechnicianDate(technician_date), force_new_tour: true})
          }
        }
      }
    )
    this.priorityRuleChangedSubscription = PASettingsControl.Instance.priorityRuleChangedSubject.subscribe(
      priority => {
        for (let technician_date of this.technicianDates) {
          if (this.changedPriorityRuleAffectsTechnicianDate(priority, technician_date)) {
            technician_date.updateNewTourOperations({insert_operations: this.getConstrainedInsertOperationsForTechnicianDate(technician_date), force_new_tour: true})
          }
        }
      }
    )
  }

  changedPriorityCountsAffectsTechnicianDate(cp_counts: { priority: Priority, start_ts: number }[], technician_date: PATechnicianDate): boolean {
    return !!(cp_counts.find(cpc => technician_date.changedTourContainer.tour.operations.find(op => op.isAffectedByChangedPriorityCount(cpc))))
  }

  changedPriorityRuleAffectsTechnicianDate(priority: Priority, technician_date: PATechnicianDate): boolean {
    return !!(technician_date.changedTourContainer.tour.operations.find(op => op.ticket.priority.id ?? priority.id))
  }

  async updateComponent(changes: SimpleChanges): Promise<void> {

    let insert_operation_changes = changes['insertOperations']
    let time_filter_changes = changes['timeFilter']
    let technician_dates_changes = new ListsSimpleChange<PATechnicianDate>(changes['technicianDates'])

    for (let technician_date of this.technicianDates) {
      let technician_date_insert_operations_changed = (insert_operation_changes && !PAUtil.equalSets(new Set(technician_date.getConstrainedOperations(insert_operation_changes.previousValue || [], this.planningInstructions)), new Set(technician_date.getConstrainedOperations(insert_operation_changes.currentValue || [], this.planningInstructions))))
      if (technician_date_insert_operations_changed) {
        technician_date.newTourOperationChanges = {
          insert_operations: this.getConstrainedInsertOperationsForTechnicianDate(technician_date),
          delete_operations: technician_date.newTourOperationChanges.delete_operations,
          force_new_tour: technician_date.newTourOperationChanges.force_new_tour
        }
      }

      const technician_date_is_new = !technician_dates_changes.previousValue?.includes(technician_date) && technician_dates_changes.currentValue?.includes(technician_date)
      if (time_filter_changes || technician_date_is_new) {
        let force_update = !!(time_filter_changes)
        technician_date.updateNewTourOperations({insert_operations: this.getConstrainedInsertOperationsForTechnicianDate(technician_date), force_new_tour: force_update})
      }
    }

    /*let select_timeline_tour = false

    if (this.technicianDate.changedTourContainer.selected && insert_operations_changed) {
      select_timeline_tour = true
    }

    if (select_timeline_tour) {
      this.technicianDate.selectTimelineTourForMap(true)
    }*/
  }

  selectOldTour(): void {
    /*if (this.technicianDate.changedTourContainer.selected) {
      this.technicianDate.changedTourContainer.selected = false
      this.technicianDate.fireUpdateManually()
      this.technicianDate.selectTimelineTourForMap()
    }*/
  }

  selectNewTour(): void {
    /*if (!this.technicianDate.changedTourContainer.selected) {
      this.technicianDate.changedTourContainer.selected = true
      this.technicianDate.fireUpdateManually()
      this.technicianDate.selectTimelineTourForMap()
    }*/
  }

  getSLAWarnings(technician_date: PATechnicianDate): string[] {
    return this.getConstrainedInsertOperationsForTechnicianDate(technician_date)
      .filter(operation => operation.ticket.datesla && !this.slaDateIsAfterTechnicianDate(operation.ticket.datesla, technician_date))
      .map(operation => `${operation.ticket.address_company}: SLA ${PATimeControl.Instance.dateToDatestring(new Date(operation.ticket.datesla), false, false, '.')} überschritten`)
  }

  getConstrainedInsertOperationsForTechnicianDate(technician_date: PATechnicianDate): PAOperation[] {
    return technician_date.getConstrainedOperations(this.insertOperations, this.planningInstructions)
  }

  public slaDateIsAfterTechnicianDate(sla_date: string, technician_date: PATechnicianDate): boolean {
    return PATimeControl.Instance.dateOneIsBeforeDateTwo(new Date(technician_date.day.utc_timestamp), (new Date(sla_date)))
  }

  atLeastOnTourHasChanged(): boolean {
    for (let technician_date of this.technicianDates) {
      if (this.tourHasChanged(technician_date)) {
        return true
      }
    }
    return false
  }

  public tourHasChanged(technician_date: PATechnicianDate): boolean {
    return !technician_date.tour.hasEqualRoute(technician_date.changedTourContainer.tour.operations, true)
  }

  async saveNewTours(skip_check_db_changes?: boolean): Promise<void> {
    if (!skip_check_db_changes) {
      let db_changes: DBOperationChange[] = []
      for (let technician_date of this.technicianDates) {
        db_changes = db_changes.concat(await technician_date.checkNewTourForDBChanges())
      }
      this.DBChanges = db_changes
    } else {
      this.DBChanges = []
    }

    if (this.DBChanges.length > 0) {
      this.showDBChangeModal = true
    } else {
      let changed_technician_dates: PATechnicianDate[] = []
      let changed_stores: PAStore[] = []
      for (let technician_date of this.technicianDates) {
        if (this.tourHasChanged(technician_date)) {
          let delete_operations = technician_date.tour.operations.filter(old_tour_op => !technician_date.changedTourContainer.tour.operations.find(new_tour_op => old_tour_op.id == new_tour_op.id))

          let changed_operations = (technician_date.changedTourContainer.tour.operations || [])
          let changed_data = await Promise.all(changed_operations.map(
            async op => {
              let base_op = PADataControl.Instance.getOperation(op.id, null)
              base_op.initOperationChange('manual', {
                new_date: op.operation_date ? new Date(op.operation_date) : null,
                new_uid: op.user_ids[0]
              })
              return await base_op.applyOperationChange('manual', true)
            }
          ))

          let deleted_data = await Promise.all(delete_operations.map(
            async op => {
              let base_op = PADataControl.Instance.getOperation(op.id, null)

              if (base_op.isUnassigned()) {
                return await base_op.revertOperationChange('manual')
              } else {
                base_op.initOperationChange('manual', {
                  new_date: null,
                  new_uid: 283
                })
                return await base_op.applyOperationChange('manual', true)
              }
            }
          ))

          changed_data = changed_data.concat(deleted_data)

          changed_technician_dates = changed_technician_dates.concat([...new Set(changed_data.reduce((tds: PATechnicianDate[], data) => tds.concat(data.changed_technician_dates), []))])
          changed_stores = changed_stores.concat([...new Set(changed_data.reduce((stores: PAStore[], data) => stores.concat(data.changed_stores), []))])

          technician_date.newTourOperationChanges = {}
          technician_date.changedTourContainer.unfittingOperations = []
          technician_date.changedTourContainer.deletingOperations = []
        }
      }

      changed_technician_dates.map(td => td.tour.updateRoute())
      changed_stores.map(store => store.fireUpdateManually())

      PAFilterControl.Instance.updateUnassignedOperations()
      this.tourSavedEvent.emit(this.technicianDates)
      PATourPlannerControl.Instance.lastOperationChangeTimeStamp = Date.now()
    }
  }

  protected readonly event = event;
  protected readonly Operation = PAOperation;
  protected readonly faWindowClose = faWindowClose;
  protected readonly faLock = faLock;
  protected readonly faLockOpen = faLockOpen;
  protected readonly PAStore = PAStore;
  protected readonly PAStoreControl = PAStoreControl;
  protected readonly PATimeControl = PATimeControl;
}

export interface InsertionConfig {
  tour_index: number, // 0...n -> selected idx, -1 -> optimized idx
  removable: boolean
}