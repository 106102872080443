<hawk-record-navigation class="common_style" [breadcrumbLinkList]="[{description: 'Kunden', link: '/a/kunde'}]" [root]="'customer'" [leafDescription]="'Neuer Kunde'">
  <form [formGroup]="customerForm" class="normal-form" (submit)="onSubmit()">
    <div style="max-width: 800px;">
      <section style="width: 100%;">
        <h2>Stammdaten</h2>
        <div class="input_list label_20">
          <div class="input_row">
            <div class="input_label">
              Abkürzung*
            </div>
            <div style="flex: 1;" class="flex_col">
              <div class="input_container">
                <mat-form-field class="outline-small no-subscript" appearance="outline">
                  <input style="text-transform:uppercase" maxlength="3" matInput formControlName="letter">
                </mat-form-field>
              </div>
              <div class="input_description">
                Artikel des Kunden erhalten eine RMA mit dieser Abkürzung. Verwenden Sie maximal drei Buchstaben.
              </div>
            </div>
          </div>
          <div class="input_row">
            <div class="input_label">
              Name*
            </div>
            <div class="input_container">
              <mat-form-field class="outline-small no-subscript" appearance="outline">
                <input matInput formControlName="name">
              </mat-form-field>
            </div>
          </div>
          <div class="input_row">
            <div class="input_label">
              URL
            </div>
            <div class="input_container">
              <mat-form-field class="outline-small no-subscript" appearance="outline">
                <input matInput formControlName="url">
              </mat-form-field>
            </div>
          </div>
          <div class="input_row">
            <div class="input_label">
              XML-ID
            </div>
            <div style="flex: 1;" class="flex_col">
              <div class="input_container">
                <mat-form-field class="outline-small no-subscript" appearance="outline">
                  <input matInput formControlName="xml_id">
                </mat-form-field>
              </div>
              <div class="input_description">
                Für den XML-Export von Tickets
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="flex_col" style="width: 100%;">
        <h2 style="margin-bottom: 0!important">Adresse</h2>
        <div style="margin: 8px 8px 16px auto;" id="address_geocoder"></div>
        <div class="input_list label_20">
          <div class="input_row">
            <div class="input_label">
              Firma
            </div>
            <div class="input_container">
              <mat-form-field class="outline-small no-subscript" appearance="outline">
                <input matInput formControlName="company">
              </mat-form-field>
            </div>
          </div>
          <div class="input_row">
            <div class="input_label">
              Straße
            </div>
            <div class="input_container">
              <mat-form-field class="outline-small no-subscript" appearance="outline">
                <input matInput formControlName="street">
              </mat-form-field>
            </div>
          </div>
          <div class="input_row">
            <div class="input_label">
              Ort
            </div>
            <div class="input_container">
              <mat-form-field class="outline-small no-subscript" appearance="outline" style="max-width: 33%; margin-right: 12px;">
                <input matInput formControlName="zip" placeholder="PLZ">
              </mat-form-field>
              <mat-form-field class="outline-small no-subscript" appearance="outline">
                <input matInput formControlName="city">
              </mat-form-field>
            </div>
          </div>
          <div class="input_row">
            <div class="input_label">
              Land
            </div>
            <div class="input_container">
              <mat-form-field class="outline-small no-subscript" appearance="outline">
                <input matInput formControlName="country">
              </mat-form-field>
            </div>
          </div>
          <div class="input_row">
            <div class="input_label">
              Staat
            </div>
            <div class="input_container">
              <mat-form-field class="outline-small no-subscript" appearance="outline">
                <input matInput formControlName="state">
              </mat-form-field>
            </div>
          </div>
        </div>
      </section>
    </div>

    <div style="flex-wrap: wrap;" class="flex_row footer_row">
      <button mat-raised-button color="primary" class="primary_bg_color_button" [disabled]="!customerForm.valid">
        Kunden erstellen
      </button>
      <a [routerLink]="'/kunde'">
        <button type="button" mat-button>
          Abbrechen
        </button>
      </a>
    </div>
  </form>
</hawk-record-navigation>
