import { PAUtil } from "./util"

export class PADistance {

  static realPositionDistances = new Map<string, { distance: number, duration: number }>()
  static positionDistancesAsTheCrowFlies = new Map<string, number>()

  static coordinatesToDistanceKey(from_lat: number, from_long: number, to_lat: number, to_long: number): string {
    return from_lat.toString() + "," + from_long.toString() + "," + to_lat.toString() + "," + to_long.toString()
  }

  static getDistanceToCoordinatesAsTheCrowFlies(from_lat: number, from_lon: number, to_lat: number, to_lon: number): number {
    let coordinates_key = this.coordinatesToDistanceKey(from_lat, from_lon, to_lat, to_lon)
    if (this.positionDistancesAsTheCrowFlies.has(coordinates_key)) {
      return this.positionDistancesAsTheCrowFlies.get(coordinates_key)
    } else {
      let distance = PAUtil.calcDistanceAsTheCrowFlies(from_lat, from_lon, to_lat, to_lon)
      let inverse_coordinates_key = this.coordinatesToDistanceKey(to_lat, to_lon, from_lat, from_lon)
      this.positionDistancesAsTheCrowFlies.set(coordinates_key, distance)
      this.positionDistancesAsTheCrowFlies.set(inverse_coordinates_key, distance)
      return distance
    }
  }
}