import { Injectable } from '@angular/core'
import { HttpClient, HttpParams } from '@angular/common/http'

import { environment } from '../../environments/environment'
import { ArticleHandling, EditEventType, Operation, OperationFile, Part } from '../_models'
import { TicketJobResponse } from '../_models/ticket-job-response.model'
import {Observable} from "rxjs";
import { OperationCoordinates } from "../_models/operation.interface";

@Injectable({
  providedIn: 'root'
})
export class OperationService {

  constructor(private http: HttpClient) { }

  public dangerousDelete(id: number) {
    const path = environment.apiURL + 'operations/' + id + '/dangerous_delete.json'

    return this.http.delete<Operation>(path)
  }

  public getParts(id: string) {
    const path = environment.apiURL + 'operations/' + id + '/parts.json'

    return this.http.get<Part[]>(path)
  }

  public createPart(part: Part) {
    const path = environment.apiURL + 'operation_parts.json'

    return this.http.post<Part>(path, part)
  }

  public saveUploadedFile(id: number, files: FileList) {
    const path = environment.apiURL + 'operations/' + id + '/upload_file'

    const file: File = files[0];

    if (file) {
      const formData = new FormData()
      formData.append("file", file)
      for (const file of files) {
        formData.append('files[]', file, file.name)
      }

      return this.http.post(path, formData)
    }
  }

  public getFiles(id: number) {
    const path = environment.apiURL + 'operations/' + id + '/files.json'

    return this.http.get<OperationFile[]>(path)
  }

  public destroyFile(id: number, filename: string) {
    const path = environment.apiURL + 'operations/' + id + '/file/' + filename + '/delete.json'

    return this.http.delete(path)
  }

  public renameFile(operationId: number, oldFilename: string, newFilename: EditEventType) {
    const path = environment.apiURL + 'operations/' + operationId + '/rename_file.json'

    const params = {
      filename: oldFilename,
      new_filename: newFilename
    }

    return this.http.post(path, params)
  }

  public destroyPart(part: Part) {
    const path = environment.apiURL + 'operation_parts/' + part.id + '.json'

    return this.http.delete<Part>(path)
  }

  public savePart(part: Part) {
    const path = environment.apiURL + 'operation_parts/' + part.id + '.json'

    return this.http.patch<Part>(path, { operation_part: part })
  }

  public markStepsDone(operationId: number) {
    const path = environment.apiURL + 'operation_metadata/' + operationId + '/mark_done.json'

    return this.http.post(path, {})
  }

  public getArticleHandling(id: string) {
    const path = environment.apiURL + 'operations/' + id + '/article_handling.json'

    return this.http.get<ArticleHandling[]>(path)
  }

  public getOperationJobs(id: string) {
    const path = environment.apiURL + 'operations/' + id + '/jobs.json'
    return this.http.get<TicketJobResponse>(path)
  }

  public updateOperation(id: string, data: any) {
    const path = environment.apiURL + 'operations/' + id + '.json'

    return this.http.patch<Operation>(path, data)
  }

  public getOperationDistanceToCoordinates(op_ids: number[], lat: number, long: number) {

    let params = new HttpParams()
    op_ids.forEach(id => {
      params = params.append('ids[]', id.toString())
    })

    params = params.append('lat', lat.toString())
    params = params.append('long', long.toString())

    const path = environment.apiURL + 'operations/distance.json'

    return this.http.get<{ id: number, distance: number, duration: number }[]>(path, { params })
  }

  public getDistanceBetweenCoordinates(lat_from: number, lon_from: number, lat_to: number, lon_to: number) {

    let params = new HttpParams()

    params = params.append('from_lat', lat_from.toString())
    params = params.append('from_lon', lon_from.toString())

    params = params.append('to_lat', lat_to.toString())
    params = params.append('to_lon', lon_to.toString())

    const path = environment.apiURL + 'coordinates_distance.json'

    return this.http.get<{ distance: number, duration: number, valid: boolean }>(path, { params })
  }

  public loadOperationCoordinates(constraints?: { project_id?: number, only_unassigned?: boolean, exclude_material_return?: boolean }): Observable<OperationCoordinates[]> {
    let path = environment.apiURL + `operations_coordinates`

    let params = new HttpParams()
    if (constraints?.project_id) params = params.append('project_id', constraints.project_id)
    if (constraints?.only_unassigned) params = params.append('only_unassigned', constraints.only_unassigned)
    if (constraints?.exclude_material_return) params = params.append('exclude_material_return', constraints.exclude_material_return)

    return this.http.get<OperationCoordinates[]>(path, {params})
  }
}
