<div style="display: inline-flex; flex-direction: column; height: 100%; flex: 1; user-select: none;" >
  <div class="black_gradient_background_no_hover" style="border: 1px solid black; font-size: .8rem; color: white; display: inline-flex; position: relative; flex-direction: column; flex:1">

    <div [id]="'tour_map_window_' + id" class="tour_map_window map_window" [ngClass]="PASettingsControl.Instance.selectedSettingConfig.data.general.map.show_operation_times ? 'show_time_info' : ''" style="flex: 1;">
      <div #select_multiple_stores_container class="select_multiple_stores_container" style="display: none">
        <fa-icon style="position: absolute; top: 0; left: 4px; color: green" [icon]="faPlusCircle"></fa-icon>
      </div>
    </div>

    <ng-container *ngIf="shownTechnicianDates && PASettingsControl.Instance.selectedSettingConfig.data.general.map.seperated_tour_view">

      <div style="position: relative; width: 100%;">
        <hawk-tour-timeline style="flex: 1;"
        [technicianDateCollection]="technicianDateCollection"
        [technicianDates]="shownTechnicianDates"
        [showHeader]="false"
        [insertOperations]="PATourPlannerControl.Instance.tourPlannerInsertOperations"
        [timeFilter]="PAFilterControl.Instance.selectedOperationTimeFilter"
        [mapContainer]="mapContainer"
        [enableSave]="true"
        [planningInstructions]="planningInstructions"
        (tourSavedEvent)="afterTourSave()"
        >
        </hawk-tour-timeline>
      </div>
    </ng-container>
  </div>
</div>