
import { MapBoxService } from "../../../_services/mapbox.service";
import * as mapboxgl from "mapbox-gl";
import { PAStore } from "../classes/store";
import { PACoordinates } from "../classes/coordinates";
import { MapContainer } from "../map/pa-map";

export class PAMapControl {

  private static _instance: PAMapControl
  public static get Instance()
  {
    return this._instance || (this._instance = new this());
  }

  selectedIsoCoordinates: PACoordinates
  isochroneDistance: number = 0
  mainMapContainer: MapContainer;

  private _mapBoxService: MapBoxService

  private constructor(
  ) {
  }

  set mapBoxService(mbs: MapBoxService) {
    this._mapBoxService = mbs
  }

  async setDrivingIso(lat: number, long: number, minutes?: number): Promise<void> {
    let source: mapboxgl.GeoJSONSource = this.mainMapContainer.map.getSource('iso') as mapboxgl.GeoJSONSource
    if (!minutes) {
      if (this.isochroneDistance > 0) {
        minutes = this.isochroneDistance
      } else {
        if (source) {
          source.setData({
            'type': 'FeatureCollection',
            'features': []
          })
        }
        return
      }
    }
    let driving_iso: GeoJSON.FeatureCollection<GeoJSON.Geometry> = await this.getDrivingIso(lat, long, minutes)
    if (source) source.setData(driving_iso)
  }

  unsetDrivingIso(): void {
    let source: mapboxgl.GeoJSONSource = this.mainMapContainer.map.getSource('iso') as mapboxgl.GeoJSONSource
    if (source) {
      source.setData(
        {
          'type': 'FeatureCollection',
          'features': []
        }
      )
    }
  }

  async getDrivingIso(lat: number, long: number, minutes: number): Promise<GeoJSON.FeatureCollection<GeoJSON.Geometry>> {
    return new Promise((resolve, reject) => {
      this._mapBoxService.getDrivingIso(lat, long, minutes).subscribe(
        (data: any) => {
          resolve(data)
        },
        (err) => {
          console.error(err)
          reject()
        },
      )
    })
  }

  async setStoresDrivingIso(store: PAStore) {
    this.selectedIsoCoordinates = store.location.coordinates
    await this.updateSelectedCoordinatesDrivingIso()
  }

  async updateSelectedCoordinatesDrivingIso(): Promise<void> {
    if (this.selectedIsoCoordinates) {
      await this.setDrivingIso(this.selectedIsoCoordinates.latitude, this.selectedIsoCoordinates.longitude)
    } else {
      this.unsetDrivingIso()
    }
  }
}