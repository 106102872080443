import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { environment } from '../../environments/environment'
import { DeliveryNote, DeliveryNoteSearchParams } from '../_models'

@Injectable({
  providedIn: 'root'
})
export class DeliveryNoteService {
    constructor(private http: HttpClient) {}

    public update(id: String | string | number, params: any) {
        let path = environment.apiURL + 'delivery_notes/' + id
        return this.http.patch(path, params);
    }

    public create(params: any) {
        let path = environment.apiURL + 'delivery_notes'
        return this.http.post(path, params);
    }
    public search(searchParams: DeliveryNoteSearchParams) {
        let path = environment.apiURL + "delivery_notes/search"
        return this.http.post<DeliveryNote[]>(path, searchParams)
    }

    public calculateShippingPrice(id: String | string | number, params: any) {
        let path = environment.apiURL + `delivery_notes/${id}/shipping_price`;
        return this.http.post<any>(path, { shipping: { ...params } });
    }

    public checkShipcloudTrackingStatus(id: String | string | number) {
        let path = environment.apiURL + `delivery_notes/${id}/check_shipcloud_tracking_status`;
        return this.http.post<DeliveryNote>(path, {});
    }

    public show(id: String | string | number, params: any = {}) {
        let path = environment.apiURL + `delivery_notes/${id}.json`;
        return this.http.get<DeliveryNote>(path, { params: params });
    }

    public delete(id: String | string | number) {
        let path = environment.apiURL + `delivery_notes/${id}`;
        return this.http.delete<any>(path);
    }

    public otherDeliveries(id: String | string | number) {
        let path = environment.apiURL + `delivery_notes/${id}/other_deliveries.json`;
        return this.http.get<DeliveryNote[]>(path);
    }

    public savePickup(id: String | string | number, params: any) {
        let path = environment.apiURL + `delivery_notes/${id}/save_pickup`;
        return this.http.post<any>(path, { pickup: { ...params } });
    }

    public saveReceipt(id: String | string | number, params: any) {
        let path = environment.apiURL + `delivery_notes/${id}/save_receipt`;
        return this.http.post<any>(path, { receipt: { ...params } });
    }

    public saveShipcloudRetoure(id: String | string | number, params: any) {
        let path = environment.apiURL + `delivery_notes/${id}/save_shipcloud_retoure`;
        return this.http.post<any>(path, { shipping: { ...params } });
    }

    public createShipping(id: String | string | number, params: any) {
        let path = environment.apiURL + `delivery_notes/${id}/create_shipping`;
        return this.http.post<any>(path, { shipping: { ...params } });
    }

    public articles(id: String | string | number) {
        let path = environment.apiURL + `delivery_notes/${id}/articles`;
        return this.http.get<any[]>(path);
    }

    public createRetoure(id: String | string | number) {
        let path = environment.apiURL + `delivery_notes/${id}/create_retoure`;
        return this.http.post<any>(path, {});
    }
}