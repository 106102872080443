
import { Component, inject, OnInit } from '@angular/core';
import { ColDef, GridReadyEvent } from "ag-grid-community";
import { AG_GRID_LOCALE_DE } from "../../_helpers/aggrid.locale.de";
import {
  GridApi,
  RowClassRules,
  SideBarDef, SizeColumnsToContentStrategy,
  SizeColumnsToFitGridStrategy,
  SizeColumnsToFitProvidedWidthStrategy
} from "ag-grid-enterprise";
import { CustomerService } from "../../_services";
import { APCustomer } from "../../_models/customer.interface";
import { AgGridAngular } from "ag-grid-angular";
import { MatButton } from "@angular/material/button";
import { AgGridThemeService } from "../../_services/ag-grid-theme.service";
import { RecordNavigationComponent } from "../_shared/record-navigation/record-navigation.component";

@Component({
  selector: 'hawk-customer-index',
  templateUrl: './customer-index.component.html',
  standalone: true,
  imports: [
    RecordNavigationComponent,
    AgGridAngular,
    MatButton
  ],
  styleUrls: ['./customer-index.component.scss', '../_shared/styles/common-styles.scss']
})
export class CustomerIndexComponent implements OnInit{
  public hawkTheme = inject(AgGridThemeService).getTheme();

  protected readonly locale = AG_GRID_LOCALE_DE;

  private gridApi!: GridApi;
  public rowClassRules: RowClassRules = {}
  autoSizeStrategy: SizeColumnsToFitGridStrategy | SizeColumnsToFitProvidedWidthStrategy | SizeColumnsToContentStrategy = {
    type: "fitGridWidth",
  }

  public shownCustomers: APCustomer[] = []

  public colDefs: ColDef[] = [
    {
      field: "letter",
      headerName: "Abk.",
      width: 90,
    },
    {
      field: "name",
      headerName: "Name",
      width: 300,
      cellRenderer: this.nameRenderer,
      cellClass: 'hover_link_cell'
    },
    {
      field: "url",
      headerName: "URL",
      width: 260,
      cellRenderer: this.urlRenderer,
      cellClass: 'hover_link_cell'
    },
    {
      field: "article_count",
      headerName: "Artikel",
      width: 200,
      cellRenderer: this.articleRenderer,
      cellClass: 'hover_link_cell'
    },
    {
      field: "store_count",
      headerName: "Stores",
      width: 200,
    },
    {
      field: "project_count",
      headerName: "Projekte",
      width: 200,
    },
    {
      field: "users",
      width: 200,
      headerName: "Nutzer",
      valueFormatter: (params: { value: any[] }) => {
        if (params.value) {
          return params.value.length.toString()
        }
        return null
      },
    },
  ]

  public sideBarOptions: SideBarDef = {
    hiddenByDefault: false,
    toolPanels: ['columns', 'filters']
  }

  public defaultColDef: ColDef = {
    filter: true
  }

  public rowSelection: "single" | "multiple" = "single"

  constructor(
    private customerService: CustomerService
  ) {
  }

  ngOnInit(): void {
    this.customerService.loadAPCustomers().subscribe(
      data => {
        this.shownCustomers = data
      },
      err => {
        console.log(err)
      }
    )
  }

  onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api
  }

  public quickSearchChanged(e: Event) {
    const newValue = (e.target as HTMLInputElement).value
    this.gridApi.setGridOption(
      "quickFilterText",
      newValue,
    )
  }

  nameRenderer(params) {
    const id = params.data.id
    return `<a class="hover_link" href="/a/kunde/${id}" rel="noopener">${params.value}</a>`
  }

  urlRenderer(params) {
    return `<a class="hover_link" href="${params.value}" rel="noopener">${params.value}</a>`
  }

  articleRenderer(params) {
    const id = params.data.id
    return `<a class="hover_link" href="/articleOverview/${id}" rel="noopener">${params.value || '-'}</a>`
  }
}
