<div class="row">
  <div class="col-sm-3">
    <h3>Ticketreview
    </h3>
  </div>
</div>
<div class="row">
  <div class="col-12">
    <div class="card">
      <div class="card-body">
      </div>
    </div>
  </div>
</div>

<div class="row newSection">
  <div class="col-sm-3">
    <h3>Aufgaben</h3>
  </div>
</div>

<div class="row">
  <div class="col-12 deviceCard" *ngFor="let device of devices">
    <div class="card">
      <div class="card-body">
        <h3>{{ device.identifier }} <span *ngIf="device.sub_identifier">{{ device.sub_identifier }}</span><small *ngIf="device.serial_number">({{ device.serial_number }})</small></h3>

        <div class="row">
          <div class="col-12" *ngFor="let job of device.jobs">

            <div class="row">
              <div class="col-12">
                <p *ngIf="job.job_type != 'checklist_info'">{{ job.id }} <b>{{ job.description }}</b> {{ job.done ? '✓' : 'X' }} - {{ job.status }} <button type="button" class="btn btn-success btn-sm">geprüft, ok</button> <button type="button" class="btn btn-danger btn-sm">unzureichend!</button><span class="float-right"><small>erledigt: {{ job.done_at | date: 'dd.MM.yy HH:mm' }}, updated: {{ job.updated_at | date: 'dd.MM.yy HH:mm' }}</small></span><br>
                {{ job.reason }}</p>
                <h4 *ngIf="job.job_type == 'checklist_info'">{{ job.description }}</h4>
              </div>
            </div>
            <div class="row deviceImage" *ngIf="(job.job_type == 'picture') || (job.job_type == 'picture_png')">
              <div class="col-4" *ngIf="job.reference_image">
                <img src="{{job.reference_image}}" />
              </div>
              <img class="col-4" [defaultImage]="defaultImage" [lazyLoad]="apiImagePath(job.result_image)" *ngIf="job.result_image"/>
              <img class="col-4" [defaultImage]="defaultImage" [lazyLoad]="apiImagePath(job.result_overlay_image)" *ngIf="job.result_overlay_image"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
