import { Pipe, PipeTransform } from '@angular/core'
import { ArticleTemplate } from '../_models'

@Pipe({
    name: 'articleTemplateFilter',
})
export class ArticleTemplateFilterPipe implements PipeTransform {
  transform(allArticleTemplates: ArticleTemplate[], searchText?: string): ArticleTemplate[] {
    if (!allArticleTemplates) {
      return allArticleTemplates
    }

    const searchTerms = searchText.toLowerCase().split(' ')

    return allArticleTemplates.filter((articleTemplate) => {

      let searchTermMaps = searchTerms.map((term) => {
        return `${articleTemplate.description} ${articleTemplate.product_category} ${articleTemplate.vendor} ${articleTemplate.id}`.toLowerCase().includes(term)
      })

      return searchTermMaps.reduce((acc, t) => acc && t, true)
    })
  }
}

