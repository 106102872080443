import { CommonModule } from "@angular/common";
import { ReactiveFormsModule } from "@angular/forms";
import { FormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { FaIconComponent, FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { ViewportScroller } from '@angular/common'
import { Component, OnDestroy, OnInit } from '@angular/core'
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'
import { FlatpickrDefaultsInterface, FlatpickrModule } from 'angularx-flatpickr'
import { Subject, Subscription } from 'rxjs'
import { unsubscribeAll } from 'src/app/_helpers/unsubscribe.helper'
import { ArticleSummary, ArticleTemplate, EditEventType, Operation, OperationMetadata, OperationMetadataStep, OperationTechnicianEntry, Status, Technician, Ticket, TicketLocation, TicketMetadata } from 'src/app/_models'
import { ArticleService, OperationService, SessionService, TicketService, UserService } from 'src/app/_services'
import { EditInputComponent } from "../_shared/edit-input/edit-input.component";
import { StoreSubboxComponent } from "./store-subbox/store-subbox.component";
import { NgxMapboxGLModule } from "ngx-mapbox-gl";
import { OperationSubpageComponent } from "./operation-subpage/operation-subpage.component";
import { LazyLoadImageModule } from "ng-lazyload-image";
import { StoreFilterPipe } from "src/app/_pipes";
import { NoteSubpageComponent } from "./note-subpage/note-subpage.component";

@Component({
  imports: [CommonModule, ReactiveFormsModule, FormsModule, RouterModule, FlatpickrModule, FontAwesomeModule, EditInputComponent, StoreSubboxComponent, NgxMapboxGLModule, OperationSubpageComponent, LazyLoadImageModule, StoreFilterPipe, NoteSubpageComponent],
    selector: 'hawk-ticket-page',
    templateUrl: './ticket-page.component.html',
    styleUrls: ['./ticket-page.component.scss'],
})
export class TicketPageComponent implements OnInit, OnDestroy {

  defaultImage = 'https://via.placeholder.com/1500'

  public ticket: Ticket
  public ticketId: string
  public operations: Operation[] = []
  public technicians: Technician[] = []
  public articleTemplates: ArticleTemplate[] = []
  public articleSummary: ArticleSummary
  public selectedOperation: Operation
  public position: [number, number]
  public metaData: TicketMetadata
  public descriptionLinebreaks = false

  private subscriptions: Subscription[] = []

  public isBookkeeper = false

  public technicianTicketLocations: { [key: number]: TicketLocation[] } = {}

  public storeSearchboxValue = ''

  public markerData: { lat: number, lng: number, color: string, text: string }[]

  public paramOpId: string
  public paramQmJobId: string

  public showModal = ''

  public editExtNr = false
  public editDateSla = false
  public editProject = false
  public editStore = false
  public editDescription = false
  public editAddrCompany = false
  public editAddrName = false
  public editAddrStreet = false
  public editAddrZipCity = false
  public editAddrCountry = false
  public editStoreContact = false

  public selectedStep: OperationMetadata
  public resetStoreUnit: Subject<void> = new Subject<void>();

  public datePickerConfig: FlatpickrDefaultsInterface = {
    enableTime: true,
    // utc: true,
    convertModelValue: true,
    altInput: true,
    allowInput: true,
    altFormat: 'd.m.Y H:i',
    dateFormat: 'Y-m-d H:i',
    defaultHour: 10,
    defaultMinute: 0,
//
    time24hr: true,
    enable : [{from : new Date(1985, 1), to : new Date(new Date().getFullYear() + 50, 12)}]
    }
  public selectedDate

  public ticketContactForm = new UntypedFormGroup({
    contact_name: new UntypedFormControl(''),
    contact_email: new UntypedFormControl(''),
    contact_phone: new UntypedFormControl(''),
  })

  public prioForm = new UntypedFormGroup({
    priority_id: new UntypedFormControl(''),
  })

  public statusForm = new UntypedFormGroup({
    status_id: new UntypedFormControl(''),
  })

  public metaTimeForm = new UntypedFormGroup({
    time_estimation: new UntypedFormControl(''),
  })

  public appointmentForm = new UntypedFormGroup({
    appointment_date: new UntypedFormControl(''),
    appointment_contact: new UntypedFormControl(''),
  })

constructor(
    private route: ActivatedRoute,
    private router: Router,
    private ticketService: TicketService,
    private operationService: OperationService,
    private userService: UserService,
    private sessionService: SessionService,
    private articleService: ArticleService,
    private viewportScroller: ViewportScroller
  ) { }

  ngOnInit(): void {
    this.ticketId = this.route.snapshot.paramMap.get('id')
    this.markerData = []

    this.paramOpId = this.route.snapshot.queryParamMap.get('operation_id')
    this.paramQmJobId = this.route.snapshot.queryParamMap.get('qmJob')

    this.loadTicket()
    this.loadPermissions()

    this.subscriptions.push(this.articleService.loadArticleTemplates().subscribe(
        (data) => {
          this.articleTemplates = data
        },
        (err) => {
          console.error(err)
        },
      )
    )
  }

  public ngOnDestroy() {
    unsubscribeAll(this.subscriptions)
  }

  public handleReloadTicket(reload: boolean): void {
    if (reload) {
      this.loadTicket();
    }
  }

  public loadTicket() {
    const id = this.ticketId
    this.subscriptions.push(
      this.ticketService.getTicket(id).subscribe(
        (data) => {
          this.updateTicket(data)

          this.subscriptions.push(
            this.ticketService.getOperations(String(this.ticket.id)).subscribe(
              (dataOp) => {
                this.operations = dataOp

                const l = this.operations.length
                if (l > 0) {
                  if (this.paramOpId) {
                    const match = this.operations.filter((op) => op.id.toString() === this.paramOpId)

                    if (match) {
                      this.selectOperation(match[0])
                    }
                  }
                  if (!this.selectedOperation)  {
                    this.selectOperation(this.operations[l - 1])
                  }
                  this.loadTicketLocations()
                }

                this.loadArticleSummary()
              },
              (err) => {
                console.error(err)
              },
            )
          )

          this.subscriptions.push(
            this.userService.getProjectTechnicians(data.project_id).subscribe(
              (technicians) => {
                this.technicians = technicians
              },
              (err) => {
                console.error(err)
              },
            )
          ) 
        },
        (err) => {
          console.error(err)
        },
      )
    )
  }

  public updateTicket(ticketData: Ticket) {
    this.ticket = ticketData
    this.markerData = []

    this.ticketContactForm.controls.contact_name.setValue(this.ticket.contact_name)
    this.ticketContactForm.controls.contact_email.setValue(this.ticket.contact_email)
    this.ticketContactForm.controls.contact_phone.setValue(this.ticket.contact_phone)

    this.metaTimeForm.controls.time_estimation.setValue(this.ticket.time_estimation)
    this.appointmentForm.controls.appointment_contact.setValue(this.ticket.appointment_contact)
    this.appointmentForm.controls.appointment_date.setValue(this.ticket.appointment_date)

    this.position = [this.ticket.address_longitude, this.ticket.address_latitude]

    this.markerData.push({
      lat: this.ticket.address_latitude,
      lng: this.ticket.address_longitude,
      color: '#af0000',
      text: 'Store'
    })

    this.subscriptions.push(
      this.ticketService.getMetaData(ticketData.id).subscribe(
        (metaData) => {
          this.metaData = metaData

          this.setMeta()
        },
        (err) => {
          console.error(err)
        }
      )
    )
  }

  public loadTicketLocations() {
    this.technicianTicketLocations = {}

    this.operations.forEach((op) => {
      this.subscriptions.push(
        this.ticketService.getTechnicianTicketLocations(op.technicians[0].id, (new Date(op.operation_date)).toISOString().slice(0, 10)).subscribe(
          (locations) => {
          this.technicianTicketLocations[op.technicians[0].id] = locations
          this.selectOperation(this.selectedOperation)
          },
          (err) => {
            console.error(err)
          }
        )
      )
    })
  }

  public loadArticleSummary() {
    this.subscriptions.push(
      this.ticketService.getArticleSummary(this.ticket.id).subscribe(
        (data) => {
        this.articleSummary = data
        },
        (err) => {
          console.error(err)
        }
      )
    )
  }

  public loadPermissions() {
    this.subscriptions.push(
      this.sessionService.rolePermissions().subscribe(
        (data) => {
          if (this.checkPermission(["ACCOUNTANT"], data.roles)) {
            this.isBookkeeper = true
          }
          
        },
        (err) => {
          console.error(err)
        },
      )
    )
  }

  private checkPermission(validPermissions: string[], userPermissions: string[]): boolean {
    for (let role of userPermissions) {
      if (validPermissions.includes(role)) {
        return true
      }
    }
    return false
  }

  public operationActive(operation: Operation): boolean {
    if (this.selectedOperation) {
      return operation === this.selectedOperation
    } else {
      return false
    }
  }

  public selectOperation(operation: Operation) {
    this.selectedOperation = operation

    this.markerData = []

    this.markerData.push({
      lat: this.ticket.address_latitude,
      lng: this.ticket.address_longitude,
      color: '#af0000',
      text: 'Store'
    })

    if (operation.technicians) {
      const tech = operation.technicians[0]

      if (this.technicianTicketLocations[tech.id]) {
        this.technicianTicketLocations[tech.id].forEach((element) => {
          if (element.latitude !== this.ticket.address_latitude || this.ticket.address_longitude !== element.longitude) {
            this.markerData.push({
              lat: element.latitude,
              lng: element.longitude,
              color: '#af00af',
              text: element.company
            })
          }
        })
      }
    }
  }

  public canReject(): boolean {
    return (this.metaData?.status?.name?.toLowerCase().includes('complete') === false) && (this.metaData?.status?.name?.toLowerCase().includes('rejected') === false)
  }

  public convertLinebreaks(text: string, replace: boolean = true): string {
    return replace ? text.replace(new RegExp('\n', 'g'), '<br />') : text
  }

  public toggleDescriptionLinebreaks() {
    this.descriptionLinebreaks = !this.descriptionLinebreaks
  }

  public editContact() {
    this.showModal = 'contact'
  }

  public saveContact() {
    this.showModal = ''

    this.ticketService.updateTicket(this.ticketId, this.ticketContactForm.value).subscribe(
      (data) => {
        this.updateTicket(data)
      },
      (error) => {}
    )
  }

  public setMeta() {
    this.prioForm.controls.priority_id.setValue(this.metaData.priority.id)
    this.statusForm.controls.status_id.setValue(this.metaData.status.id)
  }

  public saveStatus() {
    this.showModal = ''

    if (this.ticket.is_mt) {
      const status_array = this.metaData.status_list.filter((s) => s.id.toString() === this.statusForm.value.status_id.toString())
      const statusObject = status_array[0]

      // if status name contains rejected, dont update status and show alert to user
      if (statusObject.name.toLowerCase().includes('rejected')) {
        window.alert('Ticket kann nicht auf abgelehnt gesetzt werden. Bitte MT Tab in der Mitte nutzen!')
        return
      }
    }

    this.subscriptions.push(
      this.ticketService.updateTicketStatus(this.ticketId, this.statusForm.value).subscribe(
        (data) => {
          this.updateTicket(data)
        },
        (error) => {}
      )
    )
  }

  public savePrio() {
    this.showModal = ''

    this.subscriptions.push(
      this.ticketService.updateTicket(this.ticketId, this.prioForm.value).subscribe(
        (data) => {
          this.updateTicket(data)
        },
        (error) => {}
      )
    )
  }

  public saveTimeEstimation() {
    this.showModal = ''

    this.subscriptions.push(
      this.ticketService.updateTicket(this.ticketId, this.metaTimeForm.value).subscribe(
        (data) => {
          this.updateTicket(data)
        },
        (error) => {}
      )
    )
  }

  public saveAppointment() {
    this.showModal = ''

    const appointmentData = {
      appointment_contact: this.appointmentForm.value.appointment_contact,
      appointment_date: this.formatDate(this.appointmentForm.value.appointment_date)
    }

    this.subscriptions.push(
      this.ticketService.updateTicket(this.ticketId, appointmentData).subscribe(
        (data) => {
          this.updateTicket(data)
        },
        (error) => {}
      )
    )
  }

  // External number
  public saveExtNr(event: EditEventType) {
    this.metaUpdate('external_order_nr', event)
    this.editExtNr = false
  }

  // External number
  public saveDateSla(event: EditEventType) {
    this.metaUpdate('datesla', event)
    this.editDateSla = false
  }

  // update project
  public saveProject(event: EditEventType) {
    this.metaUpdate('project_id', event)
    this.editProject = false
  }

  public saveStore(storeId: number) {
    this.metaUpdate('store_id', storeId)
    this.editStore = false
  }

  public removeStore() {
    this.metaUpdate('store_id', null)
    this.editStore = false
  }

  public saveDescription(event: EditEventType) {
    this.metaUpdate('description', event)
    this.editDescription = false
  }

  public deleteTicket() {
    if (this.ticketDeletable()) {
      const doIt = window.confirm('Ja, Ticket wirklich löschen.')

      if (doIt) {
        this.subscriptions.push(
          this.ticketService.deleteTicket(this.ticket.id).subscribe(
            (data) => {
              this.router.navigate([''])
              console.log(data)
            },
            (error) => { console.error(error) }
          )
        )
      }
    } else {
      window.alert('Ticket kann nicht gelöscht werden.')
    }
    this.showModal = ''
  }

  public deleteOperation(operation: Operation) {
    if (this.operationDeletable(operation)) {
      const doIt = window.confirm('Ja, Einsatz wirklich löschen.')
      if (doIt) {
        this.subscriptions.push(
          this.ticketService.deleteOperation(operation.id).subscribe(
            (data) => {
              console.log(data)
              this.loadTicket()
            },
            (error) => { console.error(error) }
          )
        )
      }
    } else {
      window.alert('Einsatz kann nicht gelöscht werden.')
    }

    this.showModal = ''
  }

  public dangerousDelete() {
    const doIt = window.confirm('Ja, Ticket und alle Einsätze wirklich löschen.')
    if (doIt) {
      const challange = window.prompt('Sicher? Zur Bestätigung bitte Ticketnummer eingeben.')
      if (challange === this.ticketId) {
        this.subscriptions.push(
          this.ticketService.dangerousDelete(parseInt(this.ticketId)).subscribe(
            {
              next: (data) => {
                console.log(data)
                this.router.navigate([''])
              },
              error: (error) => { 
                console.error(error)
                window.alert(error)
                this.showModal = ''
              }
            }
          )
        )
      } else {
        window.alert('Ticket ID falsch. Löschen nicht gestartet.')
        this.showModal = ''
      }
    } else {
      window.alert('Einsatz kann nicht gelöscht werden.')
      this.showModal = ''
    }
  }

  public dangerousDeleteOperation(opertionId: number) {
    const doIt = window.confirm(`Ja, Einsatz (${opertionId}) wirklich löschen.`)
    if (doIt) {
      const challange = window.prompt('Sicher? Zur Bestätigung bitte Ticketnummer eingeben.')
      if (challange === this.ticketId) {
        this.subscriptions.push(
          this.operationService.dangerousDelete(opertionId).subscribe(
            {
              next: (data) => {
                console.log(data)
                window.location.reload();
              },
              error: (error) => { 
                console.error(error)
                window.alert(error)
                this.showModal = ''
              }
            }
          )
        )
      } else {
        window.alert('Ticket ID falsch. Löschen nicht gestartet.')
        this.showModal = ''
      }
    } else {
      window.alert('Einsatz kann nicht gelöscht werden.')
      this.showModal = ''
    }
  }

  public ticketDeletable(): boolean {
    return this.ticket.deletable
  }

  public operationDeletable(operation: Operation): boolean {
    return operation.deletable
  }

  public metaUpdate(key, value) {
    const changeData = {}
    changeData[key] = value

    this.showModal = ''

    this.subscriptions.push(
      this.ticketService.updateTicket(this.ticketId, changeData).subscribe(
        (data) => {
          this.updateTicket(data)
        },
        (error) => {}
      )
    )
  }

  public technicianPresent(): boolean {
    return this.selectedOperation && this.selectedOperation.technicians.length > 0
  }

  public mainTechnician(): OperationTechnicianEntry {
    if (this.technicianPresent()) {
      return this.selectedOperation.technicians[0]
    }

    return
  }

  public operationDate(): Date {
    if (this.technicianPresent()) {
      return new Date(this.selectedOperation.operation_date)
    }

    return
  }

  public technicianWarehousePresent(): boolean {
    return this.technicianPresent() && this.mainTechnician().warehouses.length > 0
  }

  public mainTechnicianWarehouse(): number {
    if (this.technicianWarehousePresent()) {
      return this.mainTechnician().warehouses[0]
    }

    return
  }

  public closeModal() {
    this.setMeta()
    this.showModal = ''
  }

  public selectStep(step: OperationMetadata) {
    this.selectedStep = step
    this.resetStoreUnit.next()
    this.viewportScroller.scrollToAnchor('storeSubbox')
  }

  public disableMtStatus(status: Status): boolean {
    if (this.ticket.is_mt && status.name.toLowerCase().includes('abgelehnt')) {
      return true
    } else {
      return false
    }   
  }

  private formatDate(date) {
    if (date) {
      return date.getFullYear() + '-' +
      ((date.getMonth() + 1).toString().padStart(2, '0')) + '-' +
      date.getDate().toString().padStart(2, '0') + 'T' +
      date.getHours().toString().padStart(2, '0') + ':' +
      date.getMinutes().toString().padStart(2, '0') + ':00'
    }

    return
  }
}