<div class="ticketPage" *ngIf="warehouse">
    <section class="main-content">
        <header class="columns">
            <section>
                <h2 (click)="editLabel = !editLabel">Stammdaten</h2>
                <p class="description" [innerHtml]="warehouse.label" (click)="toggleLabel()" *ngIf="!editLabel"></p>
                <p class="description" *ngIf="editLabel">
                    <hawk-shared-edit-input [textValue]="warehouse.label" [modus]="'text'"
                                            (saveClicked)="saveLabel($event)" (cancelClicked)="editLabel = false"></hawk-shared-edit-input>
                </p>
                <table class="ticketPageTable base mt-3">
                    <tr>
                        <th class="tableLable">Artikel</th>
                        <td>
                            <a class="g-link d-flex gap-2" [routerLink]="['/', 'lager', warehouseId, 'articles']">
                                <i class="fa fa-cube mr6"></i>{{warehouse.articles_count}}
                            </a>
                        </td>
                    </tr>
                    <tr>
                        <th class="tableLable">Bad parts</th>
                        <td>
                            <a class="g-link d-flex gap-2" [routerLink]="['/', 'lager', warehouseId, 'bad-parts']">
                                <i class="fa fa-bug mr6"></i>{{warehouse.bad_parts_count}}
                            </a>
                        </td>
                    </tr>
                    <tr>
                        <th class="editable tableLable" (click)="editTechnician = !editTechnician">Techniker</th>
                        <td *ngIf="!editTechnician">
                            <a class="g-link d-flex gap-2" href="/a/benutzer/{{warehouse.owner_id}}" *ngIf="warehouse.owner_id">
                                <i class="fa fa-user mr6" aria-hidden="true"></i>{{warehouse.owner}}
                            </a>
                            <span *ngIf="!warehouse.owner_id">-</span>
                        </td>
                        <td *ngIf="editTechnician"><hawk-shared-edit-input [selectValue]="warehouse.owner_id" [selectOptions]="technicians" [modus]="'select'" (saveClicked)="saveTechnician($event)" (cancelClicked)="editTechnician = false"></hawk-shared-edit-input></td>
                    </tr>
                    <tr>
                        <th class="editable tableLable" (click)="editTelephone = !editTelephone">Telefon</th>
                        <td *ngIf="!editTelephone">{{warehouse.telephone}}</td>
                        <td *ngIf="editTelephone"><hawk-shared-edit-input [textValue]="warehouse.telephone" [modus]="'text'" (saveClicked)="saveTelephone($event)" (cancelClicked)="editTelephone = false"></hawk-shared-edit-input></td>
                    </tr>
                    <tr>
                        <th class="editable tableLable" (click)="editMainWarehouse = !editMainWarehouse">Hauptlager</th>
                        <td *ngIf="!editMainWarehouse">
                            <fa-icon [icon]="['far','check']" *ngIf="warehouse.main_warehouse"></fa-icon>
                            <fa-icon [icon]="['far','times']" *ngIf="!warehouse.main_warehouse"></fa-icon>
                        </td>
                        <td *ngIf="editMainWarehouse">
                            <hawk-shared-edit-input [textValue]="warehouse.main_warehouse" [modus]="'checkbox'" (saveClicked)="saveMainWarehouse($event)" (cancelClicked)="editMainWarehouse = false"></hawk-shared-edit-input>
                        </td>
                    </tr>
                    <tr>
                        <th class="editable tableLable" (click)="editFreeFill = !editFreeFill">Free fill</th>
                        <td *ngIf="!editFreeFill">
                            <fa-icon [icon]="['far','check']" *ngIf="warehouse.freefill"></fa-icon>
                            <fa-icon [icon]="['far','times']" *ngIf="!warehouse.freefill"></fa-icon>
                        </td>
                        <td *ngIf="editFreeFill"><hawk-shared-edit-input [textValue]="warehouse.freefill" [modus]="'checkbox'" (saveClicked)="saveFreeFill($event)" (cancelClicked)="editFreeFill = false"></hawk-shared-edit-input></td>
                    </tr>
                    <tr>
                        <th class="editable tableLable" (click)="editUniStockId = !editUniStockId">Stock-ID bei Uni-Data</th>
                        <td *ngIf="!editUniStockId">{{warehouse.uni_stock_id}}</td>
                        <td *ngIf="editUniStockId"><hawk-shared-edit-input [textValue]="warehouse.uni_stock_id" [modus]="'text'" (saveClicked)="saveUniStockId($event)" (cancelClicked)="editUniStockId = false"></hawk-shared-edit-input></td>
                    </tr>
                </table>
            </section>
            <section>
                <h2>Beschränkung der Artikelvorlagen</h2>
                <table class="ticketPageTable base mt-3">
                    <tr>
                        <th class="editable tableLable" (click)="editLimitTemplate1 = !editLimitTemplate1">Kommentar 1</th>
                        <td *ngIf="!editLimitTemplate1">{{warehouse.limit_template1 || '-'}}</td>
                        <td *ngIf="editLimitTemplate1"><hawk-shared-edit-input [textValue]="warehouse.limit_template1" [modus]="'text'" (saveClicked)="saveLimitTemplate1($event)" (cancelClicked)="editLimitTemplate1 = false"></hawk-shared-edit-input></td>
                    </tr>
                    <tr>
                        <th class="editable tableLable" (click)="editLimitTemplate2 = !editLimitTemplate2">Kommentar 2</th>
                        <td *ngIf="!editLimitTemplate2">{{warehouse.limit_template2 || '-'}}</td>
                        <td *ngIf="editLimitTemplate2"><hawk-shared-edit-input [textValue]="warehouse.limit_template2" [modus]="'text'" (saveClicked)="saveLimitTemplate2($event)" (cancelClicked)="editLimitTemplate2 = false"></hawk-shared-edit-input></td>
                    </tr>
                    <tr>
                        <th class="editable tableLable" (click)="editLimitTemplate3 = !editLimitTemplate3">Kommentar 3</th>
                        <td *ngIf="!editLimitTemplate3">{{warehouse.limit_template3 || '-'}}</td>
                        <td *ngIf="editLimitTemplate3"><hawk-shared-edit-input [textValue]="warehouse.limit_template3" [modus]="'text'" (saveClicked)="saveLimitTemplate3($event)" (cancelClicked)="editLimitTemplate3 = false"></hawk-shared-edit-input></td>
                    </tr>
                </table>
            </section>
            <section>
                <h2>Address</h2>
                <table class="ticketPageTable base mt-3">
                    <tr>
                        <th class="editable tableLable" (click)="editCompany = !editCompany">Company</th>
                        <td *ngIf="!editCompany">{{warehouse.address_company || '-'}}</td>
                        <td *ngIf="editCompany"><hawk-shared-edit-input [textValue]="warehouse.address_company" [modus]="'text'" (saveClicked)="saveCompany($event)" (cancelClicked)="editCompany = false"></hawk-shared-edit-input></td>
                    </tr>
                    <tr>
                        <th class="editable tableLable" (click)="editName = !editName">Name</th>
                        <td *ngIf="!editName">{{warehouse.address_firstname}} {{warehouse.address_lastname}}</td>
                        <td *ngIf="editName">
                            <span class="inline">
                                <input type="text" [(ngModel)]="addressFirstName" (keydown.esc)="editName = false" (keydown.enter)="saveName()">
                                <input type="text" [(ngModel)]="addressLastName" (keydown.esc)="editName = false" (keydown.enter)="saveName()">
                                <button (click)="saveName()"><fa-icon [icon]="['far','save']"></fa-icon></button>
                                <button (click)="editName = false"><fa-icon [icon]="['far','times']"></fa-icon></button>
                            </span>
                        </td>
                    </tr>
                    <tr>
                        <th class="editable tableLable" (click)="editStreet = !editStreet">Street</th>
                        <td *ngIf="!editStreet">{{warehouse.address_street || '-'}}</td>
                        <td *ngIf="editStreet"><hawk-shared-edit-input [textValue]="warehouse.address_street" [modus]="'text'" (saveClicked)="saveStreet($event)" (cancelClicked)="editStreet = false"></hawk-shared-edit-input></td>
                    </tr>
                    <tr>
                        <th class="editable tableLable" (click)="editZipcode = !editZipcode">Zipcode and city</th>
                        <td *ngIf="!editZipcode">{{warehouse.address_zip}} {{warehouse.address_city}}</td>
                        <td *ngIf="editZipcode">
                            <span class="inline">
                                <input type="text" [(ngModel)]="addressZip" (keydown.esc)="editZipcode = false" (keydown.enter)="saveZipcode()">
                                <input type="text" [(ngModel)]="addressCity" (keydown.esc)="editZipcode = false" (keydown.enter)="saveZipcode()">
                                <button (click)="saveZipcode()"><fa-icon [icon]="['far','save']"></fa-icon></button>
                                <button (click)="editZipcode = false"><fa-icon [icon]="['far','times']"></fa-icon></button>
                            </span>
                        </td>
                    </tr>
                    <tr>
                        <th class="editable tableLable" (click)="editCountry = !editCountry">Country (state)</th>
                        <td *ngIf="!editCountry">{{warehouse.address_country}} ({{warehouse.address_state}})</td>
                        <td *ngIf="editCountry">
                            <span class="inline">
                                <input type="text" [(ngModel)]="addressCountry" (keydown.esc)="editCountry = false" (keydown.enter)="saveCountry()">
                                <input type="text" [(ngModel)]="addressState" (keydown.esc)="editCountry = false" (keydown.enter)="saveCountry()">
                                <button (click)="saveCountry()"><fa-icon [icon]="['far','save']"></fa-icon></button>
                                <button (click)="editCountry = false"><fa-icon [icon]="['far','times']"></fa-icon></button>
                            </span>
                        </td>
                    </tr>
                </table>
            </section>
        </header>
        <section>
            <h2>Lagerplätze</h2>
            <button
            [disabled]="isAddingStorage"
            (click)="addStorage()"
            class="btn g-link d-flex align-items-center gap-1 ps-0 my-1"><i class="fa fa-plus-circle"></i>Neuer Lagerplatz</button>
            <ag-grid-angular class="ag-theme-quartz" style="height:100%; width: 100%;"
            [theme]="hawkTheme" [loadThemeGoogleFonts]="true"
            [rowData]="storageBins"
            [defaultColDef]="defaultColDef"
            [getRowId]="getRowId"
            [columnDefs]="colDefs"
            [rowClassRules]="rowClassRules"
            [localeText]="locale"
            [rowSelection]="rowSelection"
            (suppressClickEdit)="false"
            (rowEditingStopped)="onRowEditingStopped($event)"
            (rowValueChanged)="onRowValueChanged($event)"
            (gridReady)="onGridReady($event)">
            </ag-grid-angular>
            <!-- <br/>
            (rowEditingStarted)="onRowEditingStarted($event)"
            <button (ng-disabled)="isAddingStorage" (click)="addStorage()">Neuer Lagerplatz</button> -->
        </section>
        <div class="d-flex col-auto ms-2">
            <a target="_blank" href="/warehouse/warehouse/{{warehouseId}}/edit" class="btn btn-primary d-flex align-items-center gap-1"><i class="fa fa-pencil"></i>Bearbeiten</a>
        </div>
    </section>
    <aside>
        <section>
            <h3>Karte</h3>
            <mgl-map [style]="'mapbox://styles/mapbox/streets-v9'" [zoom]="[11]" [center]="position">
                <div *ngFor="let marker of markerData">
                    <mgl-marker *ngIf="marker.lat && marker.lng" [lngLat]="[marker.lng, marker.lat]">
                        <div class="marker" [style.borderColor]="'black'" [style.backgroundColor]="marker.color" title="{{marker.text}}"></div>
                    </mgl-marker>
                </div>
            </mgl-map>
            <br/>
            <div class="d-flex gap-2">
                <a href="/warehouse/warehouse/{{warehouseId}}/exportMaterial" class="d-flex align-items-center gap-1 g-link" target="_blank">
                    <i class="fa fa-download"></i>Material-Export
                </a>
                <a [routerLink]="['/', 'lager', warehouseId, 'retoure_international']" class="d-flex align-items-center gap-1 g-link" target="_new">
                    <i class="fas fa-warehouse-alt"></i> Int. Retoure anlegen
                </a>
                <button (click)="onDelete()"  class="btn d-flex align-items-center gap-1 g-link" [disabled]="!canDelete">
                    <i class="fas fa-trash-alt"></i>Lager deaktivieren
                </button>
            </div>
        </section>
    </aside>
</div>