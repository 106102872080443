import { Component, EventEmitter, Input, Output } from '@angular/core';
import { faWindowClose } from '@fortawesome/free-solid-svg-icons';
import { NgClass } from '@angular/common';

import { CommonModule } from "@angular/common";
import { FaIconComponent, FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@Component({
  selector: 'hawk-popup-modal',
  templateUrl: './popup-modal.component.html',
  styleUrls: ['./popup-modal.component.scss'],
  standalone: true,
  imports: [NgClass, FaIconComponent, CommonModule, FontAwesomeModule]
})
export class PopupModalComponent {

  faWindowClose = faWindowClose

  @Input() shown: boolean = false
  @Output() closeEvent = new EventEmitter<void>()

}