import {
  Injectable,
  Injector,
  ApplicationRef,
  ComponentFactoryResolver,
  ComponentRef,
  Type
} from '@angular/core'

@Injectable({
  providedIn: 'root'
})
export class DynamicComponentService {

  constructor(private injector: Injector,
              private resolver: ComponentFactoryResolver,
              private appRef: ApplicationRef) { }

  public injectComponent<T>(component: Type<T>, propertySetter?: (type: T) => void): {div_element: HTMLDivElement, component_ref: ComponentRef<T> }  {

    // Resolve the Component and Create
    const compFactory = this.resolver.resolveComponentFactory<T>(component);
    let comp_ref = compFactory.create(this.injector);

    // Allow a Property Setter to be Passed in (To Set a Model Property, etc)
    if (propertySetter)
      propertySetter(comp_ref.instance);

    // Attach to Application
    this.appRef.attachView(comp_ref.hostView);

    // Create Wrapper Div and Inject Html
    let div = document.createElement('div');
    div.appendChild(comp_ref.location.nativeElement);

    // Return the Rendered DOM Element
    return {div_element: div, component_ref: comp_ref};
  }
}