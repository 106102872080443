<div class="row">
  <div class="col-sm-3">
    <h3>Massenerfassung im Lager
    </h3>
  </div>
</div>

<div class="row">
  <div class="col-sm-12">
    <div class="card">
      <div class="card-body">
        <h5 class="card-title">Artikel Metadaten</h5>
        <form [formGroup]="metaForm">
          <div class="col">
            <div class="form-group">
              <label for="staticEmail">Typ</label>
                <ng-select [items]="templates"
                    bindLabel="combined"
                    autofocus
                    #metaType (close)="metaType.blur()"
                    placeholder="Artikeltyp auswählen"
                    formControlName="metaType">
                </ng-select>
            </div>
            <div class="form-group">
              <label for="kundeBentomax">Eigentümer</label>

              <div class="form-check">
                <input class="form-check-input" type="radio" name="ownerSelect" id="kundeBentomax" formControlName="ownerSelect" value="bentomax" [checked]="true">
                <label class="form-check-label" for="kundeBentomax">
                  Bentomax
                </label>
              </div>
              <div class="form-check">
                <input class="form-check-input" type="radio" name="ownerSelect" id="kundeOther" formControlName="ownerSelect" value="other">
                <label class="form-check-label" for="kundeOther">
                  Kunde
                </label>
              </div>

              <ng-select [items]="owner"
                  bindLabel="name"
                  autofocus
                  bindValue="id"
                  placeholder="Eigentümer auswählen"
                  formControlName="metaOwner"
                  #metaOwner (close)="metaOwner.blur()"
                  *ngIf="showCustomer()">
              </ng-select>
            </div>
            <div class="form-group">
              <label for="inputCount">Anzahl</label>
                <input type="number" class="form-control" id="inputCount" formControlName="metaAmount" placeholder="Anzahl Teile">
                <small id="passwordHelpBlock" class="form-text text-muted">
                  Hat der Artikel die erfassung der Seriennummer aktiviert:<br>
                  Die Anzahl wird nur zum Abgleich mit der Anzahl der gescannten Seriennummern benötigt. Das Feld ist deshalb optional.<br>
                  Ansonsten ist es ein Pflichtfeld.
                </small>
            </div>
          </div>

          <div class="col">
            <div class="form-group">
              <label for="inputDeliveryNoteNumber">externer Lieferschein</label>
                <input type="text" class="form-control" id="inputDeliveryNoteNumber"  formControlName="deliveryNoteNumber" placeholder="externe Lieferscheinnummer eingeben">
            </div>
            <div class="form-group">
              <label for="">Lieferdatum</label>
              <div class="input-group">
                <input class="form-control" placeholder="Datum eingeben"
                        name="dp" formControlName="deliveryDate" ngbDatepicker #d="ngbDatepicker">
                <div class="input-group-append">
                  <button class="btn btn-outline-secondary far fa-calendar-alt" (click)="d.toggle()" type="button"></button>
                </div>
              </div>
            </div>
            <div class="form-group">
              <label for="">Herstellungsdatum</label>
              <div class="input-group">
                <input class="form-control" placeholder="Datum eingeben"
                        name="dp" formControlName="productionDate" ngbDatepicker #d2="ngbDatepicker">
                <div class="input-group-append">
                  <button class="btn btn-outline-secondary far fa-calendar-alt" (click)="d2.toggle()" type="button"></button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

<div class="row" *ngIf="useSerialNumbers()">
  <div class="col-sm-12">
    <div class="card">
      <div class="card-body">
        <h5 class="card-title">Seriennummern erfassen</h5>
        <div class="col-sm-6 col-xs-12">
          <div class="form-group row">
            <label for="staticEmail" class="col-sm-4 col-form-label">Seriennummer erfassen</label>
            <div class="col-sm-7">
              <form [formGroup]="serialForm" (submit)="onSNSubmit()">
                <input type="text" class="form-control" #serialNumberInput id="inputSNNum" placeholder="Seriennummer" formControlName="serialInput" >
              </form>
              <small id="passwordHelpBlock" class="form-text text-muted">
                Enter zum Bestätigen
              </small>
            </div>
          </div>
        </div>

        <div class="col-sm-6 col-xs-12">
          {{serialNumbers | json}}
        </div>
        <div class="col-sm-6 col-xs-12">
          Gescannte Codes: {{serialNumberSize()}}<span *ngIf="expectedSNCount()">, es fehlen: {{ expectedSNCount() - serialNumberSize() }}</span>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="row" *ngIf="!useSerialNumbers()">
  <div class="col-sm-12">
    <div class="card">
      <div class="card-body">
        <h5 class="card-title">Seriennummern erfassen</h5>
        <div class="col-sm-6 col-xs-12">
          <p>Eingabe ist nicht aktiviert, weil der Artikel nicht zur Eingabe von Seriennummern konfiguriert ist.</p>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="row">
  <div class="col-sm-12">
    <div class="card">
      <div class="card-body">
        <h5 class="card-title">Prüfen und hinzufügen</h5>
        <form [formGroup]="endForm">
          <div class="col">
            <div class="form-group">
              <label for="staticEmail">Anfangslager</label>
              <ng-select [items]="warehouses"
                  bindLabel="combined_label"
                  autofocus
                  bindValue="combined_id"
                  groupBy="name"
                  #startWarehouse (close)="startWarehouse.blur()"
                  placeholder="Anfangslager auswählen"
                  formControlName="startWarehouse">
              </ng-select>
              <small id="passwordHelpBlock" class="form-text text-muted">
                Hier das Eingangslager bei Bentomax wählen
              </small>
            </div>
            <div class="form-group">
              <label for="">Endlager</label>

              <ng-select [items]="warehouses"
                  bindLabel="combined_label"
                  autofocus
                  bindValue="combined_id"
                  groupBy="name"
                  #endWarehouse (close)="endWarehouse.blur()"
                  placeholder="Endlager auswählen"
                  formControlName="endWarehouse">
              </ng-select>
              <small id="passwordHelpBlock" class="form-text text-muted">
                Wenn die Artikel direkt nach der Erfassung in ein zweites Lager versendet werden sollen, kann hier das nächste Lager ausgewählt werden.<br>
                Dadurch werden die RMA Aufkleber mit neuem Lageraufdruck erzeugt und der passende Lieferschein erzeugt.
              </small>
            </div>
          </div>
        </form>

        <button class="btn btn-success" (click)="createArticles()">Artikel erzeugen und einbuchen</button>
      </div>
    </div>
  </div>
</div>
<div class="row">
  <div class="col-sm-12">
    <div class="card">
      <div class="card-body">
        {{ metaForm.getRawValue() | json }}

        {{ serialForm.getRawValue() | json }}

        {{ endForm.getRawValue() | json }}
      </div>
    </div>
  </div>
</div>
