<div class="articleSearch" style="border:2px dotted yellow">
  <h2 style="border:2px solid red">Artikelsuche</h2>
  <mat-form-field style="border:2px solid; width: 400px;">
    <mat-label>Artikelvorlage wählen*</mat-label>
    <mat-select multiple [(ngModel)]="selectedArticleTemplateId" (selectionChange)="changeSelectedTemplates()">
      <mat-option>keins</mat-option>
      <ng-container *ngFor="let template of articleTemplates">
        <mat-option [value]="template.id">{{template.id}} {{template.vendor}} {{template.product_category}}</mat-option>
      </ng-container>
    </mat-select>
    <mat-error>Bitte wählen Sie eine Artikelvorlage aus.</mat-error>
  </mat-form-field>
  <mat-form-field *ngIf="articlesPresent">
    <mat-label>Suchbegriff</mat-label>
    <input matInput [(ngModel)]="searchTerm">
  </mat-form-field>
  <h2 *ngIf="!articlesPresent"></h2>
</div>

<div class="articleSearch" *ngIf="!articlesPresent">
  <div>Bitte eine Artikelvorlage auswählen um Suche anzuzeigen</div>
</div>

<div class="articleSearch">
  <ag-grid-angular class="ag-theme-quartz" style="height:800px; width: 100%;" [rowData]="articles"
    [columnDefs]="colDefs" [pagination]="true" [paginationPageSize]="50" [localeText]="locale"
    [theme]="hawkTheme" [loadThemeGoogleFonts]="true"
    [paginationPageSizeSelector]="[50,100,300,1000,9000]">
  </ag-grid-angular>
</div>