<hawk-record-navigation *ngIf="user" [breadcrumbLinkList]="[{link: '/a/benutzer', description: 'Nutzer'}, {link: '/a/benutzer/' + user.id, description: user.full_name}]" [root]="'user'" [leafDescription]="'Passwort neu setzen'">
  <form class="normal-form" [formGroup]="passwordForm" style="width: 100%; max-width: 800px;" (submit)="onSubmit()">
    <div style="flex: 1;" class="card-body responsive_split">
      <mat-form-field style="margin-right: 8px" appearance="outline">
        <mat-label>Neues Passwort</mat-label>
        <input type="password" matInput formControlName="new_password">
        <mat-error *ngIf="passwordForm.controls['password_confirm'].errors?.required">Bitte geben SIe ein neues Passwort ein</mat-error>
      </mat-form-field>

      <mat-form-field style="margin-right: 8px" appearance="outline">
        <mat-label>Passwort Wiederholung</mat-label>
        <input type="password" matInput formControlName="password_confirm">
        <mat-error *ngIf="passwordForm.controls['password_confirm'].errors?.required">Bitte bestätigen Sie das Passwort</mat-error>
        <mat-error *ngIf="passwordForm.controls['password_confirm'].errors?.confirmedValidator">Die beiden Passwörter stimmen nicht überein</mat-error>
      </mat-form-field>
    </div>
    <mat-checkbox style="margin-left: 6px;" color="primary" [(ngModel)]="sendEmail" [ngModelOptions]="{standalone: true}">Zugangsdaten per E-Mail ({{user.email1}}) zusenden</mat-checkbox>
    <br>
    <div style="margin: 64px 8px 8px;" class="flex_row">
      <button mat-raised-button color="primary" class="primary_bg_color_button" [disabled]="!passwordForm.valid"> Passwort speichern </button>
      <a [routerLink]="'/benutzer/' + user.id">
        <button type="button" style="margin-left: 8px;" mat-button> Abbrechen </button>
      </a>
    </div>
  </form>
</hawk-record-navigation>