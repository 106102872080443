<div class="">
    <div class="d-flex align-items-center">
      <div class="col t-header d-flex align-items-center justify-content-start">
        <h4 class="mb20 d-flex align-items-center gap-1"><i class="fa fa-building-o"></i>Lager</h4>
      </div>
      <div class="col quicksettings">
        <input type="text" (input)="quicksearchChanged($event)" placeholder="Schnellfilter">
      </div>
    </div>
    <ag-grid-angular class="ag-theme-quartz" style="height:800px; width: 100%;"
      [theme]="hawkTheme" [loadThemeGoogleFonts]="true"
      [defaultColDef]="defaultColDef" [columnDefs]="colDefs" [rowClassRules]="rowClassRules"
      [pagination]="true"
      [rowData]="warehouses"
      [rowSelection]="rowSelection" [initialState]="initialState"
      [paginationPageSize]="paginationPageSize" [localeText]="locale" [sideBar]="sideBarOptions"
      [paginationPageSizeSelector]="paginationPageSizeSelector" (gridReady)="onGridReady($event)">
    </ag-grid-angular>
    <div class="g-actions">
      <a href="/warehouse/warehouse/create" target="_blank" class="btn btn-primary">
        Neues Lager
      </a>
    </div>
  </div>