import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { faChevronDown, faChevronRight, faChevronUp, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { OWClient, OWJob, OWLocation, OWObjective, OWOperator, OWOperatorDay, OWPlannedJob, OWOperatorDayTemplate } from 'src/app/_models/optiwae.interface';
import { Priority } from 'src/app/_models/priority.interface';
import { OptiwaeService } from 'src/app/_services/optiwae.service';
import { CalendarComponent } from '../calendar/calendar.component';
import { PAOperation } from '../classes/operation';
import { PAProject } from '../classes/project';
import { PATechnician } from '../classes/technician';
import { PAUtil } from '../classes/util';
import { NgIf } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { CommonModule } from "@angular/common";
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@Component({
  selector: 'hawk-optiwae-connector',
  templateUrl: './optiwae-connector.component.html',
  styleUrls: ['./optiwae-connector.component.scss', './../styles/common_styles.scss'],
  standalone: true,
  imports: [NgIf, ReactiveFormsModule, FormsModule, CommonModule, FontAwesomeModule]
})
export class OptiwaeConnectorComponent implements OnInit, OnChanges{

  username: string
  password: string

  faChevronUp = faChevronUp
  faChevronDown = faChevronDown
  faChevronRight = faChevronRight
  faPlus = faPlus
  faTrash = faTrash

  public Util = PAUtil
  public Project = PAProject
  public CalendarComponent = CalendarComponent

  @Input() public technicians: PATechnician[] = []
  @Input() public openOperations: PAOperation[] = []

  @Output() public closeComponentEvent = new EventEmitter<void>()
  public owOperators: OWOperator[] = []
  public owJobs: OWJob[] = []
  public owOperatorDays: OWOperatorDay[] = []
  public owOperatorDayTemplates: OWOperatorDayTemplate[] = []
  public owObjectives: OWObjective[] = []
  public owClients: OWClient[] = []
  public owPlannedJobs: {planned_job: OWPlannedJob, job: OWJob}[] = []  
  public owLocations: OWLocation[] = []
  public owUnplannedJobs: OWJob[] = []
  public owFromDate = ''
  public owUntilDate = ''
  public dateTimestampRange: number[] = []

  public selectedOperatorsInDataSelection: OWOperator[] = []
  public selectedOperatorDaysInDataSelection: OWOperatorDay[] = []
  public selectedJobDataInDataSelection: {planned_job: OWPlannedJob, job: OWJob}[] = []
  public selectedOpenJobsInDataSelection: OWJob[] = []

  static optiwaeExamples: any[] = []

  // UI
  public showOperatorsInDataSelection = false
  public showOpenJobsInDataSelection = false
  public expandedOperatorsInSelection: OWOperator[] = []
  public expandedOperatorDaysInSelection: OWOperatorDay[] = []

  // Modals
  public showOperatorSelectionModal = false
  public operatorSelectionModalTechnicians: PATechnician[] = []
  public operatorSelectionModalShownTechnicians: PATechnician[] = []
  public operatorSelectionModalTechniciansGroupFilter = 'all'
  public operatorSelectionModalTechniciansProjectFilter = 'all'
  public operatorSelectionModalTechniciansNameFilter = ''
  public operatorSelectionModalTechniciansPageIdx = 0
  public operatorSelectionModalSelectedTechnicians: PATechnician[] = []

  public showOpenJobSelectionModal = false
  public openJobSelectionModalOperations: PAOperation[] = []
  public openJobSelectionModalShownOperations: PAOperation[] = []
  public openJobSelectionModalOperationsProjectFilter: PAProject = null
  public openJobSelectionModalOperationsPriorityFilter: Priority = null
  public openJobSelectionModalOperationsNameFilter = ''
  public openJobSelectionModalOperationsPageIdx = 0
  public openJobSelectionModalSelectedOperations: PAOperation[] = []

  constructor(
    public optiwaeService: OptiwaeService
  ) {
  }

  ngOnInit(): void {
    //this.updateDateRange()
  }

  ngOnChanges(changes: SimpleChanges): void {
    
    /*let technician_changes = changes['technicians']
    if (technician_changes) {
      this.updateOperatorSelectionModalTechnicians()
    }

    let operation_changes = changes['openOperations']
    if (operation_changes) {
      this.updateOpenJobSelectionModalOperations()
    }*/
  }

  public async login() {
    await this.optiwaeService.login(this.password, this.username)
  }

  /*public updateDateRange(): void {
    this.dateTimestampRange = []
    const start_timestamp = PAUtil.dateToTimestamp(new Date(Date.parse(this.owFromDate)), true, true)
    const end_timestamp = PAUtil.dateToTimestamp(new Date(Date.parse(this.owUntilDate)), true, true)
    let current_timestamp = start_timestamp
    while (current_timestamp <= end_timestamp) {
      this.dateTimestampRange.push(current_timestamp)
      current_timestamp += 24 * 60 * 60 * 1000
    }
    this.setDayDataForOptiwae()
  }

  async setDayDataForOptiwae() {
    this.owOperators = []
    this.owJobs = []
    this.owOperatorDays = []
    this.owOperatorDayTemplates = []
    this.owObjectives = []
    this.owPlannedJobs = []
    this.owClients = []
    this.owLocations = []
    this.owUnplannedJobs = []
    this.selectedOperatorsInDataSelection = []
    this.selectedOperatorDaysInDataSelection = []
    this.selectedJobDataInDataSelection = []
    this.selectedOpenJobsInDataSelection = []

/!*     let selectedOperators = this.parentPlanningAssistantComponent.technicians.filter(operator => (operator.address_latitude > 0 && operator.address_longitude > 0)).slice(0, 10)
    await this.addOperatorsToOptiwaeData(selectedOperators)

    let selectedJobs = this.parentPlanningAssistantComponent.unassignedOperations.filter(job => (job.address_latitude > 0 && job.address_longitude > 0)).slice(0, 30)
    await this.addJobsToOptiwaeData(selectedJobs)
    this.updateUnplannedJobs() *!/
  }

  async addOperatorsToOptiwaeData(operators: PATechnician[]) {

    for (let operator of operators) {
      const ow_operator: OWOperator = this.operatorToOWOperator(operator)
      this.owOperators.push(ow_operator)

      await this.calculateOWOperatorDistancesToOWJobs([ow_operator], this.owJobs);

      await this.addOperatorsOperatorDayDataToOptiwae(operator)
    }
  }

  removeOperatorsFromOptiwaeData(operators: OWOperator[]): void {
    this.owOperators = this.owOperators.filter(operator => operators.indexOf(operator) < 0)
    const remove_operator_days = this.owOperatorDays.filter(operator_day => operators.filter(operator => operator_day.operator_id == operator.operator_id).length > 0)
    this.owOperatorDays = this.owOperatorDays.filter(operator_day => remove_operator_days.indexOf(operator_day) < 0)
    this.owPlannedJobs = this.owPlannedJobs.filter(job_object => remove_operator_days.filter(operator_day => operator_day.operator_id == job_object.planned_job.operator_id).length == 0)
  }

  removeOpenJobsFromOptiwaeData(open_jobs: OWJob[]): void {
    this.owJobs = this.owJobs.filter(job => open_jobs.indexOf(job) < 0)
    this.owUnplannedJobs = this.owUnplannedJobs.filter(job => open_jobs.indexOf(job) < 0)
  }

  async addOperatorsOperatorDayDataToOptiwae(operator: PATechnician) {
    
    const start_timestamp = PAUtil.dateToTimestamp(new Date(Date.parse(this.owFromDate)), true, true)
    const end_timestamp = PAUtil.dateToTimestamp(new Date(Date.parse(this.owUntilDate)), true, true)
    let current_timestamp = start_timestamp
    while (current_timestamp <= end_timestamp) {
      const operator_day = PATechnician.getTechnician(operator.id).getTechnicianDate(current_timestamp, true, true)
      await operator_day.waitUntilDataWasLoaded()
      await this.addOperatorDayToOptiwaeData(operator_day)
      current_timestamp += 24 * 60 * 60 * 1000
    }

  }

  async addOperatorDayToOptiwaeData(operator_day: PATechnicianDate) {
    await this.addPlannedJobsToOptiwaeData(operator_day.operations)
    let day_date = new Date(operator_day.day.timestamp)
    const day_idx = day_date.getDay()
    this.owOperatorDays.push({
      type: 'OperatorDay',
      operator_day_id: this.getOperatorDayID(operator_day),
      operator_id: operator_day.uid,
      day: {
        type: 'SerializableDateTime',
        year: day_date.getFullYear(),
        month: day_date.getMonth() + 1,
        day: day_date.getDate(),
        hour: 0,
        minute: 0,
        second: 0,
        microsecond: 0,
        tzinfo: null
      },
      tour_starting_location_id: this.getOWOperator(operator_day.uid).location_id,
      tour_ending_location_id: this.getOWOperator(operator_day.uid).location_id,
      max_work_time: {
        type: 'SerializableTimeDelta',
        days: 0,
        seconds: day_idx? 600 * 60 : 0,
        microseconds: 0
      },
      work_window_start_time: '07:00',
      work_window_end_time: '21:00',
      traveler: false
    })
  }

  async addPlannedJobsToOptiwaeData(jobs: PAOperation[]) {
    for (let job of jobs) {
      //add operation data
      await this.addPlannedJobToOWData(job)
    }
  }

  async addJobsToOptiwaeData(jobs: PAOperation[]) {
    for (let job of jobs) {
      await this.addJobToOWData(job)
    }
  }

  private async calculateOWOperatorDistancesToOWJobs(ow_operators: OWOperator[], ow_jobs: OWJob[]) {

    for (let ow_job of ow_jobs) {

      for (let ow_operator of ow_operators) {

        const job_client = this.getOWClient(ow_job.client_id)

        if (job_client.location_id != ow_operator.location_id) {

          let match_objective = this.owObjectives.filter(objective => this.objectiveMatchesObjectsLocationIDs(objective, job_client, ow_operator))

          if (!match_objective.length) {

            /!* let travel_data = await this.parentPlanningAssistantComponent.getTravelDataFromOperationToCoordinates(operation, technician.address_latitude, technician.address_longitude);
            let objective: OWObjective = {
              type: 'Objective',
              location_id_1: ow_operator.location_id,
              location_id_2: this.getOWClient(ow_job.client_id).location_id,
              distance_km: travel_data.distance,
              travel_time: travel_data.duration
            };
            this.owObjectives.push(objective); *!/

          }

        }

      }

    }

  }

  private async calculateOWJobsDistancesToOWJobs(ow_jobs_1: OWJob[], ow_jobs_2: OWJob[]) {

    for (let ow_job_1 of ow_jobs_1) {

      for (let ow_job_2 of ow_jobs_2) {

        const client_1 = this.getOWClient(ow_job_1.client_id)
        const client_2 = this.getOWClient(ow_job_2.client_id)

        if (client_1.location_id != client_2.location_id) {

          let match_objective = this.owObjectives.filter(objective => this.objectiveMatchesObjectsLocationIDs(objective, client_1 , client_2))

          if (!match_objective.length) {

            let operation_1 = this.abstractionLayer.getOperation(ow_job_1.job_id)
            let operation_2 = this.abstractionLayer.getOperation(ow_job_2.job_id)

            /!* let travel_data = await this.parentPlanningAssistantComponent.getTravelDataFromOperationToCoordinates(operation_1, operation_2.address_latitude, operation_2.address_longitude);
            let objective: OWObjective = {
              type: 'Objective',
              location_id_1: this.getOWClient(ow_job_1.client_id).location_id,
              location_id_2: this.getOWClient(ow_job_2.client_id).location_id,
              distance_km: travel_data.distance,
              travel_time: travel_data.duration
            };
            this.owObjectives.push(objective); *!/

          }

        }

      }

    }

  }

  public objectiveMatchesObjectsLocationIDs(objective: OWObjective, location_id_object_1: OWHasLocationID, location_id_object_2: OWHasLocationID) {
    const location_id_1 = location_id_object_1.location_id
    const location_id_2 = location_id_object_2.location_id
    return (objective.location_id_1 == location_id_1 && objective.location_id_2 == location_id_2) || (objective.location_id_1 == location_id_2 && objective.location_id_2 == location_id_1)
  }

  operatorToOWOperator(operator: PATechnician): OWOperator {
    let home_location_id = operator.location.location_id
    let ow_location = operator.location.toOwLocation()
    this.owLocations.push(ow_location)

    return {
      type: 'Operator',
      operator_id: operator.id, 
      name: operator.firstname + ' ' + operator.lastname, 
      location_id: home_location_id,
      properties: ['uid:' + operator.id.toString()],
      external_id: operator.id.toString()
    }
  }

  async addJobToOWData(job: PAOperation): Promise<OWJob> {

    let ow_client: OWClient
    let ow_location: OWLocation

    ow_location = job.client.location.toOwLocation()
    this.owLocations.push(ow_location)

    ow_client = {
      type: 'Client',
      client_id: job.client.client_id,
      name: job.ticket.address_company,
      location_id: ow_location.location_id
    }
    this.owClients.push(ow_client)

    let ow_job: OWJob
    let match_job = this.owJobs.filter(ow_job => ow_job.job_id == job.id)
    if (match_job.length > 0) {
      ow_job = match_job[0]
    } else {
      ow_job = {
        type: 'Job',
        job_id: job.id,
        client_id: ow_client.client_id, 
        job_date_ranges: this.generateJobOWDateRanges(job),
        time_estimate: this.generateJobOWTimeEastimate(job),
        needs_properties: job.needs_properties,
        preferred_properties: job.preferred_properties,
        external_id: job.id.toString()
      }
      this.owJobs.push(ow_job)
    }
    return ow_job
  }

  async addPlannedJobToOWData(job: PAOperation): Promise<void> {

    let ow_job = await this.addJobToOWData(job)

    let ow_plannedjob = {
      type: 'PlannedJob',
      job_id: job.id,
      dt: this.getJobsOWDate(job),
      operator_id: job.user_ids[0]
    }

    this.owPlannedJobs.push({planned_job: ow_plannedjob, job: ow_job})
  }

  getJobsOWDate(job: PAOperation): OWDate {
    const job_date = new Date(job.operation_date)
    return {
      type: 'SerializableDateTime',
      year: job_date.getFullYear(),
      month: job_date.getMonth() + 1,
      day: job_date.getDate(),
      hour: job_date.getHours(),
      minute: job_date.getMinutes(),
      second: job_date.getSeconds(),
      microsecond: job_date.getMilliseconds() * 1000,
      tzinfo: null
    }
  }

  getJobsOperatorDayID(job: PAOperation): number {
    const operator_day = PATechnician.getTechnician(job.user_ids[0]).getTechnicianDate(PAUtil.dateToTimestamp(new Date(job.operation_date), true, true))
    return this.getOperatorDayID(operator_day)
  }

  getOperatorDayID(operator_day: PATechnicianDate): number {
    if (typeof operator_day.id == 'undefined') {
      operator_day.id = this.abstractionLayer.generateNextOperatorDayId()
    }
    return operator_day.id
  }

  generateJobOWDateRanges(job: PAOperation): OWDateRange[] {
    const ow_date_ranges: OWDateRange[] = []
    const start_timestamp = PAUtil.dateToTimestamp(new Date(Date.parse(this.owFromDate)), true, true)
    const end_timestamp = PAUtil.dateToTimestamp(new Date(Date.parse(this.owUntilDate)), true, true)
    let current_timestamp = start_timestamp
    while (current_timestamp <= end_timestamp) {

      const current_date = new Date(current_timestamp)

      const ow_date_from: OWDate = {
        type: 'SerializableDateTime',
        year: current_date.getFullYear(),
        month: current_date.getMonth() + 1,
        day: current_date.getDate(),
        hour: 8,
        minute: 0,
        second: 0,
        microsecond: 0,
        tzinfo: null
      }

      const ow_date_until: OWDate = {
        type: 'SerializableDateTime',
        year: current_date.getFullYear(),
        month: current_date.getMonth() + 1,
        day: current_date.getDate(),
        hour: 20,
        minute: 0,
        second: 0,
        microsecond: 0,
        tzinfo: null
      }

      ow_date_ranges.push({
        type: 'JobDateRange',
        begin: ow_date_from,
        end: ow_date_until,
        repeat_frequency: -1,
        repeats: 1
      })

      current_timestamp += 24 * 60 * 60 * 1000
    }

    return ow_date_ranges
  }

  generateJobOWTimeEastimate(job: PAOperation): OWTime {
    return {
      type: 'SerializableTimeDelta',
      days: 0,
      seconds: job.ticket.time_estimation * 60,
      microseconds: 0,
    }
  }

  getOWClient(id: number): OWClient {
    return this.owClients.filter(client => client.client_id == id)[0]
  }

  getOWJob(id: number): OWJob {
    return this.owJobs.filter(job => job.job_id == id)[0]
  }

  getOWOperator(id: number): OWOperator {
    return this.owOperators.filter(operator => operator.operator_id == id)[0]
  }

  getOWLocation(id: number): OWLocation {
    return this.owLocations.filter(location => location.location_id == id)[0]
  }

  getOWOperatorDay(operator_id: number, timestamp: number): OWOperatorDay {
    const day_date = new Date(timestamp)
    const year = day_date.getFullYear()
    const month = day_date.getMonth() + 1
    const day = day_date.getDate()

    return this.owOperatorDays.filter(operator_day => 
      operator_day.operator_id == operator_id
      && operator_day.day.day == day
      && operator_day.day.month == month
      && operator_day.day.year == year)[0]
  }

  public async getOptiwaeOptimization() {

    let data: OWAbstractionData = {
      operators: [... new Set(this.owOperators)],
      jobs: [... new Set(this.owJobs)],
      operator_day_templates: [...new Set(this.owOperatorDays)].map(operator_day => PATechnician.getTechnician(operator_day.operator_id).getTechnicianDate(PAUtil.owDateToDayTimestamp(operator_day.day), true).toOWOperatorDayTemplate()),
      objectives: 'auto',
      clients: [... new Set(this.owClients)],
      planned_jobs: [... new Set(this.owPlannedJobs.map(job => job.planned_job))],
      locations: [... new Set(this.owLocations)],
    }

    let request_body = this.optiwaeService.createOptimizationRequestBody(data)
    let request = this.optiwaeService.createRequest("Optimize", request_body)

    this.closeComponentEvent.emit()

    let answer = await this.optiwaeService.sendRequestAndWaitForAnswer(request)

    if (answer.http_status_code == 200) {
      let optimization_anwer_body = answer.body as OWOptimizationAnswerBody

      //this.abstractionLayer.importLocationDistances(optimization_anwer_body.result.objectives)

      // Update Operator Days
      /!* for (let operator_day of optimization_anwer_body.result.operator_days) {
        let operator_day_before = PATechnician.getTechnician(operator_day.operator.operator_id).getTechnicianDate(PAUtil.owDateInputToDayTimestamp(operator_day.day))
        operator_day_before.updateWithOWOperatorDayInput(operator_day)
      } *!/

      // Update Planned Jobs
      for (let planned_job of optimization_anwer_body.result.planned_jobs) {
        let planned_jobs_job_before = this.abstractionLayer.getOperation(planned_job.job.job_id)
        planned_jobs_job_before.updateJobWithOWPlannedJobInput(planned_job)
      }

      // Update Operatin Changes
      //PAOperation.updateOperationChanges('manual', true)

    }
    console.log(answer)
  }

  public downloadOWJSON(content: string): void {
    var a = document.createElement("a");
    var file = new Blob([content], {type: "text/plain"});
    a.href = URL.createObjectURL(file);
    a.download = 'optiwae_content.json';
    a.click();
  }

  public changeOWFromDate(date_value: string): void {
    this.owFromDate = date_value
    this.updateDateRange()
  }

  public changeOWUntilDate(date_value: string): void {
    this.owUntilDate = date_value
    this.updateDateRange()
  }

  private removeElementFromList(list: any[], elem: any): void {
    if (list.indexOf(elem) >= 0) {
      list.splice(list.indexOf(elem),1)
    }
  }

  public toggleOperatorSelectionExpansion(operator: OWOperator): void {
    if (this.expandedOperatorsInSelection.indexOf(operator) < 0) {
      this.expandedOperatorsInSelection.push(operator)
    } else {
      this.removeElementFromList(this.expandedOperatorsInSelection, operator)
    }
  }

  public toggleOperatorDaySelectionExpansion(operator_day: OWOperatorDay): void {
    if (this.expandedOperatorDaysInSelection.indexOf(operator_day) < 0) {
      this.expandedOperatorDaysInSelection.push(operator_day)
    } else {
      this.removeElementFromList(this.expandedOperatorDaysInSelection, operator_day)
    }
  }

  public getPlannedJobsOnOperatorDay(operator_day: OWOperatorDay): {planned_job: OWPlannedJob, job: OWJob}[] {
    return this.owPlannedJobs.filter(planned_job => planned_job.planned_job.operator_id == operator_day.operator_id && PAUtil.owDateToDayTimestamp(planned_job.planned_job.dt) == PAUtil.owDateToDayTimestamp(operator_day.day))
  }

  public getSelectedPlannedJobsOnOperatorDay(operator_day: OWOperatorDay): {planned_job: OWPlannedJob, job: OWJob}[] {
    return this.selectedJobDataInDataSelection.filter(planned_job => planned_job.planned_job.operator_id == operator_day.operator_id && PAUtil.owDateToDayTimestamp(planned_job.planned_job.dt) == PAUtil.owDateToDayTimestamp(operator_day.day))
  }

  public getOperatorDayWorktimeString(operator_day: OWOperatorDay): string {
    let minutes = this.getOperatorDayWorktimeInMinutes(operator_day)
    let hours = Math.floor(minutes / 60)
    let plus_minutes = minutes % 60
    let res = hours.toString() + 'h'
    if (plus_minutes) {
      res = res + ' und ' + plus_minutes.toString() + 'min'
    }
    return  res
  }

  public getOperatorDayWorktimeWindowString(operatorDay: OWOperatorDay): string {
    return operatorDay.work_window_start_time + 'Uhr - ' + operatorDay.work_window_end_time + 'Uhr'
  }

  public OWTimeToTimeString(ow_time: OWTime): string{
    let hours = Math.floor(ow_time.seconds / 3600)
    let plus_minutes = (ow_time.seconds % 3600) / 60
    let res = ''
    if (hours) {
      res = hours.toString() + 'h '
    }

    if (plus_minutes) {
      if (plus_minutes < 10) {
        res = res + '0' + plus_minutes.toString() 
      } else {
        res = res + plus_minutes.toString()
      }
      res = res + 'min'
    }

    return res
  }

  public getOperatorDayWorktimeInMinutes(operator_day: OWOperatorDay): number {
    return operator_day.max_work_time.seconds / 60
  }

  public timestampToWeekIdx(timestamp: number): number {
    const date = new Date(timestamp)
    return date.getDay()
  }

  public dayIdxToGermanWeekday(day_idx: number, short_name?: boolean): string {
    const week_days = ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag']
    let week_day_string = week_days[day_idx % 7]
    if (short_name) {
      week_day_string = week_day_string.slice(0,2)
    }
    return week_day_string
  }

  public getUnplannedJobs(): OWJob[] {
    this.updateUnplannedJobs()
    return this.owUnplannedJobs
  }

  public updateUnplannedJobs(): void {
    let planned_job_ids = this.owPlannedJobs.map(job => job.planned_job.job_id)
    this.owUnplannedJobs = this.owJobs.filter(job => planned_job_ids.indexOf(job.job_id) < 0)
  }

  public owDateToDayTimestring(ow_date: OWDate): string {
    let time_string = ''
    if (ow_date.hour < 10) {
      time_string = time_string + '0' + ow_date.hour.toString()
    } else {
      time_string = time_string + ow_date.hour.toString()
    }
    time_string = time_string + ':'
    if (ow_date.minute < 10) {
      time_string = time_string + '0' + ow_date.minute.toString()
    } else {
      time_string = time_string + ow_date.minute.toString()
    }
    return time_string + 'Uhr'
  }

  public jobsSlaAndAppointmentDateInformation(job: OWJob): string[] {
    let operation = this.abstractionLayer.getOperation(job.job_id)
    const res = []
    if (operation.sla_date) {
      let date_string = PAUtil.dateToDatestring(new Date(operation.sla_date), false, true, '.')
      let time_string = PAUtil.dateToTimestring(new Date(operation.sla_date), false)
      res.push('SLA: ' + date_string + ' ' + time_string + 'Uhr')
    }
    if (operation.appointment_date) {
      let date_string = PAUtil.dateToDatestring(new Date(operation.appointment_date), false, true, '.')
      let time_string = PAUtil.dateToTimestring(new Date(operation.appointment_date), false)
      res.push('Termin: ' + date_string + ' ' + time_string + 'Uhr')
    }
    return res
  }

  public toggleOperatorSelectionInDataSelection(operator: OWOperator): void {
    if (this.selectedOperatorsInDataSelection.indexOf(operator) < 0) {
      this.selectedOperatorsInDataSelection.push(operator)
    } else {
      this.removeElementFromList(this.selectedOperatorsInDataSelection, operator)
    }
  }

  public toggleOperatorDaySelectionInDataSelection(operator_day: OWOperatorDay): void {
    if (this.selectedOperatorDaysInDataSelection.indexOf(operator_day) < 0) {
      this.selectedOperatorDaysInDataSelection.push(operator_day)
    } else {
      this.removeElementFromList(this.selectedOperatorDaysInDataSelection, operator_day)
    }
  }

  public toggleJobDataSelectionInDataSelection(job_data: {planned_job: OWPlannedJob, job: OWJob}): void {
    if (this.selectedJobDataInDataSelection.indexOf(job_data) < 0) {
      this.selectedJobDataInDataSelection.push(job_data)
    } else {
      this.removeElementFromList(this.selectedJobDataInDataSelection, job_data)
    }
  }

  public toggleOpenJobSelectionInDataSelection(open_job: OWJob): void {
    if (this.selectedOpenJobsInDataSelection.indexOf(open_job) < 0) {
      this.selectedOpenJobsInDataSelection.push(open_job)
    } else {
      this.removeElementFromList(this.selectedOpenJobsInDataSelection, open_job)
    }
  }

  public allOperatorsSelectedInDataSelection(): boolean {
    let xs = new Set(this.selectedOperatorsInDataSelection)
    let ys = new Set(this.owOperators)
    return this.eqSet(xs, ys)
  }

  public allOpenJobsSelectedInDataSelection(): boolean {
    let xs = new Set(this.selectedOpenJobsInDataSelection)
    let ys = new Set(this.owUnplannedJobs)
    return this.eqSet(xs, ys)
  }

  public clickAllOperatorsDataSelectionButton(): void {
    if (this.allOperatorsSelectedInDataSelection()) {
      this.deselectAllOperatorsInDataSelection()
    } else {
      this.selectAllOperatorsInDataSelection()
    }
  }

  public clickAllOpenJobsDataSelectionButton(): void {
    if (this.allOpenJobsSelectedInDataSelection()) {
      this.deselectAllOpenJobsInDataSelection()
    } else {
      this.selectAllOpenJobsInDataSelection()
    }
  }

  public removeSelectedOperatorsFromOptiwaeData(): void {
    this.removeOperatorsFromOptiwaeData(this.selectedOperatorsInDataSelection)
    this.deselectAllOperatorsInDataSelection()
  }

  public selectAllOperatorsInDataSelection(): void {
    this.selectedOperatorsInDataSelection = [...this.owOperators]
    this.selectedOperatorDaysInDataSelection = [...this.owOperatorDays]
    let selected_job_data = []
    for (let operator_day of this.selectedOperatorDaysInDataSelection) {
      for (let job_data of this.getPlannedJobsOnOperatorDay(operator_day)) {
        selected_job_data.push(job_data)
      }
    }
    this.selectedJobDataInDataSelection = selected_job_data
  }

  public deselectAllOperatorsInDataSelection(): void {
    this.selectedOperatorsInDataSelection = []
    this.selectedOperatorDaysInDataSelection = []
    this.selectedJobDataInDataSelection = []
  }

  public removeSelectedOpenJobsFromOptiwaeData(): void {
    this.removeOpenJobsFromOptiwaeData(this.selectedOpenJobsInDataSelection)
    this.deselectAllOpenJobsInDataSelection()
  }

  public selectAllOpenJobsInDataSelection(): void {
    this.selectedOpenJobsInDataSelection = [...this.owUnplannedJobs]
  }

  public deselectAllOpenJobsInDataSelection(): void {
    this.selectedOpenJobsInDataSelection = []
  }

  public checkJobObjectSelection(job_data: {planned_job: OWPlannedJob, job: OWJob}): boolean {
    return this.selectedJobDataInDataSelection.filter(job_object => job_object.job == job_data.job && job_object.planned_job == job_data.planned_job).length > 0
  }

  public allOperatorDaysSelectedInDataSelection(operator: OWOperator): boolean {
    let xs = new Set(this.selectedOperatorDaysInDataSelection.filter(operator_day => operator_day.operator_id == operator.operator_id))
    let ys = new Set(this.owOperatorDays.filter(operator_day => operator_day.operator_id == operator.operator_id))
    return this.eqSet(xs, ys)
  }

  public clickAllOperatorDaysDataSelectionButton(operator: OWOperator): void {
    if (this.allOperatorDaysSelectedInDataSelection(operator)) {
      this.deselectAllOperatorDaysInDataSelection(operator)
    } else {
      this.selectAllOperatorDaysInDataSelection(operator)
    }
  }

  public selectAllOperatorDaysInDataSelection(operator: OWOperator): void {
    const operators_operator_days = this.owOperatorDays.filter(operator_day => operator_day.operator_id == operator.operator_id)
    this.selectedOperatorDaysInDataSelection = [...new Set(this.selectedOperatorDaysInDataSelection.concat(operators_operator_days))] 
    for (let operator_day of operators_operator_days) {
      this.selectAllJobsOnOperatorDayInDataSelection(operator_day)
    }
  }

  public deselectAllOperatorDaysInDataSelection(operator: OWOperator): void {
    let operator_days_to_deselect = this.selectedOperatorDaysInDataSelection.filter(operator_day => operator_day.operator_id == operator.operator_id)
    this.selectedOperatorDaysInDataSelection = this.selectedOperatorDaysInDataSelection.filter(operator_day => operator_day.operator_id != operator.operator_id)
    operator_days_to_deselect.map(operator_day => this.deselectAllJobsOnOperatorDayInDataSelection(operator_day))
  }

  public allJobsOnOperatorDaySelectedInDataSelection(operator_day: OWOperatorDay): boolean {
    let xs = new Set(this.getPlannedJobsOnOperatorDay(operator_day))
    let ys = new Set(this.getSelectedPlannedJobsOnOperatorDay(operator_day))
    return this.eqSet(xs, ys)
  }

  public clickAllJobsOnOperatorDayDataSelectionButton(operator_day: OWOperatorDay): void {
    if (this.allJobsOnOperatorDaySelectedInDataSelection(operator_day)) {
      this.deselectAllJobsOnOperatorDayInDataSelection(operator_day)
    } else {
      this.selectAllJobsOnOperatorDayInDataSelection(operator_day)
    }
  }

  public selectAllJobsOnOperatorDayInDataSelection(operator_day: OWOperatorDay) {
    const operator_days_jobs = this.owPlannedJobs.filter(job => operator_day.operator_id == job.planned_job.operator_id && PAUtil.owDateToDayTimestamp(job.planned_job.dt) == PAUtil.owDateToDayTimestamp(operator_day.day))
    this.selectedJobDataInDataSelection = [...new Set(this.selectedJobDataInDataSelection.concat(operator_days_jobs))] 
  }

  public deselectAllJobsOnOperatorDayInDataSelection(operator_day: OWOperatorDay) {
    this.selectedJobDataInDataSelection = this.selectedJobDataInDataSelection.filter(job => job.planned_job.operator_id != operator_day.operator_id || PAUtil.owDateToDayTimestamp(job.planned_job.dt) != PAUtil.owDateToDayTimestamp(operator_day.day))
  }

  public eqSet<T>(xs: Set<T>, ys: Set<T>): boolean {
    return xs.size === ys.size && [...xs].every((x) => ys.has(x))
  }

  /!**
   * @param  {Set<T>} xs The Set
   * @param  {Set<T>} ys The possible Subset
   * @returns true if ys is a subset of xs
   *!/
  public subSet<T>(xs: Set<T>, ys: Set<T>): boolean {
    return [...ys].every((x) => xs.has(x))
  }

  public getOWJobsStoreOpeningTimes(ow_job: OWJob): OpeningTimes {
    let job = this.abstractionLayer.getOperation(ow_job.job_id)
    return job.store_openings
  }

  public getJobsStoreOpeningString(job: OWJob): string {
    return this.openingsToString(this.getOWJobsStoreOpeningTimes(job))
  }

  public openingsToString(openings: OpeningTimes): string {

    let res = ''

    let day_openings: {day_idx: number, day_opening: DayOpening}[] = []
    const week_days = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday']
    const ger_days = ['Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa', 'So']

    for (let week_day of week_days) {
      let day_opening = openings[week_day]
      day_opening ? day_openings.push({day_idx: week_days.indexOf(week_day), day_opening: day_opening}) : {}
    }

    const opening_groups: {day_idx: number, day_opening: DayOpening}[][] = []
    for (let opening of day_openings) {
      if (opening.day_opening.close != opening.day_opening.open) {
        if (opening_groups.length == 0) {
          opening_groups.push([opening])
        } else {
          let last_opening_group = opening_groups[opening_groups.length - 1]
          let last_opening_group_element = last_opening_group[last_opening_group.length - 1]
          if (last_opening_group_element.day_idx == opening.day_idx - 1 && last_opening_group_element.day_opening.open == opening.day_opening.open && last_opening_group_element.day_opening.close == opening.day_opening.close ) {
            last_opening_group.push(opening)
          } else {
            opening_groups.push([opening])
          }
        }
      }
    }

    for (let opening_group of opening_groups) {
      if (opening_group.length == 1) {
        const opening = opening_group[0]
        res = res + ger_days[opening.day_idx] + ': ' + opening.day_opening.open + '-' + opening.day_opening.close + 'Uhr'
      } else if (opening_group.length == 2) {
        res = res + ger_days[opening_group[0].day_idx] + ': ' + opening_group[0].day_opening.open + '-' + opening_group[0].day_opening.close + 'Uhr, '
        res = res + ger_days[opening_group[1].day_idx] + ': ' + opening_group[1].day_opening.open + '-' + opening_group[1].day_opening.close + 'Uhr'
      } else if (opening_group.length > 2) {
        res = res + ger_days[opening_group[0].day_idx] + '-' + ger_days[opening_group[opening_group.length - 1].day_idx] + ': ' + opening_group[0].day_opening.open + '-' + opening_group[0].day_opening.close + 'Uhr'
      }
      if (opening_groups.indexOf(opening_group) != opening_groups.length - 1) {
        res = res + ', '
      }
    }
    

    return res
  }

  public closeOperatorSelectionModal(event: {target: HTMLElement}): void {
    let target_is_modal_backgound = event.target == document.getElementById('operator_selection_modal')
    let target_is_close_button = event.target == document.getElementById('operator_selection_modal_close_button')
    let target_is_abort_button = event.target == document.getElementById('operator_selection_modal_abort_button')
    if ( target_is_modal_backgound || target_is_close_button || target_is_abort_button) {
      this.showOperatorSelectionModal = false
    }
  }

  public closeOpenJobSelectionModal(event: {target: HTMLElement}): void {
    let target_is_modal_backgound = event.target == document.getElementById('open_job_selection_modal')
    let target_is_close_button = event.target == document.getElementById('open_job_selection_modal_close_button')
    let target_is_abort_button = event.target == document.getElementById('open_job_selection_modal_abort_button')
    if ( target_is_modal_backgound || target_is_close_button || target_is_abort_button) {
      this.showOpenJobSelectionModal = false
    }
  }

  public addOperatorSelectionModalSelectedTechniciansToOptiwaeData(): void {
    const technicians_to_add = this.operatorSelectionModalSelectedTechnicians.filter(technician => !this.technicianAlreadyImported(technician))
    this.addOperatorsToOptiwaeData(technicians_to_add)
    this.showOperatorSelectionModal = false
    this.resetOperatorSelectionModal()
  }

  public addOpenJobSelectionModalSelectedOperationsToOptiwaeData(): void {
    const operations_to_add = this.openJobSelectionModalSelectedOperations.filter(operation => !this.operationAlreadyImported(operation))
    this.addUnplannedJobs(operations_to_add)
    this.showOpenJobSelectionModal = false
    this.resetOpenJobSelectionModal()
  }

  async addUnplannedJobs(operations: PAOperation[]): Promise<void> {
    await this.addJobsToOptiwaeData(operations)
    this.updateUnplannedJobs()
  }

  public resetOperatorSelectionModal(): void {
    this.operatorSelectionModalTechniciansGroupFilter = 'all'
    this.operatorSelectionModalTechniciansProjectFilter = 'all'
    this.operatorSelectionModalTechniciansNameFilter = ''
    this.operatorSelectionModalTechniciansPageIdx = 0
    this.operatorSelectionModalSelectedTechnicians = []
    this.updateOperatorSelectionModalTechnicians()
  }

  public resetOpenJobSelectionModal(): void {
    this.openJobSelectionModalOperationsPriorityFilter = null
    this.openJobSelectionModalOperationsProjectFilter = null
    this.openJobSelectionModalOperationsNameFilter = ''
    this.openJobSelectionModalOperationsPageIdx = 0
    this.openJobSelectionModalSelectedOperations = []
    this.updateOpenJobSelectionModalOperations()
  }

  public updateOperatorSelectionModalTechniciansGroupFilter(event: Event): void {
    const input = event.target as HTMLInputElement
    const value: string = input.value

    this.operatorSelectionModalTechniciansGroupFilter = value
    this.updateOperatorSelectionModalTechnicians()
  }

  public updateOperatorSelectionModalTechniciansProjectFilter(event: Event): void {
    const input = event.target as HTMLInputElement
    const value: string = input.value

    this.operatorSelectionModalTechniciansProjectFilter = value
    this.updateOperatorSelectionModalTechnicians()
  }

  public updateOperatorSelectionModalTechniciansNameFilter(): void {
    this.updateOperatorSelectionModalTechnicians()
  }

  public updateOpenJobSelectionModalOperationsPriorityFilter(priority_id: string): void {
    if (priority_id != 'all'){
      this.openJobSelectionModalOperationsPriorityFilter = PAProject.getPriority(Number.parseInt(priority_id))
    } else {
      this.openJobSelectionModalOperationsPriorityFilter = null
    }
    this.updateOpenJobSelectionModalOperations()
  }

  public updateOpenJobSelectionModalOperationsProjectFilter(project_id: string): void {
    if(project_id != 'all') {
      this.openJobSelectionModalOperationsProjectFilter = PAProject.getProject(Number.parseInt(project_id))
    } else {
      this.openJobSelectionModalOperationsProjectFilter = null
    }
    this.updateOpenJobSelectionModalOperations()
  }

  public updateOpenJobSelectionModalOperationsNameFilter(value: string): void {
    this.openJobSelectionModalOperationsNameFilter = value
    this.updateOpenJobSelectionModalOperations()
  }

  public updateOperatorSelectionModalTechnicians(): void {
    let filtered_technicians = PAFilter.filterOutPseudoTechnicians(this.technicians)

    if (this.operatorSelectionModalTechniciansGroupFilter == 'bentomax') {
      filtered_technicians = PAFilter.filterTechniciansForBentomaxTechnicians(filtered_technicians)
    } else if (this.operatorSelectionModalTechniciansGroupFilter == 'external') {
      filtered_technicians = PAFilter.filterTechniciansForExternalTechnicians(filtered_technicians)
    }

    const project_id = Number(this.operatorSelectionModalTechniciansProjectFilter)
    if (this.operatorSelectionModalTechniciansProjectFilter != 'all' && Number.isInteger(project_id)) {
      const project = PAProject.getProject(project_id)
      filtered_technicians = PAFilter.filterTechniciansForProjectInclusiveTechnicians(filtered_technicians, project_id)
    }

    if (this.operatorSelectionModalTechniciansNameFilter != '') {
      filtered_technicians = PAFilter.filterTechniciansForName(filtered_technicians, this.operatorSelectionModalTechniciansNameFilter)
    }

    this.operatorSelectionModalTechnicians = filtered_technicians
    
    this.operatorSelectionModalTechniciansPageIdx = Math.max(Math.min(this.operatorSelectionModalTechniciansPageIdx, Math.ceil(filtered_technicians.length / 16) - 1), 0)
    const page_start_idx = this.operatorSelectionModalTechniciansPageIdx * 16
    const page_end_idx = Math.min(page_start_idx + 16, filtered_technicians.length)
    this.operatorSelectionModalShownTechnicians = filtered_technicians.slice(page_start_idx, page_end_idx)
  }
  
  public updateOpenJobSelectionModalOperations(): void {
    let filtered_operations = this.openOperations

    if (this.openJobSelectionModalOperationsPriorityFilter != null)  {
      filtered_operations = PAFilter.filterOperationsForPriorityInclusiveOperations(filtered_operations, this.openJobSelectionModalOperationsPriorityFilter.id)
    } 

    if (this.openJobSelectionModalOperationsProjectFilter != null) {
      filtered_operations = PAFilter.filterOperationsForProjectInclusiveOperations(filtered_operations, this.openJobSelectionModalOperationsProjectFilter.id)
    }

    if (this.openJobSelectionModalOperationsNameFilter != '') {
      filtered_operations = PAFilter.filterOperationsForName(filtered_operations, this.openJobSelectionModalOperationsNameFilter)
    }

    this.openJobSelectionModalOperations = filtered_operations
    
    this.openJobSelectionModalOperationsPageIdx = Math.max(Math.min(this.openJobSelectionModalOperationsPageIdx, Math.ceil(filtered_operations.length / 16) - 1), 0)
    const page_start_idx = this.openJobSelectionModalOperationsPageIdx * 16
    const page_end_idx = Math.min(page_start_idx + 16, filtered_operations.length)
    this.openJobSelectionModalShownOperations = filtered_operations.slice(page_start_idx, page_end_idx)
  }

  public ceil(number: number): number {
    return Math.ceil(number)
  }

  public allOperatorSelectionModalTechniciansSelected(): boolean {
    let xs = new Set(this.operatorSelectionModalSelectedTechnicians)
    let ys = new Set(this.operatorSelectionModalTechnicians)
    return this.subSet(xs, ys)
  }

  public allOpenJobSelectionModalOperationsSelected(): boolean {
    let xs = new Set(this.openJobSelectionModalSelectedOperations)
    let ys = new Set(this.openJobSelectionModalOperations)
    return this.subSet(xs, ys)
  }

  public clickAllOperatorSelectionModalTechniciansSelectionButton(): void {
    if (this.allOperatorSelectionModalTechniciansSelected()) {
      this.deselectAllOperatorSelectionModalTechnicians()
    } else {
      this.selectAllOperatorSelectionModalTechnicians()
    }
  }

  public clickAllOpenJobSelectionModalOperationsSelectionButton(): void {
    if (this.allOpenJobSelectionModalOperationsSelected()) {
      this.deselectAllOpenJobSelectionModalOperations()
    } else {
      this.selectAllOpenJobSelectionModalOperations()
    }
  }

  public selectAllOperatorSelectionModalTechnicians(): void {
    this.operatorSelectionModalSelectedTechnicians = [... new Set(this.operatorSelectionModalTechnicians.concat(this.operatorSelectionModalSelectedTechnicians))]
  }

  public selectAllOpenJobSelectionModalOperations(): void {
    this.openJobSelectionModalSelectedOperations = [... new Set(this.openJobSelectionModalOperations.concat(this.openJobSelectionModalSelectedOperations))]
  }

  public deselectAllOperatorSelectionModalTechnicians(): void {
    this.operatorSelectionModalSelectedTechnicians = this.operatorSelectionModalSelectedTechnicians.filter(technician => this.operatorSelectionModalTechnicians.indexOf(technician) < 0)
  }

  public deselectAllOpenJobSelectionModalOperations(): void {
    this.openJobSelectionModalSelectedOperations = this.openJobSelectionModalSelectedOperations.filter(operation => this.openJobSelectionModalOperations.indexOf(operation) < 0)
  }

  public toggleOperatorSelectionModalTechnicianSelection(technician: PATechnician): void {
    if (this.operatorSelectionModalSelectedTechnicians.indexOf(technician) < 0) {
      this.operatorSelectionModalSelectedTechnicians.push(technician)
    } else {
      this.removeElementFromList(this.operatorSelectionModalSelectedTechnicians, technician)
    }
  }

  public toggleOpenJobSelectionModalOperationSelection(operation: PAOperation): void {
    if (this.openJobSelectionModalSelectedOperations.indexOf(operation) < 0) {
      this.openJobSelectionModalSelectedOperations.push(operation)
    } else {
      this.removeElementFromList(this.openJobSelectionModalSelectedOperations, operation)
    }
  }

  public increaseOperatorSelectionModalTechniciansPageIdx(): void {
    this.operatorSelectionModalTechniciansPageIdx += 1
    this.updateOperatorSelectionModalTechnicians()
  }

  public decreaseOperatorSelectionModalTechniciansPageIdx(): void {
    this.operatorSelectionModalTechniciansPageIdx -= 1
    this.updateOperatorSelectionModalTechnicians()
  }

  public increaseOpenJobSelectionModalOperationsPageIdx(): void {
    this.openJobSelectionModalOperationsPageIdx += 1
    this.updateOpenJobSelectionModalOperations()
  }

  public decreaseOpenJobSelectionModalOperationsPageIdx(): void {
    this.openJobSelectionModalOperationsPageIdx -= 1
    this.updateOpenJobSelectionModalOperations()
  }

  public technicianAlreadyImported(technician: PATechnician): boolean {
    return this.owOperators.filter(operator => operator.operator_id == technician.id).length > 0
  }

  public operationAlreadyImported(operation: PAOperation): boolean {
    return this.owUnplannedJobs.filter(job => job.job_id == operation.id).length > 0
  }
*/
}