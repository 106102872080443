
<div *ngIf="!optiwaeService.sessionId" style="width: 100%; text-align: center; color: white; padding: 40px 80px; display: inline-flex;">
  <div class="blue_gradient_background_no_hover" style="margin: auto; border: 2px solid black; padding: 20px 40px; display: inline-flex; flex-direction: column; border-radius: 8px;">
    <h3> LinkGuide Login </h3>
    <input style="width: 200px; margin: auto; margin-top: 6px;" type="text" placeholder="username" [(ngModel)]="username">
    <input style="width: 200px; margin: auto; margin-top: 6px;" type="password" placeholder="password" [(ngModel)]="password">
    <button style="width: 200px; margin: auto; margin-top: 6px;" (click)="login()"> Login </button>
  </div>
</div>

<!--<ng-container *ngIf="optiwaeService.sessionId">
  <div *ngIf="showOperatorSelectionModal" id="operator_selection_modal" class="auto-modal" (click)="closeOperatorSelectionModal($event)">
    &lt;!&ndash; Modal content &ndash;&gt;
    <div class="auto-modal-content">
      <span id="operator_selection_modal_close_button" class="close" (click)="closeOperatorSelectionModal($event)">&times;</span>
      
      <div id="operator_selection_filter_container">
        <label for="technician_group_select"> Gruppen Auswahl </label>
        <select id="technician_group_select" (change)="updateOperatorSelectionModalTechniciansGroupFilter($event)">
          <option value="all">Alle</option>
          <option [selected]="operatorSelectionModalTechniciansGroupFilter == 'bentomax'" value="bentomax">Bentomax</option>
          <option [selected]="operatorSelectionModalTechniciansGroupFilter == 'external'" value="external">Extern</option>
        </select>
  
        <label for="technician_project_select"> Projekt Auswahl </label>
        <select style="max-width: 200px;" id="technician_project_select" (change)="updateOperatorSelectionModalTechniciansProjectFilter($event)">
          <option value="all">Alle</option>
          <option [selected]="operatorSelectionModalTechniciansProjectFilter == project.id" *ngFor="let project of Project.loadedProjects" [value]="project.id">{{project.project_name}}</option>
        </select>
      </div>
      
      <div id="operator_selection_input_table_container">
        <table id="operator_selection_input_table">
          <thead>
            <tr style="border-bottom: 2px solid black; background-color: #2f2f2f;">
              <th style="min-width: 45px; max-width: 45px;">
                Id
              </th>
              <th style="min-width: 250px; max-width: 250px;">
                Name <input id="name_search_input" type="text" placeholder="Techniker suchen..." style="font-size: .8rem; margin-left: 8px;" [(ngModel)]="operatorSelectionModalTechniciansNameFilter" (change)="updateOperatorSelectionModalTechniciansNameFilter()">
              </th>
              <th style="min-width: 106px; max-width: 106px;">
                Gruppe
              </th>
              <th>
                <input type="checkbox" (change)="clickAllOperatorSelectionModalTechniciansSelectionButton()" [checked]="allOperatorSelectionModalTechniciansSelected()">
              </th>
            </tr>
          </thead>
          <tbody>
            <tr *ngIf="operatorSelectionModalTechniciansPageIdx != 0">
              <td colspan="5" class="table_page_change" (click)="decreaseOperatorSelectionModalTechniciansPageIdx()">
                <fa-icon [icon]="faChevronUp"></fa-icon>
              </td>
            </tr>
            <tr *ngFor="let technician of operatorSelectionModalShownTechnicians" [ngClass]="technicianAlreadyImported(technician) ? 'already_imported' : ''">
              <td>
                {{technician.id}}
              </td>
              <td>
                <ng-container *ngIf="(technician.firstname + ' ' + technician.lastname).length > 28">
                  {{Util.cutString(technician.firstname + ' ' + technician.lastname, 28)}}...
                </ng-container>
                <ng-container *ngIf="28 >= (technician.firstname + ' ' + technician.lastname).length">
                  {{technician.firstname + ' ' + technician.lastname}}
                </ng-container>
              </td>
              <td>
                <ng-container *ngIf="technician.isBentomaxTechnician()">
                  Bentomax
                </ng-container>
                <ng-container *ngIf="!technician.isBentomaxTechnician()">
                  Extern
                </ng-container>
              </td>
              <td>
                <input type="checkbox" (change)="toggleOperatorSelectionModalTechnicianSelection(technician)" [checked]="operatorSelectionModalSelectedTechnicians.indexOf(technician) >= 0">
              </td>
            </tr>
            <tr *ngIf="!(operatorSelectionModalTechniciansPageIdx >= ceil(operatorSelectionModalTechnicians.length / 16) - 1)">
              <td colspan="5" class="table_page_change" (click)="increaseOperatorSelectionModalTechniciansPageIdx()">
                <fa-icon [icon]="faChevronDown"></fa-icon>
              </td>
            </tr>
          </tbody>
        </table>
        <div style="width: 100%; text-align: center; padding: 10px 20px;font-weight: bold; background-color: #2f2f2f;">
           {{operatorSelectionModalTechnicians.length}} Techniker ({{operatorSelectionModalSelectedTechnicians.length}} ausgewählt)
        </div>
      </div>
  
      <div id="operator_selection_function_container">
        <button (click)="addOperatorSelectionModalSelectedTechniciansToOptiwaeData()" id="operator_selection_modal_add_button">hinzufügen</button>
        <button id="operator_selection_modal_abort_button">abbrechen</button>
      </div>
  
    </div>
  </div>
  
  <div *ngIf="showOpenJobSelectionModal" id="open_job_selection_modal" class="auto-modal" (click)="closeOpenJobSelectionModal($event)">
    &lt;!&ndash; Modal content &ndash;&gt;
    <div class="auto-modal-content">
      <span id="open_job_selection_modal_close_button" class="close" (click)="closeOpenJobSelectionModal($event)">&times;</span>
      
      <div id="open_job_selection_filter_container">
  
        <label for="operation_project_select"> Projekt Auswahl </label>
        <select style="max-width: 180px;" id="operation_project_select" (change)="updateOpenJobSelectionModalOperationsProjectFilter($event)">
          <option value="all">Alle</option>
            <option [selected]="openJobSelectionModalOperationsProjectFilter && openJobSelectionModalOperationsProjectFilter.id == project.id" *ngFor="let project of Project.loadedProjects" [value]="project.id">
              {{project.project_name}}
            </option>
        </select>
  
        <label for="operation_priority_select"> Priorität Auswahl </label>
        <select style="max-width: 105px;" id="operation_priority_select" (change)="updateOpenJobSelectionModalOperationsPriorityFilter($event)">
          <option value="all">Alle</option>
          <ng-container *ngIf="openJobSelectionModalOperationsProjectFilter">
            <option [selected]="openJobSelectionModalOperationsPriorityFilter && openJobSelectionModalOperationsPriorityFilter.id == priority.id" *ngFor="let priority of openJobSelectionModalOperationsProjectFilter.priorities" [value]="priority.id">
              {{priority.name}}
            </option>
          </ng-container>
        </select>
  
      </div>
      
      <div id="open_job_selection_input_table_container">
        <table id="open_job_selection_input_table">
          <thead>
            <tr style="border-bottom: 2px solid black; background-color: #2f2f2f;">
              <th style="min-width: 80px; max-width: 80px;">
                Id
              </th>
              <th style="min-width: 250px; max-width: 250px;">
                Name <input id="name_search_input" type="text" placeholder="Auftrag suchen..." style="font-size: .8rem; margin-left: 8px;" [(ngModel)]="openJobSelectionModalOperationsNameFilter" (change)="updateOpenJobSelectionModalOperationsNameFilter($event)">
              </th>
              <th style="min-width: 162px; max-width: 162px;">
                Projekt
              </th>
              <th style="min-width: 162px; max-width: 162px;">
                Priorität
              </th>
              <th>
                <input type="checkbox" (change)="clickAllOpenJobSelectionModalOperationsSelectionButton()" [checked]="allOpenJobSelectionModalOperationsSelected()">
              </th>
            </tr>
          </thead>
          <tbody>
            <tr *ngIf="openJobSelectionModalOperationsPageIdx != 0">
              <td colspan="5" class="table_page_change" (click)="decreaseOpenJobSelectionModalOperationsPageIdx()">
                <fa-icon [icon]="faChevronUp"></fa-icon>
              </td>
            </tr>
            <tr *ngFor="let operation of openJobSelectionModalShownOperations" [ngClass]="operationAlreadyImported(operation) ? 'already_imported' : ''">
              <td>
                {{operation.id < 0 ? 'NEU' : operation.id}}
              </td>
              <td>
                {{Util.cutString(operation.address_company, 20)}}
              </td>
              <td>
                {{Util.cutString(operation.project_name, 18)}}
              </td>
              <td>
                {{Util.cutString(operation.priority_name, 18)}}
              </td>
              <td>
                <input type="checkbox" (change)="toggleOpenJobSelectionModalOperationSelection(operation)" [checked]="openJobSelectionModalSelectedOperations.indexOf(operation) >= 0">
              </td>
            </tr>
            <tr *ngIf="!(openJobSelectionModalOperationsPageIdx >= ceil(openJobSelectionModalOperations.length / 16) - 1)">
              <td colspan="5" class="table_page_change" (click)="increaseOpenJobSelectionModalOperationsPageIdx()">
                <fa-icon [icon]="faChevronDown"></fa-icon>
              </td>
            </tr>
          </tbody>
        </table>
        <div style="width: 100%; text-align: center; padding: 10px 20px;font-weight: bold; background-color: #2f2f2f;">
          {{openJobSelectionModalOperations.length}} Aufträge ({{openJobSelectionModalSelectedOperations.length}} ausgewählt)
        </div>
      </div>
  
      <div id="open_job_selection_function_container">
        <button (click)="addOpenJobSelectionModalSelectedOperationsToOptiwaeData()" id="open_job_selection_modal_add_button">hinzufügen</button>
        <button id="open_job_selection_modal_abort_button">abbrechen</button>
      </div>
  
    </div>
  </div>
  
  <div id="data_selection_container">
    <table id="time_period_selection">
      <thead>
        <tr>
          <th>
            Planungszeitraum
          </th>
          <th>
            <div class="fill_table_cell">
              Vom
              <input style="border-radius: 8px; padding: 1px 4px; margin-left: 4px;" type="date" [ngModel]="owFromDate" [min]="abstractionLayer.currentDate" [max]="owUntilDate" (change)="changeOWFromDate($event)">
            </div>
          </th>
          <th>
            <div class="fill_table_cell">
              Bis
              <input style="border-radius: 8px; padding: 1px 4px; margin-left: 4px;" type="date" [ngModel]="owUntilDate" [min]="owFromDate" (change)="changeOWUntilDate($event)">
            </div>
          </th>
        </tr>
      </thead>
    </table>
    <table *ngIf="selectedOperatorsInDataSelection.length || selectedOpenJobsInDataSelection.length" id="selected_entities">
      <thead>
        <tr>
          <th colspan="2">
            Ausgewählt:
          </th>
          <th>
            <div class="fill_table_cell">
              {{selectedOperatorsInDataSelection.length}} Techniker
              <button (click)="removeSelectedOperatorsFromOptiwaeData()" [disabled]="!selectedOperatorsInDataSelection.length" [ngClass]="selectedOperatorsInDataSelection.length? '' : 'disabled'"> <fa-icon [icon]="faTrash"></fa-icon> </button>
            </div>
          </th>
          <th>
            <div class="fill_table_cell">
              {{selectedOpenJobsInDataSelection.length}} offene Aufträge
              <button (click)="removeSelectedOpenJobsFromOptiwaeData()" [disabled]="!selectedOpenJobsInDataSelection.length" [ngClass]="selectedOpenJobsInDataSelection.length? '' : 'disabled'"> <fa-icon [icon]="faTrash"></fa-icon> </button>
            </div>
          </th>
        </tr>
      </thead>
    </table>
    <table id="operator_table" class="instance_table level_0">
      <thead>
        <tr (click)="showOperatorsInDataSelection = !showOperatorsInDataSelection" class="black_clickable">
          <th colspan="4">
            Techniker
          </th>
        </tr>
        <tr *ngIf="showOperatorsInDataSelection && owOperators.length">
          <th>
  
          </th>
          <th>
            <div class="fill_table_cell">
              Name
            </div>
          </th>
          <th>
            <div class="fill_table_cell">
              Eigenschaften
            </div>
          </th>
          <th>
            <input type="checkbox" [checked]="allOperatorsSelectedInDataSelection()" (click)="clickAllOperatorsDataSelectionButton()">
          </th>
        </tr>
      </thead>
      <tbody *ngIf="showOperatorsInDataSelection">
        <ng-container *ngFor="let operator of owOperators">
          <tr class="with_padding expandable_tr selectable_tr">
            <td>
              <div (click)="toggleOperatorSelectionExpansion(operator)">
                <ng-container *ngIf="expandedOperatorsInSelection.indexOf(operator) >= 0">
                  <fa-icon [icon]="faChevronDown"></fa-icon>
                </ng-container>
                <ng-container *ngIf="0 > expandedOperatorsInSelection.indexOf(operator)">
                  <fa-icon [icon]="faChevronRight"></fa-icon>
                </ng-container>
              </div>
            </td>
            <td>
              <div class="fill_table_cell">
                {{operator.name}}
              </div>
            </td>
            <td>
              <div class="fill_table_cell">
                [{{operator.properties.join(', ')}}]
              </div>
            </td>
            <td>
              <input type="checkbox" [checked]="selectedOperatorsInDataSelection.indexOf(operator) >= 0" (change)="toggleOperatorSelectionInDataSelection(operator)">
            </td>
          </tr>
          <tr *ngIf="expandedOperatorsInSelection.indexOf(operator) >= 0">
            <td colspan="4">
              <table class="instance_table level_1">
                <thead>
                  <tr>
                    <th></th>
                    <th> Datum </th>
                    <th> Aufträge </th>
                    <th> Maximale Arbeitszeit</th>
                    <th> Arbeitszeitfenster</th>
                    <th> Traveller </th>
                    <th>
                      <input type="checkbox" [checked]="allOperatorDaysSelectedInDataSelection(operator)" (click)="clickAllOperatorDaysDataSelectionButton(operator)">
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <ng-container *ngFor="let timestamp of dateTimestampRange">
                    <ng-container *ngFor="let operator_day of [getOWOperatorDay(operator.operator_id, timestamp)]">
                      <tr class="with_padding expandable_tr selectable_tr">
                        <td>
                          <div (click)="toggleOperatorDaySelectionExpansion(operator_day)">
                            <ng-container *ngIf="expandedOperatorDaysInSelection.indexOf(operator_day) >= 0">
                              <fa-icon [icon]="faChevronDown"></fa-icon>
                            </ng-container>
                            <ng-container *ngIf="0 > expandedOperatorDaysInSelection.indexOf(operator_day)">
                              <fa-icon [icon]="faChevronRight"></fa-icon>
                            </ng-container>
                          </div>
                        </td>
                        <td>
                          <div class="fill_table_cell">
                            {{dayIdxToGermanWeekday(timestampToWeekIdx(timestamp), true)}} 
                            {{Util.timestampToDatestring(timestamp, false, '.')}}
                          </div>
                        </td>
                        <td>
                          <div class="fill_table_cell">
                            {{getPlannedJobsOnOperatorDay(operator_day).length}}
                          </div>
                        </td>
                        <td>
                          <div class="fill_table_cell">
                            {{getOperatorDayWorktimeString(operator_day)}}
                          </div>
                        </td>
                        <td>
                          <div class="fill_table_cell">
                            {{getOperatorDayWorktimeWindowString(operator_day)}}
                          </div>
                        </td>
                        <td>
                          <div class="fill_table_cell">
                            <ng-container *ngIf="operator_day.traveler">
                              Ja
                            </ng-container>
                            <ng-container *ngIf="!operator_day.traveler">
                              Nein
                            </ng-container>
                          </div>
                        </td>
                        <td>
                          <input type="checkbox" [checked]="selectedOperatorDaysInDataSelection.indexOf(operator_day) >= 0" (change)="toggleOperatorDaySelectionInDataSelection(operator_day)">
                        </td>
                      </tr>
                      <ng-container *ngIf="expandedOperatorDaysInSelection.indexOf(operator_day) >= 0">
                        <tr>
                          <td colspan="7">
                            <table class="instance_table level_2">
                              <thead>
                                <tr>
                                  <th>
                                    ID
                                  </th>
                                  <th>
                                    Start
                                  </th>
                                  <th>
                                    Dauer
                                  </th>
                                  <th>
                                    SLA/Termin?
                                  </th>
                                  <th>
                                    Store Öffnungszeiten
                                  </th>
                                  <th>
                                    Braucht Technikereigenschaften
                                  </th>
                                  <th>
                                    <input type="checkbox" [checked]="allJobsOnOperatorDaySelectedInDataSelection(operator_day)" (click)="clickAllJobsOnOperatorDayDataSelectionButton(operator_day)">
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr *ngFor="let job_object of getPlannedJobsOnOperatorDay(operator_day)" class="with_padding selectable_tr">
                                  <td>
                                    <div class="fill_table_cell">
                                      {{job_object.job.job_id}}
                                    </div>
                                  </td>
                                  <td>
                                    <div class="fill_table_cell">
                                      {{owDateToDayTimestring(job_object.planned_job.dt)}}
                                    </div>
                                  </td>
                                  <td>
                                    <div class="fill_table_cell">
                                      {{OWTimeToTimeString(job_object.job.time_estimate)}}
                                    </div>
                                  </td>
                                  <td>
                                    <div class="fill_table_cell">
                                      <ng-container *ngIf="jobsSlaAndAppointmentDateInformation(job_object.job).length">
                                        {{jobsSlaAndAppointmentDateInformation(job_object.job)}}
                                      </ng-container>
                                      <ng-container *ngIf="!jobsSlaAndAppointmentDateInformation(job_object.job).length">
                                        nein
                                      </ng-container>
                                    </div>
                                  </td>
                                  <td>
                                    <div class="fill_table_cell">
                                      {{getJobsStoreOpeningString(job_object.job)}}
                                    </div>
                                  </td>
                                  <td>
                                    <div class="fill_table_cell">
                                      <ng-container *ngIf="job_object.job.needs_properties.length">
                                        [{{job_object.job.needs_properties.join(', ')}}]
                                      </ng-container>
                                      <ng-container *ngIf="!job_object.job.needs_properties.length">
                                        keine
                                      </ng-container>
                                    </div>
                                  </td>
                                  <td>
                                    <input type="checkbox" [checked]="checkJobObjectSelection(job_object)" (change)="toggleJobDataSelectionInDataSelection(job_object)">
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                      </ng-container>
                    </ng-container>
                  </ng-container>
                </tbody>
              </table>
            </td>
          </tr>
        </ng-container>
        <tr class="with_padding" style="cursor: pointer;">
          <td colspan="4" (click)="showOperatorSelectionModal = true">
            <fa-icon [icon]="faPlus"></fa-icon>
          </td>
        </tr>
      </tbody>
    </table>
  
    <table id="open_jobs_table" class="instance_table level_0">
      <thead>
        <tr (click)="showOpenJobsInDataSelection = !showOpenJobsInDataSelection" class="black_clickable">
          <th colspan="6">
            Offene Aufträge
          </th>
        </tr>
        <tr *ngIf="showOpenJobsInDataSelection && owUnplannedJobs.length">
          <th>
            <div class="fill_table_cell">
              ID
            </div>
          </th>
          <th>
            <div class="fill_table_cell">
              SLA/Termin?
            </div>
          </th>
          <th>
            <div class="fill_table_cell">
              Dauer
            </div>
          </th>
          <th>
            <div class="fill_table_cell">
              Store Öffnungszeiten
            </div>
          </th>
          <th>
            <div class="fill_table_cell">
              Braucht Technikereigenschaften
            </div>
          </th>
          <th>
            <input type="checkbox" [checked]="allOpenJobsSelectedInDataSelection()" (change)="clickAllOpenJobsDataSelectionButton()">
          </th>
        </tr>
      </thead>
      <tbody *ngIf="showOpenJobsInDataSelection">
        <tr *ngFor="let job of owUnplannedJobs" class="with_padding selectable_tr">
          <td>
            <div class="fill_table_cell">
              {{job.job_id}}
            </div>
          </td>
          <td>
            <div class="fill_table_cell">
              <ng-container *ngIf="jobsSlaAndAppointmentDateInformation(job).length">
                {{jobsSlaAndAppointmentDateInformation(job)}}
              </ng-container>
              <ng-container *ngIf="!jobsSlaAndAppointmentDateInformation(job).length">
                nein
              </ng-container>
            </div>
          </td>
          <td>
            <div class="fill_table_cell">
              {{OWTimeToTimeString(job.time_estimate)}}
            </div>
          </td>
          <td>
            <div class="fill_table_cell">
              {{getJobsStoreOpeningString(job)}}
            </div>
          </td>
          <td>
            <div class="fill_table_cell">
              <ng-container *ngIf="job.needs_properties.length">
                [{{job.needs_properties.join(', ')}}]
              </ng-container>
              <ng-container *ngIf="!job.needs_properties.length">
                keine
              </ng-container>
            </div>
          </td>
          <td>
            <input type="checkbox" [checked]="selectedOpenJobsInDataSelection.indexOf(job) >= 0" (change)="toggleOpenJobSelectionInDataSelection(job)">
          </td>
        </tr>
        <tr class="with_padding" style="cursor: pointer;" (click)="showOpenJobSelectionModal = true">
          <td colspan="6">
            <fa-icon [icon]="faPlus"></fa-icon>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div style="width: 100%; text-align: center; background-color: #444444;">
    <button class="optimize_button" style="font-size: .9rem; margin: 10px 0px;" [disabled]="!owOperators.length || !owUnplannedJobs" (click)="getOptiwaeOptimization()">Optimieren</button>
  </div>
  
</ng-container>-->

