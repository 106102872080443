<div class="articleSplitterPage">
<div class="full-width-content">
  <section>
    <h2>Auswahl Empfänger</h2>
    <table class="articleSplitterPageTable base">
        <tr *ngIf="warehouses">
            <th class="tableLable">Lager</th>
            <td style="width: 300px">
              <select [(ngModel)]="selectedWarehouse" (change)="selectFirstBin()">
                <option [ngValue]="wh" *ngFor="let wh of warehouses">
                  {{wh.label}}
                </option>
              </select>
            </td>
            <th class="tableLable">Lagerplatz</th>
            <td style="width: 300px">
              <select [(ngModel)]="selectedBin">
                <option [ngValue]="bin" *ngFor="let bin of selectedWarehouse.bins">
                  {{bin.name}}
                </option>
              </select>
            </td>
            <td></td>
            <td></td>
            <td></td>
        </tr>
    </table>
</section>
</div>
<div class="article-selection">
  <section class="article-select">

      <section>
          <h2>Artikel auswählen</h2>

          <section class="tabs">
            <div class="tablist" role="tablist">
                <button role="tab" class="tab" [attr.aria-selected]="selectedTab == 'as'" (click)="selectedTab = 'as'">Artikelsuche</button>
                <button role="tab" class="tab" [attr.aria-selected]="selectedTab == 'ls'" (click)="selectedTab = 'ls'">Lieferscheinsuche</button>
            </div>

            <div *ngIf="selectedTab == 'as'" style="margin: 10px">
              <b style="margin-right: 10px">Multi-Suchbox:</b>
              <input type="text" [(ngModel)]="searchTermArticle" (keyup.ENTER)="searchArticles(searchTermArticle)"> <button (click)="searchArticles(searchTermArticle)">suchen!</button>
              <fa-icon [icon]="['fas','circle-notch']" [animation]="'spin'" size="lg" *ngIf="articleSpinner"></fa-icon>

              <div class="articleResults" *ngIf="foundArticles">

                <div class="article" *ngFor="let article of foundArticles"  [ngClass]="{'used': (article.location == 'CONSUMED')}">
                  <b>{{article.rma}}</b> ({{articleStatus(article)}})
                  <button class="articleButton" (click)="selectArticle(article)" *ngIf="!isArticleSelected(article) && (article.location !== 'CONSUMED')"><fa-icon [icon]="['far','plus-circle']"></fa-icon></button>
                  <br>
                  <small>{{article.description}}</small><br>
                  sn: {{article.serial_number}}
                  <span *ngIf="article.status == 'CONSUMED'">verbaucht!</span>
                </div>
              </div>
            </div>

            <div *ngIf="selectedTab == 'ls'" style="margin: 10px">
              <b style="margin-right: 10px">Lieferschein suchen:</b>
              <input type="text" [(ngModel)]="searchTermDeliveryNote" (keyup.ENTER)="searchDeliveryNote(searchTermDeliveryNote)"> <button (click)="searchDeliveryNote(searchTermDeliveryNote)">suchen!</button>
              <div class="articleResults" *ngIf="foundDeliveryNote">
                <div class="article" *ngFor="let article of foundDeliveryNote.articles" [ngClass]="{'differentWarehouse' : hasDifferentWarehouse(article, foundDeliveryNote), 'used': (!hasDifferentWarehouse(article, foundDeliveryNote) && (article.location == 'CONSUMED'))}">
                  <b>{{article.rma}}</b> ({{articleStatus(article)}})
                  <button class="articleButton" (click)="selectArticle(article)" *ngIf="!isArticleSelected(article) && !hasDifferentWarehouse(article, foundDeliveryNote)"><fa-icon [icon]="['far','plus-circle']"></fa-icon></button>
                  <br>
                  <small>{{article.description}}</small><br>
                  sn: {{article.serial_number}}
                  <span *ngIf="article.status == 'CONSUMED'">verbaucht!</span>
                  <ng-container *ngIf="hasDifferentWarehouse(article, foundDeliveryNote)">
                    <br>
                    <b>nicht möglich, bereits umgebucht!</b>
                  </ng-container>
                </div>
              </div>

            </div>
        </section>
      </section>
  </section>
  <aside>
          <section>
              <h3>Ausgewählte Artikel</h3>
              <div style="width:500px"></div>
              <div class="articleSelected" *ngIf="selectedArticles">

                <div class="article" *ngFor="let article of selectedArticles">
                  <b>{{article.rma}}</b> ({{articleStatus(article)}})
                  <button class="articleButton" (click)="unselectArticle(article)" *ngIf="isArticleSelected(article)"><fa-icon [icon]="['far','minus-circle']"></fa-icon></button>
                  <br>
                  <small>{{article.description}}</small><br>
                  sn: {{article.serial_number}}
                  <span *ngIf="article.status == 'CONSUMED'">verbaucht!</span>
                </div>
              </div>
          </section>
  </aside>
</div>
<div class="full-width-content">
  <section>
    <h2>Ausgabe</h2>
    <button (click)="splitArticles()" *ngIf="!hasCreatedDeliveryNotes()">Lieferscheine erzeugen</button>

    <ng-container *ngIf="hasCreatedDeliveryNotes()">
      <p>
        <b>erzeugte Lieferscheine:</b><br>
      </p>
      <table>
        <tr>
          <th></th>
          <th></th>
        </tr>
        <tr *ngFor="let deliveryNote of createdDeliveryNotes">
          <td>
            <a [routerLink]="['/', 'lieferscheine', deliveryNote.id]" target="_new"><fa-icon [icon]="['far','truck']"></fa-icon> {{ deliveryNote.number}}</a>
          </td>
          <td>
            <a href="/r/api/v1/delivery_notes/{{ deliveryNote.id }}/label.pdf" target="_new"><fa-icon [icon]="['far','print']"></fa-icon> Ettiketten drucken</a>
          </td>
        </tr>
      </table>
    </ng-container>
</section>
</div>
</div>

