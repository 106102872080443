import { Subject, Subscription } from "rxjs";

export abstract class FireUpdateOnChange<Out> {

  public updateSubject = new Subject<Out>();

  fireUpdate(value: Out): void {
    this.updateSubject.next(value)
  }

  subscribeToChanges(c_next?: ((value: Out) => void) | null, c_error?: ((error: any) => void) | null, c_complete?: (() => void) | null): Subscription {
    let obs = {
      next: (x: Out) => c_next(x),
      error: (err: any) => c_error(err),
      complete: () => c_complete(),
    };
    return this.updateSubject.subscribe(obs)
  }

  fireUpdateManually(output: Out, skip_before_change?: boolean): void {
    if (!skip_before_change) {
      this.executeBeforeChange()
    }
    this.fireUpdate(output)
  }

  abstract executeBeforeChange(): void
}