import { Component } from '@angular/core';
import { faMailBulk, faPhone } from "@fortawesome/free-solid-svg-icons";
import { ReactiveFormsModule, UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { MapBoxService } from "../../_services/mapbox.service";
import { CustomerService, StoreService } from "../../_services";
import { Router, RouterLink } from "@angular/router";
import * as mapboxgl from "mapbox-gl";
import { PAUtil } from "../planning-assistant/classes/util";
import { GeocoderEvent } from "../../_models/mapbox.interface";
import { APCustomer } from "../../_models/customer.interface";
import { PermittedStoreParams } from 'src/app/_models/store.interface';
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import { MatFormField } from "@angular/material/form-field";
import { MatInput } from "@angular/material/input";
import { MatOption, MatSelect } from "@angular/material/select";
import { FaIconComponent } from "@fortawesome/angular-fontawesome";
import { MatButton } from "@angular/material/button";
import { NgForOf } from "@angular/common";
import { RecordNavigationComponent } from "../_shared/record-navigation/record-navigation.component";

@Component({
  selector: 'hawk-store-create',
  templateUrl: './store-create.component.html',
  standalone: true,
  imports: [
    RouterLink,
    RecordNavigationComponent,
    ReactiveFormsModule,
    MatFormField,
    MatInput,
    MatSelect,
    MatOption,
    FaIconComponent,
    MatButton,
    NgForOf
  ],
  styleUrls: ['./store-create.component.scss', '../_shared/styles/common-styles.scss']
})
export class StoreCreateComponent {

  protected readonly faMailBulk = faMailBulk;
  protected readonly faPhone = faPhone;

  customers: APCustomer[] = []

  addressGeocoder?: MapboxGeocoder

  storeForm: UntypedFormGroup = new UntypedFormGroup({
    nr: new UntypedFormControl({value: ''}, [Validators.required]),
    customer_ids: new UntypedFormControl({value: []}),
    name: new UntypedFormControl({value: ''}, [Validators.required]),
    email: new UntypedFormControl({value: ''}, [Validators.email]),
    comment: new UntypedFormControl({value: ''}),
    phone1: new UntypedFormControl({value: ''}, Validators.pattern('[- +()0-9]+')),
    phone2: new UntypedFormControl({value: ''}, Validators.pattern('[- +()0-9]+')),
    register: new UntypedFormControl({value: 0}),
    ip: new UntypedFormControl({value: ''}),
    company: new UntypedFormControl({value: ''}),
    street: new UntypedFormControl({value: '', disabled: true}),
    zip: new UntypedFormControl({value: '', disabled: true}, [Validators.pattern('^([a-zA-Z]{2}-)?[0-9]{2,5}( [a-zA-Z]{0,2})?$')]),
    city: new UntypedFormControl({value: '', disabled: true}),
    country: new UntypedFormControl({value: '', disabled: true}),
    state: new UntypedFormControl({value: '', disabled: true}),
    lat: new UntypedFormControl({value: ''}),
    lng: new UntypedFormControl({value: ''}),
    address_firstname: new UntypedFormControl({value: ''}),
    address_lastname: new UntypedFormControl({value: ''}),
  });

  constructor(
    private mapBoxService: MapBoxService,
    private customerService: CustomerService,
    private storeService: StoreService,
    private router: Router
  ) {
  }

  ngOnInit(): void {
    this.loadCustomers()
    this.resetForm()
    this.setGeocoder()
  }

  loadCustomers(): void {
    this.customerService.loadAPCustomers().subscribe(
      data => {
        this.customers = data
      },
      err => {
        console.log(err)
      }
    )
  }

  resetForm():void {
    this.storeForm.setValue({
      nr: '',
      customer_ids: [],
      name: '',
      email: '',
      comment: '',
      phone1: '',
      phone2: '',
      register: 0,
      ip: '',
      company: '',
      street: '',
      zip: '',
      city: '',
      country: '',
      state: '',
      lat: 0,
      lng: 0,
      address_firstname: '',
      address_lastname: '',
    });
  }

  public async setGeocoder(): Promise<void> {
    this.addressGeocoder = new MapboxGeocoder({
      accessToken: this.mapBoxService.accessToken,
      marker: false,
      language: 'de-DE',
      mapboxgl: mapboxgl,
      placeholder: 'Adresse suchen'
    })
    while (!document.getElementById('address_geocoder')) {
      await PAUtil.sleep(100)
    }
    const address_geocoder_elem = document.getElementById('address_geocoder')
    this.addressGeocoder.addTo(address_geocoder_elem);

    this.addressGeocoder.on('result', (event) => {
      this.onAddressGeocoderEvent(event)
    })
  }

  private onAddressGeocoderEvent(event: GeocoderEvent) {
    let {country, region, city, post_code, street, street_no, center, name} = PAUtil.getGeocoderEventAddress(event);

    let address_country_control = this.storeForm.controls['country']
    address_country_control.setValue(country)
    address_country_control.markAsTouched();
    address_country_control.updateValueAndValidity()
    let address_state_control = this.storeForm.controls['state']
    address_state_control.setValue(region)
    address_state_control.markAsTouched();
    address_state_control.updateValueAndValidity()
    let address_city_control = this.storeForm.controls['city']
    address_city_control.setValue(city)
    address_city_control.markAsTouched();
    address_city_control.updateValueAndValidity()
    let address_zip_control = this.storeForm.controls['zip']
    address_zip_control.setValue(post_code)
    address_zip_control.markAsTouched();
    address_zip_control.updateValueAndValidity()
    let address_street_control = this.storeForm.controls['street']
    address_street_control.setValue(street + ' ' + street_no)
    address_street_control.markAsTouched();
    address_street_control.updateValueAndValidity()

    let latitude_control = this.storeForm.controls['lat']
    latitude_control.setValue(center[1])
    latitude_control.markAsTouched();
    latitude_control.updateValueAndValidity()
    let longitude_control = this.storeForm.controls['lng']
    longitude_control.setValue(center[0])
    longitude_control.markAsTouched();
    longitude_control.updateValueAndValidity()

    this.storeForm.updateValueAndValidity()
  }

  async onSubmit() {
    const store_id = await this.createStore()
    if (store_id) {
      this.router.navigate(['/filiale', store_id])
    }
  }

  createStore(): Promise<number> {
    return new Promise<number>(resolve => {
      this.storeService.create(this.getCreateHash(), {customer_ids: this.getCustomerIds()}).subscribe(
        data => {
          resolve(data.id)
        },
        err => {
          console.log(err)
          resolve(null)
        }
      )
    })
  }

  getCreateHash(): PermittedStoreParams {
    const controls = this.storeForm.controls

    let street = controls['street'].value
    let zip_code = controls['zip'].value
    let city = controls['city'].value
    let country = controls['country'].value

    return {
      address_firstname: controls['address_firstname'].value,
      address_lastname: controls['address_lastname'].value,
      name: controls['name'].value,
      comment: controls['comment'].value,
      email: controls['email'].value,
      phone1: controls['phone1'].value,
      phone2: controls['phone2'].value,
      nr: controls['nr'].value,
      address_extra: null,
      address_street_no: null,
      address_company: controls['company'].value,
      address_street: street,
      address_city: city,
      address_zip: zip_code,
      address_country: country,
      address_state: controls['state'].value,
      address_latitude: controls['lat'].value,
      address_longitude: controls['lng'].value,
      address_used_google_maps_parameter: `${street}, ${zip_code} ${city}, ${country}`,
      register: controls['register'].value
    }
  }

  getCustomerIds(): number[] {
    return this.storeForm.controls['customer_ids'].value
  }
}
