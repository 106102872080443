import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DeliveryNote } from 'src/app/_models';
import { DeliveryNoteService } from 'src/app/_services';
import { CommonModule } from "@angular/common";
import { ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { NgSelectModule } from "@ng-select/ng-select";

@Component({
  imports: [CommonModule, ReactiveFormsModule, RouterModule, MatFormFieldModule, MatCheckboxModule, NgSelectModule],
    selector: 'hawk-ship-cloud-retoure',
    templateUrl: './ship-cloud-retoure.component.html',
    styleUrls: ['./ship-cloud-retoure.component.scss'],
})
export class ShipCloudRetoureComponent implements OnInit {
  constructor(
    private deliveryNoteService: DeliveryNoteService,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  public deliveryNoteId: number | String | string;
  public loadingPricing: boolean = false;
  public submittingLabel: boolean = false;
  public deliveryNote: DeliveryNote;
  public searchTerm: string = ''
  public carriersList: any[] = ["DHL", "DPD", "UPS"]
  public shipcloudRetoureForm: FormGroup;
  public saveShipcloudRetoure() {
    const formData = this.shipcloudRetoureForm.value;
    this.submittingLabel = true;
    this.deliveryNoteService.saveShipcloudRetoure(this.deliveryNoteId, formData).subscribe(
      {
        next: () => {
          this.submittingLabel = false;
          this.router.navigate(['/', 'lieferscheine', this.deliveryNote.id]);
        },
        error: (error) => {
          alert(`Errors: ${error}`);
          this.submittingLabel = false;
        }
      }
    )
  }

  ngOnInit(): void {
    this.deliveryNoteId = parseInt(this.route.snapshot.paramMap.get('id'));
    this.loadDeliveryNote();
  }
  private loadDeliveryNote() {
    this.deliveryNoteService.show(this.deliveryNoteId).subscribe(
      {
        next: (response: DeliveryNote) => {
          this.deliveryNote = response;
          this.shipcloudRetoureForm = new FormGroup({
            width: new FormControl(response.parcel_width),
            height: new FormControl(response.parcel_height),
            length: new FormControl(response.parcel_length),
            weight: new FormControl(response.parcel_weight),
            carrier: new FormControl(),
            description: new FormControl(response.parcel_description),
            test: new FormControl(false),
            create_retoure_note: new FormControl(true)
          });
        },
        error: (error) => {
          console.log('error', error);
        }
      }
    )
  }

}