
import { PATechnician } from "../classes/technician";
import { PAUtil, WeekDay } from "../classes/util";
import { PAFilter } from "../classes/filter";
import { PAProject } from "../classes/project";
import {  UserService } from "../../../_services";
import { PAOperation } from "../classes/operation";
import { PADataControl } from "./pa-data-control";
import { PATimeControl } from "./pa-time-control";

export class PAFilterControl {

  private static _instance: PAFilterControl

  public static get Instance()
  {
    return this._instance || (this._instance = new this());
  }

  // Technicians
  globalFilteredTechnicians: PATechnician[] = []
  preferredCalendarTechnicians: PATechnician[] = []
  selectedTechnicianOwnerFilter: string = "off"
  selectedTechnicianOperationsInCalendarWeekRangeFilter = "off"
  operationsInCalendarWeekRangeCalendarWeekNumber: number = PATimeControl.Instance.getCurrentCalendarWeek().number
  operationsInCalendarWeekRangeCalendarWeekYear: number = PATimeControl.Instance.getCurrentCalendarWeek().year
  queuedTechnicianFilterUpdate = false
  runningTechnicianFilterUpdate = false

  // Operations
  public globalFilteredOperations: PAOperation[] = []
  public selectedOperationTimeFilter: 'planned' | 'average' = "average"
  public queuedOperationFilterUpdate = false
  public runningOperationFilterUpdate = false
  public operationHasMaterialFilter = 'off'
  public operationHasDeadlineFilter = 'off'
  public unplannedOperations: PAOperation[] = []
  public filteredUnplannedOperations: PAOperation[] = []

  // Projects
  projectSearchString = ''
  nonServiceProjects: PAProject[] = []
  serviceProjects: PAProject[] = []
  filteredNonServiceProjects: PAProject[] = []
  filteredServiceProjects: PAProject[] = []
  serviceProjectsToggleGroup: string = ''
  nonServiceProjectsToggleGroup: string = ''

  private _userService: UserService

  // WeekDays
  filteredWeekDays: WeekDay[] = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday']

  private constructor(
  ) {
  }

  set userService(us: UserService) {
    this._userService = us
  }

  public async updateTechnicianFilter() {
    if (this.queuedTechnicianFilterUpdate) {
      return
    }
    if (this.runningTechnicianFilterUpdate) {
      this.queuedTechnicianFilterUpdate = true
      while (this.runningTechnicianFilterUpdate) {
        await PAUtil.sleep(100)
      }
      this.queuedTechnicianFilterUpdate = false
    }
    this.runningTechnicianFilterUpdate = true
    this.globalFilteredTechnicians = await this.filterTechnicians()

    this.runningTechnicianFilterUpdate = false

  }

  public async filterTechnicians(): Promise<PATechnician[]> {

    let technicians = PADataControl.Instance.loadedTechnicians

    // Filter out pseudo technicians
    technicians = PAFilter.filterOutPseudoTechnicians(technicians)

    // Technician Owner Filter
    if (this.selectedTechnicianOwnerFilter == 'bentomax') {
      technicians = PAFilter.filterTechniciansForBentomaxTechnicians(technicians)
    } else if (this.selectedTechnicianOwnerFilter == 'external') {
      technicians = PAFilter.filterTechniciansForExternalTechnicians(technicians)
    }

    // Project Membership Filter
    if (PAProject.selectedProjects.length) {
      technicians = PAFilter.filterTechniciansForProjectsInclusiveTechnicians(technicians, PAProject.selectedProjects.map(project => project.id))
    }

    // Has Operations In Calendar Week Range Filter
    if (this.selectedTechnicianOperationsInCalendarWeekRangeFilter == 'technicians_with_operations') {
      technicians = PAFilter.filterTechniciansForOperations(technicians)
    } else if (this.selectedTechnicianOperationsInCalendarWeekRangeFilter == 'technicians_with_operations_in_calendar_week' && this.operationsInCalendarWeekRangeCalendarWeekNumber && this.operationsInCalendarWeekRangeCalendarWeekYear) {
      technicians = await PAFilter.filterTechniciansForOperationsInCalendarWeekRange(technicians, [{
        number: this.operationsInCalendarWeekRangeCalendarWeekNumber,
        year: this.operationsInCalendarWeekRangeCalendarWeekYear
      }], this._userService)
    } else if (this.selectedTechnicianOperationsInCalendarWeekRangeFilter == 'technicians_without_operations_in_calendar_week' && this.operationsInCalendarWeekRangeCalendarWeekNumber && this.operationsInCalendarWeekRangeCalendarWeekYear) {
      technicians = await PAFilter.filterTechniciansForAbsentOperationsInCalendarWeekRange(technicians, [{
        number: this.operationsInCalendarWeekRangeCalendarWeekNumber,
        year: this.operationsInCalendarWeekRangeCalendarWeekYear
      }], this._userService)
    }

    // Show Bentomax Technicians first
    technicians.sort(
      (technician_1, technician_2) => (
        technician_1.isBentomaxTechnician() <
        technician_2.isBentomaxTechnician()
      ) ? 1 : -1
    )
    return technicians
  }

  getTechnicianFilterString(): string {

    let owner_string = ''
    switch (this.selectedTechnicianOwnerFilter) {
      case 'bentomax': {
        owner_string = 'Bentomax Techniker'
        break
      }
      case 'external': {
        owner_string = 'Externe Techniker'
        break
      }
    }
    let has_operations_string = ''
    switch (this.selectedTechnicianOperationsInCalendarWeekRangeFilter) {
      case 'technicians_with_operations_in_calendar_week': {
        has_operations_string = `mit Aufträgen in KW ${this.operationsInCalendarWeekRangeCalendarWeekNumber} ${this.operationsInCalendarWeekRangeCalendarWeekYear}`
        break
      }
      case 'technicians_without_operations_in_calendar_week': {
        has_operations_string = `ohne Aufträge in KW ${this.operationsInCalendarWeekRangeCalendarWeekNumber} ${this.operationsInCalendarWeekRangeCalendarWeekYear}`
        break
      }
    }
    if (owner_string && has_operations_string) {
      return [owner_string, has_operations_string].join(' ')
    } else if (owner_string) {
      return owner_string
    } else if (has_operations_string) {
      return 'Techniker ' + has_operations_string
    } else {
      return 'Alle Techniker'
    }
  }

  public async updateOperationFilter(wait_until_all_updates_finished?: boolean) {
    if (this.queuedOperationFilterUpdate) {
      if (wait_until_all_updates_finished) {
        while (this.runningOperationFilterUpdate) {
          console.log('wait until all operation updates finished')
          await PAUtil.sleep(100)
        }
      }
      return
    }

    if (this.runningOperationFilterUpdate) {
      this.queuedOperationFilterUpdate = true
      while (this.runningOperationFilterUpdate) {
        await PAUtil.sleep(100)
      }
      this.queuedOperationFilterUpdate = false
    }

    this.runningOperationFilterUpdate = true
    this.globalFilteredOperations = this.filterOperations()

    this.updateFilteredUnplannedOperations()

    this.runningOperationFilterUpdate = false

  }

  public filterOperations(): PAOperation[] {

    let operations = PADataControl.Instance.getChangedLoadedOperations()

    // Project Membership Filter
    operations = PAFilter.filterOperationsForProjectsInclusiveOperations(operations, PAProject.selectedProjects.map(project => project.id))

    if (this.operationHasMaterialFilter == 'true') {
      operations = PAFilter.filterOperationsForNeededMaterial(operations)
    } else if (this.operationHasMaterialFilter == 'false') {
      operations = PAFilter.filterOperationsForNoNeededMaterial(operations)
    }

    if (this.operationHasDeadlineFilter == 'sla_or_appointment') {
      operations = PAFilter.filterOperationsForSlaOrAppointment(operations)
    } else if (this.operationHasDeadlineFilter == 'sla') {
      operations = PAFilter.filterOperationsForSla(operations)
    } else if (this.operationHasDeadlineFilter == 'appointment') {
      operations = PAFilter.filterOperationsForAppointment(operations)
    }

    return operations
  }

  getOperationFilterString(): string {
    let active_filter = []
    switch (this.operationHasMaterialFilter) {
      case 'true': {
        active_filter.push('mit benötigtem Material')
        break
      }
      case 'false': {
        active_filter.push('ohne benötigtes Material')
        break
      }
    }
    switch (this.operationHasDeadlineFilter) {
      case 'sla_or_appointment': {
        active_filter.push('mit SLA/Termin')
        break
      }
      case 'sla': {
        active_filter.push('mit SLA')
        break
      }
      case 'appointment': {
        active_filter.push('mit Termin')
        break
      }
    }
    if (active_filter.length) {
      return `Aufträge ${active_filter.join(' und ')}`
    } else {
      return 'Alle Aufträge'
    }
  }

  public updateUnassignedOperations(): void {
    this.unplannedOperations = PADataControl.Instance.getUnassignedOperations()
    this.updateFilteredUnplannedOperations()
  }

  public updateFilteredUnplannedOperations():void {
    this.filteredUnplannedOperations = this.unplannedOperations.filter(operation => this.globalFilteredOperations.includes(operation))
  }

  async toggleProjectSelection(project: PAProject): Promise<void> {
    let projects = PAProject.selectedProjects
    if (projects.includes(project)) {
      PAProject.selectedProjects = projects.filter(p => p != project)
    } else {
      PAProject.selectedProjects = projects.concat([project])
    }
    this.updateProjectToggleGroups()
    await this.loadNotLoadedSelectedProjects()
  }

  updateShownProjects(): void {
    this.nonServiceProjects = PADataControl.Instance.loadedProjects.filter(project => project.isNotServiceProject())
    this.serviceProjects = PADataControl.Instance.loadedProjects.filter(project => project.isServiceProject())
    this.filteredNonServiceProjects = this.nonServiceProjects.filter(project => project.includesString(this.projectSearchString))
    this.filteredServiceProjects = this.serviceProjects.filter(project => project.includesString(this.projectSearchString))
  }

  selectAllServiceProjects(): void {
    PAProject.selectedProjects = [...new Set(PAProject.selectedProjects.concat(this.serviceProjects))]
    this.loadNotLoadedSelectedProjects()
  }

  deselectAllServiceProjects(): void {
    PAProject.selectedProjects = PAProject.selectedProjects.filter(project => !this.serviceProjects.includes(project))
    this.loadNotLoadedSelectedProjects()
  }

  selectAllNonServiceProjects(): void {
    PAProject.selectedProjects = [...new Set(PAProject.selectedProjects.concat(this.nonServiceProjects))]
    this.loadNotLoadedSelectedProjects()
  }

  deselectAllNonServiceProjects(): void {
    PAProject.selectedProjects = PAProject.selectedProjects.filter(project => !this.nonServiceProjects.includes(project))
    this.loadNotLoadedSelectedProjects()
  }

  updateProjectToggleGroups(): void {
    this.updateServiceProjectToggleGroups()
    this.updateNonServiceProjectToggleGroups()
  }

  updateServiceProjectToggleGroups(): void {
    switch (PAProject.selectedProjects.filter(project => project.isServiceProject()).length) {
      case 0 : {
        this.serviceProjectsToggleGroup = 'none'
        break;
      }
      case PADataControl.Instance.loadedProjects.filter(project => project.isServiceProject()).length : {
        this.serviceProjectsToggleGroup = 'all'
        break;
      }
      default: {
        this.serviceProjectsToggleGroup = 'selected'
        break;
      }
    }
  }

  updateNonServiceProjectToggleGroups(): void {
    switch (PAProject.selectedProjects.filter(project => project.isNotServiceProject()).length) {
      case 0 : {
        this.nonServiceProjectsToggleGroup = 'none'
        break;
      }
      case PADataControl.Instance.loadedProjects.filter(project => project.isNotServiceProject()).length : {
        this.nonServiceProjectsToggleGroup = 'all'
        break;
      }
      default: {
        this.nonServiceProjectsToggleGroup = 'selected'
        break;
      }
    }
  }

  async loadNotLoadedSelectedProjects() {
    let not_loaded_projects = PAProject.selectedProjects.filter(project => project.operationLoadingStatus == 'init')
    if (not_loaded_projects.length) {
      let promises: Promise<void>[] = []
      for (let project of not_loaded_projects) {
        promises.push(project.loadUnplannedOperationsForProject())
        promises.push(project.loadUnplannedTicketsForProject())
      }
      await Promise.all(promises)
    }

    await this.updateTechnicianFilter()
    await this.updateOperationFilter()
  }

  internalProjectIsActivated() {
    return PAProject.selectedProjects.includes(PADataControl.Instance.getProject(133))
  }
}
