<div class="qmPage">
  <header class="columns">
    <section>
      <h2>{{getTitle()}}</h2>
      <table class="qmPageTable base">
          <tr>
            <th>id</th>
            <th>Einsatztermin</th>
            <th *ngIf="showTravelstart()">Abfahrt</th>
            <th>Techniker</th>
            <th>Rufnummer</th>
            <th>TicketID</th>
            <th>Ort</th>
            <th>Projekt</th>
            <th>Anreise</th>
            <th *ngIf="showCreated()">erstellt</th>
            <th>Aktionen</th>
          </tr>
          <tr *ngFor="let job of jobs | qmJobType:jobType" [ngClass]="{'locked' : isLocked(job)}">
            <th>{{job.id}}</th>
            <td>{{job.operation_date | date: 'dd.MM.yyyy HH:mm'}}</td>
            <td *ngIf="showTravelstart()">{{job.date_travel_start | date: 'dd.MM.yyyy HH:mm'}}</td>
            <td>{{job.technician_name}}</td>
            <td><a href="tel:{{job.technician_phone}}">{{job.technician_phone}}</a></td>
            <td><a href="/a/ticket/{{job.ticket_id}}?qmJob={{job.id}}&operation_id={{job.operation_id}}" target="_blank">{{job.ticket_id}}</a></td>
            <td>{{job.job_city}}</td>
            <td>{{job.project_name}}</td>
            <td>{{job.travel_distance | number:'1.0-0'}} km / {{job.travel_duration | number:'1.0-0'}} min</td>
            <td *ngIf="showCreated()">{{job.created_at | date: 'dd.MM.yyyy HH:mm'}}</td>
            <td>
              <button (click)="setLock(job.id)" title="Vorgang sperren" *ngIf="!isLocked(job)"><fa-icon [icon]="['far','lock']"></fa-icon></button>
              <button (click)="setUnlock(job.id)" title="Vorgangssperre aufheben" *ngIf="isLocked(job)"><fa-icon [icon]="['far','lock-open']"></fa-icon></button>
              <button (click)="setDone(job.id)" title="erledigt"><fa-icon [icon]="['fas','check']"></fa-icon></button>
              <button (click)="setDelete(job.id)" title="löschen"><fa-icon [icon]="['far','trash']"></fa-icon></button>
              <button (click)="setFollowUp(job.id)" title="Wiedervorlage in 15min"><fa-icon [icon]="'history'"></fa-icon></button>
            </td>
          </tr>
      </table>
    </section>
  </header>
</div>
