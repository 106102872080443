
import { Injectable } from '@angular/core'
import { HttpClient, HttpParams } from '@angular/common/http'

import { environment } from '../../environments/environment'
import { TravelTechnicianDate } from '../_models/planning-assistant.interface'
import { PACoordinates } from '../_components/planning-assistant/classes/coordinates'

@Injectable({
  providedIn: 'root'
})
export class TravelTechnicianDateService {

  constructor(private http: HttpClient) {}

  public getTravelTechnicianDates(from_date: string, until_date: string) {
    const path = environment.apiURL + 'travel_technician_dates'

    let params = new HttpParams()
    params = params.append('from_date', from_date)
    params = params.append('until_date', until_date)

    return this.http.get<TravelTechnicianDate[]>(path, { params })
  }

  public getTechnicianDateStartAndEndCoordinates(date_string: string, uid: number) {
    const path = environment.apiURL + 'travel_technician_dates/start_and_end_coordinates/' + uid.toString() + '/' + date_string

    return this.http.get<{start: PACoordinates, end: PACoordinates, travel_technician_date_id?: number}>(path)
  }

  public createTravelTechnicianDate(data: {
    user_id: number,
    date: string,
    location_name?: string,
    location_address_latitude?: number,
    location_address_longitude?: number
  }) {
    const path = environment.apiURL + 'travel_technician_dates'

    return this.http.post<TravelTechnicianDate>(path, data)
  }

  public updateTravelTechnicianDate(id: number, data: {
    travel_technician_date: {
      location_name?: string,
      location_address_latitude?: number,
      location_address_longitude?: number
    }
  }) {
    const path = environment.apiURL + 'travel_technician_dates/' + id

    return this.http.patch<TravelTechnicianDate>(path, data)
  }

  public deleteTravelTechnicianDate(id: number){
    const path = environment.apiURL + 'travel_technician_dates/' + id

    return this.http.delete<TravelTechnicianDate>(path)
  }
}