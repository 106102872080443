<table class="instance_list">
  <tbody>
    <ng-container *ngFor="let operation of operations">
      <tr (click)="selectStoreOperationEvent.emit(operation)" [ngClass]="selectedOperation == operation ? 'selected' : ''" style="position: relative;">
        <td style="text-align: left; white-space: nowrap; border-right: 1px solid rgb(32, 32, 32); border-left: 1px solid black; width: 0;">
          <div style="display: inline-flex; flex-direction: column;">
            <div>
              T: 
              <ng-container *ngIf="operation.ticket.id >= 0">
                <a href="/a/ticket/{{operation.ticket.id}}?operation_id={{operation.id}}" target="_blank"> {{operation.ticket.id}}  </a> 
              </ng-container>
              <ng-container *ngIf="operation.ticket.id < 0">
                NEU
              </ng-container>
            </div>
            <div style="margin: auto; white-space: nowrap;">
              {{operation.ticket.priority.name}}
            </div>
            <div *ngIf="operation.slaDateInformation() || operation.ticket.memos?.length" style="display: inline-flex; flex-direction: row;">
              <div *ngIf="operation.slaDateInformation()" class="info_marker"  [tooltip]="operation.slaDateInformation()">
                <b>SLA!</b>
              </div>
              <div *ngIf="operation.ticket.memos?.length" class="info_marker" [tooltip]="operation.ticket.memos.join( ' | ')">
                <b>Memos!</b>
              </div>
            </div>
          </div>
        </td>
        <td>
          <div style="font-size: 12px; text-align: left;" [tooltip]="operation.description.length > 90 ? operation.description : null">
            {{operation.description.length > 90 ? (operation.description.slice(0, 90) + '...') : operation.description}}
          </div>
        </td>
        <td style="padding: 0!important; width: 0;">
          <div style="display: inline-flex; flex-direction: column;">
            <div style="margin: auto; padding: 2px 4px;">
              <ng-container *ngIf="operation.isInPlanningProcess()">
                <button 
                style="font-size: .75rem; white-space: nowrap;"
                mat-raised-button 
                color="warn" 
                disabled>
                  In Planung
                </button>
              </ng-container>
              <ng-container *ngIf="!operation.isInPlanningProcess()">
                <div *ngIf="PADataControl.Instance.unassignedOperationUserIds.includes(operation.user_ids[0])" style="display: inline-flex; flex-direction: row;">
                  <button class="plan_button"
                  mat-raised-button 
                  (click)="clickPlanStoreOperation($event, operation)">
                    Planen
                  </button>
                  <button class="add_button"
                  *ngIf="PADataControl.Instance.unassignedOperationUserIds.includes(operation.user_ids[0]) && !PATourPlannerControl.Instance.tourPlannerExtraOperationCollection.includes(operation)"
                  mat-raised-button 
                  [disabled]="!PATourPlannerControl.Instance.planningMenuIsInPlanningProcess()"
                  (click)="clickAddStoreOperationToPlanning($event, operation)">
                    &plus;
                  </button>
                  <button class="add_button"
                  *ngIf="PADataControl.Instance.unassignedOperationUserIds.includes(operation.user_ids[0]) && PATourPlannerControl.Instance.tourPlannerExtraOperationCollection.includes(operation)"
                  mat-raised-button 
                  color="warn" 
                  [disabled]="!PATourPlannerControl.Instance.planningMenuIsInPlanningProcess()"
                  (click)="clickRemoveStoreOperationFromPlanning($event, operation)">
                    &minus;
                  </button>
                </div>
                
                <ng-container *ngIf="!PADataControl.Instance.unassignedOperationUserIds.includes(operation.user_ids[0])">
                  <div class="technician_date black_gradient_background" (click)="PATourPlannerControl.Instance.planTechnicianDate(operation.getTechnicianDate())">
                    <div style="margin: auto;">
                      <div class="info_marker" [style.backgroundColor]="PADataControl.Instance.getTechnician(operation.user_ids[0]).getColorString(.6)" style="color: white!important;" [tooltip]="PADataControl.Instance.getTechnician(operation.user_ids[0]).getFullName()">
                        {{PADataControl.Instance.getTechnician(operation.user_ids[0]).getNameAbbreviation()}}
                      </div>
                    </div>
                    <div style="font-size: 12px; margin-top: 2px; min-width: 84px">
                      {{operation.getDateString()}}
                    </div>
                  </div>
                </ng-container>
              </ng-container>
            </div>
            <div *ngIf="PADataControl.Instance.unassignedOperationUserIds.includes(operation.user_ids[0])" style="width: 100%; text-align: right; font-size: 12px; margin: auto; padding: 0 4px;">
              <i>{{operation.ticket.time_estimation + 'min'}}  </i>
            </div> 
          </div>   
        </td>
        <div *ngIf="operation.isDone()" style="position: absolute; top:-1px; bottom: -1px; right: 0; left: 0; background-color: rgba(0, 0, 0, 0.5); display: inline-flex; flex-direction: row; pointer-events: none;">
          <div style="width: 75%; height: 100%;"></div>
          <div style="margin: auto; font-size: 2.4rem; color: greenyellow;">
            &#10004;
          </div>
        </div>
        <div *ngIf="operation.isUnassigned() && !operation.isGloballyVisible()" (click)="$event.stopPropagation()" style="position: absolute; top:-1px; bottom: -1px; right: 0; left: 0; background-color: rgba(0, 0, 0, 0.75); display: inline-flex; flex-direction: row; pointer-events: none;">
          <div style="margin: auto; font-size: .9rem; color: white;">
            Gefiltert
          </div>
        </div>
      </tr>
      <tr *ngIf="!operation.isDone()" >
        <td colspan="3" style="text-align: left; padding: 0!important; background-color: #444444; border-left: 1px solid black;">
          <div class="expand" style="max-height: 200px;" [ngClass]="operation == selectedOperation ? '' : 'hidden'">
            <div style="display: inline-flex; flex-direction: column; width: 100%;">
              <div style="display: inline-flex; flex-direction: row;">
                <div style="margin: auto; margin-right: 4px; margin-left: 4px;">
                  Material:
                </div>
                <div style="flex: 1; padding: 2px 4px;">
                  <hawk-material-selection
                  [materialContainer]="operation.ticket.id >= 0 ? operation.ticket : operation.ticket.afterStorageTaskMaterialContainer"
                  [forProjectID]="operation.ticket.project.id">
                  </hawk-material-selection>
                </div>
              </div>
            </div>
          </div>
        </td>
      </tr>
    </ng-container>
  </tbody>
</table>

