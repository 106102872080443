<span class="inline" *ngIf="!multiUpload">
  <input type="file" class="hidden" (change)="save($event)" [accept]="acceptedFiletypes" #fileUpload>

  <button (click)="fileUpload.click()"><fa-icon [icon]="['far','paperclip']"></fa-icon></button>
</span>

<span class="inline" *ngIf="multiUpload">
  <input type="file" class="hidden" (change)="save($event)" [accept]="acceptedFiletypes" multiple #fileUploadMult>

  <button (click)="fileUploadMult.click()"><fa-icon [icon]="['far','paperclip']"></fa-icon></button>
</span>
