<div id="store_container" [ngClass]="['contact', 'address'].includes(showEditWindowFor) ? 'expand_height' : ''">
  <div id="customer_container" class="black_gradient_background_no_hover">
    <h6>Kunde / Aktive Projekte</h6>
    <div>
      {{getCustomerNames().join(', ')}}
    </div>
    <div>
      {{getProjectNames().join(', ')}}
    </div>
    <div>
    </div>
  </div>
  <div id="address_container" class="black_gradient_background" (click)="showEditWindowFor = 'address'; resetEditStoreAddressData()">
    <h6>Adresse</h6>
    <div>
      {{store.address_street}} {{store.address_street_no}}
    </div>
    <div>
      {{store.address_zip}} {{store.address_city}} {{store.address_state}} ({{store.address_country}})
    </div>
    <div>
    </div>
    <fa-icon style="position: absolute; right: 0; top: 0;" [icon]="faCog"></fa-icon>
  </div>
  <div id="contact_container" class="black_gradient_background" (click)="showEditWindowFor = 'contact'; resetEditContactFormData()">
    <h6>Kontakt</h6>
    <div>
      {{store.address_firstname}} {{store.address_lastname}} 
    </div>
    <div>
      <a [href]="'mailto:' + store.email" (click)="$event.stopPropagation()">{{store.email}}</a>
    </div>
    <div *ngIf="store.phone1">
      <a [href]="'tel:' + store.phone1" style="margin-right: 8px;" (click)="$event.stopPropagation()">{{store.phone1}}</a>
    </div>
    <div *ngIf="store.comment">
      Kommentar: {{store.comment}}
    </div>
    <fa-icon style="position: absolute; right: 0; top: 0;" [icon]="faCog"></fa-icon>
  </div>
  <div id="openings_container" class="black_gradient_background" (click)="showEditWindowFor = 'openings'; updateEditOpeningTimeTemplates()">
    <h6>Öffnungszeiten</h6>
    <div style="margin: auto; display: inline-flex; flex-direction: row; width: 100%;">
      <div *ngFor="let opening_data of openingData" class="opening_day" [ngClass]="opening_data.is_open ? '' : 'closed'">
        <div>
          {{opening_data.weekday}}
        </div>
        <div>
          <ng-container *ngIf="opening_data.open && opening_data.close">
            {{opening_data.open}} {{opening_data.close}}
          </ng-container>
        </div>
      </div>
    </div>
    <fa-icon style="position: absolute; right: 0; top: 0;" [icon]="faCog"></fa-icon>
  </div>

  <div class="edit_window" [style.display]="showEditWindowFor ? 'inline-flex' : 'none'">
    <div [style.display]="showEditWindowFor == 'address' ? 'block' : 'none'">
      <form [formGroup]="editStoreAddressForm" class="normal-form" (submit)="onAddressSubmit()">
        <mat-expansion-panel [expanded]="true" style="margin-bottom: 4px;">
          <mat-expansion-panel-header>
            <mat-panel-title> Adresse </mat-panel-title>
            <mat-panel-description>
              {{getAddressDescription()}}
            </mat-panel-description>
          </mat-expansion-panel-header>
          <mat-grid-list cols="2" rowHeight="40px">
            <mat-grid-tile style="overflow: visible;">
              <div class="controles-container">
                <div style="border: 1px solid greenyellow; width: fit-content;" id="edit_store_address_geocoder"></div>
              </div>
            </mat-grid-tile>
          </mat-grid-list>
          <mat-grid-list cols="3" rowHeight="130px">
            <mat-grid-tile>
              <div class="controles-container">
                <mat-form-field class="outline-small" appearance="outline">
                  <input formControlName="street" matInput placeholder="Straße">
                  <mat-error>Keine Straße gefunden</mat-error>
                </mat-form-field>
                <mat-form-field class="outline-small" appearance="outline">
                  <input formControlName="city" matInput placeholder="Stadt">
                  <mat-error>Keine Stadt gefunden</mat-error>
                </mat-form-field>
              </div>
            </mat-grid-tile>
            <mat-grid-tile>
              <div class="controles-container">
                <mat-form-field class="outline-small" appearance="outline">
                  <input formControlName="zip_code" matInput placeholder="Postleitzahl">
                  <mat-error>Bitte geben Sie eine gültige Postleitzahl an</mat-error>
                </mat-form-field>
                <mat-form-field class="outline-small" appearance="outline">
                  <input formControlName="country" matInput placeholder="Land">
                  <mat-error>Kein Land gefunden</mat-error>
                </mat-form-field>
              </div>
            </mat-grid-tile>
            <mat-grid-tile>
              <div class="controles-container">
                <mat-form-field class="outline-small" appearance="outline">
                  <input formControlName="state" matInput placeholder="Region/Provinz">
                </mat-form-field>
              </div>
            </mat-grid-tile>
          </mat-grid-list>
        </mat-expansion-panel>

        <mat-expansion-panel [expanded]="false" style="margin-bottom: 4px;">
          <mat-expansion-panel-header>
            <mat-panel-title> Koordinaten </mat-panel-title>
            <mat-panel-description>
              <div [style.color]="editStoreAddressForm.controls['lat'].value && editStoreAddressForm.controls['lng'].value ? 'green' : 'red'">
                {{editStoreAddressForm.controls['lat'].value && editStoreAddressForm.controls['lng'].value ? 'Gefunden' : 'Nicht gefunden'}}
              </div>
            </mat-panel-description>
          </mat-expansion-panel-header>
          <mat-grid-list cols="2" rowHeight="65px">
            <mat-grid-tile>
              <div class="controles-container">
                <mat-form-field class="outline-small" appearance="outline">
                  <input formControlName="lng" matInput placeholder="Länge">
                  <mat-error>Keinen Längengrad gefunden</mat-error>
                </mat-form-field>
              </div>
            </mat-grid-tile>
            <mat-grid-tile>
              <div class="controles-container">
                <mat-form-field class="outline-small" appearance="outline">
                  <input formControlName="lat" matInput placeholder="Breite">
                  <mat-error>Keinen Breitengrad gefunden</mat-error>
                </mat-form-field>
              </div>
            </mat-grid-tile>
          </mat-grid-list>
        </mat-expansion-panel>
        <mat-grid-list cols="2" rowHeight="60px">
          <mat-grid-tile>
            <div class="button-row" style="margin-left: auto; margin-right: 8px;">
              <button mat-raised-button color="warn" type='button' (click)="showEditWindowFor = null">Abbrechen</button>
            </div>
          </mat-grid-tile>
          <mat-grid-tile>
            <div class="button-row" style="margin-right: auto; margin-left: 8px;">
              <button mat-raised-button color="primary" type="submit" [disabled]="editStoreAddressForm.invalid">Speichern</button>
            </div>
          </mat-grid-tile>
        </mat-grid-list>
      </form>
    </div>
    <div [style.display]="showEditWindowFor == 'contact' ? 'inline-flex' : 'none'" style="padding: 2px 4px; flex:1; flex-direction: column;">
      <form [formGroup]="editContactForm" class="normal-form vertical_flex" (submit)="onContactSubmit()" style="flex: 1;">
        <div style="flex: 1;">
          <mat-expansion-panel [expanded]="true" style="margin-bottom: 4px;">
            <mat-expansion-panel-header>
              <mat-panel-title> Kontakt ändern </mat-panel-title>
              <mat-panel-description>
                {{getContactDescription()}}
              </mat-panel-description>
            </mat-expansion-panel-header>
            <mat-grid-list cols="2" rowHeight="130px">
              <mat-grid-tile>
                <div class="controles-container">
                  <mat-form-field class="outline-small" appearance="outline">
                    <input formControlName="firstname" matInput placeholder="Vorname">
                  </mat-form-field>
                  <mat-form-field class="outline-small" appearance="outline">
                    <input formControlName="lastname" matInput placeholder="Nachname">
                  </mat-form-field>
                </div>
              </mat-grid-tile>
              <mat-grid-tile>
                <div class="controles-container">
                  <mat-form-field class="outline-small" appearance="outline">
                    <input formControlName="email" matInput placeholder="Email">
                    <mat-error>Bitte geben Sie eine gültige Email an.</mat-error>
                  </mat-form-field>
                  <mat-form-field class="outline-small" appearance="outline">
                    <input formControlName="phone" matInput placeholder="Telefon">
                    <mat-error>Bitte geben Sie eine gültige Telefonnummer an.</mat-error>
                  </mat-form-field>
                </div>
              </mat-grid-tile>
            </mat-grid-list>
            <mat-grid-list cols="1" rowHeight="75px">
              <mat-grid-tile>
                <div class="controles-container">
                  <mat-form-field class="outline-small" appearance="outline" floatLabel="always">
                    <mat-label>Kommentar/Landkreis hinzufügen</mat-label>
                    <input formControlName="comment" matInput placeholder="z.B. 'Kreisfreie Stadt Kiel' oder 'Kreis Schleswig-Flensburg'">
                  </mat-form-field>
                </div>
              </mat-grid-tile>
            </mat-grid-list>
          </mat-expansion-panel>
        </div>
        <mat-grid-list cols="2" rowHeight="60px">
          <mat-grid-tile>
            <div class="button-row" style="margin-left: auto; margin-right: 8px;">
              <button mat-raised-button color="warn" type='button' (click)="showEditWindowFor = null">Abbrechen</button>
            </div>
          </mat-grid-tile>
          <mat-grid-tile>
            <div class="button-row" style="margin-right: auto; margin-left: 8px;">
              <button mat-raised-button color="primary" type="submit" [disabled]="editContactForm.invalid">Speichern</button>
            </div>
          </mat-grid-tile>
        </mat-grid-list>
      </form>
    </div>
    <div [style.display]="showEditWindowFor == 'openings' ? 'inline-flex' : 'none'" style="padding: 2px 4px; flex:1; flex-direction: column;">
      <h6>Öffnungszeiten ändern</h6>
      <hawk-opening-time-editor *ngIf="editOpeningTimeTemplates" [openingTimeTemplates]="editOpeningTimeTemplates" style="display: inline-flex; flex: 1;">
      </hawk-opening-time-editor>
      <div class="horizontal_flex" style="width: 100%; padding: 4px;">
        <button mat-raised-button color="warn" type='button' style="margin: auto 8px auto auto;" (click)="showEditWindowFor = null">Abbrechen</button>
        <button mat-raised-button color="primary" style="margin: auto auto auto 8px;" (click)="saveOpeningTimes()">Speichern</button>
      </div>
    </div>
    <fa-icon style="position: absolute; top: 0; right: 2px; cursor: pointer;" [icon]="faWindowClose" (click)="showEditWindowFor = null"></fa-icon>
  </div>
</div>
