<div class="container-fluid" style="padding-left: 5px; padding-right: 0;">

  <nav class="navbar navbar-expand-md navbar-light bg-light">
    <a class="navbar-brand" href="#">
      <img src="assets/hawk-logo.png" style="width: 30px">
    </a>
    <button class="navbar-toggler" type="button" (click)="toggleNavbar()">
      <span class="navbar-toggler-icon"></span>
    </button>

    @if (isLoggedIn()) {
      <div [ngbCollapse]="navbarCollapsed" class="collapse navbar-collapse">
        <ul class="navbar-nav mr-auto">
          <li class="nav-item active">
            <a class="nav-link" href="/dashboard/customer" (click)="dontCollapseNavbar()">Dashboard BI</a>
          </li>
          <li class="nav-item active">
            <a class="nav-link" href="/dashboard" (click)="dontCollapseNavbar()">Dashb (alt)</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLink="/ticket/suche/index">Tickets</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLink="/ticket/neu" (click)="dontCollapseNavbar()">Ticketerstellung</a>
          </li>

          <li class="nav-item">
            <a class="nav-link" routerLink="/qm/uebersicht" (click)="dontCollapseNavbar()">QM Übersicht</a>
          </li>

          <li class="nav-item">
            <a class="nav-link" routerLink="/planning_assistant" (click)="dontCollapseNavbar()">Projektassistent</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#" (click)="dontCollapseNavbar()">Projekteinstellungen</a>
          </li>
          <li class="nav-item dropdown" ngbDropdown>
            <a class="nav-link dropdown-toggle" id="dropdown04" ngbDropdownToggle>Lager</a>
            <div class="dropdown-menu" aria-labelledby="dropdown04" ngbDropdownMenu>
              <a class="dropdown-item" routerLink="/lager/uebersicht" (click)="dontCollapseNavbar()">Lagerstandorte</a>
              <a class="dropdown-item" routerLink="/artikel/suche/index" (click)="dontCollapseNavbar()">Artikel finden</a>
              <a class="dropdown-item" routerLink="/lieferscheine/suche/index" (click)="dontCollapseNavbar()">Lieferscheine</a>
              <a class="dropdown-item" routerLink="/artikelvorlagen/uebersicht" (click)="dontCollapseNavbar()">Artikelvorlagen</a>
              <a class="dropdown-item" routerLink="/lager/artikel_aufteilen" (click)="dontCollapseNavbar()">Artikel aufteilen</a>
              <a class="dropdown-item" routerLink="/lager/eingang" (click)="dontCollapseNavbar()">Erfassung Großlieferung</a>
              <a class="dropdown-item" routerLink="/lager/verarbeitung" (click)="dontCollapseNavbar()">Großlieferung weiterverarbeiten</a>
            </div>
          </li>

          <li class="nav-item dropdown" ngbDropdown>
            <a class="nav-link dropdown-toggle" id="dropdownAdmin" ngbDropdownToggle>Admin</a>
            <div class="dropdown-menu" aria-labelledby="dropdownAdmin" ngbDropdownMenu>
              <a class="dropdown-item" routerLink="/benutzer" (click)="navbarCollapsed = false">Benutzer</a>
              <a class="dropdown-item" routerLink="/kunde" (click)="navbarCollapsed = false">Kunden</a>
              <a class="dropdown-item" routerLink="/filiale" (click)="navbarCollapsed = false">Filialen</a>
              <a class="dropdown-item" href="/admin/user" (click)="navbarCollapsed = false">Alte Adminseite</a>
            </div>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="/project">Projekt (alt)</a>
          </li>
        </ul>

        <ul class="navbar-nav ms-auto">
          <li class="nav-item">
            <a class="nav-link" href="/account" (click)="dontCollapseNavbar()">Konto</a>
          </li>

          <li class="nav-item">
            <a class="nav-link" href="" (click)="handleLogout(); dontCollapseNavbar()">Abmelden</a>
          </li>
        </ul>
        <!-- <form class="form-inline my-2 my-md-0">
          <input class="form-control" type="text" placeholder="Search">
        </form> -->
      </div>
    }
  </nav>
</div>

<div class="container-fluid">
  <div class="row main" style="padding-top:0px">
    <div class="col" style="padding:0px">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>

<footer class="bd-footer text-muted">
  <div class="container-fluid">
    <p>Made with <i class="fas fa-heart"></i> for Bentomax</p>
  </div>
</footer>
