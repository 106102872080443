
<div class="body_container">
  <div style="min-width: 240px; margin-right: 12px; overflow-y: auto;">
    <h6 style="margin-bottom: 0;">Aktive Regeln</h6>
    <div id="projects_container">
      <div class="project" *ngFor="let project of projects">
        <ng-container *ngIf="project.hasPriorityWithRule()">
          <hawk-expand-bar-header
                  [description]="project.project_name"
                  [fontColor]="'white'"
                  [isExpanded]="expandProjects.includes(project)"
                  [leftAligned]="true"
                  [fontSize]="'.8rem'"
                  style="width: 100%;"
                  (toggle)="$event ? addToExpandProjects(project) : removeFromExpandProjects(project)"
          ></hawk-expand-bar-header>
          <div class="priorities_container" *ngIf="expandProjects.includes(project)">
            <ng-container *ngFor="let priority of project.priorities">
              <div class="priority" *ngIf="priorityHasRule(priority)" (click)="selectProjectAndPriority(project, priority)" [ngClass]="priority == config?.project_priority?.priority ? 'selected': ''">
                <div style="flex: 1;">{{priority.name}}</div>
                <div>Regeln: {{PASettingsControl.Instance.getAdditionalPriorityTimeRules(priority).length}}</div>
              </div>
            </ng-container>
          </div>
        </ng-container>
      </div>
    </div>
  </div>

  <form [formGroup]="timePredictionRuleForm" (submit)="saveTimePredictionRule()" style="padding: 10px; height: 100%; border: 1px solid black; border-radius: 4px; width: 600px; overflow: auto;
  background-color: white; color: black;">

    <h6>Priorität auswählen</h6>
    <div style="display: inline-flex; flex-direction: row; margin-bottom: 6px;">
      <mat-form-field style="width: 274px;" class="outline-small no-subscript" appearance="outline">
        <mat-label>Projekt auswählen</mat-label>
        <mat-select (selectionChange)="selectProject($event.value)" [(value)]="config.project_priority.project">
          <input type="search" style="width: 100%;" (search)="filterSearchProjects($event)" (keyup)="filterSearchProjects($event)" placeholder="Suche Projekt...">
          <mat-option>None</mat-option>
          <ng-container *ngFor="let project of searchProjects || projects">
            <mat-option [value]="project">{{project.project_name}}</mat-option>
          </ng-container>
        </mat-select>
        <mat-error>Bitte wählen Sie ein Projekt aus.</mat-error>
      </mat-form-field>
      <mat-form-field style="margin-left: 8px; width: 274px;" class="outline-small no-subscript" appearance="outline">
        <mat-label>Priorität auswählen</mat-label>
        <mat-select [disabled]="!config?.project_priority?.project" [(value)]="config.project_priority.priority" (selectionChange)="selectPriority($event.value)">
          <mat-option>None</mat-option>
          <ng-container *ngFor="let priority of config?.project_priority?.project?.priorities || []">
            <mat-option [value]="priority">{{priority.name}}</mat-option>
          </ng-container>
        </mat-select>
        <mat-error>Bitte wählen Sie eine Priorität aus.</mat-error>
      </mat-form-field>
    </div>

    <div *ngIf="shownPriorityRules.length" class="priority_rule_container">
      <h6>Definierte Regeln</h6>
      <div class="rule black_gradient_background" *ngFor="let rule of shownPriorityRules" (click)="editRule(rule)">
        <div>
          #{{shownPriorityRules.indexOf(rule) + 1}}
        </div>
        <div>
          Auftrag: {{rule.step_start}}-{{rule.step_end}}
        </div>
        <div style="flex: 1;">
          {{rule.technician == 'default' ? 'Alle Techniker' : rule.technician.getFullName(24)}}
        </div>
        <div>
          Typ: {{rule.timePredictionFunctionWrapper.type == 'absolute' ? 'Absolut' : 'Linear sinkend'}}
        </div>
        <div>
          +{{rule.timePredictionFunctionWrapper.y_offset_value}}% Zeit
        </div>
        <div>
          <div style="color: white;" (click)="$event.stopPropagation();deleteRule(rule)">
            <fa-icon [icon]="faTrash"></fa-icon>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="!editTimePredictionRule && config?.project_priority?.priority">
      <button mat-flat-button color="primary" (click)="addTimePredictionRuleForPriority(config?.project_priority?.priority)">Neue Rampup-Regel</button>
    </div>

    <div *ngIf="editTimePredictionRule">
      <h6>Regel bearbeiten</h6>
      <div style="display: inline-flex; flex-direction: row; margin-bottom: 6px;">
        <mat-form-field style="width: 180px;" class="outline-small no-subscript" appearance="outline">
          <mat-label>Ab Auftrag #</mat-label>
          <input formControlName="step_from" matInput type="number" min="1" [(ngModel)]="editTimePredictionRule.step_start" (ngModelChange)="update(true)">
          <mat-error>Bitte wählen Sie eine Zahl &#8805; 1 aus.</mat-error>
        </mat-form-field>
        <mat-form-field style="margin-left: 8px; width: 180px;" class="outline-small no-subscript" appearance="outline">
          <mat-label>Bis Auftrag #</mat-label>
          <input formControlName="step_until" matInput type="number" [min]="editTimePredictionRule.step_start + 1" [(ngModel)]="editTimePredictionRule.step_end" (ngModelChange)="update(true)">
        </mat-form-field>
        <mat-form-field style="margin-left: 8px; width: 180px;" class="outline-small no-subscript" appearance="outline">
          <mat-label>Für Techniker</mat-label>
          <mat-select formControlName="technician" [(value)]="editTimePredictionRule.technician" (selectionChange)="editTimePredictionRule.technician = $event.value; update(true)">
            <mat-option [value]="'default'">Alle</mat-option>
            <ng-container *ngFor="let technician of technicians">
              <mat-option [value]="technician">{{technician.getFullName(30)}}</mat-option>
            </ng-container>
          </mat-select>
        </mat-form-field>
      </div>
      <h6>Zusätzliche Zeit</h6>
      <div style="display: inline-flex; flex-direction: row;" *ngIf="editTimePredictionRule?.timePredictionFunctionWrapper">
        <mat-form-field style="width: 274px;" class="outline-small no-subscript" appearance="outline">
          <mat-label>Funktionstyp</mat-label>
          <mat-select formControlName="function_type" [(value)]="editTimePredictionRule.timePredictionFunctionWrapper.type" (selectionChange)="editTimePredictionRule.setTimePredictionFunctionWrapper($event.value); update(true)">
            <ng-container *ngFor="let type of ['absolute', 'linear']">
              <mat-option [value]="type">{{type == 'absolute' ? 'Absoluter Wert' : 'Linear sinkend'}}</mat-option>
            </ng-container>
          </mat-select>
        </mat-form-field>
        <ng-container>
          <mat-form-field style="margin-left: 8px; width: 274px;" class="outline-small no-subscript" appearance="outline">
            <mat-label>Zusätzliche Zeit (%)</mat-label>
            <input formControlName="function_y_value" matInput type="number" min="0" [(ngModel)]="editTimePredictionRule.timePredictionFunctionWrapper.y_offset_value" (ngModelChange)="update(true)">
          </mat-form-field>
        </ng-container>
      </div>
    </div>
    <hr>
    <div style="padding: 12px; width: 100%; display: flex;">
      <div class="chart-container" [ngClass]="this.editTimePredictionRule || this.shownPriorityRules?.length ? '' : 'hidden'">
        <canvas id="MyChart">{{chart}}</canvas>
      </div>
    </div>
    <div style="display: inline-flex; flex-direction: row; margin-top: 6px;" *ngIf="editTimePredictionRule && config?.project_priority?.priority">
      <button type="submit" mat-flat-button color="primary">Regel speichern</button>
      <button type="button" mat-flat-button color="warn" style="margin-left: 4px;" (click)="abortRuleChange()">abbrechen</button>
    </div>
  </form>
</div>
