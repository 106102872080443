import {
  Component, ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChange,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {
  faCheck,
  faExclamationTriangle,
  faPlusCircle,
  faTrash,
  faWindowClose
} from '@fortawesome/free-solid-svg-icons';
import { Opening, PermittedOpeningParams } from 'src/app/_models/opening.interface';
import { MapRoute } from 'src/app/_models/planning-assistant.interface';
import { CalendarComponent } from '../calendar/calendar.component';
import { PACoordinates } from '../classes/coordinates';
import { PAOperation } from '../classes/operation';
import { PAProject } from '../classes/project';
import { PAStore } from '../classes/store';
import { PATechnician } from '../classes/technician';
import { PATicket } from '../classes/ticket';
import { PAUtil } from '../classes/util';
import { Store } from 'src/app/_models';
import { CustomerService, StoreService } from 'src/app/_services';
import { MatTableDataSource, MatTable, MatColumnDef, MatHeaderCellDef, MatHeaderCell, MatCellDef, MatCell, MatHeaderRowDef, MatHeaderRow, MatRowDef, MatRow, MatNoDataRow } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort, MatSortHeader } from '@angular/material/sort';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { UntypedFormControl, UntypedFormGroup, Validators, ReactiveFormsModule, FormsModule } from '@angular/forms';
import { CustomerLetter } from 'src/app/_models/customer.interface';
import { PermittedStoreParams } from 'src/app/_models/store.interface';
import { map, mergeMap, startWith } from 'rxjs/operators';
import { merge, Observable } from 'rxjs';
import { TicketMaterialContainer } from '../classes/material-container/material-container';
import { PAMap } from "./pa-map";
import { PATour } from "../classes/tour";
import { DynamicComponentService } from "../../../_services/dynamic-component-service";
import { PopoutWindowComponent } from "../reuseable-components/popout-window/popout-window.component";
import { PADataControl } from "../singletons/pa-data-control";
import { PATourPlannerControl } from "../singletons/pa-tourplanner-control";
import { PAMapboxControl } from "../singletons/pa-mapbox-control";
import { PAMapControl } from "../singletons/pa-map-control";
import { PASettingsControl } from "../singletons/pa-settings-control";
import { PATimeControl } from "../singletons/pa-time-control";
import { HolidaysTypes } from "date-holidays";
import { GeocoderEvent } from "../../../_models/mapbox.interface";
import { NgIf, NgClass, NgFor, AsyncPipe } from '@angular/common';
import { MatFormField, MatLabel, MatSuffix, MatError } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatIconButton, MatButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { ExpandBarHeaderComponent } from '../reuseable-components/expand-bar-header/expand-bar-header.component';
import { MatGridList, MatGridTile } from '@angular/material/grid-list';
import { MatSelect } from '@angular/material/select';
import { MatOption, MatOptgroup } from '@angular/material/core';
import { MatExpansionPanel, MatExpansionPanelHeader, MatExpansionPanelTitle, MatExpansionPanelDescription } from '@angular/material/expansion';
import { MatAutocompleteTrigger, MatAutocomplete } from '@angular/material/autocomplete';
import { TooltipModule } from '@cloudfactorydk/ng2-tooltip-directive';
import { OpeningTimeEditorComponent } from '../reuseable-components/opening-time-editor/opening-time-editor.component';
import { StoreDataComponent } from '../reuseable-components/store-data/store-data.component';
import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { MaterialSelectionComponent } from '../reuseable-components/material-selection/material-selection.component';
import { MatCheckbox } from '@angular/material/checkbox';
import { CommonModule } from "@angular/common";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatSelectModule } from "@angular/material/select";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { FaIconComponent, FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import * as mapboxgl from 'mapbox-gl';
import { ThrobberComponent } from "../../_shared/throbber/throbber.component";

export type OpeningTimeTemplate = {
  day_idxs: number[];
  open: string;
  close: string;
};

@Component({
  selector: 'hawk-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss', './../styles/common_styles.scss', './../../_shared/styles/common-styles.scss'],
  standalone: true,
  imports: [NgIf, MatFormField, MatLabel, MatInput, ReactiveFormsModule, FormsModule, MatIconButton, MatSuffix, MatIcon, ExpandBarHeaderComponent, NgClass, MatTable, MatSort, MatColumnDef, MatHeaderCellDef, MatHeaderCell, MatSortHeader, MatCellDef, MatCell, MatHeaderRowDef, MatHeaderRow, MatRowDef, MatRow, MatNoDataRow, MatPaginator, MatGridList, MatGridTile, MatSelect, MatOption, NgFor, MatError, MatExpansionPanel, MatExpansionPanelHeader, MatExpansionPanelTitle, MatExpansionPanelDescription, MatAutocompleteTrigger, MatAutocomplete, MatOptgroup, ThrobberComponent, FaIconComponent, TooltipModule, OpeningTimeEditorComponent, MatButton, StoreDataComponent, CdkTextareaAutosize, MaterialSelectionComponent, MatCheckbox, AsyncPipe, CommonModule, MatFormFieldModule, MatSelectModule, MatCheckboxModule, FontAwesomeModule]
})
export class MapComponent extends PAMap implements OnInit, OnChanges{
  faWindowClose = faWindowClose
  faCheck = faCheck
  faTrash = faTrash

  CalendarComponent = CalendarComponent

  public geocodeCenter: number[]
  public geoCodeStreet: string
  public geoCodeCity: string
  public geoCodeZipCode: string
  public position: [number, number]

  displayedStoreColumns: string[] = ['address_company', 'address_street', 'address_street_no', 'address_zip', 'address_city', 'customers', 'projects'];
  storesCloseToGeocodeCity: Store[] = []
  storesCloseToGeocodeCityDataSource: MatTableDataSource<Store>
  selectedTemporaryOperationStore: Store

  showTemporaryOperationStoreSelection: boolean = true
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  storeSearchString = ''

  @ViewChild('select_multiple_stores_container') multipleStoresContainer: ElementRef<HTMLDivElement>;

  public Project = PAProject
  public Technician = PATechnician
  public Operation = PAOperation
  public MapComponent = MapComponent
  public Util = PAUtil
  public stores: PAStore[] = []

  @Input() technicians: PATechnician[]
  @Input() operations: PAOperation[]
  @Input() tourPlannerExtraOperations: PAOperation[]
  @Input() selectedTourPlannerTours: PATour[]
  @Input() selectedStore: PAStore
  @Input() selectedIsoCoordinates: PACoordinates
  @Input() operationTimeFilter: string
  @Input() drivingIsoDistance: number
  @Input() shownRoutes: MapRoute[] = []
  @Input() mapBoxAccessToken: string
  @Input() externalWindow: PopoutWindowComponent
  @Input() showTravelMarkerCoordinates: boolean
  @Input() technicianDistanceType: string

  @Output() addOperationToCurrentTourEvent = new EventEmitter<PAOperation>()

  public showStoreModal: boolean
  public showNewTicketForm = false
  public ticketForm: UntypedFormGroup = new UntypedFormGroup({
    project_id: new UntypedFormControl(null, Validators.required),
    priority_id: new UntypedFormControl(null, Validators.required),
    status_id: new UntypedFormControl(null, Validators.required),
    time_estimation: new UntypedFormControl(null, Validators.required),
    external_order_nr: new UntypedFormControl(''),
    sla_date: new UntypedFormControl(null),
    description: new UntypedFormControl('', Validators.required),
    contact_name: new UntypedFormControl(null),
    contact_email: new UntypedFormControl(null, Validators.email),
    contact_phone: new UntypedFormControl(null, Validators.pattern('[- +()0-9]+'))
  });
  slaHolidayWarning: HolidaysTypes.Holiday
  public ticketMaterialContainer: TicketMaterialContainer
  saveTicketInDB = true
  filteredTemporaryOperationProjects: PAProject[] = []

  shownNewStoreForm = false
  storeFormTemplate: Store
  public storeForm: UntypedFormGroup = new UntypedFormGroup({
    address_city: new UntypedFormControl({value: ''}, Validators.required),
    address_country: new UntypedFormControl({value: ''}, Validators.required),
    address_street: new UntypedFormControl({value: ''}, Validators.required),
    address_zip: new UntypedFormControl({value: ''}, [Validators.required, Validators.pattern('^([a-zA-Z]{2}-)?[0-9]{2,5}( [a-zA-Z]{0,2})?$')]),
    address_state: new UntypedFormControl({value: ''}),
    email: new UntypedFormControl('', Validators.email),
    phone: new UntypedFormControl('', Validators.pattern('[- +()0-9]+')),
    firstname: new UntypedFormControl(null),
    lastname: new UntypedFormControl(null),
    address_name: new UntypedFormControl({value: ''}, Validators.required),
    comment: new UntypedFormControl(null),
    address_latitude: new UntypedFormControl({value: ''}, Validators.required),
    address_longitude: new UntypedFormControl({value: ''}, Validators.required),
    customer_id: new UntypedFormControl({value: null}, Validators.required),
    store_nr: new UntypedFormControl({value: ''}, Validators.required),
    store_nr_valid: new UntypedFormControl({value: 'init'}, [Validators.requiredTrue]),
    project_id: new UntypedFormControl({value: null})
  });

  showAddProjectForm = false
  public addProjectForm: UntypedFormGroup = new UntypedFormGroup({
    project_id: new UntypedFormControl({value: ''}, Validators.required)
  });
  filteredAddStoreProjects: PAProject[] = []

  storeGeocoder: MapboxGeocoder
  customerLetters = new Map<number, CustomerLetter>()
  filteredCustomerLetters: CustomerLetter[] = []
  openingTimeTemplates: OpeningTimeTemplate[]
  autocompleteStores: Store[] = []
  autocompleteNames: Observable<string[]>

  public updating = false

  previousTours: PATour[] = []
  previousTechnicians: PATechnician[] = []
  private geocoder: MapboxGeocoder;
  private lastGeocoderEvent: GeocoderEvent;

  constructor(
    private customerService: CustomerService,
    public storeLoadingService: StoreService,
    private _liveAnnouncer: LiveAnnouncer,
    private _storeService: StoreService,
    dynamicComponentService: DynamicComponentService,
  ) {
    super(dynamicComponentService)
    this.resetOpeningTimeTemplates()
    this.customerService.loadCustomerLetters().subscribe(
      data => {
        for (let customer_letter of data) {
          this.customerLetters.set(customer_letter.id, customer_letter)
        }
      }, 
      err => {
        console.log(err)
      }
    )
  }

  async processChanges(changes: SimpleChanges) {
    let selected_tour_planner_tours_changes = changes['selectedTourPlannerTours'] ? new ListsSimpleChange<PATour>(changes['selectedTourPlannerTours']) : null
    let tour_planner_extra_operation_changes = changes['tourPlannerExtraOperations'] ? new ListsSimpleChange<PAOperation>(changes['tourPlannerExtraOperations']) : null

    let driving_iso_changes = changes['drivingIsoDistance']
    let selected_iso_coordinates_changes = changes['selectedIsoCoordinates']

    let previous_tours: PATour[] = this.previousTours
    let current_tours: PATour[] = []

    let is_in_technician_planning_mode = PATourPlannerControl.Instance.tourPlannerPlanningMode == 'technician'
    let technician_to_plan = is_in_technician_planning_mode && PATourPlannerControl.Instance.technicianToPlanID ? PADataControl.Instance.getTechnician(PATourPlannerControl.Instance.technicianToPlanID) : null

    if (!is_in_technician_planning_mode) {
      if (selected_tour_planner_tours_changes) {
        current_tours = current_tours.concat(selected_tour_planner_tours_changes.currentValue)
      }
    } else {
      if (technician_to_plan) {
        current_tours = current_tours.concat(technician_to_plan.getCurrentCalendarWeekTechnicianDates().map(td => td.tour))
      }
    }

    previous_tours = [...new Set(previous_tours)].filter(tour => tour)
    current_tours = [...new Set(current_tours)].filter(tour => tour)

    previous_tours.map(tour => tour.stopMapTourAnimation(this.mapContainer))
    await this.updateSelectedTourWaypoints(current_tours)

    const stores_before = this.stores
    let previous_technicians = this.previousTechnicians
    let current_technicians = [...new Set(this.technicians.concat(current_tours.map(tour => tour.technicianDate.technician)))]
    let current_operations: PAOperation[] = is_in_technician_planning_mode && technician_to_plan ? [] : [...this.operations]

    let current_stores = [...new Set(current_operations.map(operation => operation.getStore()))].filter(store => store && store.shownMapOperations.length > 0)
    let selected_tour_stores = [...new Set(current_tours.reduce((operations: PAOperation[], tour: PATour) => operations.concat(tour.operations), []).map(operation => operation.getStore()))]
    current_stores = [... new Set(current_stores.concat(selected_tour_stores))].filter(store => store)

    if (!PAUtil.equalSets(new Set(current_technicians), new Set(previous_technicians))) {
      this.updateMapTechnicianMarker(previous_technicians, current_technicians)
      this.previousTechnicians = current_technicians
    }

    if (!PAUtil.equalSets(new Set(current_stores), new Set(stores_before)) || tour_planner_extra_operation_changes?.listValuesChanged()) {
      this.updateMapTourStoreMarker(stores_before, selected_tour_stores, current_tours)
      this.stores = [...current_stores]

      this.mapStoreClusterer.stores = [...current_stores]
    }

    if (driving_iso_changes || selected_iso_coordinates_changes) {
      PAMapControl.Instance.updateSelectedCoordinatesDrivingIso()
    }

    if (this.showTravelMarkerCoordinates) {
      previous_tours.map(tour => tour ? tour.technicianDate.removeTourPlanningDestinationMarkerFromMap() : null)
      current_tours.map(tour => tour.technicianDate.displayTourPlanningDestinationMarkerOnMap(this.mapContainer.map))
    }

    await this.updateRoutesLineLayers(this.shownRoutes || [])

    this.previousTours = current_tours
  }

  ngOnInit(): void {
    this.waitAndInitMap(this.mapBoxAccessToken, 0).then(_ => {
      this.afterMapInit()
    })

    let address_name_obs = this.storeForm.controls['address_name'].valueChanges.pipe(
      startWith(''),
      map(value => this.updateAutocompleteNames(value || '')),
    )
    let customer_obs = this.storeForm.controls['customer_id'].valueChanges.pipe(
      mergeMap(_ => this.changeSelectedCustomer()),
    )
    let project_obs = this.storeForm.controls['project_id'].valueChanges.pipe(
      mergeMap(_ => this.changeSelectedProject()),
    )

    this.autocompleteNames = merge(address_name_obs, customer_obs, project_obs);
  }

  afterMapInit(): void {
    this.mapContainer.map.on('load', () => {
      // When the map loads, add the source and layer
      this.mapContainer.map.addSource('iso', {
        type: 'geojson',
        data: {
          'type': 'FeatureCollection',
          'features': []
        }
      });

      this.mapContainer.map.addLayer(
          {
            'id': 'isoLayer',
            'type': 'fill',
            'source': 'iso',
            'layout': {},
            'paint': {
              'fill-color': '#5a3fc0',
              'fill-opacity': 0.3
            }
          },
          'poi-label'
      );
    });

    this.mapContainer.map['doubleClickZoom'].disable()

    this.geocoder =
        new MapboxGeocoder({
          accessToken: this.mapBoxAccessToken,
          marker: false,
          mapboxgl: mapboxgl,
          placeholder: 'Bitte eingeben: PLZ, Stadt, Straße',
          flyTo: {
            bearing: 0,
            speed: 0.8,
            curve: 1,
            zoom: 9,
            easing: function (t) {
              return t;
            }
          },
        })

    this.setOnGeocoderResult()
    document.getElementById('geocoder').appendChild(this.geocoder.onAdd(this.mapContainer.map));
  }

  public setOnGeocoderResult(){
    this.geocoder.on('result', event => {
      this.onGeocoderResult(event)
    })
  } 

  public onGeocoderResult(event: GeocoderEvent){
    this.lastGeocoderEvent = event
    this.geocodeCenter = event.result.center

    let { country, region, city, post_code, street, street_no, center, name } = PAUtil.getGeocoderEventAddress(event, true);

    this.geoCodeCity = city
    this.geoCodeZipCode = post_code
    this.geoCodeStreet = street + (street_no ? ' ' + street_no : '')

    this.updateStoresCloseToGeoCodeCity().then(_ => this.openStoreModal())
    this.geocoder.clear()
  }

  async updateStoresCloseToGeoCodeCity(): Promise<void> {
    if (this.geoCodeCity || this.geoCodeZipCode || this.geoCodeStreet) {
      this.storeLoadingService.getStoresWithLocalRestrictions({city: this.geoCodeCity, zip_code: this.geoCodeZipCode, street: this.geoCodeStreet}).subscribe(
        data => {
          this.storesCloseToGeocodeCity = data
          this.updateStoreDataSource()
        },
        err => {
          console.log(err)
        }
      )
    } else {
      this.storesCloseToGeocodeCity = []
      this.updateStoreDataSource()
    }
  }

  async updateStoreDataSource(): Promise<void> {
    await PAUtil.sleep(50)
    let filtered_stores = this.filterStoresForValidGeocodeCoordinates(this.filterStoresForSearchString(this.storesCloseToGeocodeCity, this.storeSearchString))
    let sorted_stores = filtered_stores.sort((store_a, store_b) => 
      PAUtil.calcDistanceAsTheCrowFlies(store_a.address_latitude, store_a.address_longitude, this.geocodeCenter[1], this.geocodeCenter[0] ) < 
      PAUtil.calcDistanceAsTheCrowFlies(store_b.address_latitude, store_b.address_longitude, this.geocodeCenter[1], this.geocodeCenter[0] ) ?
      -1 : 1
    )
    this.storesCloseToGeocodeCityDataSource = new MatTableDataSource<Store>(sorted_stores);
    this.storesCloseToGeocodeCityDataSource.paginator = this.paginator;
    this.storesCloseToGeocodeCityDataSource.sort = this.sort;
  }

  filterStoresForSearchString(stores: Store[], search_string: string): Store[] {
    return stores.filter(store => (JSON.stringify(store) + this.getStoreCustomerNames(store) + this.getStoreProjectNames(store)).toLowerCase().includes(search_string.toLowerCase()))
  }

  filterStoresForValidGeocodeCoordinates(stores: Store[]): Store[] {
    return stores.filter(store => (store.address_latitude || store.address_longitude) && Number.isFinite(store.address_latitude) && Number.isFinite(store.address_longitude))
  }

  changeSelectedStore(store: Store): void {
    this.selectedTemporaryOperationStore = store
    this.showTemporaryOperationStoreSelection = false
    this.showNewTicketForm = false
  }

  async updateSelectedTourWaypoints(tours_after_change: PATour[]) : Promise<void> {
    await this.updateDisplayedRoutes(tours_after_change)
  }

  public openStoreModal(){
    //this.selectedOperationWindowName = 'temporary_operation'
    this.resetStoreModal()
    this.showStoreModal = true
    this.updateStoreDataSource()
  }

  public closeTemporaryOperationModal(event: MouseEvent){
    let target_is_modal_background = event.target == document.getElementById('temporary_operation_modal')
    let target_is_close_button = event.target == document.getElementById('temporary_operation_modal_close_button')
    let target_is_add_temporary_operation_button = event.target == document.getElementById('add_temporary_operation_button')
    if ( target_is_modal_background || target_is_close_button || target_is_add_temporary_operation_button) {
      this.showStoreModal = false
    }
  }

  announceSortChange(sortState: Sort) {
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
  }

  initializeTicketFormGroup() {
    this.ticketForm.setValue({
      project_id: null,
      priority_id: null,
      status_id: null,
      time_estimation: null,
      description: '',
      external_order_nr: '',
      sla_date: null,
      contact_name: null,
      contact_email: null,
      contact_phone: null
    });
    this.updateSLAHolidayWarning()
    this.ticketForm.markAsUntouched()
    this.ticketMaterialContainer = new TicketMaterialContainer()
    this.setSLAPreselection().then(_ => {this.updateSLAHolidayWarning()})
  }

  async setSLAPreselection(): Promise<void> {
    const time_control = PATimeControl.Instance
    let openings = await this.getSelectedStoreOpenings()
    if (openings.length && openings.find(op => [0,1,2,3,4,5,6].includes(op.day))) {

      let target_opening_day_idx: number = (new Date).getDay()

      const current_day_minutes = time_control.timeStringToMinutes(time_control.dateToTimestring(new Date(), false))
      const minutes_threshold = 16 * 60 // After 16:00 delay SLA by one working day
      let set_sla_in_n_days = 1
      if (current_day_minutes > minutes_threshold) {
        target_opening_day_idx = (target_opening_day_idx + 1) % 7
        set_sla_in_n_days += 1
      }

      let found_date_string: string = ''
      while(!found_date_string) {
        let target_opening = openings.find(op => op.day == target_opening_day_idx)
        if (target_opening) {
          const found_close_time = target_opening.close
          if (found_close_time) {
            let close_day_minutes = time_control.timeStringToMinutes(found_close_time)
            if (Number.isInteger(close_day_minutes)) {
              const target_timestamp = Date.now() + (24 * 60 * 60 * 1000) * set_sla_in_n_days
              let holidays = PATimeControl.Instance.getTimestampsHolidays(target_timestamp, true)
              if (!holidays.length) {
                const target_day_timestamp = time_control.dateToTimestamp(new Date(target_timestamp), true, false)
                const close_timestamp = target_day_timestamp + close_day_minutes * 60 * 1000
                found_date_string = time_control.formatDate(new Date(close_timestamp))
              }
            }
          }
        }

        if (!found_date_string) {
          target_opening_day_idx = (target_opening_day_idx + 1) % 7
          set_sla_in_n_days += 1
        }
      }

      this.ticketForm.controls['sla_date'].setValue(found_date_string)
    }
  }

  updateSLAHolidayWarning(): void {
    let time_control = PATimeControl.Instance
    let sla_date = this.ticketForm.controls['sla_date'].value
    if (sla_date) {
      let timestamp = time_control.dateStringToTimestamp(sla_date, false, false)
      let holidays = time_control.getTimestampsHolidays(timestamp)
      if (holidays.length) {
        this.slaHolidayWarning = holidays[0]
      } else {
        this.slaHolidayWarning = null
      }
    } else {
      this.slaHolidayWarning = null
    }
  }

  async getSelectedStoreOpenings(): Promise<Opening[]> {
    return await new Promise<Opening[]>(
      resolve => {
        if (this.selectedTemporaryOperationStore?.id) {
          this._storeService.getStoreOpenings(this.selectedTemporaryOperationStore?.id).subscribe(
            data => {
              resolve(data)
            },
            err => {
              console.log(err)
              resolve([])
            }
          )
        } else {
          resolve([])
        }
      }
    )
  }

  resetTicketFormPriorityAndStatus(): void {
    this.ticketForm.controls['priority_id'].setValue(null)
    this.ticketForm.controls['status_id'].setValue(null)
  }

  async onTicketSubmit(): Promise<void> {
    if (this.ticketForm.valid) {
      let project = PADataControl.Instance.getProject(this.ticketForm.get('project_id').value)
      let id = PADataControl.Instance.generateNextTemporaryTicketId()
      let ticket = new PATicket(
        id,
        PADataControl.Instance.generateNextLocationId(),
        PADataControl.Instance.generateNextClientId(),
        this.ticketForm.get('sla_date').value,
        null,
        project,
        new PACoordinates(this.selectedTemporaryOperationStore.address_latitude, this.selectedTemporaryOperationStore.address_longitude),
        this.ticketForm.get('time_estimation').value,
        this.selectedTemporaryOperationStore.address_company,
        this.selectedTemporaryOperationStore.address_street,
        this.selectedTemporaryOperationStore.address_street_no,
        this.selectedTemporaryOperationStore.address_zip,
        this.selectedTemporaryOperationStore.address_city,
        this.selectedTemporaryOperationStore.address_country,
        [],
        PAOperation.openingsToOpeningTimes(await PADataControl.Instance.getStoreOpenings(this.selectedTemporaryOperationStore.id)),
        this.ticketForm.get('external_order_nr').value,
        this.ticketForm.get('contact_name').value,
        this.ticketForm.get('contact_email').value,
        this.ticketForm.get('contact_phone').value,
        PADataControl.Instance.getPriority(this.ticketForm.get('priority_id').value),
        project.getStatus(this.ticketForm.get('status_id').value),
        [],
        this.ticketForm.get('description').value
      )
      ticket.store = this.selectedTemporaryOperationStore
      ticket.afterStorageTaskMaterialContainer = this.ticketMaterialContainer
      ticket.generateTemporaryTicketOperation()

      if (this.saveTicketInDB) {
        await ticket.save(this.ticketForm.get('description').value)
      }

      this.showNewTicketForm = false
      PATourPlannerControl.Instance.planOperation(ticket.temporaryOperation)
      this.resetStoreModal()
    }
  }

  resetStoreModal(): void {
    this.initializeTicketFormGroup()
    this.selectedTemporaryOperationStore = null
    this.showStoreModal = false
    this.showTemporaryOperationStoreSelection = true
  }

  addNewStoreForm(store_form_template?: Store): void {
    this.shownNewStoreForm = true
    this.storeForm.setValue({
      address_city: store_form_template?.address_city || '',
      address_country: store_form_template?.address_country || '',
      address_street: store_form_template?.address_street ? store_form_template?.address_street + (store_form_template?.address_street_no ? ' ' + store_form_template?.address_street_no : '') : '',
      address_zip: store_form_template?.address_zip || '',
      address_state: store_form_template?.address_state || '',
      email: store_form_template?.email || '',
      phone: store_form_template?.phone1 || '',
      firstname: store_form_template?.address_firstname || '',
      lastname: store_form_template?.address_lastname || 'Filialleitung',
      address_name: store_form_template?.name || '',
      comment: store_form_template?.comment || '',
      address_latitude: store_form_template?.address_latitude || null,
      address_longitude: store_form_template?.address_longitude || null,
      customer_id: null,
      store_nr: store_form_template?.nr || '',
      store_nr_valid: 'init',
      project_id: null
    });
    this.storeForm.markAsUntouched()
    this.storeFormTemplate = store_form_template
    this.setStoreGeocoder(this.lastGeocoderEvent)
    this.filterCustomerLettersMeta('')
    this.resetOpeningTimeTemplates(store_form_template)
    this.showTemporaryOperationStoreSelection = true
  }

  removeStoreForm(): void {
    if (this.storeFormTemplate) {
      this.showTemporaryOperationStoreSelection = false
      this.storeFormTemplate = null
    }
    this.shownNewStoreForm = false
  }

  async onStoreSubmit(): Promise<void> {
    let customer_letter = this.customerLetters.get(this.storeForm.controls['customer_id'].value)
    let name = this.storeForm.controls['address_name'].value
    let nr = `C${customer_letter?.id}_${customer_letter?.letter}_${this.storeForm.controls['store_nr'].value}`

    let street = this.storeForm.controls['address_street'].value
    let zip_code = this.storeForm.controls['address_zip'].value
    let city = this.storeForm.controls['address_city'].value
    let country = this.storeForm.controls['address_country'].value

    let store_params: PermittedStoreParams = {
      name: name,
      address_city: city,
      address_company: name,
      address_country: country,
      address_latitude: this.storeForm.controls['address_latitude'].value,
      address_longitude: this.storeForm.controls['address_longitude'].value,
      address_street: street,
      address_zip: zip_code,
      nr: nr,
      address_firstname: this.storeForm.controls['firstname'].value,
      address_lastname: this.storeForm.controls['lastname'].value,
      address_state: this.storeForm.controls['address_state'].value,
      address_street_no: '',
      comment: this.storeForm.controls['comment'].value,
      email: this.storeForm.controls['email'].value,
      phone1: this.storeForm.controls['phone'].value,
      address_used_google_maps_parameter: `${street}, ${zip_code} ${city}, ${country}`,
      register: 0 // TODO
    }

    let project_id = this.storeForm.controls['project_id'].value

    let init_store_for = {
      project_ids: project_id ? [project_id] : [],
      customer_ids: [this.storeForm.controls['customer_id'].value]
    }
    await new Promise<void>(resolve => {
      this.storeLoadingService.create(store_params, init_store_for).subscribe(
        async data => {
          PATourPlannerControl.Instance.snackBar.open(
            'Store angelegt',
            'ok'
          )._dismissAfter(3000)
          this.storesCloseToGeocodeCity.push(data)

          let store_opening_promises = []
          for (let opening_template of this.openingTimeTemplates) {
            for (let day_idx of opening_template.day_idxs) {
              let opening_params: PermittedOpeningParams = {
                close: opening_template.close,
                open: opening_template.open,
                day: day_idx,
                store_id: data.id
              }
              store_opening_promises.push(this.createStoreOpening(opening_params))
            }
          }
          for (let promise of store_opening_promises) {
            await promise
          }
          
          this.removeStoreForm()
          this.updateStoreDataSource()
          this.changeSelectedStore(data)
          resolve()
        },
        err => {
          console.log(err)
          resolve()
        }
      )
    })
  }

  public createStoreOpening(opening_template: PermittedOpeningParams): Promise<Opening> {
    return new Promise<Opening>(resolve => {
      this.storeLoadingService.createStoreOpening(opening_template).subscribe(
        data => { 
          resolve(data)
        },
        err => {
          console.log(err)
          resolve(null)
        }
      )
    })
  }
  
  public async setStoreGeocoder(init_with_geo_event?: GeocoderEvent): Promise<void> {
    this.storeGeocoder = new MapboxGeocoder({
      accessToken: this.mapBoxAccessToken,
      marker: false,
      language: 'de-DE',
      mapboxgl: mapboxgl
    })
    while (!document.getElementById('store_address_geocoder')) {
      await PAUtil.sleep(100)
    }
    const geocoder_elem = document.getElementById('store_address_geocoder')
    !geocoder_elem.children.length ? geocoder_elem.appendChild(this.storeGeocoder.onAdd(this.mapContainer.map)) : {};
    this.storeGeocoder.on('result', (event) => {
      this.onStoreGeocoderEvent(event)
    })
    if (init_with_geo_event) this.onStoreGeocoderEvent(init_with_geo_event)
  }

  onStoreGeocoderEvent(event: GeocoderEvent): void {

    let {country, region, city, post_code, street, street_no, center, name} = PAUtil.getGeocoderEventAddress(event);

    let address_country_control = this.storeForm.controls['address_country']
    address_country_control.setValue(country)
    address_country_control.markAsTouched();
    address_country_control.updateValueAndValidity()
    let address_state_control = this.storeForm.controls['address_state']
    address_state_control.setValue(region)
    address_state_control.markAsTouched();
    address_state_control.updateValueAndValidity()
    let address_city_control = this.storeForm.controls['address_city']
    address_city_control.setValue(city)
    address_city_control.markAsTouched();
    address_city_control.updateValueAndValidity()
    let address_zip_control = this.storeForm.controls['address_zip']
    address_zip_control.setValue(post_code)
    address_zip_control.markAsTouched();
    address_zip_control.updateValueAndValidity()
    let address_street_control = this.storeForm.controls['address_street']
    address_street_control.setValue(street + ' ' + street_no)
    address_street_control.markAsTouched();
    address_street_control.updateValueAndValidity()

    let latitude_control = this.storeForm.controls['address_latitude']
    latitude_control.setValue(center[1])
    latitude_control.markAsTouched();
    latitude_control.updateValueAndValidity()
    let longitude_control = this.storeForm.controls['address_longitude']
    longitude_control.setValue(center[0])
    longitude_control.markAsTouched();
    longitude_control.updateValueAndValidity()

    this.storeForm.updateValueAndValidity()

  }

  async updateStoreNr(): Promise<void> {

    let store_nr_valid_control = this.storeForm.controls['store_nr_valid']
    let store_nr_control = this.storeForm.controls['store_nr']
    let customer_control = this.storeForm.controls['customer_id']

    if (store_nr_control.value && customer_control.value) {
      store_nr_valid_control.setValue('pending')
      
      let customer_letter = this.customerLetters.get(customer_control.value)
      let store_nr = `C${customer_letter?.id}_${customer_letter?.letter}_${store_nr_control.value}`
      let result = await new Promise<boolean>(resolve => {
        this.storeLoadingService.getStoresWithStoreNr(store_nr).subscribe(
          data => {
            resolve(!data.length)
          },
          err => {
            console.log(err)
            resolve(false)
          }
        )
      })

      store_nr_valid_control.setValue(result)
    } else {
      store_nr_valid_control.setValue('init')
    }
  }

  async resetOpeningTimeTemplates(template_store?: Store) {
    if (template_store) {
      let {templates, store_openings} = await PADataControl.Instance.getStoresOpeningTimeTemplates(template_store.id)
      this.openingTimeTemplates = templates
    } else {
      this.openingTimeTemplates = [{
        open: '07:00',
        close: '21:00',
        day_idxs: [0,1,2,3,4,5]
      }]
    }
  }

  getOpeningTimeDescription(): string {
    let opening_string = this.openingTimeTemplates.map(template => PAUtil.openingTimeTemplateDayRangeString(template) + ' ' + template.open + ' - ' +template.close).join(' | ')
    return !opening_string ? 'Keine' : opening_string == 'Mo - Sa 07:00 - 21:00' ? 'Standard: ' + opening_string : 'Angepasst: ' + opening_string
  }

  getContactDescription(): string {
    return [
      this.storeForm.controls['firstname'].value,
      this.storeForm.controls['lastname'].value,
      this.storeForm.controls['email'].value,
      this.storeForm.controls['phone'].value,
      this.storeForm.controls['comment'].value,
    ].filter(value => value).join(' ')
  }

  getAddressDescription(): string {
    let street = this.storeForm.controls['address_street'].value ? this.storeForm.controls['address_street'].value : '';
    let zip = this.storeForm.controls['address_zip'].value
    let city = this.storeForm.controls['address_city'].value
    let country = this.storeForm.controls['address_country'].value
    let address = street && zip && city && country ? `${street}, ${zip} ${city} (${country})` : ''

    return [
      this.storeForm.controls['address_name'].value,
      address
    ].filter(value => value).join(' | ')
  }

  getTicketDataDescription(): string {
    let project = this.ticketForm.controls['project_id'].value ? PADataControl.Instance.getProject(this.ticketForm.controls['project_id'].value) : null;
    let project_name = project ? project.project_name : '';
    let priority_name = this.ticketForm.controls['priority_id'].value ? PADataControl.Instance.getPriority(this.ticketForm.controls['priority_id'].value).name : '';
    let status_name = this.ticketForm.controls['status_id'].value ? project.getStatus(this.ticketForm.controls['status_id'].value).name : '';
    let time_estimation = this.ticketForm.controls['time_estimation'].value ? this.ticketForm.controls['time_estimation'].value.toString() + 'min' : ''
    let ext_nr = this.ticketForm.controls['external_order_nr'].value ? 'Ext.Nr.: ' + this.ticketForm.controls['external_order_nr'].value : ''
    let sla_date = this.ticketForm.controls['sla_date'].value ? 'SLA: ' + PATimeControl.Instance.dateToDateTimeString(new Date(this.ticketForm.controls['sla_date'].value), false) : ''

    return [
      project_name,
      priority_name,
      status_name,
      time_estimation,
      ext_nr,
      sla_date
    ].filter(value => value).join(' | ')
  }

  getTicketMaterialDescription(): string {
    return this.ticketMaterialContainer.getMaterialString()
  }

  getTicketDescriptionDescription(): string {
    let description = this.ticketForm.controls['description'].value
    return description ? (description.length > 100 ? `${description.slice(0,100)}...` : description) : ''
  }

  getTicketContactDescription(): string {
    return [
      this.ticketForm.controls['contact_name'].value,
      this.ticketForm.controls['contact_phone'].value,
      this.ticketForm.controls['contact_email'].value,
    ].filter(value => value).join(' | ')
  }

  getCustomerLetters(): CustomerLetter[] {
    return [...this.customerLetters.values()]
  }

  getStoreCustomerNames(store: Store): string {
    return store.customer_ids.map(id => this.customerLetters.get(id)?.name).join(', ')
  }

  getStoreProjectNames(store: Store): string {
    return store.project_ids.map(id => PADataControl.Instance.hasLoadedProjectWithId(id) ? PADataControl.Instance.getProject(id) : null).filter(project => project).map(project => project.project_name).join(', ')
  }

  filterCustomerLetters(event: Event): void {
    const input = event.target as HTMLInputElement
    const search_string: string = input.value

    this.filterCustomerLettersMeta(search_string)
  }

  filterCustomerLettersMeta(search_string: string): void {
    this.filteredCustomerLetters = [...this.customerLetters.values()].filter(customer_letter => customer_letter.name.toLowerCase().includes(search_string.toLowerCase()))
  }

  filterTemporaryOperationProject(event: Event): void {
    const input = event.target as HTMLInputElement
    const search_string: string = input.value

    this.filterTemporaryOperationProjectMeta(search_string)
  }

  filterTemporaryOperationProjectMeta(search_string: string): void {
    this.filteredTemporaryOperationProjects = PADataControl.Instance.loadedProjects.filter(project => project.project_name.toLowerCase().includes(search_string.toLowerCase()) && this.selectedTemporaryOperationStore.project_ids.includes(project.id))
  }

  async changeSelectedCustomer(): Promise<string[]> {
    this.storeForm.controls['project_id'].setValue(null)
    await this.updateStoreNr()
    await this.updateAutocompleteStores()
    return this.updateAutocompleteNames('')
  }

  async changeSelectedProject(): Promise<string[]> {
    await this.updateAutocompleteStores()
    return this.updateAutocompleteNames('')
  }

  async updateAutocompleteStores(): Promise<void> {
    this.autocompleteStores = []
    let customer_id = this.storeForm.controls['customer_id'].value

    await new Promise<void>(
      resolve => {
        if (Number.isInteger(customer_id)) {
          let project_id = this.storeForm.controls['project_id'].value
          if (Number.isInteger(project_id)) {
            this.storeLoadingService.getProjectStores(project_id, 20).subscribe(
              data => {
                this.autocompleteStores = data
                resolve()
              },
              err => {
                console.log(err)
                resolve()
              }
            )
          } else {
            this.storeLoadingService.getCustomerStores(customer_id, 20).subscribe(
              data => {
                this.autocompleteStores = data
                resolve()
              },
              err => {
                console.log(err)
                resolve()
              }
            )
          }
        }
      }
    )
  }

  updateAutocompleteNames(search: string): string[] {
    let random_stores: Store[] = []
    let valid_stores = this.autocompleteStores.filter(store => store.name.toLowerCase().includes(search.toLowerCase()))
    let random_store_amount = 3
    if (random_store_amount >= valid_stores.length) {
      random_stores = valid_stores
    } else {
      while(random_store_amount) {
        let random_store = valid_stores.splice(Math.floor(Math.random() * valid_stores.length), 1)[0]
        random_stores.push(random_store)
        random_store_amount -= 1
      }
    }
    return random_stores.map(store => store.name)
  }

  onAddProjectSubmit(): void {
    if (this.addProjectForm.valid) {
      let project_id = this.addProjectForm.get('project_id').value
      this.storeLoadingService.addStoreProject(this.selectedTemporaryOperationStore.id, project_id).subscribe(
        data => {
          PATourPlannerControl.Instance.snackBar.open(
            'Projekt hinzugefügt',
            'ok'
          )._dismissAfter(3000)
          let idx = this.storesCloseToGeocodeCity.indexOf(this.selectedTemporaryOperationStore)
          if (idx != -1) {
            this.selectedTemporaryOperationStore = this.storesCloseToGeocodeCity[idx] = data
          }
          this.showAddProjectForm = false
        },
        err => {
          console.log(err)
        }
      )
    }
  }

  filterAddStoreProject(event: Event) {
    const input = event.target as HTMLInputElement
    const search_string: string = input.value

    this.filterAddStoreProjectMeta(search_string)
  }

  filterAddStoreProjectMeta(search_string: string): void {
    this.filteredAddStoreProjects = PADataControl.Instance.loadedProjects.filter(project => project.project_name.toLowerCase().includes(search_string.toLowerCase()))
  }

  clickAddTicket(): void {
    this.showNewTicketForm = true
    this.showAddProjectForm = false
    this.initializeTicketFormGroup()
    this.filterTemporaryOperationProjectMeta('')
  }

  clickAddProject(): void {
    this.showAddProjectForm = true
    this.showNewTicketForm = false
    this.initializeAddProjectFormGroup()
    this.filterAddStoreProjectMeta('')
  }

  initializeAddProjectFormGroup() {
    this.addProjectForm.setValue({
      project_id: null
    });
    this.addProjectForm.markAsUntouched()
  }

  async updateRoutesLineLayers(routes: MapRoute[]): Promise<void> {
    let prefix = 'extra_route_layers_'
    PAMapboxControl.Instance.deleteUUIDLayers(prefix, this.mapContainer)
    await PAMapboxControl.Instance.generateLineLayers(
      routes,
      this.mapContainer,
      {
        use_exact_waypoints: PATourPlannerControl.Instance.technicianDistanceType == 'street_distance',
        prefix: prefix,
      }
    )
  }

  protected readonly faPlusCircle = faPlusCircle;
  protected readonly PASettingsControl = PASettingsControl;
  protected readonly PADataControl = PADataControl;
  protected readonly PATimeControl = PATimeControl;
  protected readonly faExclamationTriangle = faExclamationTriangle;
}

export class ListsSimpleChange<T> extends SimpleChange {
  
  //previousValue: T[];
  //currentValue: T[];

  constructor(simple_change: SimpleChange){
    super(simple_change?.previousValue, simple_change?.currentValue, simple_change?.firstChange || false)
  };

  listValuesChanged(): boolean {
    return !PAUtil.equalSets(new Set(this.previousValue), new Set(this.currentValue))
  }

  getRemovedValues(): T[] {
    let previous_value = this.previousValue || []
    let current_value = this.currentValue || []
    return previous_value.filter(value => !current_value.includes(value))
  }

  getAddedValues(): T[] {
    let previous_value = this.previousValue || []
    let current_value = this.currentValue || []
    return current_value.filter(value => !previous_value.includes(value))
  }
}