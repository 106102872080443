import { Pipe, PipeTransform } from '@angular/core'
import { Article } from '../_models'

@Pipe({
    name: 'articleFilter',
})
export class ArticleFilterPipe implements PipeTransform {
  transform(allArticles: Article[], searchText?: string): Article[] {
    if (!allArticles) {
      return allArticles
    }

    const searchTerms = searchText.toLowerCase().split(' ')

    return allArticles.filter((article) => {

      let searchTermMaps = searchTerms.map((term) => {
        return `${article.description} ${article.rma} ${article.serial_number} ${article.id}`.toLowerCase().includes(term)
      })

      return searchTermMaps.reduce((acc, t) => acc && t, true)
    })
  }
}

