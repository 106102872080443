import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormsModule } from '@angular/forms';
import { CommonModule } from "@angular/common";
import { ReactiveFormsModule } from "@angular/forms";
import { MatFormFieldModule } from "@angular/material/form-field";
import { NgSelectModule } from "@ng-select/ng-select";
import { MatInputModule } from '@angular/material/input';

@Component({
  imports: [
    CommonModule, 
    ReactiveFormsModule, 
    MatFormFieldModule, 
    NgSelectModule,
    MatInputModule, 
    FormsModule,
  ],
    selector: 'hawk-address-form',
    templateUrl: './address-form.component.html',
    styleUrls: ['./address-form.component.scss'],
})
export class AddressFormComponent  implements OnInit {
  @Input() formGroup: FormGroup;
  @Input() prefix: string = '';
  @Input() countries: any[] = [];

  public searchTerm: string = ''
  public _prefix: string = '';

  constructor(
  ) { }

  ngOnInit(): void {
    console.log('formgroup', this.formGroup)
    this._prefix = this.prefix;
    if(this.prefix && !this.prefix.endsWith('_')) this._prefix = this.prefix + '_';
  }
}