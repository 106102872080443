import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http'
import { OWNetworkAnswer, OWNetworkRequestBody, OWNetworkRequest, OWZipCodeDistanceRequestBody, OWOptimizationRequestBody, OWAbstractionData, OWLoginRequestBody, OWLoginAnswerBody } from '../_models/optiwae.interface';
import { environment } from '../../environments/environment'
import { v4 as uuidv4 } from 'uuid';
import { PAUtil } from '../_components/planning-assistant/classes/util';
import { WebSocket } from '../_components/planning-assistant/classes/websocket'; 
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class OptiwaeService {

  public webSocket: WebSocket
  public answers = new Map<string, OWNetworkAnswer>()
  public activeConnection: boolean
  public sessionId: string

  constructor(private http: HttpClient) { 
    //this.connectWebsocket()
    let session = localStorage.getItem('sessionID')
    if (session) {
      this.sessionId = session
    }
  }

  async connectWebsocket() {
    this.webSocket = new WebSocket('wss://demo.end-info.de:15292/websocket');
  
    (this.webSocket.messages$ as Observable<OWNetworkAnswer>) 
      .subscribe({
        next: (msg: OWNetworkAnswer) => {
          this.answers.set(msg.request_token, msg);
        },
        error: (err) => console.error('WebSocket error:', err),
        complete: () => console.log('WebSocket closed')
      });
  
    this.webSocket.connectionStatus$.subscribe(status => {
      console.log('WebSocket status:', status);
      this.activeConnection = status;
    });
  }

  public loadExampleJsons() {
    const path = environment.apiURL + 'example_json_files/optiwae'
    return this.http.get<any>(path)
  }

  createRequest(route: string, body: OWNetworkRequestBody): OWNetworkRequest {
    return {
        route: route,
        body: body,
        request_token: this.createUUIDToken()
    }
}

  createUUIDToken(){
      return uuidv4()
  }

  createZipCodeDistanceRequestBody(zip_code_1: string, zip_code_2: string): OWZipCodeDistanceRequestBody {
      return {
          zip_code_1: zip_code_1,
          zip_code_2: zip_code_2,
          session_id: this.sessionId
      }
  }

  createLoginRequestBody(password: string, username: string): OWLoginRequestBody {
    return {
      password: password,
      username: username
    }
  }

  createOptimizationRequestBody(abstraction_data: OWAbstractionData): OWOptimizationRequestBody {
      return {
          abstraction_data: abstraction_data,
          session_id: this.sessionId
      }
  }

  sendRequest(request: OWNetworkRequest): void {
    this.webSocket.message(request)
  }

  async waitForAnswer(request: OWNetworkRequest): Promise<OWNetworkAnswer> {
      while (!this.answers.has(request.request_token)){
          await PAUtil.sleep(100)
      }
      return this.answers.get(request.request_token)
  }

  async sendRequestAndWaitForAnswer(request: OWNetworkRequest): Promise<OWNetworkAnswer> {
      this.sendRequest(request)
      let answer = await this.waitForAnswer(request)
      return answer
  }

  public async login(password: string, username: string){
    let request = this.createRequest('Login',this.createLoginRequestBody(password,username))
    let answer_body = (await this.sendRequestAndWaitForAnswer(request)).body as OWLoginAnswerBody
    this.sessionId = answer_body.session_id
    PAUtil.setLocalStorageItem('sessionID', this.sessionId)
    return answer_body
  }

}
function subscribe(arg0: { next: (msg: OWNetworkAnswer) => Map<string, OWNetworkAnswer>; }) {
  throw new Error('Function not implemented.');
}

