<div class="row">
  <div class="col-sm-3">
    <h3>Lager
    </h3>
  </div>
</div>
<div class="row">
  <div class="col-sm-3">
    <div class="card">
      <a routerLink="/lager/eingang" class="muted">
        <div class="card-body">
          <p class="icons"><i class="fas fa-truck"></i><i class="fas fa-arrow-right"></i><i class="far fa-warehouse"></i><i class="fad fa-clipboard-check"></i></p><br>
          <h2>Erfassung Großlieferung</h2>
        </div>
      </a>
    </div>
  </div>
  <div class="col-sm-3">
    <div class="card">
      <a routerLink="/lager/verarbeitung" class="muted">
        <div class="card-body">
          <p class="icons"><i class="fas fa-list"></i></p><br>
          <h2>Großlieferung Weiterverarbeitung</h2>
        </div>
      </a>
    </div>
  </div>
</div>
