<div class="row">
  <div class="col-sm-12">
    <h3>Rücksendebeauftragung
    </h3>

    <div class="card">
      <div class="card-body">
        <p>
          Hier können Rücksendeaufträge erfasst werden. Nach dem Absenden werden diese oberhalb des Formulars angezeigt
          und mit einem Klick auf den Lieferschein kann die bereits erzeugte Versandmarke abgerufen werden.
        </p>
      </div>
    </div>
  </div>
</div>

<div class="row" *ngIf="returnJobs?.length > 0">
  <div class="col-12">
    <div class="card">
      <div class="card-body">
        <h2>Gerade erfasse Aufträge</h2>
        <table style="width: 100%;">
          <tr>
            <td>Absender</td>
            <td>Gewicht</td>
            <td>Länge</td>
            <td>Breite</td>
            <td>Höhe</td>
            <td>Lieferschein</td>
          </tr>
          <tr *ngFor="let job of returnJobs">
            <td>{{ job.sender_address_company }}</td>
            <td>{{ job.parcel_weight }}</td>
            <td>{{ job.parcel_length }}</td>
            <td>{{ job.parcel_width }}</td>
            <td>{{ job.parcel_height }}</td>
            <td><a href="/warehouse/deliveryNote/{{ job.id }}/show" target="_blank">Zum Lieferschein {{ job.id }}</a>
            </td>
          </tr>
        </table>
      </div>
    </div>

  </div>
</div>

<div class="row">
  <div class="col-12">
    <div class="card">

      <div class="card-body" *ngIf="status == 'sending'">
        Auftrag wird versendet.
        <i class="fa fa-circle-o-notch fa-spin fa-fw"></i>
      </div>
      <div class="card-body" *ngIf="status == 'new'">
        <form [formGroup]="form" (submit)="onSubmit()">
          <h2>Neuen Auftrag anlegen</h2>
          <mat-form-field class="projectfield">
            <mat-label>Lager ID Absender</mat-label>
            <input matInput placeholder="Lager ID" formControlName="warehouse_id">
          </mat-form-field>

          <mat-form-field class="projectfield">
            <mat-label>Paket Gewicht</mat-label>
            <input matInput type="number" placeholder="Gewicht in kg" formControlName="parcel_weight">
          </mat-form-field>
          <mat-form-field class="projectfield">
            <mat-label>Paket Länge</mat-label>
            <input matInput type="number" placeholder="Länge in cm" formControlName="parcel_length">
          </mat-form-field>
          <mat-form-field class="projectfield">
            <mat-label>Paket Breite</mat-label>
            <input matInput type="number" placeholder="Breite in cm" formControlName="parcel_width">
          </mat-form-field>
          <mat-form-field class="projectfield">
            <mat-label>Paket Höhe</mat-label>
            <input matInput type="number" placeholder="Höhe in cm" formControlName="parcel_height">
          </mat-form-field>
          <button mat-flat-button color="primary" type="submit">Beauftragen!</button>
        </form>
      </div>
    </div>

  </div>
</div>