import { FormControl, FormGroup, FormsModule } from '@angular/forms';
import { ArticleTemplate } from 'src/app/_models';
import { CommonModule } from "@angular/common";
import { ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { MatFormFieldModule } from "@angular/material/form-field";
import { NgSelectModule } from "@ng-select/ng-select";

import { Component, OnInit, inject } from '@angular/core'
import { ArticleService } from 'src/app/_services'
import { Router } from '@angular/router'
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
@Component({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    NgSelectModule,
    FontAwesomeModule
  ],
    selector: 'hawk-article-search-form',
    templateUrl: './article-search-form.component.html',
    styleUrls: ['./article-search-form.component.scss'],
})
export class ArticleSearchFormComponent implements OnInit {
  public products: ArticleTemplate[] = []
  public statusList: any[] = [
    {id: '', description: 'Alle Artikle'},
    {id: 'WAREHOUSE', description: 'Artikel im Lager'},
    {id: 'SHIPPING', description: 'Artikel im Versand'},
    {id: 'CONSUMED', description: 'Verbrauchte Artikel'}
  ]
  public searchTerm: string = ''

  public articleSearchForm = new FormGroup({
    status_id: new FormControl(),
    product_id: new FormControl(),
    rma: new FormControl(),
    serial_number: new FormControl()
  })

  constructor(
    private router: Router,
    private articleService: ArticleService
  ) { }

  ngOnInit(): void {
    this.articleService.loadArticleTemplates().subscribe({
      next: (data) => {
        this.products = data.sort((a, b) => (a.description.localeCompare(b.description)));
      },
      error: (err) => {
        console.error(err)
      }
    });
  }

  public search() {
    const formdata = this.articleSearchForm.value
    let params = {
      status_id: formdata.status_id,
      product_id: formdata.product_id,
      rma: formdata.rma,
      serial_number: formdata.serial_number
    }

    this.router.navigate(['artikel', 'suche'], { queryParams: params });
  }
}