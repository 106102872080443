<hawk-record-navigation class="common_style" *ngIf="store" [breadcrumbLinkList]="[{description: 'Filialen', link: '/a/filiale'}]" [root]="'store'" [leafDescription]="store.name">
  <div style="flex: 1;" class="card-body responsive_split">
    <div style="flex: 1;" class="flex_col">
      <section>
        <h2>Stammdaten</h2>
        <table class="section_table">
          <tbody>
          <tr>
            <th class="editable" (click)="editNr = !editNr">Nr</th>
            <td *ngIf="!editNr">{{store.nr}}</td>
            <td *ngIf="editNr"><hawk-shared-edit-input [textValue]="store.nr" (saveClicked)="saveNr($any($event))" (cancelClicked)="editNr = false"></hawk-shared-edit-input></td>
          </tr>
          <tr>
            <th class="editable" (click)="editName = !editName">Name</th>
            <td *ngIf="!editName">{{store.name}}</td>
            <td *ngIf="editName"><hawk-shared-edit-input [textValue]="store.name" (saveClicked)="saveName($any($event))" (cancelClicked)="editName = false"></hawk-shared-edit-input></td>
          </tr>
          <tr>
            <th class="editable" (click)="editCustomer = !editCustomer">Kunden</th>
            <td *ngIf="!editCustomer">
              <a *ngFor="let customer of storeCustomers" class="mr6" [routerLink]="'/kunde/' + customer.id">
                {{customer.name}}
                {{customer.name}}
              </a>
            </td>
            <td *ngIf="editCustomer"><hawk-shared-edit-input [modus]="'multiselect'" [multiSelectValue]="selectedStoreCustomerIds" [selectOptions]="customers" (saveClicked)="saveCustomers($any($event))" (cancelClicked)="editCustomer = false"></hawk-shared-edit-input></td>
          </tr>
          <tr>
            <th class="editable" (click)="editMail = !editMail">E-Mail</th>
            <td *ngIf="!editMail"><a [href]="'mailto:' + store.email">{{store.email}}</a></td>
            <td *ngIf="editMail"><hawk-shared-edit-input [textValue]="store.email" (saveClicked)="saveMail($any($event))" (cancelClicked)="editMail = false"></hawk-shared-edit-input></td>
          </tr>
          <tr>
            <th class="editable" (click)="editPhone1 = !editPhone1">Telefon 1</th>
            <td *ngIf="!editPhone1"><a [href]="'phone:' + store.phone1">{{store.phone1}}</a></td>
            <td *ngIf="editPhone1"><hawk-shared-edit-input [textValue]="store.phone1" (saveClicked)="savePhone1($any($event))" (cancelClicked)="editPhone1 = false"></hawk-shared-edit-input></td>
          </tr>
          <tr>
            <th class="editable" (click)="editPhone2 = !editPhone2">Telefon 2</th>
            <td *ngIf="!editPhone2"><a [href]="'phone:' + store.phone2">{{store.phone2}}</a></td>
            <td *ngIf="editPhone2"><hawk-shared-edit-input [textValue]="store.phone2" (saveClicked)="savePhone2($any($event))" (cancelClicked)="editPhone2 = false"></hawk-shared-edit-input></td>
          </tr>
          <tr>
            <th class="editable" (click)="editComment = !editComment">Kommentar</th>
            <td *ngIf="!editComment">{{store.comment}}</td>
            <td *ngIf="editComment"><hawk-shared-edit-input [textValue]="store.comment" (saveClicked)="saveComment($any($event))" (cancelClicked)="editComment = false"></hawk-shared-edit-input></td>
          </tr>
          </tbody>
        </table>
      </section>
      <section>
        <h2>Ausstattung</h2>
        <table class="section_table">
          <tbody>
          <tr>
            <th class="editable" (click)="editRegister = !editRegister">Anzahl Kassen</th>
            <td *ngIf="!editRegister">{{store.register}}</td>
            <td *ngIf="editRegister"><hawk-shared-edit-input [modus]="'number'" [numberValue]="store.register" (saveClicked)="saveRegister($any($event))" (cancelClicked)="editRegister = false"></hawk-shared-edit-input></td>
          </tr>
          <tr>
            <th class="editable" (click)="editIP = !editIP">IP</th>
            <td *ngIf="!editIP">{{store.ip}}</td>
            <td *ngIf="editIP"><hawk-shared-edit-input [textValue]="store.ip" (saveClicked)="saveIP($any($event))" (cancelClicked)="editIP = false"></hawk-shared-edit-input></td>
          </tr>
          </tbody>
        </table>
      </section>
      <section>
        <h2 style="margin-bottom: 0">Adresse <a [href]="'https://maps.google.de?q=' + [store.address_street, store.address_zip, store.address_city].join(',')" target="_blank">Google Maps</a></h2>
        <table class="section_table">
          <tbody>
          <tr>
            <td>Firma</td>
            <td>{{store.address_company}}</td>
            <td style="text-align: right;" rowspan="5">
              <button mat-raised-button (click)="showModal = 'address'">
                Adresse ändern
              </button>
            </td>
          </tr>
          <tr>
            <td>Name</td>
            <td>{{(store.address_firstname || '') + ' ' + (store.address_lastname || '')}}</td>
          </tr>
          <tr>
            <td>Straße</td>
            <td>{{store.address_street}}</td>
          </tr>
          <tr>
            <td>PLZ und Ort</td>
            <td>{{store.address_zip}} {{store.address_city}}</td>
          </tr>
          <tr>
            <td>Land (Staat)</td>
            <td>{{(store.address_country || '').toUpperCase()}}</td>
          </tr>
          </tbody>
        </table>
      </section>
      <section>
        <h2>Karte</h2>
        <div *ngIf="store.address_latitude && store.address_longitude" style="flex: 1; width: 100%; border: 1px solid gray;" class="flex_row">
          <mgl-map [style]="'mapbox://styles/mapbox/streets-v9'" [zoom]="[5]" [center]="[store.address_longitude, store.address_latitude]">
            <mgl-marker [lngLat]="[store.address_longitude, store.address_latitude]">
              <div class="marker" [style.borderColor]="'black'" [style.backgroundColor]="'#af0000'" title="{{store.name}}"></div>
            </mgl-marker>
          </mgl-map>
        </div>
      </section>
    </div>
    <div class="flex_col" style="width: 65%;">
      <section style="flex: 0!important;">
        <h2>Öffnungszeiten</h2>
        <table class="section_table">
          <tbody>
          <tr>
            <td>Montag</td>
            <td>
              <div class="flex_row weekday_openings">
                <div class="opening" *ngFor="let opening of mondayOpenings">
                  {{opening.open}} - {{opening.close}} <fa-icon class="click-icon" (click)="deleteOpening(opening)" [icon]="faTrash"></fa-icon>
                </div>
              </div>
            </td>
            <td style="text-align: right;" rowspan="7">
              <button mat-raised-button (click)="openOpeningModal()">
                Öffnungszeiten hinzufügen
              </button>
            </td>
          </tr>
          <tr>
            <td>Dienstag</td>
            <td>
              <div class="flex_row weekday_openings">
                <div class="opening" *ngFor="let opening of tuesdayOpenings">
                  {{opening.open}} - {{opening.close}} <fa-icon class="click-icon" (click)="deleteOpening(opening)" [icon]="faTrash"></fa-icon>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td>Mittwoch</td>
            <td>
              <div class="flex_row weekday_openings">
                <div class="opening" *ngFor="let opening of wednesdayOpenings">
                  {{opening.open}} - {{opening.close}} <fa-icon class="click-icon" (click)="deleteOpening(opening)" [icon]="faTrash"></fa-icon>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td>Donnerstag</td>
            <td>
              <div class="flex_row weekday_openings">
                <div class="opening" *ngFor="let opening of thursdayOpenings">
                  {{opening.open}} - {{opening.close}} <fa-icon class="click-icon" (click)="deleteOpening(opening)" [icon]="faTrash"></fa-icon>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td>Freitag</td>
            <td>
              <div class="flex_row weekday_openings">
                <div class="opening" *ngFor="let opening of fridayOpenings">
                  {{opening.open}} - {{opening.close}} <fa-icon class="click-icon" (click)="deleteOpening(opening)" [icon]="faTrash"></fa-icon>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td>Samstag</td>
            <td>
              <div class="flex_row weekday_openings">
                <div class="opening" *ngFor="let opening of saturdayOpenings">
                  {{opening.open}} - {{opening.close}} <fa-icon class="click-icon" (click)="deleteOpening(opening)" [icon]="faTrash"></fa-icon>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td>Sonntag</td>
            <td>
              <div class="flex_row weekday_openings">
                <div class="opening" *ngFor="let opening of sundayOpenings">
                  {{opening.open}} - {{opening.close}} <fa-icon class="click-icon" (click)="deleteOpening(opening)" [icon]="faTrash"></fa-icon>
                </div>
              </div>
            </td>
          </tr>
          </tbody>
        </table>
      </section>
      <section>
        <h2>Tickets</h2>
        <div class="full_width flex_row">
          <input type="text" style="margin: auto 0 4px auto;" placeholder="Schnellfilter..." (input)="quickSearchChanged($event)">
        </div>
        <ag-grid-angular class="ag-theme-quartz" style="height: 480px; width: 100%;" [rowData]="tickets" [theme]="hawkTheme"
                         [defaultColDef]="defaultColDef" [columnDefs]="colDefs" [rowClassRules]="rowClassRules" [pagination]="true"
                         [rowSelection]="rowSelection" [autoSizeStrategy]="autoSizeStrategy"
                         [paginationPageSize]="16" [localeText]="locale" [sideBar]="sideBarOptions"
                         [paginationPageSizeSelector]="[16,32,64,128]" (gridReady)="onGridReady($event)">
        </ag-grid-angular>
      </section>
    </div>
  </div>
  <div style="flex-wrap: wrap;" class="flex_row footer_row">
    <a [routerLink]="'/filiale'">
      <button mat-button class="primary_color_button" color="primary">
        <fa-icon [icon]="faArrowLeft"></fa-icon>
        Zurück
      </button>
    </a>
    <div style="flex: 1;"></div>
    <div [matTooltip]="storeIsDeletable ? '' : 'Filialen können nur gelöscht werden, wenn keine Abhängigkeiten zu Tickets vorhanden sind'">
      <button mat-raised-button [disabled]="!storeIsDeletable" (click)="deleteStore()">
        <fa-icon [icon]="faTrash"></fa-icon>
        Löschen
      </button>
    </div>
  </div>

  <div *ngIf="showModal === 'address'" aria-modal="true" class="modal is-active" role="dialog">
    <div class="modal-background"></div>
    <div class="modal-content">
      <div class="wrapper" style="padding: 2px;">
        <hawk-shared-edit-address [city]="store.address_city" [company]="store.address_company" [country]="store.address_country"
                                  [lat]="store.address_latitude" [lng]="store.address_longitude"
                                  [state]="store.address_state" [street]="store.address_street" [zip]="store.address_zip"
                                  [firstname]="store.address_firstname" [lastname]="store.address_lastname" [editName]="true"
                                  (onSubmitEvent)="saveAddress($event)" (onAbortEvent)="showModal = null"
        ></hawk-shared-edit-address>
      </div>
    </div>
    <button class="modal-close" aria-label="close" (click)="showModal = null"></button>
  </div>

  <div class="modal is-active has-toolbar" role="dialog" aria-modal="true" *ngIf="showModal === 'openings'">
    <div class="modal-background"></div>
    <div class="modal-content">
      <div class="wrapper" style="padding: 2px 2px 24px 2px;">
        <h4>Öffnungszeiten hinzufügen</h4>
        <div class="flex_row mb12">
          <mat-checkbox color="primary" class="mr12" [(ngModel)]="setMonday">Mo</mat-checkbox>
          <mat-checkbox color="primary" class="mr12" [(ngModel)]="setTuesday">Di</mat-checkbox>
          <mat-checkbox color="primary" class="mr12" [(ngModel)]="setWednesday">Mi</mat-checkbox>
          <mat-checkbox color="primary" class="mr12" [(ngModel)]="setThursday">Do</mat-checkbox>
          <mat-checkbox color="primary" class="mr12" [(ngModel)]="setFriday">Fr</mat-checkbox>
          <mat-checkbox color="primary" class="mr12" [(ngModel)]="setSaturday">Sa</mat-checkbox>
          <mat-checkbox color="primary" class="mr12" [(ngModel)]="setSunday">So</mat-checkbox>
        </div>
        <div class="flex_row">
          <mat-form-field class="ml12 outline-small no-subscript" appearance="outline">
            <mat-label>Von</mat-label>
            <input type="time" matInput [(ngModel)]="timeFrom">
          </mat-form-field>
          <mat-form-field class="ml12 outline-small no-subscript" appearance="outline">
            <mat-label>Bis</mat-label>
            <input type="time" matInput [(ngModel)]="timeUntil">
          </mat-form-field>
        </div>
      </div>
      <div class="modal-toolbar">
        <div class="modal-toolbar-container-left">
          <button mat-raised-button class="primary_bg_color_button mr6" (click)="addOpenings()">Hinzufügen</button>
          <button mat-button (click)="showModal = null">Abbrechen</button>
        </div>
        <div class="modal-toolbar-container-right">
        </div>
      </div>
    </div>
    <button class="modal-close" aria-label="close" (click)="showModal = null"></button>
  </div>

</hawk-record-navigation>
