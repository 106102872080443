<div style="width: 100%;" class="flex_col common_style">
  <div class="switch_flex">
    <div style="margin: 8px 4px; padding: 8px 8px 5px;overflow-x: auto; position: relative; flex: 1;" class="card">
      <mat-tab-group [(selectedIndex)]="selectedTabIdx" (selectedTabChange)="updateTableSizes($event)">
        <mat-tab label="Service Tickets">
          <div class="tab_body" style="position: relative; margin-top: 6px; overflow-x: auto; max-width: 100%;">
            <ag-grid-angular class="ag-theme-quartz" style="height: 580px; min-width: 1590px; max-width: 100%;" [rowData]="shownTickets"
                             [theme]="hawkTheme" [loadThemeGoogleFonts]="true"
                             [defaultColDef]="defaultColDef" [columnDefs]="ticketColDefs" [rowClassRules]="rowClassRules" [pagination]="true"
                             [rowSelection]="'multiple'" [tooltipShowDelay]=".2" [autoSizePadding]="0" [autoSizeStrategy]="autoSizeStrategy"
                             [paginationPageSize]="20" [localeText]="locale" [sideBar]="sideBarOptions" [rowMultiSelectWithClick]="true"
                             [paginationPageSizeSelector]="[20,40,80,160]" (gridReady)="onTicketGridReady($event)" (selectionChanged)="onTicketSelectionChanged()">
            </ag-grid-angular>
          </div>
          <div class="flex_row full_width" style="max-width: 100%;">
            <section style="flex: 0; margin-left: 0; margin-right: 8px; margin-bottom: 0;">
              <h2>Planungassistent</h2>
              <button id="pa_button" mat-raised-button color="primary" [disabled]="!selectedTicketIds.length" (click)="openPlanningAssistant()">
                Auswahl öffnen
              </button>
            </section>
            <section style="flex-wrap: wrap; flex: 1; margin-left: 0; margin-right: 0; margin-bottom: 0;" class="flex_row">
              <h2>Projektauswahl</h2>
              <div style="height: 30px; width: 100%; position: relative;">
                <div class="abs_bounds section_scrollbar" style="overflow-x: auto; bottom: -20px; left: -10px; right: -10px; padding: 0 10px;">
                  <div class="flex_row" style="width: fit-content; flex-wrap: nowrap;">
                    <div style="border-right: 1px solid black; margin-right: 4px;">
                      <div class="open_project" [ngClass]="selectedProjects == 'all' ? 'selected' : ''" (click)="selectAllProjects()">
                        <div style="margin: auto 12px;">
                          Alle
                        </div>
                      </div>
                    </div>
                    <div class="open_project"
                         [ngClass]="(selectedProjects != 'all' && selectedProjects.includes(open_project.project_name)) ? 'selected' : ''"
                         *ngFor="let open_project of openProjects" (click)="clickProject(open_project.project_name)"
                         [style.color]="open_project.unplanned_tickets.length == 0 ? 'gray' : 'black'"
                         [style.background-color]="open_project.unplanned_tickets.length == 0 ? 'lightgray' : 'white'"
                    >
                      <div style="margin: auto 12px;">
                        {{open_project.project_name}}
                        <ng-container *ngIf="open_project.unplanned_tickets.length == 0">
                          <fa-icon style="color: rgb(0,128,0);" [icon]="faCheck"></fa-icon>
                        </ng-container>
                        <ng-container *ngIf="open_project.unplanned_tickets.length != 0">
                          ({{open_project.unplanned_tickets.length}})
                        </ng-container>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </mat-tab>
        <mat-tab label="Techniker">
          <div class="tab_body" style="position: relative; margin-top: 6px; overflow-x: auto; max-width: 100%">
            <ag-grid-angular class="ag-theme-quartz" style="height: 580px; min-width: 1300px; max-width: 100%;" [rowData]="shownTechnicians"
                             [theme]="hawkTheme" [loadThemeGoogleFonts]="true"
                             [defaultColDef]="defaultColDef" [columnDefs]="technicianColDefs" [rowClassRules]="rowClassRules" [pagination]="true"
                             [rowSelection]="'single'" [autoSizeStrategy]="autoSizeStrategy"
                             [paginationPageSize]="20" [localeText]="locale" [sideBar]="sideBarOptions" [rowMultiSelectWithClick]="true"
                             [paginationPageSizeSelector]="[20,40,80,160]" (gridReady)="onTechnicianGridReady($event)" (selectionChanged)="onTechnicianSelectionChanged()"
                             (rowDataUpdated)="onTechnicianRowDataUpdate()"
            >
            </ag-grid-angular>
          </div>
        </mat-tab>
      </mat-tab-group>
      <div id="filter_container">
        <div class="filter_collection" [ngClass]="selectedTabIdx == 0 ? 'shown': 'hidden'">
          <div style="flex: 1;"></div>
          <input type="search" style="margin: auto 0 auto 0; width: 50%; max-width: 400px" placeholder="Schnellfilter..." (input)="ticketQuickSearchChanged($event)">
        </div>
        <div class="filter_collection" [ngClass]="selectedTabIdx == 1 ? 'shown': 'hidden'">
          <div style="margin: auto 0 auto auto;" class="no_flex">
            <mat-button-toggle-group style="min-width: fit-content;" [(ngModel)]="technicianDayOperationFilter" (ngModelChange)="updateShownTechnicians()">
              <mat-button-toggle value="with_day_operations">Mit Tagesaufträgen</mat-button-toggle>
              <mat-button-toggle value="all">Alle</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
          <div style="margin: auto 6px;" class="no_flex">
            <mat-button-toggle-group style="min-width: fit-content;" [(ngModel)]="technicianGroupFilter" (ngModelChange)="updateShownTechnicians()">
              <mat-button-toggle value="bentomax">Bentomax-Techniker</mat-button-toggle>
              <mat-button-toggle value="all">Alle</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
          <input type="search" style="margin: auto 0 auto 0; width: 33%; max-width: 400px;" placeholder="Schnellfilter..." (input)="technicianQuickSearchChanged($event)">
        </div>
      </div>
    </div>
    <div class="card side_card" [ngClass]="selectedTabIdx == 0 ? 'small' : 'big'">
      <div class="side_card_content" [ngClass]="selectedTabIdx == 0 ? 'shown': 'hidden'">
        <div style="width: 100%; height: fit-content; flex-wrap: wrap;" class="flex_row">
          <div class="kpi_tile" style="background-color: #c57626; border-color: #c57626; color: white; max-width: 100%!important;" (click)="selectKPITile('6sla')" [ngClass]="(SLA6HWarningTicketCount ? '' : 'no_data ') + (selectedKPITile == '6sla' ? 'selected' : '')">
            <h6>6h bis SLA</h6>
            <div style="margin: auto 0;">
              {{SLA6HWarningTicketCount}} Ticket(s)
            </div>
          </div>
          <div class="kpi_tile" style="background-color: #af0d0d; border-color: #af0d0d; color: white; max-width: 100%!important;" (click)="selectKPITile('0sla')" [ngClass]="(SLA0HWarningTicketCount ? '' : 'no_data ') + (selectedKPITile == '0sla' ? 'selected' : '')">
            <h6>SLA überschritten</h6>
            <div style="margin: auto 0;">
              {{SLA0HWarningTicketCount}} Ticket(s)
            </div>
          </div>
        </div>
        <div style="flex-wrap: wrap;" class="flex_row">
          <div class="kpi_tile" (click)="selectKPITile('new')" [ngClass]="(newTicketCount ? '' : 'no_data ') + (selectedKPITile == 'new' ? 'selected' : '')">
            <h6>Heute erstellt</h6>
            <div class="count">
              {{newTicketCount}}
            </div>
            <!--<div class="canvas_container">
              <canvas class="project_canvas" id="newTicketChart">{{newTicketChart}}</canvas>
            </div>-->
          </div>
          <div class="kpi_tile" (click)="selectKPITile('qm')" [ngClass]="(qmTicketCount ? '' : 'no_data ') + (selectedKPITile == 'qm' ? 'selected' : '')">
            <h6>QM-Tickets</h6>
            <div class="count">
              {{qmTicketCount}}
            </div>
            <!--<div class="canvas_container">
              <canvas class="project_canvas" id="qmTicketChart">{{qmTicketChart}}</canvas>
            </div>-->
          </div>
          <div class="kpi_tile" (click)="selectKPITile('overdue')" [ngClass]="(overdueTicketCount ? '' : 'no_data ') + (selectedKPITile == 'overdue' ? 'selected' : '')">
            <h6>Überfällig</h6>
            <div class="count">
              {{overdueTicketCount}}
            </div>
            <!--<div class="canvas_container">
              <canvas class="project_canvas" id="overdueTicketChart">{{overdueTicketChart}}</canvas>
            </div>-->
          </div>
          <div class="kpi_tile" (click)="selectKPITile('not_approached')" [ngClass]="(notApproachedTicketCount ? '' : 'no_data ') + (selectedKPITile == 'not_approached' ? 'selected' : '')">
            <h6>Nicht angefahren</h6>
            <div class="count">
              {{notApproachedTicketCount}}
            </div>
            <!--<div class="canvas_container">
              <canvas class="project_canvas" id="notApproachedTicketChart">{{notApproachedTicketChart}}</canvas>
            </div>-->
          </div>
        </div>
      </div>
      <div class="side_card_content" [ngClass]="selectedTabIdx == 1 ? 'shown': 'hidden'">
        <ng-container *ngIf="selectedTechnician">
          <h4 style="margin: 0;">Tageseinsätze {{selectedTechnician.full_name}}</h4>
          <hr style="margin: 4px 0;">
          <hawk-route-visualization
                  style="height: 100%; max-height: 630px; overflow-y: auto;"
                  [nodes]="routeVisualizationNodes"
          ></hawk-route-visualization>
        </ng-container>
      </div>
    </div>
  </div>
  <div class="card" style="margin: 0 4px 4px; padding: 8px; position: relative;">
    <hawk-capacity-bars
            [barData]="technicianBarData"
            [options]="capacityBarOptions"
            [filter]="capacityBarsFilter"
            [deadlines]="deadlineNow ? [deadlineNow] : []"
            [labels]="[{color: 'rgb(0,128,0)', description: 'Unterwegs'}, {color: 'rgb(253,0,0)', description: 'Nicht genutzt'}, {color: 'rgb(255,132,132)', description: 'Freie Zeit'}, {color: 'lightgray', description: 'Abwesenheit'}]"
            [measureUnit]="':00'"
            [unitAmountEnd]="unitAmountEnd"
            [unitAmountInterSteps]="6"
            [unitAmountStart]="unitAmountStart"
            (clickBarEvent)="selectTechnicianInTable(shownTechnicians[$event])"
            (changeOptionEvent)="changeBarOption($event)"
    >
    </hawk-capacity-bars>
    <div *ngIf="showCapacityBarThrobber" style="position: absolute; z-index: 200; top: 0; left: 0; width: 100%; height: 100%;">
      <hawk-throbber [description]="'Aktualisiere Daten...'"></hawk-throbber>
    </div>
  </div>
</div>
