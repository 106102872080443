import { CommonModule } from "@angular/common";
import { ReactiveFormsModule } from "@angular/forms";

import { Component, HostBinding, Input, OnChanges, OnInit, SimpleChange, SimpleChanges } from '@angular/core'
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms'
import { NotePageFilterParameter, Operation, OperationTechnicianEntry, TicketLegacyNote, TicketNote } from 'src/app/_models'
import { TicketNoteService } from 'src/app/_services'
import { TicketNoteFilterPipe } from "src/app/_pipes";

@Component({
  imports: [CommonModule, ReactiveFormsModule, TicketNoteFilterPipe],
    selector: 'hawk-note-subpage',
    templateUrl: './note-subpage.component.html',
    styleUrls: ['./note-subpage.component.scss'],
})
export class NoteSubpageComponent implements OnInit, OnChanges {
  @Input() operation: Operation
  @Input() ticketId: number

  public notes: TicketNote[] = []
  public legacyNotes: TicketLegacyNote[] = []
  public technicians: OperationTechnicianEntry[] = []
  public selectedTab = 'history'

  public sourceTypes = ['email', 'phone', 'qmjob', 'system']
  public sourceTypeLabels = {
    email: 'E-Mail',
    phone: 'Telefon',
    system: 'System',
    qmjob: 'QM-Aufgabe'
  }

  public noteForm = new UntypedFormGroup({
    special_billing: new UntypedFormControl(false),
    not_on_time: new UntypedFormControl(false),
    material_faulty: new UntypedFormControl(false),
    special_condition: new UntypedFormControl(false),
    feedback_incomplete: new UntypedFormControl(false),
    missing_signature: new UntypedFormControl(false),
    other_problem: new UntypedFormControl(false),
    other_problem_title: new UntypedFormControl(''),
    technician_id: new UntypedFormControl(''),
    note: new UntypedFormControl(''),
    share_internal: new UntypedFormControl(false),
    share_technician: new UntypedFormControl(false),
    share_customer: new UntypedFormControl(false),
    share_partner: new UntypedFormControl(false),
    share_bookkeeper: new UntypedFormControl(false),
    source_type: new UntypedFormControl('phone'),
    source: new UntypedFormControl(''),
  })

  public filterType: string[] = []
  public filterTechnician = ''
  public filterSource = ''
  public filterDestination: string[] = []
  public activeFilterBox = 'none'
  public filterParameter: NotePageFilterParameter

  public filterForm = new UntypedFormGroup({
    special_billing: new UntypedFormControl(false),
    not_on_time: new UntypedFormControl(false),
    material_faulty: new UntypedFormControl(false),
    special_condition: new UntypedFormControl(false),
    feedback_incomplete: new UntypedFormControl(false),
    missing_signature: new UntypedFormControl(false),
    other_problem: new UntypedFormControl(false),
    technician_id: new UntypedFormControl(''),
    share_internal: new UntypedFormControl(false),
    share_technician: new UntypedFormControl(false),
    share_customer: new UntypedFormControl(false),
    share_partner: new UntypedFormControl(false),
    share_bookkeeper: new UntypedFormControl(false),
    source_type: new UntypedFormControl('phone'),
  })

  constructor(
    public ticketNoteService: TicketNoteService
  ) {
  }

  ngOnInit(): void {
    this.technicianChanged()
    this.getNotes()
    this.buildFilterParameters()
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.operation && changes.operation.currentValue && changes.operation.currentValue.technicians) {
      const operation: Operation = changes.operation.currentValue
      this.updateOperation(operation)
    }
  }

  public updateOperation(operation: Operation) {
    if (operation.technicians) {
        this.technicians = operation.technicians

        const l = this.technicians.length
        if (l > 0) {
          this.noteForm.patchValue({technician_id: this.technicians[0].id})
        }
      }
    this.technicianChanged()
    this.getNotes()
  }

  public technicianChanged(): void {
    if ((this.technicians.length > 0) &&
          this.noteForm.get('technician_id').value !== '-' &&
          this.noteForm.get('share_internal').value !== true) {
      ['share_partner', 'share_technician'].forEach((element) => {
        this.noteForm.get(element).enable()
      })
    } else {
      ['share_partner', 'share_technician'].forEach((element) => {
        this.noteForm.controls[element].setValue(false)
        this.noteForm.get(element).disable()
      })
    }
  }

  public checkboxChanged(): void {
    ['share_partner', 'share_technician', 'share_customer'].forEach((element) => {
      if (this.noteForm.get('share_internal').value === true) {
        this.noteForm.controls[element].setValue(false)
        this.noteForm.get(element).disable()
      } else {
        this.noteForm.get(element).enable()
      }
    })
  }

  public noteTypeChanged(): void {
    this.noteForm.controls.other_problem_title.setValue('')
  }

  public selectTab(tab: string) {
    this.selectedTab = tab
  }

  public isSelectedTab(tab: string): boolean {
    return this.selectedTab === tab
  }

  public otherProblemChecked(): boolean {
    return this.noteForm.get('other_problem').value
  }

  public resetForm() {
    this.noteForm.reset()
    const checkboxes = ['share_partner', 'share_technician', 'share_customer', 'share_internal', 'share_bookkeeper',
             'special_billing', 'not_on_time', 'material_faulty', 'special_condition', 'feedback_incomplete',
             'missing_signature', 'other_problem']
    checkboxes.forEach((element) => {
        this.noteForm.controls[element].setValue(false)
    })

    const textfields = ['other_problem_title', 'note', 'source']
    textfields.forEach((element) => {
      this.noteForm.controls[element].setValue('')
    })

    this.noteForm.get('source_type').setValue('phone')
    if (this.operation) {
      this.updateOperation(this.operation)
    }
  }

  public getNotes() {
    this.ticketNoteService.getNotes(String(this.ticketId)).subscribe(
      (data) => {
        this.notes = data
      },
      (err) => {
        console.error(err)
      },
    )

    this.ticketNoteService.getLegacyNotes(String(this.ticketId)).subscribe(
      (data) => {
        this.legacyNotes = data
      },
      (err) => {
        console.error(err)
      },
    )
  }

  public submitNote() {
    const formdata = this.noteForm.value

    const note: TicketNote = {
      ticket_id: this.ticketId,
      special_billing: formdata.special_billing,
      not_on_time: formdata.not_on_time,
      material_faulty: formdata.material_faulty,
      special_condition: formdata.special_condition,
      feedback_incomplete: formdata.feedback_incomplete,
      missing_signature: formdata.missing_signature,
      other_problem: formdata.other_problem,
      other_problem_title: formdata.other_problem_title,
      technician_id: formdata.technician_id,
      note: formdata.note,
      share_internal: formdata.share_internal,
      share_technician: formdata.share_technician,
      share_customer: formdata.share_customer,
      share_partner: formdata.share_partner,
      share_bookkeeper: formdata.share_bookkeeper,
      source_type: formdata.source_type,
      source: formdata.source,
    }

    if (this.operation) {
      note.operation_id = this.operation.id
    }

    if (formdata.technician_id === '-') {
      delete note.technician_id
    }

    this.ticketNoteService.createNote(note).subscribe(
      (data) => {
        this.resetForm()
        this.getNotes()
        this.selectTab('history')
      },
      (err) => {
        console.error(err)
      },
    )
  }

  public translateBoolean(b: boolean): string {
    return b === true ? '✓' : ''
  }

  public nodeClass(b: boolean): string {
    return b === true ? 'noteType noteActive' : 'noteType'
  }

  public activateFilterBox(filter: string): boolean {
    this.activeFilterBox = filter
    return false
  }

  public setType() {
    this.filterType = []

    const checkboxes = ['special_billing', 'not_on_time',
                        'material_faulty', 'special_condition',
                        'feedback_incomplete', 'missing_signature',
                        'other_problem']
    checkboxes.forEach((element) => {
      if (this.filterForm.get(element).value) {
        this.filterType.push(element)
      }
    })

    this.buildFilterParameters()
    this.activeFilterBox = 'none'
  }

  public resetType() {
    this.filterType = []
    const checkboxes = ['special_billing', 'not_on_time',
                        'material_faulty', 'special_condition',
                        'feedback_incomplete', 'missing_signature',
                        'other_problem']
    checkboxes.forEach((element) => {
      if (this.filterForm.get(element).value) {
        this.filterForm.controls[element].setValue(false)
      }
    })

    this.buildFilterParameters()
    this.activeFilterBox = 'none'
  }

  public setTechnician() {
    this.filterTechnician = ''

    if (this.filterForm.get('technician_id').value !== '-') {
      this.filterTechnician = this.filterForm.get('technician_id').value
    }

    this.buildFilterParameters()
    this.activeFilterBox = 'none'
  }

  public resetTechnician() {
    this.filterTechnician = ''

    this.filterForm.controls.technician_id.setValue('')

    this.activeFilterBox = 'none'
  }

  public setSource() {
    this.filterSource = ''

    if (this.filterForm.get('source_type').value !== '') {
      this.filterSource = this.filterForm.get('source_type').value
    }

    this.buildFilterParameters()
    this.activeFilterBox = 'none'
  }

  public resetSource() {
    this.filterSource = ''

    this.filterForm.controls.source_type.setValue('')

    this.buildFilterParameters()
    this.activeFilterBox = 'none'
  }

  public setDestination() {
    this.filterDestination = []

    const checkboxes = ['share_partner', 'share_technician', 'share_customer', 'share_internal', 'share_bookkeeper']
    checkboxes.forEach((element) => {
      if (this.filterForm.get(element).value) {
        this.filterDestination.push(element)
      }
    })

    this.buildFilterParameters()
    this.activeFilterBox = 'none'
  }

  public resetDestination() {
    this.filterDestination = []

    const checkboxes = ['share_partner', 'share_technician', 'share_customer', 'share_internal', 'share_bookkeeper']
    checkboxes.forEach((element) => {
      this.filterForm.controls[element].setValue(false)
    })

    this.buildFilterParameters()
    this.activeFilterBox = 'none'
  }

  public buildFilterParameters() {
    this.filterParameter = {
      jobtype: this.filterType,
      technician: this.filterTechnician,
      source: this.filterSource,
      destination: this.filterDestination,
    }
  }
}