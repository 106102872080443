
import { UserService } from "src/app/_services"
import { PlanningAssistantService } from "src/app/_services/planning-assistant.service"
import { PADistance } from "./distance"
import { PACoordinates } from "./coordinates"
import { PAProgress } from "./progress"
import { PAProject } from "./project"
import { PAUtil } from "./util"
import { PATechnicianDate } from "./technician-date"
import { CalendarWeek, CalendarWeekData, MapRoute } from "src/app/_models/planning-assistant.interface"
import { CalendarComponent } from "../calendar/calendar.component"
import { PALocation } from "./location"
import { PAFilter } from "./filter"
import { PATicket } from "./ticket"
import { Article, Priority } from "src/app/_models"
import { PAOperation } from "./operation"
import { MaterialContainer } from "./material-container/material-container.interface"
import { MarkerInstance } from "./mapbox-marker"
import { Subject } from "rxjs"
import { FireUpdateOnChange } from "./util-classes/fire-update-on-change";
import { PADataControl } from "../singletons/pa-data-control";
import { PAFilterControl } from "../singletons/pa-filter-control";
import { PATimeControl } from "../singletons/pa-time-control";
import { UserAbsence } from "../../../_models/technician.interface";

export class PATechnician extends FireUpdateOnChange<void> implements MarkerInstance {

  static pseudoTechnicianNames: string[] = ['offene Planung', 'Bentomax Bereitschaft', 'nicht vergeben', 'Kein Einsatz notwendig', 'Materialerfassung Kiel']
  static planningAssistantService: PlanningAssistantService
  static userService: UserService
  static calendarComponent: CalendarComponent

  operationCounts?: {
    project: Map<number, number>,
    priority: Map<number, number>
  }
  technicianDates = new Map<number, PATechnicianDate>()
  public loadedCalendarWeekDataUpdates: { cwd: CalendarWeekData, last_update: number }[] = []
  public calendarWeeksWorkTimes = new Map<CalendarWeekData, { average: number, planned: number }>()
  public location: PALocation
  public articles: Article[]
  private loadingArticles = false

  private _color?: { r: number, g: number, b: number, a: number }

  public priorityBasedOperationTimes = new Map<number, { operation_id: number, duration: number, date: string }[]>()
  private loadingPriorityIds = []
  public currentTourPlannings: TechnicianTourPlanning

  public placeholderTechnicianIds: number[] = []

  constructor(
    public id: number,
    public locationID: number,
    public firstname: string,
    public lastname: string,
    public coordinates: PACoordinates,
    public company: string,
    public zipCode: string,
    public companyAddressCompany: string,
    public telephones: string[],
    public roles: string[],
    public properties: string[],
    public driving_time_factor: number
  ) {
    super()
    if (PADataControl.Instance.locationMap.has(locationID)) {
      this.location = PADataControl.Instance.locationMap.get(locationID)
    } else {
      this.location = new PALocation(coordinates, locationID, zipCode, '', '')
    }
    PADataControl.Instance.technicianMap.set(id, this)
    PADataControl.Instance.allTechnicians = PADataControl.Instance.allTechnicians.concat(PAFilter.filterOutPseudoTechnicians([this]))
    this.operationCounts = {
      priority: new Map<number, number>(),
      project: new Map<number, number>()
    }
    this.currentTourPlannings = new TechnicianTourPlanning(this, PATimeControl.Instance)
    this.fireUpdate()
  }

  get color(): { r: number, g: number, b: number, a: number } {
    return this._color
  }

  set color(color: { r: number, g: number, b: number, a: number }) {
    this._color = color
    this.fireUpdateManually()
  }

  getCoordinates(): PACoordinates {
    return this.location.coordinates
  }

  executeBeforeChange(): void {
    return
  }

  getCurrentCalendarWeekTechnicianDates(): PATechnicianDate[] {
    return PATimeControl.Instance.getCurrentCalendarWeekDayFilteredTimestamps().map(ts => this.getTechnicianDate(ts, true, true))
  }

  public getColorString(change_alpha?: number): string {
    let preferred_technicians = PAFilterControl.Instance.preferredCalendarTechnicians

    let rgba = this.color || (preferred_technicians.includes(this) ? {
      r: 0,
      g: 127,
      b: 211,
      a: 1
    } : PAUtil.hexToRgba('#ffffffaa'))

    return change_alpha ? PAUtil.rgbaToString({
      r: rgba.r,
      g: rgba.g,
      b: rgba.b,
      a: change_alpha
    }) : PAUtil.rgbaToString(rgba)
  }

  getDistanceToCoordinatesAsTheCrowFlies(lat: number, lon: number): number {
    return this.location.getDistanceToCoordinatesAsTheCrowFlies(lat, lon)
  }

  getDistanceToLocationAsTheCrowFlies(location: PALocation): number {
    return this.location.getDistanceToCoordinatesAsTheCrowFlies(location.coordinates.latitude, location.coordinates.longitude)
  }

  public getLoadedActiveProjects(apply_global_filter?: boolean): PAProject[] {
    let projects = PADataControl.Instance.loadedProjects.filter(project => project.active_technician_ids.length == 0 || project.active_technician_ids.indexOf(this.id) >= 0)
    return apply_global_filter ? projects.filter(p => PAProject.selectedProjects.includes(p)) : projects
  }

  async loadArticles(): Promise<void> {
    if (!this.articles && !this.loadingArticles) {
      let progress = new PAProgress(`Lade Artikel von ${this.getFullName()}`, 1)
      this.loadingArticles = true
      this.articles = []
      return new Promise<void>(resolve => {
        progress.addSubProgress(PATechnician.userService.getArticles(this.id), this.firstname + ' ' + this.lastname).subscribe(
          data => {
            this.articles = data
            this.loadingArticles = false
            resolve()
          },
          err => {
            console.log(err)
            resolve()
          }
        )
      })
    } else {
      return new Promise<void>(resolve => {
        resolve()
      })
    }
  }

  async loadDistancesToCoordinates(lat: number, long: number): Promise<void> {
    return new Promise((resolve) => {
      PATechnician.userService.distancesToCoordinates([this.id], lat, long).subscribe(
        async (data) => {
          for (let dist_obj of data) {
            let technician = PADataControl.Instance.getTechnician(dist_obj.id)
            let operation_to_coordinates_key = PADistance.coordinatesToDistanceKey(lat, long, technician.coordinates.latitude, technician.coordinates.longitude)
            let inverse_operation_to_coordinates_key = PADistance.coordinatesToDistanceKey(technician.coordinates.latitude, technician.coordinates.longitude, lat, long)
            PADistance.realPositionDistances.set(operation_to_coordinates_key, {
              distance: Math.round(dist_obj.distance),
              duration: Math.round(dist_obj.duration)
            })
            PADistance.realPositionDistances.set(inverse_operation_to_coordinates_key, {
              distance: Math.round(dist_obj.distance),
              duration: Math.round(dist_obj.duration)
            })
          }
          resolve()
        },
        (err) => {
          console.error(err)
          resolve()
        }
      )
    })
  }

  public getTechnicianDate(timestamp: number, init_if_not_existent?: boolean, load_data?: boolean): PATechnicianDate {
    if (!this.technicianDates.has(timestamp) && init_if_not_existent) {
      this.initTechnicianDate(timestamp, load_data)
    }
    return this.technicianDates.get(timestamp)
  }

  public isBentomaxTechnician(): boolean {
    let address_company_matches = false
    if (this.company) {
      address_company_matches = this.company.toLowerCase().includes('bentomax')
    }
    let company_address_company_matches = false
    if (this.companyAddressCompany) {
      company_address_company_matches = this.companyAddressCompany.toLowerCase().includes('bentomax')
    }
    return (address_company_matches || company_address_company_matches) && this.roles.indexOf('SUBCONTRACTOR') < 0
  }

  public isProjectTechnician(project_id: number): boolean {
    let project_technician_ids = PADataControl.Instance.getProject(project_id).active_technician_ids
    return project_technician_ids.indexOf(this.id) >= 0 || !project_technician_ids.length
  }

  public async loadPriorityBasedOperationTimes(priority_id: number): Promise<boolean> {
    if (!this.operationTimeEntryForPriorityWasLoaded(priority_id)) {
      if (!this.loadingPriorityIds.includes(priority_id)) {
        this.loadingPriorityIds.push(priority_id)
        return new Promise((resolve) => {
            PATechnician.userService.priorityBasedOperationTimes(this.id, priority_id).subscribe(
              (data) => {
                this.priorityBasedOperationTimes.set(priority_id, data)
                PAUtil.removeElementFromList(this.loadingPriorityIds, priority_id)
                resolve(true)
              },
              (err) => {
                console.error(err)
                PAUtil.removeElementFromList(this.loadingPriorityIds, priority_id)
                resolve(false)
              }
            )
          }
        )
      } else {
        while (this.loadingPriorityIds.includes(priority_id)) {
          await PAUtil.sleep(50)
        }
        return
      }
    }
  }

  public async loadTechnicianProjectOperationCounts(project_id: number): Promise<void> {
    this.operationCounts.project.set(project_id, 0)
    return new Promise((resolve) => {
      PATechnician.userService.project_operation_count(this.id, project_id).subscribe(
        async (data) => {
          this.operationCounts.project.set(project_id, data.done_project_operations_count)
          resolve()
        },
        (err) => {
          console.error(err)
          resolve()
        },
      )
    })
  }

  public async loadTechnicianPriorityOperationCounts(priority_id: number): Promise<void> {
    this.operationCounts.priority.set(priority_id, 0)
    return new Promise((resolve) => {
      PATechnician.userService.priority_operation_count(this.id, priority_id).subscribe(
        async (data) => {
          this.operationCounts.priority.set(priority_id, data.done_priority_operations_count)
          resolve()
        },
        (err) => {
          console.error(err)
          resolve()
        },
      )
    })
  }

  public async updateTechnicianExperience(project_id: number, priority_id: number) {
    if (!this.operationCounts.priority.has(priority_id)) {
      await this.loadTechnicianPriorityOperationCounts(priority_id)
    }
    if (!this.operationCounts.project.has(project_id)) {
      await this.loadTechnicianProjectOperationCounts(project_id)
    }
  }

  public getPriorityBasedAverageOperationTime(priority_id: number): number {
    let operation_times = this.getOperationTimes(priority_id)
    if (operation_times) {
      return PAUtil.averageTime(operation_times.map(operation_time => operation_time.duration))
    } else {
      return -1
    }
  }

  public getAverageOperationTime(operation: PAOperation): number {
    let average_time = this.getPriorityBasedAverageOperationTime(operation.ticket.priority.id)
    if (average_time > 0) {
      return average_time
    } else {
      return operation.ticket.time_estimation
    }
  }

  public getOperationTimes(priority_id: number): { operation_id: number, duration: number, date: string }[] {
    if (this.operationTimeEntryForPriorityWasLoaded(priority_id)) {
      return this.priorityBasedOperationTimes.get(priority_id)
    } else {
      return []
    }
  }

  public operationTimeEntryForPriorityWasLoaded(priority_id: number): boolean {
    return this.priorityBasedOperationTimes.has(priority_id)
  }

  public getAbsencesForDayTimestamp(ts: number): UserAbsence[] {
    return PADataControl.Instance.absences.filter(absence => absence.employee_name.toLowerCase() == this.getFullName().toLowerCase() && absence.from.getTime() <= ts && ts <= absence.until.getTime() - 1)
  }

  public initTechnicianDate(utc_timestamp: number, load_data?: boolean) {
    let week_day = PATimeControl.Instance.getTimeStampsWeekDay(utc_timestamp)
    let holidays = PATimeControl.Instance.getTimestampsHolidays(utc_timestamp)
    let absences = this.getAbsencesForDayTimestamp(utc_timestamp)
    let utc_date = PATimeControl.Instance.timestampToDatestring(utc_timestamp, false)
    let local_date = new Date(utc_timestamp)
    local_date.setHours(0)
    let date_in_hawk_link_format = utc_date.split('-').join('.')
    const technician_date = new PATechnicianDate(
      this,
      utc_date,
      {
        week_day: week_day,
        utc_timestamp: utc_timestamp,
        local_timestamp: local_date.getTime(),
        holidays: holidays,
        date_string: PATimeControl.Instance.weekDayToGermanName(week_day, true) + ' ' + PATimeControl.Instance.timestampToDatestring(utc_timestamp, false, '.')
      },
      absences,
      '/ticket/search?operationFrom=' + date_in_hawk_link_format + '&operationTo=' + date_in_hawk_link_format + '&technicians=' + this.id,
      PADataControl.Instance.generateNextOperatorDayId()
    )
    this.technicianDates.set(utc_timestamp, technician_date)

    if (load_data) {
      technician_date.loadData().then(_ => {
        technician_date.tour.updateLocations()
        technician_date.changedTourContainer.tour.updateLocations()
      })
    } else {
      technician_date.tour.updateLocations()
      technician_date.changedTourContainer.tour.updateLocations()
    }
  }

  public getTotalTechnicianWorktimeOfCalendarWeekInHours(calendar_week: CalendarWeek, operation_time_filter: 'average' | 'planned'): number {
    let calendar_week_data = PATimeControl.Instance.getCalendarWeekData(calendar_week)
    if (calendar_week_data.calendar_week.year == calendar_week.year && calendar_week_data.calendar_week.number == calendar_week.number) {
      let worktime_in_minutes = PATimeControl.Instance.weekDays.reduce((sum, weekday) => sum + this.getTechnicianDate(calendar_week_data.weekdays[weekday].timestamp, true).tour.getTotalWorkTime(true, operation_time_filter), 0)
      return Math.round(worktime_in_minutes / 60)
    }
    return 0
  }

  public updateTotalTechnicianWorkTimeOfCalendarWeekInHours(calendar_week: CalendarWeek): void {
    let filtered_loaded_cwds = this.loadedCalendarWeekDataUpdates.filter(cwdu => cwdu.cwd.calendar_week.year == calendar_week.year && cwdu.cwd.calendar_week.number == calendar_week.number)
    let calendar_week_data = PATimeControl.Instance.getCalendarWeekData(calendar_week)
    let average_worktime = this.getTotalTechnicianWorktimeOfCalendarWeekInHours(calendar_week, 'average')
    let planned_worktime = this.getTotalTechnicianWorktimeOfCalendarWeekInHours(calendar_week, 'planned')
    if (filtered_loaded_cwds.length) {
      this.calendarWeeksWorkTimes.set(filtered_loaded_cwds[0].cwd, {
        planned: planned_worktime,
        average: average_worktime
      })
    } else {
      this.calendarWeeksWorkTimes.set(calendar_week_data, {planned: planned_worktime, average: average_worktime})
      this.loadedCalendarWeekDataUpdates.push({cwd: calendar_week_data, last_update: 0})
    }
  }

  public getFullName(cut_at?: number): string {
    let full_name = this.firstname + ' ' + this.lastname
    return ((cut_at && full_name.length > cut_at) ? full_name.slice(0, cut_at) + '...' : full_name)
  }

  public getNameAbbreviation(): string {
    return `${this.firstname[0]}. ${this.lastname[0]}. `
  }

  public getAirDistanceRouteToLocation(location: PALocation, color: string): MapRoute {
    return this.location.getAirDistanceRouteToLocation(location, color)
  }

  public hasTicketMaterial(ticket: PATicket): boolean {
    return this.hasContainerMaterial(ticket)
  }

  public hasContainerMaterial(container: MaterialContainer): boolean {
    if (!container.materials.length) {
      return true
    } else {
      let template_amounts = new Map<number, number>()
      for (let material of container.materials) {
        if (template_amounts.has(material.template_id)) {
          template_amounts.set(material.template_id, template_amounts.get(material.template_id) + material.amount)
        } else {
          template_amounts.set(material.template_id, material.amount)
        }
      }
      for (let template_amount_kv of template_amounts.entries()) {
        let template_id = template_amount_kv[0]
        let ticket_template_amount = template_amount_kv[1]
        let technician_template_articles = this.articles.filter(article => article.template_id == template_id)
        let technician_template_amount = technician_template_articles.length
        if (technician_template_amount < ticket_template_amount) {
          return false
        }
      }
    }
    return true
  }

  public hasOperationMaterial(operation: PAOperation): boolean {
    if (operation.ticket) {
      return this.hasTicketMaterial(operation.ticket)
    } else {
      return true
    }
  }

  public getWeekWorktimeForCalendarWeekData(cwd: CalendarWeekData, time_filter: string): number {
    let year = cwd.calendar_week.year
    let number = cwd.calendar_week.number
    let filtered_loaded_cwdus = this.loadedCalendarWeekDataUpdates.filter(lcwd => lcwd.cwd.calendar_week.number == number && lcwd.cwd.calendar_week.year == year)
    if (filtered_loaded_cwdus.length > 0) {
      return this.calendarWeeksWorkTimes.get(filtered_loaded_cwdus[0].cwd)[time_filter]
    } else {
      return 0
    }
  }

  public getCalendarWeekDataUpdate(cw: CalendarWeek): { cwd: CalendarWeekData, last_update: number } {
    return this.loadedCalendarWeekDataUpdates.filter(cwdu => cwdu.cwd.calendar_week.number == cw.number && cwdu.cwd.calendar_week.year == cw.year)[0]
  }

  public getLastCalendarWeekDataUpdateTimestamp(cw: CalendarWeek): number {
    let cwdu = this.getCalendarWeekDataUpdate(cw)
    if (cwdu) {
      return cwdu.last_update
    } else {
      return 0
    }
  }

  public calendarWeekWorkloadInPercent(cw: CalendarWeek): number {
    const day_percentages: number[] = []
    const cwd = PATimeControl.Instance.getCalendarWeekData(cw)
    for (const weekday of PATimeControl.Instance.weekDays) {
      const day_timestamp: number = cwd.weekdays[weekday].timestamp
      day_percentages.push(this.getTechnicianDate(day_timestamp, true).tour.route.time_specific_data[PAFilterControl.Instance.selectedOperationTimeFilter].workload_percent)
    }
    const sum = day_percentages.reduce((a, b) => a + b, 0)
    return Math.min(Math.round((sum / 5) || 0), 100)
  }

  public async getPriorityOperationCountUntilTimestamp(priority: Priority, timestamp: number): Promise<number> {
    return this.currentTourPlannings.getPriorityOperationCountBeforeTimestamp(priority, timestamp)
  }

  static setPlanningAssistantService(service: PlanningAssistantService): void {
    this.planningAssistantService = service
  }

  static setUserService(service: UserService): void {
    this.userService = service
  }

  static setCalendarComponent(component: CalendarComponent): void {
    this.calendarComponent = component
  }

  static initTechnicianDatesForCalendarWeeksData(technicians: PATechnician[], calendar_weeks_data: CalendarWeekData[], data_service: PADataControl) {
    for (let calendar_week_data of calendar_weeks_data) {
      this.initTechnicianDatesForCalendarWeek(technicians, calendar_week_data, data_service)
    }
  }

  static async initTechnicianDatesForCalendarWeek(technicians: PATechnician[], calendar_week_data: CalendarWeekData, data_service: PADataControl): Promise<void> {
    for (let technician of technicians) {
      let filtered_loaded_cwds = technician.loadedCalendarWeekDataUpdates.filter(cwdu => cwdu.cwd.calendar_week.year == calendar_week_data.calendar_week.year && cwdu.cwd.calendar_week.number == calendar_week_data.calendar_week.number)
      if (!filtered_loaded_cwds.length) {
        technician.calendarWeeksWorkTimes.set(calendar_week_data, {average: 0, planned: 0})
        technician.loadedCalendarWeekDataUpdates.push({cwd: calendar_week_data, last_update: 0})
      }
    }
    await data_service.loadTechnicianDatesDataForCalendarWeekData(technicians, calendar_week_data)
  }

  static async initTechnicianDateForCalendarDay(technicians: PATechnician[], timestamp: number) {
    for (let technician of technicians) {
      if (!technician.technicianDates.has(timestamp)) {
        technician.initTechnicianDate(timestamp, true)
      } else {
        let technician_date = technician.technicianDates.get(timestamp)
        if (technician_date.loadingStatus == 'init') {
          technician_date.loadData().then(_ => technician_date.tour.updateRoute())
        }
      }
    }
  }
}

export class TechnicianTourPlanning {

  priorityCountsChangedSubject = new Subject<{ priority: Priority, start_ts: number }[]>()

  private _technicianDatesInPlanning: PATechnicianDate[] = []

  constructor(
    private _technician: PATechnician,
    private _timeService: PATimeControl
  ) {
  }

  get technicianDatesInPlanning() {
    return this._technicianDatesInPlanning
  }

  set technicianDatesInPlanning(tours: PATechnicianDate[]) {
    let tours_before = this._technicianDatesInPlanning
    this._technicianDatesInPlanning = tours

    Promise.all(tours.map(async td => await td.tour.updateRoute())).then(_ => this.firePriorityCountChangeBetweenTechnicianDates(tours_before, tours))
  }

  private firePriorityCountChangeBetweenTechnicianDates(tours_before: PATechnicianDate[], tours_after: PATechnicianDate[]): void {
    let all_tour_timestamps = tours_before.concat(tours_after).map(tour => tour.day.utc_timestamp)
    let min_day_ts = Math.min(...all_tour_timestamps)
    let max_day_ts = Math.max(...all_tour_timestamps)

    let priority_change_starts: { priority: Priority, start_ts: number }[] = []
    for (let ts = min_day_ts; ts <= max_day_ts; ts += 24 * 60 * 60 * 1000) {
      let tour_before = tours_before.find(tour => tour.day.utc_timestamp == ts) || this._technician.getTechnicianDate(ts)
      let tour_after = tours_after.find(tour => tour.day.utc_timestamp == ts) || this._technician.getTechnicianDate(ts)

      if (tour_before != tour_after) {
        let priorities = [...new Set(tour_before.tour.operations.concat(tour_after.tour.operations).map(op => op.ticket.priority))]
        for (let priority of priorities) {
          if (!priority_change_starts.find(change_start => change_start.priority == priority)) {
            let priority_count_before = tour_before.tour.operations.filter(op => op.ticket.priority.id == priority.id).length
            let priority_count_after = tour_after.tour.operations.filter(op => op.ticket.priority.id == priority.id).length
            if (priority_count_before != priority_count_after) {
              priority_change_starts.push({priority: priority, start_ts: ts})
            }
          }
        }
      }
    }
    this.priorityCountsChangedSubject.next(priority_change_starts)
  }

  async getPriorityOperationCountBeforeTimestamp(priority: Priority, timestamp: number): Promise<number> {
    await this._technician.loadPriorityBasedOperationTimes(priority.id)
    let done_priority_operation_count = this._technician.getOperationTimes(priority.id).length

    let current_day_timestamp = PATimeControl.Instance.dateToTimestamp(new Date(Date.now()), true, true)
    let day_timestamps_until_timestamp: number[] = []
    while (current_day_timestamp < timestamp) {
      day_timestamps_until_timestamp.push(current_day_timestamp)
      current_day_timestamp += 24 * 60 * 60 * 1000
    }

    let loaded_tours_until_timestamp_promises = day_timestamps_until_timestamp.map(
      async ts => {
        let tour = this.technicianDatesInPlanning.find(td => td.day.utc_timestamp == ts) || this._technician.getTechnicianDate(ts, true, true)
        await tour.waitUntilDataWasLoaded()
        return tour
      }
    )

    let loaded_tours_until_timestamp = await Promise.all(loaded_tours_until_timestamp_promises)
    let planned_priority_operations_until_timestamp = loaded_tours_until_timestamp.reduce(
      (operations: PAOperation[], tour: PATechnicianDate) => {
        return operations.concat(tour.tour.operations.filter(op => op.ticket.priority.id == priority.id && !op.hasStarted() && op.getPlannedTimestamp() < timestamp))
      }, []
    )

    return done_priority_operation_count + planned_priority_operations_until_timestamp.length
  }

  async getRoutesPriorityOperationCountsOnDay(route: PAOperation[], day_timestamp: number): Promise<{
    operation: PAOperation,
    count: number
  }[]> {
    let route_priorities = [...new Set(route.map(op => op.ticket.priority))]
    let priority_counts_until_day = await Promise.all(route_priorities.map(async prio => {
      return await this.getPriorityOperationCountBeforeTimestamp(prio, day_timestamp)
    }))

    let res: { operation: PAOperation, count: number }[] = []
    let route_priority_counts = new Map<number, number>()
    for (let op of route) {
      let prio_id = op.ticket.priority.id
      if (!route_priority_counts.has(prio_id)) {
        route_priority_counts.set(prio_id, 0)
      }
      let route_priority_count = route_priority_counts.get(prio_id) + 1
      route_priority_counts.set(prio_id, route_priority_count)
      let prio_idx = route_priorities.findIndex(prio => prio_id == prio.id)

      res.push({operation: op, count: priority_counts_until_day[prio_idx] + route_priority_count})

    }

    return res
  }
}