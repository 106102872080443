<div class="row">
  <div class="col-sm-12">
    <h3>Ticket Import Sendungen (csv)
    </h3>

    <div class="card">
      <div class="card-body">
        <p>

        </p>
        <p>
          gültige Felder: <br>
          - ticket_id (z.B: MI-20-044100)<br>
          - carrier	(z.B. UPS, DHL)<br>
          - sendungsnummer (z.B. 1Z6932186861573064 )<br>
          - versandatum	(z.B. 14.08.20)<br>
          - empfangsplz (z.B. 24251)
          <br>
          <br><br>

          Upload im <b>csv</b>-Format
        </p>
      </div>
    </div>
  </div>
</div>

<div class="row">
  <div class="col-12">
    <div class="card">
      <div class="card-body" *ngIf="status == 'new'">

        <h3>Import Datei auswählen</h3>

        <form [formGroup]="form" (ngSubmit)="onSubmit()">

          <input type="file" name="importfile" (change)="onFileChange($event)" />
          <button type="submit">Upload</button>

        </form>
      </div>

      <div class="card-body" *ngIf="status == 'running'">

        <h3>Import läuft, bitte warten!</h3>
      </div>

      <div class="card-body" *ngIf="status == 'done'">

        <h3>Abgeschlossen!</h3>
        <p>Anzahl Fehler: {{errorIds.length}}</p>
        <p>Fehlerhafte ids: {{errorIds | json}}</p>
        <p>log: {{errorLog | json}}</p>
        <p>
          <button class="btn btn-primary" (click)="status = 'new'">nochmal benutzen</button>
        </p>
      </div>

      <div class="card-body" *ngIf="status == 'error'">

        <h3>Fehler!</h3>
        <p>{{errorText}}</p>
        <p>
          <button class="btn btn-primary" (click)="status = 'new'">nochmal benutzen</button>
        </p>
      </div>
    </div>

  </div>
</div>
