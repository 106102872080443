
<hawk-record-navigation class="common_style" *ngIf="user" [breadcrumbLinkList]="[{link: '/a/benutzer', description: 'Nutzer'}]" [root]="'user'" [leafDescription]="user?.full_name || ''">
  <div style="flex: 1;" class="card-body responsive_split">
    <div>
      <section>
        <h2>Kontaktdaten</h2>
        <table class="section_table base">
          <tbody>
          <tr>
            <td>E-Mail</td>
            <td><a [href]="'mailto:' + user.email1">{{user.email1}}</a></td>
          </tr>
          <tr>
            <td>Zusätzliche E-Mail</td>
            <td><a [href]="'mailto:' + user.email2">{{user.email2}}</a></td>
          </tr>
          <tr>
            <td>E-Mail MoreApp</td>
            <td><a [href]="'mailto:' + user.email_moreapp">{{user.email_moreapp}}</a></td>
          </tr>
          <tr>
            <td>Kunde</td>
            <td>
              <a *ngIf="user.customer" [routerLink]="'/kunde/' + user.customer.id">
                <fa-icon [icon]="faIndustry"></fa-icon>
                {{user.customer.name}}
              </a>
              <ng-container *ngIf="!user.customer">
                -
              </ng-container>
            </td>
          </tr>
          <tr>
            <td>Telefon 1</td>
            <td>
              <a *ngIf="user.phone1" [href]="'phone:' + user.phone1">
                <fa-icon [icon]="faPhone"></fa-icon>
                {{user.phone1}}
              </a>
            </td>
          </tr>
          <tr>
            <td>Telefon 2</td>
            <td>
              <a *ngIf="user.phone2" [href]="'phone:' + user.phone2">
                <fa-icon [icon]="faPhone"></fa-icon>
                {{user.phone2}}
              </a>
            </td>
          </tr>
          <tr>
            <td>Telefon für Dispatching</td>
            <td>
              <a *ngIf="user.telephone_dispatching" [href]="'phone:' + user.telephone_dispatching">
                <fa-icon [icon]="faPhone"></fa-icon>
                {{user.telephone_dispatching}}
              </a>
            </td>
          </tr>
          <tr>
            <td>SMS</td>
            <td>
              <div style="width: 100%;" class="flex_row">
                <div style="flex: 1;">
                  {{user.sms}}
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td>Fax</td>
            <td>{{user.fax}}</td>
          </tr>
          <tr>
            <td>Weitere Kontaktinformationen</td>
            <td>{{user.more_contact_info}}</td>
          </tr>
          <tr>
            <td>Partner E-Mail</td>
            <td><a [href]="'mailto:' + user.email_partner1">{{user.email_partner1}}</a></td>
          </tr>
          <tr>
            <td>Zusätzliche Partner E-Mail</td>
            <td><a [href]="'mailto:' + user.email_partner2">{{user.email_partner2}}</a></td>
          </tr>
          </tbody>
        </table>
      </section>
      <section>
        <h2 style="margin-bottom: 0">Adresse <a [href]="'https://maps.google.de?q=' + [user.street, user.zip, user.city].join(',')" target="_blank">Google Maps</a></h2>
        <table class="section_table base">
          <tbody>
          <tr>
            <td>Firma</td>
            <td>{{user.company}}</td>
          </tr>
          <tr>
            <td>Straße</td>
            <td>{{user.street}}</td>
          </tr>
          <tr>
            <td>PLZ und Ort</td>
            <td>{{user.zip}} {{user.city}}</td>
          </tr>
          <tr>
            <td>Land (Staat)</td>
            <td>{{user.country}}</td>
          </tr>
          </tbody>
        </table>
      </section>
      <section>
        <h2>Firmenadresse</h2>
        <table class="section_table base">
          <tbody>
          <tr>
            <td>Firma</td>
            <td>{{user.company_address_company}}</td>
          </tr>
          <tr>
            <td>Name</td>
            <td>{{user.company_address_name}}</td>
          </tr>
          <tr>
            <td>Straße</td>
            <td>{{user.company_address_street}}</td>
          </tr>
          <tr>
            <td>PLZ und Ort</td>
            <td>{{user.company_address_zip}} {{user.company_address_city}}</td>
          </tr>
          <tr>
            <td>Land (Staat)</td>
            <td>{{user.company_address_country}}</td>
          </tr>
          </tbody>
        </table>
      </section>
      <section>
        <h2>Sonstiges</h2>
        <table class="section_table base">
          <tbody>
          <tr *ngIf="user.employer">
            <td>Angestellt bei</td>
            <td>
              <a style="font-size: 1rem;" [routerLink]="'/benutzer/' + user.employer.id">
                <fa-icon [icon]="faUser"></fa-icon>
                {{user.employer.name}}
              </a>
            </td>
          </tr>
          <tr>
            <td>Packstation Karten-Nr</td>
            <td>{{user.card_nr_packstation}}</td>
          </tr>
          <tr>
            <td>Pickpoint-Nr</td>
            <td>{{user.pickpoint_nr}}</td>
          </tr>
          <tr>
            <td>XML-ID</td>
            <td>{{user.xml_id}}</td>
          </tr>
          </tbody>
        </table>
      </section>
    </div>
    <div class="flex_col">
      <section style="flex: 0!important;">
        <h2>Nutzerrollen</h2>
        <div style="flex-wrap: wrap;" class="flex_row">
          <ng-container *ngFor="let role of user.roles">
            <div *ngIf="!role.name.includes('CRM')" class="user_role">{{role.name}}</div>
          </ng-container>
        </div>
      </section>
      <section style="flex: 1;">
        <h2>Karte</h2>
        <div class="flex_col" style="width: 100%; height: 100%;">
          <div *ngIf="user.lat && user.lng" style="flex: 1; border: 1px solid gray;" class="flex_row">
            <mgl-map [style]="'mapbox://styles/mapbox/streets-v9'" [zoom]="[5]" [center]="[user.lng, user.lat]" [scrollZoom]="ctrlIsPressed">
              <mgl-marker [lngLat]="[user.lng, user.lat]">
                <div class="marker" [style.borderColor]="'black'" [style.backgroundColor]="'#af0000'" title="{{user.full_name}}"></div>
              </mgl-marker>
            </mgl-map>
          </div>
        </div>
      </section>
    </div>

    <div *ngIf="user.employees.length || user.dispatcher_projects.length || user.warehouses.length" class="flex_col">
      <section style="flex: 1;">
        <h2>Navigation</h2>
        <mat-tab-group>
          <mat-tab *ngIf="user.warehouses.length" [label]="'Lager (' + user.warehouses.length + ')'">
            <section class="max440y">
              <table class="section_table base">
                <tbody>
                <tr *ngFor="let warehouse of user.warehouses">
                  <td>
                    <a style="font-size: 14px;" [href]="'/warehouse/warehouse/' + warehouse.id  + '/show'" target="_blank">
                      Hawk 1.0
                    </a>
                  </td>
                  <td>
                    <a style="font-size: 14px;" [routerLink]="'/warehouse/warehouse/' + warehouse.id  + '/show'" target="_blank">
                      {{warehouse.label}}
                    </a>
                  </td>
                </tr>
                </tbody>
              </table>
            </section>
          </mat-tab>
          <mat-tab *ngIf="user.dispatcher_projects.length" [label]="'Disp. Projekte (' + user.dispatcher_projects.length + ')'">
            <section>
              <div style="width: 100%;" class="flex_row">
                <mat-slide-toggle style="margin-left: auto; margin-bottom: 4px;" [(ngModel)]="showOnlyActiveProjects">Nur aktive Projekte</mat-slide-toggle>
              </div>
              <div class="max440y">
                <table class="section_table base">
                  <tbody>
                  <ng-container *ngFor="let project of user.dispatcher_projects">
                    <tr *ngIf="!showOnlyActiveProjects || project.active">
                      <td>
                        <a style="font-size: 14px;" [href]="'/project/' + project.id + '/show'" target="_blank">
                          Hawk 1.0
                        </a>
                      </td>
                      <td>
                        <a style="font-size: 14px;" [routerLink]="'/projekt/' + project.id" target="_blank">
                          <fa-icon [icon]="faProductHunt"></fa-icon>
                          {{project.name}}
                        </a>
                      </td>
                    </tr>
                  </ng-container>
                  </tbody>
                </table>
              </div>
            </section>
          </mat-tab>
          <mat-tab *ngIf="user.employees.length" [label]="'Angestellte (' + user.employees.length + ')'">
            <section class="max440y">
              <table class="section_table base">
                <tbody>
                <tr *ngFor="let employee of user.employees">
                  <td>
                    <a style="font-size: 14px;" [routerLink]="'/benutzer/' + employee.id" target="_blank">
                      <fa-icon [icon]="faUser"></fa-icon>
                      {{employee.name}}
                    </a>
                  </td>
                </tr>
                </tbody>
              </table>
            </section>
          </mat-tab>
        </mat-tab-group>
      </section>
    </div>
  </div>
  <div style="flex-wrap: wrap;" class="flex_row footer_row">
    <a [routerLink]="'/benutzer/' + user.id + '/bearbeiten'">
      <button mat-raised-button class="primary_bg_color_button" color="primary">
        <fa-icon [icon]="faEdit"></fa-icon>
        Bearbeiten
      </button>
    </a>
    <a [routerLink]="'/benutzer/' + user.id + '/passwort'">
      <button mat-raised-button class="primary_bg_color_button" color="primary">
      <fa-icon [icon]="faKey"></fa-icon>
      Passwort
      </button>
    </a>
    <a [routerLink]="'/benutzer'">
      <button mat-button class="primary_color_button" color="primary">
          <fa-icon [icon]="faArrowLeft"></fa-icon>
          Zurück
      </button>
    </a>
    <div style="flex: 1;"></div>
    <button mat-raised-button (click)="toggleUserActivation()">
      {{user.active ? 'Deaktivieren' : 'Aktivieren'}}
    </button>
    <div [matTooltip]="userIsDeletable ? '' : 'Nutzer können nur gelöscht werden, wenn keine Abhängigkeiten zu Lagern, Aufträgen, Platzhalter Technikern, Angestellten, (Dispatcher) Projekten, und QM-Jobs vorhanden sind'">
      <button mat-raised-button [disabled]="!userIsDeletable" (click)="deleteUser()">
        <fa-icon [icon]="faTrash"></fa-icon>
        Löschen
      </button>
    </div>
  </div>
</hawk-record-navigation>
