import { Injectable } from '@angular/core'
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router'
import { SessionService } from '../_services'

@Injectable({ providedIn: 'root' })
export class AdminGuard implements CanActivate {

    constructor(
        private router: Router,
        private sessionService: SessionService) { }

    public async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        let is_admin = await this.checkAdminPermission()

        if (is_admin) {
            return true
        }
        this.router.navigate(['/login'], { queryParams: { returnUrl: state.url }})
        return false
    }

    private async checkAdminPermission(): Promise<boolean> {
        return new Promise((resolve, reject) => {
            this.sessionService.rolePermissions().subscribe(
                (data) => {
                    const valid_roles = ["ADMINISTRATOR", "BOSS", "SUPERVISOR_DISPATCHER", "DISPATCHER"]
                    for (let role of data.roles) {
                        if (valid_roles.includes(role)) {
                            resolve(true)
                        }
                    }
                    resolve(false)
                },
                (err) => {
                    console.error(err)
                    resolve(false)
                },
            )
        }
        )
    }
}
