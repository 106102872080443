import { CommonModule, NgClass, NgIf } from "@angular/common";
import { Component } from "@angular/core";
import { RouterModule } from "@angular/router";
import { ICellRendererAngularComp } from "ag-grid-angular";
import { ICellRendererParams } from "ag-grid-enterprise";

interface GeneralLinkCellRendererParams extends ICellRendererParams {
  labelFromData?: (params: boolean) => boolean;
  dataKey?: (params: string) => string;
  iconFunc?: (data: any) => string[] | String[];
  routerLink?: any[] | string;
  label?: string | String | number | undefined;
  icon?: string | String | undefined | string[] | String[];
  onClickFunc?: (data?: any) => void;
}

@Component({
    selector: 'ag-grid-warehouse-link-renderer',
    imports: [RouterModule, NgIf, NgClass, CommonModule],
    template: `<a class='g-link' *ngIf="routerLink !== null" [routerLink]="routerLink" [ngClass]="{'d-flex align-items-center gap-1': label !== null}">
              <i class="{{icon}}" *ngIf="icon"></i><span *ngIf="label !== null">{{label}}</span>
            </a>
            <a class='g-link' *ngIf="routerLink === null" (click)="onClick()" href="javascript:;" [ngClass]="{'d-flex align-items-center gap-1': label !== null}">
              <i class="{{icon}}" *ngIf="icon"></i><span *ngIf="label !== null">{{label}}</span>
            </a>`
})
export class AgGridGenericLinkRenderer implements ICellRendererAngularComp {
  params!: ICellRendererParams;
  public label!: string | String | number | undefined;
  public routerLink!: any[] | string;
  public icon?: string | String | undefined | string[] | String[];
  public iconFunc?: (data: any) => string[] | String[];
  onClickFunc?: (data: any) => void;

  public onClick() {
    console.log()
    if(this.onClickFunc) {
      this.onClickFunc(this.params?.data);
    }
  }

  agInit(params: GeneralLinkCellRendererParams) {
    this.params = params;
    if(params.onClickFunc) {
      this.onClickFunc = params.onClickFunc;
    }
    if(params.icon && (params.icon !== '' || (params.icon || []).length > 0)) {
      this.icon = Array.isArray(params.icon) ? params.icon.join(' ') : params.icon;
    }
    if(params.iconFunc) {
      this.icon = params.iconFunc(params.data);
    }
    if(params.labelFromData) {
      this.label = params.data[`${params.dataKey}`]
    } else {
      this.label = params.label;
    }
    if(params.routerLink && Array.isArray(params.routerLink)) {
      this.routerLink = params.routerLink.map((link) => {
        if(`${link}`.startsWith('@')) {
          const key = `${link}`.replace('@', '');
          return params.data[key];
        }
        return link;
      });
    } else if(params.routerLink && !Array.isArray(params.routerLink)){
      this.routerLink = params.routerLink;
    } else {
      this.routerLink = null;
    }
  }

  refresh(params: ICellRendererParams) {
    this.params = params;
    // As we have updated the params we return true to let AG Grid know we have handled the refresh.
    // So AG Grid will not recreate the cell renderer from scratch.
    return true;
  }
}