import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { CalendarWeekData } from 'src/app/_models/planning-assistant.interface';
import { PAFilter } from '../classes/filter';
import { PAProject } from '../classes/project';
import { PATechnician } from '../classes/technician';
import { PATechnicianDate } from '../classes/technician-date';
import { PAUtil, WeekDay } from '../classes/util';
import { TimeData } from "../classes/tour";
import { faEye, faEyeSlash, faStar } from "@fortawesome/free-solid-svg-icons";
import { PAOperation } from "../classes/operation";
import { PADataControl } from "../singletons/pa-data-control";
import { PATimeControl } from "../singletons/pa-time-control";
import { PATourPlannerControl } from "../singletons/pa-tourplanner-control";
import { PAFilterControl } from "../singletons/pa-filter-control";
import { CommonModule, NgClass, NgFor, NgIf } from '@angular/common';
import { ExpandBarHeaderComponent } from '../reuseable-components/expand-bar-header/expand-bar-header.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TooltipModule } from '@cloudfactorydk/ng2-tooltip-directive';
import { TimelineComponent } from '../tourplanner-menu/tour-timeline/timeline/timeline.component';
import { FaIconComponent, FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@Component({
  selector: 'hawk-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss', './../styles/common_styles.scss'],
  standalone: true,
  imports: [NgIf, ExpandBarHeaderComponent, NgClass, NgFor, ReactiveFormsModule, FormsModule, TooltipModule, FaIconComponent, TimelineComponent, CommonModule, FontAwesomeModule]
})
export class CalendarComponent implements OnInit, OnChanges {

  static defaultInitializedWeekAmount = 3

  readonly Operation = PAOperation

  isExpanded: boolean = false

  @Input() technicians: PATechnician[]
  @Input() preferredTechnicians: PATechnician[]
  @Input() operationTimeFilter: string
  @Input() filteredWeekdays: WeekDay[] = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday']
  @Input() shownCalendarWeeksData: CalendarWeekData[]
  @Input() selectedCalendarWeekData: CalendarWeekData
  @Input() selectedCalendarDay: string
  @Output() addPreferredTechnicianEvent = new EventEmitter<PATechnician>()
  @Output() removePreferredTechnicianEvent = new EventEmitter<PATechnician>()

  public Util = PAUtil
  public Project = PAProject
  public CalendarComponent = CalendarComponent
  public Technician = PATechnician

  public showProjectModal = false
  public modalProject?: PAProject
  public modalProjectColor?: string

  public showPreferredTechnicians: boolean = true
  public showFilteredTechnicians: boolean = true

  public showTechnicianIndex = 0
  public maxShownTechniciansAmount = 5
  public shownTechnicians: PATechnician[] = []
  public technicianSearchName: string = ''

  constructor(
  ) {
  }

  ngOnChanges(changes: SimpleChanges): void {

    let update_shown_technicians = false

    let technician_changes = changes['technicians']
    if (technician_changes && !PAUtil.equalSets(new Set(technician_changes.previousValue), new Set(technician_changes.currentValue))) {
      update_shown_technicians = true
    }

    let preferred_technician_changes = changes['preferredTechnicians']
    if (preferred_technician_changes && !PAUtil.equalSets(new Set(preferred_technician_changes.previousValue), new Set(preferred_technician_changes.currentValue))) {
      update_shown_technicians = true
    }

    let shown_calendar_weeks_data_changes = changes['shownCalendarWeeksData']
    let selected_calendar_week_data_changes = changes['selectedCalendarWeekData']
    let selected_calendar_day_changes = changes['selectedCalendarDay']
    if (shown_calendar_weeks_data_changes || selected_calendar_week_data_changes || selected_calendar_day_changes) {
      update_shown_technicians = true
    }

    if (update_shown_technicians) {
      this.updateShownTechnicians()
    }

  }

  async ngOnInit(): Promise<void> {
    PATechnician.setCalendarComponent(this)
    PATimeControl.Instance.initCalendar()
  }

  public updateShownTechnicians(): void {
    this.shownTechnicians = this.getShownTechnicians()
  }

  decrementTimeRange(): void {
    if (!PATimeControl.Instance.selectedCalendarWeekData && !PATimeControl.Instance.selectedCalendarDay?.length) {
      this.decrementShownCalendarWeeks()
    }

    if (PATimeControl.Instance.selectedCalendarWeekData && !PATimeControl.Instance.selectedCalendarDay?.length) {
      this.decrementSelectedCalendarWeek()
    }

    if (PATimeControl.Instance.selectedCalendarWeekData && PATimeControl.Instance.selectedCalendarDay?.length) {
      this.decrementSelectedCalendarDay()
    }
  }

  incrementTimeRange(): void {
    if (!PATimeControl.Instance.selectedCalendarWeekData && !PATimeControl.Instance.selectedCalendarDay?.length) {
      this.incrementShownCalendarWeeks()
    }

    if (PATimeControl.Instance.selectedCalendarWeekData && !PATimeControl.Instance.selectedCalendarDay?.length) {
      this.incrementSelectedCalendarWeek()
    }

    if (PATimeControl.Instance.selectedCalendarWeekData && PATimeControl.Instance.selectedCalendarDay?.length) {
      this.incrementSelectedCalendarDay()
    }
  }

  public increaseShowTechnicianIndex() {
    if (!this.technicianShowIndexIsMaximal()) {
      this.showTechnicianIndex += 1
      this.shownTechnicians = this.getShownTechnicians()
      this.updateTechniciansCurrentCalendarData(this.shownTechnicians.concat(this.preferredTechnicians))
    }
  }

  public decreaseShowTechnicianIndex() {
    if (!this.technicianShowIndexIsMinimal()) {
      this.showTechnicianIndex -= 1
      this.shownTechnicians = this.getShownTechnicians()
      this.updateTechniciansCurrentCalendarData(this.shownTechnicians.concat(this.preferredTechnicians))
    }
  }

  public technicianShowIndexIsMaximal(): boolean {
    return this.technicians.length / this.maxShownTechniciansAmount - 1 <= this.showTechnicianIndex;
  }

  public technicianShowIndexIsMinimal(): boolean {
    return this.showTechnicianIndex <= 0
  }

  public getTimeFilteredCalendarDataOfTechnicianDate(technician_date: PATechnicianDate) {
    return this.getCalendarDataOfTechnicianDate(technician_date, this.operationTimeFilter)
  }

  public getCalendarDataOfTechnicianDate(technician_date: PATechnicianDate, operation_time_filter: string): TimeData {
    return technician_date.tour.route.time_specific_data[operation_time_filter]
  }

  incrementShownCalendarWeeks(): void {
    PATimeControl.Instance.incrementShownCalendarWeeks()
  }

  decrementShownCalendarWeeks(): void {
    PATimeControl.Instance.decrementShownCalendarWeeks()
  }

  incrementSelectedCalendarWeek(): void {
    PATimeControl.Instance.incrementSelectedCalendarWeek()
  }

  decrementSelectedCalendarWeek(): void {
    PATimeControl.Instance.decrementSelectedCalendarWeek()
  }

  incrementSelectedCalendarDay(): void {
    let weekday_idx = this.filteredWeekdays.indexOf(PATimeControl.Instance.selectedCalendarDay) + 1
    if (weekday_idx >= this.filteredWeekdays.length) {
      this.incrementSelectedCalendarWeek()
      PATimeControl.Instance.selectedCalendarDay = this.filteredWeekdays[0]
    } else {
      PATimeControl.Instance.selectedCalendarDay = this.filteredWeekdays[weekday_idx]
    }
  }

  decrementSelectedCalendarDay(): void {
    console.log(Date.now())
    let weekday_idx = this.filteredWeekdays.indexOf(PATimeControl.Instance.selectedCalendarDay) - 1
    if (weekday_idx < 0) {
      this.decrementSelectedCalendarWeek()
      PATimeControl.Instance.selectedCalendarDay = this.filteredWeekdays[this.filteredWeekdays.length - 1]
    } else {
      PATimeControl.Instance.selectedCalendarDay = this.filteredWeekdays[weekday_idx]
    }
    console.log(Date.now())
  }

  public selectCalendarWeekAndDay(calendar_week_data: CalendarWeekData, day: WeekDay) {
    this.selectCalendarWeek(calendar_week_data)
    this.selectCalendarDay(day)
  }

  public selectCalendarDay(day: WeekDay) {
    if (PATimeControl.Instance.weekDays.indexOf(day) >= 0) {
      PATimeControl.Instance.selectedCalendarDay = day
      this.updateTechniciansCurrentCalendarData(this.shownTechnicians.concat(this.preferredTechnicians))
    }
  }

  public deselectCalendarDay() {
    PATimeControl.Instance.selectedCalendarDay = null
    this.updateTechniciansCurrentCalendarData(this.shownTechnicians.concat(this.preferredTechnicians))
  }

  public selectCalendarWeek(calendar_week_data: CalendarWeekData) {
    PATimeControl.Instance.selectedCalendarWeekData = calendar_week_data
    this.updateTechniciansCurrentCalendarData(this.shownTechnicians.concat(this.preferredTechnicians))
  }

  public deselectCalendarWeek() {
    PATimeControl.Instance.selectedCalendarWeekData = null
    this.deselectCalendarDay()
    this.updateTechniciansCurrentCalendarData(this.shownTechnicians.concat(this.preferredTechnicians))
  }

  public getShownTechnicians(): PATechnician[] {
    let technicians = PAFilter.filterTechniciansForName(this.technicians, this.technicianSearchName).filter(technician => !this.preferredTechnicians.includes(technician))

    let max_show_technician_index = Math.max(0, Math.ceil(technicians.length / this.maxShownTechniciansAmount - 1))
    this.showTechnicianIndex = Math.min(this.showTechnicianIndex, max_show_technician_index)
    let start_idx = this.showTechnicianIndex * this.maxShownTechniciansAmount
    let end_idx = Math.min(technicians.length, start_idx + this.maxShownTechniciansAmount)
    let shown_technicians = technicians.slice(start_idx, end_idx)

    let all_technicians = this.preferredTechnicians.concat(shown_technicians)
    if (shown_technicians.length) {
      this.updateTechniciansCurrentCalendarData(all_technicians)
    }

    return shown_technicians
  }

  public updateTechniciansCurrentCalendarData(technicians: PATechnician[]): void {
    if (PATimeControl.Instance.shownCalendarWeeksData && !PATimeControl.Instance.selectedCalendarWeekData) {
      PATechnician.initTechnicianDatesForCalendarWeeksData(technicians, PATimeControl.Instance.shownCalendarWeeksData, PADataControl.Instance)
    } else if (PATimeControl.Instance.shownCalendarWeeksData && PATimeControl.Instance.selectedCalendarWeekData && !PATimeControl.Instance.selectedCalendarDay) {
      PATechnician.initTechnicianDatesForCalendarWeek(technicians, PATimeControl.Instance.selectedCalendarWeekData, PADataControl.Instance)
    } else if (PATimeControl.Instance.shownCalendarWeeksData && PATimeControl.Instance.selectedCalendarWeekData && PATimeControl.Instance.selectedCalendarDay) {
      const calendar_week_day = PATimeControl.Instance.selectedCalendarWeekData.weekdays[PATimeControl.Instance.selectedCalendarDay]
      PATechnician.initTechnicianDateForCalendarDay(technicians, calendar_week_day.timestamp)
    }
  }

  public changeModalProjectColor(event: Event): void {
    const input = event.target as HTMLInputElement
    this.modalProjectColor = input.value
  }

  public closeProjectModal(event: MouseEvent) {
    let target_is_modal_backgound = event.target == document.getElementById('project_modal')
    let target_is_close_button = event.target == document.getElementById('project_modal_close_button')
    let target_is_update_project_button = event.target == document.getElementById('update_project_button')
    if (target_is_modal_backgound || target_is_close_button || target_is_update_project_button) {
      this.showProjectModal = false
    }
  }

  public togglePreferredTechnician(technician: PATechnician) {
    if (this.preferredTechnicians.indexOf(technician) >= 0) {
      this.removePreferredTechnician(technician)
    } else {
      this.addPreferredTechnician(technician)
    }
  }

  addPreferredTechnician(technician: PATechnician): void {
    this.addPreferredTechnicianEvent.emit(technician)
  }

  removePreferredTechnician(technician: PATechnician): void {
    this.removePreferredTechnicianEvent.emit(technician)
  }

  protected readonly faStar = faStar;
  protected readonly faEye = faEye;
  protected readonly faEyeSlash = faEyeSlash;
  protected readonly PATimeControl = PATimeControl;
  protected readonly PATourPlannerControl = PATourPlannerControl;
  protected readonly PAFilterControl = PAFilterControl;
}