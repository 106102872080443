import { Component, inject, OnInit } from '@angular/core';
import { EditEventType, StorageBin, Technician } from '../_models';
import { TechnicianService } from '../_services/technician.service';
import { GridReadyEvent, GetRowIdParams } from 'ag-grid-community';
import { AgGridGenericLinkRenderer } from '../_components/_shared/ag-grid-renderer/ag-grid-generic-link.component';
import { AgGridStorageBinActionsRenderer } from '../_components/_shared/ag-grid-renderer/ag-grid-storage-bins-actions.component';
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { FaIconComponent, FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { ActivatedRoute, Router, RouterModule } from '@angular/router'
import { GridApi, ColDef, RowClassRules, StartEditingCellParams } from 'ag-grid-enterprise'
import { WarehouseService } from '../_services'

import { AG_GRID_LOCALE_DE } from 'src/app/_helpers/aggrid.locale.de'
import { EditInputComponent } from '../_components/_shared/edit-input/edit-input.component';
import { AgGridAngular } from 'ag-grid-angular';
import { NgxMapboxGLModule } from 'ngx-mapbox-gl';
import { AgGridThemeService } from '../_services/ag-grid-theme.service';

@Component({
    selector: 'hawk-warehouse-show',
    templateUrl: './warehouse-show.component.html',
    styleUrls: ['./warehouse-show.component.scss'],
    imports: [RouterModule, CommonModule, FormsModule, FontAwesomeModule, EditInputComponent, AgGridAngular, NgxMapboxGLModule],
})
export class WarehouseShowComponent implements OnInit {
  private gridApi!: GridApi;
  public hawkTheme = inject(AgGridThemeService).getTheme();
  public locale = AG_GRID_LOCALE_DE
  public warehouseId?: String
  public warehouse: any;
  public markerData?: { lat: number, lng: number, color: string, text: string }[]
  public position?: [number, number]
  public technicians: Technician[] = []
  public storageBins?: StorageBin[]
  public addressFirstName?: String;
  public addressLastName?: String;
  public addressZip?: string | String;
  public addressCity?: string | String;
  public addressCountry?: string | String;
  public addressState?: string | String;
  public editTelephone: boolean = false;
  public editTechnician: boolean = false;
  public editMainWarehouse: boolean = false;
  public editFreeFill: boolean = false;
  public editUniStockId: boolean = false;
  public editLabel: boolean = false;
  public editLimitTemplate1: boolean = false;
  public editLimitTemplate2: boolean = false;
  public editLimitTemplate3: boolean = false;
  public editCompany: boolean = false;
  public editName: boolean = false;
  public editStreet: boolean = false;
  public editZipcode: boolean = false;
  public editCountry: boolean = false;
  public isAddingStorage: boolean = false;
  public isRowEditing: boolean = false;
  public canDelete: boolean = false;
  public editingRowIndex?: number;
  public rowSelection: "single" | "multiple" = "multiple"
  public editType: "fullRow" = "fullRow"
  public rowClassRules: RowClassRules = {
    // if tech on site, color row
    'tech-on-site': (params) => {
      if (params?.data === undefined) return "...";
      return params.data.tech_on_site
    },
  }

  public defaultColDef: ColDef = {
    filter: true,
    flex: 1,
    editable: true,
    enableCellChangeFlash: true
  }

  public getRowId(params: GetRowIdParams) {
    return params.data.id;
  }

  // Column Definitions: Defines the columns to be displayed.
  public colDefs: ColDef[] = [
    {
      field: "name",
      headerName: '',
      valueSetter: (params) => {
        if(params.newValue && params.newValue !== '' && params.newValue !== undefined) {
          params.data.name = params.newValue;
          return true;
        }
        return false;
      },
      cellRenderer: AgGridGenericLinkRenderer,
      cellRendererParams: {
        routerLink: ['/', 'lager', '@warehouse_id', 'lagerort', '@id', 'alle'],
        labelFromData: true,
        dataKey: 'name',
        // icon: ['fa', 'fa-cubes']
      }
    },
    {
      field: "articles_count",
      headerName: this.locale.warehouseArticle,
      editable: false,
      cellRenderer: AgGridGenericLinkRenderer,
      cellRendererParams: {
        routerLink: ['/', 'lager', '@warehouse_id', 'lagerort', '@id', 'articles'],
        labelFromData: true,
        dataKey: 'articles_count',
        icon: ['fa', 'fa-cube']
      },
      minWidth: 80,
      width: 80,
      maxWidth: 100
    },
    {
      field: "bad_parts_count",
      headerName: this.locale.warehouseBadParts,
      editable: false,
      cellRenderer: AgGridGenericLinkRenderer,
      cellRendererParams: {
        routerLink: ['/', 'lager', '@warehouse_id', 'lagerort', '@id', 'bad-parts'],
        labelFromData: true,
        dataKey: 'bad_parts_count',
        icon: ['fa', 'fa-bug']
      },
      minWidth: 105,
      width: 105,
      maxWidth: 120
    },
    {
      field: "is_full",
      headerName: 'Full',
      editable: false,
      cellRenderer: AgGridGenericLinkRenderer,
      cellRendererParams: {
        onClickFunc: (data?: any) => {
          return this.updateBinFull(data);
        },
        label: null,
        dataKey: 'is_full',
        iconFunc: (data: any) => {
          if(data.is_full) {
            return ['fa', 'fa-battery-full'];
          } else if (data.articles_count <= 0) {
            return ['fa', 'fa-battery-empty']
          } else {
            return ['fa', 'fa-battery-quarter']
          }
        }
      },
      minWidth: 80,
      width: 80,
      maxWidth: 100
    },
    {
      field: 'actions',
      headerName: '',
      editable: false,
      sortable: false,
      filter: false,
      cellRenderer: AgGridStorageBinActionsRenderer,
      cellRendererParams: {
        onEditFunc: (params?: StartEditingCellParams) => {
          this.gridApi.startEditingCell({ rowIndex: params.rowIndex, colKey: 'name' });
        },
        onDeleteFunc: (data?: any) => {
          return this.onDeleteStorageBin(data);
        }
      }
    }
  ]
  constructor(
    private warehouseService: WarehouseService,
    private route: ActivatedRoute,
    private router: Router,
    private technicianService: TechnicianService,
  ) { }

  ngOnInit(): void {
    this.warehouseId = this.route.snapshot.paramMap.get('id') || "0"
    this.markerData = []
    this.addressFirstName = ''
    this.addressLastName = ''
    this.loadTechnicians()
    this.loadWareHouse()
  }

  onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api!;
    params.api!.setGridOption("domLayout", "autoHeight");
    this.warehouseService.loadStorageBins(this.warehouseId).subscribe(
      {
        next: (storageBins:StorageBin[]) => {
          this.storageBins = storageBins;
          this.canDelete = storageBins.length <= 0;
        },
        error: (error) => {
          console.log('error', error);
        }
      }
    )
  }

  private updateBinFull(data: any) {
    this.warehouseService.updateStorageBin(
      this.warehouseId,
      data.id,
      {
        binIsFull: data.is_full ? null : true
      }
    ).subscribe(
      {
        next: (strogageBin: StorageBin) => {
          const rowNode = this.gridApi.getRowNode(`${data.id}`);
          if(rowNode) {
            rowNode.setData({
              ...data,
              is_full: strogageBin.is_full
            });
          }
        },
        error: (error) => {
          console.log('error', error);
        }
      }
    )
  }

  private loadTechnicians() {
    this.technicianService.getAll().subscribe({
      next: (data) => {
        this.technicians = data
      },
      error: (err) => {
        console.error(err)
      }
    })
  }

  private loadWareHouse() {
    this.warehouseService.loadWarehouse(this.warehouseId).subscribe(
      {
        next: (data) => {
          this.updateData(data);
        },
        error: (error) => {
          console.log('error', error);
        }
      }
    )
  }

  public addStorage() {
    this.isAddingStorage = true;
    this.gridApi.applyTransactionAsync({add: [{
      articles_count: 0,
      bad_parts_count: 0,
      is_full: false,
      warehouse_id: this.warehouseId,
      new_record: true
    }]}, (event) => {
      this.editingRowIndex = event.add[0].rowIndex;
      this.isRowEditing = true;
      this.gridApi.startEditingCell({
        rowIndex: this.editingRowIndex,
        colKey: 'name'
      });
      // this.isAddingStorage = false;
    })
  }

  private stopAdding() {
    this.isAddingStorage = false;
  }
  public onRowValueChanged(event: any) {
    console.log('on change row', event);
    let service = null;
    const warehouseId = this.warehouseId;
    const data = event.data;
    const isNewRecord = data.new_record ? true : false;
    this.gridApi.showLoadingOverlay();
    if (data.new_record) {
      service = this.warehouseService.addNewStorageBin(this.warehouseId, { label: data.name });
    } else if(data.id && parseInt(data.id) > 0) {
      service = this.warehouseService.updateStorageBin(this.warehouseId, data.id, { label: data.name });
    }
    if (!service) {
      return;
    }
    service.subscribe(
      {
        next: (data: any) => {
          if(isNewRecord) {
            event.node.setData({
              ...data
            })
          }
          this.gridApi.hideOverlay();
          this.stopAdding();
        },
        error: (error: any) => {
          if(isNewRecord) {
            this.gridApi.applyTransactionAsync({remove: [{
              articles_count: 0,
              bad_parts_count: 0,
              is_full: false,
              warehouse_id: warehouseId,
              new_record: true
            }]});
            this.stopAdding();
          } else {
            if(error && error.data) {
              event.node.setData({
                ...error.data
              });
            }
          }
          this.gridApi.hideOverlay();
        }
      }
    )
  }

  public onRowEditingStarted(event) {
    console.log('on editing rowEditingStarted', event);
  }

  public onDeleteStorageBin(data: any) {
    if(data.new_record) return;
    if(data.bad_parts_count > 0 || data.articles_count > 0) return;
    if(confirm('Are you sure you want to delete item?')) {
      this.gridApi.showLoadingOverlay();
      this.warehouseService.deleteStorageBin(this.warehouseId, data.id).subscribe(
        {
          next: () => {
            this.gridApi.applyTransactionAsync({remove: [data.id]});
            this.gridApi.hideOverlay();
          },
          error: (error: any) => {
            this.gridApi.hideOverlay();
            alert(`${error.message}`);
          }
        }
      )
    }
  }

  public onRowEditingStopped(event: any) {
    if(event.data.new_record && (!event.data.name || event.data.name === '' || event.data.name === undefined)) {
      this.gridApi.applyTransactionAsync({remove: [{
        articles_count: 0,
        bad_parts_count: 0,
        is_full: false,
        warehouse_id: this.warehouseId,
        new_record: true
      }]});
      this.isAddingStorage = false;
    }
  }

  public saveTelephone(event: EditEventType) {
    this.metaUpdate('telephone', event);
    this.editTelephone = false;
  }

  public saveTechnician(event: EditEventType) {
    this.metaUpdate('owner_id', event);
    this.editTechnician = false;
  }

  public saveMainWarehouse(event: EditEventType) {
    this.metaUpdate('main_warehouse', event === true ? true : false);
    this.editMainWarehouse = false;
  }

  public saveFreeFill(event: EditEventType) {
    this.metaUpdate('freefill', event === true ? true : false);
    this.editFreeFill = false;
  }

  public saveUniStockId(event: EditEventType) {
    this.metaUpdate('uni_stock_id', event);
    this.editUniStockId = false;
  }

  public saveLabel(event: EditEventType) {
    this.metaUpdate('label', event);
    this.editLabel = false;
  }

  public saveLimitTemplate1(event: EditEventType) {
    this.metaUpdate('limit_template1', event);
    this.editLimitTemplate1 = false;
  }

  public saveLimitTemplate2(event: EditEventType) {
    this.metaUpdate('limit_template2', event);
    this.editLimitTemplate2 = false;
  }

  public saveLimitTemplate3(event: EditEventType) {
    this.metaUpdate('limit_template3', event);
    this.editLimitTemplate3 = false;
  }

  public saveCompany(event: EditEventType) {
    this.metaUpdate('address_company', event);
    this.editCompany = false;
  }

  public saveStreet(event: EditEventType) {
    this.metaUpdate('address_street', event);
    this.editStreet = false;
  }

  public saveName() {
    const name = { address_firstname: this.addressFirstName || '', address_lastname: this.addressLastName || '' };
    this.metaUpdate(null, null, name);
    this.editName = false;
  }

  public saveZipcode() {
    this.metaUpdate(null, null, { address_zip: this.addressZip, address_city: this.addressCity });
    this.editZipcode = false;
  }

  public saveCountry() {
    this.metaUpdate(null, null, { address_country: this.addressCountry, address_state: this.addressState });
    this.editCountry = false;
  }

  public toggleLabel() {
    this.editLabel = !this.editLabel;
  }

  public onDelete() {
    if(this.storageBins.length > 0) return;

    if(confirm('Möchten Sie das Lager wirklich deaktivieren?')) {
      this.warehouseService.deactivateWarehouse(this.warehouseId).subscribe(
        {
          next: () => {
            this.router.navigate(['lager', 'uebersicht']);
          },
          error: (error) => {
            alert(error?.message || 'Something wrong went');
          }
        }
      )
    }
    return false;
  }

  private metaUpdate(key:string|null = null, value:any = null, data = {}) {
    const changeData = key === null ? data : {}
    if(key !== null)
      changeData[key] = value

    if (!this.warehouseId || this.warehouseId !== "0") {
      return;
    }

    this.warehouseService.updateWareHouse(this.warehouseId, changeData).subscribe(
      {
        next: (data) => {
          this.updateData(data)
        },
        error: (error) => {
          console.log('error', error);
        }
      }
    )
  }

  private updateData(data: any) {
    this.warehouse = data;
    this.addressFirstName = data.address_firstname;
    this.addressLastName = data.address_lastname;
    this.addressCity = data.address_city;
    this.addressZip = data.address_zip;
    this.addressCountry = data.address_country;
    this.addressState = data.address_state;
    if (this.markerData) {
      this.markerData.push({
        lat: data.address_latitude,
        lng: data.address_longitude,
        color: '#af0000',
        text: 'Store'
      });
    }
    this.position = [data.address_longitude, data.address_latitude]
  }

}