import { NgIf } from "@angular/common";
import { Component } from "@angular/core";
import { ICellRendererAngularComp } from "ag-grid-angular";
import { ICellRendererParams } from "ag-grid-enterprise";
import { CommonModule } from "@angular/common";

interface GeneralLinkCellRendererParams extends ICellRendererParams {
  onDeleteFunc: (data: any) => void;
  onEditFunc: (data: any) => void;
}

@Component({
    selector: 'ag-grid-storage-bin-actions-renderer',
    imports: [NgIf, CommonModule],
    template: `<div class='d-flex align-items-center gap-1'>
    <span class="btn g-link" (click)="onEdit()"><i class="fas fa-pencil"></i></span>
    <a class='g-link' target="_blank" href="/warehouse/inventory/{{params.data.id}}/create">
      <i class="fa fa-clipboard"></i>
    </a>
    <button class="btn g-link" *ngIf="canDelete" (click)="onDelete()"><i class="fas fa-trash-alt"></i></button>
  </div>`
})
export class AgGridStorageBinActionsRenderer implements ICellRendererAngularComp {
  params!: ICellRendererParams;
  public canDelete?: boolean;
  onDeleteFunc?: (data: any) => void;
  onEditFunc?: (data: any) => void;

  public onEdit() {
    if(this.onEditFunc) this.onEditFunc(this.params);
  }

  public onDelete() {
    if(this.onDeleteFunc) {
      this.onDeleteFunc(this.params?.data);
    }
  }

  agInit(params: GeneralLinkCellRendererParams) {
    this.params = params;
    this.canDelete = !params.data.new_record && params.data.bad_parts_count <= 0 && params.data.articles_count <= 0;
    if(params.onDeleteFunc) {
      this.onDeleteFunc = params.onDeleteFunc;
    }
    if(params.onEditFunc) {
      this.onEditFunc = params.onEditFunc;
    }
  }

  refresh(params: ICellRendererParams) {
    this.params = params;
    // As we have updated the params we return true to let AG Grid know we have handled the refresh.
    // So AG Grid will not recreate the cell renderer from scratch.
    return true;
  }
}