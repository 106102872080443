// Country names object using 3-letter country codes to reference country name
// ISO 3166 Alpha-3 Format: [3 letter Country Code]: [Country Name]
// Sorted alphabetical by country name (special characters on bottom)
export const countryListAlpha3 = {
  "AFG": "Afghanistan",
  "ALB": "Albania",
  "DZA": "Algeria",
  "ASM": "American Samoa",
  "AND": "Andorra",
  "AGO": "Angola",
  "AIA": "Anguilla",
  "ATA": "Antarctica",
  "ATG": "Antigua and Barbuda",
  "ARG": "Argentina",
  "ARM": "Armenia",
  "ABW": "Aruba",
  "AUS": "Australia",
  "AUT": "Austria",
  "AZE": "Azerbaijan",
  "BHS": "Bahamas (the)",
  "BHR": "Bahrain",
  "BGD": "Bangladesh",
  "BRB": "Barbados",
  "BLR": "Belarus",
  "BEL": "Belgium",
  "BLZ": "Belize",
  "BEN": "Benin",
  "BMU": "Bermuda",
  "BTN": "Bhutan",
  "BOL": "Bolivia (Plurinational State of)",
  "BES": "Bonaire, Sint Eustatius and Saba",
  "BIH": "Bosnia and Herzegovina",
  "BWA": "Botswana",
  "BVT": "Bouvet Island",
  "BRA": "Brazil",
  "IOT": "British Indian Ocean Territory (the)",
  "BRN": "Brunei Darussalam",
  "BGR": "Bulgaria",
  "BFA": "Burkina Faso",
  "BDI": "Burundi",
  "CPV": "Cabo Verde",
  "KHM": "Cambodia",
  "CMR": "Cameroon",
  "CAN": "Canada",
  "CYM": "Cayman Islands (the)",
  "CAF": "Central African Republic (the)",
  "TCD": "Chad",
  "CHL": "Chile",
  "CHN": "China",
  "CXR": "Christmas Island",
  "CCK": "Cocos (Keeling) Islands (the)",
  "COL": "Colombia",
  "COM": "Comoros (the)",
  "COD": "Congo (the Democratic Republic of the)",
  "COG": "Congo (the)",
  "COK": "Cook Islands (the)",
  "CRI": "Costa Rica",
  "HRV": "Croatia",
  "CUB": "Cuba",
  "CUW": "Curaçao",
  "CYP": "Cyprus",
  "CZE": "Czechia",
  "CIV": "Côte d'Ivoire",
  "DNK": "Denmark",
  "DJI": "Djibouti",
  "DMA": "Dominica",
  "DOM": "Dominican Republic (the)",
  "ECU": "Ecuador",
  "EGY": "Egypt",
  "SLV": "El Salvador",
  "GNQ": "Equatorial Guinea",
  "ERI": "Eritrea",
  "EST": "Estonia",
  "SWZ": "Eswatini",
  "ETH": "Ethiopia",
  "FLK": "Falkland Islands (the) [Malvinas]",
  "FRO": "Faroe Islands (the)",
  "FJI": "Fiji",
  "FIN": "Finland",
  "FRA": "France",
  "GUF": "French Guiana",
  "PYF": "French Polynesia",
  "ATF": "French Southern Territories (the)",
  "GAB": "Gabon",
  "GMB": "Gambia (the)",
  "GEO": "Georgia",
  "DEU": "Germany",
  "GHA": "Ghana",
  "GIB": "Gibraltar",
  "GRC": "Greece",
  "GRL": "Greenland",
  "GRD": "Grenada",
  "GLP": "Guadeloupe",
  "GUM": "Guam",
  "GTM": "Guatemala",
  "GGY": "Guernsey",
  "GIN": "Guinea",
  "GNB": "Guinea-Bissau",
  "GUY": "Guyana",
  "HTI": "Haiti",
  "HMD": "Heard Island and McDonald Islands",
  "VAT": "Holy See (the)",
  "HND": "Honduras",
  "HKG": "Hong Kong",
  "HUN": "Hungary",
  "ISL": "Iceland",
  "IND": "India",
  "IDN": "Indonesia",
  "IRN": "Iran (Islamic Republic of)",
  "IRQ": "Iraq",
  "IRL": "Ireland",
  "IMN": "Isle of Man",
  "ISR": "Israel",
  "ITA": "Italy",
  "JAM": "Jamaica",
  "JPN": "Japan",
  "JEY": "Jersey",
  "JOR": "Jordan",
  "KAZ": "Kazakhstan",
  "KEN": "Kenya",
  "KIR": "Kiribati",
  "PRK": "Korea (the Democratic People's Republic of)",
  "KOR": "Korea (the Republic of)",
  "KWT": "Kuwait",
  "KGZ": "Kyrgyzstan",
  "LAO": "Lao People's Democratic Republic (the)",
  "LVA": "Latvia",
  "LBN": "Lebanon",
  "LSO": "Lesotho",
  "LBR": "Liberia",
  "LBY": "Libya",
  "LIE": "Liechtenstein",
  "LTU": "Lithuania",
  "LUX": "Luxembourg",
  "MAC": "Macao",
  "MDG": "Madagascar",
  "MWI": "Malawi",
  "MYS": "Malaysia",
  "MDV": "Maldives",
  "MLI": "Mali",
  "MLT": "Malta",
  "MHL": "Marshall Islands (the)",
  "MTQ": "Martinique",
  "MRT": "Mauritania",
  "MUS": "Mauritius",
  "MYT": "Mayotte",
  "MEX": "Mexico",
  "FSM": "Micronesia (Federated States of)",
  "MDA": "Moldova (the Republic of)",
  "MCO": "Monaco",
  "MNG": "Mongolia",
  "MNE": "Montenegro",
  "MSR": "Montserrat",
  "MAR": "Morocco",
  "MOZ": "Mozambique",
  "MMR": "Myanmar",
  "NAM": "Namibia",
  "NRU": "Nauru",
  "NPL": "Nepal",
  "NLD": "Netherlands (the)",
  "NCL": "New Caledonia",
  "NZL": "New Zealand",
  "NIC": "Nicaragua",
  "NER": "Niger (the)",
  "NGA": "Nigeria",
  "NIU": "Niue",
  "NFK": "Norfolk Island",
  "MNP": "Northern Mariana Islands (the)",
  "NOR": "Norway",
  "OMN": "Oman",
  "PAK": "Pakistan",
  "PLW": "Palau",
  "PSE": "Palestine, State of",
  "PAN": "Panama",
  "PNG": "Papua New Guinea",
  "PRY": "Paraguay",
  "PER": "Peru",
  "PHL": "Philippines (the)",
  "PCN": "Pitcairn",
  "POL": "Poland",
  "PRT": "Portugal",
  "PRI": "Puerto Rico",
  "QAT": "Qatar",
  "MKD": "Republic of North Macedonia",
  "ROU": "Romania",
  "RUS": "Russian Federation (the)",
  "RWA": "Rwanda",
  "REU": "Réunion",
  "BLM": "Saint Barthélemy",
  "SHN": "Saint Helena, Ascension and Tristan da Cunha",
  "KNA": "Saint Kitts and Nevis",
  "LCA": "Saint Lucia",
  "MAF": "Saint Martin (French part)",
  "SPM": "Saint Pierre and Miquelon",
  "VCT": "Saint Vincent and the Grenadines",
  "WSM": "Samoa",
  "SMR": "San Marino",
  "STP": "Sao Tome and Principe",
  "SAU": "Saudi Arabia",
  "SEN": "Senegal",
  "SRB": "Serbia",
  "SYC": "Seychelles",
  "SLE": "Sierra Leone",
  "SGP": "Singapore",
  "SXM": "Sint Maarten (Dutch part)",
  "SVK": "Slovakia",
  "SVN": "Slovenia",
  "SLB": "Solomon Islands",
  "SOM": "Somalia",
  "ZAF": "South Africa",
  "SGS": "South Georgia and the South Sandwich Islands",
  "SSD": "South Sudan",
  "ESP": "Spain",
  "LKA": "Sri Lanka",
  "SDN": "Sudan (the)",
  "SUR": "Suriname",
  "SJM": "Svalbard and Jan Mayen",
  "SWE": "Sweden",
  "CHE": "Switzerland",
  "SYR": "Syrian Arab Republic",
  "TWN": "Taiwan",
  "TJK": "Tajikistan",
  "TZA": "Tanzania, United Republic of",
  "THA": "Thailand",
  "TLS": "Timor-Leste",
  "TGO": "Togo",
  "TKL": "Tokelau",
  "TON": "Tonga",
  "TTO": "Trinidad and Tobago",
  "TUN": "Tunisia",
  "TUR": "Turkey",
  "TKM": "Turkmenistan",
  "TCA": "Turks and Caicos Islands (the)",
  "TUV": "Tuvalu",
  "UGA": "Uganda",
  "UKR": "Ukraine",
  "ARE": "United Arab Emirates (the)",
  "GBR": "United Kingdom of Great Britain and Northern Ireland (the)",
  "UMI": "United States Minor Outlying Islands (the)",
  "USA": "United States of America (the)",
  "URY": "Uruguay",
  "UZB": "Uzbekistan",
  "VUT": "Vanuatu",
  "VEN": "Venezuela (Bolivarian Republic of)",
  "VNM": "Viet Nam",
  "VGB": "Virgin Islands (British)",
  "VIR": "Virgin Islands (U.S.)",
  "WLF": "Wallis and Futuna",
  "ESH": "Western Sahara",
  "YEM": "Yemen",
  "ZMB": "Zambia",
  "ZWE": "Zimbabwe",
  "ALA": "Åland Islands"
};

export const countryListAlpha3German = {
  "AFG": "Afghanistan",
  "ALB": "Albanien",
  "DZA": "Algerien",
  "ASM": "Amerikanisch-Samoa",
  "AND": "Andorra",
  "AGO": "Angola",
  "AIA": "Anguilla",
  "ATA": "Antarktis",
  "ATG": "Antigua und Barbuda",
  "ARG": "Argentinien",
  "ARM": "Armenien",
  "ABW": "Aruba",
  "AUS": "Australien",
  "AUT": "Österreich",
  "AZE": "Aserbaidschan",
  "BHS": "Bahamas (die)",
  "BHR": "Bahrain",
  "BGD": "Bangladesch",
  "BRB": "Barbados",
  "BLR": "Weißrussland",
  "BEL": "Belgien",
  "BLZ": "Belize",
  "BEN": "Benin",
  "BMU": "Bermuda",
  "BTN": "Bhutan",
  "BOL": "Bolivien (Plurinationaler Staat)",
  "BES": "Bonaire, Sint Eustatius und Saba",
  "BIH": "Bosnien und Herzegowina",
  "BWA": "Botswana",
  "BVT": "Bouvetinsel",
  "BRA": "Brasilien",
  "IOT": "Britisches Territorium im Indischen Ozean (das)",
  "BRN": "Brunei Darussalam",
  "BGR": "Bulgarien",
  "BFA": "Burkina Faso",
  "BDI": "Burundi",
  "CPV": "Cabo Verde",
  "KHM": "Kambodscha",
  "CMR": "Kamerun",
  "CAN": "Kanada",
  "CYM": "Kaimaninseln (die)",
  "CAF": "Zentralafrikanische Republik (die)",
  "TCD": "Tschad",
  "CHL": "Chile",
  "CHN": "China",
  "CXR": "Weihnachtsinsel",
  "CCK": "Cocos (Keeling) Inseln (die)",
  "COL": "Kolumbien",
  "COM": "Komoren (die)",
  "COD": "Kongo (Demokratische Republik)",
  "COG": "Kongo (die)",
  "COK": "Cookinseln (die)",
  "CRI": "Costa Rica",
  "HRV": "Kroatien",
  "CUB": "Kuba",
  "CUW": "Curaçao",
  "CYP": "Zypern",
  "CZE": "Tschechische Republik",
  "CIV": "Côte d'Ivoire",
  "DNK": "Dänemark",
  "DJI": "Dschibuti",
  "DMA": "Dominica",
  "DOM": "Dominikanische Republik (die)",
  "ECU": "Ecuador",
  "EGY": "Ägypten",
  "SLV": "El Salvador",
  "GNQ": "Äquatorialguinea",
  "ERI": "Eritrea",
  "EST": "Estland",
  "SWZ": "Eswatini",
  "ETH": "Äthiopien",
  "FLK": "Falklandinseln (die) [Malwinen]",
  "FRO": "Färöer Inseln (die)",
  "FJI": "Fidschi",
  "FIN": "Finnland",
  "FRA": "Frankreich",
  "GUF": "Französisch-Guayana",
  "PYF": "Französisch-Polynesien",
  "ATF": "Französische Südterritorien (die)",
  "GAB": "Gabun",
  "GMB": "Gambia (die)",
  "GEO": "Georgien",
  "DEU": "Deutschland",
  "GHA": "Ghana",
  "GIB": "Gibraltar",
  "GRC": "Griechenland",
  "GRL": "Grönland",
  "GRD": "Grenada",
  "GLP": "Guadeloupe",
  "GUM": "Guam",
  "GTM": "Guatemala",
  "GGY": "Guernsey",
  "GIN": "Guinea",
  "GNB": "Guinea-Bissau",
  "GUY": "Guyana",
  "HTI": "Haiti",
  "HMD": "Heard Island und McDonald-Inseln",
  "VAT": "Vatikanstadt",
  "HND": "Honduras",
  "HKG": "Hongkong",
  "HUN": "Ungarn",
  "ISL": "Island",
  "IND": "Indien",
  "IDN": "Indonesien",
  "IRN": "Iran (Islamische Republik)",
  "IRQ": "Irak",
  "IRL": "Irland",
  "IMN": "Isle of Man",
  "ISR": "Israel",
  "ITA": "Italien",
  "JAM": "Jamaika",
  "JPN": "Japan",
  "JEY": "Jersey",
  "JOR": "Jordanien",
  "KAZ": "Kasachstan",
  "KEN": "Kenia",
  "KIR": "Kiribati",
  "PRK": "Korea (die Demokratische Volksrepublik)",
  "KOR": "Korea (die Republik)",
  "KWT": "Kuwait",
  "KGZ": "Kirgisistan",
  "LAO": "Demokratische Volksrepublik Laos (die)",
  "LVA": "Lettland",
  "LBN": "Libanon",
  "LSO": "Lesotho",
  "LBR": "Liberia",
  "LBY": "Libyen",
  "LIE": "Liechtenstein",
  "LTU": "Litauen",
  "LUX": "Luxemburg",
  "MAC": "Macao",
  "MDG": "Madagaskar",
  "MWI": "Malawi",
  "MYS": "Malaysia",
  "MDV": "Malediven",
  "MLI": "Mali",
  "MLT": "Malta",
  "MHL": "Marshallinseln (die)",
  "MTQ": "Martinique",
  "MRT": "Mauretanien",
  "MUS": "Mauritius",
  "MYT": "Mayotte",
  "MEX": "Mexiko",
  "FSM": "Mikronesien (Föderierte Staaten von)",
  "MDA": "Moldawien (die Republik)",
  "MCO": "Monaco",
  "MNG": "Mongolei",
  "MNE": "Montenegro",
  "MSR": "Montserrat",
  "MAR": "Marokko",
  "MOZ": "Mosambik",
  "MMR": "Myanmar",
  "NAM": "Namibia",
  "NRU": "Nauru",
  "NPL": "Nepal",
  "NLD": "Niederlande (die)",
  "NCL": "Neukaledonien",
  "NZL": "Neuseeland",
  "NIC": "Nicaragua",
  "NER": "Niger (der)",
  "NGA": "Nigeria",
  "NIU": "Niue",
  "NFK": "Norfolkinsel",
  "MNP": "Nördliche Marianen (die)",
  "NOR": "Norwegen",
  "OMN": "Oman",
  "PAK": "Pakistan",
  "PLW": "Palau",
  "PSE": "Palästina, Staat von",
  "PAN": "Panama",
  "PNG": "Papua-Neuguinea",
  "PRY": "Paraguay",
  "PER": "Peru",
  "PHL": "Philippinen (die)",
  "PCN": "Pitcairn",
  "POL": "Polen",
  "PRT": "Portugal",
  "PRI": "Puerto Rico",
  "QAT": "Katar",
  "MKD": "Republik Nordmazedonien",
  "ROU": "Rumänien",
  "RUS": "Russische Föderation (die)",
  "RWA": "Ruanda",
  "REU": "Réunion",
  "BLM": "Saint Barthélemy",
  "SHN": "St. Helena, Ascension und Trist",
  "KNA": "St. Kitts und Nevis",
  "LCA": "St. Lucia",
  "MAF": "St. Martin (französischer Teil)",
  "SPM": "St. Pierre und Miquelon",
  "VCT": "St. Vincent und die Grenadinen",
  "WSM": "Samoa",
  "SMR": "San Marino",
  "STP": "Sao Tome und Principe",
  "SAU": "Saudi-Arabien",
  "SEN": "Senegal",
  "SRB": "Serbien",
  "SYC": "Seychellen",
  "SLE": "Sierra Leone",
  "SGP": "Singapur",
  "SXM": "Sint Maarten (niederländischer Teil)",
  "SVK": "Slowakei",
  "SVN": "Slowenien",
  "SLB": "Salomonen",
  "SOM": "Somalia",
  "ZAF": "Südafrika",
  "SGS": "Südgeorgien und die Südlichen Sandwichinseln",
  "SSD": "Südsudan",
  "ESP": "Spanien",
  "LKA": "Sri Lanka",
  "SDN": "Sudan (der)",
  "SUR": "Surinam",
  "SJM": "Svalbard und Jan Mayen",
  "SWE": "Schweden",
  "CHE": "Schweiz",
  "SYR": "Syrische Arabische Republik",
  "TWN": "Taiwan",
  "TJK": "Tadschikistan",
  "TZA": "Tansania, Vereinigte Republik",
  "THA": "Thailand",
  "TLS": "Timor-Leste",
  "TGO": "Togo",
  "TKL": "Tokelau",
  "TON": "Tonga",
  "TTO": "Trinidad und Tobago",
  "TUN": "Tunesien",
  "TUR": "Türkei",
  "TKM": "Turkmenistan",
  "TCA": "Turks- und Caicosinseln (die)",
  "TUV": "Tuvalu",
  "UGA": "Uganda",
  "UKR": "Ukraine",
  "ARE": "Vereinigte Arabische Emirate (die)",
  "GBR": "Vereinigtes Königreich von Großbritannien und Nordirland (die)",
  "UMI": "United States Minor Outlying Islands (die)",
  "USA": "Vereinigte Staaten von Amerika (die)",
  "URY": "Uruguay",
  "UZB": "Usbekistan",
  "VUT": "Vanuatu",
  "VEN": "Venezuela (Bolivarische Republik)",
  "VNM": "Vietnam",
  "VGB": "Jungferninseln (Britisch)",
  "VIR": "Jungferninseln (U.S.)",
  "WLF": "Wallis und Futuna",
  "ESH": "Westsahara",
  "JEM": "Jemen",
  "ZMB": "Sambia",
  "ZWE": "Simbabwe",
  "ALA": "Åland-Inseln"
}