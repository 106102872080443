import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { faCar, faWrench } from "@fortawesome/free-solid-svg-icons";
import { faHouseUser } from "@fortawesome/pro-light-svg-icons";
import { CommonModule } from "@angular/common";
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MatExpansionPanel, MatExpansionPanelDescription, MatExpansionPanelHeader, MatExpansionPanelTitle } from '@angular/material/expansion';

export interface RouteNode {
  icon: RouteNodeIcon;
  day_start_time?: number; //in minutes
  duration: number; // in minutes
  estimated_duration: number,
  header: string;
  main_description: string,
  status: 'done' | 'active' | 'open',
  link?: string,
}

export type RouteNodeIcon = 'driving' | 'working' | 'absence'

@Component({
  imports: [CommonModule, FontAwesomeModule, MatExpansionPanel, MatExpansionPanelDescription, MatExpansionPanelHeader, MatExpansionPanelTitle],
  selector: 'hawk-route-visualization',
  templateUrl: './route-visualization.component.html',
  styleUrls: ['./route-visualization.component.scss'],
  standalone: true
})
export class RouteVisualizationComponent implements OnChanges {

  @Input() nodes: RouteNode[] = []

  icons = {
    driving: faCar,
    working: faWrench,
    absence: faHouseUser
  }
  expandedNode?: RouteNode;

  durationSums = {
    driving: 0,
    working: 0,
    total: 0
  }

  dayMinutesToTimeString(minutes: number, skip_mod_24?: boolean) {
    let hours = Math.floor(minutes / 60)
    if (!skip_mod_24) hours = hours % 24
    let hour_string = hours.toString()
    if (hour_string.length == 1) {
      hour_string = '0' + hour_string
    }
    let minute_string = (Math.floor(minutes % 60)).toString()
    if (minute_string.length == 1) {
      minute_string = '0' + minute_string
    }
    return hour_string + ':' + minute_string
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.updateDurationSums()
  }

  updateDurationSums(): void {
    this.durationSums.total = 0
    for (let icon of ['driving', 'working']) {
      let sum = 0
      for (let node of this.nodes) {
        if (node.icon == icon) sum += node.duration || node.estimated_duration
      }
      const round_sum = Math.round(sum)
      this.durationSums[icon] = round_sum
      this.durationSums.total += round_sum
    }

  }

  protected readonly Math = Math;
}