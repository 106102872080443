import { HolidaysTypes } from "date-holidays"
import { CalendarWeek } from "src/app/_models/planning-assistant.interface"
import { PAOperation } from "./operation"
import { PAUtil, WeekDay } from "./util"
import { PACoordinates } from "./coordinates"
import { PATechnician } from "./technician"
import { TravelTechnicianDateService } from "src/app/_services/travel_technician_date.service"
import { PAProgress } from "./progress"
import { TravelTechnicianDate } from "./travel-technician-date"
import { Subscription } from "rxjs"
import { PATour } from "./tour";
import { PALocation } from "./location";
import { PAStore } from "./store";
import { InsertionConfig } from "../tourplanner-menu/tour-timeline/tour-timeline.component";
import { FireUpdateOnChange } from "./util-classes/fire-update-on-change";
import { PADataControl } from "../singletons/pa-data-control";
import { PATourPlannerControl } from "../singletons/pa-tourplanner-control";
import { PATimeControl } from "../singletons/pa-time-control";
import { PAFilterControl } from "../singletons/pa-filter-control";
import { DraggableDestinationMarker } from "./draggable-marker";
import * as mapboxgl from "mapbox-gl";
import { PAPlanningInstructions } from "./planning-instructions";
import { UserAbsence } from "../../../_models/technician.interface";

export class PATechnicianDate extends FireUpdateOnChange<'new_tour'> {

  static travelTechnicianDates = new Map<number, Map<number, TravelTechnicianDate> | 'loading'>()
  static travelTechnicianDateService: TravelTechnicianDateService
  static defaultMaxWorkTime = 480
  static skipOperationUpdatesForMilliseconds = 1000 * 60 * 10 // 5min

  public loadingStatus: 'init' | 'loading' | 'loaded' = 'init'

  public initializedTravelTechnicianDate = false
  public previousTravelTechnicianDate: TravelTechnicianDate
  public previousTravelTechnicianDateChangeSubscription: Subscription
  public travelTechnicianDate: TravelTechnicianDate
  public travelTechnicianDateChangeSubscription: Subscription

  public latestNewOperationUpdateTimestamp = 0
  freeTourIndexes: number[] = []
  automaticLunchBreakCalculation = true
  lunchBreakAfterOperationNumber: number
  insertionConfigMap = new Map<number, InsertionConfig>()
  private _newTourOperationChanges: {
    force_new_tour?: boolean,
    insert_operations?: PAOperation[],
    delete_operations?: PAOperation[]
  } = {}

  tour: PATour

  changedTourContainer: ChangedTourContainer

  constructor(
    public technician: PATechnician,
    public date: string,
    public day: {
      week_day: WeekDay,
      utc_timestamp: number,
      local_timestamp: number,
      holidays: HolidaysTypes.Holiday[],
      date_string: string
    },
    public absences: UserAbsence[],
    public hawk_link: string,
    public id?: number
  ) {
    super()
    this.tour = this.generateNewEmptyTour()
    this.changedTourContainer = {
      tour: this.generateNewEmptyTour(),
      max_tour_minutes: 600,
      unfittingOperations: [],
      deletingOperations: []
    }
  }

  executeBeforeChange(): void {
    return
  }

  get newTourOperationChanges(): {
    force_new_tour?: boolean,
    insert_operations?: PAOperation[],
    delete_operations?: PAOperation[]
  } {
    return this._newTourOperationChanges
  }

  set newTourOperationChanges(changes: {
    force_new_tour?: boolean,
    insert_operations?: PAOperation[],
    delete_operations?: PAOperation[],
    skip_map_center?: boolean
  }) {
    this._newTourOperationChanges = changes
    this.updateNewTour(this._newTourOperationChanges).then(
      _ => {
        //this.selectTimelineTourForMap(changes.skip_map_center)
      }
    )
  }

  isPastTechnicianDate(): boolean {
    let todays_day_timestamp = PATimeControl.Instance.dateToTimestamp(new Date(), true, true)
    return todays_day_timestamp > this.day.utc_timestamp
  }

  isTodaysTechnicianDate(): boolean {
    let todays_day_timestamp = PATimeControl.Instance.dateToTimestamp(new Date(), true, true)
    return todays_day_timestamp == this.day.utc_timestamp
  }

/*  selectTimelineTourForMap(skip_map_center?: boolean): void {
    PATourPlannerControl.Instance.updateSelectedTourPlannerTours()
    if (!skip_map_center) PATourPlannerControl.Instance.centerTourPlannerTourInMap(this)
  }*/

  updateNewTourOperationChanges() {
    this.newTourOperationChanges = {
      insert_operations: this._newTourOperationChanges.insert_operations,
      delete_operations: this._newTourOperationChanges.delete_operations,
      force_new_tour: false,
      skip_map_center: true
    }
  }

  async setTravelTechnicianDate(): Promise<void> {

    let update_travel_status = false
    if (!this.travelTechnicianDate) {
      this.travelTechnicianDate = await this.getTravelTechnicianDate()
      this.travelTechnicianDateChangeSubscription = this.travelTechnicianDate.changedSubject.subscribe(() => {
        this.tour.onTravelTechnicianDateChange()
        this.updateNewTourOperationChanges()
        //this.changedTourContainer.tour.onTravelTechnicianDateChange()
      })
      update_travel_status = true
    }

    if (!this.previousTravelTechnicianDate) {
      let prev_technician_date = this.getPreviousTechnicianDate(true)
      if (prev_technician_date) {
        this.previousTravelTechnicianDate = await prev_technician_date.getTravelTechnicianDate()
        this.previousTravelTechnicianDateChangeSubscription = this.previousTravelTechnicianDate.changedSubject.subscribe(() => {
          this.tour.onPreviousTravelTechnicianDateChange()
          this.updateNewTourOperationChanges()
          //this.changedTourContainer.tour.onPreviousTravelTechnicianDateChange()
        })
        update_travel_status = true
      }
    }

    if (update_travel_status && this.tour) {
      this.tour.updateStartLocation();
      this.changedTourContainer.tour.updateStartLocation()
      await this.tour.updateTravelStatus(true)
      await this.changedTourContainer.tour.updateTravelStatus(true)
    }
  }

  public async getTravelTechnicianDate(): Promise<TravelTechnicianDate> {
    return await PATechnicianDate.getTravelTechnicianDate(this.technician.id, this.day.utc_timestamp)
  }

  public getNewTourChangeSubject() {
    return this.changedTourContainer.tour.updateSubject
  }

  public async waitUntilDataWasLoaded(): Promise<void> {
    while (this.loadingStatus == 'init') {
      await PAUtil.sleep(0.1)
    }
  }

  public plannableHash(deadline?: { from_ts?: number, until_ts?: number }): {
    technician_date: PATechnicianDate,
    plannable: boolean,
    reason?: string
  } {
    if (deadline) {
      if (deadline.from_ts && deadline.until_ts) {
        return {
          technician_date: this,
          plannable: this.day.utc_timestamp + 24 * 60 * 60 * 1000 > deadline.until_ts && this.day.utc_timestamp < deadline.until_ts,
          reason: 'SLA oder Termin'
        }
      } else if (deadline.from_ts) {
        return {
          technician_date: this,
          plannable: this.day.utc_timestamp + 24 * 60 * 60 * 1000 > deadline.until_ts,
          reason: 'SLA oder Termin'
        }
      } else if (deadline.until_ts) {
        return {technician_date: this, plannable: this.day.utc_timestamp < deadline.until_ts, reason: 'SLA oder Termin'}
      } else {
        return {technician_date: this, plannable: true}
      }
    }

    let holidays = this.getPublicHolidays()
    if (holidays.length) {
      return {technician_date: this, plannable: false, reason: holidays[0].name}
    }

    if (this.absences.length) {
      return {technician_date: this, plannable: false, reason: this.absences[0].type}
    }

    return {technician_date: this, plannable: true}
  }

  public getPublicHolidays(): HolidaysTypes.Holiday[] {
    return this.day.holidays.filter(holiday => holiday.type == 'public')
  }

  public getCalendarWeek(): CalendarWeek {
    return PATimeControl.Instance.timestampToCalendarWeek(this.day.utc_timestamp)
  }

  public getPreviousTechnicianDate(init_if_not_existent?: boolean, load_data?: boolean): PATechnicianDate {
    return this.technician.getTechnicianDate(this.day.utc_timestamp - 24 * 60 * 60 * 1000, init_if_not_existent, load_data)
  }

  generateNewEmptyTour(): PATour {
    return new PATour(
      this
    )
  }

  public getDestinationMarker(): DraggableDestinationMarker {
    return this.travelTechnicianDate?.draggableDestinationMarker
  }

  public displayTourPlanningDestinationMarkerOnMap(map: mapboxgl.Map): mapboxgl.Marker {
    let travel_technician_date = this.travelTechnicianDate
    let destination_marker = this.getDestinationMarker()
    if (destination_marker && travel_technician_date?.isActive() && destination_marker.map != map) {
      destination_marker.map = map
    }
    return destination_marker?.marker
  }

  public removeTourPlanningDestinationMarkerFromMap(): void {
    let destination_marker = this.getDestinationMarker()
    if (destination_marker) {
      destination_marker.map = null
    }
  }

  public async loadData(): Promise<void> {
    if (this.loadingStatus != 'loading' && this.loadingStatus != 'loaded') {
      let date = PATimeControl.Instance.timestampToDatestring(this.day.utc_timestamp, true)
      let technician_name = this.technician.getFullName()
      let progress = new PAProgress('Lade Aufträge -> ' + technician_name + ' ' + date, 1)
      this.loadingStatus = 'loading'
      return new Promise((resolve) => {
        progress.addSubProgress(PAOperation.planningAssistantService.getOperationUserInfo([this.technician.id], date + " 00:00:00", date + " 23:59:59", {exclude_material_return: true}), '').subscribe(
          async (data) => {
            if (this.loadingStatus == 'loading') {
              this.loadingStatus = 'loaded'
              if (data.length) {
                let operations: PAOperation[] = []
                data.map(entry => operations = operations.concat(entry.operations.map(operation_hash => PADataControl.Instance.operationHashToOperation(operation_hash, true))))
                PADataControl.Instance.addOperationsToLoadedOperations(operations)
                await PAOperation.insertOperationsInTechnicianDateAndStore(operations)
              }
              await this.tour.updateRoute()
            } else {
              if (data.length) {
                await PADataControl.Instance.updateOperations(data[0].operations.map(operation_hash => PADataControl.Instance.operationHashToOperation(operation_hash)))
              }
            }
            resolve()
          },
          (err) => {
            console.error(err)
            resolve()
          },
        )
      })
    }
  }

  getWeekdayIdx(): number {
    return PATimeControl.Instance.weekDays.indexOf(this.day.week_day)
  }

  hasOperationWithIdInChangedTour(op_id: number): boolean {
    let ctc = this.changedTourContainer
    return !!ctc.tour.operations.concat(ctc.unfittingOperations).find(op => op.id == op_id)
  }

  async updateNewTour(config?: {
    force_new_tour?: boolean,
    insert_operations?: PAOperation[],
    delete_operations?: PAOperation[]
  }): Promise<void> {
    let insertion_operations = config?.insert_operations || []
    let deletion_operations = config?.delete_operations || []
    this.updateInsertionConfigMap(insertion_operations)

    let update_ts = this.latestNewOperationUpdateTimestamp = Date.now()

    let scored_routes = await this.calculatePossibleRoutes(update_ts, insertion_operations, deletion_operations)
    if (update_ts != this.latestNewOperationUpdateTimestamp) return

    let sorted_routes = sortScoredRoutes(scored_routes)
    if (update_ts != this.latestNewOperationUpdateTimestamp) return

    let found_route_validity: RouteValidity
    for (let scored_route of sorted_routes) {
      let route_validity = await this.getRouteValidity(scored_route.route)
      if (!route_validity.errors.length) {
        found_route_validity = route_validity

        let unfitting_operations_before = this.changedTourContainer.unfittingOperations
        this.changedTourContainer.unfittingOperations = scored_route.removed_operations.map(o => o.clone());
        this.changedTourContainer.deletingOperations = deletion_operations;
        [...new Set(unfitting_operations_before.concat(this.changedTourContainer.unfittingOperations))].map(op => op.getStore().fireUpdateManually())
        this.changedTourContainer.unfittingOperations.concat(this.changedTourContainer.deletingOperations).map(op => {
          let config = this.insertionConfigMap.get(op.id)
          if (config) config.tour_index = -1
        })

        this.freeTourIndexes = PAUtil.range(0, scored_route.route.length - 1)
        break
      }
    }

    let new_tour = this.generateNewEmptyTour()
    found_route_validity.lunch_break ? new_tour.addLunchBreak(found_route_validity.lunch_break.after_operation_idx, found_route_validity.lunch_break.duration_minutes) : {}
    let operations = this.tour.operations.concat(insertion_operations)

    let cloned_fixed_operations = operations.filter(operation => operation.date_travel_start).map(op => op.clone())
    let cloned_insert_operations = found_route_validity.insertions.map(i => i.operation.clone())
    for (let operation of cloned_fixed_operations.concat(cloned_insert_operations)) {
      operation.tour = new_tour
      operation.user_ids = [new_tour.technicianDate.technician.id]
    }
    for (let operation of cloned_insert_operations) {
      let date_time_ms = PATimeControl.Instance.dateToTimestamp(new Date(new_tour.technicianDate.day.utc_timestamp), true, false) + found_route_validity.insertions.find(i => i.operation.id == operation.id).start_time_ms
      operation.operation_date = PATimeControl.Instance.formatDate(new Date(date_time_ms))
    }
    await new_tour.insertOperations(cloned_fixed_operations.concat(cloned_insert_operations), {wait_for_all_queued_updates: true})

    const old_start_location = this.changedTourContainer.tour.route.start_location
    const old_end_location = this.changedTourContainer.tour.route.end_location
    const start_or_end_location_changed = old_start_location != new_tour.startLocation || old_end_location != new_tour.endLocation

    if (config?.force_new_tour || !this.changedTourContainer.tour.hasEqualRoute(new_tour.operations, true) || start_or_end_location_changed) {
      this.changedTourContainer.tour = new_tour
      this.fireUpdateManually('new_tour')
      PATourPlannerControl.Instance.updateTechnicianToursInPlanningProcess()
    }

    if (update_ts == this.latestNewOperationUpdateTimestamp) {
      this.latestNewOperationUpdateTimestamp = null
    }
  }

  async calculatePossibleRoutes(update_ts: number, insert_operations: PAOperation[], delete_operations: PAOperation[]): Promise<ScoredRoute[]> {

    let permutation_input_limit = 6

    let operations = this.tour.operations.concat(insert_operations).filter(o => !delete_operations.find(delete_op => delete_op.id == o.id))
    let store_operations_map = new Map<PAStore, PAOperation[]>()

    let ordered_already_started_operations = operations.filter(operation => operation.date_travel_start).sort((operation_a, operation_b) => Date.parse(operation_a.date_travel_start) < Date.parse(operation_b.date_travel_start) ? -1 : 1)
    let not_started_operations = operations.filter(operation => !operation.date_travel_start)

    let not_started_operations_with_set_tour_idx = not_started_operations.filter(o => this.insertionConfigMap.has(o.id) && this.insertionConfigMap.get(o.id).tour_index >= 0)
    let not_started_operations_to_optimize = not_started_operations.filter(o => !this.insertionConfigMap.has(o.id) || this.insertionConfigMap.get(o.id).tour_index == -1)

    let ordered_operations_with_tour_idx = not_started_operations_with_set_tour_idx.sort((o1, o2) => {
      return this.insertionConfigMap.get(o1.id).tour_index < this.insertionConfigMap.get(o2.id).tour_index ? -1 : 1
    })

    for (let operation of not_started_operations_to_optimize) {
      let operations_store = operation.getStore()
      if (!store_operations_map.has(operations_store)) {
        store_operations_map.set(operations_store, [operation])
      } else {
        store_operations_map.get(operations_store).push(operation)
      }
    }

    let not_started_stores_to_optimize = [...new Set(not_started_operations_to_optimize.map(op => op.getStore()))]
    let stores_not_to_permute = not_started_stores_to_optimize.splice(permutation_input_limit)
    let not_started_locations_to_optimize = not_started_stores_to_optimize.map(store => store.location)
    let last_fixed_location_before_permutations = ordered_already_started_operations[ordered_already_started_operations.length - 1]?.ticket.client.location || this.tour.startLocation

    if (not_started_locations_to_optimize.length >= 1) {
      await PALocation.preloadLocationDistanceMatrix([last_fixed_location_before_permutations].concat(not_started_locations_to_optimize), not_started_locations_to_optimize.concat([this.tour.endLocation]), 'min_time')
    }

    let store_permutations = PAUtil.permutator<PAStore>(not_started_stores_to_optimize)
    let scored_permutations: ScoredRoute[] = []
    let lowest_operation_remove_count = -1

    let permutation_chunks = PAUtil.chunkArray<PAStore[]>(store_permutations, 32)
    let invalid_permutation_count = 0
    for (let stores_chunk of permutation_chunks) {
      console.log(`starting chunk ${permutation_chunks.indexOf(stores_chunk)}`)
      if (update_ts != this.latestNewOperationUpdateTimestamp) return

      await Promise.all(stores_chunk.map(async store_permutation => {
        let ordered_operations: PAOperation[] = []
        for (let store of store_permutation.concat(stores_not_to_permute)) {
          let store_operations = store_operations_map.get(store)
          ordered_operations = ordered_operations.concat(store_operations)
        }
        let route = ordered_already_started_operations.concat(ordered_operations)

        for (let operation of ordered_operations_with_tour_idx) {
          let idx = this.insertionConfigMap.get(operation.id).tour_index
          route.splice(idx, 0, operation)
        }

        let valid_partial_route = await this.findTimeConstrainedPartialRoute({
          route: route,
          start_location: this.tour.startLocation,
          end_location: this.tour.endLocation
        }, this.changedTourContainer.max_tour_minutes * 60 * 1000, lowest_operation_remove_count)
        if (valid_partial_route) {
          let removed_operation_count = valid_partial_route.removed_operations.length
          if (lowest_operation_remove_count == -1 || removed_operation_count <= lowest_operation_remove_count) {
            lowest_operation_remove_count = valid_partial_route.removed_operations.length
            scored_permutations.push({
              route: valid_partial_route.partial_route,
              score: null,
              removed_operations: valid_partial_route.removed_operations
            })
          } else {
            invalid_permutation_count += 1
          }
        } else {
          invalid_permutation_count += 1
        }
      }))
    }

    if (update_ts != this.latestNewOperationUpdateTimestamp) return
    await Promise.all(scored_permutations.map(async store_permutation => {
      store_permutation.score = await routeScore(store_permutation.route, this.tour.startLocation, this.tour.endLocation)
    }))

    return scored_permutations
  }

  async findTimeConstrainedPartialRoute(
    route_data: { route: PAOperation[], start_location: PALocation, end_location: PALocation },
    max_tour_time_ms: number,
    highest_remove_count: number
  ): Promise<{ partial_route: PAOperation[], removed_operations: PAOperation[] }> {

    let operation_time_map = new Map<PAOperation, number>()

    let route = route_data.route
    let route_priority_idxs = await this.technician.currentTourPlannings.getRoutesPriorityOperationCountsOnDay(route, this.day.utc_timestamp)

    for (let operation of route) {
      let operation_time = operation.calculateOperationTimeMilliseconds({
        use_technician: this.technician,
        use_priority_idx: route_priority_idxs.find(route_idxs => route_idxs.operation == operation).count
      })
      operation_time_map.set(operation, operation_time)
    }

    let removed_operations: PAOperation[] = []

    let current_partial_route = route
    while (current_partial_route.length && (highest_remove_count == -1 || removed_operations.length <= highest_remove_count)) {

      let route_time_minutes = (await current_partial_route[current_partial_route.length - 1].ticket.location.getDistanceToLocation(route_data.end_location, true)).duration
      let technician_route_time_ms = Math.round(route_time_minutes * this.technician.driving_time_factor) * 60 * 1000
      let last_removable_operation_idx = -1

      for (let operation of current_partial_route) {
        let idx = current_partial_route.indexOf(operation)
        let driving_time_minutes = (await (idx == 0 ? route_data.start_location : current_partial_route[idx - 1].ticket.location).getDistanceToLocation(operation.ticket.location, true)).duration
        let technician_driving_time_ms = Math.round(driving_time_minutes * this.technician.driving_time_factor) * 60 * 1000
        technician_route_time_ms = technician_route_time_ms + technician_driving_time_ms + operation_time_map.get(operation)

        if (this.insertionConfigMap.has(operation.id) && this.insertionConfigMap.get(operation.id).removable) {
          last_removable_operation_idx = idx
        }
      }

      if (technician_route_time_ms < max_tour_time_ms || last_removable_operation_idx == -1) {
        return {partial_route: current_partial_route, removed_operations: removed_operations}
      } else if (last_removable_operation_idx != -1) {
        removed_operations = removed_operations.concat(current_partial_route.splice(last_removable_operation_idx, 1))

        let removed_operation_was_not_the_last_operation = current_partial_route.length != last_removable_operation_idx
        if (removed_operation_was_not_the_last_operation) {
          //after remove: shift operations with determined tour-idx to the right position
          let all_spliced_ops: {op: PAOperation, idx: number}[] = []
          for (let idx of PAUtil.range(last_removable_operation_idx, current_partial_route.length - 1)) {
            if (this.insertionConfigMap.get(current_partial_route[idx - all_spliced_ops.length].id).tour_index != -1) {
              all_spliced_ops.push({op: current_partial_route.splice(idx - all_spliced_ops.length, 1)[0], idx: idx});
            }
          }
          for (let spliced_operation_idx of all_spliced_ops) {
            current_partial_route.splice(spliced_operation_idx.idx + 1, 0, spliced_operation_idx.op);
          }
        }
      }
    }

    if (highest_remove_count != -1 && removed_operations.length > highest_remove_count) {
      return null
    }

    return {partial_route: current_partial_route, removed_operations: removed_operations}
  }

  async getRouteValidity(route: PAOperation[]): Promise<RouteValidity> {

    if (!route.length) {
      return {insertions: [], errors: []}
    }

    let ts_now = Date.now()
    let route_priority_idxs = await this.technician.currentTourPlannings.getRoutesPriorityOperationCountsOnDay(route, this.day.utc_timestamp)

    let errors: string[] = []
    let insertions: { operation: PAOperation, start_time_ms: number, tour_time_ms: number }[] = []
    let already_started_operations = route.filter(operation => operation.date_travel_start)

    let next_earliest_driving_start_ms: number
    let last_started_location: PALocation

    if (already_started_operations.length) {
      let last_operation = already_started_operations[already_started_operations.length - 1]
      let operation_time_data = await last_operation.getOperationTimeData(this.technician, 0)

      if (PAFilterControl.Instance.selectedOperationTimeFilter == 'planned') {
        next_earliest_driving_start_ms = operation_time_data.estimated_end_time * 60 * 1000
      } else {
        next_earliest_driving_start_ms = operation_time_data.average_end_time * 60 * 1000
      }
      last_started_location = last_operation.ticket.client.location
    } else {
      let first_operation = route[0]
      let first_operations_opening_time = first_operation.ticket.store_openings[PATimeControl.Instance.getTimeStampsWeekDay(ts_now)]?.open
      last_started_location = this.tour.startLocation

      if (first_operations_opening_time) {
        let driving_time_minutes = (await last_started_location.getDistanceToLocation(first_operation.ticket.location)).duration
        let technician_driving_time_ms = Math.round(driving_time_minutes * this.technician.driving_time_factor) * 60 * 1000
        next_earliest_driving_start_ms = (PATimeControl.Instance.timeStringToMinutes(first_operations_opening_time) * 60 * 1000) - technician_driving_time_ms
      } else {
        next_earliest_driving_start_ms = 8 * 60 * 60 * 1000 // 8:00AM
      }
    }

    if (this.day.utc_timestamp < ts_now && ts_now < this.day.utc_timestamp + 24 * 60 * 60 * 1000) {
      let ms_since_day_start = ts_now - PATimeControl.Instance.dateToTimestamp(new Date(), true, false)
      if (ms_since_day_start > next_earliest_driving_start_ms) {
        next_earliest_driving_start_ms = ms_since_day_start
      }
    }

    let already_started_operation_times = await Promise.all(already_started_operations.map(
      async op => {
        let op_time_data = await op.getOperationTimeData(this.technician, 0)
        return op_time_data.driving_time + PAFilterControl.Instance.selectedOperationTimeFilter == 'planned' ? op_time_data.estimated_worktime : op_time_data.average_worktime
      }
    ))

    let not_started_operations = route.filter(operation => !operation.date_travel_start)
    let last_tour_location = not_started_operations.length ? not_started_operations[not_started_operations.length - 1] : last_started_location
    let home_driving_minutes = (await last_tour_location.getDistanceToLocation(this.tour.endLocation)).duration
    let technician_home_driving_ms = Math.round(home_driving_minutes * this.technician.driving_time_factor) * 60 * 1000
    let current_tour_time_ms = already_started_operation_times.reduce((sum, time) => sum + time * 60 * 1000, 0)

    for (let operation of not_started_operations) {

      let current_location = operation.ticket.client.location
      let driving_time_minutes = (await last_started_location.getDistanceToLocation(current_location)).duration
      let technician_driving_time_ms = Math.round(driving_time_minutes * this.technician.driving_time_factor) * 60 * 1000
      let operation_time_ms = operation.calculateOperationTimeMilliseconds({
        use_technician: this.technician,
        use_priority_idx: route_priority_idxs.find(route_idxs => route_idxs.operation == operation).count
      })
      let operation_start_ms = next_earliest_driving_start_ms + technician_driving_time_ms

      insertions.push({operation: operation, start_time_ms: operation_start_ms, tour_time_ms: current_tour_time_ms})
      last_started_location = current_location
      next_earliest_driving_start_ms = operation_start_ms + operation_time_ms

      current_tour_time_ms = current_tour_time_ms + (technician_driving_time_ms + operation_time_ms)
    }

    //add lunch break
    let lunch_break: { after_operation_idx: number, duration_minutes: number }
    let lunch_config = PATour.getLunchBreakTimeConfig((current_tour_time_ms + technician_home_driving_ms) / 60000)
    if (lunch_config) {
      let shift_start_insertion: { operation: PAOperation, start_time_ms: number, tour_time_ms: number }

      const has_invalid_lunch_break_idx_config = this.lunchBreakAfterOperationNumber > route.length - 1
      if (has_invalid_lunch_break_idx_config) {
        this.automaticLunchBreakCalculation = true
        this.lunchBreakAfterOperationNumber = null
      }

      if (this.lunchBreakAfterOperationNumber) {
        shift_start_insertion = insertions.find(insertion => this.lunchBreakAfterOperationNumber == route.indexOf(insertion.operation))
      } else {
        shift_start_insertion = insertions.find(insertion => insertion.tour_time_ms > lunch_config.after_tour_minute * 60000 && route.indexOf(insertion.operation) != 0)
        if (!shift_start_insertion && insertions.length && route.length > 1) {
          // If no valid operation found, take the last insertion if the tour has at least 2 operations
          shift_start_insertion = insertions[insertions.length - 1]
        }
      }
      if (shift_start_insertion) {
        let shift_start_idx = insertions.indexOf(shift_start_insertion)
        lunch_break = {
          after_operation_idx: route.indexOf(shift_start_insertion.operation) - 1,
          duration_minutes: lunch_config.minutes
        }
        insertions.map(
          insertion => {
            if (insertions.indexOf(insertion) >= shift_start_idx) {
              insertion.start_time_ms += lunch_config.minutes * 60000
            }
          }
        )
      } else {
        lunch_break = {
          after_operation_idx: this.lunchBreakAfterOperationNumber || 0,
          duration_minutes: lunch_config.minutes
        }
      }
    }

    return {
      errors: errors,
      insertions: insertions,
      lunch_break: lunch_break
    }
  }

  public updateInsertionConfigMap(insertion_operations: PAOperation[]) {
    let all_operations: PAOperation[]

    let tour_operations = this.tour.operations
    if (tour_operations) {
      for (let operation of tour_operations) {
        if (!this.insertionConfigMap.has(operation.id)) {
          this.insertionConfigMap.set(operation.id, {removable: false, tour_index: -1})
        }
      }
    }

    for (let operation of insertion_operations) {
      if (!this.insertionConfigMap.has(operation.id)) {
        let is_main_operation_to_plan = PATourPlannerControl.Instance.tourPlannerPlanningMode == 'operation' && PATourPlannerControl.Instance.operationToPlanID == operation.id
        this.insertionConfigMap.set(operation.id, {removable: !is_main_operation_to_plan, tour_index: -1})
      }
    }

    all_operations = tour_operations.concat(insertion_operations)
    for (let key of this.insertionConfigMap.keys()) {
      if (!all_operations.find(op => key == op.id)) {
        this.insertionConfigMap.delete(key)
      }
    }
  }

  removeOperationsFromNewTour(remove_operations: PAOperation[]): void {
    let insert_operations = this.newTourOperationChanges?.insert_operations || []
    const remove_insert_operations = insert_operations.filter(ins_op => remove_operations.find(rem_op => rem_op.id == ins_op.id))
    const delete_operations = this.tour.operations.filter(tour_op => remove_operations.find(rem_op => rem_op.id == tour_op.id))
    if (remove_insert_operations.length) {
      let timestamps = PATourPlannerControl.Instance.tourPlannerPlanningMode == 'technician' ? [this.day.utc_timestamp] : null
      PATourPlannerControl.Instance.planningInstructions.removeOperationsTechnicianConstraints(remove_insert_operations, [this.technician], timestamps)
      PATourPlannerControl.Instance.removeExtraOperationsIfNotUsed(remove_insert_operations)
    }
    if (delete_operations.length) {
      let changes_before = this.newTourOperationChanges
      this.newTourOperationChanges = {
        insert_operations: changes_before?.insert_operations || [],
        force_new_tour: changes_before?.force_new_tour || false,
        delete_operations: (changes_before.delete_operations || []).concat(delete_operations)
      }
    }
  }

  restoreOperationToNewTour(add_operation: PAOperation): void {
    let changes_before = this.newTourOperationChanges
    let deleted_operations_before = changes_before?.delete_operations || []
    let deleted_operation = deleted_operations_before.find(operation => operation.id == add_operation.id)
    if (deleted_operation) {
      this.newTourOperationChanges = {
        insert_operations: changes_before?.insert_operations || [],
        force_new_tour: changes_before?.force_new_tour || false,
        delete_operations: deleted_operations_before.filter(op => op.id != add_operation.id)
      }
    }
  }

  insertionConfigIndexIsDisabled(idx: number): boolean {
    return !![...this.insertionConfigMap].find(i_config_kv => !this.changedTourContainer.unfittingOperations.find(op => op.id == i_config_kv[0]) && i_config_kv[1].tour_index == idx)
  }

  updateNewTourOperations(config?: {force_new_tour?: boolean, insert_operations?: PAOperation[], delete_operations?: PAOperation[]}): void {
    if (!this.isPastTechnicianDate()) {
      let delete_operations_before = this.newTourOperationChanges?.delete_operations || []
      let insert_operations_before = this.newTourOperationChanges?.insert_operations || []
      this.newTourOperationChanges = {
        force_new_tour: config?.force_new_tour,
        insert_operations: config?.insert_operations || insert_operations_before,
        delete_operations: config?.delete_operations || delete_operations_before
      }
    }
  }

  async checkCurrentTourForDBChanges(): Promise<DBOperationChange[]> {
    return await this.checkTourForDBChanges(this.tour)
  }

  async checkNewTourForDBChanges(): Promise<DBOperationChange[]> {
    return await this.checkTourForDBChanges(this.changedTourContainer.tour)
  }

  async checkTourForDBChanges(tour: PATour): Promise<DBOperationChange[]> {
    let new_tour_operations = tour.operations
    let check_operations = new_tour_operations.filter(op => op.id > 0)

    if (!check_operations.length) return []

    return await new Promise<DBOperationChange[]>(
      resolve => {
        PAOperation.planningAssistantService.getPlanningAssistantOperations(check_operations.map(op => op.id)).subscribe(
          data => {
            let res: DBOperationChange[] = []
            for (let operation_hash of data) {
              let new_tour_base_operation = PADataControl.Instance.getOperation(operation_hash.id, null)
              let user_ids_changed: OperationUIDChanged = new_tour_base_operation.user_ids[0] != operation_hash.user_ids[0] ? {current_uid: new_tour_base_operation.user_ids[0], db_uid: operation_hash.user_ids[0]} : null
              let operation_date_changed: OperationDateChanged = new_tour_base_operation.operation_date != operation_hash.operation_date ? {current_operation_date: new_tour_base_operation.operation_date, db_operation_date: operation_hash.operation_date} : null
              if (user_ids_changed || operation_date_changed) {
                if (!user_ids_changed) {
                  res.push({base_operation: new_tour_base_operation, date_changed: operation_date_changed})
                } else if (!operation_date_changed) {
                  res.push({base_operation: new_tour_base_operation, uid_changed: user_ids_changed})
                } else {
                  res.push({base_operation: new_tour_base_operation, uid_changed: user_ids_changed, date_changed: operation_date_changed})
                }
              }
            }
            resolve(res)
          },
          err => {
            console.log(err)
          }
        )
      }
    )
  }

  getConstrainedOperations(operations: PAOperation[], planning_instructions: PAPlanningInstructions): PAOperation[] {
    return operations.filter(op => planning_instructions.operationIsDoableOnTechnicianDate(op, this))
  }

  static setTravelTechnicianDateService(service: TravelTechnicianDateService): void {
    this.travelTechnicianDateService = service
  }

  static async getTravelTechnicianDate(uid: number, timestamp: number): Promise<TravelTechnicianDate> {
    if (!PATechnicianDate.travelTechnicianDates.has(timestamp)) {
      const cw = PATimeControl.Instance.timestampToCalendarWeek(timestamp)
      await this.loadTravelTechnicianDatesForCalendarWeek(cw)
    }

    while (PATechnicianDate.travelTechnicianDates.get(timestamp) == 'loading') {
      await PAUtil.sleep(50)
    }

    let travel_technician_date_user_map = PATechnicianDate.travelTechnicianDates.get(timestamp) as Map<number, TravelTechnicianDate>
    if (!travel_technician_date_user_map.has(uid)) {
      travel_technician_date_user_map.set(uid, new TravelTechnicianDate(uid, timestamp))
    }
    return travel_technician_date_user_map.get(uid)
  }

  static async loadTravelTechnicianDatesForCalendarWeek(cw: CalendarWeek): Promise<void> {
    let cwd = PATimeControl.Instance.getCalendarWeekData(cw)
    let ttds = PATechnicianDate.travelTechnicianDates
    ttds.set(cwd.weekdays.monday.timestamp, 'loading')
    ttds.set(cwd.weekdays.tuesday.timestamp, 'loading')
    ttds.set(cwd.weekdays.wednesday.timestamp, 'loading')
    ttds.set(cwd.weekdays.thursday.timestamp, 'loading')
    ttds.set(cwd.weekdays.friday.timestamp, 'loading')
    ttds.set(cwd.weekdays.saturday.timestamp, 'loading')
    ttds.set(cwd.weekdays.sunday.timestamp, 'loading')

    const date_from = PATimeControl.Instance.dateToDatestring(new Date(cwd.weekdays['monday'].timestamp), true, false) + " 00:00:00"
    const date_until = PATimeControl.Instance.dateToDatestring(new Date(cwd.weekdays['sunday'].timestamp), true, false) + " 23:59:59"
    return new Promise((resolve, reject) => {
        this.travelTechnicianDateService.getTravelTechnicianDates(date_from, date_until).subscribe(
          async (data) => {
            ttds.set(cwd.weekdays.monday.timestamp, new Map<number, TravelTechnicianDate>())
            ttds.set(cwd.weekdays.tuesday.timestamp, new Map<number, TravelTechnicianDate>())
            ttds.set(cwd.weekdays.wednesday.timestamp, new Map<number, TravelTechnicianDate>())
            ttds.set(cwd.weekdays.thursday.timestamp, new Map<number, TravelTechnicianDate>())
            ttds.set(cwd.weekdays.friday.timestamp, new Map<number, TravelTechnicianDate>())
            ttds.set(cwd.weekdays.saturday.timestamp, new Map<number, TravelTechnicianDate>())
            ttds.set(cwd.weekdays.sunday.timestamp, new Map<number, TravelTechnicianDate>())

            for (let ttd_hash of data) {
              let lat = ttd_hash.location_address_latitude
              let lng = ttd_hash.location_address_longitude
              let coordinates = lat && lng ? new PACoordinates(lat, lng, ttd_hash.location_name) : null
              let timestamp = PATimeControl.Instance.dateStringToTimestamp(ttd_hash.date, true, true)
              let ttd = new TravelTechnicianDate(ttd_hash.user_id, timestamp, {
                dbID: ttd_hash.id,
                coordinates: coordinates
              })
              let map = ttds.get(timestamp) as Map<number, TravelTechnicianDate>
              map.set(ttd_hash.user_id, ttd)
            }

            resolve()
          },
          (err) => {
            console.error(err)
            reject()
          },
        )
      }
    )
  }

  closestDistanceToLocation(ref_location: PALocation) {
    let closest_location_distance = -1
    for (let location of [this.tour.startLocation, ...this.tour.operations.map(op => op.ticket.location), this.tour.endLocation]) {
      location = location || this.tour.technicianDate.technician.location
      let distance = PAUtil.calcDistanceAsTheCrowFlies(location.coordinates.latitude, location.coordinates.longitude, ref_location.coordinates.latitude, ref_location.coordinates.longitude)
      if (closest_location_distance == -1 || closest_location_distance > distance) {
        closest_location_distance = distance
      }
    }
    return closest_location_distance
  }
}

async function routeScore(route: PAOperation[], start_location: PALocation, end_location: PALocation): Promise<{
  distance: number,
  duration: number
}> {
  let score = {distance: 0, duration: 0}
  let current_location = start_location
  for (let operation of route) {
    let operation_location = operation.ticket.client.location
    let distance = await current_location.getDistanceToLocation(operation_location)
    score.distance += distance.distance
    score.duration += distance.duration
    current_location = operation_location
  }
  let home_distance = await current_location.getDistanceToLocation(end_location)
  score.distance += home_distance.distance
  score.duration += home_distance.duration
  return score
}

function sortScoredRoutes(scored_routes: ScoredRoute[]): ScoredRoute[] {

  let sortScoredRoutesByTime = (scored_permutation_a: ScoredRoute, scored_permutation_b: ScoredRoute) => {
    if (scored_permutation_a.removed_operations.length < scored_permutation_b.removed_operations.length) return -1
    if (scored_permutation_a.removed_operations.length > scored_permutation_b.removed_operations.length) return 1

    let primary_score_a = scored_permutation_a.score.duration
    let primary_score_b = scored_permutation_b.score.duration
    let secondary_score_a = scored_permutation_a.score.distance
    let secondary_score_b = scored_permutation_b.score.distance

    if (primary_score_a < primary_score_b) return -1
    if (primary_score_a > primary_score_b) return 1
    if (primary_score_a == primary_score_b) {
      if (secondary_score_a < secondary_score_b) return -1
      if (secondary_score_a >= secondary_score_b) return 1
    }
  }

  return scored_routes.sort(sortScoredRoutesByTime)
}

export interface ChangedTourContainer {
  tour: PATour
  max_tour_minutes: number;
  unfittingOperations: PAOperation[]
  deletingOperations: PAOperation[]
}

export interface RouteValidity {
  errors: string[],
  insertions: { operation: PAOperation, start_time_ms: number }[],
  lunch_break?: { after_operation_idx: number, duration_minutes: number }
}

interface ScoredRoute {
  route: PAOperation[];
  score: {
    distance: number;
    duration: number;
  };
  removed_operations: PAOperation[]
}

export interface DBOperationChange {
  base_operation: PAOperation
  uid_changed?: OperationUIDChanged
  date_changed?: OperationDateChanged
}

export interface OperationUIDChanged {
  current_uid: number
  db_uid: number
}

export interface OperationDateChanged {
  current_operation_date: string
  db_operation_date: string
}