import { MapRoute } from "../../../_models/planning-assistant.interface";
import * as mapboxgl from "mapbox-gl";
import { v4 as uuidv4 } from "uuid";
import { PAUtil } from "./util";
import { PATour } from "./tour";
import { MapContainer } from "../map/pa-map";
import { PAMapboxControl } from "../singletons/pa-mapbox-control";
import { PADataControl } from "../singletons/pa-data-control";
import { PATourPlannerControl } from "../singletons/pa-tourplanner-control";

export class TourAnimation {

  public routes: MapRoute[] = []
  private readonly _animationSeconds: number = 3
  private readonly _animationPauseSeconds: number = 0
  private readonly _fps: number = 25
  private _hoveredMapboxLayer = ''
  public stopAnimation = true
  public running = false

  private lastUUID = ''
  private lastLineLayers: LineLayerContainer[] = []

  constructor(
    public tour: PATour,
    public mapContainer: MapContainer,
    config?: { animation_seconds?: number, animation_pause_seconds?: number, fps?: number }
  ) {
    if (config?.animation_seconds) this._animationSeconds = config.animation_seconds
    if (config?.animation_pause_seconds) this._animationPauseSeconds = config.animation_pause_seconds
    if (config?.fps) this._fps = config.fps
  }

  async initAnimation(): Promise<void> {
    let uuid = uuidv4()
    this.updateRoutes()
    let line_layers = await PAMapboxControl.Instance.generateLineLayers(
      this.routes,
      this.mapContainer,
      {
        use_exact_waypoints: true,
        prefix: uuid,
        color: this.getOverrideColor(),
        listeners: {
          on_mouse_over: this.onLayerMouseover.bind(this),
          on_mouse_out: this.onLayerMouseout.bind(this),
        }
      }
    )

    if (this.lastUUID) PAMapboxControl.Instance.deleteUUIDLayers(this.lastUUID, this.mapContainer)

    this.lastUUID = uuid
    this.lastLineLayers = line_layers
  }

  getOverrideColor(): string {
    return PAUtil.rgbaToString(PAUtil.getIndexColorRGBA(this.tour.technicianDate.getWeekdayIdx()))
  }

  async startAnimation(): Promise<void> {

    let uuid = this.lastUUID
    let line_layers = this.lastLineLayers

    if (line_layers.length && !this.running) {
      this.stopAnimation = false
      this.running = true
      const summed_tour_line_length = line_layers.reduce((sum, line_layer) => sum + line_layer.line_length, 0)

      let line_animation_seconds = this._animationSeconds * (line_layers[0].line_length / summed_tour_line_length)
      let total_frames = Math.floor(this._fps * line_animation_seconds)
      let ms_interval = 1000 / this._fps
      let frame_size = 1 / total_frames
      let current_frame = 1
      let frame_center = 0

      //console.log(`started animation for tour ${this.tour.technicianDate.technician.getFullName()} - ${this.tour.technicianDate.date}`)
      this.increaseLayerWidth(line_layers);
      this.moveLayersUp(line_layers)

      let current_layer: LineLayerContainer = line_layers[0]
      this.mapContainer.map.moveLayer(current_layer.layer.id)
      PAMapboxControl.Instance.moveCustomLabelLayersUp(this.mapContainer)

      let run_animation = true

      while (run_animation) {

        let is_in_selected_technician_week_tours = false
        let id = PATourPlannerControl.Instance.tourPlannerPlanningMode == 'technician' ? PATourPlannerControl.Instance.technicianToPlanID : null
        if (id) {
          let technician = PADataControl.Instance.getTechnician(id)
          is_in_selected_technician_week_tours = technician.getCurrentCalendarWeekTechnicianDates().includes(this.tour.technicianDate)
        }
        let color = is_in_selected_technician_week_tours ? PAUtil.rgbaToString(PAUtil.getIndexColorRGBA(this.tour.technicianDate.getWeekdayIdx())) : current_layer.color

        this.mapContainer.map.setPaintProperty(current_layer.layer.id, 'line-gradient', this.generateLinearGradientExpressionForCenter(frame_center, color, 'cyan', current_layer.line_length, summed_tour_line_length));
        if (current_frame > total_frames) {
          this.mapContainer.map.setPaintProperty(current_layer.layer.id, 'line-gradient', this.generateLinearGradientExpressionForColor(color));
          current_frame = 1
          frame_center = 0

          if (current_layer == line_layers[line_layers.length - 1]) await PAUtil.sleep(this._animationPauseSeconds * 1000)

          let current_layer_idx = line_layers.indexOf(current_layer)
          current_layer = current_layer_idx == line_layers.length - 1 ? line_layers[0] : line_layers[current_layer_idx + 1]

          line_animation_seconds = this._animationSeconds * (current_layer.line_length / summed_tour_line_length)
          total_frames = Math.floor(this._fps * line_animation_seconds)
          frame_size = 1 / total_frames

          if (this.mapContainer.map.getLayer(current_layer.layer.id)) {
            this.mapContainer.map.moveLayer(current_layer.layer.id)
            PAMapboxControl.Instance.moveCustomLabelLayersUp(this.mapContainer)
          }
        } else {
          current_frame = current_frame + 1
          frame_center = frame_center + frame_size
        }

        await PAUtil.sleep(ms_interval)

        let line_layers_were_removed = line_layers.filter(lineLayer => this.mapContainer.map.getLayer(lineLayer.layer.id)).length != line_layers.length
        let stop_animation = (this.lastUUID != uuid) || this.stopAnimation

        if (line_layers_were_removed || stop_animation) {
          run_animation = false

          this.resetLineLayerColors(line_layers, color);
          this.decreaseLayerWidth(line_layers);
        }
      }
      this.running = false
    }

  }

  public onLayerMouseover(ev: mapboxgl.MapMouseEvent & {
    features?: mapboxgl.MapboxGeoJSONFeature[] | undefined
  } & mapboxgl.EventData, id: string): void {
    if (!this._hoveredMapboxLayer) {
      ev.originalEvent.stopPropagation()
      this._hoveredMapboxLayer = id + '_layer'
      this.startAnimation()
    }
  }

  public onLayerMouseout(id: string): void {
    if (this._hoveredMapboxLayer == id + '_layer') {
      this._hoveredMapboxLayer = ''
      this.stopAnimation = true
    }
  }

  private resetLineLayerColors(line_layers: LineLayerContainer[], color: string) {
    line_layers.map(line_layer => {
      if (this.mapContainer.map.getLayer(line_layer.layer.id)) {
        try {
          this.mapContainer.map.setPaintProperty(line_layer.layer.id, 'line-gradient', this.generateLinearGradientExpressionForColor(color));
        } catch (err) {
          console.log(err)
        }
      }
    })
  }

  public moveLastTourLayersUp() {
    this.moveLayersUp(this.lastLineLayers)
  }

  private moveLayersUp(line_layers: LineLayerContainer[]) {
    for (let layer of line_layers) {
      if (this.mapContainer.map.getLayer(layer.layer.id)) {
        try {
          this.mapContainer.map.moveLayer(layer.layer.id)
        } catch (err) {
          console.log(err)
        }
      }
    }
    PAMapboxControl.Instance.moveCustomLabelLayersUp(this.mapContainer)
  }

  private increaseLayerWidth(line_layers: LineLayerContainer[]) {
    for (let layer of line_layers) {
      if (this.mapContainer.map.getLayer(layer.layer.id)) {
        try {
          this.mapContainer.map.setPaintProperty(layer.layer.id, 'line-width', layer.line_width + 3)
          this.mapContainer.map.setPaintProperty(layer.layer.id, 'line-opacity', 1)
        } catch (err) {
          console.log(err)
        }
      }
    }
  }

  private decreaseLayerWidth(line_layers: LineLayerContainer[]) {
    for (let layer of line_layers) {
      if (this.mapContainer.map.getLayer(layer.layer.id)) {
        try {
          this.mapContainer.map.setPaintProperty(layer.layer.id, 'line-width', layer.line_width)
          this.mapContainer.map.setPaintProperty(layer.layer.id, 'line-opacity', .75)
        } catch (err) {
          console.log(err)
        }
      }
    }
  }

  public deleteCurrentLayers(): void {
    PAMapboxControl.Instance.deleteUUIDLayers(this.lastUUID, this.mapContainer)
    //console.log(`${this.mapContainer.name}: deleting layers ${this.lastUUID}`)
  }

  updateRoutes(): void {
    this.routes = this.tour.toMapRoutes()
  }

  generateLinearGradientExpressionForCenter(center: number, base_color: string, contrast_color: string, line_length: number, total_tour_line_length: number): mapboxgl.Expression {

    center = Math.min(center, 1)

    let gradient_start: number
    let gradient_end: number

    let width = 0.03 / (line_length / total_tour_line_length)

    if (center + width > 1 && center - width < 0) {
      return this.generateLinearGradientExpressionForColor(base_color)
    }

    if (center < width) {
      gradient_start = 0
      gradient_end = center + width
    }

    if (width <= center && center < (1 - width)) {
      gradient_start = center - width
      gradient_end = center + width
    }

    if ((1 - width) <= center) {
      gradient_start = center - width
      gradient_end = 1
    }

    let gradient_array: (string | number) [] = []

    if (gradient_start != 0) {
      gradient_array.push(0)
      gradient_array.push(base_color)
    }

    if (gradient_start != center) {
      gradient_array.push(gradient_start)
      gradient_array.push(base_color)
    }

    gradient_array.push(center)
    gradient_array.push(contrast_color)

    if (center != gradient_end) {
      gradient_array.push(gradient_end)
      gradient_array.push(base_color)
    }

    if (gradient_end != 1) {
      gradient_array.push(1)
      gradient_array.push(base_color)
    }

    return [
      'interpolate',
      ['linear'],
      ['line-progress'],
      ...gradient_array
    ]
  }

  generateLinearGradientExpressionForColor(base_color: string): mapboxgl.Expression {

    let gradient_array: (string | number) [] = []

    gradient_array.push(0)
    gradient_array.push(base_color)

    gradient_array.push(1)
    gradient_array.push(base_color)

    return [
      'interpolate',
      ['linear'],
      ['line-progress'],
      ...gradient_array
    ]
  }

}

export interface LineLayerContainer {
  layer: mapboxgl.LineLayer,
  color: string,
  line_length: number,
  line_width: number
}
