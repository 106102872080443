import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { PAUtil } from '../classes/util';
import { PAOperation } from '../classes/operation';
import { PAPlanningInstructions } from '../classes/planning-instructions';
import { PAFilter } from '../classes/filter';
import { Subscription } from 'rxjs';
import { MatSlideToggleChange, MatSlideToggle } from '@angular/material/slide-toggle';
import {
  faArrowUp, faCar, faFilter, faFlag, faStar,
  faWindowClose,
  faWindowMaximize,
  faWindowMinimize,
  faWindowRestore
} from "@fortawesome/free-solid-svg-icons";
import { PATechnician } from "../classes/technician";
import { PAProject } from "../classes/project";
import { PATimeControl } from "../singletons/pa-time-control";
import { PATourPlannerControl } from "../singletons/pa-tourplanner-control";
import { PASettingsControl } from "../singletons/pa-settings-control";
import { PAFilterControl } from "../singletons/pa-filter-control";
import { TechnicianDateCollection } from "../classes/technician-date-collection";
import { ListsSimpleChange } from "../map/map.component";
import { NgFor, NgClass, NgIf } from '@angular/common';
import { TooltipModule } from '@cloudfactorydk/ng2-tooltip-directive';

import { TourMapComponent } from './tour-map/tour-map.component';
import { CommonModule } from "@angular/common";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { FaIconComponent, FontAwesomeModule } from '@fortawesome/angular-fontawesome';

interface MapSlot {
  technician_date_collection: TechnicianDateCollection;
  id: number;
  operations: PAOperation[];
  only_close_operations: boolean;
  only_time_fitting_operations: boolean;
  close_operation_distance: number;
  operation_projects_container: { project: PAProject, amount: number }[]
  quick_filter_projects: PAProject[];
  tour_selection_change_subscription: Subscription;
  selected_tour_route_change_subscription: Subscription;
  minimized: boolean;
  maximized: boolean;
  position?: { top: string, height: string, width: string, right: string, z_index: string }
}

@Component({
  selector: 'hawk-tour-comparison',
  templateUrl: './tour-comparison.component.html',
  styleUrls: ['./tour-comparison.component.scss', './../styles/common_styles.scss'],
  standalone: true,
  imports: [NgFor, NgClass, NgIf, TooltipModule, FaIconComponent, TourMapComponent, MatSlideToggle, CommonModule, MatSlideToggleModule, FontAwesomeModule]
})
export class TourComparisonComponent implements OnInit, OnChanges, OnDestroy {

  protected readonly faWindowMinimize = faWindowMinimize
  protected readonly faWindowMaximize = faWindowMaximize
  protected readonly faWindowClose = faWindowClose
  protected readonly faWindowRestore = faWindowRestore
  protected readonly faArrowUp = faArrowUp;

  mapSlots: MapSlot[] = []
  tourSlotHeaders: { technician: PATechnician, tour_slots: MapSlot[] }[] = []
  mainMapSlot: MapSlot
  distanceSubscription: Subscription

  @Input() technicianDateCollections: TechnicianDateCollection[] = []
  @Input() operations: PAOperation[] = []
  @Input() mapBoxAccessToken: string
  @Input() planningInstructions: PAPlanningInstructions
  @Input() filteredWeekdays = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday']

  constructor(
  ) {
  }

  async addSlot(tdc: TechnicianDateCollection): Promise<void> {
    let added = false

    if (!this.mapSlots.find(slot => slot.technician_date_collection?.technician == tdc.technician))  {
      for (let slot of this.mapSlots) {

        if (!slot.technician_date_collection) {
          slot.technician_date_collection = tdc
          slot.quick_filter_projects = []
          slot.operation_projects_container = []
          slot.tour_selection_change_subscription = tdc.subscribeToChanges(change => {
            if (change == 'selected_tour') {
              this.onSelectedTourChanged(slot)
            }
            /*if (change == 'changed_technician_dates') {
              this.updateTourSlot(slot)
            }*/
          })

          if (slot.technician_date_collection.selectedTour) {
            slot.selected_tour_route_change_subscription = slot.technician_date_collection.selectedTour.subscribeToChanges(
              _ => {
                this.updateTourSlot(slot)
              }
            )
          }

          this.updateTourSlot(slot)
          added = true
          if (!this.mainMapSlot) {
            this.selectMainTourSlot(slot)
          }
          break
        }
      }
      if (!added) {
        let slot: MapSlot = {
          technician_date_collection: tdc,
          id: this.mapSlots.length + 1,
          operations: [],
          only_close_operations: true,
          only_time_fitting_operations: true,
          close_operation_distance: PATourPlannerControl.Instance.selectedDistanceFilter,
          operation_projects_container: [],
          quick_filter_projects: [],
          tour_selection_change_subscription: tdc.subscribeToChanges(change => {
            if (change == 'selected_tour') {
              this.onSelectedTourChanged(slot)
            }
          }),
          selected_tour_route_change_subscription: null,
          minimized: false,
          maximized: false
        }

        if (slot.technician_date_collection.selectedTour) {
          slot.selected_tour_route_change_subscription = slot.technician_date_collection.selectedTour.subscribeToChanges(
            _ => {
              this.updateTourSlot(slot)
            }
          )
        }

        this.mapSlots.push(slot)
        this.updateTourSlot(slot)
        if (!this.mainMapSlot) {
          this.selectMainTourSlot(slot)
        }
      }
    }
  }

  onSelectedTourChanged(slot: MapSlot): void {
    if (slot.selected_tour_route_change_subscription) {
      slot.selected_tour_route_change_subscription.unsubscribe()
    }

    if (slot.technician_date_collection.selectedTour) {
      slot.selected_tour_route_change_subscription = slot.technician_date_collection.selectedTour.subscribeToChanges(
        _ => {
          this.updateTourSlot(slot)
        }
      )
    }

    this.updateTourSlot(slot)
  }

  clearSlot(tdc: TechnicianDateCollection): void {
    for (let slot of this.mapSlots) {
      if (slot.technician_date_collection == tdc) {
        slot.technician_date_collection = null
        slot.operations = []
        slot.tour_selection_change_subscription.unsubscribe()
        slot.tour_selection_change_subscription = null

        if (slot.selected_tour_route_change_subscription) {
          slot.selected_tour_route_change_subscription.unsubscribe()
          slot.selected_tour_route_change_subscription = null
        }
      }
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.asyncOnChange(changes)
  }

  async asyncOnChange(changes: SimpleChanges): Promise<void> {
    let tdc_changes = changes['technicianDateCollections'] ? new ListsSimpleChange<TechnicianDateCollection>(changes['technicianDateCollections']) : null
    let tdcs_changed = tdc_changes && tdc_changes.listValuesChanged()
    if (tdcs_changed) {
      let remove_tdcs = (tdc_changes.previousValue || []).filter(pt => !(tdc_changes.currentValue || []).find(ct => ct == pt))
      let add_tdcs = (tdc_changes.currentValue || []).filter(ct => !(tdc_changes.previousValue || []).find(pt => pt == ct))
      remove_tdcs.map(tdc => this.clearSlot(tdc))
      await this.addTechnicianDateCollectionsToSlots(add_tdcs)
    }

    let operation_changes = changes['operations']
    let prev_operations = operation_changes?.previousValue as PAOperation[]
    let curr_operations = operation_changes?.currentValue as PAOperation[]
    let operations_changed = operation_changes && !PAUtil.equalSets(new Set(prev_operations), new Set(curr_operations))
    if (operations_changed) {
      this.updateTourSlots()
    }
    this.updateTourSlotPositions()
  }

  async addTechnicianDateCollectionsToSlots(tdcs: TechnicianDateCollection[]): Promise<void> {
    for (let tdc of tdcs) {
      await this.addSlot(tdc)
    }
  }

  updateTourSlots(): void {
    for (let slot of this.mapSlots) {
      this.updateTourSlot(slot)
    }
  }

  updateTourSlotPositions(): void {
    let minimized_height = 30

    let tour_slot_container_elem = document.getElementById('tour_slot_container')
    if (tour_slot_container_elem) {

      let sorted_shown_tour_slots = this.mapSlots
        .filter(s => s.technician_date_collection)
        //.sort((s1, s2) => s1.technician_date.day.timestamp < s2.technician_date.day.timestamp ? -1 : 1)
      let main_tour_slot = sorted_shown_tour_slots.find(s => s == this.mainMapSlot && s.technician_date_collection && !s.minimized)
      this.mainMapSlot = main_tour_slot = main_tour_slot || sorted_shown_tour_slots.find(s => !s.minimized && s.technician_date_collection)

      let slots_except_main_slot = sorted_shown_tour_slots.filter(s => s != this.mainMapSlot)
      let minimized_slot_amount = slots_except_main_slot.filter(s => s.minimized).length
      let not_minimized_slot_amount = slots_except_main_slot.length - minimized_slot_amount

      let slot_height_percentage = 100 / not_minimized_slot_amount

      if (main_tour_slot) {
        main_tour_slot.position = {
          top: '0',
          height: '100%',
          right: sorted_shown_tour_slots.length > 1 && !main_tour_slot.maximized ? '50%' : '0',
          width: sorted_shown_tour_slots.length > 1 && !main_tour_slot.maximized ? '50%' : '100%',
          z_index: main_tour_slot.maximized ? '30' : '10'
        }
      }

      let offset_pixel = 0
      let offset_percent = 0
      for (let slot of slots_except_main_slot) {

        let top = `calc(${offset_percent}% + ${offset_pixel}px)`
        let height = slot.minimized ? `${minimized_height}px` : `calc(${slot_height_percentage}% - ${minimized_slot_amount * minimized_height / not_minimized_slot_amount}px)`
        slot.position = {
          top: slot.maximized ? '0' : top,
          height: slot.maximized ? '100%' : height,
          right: '0', width: main_tour_slot && !slot.maximized ? '50%' : '100%',
          z_index: slot.maximized ? '30' : '5'
        }

        if (slot.minimized) {
          offset_pixel += minimized_height
        } else {
          offset_pixel -= minimized_slot_amount * minimized_height / not_minimized_slot_amount
          offset_percent += slot_height_percentage
        }
      }
    }
  }

  updateTourSlot(tour_slot: MapSlot): void {
    this.getShownMapSlotOperations(tour_slot).then(
      operations => {
        tour_slot.operation_projects_container = [...new Set(operations.map(op => op.ticket.project))].map(p => {
          return {project: p, amount: operations.filter(op => op.ticket.project == p).length}
        })
        tour_slot.operations = operations.filter(op => !tour_slot.quick_filter_projects.includes(op.ticket.project))
        this.updateTourSlotPositions()
      }
    )
  }

  async getShownMapSlotOperations(slot: MapSlot): Promise<PAOperation[]> {
    if (slot.technician_date_collection) {

      const future_technician_dates = slot.technician_date_collection.technicianDates.filter(td => !td.isPastTechnicianDate())
      const futureTours = future_technician_dates.map(td => td.changedTourContainer.tour)

      if (futureTours.length && slot.technician_date_collection.showTourMode == 'new') {
        let doable_operations = this.operations.filter(op => op.isUnassigned() && PATourPlannerControl.Instance.planningInstructions.checkGeneralDoability(op, slot.technician_date_collection.technician))

        if (slot.technician_date_collection.selectedTour) {
          if (slot.only_time_fitting_operations) {
            let new_tour_time = slot.technician_date_collection.selectedTour.route.time_specific_data[PAFilterControl.Instance.selectedOperationTimeFilter].total_time
            let max_tour_time = slot.technician_date_collection.selectedTour.technicianDate.changedTourContainer.max_tour_minutes
            doable_operations = doable_operations.filter(o => o.calculateOperationTimeMilliseconds({use_technician: slot.technician_date_collection.technician}) / 60000 < max_tour_time - new_tour_time)
          }
          if (slot.only_close_operations) {
            doable_operations = await PAFilter.filterOperationsForDistanceToTour(doable_operations, slot.technician_date_collection.selectedTour, slot.close_operation_distance, 4)
          }
        }

        return doable_operations
      } else {
        return []
      }
    } else {
      return []
    }
  }

  toggleOnlyCloseOperations(tour_slot: MapSlot, event: MatSlideToggleChange): void {
    tour_slot.only_close_operations = event.checked
    this.updateTourSlot(tour_slot)
  }

  toggleTimeFittingOperations(tour_slot: MapSlot, event: MatSlideToggleChange): void {
    tour_slot.only_time_fitting_operations = event.checked
    this.updateTourSlot(tour_slot)
  }

  ngOnInit(): void {
    this.distanceSubscription = PATourPlannerControl.Instance.distanceChangeSubject.subscribe(_ => {
      for (let slot of this.mapSlots) {
        slot.close_operation_distance = PATourPlannerControl.Instance.selectedDistanceFilter
        this.updateTourSlot(slot)
      }
    })
  }

  selectMainTourSlot(slot: MapSlot): void {
    slot.minimized = false
    this.mainMapSlot = slot
    this.updateTourSlotPositions()
  }

  minimizeTourSlot(slot: MapSlot): void {
    slot.minimized = true
    slot.maximized = false
    this.updateTourSlotPositions()
  }

  unminimizeTourSlot(slot: MapSlot): void {
    slot.minimized = false
    this.updateTourSlotPositions()
  }

  maximizeTourSlot(slot: MapSlot): void {
    for (let slot of this.mapSlots) {
      slot.maximized = false
    }
    slot.minimized = false
    slot.maximized = true
    this.updateTourSlotPositions()
  }

  restoreTourSlot(slot: MapSlot): void {
    slot.maximized = false
    this.updateTourSlotPositions()
  }

  ngOnDestroy(): void {
    this.distanceSubscription.unsubscribe()
  }

  protected readonly faStar = faStar;
  protected readonly Util = PAUtil;
  protected readonly PAUtil = PAUtil;
  protected readonly faFlag = faFlag;

  toggleProjectQuickSelectForTourSlot(project: PAProject, tour_slot: MapSlot) {
    if (tour_slot.quick_filter_projects.includes(project)) {
      tour_slot.quick_filter_projects = tour_slot.quick_filter_projects.filter(p => p != project)
    } else {
      tour_slot.quick_filter_projects.push(project)
    }
    this.updateTourSlot(tour_slot)
  }

  protected readonly faFilter = faFilter;
  protected readonly PATourPlannerControl = PATourPlannerControl;
  protected readonly PATimeControl = PATimeControl;
  protected readonly PASettingsControl = PASettingsControl;
  protected readonly Math = Math;
  protected readonly faCar = faCar;
}