import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatSelectModule } from "@angular/material/select";

import { ChangeDetectorRef, Component, inject, OnInit } from '@angular/core'
import { AgGridAngular } from 'ag-grid-angular'
import { ColDef } from 'ag-grid-community'

import { Article, ArticleTemplate } from 'src/app/_models'
import { ArticleService } from 'src/app/_services'

import { AG_GRID_LOCALE_DE } from 'src/app/_helpers/aggrid.locale.de'
import { AgGridThemeService } from "src/app/_services/ag-grid-theme.service";

@Component({
  imports: [CommonModule, FormsModule, MatFormFieldModule, MatSelectModule, AgGridAngular],
    selector: 'hawk-article-search',
    templateUrl: './article-search.component.html',
    styleUrls: ['./article-search.component.scss'],
})
export class ArticleSearchComponent implements OnInit {
  public hawkTheme = inject(AgGridThemeService).getTheme();
  public articleTemplates: ArticleTemplate[] = []
  public articles: Article[] = []

  public locale = AG_GRID_LOCALE_DE

  public selectedArticleTemplateId: number[] = []
  public searchTerm: string = ''

  public options = { loader: true }
  public columns = [{ key: 'rma', title: "RMA", width: 50, sorting: true }]

  // Column Definitions: Defines the columns to be displayed.
  colDefs: ColDef[] = [
    { field: "rma", headerName: "RMA", filter: true },
    { field: "description", filter: true },
    { field: "bad_part", filter: true },
    { field: "product", filter: true },
    { field: "serial_number", filter: true }
  ]

  constructor(
    private articleService: ArticleService,
    private cdr: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.articleService.loadArticleTemplates().subscribe(
      (data) => {
        this.articleTemplates = data
      },
      (err) => {
        console.error(err)
      },
    )

  }

  public get articlesPresent(): boolean {
    if (!this.articles) {
      return false
    }

    if (this.articles.length === 0) {
      return false
    }

    return true
  }

  public changeSelectedTemplates(): void {
    console.log("changeSelectedTemplates", this.selectedArticleTemplateId)

    this.articleService.getArticlesByTemplateFilterless(this.selectedArticleTemplateId).subscribe(
      (data) => {
        console.log("data da")
        this.articles = data
        this.cdr.detectChanges()
      },
      (err) => {
        console.error(err)
      },
    )
  }
}