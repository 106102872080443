<div class="form-group" [formGroup]="formGroup">
    <mat-form-field class="col-6" appearance="outline">
        <mat-label>Company</mat-label>
        <input
        matInput
        type="text"
        placeholder="Company"
        [formControlName]="_prefix + 'address_company'"
        />
    </mat-form-field>
</div>
<div class="form-group row" [formGroup]="formGroup">
    <mat-form-field class="col-6" appearance="outline">
        <mat-label>Vorname</mat-label>
        <input
        matInput
        type="text"
        placeholder="Vorname"
        [formControlName]="_prefix + 'address_firstname'"
        />
    </mat-form-field>
    <mat-form-field class="col-6" appearance="outline">
        <mat-label>Nachname</mat-label>
        <input
        matInput
        type="text"
        placeholder="Nachname"
        [formControlName]="_prefix + 'address_lastname'"
        />
    </mat-form-field>
</div>
<div class="form-group row" [formGroup]="formGroup">
    <mat-form-field class="col-8" appearance="outline">
        <mat-label>Street</mat-label>
        <input
        matInput
        type="text"
        placeholder="Street"
        [formControlName]="_prefix + 'address_street'"
        />
    </mat-form-field>
    <mat-form-field class="col-4" appearance="outline">
        <mat-label>Street Nr</mat-label>
        <input
        matInput
        type="text"
        placeholder="Street Nr"
        [formControlName]="_prefix + 'address_street_no'"
        />
    </mat-form-field>
</div>
<div class="form-group row" [formGroup]="formGroup">
    <mat-form-field class="col-6" appearance="outline">
        <mat-label>Ort PLZ</mat-label>
        <input
        matInput
        type="text"
        placeholder="PLZ"
        [formControlName]="_prefix + 'address_zip'"
        />
    </mat-form-field>
    <mat-form-field class="col-6" appearance="outline">
        <mat-label>Ort</mat-label>
        <input
        matInput
        type="text"
        placeholder="Ort"
        [formControlName]="_prefix + 'address_city'"
        />
    </mat-form-field>
</div>
<div class="form-group my-1" [formGroup]="formGroup">
    <ng-select
    [items]="countries"
    [formControlName]="_prefix + 'address_country'"
    placeholder="Land"
    bindLabel="name"
    bindValue="id"
    [clearable]="false"
    >
        <ng-template ng-label-tmp let-item="item">
            {{ item.name }}
        </ng-template>
        <ng-template ng-option-tmp let-item="item" let-search="searchTermStatus">
            <div *ngIf="item.name.toLowerCase().includes(searchTerm.toLowerCase())">
                {{ item.name }}
            </div>
        </ng-template>
    </ng-select>
</div>
<div class="form-group" [formGroup]="formGroup">
    <mat-form-field class="col-6" appearance="outline">
        <mat-label>Staat</mat-label>
        <input
        matInput
        type="text"
        placeholder="Staat"
        [formControlName]="_prefix + 'address_state'"
        />
    </mat-form-field>
</div>