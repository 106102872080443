import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { PAOperation } from "../classes/operation";
import {
  faArrowDown,
  faArrowRight,
  faBookOpen,
  faMap,
  faPhone,
  faSave,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { PAProject } from "../classes/project";
import { PATechnician } from "../classes/technician";
import { OperationChanges } from "../../../_models/planning-assistant.interface";
import { ListsSimpleChange } from "../map/map.component";
import { DBOperationChange, PATechnicianDate } from "../classes/technician-date";
import { PAUtil } from "../classes/util";
import { PopoutWindowComponent } from "../reuseable-components/popout-window/popout-window.component";
import { PADataControl } from "../singletons/pa-data-control";
import { PATourPlannerControl } from "../singletons/pa-tourplanner-control";
import { CommonModule, NgClass, NgFor, NgIf } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { TooltipModule } from '@cloudfactorydk/ng2-tooltip-directive';
import { PopupModalComponent } from '../reuseable-components/popup-modal/popup-modal.component';
import { DatabaseConflictsComponent } from '../reuseable-components/database-conflicts/database-conflicts.component';
import { FaIconComponent, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ThrobberComponent } from "../../_shared/throbber/throbber.component";

@Component({
  selector: 'hawk-save-menu',
  templateUrl: './save-menu.component.html',
  styleUrls: ['./save-menu.component.scss', './../styles/common_styles.scss'],
  standalone: true,
  imports: [NgClass, ReactiveFormsModule, FormsModule, NgFor, FaIconComponent, NgIf, TooltipModule, PopupModalComponent, DatabaseConflictsComponent, ThrobberComponent, CommonModule, FontAwesomeModule]
})
export class SaveMenuComponent implements OnChanges {

  protected readonly Operation = PAOperation;
  protected readonly faTrash = faTrash;
  protected readonly faBookOpen = faBookOpen;
  protected readonly Project = PAProject;
  protected readonly Technician = PATechnician;
  protected readonly faPhone = faPhone;
  protected readonly faSave = faSave;

  @Input() externalWindow: PopoutWindowComponent
  @Input() operationChanges: OperationChanges[] = []
  @Input() lastChangeTimeStamp: number

  technicianDateOperationChanges: { technician_date: PATechnicianDate, operation_changes: OperationChanges[] }[] = []
  selectedTechnicianDates: PATechnicianDate[] = []

  ticketNoteText: string
  ticketNoteSourceType = 'email'
  ticketNoteSourceText: string
  ticketNoteShareInternal = false
  ticketNoteShareTechnician = false
  ticketNoteSharePartner = false
  ticketNoteShareCustomer = false
  ticketNoteShareBookkeeper = false

  operationChangeSaveProgress = false
  openDayOperationsAfterSave = true

  showTicketId = true
  showTicketNotesForChanges: OperationChanges = null;

  DBChanges: DBOperationChange[] = [];
  showDBChangeModal = false

  constructor(
  ) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    let operation_changes_changes = changes['operationChanges'] ? new ListsSimpleChange<OperationChanges>(changes['operationChanges']) : null
    if (operation_changes_changes?.listValuesChanged() || changes['lastChangeTimeStamp']) {
      this.updateChangedTechnicianDates()
    }
  }

  updateChangedTechnicianDates(): void {
    let technician_dates = [...new Set(this.operationChanges.map(change => change.operation_change_map.get(PAOperation.operationChangePlanningID).new_operation.getTechnicianDate()))]
    this.technicianDateOperationChanges = technician_dates.map(td => {
      let operation_changes = this.operationChanges.filter(change => change.operation_change_map.get(PAOperation.operationChangePlanningID).new_operation.getTechnicianDate() == td)
      return {technician_date: td, operation_changes: operation_changes}
    })
  }

  async saveOperationChange(change: OperationChanges): Promise<void> {
    let new_operation = change.operation_change_map.get(PAOperation.operationChangePlanningID).new_operation
    this.operationChangeSaveProgress = true
    await new_operation.saveOperationChange(PAOperation.operationChangePlanningID)

    this.operationChangeSaveProgress = false
    this.resetTicketNote()
  }

  async saveOperationChangeTicket(change: OperationChanges): Promise<void> {

    console.log(`Speichere Ticket ${change.base_operation.ticket.id}`)
    let ticket = change.base_operation.ticket
    this.operationChangeSaveProgress = true

    let ticket_hash = await ticket.save(change.base_operation.description)

    this.operationChangeSaveProgress = false

    if (ticket_hash) {
      await this.saveOperationChange(change)
    }
  }

  revertOperationChange(change: OperationChanges): void {
    change.operation_change_map.get(PAOperation.operationChangePlanningID).new_operation.revertOperationChange(PAOperation.operationChangePlanningID).then(
      changed_data=> {
        let changed_technician_dates = [...new Set(changed_data.changed_technician_dates)]
        let changed_stores = [...new Set(changed_data.changed_stores)]
        changed_technician_dates.map(td => td.tour.updateRoute())
        changed_stores.map(store => store.fireUpdateManually())
      }
    )
    this.resetTicketNote()
  }

  updateTicketNoteSourceType(event: Event): void {
    const input = event.target as HTMLInputElement
    this.ticketNoteSourceType = input.value
  }

  addCurrentTicketNoteToOperation(operation: PAOperation): void {
    operation.afterStorageTaskTicketNote = {
      feedback_incomplete: null,
      material_faulty: null,
      missing_signature: null,
      not_on_time: null,
      note: this.ticketNoteText,
      other_problem: null,
      other_problem_title: null,
      share_bookkeeper: this.ticketNoteShareBookkeeper,
      share_customer: this.ticketNoteShareCustomer,
      share_internal: this.ticketNoteShareInternal,
      share_partner: this.ticketNoteSharePartner,
      share_technician: this.ticketNoteShareTechnician,
      source_type: this.ticketNoteSourceType,
      source: this.ticketNoteSourceText,
      special_billing: null,
      special_condition: null,
      technician_id: operation.user_ids[0],
      ticket_id: operation.ticket.id,
      operation_id: operation.id,
      technician_name: PADataControl.Instance.getTechnician(operation.user_ids[0]).getFullName()
    }
  }

  deleteTicketNoteFromOperation(operation: PAOperation): void {
    operation.afterStorageTaskTicketNote = null
  }

  resetTicketNote(): void {
    this.ticketNoteText = ''
    this.ticketNoteSourceType = 'email'
    this.ticketNoteSourceText = ''
    this.ticketNoteShareInternal = false
    this.ticketNoteShareTechnician = false
    this.ticketNoteSharePartner = false
    this.ticketNoteShareCustomer = false
    this.ticketNoteShareBookkeeper = false
  }

  public nodeClass(b: boolean): string {
    return b === true ? 'noteType noteActive' : 'noteType'
  }

  toggleTechnicianDate(technician_date: PATechnicianDate) {
    if (this.selectedTechnicianDates.includes(technician_date)) {
      PAUtil.removeElementFromList<PATechnicianDate>(this.selectedTechnicianDates, technician_date)
    } else {
      this.selectedTechnicianDates.push(technician_date)
    }
  }

  toggleOperationChange(change: OperationChanges) {
    if (this.showTicketNotesForChanges == change) {
      this.showTicketNotesForChanges = null
    } else {
      this.showTicketNotesForChanges = change
      this.resetTicketNote()
    }
  }

  async saveTechnicianDateChanges(technician_date_changes: {
    technician_date: PATechnicianDate,
    operation_changes: OperationChanges[]
  }, options?: {$event?: MouseEvent, skip_check_db_changes?: boolean }) {
    if (options?.$event) options?.$event.stopPropagation()

    this.DBChanges = options?.skip_check_db_changes ? [] : await technician_date_changes.technician_date.checkCurrentTourForDBChanges()
    if (this.DBChanges.length > 0) {
      this.showDBChangeModal = true
    } else {
      for (let change of technician_date_changes.operation_changes) {
        if (change.base_operation.ticket.id < 0) {
          await this.saveOperationChangeTicket(change)
        } else {
          await this.saveOperationChange(change)
        }
      }

      if (this.openDayOperationsAfterSave && technician_date_changes.technician_date) {
        window.open(technician_date_changes.technician_date.hawk_link, '_blank')
      }
    }

  }

  protected readonly faArrowDown = faArrowDown;
  protected readonly faArrowRight = faArrowRight;
  protected readonly faMap = faMap;
  protected readonly PADataControl = PADataControl;
  protected readonly PATourPlannerControl = PATourPlannerControl;
}