
import { PAOperation } from "../classes/operation";
import { PATechnicianDate } from "../classes/technician-date";
import { PATour } from "../classes/tour";
import { MapRoute } from "../../../_models/planning-assistant.interface";
import { Subject } from "rxjs";
import { PAPlanningInstructions } from "../classes/planning-instructions";
import { PAFilterControl } from "./pa-filter-control";
import { PADataControl } from "./pa-data-control";
import { PATechnician } from "../classes/technician";
import { MatSnackBar } from "@angular/material/snack-bar";
import { TechnicianDateCollection } from "../classes/technician-date-collection";

export class PATourPlannerControl {

  private static _instance: PATourPlannerControl
  public snackBar: MatSnackBar;
  public static get Instance()
  {
    return this._instance || (this._instance = new this());
  }

  public tourPlannerExtraOperationCollection: PAOperation[] = []
  public tourPlannerInsertOperations: PAOperation[] = []
  public ticketToPlanID: number
  public operationToPlanID: number
  public technicianToPlanID: number
  private _tourPlannerOverviewTechnicianDateCollections: TechnicianDateCollection[] = []
  public selectedTourPlannerTours: PATour[] = []
  tourPlannerPlanningMode: 'operation' | 'technician' = 'operation'
  technicianDistanceType = 'street_distance'
  shownRoutes: MapRoute[] = []
  private _selectedDistanceFilter: number = 60
  distanceChangeSubject = new Subject<number>()
  planningInstructions = new PAPlanningInstructions()

  // Map Instances
  public shownMapOperations: PAOperation[] = []
  public shownMapTechnicians: PATechnician[] = []

  lastOperationChangeTimeStamp: number = 0
  lastMainRouteLocationChangeTimestamp: number = 0

  private constructor(
  ) {
  }

  get selectedDistanceFilter() {
    return this._selectedDistanceFilter
  }

  set selectedDistanceFilter(distance: number) {
    this._selectedDistanceFilter = distance
    this.distanceChangeSubject.next(distance)
  }

  get tourPlannerOverviewTechnicianDateCollections() {
    return this._tourPlannerOverviewTechnicianDateCollections
  }

  set tourPlannerOverviewTechnicianDateCollections(tdcs: TechnicianDateCollection[]) {
    const tdcs_before = this._tourPlannerOverviewTechnicianDateCollections;
    const removed_tcs = tdcs_before.filter(tdc => !tdcs.includes(tdc));
    removed_tcs.map(tcs => tcs.clear());

    this._tourPlannerOverviewTechnicianDateCollections = tdcs
    this.updateTechnicianToursInPlanningProcess()
    this.updateSelectedTourPlannerTours()
  }

  showTourOverview(tour: PATechnicianDate): void {

    let technician = tour.technician

    if (!(this.tourPlannerPlanningMode == 'operation' && this.operationToPlanID)){
      this.tourPlannerPlanningMode = 'technician'
      this.planningInstructions.resetPlanningInstructions()
    }

    switch (this.tourPlannerPlanningMode) {
      case ('operation'): {
        if (this.operationToPlanID) {
          if (!PAFilterControl.Instance.preferredCalendarTechnicians.includes(technician)){
            PAFilterControl.Instance.preferredCalendarTechnicians.push(technician)
          }
          if (!this.tourPlannerOverviewTechnicianDateCollections.find(tdc => tdc.technician == tour.technician)){
            const new_technician_date_collection = new TechnicianDateCollection(
              tour.technician
            )
            new_technician_date_collection.addTechnicianDatesForDayTimestamps([tour.day.utc_timestamp])
            this.tourPlannerOverviewTechnicianDateCollections = this.tourPlannerOverviewTechnicianDateCollections.concat([
              new_technician_date_collection
            ])
          }
        }
        break;
      }
      case ('technician'): {
        this.technicianToPlanID = tour.technician.id
        if (!this.tourPlannerOverviewTechnicianDateCollections.find(tdc => tdc.technician == tour.technician)){
          const new_technician_date_collection = new TechnicianDateCollection(
            tour.technician
          )
          new_technician_date_collection.addTechnicianDatesForDayTimestamps([tour.day.utc_timestamp])
          this.tourPlannerOverviewTechnicianDateCollections = this.tourPlannerOverviewTechnicianDateCollections.concat([
            new_technician_date_collection
          ])
        }
        break;
      }
      default: {
        break;
      }
    }
  }

  public planOperation(operation: PAOperation) {
    this.tourPlannerPlanningMode = 'operation'
    this.operationToPlanID = operation.id
    this.planningInstructions.resetPlanningInstructions()
  }

  public planTechnicianDate(td: PATechnicianDate): void {
    if (td) {
      this.tourPlannerPlanningMode = 'technician'
      this.planningInstructions.resetPlanningInstructions(true)
      this.technicianToPlanID = td.technician.id

      const new_technician_date_collection = new TechnicianDateCollection(
        td.technician
      )
      new_technician_date_collection.addTechnicianDatesForDayTimestamps([td.day.utc_timestamp])
      this.tourPlannerOverviewTechnicianDateCollections = this.tourPlannerOverviewTechnicianDateCollections.concat([
        new_technician_date_collection
      ])
      this.tourPlannerOverviewTechnicianDateCollections = [new_technician_date_collection]
      new_technician_date_collection.selectTourWithDayTimestamp(td.day.utc_timestamp)
    }
  }

  public addOperationsToTourPlanning(operations: PAOperation[]) {
    let add_operations: PAOperation[] = []
    for (let operation of operations) {
      let is_not_operation_to_plan =  this.ticketToPlanID != operation.ticket.id && this.operationToPlanID != operation.id
      if (!this.tourPlannerExtraOperationCollection.map(operation => operation.id).includes(operation.id) && is_not_operation_to_plan) {
        add_operations.push(operation)
      }
    }
    this.tourPlannerExtraOperationCollection = this.tourPlannerExtraOperationCollection.concat(add_operations)
  }

  public removeOperationsFromTourPlanning(operations: PAOperation[]) {
    let remove_operations: PAOperation[] = []

    let set_new_operation_to_plan = false

    for (let operation of operations) {
      let is_operation_to_plan = this.operationToPlanID == operation.id
      if (is_operation_to_plan) {
        set_new_operation_to_plan = true
      }
      if (this.tourPlannerExtraOperationCollection.map(operation => operation.id).includes(operation.id)) {
        remove_operations.push(operation)
      }
    }

    let new_extra_operations = this.tourPlannerExtraOperationCollection.filter(operation => !remove_operations.find(remove_operation => remove_operation.id == operation.id))
    this.operationToPlanID = set_new_operation_to_plan && new_extra_operations.length ? new_extra_operations.splice(0, 1)[0].id : null
    this.tourPlannerExtraOperationCollection = new_extra_operations
  }

  public planningMenuIsInPlanningProcess(): boolean {
    const operation_is_planned = this.tourPlannerPlanningMode == 'operation' && !!this.operationToPlanID
    const technician_is_planned = this.tourPlannerPlanningMode == 'technician' && !!this.technicianToPlanID
    return operation_is_planned || technician_is_planned
  }

  public getOperationInPlanningProcess(): PAOperation {
    if (this.tourPlannerPlanningMode == 'operation' && this.operationToPlanID) return PADataControl.Instance.getOperation(this.operationToPlanID)
  }

  public removeTechnicianDateCollectionFromOverview(technician_date_collection: TechnicianDateCollection): void {
    let possible_tdc = this.tourPlannerOverviewTechnicianDateCollections.find(tdc => tdc == technician_date_collection)
    if (possible_tdc) {
      possible_tdc.clear()
      this.tourPlannerOverviewTechnicianDateCollections = this.tourPlannerOverviewTechnicianDateCollections.filter(tdc => tdc != possible_tdc)
    }
  }

  updateTechnicianToursInPlanningProcess(): void {
    let technicians = [... new Set(this.tourPlannerOverviewTechnicianDateCollections.map(tdc => tdc.technician))]
    for (let technician of technicians) {
      technician.currentTourPlannings.technicianDatesInPlanning = this.tourPlannerOverviewTechnicianDateCollections.find(tour => tour.technician == technician).technicianDates
    }
  }

  updateSelectedTourPlannerTours(): void {
    this.selectedTourPlannerTours = this.tourPlannerOverviewTechnicianDateCollections.reduce((tours: PATour[], tdc) => tours.concat(tdc.getBaseTours()), [])
  }

  removeExtraOperationsIfNotUsed(operations: PAOperation[]) {
    this.tourPlannerExtraOperationCollection = this.tourPlannerExtraOperationCollection.filter(extra_op => {
      return !operations.find(op => op.id == extra_op.id) || (this.planningInstructions.operationConstraintMap.has(extra_op.id) && this.planningInstructions.operationConstraintMap.get(extra_op.id).length);
    })
  }

  getTechnicianDateCollectionForTechnician(technician: PATechnician): TechnicianDateCollection {
    return this._tourPlannerOverviewTechnicianDateCollections.find(tdc => tdc.technician == technician)
  }

  hasTechnicianDateCollectionForTechnicianWithTechnicianDateTimestamp(technician: PATechnician, ts: number): boolean {
    let possible_tdc = this._tourPlannerOverviewTechnicianDateCollections.find(tdc => tdc.technician == technician)
    if (possible_tdc) {
      return possible_tdc.hasTechnicianDateWithTimestamp(ts)
    } else {
      return false
    }
  }
}