<div class="ticketPage" *ngIf="ticket">
  <section class="main-content">
      <header class="columns">
          <section>
              <h2>Stammdaten</h2>
              <table class="ticketPageTable base">
                  <tr>
                      <th class="tableLable">HAWK ID</th>
                      <td>{{ticket.order_nr}}</td>
                  </tr>
                  <tr>
                      <th class="editable tableLable" (click)="editExtNr = !editExtNr">Externe Nr.</th>
                      <td *ngIf="!editExtNr">{{ticket.external_order_nr}}</td>
                      <td *ngIf="editExtNr"><hawk-shared-edit-input [textValue]="ticket.external_order_nr" (saveClicked)="saveExtNr($event)" (cancelClicked)="editExtNr = false"></hawk-shared-edit-input></td>
                  </tr>
                  <tr>
                      <th class="editable tableLable" (click)="editProject = !editProject">Projekt</th>
                      <td *ngIf="!editProject"><a href="/project/{{ticket.project_id}}/show" target="_blank">{{ticket.project_name}}</a></td>
                      <td *ngIf="editProject"><hawk-shared-edit-input [selectValue]="ticket.project_id" [selectOptions]="metaData.projects" [modus]="'select'" (saveClicked)="saveProject($event)" (cancelClicked)="editProject = false"></hawk-shared-edit-input></td>
                  </tr>
                  <tr>
                      <th>Erstellt</th>
                      <td>{{ticket.date_created | date: 'dd.MM.yy HH:mm'}}</td>
                  </tr>
                  <tr>
                      <th class="editable tableLable" (click)="editDateSla = !editDateSla">SLA Kunde</th>
                      <td *ngIf="!editDateSla">{{ticket.datesla | date: 'dd.MM.yy HH:mm'}}</td>
                      <td *ngIf="editDateSla"><hawk-shared-edit-input [textValue]="ticket.datesla" [modus]="'date'" (saveClicked)="saveDateSla($event)" (cancelClicked)="editDateSla = false"></hawk-shared-edit-input></td>
                  </tr>
                  <tr>
                      <th>SLA berechnet</th>
                      <td>??</td>
                  </tr>
              </table>
          </section>
          <section>
              <h2>Adresse</h2>
              <table class="ticketPageTable base">
                  <tr>
                      <th class="editable" (click)="showModal = 'store'">Store</th>
                      <td><a href="https://hawk.bentomax.de/admin/store/{{ticket.store_id}}/show" target="_blank">{{ticket.store_nr}}</a></td>
                  </tr>
                  <tr>
                      <th [class.editable]="!ticket.store_id" (click)="editAddrCompany = (ticket.store_id ? false : !editAddrCompany)">Firma</th>
                      <td *ngIf="!editAddrCompany">{{ticket.address_company}}</td>
                      <td *ngIf="editAddrCompany"><hawk-shared-edit-input [textValue]="ticket.address_company" (saveClicked)="metaUpdate('address_company',$event); editAddrCompany = false" (cancelClicked)="editAddrCompany = false"></hawk-shared-edit-input></td>
                  </tr>
                  <tr>
                      <th [class.editable]="!ticket.store_id" (click)="editAddrName = (ticket.store_id ? false : !editAddrName)">Name</th>
                      <td *ngIf="!editAddrName">{{ticket.address_firstname}} {{ticket.address_lastname}}</td>
                      <td *ngIf="editAddrName">
                        <hawk-shared-edit-input [textValue]="ticket.address_firstname" (saveClicked)="metaUpdate('address_firstname',$event); editAddrName = false" (cancelClicked)="editAddrName = false"></hawk-shared-edit-input><br>
                        <hawk-shared-edit-input [textValue]="ticket.address_lastname" (saveClicked)="metaUpdate('address_lastname',$event); editAddrName = false" (cancelClicked)="editAddrName = false"></hawk-shared-edit-input>
                      </td>
                  </tr>
                  <tr>
                      <th [class.editable]="!ticket.store_id" (click)="editAddrStreet = (ticket.store_id ? false : !editAddrStreet)">Straße</th>
                      <td *ngIf="!editAddrStreet">{{ticket.address_street}} {{ticket.address_street_no}}</td>
                      <td *ngIf="editAddrStreet">
                        <hawk-shared-edit-input [textValue]="ticket.address_street" (saveClicked)="metaUpdate('address_street',$event); editAddrStreet = false" (cancelClicked)="editAddrStreet = false"></hawk-shared-edit-input>
                        <hawk-shared-edit-input [textValue]="ticket.address_street_no" (saveClicked)="metaUpdate('address_street_no',$event); editAddrStreet = false" (cancelClicked)="editAddrStreet = false"></hawk-shared-edit-input>
                      </td>
                  </tr>
                  <tr>
                      <th [class.editable]="!ticket.store_id" (click)="editAddrZipCity = (ticket.store_id ? false : !editAddrZipCity)">Ort</th>
                      <td *ngIf="!editAddrZipCity">{{ticket.address_zip}} {{ticket.address_city}}</td>
                      <td *ngIf="editAddrZipCity">
                        <hawk-shared-edit-input [textValue]="ticket.address_zip" (saveClicked)="metaUpdate('address_zip',$event); editAddrZipCity = false" (cancelClicked)="editAddrZipCity = false"></hawk-shared-edit-input><br>
                        <hawk-shared-edit-input [textValue]="ticket.address_city" (saveClicked)="metaUpdate('address_city',$event); editAddrZipCity = false" (cancelClicked)="editAddrZipCity = false"></hawk-shared-edit-input>
                      </td>
                  </tr>
                  <tr>
                      <th [class.editable]="!ticket.store_id" (click)="editAddrCountry = (ticket.store_id ? false : !editAddrCountry)">Land</th>
                      <td *ngIf="!editAddrCountry">{{ticket.address_country}}</td>
                      <td *ngIf="editAddrCountry">
                        <hawk-shared-edit-input [textValue]="ticket.address_country" (saveClicked)="metaUpdate('address_country',$event); editAddrCountry = false" (cancelClicked)="editAddrCountry = false"></hawk-shared-edit-input>
                      </td>
                  </tr>
                  <tr>
                      <th [class.editable]="false">Telefon</th>
                      <td *ngIf="!editStoreContact">
                        <ng-container *ngFor="let nr of ticket.store_contact_nr">
                          <a href="tel://{{nr}}">{{nr}}</a>
                        </ng-container>

                        <ng-container *ngIf="!ticket.store_contact_nr">
                          -
                        </ng-container>
                      </td>
                  </tr>
              </table>
          </section>
          <section>
              <h2 (click)="editContact()">Kontakt</h2>
              <table class="ticketPageTable base">
                  <tr>
                      <th class="editable" (click)="editContact()">Ansprechpartner</th>
                      <td>{{ticket.contact_name}}</td>
                  </tr>
                  <tr>
                      <th class="editable" (click)="editContact()">E-Mail</th>
                      <td><a href="mailto://{{ticket.contact_email}}">{{ticket.contact_email}}</a></td>
                  </tr>
                  <tr>
                      <th class="editable" (click)="editContact()">Telefon</th>
                      <td><a href="tel://{{ticket.contact_phone}}">{{ticket.contact_phone}}</a></td>
                  </tr>
              </table>
          </section>
      </header>

      <span id="storeSubbox"></span>
      <hawk-store-subbox
        [ticket]="ticket"
        [operation]="selectedOperation"
        [selectedStep]="selectedStep"
        [resetStoreUnit]="resetStoreUnit.asObservable()"
        *ngIf="selectedStep">
      </hawk-store-subbox>

      <section>
          <h2 (click)="editDescription = !editDescription">Fehlerbeschreibung</h2>
          <p class="description" [innerHtml]="convertLinebreaks(ticket.description, descriptionLinebreaks)" (click)="toggleDescriptionLinebreaks()" *ngIf="!editDescription"></p>
          <p class="description" *ngIf="editDescription">
            <hawk-shared-edit-input [textValue]="ticket.description" [modus]="'textarea'"
                                    (saveClicked)="saveDescription($event)" (cancelClicked)="editDescription = false"></hawk-shared-edit-input>
          </p>
      </section>
      <section class="tabs">
          <div class="tablist" role="tablist">
              <a href="/operation/{{ticket.id}}/create" class="add"><strong>Neuer Einsatz</strong></a>
              <ng-container *ngFor="let operation of operations">
                <button role="tab" class="tab" [attr.aria-selected]="operationActive(operation)" (click)="selectOperation(operation)">{{operation.tech_short}} {{operation.operation_date | date: 'dd.MM.yyyy HH:mm'}} <fa-icon [icon]="['far','check']" *ngIf="operation.status == 'DONE'"></fa-icon></button>
              </ng-container>
          </div>

          <ng-container *ngFor="let operation of operations">
            <hawk-operation-subpage
              [operation]="operation"
              [technicians]="technicians"
              [articleTemplates]="articleTemplates"
              [articleSummary]="articleSummary"
              [ticket]="ticket"
              [isBookkeeper]="isBookkeeper"
              [canReject]="canReject()"
              (selectStep)="selectStep($event)"
              (reloadTicket)="handleReloadTicket($event)"
              *ngIf="operationActive(operation)"></hawk-operation-subpage>
          </ng-container>
      </section>
  </section>
  <aside>
      <section>
          <h3>Karte</h3>
          <mgl-map [style]="'mapbox://styles/mapbox/streets-v9'" [zoom]="[11]" [center]="position">
            <div *ngFor="let marker of markerData">
                <mgl-marker *ngIf="marker.lat && marker.lng" [lngLat]="[marker.lng, marker.lat]">
                    <div class="marker" [style.borderColor]="'black'" [style.backgroundColor]="marker.color" title="{{marker.text}}"></div>
                </mgl-marker>
            </div>
          </mgl-map>
      </section>
      <div class="columns">
          <section>
              <h3>Aktionen</h3>
              <table class="ticketPageTable" *ngIf="metaData">
                  <tr>
                      <th class="editable" (click)="showModal = 'status'">Status</th>
                      <td>{{metaData.status.name}}</td>
                  </tr>
                  <tr>
                      <th class="editable" (click)="showModal = 'prio'">Priorität</th>
                      <td>{{metaData.priority.name}}</td>
                  </tr>
                  <tr>
                      <th class="editable" (click)="showModal = 'appointment'">Terminvereinbarung</th>
                      <td>{{ticket.appointment_date | date:'dd.MM.yyyy HH:mm'}}<br><span *ngIf="ticket.appointment_contact">({{ticket.appointment_contact}})</span></td>
                  </tr>
                  <tr>
                      <th class="editable" (click)="showModal = 'time_estimation'">Kalkulierte Arbeitszeit</th>
                      <td>{{ticket.time_estimation}} min</td>
                  </tr>
                  <tr>
                      <th>Abrechnungsbesonderheiten</th>
                      <td>{{metaData.special_billing ? 'ja' : 'nein'}}</td>
                  </tr>
                  <tr>
                      <th>JOB</th>
                      <td>{{metaData.job_active ? 'ja' : 'nein'}}</td>
                  </tr>
                  <tr>
                      <th>Dokumentationen</th>
                      <td><a href="{{metaData.additional_documentation}}">Link</a></td>
                  </tr>
              </table>
          </section>

          <section>
              <h3>Navigation</h3>
              <nav>
                  <ul>
                      <li><a href="/a/planning_assistant?ticket_id={{ticket.id}}">Planungsassistent</a></li>
                      <li><a href="/operation/{{ticket.id}}/planningAssistant" target="_blank">Planungsassistent (alt)</a></li>
                      <li><a href="/ticket/{{ticket.id}}/map" target="_blank">Technikerkarte</a></li>
                      <li><a href="/ticket/{{ticket.id}}/show" target="_blank">Ticketseite (alt)</a></li>
                      <li *ngIf="technicianWarehousePresent()"><a [routerLink]="['/', 'lager', mainTechnicianWarehouse()]" target="_blank">Materialbestand Techniker</a></li>
                      <li *ngIf="technicianPresent()"><a [routerLink]="['/', 'ticket', 'suche']" [queryParams]="{operation_date_from: operationDate() | date: 'dd.MM.yyyy', operation_date_to: operationDate() | date: 'dd.MM.yyyy', technician_ids: mainTechnician().id}" target="_blank">Tagestickets Techniker</a><br></li>

                      <li style="margin-top: 15px; cursor: crosshair;" (click)="showModal = 'delete'">etwas löschen <fa-icon [icon]="['far','trash']"></fa-icon></li>
                  </ul>
              </nav>
          </section>

        </div>
        <hawk-note-subpage [operation]="selectedOperation" [ticketId]="ticket.id"></hawk-note-subpage>
  </aside>
</div>

<div class="modal is-active has-toolbar" role="dialog" aria-modal="true" *ngIf="showModal === 'contact'">
  <div class="modal-background"></div>
  <div class="modal-content">
    <div class="wrapper">
      <h3>Kontaktdaten</h3>
      <form [formGroup]="ticketContactForm">
        <table class="ticketPageTable base">
            <tr>
                <th>Ansprechpartner</th>
                <td><input type="text" formControlName="contact_name" /></td>
            </tr>
            <tr>
                <th >E-Mail</th>
                <td><input type="text" formControlName="contact_email" /></td>
            </tr>
            <tr>
                <th>Telefon</th>
                <td><input type="text" formControlName="contact_phone" /></td>
            </tr>
        </table>
      </form>
    </div>
    <div class="modal-toolbar">
      <div class="modal-toolbar-container-left">
      </div>
      <div class="modal-toolbar-container-right">
        <button class="button button-secondary" (click)="showModal = ''">Abbruch</button>
        <button class="button" (click)="saveContact()">ändern</button>
      </div>
    </div>
  </div>
  <button class="modal-close" aria-label="close" (click)="showModal = ''"></button>
</div>

<div class="modal is-active has-toolbar" role="dialog" aria-modal="true" *ngIf="showModal === 'status'">
  <div class="modal-background"></div>
  <div class="modal-content">
    <div class="wrapper">
      <form [formGroup]="statusForm">
        <table class="ticketPageTable base">
            <tr>
              <th class="editable">Status</th>
              <td>
                <select class="selectbox" formControlName="status_id">
                  @for (st of metaData.status_list; track st.id) {
                    <option [value]="st.id" [disabled]="disableMtStatus(st)">
                      {{st.name}}
                    </option>
                  }
                </select>
              </td>
            </tr>
        </table>
      </form>
    </div>
    <div class="modal-toolbar">
      <div class="modal-toolbar-container-left">
      </div>
      <div class="modal-toolbar-container-right">
        <button class="button button-secondary" (click)="closeModal()">Abbruch</button>
        <button class="button" (click)="saveStatus()">ändern</button>
      </div>
    </div>
  </div>
  <button class="modal-close" aria-label="close" (click)="closeModal()"></button>
</div>

<div class="modal is-active has-toolbar" role="dialog" aria-modal="true" *ngIf="showModal === 'prio'">
  <div class="modal-background"></div>
  <div class="modal-content">
    <div class="wrapper">
      <form [formGroup]="prioForm">
        <table class="ticketPageTable base">
            <tr>
              <th class="editable">Priorität</th>
              <td>
                <select class="selectbox" formControlName="priority_id">
                  <option [value]="prio.id" [selected]="prio.id === metaData.priority.id" *ngFor="let prio of metaData.priority_list">
                    {{prio.name}}
                  </option>
                </select>
              </td>
            </tr>
        </table>
      </form>
    </div>
    <div class="modal-toolbar">
      <div class="modal-toolbar-container-left">
      </div>
      <div class="modal-toolbar-container-right">
        <button class="button button-secondary" (click)="closeModal()">Abbruch</button>
        <button class="button" (click)="savePrio()">ändern</button>
      </div>
    </div>
  </div>
  <button class="modal-close" aria-label="close" (click)="closeModal()"></button>
</div>

<div class="modal is-active has-toolbar" role="dialog" aria-modal="true" *ngIf="showModal === 'time_estimation'">
  <div class="modal-background"></div>
  <div class="modal-content">
    <div class="wrapper">
      <form [formGroup]="metaTimeForm">
        <table class="ticketPageTable base">
            <tr>
              <th class="editable">Kalkulierte Arbeitszeit</th>
              <td>
                <input type="number" formControlName="time_estimation" />
              </td>
            </tr>
        </table>
      </form>
    </div>
    <div class="modal-toolbar">
      <div class="modal-toolbar-container-left">
      </div>
      <div class="modal-toolbar-container-right">
        <button class="button button-secondary" (click)="closeModal()">Abbruch</button>
        <button class="button" (click)="saveTimeEstimation()">ändern</button>
      </div>
    </div>
  </div>
  <button class="modal-close" aria-label="close" (click)="closeModal()"></button>
</div>

<div class="modal is-active has-toolbar" role="dialog" aria-modal="true" *ngIf="showModal === 'appointment'">
  <div class="modal-background"></div>
  <div class="modal-content">
    <div class="wrapper">
      <h3>Kundentermin bearbeiten      </h3>
      <form [formGroup]="appointmentForm">
        <table class="ticketPageTable base">
            <tr>
                <th>Kontaktperson</th>
                <td><input type="text" formControlName="appointment_contact" /></td>
            </tr>
            <tr>
                <th>Terminvereinbarung</th>
                <td>
                    <input
                      type="text"
                      mwlFlatpickr
                      formControlName="appointment_date"
                      [locale]="'de'"
                      [options]="datePickerConfig"
                      [convertModelValue]="true">

                </td>
            </tr>
        </table>
      </form>
    </div>
    <div class="modal-toolbar">
      <div class="modal-toolbar-container-left">
      </div>
      <div class="modal-toolbar-container-right">
        <button class="button button-secondary" (click)="showModal = ''">Abbruch</button>
        <button class="button" (click)="saveAppointment()">ändern</button>
      </div>
    </div>
  </div>
  <button class="modal-close" aria-label="close" (click)="showModal = ''"></button>
</div>

<div class="modal is-active has-toolbar" role="dialog" aria-modal="true" *ngIf="showModal === 'store'">
  <div class="modal-background"></div>
  <div class="modal-content">
    <div class="wrapper">
      <div class="storeSelectHeader">
        <h3>Store auswählen</h3>
        <div></div>
        <input type="text" [(ngModel)]="storeSearchboxValue" placeholder="Store suchen">
      </div>
      <table class="storeSelectTable base">
          <tr>
              <th>Nr</th>
              <th>Name</th>
              <th>Adresse</th>
          </tr>
          <tr *ngFor="let store of metaData.project_stores | storeFilter:storeSearchboxValue | slice:0:25" (click)="saveStore(store.id)">
              <td>{{store.nr}} <fa-icon [icon]="['far','check']" *ngIf="ticket.store_id==store.id"></fa-icon></td>
              <td>{{store.name}}</td>
              <td>
                {{store.name}}, {{store.address_firstname}} {{store.address_lastname}}<br>
{{store.address_street}}, {{store.address_zip}} {{store.address_city}}, {{store.address_country}}
              </td>

          </tr>
      </table>
    </div>
    <div class="modal-toolbar">
      <div class="modal-toolbar-container-left">
      </div>
      <div class="modal-toolbar-container-right">
        <button class="button button-secondary" (click)="showModal = ''">Abbruch</button>
        <button class="button" (click)="removeStore()">Store zurücksetzen</button>
      </div>
    </div>
  </div>
  <button class="modal-close" aria-label="close" (click)="showModal = ''"></button>
</div>

<div class="modal is-active has-toolbar" role="dialog" aria-modal="true" *ngIf="showModal === 'delete'">
  <div class="modal-background"></div>
  <div class="modal-content">
    <div class="wrapper">
      <h3>Löschen!</h3>
      <div>Die hier angezeigten Operationen sind <b>destruktiv</b>, sie können nicht rückgängig gemacht werden.
        Bevor der Link unten geklickt wird, sollte man sich <b>sehr</b> sicher sein, dass man wirklich löschen möchte.</div>

      <div style="margin-top: 15px;">
        Ticket löschen<br>
        <p>
          <button class="button button-light-text"
                  [class.button-attention]="ticketDeletable()"
                  [class.button-disabled]="!ticketDeletable()"
                  (click)="deleteTicket()"
                  [disabled]="!ticketDeletable()">
                  Ich möchte das Ticket {{ticket.order_nr}} ({{ticket.id}}) löschen
          </button>
          <small *ngIf="!ticketDeletable()"><br>Kann nicht gelöscht werden, weil Ticket Einsätze besitzt oder aus anderem Grund gesperrt ist.</small>
        </p>
      </div>

      @if (operations && operations.length > 0) {
        <div style="margin-top: 15px;">
          <br><br>
          Einsatz löschen<br>
          <p *ngFor="let operation of operations">
            <button class="button"
                    [class.button-danger]="operationDeletable(operation)"
                    [class.button-disabled]="!operationDeletable(operation)"
                    (click)="deleteOperation(operation)"
                    [disabled]="!operationDeletable(operation)">
                    Ich möchte den Einsatz {{operation.tech_short}} {{operation.operation_date | date: 'dd.MM.yyyy HH:mm'}} ({{operation.id}}) löschen
            </button>
            <small *ngIf="!operationDeletable(operation)"><br>Kann nicht gelöscht werden, weil Einsatz schon bearbeitet wurde.</small>
          </p>
        </div>
      }

      <div style="margin-top: 50px;">
        <b>Ticket und alle Einsätze löschen, ohne Prüfung</b><br>

        @if (ticket.is_mt) {
          <p>Vorsicht MT-Ticket. Bitte besondere Vorsicht walten lassen und prüfen, ob schon Rückmeldungen an MT geschickt wurden!</p>

          <p>Wurden Rückmeldungen gesendet, muss sehr aufgepasst werden!</p>
        }
        @if (operations && operations.length > 0) {
          @for (operation of operations; track operation.id) {
            <p>Nur Einsatz {{ operation.id }} löschen</p>

            <button class="button"
              [class.button-danger]="true"
              (click)="dangerousDeleteOperation(operation.id)">
              Löschen ohne Prüfung (Einsatz {{ operation.id }})
            </button>
          }
          <br><br>
        }

        @if (ticket.delivery_notes && ticket.delivery_notes.length > 0) {
          Es sind Lieferscheine diesem Ticket zugewiesen. Beim Löschen wird die Zuordnung aufgelöst, aber der Lieferschein bleibt bestehen.

          @for (deliveryNote of ticket.delivery_notes; track deliveryNote.id) {
            <p>
              <a [routerLink]="['/', 'lieferscheine', deliveryNote.id]" target="_new"><fa-icon [icon]="['far','truck']"></fa-icon> {{ deliveryNote.number}}</a>
            </p>
          }

        }

        <p>Ticket und Einsätze</p>
          <button class="button"
                  [class.button-danger]="true"
                  (click)="dangerousDelete()">
                  Löschen ohne Prüfung ist gefährlich. Ich bin mir sicher was ich mache.
          </button>
      </div>
    </div>
    <div class="modal-toolbar">
      <div class="modal-toolbar-container-left">
      </div>
      <div class="modal-toolbar-container-right">
        <button class="button button-secondary" (click)="showModal = ''">Abbruch</button>
      </div>
    </div>
  </div>
  <button class="modal-close" aria-label="close" (click)="showModal = ''"></button>
</div>
<!-- Modal with sample content and everything activated -->
<!-- <div class="modal is-active has-toolbar" role="dialog" aria-modal="true">
  <div class="modal-background"></div>
  <div class="modal-content">
    <div class="wrapper">
      <h3>Aktionen</h3>
      <table class="ticketPageTable base">
          <tr>
              <th class="editable">Status</th>
              <td>3.0 geplant</td>
          </tr>
          <tr>
              <th class="editable">Priorität</th>
              <td>NBD</td>
          </tr>
          <tr>
              <th class="editable">Terminvereinbarung</th>
              <td></td>
          </tr>
          <tr>
              <th class="editable">Aufgaben</th>
              <td></td>
          </tr>
          <tr>
              <th class="editable">Abrechnungsbesonderheiten</th>
              <td>vorhanden</td>
          </tr>
          <tr>
              <th>JOB</th>
              <td>aktiv</td>
          </tr>
          <tr>
              <th>Dokumentationen</th>
              <td><a href="#">Sharepoint-Link</a></td>
          </tr>
      </table>
    </div>
    <div class="modal-toolbar">
      <div class="modal-toolbar-container-left">
        <button class="button button-attention">Attention</button>
      </div>
      <div class="modal-toolbar-container-right">
        <button class="button button-secondary">Secondary</button>
        <button class="button">Primary</button>
      </div>
    </div>
  </div>
  <button class="modal-close" aria-label="close"></button>
</div> -->

<!-- Modal without content, without toolbar and deactivated -->
<!-- <div class="modal" role="dialog" aria-modal="true">
  <div class="modal-background"></div>
  <div class="modal-content">
    <div class="wrapper">
    </div>
  </div>
  <button class="modal-close" aria-label="close"></button>
</div> -->

<!-- Modal without content, with toolbar and deactivated -->
<!-- <div class="modal has-toolbar" role="dialog" aria-modal="true">
  <div class="modal-background"></div>
  <div class="modal-content">
    <div class="wrapper">
    </div>
    <div class="modal-toolbar">
      <div class="modal-toolbar-container-left">
        <button class="button button-attention">Attention</button>
      </div>
      <div class="modal-toolbar-container-right">
        <button class="button button-secondary">Secondary</button>
        <button class="button">Primary</button>
      </div>
    </div>
  </div>
  <button class="modal-close" aria-label="close"></button>
</div> -->

