import { formatDate } from "@angular/common";

export function dateFormatter(format = "dd.MM.YYYY HH:mm") {
  return (params: { value: string }) => {
    if (params.value) {
      return formatDate(params.value, format, 'de-DE')
    }
    return null
  }
}

export function prioRenderer() {
  return (params: { value: string }) => '<i class="fal fa-circle fa-stack-2x" style = "color: ' + params.value + '; font-size: 2.1em" > </i>'
}