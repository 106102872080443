
<div id="tour_slot_container" class="black_gradient_background_no_hover" style="display: inline-flex; flex-direction: row; height: 100%; flex:1; flex-wrap: wrap; overflow-y: auto; overflow-x: hidden; position: relative;">
  <div *ngFor="let map_slot of mapSlots"
       [id]="'tour_slot_' + map_slot.id"
       class="tour_map_container"
       [ngClass]="map_slot.technician_date_collection ? '' : 'hidden' + (mainMapSlot == map_slot ? ' main' : '')"
       [style.width]="map_slot.position?.width || '0'"
       [style.right]="map_slot.position?.right || '0'"
       [style.top]="map_slot.position?.top || '0'"
       [style.height]="map_slot.position?.height || '0'"
       [style.z-index]="map_slot.position?.z_index || '0'"
  >
    <div style="display: inline-flex; flex-direction: column; width: 100%;" [style.border]="map_slot.technician_date_collection ? '2px solid ' + Util.rgbaToString(map_slot.technician_date_collection.technician.color) : 'none'">
      <div class="control_container" *ngIf="map_slot.technician_date_collection" (click)="$event.stopPropagation()">
        <div style="flex: 1; margin-top: auto; margin-bottom: auto; display: inline-flex; flex-direction: row;">
          <div style="margin-top: auto; margin-bottom: auto; margin-left: 4px;white-space: nowrap; font-size: 14px;">
            {{map_slot.technician_date_collection.technician.getFullName(20)}}
          </div>
          <div class="horizontal_flex" style="margin: auto 4px;">
            <div class="black_gradient_background_no_hover horizontal_flex"
                 style="margin: auto; border: 1px solid black; font-size: .75rem; padding: 1px 2px; border-radius: 2px; cursor: pointer;"
                 [tooltip]="'Durchschnittlicher Fahrtzeitfaktor für ' + map_slot.technician_date_collection.technician.getFullName()"
            >
              <fa-icon [icon]="faCar"></fa-icon>
              <div style="margin: auto 2px;">
                <b>&#10005;</b>
              </div>
              <div>
                <b>{{Math.round(map_slot.technician_date_collection.technician.driving_time_factor * 100) / 100}}</b>
              </div>
            </div>
          </div>
        </div>
        <div class="control"
             [ngClass]="map_slot == mainMapSlot ? 'black_gradient_background_no_hover' : 'black_gradient_background'"
             style="border-right: 2px solid gray;"
             [style.color]="map_slot == mainMapSlot ? 'yellow' : 'gray'"
             (click)="selectMainTourSlot(map_slot)"
        ><fa-icon [icon]="faStar"></fa-icon>
        </div>
        <div *ngIf="!map_slot.minimized" class="control black_gradient_background" (click)="minimizeTourSlot(map_slot)"><fa-icon [icon]="faWindowMinimize"></fa-icon></div>
        <div *ngIf="map_slot.minimized" class="control black_gradient_background" (click)="unminimizeTourSlot(map_slot)"><fa-icon [icon]="faArrowUp"></fa-icon></div>
        <div *ngIf="!map_slot.maximized" class="control black_gradient_background" (click)="maximizeTourSlot(map_slot)"><fa-icon [icon]="faWindowMaximize"></fa-icon></div>
        <div *ngIf="map_slot.maximized" class="control black_gradient_background" (click)="restoreTourSlot(map_slot)"><fa-icon [icon]="faWindowRestore"></fa-icon></div>
        <div class="control black_gradient_background" (click)="PATourPlannerControl.Instance.removeTechnicianDateCollectionFromOverview(map_slot.technician_date_collection)"><fa-icon [icon]="faWindowClose"></fa-icon></div>
      </div>
      <div class="map_container" [style.display]="map_slot.minimized ? 'none' : 'inline-flex'">
        <hawk-tour-map style="flex: 1; display: inline-flex; flex-direction: row;"
                       [technicianDateCollection]="map_slot.technician_date_collection"
                       [mapBoxAccessToken]="mapBoxAccessToken"
                       [shownTechnicianDates]="map_slot.technician_date_collection?.technicianDates"
                       [shownTours]="map_slot.technician_date_collection?.tours"
                       [currentSelectedTour]="map_slot.technician_date_collection?.selectedTour"
                       [planningInstructions]="planningInstructions"
                       [tourPlannerPlanningMode]="PATourPlannerControl.Instance.tourPlannerPlanningMode"
                       [selectedToursUnfittingOperations]="map_slot.technician_date_collection?.selectedTour ? map_slot.technician_date_collection.selectedTour.technicianDate.changedTourContainer.unfittingOperations : []"
                       [id]="map_slot.id"
                       [tourPlannerExtraOperations]="PATourPlannerControl.Instance.tourPlannerExtraOperationCollection"
                       [operations]="map_slot.operations"
                       [technicianLivePosition]="map_slot.technician_date_collection?.technicianDateWithLivePosition ? map_slot.technician_date_collection.technicianDateWithLivePosition.tour.liveTechnicianPosition : null"
                       [selectedTourMode]="map_slot.technician_date_collection?.showTourMode"
        >
        </hawk-tour-map>
        <div class="filter_menu">
          <div style="width: 24px; display: inline-flex; flex-direction: column; background-color: rgba(0,0,0, .7); border: 1px solid black; border-top-left-radius: 12px; border-bottom-left-radius: 12px;">
            <div style="margin: auto;">
              <fa-icon [icon]="faFilter"></fa-icon>
            </div>
          </div>
          <div style="display: inline-flex; flex-direction: column; background-color: rgba(0,0,0, .7); padding: 2px 4px; border: 1px solid black;">
            <div style="border-bottom: 1px solid white; padding: 2px;">
              <mat-slide-toggle style="font-size: .7rem" [checked]="map_slot.only_close_operations" (change)="toggleOnlyCloseOperations(map_slot, $event)">
                Nur nahe Aufträge
              </mat-slide-toggle>
            </div>
            <div style="padding: 2px;">
              <mat-slide-toggle [checked]="map_slot.only_time_fitting_operations" (change)="toggleTimeFittingOperations(map_slot, $event)">
                Zeitlich passend
              </mat-slide-toggle>
            </div>
            <div class="quick_project_toggle_container">
              <div style="padding: 2px 4px;">
                Schnellauswahl:
              </div>
              <div class="project_container" *ngFor="let project_container of map_slot.operation_projects_container" (click)="toggleProjectQuickSelectForTourSlot(project_container.project, map_slot)">
                <div style="width: 24px; height: 24px; border-radius: 12px; border: 1px solid black; background-color: #323232; display: inline-flex;">
                  <fa-icon style="margin: auto;" [style.color]="map_slot.quick_filter_projects.includes(project_container.project) ? 'gray' : project_container.project.color" [icon]="faFlag"></fa-icon>
                </div>
                <div style="text-align: left; margin: auto auto auto 4px;" [style.color]="map_slot.quick_filter_projects.includes(project_container.project) ? 'gray' : 'white'">
                  {{project_container.project.project_name}}
                </div>
                <div style="margin-left: 4px; margin-top: auto; margin-bottom: auto;" [style.color]="map_slot.quick_filter_projects.includes(project_container.project) ? 'gray' : 'white'">
                  ({{project_container.amount}})
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
