<div class="flex_col" style="position:relative; width: 100%; height: 100%; padding-top: 12px;">
  <div class="card flex_col" style="height: 100%;">
    <div class="padding_10 flex_row full_width">
      <div style="flex: 1">
        <ul class="breadcrumb">
          <li *ngFor="let item of breadcrumbLinkList">
            <a [href]="item.link">
              <fa-icon *ngIf="breadcrumbLinkList.indexOf(item) == 0" [icon]="icons[root]"></fa-icon>{{item.description}}
            </a>
          </li>
          <li>
            <fa-icon *ngIf="!breadcrumbLinkList?.length" [icon]="icons[root]"></fa-icon>{{leafDescription}}
          </li>
        </ul>
      </div>
    </div>
    <ng-content></ng-content>
  </div>
</div>