import { CommonModule } from '@angular/common';
import { Component, Input, EventEmitter, Output } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';

@Component({
    selector: 'hawk-address-auto-fill',
    templateUrl: './address-auto-fill.component.html',
    styleUrls: ['./address-auto-fill.component.scss'],
    imports: [
      CommonModule, 
      ReactiveFormsModule, 
      NgSelectModule
    ],
})
export class AddressAutoFillComponent {
  @Input() formGroup!: FormGroup;
  @Input() prefix: string = '';
  @Input() loading: boolean = false;
  @Input() loadingCustomers: boolean = false;
  @Input() countries: any[] = [];
  @Input() senderRecieverTypes: any[] = [];
  @Input() technicians: any[] = [];
  @Input() wareHouses: any[] = [];
  @Input() customers: any[] = [];
  @Input() filteredStores: any[] = [];
  @Output() onAddressTypeChangeClicked = new EventEmitter()
  @Output() onAddressChangeClicked = new EventEmitter()
  @Output() onChangeCustomerClicked = new EventEmitter()
  @Output() onFetchMoreStores = new EventEmitter()
  @Output() onFetchMoreCustomers = new EventEmitter()
  public searchTerm: string = ''

  public onAddressTypeChange(event: Event) {
    this.onAddressTypeChangeClicked.emit(event)
  }

  public onAddressChange(event: Event) {
    this.onAddressChangeClicked.emit(event);
  }

  public onChangeCustomer(event: Event) {
    this.onChangeCustomerClicked.emit(event);
  }

  public fetchMoreStores() {
    this.onFetchMoreStores.emit();
  }

  public fetchMoreCustomers() {
    this.onFetchMoreCustomers.emit();
  }
}