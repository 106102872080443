import { CommonModule } from "@angular/common";
import { ReactiveFormsModule } from "@angular/forms";

import { Component, OnInit } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms'
import { TicketService } from '../_services'
import { environment } from 'src/environments/environment'

@Component({
  imports: [CommonModule, ReactiveFormsModule],
    selector: 'hawk-ticket-fixer',
    templateUrl: './ticket-fixer.component.html',
    styleUrls: ['./ticket-fixer.component.scss'],
})
export class TicketFixerComponent implements OnInit {

  form: UntypedFormGroup
  env = environment

  status = 'new'
  errorItems: string[][] = [[]]
  errorText: string

  constructor(
    private route: ActivatedRoute,
    private formBuilder: UntypedFormBuilder,
    private ticketService: TicketService,
  ) { }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      importfile: ['']
    })
  }

  onFileChange(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0]
      this.form.get('importfile').setValue(file)
    }
  }

  onSubmit() {
    const formData = new FormData()
    formData.append('file', this.form.get('importfile').value)
    this.status = 'running'

    this.ticketService.ticketFixerImport(formData).subscribe(
      (res) => {
        this.status = 'done'
        this.errorItems = res.errors || []
      },
      (err) => {
        console.error(err)
        this.status = 'error'
        this.errorText = 'es ist etwas schief gegangen.'
      }
    )
  }
}