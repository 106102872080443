import { Component, inject, OnInit } from '@angular/core';
import { AG_GRID_LOCALE_DE } from "../../_helpers/aggrid.locale.de";
import {
  GridApi,
  RowClassRules,
  SideBarDef, SizeColumnsToContentStrategy,
  SizeColumnsToFitGridStrategy,
  SizeColumnsToFitProvidedWidthStrategy
} from "ag-grid-enterprise";
import { ColDef, GridReadyEvent } from "ag-grid-community";
import { StoreService } from "../../_services";
import { APStore } from "../../_models/store.interface";
import { AgGridAngular } from "ag-grid-angular";
import { MatButton } from "@angular/material/button";
import { AgGridThemeService } from "../../_services/ag-grid-theme.service";
import { RecordNavigationComponent } from "../_shared/record-navigation/record-navigation.component";

@Component({
  selector: 'hawk-store-index',
  templateUrl: './store-index.component.html',
  standalone: true,
  imports: [
    AgGridAngular,
    RecordNavigationComponent,
    MatButton
  ],
  styleUrls: ['./store-index.component.scss', '../_shared/styles/common-styles.scss']
})
export class StoreIndexComponent implements OnInit {
  public hawkTheme = inject(AgGridThemeService).getTheme();

  public stores: APStore[]
  protected readonly locale = AG_GRID_LOCALE_DE;
  private gridApi!: GridApi;

  public rowClassRules: RowClassRules = {}
  autoSizeStrategy: SizeColumnsToFitGridStrategy | SizeColumnsToFitProvidedWidthStrategy | SizeColumnsToContentStrategy = {
    type: "fitGridWidth",
  }

  public colDefs: ColDef[] = [
    {
      field: "nr",
      headerName: "Nr.",
      width: 150,
    },
    {
      field: "name",
      headerName: "Name",
      width: 340,
      cellRenderer: this.storeNameRenderer,
      cellClass: 'hover_link_cell'
    },
    {
      field: "address",
      headerName: "Adresse",
      width: 480,
    },
    {
      field: "register",
      headerName: "Kassen",
      width: 100,
    },
    {
      field: "ip",
      headerName: "IP",
      width: 280,
    },
    {
      field: "comment",
      headerName: "Kommentar",
      width: 280,
    },
  ]

  public sideBarOptions: SideBarDef = {
    hiddenByDefault: false,
    toolPanels: ['columns', 'filters']
  }

  public defaultColDef: ColDef = {
    filter: true
  }

  public rowSelection: "single" | "multiple" = "single"

  constructor(
    public storeService: StoreService
  ) {
  }

  ngOnInit(): void {
    this.storeService.getStoresAPHashes().subscribe(
      data => {
        this.stores = data
      },
      err => {
        console.log(err)
      }
    )
  }

  onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api
  }

  public quickSearchChanged(e: Event) {
    const newValue = (e.target as HTMLInputElement).value
    this.gridApi.setGridOption(
      "quickFilterText",
      newValue,
    )
  }

  storeNameRenderer(params: any): string {
    const id = params.data.id
    return `<a class="hover_link" href="/a/filiale/${id}" rel="noopener">${params.value}</a>`
  }

}
