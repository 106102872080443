<div id="filter_container">
  <div style="flex: 0 1 auto; display: inline-flex; flex-direction: row; flex-wrap: wrap; padding: 2px 4px;">
    <div class="filter_element_container">
      <div class="filter_element">
        <b>Globale Filter</b>
      </div>
    </div>
  </div>
  <div class="filter_sub_container" id="project_selection_container">
    <div style="position: relative;">
      <div class="expand" style="position: absolute; left: 0; bottom: 100%; z-index: 100; width: 420px;" [ngClass]="showProjectFilter ? 'shown' : ''">
        <div style="background-color: white; display: inline-flex; flex-direction: row; width: 100%;">
          <div style="color: black; width: 100%;">
              <input style="width: 100%;" type="search" placeholder="Suche Projekte..." (search)="PAFilterControl.Instance.updateShownProjects()" (keyup)="PAFilterControl.Instance.updateShownProjects()" [(ngModel)]="PAFilterControl.Instance.projectSearchString">
          </div>
        </div>
        <div style="max-height: 360px; overflow: auto;">
          <div style="display: inline-flex; flex-direction: row; flex-wrap: wrap; width: 100%;">
            <div class="project_header"> 
              <div style="margin: auto; padding: 4px; font-size: .85rem;">
                Service Projekte:
              </div>
              <div style="flex: 1;"></div>
              <div>
                <mat-button-toggle-group [(ngModel)]="PAFilterControl.Instance.serviceProjectsToggleGroup">
                  <mat-button-toggle value="none" (change)="PAFilterControl.Instance.deselectAllServiceProjects()">Keine</mat-button-toggle>
                  <mat-button-toggle value="selected">Ausgewählt</mat-button-toggle>
                  <mat-button-toggle value="all" (change)="PAFilterControl.Instance.selectAllServiceProjects()">Alle</mat-button-toggle>
                </mat-button-toggle-group>
              </div>
            </div>
            <div *ngFor="let project of PAFilterControl.Instance.filteredServiceProjects"
            class="project black_gradient_background"
            [ngClass]="Project.selectedProjects.includes(project) ? 'selected' : ''"
            (click)="PAFilterControl.Instance.toggleProjectSelection(project)">
              <div style="margin: auto;">
                {{project.project_name}}
              </div>
              <input type="color" style="width: 16px; height: 16px; margin: auto 2px auto auto;" [value]="project.color" (click)="$event.stopPropagation()" (change)="project.updateColor($event)">
            </div>
          </div>
          <div style="display: inline-flex; flex-direction: row; flex-wrap: wrap; width: 100%;">
            <div class="project_header"> 
              <div style="margin: auto; padding: 4px; font-size: .85rem;">
                Nicht-Service Projekte:
              </div>
              <div style="flex: 1;"></div>
              <div>
                <mat-button-toggle-group name="fontStyle" aria-label="Font Style" [(ngModel)]="PAFilterControl.Instance.nonServiceProjectsToggleGroup">
                  <mat-button-toggle value="none" (change)="PAFilterControl.Instance.deselectAllNonServiceProjects()">Keine</mat-button-toggle>
                  <mat-button-toggle value="selected">Ausgewählt</mat-button-toggle>
                  <mat-button-toggle value="all" (change)="PAFilterControl.Instance.selectAllNonServiceProjects()">Alle</mat-button-toggle>
                </mat-button-toggle-group>
              </div>
            </div>
            <div *ngFor="let project of PAFilterControl.Instance.filteredNonServiceProjects"
            class="project black_gradient_background"
            [ngClass]="Project.selectedProjects.includes(project) ? 'selected' : ''"
            (click)="PAFilterControl.Instance.toggleProjectSelection(project)">
              <div style="margin: auto;">
                {{project.project_name}}
              </div>
              <input style="width: 16px; height: 16px; margin: auto 2px auto auto;"  type="color" [value]="project.color" (click)="$event.stopPropagation()" (change)="project.updateColor($event)">
            </div>
          </div>
        </div>
      </div>
    </div>
    <hawk-expand-bar-header
    style="height: 100%; width: 100%;"
    [description]="Project.selectedProjects.length.toString() + ' Projekte'"
    [isExpanded]="!showProjectFilter"
    [fontSize]="'.8rem'"
    [height]="'100%'"
    [fontColor]="'#18b9f8'"
    (toggle)="showProjectFilter = !$event">
    </hawk-expand-bar-header>
  </div>
  <div class="filter_sub_container" id="technician_selection_container">
    <div style="position: relative; width: 100%;">
      <div class="expand black_gradient_background_no_hover" style="position: absolute; left: 0; bottom: 100%; z-index: 100; min-width: 240px; width: 100%;"  [ngClass]="showTechnicianFilter ? 'shown' : ''">
        <div style="width: 100%; display: inline-flex; flex-direction: row; flex-wrap: nowrap; border: 1px solid black;">
          <div style="display: inline-flex; padding: 2px 4px;">
            <div style="margin: auto;">
              Gruppe
            </div>
          </div>
          <select style="flex: 1;" (change)="updateTechnicianOwnerFilter($event)">
            <option [selected]="PAFilterControl.Instance.selectedTechnicianOwnerFilter=='off'" value="off">Aus</option>
            <option [selected]="PAFilterControl.Instance.selectedTechnicianOwnerFilter=='bentomax'" value="bentomax">Bentomax</option>
            <option [selected]="PAFilterControl.Instance.selectedTechnicianOwnerFilter=='external'" value="external">Extern</option>
          </select>
        </div>
        <div style="width: 100%; display: inline-flex; flex-direction: row; flex-wrap: nowrap; border: 1px solid black">
          <div style="display: inline-flex; padding: 2px 4px;">
            <div style="margin: auto;">
              Auslastung
            </div>
          </div>
          <select style="flex: 1;" (change)="updateTechnicianOperationsInCalendarWeekRangeFilter($event)">
            <option [selected]="PAFilterControl.Instance.selectedTechnicianOperationsInCalendarWeekRangeFilter=='off'" value="off">Aus</option>
            <option [selected]="PAFilterControl.Instance.selectedTechnicianOperationsInCalendarWeekRangeFilter=='technicians_with_operations_in_calendar_week'" value="technicians_with_operations_in_calendar_week">Mit Aufträgen in KW</option>
            <option [selected]="PAFilterControl.Instance.selectedTechnicianOperationsInCalendarWeekRangeFilter=='technicians_without_operations_in_calendar_week'" value="technicians_without_operations_in_calendar_week">Ohne Aufträge in KW</option>
          </select>
        </div>
        <div style="width: 100%; display: inline-flex; flex-direction: row; flex-wrap: nowrap; border: 1px solid black; flex: 1;" *ngIf="['technicians_with_operations_in_calendar_week','technicians_without_operations_in_calendar_week'].indexOf(PAFilterControl.Instance.selectedTechnicianOperationsInCalendarWeekRangeFilter) >= 0">
          <div style="display: inline-flex; padding: 2px 4px;">
            <div style="margin: auto;">
              KW
            </div>
          </div>
          <div style="display: inline-flex; flex-direction: row; flex: 1;">
            <input style="width: 50%;" type="number" min="0" max="52" [(ngModel)]="PAFilterControl.Instance.operationsInCalendarWeekRangeCalendarWeekNumber" (change)="PAFilterControl.Instance.updateTechnicianFilter(); updateOperationFilterString()">
            <input style="width: 50%;" type="number" min="2000" [(ngModel)]="PAFilterControl.Instance.operationsInCalendarWeekRangeCalendarWeekYear" (change)="PAFilterControl.Instance.updateTechnicianFilter(); updateOperationFilterString()">
          </div>
        </div>
      </div>
    </div>
    <hawk-expand-bar-header
    style="height: 100%; width: 100%;"
    [description]="technicianFilterString"
    [isExpanded]="!showTechnicianFilter"
    [fontSize]="'.8rem'"
    [height]="'100%'"
    [fontColor]="'#18b9f8'"
    (toggle)="showTechnicianFilter = !$event">
    </hawk-expand-bar-header>
  </div>
  <div class="filter_sub_container" id="operation_selection_container">
    <div style="position: relative; width: 100%;">
      <div class="expand black_gradient_background_no_hover" style="position: absolute; left: 0; bottom: 100%; z-index: 100; min-width: 240px; width: 100%;"  [ngClass]="showOperationFilter ? 'shown' : ''">
        <div style="width: 100%; display: inline-flex; flex-direction: row; flex-wrap: nowrap; border: 1px solid black;">
          <div style="display: inline-flex; padding: 2px 4px;">
            <div style="margin: auto;">
              Braucht Material
            </div>
          </div>
          <select style="flex: 1;" (change)="updateOperationHasMaterialFilter($event)">
            <option [selected]="PAFilterControl.Instance.operationHasMaterialFilter == 'true'" value="true">Ja</option>
            <option [selected]="PAFilterControl.Instance.operationHasMaterialFilter == 'false'" value="false">Nein</option>
            <option [selected]="PAFilterControl.Instance.operationHasMaterialFilter == 'off'" value="off">Aus</option>
          </select>
        </div>
        <div style="width: 100%; display: inline-flex; flex-direction: row; flex-wrap: nowrap; border: 1px solid black;">
          <div style="display: inline-flex; padding: 2px 4px;">
            <div style="margin: auto;">
              Hat SLA/Termin
            </div>
          </div>
          <select style="flex: 1;"  (change)="updateOperationHasDeadlineFilter($event)" >
            <option [selected]="PAFilterControl.Instance.operationHasDeadlineFilter == 'sla_or_appointment'" value="sla_or_appointment">Hat SLA oder Termin</option>
            <option [selected]="PAFilterControl.Instance.operationHasDeadlineFilter == 'sla'" value="sla">Hat SLA</option>
            <option [selected]="PAFilterControl.Instance.operationHasDeadlineFilter == 'appointment'" value="appointment">Hat Termin</option>
            <option [selected]="PAFilterControl.Instance.operationHasDeadlineFilter == 'off'" value="off">Aus</option>
          </select>
        </div>
      </div>
    </div>
    <hawk-expand-bar-header
    style="height: 100%; width: 100%;"
    [description]="operationFilterString"
    [isExpanded]="!showOperationFilter"
    [fontSize]="'.8rem'"
    [height]="'100%'"
    [fontColor]="'#18b9f8'"
    (toggle)="showOperationFilter = !$event">
    </hawk-expand-bar-header>
  </div>
  <div style="display: inline-flex; flex-direction: row; flex-wrap: wrap; flex: 1 1 auto;">
    <div class="filter_sub_container">
      <div class="black_gradient_background" 
      style="color: #18b9f8; border: 1px solid black; width: 100%; padding: 2px 4px; display: inline-flex; flex-direction: row; font-size: .8rem;"
      (click)="toggleSelectedTimeFilter()">
        <div style="display: inline-flex; height: 100%; flex: 1;">
          <div style="margin: auto;">
            <ng-container *ngIf="PAFilterControl.Instance.selectedOperationTimeFilter == 'planned'">
              Hinterlegte Zeit
            </ng-container>
            <ng-container *ngIf="PAFilterControl.Instance.selectedOperationTimeFilter == 'average'">
              Durchschnittszeit
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  
    <div class="filter_sub_container">
      <div class="black_gradient_background_no_hover" style="color: #18b9f8; border: 1px solid black; width: 100%; padding: 2px 4px; display: inline-flex; flex-direction: row; font-size: .8rem;">
        <div style="display: inline-flex; height: 100%; flex: 1;">
          <div style="margin: auto;">
            ISO-Distanz
          </div>
        </div>
        <div style="display: inline-flex; height: 100%;">
          <div style="margin: auto;">
            <input type="number" min="0" max="60" [value]="PAMapControl.Instance.isochroneDistance" (change)="updateStoreIsoDistance($event)" style="max-width: 38px; height: 22px; border-radius: 4px;">
          </div>
        </div>
      </div>
    </div>
  </div>
  
</div>