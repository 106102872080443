import { Component, OnInit } from '@angular/core';
import {
  faArrowLeft,
  faEdit,
  faKey,
  faMailBulk,
  faPhone,
  faSms,
  faTrash,
  faUser
} from "@fortawesome/free-solid-svg-icons";
import { CustomerService, UserService } from "../../../_services";
import { APTechnicianHash, Technician, TechnicianUpdateHash } from "../../../_models/technician.interface";
import { ActivatedRoute, Router, RouterLink } from "@angular/router";
import { UntypedFormControl, UntypedFormGroup, Validators, ReactiveFormsModule, FormsModule } from "@angular/forms";
import { PADataControl } from "../../planning-assistant/singletons/pa-data-control";
import { Customer } from "../../../_models";
import { faSignIn } from "@fortawesome/pro-solid-svg-icons";
import { RoleService } from "../../../_services/role.service";
import { Role } from "../../../_models/role.interface";
import { NgIf, NgFor } from '@angular/common';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';

import { MatSelect } from '@angular/material/select';
import { MatOption } from '@angular/material/core';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatButton } from '@angular/material/button';
import { CommonModule } from "@angular/common";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatSelectModule } from "@angular/material/select";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { FaIconComponent, FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import * as mapboxgl from "mapbox-gl";
import { PAUtil } from "../../planning-assistant/classes/util";
import { MapBoxService } from "../../../_services/mapbox.service";
import { GeocoderEvent } from "../../../_models/mapbox.interface";
import { RecordNavigationComponent } from "../../_shared/record-navigation/record-navigation.component";

@Component({
  selector: 'hawk-user-edit',
  templateUrl: './user-edit.component.html',
  styleUrls: ['./user-edit.component.scss', '../../_shared/styles/common-styles.scss'],
  standalone: true,
  imports: [NgIf, RecordNavigationComponent, ReactiveFormsModule, MatFormField, MatInput, FaIconComponent, MatSelect, MatOption, NgFor, MatCheckbox, FormsModule, MatLabel, MatButton, CommonModule, MatFormFieldModule, MatSelectModule, MatCheckboxModule, FontAwesomeModule, RouterLink]
})
export class UserEditComponent implements OnInit {

  protected readonly faUser = faUser;

  user?: APTechnicianHash
  customers: Customer[] = []
  roles: Role[] = []
  checkedRoles: {role: Role, checked: boolean}[] = []
  subcontractors: Technician[] = []
  addressGeocoder?: MapboxGeocoder
  companyAddressGeocoder?: MapboxGeocoder

  userForm: UntypedFormGroup = new UntypedFormGroup({
    first_name: new UntypedFormControl({value: ''}, Validators.required),
    last_name: new UntypedFormControl({value: ''}, Validators.required),
    company: new UntypedFormControl({value: ''}),
    repairer: new UntypedFormControl({value: ''}),
    email1: new UntypedFormControl({value: ''}, Validators.email),
    email2: new UntypedFormControl({value: ''}),
    email_moreapp: new UntypedFormControl({value: ''}, Validators.email),
    email_partner1: new UntypedFormControl({value: ''}, Validators.email),
    email_partner2: new UntypedFormControl({value: ''}),
    customer_id: new UntypedFormControl({value: ''}),
    employer_id: new UntypedFormControl({value: ''}),
    phone1: new UntypedFormControl('', Validators.pattern('[- +()0-9]+')),
    phone2: new UntypedFormControl('', Validators.pattern('[- +()0-9]+')),
    telephone_dispatching: new UntypedFormControl('', Validators.pattern('[- +()0-9]+')),
    sms: new UntypedFormControl('', Validators.pattern('[- +()0-9]+')),
    fax: new UntypedFormControl('', Validators.pattern('[- +()0-9]+')),
    more_contact_info: new UntypedFormControl({value: ''}),
    street: new UntypedFormControl({value: '', disabled: true}),
    zip: new UntypedFormControl({value: '', disabled: true}, [Validators.pattern('^([a-zA-Z]{2}-)?[0-9]{2,5}( [a-zA-Z]{0,2})?$')]),
    city: new UntypedFormControl({value: '', disabled: true}),
    country: new UntypedFormControl({value: '', disabled: true}),
    state: new UntypedFormControl({value: '', disabled: true}),
    lat: new UntypedFormControl({value: ''}),
    lng: new UntypedFormControl({value: ''}),
    company_address_company: new UntypedFormControl({value: ''}),
    company_address_country: new UntypedFormControl({value: '', disabled: true}),
    company_address_state: new UntypedFormControl({value: '', disabled: true}),
    company_address_firstname: new UntypedFormControl({value: ''}),
    company_address_lastname: new UntypedFormControl({value: ''}),
    company_address_street: new UntypedFormControl({value: '', disabled: true}),
    company_address_zip: new UntypedFormControl({value: '', disabled: true}, [Validators.pattern('^([a-zA-Z]{2}-)?[0-9]{2,5}( [a-zA-Z]{0,2})?$')]),
    company_address_city: new UntypedFormControl({value: '', disabled: true}),
    card_nr_packstation: new UntypedFormControl({value: ''}),
    pickpoint_nr: new UntypedFormControl({value: ''}),
    xml_id: new UntypedFormControl({value: ''})
  });

  constructor(
    public userService: UserService,
    public customerService: CustomerService,
    public roleService: RoleService,
    public route: ActivatedRoute,
    public mapBoxService: MapBoxService,
    private router: Router
  ) {
  }

  ngOnInit(): void {
    const id = Number.parseInt(this.route.snapshot.paramMap.get('id'))
    this.loadUser(id)
    this.loadCustomers()
    this.loadRoles()
    this.loadSubcontractors()
    this.setGeocoder()
  }

  public async setGeocoder(): Promise<void> {
    this.addressGeocoder = new MapboxGeocoder({
      accessToken: this.mapBoxService.accessToken,
      marker: false,
      language: 'de-DE',
      mapboxgl: mapboxgl,
      placeholder: 'Adresse suchen'
    })
    this.companyAddressGeocoder = new MapboxGeocoder({
      accessToken: this.mapBoxService.accessToken,
      marker: false,
      language: 'de-DE',
      mapboxgl: mapboxgl,
      placeholder: 'Firmenadresse suchen'
    })
    while (!document.getElementById('address_geocoder') || !document.getElementById('company_address_geocoder')) {
      await PAUtil.sleep(100)
    }
    const address_geocoder_elem = document.getElementById('address_geocoder')
    const company_address_geocoder_elem = document.getElementById('company_address_geocoder')
    this.addressGeocoder.addTo(address_geocoder_elem);
    this.companyAddressGeocoder.addTo(company_address_geocoder_elem);

    this.addressGeocoder.on('result', (event) => {
      this.onAddressGeocoderEvent(event)
    })
    this.companyAddressGeocoder.on('result', (event) => {
      this.onCompanyAddressGeocoderEvent(event)
    })
  }

  loadUser(id: number): void {
    this.userService.getAPUser(id).subscribe(
      user => {
        this.user = user
        this.resetForm()
      },
      err => {
        console.log(err)
      }
    )
  }

  loadCustomers(): void {
    this.customerService.loadCustomers().subscribe(
      customers => {
        this.customers = customers.sort((c1, c2) => c1.name.toLowerCase() < c2.name.toLowerCase() ? -1 : 1)
      },
      err => {
        console.log(err)
      }
    )
  }

  loadRoles(): void {
    this.roleService.getRoles().subscribe(
      roles => {
        this.roles = roles
        this.resetRoles()
      },
      err => {
        console.log(err)
      }
    )
  }

  loadSubcontractors(): void {
    this.userService.getSubcontractors().subscribe(
      subcontractors => {
        this.subcontractors = subcontractors
      },
      err => {
        console.log(err)
      }
    )
  }

  resetForm():void {
    this.userForm.setValue({
      first_name: this.user.first_name,
      last_name: this.user.last_name,
      company: this.user.company,
      repairer: this.user.repairer,
      email1: this.user.email1,
      email2: this.user.email2,
      email_moreapp: this.user.email_moreapp,
      email_partner1: this.user.email_partner1,
      email_partner2: this.user.email_partner2,
      customer_id: this.user.customer?.id || null,
      employer_id: this.user.employer?.id || null,
      phone1: this.user.phone1,
      phone2: this.user.phone2,
      telephone_dispatching: this.user.telephone_dispatching,
      sms: this.user.sms,
      fax: this.user.fax,
      more_contact_info: this.user.more_contact_info,
      street: this.user.street,
      zip: this.user.zip,
      city: this.user.city,
      country: this.user.country,
      state: this.user.state,
      lat: this.user.lat,
      lng: this.user.lng,
      company_address_company: this.user.company_address_company,
      company_address_country: this.user.company_address_country,
      company_address_state: this.user.company_address_state,
      company_address_firstname: this.user.company_address_firstname,
      company_address_lastname: this.user.company_address_lastname,
      company_address_street: this.user.company_address_street,
      company_address_zip: this.user.company_address_zip,
      company_address_city: this.user.company_address_city,
      card_nr_packstation: this.user.card_nr_packstation,
      pickpoint_nr: this.user.pickpoint_nr,
      xml_id: this.user.xml_id,
    });

    this.resetRoles()
  }

  resetRoles(): void {
    this.checkedRoles = this.roles.filter(r => !r.name.includes('CRM')).map(
      role => {
        return { role: role, checked: this.user?.roles.map(r => r.id).includes(role.id) }
      }
    )
  }

  async onSubmit() {
    await this.updateUser()
    await this.updateRoles()
    this.router.navigate(['/benutzer/' + this.user.id])
  }

  updateUser(): Promise<void> {
    return new Promise<void>(resolve => {
      this.userService.updateUser(this.user.id, this.getUpdateHash()).subscribe(
        user => {
          this.user = user
          resolve()
        },
        error => {
          console.log(error)
          resolve()
        }
      )
    })
  }

  updateRoles(): Promise<void> {
    return new Promise<void>( resolve => {
      const not_crm_roles = this.roles.filter(r => !r.name.includes('CRM'))
      const add_roles_with_ids: number[] = not_crm_roles.filter(r => this.roleIsChecked(r.id) && !this.userHasRole(r.id)).map(r => r.id)
      const delete_roles_with_ids: number[] = not_crm_roles.filter(r => !this.roleIsChecked(r.id) && this.userHasRole(r.id)).map(r => r.id)

      this.userService.updateUserRoles(this.user.id, add_roles_with_ids, delete_roles_with_ids).subscribe(
        user => {
          this.user = user
          resolve()
        },
        error => {
          console.log(error)
          resolve()
        }
      )
    })
  }

  roleIsChecked(id: number): boolean  {
    return !!this.checkedRoles.find(cr => cr.role.id == id && cr.checked)
  }

  userHasRole(id: number): boolean {
    return !!this.user.roles.find(user_role => user_role.id == id)
  }

  getUpdateHash(): TechnicianUpdateHash {
    const controls = this.userForm.controls
    return {
      firstname: controls['first_name'].value,
      lastname: controls['last_name'].value,
      email: controls['email1'].value,
      email2: controls['email2'].value,
      email_moreapp: controls['email_moreapp'].value,
      email_partner: controls['email_partner1'].value,
      email_partner2: controls['email_partner2'].value,
      customer_id: controls['customer_id'].value,
      telephone1: controls['phone1'].value,
      telephone2: controls['phone2'].value,
      telephone_dispatching: controls['telephone_dispatching'].value,
      sms: controls['sms'].value,
      fax: controls['fax'].value,
      more_contact_info: controls['more_contact_info'].value,
      address_company: controls['company'].value,
      address_street: controls['street'].value,
      address_street_no: '',
      address_city: controls['city'].value,
      address_zip: controls['zip'].value,
      address_country: controls['country'].value,
      address_state: controls['state'].value,
      company_address_company: controls['company_address_company'].value,
      company_address_street: controls['company_address_street'].value,
      company_address_city: controls['company_address_city'].value,
      company_address_zip: controls['company_address_zip'].value,
      company_address_country: controls['company_address_country'].value,
      company_address_state: controls['company_address_state'].value,
      company_address_firstname: controls['company_address_firstname'].value,
      company_address_lastname: controls['company_address_lastname'].value,
      company_address_street_no: '',
      employer_id: controls['employer_id'].value,
      card_nr_packstation: controls['card_nr_packstation'].value,
      pickpoint_nr: controls['pickpoint_nr'].value,
      xml_id: controls['xml_id'].value,
      address_latitude: controls['lat'].value,
      address_longitude: controls['lng'].value,
    }
  }

  private onAddressGeocoderEvent(event: GeocoderEvent) {
    let {country, region, city, post_code, street, street_no, center, name} = PAUtil.getGeocoderEventAddress(event);

    let address_country_control = this.userForm.controls['country']
    address_country_control.setValue(country)
    address_country_control.markAsTouched();
    address_country_control.updateValueAndValidity()
    let address_state_control = this.userForm.controls['state']
    address_state_control.setValue(region)
    address_state_control.markAsTouched();
    address_state_control.updateValueAndValidity()
    let address_city_control = this.userForm.controls['city']
    address_city_control.setValue(city)
    address_city_control.markAsTouched();
    address_city_control.updateValueAndValidity()
    let address_zip_control = this.userForm.controls['zip']
    address_zip_control.setValue(post_code)
    address_zip_control.markAsTouched();
    address_zip_control.updateValueAndValidity()
    let address_street_control = this.userForm.controls['street']
    address_street_control.setValue(street + ' ' + street_no)
    address_street_control.markAsTouched();
    address_street_control.updateValueAndValidity()

    let latitude_control = this.userForm.controls['lat']
    latitude_control.setValue(center[1])
    latitude_control.markAsTouched();
    latitude_control.updateValueAndValidity()
    let longitude_control = this.userForm.controls['lng']
    longitude_control.setValue(center[0])
    longitude_control.markAsTouched();
    longitude_control.updateValueAndValidity()

    this.userForm.updateValueAndValidity()

  }

  private onCompanyAddressGeocoderEvent(event: any) {
    let {country, region, city, post_code, street, street_no, center, name} = PAUtil.getGeocoderEventAddress(event);

    let address_country_control = this.userForm.controls['company_address_country']
    address_country_control.setValue(country)
    address_country_control.markAsTouched();
    address_country_control.updateValueAndValidity()
    let address_state_control = this.userForm.controls['company_address_state']
    address_state_control.setValue(region)
    address_state_control.markAsTouched();
    address_state_control.updateValueAndValidity()
    let address_city_control = this.userForm.controls['company_address_city']
    address_city_control.setValue(city)
    address_city_control.markAsTouched();
    address_city_control.updateValueAndValidity()
    let address_zip_control = this.userForm.controls['company_address_zip']
    address_zip_control.setValue(post_code)
    address_zip_control.markAsTouched();
    address_zip_control.updateValueAndValidity()
    let address_street_control = this.userForm.controls['company_address_street']
    address_street_control.setValue(street + ' ' + street_no)
    address_street_control.markAsTouched();
    address_street_control.updateValueAndValidity()

    this.userForm.updateValueAndValidity()
  }

  protected readonly faMailBulk = faMailBulk;
  protected readonly PADataControl = PADataControl;
  protected readonly faPhone = faPhone;
  protected readonly faSms = faSms;
  protected readonly faArrowLeft = faArrowLeft;
  protected readonly faKey = faKey;
  protected readonly faEdit = faEdit;
  protected readonly faTrash = faTrash;
  protected readonly faSignIn = faSignIn;
}