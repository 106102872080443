import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, FormsModule, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgSelectModule } from '@ng-select/ng-select';
import { Project, Store } from 'src/app/_models';
import { ProjectService, TicketService } from 'src/app/_services';
import { CountryService } from 'src/app/_services/country.service';
import { CommonModule } from "@angular/common";
import { ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { MatFormFieldModule, MatLabel } from "@angular/material/form-field";
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatTimepickerModule } from '@angular/material/timepicker';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MatNativeDateModule } from '@angular/material/core';
import { unsubscribeAll } from 'src/app/_helpers/unsubscribe.helper';
import { Subscription } from 'rxjs';

@Component({
    selector: 'hawk-ticket-form',
    templateUrl: './ticket-form.component.html',
    styleUrls: ['./ticket-form.component.scss'],
    imports: [
      NgSelectModule, 
      CommonModule, 
      FormsModule,
      ReactiveFormsModule, 
      RouterModule, 
      MatFormFieldModule, 
      NgbDatepickerModule, 
      MatDatepickerModule, 
      MatTimepickerModule,
      MatInputModule, 
      MatLabel, 
      MatButtonModule, 
      FontAwesomeModule,
      MatIconModule,
      FormsModule,
      MatNativeDateModule,
    ],
  })
export class TicketFormComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription[] = []

  public countries: any[] = [];
  public projects: Project[] = [];
  public loadingProjects: boolean = false;
  public loadingProjectStatuses: boolean = false;
  public projectStatuses = [];
  public priorities = [];
  public loadingPriorities: boolean = false;
  public stores: any[] = [];
  public loadingStores: boolean = false;
  public ticketFields: any[] = [];
  public loadingticketFields: boolean = false;
  projectPage: number = 1;
  projectTotalPages: number;
  public formLoaded: boolean = false;
  public ticketId: number | String | string;
  public submittingTicket: boolean = false;
  public searchTerm: string = ''
  public slaDateValue: Date = new Date();

  public ticketForm: FormGroup;

  constructor(
    private ticketService: TicketService,
    private projectService: ProjectService,
    private countryService: CountryService,
    private route: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder
  ) { }

  public createOrUpdateArticle() {
    const { fields, ...rest } = this.ticketForm.value;
    const ticket = {
      ...rest,
      ticket_values_attributes: (fields || []).map((f: any) => ({ field_id: f.id, content: f.field_value }))
    }
    this.submittingTicket = true;
    const action = this.ticketId ? this.ticketService.update(this.ticketId, { ticket }) : this.ticketService.create({ ticket });
    this.subscriptions.push(action.subscribe(
      {
        next: (response: any) => {
          this.router.navigate(['/', 'ticket', response.id]);
        },
        error: (error) => {
          console.log('error', error);
          alert(`Please fix errors: ${error}`);
          this.submittingTicket = false;
        }
      }
    ))
  }

  public fetchMoreProjects() {
    if(this.projectPage < this.projectTotalPages) {
      this.projectPage += 1;
      this.loadProjects()
    }
  }

  public changeSlaDate() {
    let dateValue: Date | null = this.ticketForm.get('datesla_date')?.value;
    let timeValue: Date | null = this.ticketForm.get('datesla_time')?.value;

    if (dateValue && !timeValue) {
      const newTime = new Date(dateValue)
      newTime.setHours(20, 0, 0, 0)
      this.ticketForm.get('datesla_time')?.setValue(newTime.toISOString())

      timeValue = newTime
    }

    if (!dateValue && timeValue) {
      const today = new Date();
      const tomorrow = new Date(today);
      tomorrow.setDate(today.getDate() + 1);

      this.ticketForm.get('datesla_date')?.setValue(tomorrow.toISOString())
      dateValue = tomorrow
    }

    if (dateValue && timeValue) {
      if (timeValue instanceof Date) {
        const newDate = new Date(dateValue)
        newDate.setHours(
          timeValue.getHours(), 
          timeValue.getMinutes(), 
          timeValue.getSeconds(), 
          timeValue.getMilliseconds()
        )
        this.ticketForm.get('datesla')?.setValue(newDate.toISOString())
      }
    } else {
      console.error("timeValue is not a Date object:", timeValue);
    }
  }

  public deleteSLA() {
    this.ticketForm.get('datesla')?.setValue(null, { emitEvent: false })
    this.ticketForm.get('datesla_time')?.setValue(null, { emitEvent: false })
    this.ticketForm.get('datesla_date')?.setValue(null, { emitEvent: false })
  }

  public dateSLAPresent(): boolean {
    return !!this.ticketForm.get('datesla')?.value
  }

  loadProjects(id = null): Promise<void> {
    this.loadingProjects = true;
    return new Promise((resolve, reject) => {
      this.projectService.getAll(
        {
          page: this.projectPage,
          per_page: 200,
          ...(id && { id: id })
        }
      ).subscribe(
        {
          next: (response: any) => {
            this.projects = this.projects.concat(response.projects);
            this.projectTotalPages = response.total_pages;
            this.loadingProjects = false;
            resolve();
          },
          error: (error) => {
            console.log('error', error);
            this.loadingProjects = false;
            reject(error);
          }
        }
      )
    })
  }

  private loadCountries() {
    this.countryService.list().subscribe(
      {
        next: (countries) => {
          this.countries = countries;
        },
        error: (error) => {
          console.log('error', error);
        }
      }
    )
  }

  loadProjectStatuses(projectId: number | string | String): Promise<void> {
    this.loadingProjectStatuses = true;
    return new Promise((resolve, reject) => {
      this.projectService.getStatuses(projectId)
      .subscribe(
        {
          next: (response: any) => {
            this.projectStatuses = response;
            this.loadingProjectStatuses = false;
            resolve();
          },
          error: (error) => {
            console.log('error', error);
            this.loadingProjectStatuses = false;
            reject(error);
          }
        }
      )
    })
  }

  loadProjectPriorities(projectId: number | string | String): Promise<void> {
    this.loadingPriorities = true;
    return new Promise((resolve, reject) => {
      this.projectService.getPriorities(projectId)
      .subscribe(
        {
          next: (response: any) => {
            this.priorities = response;
            this.loadingPriorities = false;
            resolve();
          },
          error: (error) => {
            console.log('error', error);
            this.loadingPriorities = false;
            reject(error);
          }
        }
      )
    })
  }

  loadProjectStores(projectId: number | string | String): Promise<void> {
    this.loadingStores = true;
    return new Promise((resolve, reject) => {
      this.projectService.getStores(projectId)
      .subscribe(
        {
          next: (response: any) => {
            this.stores = response;
            this.loadingStores = false;
            resolve();
          },
          error: (error) => {
            console.log('error', error);
            this.loadingStores = false;
            reject(error);
          }
        }
      )
    })
  }

  loadTicketFields(projectId: number | string | String): Promise<void> {
    this.loadingticketFields = true;
    return new Promise((resolve, reject) => {
      this.projectService.getTicketFields(projectId)
      .subscribe(
        {
          next: (response: any) => {
            this.ticketFields = response;
            this.loadingticketFields = false;
            resolve();
          },
          error: (error) => {
            console.log('error', error);
            this.loadingticketFields = false;
            reject(error);
          }
        }
      )
    })
  }

  initForm() {
    this.ticketForm = new FormGroup({
      project_id: new FormControl(),
      order_nr: new FormControl(),
      description: new FormControl(),
      datesla: new FormControl(),
      datesla_date: new FormControl(),
      datesla_time: new FormControl(),
      external_order_nr: new FormControl(),
      status_id: new FormControl(null, Validators.required),
      priority_id: new FormControl(null, Validators.required),
      store_id: new FormControl(null, Validators.required),
      contact_name: new FormControl(),
      contact_email: new FormControl(),
      contact_phone: new FormControl(),
      address_company: new FormControl(),
      address_firstname: new FormControl(),
      address_lastname: new FormControl(),
      address_street: new FormControl(),
      address_street_no: new FormControl(),
      address_zip: new FormControl(),
      address_city: new FormControl(),
      address_country: new FormControl('deu'),
      address_state: new FormControl(),
      fields: this.fb.array([])
    });

    this.subscriptions.push(this.ticketForm.controls['project_id'].valueChanges.subscribe((projectId) => {
      if(!projectId) {

      } else {
        this.loadProjectStatuses(projectId);
        this.loadProjectPriorities(projectId);
        this.loadProjectStores(projectId);
        this.loadTicketFields(projectId).then(() => {
          const customFields = <FormArray>this.ticketForm.controls['fields'];
          // Reset on project change
          customFields.clear();
          this.ticketFields.map((customField) => {
            customFields.push(
              new FormGroup(
                {
                  id: new FormControl(customField.id),
                  description: new FormControl(customField.description),
                  name:  new FormControl(customField.name),
                  is_required: new FormControl(customField.mandatory),
                  field_type: new FormControl(customField.type),
                  field_value: new FormControl(null)
                }
              )
            )
          });
        })
      }
    }))

    this.subscriptions.push(this.ticketForm.get('datesla_date')?.valueChanges.subscribe(() => this.changeSlaDate()))
    this.subscriptions.push(this.ticketForm.get('datesla_time')?.valueChanges.subscribe(() => this.changeSlaDate()))
  }

  initValues(article = null, loadDefault = true) {
    if(article) {
      // this.ticketForm.controls.template_id.setValue(article.template_id);
    }

    Promise.all(
      [
        this.loadProjects()
      ]
    ).then(() => {
      if(loadDefault) {
        // this.ticketForm.controls.template_id.setValue(null);
      }
      this.formLoaded = true;
    })
    .catch((error) => {
      console.log('error', error);
      alert(`errors: ${error}`);
    })
  }

  ngOnInit(): void {
    this.ticketId = parseInt(this.route.snapshot.paramMap.get('id') || "0");
    this.loadCountries();
    this.initForm();
    this.initValues();
  }

  public ngOnDestroy() {
    unsubscribeAll(this.subscriptions)
  }

  public storeSearchFn(term: string, item: Store) {
		term = term.toLowerCase();
		return item.name?.toLowerCase()?.indexOf(term) > -1 || item.address_zip?.toLowerCase()?.indexOf(term) > -1 || item.address_city?.toLowerCase()?.indexOf(term) > -1 || item.address_street?.toLowerCase()?.indexOf(term) > -1
	}

  get fields() {
    return this.ticketForm.get('fields') as FormArray;
  }

}