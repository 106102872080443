<div class="noteSubpage">
  <div class="columns">
    <section class="tabs">
      <div class="tablist" role="tablist">
        <button role="tab" class="tab" [attr.aria-selected]="isSelectedTab('form')" (click)="selectTab('form')">Logbuch Eintrag</button>
        <button role="tab" class="tab" [attr.aria-selected]="isSelectedTab('history')" (click)="selectTab('history')">Historie</button>
      </div>

      <ng-container *ngIf="isSelectedTab('form')">
        <form [formGroup]="noteForm" (submit)="submitNote()">
        <section>
          <h3>Abrechnungsrelevante Abzüge / Vorlagen</h3>
          <ul class="checklist">
            <li><label><input type="checkbox" formControlName="special_billing" /> Besonderheiten in der Abrechnung</label></li>
            <li><label><input type="checkbox" formControlName="not_on_time" /> Terminuntreue</label></li>
            <li><label><input type="checkbox" formControlName="material_faulty" /> falsche / fehlende Materialinformationen</label></li>
            <li><label><input type="checkbox" formControlName="special_condition" /> Sonderkonditionen (Buchhaltung)</label></li>
            <li><label><input type="checkbox" formControlName="feedback_incomplete" /> unvollständige Rückmeldung</label></li>
            <li><label><input type="checkbox" formControlName="missing_signature" /> fehlende Unterschrift</label></li>
            <li><label><input type="checkbox" formControlName="other_problem" (change)="noteTypeChanged()" /> Sonstiges <input type="text" formControlName="other_problem_title" *ngIf="otherProblemChecked()" /></label></li>
          </ul>
          <select class="selectbox" formControlName="technician_id" (change)="technicianChanged()" *ngIf="operation">
            <option [value]="tech.id" *ngFor="let tech of technicians">{{tech.full_name}}</option>
            <option value="-" selected="true">-</option>
          </select>
        </section>
        <section>
          <h3>Informationen / Hinweise</h3>
          <textarea formControlName="note" ></textarea>
        </section>

        <section>
          <div class="sourcebox">
            <label style="flex-shrink: 2; margin: 0px;margin-right: 10px; text-align: center">
            Quelle:
            </label>
            <select class="selectboxSmall" formControlName="source_type" style="margin-right: 10px">
              <option [value]="st" *ngFor="let st of sourceTypes">{{sourceTypeLabels[st]}}</option>
            </select>
            <input type="text" style="height:20px" formControlName="source" placeholder="test@kunde.de" />
          </div>
        </section>
        <section>
          <h3>Teilen mit</h3>
          <ul class="checklist">
            <li><label><input type="checkbox" formControlName="share_internal" (change)="checkboxChanged()"/> Interne Informationen</label></li>
            <li><label><input type="checkbox" formControlName="share_customer" (change)="checkboxChanged()" /> Kunde</label></li>
            <li><label><input type="checkbox" formControlName="share_bookkeeper" (change)="checkboxChanged()" /> Buchhaltung (Sonderk.)</label></li>
            <li><label><input type="checkbox" formControlName="share_technician" (change)="checkboxChanged()" /> Techniker</label></li>
            <li><label><input type="checkbox" formControlName="share_partner" (change)="checkboxChanged()" /> Partner</label></li>
          </ul>
        </section>
        </form>
        <button class="save" (click)="submitNote()">Speichern</button>
      </ng-container>

      <ng-container *ngIf="isSelectedTab('history')">
        <div class="noteFilterBox">
          <section>Filter: <a href="" (click)="activateFilterBox('type')">nach Art<span *ngIf="filterType.length > 0"> (aktiv)</span></a>,
            <a href="" (click)="activateFilterBox('technician')">nach Techniker<span *ngIf="filterTechnician.length > 0"> (aktiv)</span></a>,
            <a href="" (click)="activateFilterBox('source')">nach Quelle<span *ngIf="filterSource.length > 0"> (aktiv)</span></a>,
            <a href="" (click)="activateFilterBox('destination')">nach Empfänger<span *ngIf="filterDestination.length > 0"> (aktiv)</span></a>
          </section>
          <form [formGroup]="filterForm">
            <section *ngIf="activeFilterBox == 'type'">
              <h3>Filterauswahl: Art</h3>
              <ul class="checklist">
                <li><label><input type="checkbox" formControlName="special_billing" /> Besonderheiten in der Abrechnung</label></li>
                <li><label><input type="checkbox" formControlName="not_on_time" /> Terminuntreue</label></li>
                <li><label><input type="checkbox" formControlName="material_faulty" /> falsche / fehlende Materialinformationen</label></li>
                <li><label><input type="checkbox" formControlName="special_condition" /> Sonderkonditionen (Buchhaltung)</label></li>
                <li><label><input type="checkbox" formControlName="feedback_incomplete" /> unvollständige Rückmeldung</label></li>
                <li><label><input type="checkbox" formControlName="missing_signature" /> fehlende Unterschrift</label></li>
                <li><label><input type="checkbox" formControlName="other_problem" (change)="noteTypeChanged()" /> Sonstiges </label></li>
              </ul>
              <button (click)="setType()">Filter speichern</button>
              <button (click)="resetType()">Filter löschen</button>
            </section>
            <section *ngIf="activeFilterBox == 'technician'">
              <h3>Filterauswahl: Techniker</h3>
              <select class="selectbox" formControlName="technician_id">
              <option [value]="tech.id" *ngFor="let tech of technicians">{{tech.full_name}}</option>
              </select>
              <button (click)="setTechnician()">Filter speichern</button>
              <button (click)="resetTechnician()">Filter löschen</button>
            </section>
            <section *ngIf="activeFilterBox == 'source'">
              <h3>Filterauswahl: Quelle</h3>
              <select class="selectboxSmall" formControlName="source_type" style="margin-right: 10px">
                <option [value]="st" *ngFor="let st of sourceTypes">{{sourceTypeLabels[st]}}</option>
              </select>
              <button (click)="setSource()">Filter speichern</button>
              <button (click)="resetSource()">Filter löschen</button>
            </section>
            <section *ngIf="activeFilterBox == 'destination'">
              <h3>Filterauswahl: Empfänger</h3>
              <ul class="checklist">
                <li><label><input type="checkbox" formControlName="share_internal" /> Interne Informationen</label></li>
                <li><label><input type="checkbox" formControlName="share_customer"  /> Kunde</label></li>
                <li><label><input type="checkbox" formControlName="share_bookkeeper" /> Buchhaltung (Sonderk.)</label></li>
                <li><label><input type="checkbox" formControlName="share_technician" /> Techniker</label></li>
                <li><label><input type="checkbox" formControlName="share_partner" /> Partner</label></li>
              </ul>
              <button (click)="setDestination()">Filter speichern</button>
              <button (click)="resetDestination()">Filter löschen</button>
            </section>
          </form>
        </div>

        <div class="noteBox" *ngFor="let note of notes | ticketNoteFilter:filterParameter ">
          <div class="noteRow">
            <section [className]="nodeClass(note.special_billing)">Ab</section>
            <section [className]="nodeClass(note.not_on_time)">Ter</section>
            <section [className]="nodeClass(note.material_faulty)">Mat</section>
            <section [className]="nodeClass(note.special_condition)">SK</section>
            <section [className]="nodeClass(note.feedback_incomplete)">Rü</section>
            <section [className]="nodeClass(note.missing_signature)">Unt</section>
            <section [className]="nodeClass(note.other_problem)">S</section>
            <section class="noteTypeText">{{note.other_problem_title}}</section>
          </div>
          <div class="noteRow">
            <section>{{note.note}}</section>
          </div>
          <div class="noteRow">
            <section class="noteType">
              <div class="noteRow">
                <section [className]="nodeClass(note.share_internal)">Intern</section>
                <section [className]="nodeClass(note.share_partner)">Partner</section>
                <section [className]="nodeClass(note.share_technician)">Techniker</section>
                <section [className]="nodeClass(note.share_customer)">Kunde</section>
                <section [className]="nodeClass(note.share_bookkeeper)">Buchhaltung (Sonderk.)</section>
              </div>
            </section>
            <section class="noteTypeTech">Techniker: {{note.technician_name ? note.technician_name : '-'}}</section>
            <section class="noteTypeAuthor">
              {{note.created_by_name}}, {{note.created_at | date: 'dd.MM.yyyy HH:mm:ss'}}
            </section>
          </div>
        </div>

        <div class="noteBox" *ngFor="let note of legacyNotes">
          <div class="noteRow">
            <section class="noteTypeText">Aus altem Hawk</section>
          </div>
          <div class="noteRow">
            <section>{{note.text}}</section>
          </div>
          <div class="noteRow">
            <section class="noteType">
              <div class="noteRow">
                <section></section>
              </div>
            </section>
            <section class="noteTypeAuthor">
              {{note.email}}, {{note.date_created | date: 'dd.MM.yyyy HH:mm:ss'}}
            </section>
          </div>
        </div>
      </ng-container>
    </section>
  </div>
</div>
