import { TechnicianDayTravelHash } from "./technician.interface";
import { Route as MBRoute } from "../_services/mapbox.service";
import {
  BarData,
  BarDataValue,
} from "../welcome/capacity-bars/capacity-bars.component";
import { WPOperation } from "./operation.interface";
import { PAUtil } from "../_components/planning-assistant/classes/util";
import { OperationService } from "../_services";
import { RouteNode } from "../welcome/route-visualization/route-visualization.component";

export interface TechnicianTourRoute {
  hours: {
    total_hours: number
    driving_hours: number
    working_hours: number
    unused_capacity_hours: number
    additional_free_hours: number
  },
  route: Route
  absence?: string
  errors?: string[]
}

export type Route = (OperationWaypoint | DrivingConnection)[]

export interface Coordinates {
  lat: number,
  lon: number
}

export interface CheckItem {
  status: 'done' | 'active' | 'open',
}

export interface OperationWaypoint extends CheckItem {
  type: 'OperationWaypoint',
  work_time: number,
  start_time: number,
  operation: WPOperation
}

export interface DrivingConnection extends CheckItem {
  type: 'DrivingConnection',
  from_coordinates: Coordinates,
  to_coordinates: Coordinates,
  destination: string
  estimated_minutes: number,
  driven_minutes?: number
  driving_start_time: number
}

export class TechnicianTour {

  private _capacityBarData?: BarData;
  private _routeNodes?: RouteNode[];
  private _route?: TechnicianTourRoute
  private readonly _noLocationChangeForProjects: number[] = [133] // no location change in routes for operations from internal Bentomax project (id 133)

  constructor(
    private technician: TechnicianDayTravelHash,
    private operationService: OperationService,
    private config?: {}
  ) {
    this.generateRoute().then(
      tour_route => {
        this._capacityBarData = this.generateCapacityBarData(tour_route)
        this._routeNodes = this.generateRouteVisualizationData(tour_route.route)
        this._route = tour_route
      }
    )
  }

  get capacityBarData(): BarData {
    return this._capacityBarData;
  }

  get routeNodes(): RouteNode[] {
    return this._routeNodes;
  }

  private async generateRoute(): Promise<TechnicianTourRoute> {
    if (this.technician.day_absence) {
      return this.generateAbsenceRoute()
    } else {

      const { route, driving_hours, working_hours } = await this.generateTechniciansOperationsRoute(this.technician.day_operations)
      let total_hours = driving_hours + working_hours

      if (total_hours > 0) {
        total_hours += 0.75 // add 45min break
      }

      return {
        errors: [],
        hours: {
          total_hours,
          working_hours,
          driving_hours,
          unused_capacity_hours: Math.max(8.75 - total_hours, 0),
          additional_free_hours: Math.min(2, Math.max(10.75 - total_hours, 0)),
        },
        route: route
      }
    }
  }

  async waitForBarData(): Promise<BarData> {
    while (!this._capacityBarData) {
      await PAUtil.sleep(50)
    }
    return this._capacityBarData
  }

  async waitForRouteNodes(): Promise<RouteNode[]> {
    while (!this._routeNodes) {
      await PAUtil.sleep(50)
    }
    return this._routeNodes
  }

  generateAbsenceRoute(): TechnicianTourRoute {
    return {
      absence: this.technician.day_absence,
      hours: {
        total_hours: 8,
        working_hours: 8,
        additional_free_hours: 0,
        driving_hours: 0,
        unused_capacity_hours: 0
      },
      route: []
    }
  }

  async generateTechniciansOperationsRoute(operations: WPOperation[]): Promise<{
    route: Route,
    driving_hours: number,
    working_hours: number
  }> {
    const technician = this.technician
    let last_coordinates = { lat: technician.day_start_lat, lon: technician.day_start_lon }
    let home_driving_start_time = 8 * 60 * 60 * 1000 // 8Uhr
    const route: Route = []

    let driving_hours = 0
    let working_hours = 0

    for (let operation of operations) {
      const driving_connection_to_operation = await this.getOperationDrivingConnection(operation, last_coordinates)
      if (driving_connection_to_operation) {
        route.push(driving_connection_to_operation)
        const use_minutes = driving_connection_to_operation.driven_minutes || driving_connection_to_operation.estimated_minutes
        driving_hours += use_minutes / 60
        last_coordinates = driving_connection_to_operation.to_coordinates
      }


      const work_time = this.operationWorkTime(operation)
      working_hours += (work_time / 60)
      const operation_start_timestamp = this.operationsStartTimestamp(operation)

      route.push({
        type: 'OperationWaypoint',
        operation: operation,
        status: operation.finished ? 'done' : (operation.on_site ? 'active' : 'open'),
        start_time: this.timestampToDayMinutes(operation_start_timestamp),
        work_time: work_time
      })

      if (operations.indexOf(operation) == operations.length - 1) {
        let finished_operation_timestamp = operation_start_timestamp + work_time * 60 * 1000;
        home_driving_start_time = this.timestampToDayMinutes(finished_operation_timestamp)
      }
    }

    const home_driving_connection = await this.getHomeDrivingConnection(last_coordinates, home_driving_start_time)
    if (home_driving_connection) {
      route.push(home_driving_connection)
      const use_minutes = home_driving_connection.driven_minutes || home_driving_connection.estimated_minutes
      driving_hours += use_minutes / 60
    }

    return { route, driving_hours, working_hours }
  }

  async getOperationDrivingConnection(operation: WPOperation, from_coordinates: Coordinates): Promise<DrivingConnection | undefined> {
    const { driven_minutes, estimated_minutes } = await this.drivingTimeToOperation(operation, from_coordinates)

    if (driven_minutes > 0 || estimated_minutes > 0) {

      let start_timestamp: number
      if (operation.travel_start) {
        start_timestamp = new Date(operation.travel_start).getTime()
      } else {
        const use_minutes = driven_minutes || estimated_minutes
        if (operation.on_site) {
          start_timestamp = new Date(operation.on_site).getTime() - use_minutes
        } else {
          start_timestamp = new Date(operation.operation_date).getTime() - use_minutes
        }
      }

      return {
        from_coordinates: from_coordinates,
        to_coordinates: { lat: operation.lat, lon: operation.lon },
        estimated_minutes: estimated_minutes,
        driven_minutes: driven_minutes,
        driving_start_time: this.timestampToDayMinutes(start_timestamp),
        type: "DrivingConnection",
        status: operation.on_site ? 'done' : ( operation.travel_start ? 'active' : 'open'),
        destination: `Fahrt nach ${operation.city}`
      }
    }
    return
  }

  async getHomeDrivingConnection(from_coordinates: Coordinates, driving_start_time: number): Promise<DrivingConnection | undefined> {
    const driving_time = await this.getHomeDrivingMinutes(from_coordinates)

    if (driving_time > 0) {
      return {
        from_coordinates: from_coordinates,
        to_coordinates: { lat: this.technician.day_end_lat, lon: this.technician.day_end_lon },
        estimated_minutes: driving_time,
        driven_minutes: null,
        driving_start_time: driving_start_time,
        type: "DrivingConnection",
        destination: 'Heimfahrt',
        status: 'open'
      }
    }
    return
  }

  private async drivingTimeToOperation(operation: WPOperation, from_coordinates: Coordinates): Promise<{ estimated_minutes: number, driven_minutes: number }>{
    let estimated_minutes = 0
    let driven_minutes = 0
    if (!this._noLocationChangeForProjects.includes(operation.project_id)) {
      estimated_minutes = await this.mapboxDrivingTimeToOperation(operation, from_coordinates)
      if (operation.travel_start && operation.on_site) {
        driven_minutes = (new Date(operation.on_site).getTime() - new Date(operation.travel_start).getTime()) / (1000 * 60)
      }
    }

    return { estimated_minutes, driven_minutes }
  }

  private async mapboxDrivingTimeToOperation(operation: WPOperation, from_coordinates: Coordinates): Promise<number>{
    if (operation.lat == from_coordinates.lat && operation.lon == from_coordinates.lon) {
      return 0
    } else {
      return (await this.distanceBetweenCoordinates(from_coordinates.lat, from_coordinates.lon, operation.lat, operation.lon)).duration * this.technician.driving_time_factor
    }
  }

  operationsStartTimestamp(operation: WPOperation): number {
    let use_date: Date
    if (operation.on_site) {
      use_date = new Date(operation.on_site)
    } else {
      use_date = new Date(operation.operation_date)
    }

    return use_date.getTime()
  }

  timestampToDayMinutes(timestamp: number): number {
    const date = new Date(timestamp)
    date.setHours(0)
    date.setMinutes(0)
    date.setMilliseconds(0)
    return (timestamp - date.getTime()) / (60 * 1000)
  }

  // get work time in minutes
  operationWorkTime(operation: WPOperation): number {
    if (operation.on_site && operation.finished) {
      return (new Date(operation.finished).getTime() - new Date(operation.on_site).getTime()) / (1000 * 60)
    } else {
      return operation.time_estimation
    }
  }

  async getHomeDrivingMinutes(from_coordinates: Coordinates): Promise<number> {
    const technician = this.technician
    let home_driving_minutes = 0
    if (!technician.travel_technician_date_id) {
      const driving_home_duration = (await this.distanceBetweenCoordinates(from_coordinates.lat, from_coordinates.lon, technician.day_end_lat, technician.day_end_lon)).duration * technician.driving_time_factor
      if (driving_home_duration) {
        home_driving_minutes += driving_home_duration
      }
    }
    return home_driving_minutes
  }

  private hoursToTimeString(hours: number): string {
    let full_hour = Math.floor(hours)
    let minutes = Math.round((hours - full_hour) * 60)

    if (minutes == 60) {
      full_hour += 1
      minutes = 0
    }

    return `${full_hour}:${minutes < 10 ? '0' : ''}${minutes}`
  }

  private async distanceBetweenCoordinates(lat1: number, lon1: number, lat2: number, lon2: number): Promise<{ distance: number, duration: number, valid: boolean }> {
    if (lat1 == lat2 && lon1 == lon2) return { distance: 0, duration: 0, valid: true }

    // check local storage first
    const storage_key = `route:${lat1},${lon1};${lat2},${lon2}`
    let local_storage_value = localStorage.getItem(storage_key)
    let stored_route = (local_storage_value ? JSON.parse(local_storage_value) as MBRoute : null)

    if (stored_route) {
      return {distance: stored_route.distance, duration: stored_route.duration, valid: isFinite(stored_route.duration)}
    } else {
      return await new Promise(resolve => {
        this.operationService.getDistanceBetweenCoordinates(lat1, lon1, lat2, lon2).subscribe(
          data => {
            const route: MBRoute = {distance: data.distance, duration: data.duration}
            const route_string = JSON.stringify(route)
            PAUtil.setLocalStorageItem(storage_key, route_string)
            resolve(data)
          },
          error => {
            console.log(error)
            resolve({ distance: -1, duration: -1, valid: false })
          }
        )
      })
    }
  }

  private generateCapacityBarData(route: TechnicianTourRoute): BarData {
    const technician = this.technician
    return {
      description: technician.full_name.length > 21 ? `${technician.full_name.slice(0, 18)}...` : technician.full_name,
      values: this.generateCapacityBarDataValues(route),
      start_at_unit_amount: this.getBarDataStartUnitAmount(),
      style: this.getBarDataStyle(),
      filter_attributes: this.getBarDataFilterAttributes(),
      options: {
        'Reisetechniker': !!technician.travel_technician_date_id
      }
    }
  }

  private getBarDataStartUnitAmount(): number {
    const technician = this.technician
    if (technician.day_operations.length && !technician.day_absence) {
      const day_travel_start = technician.day_operations[0].travel_start
      return day_travel_start ? this.getHoursOnDate(new Date(day_travel_start)) : this.getHoursNow()
    } else {
      return 8 // Wenn keine Aufträge oder Absence -> Bar-Graph ab 8Uhr starten
    }
  }

  private generateCapacityBarDataValues(route: TechnicianTourRoute): BarDataValue[] {
    const res: BarDataValue[] = []
    const total_hours = route.hours.total_hours
    const absence = route.absence

    if (absence) {
      return [{
        tooltip: {
          infos: [absence],
          errors: []
        },
        icon: null,
        unit_amount: 8,
        color: 'lightgray'
      }]
    } else {
      if (total_hours > 0) {
        const tooltip = {
          infos: [
            `Geplante Tour: ${this.hoursToTimeString(total_hours)}h`,
            `Davon Fahrtzeit: ${this.hoursToTimeString(route.hours.driving_hours)}h`,
            `Davon Auftragszeit: ${this.hoursToTimeString(route.hours.working_hours)}h`,
            `Davon Pause: 0:45h`,
          ],
          errors: route.errors || []
        }

        let icon: 'loading_error' | 'finished' | null = null
        if (this.technician.day_operations.length) {
          const last_operation = this.technician.day_operations[this.technician.day_operations.length - 1]
          if (last_operation.finished) icon = 'finished'
        }

        res.push({
          color: 'rgb(0,128,0)',
          icon: icon,
          tooltip: tooltip,
          unit_amount: total_hours
        })
      }

      let unused_hours = route.hours.unused_capacity_hours
      if (unused_hours) {
        res.push({
          color: 'rgb(253,0,0)',
          unit_amount: unused_hours,
          tooltip: {
            infos: [`Der Techniker hat nicht genutzte Kapazitäten! (${this.hoursToTimeString(unused_hours)}h)`],
            errors: []
          },
        })
      }

      let free_hours = route.hours.additional_free_hours
      if (free_hours) {
        res.push({
          color: 'rgb(255,132,132)',
          unit_amount: free_hours,
          tooltip: {
            infos: [`Der Techniker hat noch weitere freie Zeit (${this.hoursToTimeString(free_hours)}h)`],
            errors: []
          },
        })
      }

      return res
    }
  }

  private generateRouteVisualizationData(route: Route): RouteNode[] {

    if (this.technician.day_absence) {
      return [{
        duration: 480,
        icon: 'absence',
        main_description: '',
        day_start_time: null,
        estimated_duration: 480,
        status: 'done',
        header: `Abwesenheit: ${this.technician.day_absence}`
      }]
    }

    let route_nodes: RouteNode[] = []
    for (let route_item of route) {
      if (route_item.type == 'OperationWaypoint') {
        let op = route_item.operation
        route_nodes.push({
          header: `Auftrag ${op.company || ''}`,
          main_description: op.description,
          icon: 'working',
          duration: route_item.work_time,
          estimated_duration: op.time_estimation,
          day_start_time: route_item.start_time,
          link: `/a/ticket/${op.ticket_id}?operation_id=${op.id}`,
          status: route_item.status
        })
      }
      if (route_item.type == 'DrivingConnection') {
        route_nodes.push({
          header: route_item.destination,
          status: route_item.status,
          main_description: '',
          icon: 'driving',
          duration: route_item.driven_minutes,
          estimated_duration: route_item.estimated_minutes,
          day_start_time: route_item.driving_start_time
        })
      }
    }
    return route_nodes
  }

  private getBarDataStyle(): 'filled' | 'bordered' {
    return this.technician.day_operations.length || this.technician.day_absence ? 'filled' : 'bordered'
  }

  private getBarDataFilterAttributes(): string[] {
    const res: string[] = []
    const technician = this.technician
    if (!technician.day_operations.length) {
      res.push('Ohne Auftrag')
    } else {
      if (!technician.day_operations.find(op => !op.finished)) {
        res.push('Alle Aufträge abgeschlossen')
      } else {
        const started_operations = technician.day_operations.filter(op => op.travel_start)
        if (started_operations.length) {
          const last_started_operation_index = technician.day_operations.indexOf(started_operations[started_operations.length - 1])
          if (technician.day_operations.length - 1 == last_started_operation_index) {
            res.push('Im letzten Auftrag')
          }
        }
      }

    }

    return res;
  }

  private getHoursNow(): number {
    const date_now = new Date()
    return this.getHoursOnDate(date_now)
  }

  private getHoursOnDate(date: Date): number {
    const hour_now = date.getHours()
    const minute_now = date.getMinutes()
    return hour_now + (minute_now / 60)
  }

}