import { Injectable } from '@angular/core'
import { Router } from '@angular/router'

import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http'

import { environment } from '../../environments/environment'
import { LoginAnswer, User } from '../_models/'
import { APTechnicianHash } from '../_models/technician.interface'
import { LocalStorageService } from './localstore.service'
import { firstValueFrom } from 'rxjs'

@Injectable({
  providedIn: 'root',
})
export class SessionService {

  public authToken: string
  public loginError: string = null

  constructor(
    private router: Router,
    private http: HttpClient,
    private localStorageService: LocalStorageService
  ) { }

  public setCurrentUser(user: APTechnicianHash) {
    this.localStorageService.setItem('currentUser', user);
    this.localStorageService.setItem('currentRoles', user.roles);
  }

  public getCurrentUser(): APTechnicianHash {
    const currentUser = this.localStorageService.getItem<APTechnicianHash>('currentUser');
    if (currentUser) {
      return currentUser
    }
    return null
  }

  public removeCurrentUser(): void {
    this.localStorageService.removeItem('currentUser');
    this.localStorageService.removeItem('currentRoles');
    this.localStorageService.removeItem('loginValidUntil');
  }

  public login(email: string, password: string) {
    const path = environment.apiURL + 'login_hawk.json'
    const body = { email, password }

    return this.http.post<APTechnicianHash>(path, body)
  }

  public async loginRedirect(email: string, password: string, returnUrl: string) {
    const path = environment.apiURL + 'login_hawk.json'
    const body = { email, password }

    try {
      const data = await firstValueFrom(this.http.post<APTechnicianHash>(path, body));
      this.setCurrentUser(data);
      this.router.navigate([returnUrl]);
    } catch (err: unknown) {
      console.error(err);

      if (err instanceof HttpErrorResponse) {
        this.loginError = err.message;
      } else {
        this.loginError = "Ein Fehler ist aufgetreten."
      }
    }
  }

  public isLoggedIn() {
    const path = environment.apiURL + 'logged_in.json'

    return this.http.get<APTechnicianHash>(path)
  }

  public async logout() {
    const path = environment.apiURL + 'logout.json'

    try {
      const data = await firstValueFrom(this.http.post(path, {}));
      this.removeCurrentUser()
      this.router.navigate(['login'])
    } catch (err: unknown) {
      console.error(err);

      if (err instanceof HttpErrorResponse) {
        this.loginError = err.message;
      } else {
        this.loginError = "Ein Fehler ist aufgetreten."
      }

      this.router.navigate(['login'])
    }
  }

  public loggedIn(): boolean {
    return !!this.getCurrentUser()
  }

  public rolePermissions() {
    const path = environment.apiURL + 'role_permissions.json'

    return this.http.get<{roles: string[]}>(path)
  }
}
