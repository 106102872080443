<hawk-record-navigation class="common_style" *ngIf="customer" [breadcrumbLinkList]="[{description: 'Kunden', link: '/a/kunde'}, {description: customer.name, link: '/a/kunde/' + customer.id}]" [root]="'customer'" [leafDescription]="'Filialen importieren'">
  <div class="flex_col" style="max-width: 600px;">
    <section>
      <h2 style="cursor: pointer;" (click)="showFormatModal = true">Format <fa-icon [icon]="faInfoCircle"></fa-icon><fa-icon [icon]="faDownload"></fa-icon></h2>
      <div class="input_list label_20">
        <div class="input_row">
          <div class="input_label">
            Header-Zeile
          </div>
          <div style="flex: 1;" class="flex_col">
            <div class="input_container">
              <mat-form-field class="outline-small no-subscript" appearance="outline">
                <mat-select [(ngModel)]="headerFormat" [disabled]="!!file">
                  <mat-option [value]="true">Vorhanden (Zeile 1 überspringen)</mat-option>
                  <mat-option [value]="false">Nicht vorhanden</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
        </div>
        <div class="input_row">
          <div class="input_label">
            Länder
          </div>
          <div style="flex: 1;" class="flex_col">
            <div class="input_container">
              <mat-form-field class="outline-small no-subscript" appearance="outline">
                <mat-select [(ngModel)]="selectedCountryFormat" [disabled]="!!file">
                  <mat-option [value]="'full_name'">full-name</mat-option>
                  <mat-option [value]="'3_letter'">3-letter</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
        </div>
        <div class="input_row">
          <div class="input_label">
          </div>
          <div style="flex: 1;" class="flex_col">
            <div class="input_container">
              <button mat-raised-button class="primary_bg_color_button" [disabled]="!alreadyCreatedCustomerStores" (click)="fileInput.click()">
                Datei auswählen
              </button>
              <div *ngIf="file" style="margin: auto 4px;">Auswahl: {{file.name}} <fa-icon style="cursor: pointer;" [icon]="faTrash" (click)="resetFile()"></fa-icon></div>
              <input #fileInput type="file" style="display: none;" (change)="incomingFile($event)" placeholder="Upload file" accept=".xlsx">
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
  <div style="position:relative; margin: 10px;" *ngIf="storeImportUnits?.length">
    <ag-grid-angular class="ag-theme-quartz" style="height: 480px;" [rowData]="storeImportUnits" [theme]="hawkTheme"
                     [defaultColDef]="defaultColDef" [columnDefs]="storeColDefs" [rowClassRules]="rowClassRules" [pagination]="true"
                     [rowSelection]="rowSelection" [autoSizeStrategy]="autoSizeStrategy"
                     [paginationPageSize]="16" [localeText]="locale" [sideBar]="sideBarOptions"
                     [paginationPageSizeSelector]="[16,32,64, 128]" (gridReady)="onStoreGridReady($event)">
    </ag-grid-angular>
    <input type="text" style="position: absolute; bottom: 0; left: 0;" placeholder="Schnellfilter..." (input)="storeQuickSearchChanged($event)">
  </div>
  <section *ngIf="countryErrorForm" [ngClass]="countryErrorForm.valid ? 'success' : 'error'">
    <h2>Formatierungsfehler</h2>
    <small class="text-muted">Bitte geben Sie die ISO3-Länder-Codes für folgende Länder an:</small>
    <form [formGroup]="countryErrorForm" style="padding: 40px 0">
      <mat-form-field *ngFor="let field of Object.keys(countryErrorForm.controls)" style="margin-right: 20px;" class="outline-small no-subscript" appearance="outline">
        <mat-label>{{field}}</mat-label>
        <input matInput maxlength="3" style="text-transform: uppercase" [formControlName]="field">
        <mat-error>Format: Genau 3 Buchstaben</mat-error>
      </mat-form-field>
    </form>
  </section>
  <div class="flex_col" style="width: fit-content; min-width: 600px;">
    <section>
      <h2 style="cursor: pointer;" (click)="showFormatModal = true">Verknüpfungen anlegen</h2>
      <div class="input_list label_20">
        <div class="input_row">
          <div class="input_label">
            Projekte
          </div>
          <div style="flex: 1;" class="flex_col">
            <div class="input_container">
              <mat-form-field class="outline-small no-subscript" appearance="outline">
                <mat-select [(ngModel)]="addProjectRelations" multiple>
                  <mat-option *ngFor="let project of projects" [value]="project.id">{{project.name}}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
  <div style="margin-left: 16px;">
    <ng-container *ngIf="!showSuccess">
      <ng-container *ngIf="!showError">
        <small class="text-muted" *ngIf="storeImportUnits?.length && !importableUnits?.length">Hinweis: Alle Filialen wurden importiert! (Bestehende Filialen können geupdated werden)</small>
        <small class="text-muted"  *ngIf="importableUnits?.length">Hinweis: Noch {{importableUnits?.length}}/{{storeImportUnits?.length}} Filiale(n) zum importieren</small>
      </ng-container>
      <ng-container *ngIf="showError">
        <small class="text-error">Fehler beim Import: {{showError}}</small>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="showSuccess">
      <small class="text-success">{{showSuccess}}</small>
    </ng-container>
  </div>
  <div style="flex-wrap: wrap;" class="flex_row footer_row">
    <button mat-raised-button color="primary" class="primary_bg_color_button" (click)="bulkImportStores()" [disabled]="(countryErrorForm && !countryErrorForm.valid) || uploadProgress > 0">
      Filialen importieren
    </button>
    <a [routerLink]="'/kunde/' + customer.id">
      <button type="button" mat-button>
        Abbrechen
      </button>
    </a>
    <mat-progress-bar mode="determinate" [value]="uploadProgress"></mat-progress-bar>
  </div>

  <div style="width: 100%;" class="modal is-active has-toolbar" role="dialog" aria-modal="true" *ngIf="showFormatModal">
    <div class="modal-background"></div>
    <div style="width: 100%;" class="modal-content">
      <div class="wrapper" style="padding: 2px;">
        <div class="flex_row">
          <h4 style="margin-bottom: 0">Beschreibung Excel Format</h4>
          <a style="margin-left:6px; margin-top: auto;" download="FilialenImport.xlsx" href="../../../assets/example-files/FilialenImport.xlsx">Beispiel Excel runterladen</a>
        </div>
        <hr>
        <div style="max-width: 100%; overflow-x: auto; white-space: nowrap; padding: 20px 0;">
          <table class="table table-bordered table-condensed">
            <tbody>
            <tr>
              <td [matTooltip]="'Eindeutige Bezeichnung (nr)'">Filiale <fa-icon [icon]="faInfoCircle"></fa-icon></td>
              <td [matTooltip]="'Firma (address_company)'">Name <fa-icon [icon]="faInfoCircle"></fa-icon></td>
              <td [matTooltip]="'Filialen-Name (name)'">Spalte1 <fa-icon [icon]="faInfoCircle"></fa-icon></td>
              <td [matTooltip]="'Nachname-Adresse (address_lastname)'">Lastname <fa-icon [icon]="faInfoCircle"></fa-icon></td>
              <td>Straße</td>
              <td>HausNr</td>
              <td>PLZ</td>
              <td>Ort</td>
              <td [matTooltip]="'3-Buchstabencode, Englischer/Deutscher Ländername oder leer für Deutschland'">Land <fa-icon [icon]="faInfoCircle"></fa-icon></td>
              <td [matTooltip]="''">Bundesland</td>
              <td [matTooltip]="''">E-Mail</td>
              <td [matTooltip]="''">Bemerkung</td>
              <td [matTooltip]="''">Anzahl der Kassenplätze</td>
              <td [matTooltip]="''">IP</td>
              <td [matTooltip]="'Nicht benutztes Feld'">- <fa-icon [icon]="faInfoCircle"></fa-icon></td>
              <td [matTooltip]="''">Telefon1</td>
              <td [matTooltip]="''">Telefon2</td>
              <td [matTooltip]="openingTooltip">Montag <fa-icon [icon]="faInfoCircle"></fa-icon></td>
              <td [matTooltip]="openingTooltip">Dienstag <fa-icon [icon]="faInfoCircle"></fa-icon></td>
              <td [matTooltip]="openingTooltip">Mittwoch <fa-icon [icon]="faInfoCircle"></fa-icon></td>
              <td [matTooltip]="openingTooltip">Donnerstag <fa-icon [icon]="faInfoCircle"></fa-icon></td>
              <td [matTooltip]="openingTooltip">Freitag <fa-icon [icon]="faInfoCircle"></fa-icon></td>
              <td [matTooltip]="openingTooltip">Samstag <fa-icon [icon]="faInfoCircle"></fa-icon></td>
              <td [matTooltip]="openingTooltip">Sonntag <fa-icon [icon]="faInfoCircle"></fa-icon></td>
            </tr>
            <tr>
              <td>C58_120356</td>
              <td>Lotterie Trapp B (2000066)</td>
              <td>Lotterie Trapp B</td>
              <td>SAP: XXXXXX</td>
              <td>Markt 12</td>
              <td>-</td>
              <td>DE-25821</td>
              <td>Bredstedt</td>
              <td>Deutschland</td>
              <td>de-sh</td>
              <td>{{'example@mail.de'}}</td>
              <td>Bemerkung 1</td>
              <td>3</td>
              <td></td>
              <td></td>
              <td>+49 1234 56789</td>
              <td>+49 1234 56790</td>
              <td>08:00-14:00#15:00-20:00</td>
              <td>08:00-20:00</td>
              <td>08:00-20:00</td>
              <td>08:00-20:00</td>
              <td>08:00-20:00</td>
              <td>08:00-20:00</td>
              <td></td>
            </tr>
            <tr>
              <td>...</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="modal-toolbar">
        <div class="modal-toolbar-container-left">
        </div>
        <div class="modal-toolbar-container-right">
          <button mat-flat-button (click)="showFormatModal=false">Schließen</button>
        </div>
      </div>
    </div>
    <button class="modal-close" aria-label="close" (click)="showFormatModal = null"></button>
  </div>

</hawk-record-navigation>


