import {
  Component,
  ViewChild,
  OnInit,
  ApplicationRef,
  Injector,
  OnDestroy,
  Input, OnChanges, SimpleChanges, ElementRef, Output, EventEmitter, HostListener, Injectable
} from '@angular/core';
import { CdkPortal, DomPortalOutlet } from '@angular/cdk/portal';
import { OverlayContainer } from "@angular/cdk/overlay";
import { faCompress, faExpand } from "@fortawesome/free-solid-svg-icons";
import { NgIf } from '@angular/common';

import { CommonModule } from "@angular/common";
import { FaIconComponent, FontAwesomeModule } from '@fortawesome/angular-fontawesome';

/**
 * This component template wrap the projected content
 * with a 'cdkPortal'.
 */

@Component({
  selector: 'pa-popout-window',
  templateUrl: './popout-window.component.html',
  styleUrls: ['./../../styles/common_styles.scss'],
  standalone: true,
  imports: [CdkPortal, NgIf, FaIconComponent, CommonModule, FontAwesomeModule]
})
export class PopoutWindowComponent implements OnInit, OnChanges, OnDestroy {

  @Input() windowDescription: string = ''
  @Input() width: number = 1400
  @Input() height: number = 800
  @Input() windowLeft: number;
  @Input() windowTop: number;

  @Output() closed: EventEmitter<boolean> = new EventEmitter();

  @ViewChild('innerWrapper') private innerWrapper: ElementRef;
  @ViewChild(CdkPortal) portal: CdkPortal;

  private externalWindow: WindowProxy = null;
  public readonly nativeElement: HTMLElement

  private styleObserver: MutationObserver;
  private _isPoppedOut = false;

  @HostListener('window:beforeunload')
  private beforeunloadHandler(): void {
    this.close();
  }

  public customCdkOverlayContainer: CustomCdkOverlayContainer

  constructor(
    private applicationRef: ApplicationRef,
    private injector: Injector,
    element: ElementRef,
    _customOverlayContainer: OverlayContainer
  ) {
    this.nativeElement = element.nativeElement
    this.customCdkOverlayContainer = _customOverlayContainer as CustomCdkOverlayContainer
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['windowDescription'] && this.externalWindow) {
      this.externalWindow.document.title = changes['windowDescription'].currentValue
    }
  }

  ngOnInit(){
  }

  get isPoppedOut(): boolean {
    return this._isPoppedOut
  }

  open(): void {
    if (!this.externalWindow) {

      const elmRect = this.innerWrapper.nativeElement.getBoundingClientRect();

      const navHeight = window.outerHeight - window.innerHeight;
      const navWidth = window.outerWidth - window.innerWidth;

      const winLeft = this.windowLeft || window.screenX + navWidth + elmRect.left;
      const winTop = this.windowTop || window.screenY + navHeight + elmRect.top - 60;
      this.externalWindow = window.open(
        '',
        '',
        `width=${this.width > 99 ? this.width : elmRect.width},
        height=${this.height > 99 ? this.height : elmRect.height + 1},
        left=${winLeft},
        top=${winTop}`
      );

      this.externalWindow.document.title = this.windowDescription
      this.externalWindow.document.body.style.margin = '0';

      document.head.querySelectorAll('style').forEach(node => {
        this.externalWindow.document.head.appendChild(node.cloneNode(true));
      });

      this.observeStyleChanges();

      document.head.querySelectorAll('link[rel="stylesheet"]').forEach(node => {
        this.externalWindow.document.head.insertAdjacentHTML('beforeend',
          `<link rel="stylesheet" type="${(node as HTMLLinkElement).type}" href="${(node as HTMLLinkElement).href}">`);
      });

      (document as any).fonts.forEach(node => {
        (this.externalWindow.document as any).fonts.add(node);
      });

      const host = new DomPortalOutlet(
        this.externalWindow.document.body,
        null,
        this.applicationRef,
        this.injector
      );

      host.attach(this.portal);

      this.customCdkOverlayContainer.setDocument(this.getDocument())

      // Pop Over Content to new Window
      let content = document.getElementById('popout_content')
      let popped_out_container = this.getDocument().querySelector('#popped_out_container')
      popped_out_container.appendChild(content)

      this._isPoppedOut = true
      this.externalWindow.addEventListener('unload', () => {
        this.popIn();
      });
    } else {
      this.externalWindow.focus();
    }
  }

  public popIn(): void {
    let content = this.getDocument().getElementById('popout_content')
    let popped_in_container = document.querySelector('#popped_in_container')
    popped_in_container.appendChild(content)
    this.close();
  }

  private observeStyleChanges(): void {
    const docHead = document.querySelector('head');

    this.styleObserver?.disconnect();
    this.styleObserver = new MutationObserver((mutations: MutationRecord[]) => {
      mutations.forEach((mutation: MutationRecord) => {
        mutation.addedNodes.forEach((node: Node) => {
          if (node.nodeName === 'STYLE') {
            this.externalWindow.document.head.appendChild(node.cloneNode(true));
          }
        });
      });
    });

    this.styleObserver.observe(docHead, { childList: true });
  }

  close(): void {
    if (this.externalWindow) {
      this.styleObserver?.disconnect();
      this.externalWindow.close();
      this.externalWindow = null;
      this._isPoppedOut = false;
      this.customCdkOverlayContainer.setDocument(this.getDocument())
      this.closed.emit(true);
    }
  }

  getDocument(): Document {
    return this.externalWindow ? this.externalWindow.document : this.nativeElement.ownerDocument
  }

  toggleExternalFullScreen(){
    if (this.isDocumentInFullScreenMode()) {
      this.exitExternalFullScreen()
    } else {
      this.requestExternalFullScreen()
    }
  }

  requestExternalFullScreen(): void {
    if (this.isPoppedOut) this.getDocument().body.requestFullscreen()
  }

  exitExternalFullScreen(): void {
    if (this.isPoppedOut) this.getDocument().exitFullscreen()
  }

  isDocumentInFullScreenMode() {
    const current_document = this.getDocument()
    return current_document.fullscreenElement !== null;
  }

  ngOnDestroy(){
    this.close()
  }

  protected readonly faCompress = faCompress;
  protected readonly faExpand = faExpand;
}

@Injectable({providedIn: 'root'})
export class CustomCdkOverlayContainer extends OverlayContainer {

  public setDocument(doc: Document): void {
    this._document = doc
    this._createContainer()
  }
}