import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'

import { environment } from '../../environments/environment'
import { Technician } from '../_models'

@Injectable({
  providedIn: 'root'
})
export class TechnicianService {

  constructor(private http: HttpClient) { }

  public getAll() {
    const path = environment.apiURL + 'users/all.json'

    return this.http.get<Technician[]>(path)
  }
}
