import { Component, OnInit, ViewChildren, QueryList, ElementRef } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, FormsModule, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DeliveryNote } from 'src/app/_models';
import { ArticleService, CustomerService, DeliveryNoteService, StoreService, UserService, WarehouseService } from 'src/app/_services';
import { CountryService } from 'src/app/_services/country.service';
import { CommonModule } from "@angular/common";
import { ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { MatFormFieldModule, MatLabel } from "@angular/material/form-field";
import { AddressAutoFillComponent } from '../_shared/address-auto-fill/address-auto-fill.component';
import { AddressFormComponent } from '../_shared/address-form/address-form.component';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatTableModule } from '@angular/material/table';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgSelectModule } from '@ng-select/ng-select';
import { MatDatepickerModule, MatDatepickerToggle } from '@angular/material/datepicker';

@Component({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatLabel,
    MatButtonModule,
    MatIconModule,
    MatCheckboxModule,
    MatTableModule,
    MatDatepickerModule, 
    NgSelectModule,
    FontAwesomeModule,
    AddressFormComponent,
    AddressAutoFillComponent,
  ],
    selector: 'hawk-delivery-note-create',
    templateUrl: './delivery-note-create.component.html',
    styleUrls: ['./delivery-note-create.component.scss'],
})
export class DeliveryNoteCreateComponent implements OnInit {
  constructor(
    private deliveryNoteService: DeliveryNoteService,
    private route: ActivatedRoute,
    private router: Router,
    private countryService: CountryService,
    private fb: FormBuilder,
    private aricleService: ArticleService,
    private userService: UserService,
    private wareHouseService: WarehouseService,
    private customerService: CustomerService,
    private storeService: StoreService
  ) { }

  @ViewChildren('rmaInput') rmaInputs!: QueryList<ElementRef>;
  public deliveryNoteId: number | String | string;
  public deliveryNote: DeliveryNote;
  public countries: any[];
  public submittingNote: boolean = false;
  public isLoaded: boolean = false;
  public recipientLoading: boolean = false;
  public senderLoading: boolean = false;
  public senderCustomersLoading: boolean = false;
  public recipientCustomersLoading: boolean = false;
  public setRmasDefaultValue: boolean = false;
  public senderPage: number = 1;
  public recipientPage: number = 1;
  public searchTerm: string = '';
  public senderCustomerId: number;
  public recipientCustomerId: number;
  //angel_de, asendia, cargo_international, dhl, dhl_express, dpag, dpd, gls, go, hermes, iloxx, parcel_one, ups
  public carriersList: any[] = ["DHL", "dhl_express", "DPD", "UPS", "GO"]
  public shippingServices: any[] = [
    { id: "standard", name: "Standard" },
    { id: "one_day", name: "Express (1 day)" },
    { id: "one_day_early", name: "Express (1 day early)" }
  ]
  public senderRecieverTypes = [
    {
      id: 'TECHNICIAN',
      name: 'Techniker'
    },
    {
      id: 'CUSTOMER',
      name: 'Kunde/Store'
    },
    {
      id: 'WAREHOUSE',
      name: 'Lager'
    },
    {
      id: 'FREE',
      name: 'Frei'
    }
  ];
  public technicians: any[];
  public wareHouses: any[];
  public senderCustomers: any[];
  public recipientCustomers: any[];
  public senderCustomerPage: number = 1;
  public recipientCustomerPage: number = 1;
  public senderStores: any[] = [];
  public recipientStores: any[] = [];
  senderTotalPages: number;
  recipientTotalPages: number;
  senderCustomerTotalPages: number;
  recipientCustomerTotalPages: number;
  public deliveryNoteForm: FormGroup;
  public addressesForm: UntypedFormGroup;
  private rmaGroup(values: any = {}) {
    return new FormGroup(
      {
        id: new FormControl(values?.id),
        template_description: new FormControl(values?.template_description),
        product_category: new FormControl(values?.product_category),
        vendor: new FormControl(values?.vendor),
        location: new FormControl(values?.location),
        rma: new FormControl(values?.rma),
        serial_number: new FormControl(values?.serial_number)
      }
    )
  }

  public saveDeliveryNote() {
    const { rma, ...formData } = this.deliveryNoteForm.value;
    this.submittingNote = true;
    const {
      sender_type, recipient_type, sender_technician: sender_technician_id,
      recipient_technician: recipient_technician_id, sender_customer: sender_customer_id,
      recipient_customer: recipient_customer_id, sender_warehouse: sender_warehouse_id,
      recipient_warehouse: recipient_warehouse_id, sender_store: sender_store_id,
      recipient_store: recipient_store_id
    } = this.addressesForm.value;
    const article_ids = (rma || []).filter((a:any) => (a.id > 0)).map((a:any) => (a.id));
    const data = {
      ...formData, article_ids,
      sender_type, recipient_type, sender_technician_id, recipient_technician_id, sender_customer_id,
      recipient_customer_id, sender_warehouse_id, recipient_warehouse_id, sender_store_id,
      recipient_store_id
    }
    if(this.deliveryNoteId) {
      this.update(data);
    } else {
      this.create(data);
    }
  }

  private create(params: any) {
    this.deliveryNoteService.create({delivery_note: params}).subscribe(
      {
        next: (response: any) => {
          this.router.navigate(['/', 'lieferscheine', response.id]);
        },
        error: (error) => {
          console.log('error', error);
          alert(`Please fix errors: ${error}`);
          this.submittingNote = false;
        }
      }
    )
  }

  private update(params: any) {
    this.deliveryNoteService.update(this.deliveryNoteId, {delivery_note: params}).subscribe(
      {
        next: (response: any) => {
          this.router.navigate(['/', 'lieferscheine', response.id]);
        },
        error: (error) => {
          console.log('error', error);
          alert(`Please fix errors: ${error}`);
          this.submittingNote = false;
        }
      }
    )
  }

  private loadCountries() {
    this.countryService.list().subscribe(
      {
        next: (countries) => {
          this.countries = countries;
        },
        error: (error) => {
          console.log('error', error);
        }
      }
    )
  }

  public onDeleteRma(rmaIndex: number) {
    const rmaFormControl = <FormArray>this.deliveryNoteForm.controls.rma;
    rmaFormControl.removeAt(rmaIndex);
  }

  private loadAddressValues(prefix = 'sender_' ,values = {}) {
    const defaultFields = [
      'address_city', 'address_company', 'address_country',
      'address_firstname', 'address_lastname', 'address_state',
      'address_street', 'address_street_no', 'address_zip'
    ];
    defaultFields.forEach((field) => {
      this.deliveryNoteForm.controls[`${prefix}${field}`].setValue(values[field] || values[`${prefix}${field}`] || '');
    });
  }

  public onAddressTypeChange(value: any, type: string = 'sender_') {
    if(value.id === 'FREE') {
      this.loadAddressValues(type);
    } else if(value.id === 'TECHNICIAN') {
      this.addressesForm.controls[`${type}technician`].setValue(this.technicians[0]?.id);
      this.loadAddressValues(type, this.technicians[0]);
    } else if(value.id === 'WAREHOUSE') {
      this.addressesForm.controls[`${type}warehouse`].setValue(this.wareHouses[0]?.id);
      this.loadAddressValues(type, this.wareHouses[0]);
    } else if(value.id === 'CUSTOMER') {
      this.loadCustomers(type, 1, true);
    }
  }

  private applyCustomerFilters(type: string, customer: any, page: number = 1, resetControl = true) {
    if(resetControl)
      this.addressesForm.controls[`${type}store`].setValue(null);
    if(type === 'sender_') {
      this.senderCustomerId = customer.id;
      this.loadCustomerStores('sender_', customer.id, page);
    } else {
      this.recipientCustomerId = customer.id;
      this.loadCustomerStores('recipient_', customer.id, page);
    }
  }

  public onAddressChange(values: any, type: string) {
    this.loadAddressValues(type, values);
  }

  public onChangeCustomer(value: any, type: string) {
    this.onAddressChange(value, type);
    this.applyCustomerFilters(type, value);
    if(type === 'sender_') {
      this.senderCustomerId = value.id;
    } else {
      this.recipientCustomerId = value.id;
    }
  }

  public onFetchMoreCustomers(type: string) {
    if(type === 'sender_') {
      if(this.senderCustomerTotalPages > this.senderCustomerPage) {
        this.senderCustomerPage += 1;
        this.loadCustomers(type, this.senderCustomerPage);
      }
    } else {
      if(this.recipientCustomerTotalPages > this.recipientCustomerPage) {
        this.recipientCustomerPage += 1;
        this.loadCustomers(type, this.recipientCustomerPage);
      }
    }
  }

  public onFetchMoreStores(type: string) {
    if(type === 'sender_') {
      if(this.senderTotalPages > this.senderPage) {
        this.senderPage += 1;
        this.loadCustomerStores(type, this.senderCustomerId, this.senderPage);
      }
    } else {
      if(this.recipientTotalPages > this.recipientPage) {
        this.recipientPage += 1;
        this.loadCustomerStores(type, this.recipientCustomerId, this.recipientPage);
      }
    }
  }

  public onRmaKeyDown(event: Event, rmaIndex: number) {
    event.preventDefault();
    const value = (event.target as HTMLInputElement).value;
    if(!value || value === undefined || value === '') return;

    const rmaFormControl = <FormArray>this.deliveryNoteForm.controls.rma;
    this.aricleService.searchArticles(value, { single_search: true }).subscribe(
      {
        next: (response: any) => {
          if(response && response.id) {
            const values = rmaFormControl.value;
            values[rmaIndex] = response;
            rmaFormControl.setValue(values);
            rmaFormControl.push(this.rmaGroup())
            setTimeout(() => {
              const inputElements = this.rmaInputs.toArray();
              const newInput = inputElements[inputElements.length - 1];
              newInput?.nativeElement.focus();
            });
          } else {
            const values = rmaFormControl.value;
            values[rmaIndex].id = -1;
            rmaFormControl.setValue(values);
          }
        },
        error: (error) => {
          console.log('error', error);
        }
      }
    );
  }

  private loadTechnicians() {
    this.userService.getTechnicians({address_detail: true}).subscribe(
      {
        next: (technicians) => {
          this.technicians = technicians;
        },
        error: (error) => {
          console.log('error', error);
        }
      }
    )
  }

  private loadWareHouses() {
    this.wareHouseService.loadWarehouses({ address_detail: true }).subscribe(
      {
        next: (wareHouses) => {
          this.wareHouses = wareHouses;
        },
        error: (error) => {
          console.log('error', error);
        }
      }
    )
  }

  private loadCustomers(customerType: string, page: number = 1, applyFirst = false, includingCustomerId = null) {
    const params: any = { page, per_page: 9999 }
    if(customerType === 'sender_') {
      this.senderCustomersLoading = true;
    } else {
      this.recipientCustomersLoading = true;
    }
    if(includingCustomerId) {
      params['id'] = includingCustomerId;
    }
    this.customerService.loadCustomers({ address_detail: true, ...params }).subscribe(
      {
        next: (response: any) => {
          let customer = null;
          if(customerType === 'sender_') {
            this.senderCustomerTotalPages = response.total_pages;
            this.senderCustomers = response.customers;
            this.senderCustomersLoading = false;
            customer = this.senderCustomers[0];
          } else {
            this.recipientCustomerTotalPages = response.total_pages;
            this.recipientCustomers = response.customers;
            this.recipientCustomersLoading = false;
            customer = this.recipientCustomers[0];
          }
          if(applyFirst && customer) {
            this.loadAddressValues(customerType, customer);
            this.applyCustomerFilters(customerType, customer);
          }
          if(includingCustomerId) {
            this.loadCustomerStores('sender_', customer.id, page);
            this.applyCustomerFilters(customerType, customer, 1, false);
          }
        },
        error: (error) => {
          console.log('error', error);
        }
      }
    )
  }

  private loadCustomerStores(customerType: string, customerId: any, page: number = 1) {
    const params: any = { page, per_page: 99999 }
    if(customerType === 'sender_') {
      this.senderLoading = true;
    } else {
      this.recipientLoading = true;
    }
    this.storeService.getCustomerStoresWithSearch(customerId, params).subscribe(
      {
        next: (response) => {
          if(customerType === 'sender_') {
            this.senderStores = response.stores;
            this.senderTotalPages = response.total_pages;
            this.senderLoading = false;
          } else {
            this.recipientStores = response.stores;
            this.recipientTotalPages = response.total_pages;
            this.recipientLoading = false;
          }
        },
        error: (error) => {
          console.log('error', error);
        }
      }
    )
  }

  private loadDeliveryNote() {
    this.deliveryNoteService.show(this.deliveryNoteId).subscribe(
      {
        next: (response: DeliveryNote) => {
          this.deliveryNote = response;
          if(this.deliveryNote.status === 'RECEIPT') {
            this.router.navigate(['/', 'lieferscheine', this.deliveryNoteId]);
          }
          this.isLoaded = true;
          this.initializeForm();
        },
        error: (error) => {
          console.log('error', error);
        }
      }
    )
  }

  private initializeForm() {
    const defaultDeliveryNote = this.deliveryNote || ({} as DeliveryNote);
    this.addressesForm = new UntypedFormGroup(
      {
        sender_type: new UntypedFormControl(defaultDeliveryNote.sender_type || 'FREE'),
        recipient_type: new UntypedFormControl(defaultDeliveryNote.recipient_type || 'FREE'),
        sender_technician: new UntypedFormControl(defaultDeliveryNote.sender_technician_id),
        recipient_technician: new UntypedFormControl(defaultDeliveryNote.recipient_technician_id),
        sender_customer: new UntypedFormControl(defaultDeliveryNote.sender_customer_id),
        recipient_customer: new UntypedFormControl(defaultDeliveryNote.recipient_customer_id),
        sender_warehouse: new UntypedFormControl(defaultDeliveryNote.sender_warehouse_id),
        recipient_warehouse: new UntypedFormControl(defaultDeliveryNote.recipient_warehouse_id),
        sender_store: new UntypedFormControl(defaultDeliveryNote.sender_store_id),
        recipient_store: new UntypedFormControl(defaultDeliveryNote.recipient_store_id),
      }
    )
    const rmas = this.deliveryNoteId ? this.deliveryNote
    .articles.map((article) => (this.rmaGroup(article))) : [this.rmaGroup()];
    if(this.deliveryNoteId) {
      this.setRmasDefaultValue = true;
    }
    if(rmas.length <= 0) {
      rmas.push(this.rmaGroup());
    } else if(this.deliveryNoteId) {
      rmas.push(this.rmaGroup());
    }
    this.deliveryNoteForm = new FormGroup(
      {
        sender_address_city: new FormControl(defaultDeliveryNote.sender_address_city),
        sender_address_company: new FormControl(defaultDeliveryNote.sender_address_company),
        sender_address_country: new FormControl(defaultDeliveryNote.sender_address_country || 'deu'),
        sender_address_firstname: new FormControl(defaultDeliveryNote.sender_address_firstname),
        sender_address_lastname: new FormControl(defaultDeliveryNote.sender_address_lastname),
        sender_address_state: new FormControl(defaultDeliveryNote.sender_address_state),
        sender_address_street: new FormControl(defaultDeliveryNote.sender_address_street),
        sender_address_street_no: new FormControl(defaultDeliveryNote.sender_address_street_no),
        sender_address_zip: new FormControl(defaultDeliveryNote.sender_address_zip),
        recipient_address_city:  new FormControl(defaultDeliveryNote.recipient_address_city),
        recipient_address_company:  new FormControl(defaultDeliveryNote.recipient_address_company),
        recipient_address_country:  new FormControl(defaultDeliveryNote.recipient_address_country || 'deu'),
        recipient_address_firstname:  new FormControl(defaultDeliveryNote.recipient_address_firstname),
        recipient_address_lastname:  new FormControl(defaultDeliveryNote.recipient_address_lastname),
        recipient_address_state:  new FormControl(defaultDeliveryNote.recipient_address_state),
        recipient_address_street:  new FormControl(defaultDeliveryNote.recipient_address_street),
        recipient_address_street_no:  new FormControl(defaultDeliveryNote.recipient_address_street_no),
        recipient_address_zip:  new FormControl(defaultDeliveryNote.recipient_address_zip),
        info:  new FormControl(defaultDeliveryNote.info),
        order_number:  new FormControl(defaultDeliveryNote.order_number),
        rma: this.fb.array(rmas)
      }
    );
    if(defaultDeliveryNote.sender_customer_id) {
      this.loadCustomers('sender_', 1, false, defaultDeliveryNote.sender_customer_id);
    }
    if(defaultDeliveryNote.recipient_customer_id) {
      this.loadCustomers('sender_', 1, false, defaultDeliveryNote.recipient_customer_id);
    }
  }

  ngOnInit(): void {
    this.deliveryNoteId = parseInt(this.route.snapshot.paramMap.get('id'));
    if(this.deliveryNoteId) {
      this.loadDeliveryNote();
    } else {
      this.initializeForm();
      this.isLoaded = true;
    }
    this.loadCountries();
    this.loadTechnicians();
    this.loadWareHouses();
    // this.loadStores();
  }

  get rmas() {
    return this.deliveryNoteForm.get('rma') as FormArray;
  }

}