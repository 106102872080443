import { CommonModule } from "@angular/common";
import { ReactiveFormsModule } from "@angular/forms";
import { MatFormFieldModule } from "@angular/material/form-field";

import { Component, OnInit } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms'
import { WarehouseService } from '../_services'
import { environment } from 'src/environments/environment'
import { DeliveryNote } from '../_models'

@Component({
  imports: [CommonModule, ReactiveFormsModule, MatFormFieldModule],
    selector: 'hawk-warehouse-retoure',
    templateUrl: './warehouse-retoure.component.html',
    styleUrls: ['./warehouse-retoure.component.scss'],
})
export class WarehouseRetoureComponent implements OnInit {

  form: UntypedFormGroup
  env = environment

  status = 'new'
  errorItems: string[][] = [[]]
  errorText: string

  returnJobs: DeliveryNote[] = []

  constructor(
    private route: ActivatedRoute,
    private warehouseService: WarehouseService,
  ) { }

  ngOnInit(): void {
    this.resetForm()
  }

  public resetForm(): void {
    this.form = new UntypedFormGroup({
      warehouse_id: new UntypedFormControl(this.route.snapshot.paramMap.get('id')),
      parcel_weight: new UntypedFormControl(),
      parcel_length: new UntypedFormControl(),
      parcel_width: new UntypedFormControl(),
      parcel_height: new UntypedFormControl(),
    })
  }

  onSubmit() {
    console.log(this.form.value)
    this.status = 'sending'

    this.warehouseService.createIntRetoure(this.form.value).subscribe(
      (res) => {
        console.log(res)
        this.returnJobs.push(res)
        this.form.reset()
        this.resetForm()
        this.status = 'new'
      },
      (err) => {
        console.error(err)
        this.status = 'error'
        this.errorText = 'es ist etwas schief gegangen.'
      }
    )
  }
}