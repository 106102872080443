<div>
    <div class="ticketSearch">
        <h2>Neue Abfrage</h2>
        <form (submit)="search()" [formGroup]="articleSearchForm" style="width: 90%">
            <table style="width: 100%">
                <tr>
                <td *ngIf="statusList">
                    <mat-label>Status</mat-label>
                    <ng-select
                    [items]="statusList"
                    bindLabel="description"
                    bindValue="id"
                    formControlName="status_id"
                    placeholder="Status auswählen"
                    >
                    <ng-template ng-label-tmp let-item="item">
                        {{ item.description }}
                    </ng-template>
                    <ng-template
                        ng-option-tmp
                        let-item="item"
                        let-search="searchTermStatus"
                    >
                        <div
                        *ngIf="
                            item.description
                            .toLowerCase()
                            .includes(searchTerm.toLowerCase())
                        "
                        >
                        {{ item.description }}
                        </div>
                    </ng-template>
                    </ng-select>
                </td>
                <td>
                    <mat-label>Produkt</mat-label>
                    <ng-select
                    [items]="products"
                    bindLabel="description"
                    bindValue="id"
                    formControlName="product_id"
                    placeholder="Produkt"
                    >
                    <ng-template ng-label-tmp let-item="item">
                        {{ item.description }}
                    </ng-template>
                    <ng-template
                        ng-option-tmp
                        let-item="item"
                        let-search="searchTermStatus"
                    >
                        <div
                        *ngIf="
                            item.description
                            .toLowerCase()
                            .includes(searchTerm.toLowerCase())
                        "
                        >
                        {{ item.description }}
                        </div>
                    </ng-template>
                    </ng-select>
                </td>
                </tr>
                <tr>
                <td>
                    <mat-form-field appearance="outline">
                    <mat-label>SN</mat-label>
                    <input
                        matInput
                        type="text"
                        placeholder="SN"
                        formControlName="serial_number"
                    />
                    </mat-form-field>
                </td>
                <td>
                    <mat-form-field appearance="outline">
                    <mat-label>RMA</mat-label>
                    <input
                        matInput
                        type="text"
                        placeholder="RMA"
                        formControlName="rma"
                    />
                    </mat-form-field>
                </td>
                </tr>
                <tr>
                    <td>
                        <button class="button mt-3">Abfrage starten</button>
                    </td>
                    <td>
                        <div class="d-flex align-items-center gap-1 justify-content-end">
                            <a class="btn btn-primary d-flex gap-1 align-items-center" [routerLink]="['/', 'artikel', 'neu']">
                                <i class="fa fa-long-arrow-right"></i><i class="fa fa-building-o"></i>Wareneingang
                            </a>
                            <a class="btn btn-seconday" href="/warehouse/article/labelTest">Etikettentest</a>
                        </div>
                    </td>
                </tr>
            </table>
        </form>
    </div>
</div>