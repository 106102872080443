import { Component, OnInit } from '@angular/core';
import { PAProgress } from '../../classes/progress';
import { faCheck, faUpload } from '@fortawesome/free-solid-svg-icons';
import { PAUtil } from '../../classes/util';
import { NgClass, NgIf, NgFor } from '@angular/common';

import { MatProgressBar } from '@angular/material/progress-bar';
import { ExpandBarHeaderComponent } from '../expand-bar-header/expand-bar-header.component';
import { CommonModule } from "@angular/common";
import { FaIconComponent, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ThrobberComponent } from "../../../_shared/throbber/throbber.component";

@Component({
  selector: 'hawk-loading-container',
  templateUrl: './loading-container.component.html',
  styleUrls: ['./loading-container.component.scss', './../../styles/common_styles.scss'],
  standalone: true,
  imports: [NgClass, FaIconComponent, NgIf, MatProgressBar, ThrobberComponent, ExpandBarHeaderComponent, NgFor, CommonModule, FontAwesomeModule]
})
export class LoadingContainerComponent implements OnInit {

  faUpload = faUpload
  faCheck = faCheck

  showProgressInfo = false
  showProgresses = false

  showRunningProgresses = true
  showFinishedProgresses = false

  showSubProgressesForProgresses: PAProgress[] = []

  Progress = PAProgress

  constructor() {
  }

  ngOnInit(): void {
  }

  toggleShowProgresses() {
    this.showProgresses = !this.showProgresses
  }

  toggleShowSubProgressesForProgress(progress: PAProgress): void {
    if (this.showSubProgressesForProgresses.includes(progress)) {
      PAUtil.removeElementFromList(this.showSubProgressesForProgresses, progress)
    } else {
      this.showSubProgressesForProgresses.push(progress)
    }
  }

}