<div id="operation_collection" style="min-width: 100%; border: 1px solid black; display: inline-flex; flex-direction: column;">
  <div style="width: 100%; display: inline-flex; flex-direction: row; height: 62px; background-color: #292929;">
    <div style="text-align: center; min-width: 160px; max-width: 160px; display: inline-flex; border-bottom: 1px solid black; border-right: 1px solid black;">
      <div style="margin: auto; display: inline-flex; flex-direction: row;">
        <ng-container *ngIf="technicians.length == 1">
          <div style="margin: auto; width: 12px; height: 12px; border-radius: 6px; border: 1px solid black; " [style.background-color]="technicians[0].getColorString()"></div>
          <div style="margin: auto; padding: 2px 4px; color: white; line-height: 14px;">
            {{technicians[0].getFullName(10)}}
          </div>
        </ng-container>
      </div>
    </div>
    <div *ngIf="!technicians.length" style="min-width: 124px; scrollbar-gutter: stable; overflow-y: auto; border-bottom: 1px solid black; flex: 1; display: inline-flex; flex-direction: column;">
      <div style="margin: auto; text-align: center; white-space: nowrap; display: inline-flex; flex-direction: row;">
        <div style="margin: auto auto auto 4px;">
          &plus; Techniker
        </div>
      </div>
      <div style="width: 100%; text-align: center; display: inline-flex; flex-direction: row;">
        <div style="width: 100%; display: inline-flex;">
          <div style="margin: auto; flex: 1;">
            Mat.
          </div>
          <div style="margin: auto; flex: 1;">
            Proj.
          </div>
          <div style="margin: auto; flex: 1;">
            &#8960;t
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="technicians.length && technicians.length != 1" style="display: inline-flex; scrollbar-gutter: stable; overflow-y: auto; flex:1; border-bottom: 1px solid black;">
      <div *ngFor="let technician of technicians" style="min-width: 124px; border-right: 1px solid black; flex: 1; display: inline-flex; flex-direction: column;">
        <div style="margin: auto; text-align: center; white-space: nowrap; display: inline-flex; flex-direction: row;">
          <div style="margin: auto; width: 12px; height: 12px; border-radius: 6px; border: 1px solid black; " [style.background-color]="technician.getColorString()"></div>
          <div style="margin: auto; padding: 2px 4px; color: white; line-height: 14px;">
            {{technician.getFullName(10)}}
          </div>
          <button (click)="deselectTechnicianEvent.emit(technician)" style="margin: auto; width: 20px; padding: 0 2px; text-align: center; background-color: rgba(0,0,0,0); border: none;">
            <fa-icon style="color: white;" [icon]="faWindowClose"></fa-icon>
          </button>
        </div>
        <div style="width: 100%; text-align: center; display: inline-flex; flex-direction: row;">
          <div style="width: 100%; display: inline-flex;">
            <div style="margin: auto; flex: 1;">
              Ma
            </div>
            <div style="margin: auto; flex: 1;">
              Pr
            </div>
            <div style="margin: auto; flex: 1;">
              &#8960;t
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="technicians.length == 1" style="display: inline-flex; position: relative; scrollbar-gutter: stable; overflow-y: auto; flex:1; border-bottom: 1px solid black;">
      <div style="width: 100px; margin: auto 0;">
        <div style="width: 100%; display: inline-flex; text-align: center;">
          <div style="margin: auto; flex: 1;">
            Ma
          </div>
          <div style="margin: auto; flex: 1;">
            Pr
          </div>
          <div style="margin: auto; flex: 1;">
            &#8960;t
          </div>
        </div>
      </div>
      <div class="change_cw_button left black_gradient_background" (click)="prevCWEvent.emit()">
        <div style="margin: auto;">
          ❬
        </div>
      </div>
      <div class="vertical_flex" style="flex: 1;">
        <div class="black_gradient_background" style="text-align: center; border: 1px solid black;" (click)="openCompleteCalendarWeekForTechnician(technicians[0])">
          Komplette Woche öffnen
        </div>
        <div class="horizontal_flex" style="flex: 1;">
          <div *ngFor="let week_day of filteredWeekdays" style="min-width: 64px; height: 100%; border-right: 1px solid black; flex: 1; display: inline-flex; flex-direction: column;">
            <div class="weekday_container"
                 [ngClass]="(tourComparisonsIncludeTechnicianDate(technicians[0].getTechnicianDate(getShownCalendarWeekData().weekdays[week_day].timestamp, true)) ? 'selected' : 'black_gradient_background')"
                 (click)="clickTechnicianDateEvent.emit({technician_id: technicians[0].id, technician_dates: [technicians[0].getTechnicianDate(getShownCalendarWeekData().weekdays[week_day].timestamp, true)]})">
              <div class="week_day">
                <div style="margin: auto; line-height: 11px;">
                  {{PATimeControl.Instance.weekDayToGermanName(week_day, true)}}
                  {{PATimeControl.Instance.timestampToDatestring(getShownCalendarWeekData().weekdays[week_day].timestamp, false, '.').slice(0, 5)}}
                </div>
                <div style="margin: auto; line-height: 11px;"
                     [ngClass]="(25 > technicians[0].getTechnicianDate(getShownCalendarWeekData().weekdays[week_day].timestamp, true).tour.route.time_specific_data[operationTimeFilter].workload_percent) ? 'green' : (technicians[0].getTechnicianDate(getShownCalendarWeekData().weekdays[week_day].timestamp, true).tour.route.time_specific_data[operationTimeFilter].workload_percent > 75 ? 'red' : 'yellow')" >
                  {{technicians[0].getTechnicianDate(getShownCalendarWeekData().weekdays[week_day].timestamp, true).tour.route.time_specific_data[operationTimeFilter].workload_percent}}%
                </div>
              </div>
              <div style="position: absolute; bottom: 0; left: 0; right: 0; height: 6px;" [style.background-color]="Util.rgbaToString(Util.getIndexColorRGBA(PATimeControl.Instance.weekDays.indexOf(week_day)))"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="black_gradient_background change_cw_button right" (click)="nextCWEvent.emit()">
        <div style="margin: auto;">
          ❭
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="storeOperations?.length" style="width: 100%; display: inline-flex; flex-direction: row; max-height: 300px; scrollbar-gutter: stable; overflow-y: auto; overflow-x: hidden;">
    <div style="min-width: 160px; max-width: 160px; display: inline-flex; flex-direction: column; border-top: 1px solid black;">
      <div *ngFor="let store_operation_data of storeOperations" [id]="store_operation_data.store.address_company" (click)="selectStore(store_operation_data.store)" 
      class="store" [ngClass]="store_operation_data.store == scrollToStore ? 'selected' : ''">
        <div style="display: inline-flex; flex-direction: row; height: 16px; padding: 2px; padding-bottom: 0; position: relative;">
          <div style="margin: auto; margin-left: 0; flex: 1;" [tooltip]="store_operation_data.store.address_company?.length >= 26 ? store_operation_data.store.address_company : null">
            {{store_operation_data.store.address_company?.length < 26 ? store_operation_data.store.address_company : store_operation_data.store.address_company.slice(0, 23) + '...'}}
            <ng-container *ngIf="store_operation_data.store == scrollToStore">
              &#9733;
            </ng-container>
          </div>
          <div style="position: absolute;top: 0;right: 0;bottom: 0;">
            <ng-container *ngIf="store_operation_data.store.letter">
              <div style="background-color: #673ab7; color: white; border-radius: 50%; padding: 2px 4px; font-size: 9px; line-height: 9px; border: 1px solid white;">
                {{store_operation_data.store.letter}}
              </div>
            </ng-container>
          </div>
        </div>
        <div *ngFor="let operation of store_operation_data.operations" class="black_gradient_background" 
        style="border: 1px solid black; border-right: 0; height: 20px; display: inline-flex; flex-direction: row; position: relative; padding: 2px;"
        [tooltip]="operation.description + (operation.ticket.memos?.length? (' | ' + operation.ticket.memos.join(', ')) : '')">
          <div style="margin: auto; margin-left: 0; flex: 1; line-height: 14px;">
            {{operation.ticket.priority.name}}
          </div>
          <div style="margin: auto; margin-right: 18px; line-height: 14px;">
            <i>{{operation.ticket.time_estimation}}min</i>
          </div>
          <button (click)="$event.stopPropagation();deselectOperationEvent.emit(operation)" class="cancel_button" style="text-align: center; z-index: 10; right: 8px; top: 9px; line-height: 10px;">
            <fa-icon style="color: white;" [icon]="faWindowClose"></fa-icon>
          </button>
        </div>
      </div>
    </div>
    <div *ngIf="!technicians.length" style="min-width: 124px; flex: 1; display: inline-flex; flex-direction: column;">
      <div style="flex: 1; display: inline-flex;">
        <div style="margin: auto;">
          <h6>Bitte wählen Sie einen Techniker aus...</h6>
        </div>
      </div>
    </div>
    <div id="field_action_container" *ngIf="technicians.length && technicians.length != 1" style="position: relative; display: inline-flex; flex-direction: row; flex:1">
      <div *ngFor="let technician of technicians" style="min-width: 124px; flex: 1; display: inline-flex; flex-direction: column; border-top: 1px solid black;">
        <div *ngFor="let store_operation_data of storeOperations" style="border-right: 1px solid black; padding: 0;">
          <div style="height: 16px; font-size: 12px; width: 100%; padding: 8px; position: relative;">
            <div style="position: absolute; display: inline-flex; flex-direction: row; top: 0; left: 0; bottom: 0; right: 0;" >
              <div style="padding: 0 4px; font-size: 11px; width: 100%; background-color: #3f3f3f;">
                {{store_operation_data.location.getSyncStreetDurationToLocation(technician.location, technician.driving_time_factor)}}min
                | 
                {{store_operation_data.location.getSyncStreetDistanceToLocation(technician.location)}}km
              </div>
            </div>
          </div>
          <div *ngFor="let operation of store_operation_data.operations" style="width: 100%; height: 20px; cursor: pointer;" class="pi_field" 
          [id]="'o:'+ operation.id + '_t:' + technician.id"
          [ngClass]="getPlanningInstructionClass(operation, technician)" 
          (mousedown)="onFieldMouseDown({operation: operation, technician: technician})"
          (mouseenter)="onFieldMouseEnter({operation: operation, technician: technician})">
            <div style="display: inline-flex; flex-direction: row; width: 100%; height: 16px;">
              <div style="margin: auto; flex: 1; text-align: center; line-height: 12px;">
                <ng-container *ngIf="technician.articles">
                  <ng-container *ngIf="technician.hasTicketMaterial(operation.ticket)">
                    <fa-icon [icon]="faCheck" style="color: rgb(113, 255, 47);" [ngClass]="getPlanningInstructionClass(operation, technician) == 'not_selected' ? 'not_selected' : ''"></fa-icon>
                  </ng-container>
                  <ng-container *ngIf="!technician.hasTicketMaterial(operation.ticket)">
                    &#x274C;
                  </ng-container>
                </ng-container>
                <ng-container *ngIf="!technician.articles">
                  ?
                </ng-container>
              </div>
              <div style="margin: auto; flex: 1; text-align: center; line-height: 12px;">
                <ng-container *ngIf="technician.isProjectTechnician(operation.ticket.project.id)">
                  <fa-icon [icon]="faCheck" style="color: rgb(113, 255, 47);" [ngClass]="getPlanningInstructionClass(operation, technician) == 'not_selected' ? 'not_selected' : ''"></fa-icon>
                </ng-container>
                <ng-container *ngIf="!technician.isProjectTechnician(operation.ticket.project.id)">
                  &#x274C;
                </ng-container>
              </div>
              <div style="margin: auto; flex: 1; text-align: center; line-height: 12px;">
                <ng-container *ngIf="technician.getPriorityBasedAverageOperationTime(operation.ticket.priority.id) >= 0">
                  {{technician.getPriorityBasedAverageOperationTime(operation.ticket.priority.id)}}<sub>min</sub>
                </ng-container>
                <ng-container  *ngIf="0 > technician.getPriorityBasedAverageOperationTime(operation.ticket.priority.id)">
                  <ng-container *ngIf="technician.getPriorityBasedAverageOperationTime(operation.ticket.priority.id) == -1">
                    -
                  </ng-container>
                  <ng-container *ngIf="technician.getPriorityBasedAverageOperationTime(operation.ticket.priority.id) != -1">
                    Err
                  </ng-container>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div id="field_action_container" *ngIf="technicians.length == 1" style="position: relative; display: inline-flex; flex-direction: row; flex:1">
      
      <div style="width: 100px; margin: auto 0;">
        <div *ngFor="let store_operation_data of storeOperations" style="border-right: 1px solid black; padding: 0;">
          <div style="height: 16px; font-size: 12px; width: 100%; padding: 8px; position: relative;">
            <div style="position: absolute; display: inline-flex; flex-direction: row; top: 0; left: 0; bottom: 0; right: 0;" >
              <div style="padding: 0 4px; font-size: 11px; width: 100%; background-color: #3f3f3f;">
                {{store_operation_data.location.getSyncStreetDurationToLocation(technicians[0].location, technicians[0].driving_time_factor)}}min
                | 
                {{store_operation_data.location.getSyncStreetDistanceToLocation(technicians[0].location)}}km
              </div>
            </div>
          </div>
          <div *ngFor="let operation of store_operation_data.operations" style="width: 100%; height: 20px; cursor: pointer; background-color: #5a5a5a;">
            <div style="display: inline-flex; flex-direction: row; width: 100%; height: 16px;">
              <div style="margin: auto; flex: 1; text-align: center; line-height: 12px;">
                <ng-container *ngIf="technicians[0].articles">
                  <ng-container *ngIf="technicians[0].hasTicketMaterial(operation.ticket)">
                    <fa-icon [icon]="faCheck" style="color: rgb(113, 255, 47);" [ngClass]="getPlanningInstructionClass(operation, technicians[0]) == 'not_selected' ? 'not_selected' : ''"></fa-icon>
                  </ng-container>
                  <ng-container *ngIf="!technicians[0].hasTicketMaterial(operation.ticket)">
                    &#x274C;
                  </ng-container>
                </ng-container>
                <ng-container *ngIf="!technicians[0].articles">
                  ?
                </ng-container>
              </div>
              <div style="margin: auto; flex: 1; text-align: center; line-height: 12px;">
                <ng-container *ngIf="technicians[0].isProjectTechnician(operation.ticket.project.id)">
                  <fa-icon [icon]="faCheck" style="color: rgb(113, 255, 47);" [ngClass]="getPlanningInstructionClass(operation, technicians[0]) == 'not_selected' ? 'not_selected' : ''"></fa-icon>
                </ng-container>
                <ng-container *ngIf="!technicians[0].isProjectTechnician(operation.ticket.project.id)">
                  &#x274C;
                </ng-container>
              </div>
              <div style="margin: auto; flex: 1; text-align: center; line-height: 12px;">
                <ng-container *ngIf="technicians[0].getPriorityBasedAverageOperationTime(operation.ticket.priority.id) >= 0">
                  {{technicians[0].getPriorityBasedAverageOperationTime(operation.ticket.priority.id)}}<sub>min</sub>
                </ng-container>
                <ng-container  *ngIf="0 > technicians[0].getPriorityBasedAverageOperationTime(operation.ticket.priority.id)">
                  <ng-container *ngIf="technicians[0].getPriorityBasedAverageOperationTime(operation.ticket.priority.id) == -1">
                    -
                  </ng-container>
                  <ng-container *ngIf="technicians[0].getPriorityBasedAverageOperationTime(operation.ticket.priority.id) != -1">
                    Err
                  </ng-container>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style="width: 15px; border-right: 1px solid black; border-left: 1px solid black;"></div>
      <div *ngFor="let week_day of filteredWeekdays" style="min-width: 64px; flex: 1; display: inline-flex; flex-direction: column;">
        <div *ngFor="let store_operation_data of storeOperations" style="border-right: 1px solid black; padding: 0;">
          <div style="height: 16px; font-size: 12px; width: 100%; padding: 8px; position: relative;">
            <div style="position: absolute; display: inline-flex; flex-direction: row; top: 0; left: 0; bottom: 0; right: 0;" >
              <div style="padding: 0 4px; font-size: 11px; width: 100%; background-color: #3f3f3f;">
              </div>
            </div>
          </div>
          <div *ngFor="let operation of store_operation_data.operations" style="width: 100%; height: 20px; cursor: pointer;" class="pi_field" 
          [id]="'o:'+ operation.id + '_w:' + week_day"
          [ngClass]="getWeekdayPlanningInstructionClass(operation, week_day)"
          (mousedown)="onWOFieldMouseDown({operation: operation, weekday: week_day})"
          (mouseenter)="onWOFieldMouseEnter({operation: operation, weekday: week_day})">
            <div style="display: inline-flex; flex-direction: row; width: 100%; height: 16px;">
              <ng-container *ngIf="PATourPlannerControl.Instance.getTechnicianDateCollectionForTechnician(technicians[0]) && PATourPlannerControl.Instance.hasTechnicianDateCollectionForTechnicianWithTechnicianDateTimestamp(technicians[0], getShownCalendarWeekData().weekdays[week_day].timestamp)">
                <ng-container *ngIf="PATourPlannerControl.Instance.getTechnicianDateCollectionForTechnician(technicians[0]).showTourMode == 'new' && technicians[0].getTechnicianDate(getShownCalendarWeekData().weekdays[week_day].timestamp).changedTourContainer.tour.getRouteIdxForOperationWithId(operation.id) != -1">
                  <div class="operation_index_content">
                    <div style="margin: auto; line-height: .85rem;">
                      {{technicians[0].getTechnicianDate(getShownCalendarWeekData().weekdays[week_day].timestamp).changedTourContainer.tour.getRouteIdxForOperationWithId(operation.id) + 1}}
                    </div>
                  </div>
                </ng-container>
                <ng-container *ngIf="PATourPlannerControl.Instance.getTechnicianDateCollectionForTechnician(technicians[0]).showTourMode == 'base' && technicians[0].getTechnicianDate(getShownCalendarWeekData().weekdays[week_day].timestamp).tour.getRouteIdxForOperationWithId(operation.id) != -1">
                  <div class="operation_index_content">
                    <div style="margin: auto; line-height: .85rem;">
                      {{technicians[0].getTechnicianDate(getShownCalendarWeekData().weekdays[week_day].timestamp).tour.getRouteIdxForOperationWithId(operation.id) + 1}}
                    </div>
                  </div>
                </ng-container>
                <ng-container *ngIf="technicianDateHasUnfittingOperationWithId(technicians[0].getTechnicianDate(getShownCalendarWeekData().weekdays[week_day].timestamp), operation.id)">
                  <div style="margin: auto; line-height: .85rem;" [tooltip]="'Konnte nicht eingefügt werden (Max. Tour Zeit überschritten)'">
                    <fa-icon style="color: orange;" [icon]="faExclamationTriangle"></fa-icon>
                  </div>
                </ng-container>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ng-container *ngIf="technicians.length == 1">
    <div style="width: 100%; display: inline-flex; flex-direction: row; height: 20px; scrollbar-gutter: stable; overflow: auto;">
      <div style="min-width: 260px; max-width: 260px; display: inline-flex; flex-direction: row; border-right: 1px solid black; background-color: #292929;">
        <div style="margin: auto 4px auto auto;">
          Fahrtzeit
        </div>
      </div>
      <div style="width: 15px; border-right: 1px solid black; border-left: 1px solid black;"></div>
      <div *ngFor="let weekday of filteredWeekdays" style="min-width: 64px; flex: 1; display: inline-flex; border-right: 1px solid black; border-top: 1px solid black; background-color: #292929;">
        <div style="margin: auto; font-size: .7rem">
          {{technicians[0].getTechnicianDate(getShownCalendarWeekData().weekdays[weekday].timestamp).tour.route.time_specific_data[PAFilterControl.Instance.selectedOperationTimeFilter].driving_time}}<ng-container *ngIf="!showNewTourTimeForTechnicianDate(technicians[0].getTechnicianDate(getShownCalendarWeekData().weekdays[weekday].timestamp))"><sub>min</sub></ng-container>
          <ng-container *ngIf="showNewTourTimeForTechnicianDate(technicians[0].getTechnicianDate(getShownCalendarWeekData().weekdays[weekday].timestamp))">
            <fa-icon style="margin: 0 2px;" [icon]="faArrowRight"></fa-icon>{{technicians[0].getTechnicianDate(getShownCalendarWeekData().weekdays[weekday].timestamp).changedTourContainer.tour.route.time_specific_data[PAFilterControl.Instance.selectedOperationTimeFilter].driving_time}}
          </ng-container>
        </div>
      </div>
    </div>
    <div style="width: 100%; display: inline-flex; flex-direction: row; height: 20px; scrollbar-gutter: stable; overflow: auto;">
      <div style="min-width: 260px; max-width: 260px; display: inline-flex; flex-direction: row; border-right: 1px solid black; background-color: #292929;">
        <div style="margin: auto 4px auto auto;">
          Aufträge
        </div>
      </div>
      <div style="width: 15px; border-right: 1px solid black; border-left: 1px solid black;"></div>
      <div *ngFor="let weekday of filteredWeekdays" style="min-width: 64px; flex: 1; display: inline-flex; border-right: 1px solid black; border-top: 1px solid black; background-color: #292929;">
        <div style="margin: auto; font-size: .7rem">
          {{technicians[0].getTechnicianDate(getShownCalendarWeekData().weekdays[weekday].timestamp).tour.route.time_specific_data[PAFilterControl.Instance.selectedOperationTimeFilter].operation_work_time}}<ng-container *ngIf="!showNewTourTimeForTechnicianDate(technicians[0].getTechnicianDate(getShownCalendarWeekData().weekdays[weekday].timestamp))"><sub>min</sub></ng-container>
          <ng-container *ngIf="showNewTourTimeForTechnicianDate(technicians[0].getTechnicianDate(getShownCalendarWeekData().weekdays[weekday].timestamp))">
            <fa-icon style="margin: 0 2px;" [icon]="faArrowRight"></fa-icon>{{technicians[0].getTechnicianDate(getShownCalendarWeekData().weekdays[weekday].timestamp).changedTourContainer.tour.route.time_specific_data[PAFilterControl.Instance.selectedOperationTimeFilter].operation_work_time}}
          </ng-container>
        </div>
      </div>
    </div>
    <div style="width: 100%; display: inline-flex; flex-direction: row; height: 20px; scrollbar-gutter: stable; overflow: auto;">
      <div style="min-width: 260px; max-width: 260px; display: inline-flex; flex-direction: row; border-right: 1px solid black; background-color: #292929;">
        <div style="margin: auto 4px auto auto;">
          Gesamt
        </div>
      </div>
      <div style="width: 15px; border-right: 1px solid black; border-left: 1px solid black;"></div>
      <div *ngFor="let weekday of filteredWeekdays" style="min-width: 64px; flex: 1; display: inline-flex; border-right: 1px solid black; border-top: 1px solid black; background-color: #292929;">
        <div style="margin: auto; font-size: .7rem">
          {{technicians[0].getTechnicianDate(getShownCalendarWeekData().weekdays[weekday].timestamp).tour.route.time_specific_data[PAFilterControl.Instance.selectedOperationTimeFilter].total_time}}<ng-container *ngIf="!showNewTourTimeForTechnicianDate(technicians[0].getTechnicianDate(getShownCalendarWeekData().weekdays[weekday].timestamp))"><sub>min</sub></ng-container>
          <ng-container *ngIf="showNewTourTimeForTechnicianDate(technicians[0].getTechnicianDate(getShownCalendarWeekData().weekdays[weekday].timestamp))">
            <fa-icon style="margin: 0 2px;" [icon]="faArrowRight"></fa-icon>{{technicians[0].getTechnicianDate(getShownCalendarWeekData().weekdays[weekday].timestamp).changedTourContainer.tour.route.time_specific_data[PAFilterControl.Instance.selectedOperationTimeFilter].total_time}}
          </ng-container>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="technicians.length != 1" >
    <div style="width: 100%; display: inline-flex; flex-direction: row; height: 28px; scrollbar-gutter: stable; overflow: auto;">
      <div style="min-width: 160px; max-width: 160px; display: inline-flex; flex-direction: row; border-right: 1px solid black; background-color: #292929;">
        <div style="margin: auto 4px auto auto;">
        </div>
      </div>
      <div *ngFor="let technician of technicians" style="min-width: 124px; flex: 1; display: inline-flex; border-right: 1px solid black; border-top: 1px solid black; background-color: #292929;">
        <div style="margin: auto;">
          +{{getTechniciansSelectedAdditionalTime(technician)}}<sub>min</sub>
        </div>
      </div>
    </div>
    <div style="display: inline-flex; flex-direction: row; scrollbar-gutter: stable; overflow-y: auto; ">
      <div style="min-width: 160px; max-width: 160px; display: inline-flex; flex-direction: row; border-right: 1px solid black;">
        <div style="border: 1px solid black; display: inline-flex; padding: 2px 4px;" class="black_gradient_background" (click)="prevCWEvent.emit()">
          <div style="margin: auto;">&#9664;</div>
        </div>
        <div class="black_gradient_background_no_hover" style="display: inline-flex; flex-direction: column; border: 1px solid black; flex: 1; font-size: .8rem;">
          <div style="margin: auto;">KW {{getShownCalendarWeekData().calendar_week.number}} {{getShownCalendarWeekData().calendar_week.year}}</div>
          <div style="margin: auto;">
            {{PATimeControl.Instance.timestampToDatestring(getShownCalendarWeekData().weekdays['monday'].timestamp, false, '.').slice(0, 5)}}
            - {{PATimeControl.Instance.timestampToDatestring(getShownCalendarWeekData().weekdays['sunday'].timestamp, false, '.').slice(0, 5)}} </div>
        </div>
        <div style="border: 1px solid black; display: inline-flex; padding: 2px 4px;" class="black_gradient_background" (click)="nextCWEvent.emit()">
          <div style="margin: auto;">&#9654;</div>
        </div>
      </div>
      <div *ngFor="let technician of technicians" style="flex: 1; min-width: 124px; display: inline-flex; flex-direction: row; flex-wrap: wrap; border-right: 1px solid black; border-top: 1px solid black;">
        <div *ngFor="let week_day of filteredWeekdays"
             class="weekday_container"
             [ngClass]="(tourComparisonsIncludeTechnicianDate(technician.getTechnicianDate(getShownCalendarWeekData().weekdays[week_day].timestamp)) ? 'selected' : 'black_gradient_background')"
             (click)="clickTechnicianDateEvent.emit({technician_id: technician.id, technician_dates: [technician.getTechnicianDate(getShownCalendarWeekData().weekdays[week_day].timestamp)]})">
          <div class="week_day">
            <div style="margin: auto; line-height: 11px;">
              {{PATimeControl.Instance.weekDayToGermanName(week_day, true)}}
            </div>
            <div style="margin: auto; line-height: 11px;"
                 [ngClass]="(25 > technician.getTechnicianDate(getShownCalendarWeekData().weekdays[week_day].timestamp).tour.route.time_specific_data[operationTimeFilter]?.workload_percent) ? 'green' : (technician.getTechnicianDate(getShownCalendarWeekData().weekdays[week_day].timestamp).tour.route.time_specific_data[operationTimeFilter]?.workload_percent > 75 ? 'red' : 'yellow')" >
              {{technician.getTechnicianDate(getShownCalendarWeekData().weekdays[week_day].timestamp).tour.route.time_specific_data[operationTimeFilter]?.workload_percent}}%
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</div>