import * as mapboxgl from "mapbox-gl";
import { PACoordinates } from "./coordinates";
import { TravelTechnicianDate } from "./travel-technician-date";
import { PAUtil } from "./util";
import { PADataControl } from "../singletons/pa-data-control";
import { PATimeControl } from "../singletons/pa-time-control";

export class DraggableDestinationMarker {

  public marker: mapboxgl.Marker
  private _coordinates: PACoordinates
  private _currentCircleId: string = ''

  constructor(
    private _map: mapboxgl.Map,
    private _travelTechnicianDate: TravelTechnicianDate,
    startCoordinates?: PACoordinates
  ) {
    this.marker = this.generateDestinationMarker()
    if (startCoordinates) {
      this.marker.setLngLat([startCoordinates.longitude, startCoordinates.latitude])
      this._coordinates = new PACoordinates(startCoordinates.latitude, startCoordinates.longitude, startCoordinates.name)
      /*this.updateCircle()*/
    }
    if (this._map) this.marker.addTo(this._map)
    this.marker.on('dragend', () => {
      let lnglat = this.marker.getLngLat()
      this.coordinates = new PACoordinates(lnglat.lat, lnglat.lng);
    })
    /*this.initDistanceSubscription()*/
  }

  /*initDistanceSubscription() {
    PATourPlannerControl.Instance.distanceChangeSubject.subscribe(_ => {
      this.updateCircle()
    })
  }*/

  get map() {
    return this._map
  }

  set map(map: mapboxgl.Map) {
    if (this._map != map) {
      if (this._map) {
        this.marker.remove()
        this.removeCircle()
      }
      if (map) {
        this.marker.addTo(map)
      }
      this._map = map
      /*this.updateCircle()*/
    }
  }

  get coordinates() {
    return this._coordinates
  }

  set coordinates(coords: PACoordinates) {
    this._coordinates = coords
    if (coords) {
      this.marker.setLngLat([coords.longitude, coords.latitude])
    }
    /*this.updateCircle()*/
    this._travelTechnicianDate.updateLocation()
    this.updateTravelTechnicianDateTours()
  }

  updateTravelTechnicianDateTours(): void {
    this._travelTechnicianDate.hasChanged()
  }

  /*updateCircle(radius?: number): void {
    radius = radius || PATourPlannerControl.Instance.selectedDistanceFilter
    if (this.map) {
      let last_id = this._currentCircleId

      this._currentCircleId = this.coordinates ? `destination_circle_${this._travelTechnicianDate.technicianID}_${this._travelTechnicianDate.timestamp}_${this.coordinates.longitude},${this.coordinates.latitude}_${radius}` : ''

      if (last_id != this._currentCircleId) {
        if (last_id) {
          this.map.removeLayer(last_id)
          this.map.removeSource(last_id)
        }
        if (this._currentCircleId) {
          let circle = PAUtil.createGeoJSONCircle([this.coordinates.longitude, this.coordinates.latitude], radius)
          if (!this.map.getSource(this._currentCircleId)) {
            this.map.addSource(this._currentCircleId, circle);
          }

          if (!this.map.getLayer(this._currentCircleId)) {
            this.map.addLayer({
              "id": this._currentCircleId,
              "type": "fill",
              "source": this._currentCircleId,
              "layout": {},
              "paint": {
                "fill-color": "blue",
                "fill-opacity": 0.2
              }
            });
          }
        }
      }
    }
  }*/

  generateDestinationMarker(): mapboxgl.Marker {
    const el = document.createElement('div')
    el.style.backgroundSize = '100%'
    el.style.cursor = 'pointer'
    el.style.width = '28px'
    el.style.height = '28px'
    el.classList.add('destination_background_container')

    const el_svg_border = document.createElement('div')
    el_svg_border.classList.add('destination_background_border')

    const el_svg = document.createElement('div')
    el_svg.classList.add('destination_background')
    let week_day = PADataControl.Instance.getTechnician(this._travelTechnicianDate.technicianID).getTechnicianDate(this._travelTechnicianDate.timestamp, true, true).day.week_day
    el_svg.style.backgroundColor = PAUtil.getIndexColorString(PATimeControl.Instance.weekDays.indexOf(week_day))

    el.appendChild(el_svg)
    el.appendChild(el_svg_border)
    return new mapboxgl.Marker(el, {draggable: true})
  }

  removeCircle(): void {
    if (this._currentCircleId && this.map) {
      this.map.removeLayer(this._currentCircleId)
      this.map.removeSource(this._currentCircleId)
      this._currentCircleId = ''
    }
  }

  remove(): void {
    this.marker.remove()
  }

}