import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { DBOperationChange } from "../../classes/technician-date";
import { PAOperation } from "../../classes/operation";
import { PAUtil } from "../../classes/util";
import { PATimeControl } from "../../singletons/pa-time-control";
import { PADataControl } from "../../singletons/pa-data-control";
import { NgFor, NgIf } from '@angular/common';
import { MatExpansionPanel, MatExpansionPanelHeader, MatExpansionPanelTitle, MatExpansionPanelDescription } from '@angular/material/expansion';
import { MatButton } from '@angular/material/button';
import { CommonModule } from "@angular/common";

@Component({
  selector: 'hawk-database-conflicts',
  templateUrl: './database-conflicts.component.html',
  styleUrls: ['./database-conflicts.component.scss', './../../styles/common_styles.scss'],
  standalone: true,
  imports: [NgFor, MatExpansionPanel, MatExpansionPanelHeader, MatExpansionPanelTitle, MatExpansionPanelDescription, NgIf, MatButton, CommonModule]
})
export class DatabaseConflictsComponent implements OnChanges {

  @Input() DBChanges: DBOperationChange[] = []

  @Output() saveNewTourEvent: EventEmitter<void> = new EventEmitter()
  @Output() closeEvent: EventEmitter<void> = new EventEmitter()

  expandedOperations: PAOperation[] = []

  ngOnChanges(changes: SimpleChanges): void {
    this.expandedOperations = this.DBChanges.map(change => change.base_operation)
  }

  dateTimeString(operation_date: string): string {
    let date = new Date(operation_date)
    return PATimeControl.Instance.dateToDatestring(date, false, false, '.') + ' ' + PATimeControl.Instance.dateToTimestring(date, false) + 'Uhr'
  }

  uidNameString(uid: number): string {
    if (PADataControl.Instance.unassignedOperationUserIds.includes(uid)) {
      return 'Nicht vergeben'
    } else {
      return PADataControl.Instance.getTechnician(uid).getFullName()
    }
  }

  protected readonly PAUtil = PAUtil;
  protected readonly PATimeControl = PATimeControl;
  protected readonly Date = Date;
}