<div>
  <div class="ticketSearch">
    <h2>Ticketsuche</h2>
    <form [formGroup]="ticketSearchForm" style="width:95%">
      <table style="width:100%">
        <tr>
          <td style="width:33%">
            <mat-form-field appearance="outline">
              <mat-label>AuftragsNr</mat-label>
              <input matInput type="text" placeholder="Auftragsnummer" formControlName="order_number">
            </mat-form-field>
          </td>
          <td style="width:33%">
            <mat-form-field appearance="outline">
              <mat-label>Externe Nr</mat-label>
              <input matInput type="text" placeholder="Externe Auftragsnummer" formControlName="external_order_number">
            </mat-form-field>
          </td>
          <td style="width:33%">
            <mat-label>Projektauswahl</mat-label>
            <ng-select [items]="projects" bindLabel="name" bindValue="id" formControlName="project_id"
              placeholder="Projekt auswählen">
              <ng-template ng-label-tmp let-item="item">
                {{ item.name }}
              </ng-template>
              <ng-template ng-option-tmp let-item="item" let-search="searchTermProject">
                <div *ngIf="item.name.toLowerCase().includes(searchTermProject.toLowerCase())">
                  {{ item.name }}
                </div>
              </ng-template>
            </ng-select>
          </td>
        </tr>
        <tr>
          <td colspan="2">
            <mat-form-field class="errorText" appearance="outline">
              <mat-label>Fehlertext</mat-label>
              <input matInput type="text" placeholder="Fehlertext" formControlName="fault_description">
            </mat-form-field>
          </td>

          <td *ngIf="currentProject">
            <mat-label>Status</mat-label>
            <ng-select [items]="currentProject.status" bindLabel="name" bindValue="id" formControlName="status_id"
              placeholder="Status auswählen">
              <ng-template ng-label-tmp let-item="item">
                {{ item.name }}
              </ng-template>
              <ng-template ng-option-tmp let-item="item" let-search="searchTermStatus">
                <div *ngIf="item.name.toLowerCase().includes(searchTermStatus.toLowerCase())">
                  {{ item.name }}
                </div>
              </ng-template>
            </ng-select>
          </td>
        </tr>
        <tr>
          <td>
            <mat-form-field appearance="outline">
              <mat-label>Rechnungsnummer</mat-label>
              <input matInput type="text" placeholder="Rechnungsnummer" formControlName="invoice_number">
            </mat-form-field>
          </td>
          <td>
            <mat-form-field appearance="outline">
              <mat-label>Rechnungsnummer Partner</mat-label>
              <input matInput type="text" placeholder="Rechnungsnummer Partner"
                formControlName="invoice_number_partner">
            </mat-form-field>
          </td>
          <td *ngIf="currentProject">
            <mat-label>Priorität</mat-label>
            <ng-select [items]="currentProject.priorities" bindLabel="name" bindValue="id" formControlName="priority_id"
              placeholder="Prio auswählen">
              <ng-template ng-label-tmp let-item="item">
                {{ item.name }}
              </ng-template>
              <ng-template ng-option-tmp let-item="item" let-search="searchTermPrio">
                <div *ngIf="item.name.toLowerCase().includes(searchTermProject.toLowerCase())">
                  {{ item.name }}
                </div>
              </ng-template>
            </ng-select>
          </td>
        </tr>
        <tr>
          <td>
            <mat-label>Erstellungsdatum von</mat-label>

            <div class="dp-hidden position-absolute">
              <div class="input-group">
                <input name="datepickerCreation" class="form-control" ngbDatepicker #datepickerCreation="ngbDatepicker"
                  [autoClose]="'outside'" (dateSelect)="onDateSelectionCreation($event)" [displayMonths]="2"
                  [dayTemplate]="tCre" outsideDays="hidden" [startDate]="create_date_from!" tabindex="-1" />

                <ng-template #tCre let-date let-focused="focused">
                  <span class="custom-day" [class.focused]="focused" [class.range]="isRangeCreation(date)"
                    [class.faded]="isHoveredCreation(date) || isInsideCreation(date)"
                    (mouseenter)="hoveredDateCreation = date" (mouseleave)="hoveredDateCreation = null">
                    {{ date.day }}
                  </span>
                </ng-template>
              </div>
            </div>

            <div class="input-group">
              <input #dpFromDate class="form-control" placeholder="tt.mm.jjjj" name="dpFromDate"
                [value]="formatter.format(create_date_from)"
                (input)="create_date_from = validateInput(create_date_from, dpFromDate.value)" />
              <button class="btn btn-outline-secondary" (click)="datepickerCreation.toggle()" type="button">
                <fa-icon [icon]="['far','calendar']"></fa-icon>
              </button>
            </div>
          </td>
          <td><mat-label>Erstellungsdatum bis</mat-label>
            <div class="input-group">
              <input #dpToDate class="form-control" placeholder="tt.mm.jjjj" name="dpToDate"
                [value]="formatter.format(create_date_to)"
                (input)="create_date_to = validateInput(create_date_to, dpToDate.value)" />
              <button class="btn btn-outline-secondary" (click)="datepickerCreation.toggle()" type="button">
                <fa-icon [icon]="['far','calendar']"></fa-icon>
              </button>
            </div>
          </td>
        </tr>
        <tr>
          <td>
            <div class="links">
              <a href="" (click)="setRange('created', 'today')">heute</a>&nbsp;
              <a href="" (click)="setRange('created', 'thisweek')">diese Woche</a>&nbsp;
              <a href="" (click)="setRange('created', 'nextweek')">nächste Woche</a>&nbsp;
              <a href="" (click)="deleteFromDate('created')">von löschen</a>
            </div>
          </td>
        </tr>
        <tr>
          <td>
            <mat-label>Einsatzdatum von</mat-label>

            <div class="dp-hidden position-absolute">
              <div class="input-group">
                <input name="datepickerOperation" class="form-control" ngbDatepicker
                  #datepickerOperation="ngbDatepicker" [autoClose]="'outside'"
                  (dateSelect)="onDateSelectionOperation($event)" [displayMonths]="2" [dayTemplate]="tOp"
                  outsideDays="hidden" [startDate]="operation_date_from!" tabindex="-1" />

                <ng-template #tOp let-date let-focused="focused">
                  <span class="custom-day" [class.focused]="focused" [class.range]="isRangeOperation(date)"
                    [class.faded]="isHoveredOperation(date) || isInsideOperation(date)"
                    (mouseenter)="hoveredDateOperation = date" (mouseleave)="hoveredDateOperation = null">
                    {{ date.day }}
                  </span>
                </ng-template>
              </div>
            </div>

            <div class="input-group">
              <input #dpFromDateOperation class="form-control" placeholder="tt.mm.jjjj" name="dpFromDateOperation"
                [value]="formatter.format(operation_date_from)"
                (input)="operation_date_from = validateInput(operation_date_from, dpFromDateOperation.value)" />
              <button class="btn btn-outline-secondary" (click)="datepickerOperation.toggle()" type="button">
                <fa-icon [icon]="['far','calendar']"></fa-icon>
              </button>
            </div>
          </td>
          <td><mat-label>Einsatzdatum bis</mat-label>
            <div class="input-group">
              <input #dpToDateOperation class="form-control" placeholder="tt.mm.jjjj" name="dpToDateOperation"
                [value]="formatter.format(operation_date_to)"
                (input)="operation_date_to = validateInput(operation_date_to, dpToDateOperation.value)" />
              <button class="btn btn-outline-secondary" (click)="datepickerOperation.toggle()" type="button">
                <fa-icon [icon]="['far','calendar']"></fa-icon>
              </button>
            </div>
          </td>
        </tr>
        <tr>
          <td>
            <div class="links">
              <a href="" (click)="setRange('operation', 'today')">heute</a>&nbsp;
              <a href="" (click)="setRange('operation', 'thisweek')">diese Woche</a>&nbsp;
              <a href="" (click)="setRange('operation', 'nextweek')">nächste Woche</a>&nbsp;
              <a href="" (click)="deleteFromDate('operation')">von löschen</a>
            </div>
          </td>
        </tr>
        <tr *ngIf="technicians.length > 0">
          <td colspan="2">
            <mat-label>Techniker </mat-label>
            <ng-select [items]="technicians" bindLabel="search_name" bindValue="id" [multiple]="true"
              placeholder="Techniker auswählen" formControlName="technician_ids">
            </ng-select>
          </td>
        </tr>
        <tr *ngIf="companies.length > 0">
          <td colspan="2">
            <mat-label>Firma Partner</mat-label>
            <ng-select [items]="companies" bindLabel="company" bindValue="company" [multiple]="true"
              placeholder="Firma auswählen" formControlName="technician_company_names">
            </ng-select>
          </td>
        </tr>
        <tr>
          <td colspan="2">
          </td>
        </tr>
        <tr>
          <td colspan="2">
            <mat-checkbox formControlName="is_open">Nur offene Tickets</mat-checkbox><br>
            <mat-checkbox formControlName="not_invoiced">Nicht abgerechnete Tickets</mat-checkbox><br>
            <mat-checkbox formControlName="not_invoiced_partner">Nicht von Partnern abgerechnete
              Tickets</mat-checkbox><br>
            <mat-checkbox formControlName="has_date">Nur Tickets mit Terminvereinbarung</mat-checkbox>
          </td>
        </tr>
        <tr>
          <td colspan="2">
            <button class="button" (click)="search()"> Suche starten</button>
          </td>

          <td style="text-align: right">
            <a class="button" [routerLink]="['/', 'ticket', 'neu']">Neues Ticket erstellen</a>
          </td>
        </tr>
      </table>
    </form>
  </div>

</div>