import { bootstrapApplication } from '@angular/platform-browser';
import { AppComponent } from './app/app.component';

import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import { appConfig } from './app/app.config';
// Register locale
registerLocaleData(localeDe, 'de');

bootstrapApplication(AppComponent, appConfig)
  .catch(err => console.error(err));
