<div class="travel_technician_menu_container" [ngClass]="anchor + '_anchor'" *ngIf="travelTechnicianDate">
  <div style="display: inline-flex; flex-direction: row; font-size: 12px; line-height: 14px; flex: 1;" class="black_gradient_background" [tooltip]="tooltip" (click)="createTravelTechnicianDate()">
    <div style="background-size: 100%; width: 18px; height: 18px; position: relative; margin: auto;" class="destination_background_container">
      <div class="destination_background" [style.background-color]="PAUtil.getIndexColorString(PATimeControl.Instance.weekDays.indexOf(technicianDate.day.week_day))" [ngClass]="travelTechnicianDate.isActive() ? '' : 'inactive'"></div>
      <div class="destination_background_border"></div>
    </div>
  </div>
  <hawk-popup-menu #TravelTechnicianPopupMenuComponent [anchor]="anchor">
    <div class="content" style="position:relative; background: #2d2d2d; cursor: auto;">
      <fa-icon style="position: absolute; top: 0; right: 4px; color: white; cursor: pointer;" [icon]="faWindowClose" (click)="popupMenu.close()"></fa-icon>
      <div class="vertical_flex" style="padding: 2px 4px;">
        <h6>{{tooltip}}</h6>
        <div class="horizontal_flex">
          <div style="margin-right: 4px;">Ziel: {{getDestination()}} </div>
          <a *ngIf="travelTechnicianDate.coordinates?.longitude && travelTechnicianDate.coordinates?.latitude"
             target="_blank"
             [href]="'https://www.google.de/maps/search/Hotel/@' + travelTechnicianDate.coordinates.latitude + ',' + travelTechnicianDate.coordinates.longitude + ',13.00z'">
            Hotels in der Nähe
          </a>
        </div>
        <div #TargetGeocoder></div>
        <div class="horizontal_flex" style="margin-top: 4px; white-space: nowrap;">
          <button mat-raised-button color="primary" (click)="showGenerateOperationModal = true; initializeTicketFormGroup()">Int. Auftrag erstellen</button>
          <button mat-raised-button color="warn" style="margin-left: 4px;" (click)="deleteTravelTechnicianDate(); $event.stopPropagation()">entfernen</button>
        </div>
      </div>
    </div>
  </hawk-popup-menu>
</div>

<hawk-popup-modal [shown]="showGenerateOperationModal" (closeEvent)="showGenerateOperationModal = false">
  <div style="width: 600px; background-color: #1f1f1f; border-radius: 4px;">
    <form [formGroup]="ticketForm" (submit)="onTicketSubmit()" style="padding: 8px; border: 1px solid black; border-radius: 4px;">
      <h6 style="color: white;">
        Auftrags-Daten
      </h6>
      <div class="vertical_flex" style="background-color: white; color: black; border-radius: 4px; padding: 8px; width: 100%; max-height: 550px; overflow-y: auto;">
        <div class="vertical_flex" style="height: fit-content;">
          <mat-grid-list cols="2" rowHeight="180px">
            <mat-grid-tile>
              <div class="controles-container">
                <mat-form-field>
                  <mat-label>Projekt</mat-label>
                  <mat-select formControlName="project_id" [disabled]="true" [value]="ticketForm.controls['project_id'].value">
                    <mat-option>None</mat-option>
                    <ng-container *ngFor="let project of PADataControl.Instance.loadedProjects">
                      <mat-option [value]="project.id">{{project.project_name}}</mat-option>
                    </ng-container>
                  </mat-select>
                  <mat-error>Bitte wählen Sie ein Projekt aus.</mat-error>
                </mat-form-field>
                <mat-form-field>
                  <mat-label>Priorität auswählen</mat-label>
                  <mat-select formControlName="priority_id">
                    <mat-option>None</mat-option>
                    <ng-container *ngFor="let priority of PADataControl.Instance.getProject(ticketForm.get('project_id').value)?.priorities">
                      <mat-option [value]="priority.id">{{priority.name}}</mat-option>
                    </ng-container>
                  </mat-select>
                  <mat-error>Bitte wählen Sie eine Priorität aus.</mat-error>
                </mat-form-field>
                <mat-form-field>
                  <mat-label>Status auswählen</mat-label>
                  <mat-select formControlName="status_id" [disabled]="!ticketForm.get('project_id').value">
                    <mat-option>None</mat-option>
                    <ng-container *ngFor="let status of PADataControl.Instance.getProject(ticketForm.get('project_id').value)?.status">
                      <mat-option [value]="status.id">{{status.name}}</mat-option>
                    </ng-container>
                  </mat-select>
                  <mat-error>Bitte wählen Sie einen Status aus.</mat-error>
                </mat-form-field>
              </div>
            </mat-grid-tile>
          </mat-grid-list>
          <h6>
            Hotel-Adresse
          </h6>
          <mat-grid-list cols="2" rowHeight="55px">
            <mat-grid-tile>
              <div class="controles-container">
                <mat-form-field>
                  <input formControlName="address_name" matInput placeholder="Hotel-Name" >
                  <mat-error>Bitte geben Sie einen Namen an</mat-error>
                </mat-form-field>
              </div>
            </mat-grid-tile>
            <mat-grid-tile style="overflow: visible;">
              <div class="controles-container">
                <div style="border: 1px solid greenyellow" #TicketFormGeocoder></div>
              </div>
            </mat-grid-tile>
          </mat-grid-list>
          <mat-grid-list cols="3" rowHeight="110px">
            <mat-grid-tile>
              <div class="controles-container">
                <mat-form-field>
                  <input formControlName="address_street" matInput placeholder="Straße">
                  <mat-error>Keine Straße gefunden</mat-error>
                </mat-form-field>
                <mat-form-field>
                  <input formControlName="address_street_no" matInput placeholder="Zusatz/Nummer">
                </mat-form-field>
              </div>
            </mat-grid-tile>
            <mat-grid-tile>
              <div class="controles-container">
                <mat-form-field>
                  <input formControlName="address_zip" matInput placeholder="Postleitzahl">
                  <mat-error>Bitte geben Sie eine gültige Postleitzahl an</mat-error>
                </mat-form-field>
                <mat-form-field>
                  <input formControlName="address_city" matInput placeholder="Stadt">
                  <mat-error>Keine Stadt gefunden</mat-error>
                </mat-form-field>
              </div>
            </mat-grid-tile>
            <mat-grid-tile>
              <div class="controles-container">
                <mat-form-field>
                  <input formControlName="address_state" matInput placeholder="Region/Provinz">
                </mat-form-field>
                <mat-form-field>
                  <input formControlName="address_country" matInput placeholder="Land">
                  <mat-error>Kein Land gefunden</mat-error>
                </mat-form-field>
              </div>
            </mat-grid-tile>
          </mat-grid-list>
          <mat-grid-list cols="2" rowHeight="55px">
            <mat-grid-tile>
              <div class="controles-container">
                <mat-form-field>
                  <input formControlName="address_longitude" matInput placeholder="Längengrad">
                  <mat-error>Keinen Längengrad gefunden</mat-error>
                </mat-form-field>
              </div>
            </mat-grid-tile>
            <mat-grid-tile>
              <div class="controles-container">
                <mat-form-field>
                  <input formControlName="address_latitude" matInput placeholder="Breitengrad">
                  <mat-error>Keinen Breitengrad gefunden</mat-error>
                </mat-form-field>
              </div>
            </mat-grid-tile>
          </mat-grid-list>
          <br>
          <h6>
            Beschreibung
          </h6>
          <mat-grid-list cols="1" rowHeight="100px">
            <mat-grid-tile class="normal_alignment">
              <div class="controles-container" style="border: 1px solid rgba(0, 0, 0, 0.295); border-radius: 4px;">
                <mat-form-field>
                    <textarea spellcheck="false" formControlName="description" matInput placeholder="Beschreibung hinzufügen..."
                              cdkTextareaAutosize
                              #autosize="cdkTextareaAutosize"
                              cdkAutosizeMinRows="1"
                              cdkAutosizeMaxRows="13"></textarea>
                  <mat-error>Bitte erstellen Sie eine kurze Beschreibung.</mat-error>
                </mat-form-field>
              </div>
            </mat-grid-tile>
          </mat-grid-list>
          <br>
          <h6 style="margin-top: 20px;">
            Kontakt-Daten (optional)
          </h6>
          <mat-grid-list cols="3" rowHeight="60px" >
            <mat-grid-tile>
              <div class="controles-container">
                <mat-form-field>
                  <input formControlName="contact_name" matInput placeholder="Name">
                </mat-form-field>
              </div>
            </mat-grid-tile>
            <mat-grid-tile>
              <div class="controles-container">
                <mat-form-field>
                  <input formControlName="contact_phone" matInput placeholder="Telefon">
                  <mat-error>Bitte geben Sie eine gültige Telefonnummer an.</mat-error>
                </mat-form-field>
              </div>
            </mat-grid-tile>
            <mat-grid-tile>
              <div class="controles-container">
                <mat-form-field>
                  <input formControlName="contact_email" matInput placeholder="Email">
                  <mat-error>Bitte geben Sie eine gültige Email an.</mat-error>
                </mat-form-field>
              </div>
            </mat-grid-tile>
          </mat-grid-list>
          <mat-grid-list cols="1" rowHeight="60px" >
            <mat-grid-tile>
              <div class="button-row" style="margin: auto;">
                <button mat-raised-button color="primary" type="submit" [disabled]="ticketForm.invalid" style="font-size: 1rem;">Speichern</button>
              </div>
            </mat-grid-tile>
          </mat-grid-list>
        </div>
      </div>
    </form>
  </div>
</hawk-popup-modal>
