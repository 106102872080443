
import { Priority } from "../../../_models";
import { TimePredictionRule } from "../classes/time-prediction-rule";
import { PATechnician } from "../classes/technician";
import { PAProject } from "../classes/project";
import { Subject } from "rxjs";
import { PAUtil } from "../classes/util";
import { PADataControl } from "./pa-data-control";
import { PlanningAssistantSettingsService } from "../services/planning-assistant-settings.service";

export type SettingTab = 'Allgemein' | 'Rampups (extra Zeit)' | 'Reisetechniker' | 'Projekte' | 'Planung'

export interface PASettingsConfig {
  id?: number
  title: string;
  data: PASettings
}

export interface PASettings {
  general: {
    show_weekdays: {
      monday: boolean,
      tuesday: boolean,
      wednesday: boolean,
      thursday: boolean,
      friday: boolean,
      saturday: boolean,
      sunday: boolean,
    },
    map: {
      show_operation_times: boolean
      seperated_tour_view: boolean
    }
  },
  tour_planning: {
    operation_planning: {
      show_close_technician_tours: boolean,
      show_close_technician_tours_distance: number
    }
  },
  travel_technicians: {
    hotel_overnight_stay: {
      default_priority_id: number
    }
  }
}

export class PASettingsControl {

  private static _instance: PASettingsControl
  public static get Instance()
  {
    return this._instance || (this._instance = new this());
  }

  additionalPriorityTimeRules = new Map<Priority, TimePredictionRule[]>()
  currentPriorityTimeRuleConfig: {technician?: PATechnician | 'default', project_priority: {project: PAProject, priority?: Priority}, extra_percent?: number} = {project_priority: {project: null, priority: null}}
  priorityRuleChangedSubject = new Subject<Priority>()

  showSettings = false
  settingTabs: SettingTab[] = ['Allgemein', 'Planung', 'Reisetechniker', 'Rampups (extra Zeit)']
  activeSettingsTab: SettingTab = 'Allgemein'
  projectMode: 'service' | 'free' = 'free'

  loadedSettingsConfig: PASettingsConfig

  selectedSettingConfig: PASettingsConfig = {
    title: 'Default',
    data: {
      general: {
        show_weekdays: {
          monday: true,
          tuesday: true,
          wednesday: true,
          thursday: true,
          friday: true,
          saturday: true,
          sunday: false
        },
        map: {
          show_operation_times: true,
          seperated_tour_view: true
        }
      },
      tour_planning: {
        operation_planning: {
          show_close_technician_tours: true,
          show_close_technician_tours_distance: 5
        }
      },
      travel_technicians: {
        hotel_overnight_stay: {
          default_priority_id: null
        }
      }
    }
  }

  private _settingsService?: PlanningAssistantSettingsService

  private constructor(
  ) {
  }

  set settingsService(settings_service: PlanningAssistantSettingsService) {
    this._settingsService = settings_service
  }

  async loadSettingsFromDatabase(): Promise<void> {

    let settings = await new Promise<PASettingsConfig[]>(
      resolve => {
        if (this._settingsService) {
          this._settingsService.index().subscribe(
            settings => {
              resolve(settings)
            },
            error => {
              console.log(error)
              resolve([])
            }
          )
        }
      }
    )

    if (settings.length) {
      this.selectedSettingConfig = settings[0]
      this.loadedSettingsConfig = JSON.parse(JSON.stringify(this.selectedSettingConfig))
    } else {
      this.createOrUpdateSettingsInDatabase()
    }
  }

  createOrUpdateSettingsInDatabase(): void {
    if (this.selectedSettingConfig.id) {
      this._settingsService.update(this.selectedSettingConfig.id, {setting: {data: this.selectedSettingConfig.data, title: this.selectedSettingConfig.title}}).subscribe(
        setting => {
          this.selectedSettingConfig = setting
          this.loadedSettingsConfig = JSON.parse(JSON.stringify(this.selectedSettingConfig))
        },
        error => {
          console.log(error)
        }
      )
    } else {
      this._settingsService.create({setting: this.selectedSettingConfig}).subscribe(
        setting => {
          this.selectedSettingConfig = setting
          this.loadedSettingsConfig = JSON.parse(JSON.stringify(this.selectedSettingConfig))
        },
        error => {
          console.log(error)
        }
      )
    }
  }

  settingsHaveChanged(): boolean {
    return JSON.stringify(this.selectedSettingConfig) != JSON.stringify(this.loadedSettingsConfig)
  }

  addAdditionalTechnicianPriorityOperationTimeOnDay(technician: PATechnician, project_id: number, priority_id: number, extra_percent: number) {
    let project = PADataControl.Instance.getProject(project_id)
    let priority = PADataControl.Instance.getPriority(priority_id)
    this.currentPriorityTimeRuleConfig = {technician: technician, project_priority: {project: project, priority: priority}, extra_percent: extra_percent}
    this.showSettings = true
    this.activeSettingsTab = "Rampups (extra Zeit)"
  }

  getAdditionalPriorityTimeRules(priority: Priority) {
    if (!this.additionalPriorityTimeRules.has(priority)) {
      this.additionalPriorityTimeRules.set(priority, [])
    }
    return this.additionalPriorityTimeRules.get(priority)
  }

  addAdditionalTimePriorityRule(rule: TimePredictionRule): void {
    let rules = this.getAdditionalPriorityTimeRules(rule.priority)
    if (!rules.includes(rule)) rules.push(rule)
  }

  async removeAdditionalTimePriorityRule(rule: TimePredictionRule): Promise<void> {
    let rules = this.getAdditionalPriorityTimeRules(rule.priority)
    if (rules.includes(rule)) {
      await rule.deleteInDb().then(_ => { if (!rule.isDeletable()) {PAUtil.removeElementFromList(rules, rule)}})
    }
  }

  getAdditionalPriorityTimeRuleForTechnicianAndPriorityIndex(priority: Priority, technician: PATechnician, priority_index: number): TimePredictionRule {
    let rules = this.getAdditionalPriorityTimeRules(priority)

    let technician_specific_rules = rules.filter(rule => rule.technician == technician)
    let found_technician_specific_rule = technician_specific_rules.find(rule => rule.step_start <= priority_index && priority_index <= rule.step_end)

    if (technician_specific_rules.length) {
      return found_technician_specific_rule
    } else {
      return rules.find(rule => rule.technician == 'default' && rule.step_start <= priority_index && priority_index <= rule.step_end)
    }

  }
}