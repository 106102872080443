import { Priority, Status } from "src/app/_models"
import { ProjectService } from "src/app/_services"
import { PAProgress } from "./progress"
import { PAStore } from "./store"
import { PlanningAssistantService } from "src/app/_services/planning-assistant.service"
import { PAUtil } from "./util"
import { PAOperation } from "./operation";
import { OperationCoordinates } from "../../../_models/operation.interface";
import { PATicket } from "./ticket";
import { TicketCoordinates } from "../../../_models/ticket.interface";
import { PADataControl } from "../singletons/pa-data-control";
import { PAFilterControl } from "../singletons/pa-filter-control";
import { PASettingsControl } from "../singletons/pa-settings-control";

export class PAProject {

  static projectService: ProjectService
  static planningAssistantService: PlanningAssistantService
  static selectedProjects: PAProject[] = []

  operationLoadingStatus: 'init' | 'loading' | 'loaded' = 'init'
  ticketLoadingStatus: 'init' | 'loading' | 'loaded' = 'init'

  constructor(
    public id: number,
    public project_name: string,
    public customer_id: number,
    public active_technician_ids: number[],
    public experienced_technician_ids: number[],
    public color: string,
    public priorities: Priority[],
    public status: Status[]
  ) {
  }

  public updateColor(event: Event): void {
    const input = event.target as HTMLInputElement
    const color: string = input.value
    
    this.updateColorMeta(color)
  }

  public updateColorMeta(color: string): void {    
    const params = {color: color}
    PAProject.projectService.updateProject(this.id.toString(), params).subscribe(
      (_) => {
        this.color = color
        const project_operations = PADataControl.Instance.getChangedLoadedOperations().filter(operation => operation.ticket.project.id == this.id)
        for (let operation of project_operations) {
          let store = PAStore.getOperationsStore(operation)
          if (store) {
            store.color = PAUtil.hexToRgba(color)
          }
          let tour = operation.getTour()
          if (tour) {
            tour.updateRoute()
          }
        }
      },
      (error) => {
        console.log(error)
      }
    )
  }

  public getPlannedStatus(): Status {
    let possible_status = this.status.filter(status => status.name.toLowerCase().includes('geplant') && !status.name.toLowerCase().includes('ungeplant'))
    if (possible_status) return possible_status[0]
    return null
  }

  public getUnplannedStatus(): Status {
    let possible_status = this.status.filter(status => status.name.toLowerCase().includes('ungeplant'))
    if (possible_status) return possible_status[0]
    return null
  }

  public getStatus(status_id: number): Status {
    const possible_status = this.status.filter(status => status.id == status_id)
    if (possible_status.length > 0) {
      return possible_status[0]
    }
    console.log("No status  with id: " + status_id + " found")
  }

  public includesString(s: string): boolean {
    return `${this.id} ${this.project_name}`.toLowerCase().includes(s.toLowerCase())
  }

  public excludesString(s: string): boolean {
    return !`${this.id} ${this.project_name}`.toLowerCase().includes(s.toLowerCase())
  }

  public isServiceProject(): boolean {
    return this.includesString('service')
  }

  public isNotServiceProject(): boolean {
    return this.excludesString('service')
  }

  public async loadUnplannedOperationsForProject(): Promise<void> {
    if (!['loading', 'loaded'].includes(this.operationLoadingStatus)) {
      this.operationLoadingStatus = 'loading'
      let prio_progress = new PAProgress('Priorisiere Aufträge aus: ' + this.project_name, 1)

      let operation_coordinates = await new Promise<OperationCoordinates[]>(resolve => {
        prio_progress.addSubProgress(PAOperation.operationService.loadOperationCoordinates({
          project_id: this.id,
          exclude_material_return: true,
          only_unassigned: true
        }), '').subscribe(
          data => {
            resolve(data)
          },
          err => {
            console.log(err)
            resolve([])
          }
        )
      })

      if (operation_coordinates.length) {
        let distance_sorted_coordinates = operation_coordinates.sort(
          (c1: OperationCoordinates, c2: OperationCoordinates) => {
            let loading_coordinates = PADataControl.Instance.loadingCoordinates
            return PAUtil.calcDistanceAsTheCrowFlies(c1.latitude, c1.longitude, loading_coordinates.lat, loading_coordinates.lng) < PAUtil.calcDistanceAsTheCrowFlies(c2.latitude, c2.longitude, loading_coordinates.lat, loading_coordinates.lng) ? -1 : 1
          }
        )

        let chunk_size = 250
        let coordinates_chunks = PAUtil.chunkArray<OperationCoordinates>(distance_sorted_coordinates, chunk_size)
        let loading_progress = new PAProgress(`Lade ${operation_coordinates.length} Aufträge (${this.project_name})`, coordinates_chunks.length)

        for (let coordinates_chunk of coordinates_chunks) {
          await new Promise<void>(resolve => {
            loading_progress.addSubProgress(PAProject.planningAssistantService.getPlanningAssistantOperations(coordinates_chunk.map(op_chunk => op_chunk.operation_id)), `${coordinates_chunks.indexOf(coordinates_chunk) * chunk_size + 1} - ${Math.min((coordinates_chunks.indexOf(coordinates_chunk) + 1) * chunk_size, operation_coordinates.length)}`).subscribe(
              data => {
                if (data.length) {
                  PADataControl.Instance.processOperationHashes(data)
                }
                resolve()
              },
              error => {
                console.log(error)
                resolve()
              }
            )
          })
        }
      }

      this.operationLoadingStatus = 'loaded'
    } else {
      return
    }
  }

  public async loadUnplannedTicketsForProject(pre_loaded_ticket?: PATicket): Promise<void> {
      if (!['loading', 'loaded'].includes(this.ticketLoadingStatus)) {
        this.ticketLoadingStatus = 'loading'

        let prio_progress = new PAProgress('Priorisiere Tickets aus: ' + this.project_name, 1)

        let ticket_coordinates = await new Promise<TicketCoordinates[]>(resolve => {
          prio_progress.addSubProgress(PAOperation.ticketService.loadTicketCoordinates({
            project_id: this.id,
            exclude_material_return: true,
            only_without_operations: true
          }), '').subscribe(
            data => {
              resolve(data)
            },
            err => {
              console.log(err)
              resolve([])
            }
          )
        })

        if (pre_loaded_ticket) ticket_coordinates = ticket_coordinates.filter(t => t.ticket_id != pre_loaded_ticket?.id)

        if (ticket_coordinates.length) {
          let distance_sorted_coordinates = ticket_coordinates.sort(
            (c1: TicketCoordinates, c2: TicketCoordinates) => {
              let loading_coordinates = PADataControl.Instance.loadingCoordinates
              return PAUtil.calcDistanceAsTheCrowFlies(c1.latitude, c1.longitude, loading_coordinates.lat, loading_coordinates.lng) < PAUtil.calcDistanceAsTheCrowFlies(c2.latitude, c2.longitude, loading_coordinates.lat, loading_coordinates.lng) ? -1 : 1
            }
          )

          let chunk_size = 250
          let coordinates_chunks = PAUtil.chunkArray<TicketCoordinates>(distance_sorted_coordinates, chunk_size)
          let loading_progress = new PAProgress(`Lade ${ticket_coordinates.length} Tickets (${this.project_name})`, coordinates_chunks.length)

          for (let coordinates_chunk of coordinates_chunks) {
            await new Promise<void>(resolve => {
              loading_progress.addSubProgress(PAProject.planningAssistantService.getPlanningAssistantTickets(coordinates_chunk.map(op_chunk => op_chunk.ticket_id)), `${coordinates_chunks.indexOf(coordinates_chunk) * chunk_size + 1} - ${Math.min((coordinates_chunks.indexOf(coordinates_chunk) + 1) * chunk_size, ticket_coordinates.length)}`).subscribe(
                data => {
                  if (data.length) {
                    let unplanned_tickets = data.map(ticket_hash => PADataControl.Instance.ticketHashToTicket(ticket_hash, PADataControl.Instance.generateNextLocationId(), PADataControl.Instance.generateNextClientId()))
                    for (let ticket of unplanned_tickets) {
                      ticket.generateTemporaryTicketOperation()
                    }
                    PAFilterControl.Instance.updateUnassignedOperations()
                    resolve()
                  }
                  resolve()
                },
                error => {
                  console.log(error)
                  resolve()
                }
              )
            })
          }
        }

        this.ticketLoadingStatus = 'loaded'
      } else {
       return
      }
  }

  public hasPriorityWithRule(): boolean {
    return !!this.priorities.find(p => PASettingsControl.Instance.getAdditionalPriorityTimeRules(p).length > 0)
  }

  static setProjectService(service: ProjectService) {
    this.projectService = service
  }

  static setPlanningAssistantService(service: PlanningAssistantService) {
    this.planningAssistantService = service
  }

}