import { RouterModule } from "@angular/router";

import { Component, inject, OnInit, ViewEncapsulation } from '@angular/core'
import { ColDef } from 'ag-grid-community'
import { GridApi, GridState, RowClassRules, SideBarDef } from 'ag-grid-enterprise'
import { GridReadyEvent } from 'ag-grid-community'

import { Article, DeliveryNote, DeliveryNoteSearchParams } from 'src/app/_models'

import { AG_GRID_LOCALE_DE } from 'src/app/_helpers/aggrid.locale.de'
import { ArticleService, DeliveryNoteService } from 'src/app/_services'
import { ActivatedRoute } from '@angular/router'
import { dateFormatter } from 'src/app/_helpers/aggrid-renderer'
import { AgGridGenericLinkRenderer } from '../_shared/ag-grid-renderer/ag-grid-generic-link.component'
import { AgGridExternalLinkRenderer } from '../_shared/ag-grid-renderer/ag-grid-extranl-link.component'
import { HttpParams } from '@angular/common/http'
import { AgGridAngular } from "ag-grid-angular";
import { AgGridThemeService } from "src/app/_services/ag-grid-theme.service";
import { AgGridDeliveryNotesLinkRenderer } from "../_shared/ag-grid-renderer/ag-grid-delivery-notes-link.component";

@Component({
  imports: [RouterModule, AgGridAngular],
    selector: 'hawk-artikel-search',
    templateUrl: './artikel-search.component.html',
    styleUrls: ['./artikel-search.component.scss'],
})
export class ArtikelSearchComponent implements OnInit {
  public hawkTheme = inject(AgGridThemeService).getTheme();
  public articles: Article[] = []

  public searchParams: DeliveryNoteSearchParams = {}

  public sideBarOptions: SideBarDef = {
    hiddenByDefault: false,
    toolPanels: ['columns', 'filters']
  }

  public locale = AG_GRID_LOCALE_DE
  public storageKey = 'deliveryNoteSearchStateSettings'

  public rowSelection: "single" | "multiple" = "multiple"
  public initialState?: GridState;

  public rowClassRules: RowClassRules = {
    // if tech on site, color row
    'tech-on-site': (params) => { return params.data.tech_on_site },
  }

  public defaultColDef: ColDef = {
    filter: true,
    flex: 1
  }

  // Column Definitions: Defines the columns to be displayed.
  public colDefs: ColDef[] = [
    {
      field: "rma",
      headerName: "RMA",
      cellRenderer: AgGridGenericLinkRenderer,
      cellRendererParams: {
        labelFromData: true,
        dataKey: 'rma',
        routerLink: ['/', 'artikel', '@id'],
        icon: ['fa', 'fa-cube']
      }
    },
    {
      field: "template_description",
      headerName: "Bezeichnung",
      cellRenderer: AgGridGenericLinkRenderer,
      cellRendererParams: {
        labelFromData: true,
        dataKey: 'template_description',
        routerLink: ['/', 'artikelvorlagen', '@template_id'],
        icon: ['fa', 'fa-cubes']
      }
    },
    {
      field: "bad_part",
      headerName: "Bad Part",
      cellRenderer: (params: any) => {
        if(params.data.bad_part) {
          return '<i class="fas fa-bug"></i>'
        }
        return ''
      }
    },
    {
      field: "product_category",
      headerName: "Produkt"
    },
    {
      field: "vendor",
      headerName: "Hersteller",
      hide: true
    },
    {
      field: "serial_number",
      headerName: "Seriennummer"
    },
    {
      field: "delivery_date",
      headerName: "Lieferung",
      valueFormatter: dateFormatter("MMM dd,YYYY"),
      hide: true
    },
    {
      field: "ext_delivery_note_number",
      headerName: "Liefer-Nr"
    },
    {
      field: "warehouse_label",
      headerName: "Lager",
      cellRenderer: AgGridGenericLinkRenderer,
      cellRendererParams: {
        labelFromData: true,
        dataKey: 'warehouse_label',
        routerLink: ['/', 'lager', '@warehouse_id']
      }
    },
    {
      field: "bin_label",
      headerName: "Platz",
      cellRenderer: AgGridGenericLinkRenderer,
      cellRendererParams: {
        labelFromData: true,
        dataKey: 'bin_label',
        routerLink: ['/', 'lager', '@warehouse_id', 'lagerort', '@bin_id' ,'alle']
      }
    },
    {
      field: "location",
      headerName: "",
      cellRenderer: (params: any) => {
        if(params.data.location === 'SHIPPING') {
          return `<div class="text-muted text-left">
                  <i class="fa fa-truck" ></i>
                </div>`
        }
        return ''
      }
    },
    {
      field: "status",
      headerName: "Status",
    },
    {
      field: "deliver_note_numbers",
      headerName: "Lieferscheine",
      cellRenderer: AgGridDeliveryNotesLinkRenderer,
    },
  ]

  private gridApi!: GridApi;

  constructor(
    private deliveryNoteService: DeliveryNoteService,
    private route: ActivatedRoute,
    private articleService: ArticleService
  ) { }

  onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api
  }

  ngOnInit(): void {
    this.initialState = this.getState()
    let searchParams = new HttpParams();
    this.route.queryParams.subscribe(params => {
      if (params['status_id']) {
        searchParams = searchParams.append('status_id', params['status_id']);
      }
      if (params['product_id']) {
        searchParams = searchParams.append('product_id', params['product_id']);
      }
      if (params['rma']) {
        searchParams = searchParams.append('rma', params['rma']);
      }
      if(params['serial_number']) {
        searchParams = searchParams.append('serial_number', params['serial_number']);
      }
      if(params['ids']) {
        params['ids'].forEach((id: number | string) => {
          searchParams = searchParams.append(`ids[]`, id);
        })
      }
      this.articleService.all(searchParams).subscribe(
        {
          next: (response) => {
            this.articles = response;
          },
          error: (err) => {
            console.error(err)
          }
        }
      )
    })
  }

  public quicksearchChanged(e: Event) {
    const newValue = (e.target as HTMLInputElement).value
    console.log("newValue", newValue)
    this.gridApi.setGridOption(
      "quickFilterText",
      newValue,
    )
  }

  public saveState() {
    let state = this.gridApi.getState()
    delete state['filter']
    delete state.pagination.page
    const serializedState = JSON.stringify(state)
    localStorage.setItem(this.storageKey, serializedState)
  }

  public deleteState() {
    localStorage.removeItem(this.storageKey)
  }

  public getState(): GridState {
    return JSON.parse(localStorage.getItem(this.storageKey))
  }

  public printState() {
    const stateJson = this.getState()
    console.log(localStorage.getItem(this.storageKey), stateJson)
  }
}