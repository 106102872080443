import { Injectable } from '@angular/core'
import { HttpClient, HttpParams } from '@angular/common/http'

import { environment } from '../../environments/environment'
import { UserInfo } from '../_models/user-info.interface'
import {
  PlanningAssistantOperation,
  PlanningAssistantTicket,
  PriorityRampUpRuleHash
} from '../_models/planning-assistant.interface'
import { Technician } from '../_models'
import { Observable } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class PlanningAssistantService {

  constructor(private http: HttpClient) {
  }

  public sendErrorReport(receiver: string, error_description: string, program_log: string[], current_variables: string[]) {
    const path = environment.apiURL + 'planning_assistant_error_report'

    const data = {
      receiver: receiver,
      error_description: error_description,
      program_log: program_log,
      current_variables: current_variables
    }
    return this.http.post<{}>(path, data)
  }

  public getTicketOperations(ticket_id: number) {
    const path = environment.apiURL + 'planning_assistant_ticket_operations/' + ticket_id.toString()

    return this.http.get<PlanningAssistantOperation[]>(path)
  }

  public getAllTechnicians() {

    let params = new HttpParams()
    params = params.append('ignore_placeholder', true)

    const path = environment.apiURL + 'technicians_planning_assistant_info'

    return this.http.get<UserInfo[]>(path, {params: params})

  }

  public getPlaceholderTechnicians() {
    const path = environment.apiURL + 'technicians/placeholder.json'
    return this.http.get<Technician[]>(path)
  }

  public getUnplannedTickets(exclude_material_return?: boolean) {

    const path = environment.apiURL + 'planning_assistant_unplanned_tickets'

    let params = new HttpParams()
    if (exclude_material_return) {
      params = params.append('exclude_material_return', true)
    }

    return this.http.get<PlanningAssistantTicket[]>(path, {params: params})
  }

  public getPlanningAssistantTicket(id: string) {
    const path = environment.apiURL + 'planning_assistant_tickets/' + id + '.json'
    return this.http.get<PlanningAssistantTicket>(path)
  }

  public getPlanningAssistantOperations(ids: number[]): Observable<PlanningAssistantOperation[]> {
    const path = environment.apiURL + 'operations_in_planning_assistant_format'

    let params = new HttpParams()
    ids.forEach(id => {
      params = params.append('ids[]', id.toString())
    })

    return this.http.get<PlanningAssistantOperation[]>(path, {params})
  }

  public getPlanningAssistantTickets(ids: number[]): Observable<PlanningAssistantTicket[]> {
    const path = environment.apiURL + 'tickets_in_planning_assistant_format'

    let params = new HttpParams()
    ids.forEach(id => {
      params = params.append('ids[]', id.toString())
    })

    return this.http.get<PlanningAssistantTicket[]>(path, {params})
  }

  public getUnplannedOperationsForProject(project_id: number, exclude_material_return?: boolean) {

    const path = environment.apiURL + `planning_assistant_unplanned_project_operations/${project_id}`

    let params = new HttpParams()
    if (exclude_material_return) {
      params = params.append('exclude_material_return', true)
    }

    return this.http.get<PlanningAssistantOperation[]>(path, {params: params})
  }

  public getOperationUserInfo(ids: number[], date_start: string, date_until: string, config?: {
    project_id?: number,
    exclude_material_return?: boolean
  }) {

    let params = new HttpParams()
    ids.forEach(id => {
      params = params.append('ids[]', id.toString())
    })

    params = params.append('date_start', date_start)
    params = params.append('date_until', date_until)

    if (config?.project_id) {
      params = params.append('project_id', config.project_id)
    }

    if (config?.exclude_material_return) {
      params = params.append('exclude_material_return', true)
    }

    const path = environment.apiURL + 'users_operations_between_dates'

    return this.http.get<{ user_id: number, operations: PlanningAssistantOperation[] }[]>(path, {params})
  }

  public getPriorityRampUpRules() {
    const path = environment.apiURL + 'priority_rampup_rules'
    return this.http.get<PriorityRampUpRuleHash[]>(path)
  }

  public createPriorityRampUpRule(config: PriorityRampUpRuleHash) {
    const path = environment.apiURL + 'priority_rampup_rule'
    return this.http.post<PriorityRampUpRuleHash>(path, {priority_rampup_rule: config})
  }

  public updatePriorityRampUpRule(id: number, config: PriorityRampUpRuleHash) {
    const path = environment.apiURL + `priority_rampup_rule/${id}`
    return this.http.patch<PriorityRampUpRuleHash>(path, {priority_rampup_rule: config})
  }

  public deletePriorityRampUpRule(id: number) {
    const path = environment.apiURL + `priority_rampup_rule/${id}`
    return this.http.delete<PriorityRampUpRuleHash>(path)
  }

}
