import { Component, ElementRef, OnDestroy } from '@angular/core';
import { PAStore } from "../../classes/store";
import { PATour } from "../../classes/tour";
import { PAUtil } from "../../classes/util";
import { faCalendarCheck, faEye, faLock, faLockOpen, faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { PAOperation } from "../../classes/operation";
import { PATimeControl } from "../../singletons/pa-time-control";
import { PATourPlannerControl } from "../../singletons/pa-tourplanner-control";
import { MapContainer } from "../../map/pa-map";
import { PATechnicianDate } from "../../classes/technician-date";
import { NgIf, NgFor } from '@angular/common';

import { TooltipModule } from '@cloudfactorydk/ng2-tooltip-directive';
import { MatFormField } from '@angular/material/form-field';
import { MatSelect } from '@angular/material/select';
import { MatOption } from '@angular/material/core';
import { CommonModule } from "@angular/common";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatSelectModule } from "@angular/material/select";
import { FaIconComponent, FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@Component({
  selector: 'hawk-tour-stopover',
  templateUrl: './tour-stopover.component.html',
  styleUrls: ['./tour-stopover.component.scss', './../../styles/common_styles.scss', './../../../_shared/styles/common-styles.scss'],
  standalone: true,
  imports: [NgIf, NgFor, FaIconComponent, TooltipModule, MatFormField, MatSelect, MatOption, CommonModule, MatFormFieldModule, MatSelectModule, FontAwesomeModule]
})
export class TourStopoverComponent implements OnDestroy {

  protected readonly Math = Math
  protected readonly faCalendarCheck = faCalendarCheck;
  protected readonly faLock = faLock;
  protected readonly faPlus = faPlus;
  protected readonly faLockOpen = faLockOpen;
  protected readonly Number = Number;
  protected readonly faEye = faEye;
  protected readonly faTrash = faTrash;

  private _store: PAStore
  private _referenceTours: PATour[]
  private _notInsertedOperations: PAOperation[] = []
  private _deletedOperations: PAOperation[] = []
  private _mapContainer: MapContainer;
  private _selectedTechnicianDate: PATechnicianDate
  shownNotInsertedOperations: PAOperation[] = []
  shownDeletedOperations: PAOperation[] = []

  constructor(
    public elRef: ElementRef
  ) {
  }

  tourIdxs: { tour: PATour, idx: number, color: string, operation: PAOperation }[] = []

  get store(): PAStore {
    return this._store
  }

  set store(store: PAStore) {
    this._store = store
    this.update()
  }

  get referenceTours(): PATour[] {
    return this._referenceTours
  }

  set referenceTours(ref_tours: PATour[]) {
    this._referenceTours = ref_tours
    this.update()
  }

  get selectedTechnicianDate(): PATechnicianDate {
    return this._selectedTechnicianDate
  }

  set selectedTechnicianDate(td: PATechnicianDate) {
    this._selectedTechnicianDate = td
    this.updateShownNotInsertedOperations()
    this.updateShownDeletedOperations()
  }

  get notInsertedOperations(): PAOperation[] {
    return this._notInsertedOperations
  }

  set notInsertedOperations(operations: PAOperation[]) {
    this._notInsertedOperations = operations
    this.updateShownNotInsertedOperations()
  }

  get deletedOperations(): PAOperation[] {
    return this._deletedOperations
  }

  set deletedOperations(operations: PAOperation[]) {
    this._deletedOperations = operations
    this.updateShownDeletedOperations()
  }

  get mapContainer(): MapContainer {
    return this._mapContainer
  }

  set mapContainer(map_container: MapContainer) {
    this._mapContainer = map_container
  }

  update(): void {
    if (this.referenceTours && this.store) {
      this.updateTourIdxs(this.referenceTours)
    }
  }

  updateTourIdxs(tours: PATour[]): void {
    let res: { tour: PATour, idx: number, color: string, operation: PAOperation }[] = []
    for (let tour of tours) {
      let operations = tour.operations
      for (let operation of operations) {
        if (operation.getStore() == this.store) {
          let op_idx = operations.indexOf(operation) + 1
          res.push({
            tour: tour,
            idx: op_idx,
            operation: operation,
            color: PAUtil.rgbaToString(PAUtil.getIndexColorRGBA(PATimeControl.Instance.weekDays.indexOf(tour.technicianDate.day.week_day)))
          })
        }
      }
    }
    this.tourIdxs = res
  }

  hasSingleTourInput(): boolean {
    return this.referenceTours instanceof PATour
  }

  ngOnDestroy(): void {
    console.log('Tour Stopover Component destroyed')
  }

  clickIdx(tour_idx: { tour: PATour; idx: number; color: string, operation: PAOperation }) {
    if (!this.isMainMapContainer()) {
      tour_idx.tour.technicianDate.removeOperationsFromNewTour([tour_idx.operation])
    } else {
      PATourPlannerControl.Instance.showTourOverview(tour_idx.tour.technicianDate)
    }
  }

  isMainMapContainer(): boolean {
    return this._mapContainer.name == 'map_0'
  }

  isChangedTour(tour: PATour): boolean {
    return this.referenceTours instanceof PATour && this.referenceTours.technicianDate.changedTourContainer.tour == tour
  }

  makeNotInsertedOperationsUnremovable() {
    if (this._selectedTechnicianDate) {
      for (let operation of this.shownNotInsertedOperations) {
        let store = operation.getStore()
        if (this.store == store) {
          this._selectedTechnicianDate.insertionConfigMap.get(operation.id).removable = false
          this._selectedTechnicianDate.updateNewTourOperations()
        }
      }
    }
  }

  addStoreOperationsToNewTour() {
    if (this._selectedTechnicianDate) {
      for (let operation of this.shownDeletedOperations) {
        let store = operation.getStore()
        if (this.store == store) {
          this._selectedTechnicianDate.restoreOperationToNewTour(operation)
        }
      }
    }
  }

  private updateShownNotInsertedOperations() {
    if (this.selectedTechnicianDate) {
      this.shownNotInsertedOperations = this._notInsertedOperations.filter(op => this.selectedTechnicianDate.changedTourContainer.unfittingOperations.includes(op))
    } else {
      this.shownNotInsertedOperations = []
    }
  }

  private updateShownDeletedOperations() {
    if (this.selectedTechnicianDate) {
      this.shownDeletedOperations = this._deletedOperations.filter(op => this.selectedTechnicianDate.changedTourContainer.deletingOperations.includes(op))
    } else {
      this.shownDeletedOperations = []
    }
  }
}