import { inject, } from '@angular/core'
import { Router, CanActivateFn } from '@angular/router'
import { SessionService } from '../_services'
import { firstValueFrom } from 'rxjs'

export const authGuard: CanActivateFn = (route, state) => {
  const sessionService = inject(SessionService);
  const router = inject(Router);
  try {
    return sessionService.loggedIn() ? true : router.parseUrl('/login?returnUrl=' + state.url);
  } catch (error) {
    return router.parseUrl('/login?returnUrl=' + state.url);
  }
}
