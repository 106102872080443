import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import {
  faCar,
  faCheck,
  faExclamationCircle,
  faInfoCircle,
  faWrench,
  IconDefinition
} from "@fortawesome/free-solid-svg-icons";
import { PAFilterControl } from "../../_components/planning-assistant/singletons/pa-filter-control";
import { CommonModule } from "@angular/common";
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TooltipModule, TooltipOptions } from '@cloudfactorydk/ng2-tooltip-directive';
import { MatCheckbox } from "@angular/material/checkbox";
import { FormsModule } from "@angular/forms";
import { MatExpansionPanel, MatExpansionPanelHeader, MatExpansionPanelTitle } from "@angular/material/expansion";
import { MatFormField, MatLabel } from "@angular/material/form-field";
import { MatOption, MatSelect } from "@angular/material/select";

export interface BarData {
  description: string
  values: BarDataValue[]
  start_at_unit_amount: number
  style: 'filled' | 'bordered'
  filter_attributes: string[]
  options: {
    'Reisetechniker'?: boolean
  }
}

export interface BarDataValue {
  color: string,
  unit_amount: number,
  tooltip: BarTooltip,
  icon?: 'loading_error' | 'finished' | null
}

export interface BarOption {
  title: string,
  tooltip: string
}

export interface Deadline {
  at_value: number,
  description: string,
  color: string
}

interface Label {
  color: string,
  description: string
}

interface UnitAmountSteps {
  percentage: number,
  description: string
}

interface BarPercentage {
  from_percent: number
  to_percent: number,
  tooltip: BarTooltip,
  icon?: IconDefinition,
  color: string
}

export interface BarTooltip {
  infos: string[],
  errors: string[]
}

interface Bar {
  description: string;
  percentages: BarPercentage[];
  data: BarData;
}

@Component({
  imports: [CommonModule, FontAwesomeModule, TooltipModule, MatCheckbox, FormsModule, MatLabel, MatExpansionPanel, MatExpansionPanelHeader, MatExpansionPanelTitle, MatFormField, MatSelect, MatOption],
  selector: 'hawk-capacity-bars',
  templateUrl: './capacity-bars.component.html',
  styleUrls: ['./capacity-bars.component.scss', './../../_components/_shared/styles/common-styles.scss'],
  standalone: true
})
export class CapacityBarsComponent implements OnChanges {

  @Input() barData: BarData[]
  @Input() measureUnit: string = '%'
  @Input() deadlines: Deadline[]
  @Input() labels: Label[]

  @Input() unitAmountStart: number = 0
  @Input() unitAmountEnd: number = 100
  @Input() unitAmountInterSteps: number = 1

  @Input() filter: string[] = []
  @Input() options: BarOption[] = []

  @Output() clickBarEvent = new EventEmitter<number>()
  @Output() changeOptionEvent = new EventEmitter<{idx: number, option: string, new_value: boolean}>()

  public unitAmountSteps: UnitAmountSteps[] = []
  public bars: Bar[] = []
  public activeFilter: string

  public icons = {
    loading_error: faExclamationCircle,
    finished: faCheck
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.updateUnitAmountSteps()
    this.updateBarPercentages()
  }

  barIsShown(bar: Bar): boolean {
    if (!this.activeFilter) {
      return true
    } else {
      return !!bar.data.filter_attributes.find(attr => attr == this.activeFilter)
    }
  }

  private updateUnitAmountSteps() {
    const res: UnitAmountSteps[] = []
    const range = this.unitAmountEnd - this.unitAmountStart
    const step_value = range / (this.unitAmountInterSteps + 1)
    const step_percentage = 100 / (this.unitAmountInterSteps + 1)
    for (let i = 0; i <= (this.unitAmountInterSteps + 1); i++) {
      res.push({
        percentage: i * step_percentage,
        description: `${this.unitAmountStart + i * step_value}${this.measureUnit}`
      })
    }
    this.unitAmountSteps = res
  }

  private updateBarPercentages() {
    this.bars = this.barData.map(bar_item => {
      return {
        percentages: this.barDataItemToBarPercentages(bar_item),
        description: bar_item.description,
        data: bar_item
      }
    })
  }

  private barDataItemToBarPercentages(bar_item: BarData): BarPercentage[] {
    const percentages: BarPercentage[] = []
    const range = this.unitAmountEnd - this.unitAmountStart

    let current_percentage = ((bar_item.start_at_unit_amount - this.unitAmountStart) / range) * 100
    for (let value of bar_item.values) {
      percentages.push({
        from_percent: current_percentage,
        to_percent: current_percentage += (value.unit_amount / range) * 100,
        icon: value.icon ? this.icons[value.icon] : null,
        tooltip: value.tooltip,
        color: value.color
      })
    }

    return percentages
  }

  protected readonly faCar = faCar;
  protected readonly faCheck = faCheck;
  protected readonly faWrench = faWrench;
  protected readonly PAFilterControl = PAFilterControl;
  protected readonly faInfoCircle = faInfoCircle;

}