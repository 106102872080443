import { ApplicationConfig, importProvidersFrom, LOCALE_ID, provideZoneChangeDetection } from '@angular/core';
import { provideRouter, RouterModule } from '@angular/router';

import { routes } from './app.routes'
import { APP_BASE_HREF } from '@angular/common';
import { provideHttpClient, withInterceptorsFromDi, HTTP_INTERCEPTORS } from '@angular/common/http';
import { provideAnimations } from '@angular/platform-browser/animations';
import { TooltipModule, TooltipOptions} from '@cloudfactorydk/ng2-tooltip-directive';
import { NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { FlatpickrModule } from 'angularx-flatpickr';
import { German } from 'flatpickr/dist/l10n/de'
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { GermanParserFormatter } from './_helpers/date-parser.injectable';
import { ErrorInterceptor } from './_helpers/error.interceptor';
import flatpickr from 'flatpickr';
import { AdminGuard } from './_guards';
import { NgxMapboxGLModule } from 'ngx-mapbox-gl';
import { SessionService } from './_services';

export const MyDefaultTooltipOptions: TooltipOptions = {
    'show-delay': 0,
    'hideDelay': 0,
    'hideDelayAfterClick': 0,
    'animationDuration': 50,
    'maxWidth': '400px',
    'theme': 'light'
  };
  
export function flatpickrFactory() {
    flatpickr.localize(German)
    return flatpickr
  }
export const appConfig: ApplicationConfig = {
  providers: [
    provideZoneChangeDetection({ eventCoalescing: true }), 
    provideRouter(routes),
    provideHttpClient(withInterceptorsFromDi()), // Provide HTTP Client
    provideAnimations(), // Needed for Material animations
    { provide: APP_BASE_HREF, useValue: '/a' },
    { provide: LOCALE_ID, useValue: 'de' },
    { provide: NgbDateParserFormatter, useClass: GermanParserFormatter },

    importProvidersFrom(TooltipModule.forRoot(MyDefaultTooltipOptions)), // -> tooltip fix
    //{ provide: TooltipModule, useValue: TooltipModule.forRoot(MyDefaultTooltipOptions) },

    // HTTP Interceptors
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },

    { provide: SessionService },

    // Guards
    AdminGuard,

    // Import necessary modules
    importProvidersFrom(
      RouterModule,
      NgMultiSelectDropDownModule.forRoot(),
      FlatpickrModule.forRoot(),
      NgxMapboxGLModule.withConfig({
        accessToken: 'pk.eyJ1IjoibWFwYm94cmFka2UiLCJhIjoiY2o5YmRqYXB0MDJvdDJwbzc5Z2FwZ2lucyJ9._yiQpupY4qWmb72XuF3-dA'
    })
    )
  ]
};