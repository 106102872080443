import { Component, EventEmitter, Output } from '@angular/core';
import { PAProject } from "../classes/project";
import { PATechnician } from "../classes/technician";
import { WeekDay } from "../classes/util";
import { PATimeControl } from "../singletons/pa-time-control";
import { PASettingsControl } from "../singletons/pa-settings-control";
import { PADataControl } from "../singletons/pa-data-control";
import { PAFilterControl } from "../singletons/pa-filter-control";
import { NgFor, NgClass, NgIf } from '@angular/common';
import { MatButton } from '@angular/material/button';
import { MatSlideToggle } from '@angular/material/slide-toggle';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatSelect } from '@angular/material/select';
import { MatOption } from '@angular/material/core';
import { AdditionalPriorityTimeComponent } from '../reuseable-components/additional-priority-time/additional-priority-time.component';
import { CommonModule } from "@angular/common";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatSelectModule } from "@angular/material/select";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";

@Component({
  selector: 'hawk-pa-settings',
  templateUrl: './pa-settings.component.html',
  styleUrls: ['./pa-settings.component.scss', './../styles/common_styles.scss', './../../_shared/styles/common_styles.scss'],
  standalone: true,
  imports: [NgFor, NgClass, MatButton, NgIf, MatSlideToggle, ReactiveFormsModule, FormsModule, MatFormField, MatLabel, MatInput, MatSelect, MatOption, AdditionalPriorityTimeComponent, CommonModule, MatFormFieldModule, MatSelectModule, MatSlideToggleModule]
})
export class PaSettingsComponent {

  @Output() updateWeekdaysEvent = new EventEmitter<WeekDay[]>()

  protected readonly Project = PAProject;
  protected readonly Technician = PATechnician;

  constructor(
  ) {
  }

  public updateFilteredWeekdays(): void {
    this.updateWeekdaysEvent.emit(this.weekDayFilterToWeekdayStringList())
  }

  public weekDayFilterToWeekdayStringList(): WeekDay[] {
    let res = []
    let show_weekday_config = PASettingsControl.Instance.selectedSettingConfig.data.general.show_weekdays
    show_weekday_config.monday ? res.push('monday') : {}
    show_weekday_config.tuesday ? res.push('tuesday') : {}
    show_weekday_config.wednesday ? res.push('wednesday') : {}
    show_weekday_config.thursday ? res.push('thursday') : {}
    show_weekday_config.friday ? res.push('friday') : {}
    show_weekday_config.saturday ? res.push('saturday') : {}
    show_weekday_config.sunday ? res.push('sunday') : {}
    return res
  }

  public toggleWeekday(weekday: string): void {
    let show_weekday_config = PASettingsControl.Instance.selectedSettingConfig.data.general.show_weekdays
    switch (weekday) {
      case 'mo' : {
        show_weekday_config.monday = !show_weekday_config.monday
        break
      }
      case 'di' : {
        show_weekday_config.tuesday = !show_weekday_config.tuesday
        break
      }
      case 'mi' : {
        show_weekday_config.wednesday = !show_weekday_config.wednesday
        break
      }
      case 'do' : {
        show_weekday_config.thursday = !show_weekday_config.thursday
        break
      }
      case 'fr' : {
        show_weekday_config.friday = !show_weekday_config.friday
        break
      }
      case 'sa' : {
        show_weekday_config.saturday = !show_weekday_config.saturday
        break
      }
      case 'so' : {
        show_weekday_config.sunday = !show_weekday_config.sunday
        break
      }
    }
    this.updateFilteredWeekdays()
  }

  selectedWeekDaysToString(): string {
    let show_weekday_config = PASettingsControl.Instance.selectedSettingConfig.data.general.show_weekdays
    let weekdays_groups = []
    let connected = false
    for (let week_day of PATimeControl.Instance.weekDays) {
      let filter_value = show_weekday_config[week_day]
      let element_count = weekdays_groups.length
      if (connected && element_count) {
        if (filter_value) {
          weekdays_groups[element_count - 1].push(PATimeControl.Instance.weekDayToGermanName(week_day, true))
        } else {
          connected = false
        }
      } else {
        if (filter_value) {
          weekdays_groups.push([PATimeControl.Instance.weekDayToGermanName(week_day, true)])
          connected = true
        }
      }
    }
    return weekdays_groups.map(weekday_group => weekday_group.length == 1 ? weekday_group[0] : `${weekday_group[0]}-${weekday_group[weekday_group.length - 1]}`).join(', ')
  }

  protected readonly PASettingsControl = PASettingsControl;
  protected readonly PAFilterControl = PAFilterControl;
  protected readonly PADataControl = PADataControl;
}