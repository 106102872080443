<div class="">
  <div class="quicksettings">
    <button class="btn btn-inverted" (click)="saveState()">Meine config speichern</button>
    <button  class="btn" (click)="deleteState()">config löschen</button>
    <input type="text" (input)="quicksearchChanged($event)" placeholder="Schnellfilter">
  </div>
  <ag-grid-angular style="height:800px; width: 100%;" [rowData]="tickets" [theme]="hawkTheme" [loadThemeGoogleFonts]="true"
    [theme]="hawkTheme" [loadThemeGoogleFonts]="true"
    [defaultColDef]="defaultColDef" [columnDefs]="colDefs" [rowClassRules]="rowClassRules" [pagination]="true"
    [rowSelection]="rowSelection" [initialState]="initialState"
    [paginationPageSize]="100" [localeText]="locale" [sideBar]="sideBarOptions"
    [paginationPageSizeSelector]="[50,100,300,1000,9000]" (gridReady)="onGridReady($event)">
  </ag-grid-angular>
</div>