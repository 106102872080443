import { Injectable } from '@angular/core'
import { HttpClient, HttpHeaders } from '@angular/common/http'

import { environment } from '../../environments/environment'
import { Warehouse, BulkList, DeliveryNote, StorageBin } from '../_models'
import { ReturnRequest } from '../_models/return-request.interface'

@Injectable({
  providedIn: 'root'
})
export class WarehouseService {

  constructor(
    private http: HttpClient,
  ) {
  }

  public loadWarehouses(params = {}) {
    const path = environment.apiURL + 'warehouses.json'
    return this.http.get<Warehouse[]>(path, { params: params })
  }

  public loadWarehouse(id: number | string | String, params = {}) {
    const path = environment.apiURL + 'warehouses/' + id + '.json'
    return this.http.get<any>(path, { params })
  }

  public loadBulkJobs() {
    const path = environment.apiURL + 'bulk_list.json'
    return this.http.get<BulkList[]>(path)
  }

  public loadArticles(id: number | string | String, params = {}) {
    const path = environment.apiURL + 'warehouses/' + id + '/articles.json'
    return this.http.get<any[]>(path, { params })
  }

  public loadRmas(id: number | string | String, template_id: number | string | String, params = {}) {
    const path = environment.apiURL + 'warehouses/' + id + '/rmas/' + template_id + '.json'
    return this.http.get<any[]>(path, { params })
  }

  public loadStorageBins(id: number | string | String, params: any = {}) {
    const path = environment.apiURL + 'warehouses/' + id + '/storage_bins.json'
    return this.http.get<StorageBin[]>(path, { params: params })
  }

  public updateWareHouse(id: string | number | String, data: any) {
    const path = environment.apiURL + 'warehouses/' + id + '.json'

    return this.http.patch<any>(path, data)
  }

  public deleteWareHouse(id: string | String | number) {
    const path = environment.apiURL + 'warehouses/' + id + '.json'

    return this.http.delete<any>(path);
  }

  public deactivateWarehouse(id: string | String | number) {
    const path = environment.apiURL + 'warehouses/' + id + '/deactivate.json'

    return this.http.delete<any>(path);
  }

  public deleteStorageBin(wreHouseId: string | number | String, id: string | number | String) {
    const path = environment.apiURL + 'warehouses/' + wreHouseId + '/storage-bin/' + id + '.json'

    return this.http.delete<any>(path);
  }

  public updateStorageBin(wreHouseId: string | number | String, id: string | number | String, data: object | any) {
    const path = environment.apiURL + 'warehouses/' + wreHouseId + '/storage-bin/' + id + '/update.json'

    return this.http.post<any>(path, { storage_bin: { ...data } });
  }

  public addNewStorageBin(wreHouseId: string | number | String, data: object | any) {
    const path = environment.apiURL + 'warehouses/' + wreHouseId + '/storage-bin' + '/add.json'

    return this.http.post<any>(path, { storage_bin: { ...data } });
  }

  public splitArticles(storage_bin_id: number, warehouse_id: number, article_ids: number[]) {
    const data = {
      article_ids: article_ids,
      warehouse_id: warehouse_id,
      storage_bin_id: storage_bin_id
    }

    const path = environment.apiURL + 'warehouses/split_articles.json'

    return this.http.post<DeliveryNote[]>(path, data)
  }

  public createIntRetoure(data: ReturnRequest) {

    const path = environment.apiURL + 'warehouses/create_international_return.json'

    return this.http.post<DeliveryNote>(path, data)
  }

}
