import { Component, inject, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AG_GRID_LOCALE_DE } from 'src/app/_helpers/aggrid.locale.de';
import { DeliveryNote, StorageBin, Warehouse } from 'src/app/_models';
import { DeliveryNoteService, WarehouseService } from 'src/app/_services';
import { AgGridGenericLinkRenderer } from '../_shared/ag-grid-renderer/ag-grid-generic-link.component';
import { ColDef, RowClassRules } from 'ag-grid-enterprise';
import { CommonModule } from "@angular/common";
import { ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { NgSelectModule } from "@ng-select/ng-select";
import { AgGridAngular } from 'ag-grid-angular';
import { AgGridThemeService } from 'src/app/_services/ag-grid-theme.service';

@Component({
  imports: [CommonModule, ReactiveFormsModule, RouterModule, NgSelectModule, AgGridAngular],
    selector: 'hawk-delivery-note-receipt',
    templateUrl: './delivery-note-receipt.component.html',
    styleUrls: ['./delivery-note-receipt.component.scss'],
})
export class DeliveryNoteReceiptComponent implements OnInit {
  constructor(
    private deliveryNoteService: DeliveryNoteService,
    private route: ActivatedRoute,
    private router: Router,
    private warehouseService: WarehouseService
  ) { }

  public hawkTheme = inject(AgGridThemeService).getTheme();
  public articles: any[];
  public locale = AG_GRID_LOCALE_DE;
  public deliveryNoteId: number | String | string;
  public loadingPricing: boolean = false;
  public deliveryNote: DeliveryNote;
  public searchTerm: string = ''
  public locations: any[] = ["Lager", "Verbraucht"]
  public warehouses: Warehouse[];
  public bins: StorageBin[];
  public showTargetSelection: boolean = true
  public loadingWarehouses: boolean = true;
  warehouseTotalPages: number
  warehousesPage: number = 1
  public loadingbins: boolean = false;
  binsTotalPages: number
  binsPage: number = 1
  public submitting: boolean = false;

  public rowSelection: "single" | "multiple" = "multiple"
  public rowClassRules: RowClassRules = {
    // if tech on site, color row
    'tech-on-site': (params) => {
      if (params?.data === undefined) return "...";
      return params.data.tech_on_site
    },
  }

  public defaultColDef: ColDef = {
    filter: true,
    flex: 1
  }

  public colDefs: ColDef[] = [
    {
      field: "rma",
      headerName: 'Bezeichnung',
      cellRenderer: AgGridGenericLinkRenderer,
      cellRendererParams: {
        routerLink: ['/', 'artikel', '@id'],
        labelFromData: true,
        dataKey: 'rma'
      }
    },
    { field: "description" },
    { field: "product_category", headerName: 'Produkt' },
    { field: "vendor", headerName: 'Hersteller' }
  ]
  public deliveryNoteReceipt: FormGroup;

  public saveReceipt() {
    const formData = this.deliveryNoteReceipt.value;
    this.deliveryNoteService.saveReceipt(this.deliveryNoteId, formData).subscribe(
      {
        next: () => {
          this.router.navigate(['lieferscheine', this.deliveryNoteId]);
        },
        error: (error) => {
          console.log('error', error);
        }
      }
    )
  }

  ngOnInit(): void {
    this.deliveryNoteId = parseInt(this.route.snapshot.paramMap.get('id'));
    this.loadDeliveryNote();
  }

  public loadMoreWarehouses() {
    if(this.warehouseTotalPages > this.warehousesPage) {
      this.warehousesPage += 1;
      this.loadWareHouses(this.warehousesPage);
    }
  }

  private loadWareHouses(page: number, includedId: number | null = null) {
    this.loadingWarehouses = true;
    this.warehouseService.loadWarehouses(
      { per_page: 600, page: page, ...(includedId && { id: includedId } ) }
    )
    .subscribe(
      {
        next: (response: any) => {
          this.warehouseTotalPages = response.total_pages;
          this.warehouses = response.warehouses;
          this.loadingWarehouses = false;
        },
        error: (error) => {
          console.log('error', error);
          this.loadingWarehouses = false;
        }
      }
    )
  }

  public loadMoreBins() {
    const warehouseId = this.deliveryNoteReceipt.controls.warehouse_id.value;
    if(warehouseId && this.binsTotalPages > this.binsPage) {
      this.binsPage += 1;
      this.loadBins(this.binsPage, warehouseId);
    }
  }

  private loadBins(page: number, warehouseId: number, includedId: number | null = null) {
    this.loadingbins = true;
    this.warehouseService.loadStorageBins(warehouseId,
      { per_page: 200, page: page, ...(includedId && { bin_id: includedId } ) }
    )
    .subscribe(
      {
        next: (response: any) => {
          this.binsTotalPages = response.total_pages;
          this.bins = response.bins;
          this.loadingbins = false;
        },
        error: (error) => {
          console.log('error', error);
          this.loadingbins = false;
        }
      }
    )
  }
  private loadDeliveryNote() {
    this.deliveryNoteService.show(this.deliveryNoteId, { load_first_bin_set: true }).subscribe(
      {
        next: (response: DeliveryNote) => {
          this.deliveryNote = response;
          this.articles = response.articles;
          // In case delivery note has it's own warehouse.
          if(this.deliveryNote.recipient_warehouse_id) {
            this.loadWareHouses(1, this.deliveryNote.recipient_warehouse_id);
            this.loadBins(1, this.deliveryNote.recipient_warehouse_id);
          }
          // In this case lets load first warehouse from the bins.
          else if(this.deliveryNote.warehouse_id) {
            this.loadWareHouses(1, this.deliveryNote.warehouse_id);
            this.loadBins(1, this.deliveryNote.warehouse_id, this.deliveryNote.bin_id)
          } else {
            this.loadWareHouses(1);
          }
          this.initializeForm();
        },
        error: (error) => {
          console.log('error', error);
        }
      }
    )
  }

  private initializeForm() {
    const customValidator: ValidatorFn = (
      control: AbstractControl,
    ): ValidationErrors | null => {
      const location = control.get('location');
      const warehouseId = control.get('warehouse_id');
      const storageBin = control.get('storage_bin_id');

      if(!location.value) return location;
      if(location.value === 'Verbraucht') return null;
      if(!warehouseId.value) return warehouseId;
      if(!storageBin.value) return storageBin;

      return null;
    };
    this.deliveryNoteReceipt = new FormGroup({
      location: new FormControl('Lager'),
      warehouse_id: new FormControl(this.deliveryNote.recipient_warehouse_id || this.deliveryNote.warehouse_id),
      storage_bin_id: new FormControl(this.deliveryNote.bin_id),
    }, { validators: customValidator });
    this.deliveryNoteReceipt.controls.location.valueChanges.subscribe((x) => {
      if(x === 'Verbraucht') {
        this.deliveryNoteReceipt.controls.warehouse_id.disable()
        this.deliveryNoteReceipt.controls.storage_bin_id.disable()
        this.showTargetSelection = false
      } else {
        this.deliveryNoteReceipt.controls.warehouse_id.enable()
        this.deliveryNoteReceipt.controls.storage_bin_id.enable()
        this.showTargetSelection = true
      }
    });
    this.deliveryNoteReceipt.controls.warehouse_id.valueChanges.subscribe((x) => {
      this.bins = [];
      this.deliveryNoteReceipt.controls.storage_bin_id.setValue(null);
      if(!x) {
        this.deliveryNoteReceipt.controls.storage_bin_id.disable()
        return;
      }

      this.deliveryNoteReceipt.controls.storage_bin_id.enable()
      this.loadBins(1, x);
    });
  }

}