<div *ngIf="currentCalendarWeekData && referenceOperation" class="close_tours_container">
  <div *ngIf="updatingTechnicianDates || updatingTravelTechnicianDates" style="position:absolute; pointer-events: none; top: 0; right: 0; width: 40px; height: 40px;">
    <hawk-throbber [description]="''"></hawk-throbber>
  </div>
  <div style="margin: auto" *ngIf="!shownCloseTechnicianDates.length">
    <h6>Keine geplanten nahen Touren/Aufträge</h6>
  </div>
  <div *ngFor="let close_technician_date of shownCloseTechnicianDates"
       class="close_tour" (click)="clickTechnicianDate.emit(close_technician_date.technician_date)"
       [ngClass]="(tourComparisonsIncludeTechnicianDate(close_technician_date.technician_date) ? 'selected' : 'black_gradient_background')"
  >
    <div style="text-align: center; display: inline-flex; flex-direction: row">
      <div style="margin: auto; flex: 1;">
        {{close_technician_date.technician_date.technician.getFullName()}} <br>
        {{close_technician_date.technician_date.day.date_string}}
      </div>
      <div *ngIf="close_technician_date.travel_technician_date_type"
           style="background-size: 100%; width: 24px; height: 24px; margin: auto 6px auto 6px;position: relative;"
           class="destination_background_container"
           [tooltip]="'Reisetechniker-Tag: ' + close_technician_date.travel_technician_date_type"
      >
        <div class="destination_background"></div>
        <div class="destination_background_border"></div>
      </div>
    </div>
    <div style="text-align: center; margin: auto; display: inline-flex; flex-direction: row; flex-wrap: wrap;">
      <div style="white-space: nowrap; margin: auto 3px auto auto;" [ngClass]="(25 > close_technician_date.technician_date.tour.route.time_specific_data[PAFilterControl.Instance.selectedOperationTimeFilter].workload_percent) ? 'green' : (close_technician_date.technician_date.tour.route.time_specific_data[PAFilterControl.Instance.selectedOperationTimeFilter].workload_percent > 75 ? 'red' : 'yellow')">
        Ausl. {{close_technician_date.technician_date.tour.route.time_specific_data[PAFilterControl.Instance.selectedOperationTimeFilter].workload_percent}}%
      </div>
      <div style="white-space: nowrap; margin: auto auto auto 0; padding-left: 3px; border-left: 1px solid white;">
        Entf. {{close_technician_date.distance_km.toPrecision(2)}}km
      </div>
    </div>
    <div style="position: absolute; bottom: 0; left: 0; right: 0; height: 6px;" [style.background-color]="Util.rgbaToString(Util.getIndexColorRGBA(PATimeControl.Instance.weekDays.indexOf(close_technician_date.technician_date.day.week_day)))"></div>
  </div>
</div>
