<hawk-record-navigation class="common_style" *ngIf="user" [breadcrumbLinkList]="[{link: '/a/benutzer', description: 'Nutzer'}, {link: '/a/benutzer/' + user.id, description: user.full_name}]" [root]="'user'" [leafDescription]="'Bearbeiten'">
  <form [formGroup]="userForm" class="normal-form" (submit)="onSubmit()">
    <div style="flex: 1;" class="card-body responsive_split">
      <div class="flex_col">
        <section class="big_header">
          <h2>Stammdaten</h2>
          <div class="input_list label_20">
            <div class="input_row">
              <div class="input_label">
                Vorname:
              </div>
              <div class="input_container">
                <mat-form-field class="outline-small no-subscript" appearance="outline">
                  <input matInput formControlName="first_name">
                </mat-form-field>
              </div>
            </div>
            <div class="input_row">
              <div class="input_label">
                Nachname:
              </div>
              <div class="input_container">
                <mat-form-field class="outline-small no-subscript" appearance="outline">
                  <input matInput formControlName="last_name">
                </mat-form-field>
              </div>
            </div>
            <div class="input_row">
              <div class="input_label">
                E-Mail:
              </div>
              <div class="input_container">
                <div class="icon_wrapper">
                  <div class="icon_container">
                    <fa-icon [icon]="faMailBulk"></fa-icon>
                  </div>
                  <mat-form-field class="outline-small no-subscript" appearance="outline">
                    <input matInput formControlName="email1">
                  </mat-form-field>
                </div>
              </div>
            </div>
            <div class="input_row">
              <div class="input_label">
                Zusätzliche E-Mail:
              </div>
              <div style="flex: 1;" class="flex_col">
                <div class="input_container">
                  <div class="icon_wrapper">
                    <div class="icon_container">
                      <fa-icon [icon]="faMailBulk"></fa-icon>
                    </div>
                    <mat-form-field class="outline-small no-subscript" appearance="outline">
                      <input matInput formControlName="email2">
                    </mat-form-field>
                  </div>
                </div>
                <div class="input_description">
                  Nachrichten werden zusätzlich per CC an diese E-Mail-Adressen gesendet. Mehrere E-Mail können mit einem Semikolon getrennt angegeben werden.
                </div>
              </div>
            </div>
            <div class="input_row">
              <div class="input_label">
                E-Mail MoreApp:
              </div>
              <div style="flex: 1;" class="flex_col">
                <div class="input_container">
                  <div class="icon_wrapper">
                    <div class="icon_container">
                      <fa-icon [icon]="faMailBulk"></fa-icon>
                    </div>
                    <mat-form-field class="outline-small no-subscript" appearance="outline">
                      <input matInput formControlName="email_moreapp">
                    </mat-form-field>
                  </div>
                </div>
                <div class="input_description">
                  Diese E-Mail-Adresse wird für die Zuordnung in der MoreApp benutzt.
                </div>
              </div>
            </div>
            <div class="input_row">
              <div class="input_label">
                Partner E-Mail:
              </div>
              <div class="input_container">
                <div class="icon_wrapper">
                  <div class="icon_container">
                    <fa-icon [icon]="faMailBulk"></fa-icon>
                  </div>
                  <mat-form-field class="outline-small no-subscript" appearance="outline">
                    <input matInput formControlName="email_partner1">
                  </mat-form-field>
                </div>
              </div>
            </div>
            <div class="input_row">
              <div class="input_label">
                Zusätzliche Partner E-Mail:
              </div>
              <div style="flex: 1;" class="flex_col">
                <div class="input_container">
                  <div class="icon_wrapper">
                    <div class="icon_container">
                      <fa-icon [icon]="faMailBulk"></fa-icon>
                    </div>
                    <mat-form-field class="outline-small no-subscript" appearance="outline">
                      <input matInput formControlName="email_partner2">
                    </mat-form-field>
                  </div>
                </div>
                <div class="input_description">
                  Mehrere E-Mail können mit einem Semikolon getrennt angegeben werden.
                </div>
              </div>
            </div>
            <div class="input_row">
              <div class="input_label">
                Kunde:
              </div>
              <div class="input_container">
                <mat-form-field class="outline-small no-subscript" appearance="outline">
                  <mat-select formControlName="customer_id">
                    <mat-option [value]="null">-Keine Zuordnung-</mat-option>
                    <mat-option *ngFor="let customer of customers" [value]="customer.id">{{customer.name}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
          </div>
        </section>
        <section class="big_header">
          <h2>Angestellt bei Subunternehmer</h2>
          <mat-form-field class="outline-small no-subscript" appearance="outline" style="width: 100%;">
            <mat-select formControlName="employer_id">
              <mat-option [value]="null">-</mat-option>
              <mat-option *ngFor="let employer of subcontractors" [value]="employer.id">{{employer.name}}</mat-option>
            </mat-select>
          </mat-form-field>
        </section>
      </div>
      <div class="flex_col">
        <section class="big_header">
          <h2>Adresse</h2>
          <div class="flex_row geocoder_container">
            <div class="geocoder" id="address_geocoder"></div>
          </div>
          <div class="input_list label_15">
            <div class="input_row">
              <div class="input_label">
                Firma
              </div>
              <div class="input_container">
                <mat-form-field class="outline-small no-subscript" appearance="outline">
                  <input matInput formControlName="company">
                </mat-form-field>
              </div>
            </div>
            <div class="input_row">
              <div class="input_label">
                Straße
              </div>
              <div class="input_container">
                <mat-form-field class="outline-small no-subscript" appearance="outline">
                  <input matInput formControlName="street">
                </mat-form-field>
              </div>
            </div>
            <div class="input_row">
              <div class="input_label">
                Ort
              </div>
              <div class="input_container">
                <mat-form-field class="outline-small no-subscript" appearance="outline" style="max-width: 33%; margin-right: 12px;">
                  <input matInput formControlName="zip" placeholder="PLZ">
                </mat-form-field>
                <mat-form-field class="outline-small no-subscript" appearance="outline">
                  <input matInput formControlName="city">
                </mat-form-field>
              </div>
            </div>
            <div class="input_row">
              <div class="input_label">
                Land
              </div>
              <div class="input_container">
                <mat-form-field class="outline-small no-subscript" appearance="outline">
                  <input matInput formControlName="country">
                </mat-form-field>
              </div>
            </div>
            <div class="input_row">
              <div class="input_label">
                Staat
              </div>
              <div class="input_container">
                <mat-form-field class="outline-small no-subscript" appearance="outline">
                  <input matInput formControlName="state">
                </mat-form-field>
              </div>
            </div>
          </div>
        </section>
        <section class="big_header">
          <h2>Firmenadresse</h2>
          <div class="flex_row geocoder_container">
            <div class="geocoder" id="company_address_geocoder"></div>
          </div>
          <div class="input_list label_15">
            <div class="input_row">
              <div class="input_label">
                Firma
              </div>
              <div class="input_container">
                <mat-form-field class="outline-small no-subscript" appearance="outline">
                  <input matInput formControlName="company_address_company">
                </mat-form-field>
              </div>
            </div>
            <div class="input_row">
              <div class="input_label">
                Name
              </div>
              <div class="input_container">
                <mat-form-field class="outline-small no-subscript" appearance="outline" style="margin-right: 12px;">
                  <input matInput formControlName="company_address_firstname" placeholder="Vorname">
                </mat-form-field>
                <mat-form-field class="outline-small no-subscript" appearance="outline">
                  <input matInput formControlName="company_address_lastname" placeholder="Nachname">
                </mat-form-field>
              </div>
            </div>
            <div class="input_row">
              <div class="input_label">
                Straße
              </div>
              <div class="input_container">
                <mat-form-field class="outline-small no-subscript" appearance="outline">
                  <input matInput formControlName="company_address_street">
                </mat-form-field>
              </div>
            </div>
            <div class="input_row">
              <div class="input_label">
                Ort
              </div>
              <div class="input_container">
                <mat-form-field class="outline-small no-subscript" appearance="outline" style="max-width: 33%; margin-right: 12px;">
                  <input matInput formControlName="company_address_zip" placeholder="PLZ">
                </mat-form-field>
                <mat-form-field class="outline-small no-subscript" appearance="outline">
                  <input matInput formControlName="company_address_city">
                </mat-form-field>
              </div>
            </div>
            <div class="input_row">
              <div class="input_label">
                Land
              </div>
              <div class="input_container">
                <mat-form-field class="outline-small no-subscript" appearance="outline">
                  <input matInput formControlName="company_address_country">
                </mat-form-field>
              </div>
            </div>
            <div class="input_row">
              <div class="input_label">
                Staat
              </div>
              <div class="input_container">
                <mat-form-field class="outline-small no-subscript" appearance="outline">
                  <input matInput formControlName="company_address_state">
                </mat-form-field>
              </div>
            </div>
          </div>
        </section>
      </div>
      <div class="flex_col">
        <section class="big_header">
          <h2>Kontaktdaten</h2>
          <div class="input_list label_20">
            <div class="input_row">
              <div class="input_label">
                Telefon 1:
              </div>
              <div class="input_container">
                <div class="icon_wrapper">
                  <div class="icon_container">
                    <fa-icon [icon]="faPhone"></fa-icon>
                  </div>
                  <mat-form-field class="outline-small no-subscript" appearance="outline">
                    <input matInput formControlName="phone1">
                  </mat-form-field>
                </div>
              </div>
            </div>
            <div class="input_row">
              <div class="input_label">
                Telefon 2:
              </div>
              <div class="input_container">
                <div class="icon_wrapper">
                  <div class="icon_container">
                    <fa-icon [icon]="faPhone"></fa-icon>
                  </div>
                  <mat-form-field class="outline-small no-subscript" appearance="outline">
                    <input matInput formControlName="phone2">
                  </mat-form-field>
                </div>
              </div>
            </div>
            <div class="input_row">
              <div class="input_label">
                Telefon für Dispatching:
              </div>
              <div class="input_container">
                <div class="icon_wrapper">
                  <div class="icon_container">
                    <fa-icon [icon]="faPhone"></fa-icon>
                  </div>
                  <mat-form-field class="outline-small no-subscript" appearance="outline">
                    <input matInput formControlName="telephone_dispatching">
                  </mat-form-field>
                </div>
              </div>
            </div>
            <div class="input_row">
              <div class="input_label">
                SMS:
              </div>
              <div class="input_container">
                <div class="icon_wrapper">
                  <div class="icon_container">
                    <fa-icon [icon]="faSms"></fa-icon>
                  </div>
                  <mat-form-field class="outline-small no-subscript" appearance="outline">
                    <input matInput formControlName="sms">
                  </mat-form-field>
                </div>
              </div>
            </div>
            <div class="input_row">
              <div class="input_label">
                Fax:
              </div>
              <div class="input_container">
                <mat-form-field class="outline-small no-subscript" appearance="outline">
                  <input matInput formControlName="fax">
                </mat-form-field>
              </div>
            </div>
            <div class="input_row">
              <div class="input_label">
                Weitere Kontaktinformationen:
              </div>
              <div class="input_container">
                <mat-form-field class="outline-small no-subscript" appearance="outline">
                  <textarea style="height: 80px;" matInput formControlName="more_contact_info"></textarea>
                </mat-form-field>
              </div>
            </div>
          </div>
        </section>
        <section class="big_header">
          <h2>Nutzerrollen</h2>
          <div class="well flex_row" style="width: 100%; flex-wrap: wrap;">
            @for (checked_role of checkedRoles; track checkedRoles) {
              <mat-checkbox style="padding-right: 12px; margin-right: 6px; margin-top: 6px;" color="primary" [style.border-right]="checkedRoles.indexOf(checked_role) != checkedRoles.length - 1 ? '1px solid lightgray' : ''" [(ngModel)]="checked_role.checked" [ngModelOptions]="{standalone: true}">{{checked_role.role.name}}</mat-checkbox>
            }
          </div>
        </section>
      </div>
    </div>

    <div style="width: 100%; padding-top: 0!important;;" class="flex_col card-body">
      <section class="big_header">
        <h2>Sonstiges</h2>
        <div class="well flex_row" style="width: 100%;">
          <mat-form-field style="margin-right: 10px;" class="outline-small no-subscript" appearance="outline" floatLabel="always">
            <mat-label>Karten-Nr der Packstation:</mat-label>
            <input matInput formControlName="card_nr_packstation">
          </mat-form-field>
          <mat-form-field style="margin-right: 10px;"  class="outline-small no-subscript" appearance="outline" floatLabel="always">
            <mat-label>Pickpoint-Nr:</mat-label>
            <input matInput formControlName="pickpoint_nr">
          </mat-form-field>
          <mat-form-field style="margin-right: 10px;"  class="outline-small no-subscript" appearance="outline" floatLabel="always">
            <mat-label>XML-ID (für Ticket-Export):</mat-label>
            <input matInput formControlName="xml_id">
          </mat-form-field>
        </div>
      </section>
    </div>

    <div style="flex-wrap: wrap;" class="flex_row footer_row">
      <button mat-raised-button class="primary_bg_color_button" color="primary" [disabled]="!userForm.valid">
        Änderung speichern
      </button>
      <a [routerLink]="'/benutzer/' + user.id">
        <button type="button" mat-button>
          Abbrechen
        </button>
      </a>
    </div>
  </form>
</hawk-record-navigation>