import { Component, OnDestroy } from '@angular/core';
import { PAStore } from "../../classes/store";
import { PAProject } from "../../classes/project";
import { PAOperation } from "../../classes/operation";
import { faCalendarCheck, faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import { PATechnicianDate } from "../../classes/technician-date";
import { PATourPlannerControl } from "../../singletons/pa-tourplanner-control";
import { NgIf, NgFor } from '@angular/common';
import { IconExpandBarComponent } from '../icon-expand-bar/icon-expand-bar.component';
import { TooltipModule } from '@cloudfactorydk/ng2-tooltip-directive';

import { CommonModule } from "@angular/common";
import { FaIconComponent, FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@Component({
  selector: 'hawk-store-popup',
  templateUrl: './store-popup.component.html',
  styleUrls: ['./store-popup.component.scss', './../../styles/common_styles.scss'],
  standalone: true,
  imports: [NgIf, NgFor, IconExpandBarComponent, TooltipModule, FaIconComponent, CommonModule, FontAwesomeModule]
})
export class StorePopupComponent implements OnDestroy {

  public store: PAStore
  public projects: PAProject[] = []
  public technicianDate: PATechnicianDate

  public expandedProjects: PAProject[] = []

  constructor(
  ) {
  }

  getStoresProjectOperations(project: PAProject): PAOperation[] {
    return this.store.shownMapOperations.filter(o => o.ticket.project.id == project.id)
  }

  removeOperationFromTourPlanning(operation: PAOperation): void {
    if (this.technicianDate) {
      this.technicianDate.removeOperationsFromNewTour([operation])
    } else {
      PATourPlannerControl.Instance.removeOperationsFromTourPlanning([operation]);
    }
    this.store.fireUpdateManually()
  }

  addOperationToTourPlanning(operation: PAOperation): void {
    if (this.technicianDate) {
      PATourPlannerControl.Instance.addOperationsToTourPlanning([operation]);
      PATourPlannerControl.Instance.planningInstructions.addOperationsTechnicianConstraints([operation], [this.technicianDate.technician], [this.technicianDate.day.utc_timestamp])
    } else {
      if (PATourPlannerControl.Instance.planningMenuIsInPlanningProcess()) {
        PATourPlannerControl.Instance.addOperationsToTourPlanning([operation]);
      } else {
        PATourPlannerControl.Instance.planOperation(operation)
      }
    }
    this.store.fireUpdateManually()
  }

  ngOnDestroy(): void {
    console.log('Store Popup destroyed')
  }

  protected readonly faPlus = faPlus;
  protected readonly faMinus = faMinus;
  protected readonly faCalendarCheck = faCalendarCheck;
  protected readonly PATourPlannerControl = PATourPlannerControl;
}