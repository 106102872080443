import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { PATicket } from '../classes/ticket';
import { PAProject } from '../classes/project';
import { PAOperation } from '../classes/operation';
import { PATechnician } from '../classes/technician';
import { PAFilter } from '../classes/filter';
import { PALocation } from '../classes/location';
import { PAUtil, WeekDay } from '../classes/util';
import { CalendarWeekData } from 'src/app/_models/planning-assistant.interface';
import { PATechnicianDate } from '../classes/technician-date';
import { faCheck, faPhone, faTrash, faWindowClose } from '@fortawesome/free-solid-svg-icons';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import {
  MatCell,
  MatCellDef,
  MatColumnDef,
  MatHeaderCell,
  MatHeaderCellDef,
  MatHeaderRow,
  MatHeaderRowDef,
  MatNoDataRow,
  MatRow,
  MatRowDef,
  MatTable,
  MatTableDataSource
} from '@angular/material/table';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { MatSort, MatSortHeader, Sort } from '@angular/material/sort';
import { Route } from 'src/app/_services/mapbox.service';
import { PAPlanningInstructions } from '../classes/planning-instructions';
import { PAStore } from '../classes/store';
import { PopoutWindowComponent } from "../reuseable-components/popout-window/popout-window.component";
import { PATourPlannerControl } from "../singletons/pa-tourplanner-control";
import { PADataControl } from "../singletons/pa-data-control";
import { PATimeControl } from "../singletons/pa-time-control";
import { PAMapControl } from "../singletons/pa-map-control";
import { PAFilterControl } from "../singletons/pa-filter-control";
import { PAMapboxControl } from "../singletons/pa-mapbox-control";
import { PASettingsControl } from "../singletons/pa-settings-control";
import { TechnicianDateCollection } from "../classes/technician-date-collection";
import { CommonModule, NgClass, NgFor, NgIf } from '@angular/common';
import { MatFormField, MatFormFieldModule, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonToggle, MatButtonToggleGroup, MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatButton, MatButtonModule } from '@angular/material/button';
import { ExpandBarHeaderComponent } from '../reuseable-components/expand-bar-header/expand-bar-header.component';

import { MaterialSelectionComponent } from '../reuseable-components/material-selection/material-selection.component';
import { OperationCollectionComponent } from './operation-collection/operation-collection.component';
import { CloseToursComponent } from './close-tours/close-tours.component';
import { MatSelect, MatSelectModule } from '@angular/material/select';
import { MatOptgroup, MatOption } from '@angular/material/core';
import { FaIconComponent, FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@Component({
  selector: 'hawk-tourplanner-menu',
  templateUrl: './tourplanner-menu.component.html',
  styleUrls: ['./tourplanner-menu.component.scss', './../styles/common_styles.scss', './../../_shared/styles/common-styles.scss'],
  standalone: true,
  imports: [NgClass, MatFormField, MatLabel, MatInput, ReactiveFormsModule, MatButtonToggleGroup, FormsModule, MatButtonToggle, MatButton, NgIf, ExpandBarHeaderComponent, MatTable, MatSort, MatColumnDef, MatHeaderCellDef, MatHeaderCell, MatSortHeader, MatCellDef, MatCell, MatHeaderRowDef, MatHeaderRow, MatRowDef, MatRow, MatNoDataRow, MatPaginator, FaIconComponent, MaterialSelectionComponent, NgFor, OperationCollectionComponent, CloseToursComponent, MatSelect, MatOptgroup, MatOption, CommonModule, MatFormFieldModule, MatButtonModule, MatSelectModule, FontAwesomeModule, MatButtonToggleModule]
})
export class TourplannerMenuComponent implements OnInit, OnChanges {

  displayedOperationColumns: string[] = ['id', 'address_company', 'project_name', 'city', 'service'];
  displayedTechnicianColumns: string[] = ['full_name', 'company', 'workload'];
  operationDataSource: MatTableDataSource<PAOperation>
  technicianDataSource: MatTableDataSource<PATechnician>

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  faWindowClose = faWindowClose
  faPhone = faPhone
  faTrash = faTrash
  faCheck = faCheck

  @Input() planningMode: 'operation' | 'technician' = 'operation'
  @Input() technicians: PATechnician[]
  @Input() operations: PAOperation[]
  @Input() shownCalendarWeeksData: CalendarWeekData[]
  @Input() selectedCalendarWeekData: CalendarWeekData
  @Input() selectedCalendarDay: string
  @Input() filteredWeekdays: WeekDay[] = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday']
  @Input() operationTimeFilter: 'planned' | 'average'
  @Input() filteredProjects: PAProject[] = []

  filteredTechnicians: PATechnician[] = []
  shownTechnicians: PATechnician[] = []
  shownInstances: Instance[] = []
  filteredInstances: Instance[] = []

  insertOperations: PAOperation[] = []

  showInstanceFilter = true
  showTechnicianFilter = true
  showTechnicianInstances = true
  showData = false
  showInstanceSelection = true
  showTechnicianSelection = true
  showExtraOperationCollection = true
  showExtraTechnicianTours = true
  @Input() extraOperationCollection: PAOperation[] = []
  @Input() planningInstructions: PAPlanningInstructions

  updating: boolean = false

  @Input() selectedOperationID: number
  @Input() selectedTechnicianID: number
  @Input() showTechnicianDatesForTechnicians: PATechnician[]
  @Input() showOverviewForTechnicianDateCollections: TechnicianDateCollection[]
  @Input() externalWindow: PopoutWindowComponent
  @Input() projectMode: 'service' | 'free'

  @Output() insertOperationInTourEvent = new EventEmitter<PAOperation>()
  @Output() updatePreferredTechniciansEvent = new EventEmitter<PATechnician[]>()

  Project = PAProject
  Util = PAUtil
  Operation = PAOperation
  Store = PAStore

  selectedDeadlineTypeSelection: string
  selectedDeadlineDateSelection: string
  selectedTechnicianOwnerFilter: string;
  selectedMaterialFilter: string;
  selectedTechnicianProjectFilter: string;
  selectedInstanceTypeFilter: string;
  selectedInstanceMaterialFilter: string;
  selectedPriorityIds: number[] = []
  selectedStatusIds: number[] = []

  shownTechnicianDates: {
    technician_id: number,
    technician_date_data: { technician_date: PATechnicianDate, plannable: boolean, reason?: string }[]
  }[] = []

  maxShownInstancesAmount = 25
  maxShownTechniciansAmount = 10

  instanceSearch: string = ''
  operationCollectionOperations: PAOperation[] = []

  constructor(
    private _liveAnnouncer: LiveAnnouncer
  ) {
  }

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    let technician_changes = changes['technicians']
    let operation_changes = changes['operations']
    let ticket_changes = changes['tickets']

    let technicians_changed = technician_changes && !PAUtil.equalSets(new Set(technician_changes.previousValue), new Set(technician_changes.currentValue))
    let operations_changed = operation_changes && !PAUtil.equalSets(new Set(operation_changes.previousValue), new Set(operation_changes.currentValue))
    let tickets_changed = ticket_changes && !PAUtil.equalSets(new Set(ticket_changes.previousValue), new Set(ticket_changes.currentValue))

    let shownCalendarWeeksDataChange = changes['shownCalendarWeeksData']
    let selectedCalendarWeekDataChange = changes['selectedCalendarWeekData']
    let selectedCalendarDayChange = changes['selectedCalendarDay']
    let operation_time_filter_changes = changes['operationTimeFilter']

    let selectedTicketChanges = changes['selectedTicketID']
    let selectedOperationChanges = changes['selectedOperationID']
    let selectedTechnicianChanges = changes['selectedTechnicianID']

    let show_technician_dates_for_ticket_with_id_changes = changes['showTechnicianDatesForTicketWithID']
    let show_technician_dates_for_operation_with_id_changes = changes['showTechnicianDatesForOperationWithID']

    let filtered_weekday_changes = changes['filteredWeekdays']
    let filtered_project_changes = changes['filteredProjects']

    let extra_operation_collection_changes = changes['extraOperationCollection']
    let extra_operation_collection_changed = extra_operation_collection_changes && !PAUtil.equalSets(new Set(extra_operation_collection_changes.previousValue), new Set(extra_operation_collection_changes.currentValue))

    let result_tour_changes = changes['resultTour']
    let result_tour_changed = result_tour_changes && result_tour_changes.previousValue != result_tour_changes.currentValue

    let project_mode_changes = changes['projectMode']

    let update = false
    let update_map_bounds = false

    if (tickets_changed || technicians_changed || operations_changed) {
      if (operations_changed) {
        if (operation_changes.currentValue && this.selectedOperationID && !operation_changes.currentValue.includes(PADataControl.Instance.getOperation(this.selectedOperationID)) && this.planningMode == 'operation') {
          this.showOverviewForTechnicianDateCollections = PATourPlannerControl.Instance.tourPlannerOverviewTechnicianDateCollections = []
          this.shownTechnicianDates = []
        }
        update = true
      }
      if (technicians_changed) {
        if (technician_changes.currentValue) {
          this.shownTechnicians = this.shownTechnicians.filter(shown_technician => technician_changes.currentValue.includes(shown_technician))
        }
        update = true
      }
    }

    if (shownCalendarWeeksDataChange || selectedCalendarWeekDataChange || selectedCalendarDayChange
      || show_technician_dates_for_ticket_with_id_changes || show_technician_dates_for_operation_with_id_changes || filtered_weekday_changes
      || result_tour_changed) {
      update = true
      update_map_bounds = true
    }

    if (operation_time_filter_changes || extra_operation_collection_changed || project_mode_changes) {
      update = true
    }

    if (filtered_project_changes) {
      let new_projects = filtered_project_changes.previousValue && filtered_project_changes.currentValue ? filtered_project_changes.currentValue.filter(p => !filtered_project_changes.previousValue.includes(p)) : filtered_project_changes.currentValue
      this.updateSelectedPriorityIds(new_projects)
      this.updateSelectedStatusIds(new_projects)
    }

    if (update) {
      await this.update()
    }

    if (update_map_bounds) {
      this.updateMapBounds()
    }

    if (selectedOperationChanges || selectedTicketChanges || selectedTechnicianChanges) {
      if (selectedOperationChanges && selectedOperationChanges.currentValue && this.planningMode == 'operation') {
        this.changeSelectedOperation(selectedOperationChanges.currentValue)
      }
      if (selectedTechnicianChanges && selectedTechnicianChanges.currentValue && this.planningMode == 'technician') {
        this.changeSelectedTechnician(selectedTechnicianChanges.currentValue)
      }
    }
  }

  public updateOperationDataSource() {
    this.operationDataSource = new MatTableDataSource<PAOperation>(this.filterOperationsForSearchString());
    this.operationDataSource.paginator = this.paginator;
    this.operationDataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'service':
          return item.ticket.isServiceTicket() ? 0 : 1;
        case 'address_company':
          return item.ticket.address_company;
        case 'project_name':
          return item.ticket.project.project_name;
        case 'city':
          return item.ticket.address_city;
        case 'id':
          return item.ticket.id;
        default:
          return item[property];
      }
    };
    this.operationDataSource.sort = this.sort;
  }

  public updateTechnicianDataSource() {
    let current_cwd: CalendarWeekData
    if (this.shownCalendarWeeksData) {
      if (this.selectedCalendarWeekData) {
        current_cwd = this.selectedCalendarWeekData
      } else {
        current_cwd = this.shownCalendarWeeksData[0]
      }
    }
    this.technicianDataSource = new MatTableDataSource<PATechnician>(this.filterTechniciansForSearchString());
    this.technicianDataSource.paginator = this.paginator;
    this.technicianDataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'workload':
          return current_cwd ? item.calendarWeekWorkloadInPercent(current_cwd.calendar_week) : 0
        case 'full_name':
          return item.getFullName()
        case 'company':
          return item.isBentomaxTechnician() ? 'Bentomax' : 'Extern'
        default:
          return item[property];
      }
    };
    this.technicianDataSource.sort = this.sort;
  }

  public filterOperationsForSearchString(): PAOperation[] {
    return this.operations.filter(operation =>
      operation.id.toString().toLowerCase().includes(this.instanceSearch.toLowerCase()) ||
      operation.ticket.id.toString().toLowerCase().includes(this.instanceSearch.toLowerCase()) ||
      (operation.ticket.address() || '').toLowerCase().includes(this.instanceSearch.toLowerCase()) ||
      (operation.ticket.project.project_name || '').toLowerCase().includes(this.instanceSearch.toLowerCase()) ||
      (operation.ticket.address_company || '').toLowerCase().includes(this.instanceSearch.toLowerCase())||
      (operation.ticket.description || '').toLowerCase().includes(this.instanceSearch.toLowerCase())
    )
  }

  public filterTechniciansForSearchString(): PATechnician[] {
    return this.technicians.filter(technician =>
      technician.id.toString().toLowerCase().includes(this.instanceSearch.toLowerCase()) ||
      technician.getFullName().toLowerCase().includes(this.instanceSearch.toLowerCase()) ||
      (technician.isBentomaxTechnician() ? 'Bentomax' : 'Extern').toLowerCase().includes(this.instanceSearch.toLowerCase()))
  }

  ngOnInit(): void {
    PATourPlannerControl.Instance.tourPlannerOverviewTechnicianDateCollections = this.showOverviewForTechnicianDateCollections = []
    this.resetTechnicianFilter()
    this.resetOperationFilter()

    this.updateOperationDataSource()
    this.updateTechnicianDataSource()
  }

  resetTechnicianFilter(): void {
    this.maxShownTechniciansAmount = 10
    this.selectedTechnicianOwnerFilter = 'all'
    this.selectedMaterialFilter = 'off'
    this.selectedTechnicianProjectFilter = 'ticket_project'
  }

  resetOperationFilter(): void {
    this.selectedInstanceTypeFilter = 'Offene Aufträge'
    this.selectedInstanceMaterialFilter = 'true'
    let timestamp_now = (new Date()).getTime()
    let timestamp_in_three_weeks = timestamp_now + 3 * 7 * 24 * 60 * 60 * 1000
    this.selectedDeadlineDateSelection = PATimeControl.Instance.formatDate(new Date(timestamp_in_three_weeks), false)
    this.selectedDeadlineTypeSelection = 'off'
    this.updateSelectedPriorityIds()
    this.updateSelectedStatusIds()
  }

  updateSelectedPriorityIds(add_projects?: PAProject[]): void {
    add_projects = add_projects || PADataControl.Instance.loadedProjects
    this.selectedPriorityIds = [...new Set(this.selectedPriorityIds.concat(add_projects.reduce((prio_ids: number[], project) => prio_ids.concat(project.priorities.map(p => p.id)), [])))]
  }

  updateSelectedStatusIds(add_projects?: PAProject[]): void {
    add_projects = add_projects || PADataControl.Instance.loadedProjects
    this.selectedStatusIds = [...new Set(this.selectedStatusIds.concat(add_projects.reduce((status_ids: number[], project) => status_ids.concat(project.status.map(s => s.id)), [])))]
  }

  async updatePlanningMode(event: Event): Promise<void> {
    const input = event.target as HTMLInputElement
    const value: 'operation' | 'technician' = input.value as 'operation' | 'technician'

    this.planningMode = PATourPlannerControl.Instance.tourPlannerPlanningMode = value
    this.showOverviewForTechnicianDateCollections = PATourPlannerControl.Instance.tourPlannerOverviewTechnicianDateCollections = []
    if (value == 'operation') {
      this.deselectOperation()
    }
    if (value == 'technician') {
      this.deselectTechnician()
    }
    await this.update()
  }

  async update(): Promise<void> {
    this.updating = true
    if (this.planningMode == 'operation') {
      if (this.selectedOperationID) {
        await this.updateFilteredTechnicians()
      } else {
        PATourPlannerControl.Instance.shownMapOperations = [...this.filterOperationsForSearchString()].filter(o => PADataControl.Instance.unassignedOperationUserIds.includes(o.user_ids[0]))
        PATourPlannerControl.Instance.shownMapTechnicians = []
        this.operations.map(operation => operation.getStore().color = null)
        PATourPlannerControl.Instance.shownRoutes = []
        PATourPlannerControl.Instance.tourPlannerOverviewTechnicianDateCollections = this.showOverviewForTechnicianDateCollections = []
        PAMapControl.Instance.selectedIsoCoordinates = null
        this.showInstanceSelection = true
        this.insertOperations = PATourPlannerControl.Instance.tourPlannerInsertOperations = []
      }
      await PAUtil.sleep(100)
      this.updateOperationDataSource()
    }
    if (this.planningMode == 'technician') {
      if (this.selectedTechnicianID) {
        this.checkInstances()
        await this.updateFilteredOperationsAndTickets()
      } else {
        PATourPlannerControl.Instance.shownMapOperations = []
        PATourPlannerControl.Instance.shownMapTechnicians = [...this.filterTechniciansForSearchString()]
        this.technicians.map(technician => technician.color = null)
        PATourPlannerControl.Instance.shownRoutes = []
        PATourPlannerControl.Instance.tourPlannerOverviewTechnicianDateCollections = this.showOverviewForTechnicianDateCollections = []
        PAMapControl.Instance.selectedIsoCoordinates = null
        this.showInstanceSelection = true
        this.insertOperations = PATourPlannerControl.Instance.tourPlannerInsertOperations = []
      }
      await PAUtil.sleep(100)
      this.updateTechnicianDataSource()
    }
    this.updating = false
  }

  resetShownData(): void {
    this.showInstanceFilter = true
    this.showTechnicianFilter = true
    this.showTechnicianInstances = true
    this.showData = false
    this.showInstanceSelection = true
    this.showTechnicianSelection = !this.selectedTechnicianID
    this.showExtraOperationCollection = true
  }

  checkInstances(): void {
    for (let extra_operation of this.extraOperationCollection) {
      if (!this.operations.includes(extra_operation)) {
        PATourPlannerControl.Instance.tourPlannerExtraOperationCollection = this.extraOperationCollection = this.extraOperationCollection.filter(operation => operation != extra_operation)
      }
    }
  }

  async deselect() {
    switch (this.planningMode) {
      case 'operation':
        this.deselectOperation();
        break;
      case 'technician':
        this.deselectTechnician();
        break;
      default: {
      }
    }
    await this.update()
  }

  deselectOperation(): void {
    this.selectedOperationID = PATourPlannerControl.Instance.operationToPlanID = null
    this.showTechnicianDatesForTechnicians = []
    this.updatePreferredTechniciansEvent.emit([])
    PATourPlannerControl.Instance.tourPlannerExtraOperationCollection = this.extraOperationCollection = []
    this.instanceSearch = ''
    this.resetShownData()
  }

  deselectTechnician(): void {
    this.selectedTechnicianID = PATourPlannerControl.Instance.technicianToPlanID = null
    PATourPlannerControl.Instance.tourPlannerExtraOperationCollection = this.extraOperationCollection = []
    this.showTechnicianDatesForTechnicians = []
    this.updatePreferredTechniciansEvent.emit([])
    this.instanceSearch = ''
    this.resetShownData()
  }

  getSelectedProjects(): PAProject[] {
    return PAProject.selectedProjects
  }

  async updateFilteredTechnicians(): Promise<void> {

    let reference_operation: PAOperation
    let reference_location: PALocation
    let reference_priority_id: number
    let shown_operations: PAOperation[] = []
    let filtered_technicians: PATechnician[] = []
    let shown_technicians: PATechnician[] = []

    if (this.planningMode == 'operation' && this.selectedOperationID) {
      reference_operation = PADataControl.Instance.getOperation(this.selectedOperationID)
      reference_location = reference_operation.ticket.client.location
      reference_priority_id = reference_operation.ticket.priority.id
      shown_operations = [reference_operation]
      filtered_technicians = await this.filterTechnicians(this.technicians)
      shown_technicians = this.getOrderedShownTechniciansForLocation(filtered_technicians, reference_location)
      shown_technicians.map(technician => technician.color = PAUtil.getIndexColorRGBA(shown_technicians.indexOf(technician)))
      PATourPlannerControl.Instance.shownMapTechnicians = [...shown_technicians]

      if (!PATourPlannerControl.Instance.shownMapOperations.includes(reference_operation)) {
        PATourPlannerControl.Instance.shownMapOperations = PATourPlannerControl.Instance.shownMapOperations.concat([reference_operation])
        this.updateOperationCollectionOperations()
        this.updateMapBounds()
      }
    }

    PAMapControl.Instance.selectedIsoCoordinates = reference_location.coordinates

    let promises: Promise<void>[] = []
    for (let technician of shown_technicians) {
      promises.push(technician.loadArticles())
    }
    await Promise.all(promises)

    if (PATourPlannerControl.Instance.technicianDistanceType == 'street_distance') shown_technicians = await this.sortTechniciansByDistanceToLocation(shown_technicians, reference_location)

    shown_technicians.map(technician => technician.location.getDistanceToLocation(reference_location))
    this.shownTechnicians = shown_technicians
    PATourPlannerControl.Instance.shownMapTechnicians = [...shown_technicians]

    PATourPlannerControl.Instance.shownRoutes = this.showTechnicianDatesForTechnicians.length ?
      this.showTechnicianDatesForTechnicians.map(technician =>
        PADataControl.Instance.getTechnician(technician.id).getAirDistanceRouteToLocation(
          reference_location,
          PADataControl.Instance.getTechnician(technician.id).getColorString()
        )
      ) :
      shown_technicians.map(technician =>
        technician.getAirDistanceRouteToLocation(reference_location, technician.getColorString())
      )

    this.showTechnicianDatesForTechnicians = this.showTechnicianDatesForTechnicians.filter(technician => shown_technicians.includes(technician))

    let shownTechnicianIds = this.shownTechnicianIds()
    let technician_operations = await this.getTechnicianOperationsForSelectedTimePeriod(shownTechnicianIds, true)
    let extra_operations = PAFilterControl.Instance.filteredUnplannedOperations.filter(o => PADataControl.Instance.unassignedOperationUserIds.includes(o.user_ids[0]))
    let selected_extra_operations = extra_operations.filter(extra_operation => this.extraOperationCollection.includes(extra_operation))
    let selected_extra_stores = [...new Set(selected_extra_operations.map(op => op.getStore()))]
    let unselected_extra_operations = this.projectMode == 'free' ? extra_operations.filter(extra_operation => !this.extraOperationCollection.includes(extra_operation)) : []
    let distance_filtered_unselected_extra_operations = unselected_extra_operations.filter(op => selected_extra_stores.concat(reference_operation.getStore()).find(st => op.ticket.location.getDistanceToCoordinatesAsTheCrowFlies(st.location.coordinates.latitude, st.location.coordinates.longitude) <= PATourPlannerControl.Instance.selectedDistanceFilter))
    let colored_operations = shown_operations.concat(technician_operations).concat(selected_extra_operations);

    [...new Set(unselected_extra_operations.map(operation => operation.getStore()))].map(store => {
      let store_color = store.getColor()
      store.color = {r: store_color.r, g: store_color.g, b: store_color.b, a: .5};
    });

    colored_operations.map(operation => {
      let store = operation.getStore()
      let technician = PADataControl.Instance.getTechnician(operation.user_ids[0])
      store.color = PAUtil.getIndexColorRGBA(this.shownTechnicians.indexOf(technician))
    })

    shown_operations = colored_operations.concat(distance_filtered_unselected_extra_operations)

    this.insertOperations = PATourPlannerControl.Instance.tourPlannerInsertOperations = [reference_operation].concat(selected_extra_operations)

    this.filteredTechnicians = filtered_technicians
    PATourPlannerControl.Instance.shownMapOperations = shown_operations

    let priorities_to_load = [...new Set([reference_priority_id].concat(selected_extra_operations.map(operation => operation.ticket.priority.id)))]

    shown_technicians.map(technician => priorities_to_load.map(priority_id => !technician.operationTimeEntryForPriorityWasLoaded(priority_id) ? technician.loadPriorityBasedOperationTimes(priority_id) : {}))

    if (this.planningMode == 'operation' && this.selectedOperationID) {
      this.showTechnicianDatesForTechniciansWithOperationDeadline(this.showTechnicianDatesForTechnicians, reference_operation.id)
    }

    this.updateOperationCollectionOperations()
    this.showOverviewForTechnicianDateCollections = PATourPlannerControl.Instance.tourPlannerOverviewTechnicianDateCollections = this.showOverviewForTechnicianDateCollections.filter(tdc => this.showTechnicianDatesForTechnicians.includes(tdc.technician))
  }

  public shownTechnicianIds(): number[] {
    return (this.showTechnicianDatesForTechnicians.length ? this.showTechnicianDatesForTechnicians : this.shownTechnicians).map(technician => technician.id);
  }

  async clickTechnicianWithOperationDeadline(uid: number, operation_id: number, skip_remove?: boolean): Promise<void> {
    let show_technician_dates_for_technicians = this.clickTechnicianWithId(uid, skip_remove);
    this.showTechnicianDatesForTechniciansWithOperationDeadline(show_technician_dates_for_technicians, operation_id)
    await this.update()
  }

  private clickTechnicianWithId(uid: number, skip_remove?: boolean) {
    let show_technician_dates_for_technicians = [...this.showTechnicianDatesForTechnicians];
    let technician = PADataControl.Instance.getTechnician(uid);
    if (show_technician_dates_for_technicians.includes(technician)) {
      if (!skip_remove) {
        PAUtil.removeElementFromList(show_technician_dates_for_technicians, technician);
      }
    } else {
      show_technician_dates_for_technicians.push(technician);
    }
    this.showData = this.showInstanceSelection = false
    this.updatePreferredTechniciansEvent.emit([...show_technician_dates_for_technicians]);
    return show_technician_dates_for_technicians;
  }

  async updateFilteredOperationsAndTickets(update_map_bounds?: boolean): Promise<void> {

    let reference_location: PALocation
    let shown_operations: PAOperation[] = []
    let shown_technicians: PATechnician[] = []

    if (this.planningMode == 'technician' && this.selectedTechnicianID) {
      this.updateShowTechnicianDatesForTechnicians([PADataControl.Instance.getTechnician(this.selectedTechnicianID)])
      let reference_technician = PADataControl.Instance.getTechnician(this.selectedTechnicianID)
      reference_location = reference_technician.location

      await reference_technician.loadArticles()

      if (PATourPlannerControl.Instance.tourPlannerOverviewTechnicianDateCollections.length) {
        shown_operations = this.filterOperationsForTechnicianLocation([...this.operations])
      }

      shown_technicians = [reference_technician]

      let technician_operations = await this.getTechnicianOperationsForSelectedTimePeriod([reference_technician.id])
      this.filteredInstances = shown_operations.map(operation => this.operationToInstance(operation, reference_location))
      this.shownInstances = this.filteredInstances.sort((instance_a, instance_b) => (
          instance_a.distance <
          instance_b.distance
        ) ? -1 : 1
      ).slice(0, this.maxShownInstancesAmount)
      shown_operations.map(operation => {
        let store = operation.getStore()
        let store_color = store.getColor()
        store.color = {r: store_color.r, g: store_color.g, b: store_color.b, a: .5}
      })
      technician_operations.map(operation => {
        let store = operation.getStore()
        store.color = null
      })

      let priorities_to_load = this.extraOperationCollection.map(operation => operation.ticket.priority.id)
      shown_technicians.map(technician => priorities_to_load.map(priority_id => !technician.operationTimeEntryForPriorityWasLoaded(priority_id) ? technician.loadPriorityBasedOperationTimes(priority_id) : {}))

      if (PATourPlannerControl.Instance.technicianDistanceType == 'street_distance') await this.sortShownInstancesByDistanceToLocation(reference_location)
      shown_operations = shown_operations.concat(technician_operations)

      this.extraOperationCollection.map(operation => {
        let store = operation.getStore()
        store.color = null
      })

      this.insertOperations = PATourPlannerControl.Instance.tourPlannerInsertOperations = [...this.extraOperationCollection]
      PATourPlannerControl.Instance.shownRoutes = this.extraOperationCollection.map(operation => operation.ticket.client.location.getAirDistanceRouteToLocation(reference_location, PADataControl.Instance.getProject(operation.ticket.project.id).color))
      this.updateOperationCollectionOperations()
    }

    PATourPlannerControl.Instance.shownMapTechnicians = this.shownTechnicians = shown_technicians
    PATourPlannerControl.Instance.shownMapOperations = shown_operations

    this.showOverviewForTechnicianDateCollections = PATourPlannerControl.Instance.tourPlannerOverviewTechnicianDateCollections = this.showOverviewForTechnicianDateCollections.filter(tdc => this.showTechnicianDatesForTechnicians.includes(tdc.technician))

    if (update_map_bounds) this.updateMapBounds()
  }

  async sortTechniciansByDistanceToLocation(technicians: PATechnician[], to_location: PALocation): Promise<PATechnician[]> {

    let distance_promises: Promise<Route>[] = []
    for (let technician of technicians) {
      distance_promises.push(technician.location.getDistanceToLocation(to_location))
    }
    for (let distance_promise of distance_promises) {
      await distance_promise
    }
    technicians = technicians.sort(technicianDistanceSortFunction)

    function technicianDistanceSortFunction(technician_a: PATechnician, technician_b: PATechnician) {
      let distance_a = technician_a.location.getSyncStreetDistanceToLocation(to_location)
      let distance_b = technician_b.location.getSyncStreetDistanceToLocation(to_location)
      if (distance_a == distance_b) return -1
      if (distance_a == -1) return 1
      if (distance_a < distance_b) return -1
    }

    return technicians
  }

  async sortShownInstancesByDistanceToLocation(to_location: PALocation): Promise<void> {

    let distance_promises: Promise<Route>[] = []
    for (let instance of this.shownInstances) {
      distance_promises.push(instance.location.getDistanceToLocation(to_location))
    }
    for (let distance_promise of distance_promises) {
      await distance_promise
    }
    this.shownInstances = this.shownInstances.sort(instanceDistanceSortFunction)

    function instanceDistanceSortFunction(instance_a: Instance, instance_b: Instance) {
      let distance_a = instance_a.location.getSyncStreetDistanceToLocation(to_location)
      let distance_b = instance_b.location.getSyncStreetDistanceToLocation(to_location)
      if (distance_a == distance_b) return -1
      if (distance_a == -1) return 1
      if (distance_a < distance_b) return -1
    }

  }

  async filterTechnicians(technicians: PATechnician[]): Promise<PATechnician[]> {
    // Filter out pseudo technicians
    technicians = PAFilter.filterOutPseudoTechnicians(technicians)

    // Technician Owner Filter
    if (this.selectedTechnicianOwnerFilter == 'bentomax') {
      technicians = PAFilter.filterTechniciansForBentomaxTechnicians(technicians)
    } else if (this.selectedTechnicianOwnerFilter == 'external') {
      technicians = PAFilter.filterTechniciansForExternalTechnicians(technicians)
    }

    let operation: PAOperation
    let ticket: PATicket
    let project: PAProject
    let priority_id: number
    let location: PALocation

    if (this.selectedOperationID && this.planningMode == 'operation') {
      operation = PADataControl.Instance.getOperation(this.selectedOperationID)
      ticket = operation?.ticket
      project = operation ? PADataControl.Instance.getProject(operation.ticket.project.id) : null
      priority_id = operation.ticket.priority.id
      location = operation.ticket.client?.location
    }

    if (project) {
      if (this.selectedTechnicianProjectFilter == 'ticket_project') {
        let project_ids = [...new Set(this.extraOperationCollection.map(op => op.ticket.project.id).concat([ticket.project.id]))]
        if (project_ids.length) {
          technicians = PAFilter.filterTechniciansForProjectsInclusiveTechnicians(technicians, project_ids)
        }
      } else if (this.selectedTechnicianProjectFilter == 'selected_project' && PAProject.selectedProjects.length) {
        technicians = PAFilter.filterTechniciansForProjectsInclusiveTechnicians(technicians, PAProject.selectedProjects.map(project => project.id))
      }
    }

    if (location && PATourPlannerControl.Instance.selectedDistanceFilter > 0) {
      technicians = PAFilter.filterTechniciansForDistanceToLocation(technicians, ticket.location, PATourPlannerControl.Instance.selectedDistanceFilter)
    }

    if (ticket && this.selectedMaterialFilter == 'true' && (ticket.materials?.length || ticket.afterStorageTaskMaterialContainer?.materials.length)) {

      let promises: Promise<void>[] = []
      for (let technician of technicians) {
        promises.push(technician.loadArticles())
      }
      await Promise.all(promises)

      let material_tickets = [...new Set([ticket].concat(this.extraOperationCollection.map(op => op.ticket)))].filter(ti => ti.getCurrentMaterials()?.length)

      // Ticket Material Filter
      if (material_tickets.length) {
        technicians = PAFilter.filterTechniciansForTicketsMaterial(technicians, material_tickets)
      }
    }

    if (priority_id && project?.id) {
      technicians.map(technician => technician.updateTechnicianExperience(project.id, priority_id))
    }
    return technicians
  }

  async filterTicketsForTechnicianLocation(tickets: PATicket[]): Promise<PATicket[]> {

    if (this.selectedTechnicianID) {
      let technician = PADataControl.Instance.getTechnician(this.selectedTechnicianID)
      let technician_projects = technician.getLoadedActiveProjects(true)
      tickets = PAFilter.filterTicketsForProjectsInclusiveTickets(tickets, technician_projects.map(project => project.id))
      if (this.selectedPriorityIds.length) {
        tickets = PAFilter.filterTicketsForPriorities(tickets, this.selectedPriorityIds)
      }
      if (this.selectedStatusIds.length) {
        tickets = PAFilter.filterTicketsForStatuses(tickets, this.selectedStatusIds)
      }
      if (PATourPlannerControl.Instance.selectedDistanceFilter > 0) {
        tickets = PAFilter.filterTicketsForDistanceToLocation(tickets, technician.location, PATourPlannerControl.Instance.selectedDistanceFilter)
      }
      if (this.selectedInstanceMaterialFilter == 'true') {
        tickets = tickets.filter(ticket => technician.hasTicketMaterial(ticket))
      }
      if (this.selectedDeadlineTypeSelection == 'sla_or_appointment') {
        tickets = PAFilter.filterTicketsForSLAOrAppointmentBeforeDeadline(tickets, (new Date(this.selectedDeadlineDateSelection).getTime()))
      }
      if (this.selectedDeadlineTypeSelection == 'sla') {
        tickets = PAFilter.filterTicketsForSLABeforeDeadline(tickets, (new Date(this.selectedDeadlineDateSelection).getTime()))
      }
      if (this.selectedDeadlineTypeSelection == 'appointment') {
        tickets = PAFilter.filterTicketsForAppointmentBeforeDeadline(tickets, (new Date(this.selectedDeadlineDateSelection).getTime()))
      }
      tickets.map(ticket => technician.updateTechnicianExperience(ticket.project.id, ticket.priority.id))
      if (this.selectedMaterialFilter == 'true') {
        tickets = PAFilter.filterTicketsForTechnicianArticles(tickets, technician)
      }
    }
    return tickets
  }

  filterOperationsForTechnicianLocation(operations: PAOperation[]): PAOperation[] {

    if (this.selectedTechnicianID) {
      let technician = PADataControl.Instance.getTechnician(this.selectedTechnicianID)
      let technician_projects = technician.getLoadedActiveProjects(true)
      operations = PAFilter.filterOperationsForProjectsInclusiveOperations(operations, technician_projects.map(project => project.id))
      if (this.selectedPriorityIds.length) {
        operations = PAFilter.filterOperationsForPriorities(operations, this.selectedPriorityIds)
      }
      if (this.selectedStatusIds.length) {
        operations = PAFilter.filterOperationsForStatuses(operations, this.selectedStatusIds)
      }
      /* if (this.selectedDistanceFilter > 0) {
        operations = PAFilter.filterOperationsForDistanceToLocation(operations, technician.location, this.selectedDistanceFilter)
      } */
      if (this.selectedInstanceMaterialFilter == 'true') {
        operations = operations.filter(operation => technician.hasOperationMaterial(operation))
      }
      if (this.selectedDeadlineTypeSelection == 'sla_or_appointment') {
        operations = PAFilter.filterOperationsForSLAOrAppointmentBeforeDeadline(operations, (new Date(this.selectedDeadlineDateSelection).getTime()))
      }
      if (this.selectedDeadlineTypeSelection == 'sla') {
        operations = PAFilter.filterOperationsForSLABeforeDeadline(operations, (new Date(this.selectedDeadlineDateSelection).getTime()))
      }
      if (this.selectedDeadlineTypeSelection == 'appointment') {
        operations = PAFilter.filterOperationsForAppointmentBeforeDeadline(operations, (new Date(this.selectedDeadlineDateSelection).getTime()))
      }
      operations.map(operation => technician.updateTechnicianExperience(operation.ticket.project.id, operation.ticket.priority.id))
      if (this.selectedMaterialFilter == 'true') {
        operations = PAFilter.filterOperationsForTechnicianArticles(operations, technician)
      }
      operations = operations.filter(o => PADataControl.Instance.unassignedOperationUserIds.includes(o.user_ids[0]))
    }
    return operations
  }

  getOrderedShownTechniciansForLocation(technicians: PATechnician[], location: PALocation) {
    return technicians.sort(
      (technician_1, technician_2) => (
        technician_1.getDistanceToLocationAsTheCrowFlies(location) <
        technician_2.getDistanceToLocationAsTheCrowFlies(location)
      ) ? -1 : 1
    ).slice(0, this.maxShownTechniciansAmount)
  }

  async getTechnicianOperationsForSelectedTimePeriod(technician_ids: number[], only_for_real_time_future?: boolean): Promise<PAOperation[]> {
    let operations: PAOperation[] = []
    let technicians = technician_ids.map(id => PADataControl.Instance.getTechnician(id))
    if (this.shownCalendarWeeksData) {
      if (this.selectedCalendarWeekData) {
        await PATechnician.initTechnicianDatesForCalendarWeek(technicians, this.selectedCalendarWeekData, PADataControl.Instance)
        let week_days = this.selectedCalendarWeekData.weekdays
        for (let week_day of [week_days.monday, week_days.tuesday, week_days.wednesday, week_days.thursday, week_days.friday, week_days.saturday, week_days.sunday]) {
          for (let technician of technicians) {
            let technician_date = technician.getTechnicianDate(week_day.timestamp, false)
            while (!technician_date || technician_date.loadingStatus == 'init') {
              await PAUtil.sleep(100)
              technician_date = technician.getTechnicianDate(week_day.timestamp, false)
            }
            operations = operations.concat(technician_date.tour.operations)
          }
        }
      } else {
        PATechnician.initTechnicianDatesForCalendarWeeksData(technicians, this.shownCalendarWeeksData, PADataControl.Instance)
        for (let week_day of this.filteredWeekdays) {
          for (let technician of technicians) {
            let technician_date = technician.getTechnicianDate(this.shownCalendarWeeksData[0].weekdays[week_day].timestamp, false)
            while (!technician_date || technician_date.loadingStatus == 'init') {
              await PAUtil.sleep(100)
              technician_date = technician.getTechnicianDate(this.shownCalendarWeeksData[0].weekdays[week_day].timestamp, false)
            }
            operations = operations.concat(technician_date.tour.operations)
          }
        }
      }
    } else {
      return []
    }

    if (only_for_real_time_future) {
      operations = operations.filter(op => op.getPlannedTimestamp() >= PATimeControl.Instance.getCurrentTimestamp())
    }

    return operations
  }

  operationToInstance(operation: PAOperation, reference_location: PALocation): Instance {
    let date_string = ''
    if (operation.ticket.appointment_date) {
      date_string = `Termin: ${PATimeControl.Instance.dateToDatestring(new Date(operation.ticket.appointment_date), false, false, '.')} ${PATimeControl.Instance.dateToTimestring(new Date(operation.ticket.appointment_date), false)}Uhr`
    } else if (operation.ticket.datesla) {
      date_string = `SLA: ${PATimeControl.Instance.dateToDatestring(new Date(operation.ticket.datesla), false, false, '.')} ${PATimeControl.Instance.dateToTimestring(new Date(operation.ticket.datesla), false)}`
    }
    return {
      distance: Math.round(operation.getDistanceToLocationAsTheCrowFlies(reference_location)),
      id: operation.id,
      name: 'Auftrag: ' + operation.ticket.address_company,
      type: 'operation',
      date_string: date_string,
      location: operation.ticket.client.location
    }
  }

  ticketToInstance(ticket: PATicket, reference_location: PALocation): Instance {
    let date_string = ''
    if (ticket.appointment_date) {
      date_string = `Termin: ${PATimeControl.Instance.dateToDatestring(new Date(ticket.appointment_date), false, false, '.')} ${PATimeControl.Instance.dateToTimestring(new Date(ticket.appointment_date), false)}Uhr`
    } else if (ticket.datesla) {
      date_string = `SLA: ${PATimeControl.Instance.dateToDatestring(new Date(ticket.datesla), false, false, '.')} ${PATimeControl.Instance.dateToTimestring(new Date(ticket.datesla), false)}`
    }
    return {
      distance: Math.round(ticket.getDistanceToLocationAsTheCrowFlies(reference_location)),
      id: ticket.id,
      name: 'Ticket: ' + ticket.address_company,
      type: 'ticket',
      date_string: date_string,
      location: ticket.location
    }
  }

  async changeSelectedOperation(id: number, update_map?: boolean) {
    this.selectedOperationID = PATourPlannerControl.Instance.operationToPlanID = id
    this.showInstanceSelection = false
    this.filterTechniciansAndUpdateMap(update_map)
  }

  async changeSelectedTechnician(id: string, update_map?: boolean) {
    this.selectedTechnicianID = PATourPlannerControl.Instance.technicianToPlanID = Number.parseInt(id)
    this.showTechnicianSelection = false
    this.filterInstancesAndUpdateMap(update_map)
  }

  async filterTechniciansAndUpdateMap(update_map?: boolean) {
    await this.updateFilteredTechnicians()
    if (update_map) this.updateMapBounds()
  }

  async filterInstancesAndUpdateMap(update_map?: boolean) {
    await this.updateFilteredOperationsAndTickets(update_map)
  }

  updateTechnicianOwnerFilter(event: Event): void {
    const input = event.target as HTMLInputElement
    this.selectedTechnicianOwnerFilter = input.value
    this.updateFilteredTechnicians()
  }

  updateTechnicianProjectFilter(event: Event): void {
    const input = event.target as HTMLInputElement
    this.selectedTechnicianProjectFilter = input.value
    this.updateFilteredTechnicians()
  }

  updateMaterialFilter(event: Event): void {
    const input = event.target as HTMLInputElement
    this.selectedMaterialFilter = input.value
    this.updateFilteredTechnicians()
  }

  updateInstanceMaterialFilter(event: Event): void {
    const input = event.target as HTMLInputElement
    this.selectedInstanceMaterialFilter = input.value
    this.updateFilteredOperationsAndTickets()
  }

  updateDeadlineTypeSelection(event: Event): void {
    const input = event.target as HTMLInputElement
    this.selectedDeadlineTypeSelection = input.value
    this.updateFilteredOperationsAndTickets()
  }

  increaseMaxShownTechniciansAmount(): void {
    this.maxShownTechniciansAmount = this.maxShownTechniciansAmount + 10
    this.updateFilteredTechnicians()
  }

  showTechnicianDatesForTechniciansWithOperationDeadline(technicians: PATechnician[], operation_id: number): void {
    let operation = PADataControl.Instance.getOperation(operation_id)
    let from_deadline_date = operation.ticket.appointment_date ? new Date(operation.ticket.appointment_date) : null
    let until_deadline_date = operation.ticket.appointment_date ? new Date(operation.ticket.appointment_date) : (operation.ticket.datesla ? new Date(operation.ticket.datesla) : null)
    if (from_deadline_date || until_deadline_date) {
      this.updateShowTechnicianDatesForTechnicians(technicians, {
        from_ts: from_deadline_date ? from_deadline_date.getTime() : null,
        until_ts: until_deadline_date ? until_deadline_date.getTime() : null
      })
    } else {
      this.updateShowTechnicianDatesForTechnicians(technicians)
    }
  }

  updateShowTechnicianDatesForTechnicians(technicians: PATechnician[], deadline?: {
    from_ts?: number,
    until_ts?: number
  }): void {

    this.shownTechnicianDates = []

    for (let technician of technicians) {
      let shown_technician_dates: PATechnicianDate[] = []
      if (this.shownCalendarWeeksData) {
        if (this.selectedCalendarWeekData) {
          for (let week_day of this.filteredWeekdays) {
            const calendar_week_day = this.selectedCalendarWeekData.weekdays[week_day]
            shown_technician_dates.push(technician.getTechnicianDate(calendar_week_day.timestamp, true))
          }
        } else {
          for (let week_day of this.filteredWeekdays) {
            const calendar_week_day = this.shownCalendarWeeksData[0].weekdays[week_day]
            shown_technician_dates.push(technician.getTechnicianDate(calendar_week_day.timestamp, true))
          }
        }
      }
      this.shownTechnicianDates.push({
        technician_id: technician.id,
        technician_date_data: shown_technician_dates.map(td => td.plannableHash(deadline))
      })
    }

    this.showTechnicianDatesForTechnicians = [...technicians]
    this.updatePreferredTechniciansEvent.emit([...technicians])
  }

  async clickShownTechnicianDates(technicians_dates: {technician_id: number, technician_dates: PATechnicianDate[], override_shown_data?: boolean}): Promise<void> {
    const possible_tdc = this.showOverviewForTechnicianDateCollections.find(tdc => tdc.technician.id == technicians_dates.technician_id)

    if (!possible_tdc) {
      const new_tdc = new TechnicianDateCollection(PADataControl.Instance.getTechnician(technicians_dates.technician_id))
      new_tdc.addTechnicianDatesForDayTimestamps(technicians_dates.technician_dates.map(td => td.day.utc_timestamp))
      this.showOverviewForTechnicianDateCollections = PATourPlannerControl.Instance.tourPlannerOverviewTechnicianDateCollections = this.showOverviewForTechnicianDateCollections.concat([
        new_tdc
      ])
    } else {
      if (technicians_dates.override_shown_data) {
        possible_tdc.clear()
      }

      if (technicians_dates.technician_dates.length == 1) {
        const technician_date = technicians_dates.technician_dates[0]
        if (possible_tdc.hasTechnicianDateWithTimestamp(technician_date.day.utc_timestamp)) {
          this.removeOverviewTechnicianDate(technician_date)
        } else {
          possible_tdc.addTechnicianDatesForDayTimestamps([technician_date.day.utc_timestamp])
        }
      } else if (technicians_dates.technician_dates.length >= 1) {
        possible_tdc.addTechnicianDatesForDayTimestamps(technicians_dates.technician_dates.map(td => td.day.utc_timestamp))
      }
    }

    this.showData = false
    this.showInstanceSelection = false
    this.showInstanceFilter = false
    this.showTechnicianSelection = false
    await this.update()
  }

  updateMapBounds(): void {

    let bounds = PAMapboxControl.Instance.getMapLngLatBounds()
    if (!bounds.isEmpty()) {
      PAMapControl.Instance.mainMapContainer.map.fitBounds(
        bounds,
        {
          padding: {top: 100, bottom: 100, left: 100, right: 100},
          speed: 1,
          maxZoom: 13,
          minZoom: PAMapControl.Instance.mainMapContainer.map.getZoom()
        }
      )
    }
  }

  async clickOperation(id: number): Promise<void> {
    this.toggleExtraOperation(PADataControl.Instance.getOperation(id))
  }

  getShownCalendarWeekData(): CalendarWeekData {
    if (this.selectedCalendarWeekData) {
      return this.selectedCalendarWeekData
    } else {
      return this.shownCalendarWeeksData[0]
    }
  }

  removeOverviewTechnicianDate(technician_date: PATechnicianDate): void {
    const possible_tdc = this.showOverviewForTechnicianDateCollections.find(tdc => tdc.technician == technician_date.technician)
    if (possible_tdc.hasTechnicianDateWithTimestamp(technician_date.day.utc_timestamp)) {
      possible_tdc.removeTechnicianDateForDayTimestamp(technician_date.day.utc_timestamp)
      if (possible_tdc.isEmpty()) {
        this.showOverviewForTechnicianDateCollections = PATourPlannerControl.Instance.tourPlannerOverviewTechnicianDateCollections = this.showOverviewForTechnicianDateCollections.filter(tdc => tdc != possible_tdc)
      }
    }
  }

  public clickNextWeek(): void {
    if (this.shownCalendarWeeksData) {
      if (this.selectedCalendarWeekData) {
        PATimeControl.Instance.incrementSelectedCalendarWeek()
      } else {
        PATimeControl.Instance.incrementShownCalendarWeeks()
      }
    }
  }

  public clickPreviousWeek(): void {
    if (this.shownCalendarWeeksData) {
      if (this.selectedCalendarWeekData) {
        PATimeControl.Instance.decrementSelectedCalendarWeek()
      } else {
        PATimeControl.Instance.decrementShownCalendarWeeks()
      }
    }
  }

  announceSortChange(sortState: Sort) {
    // This example uses English messages. If your application supports
    // multiple language, you would internationalize these strings.
    // Furthermore, you can customize the message to add additional
    // details about the values being sorted.
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
  }

  toggleExtraOperation(operation: PAOperation): void {
    if (this.extraOperationCollection.includes(operation)) {
      this.deselectExtraOperation(operation)
    } else {
      this.selectExtraOperation(operation)
    }
  }

  deselectExtraOperation(selected_operation: PAOperation): void {
    if (this.selectedOperationID == selected_operation.id) {
      if (this.extraOperationCollection.length) {
        this.selectedOperationID = this.extraOperationCollection.splice(0, 1)[0].id
      } else {
        this.selectedOperationID = null
      }
    } else {
      this.extraOperationCollection = this.extraOperationCollection.filter(operation => operation != selected_operation)
    }
    PATourPlannerControl.Instance.tourPlannerExtraOperationCollection = this.extraOperationCollection
    PATourPlannerControl.Instance.operationToPlanID = this.selectedOperationID
    this.update()
  }

  selectExtraOperation(select_operation: PAOperation): void {
    PATourPlannerControl.Instance.tourPlannerExtraOperationCollection = this.extraOperationCollection = this.extraOperationCollection.concat([select_operation])
    this.update()
  }

  toggleTechnicianDistanceType(): void {
    if (PATourPlannerControl.Instance.technicianDistanceType == 'air_distance') {
      PATourPlannerControl.Instance.technicianDistanceType = 'street_distance'
    } else {
      PATourPlannerControl.Instance.technicianDistanceType = 'air_distance'
    }
    this.update()
  }

  async afterTechnicianDateSave(technician_date: PATechnicianDate): Promise<void> {

    this.removeOverviewTechnicianDate(technician_date)

    this.extraOperationCollection = this.extraOperationCollection.filter(op => PADataControl.Instance.getOperation(op.id).isUnassigned())
    if (this.selectedOperationID && !PADataControl.Instance.getOperation(this.selectedOperationID).isUnassigned()) {
      if (this.extraOperationCollection.length) {
        this.selectedOperationID = this.extraOperationCollection.splice(0, 1)[0].id
      } else {
        this.selectedOperationID = null
      }
    }
    PATourPlannerControl.Instance.operationToPlanID = this.selectedOperationID
    PATourPlannerControl.Instance.tourPlannerExtraOperationCollection = this.extraOperationCollection
  }

  updateOperationCollectionOperations(): void {
    this.operationCollectionOperations = PATourPlannerControl.Instance.getOperationInPlanningProcess() ? [PATourPlannerControl.Instance.getOperationInPlanningProcess()].concat(this.extraOperationCollection) : this.extraOperationCollection
  }

  onPageChange(event: PageEvent): void {
    let idx_start = event.pageSize * event.pageIndex
    let shown_technicians = this.technicianDataSource.data.slice(idx_start, idx_start + event.pageSize + 1)
    if (this.shownCalendarWeeksData) {
      if (this.selectedCalendarWeekData) {
        PATechnician.initTechnicianDatesForCalendarWeek(shown_technicians, this.selectedCalendarWeekData, PADataControl.Instance)
      } else {
        PATechnician.initTechnicianDatesForCalendarWeeksData(shown_technicians, this.shownCalendarWeeksData, PADataControl.Instance)
      }
    }
  }

  protected readonly window = window;
  protected readonly PASettingsControl = PASettingsControl;
  protected readonly PAFilterControl = PAFilterControl;
  protected readonly PAMapControl = PAMapControl;
  protected readonly PADataControl = PADataControl;
  protected readonly PATourPlannerControl = PATourPlannerControl;
}

export interface Instance {
  type: string,
  id: number,
  distance: number,
  name: string,
  date_string?: string,
  location: PALocation
}