import { CommonModule } from "@angular/common";

import { Component, OnInit } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { TicketService } from 'src/app/_services'
import { Device } from 'src/app/_models/device.model'
import { environment } from 'src/environments/environment'
import { LazyLoadImageModule } from "ng-lazyload-image";

@Component({
  imports: [CommonModule, LazyLoadImageModule],
    selector: 'hawk-ticket-review',
    templateUrl: './ticket-review.component.html',
    styleUrls: ['./ticket-review.component.scss'],
})
export class TicketReviewComponent implements OnInit {

  devices: Device[] = []
  defaultImage = 'https://via.placeholder.com/1500'

  constructor(
    private route: ActivatedRoute,
    private ticketService: TicketService,
  ) { }

  ngOnInit(): void {
    const id = this.route.snapshot.paramMap.get('id')

    this.ticketService.getTicketJobs(id).subscribe(
      (data) => {
        this.devices = data.devices
      },
      (err) => {
        console.error(err)
      },
    )
  }

  /**
   * apiImagePath
   */
  public apiImagePath(path: string) {
    return environment.apiURL + path.replace('/r/device_images/', 'device_images/')
  }

}