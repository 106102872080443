import { Component, inject, OnInit } from '@angular/core';
import { CustomerService, UserService } from "../../_services";
import { ActivatedRoute, Router, RouterLink } from "@angular/router";
import {
  faArrowLeft,
  faEdit,
  faIndustry,
  faKey,
  faPhone,
  faTrash,
  faUpload,
  faUser
} from "@fortawesome/free-solid-svg-icons";
import { faProductHunt } from "@fortawesome/free-brands-svg-icons";
import { AddressOutput, EditAddressComponent } from "../_shared/edit-address/edit-address.component";
import { AG_GRID_LOCALE_DE } from "../../_helpers/aggrid.locale.de";
import { ColDef, GridReadyEvent } from "ag-grid-community";
import {
  GridApi,
  RowClassRules,
  SideBarDef, SizeColumnsToContentStrategy,
  SizeColumnsToFitGridStrategy,
  SizeColumnsToFitProvidedWidthStrategy
} from "ag-grid-enterprise";
import {
  APCustomer,
  APCustomerProject,
  APCustomerUser,
  PermittedCustomerParams
} from "../../_models/customer.interface";
import { APStore } from "../../_models/store.interface";
import { MatOption } from "@angular/material/core";
import { MatFormField, MatLabel, MatSelect } from "@angular/material/select";
import { EditInputComponent } from "../_shared/edit-input/edit-input.component";
import { NgForOf, NgIf } from "@angular/common";
import { FaIconComponent } from "@fortawesome/angular-fontawesome";
import { MatButton } from "@angular/material/button";
import { NgxMapboxGLModule } from "ngx-mapbox-gl";
import { MatTab, MatTabChangeEvent, MatTabGroup } from "@angular/material/tabs";
import { AgGridAngular } from "ag-grid-angular";
import { MatTooltip } from "@angular/material/tooltip";
import { FormsModule } from "@angular/forms";
import { AgGridThemeService } from "../../_services/ag-grid-theme.service";
import { RecordNavigationComponent } from "../_shared/record-navigation/record-navigation.component";

@Component({
  selector: 'hawk-customer-show',
  templateUrl: './customer-show.component.html',
  standalone: true,
  imports: [
    MatOption,
    MatSelect,
    MatLabel,
    MatFormField,
    RecordNavigationComponent,
    EditInputComponent,
    NgIf,
    RouterLink,
    FaIconComponent,
    MatButton,
    NgxMapboxGLModule,
    MatTabGroup,
    MatTab,
    AgGridAngular,
    MatTooltip,
    EditAddressComponent,
    FormsModule,
    NgForOf
  ],
  styleUrls: ['./customer-show.component.scss', '../_shared/styles/common-styles.scss']
})
export class CustomerShowComponent implements OnInit {
  public hawkTheme = inject(AgGridThemeService).getTheme();
  autoSizeStrategy: SizeColumnsToFitGridStrategy | SizeColumnsToFitProvidedWidthStrategy | SizeColumnsToContentStrategy = {
    type: "fitGridWidth",
  }

  protected readonly faArrowLeft = faArrowLeft;
  protected readonly faPhone = faPhone;
  protected readonly faUser = faUser;
  protected readonly faProductHunt = faProductHunt;
  protected readonly faEdit = faEdit;
  protected readonly faTrash = faTrash;
  protected readonly faIndustry = faIndustry;
  protected readonly faKey = faKey;

  customer: APCustomer = null
  stores: APStore[] = []
  projects: APCustomerProject[] = []
  customerIsDeletable = false

  public editLetter = false
  public editName = false
  public editURL = false
  public editXMLID = false
  public editAddress = false

  public showModal?: 'address' | 'user_project_access' = null
  public userProjectAccessUser?: APCustomerUser = null
  public selectedDefaultUserProjectId: number = null

  constructor(
    private customerService: CustomerService,
    private userService: UserService,
    public route: ActivatedRoute,
    public router: Router
  ) {
  }

  ngOnInit(): void {
    const id = this.route.snapshot.paramMap.get('id')
    this.updateCustomer(id)
    this.updateStores(id)
    this.updateProjects(id)
    this.updateDeletable(id)
  }

  updateCustomer(id: string) {
    this.customerService.loadAPCustomer(id).subscribe(
      data => {
        this.customer = data;
      },
      error => {
        console.log(error)
      }
    )
  }

  updateStores(id: string){
    this.customerService.loadAPCustomerStores(id).subscribe(
      data => {
        this.stores = data;
      },
      error => {
        console.log(error)
      }
    )
  }

  updateProjects(id: string) {
    this.customerService.loadAPCustomerProjects(id).subscribe(
      data => {
        this.projects = data;
      },
      error => {
        console.log(error)
      }
    )
  }

  updateDeletable(id: string): void {
    this.customerService.customerIsDeletable(id).subscribe(
      data => {
        this.customerIsDeletable = data.deletable
      },
      err => {
        console.log(err)
      }
    )
  }

  deleteCustomer() : void {
    this.customerService.deleteCustomer(this.route.snapshot.paramMap.get('id')).subscribe(
      _ => {
        this.router.navigate(['/kunde'])
      },
      error => {
        console.log(error)
      }
    )
  }

  saveName(name: string): void {
    this.saveCustomerValues({name}).then(success => {
      if (success) this.editName = false
    })
  }

  saveLetter(letter: string): void {
    this.saveCustomerValues({letter}).then(success => {
      if (success) this.editLetter = false
    })
  }

  saveURL(url: string): void {
    this.saveCustomerValues({url}).then(success => {
      if (success) this.editURL = false
    })
  }

  saveXMLID(xml_id: string): void {
    this.saveCustomerValues({xml_id}).then(success => {
      if (success) this.editXMLID = false
    })
  }

  saveAddress(address: AddressOutput) {
    this.saveCustomerValues(address).then(success => {
      if (success) this.showModal = null
    })
  }


  saveUserProjectAccessUser() {
    const promise = new Promise<boolean>(resolve => {
      this.userService.updateUser(this.userProjectAccessUser.id, { project_id: this.selectedDefaultUserProjectId }).subscribe(
        _ => {
          resolve(true)
        },
        error => {
          console.log(error)
          resolve(false)
        }
      )
    })

    promise.then(success => {
      if (success) {
        this.updateCustomer(this.route.snapshot.paramMap.get('id'))
        this.showModal = null
      }
    })
  }

  async saveCustomerValues(customer_values: PermittedCustomerParams): Promise<boolean> {
    return await new Promise<boolean>(resolve => {
      this.customerService.updateCustomer(this.customer.id, customer_values).subscribe(
        data => {
          this.customer = data
          resolve(true)
        },
        error => {
          console.log(error)
          resolve(false)
        }
      )
    })
  }

  public storeColDefs: ColDef[] = [
    {
      field: "nr",
      headerName: "Nr.",
      width: 150,
    },
    {
      field: "name",
      headerName: "Name",
      width: 220,
      cellRenderer: this.storeNameRenderer,
      cellClass: 'hover_link_cell'
    },
    {
      field: "address",
      headerName: "Adresse",
      width: 280,
    },
    {
      field: "register",
      headerName: "Kassen",
      width: 100,
    },
    {
      field: "ip",
      headerName: "IP",
      width: 115,
    },
    {
      field: "project_count",
      width: 110,
      headerName: "Projekte",
    },
    {
      field: "comment",
      headerName: "Kommentar",
      width: 145,
    },
  ]

  public projectColDefs: ColDef[] = [
    {
      field: "active",
      headerName: "Aktiv",
      width: 100,
    },
    {
      field: "name",
      headerName: "Name",
      width: 300,
      cellRenderer: this.projectNameRenderer,
      cellClass: 'hover_link_cell'
    },
    {
      field: "description",
      headerName: "Beschreibung",
      width: 340,
    },
    {
      field: "ticket_count",
      headerName: "Tickets",
      width: 190,
    },
    {
      field: "store_count",
      headerName: "Stores",
      width: 190,
    }
  ]

  private storeGridApi!: GridApi;
  private projectGridApi!: GridApi;
  public rowClassRules: RowClassRules = {}

  public sideBarOptions: SideBarDef = {
    hiddenByDefault: false,
    toolPanels: ['columns', 'filters']
  }

  public defaultColDef: ColDef = {
    filter: true
  }

  public rowSelection: "single" | "multiple" = "single"

  protected readonly alert = alert;
  protected readonly locale = AG_GRID_LOCALE_DE;

  storeNameRenderer(params: any): string {
    const id = params.data.id
    return `<a class="hover_link" href="/a/filiale/${id}" rel="noopener">${params.value}</a>`
  }

  projectNameRenderer(params: any): string {
    const id = params.data.id
    return `<a class="hover_link" href="/a/admin/projekt/${id}" rel="noopener">${params.value}</a>`
  }

  onStoreGridReady($event: GridReadyEvent<any>) {
    this.storeGridApi = $event.api
  }

  onProjectGridReady($event: GridReadyEvent<any>) {
    this.projectGridApi = $event.api
  }

  public storeQuickSearchChanged(e: Event) {
    const newValue = (e.target as HTMLInputElement).value
    this.storeGridApi.setGridOption(
      "quickFilterText",
      newValue,
    )
  }

  public projectQuickSearchChanged(e: Event) {
    const newValue = (e.target as HTMLInputElement).value
    this.projectGridApi.setGridOption(
      "quickFilterText",
      newValue,
    )
  }

  protected readonly faUpload = faUpload;

  navigateToStoreImport() {
    this.router.navigate([`/kunde/${this.route.snapshot.paramMap.get('id')}/filialen_import`])
  }

  updateTableSizes($event: MatTabChangeEvent) {
    if ($event.index == 1) {
      this.projectGridApi.sizeColumnsToFit()
    } else {
      this.storeGridApi.sizeColumnsToFit()
    }
  }
}
