import { AfterViewInit, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Store } from 'src/app/_models';
import { Opening } from 'src/app/_models/opening.interface';
import { StoreService } from 'src/app/_services';
import { PAProject } from '../../classes/project';
import { CustomerLetter } from 'src/app/_models/customer.interface';
import { PADataControl } from "../../singletons/pa-data-control";
import { PATimeControl } from "../../singletons/pa-time-control";
import { faCheck, faCog, faWindowClose } from "@fortawesome/free-solid-svg-icons";
import { MapContainer } from "../../map/pa-map";
import { PAUtil } from "../../classes/util";
import { OpeningTimeTemplate } from "../../map/map.component";
import { UntypedFormControl, UntypedFormGroup, Validators, ReactiveFormsModule } from "@angular/forms";
import { StoreUpdateParams } from "../../../../_models/store.interface";
import { PATourPlannerControl } from "../../singletons/pa-tourplanner-control";
import { GeocoderEvent } from "../../../../_models/mapbox.interface";
import { NgClass, NgIf, NgFor } from '@angular/common';

import { MatExpansionPanel, MatExpansionPanelHeader, MatExpansionPanelTitle, MatExpansionPanelDescription } from '@angular/material/expansion';
import { MatGridList, MatGridTile } from '@angular/material/grid-list';
import { MatFormField, MatError, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatButton } from '@angular/material/button';
import { OpeningTimeEditorComponent } from '../opening-time-editor/opening-time-editor.component';
import { CommonModule } from "@angular/common";
import { MatFormFieldModule } from "@angular/material/form-field";
import { FaIconComponent, FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import * as mapboxgl from "mapbox-gl";

@Component({
  selector: 'hawk-store-data',
  templateUrl: './store-data.component.html',
  styleUrls: ['./store-data.component.scss', './../../styles/common_styles.scss', './../../../_shared/styles/common-styles.scss'],
  standalone: true,
  imports: [NgClass, FaIconComponent, NgIf, NgFor, ReactiveFormsModule, MatExpansionPanel, MatExpansionPanelHeader, MatExpansionPanelTitle, MatExpansionPanelDescription, MatGridList, MatGridTile, MatFormField, MatInput, MatError, MatButton, MatLabel, OpeningTimeEditorComponent, CommonModule, MatFormFieldModule, FontAwesomeModule]
})
export class StoreDataComponent implements OnInit, OnChanges, AfterViewInit {

  @Input() store: Store
  @Input() customerLetters: CustomerLetter[] = []
  @Input() mapBoxAccessToken: string
  @Input() mapContainer: MapContainer

  openings: Opening[] = []

  openingData: { weekday: string, is_open: boolean, open?: string, close?: string }[] = []

  showEditWindowFor: 'address' | 'openings' | 'contact'
  geocoder: MapboxGeocoder
  storeIsInUpdateProcess = false

  public editStoreAddressForm: UntypedFormGroup = new UntypedFormGroup({
    city: new UntypedFormControl({value: ''}, Validators.required),
    country: new UntypedFormControl({value: ''}, Validators.required),
    street: new UntypedFormControl({value: ''}, Validators.required),
    zip_code: new UntypedFormControl({value: ''}, [Validators.required, Validators.pattern('^([a-zA-Z]{2}-)?[0-9]{2,5}( [a-zA-Z]{0,2})?$')]),
    state: new UntypedFormControl({value: ''}),
    lat: new UntypedFormControl({value: ''}, Validators.required),
    lng: new UntypedFormControl({value: ''}, Validators.required)
  });

  public editContactForm: UntypedFormGroup = new UntypedFormGroup({
    firstname: new UntypedFormControl({value: ''}),
    lastname: new UntypedFormControl({value: ''}),
    email: new UntypedFormControl(null, Validators.email),
    phone: new UntypedFormControl(null, Validators.pattern('[- +()0-9]+')),
    comment: new UntypedFormControl({value: ''})
  });

  editOpeningTimeTemplates: OpeningTimeTemplate[]

  Project = PAProject
  private loadedStoreOpenings: Opening[]

  constructor(
    private storeService: StoreService,
  ) {
  }

  ngAfterViewInit(): void {
    this.geocoder =
      new MapboxGeocoder({
        accessToken: this.mapBoxAccessToken,
        marker: false,
        language: 'de-DE',
        mapboxgl: mapboxgl
      })

    const geocoder_elem = document.getElementById('edit_store_address_geocoder')
    !geocoder_elem.children.length ? geocoder_elem.appendChild(this.geocoder.onAdd(this.mapContainer.map)) : {};
    this.geocoder.on('result', (event) => {
      this.onStoreGeocoderEvent(event)
    })

  }

  ngOnChanges(changes: SimpleChanges): void {
    let store_changes = changes['store']
    if (store_changes?.currentValue) {
      this.updateStoreOpening()
    }
  }

  ngOnInit(): void {
  }

  resetEditStoreAddressData(): void {
    this.editStoreAddressForm.controls['city'].setValue(this.store.address_city)
    this.editStoreAddressForm.controls['street'].setValue(this.store.address_street + (this.store.address_street_no ? ' ' + this.store.address_street_no : ''))
    this.editStoreAddressForm.controls['state'].setValue(this.store.address_state)
    this.editStoreAddressForm.controls['zip_code'].setValue(this.store.address_zip)
    this.editStoreAddressForm.controls['country'].setValue(this.store.address_country)
    this.editStoreAddressForm.controls['lat'].setValue(this.store.address_latitude)
    this.editStoreAddressForm.controls['lng'].setValue(this.store.address_longitude)
  }

  resetEditContactFormData(): void {
    this.editContactForm.controls['firstname'].setValue(this.store.address_firstname)
    this.editContactForm.controls['lastname'].setValue(this.store.address_lastname)
    this.editContactForm.controls['email'].setValue(this.store.email)
    this.editContactForm.controls['phone'].setValue(this.store.phone1)
    this.editContactForm.controls['comment'].setValue(this.store.comment)
  }

  async updateStoreOpening(): Promise<void> {
    this.openings = []
    await new Promise<void>(
      resolve => {
        if (this.store.id) {
          this.storeService.getStoreOpenings(this.store.id).subscribe(
            data => {
              this.openings = data
              this.updateOpeningData()
              resolve()
            },
            err => {
              console.log(err)
            }
          )
        } else {
          resolve()
        }
      }
    )
  }

  getCustomerNames(): string[] {
    return this.store.customer_ids.map(id => this.getCustomerName(id))
  }

  getCustomerName(customer_id: number): string {
    let customer = this.customerLetters.find(customer => customer.id == customer_id)
    return customer ? customer.name : ''
  }

  getProjectNames(): string[] {
    let project_names = this.store.project_ids.map(id => this.getProjectName(id)).filter(name => name)
    return project_names.length ? project_names : ['Keine aktiven Projekte']
  }

  getProjectName(project_id: number): string {
    let project = PADataControl.Instance.loadedProjects.find(project => project.id == project_id)
    return project ? project.project_name : ''
  }

  updateOpeningData(): void {
    let opening_data: { weekday: string, is_open: boolean, open?: string, close?: string }[] = []
    for (let weekday of PATimeControl.Instance.weekDays) {
      let day_idx = PATimeControl.Instance.weekDays.indexOf(weekday)
      let opening = this.openings.find(opening => opening.day == day_idx)
      opening_data.push({
        weekday: PATimeControl.Instance.weekDayToGermanName(weekday, true),
        is_open: !!opening,
        open: opening?.open,
        close: opening?.close
      })
    }
    this.openingData = opening_data
  }

  getAddressDescription(): string {
    let street = this.editStoreAddressForm.controls['street'].value || '';
    let zip = this.editStoreAddressForm.controls['zip_code'].value || '';
    let city = this.editStoreAddressForm.controls['city'].value || '';
    let country = this.editStoreAddressForm.controls['country'].value || '';
    return street && zip && city && country ? `${street}, ${zip} ${city} (${country})` : ''
  }

  onStoreGeocoderEvent(event: GeocoderEvent): void {

    let {country, region, city, post_code, street, street_no, center, name} = PAUtil.getGeocoderEventAddress(event);

    let address_country_control = this.editStoreAddressForm.controls['country']
    address_country_control.setValue(country)
    address_country_control.markAsTouched();
    address_country_control.updateValueAndValidity()
    let address_state_control = this.editStoreAddressForm.controls['state']
    address_state_control.setValue(region)
    address_state_control.markAsTouched();
    address_state_control.updateValueAndValidity()
    let address_city_control = this.editStoreAddressForm.controls['city']
    address_city_control.setValue(city)
    address_city_control.markAsTouched();
    address_city_control.updateValueAndValidity()
    let address_zip_control = this.editStoreAddressForm.controls['zip_code']
    address_zip_control.setValue(post_code)
    address_zip_control.markAsTouched();
    address_zip_control.updateValueAndValidity()
    let address_street_control = this.editStoreAddressForm.controls['street']
    address_street_control.setValue(street + ' ' + street_no)
    address_street_control.markAsTouched();
    address_street_control.updateValueAndValidity()

    let latitude_control = this.editStoreAddressForm.controls['lat']
    latitude_control.setValue(center[1])
    latitude_control.markAsTouched();
    latitude_control.updateValueAndValidity()
    let longitude_control = this.editStoreAddressForm.controls['lng']
    longitude_control.setValue(center[0])
    longitude_control.markAsTouched();
    longitude_control.updateValueAndValidity()

    this.editStoreAddressForm.updateValueAndValidity()
  }

  protected readonly faCog = faCog;
  protected readonly faWindowClose = faWindowClose;
  protected readonly faCheck = faCheck;

  onAddressSubmit() {
    let store_update_data = this.getStoreAddressUpdateData()
    this.updateStore(store_update_data);
  }

  private getStoreAddressUpdateData(): StoreUpdateParams {
    const controls = this.editStoreAddressForm.controls
    const street = controls['street'].value
    const zip = controls['zip_code'].value
    const city = controls['city'].value
    const country = controls['country'].value

    return {
      address_city: city,
      address_country: country,
      address_street: street,
      address_street_no: '',
      address_zip: zip,
      address_state: controls['state'].value,
      address_latitude: controls['lat'].value,
      address_longitude: controls['lng'].value,
      address_used_google_maps_parameter: `${street}, ${zip} ${city}, ${country}`
    }
  }

  private updateInputStoreHashWithData(data: Store) {
    this.store.address_longitude = data.address_longitude
    this.store.address_latitude = data.address_latitude
    this.store.address_country = data.address_country
    this.store.address_zip = data.address_zip
    this.store.address_street = data.address_street
    this.store.address_state = data.address_state
    this.store.address_street_no = data.address_street_no
    this.store.address_city = data.address_city
    this.store.address_used_google_maps_parameter = data.address_used_google_maps_parameter
    this.store.address_firstname = data.address_firstname
    this.store.address_lastname = data.address_lastname
    this.store.comment = data.comment
    this.store.phone1 = data.phone1
    this.store.phone2 = data.phone2
    this.store.email = data.email
  }

  async updateEditOpeningTimeTemplates(): Promise<void> {
    let {templates, store_openings} = await PADataControl.Instance.getStoresOpeningTimeTemplates(this.store.id)
    this.editOpeningTimeTemplates = templates
    this.loadedStoreOpenings = store_openings
  }

  async saveOpeningTimes() {
    const edited_weekday_idxs: number[] = []
    const error_weekday_idxs: number[] = []
    let promises: Promise<void>[] = []
    for (let template of this.editOpeningTimeTemplates) {
      for (let day_idx of template.day_idxs) {
        const loaded_opening = this.loadedStoreOpenings.find(op => op.day == day_idx)
        if (loaded_opening) {
          if (loaded_opening.open != template.open || loaded_opening.close != template.close) {
            promises.push(
              new Promise(resolve => {
                this.storeService.updateStoreOpening(loaded_opening.store_id, loaded_opening.id, {open: template.open, close: template.close}).subscribe(
                  _ => {
                    edited_weekday_idxs.push(day_idx)
                    resolve()
                  },
                  err => {
                    error_weekday_idxs.push(day_idx)
                    console.log(err)
                    resolve()
                  }
                )
              })
            )
          }
        } else {
          promises.push(
            new Promise(resolve => {
              this.storeService.createStoreOpening({open: template.open, close: template.close, store_id: this.store.id, day: day_idx}).subscribe(
                _ => {
                  edited_weekday_idxs.push(day_idx)
                  resolve()
                },
                err => {
                  error_weekday_idxs.push(day_idx)
                  console.log(err)
                  resolve()
                }
              )
              }
            )
          )
        }
      }
      this.showEditWindowFor = null
    }

    let delete_day_openings = [0, 1, 2, 3, 4, 5, 6].filter(day => this.loadedStoreOpenings.find(op => op.day == day) && !this.editOpeningTimeTemplates.find(template => template.day_idxs.includes(day)))
    for (let day of delete_day_openings) {
      const delete_store_opening = this.loadedStoreOpenings.find(op => op.day == day)
      if (delete_store_opening) {
        promises.push(
          new Promise(resolve => {
            this.storeService.destroyStoreOpening(delete_store_opening.store_id, delete_store_opening.id).subscribe(
              _ => {
                edited_weekday_idxs.push(day)
                resolve()
              },
              err => {
                error_weekday_idxs.push(day)
                console.log(err)
                resolve()
              }
            )
            }
          )
        )
      }
    }

    await Promise.all(promises)

    const edited_weekdays = edited_weekday_idxs.sort().map(idx => PATimeControl.Instance.getGermanWeekDay(idx))
    const error_weekdays = error_weekday_idxs.sort().map(idx => PATimeControl.Instance.getGermanWeekDay(idx))

    let success_string = edited_weekdays.length ? `Öffnungszeiten für ${edited_weekdays.join(', ')} erfolgreich geändert` : ''
    let error_string = error_weekdays.length ? `Fehler bei Änderungen für ${error_weekdays.join(', ')}!` : '';

    PATourPlannerControl.Instance.snackBar.open(
      [success_string, error_string].filter(s => s).join(' | '),
      'ok'
    )._dismissAfter(error_string ? 10000 : 3000)

    this.updateStoreOpening()
  }

  getContactDescription(): string {
    return [
      [
        this.editContactForm.controls['firstname'].value,
        this.editContactForm.controls['lastname'].value
      ].filter(value => value).join(' '),
      this.editContactForm.controls['email'].value,
      this.editContactForm.controls['phone'].value,
      this.editContactForm.controls['comment'].value,
    ].filter(value => value).join(' | ')
  }

  onContactSubmit() {
    let store_update_data = this.getStoreContactUpdateData()
    this.updateStore(store_update_data);
  }

  private updateStore(store_update_data: StoreUpdateParams) {
    this.storeIsInUpdateProcess = true
    this.storeService.updateStore(this.store.id, store_update_data).subscribe(
      data => {
        PATourPlannerControl.Instance.snackBar.open(
          'Store wurde in der Datenbank aktualisiert',
          'Ok'
        )._dismissAfter(3000)
        this.updateInputStoreHashWithData(data)
        this.storeIsInUpdateProcess = false
        this.showEditWindowFor = null
      },
      err => {
        console.log(err)
        this.storeIsInUpdateProcess = false
        PATourPlannerControl.Instance.snackBar.open(
          'Fehler: Store konnte nicht in der Datenbank aktualisiert werden',
          'Ok'
        )._dismissAfter(10000)
      }
    )
  }

  private getStoreContactUpdateData(): StoreUpdateParams {
    const controls = this.editContactForm.controls

    return {
      address_firstname: controls['firstname'].value,
      address_lastname: controls['lastname'].value,
      phone1: controls['phone'].value,
      email: controls['email'].value,
      comment: controls['comment'].value
    }
  }
}