<div class="qmPage">
  <section class="main-content">
    <ng-container *ngFor="let jobType of ['bod', 'not', 'tld', 'jnd', 'tlw', 'tps', 'tpe']">
      <hawk-qm-page-table [jobs]="jobs" [jobType]="jobType" (jobUpdated)="jobUpdated()"></hawk-qm-page-table>
    </ng-container>
  </section>
  <aside>

  </aside>
</div>

