import { Component, inject, OnInit } from '@angular/core';
import { ColDef, GridReadyEvent } from "ag-grid-community";
import { OperationService, TicketService, UserService } from "../_services";
import { AG_GRID_LOCALE_DE } from "../_helpers/aggrid.locale.de";
import {
  GridApi,
  ITooltipParams,
  RowClassRules,
  SideBarDef,
  SizeColumnsToContentStrategy, SizeColumnsToFitGridStrategy,
  SizeColumnsToFitProvidedWidthStrategy
} from "ag-grid-enterprise";
import { PASettingsControl } from "../_components/planning-assistant/singletons/pa-settings-control";
import { dateFormatter } from "../_helpers/aggrid-renderer";
import { WPTicket } from "../_models/ticket.interface";
import { Router } from "@angular/router";
import { Technician } from "../_models";
import { CommonModule, formatDate } from "@angular/common";
import { UserAbsence, WPTechnician } from "../_models/technician.interface";
import { WPOperation } from "../_models/operation.interface";
import { BarData, BarOption, Deadline, CapacityBarsComponent } from "./capacity-bars/capacity-bars.component";
import { RouteNode, RouteVisualizationComponent } from "./route-visualization/route-visualization.component";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { MatTabChangeEvent } from "@angular/material/tabs";
import { TravelTechnicianDateService } from "../_services/travel_technician_date.service";
import { FormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatButtonModule } from "@angular/material/button";
import { MatSelectModule } from "@angular/material/select";
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { AgGridAngular } from 'ag-grid-angular';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatTabsModule } from '@angular/material/tabs';
import { AgGridThemeService } from '../_services/ag-grid-theme.service';
import { ThrobberComponent } from "../_components/_shared/throbber/throbber.component";
import { TechnicianTour } from "../_models/technician-tour.model";

interface OpenProject {
  project_name: string,
  project_color: string,
  unplanned_tickets: WPTicket[]
}

interface ProjectChartData {
  name: string,
  color: string,
  count: number
}

type KPIFilter = '6sla' | '0sla' | 'new' | 'qm' | 'overdue' | 'not_approached' | 'all'

@Component({
  selector: 'hawk-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss', './../_components/_shared/styles/common-styles.scss'],
  imports: [CommonModule, FormsModule, CapacityBarsComponent, RouterModule, MatFormFieldModule, MatButtonModule, MatButtonToggleModule, MatSelectModule, FontAwesomeModule, AgGridAngular, RouteVisualizationComponent, MatTabsModule, ThrobberComponent],
  standalone: true
})
export class WelcomeComponent implements OnInit {

  public hawkTheme = inject(AgGridThemeService).getTheme();

  selectedTabIdx: number = 0;
  selectedKPITile: KPIFilter = 'all'
  selectedProjects: string[] | 'all' = 'all'

  // Calendar
  calendarWasLoaded = false
  technicianAbsences: UserAbsence[] = []

  pseudoTechnicianNames: string[] = ['offene Planung', 'Bentomax Bereitschaft', 'nicht vergeben', 'Kein Einsatz notwendig', 'Materialerfassung Kiel']
  placeholderTechnicians: Technician[] = []
  tickets: WPTicket[] = []
  shownTickets: WPTicket[] = []
  technicians: WPTechnician[] = []
  shownTechnicians: (WPTechnician & {tour?: TechnicianTour })[] = []
  selectedTicketIds: number[] = []
  selectedTechnician: WPTechnician = null
  openProjects: OpenProject[] = []

  // Capacity Bars
  technicianBarData: BarData[] = []
  capacityBarsFilter = ['Alle Aufträge abgeschlossen', 'Im letzten Auftrag', 'Ohne Auftrag'];
  capacityBarOptions: BarOption[] = [
    {
      title: 'Reisetechniker',
      tooltip: 'Wenn dieses Feld gesetzt ist, wird die Fahrtzeit der Heimreise des Technikers mit 0 Minuten berechnet.'
    }
  ]
  deadlineNow?: Deadline
  unitAmountStart = 6
  unitAmountEnd = 20
  showCapacityBarThrobber = true

  // Route Visualization
  routeVisualizationNodes: RouteNode[] = []

  // Sideboard
  public SLA6HWarningTicketCount: number = 0
  public SLA0HWarningTicketCount: number = 0
  public newTicketCount: number = 0
  public qmTicketCount: number = 0
  public overdueTicketCount: number = 0
  public notApproachedTicketCount: number = 0

  technicianDayOperationFilter: 'with_day_operations'| 'all' = 'all'
  technicianGroupFilter: 'bentomax'| 'all' = 'bentomax'

  protected readonly locale = AG_GRID_LOCALE_DE;
  protected readonly PASettingsControl = PASettingsControl;
  protected readonly faCheck = faCheck;

  public ticketColDefs: ColDef[] = [
    {
      field: "id",
      headerName: "ID",
      cellRenderer: this.ticketIdRenderer,
      width: 110,
      maxWidth: 130,
      cellClass: 'hover_link_cell',
      checkboxSelection: true,
      headerCheckboxSelection: true
    },
    {
      field: "project.name",
      headerName: "Projekt",
      width: 160,
      maxWidth: 200,
    },
    {
      field: "priority.name",
      headerName: "Prio",
      cellRenderer: this.prioCellRenderer2,
      width: 80,
      maxWidth: 80,
    },
    {
      field: "status",
      headerName: "Status",
      width: 120,
      maxWidth: 120,
    },
    {
      field: "sla",
      headerName: "SLA",
      valueFormatter: dateFormatter(),
      cellClassRules: {
        'sla_danger_td': function(params) {
          const hours_until_sla = (Date.parse(params.data.sla) - new Date().getTime() ) / (1000 * 3600)
          return hours_until_sla < 4;
        },
        'sla_warn_td': function(params) {
          const hours_until_sla = (Date.parse(params.data.sla) - new Date().getTime() ) / (1000 * 3600)
          return hours_until_sla < 6 && hours_until_sla >= 4;
        }
      },
      width: 150,
      maxWidth: 150
    },
    {
      field: "store_nr",
      headerName: "Store-Nr",
      cellRenderer: this.storeRenderer,
      width: 120,
      maxWidth: 180,
      cellClass: 'hover_link_cell'
    },
    {
      field: "store_name",
      headerName: "Store Name",
      width: 120,
    },
    {
      field: "city",
      headerName: "Ort",
      maxWidth: 200
    },
    {
      field: "state",
      headerName: "Bundesland",
      maxWidth: 200,
    },
    {
      field: 'description',
      headerName: "Info",
      width: 240,
      tooltipField: 'description',
    },
    /*{
      field: "date_created",
      headerName: "Erstelldatum",
      valueFormatter: dateFormatter(),
      width: 150,
      maxWidth: 150,
    },*/
    /*{
      field: "operation_date",
      headerName: "Einsatz-Datum",
      cellRenderer: this.operationDateRenderer,
      width: 150,
      maxWidth: 150,
      tooltipField: "operation_date"
    },
    {
      field: "planned_technician_name",
      headerName: "Einsatz-Techniker",
      width: 160,
      maxWidth: 160,
      tooltipField: "planned_technician_name"
    },*/
  ]

  public technicianColDefs: ColDef[] = [
    {
      field: "full_name",
      headerName: "Name",
      width: 180,
    },
    {
      field: "city",
      headerName: "Ort",
      width: 140,
      hide: true,
    },
    {
      field: "email1",
      headerName: "Email",
      cellRenderer: this.emailRenderer,
      width: 260,
    },
    {
      field: "phone1",
      headerName: "Telefon",
      cellRenderer: this.telephoneRenderer,
      width: 160,
    },
    {
      field: "day_operations",
      headerName: "Status",
      width: 200,
      cellRenderer: (params: { value: WPOperation[], data: WPTechnician }) => {
        const available = `<div style="display: flex; flex-direction: row;"><div class="available technician_status"></div> Verfügbar </div>`
        const busy = `<div style="display: flex; flex-direction: row;"><div class="busy technician_status"></div> Beschäftigt </div>`
        const absence = `<div style="display: flex; flex-direction: row;"><div class="absence technician_status"></div> ${params.data.day_absence} </div>`

        if (params.value) {
          if (params.data.day_absence) {
            return absence
          } else if (params.value.find(op => !op.finished)) {
            return busy
          } else {
            return available
          }
        }
        return null
      },
    },
    {
      field: "day_operations",
      headerName: "Tagesaufträge",
      width: 140,
      valueFormatter: (params: { value: WPOperation[], data: WPTechnician }) => {
        if (params.value) {
          return params.value.length.toString()
        }
        return null
      },
    },
    {
      field: "id",
      headerName: "Planungsassistent",
      width: 200,
      cellRenderer: (params: { value: number }) => {
        return `<a href="/a/planning_assistant?plan_technician_id=${params.value}" target="_blank">öffnen</a>`
      },
    },
  ]

  autoSizeStrategy: SizeColumnsToFitGridStrategy | SizeColumnsToFitProvidedWidthStrategy | SizeColumnsToContentStrategy = {
    type: "fitGridWidth",
  }

  private ticketGridApi!: GridApi;
  private technicianGridApi!: GridApi;

  public rowClassRules: RowClassRules = {}

  public sideBarOptions: SideBarDef = {
    hiddenByDefault: false,
    toolPanels: ['columns', 'filters']
  }

  public defaultColDef: ColDef = {
    filter: true
  }

  constructor(
    private ticketService: TicketService,
    private userService: UserService,
    private router: Router,
    private operationService: OperationService,
    private travelTechnicianDateService: TravelTechnicianDateService
  ) { }

  ngOnInit(): void {
    this.userService.placeHolderTechnicians().subscribe(
      data => {
        this.placeholderTechnicians = data
        this.ticketService.getOpenServiceTickets().subscribe(
          data => {
            this.tickets = data
            this.updateShownOperations()
            this.updateSideBoard()
          },
          err => {
            console.log(err)
          }
        )

        this.updateTechnicianData()
        setInterval(()=> { this.updateTechnicianData() }, 5 * 60 * 1000);
      },
      err => {
        console.log(err)
      }
    )
  }

  private updateTechnicianData(): void {
    this.showCapacityBarThrobber = true

    this.updateTechnicians(!this.calendarWasLoaded).then(
      _ => {
        this.showCapacityBarThrobber = false
      }
    );
  }

  private async updateTechnicians(download_calendar?: boolean) {
    await new Promise<void>(resolve => {
      this.userService.getUsersWPHashes().subscribe(
        data => {
          this.technicians = data.filter(
            t => !this.pseudoTechnicianNames.includes(t.full_name) && !this.placeholderTechnicians.find(pl_t => pl_t.id == t.id)
          ).sort(
            (ta, tb) => ta.full_name < tb.full_name ? -1 : 1
          )

          if (download_calendar) {
            this.userService.downloadUserAbsenceCalendar().then(absences => {
              this.technicianAbsences = absences
              this.calendarWasLoaded = true
              this.technicians.map(technician => {
                const technician_absences = this.getTodaysTechnicianAbsences(technician)
                if (technician_absences.length > 0) {
                  technician.day_absence = technician_absences[0].type
                }
              })
              this.updateShownTechnicians().then(
                _ => resolve()
              )
            })
          } else {
            this.updateShownTechnicians().then(
              _ => resolve()
            )
          }

        },
        error => {
          console.log(error)
          resolve()
        }
      )
    })

  }

  updateSideBoard(): void {
    this.updateSLAWarnings()
    this.updateCharts()
  }

  updateSLAWarnings(): void {
    this.SLA0HWarningTicketCount = this.filterTicketsForTimeUntilSLA(this.tickets, 0).length
    this.SLA6HWarningTicketCount = this.filterTicketsForTimeUntilSLA(this.tickets, 6 * 60 * 60 * 1000, true).length
  }

  filterTicketsForTimeUntilSLA(tickets: WPTicket[], time_ms: number, cut_at_0?: boolean): WPTicket[] {
    const timestamp_now = new Date().getTime();
    return tickets.filter(ti => {
      if (!ti.sla) return false

      const sla_ts = new Date(ti.sla).getTime()
      if (ti.date_finished && sla_ts >= new Date(ti.date_finished).getTime()) return false

      const threshold_time = sla_ts - time_ms
      return (threshold_time < timestamp_now) && (!cut_at_0 || sla_ts > timestamp_now)
    })
  }

  updateCharts(): void {
    const new_tickets = this.filterNewTickets(this.tickets)
    const qm_tickets = this.filterQMTickets(this.tickets)
    const overdue_tickets = this.filterOverdueTickets(this.tickets)
    const not_approached_tickets = this.filterNotApproachedTickets(this.tickets)

    this.newTicketCount = new_tickets.length
    this.qmTicketCount = qm_tickets.length
    this.overdueTicketCount = overdue_tickets.length
    this.notApproachedTicketCount = not_approached_tickets.length
  }

  filterNewTickets(tickets: WPTicket[]): WPTicket[] {
    return tickets.filter(ticket => this.ticketIsNew(ticket))
  }

  filterQMTickets(tickets: WPTicket[]): WPTicket[] {
    return tickets.filter(ticket => ticket.qm_jobs.length > 0)
  }

  filterOverdueTickets(tickets: WPTicket[]): WPTicket[] {
    return tickets.filter(ticket =>
      {
        if (!ticket.operation_date) return false

        const ts_now = new Date().getTime()
        const ts_operation_date = new Date(ticket.operation_date).getTime()
        if (ts_now <= ts_operation_date) {
          return false
        } else {
          return !ticket.date_on_site || !(new Date(ticket.date_on_site).getTime() > ts_operation_date)
        }
      }
    )
  }

  filterNotApproachedTickets(tickets: WPTicket[]): WPTicket[] {
    return tickets.filter(ticket => !this.ticketIsUnplanned(ticket) && !ticket.date_travel_start)
  }

  /*ticketsToProjectPieChart(tickets: WPTicket[], chart_id: string, kpi_filter: KPIFilter): Chart<'pie', number[], string> {
    const chart_data = this.getTicketsProjectChartData(tickets)
    return new Chart(chart_id, {
      type: 'pie',
      data: {
        labels: chart_data.map(data => data.name),
        datasets: [{
          label: 'Anzahl',
          data: chart_data.map(data => data.count),
          backgroundColor: chart_data.map(data => data.color),
          borderColor: chart_data.map(data => 'white'),
          borderWidth: 2,
        }]
      },
      options: {
        plugins: {
          legend: {
            display: false
          }
        },
        borderColor: 'rgb(236,236,236)',
        spacing: 1,
        'onClick' : (evt, item)=>  {
          let dataIndex = item[0].index;
          let label = evt['chart'].data.labels[dataIndex];
          this.clickProject(label)
          if (this.selectedKPITile != kpi_filter) {
            this.selectKPITile(kpi_filter)
          }
          evt.native.stopPropagation()
        }
      }
    });
  }*/

  getTicketsProjectChartData(tickets: WPTicket[]): ProjectChartData[] {
    const chart_data: ProjectChartData[] = []
    for (let project of tickets.map(ti => ti.project)) {
      if (!chart_data.find(data => data.name == project.name)) {
        const pr_tickets = tickets.filter(t => t.project.name == project.name)
        if (pr_tickets.length) {
          chart_data.push({
            name: project.name,
            color: project.color || 'rgba(125, 125, 125, 1)',
            count: pr_tickets.length,
          })
        }
      }
    }
    return chart_data
  }

  async updateShownTechnicians(): Promise<void> {
    this.shownTechnicians = this.getDayOperationFilteredTechnicians(this.getGroupFilteredTechnicians(this.technicians))
    await this.updateTechnicianCapacityBars()
  }

  getTodaysTechnicianAbsences(technician: WPTechnician): UserAbsence[] {
    const ts_now = new Date().getTime()
    return this.technicianAbsences.filter(absence => absence.employee_name.toLowerCase() == technician.full_name.toLowerCase() && absence.from.getTime() <= ts_now && ts_now <= absence.until.getTime() - 1)
  }

  getGroupFilteredTechnicians(technicians: WPTechnician[]): WPTechnician[] {
    return technicians.filter(t => {
      return !(this.technicianGroupFilter == 'bentomax' && ![t.company, t.company_address_company].filter(c => c).find(c => c.toLowerCase().includes('bentomax')));
    })
  }

  getDayOperationFilteredTechnicians(technicians: WPTechnician[]): WPTechnician[] {
    return technicians.filter(t => {
      return (this.technicianDayOperationFilter != 'with_day_operations') || (t.day_operations.length > 0);
    })
  }

  updateShownOperations(): void {
    const tickets = this.tickets

    const project_filtered_tickets = this.getProjectFilteredTickets(tickets)
    this.shownTickets = this.getKPIFilteredTickets(project_filtered_tickets)

    const open_projects: OpenProject[] = []
    for (let project of tickets.map(ti => ti.project)) {
      if (!open_projects.find(op => op.project_name == project.name)) {
        const unplanned_tickets = tickets.filter(t => t.project.name == project.name)
        open_projects.push({
          project_name: project.name,
          project_color: project.color,
          unplanned_tickets: unplanned_tickets
        })
      }
    }

    this.openProjects = open_projects
  }

  getProjectFilteredTickets(tickets: WPTicket[]): WPTicket[] {
    return tickets.filter(ticket => this.selectedProjects == 'all' || this.selectedProjects.includes(ticket.project.name))
  }

  selectKPITile(name: KPIFilter ): void {
    if (this.selectedKPITile == name) {
      this.selectedKPITile = "all"
    } else {
      this.selectedKPITile = name
    }
    this.updateShownOperations()
  }

  getKPIFilteredTickets(tickets: WPTicket[]): WPTicket[] {
    let res: WPTicket[]
    switch (this.selectedKPITile) {
      case '0sla': {
        res = this.filterTicketsForTimeUntilSLA(tickets, 0)
        break;
      }
      case '6sla': {
        res = this.filterTicketsForTimeUntilSLA(tickets, 2 * 60 * 60 * 1000, true)
        break;
      }
      case 'new': {
        res = this.filterNewTickets(tickets)
        break;
      }
      case 'qm': {
        res = this.filterQMTickets(tickets)
        break;
      }
      case 'overdue': {
        res = this.filterOverdueTickets(tickets)
        break;
      }
      case 'not_approached': {
        res = this.filterNotApproachedTickets(tickets)
        break;
      }
      default: {
        res = tickets
      }
    }
    return res
  }

  ticketIsUnplanned(ticket: WPTicket): boolean | Technician {
    return !ticket.planned_technician_id || ticket.planned_technician_id == 283 || this.placeholderTechnicians.find(technician => technician.id == ticket.planned_technician_id)
  }

  ticketIsNew(ticket: WPTicket): boolean {
    const created_date = new Date(ticket.date_created)
    const today_date = new Date()
    return  created_date.getDate() == today_date.getDate() && created_date.getMonth() == today_date.getMonth() && created_date.getFullYear() == today_date.getFullYear()
  }

  ticketIdRenderer(params) {
    const tid = params.data.id
    return `<a class="hover_link" href="/a/ticket/${tid}" rel="noopener">${params.value}</a>`
  }

  storeRenderer(params) {
    const store_id = params.data.store_id
    return `<a class="hover_link" href="/a/filiale/${store_id}" rel="noopener">${params.value}</a>`
  }

  telephoneRenderer(params) {
    const phone = params.value
    return `<a href="tel:${phone ? phone.replaceAll(' ', '') : ''}" target="_blank" rel="noopener">${phone || ''}</a>`
  }

  emailRenderer(params) {
    const mail = params.value
    return `<a href="mailto:${mail}" target="_blank" rel="noopener">${mail || ''}</a>`
  }

  private prioCellRenderer2(params): string {
    return `<img src="/r/icons/priority/${params?.data?.priority?.color?.replace('#', '')}.svg" 
              height="18" width="18" alt="priority_icon"
              aria-hidden="true" data-toggle="tooltip"
              title="${params.value}">`
  }

  public operationDateRenderer(params): string {
    if (params.value) {
      return `${formatDate(params.value, "dd.MM.YYYY HH:mm", 'de-DE')}`
    } else {
      return ''
    }
  }

  toolTipListValueGetter(params: ITooltipParams<WPTicket, string[], any>){
    return (params.value == null || !params.value.length) ? "" : params.value.join(' | ');
  }

  onTicketGridReady(params: GridReadyEvent) {
    this.ticketGridApi = params.api
  }

  onTechnicianGridReady(params: GridReadyEvent) {
    this.technicianGridApi = params.api
  }

  public ticketQuickSearchChanged(e: Event) {
    this.setTicketQuickFilterText((e.target as HTMLInputElement).value)
  }

  public technicianQuickSearchChanged(e: Event) {
    this.setTechnicianQuickFilterText((e.target as HTMLInputElement).value)
  }

  public setTicketQuickFilterText(text: string) {
    this.ticketGridApi.setGridOption(
      "quickFilterText",
      text,
    )
  }

  public setTechnicianQuickFilterText(text: string) {
    this.technicianGridApi.setGridOption(
      "quickFilterText",
      text,
    )
  }

  onTicketSelectionChanged () {
    this.selectedTicketIds = this.ticketGridApi.getSelectedRows().map(row => row.id);
  }

  onTechnicianSelectionChanged() {
    const new_selected_technician = this.technicianGridApi.getSelectedRows()[0]
    if (new_selected_technician) {
      this.selectedTechnician = new_selected_technician
      this.updateRouteVisualizationNodes()
    }
  }

  selectTechnicianInTable(technician: WPTechnician, skip_tab_switch?: boolean): void {
    if (this.selectedTabIdx != 1 && !skip_tab_switch) this.selectedTabIdx = 1
    this.technicianGridApi.forEachNode(node => {
      if (node.data.full_name == technician.full_name) this.technicianGridApi.setNodesSelected({nodes: [node], newValue: true})
    })
    this.technicianGridApi.paginationGoToPage(Math.floor(this.technicianGridApi.getSelectedNodes()[0].rowIndex / this.technicianGridApi.paginationGetPageSize()))
  }

  openPlanningAssistant(): void {
    const url = this.router.serializeUrl(
      this.router.createUrlTree(
        ['/a/planning_assistant'],
        { queryParams: { ...{
          auto_select: this.selectedTicketIds.slice(1),
          ticket_id: this.selectedTicketIds[0],
        } } }
      )
    );
    window.open(url, '_blank');
  }

  selectAllProjects(): void {
    this.selectedProjects = 'all'
    this.updateShownOperations()
  }

  clickProject(project: string) {
    if (this.selectedProjects == 'all') {
      this.selectedProjects = [project]
    } else {
      if (this.selectedProjects.includes(project)) {
        this.selectedProjects = 'all'
      } else {
        this.selectedProjects = [project]
      }
    }
    this.updateShownOperations()
  }

  private async updateTechnicianCapacityBars() {
    this.technicianBarData = await Promise.all(
      this.shownTechnicians.map(technician => {
        if (!technician.tour) technician.tour = new TechnicianTour(technician, this.operationService)
        return technician.tour.waitForBarData()
      })
    )
    this.updateDeadlineNow()
  }

  private async updateTechnicianRouteNodes(technician: (WPTechnician & { tour?: TechnicianTour })) {
    if (!technician.tour) technician.tour = new TechnicianTour(technician, this.operationService)
    this.routeVisualizationNodes = await technician.tour.waitForRouteNodes()
  }

  private updateDeadlineNow(): void {
    const time_now = this.getHoursNow()
    const show_time = time_now < this.unitAmountEnd && this.unitAmountStart <= time_now
    this.deadlineNow = show_time ? { at_value: time_now, description: 'Jetzt', color: 'blue' } : null
  }

  private getHoursNow(): number {
    const date_now = new Date()
    return this.getHoursOnDate(date_now)
  }

  private getHoursOnDate(date: Date): number {
    const hour_now = date.getHours()
    const minute_now = date.getMinutes()
    return hour_now + (minute_now / 60)
  }

  changeBarOption(event: {idx: number, option: string, new_value: boolean}): void {
    const technician = this.shownTechnicians[event.idx]
    if (event.option == 'Reisetechniker') {
      if (event.new_value) {
        this.setTravelTechnicianDate(technician)
      } else {
        this.deleteTravelTechnicianDate(technician)
      }
    }
  }

  setTravelTechnicianDate(technician: WPTechnician): void {
    this.showCapacityBarThrobber = true
    const date = new Date()
    date.setUTCHours(0, 0, 0, 0)

    const data = {
      user_id: technician.id,
      date: this.formatDate(date)
    }

    this.travelTechnicianDateService.createTravelTechnicianDate(data).subscribe(
      _ => {
        this.updateTechnicianData()
      },
      error => {
        console.log(error)
        this.showCapacityBarThrobber = false
      }
    )
  }

  deleteTravelTechnicianDate(technician: WPTechnician): void {
    this.showCapacityBarThrobber = true
    this.travelTechnicianDateService.deleteTravelTechnicianDate(technician.travel_technician_date_id).subscribe(
      _ => {
        this.updateTechnicianData()
      },
      err => {
        console.log(err)
        this.showCapacityBarThrobber = false
      }
    )
  }

  formatDate(date: Date) {
    if (date) {
      return date.getFullYear() + '-' +
        ((date.getMonth() + 1).toString().padStart(2, '0')) + '-' +
        date.getDate().toString().padStart(2, '0') + 'T' +
        date.getHours().toString().padStart(2, '0') + ':' +
        date.getMinutes().toString().padStart(2, '0') + ':00'
    }

    return
  }

  private async updateRouteVisualizationNodes() {
    if (this.selectedTechnician) {
      await this.updateTechnicianRouteNodes(this.selectedTechnician)
    }
  }

  // get work time in minutes
  operationWorkTime(operation: WPOperation): number {
    if (operation.on_site && operation.finished) {
      return (new Date(operation.finished).getTime() - new Date(operation.on_site).getTime()) / (1000 * 60)
    } else {
      return operation.time_estimation
    }
  }

  operationsStartDayMinutes(operation: WPOperation): number {
    let use_date: Date
    if (operation.on_site) {
      use_date = new Date(operation.on_site)
    } else {
      use_date = new Date(operation.operation_date)
    }

    const on_site_timestamp = use_date.getTime()
    use_date.setHours(0)
    use_date.setMinutes(0)
    use_date.setMilliseconds(0)
    return (on_site_timestamp - use_date.getTime()) / (60 * 1000)
  }

  updateTableSizes($event: MatTabChangeEvent) {
    if ($event.index == 1) {
      this.technicianGridApi.sizeColumnsToFit()
    } else {
      this.ticketGridApi.sizeColumnsToFit()
    }
  }

  onTechnicianRowDataUpdate() {
    const selected_technician_name = this.selectedTechnician?.full_name
    const new_selected_technician = this.shownTechnicians.find(t => t.full_name == selected_technician_name)
    if (new_selected_technician) {
      this.selectTechnicianInTable(new_selected_technician, true)
    }
  }
}