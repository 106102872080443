import { Component, inject, OnInit } from '@angular/core';
import { WarehouseService } from '../_services';
import { ActivatedRoute, Router } from '@angular/router';
import { ColDef, GridApi, RowClassRules, GetDataPath } from 'ag-grid-enterprise';
import {
  GridReadyEvent,
  IServerSideDatasource,
  FirstDataRenderedEvent,
  RowGroupOpenedEvent
} from 'ag-grid-community';
import { AgGridExternalLinkRenderer } from '../_components/_shared/ag-grid-renderer/ag-grid-extranl-link.component';
import { Warehouse } from '../_models';
import { AgGridGenericLinkRenderer } from '../_components/_shared/ag-grid-renderer/ag-grid-generic-link.component';
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";

import { AG_GRID_LOCALE_DE } from 'src/app/_helpers/aggrid.locale.de'
import { AgGridAngular } from 'ag-grid-angular';
import { AgGridThemeService } from '../_services/ag-grid-theme.service';

@Component({
  imports: [CommonModule, RouterModule, AgGridAngular],
    selector: 'hawk-warehouse-articles',
    templateUrl: './warehouse-articles.component.html',
    styleUrls: ['./warehouse-articles.component.scss'],
})
export class WarehouseArticlesComponent implements OnInit {
  public hawkTheme = inject(AgGridThemeService).getTheme();
  
  constructor(
    private warehouseService: WarehouseService,
    private route: ActivatedRoute,
    private router: Router,
  ) { }
  readonly ALLE = 'alle';
  readonly BAD_PARTS = 'bad-parts';
  readonly ARTICLES = 'articles'
  readonly articleTypes: string[] = [this.ARTICLES, this.BAD_PARTS, this.ALLE];
  public warehouseId: number;
  public warehouse: any;
  public breadCrumb: any[];
  public articleTemplates: any[];
  private gridApi!: GridApi;
  public articleType: string;
  public storageBinId: string | number | String;
  public locale = AG_GRID_LOCALE_DE;
  public getDataPath: GetDataPath = (data) => data.path;

  public paginationPageSize = 50;
  public paginationPageSizeSelector = [50,100,300,1000,9000]

  public rowSelection: "single" | "multiple" = "multiple"
  public rowClassRules: RowClassRules = {
    // if tech on site, color row
    'tech-on-site': (params) => {
      if (params?.data === undefined) return "...";
      return params.data.tech_on_site
    },
  }

  public detailCellRendererParams: any;

  public defaultColDef: ColDef = {
    filter: true,
    flex: 1
  }

  // Column Definitions: Defines the columns to be displayed.
  public colDefs: ColDef[] = [
    {
      field: "description",
      headerName: 'Bezeichnung',
      cellRenderer: "agGroupCellRenderer",
      cellRendererParams: {
        suppressCount: true,
        innerRenderer: AgGridGenericLinkRenderer,
        routerLink: ['/', 'artikelvorlagen', '@id'],
        labelFromData: true,
        dataKey: 'description'
      }
    },
    {
      field: "product_category",
      headerName: "Produkt"
    },
    {
      field: "vendor",
      headerName: "Hersteller"
    },
    {
      field: "articles_count",
      headerName: "Anzahl"
    },
    {
      field: "serial_numbers",
      headerName: "Seriennummern",
      hide: true
    }
  ]

  public quicksearchChanged(e: Event) {
    const newValue = (e.target as HTMLInputElement).value;
    this.gridApi.setGridOption('includeHiddenColumnsInQuickFilter', true)
    this.gridApi.setGridOption(
      "quickFilterText",
      newValue,
    )

    this.gridApi.forEachDetailGridInfo((detailGridInfo) => {
      detailGridInfo.api.setGridOption(
        "quickFilterText",
        newValue,
      )
    });
  }

  onRowGroupOpened(event: RowGroupOpenedEvent<any>) {
    if (event.expanded) {
      var rowNodeIndex = event.node.rowIndex!;
      // factor in child nodes so we can scroll to correct position
      var childCount = event.node.childrenAfterSort
        ? event.node.childrenAfterSort.length
        : 0;
      var newIndex = rowNodeIndex + childCount;
      this.gridApi.ensureIndexVisible(newIndex);
    }
  }

  getWareHouseId() {
    return this;
  }

  onFirstDataRendered(params: FirstDataRenderedEvent) {
    // params.api.forEachNode(function (node) {
    //   node.setExpanded(node.id === "1");
    // });
  }

  onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api;
  }

  loadArticleTemplates() {
    const badParts = this.articleType === this.BAD_PARTS;
    const isAlle = this.articleType === this.ALLE;
    const binId = parseInt(`${this.storageBinId}`);
    const storage_bin_id = !isNaN(binId) && binId > 0 ? binId : null;
    this.warehouseService.loadArticles(
      this.warehouseId,
      {
        ...(storage_bin_id ? { storage_bin_id } : {}),
        ...(isAlle ? {} : { bad_parts: badParts } )
      }
    )
    .subscribe({
      next: (data) => {
        this.articleTemplates = data;
      },
      error: (err) => {
        console.log('err', err);
        this.articleTemplates = [];
      }
    })
  }

  loadWareHouse() {
    const binId = parseInt(`${this.storageBinId}`);
    const includeBins = !isNaN(binId) && binId > 0;
    this.warehouseService.loadWarehouse(this.warehouseId, { include_bins: includeBins }).subscribe(
      {
        next: (response: any) => {
          this.warehouse = response;
          const storageTitle = includeBins ? (
            (this.warehouse.bins || []).find((b: any) => (b.id === binId)) || {}
          ).label : null;
          this.breadCrumb = [
            {
              title: 'Lager',
              path: ['/', 'lager', 'uebersicht']
            },
            {
              title: this.warehouse.label,
              path: ['/', 'lager', this.warehouseId]
            },
            includeBins && {
              title: `Lagerplatz ${storageTitle}`
            },
            this.articleType === this.ALLE &&
            { title: 'alle Artikel' },
            this.articleType === this.BAD_PARTS &&
            { title: 'BadPart' },
            this.articleType === this.ARTICLES &&
            { title: 'Artikel (ohne BadPart)' }
            // {
            //   title: includeBins ? 'Artikel' : 'Artikel aller Lagerplätze'
            // }
          ].filter(Boolean);
        },
        error: (error) => {
          console.log('error', error);
        }
      }
    )
  }

  ngOnInit(): void {
    this.warehouseId = parseInt(this.route.snapshot.paramMap.get('id'));
    this.storageBinId = parseInt(this.route.snapshot.paramMap.get('storage_bin_id'));
    this.articleType = this.route.snapshot.paramMap.get('article_type');
    if(!this.articleTypes.includes(this.articleType)) {
      this.router.navigateByUrl('/');
    }
    this.loadWareHouse();
    this.loadArticleTemplates();

    this.detailCellRendererParams = {
      warehouseId: this.warehouseId,
      isBadPart: this.articleType === 'bad-parts',
      detailGridOptions: {
        columnDefs: [
          {
            field: "bin",
            headerName: 'Lagerplatz'
        },
        {
            field: "rma",
            headerName: "RMA"
        },
        {
            field: "serial_number",
            headerName: "Seriennummer"
        },
        {
            field: 'actions',
            headerName: '',
            cellRenderer: ({data}) => {
                return `<div class="d-flex gap-2">
                        <a class='g-link' target="_BLANK" href="/a/artikel/${data.id}/edit">
                          <i class="fa fa-pencil ml4 mr4"></i>
                        </a>
                        <a class='g-link'onclick="return confirm('Are you sure you want to delete this item?');" target="_BLANK" href="/warehouse/warehouse/${this.warehouseId}/${data.id}/deleteArticle">
                          <i class="fas fa-trash-alt ml4 mr4"></i>
                        </a>
                    </div>`
            }
        }
        ],
        defaultColDef: {
          flex: 1,
        },
      },
      getDetailRowData: (params: any) => {
        const binId = parseInt(`${this.storageBinId}`);
        const storageBinId = !isNaN(binId) && binId > 0 ? binId : null;
        const badPartsArg: any = {}
        if(this.articleType === this.BAD_PARTS) {
          badPartsArg.bad_parts = true;
        } else if(this.articleType === this.ARTICLES) {
          badPartsArg.bad_parts = false;
        }
        this.warehouseService.loadRmas(
          this.warehouseId,
          params.data.id,
          {
            ...(storageBinId ? { storage_bin_id: storageBinId } : {}),
            ...badPartsArg
          }
        ).subscribe(
          {
            next: (response: any) => {
              params.successCallback(response.articles);
            },
            error: (error) => {
              console.log('error', error);
              params.successCallback([]);
            }
          }
        )
      },
    }
  }
}