import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, FormsModule } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AG_GRID_LOCALE_DE } from 'src/app/_helpers/aggrid.locale.de';
import { DeliveryNote } from 'src/app/_models';
import { DeliveryNoteService, WarehouseService } from 'src/app/_services';
import { CountryService } from 'src/app/_services/country.service';
import { CommonModule } from "@angular/common";
import { ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatDatepickerModule } from '@angular/material/datepicker';
import { AddressFormComponent } from '../_shared/address-form/address-form.component';
import { MatButtonModule } from '@angular/material/button';
import { MatNativeDateModule } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@Component({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatCheckboxModule,
    MatButtonModule,
    FontAwesomeModule,
    RouterModule,
    AddressFormComponent,
  ],
    selector: 'hawk-delivery-note-pickup',
    templateUrl: './delivery-note-pickup.component.html',
    styleUrls: ['./delivery-note-pickup.component.scss'],
})
export class DeliveryNotePickupComponent implements OnInit {
  constructor(
    private deliveryNoteService: DeliveryNoteService,
    private route: ActivatedRoute,
    private router: Router,
    private warehouseService: WarehouseService,
    private countryService: CountryService,
    private fb: FormBuilder
  ) { }
  public countries: any[];
  public locale = AG_GRID_LOCALE_DE;
  public deliveryNoteId: number | String | string;
  public deliveryNote: DeliveryNote;
  public submitting: boolean = false;
  public deliveryNotePickup: FormGroup;
  public formReady: boolean = false;

  public savePickup() {
    const { other_deliveries, ...formData } = this.deliveryNotePickup.value;
    const selectedDeliveries = other_deliveries.filter((d: any) => (d.is_checked));
    this.submitting = true;
    this.deliveryNoteService.savePickup(
      this.deliveryNoteId,
      {
        ...formData,
        other_deliveries: selectedDeliveries.map((d: any) => (d.id))
      }
    ).subscribe(
      {
        next: () => {
          this.router.navigate(['lieferscheine', this.deliveryNoteId]);
        },
        error: (error) => {
          alert(`Errors: ${error}`);
          console.log('error', error);
          this.submitting = false;
        }
      }
    )
  }

  ngOnInit(): void {
    this.loadCountries();
    this.deliveryNoteId = parseInt(this.route.snapshot.paramMap.get('id'));
    this.loadDeliveryNote();
  }

  private loadDeliveryNote() {
    this.deliveryNoteService.show(this.deliveryNoteId).subscribe(
      {
        next: (response: DeliveryNote) => {
          this.deliveryNote = response;
          // In case delivery note has it's own warehouse.
          this.loadOtherDeliveries();
        },
        error: (error) => {
          console.log('error', error);
        }
      }
    )
  }
  private loadOtherDeliveries() {
    this.deliveryNoteService.otherDeliveries(this.deliveryNoteId).subscribe(
      {
        next: (response: DeliveryNote[]) => {
          this.initializeForm(response);
        },
        error: (error) => {
          console.log('error', error);
        }
      }
    );
  }

  private initializeForm(otherDeliveries: DeliveryNote[]) {
    this.deliveryNotePickup = new FormGroup({
      sender_address_city: new FormControl(this.deliveryNote.sender_address_city),
      sender_address_company: new FormControl(this.deliveryNote.sender_address_company),
      sender_address_country: new FormControl(this.deliveryNote.sender_address_country || 'deu'),
      sender_address_firstname: new FormControl(this.deliveryNote.sender_address_firstname),
      sender_address_lastname: new FormControl(this.deliveryNote.sender_address_lastname),
      sender_address_state: new FormControl(this.deliveryNote.sender_address_state),
      sender_address_street: new FormControl(this.deliveryNote.sender_address_street),
      sender_address_street_no: new FormControl(this.deliveryNote.sender_address_street_no),
      sender_address_zip: new FormControl(this.deliveryNote.sender_address_zip),
      pickup_day: new FormControl(new Date()),
      pickup_start: new FormControl('09:00'),
      pickup_end: new FormControl('17:00'),
      test: new FormControl(false),
      phone: new FormControl('043492469810'),
      other_deliveries: this.fb.array(
        otherDeliveries.map((d) => (new FormGroup(
          {
            id: new FormControl(d.id),
            number: new FormControl(d.number),
            is_checked: new FormControl(false)
          }
        )))
      )
    });
    this.formReady = true;
  }

  private loadCountries() {
    this.countryService.list().subscribe(
      {
        next: (countries) => {
          this.countries = countries;
        },
        error: (error) => {
          console.log('error', error);
        }
      }
    )
  }

  get otherDeliveries() {
    return this.deliveryNotePickup.get('other_deliveries') as FormArray;
  }

}