import { CommonModule } from "@angular/common";

import { Component, Input, OnInit } from '@angular/core'
import { Observable, Subscription } from 'rxjs'
import Utils from 'src/app/_helpers/utils'
import { Operation, StoreUnit, Ticket, OperationMetadata, OperationMetadataStep } from 'src/app/_models'
import { StoreUnitImage, StoreUnitImageCompare, StoreUnitScaleCalibration } from 'src/app/_models/store_unit.interface'
import { StoreService } from 'src/app/_services'

@Component({
  imports: [CommonModule],
    selector: 'hawk-store-subbox',
    templateUrl: './store-subbox.component.html',
    styleUrls: ['./store-subbox.component.scss'],
})
export class StoreSubboxComponent implements OnInit {
  @Input() operation: Operation
  @Input() ticket: Ticket
  @Input() selectedStep: OperationMetadata
  @Input() resetStoreUnit: Observable<void>

  public storeUnits: StoreUnit[] = []
  public selectedStoreUnit: StoreUnit
  public tempStoreUnit:StoreUnit
  public storeUnitSelection = false

  public message: string = ''

  private subscriptions: Subscription[] = []

  constructor(
    private storeService: StoreService,
  ) { }

  ngOnInit(): void {
    this.getUnits()
    this.subscriptions.push(this.resetStoreUnit.subscribe(() => this.unselectStoreUnit()))
  }

  public getUnits() {
    this.storeService.getUnits(this.ticket.store_id).subscribe(
      (res) => {
        this.storeUnits = res

        this.storeUnits.forEach((storeUnit) => {
          if(storeUnit.label === Utils.cleanupString(this.selectedStep.name))
            this.selectStoreUnit(storeUnit)
        })
      },
      (err) => {
        console.error(err)
      }
    )
  }

  public selectStoreUnit(storeUnit: StoreUnit) {
    this.selectedStoreUnit = storeUnit
    this.tempStoreUnit = undefined
    this.storeUnitSelection = false
  }

  public unselectStoreUnit() {
    this.selectedStoreUnit = undefined
    this.tempStoreUnit = undefined
    this.storeUnitSelection = false
  }

  public debugggg(selectedStep) {
    console.log(selectedStep)
  }

  public toggleUnitSelection() {
    this.storeUnitSelection = !this.storeUnitSelection
  }

  public createUnit() {
    let unit = this.emptyUnit()
    unit.label = 'unbenannte Einheit'

    this.selectStoreUnit(unit)
  }

  public emptyUnit(): StoreUnit {
    let scaleCalibrations: StoreUnitScaleCalibration[] = []
    return {
        store_id: this.ticket.store_id,
        label: undefined,
        unit_type: undefined,
        checkout: {},
        scanner: {},
        scale: {},
        phone: {},
        other: {},
        additional_information: {
          scale_calibrations: scaleCalibrations
        },
        images: []
      }
  }

  public unsetTempStore() {
    this.tempStoreUnit = undefined
  }

  public transformInput() {
    const copy = Utils.deepCopy(this.selectedStoreUnit)
    let tmp = this.emptyUnit()

    Object.keys(tmp).forEach(key => {
      if(!!copy[key]) {
        tmp[key] = copy[key]
      }
    })

    this.tempStoreUnit = tmp

    this.selectedStep.steps.forEach(element => {
      this.inputSingleStep(this.tempStoreUnit, element)
    });

    this.tempStoreUnit.label = Utils.cleanupString(this.selectedStep.name)
  }

  public inputSingleStep(unit: StoreUnit, info: OperationMetadataStep) {
    switch (info.stepId) {
      case 'kassenplatz.bezeichnung':
        unit.unit_type = Utils.cleanupString(info.value).toLowerCase()
        break;
      case 'kassenplatz.kassennummer':
        unit.unit_id = info.value
        break;
      case 'kassenplatz.kassenhersteller':
        unit.checkout.vendor = Utils.cleanupString(info.value)
        break;
      case 'kassenplatz.kassentyp':
        unit.checkout.checkout_type = Utils.cleanupString(info.value)
        break;
      case 'kassenplatz.tischscanner':
        unit.scanner.vendor = Utils.cleanupString(info.value)
        break;
      case 'kassenplatz.tischscannertyp':
        unit.scanner.scanner_type = Utils.cleanupString(info.value)
        break;
      case 'kassenplatz.tischscannerseriennummer':
        unit.scanner.sn = Utils.cleanupString(info.value)
        break;
      case 'telefonanlage.hersteller':
        unit.phone.vendor = Utils.cleanupString(info.value)
        break;
      case 'telefonanlage.typ':
        unit.phone.ps_type = Utils.cleanupString(info.value)
        break;
      case 'telefonanlage.Seriennummer':
      case 'telefonanlage.seriennummer':
        unit.phone.sn = Utils.cleanupString(info.value)
        break;
      case 'kassenplatz.waagenhersteller':
      case 'bedienwaage.oug.hersteller':
      case 'bedienwaage.fleisch.hersteller':
      case 'bedienwaage.kaese.hersteller':
      case 'bedienwaage.leergut.hersteller':
        unit.scale.vendor = Utils.cleanupString(info.value)
        break;
      case 'kassenplatz.typ':
      case 'bedienwaage.oug.typ':
      case 'bedienwaage.fleisch.typ':
      case 'bedienwaage.kaese.typ':
      case 'bedienwaage.leergut.typ':
        unit.scale.scale_type = Utils.cleanupString(info.value)
        break;
      case 'kassenplatz.seriennummer':
      case 'bedienwaage.oug.seriennummer':
      case 'bedienwaage.fleisch.seriennummer':
      case 'bedienwaage.kaese.seriennummer':
      case 'bedienwaage.leergut.seriennummer':
        unit.scale.sn = Utils.cleanupString(info.value)
        break;
      case 'kassenplatz.kassenseriennummer':
        unit.scale.sn = Utils.cleanupString(info.value)
        break;
      case 'kassenplatz.eichung.vorhanden':
      case 'bedienwaage.oug.eichsiegel':
      case 'bedienwaage.fleisch.eichsiegel':
      case 'bedienwaage.kaese.eichsiegel':
      case 'bedienwaage.leergut.eichsiegel':
        unit.scale.has_calibration_mark = info.value
        break;
      case 'kassenplatz.ersteichung':
      case 'bedienwaage.oug.ersteichung':
      case 'bedienwaage.fleisch.ersteichung':
      case 'bedienwaage.kaese.ersteichung':
      case 'bedienwaage.leergut.ersteichung':
        unit.additional_information.scale_calibrations.push({
          year: this.extractCalibrationYear(info.value),
          calibration_type: 'M'
        })
        break;
      case 'kassenplatz.eichung':
      case 'bedienwaage.oug.eichung':
      case 'bedienwaage.fleisch.eichung':
      case 'bedienwaage.kaese.eichung':
      case 'bedienwaage.leergut.eichung':
        const calibrationYear = this.extractCalibrationYear(info.value)
        unit.last_calibration = calibrationYear
        unit.additional_information.scale_calibrations.push({
          year: calibrationYear,
          calibration_type: 'Eichamt'
        })
        break;
      case 'kassenplatz.bildkassentyp':
      case 'kassenplatz.tischscannerschild':
      case 'kassenplatz.waagetypenschild.eichung':
      case 'kassenplatz.waagetypenschild':
      case 'bedienwaage.oug.typenschild':
      case 'bedienwaage.oug.bild.siegel':
      case 'bedienwaage.oug.bild.komplett':
      case 'bedienwaage.fleisch.typenschild':
      case 'bedienwaage.fleisch.bild.siegel':
      case 'bedienwaage.fleisch.bild.komplett':
      case 'bedienwaage.kaese.typenschild':
      case 'bedienwaage.kaese.bild.siegel':
      case 'bedienwaage.kaese.bild.komplett':
      case 'bedienwaage.leergut.typenschild':
      case 'bedienwaage.leergut.bild.siegel':
      case 'bedienwaage.leergut.bild.komplett':
      case 'telefonanlage.bild':
      case 'telefonanlage.bild.typenschild':
      case 'serverraum.bild.schrank':
      case 'serverraum.bild.schrank.open':
      case 'serverraum.frei.he':
      case 'serverraum.bild.schrank.links':
      case 'serverraum.bild.schrank.rechts':
      case 'serverraum.bild.dreisechsig':
        if(info && info.name && info.value) {
          const img = {
            key: info.stepId,
            title: Utils.cleanupString(info.name),
            path: Utils.metadataImagePath(info.value)
          }

          unit.images.push(img)
        } else {
          console.error('inputSingleStep', 'parseImage', info)
        }
        break;
    }

    if(info.stepId.match(/telefonanlage./)) {
      unit.unit_type = 'phone'
      unit.unit_id = '0'
    }

    if(info.stepId.match(/serverraum./)) {
      unit.unit_type = 'server'
      unit.unit_id = '0'
    }
  }

  public docuHasNoValue(mode: string, key: string): boolean {
    if(mode === 'global') {
      return !this.selectedStoreUnit || (this.selectedStoreUnit && !this.docuGetStoreUnitValue(mode,key))
    } else {
      return !this.selectedStoreUnit[mode] || (this.selectedStoreUnit[mode] && !this.docuGetStoreUnitValue(mode,key))
    }
  }

  public docuValueDiffers(mode: string, key: string): boolean {
    if(mode === 'global') {
      return this.selectedStoreUnit && this.tempStoreUnit &&
               (this.docuGetStoreUnitValue(mode,key) !== this.docuGetTmpStoreUnitValue(mode,key))
    } else {
      return (this.selectedStoreUnit && this.tempStoreUnit &&
              this.selectedStoreUnit[mode] && this.tempStoreUnit[mode] &&
              this.docuGetStoreUnitValue(mode,key) && this.docuGetTmpStoreUnitValue(mode,key) &&
              (this.docuGetStoreUnitValue(mode,key) !== this.docuGetTmpStoreUnitValue(mode,key)))
              || (this.tempStoreUnit && !!this.docuGetTmpStoreUnitValue(mode,key))
    }
  }

  public docuGetStoreUnitValue(mode:string, key:string): string {
    if(mode === 'global') {
      return this.selectedStoreUnit[key]
    } else {
      return this.selectedStoreUnit[mode][key]
    }
  }
  public docuGetTmpStoreUnitValue(mode:string, key:string): string {
    if(mode === 'global') {
      return this.tempStoreUnit[key]
    } else {
      return this.tempStoreUnit[mode][key]
    }
  }

  public extractCalibrationYear(info: string): string {
    if(!info) {
      return
    }
    info = info.toUpperCase()

    let match_manufacturer = info.match(/M(?<year>\d{2})/)
    if(match_manufacturer) {
      return ['20', match_manufacturer.groups['year']].join('')
    }
    let match_other = info.match(/(?<year>\d{4})/)
    if(match_other) {
      return match_other.groups['year']
    }
    let match_other_short = info.match(/(?<year>\d{2})/)
    if(match_other_short) {
      return ['20', match_other_short.groups['year']].join('')
    }
  }

  public combineImages(): StoreUnitImageCompare[] {
    let hash: {[key: string]: StoreUnitImageCompare} = {}

    if(this.selectedStoreUnit && this.selectedStoreUnit.images) {
      this.selectedStoreUnit.images.forEach((image: StoreUnitImage) => {
        hash[image.key] = {
          title: image.title,
          key: image.key,
          current_path: image.path
        }
      })
    }

    if(this.tempStoreUnit && this.tempStoreUnit.images) {
      this.tempStoreUnit.images.forEach((image: StoreUnitImage) => {
        if(hash[image.key]) {
          hash[image.key].new_path = image.path
        } else {
          hash[image.key] = {
            title: image.title,
            key: image.key,
            new_path: image.path
          }
        }
      })
    }

    return Object.values(hash)
  }

 public translateCalibrationType(calString: string): string {
   if(calString === 'M') {
     return 'Hersteller'
   }

   return calString
 }

 public saveToServer() {
  this.storeService.createUpdateUnit(this.tempStoreUnit).subscribe(
    (res) => {
      this.selectStoreUnit(res)
      this.storeUnits.push(res)
      this.unsetTempStore()
      this.message = "Erfolgreich gespeichert."
    },
    (err) => {
      console.error(err)
    }
  )
 }

}