
import { MapRoute } from "../../../_models/planning-assistant.interface";
import { MapContainer } from "../map/pa-map";
import * as mapboxgl from "mapbox-gl";
import { LineLayerContainer } from "../classes/tour-animation";
import { PAUtil } from "../classes/util";
import { PATechnician } from "../classes/technician";
import { PATourPlannerControl } from "./pa-tourplanner-control";

export class PAMapboxControl {

  private static _instance: PAMapboxControl
  public static get Instance()
  {
    return this._instance || (this._instance = new this());
  }

  private constructor(
  ) {
  }

  async generateLineLayers(
    routes: MapRoute[],
    map_container: MapContainer,
    config?: {
      use_exact_waypoints: boolean;
      color?: string;
      listeners?: {
        on_mouse_out: (id: string) => void;
        on_mouse_over: (ev: mapboxgl.MapMouseEvent & {
          features?: mapboxgl.MapboxGeoJSONFeature[] | undefined
        } & mapboxgl.EventData, id: string) => void
      };
      prefix: string
    }
  ): Promise<LineLayerContainer[]> {
    let promises: Promise<number[][]>[] = []
    for (let route of routes) {
      promises.push(this.getRouteCoordinates(route, config?.use_exact_waypoints))
    }

    let line_layers: LineLayerContainer[] = []
    for (let promise of promises) {
      let route = routes[promises.indexOf(promise)]
      let coords = await promise
      if (coords) {
        let line_layer = this.addRouteToMap(
          coords,
          this.routeToID(route, 'route_' + (config?.prefix || '')),
          config?.color || route.color,
          route.line_width,
          route.dashed,
          route.popup_text,
          map_container,
          config?.listeners
        )
        if (line_layer) line_layers.push(line_layer)
      }
    }

    this.moveCustomLabelLayersUp(map_container)

    return line_layers
  }

  public moveCustomLabelLayersUp(map_container: MapContainer): void {
    for (let layer of map_container.map.getStyle().layers) {
      if (layer.id.includes('clabel')) {
        map_container.map.moveLayer(layer.id)
      }
    }
  }

  public addRouteToMap(
    coordinates: number[][],
    id: string, color: string,
    line_width: number,
    dashed: boolean,
    popup_text: string,
    map_container: MapContainer,
    listeners?: {
      on_mouse_over?: (ev: mapboxgl.MapMouseEvent & {
        features?: mapboxgl.MapboxGeoJSONFeature[] | undefined
      } & mapboxgl.EventData, id: string) => void,
      on_mouse_out?: (id: string) => void
    }
  ): LineLayerContainer {

    if (!map_container.map.getSource(id + '_source')) {
      map_container.map.addSource(id + '_source', {
        'type': 'geojson',
        'lineMetrics': true,
        'data': {
          'type': 'Feature',
          "properties": {},
          'geometry': {
            'type': 'LineString',
            'coordinates': coordinates
          }
        }
      });
    }

    let layer = map_container.map.getLayer(id + '_layer')

    if (!layer) {
      map_container.map.addLayer(
        {
          'id': id + '_layer',
          'type': 'line',
          'source': id + '_source',
          'layout': {
            'line-join': 'round',
            'line-cap': 'round',
          },
          'paint': dashed ? {
              'line-color': color,
              "line-width": line_width,
              "line-dasharray": [0.2, 2],
            } :
            {
              'line-color': color,
              "line-width": line_width,
              'line-opacity': .75
            }
        }
      )
    }

    layer = map_container.map.getLayer(id + '_layer')

    if (popup_text) {
      if (listeners?.on_mouse_over) {
        map_container.map.on('mouseover', id + '_layer',  (e) => {
          listeners.on_mouse_over(e, id)
        })
      }
      if (listeners?.on_mouse_out) {
        map_container.map.on('mouseout', id + '_layer', (_) => {
          listeners.on_mouse_out(id)
        })
      }
    }

    if (layer.type == 'line') return {layer: layer, color: color, line_length: this.coordinatesLineLength(coordinates), line_width: line_width}
    else return null
  }

  coordinatesLineLength(coordinates: number[][]): number {
    return coordinates.reduce((sum, coordinate: number[]) => {
      let idx = coordinates.indexOf(coordinate)
      if (idx < coordinates.length - 1) {
        let next_coordinate = coordinates[idx + 1]
        let lon_dif = next_coordinate[0] - coordinate[0]
        let lat_dif = next_coordinate[1] - coordinate[1]
        return sum + Math.sqrt(lon_dif * lon_dif + lat_dif * lat_dif)
      } else {
        return sum
      }
    },  0)
  }

  async getRouteCoordinates(route: MapRoute, use_exact_waypoints?: boolean): Promise<number[][]> {
    let coordinates: number[][]
    let id = this.routeToID(route, 'route')

    if (!PAUtil.compareObjects(route.from, route.to)) {
      if (use_exact_waypoints) {
        if (typeof localStorage[id] != 'undefined'){
          coordinates = this.parseCoordinatesString(localStorage[id])
        } else {
          coordinates = await this.getRouteWithExactWaypoints(route)
          localStorage[id] = this.coordinateListToString(coordinates)
        }
      } else {
        coordinates = [[route.from.coordinates.longitude, route.from.coordinates.latitude],[route.to.coordinates.longitude, route.to.coordinates.latitude]]
      }
    } else {
      coordinates = null
    }

    return coordinates
  }

  routeToID(route: MapRoute, prefix?: string): string {
    return `${prefix ? prefix : ''}${route.from.coordinates.latitude},${route.from.coordinates.longitude};${route.to.coordinates.latitude},${route.to.coordinates.longitude}:${route.color}`
  }

  public parseCoordinatesString(coordinates: string): number[][] {
    let res = []
    let coordinate_pairs = coordinates.split(';')
    for (let coordinate of coordinate_pairs) {
      res.push(coordinate.split(',').map(coordinate => Number.parseFloat(coordinate)))
    }
    return res
  }

  public coordinateListToString(coordinates: number[][]): string {
    let res = ''
    for (let coordinate of coordinates) {
      res = res + coordinate[0].toString() + ',' + coordinate[1].toString()
      if (coordinates.indexOf(coordinate) != coordinates.length - 1){
        res = res + ';'
      }
    }
    return res
  }

  async getRouteWithExactWaypoints(route: MapRoute): Promise<number[][]> {
    let mb_route = await route.from.getDistanceToLocation(route.to)
    return mb_route.geometry.coordinates
  }

  public deleteUUIDLayers(uuid: string, map_container: MapContainer){
    if (uuid) {
      try {
        map_container.map?.getStyle().layers?.forEach(layer => {
          if (layer.id.includes(uuid) && layer.type == 'line' && typeof layer.source == 'string') {
            map_container.map.removeLayer(layer.id)
            map_container.map.removeSource(layer.source)
          }
        })
      } catch (err) {
        console.log(err)
      }
    }
  }

  public getMapLngLatBounds(config?: {ignore_operations?: boolean, ignore_technicians?: boolean, technicians?: PATechnician[]}): mapboxgl.LngLatBounds {
    let bounds = new mapboxgl.LngLatBounds();

    (!config?.ignore_operations) ? PATourPlannerControl.Instance.shownMapOperations.map(operation => bounds.extend([operation.ticket.coordinates.longitude, operation.ticket.coordinates.latitude])) : {};
    (!config?.ignore_technicians) ? (config?.technicians || PATourPlannerControl.Instance.shownMapTechnicians).map(technician => bounds.extend([technician.coordinates.longitude, technician.coordinates.latitude])) : {};

    return bounds
  }
}