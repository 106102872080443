<div style="width: 100%; text-align: center; border: 1px solid black; border-radius: 2px; padding: 2px 4px; display: inline-flex; flex-direction: row;" [ngStyle]="{'height': height, 'font-size': fontSize, 'color': fontColor}" class="black_gradient_background" (click)="click()"> 
  <div style="flex: 1; display: inline-flex; height: 100%;">
    <div style="margin: auto;" [ngStyle]="{'margin-left': leftAligned ? '4px' : 'auto'}">
      {{description}}
    </div>
  </div>
  <div style="float: right;">
    <div *ngIf="isExpanded" style="display: inline-flex; height: 100%;">
      <div style="margin: auto;">
        <fa-icon style="margin: auto; padding: 0 4px;" [ngStyle]="{'font-size': fontSize}" [icon]="faChevronUp"></fa-icon>
      </div>
    </div>
    <div *ngIf="!isExpanded" style="display: inline-flex; height: 100%;">
      <div style="margin: auto;">
        <fa-icon style="margin: auto; padding: 0 4px;" [ngStyle]="{'font-size': fontSize}" [icon]="faChevronDown"></fa-icon>
      </div>
    </div>
  </div>
</div>