import { CommonModule } from "@angular/common";
import { FaIconComponent, FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { QmJob } from 'src/app/_models'
import { QmJobService } from 'src/app/_services'
import { QmJobTypePipe } from "src/app/_pipes";

@Component({
  imports: [CommonModule, FontAwesomeModule, QmJobTypePipe],
    selector: 'hawk-qm-page-table',
    templateUrl: './qm-page-table.component.html',
    styleUrls: ['./qm-page-table.component.scss'],
})
export class QmPageTableComponent implements OnInit {

  @Input() jobs: QmJob[]
  @Input() jobType: string
  @Output() jobUpdated = new EventEmitter<QmJob>()

  public title = {
    bod: 'Kontrollanruf Tagesbeginn',
    not: 'Kontrollanruf Terminuntreue',
    tld: 'Kontrollanruf Abfahrt (zu lange Fahrtzeit)',
    jnd: 'Kontrolle gestern nicht erledigter Jobs',
    tps: 'Kontrolle Jobs zu Projektbeginn oder Pause',
    tlw: 'Check Überschreitung kalkulierte Arbeitszeit',
    tpe: 'Kontrolle Jobs Projektende',
  }

  constructor(
    private qmJobController: QmJobService,
  ) { }

  ngOnInit(): void {
  }

  public setDone(id: number) {
    this.qmJobController.setDone(id).subscribe(
      (data) => {
        this.jobUpdated.emit(data)
      },
      (err) => {
        console.error(err)
      },
    )
  }

  public setLock(id: number) {
    this.qmJobController.setLocked(id).subscribe(
      (data) => {
        this.jobUpdated.emit(data)
      },
      (err) => {
        console.error(err)
      },
    )
  }

  public setUnlock(id: number) {
    this.qmJobController.setUnlocked(id).subscribe(
      (data) => {
        this.jobUpdated.emit(data)
      },
      (err) => {
        console.error(err)
      },
    )
  }

  public setFollowUp(id: number) {
    this.qmJobController.setFollowUp(id).subscribe(
      (data) => {
        this.jobUpdated.emit(data)
      },
      (err) => {
        console.error(err)
      },
    )
  }

  public setDelete(id: number) {
    this.qmJobController.setDeleted(id).subscribe(
      (data) => {
        this.jobUpdated.emit()
      },
      (err) => {
        console.error(err)
      },
    )
  }

  public getTitle(): string {
    return this.title[this.jobType]
  }

  public isLocked(job: QmJob): boolean {
    return job.status === 'locked'
  }

  public showCreated(): boolean {
    return true  // this.jobType !== 'bod'
  }

  public showTravelstart(): boolean {
    return this.jobType === 'bod'
  }
}