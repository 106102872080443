
<div style="display: inline-flex; flex: 1;">
  <div #innerWrapper style="width: 100%; height: 100%; overflow: auto;">
    <ng-container *cdkPortal>
      <div id="popped_out_container"></div>
    </ng-container>
    <div id="popped_in_container" style="height: 100%;">
      <div id="popout_content" style="width: 100%; height: 100%">
        <ng-content></ng-content>
        <ng-container *ngIf="isPoppedOut">
          <button class="fullscreen_button black_gradient_background" style="position: absolute; z-index: 20; top: 0; right: 0; padding: 2px 4px;" (click)="toggleExternalFullScreen()">
            <fa-icon *ngIf="isDocumentInFullScreenMode()" [icon]="faCompress"></fa-icon>
            <fa-icon *ngIf="!isDocumentInFullScreenMode()" [icon]="faExpand"></fa-icon>
          </button>
        </ng-container>
        <div class="custom-cdk-overlay-container"></div>
      </div>
    </div>
  </div>
</div>
