import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormsModule, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DeliveryNote } from 'src/app/_models';
import { DeliveryNoteService } from 'src/app/_services';
import { CommonModule } from "@angular/common";
import { ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { NgSelectModule } from "@ng-select/ng-select";
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatButtonModule } from '@angular/material/button';
import { MatNativeDateModule } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';

@Component({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatButtonModule,
    RouterModule, 
    NgbDropdownModule, 
    FontAwesomeModule, 
    NgSelectModule 
  ],
    selector: 'hawk-delivery-note-shipping',
    templateUrl: './delivery-note-shipping.component.html',
    styleUrls: ['./delivery-note-shipping.component.scss'],
})
export class DeliveryNoteShippingComponent implements OnInit {
  constructor(
    private deliveryNoteService: DeliveryNoteService,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  public deliveryNoteId: number | String | string;
  public loadingPricing: boolean = false;
  public submittingLabel: boolean = false;
  public deliveryNote: DeliveryNote;
  public searchTerm: string = ''
  //angel_de, asendia, cargo_international, dhl, dhl_express, dpag, dpd, gls, go, hermes, iloxx, parcel_one, ups
  public carriersList: any[] = ["DHL", "dhl_express", "DPD", "UPS", "GO"]
  public shippingServices: any[] = [
    { id: "standard", name: "Standard" },
    { id: "one_day", name: "Express (1 day)" },
    { id: "one_day_early", name: "Express (1 day early)" }
  ]

  public deliveryNoteShippingForm: FormGroup;

  public calculatePrirce() {
    const formData = this.deliveryNoteShippingForm.value;
    const data = {
      width: formData.width,
      height: formData.height,
      length: formData.length,
      weight: formData.weight,
      service: formData.service,
      carrier: formData.carrier
    }
    if(!Object.values(data).every((v) => (v))) {
      let missingKey = ''
      if(!data.width) missingKey = '#width'
      if(!data.height) missingKey = '#height'
      if(!data.length) missingKey = '#length'
      if(!data.weight) missingKey = '#weighth'
      if(!data.service) missingKey = '#service'
      if(!data.carrier) missingKey = '#carrier'

      alert(`Das Feld '${missingKey}' muss ausgefüllt sein`);
    } else {
      this.loadingPricing = true;
      this.deliveryNoteService.calculateShippingPrice(this.deliveryNoteId, data).subscribe(
        {
          next: (response) => {
            const element = document.getElementById('price_element');
            let price = null;
            if(response.success) {
              price = response.price;
            } else {
              price = 'errors';
              alert(`error: ${response.message}`);
            }
            if(element) element.innerText = `${price}`;
            this.loadingPricing = false;
          },
          error: (error) => {
            alert(`error: ${error}`);
            const element = document.getElementById('price_element');
            if(element) element.innerText = `errors`;
            this.loadingPricing = false;
          }
        }
      )
    }
  }

  public createShipping() {
    const formData = this.deliveryNoteShippingForm.value;
    this.submittingLabel = true;
    this.deliveryNoteService.createShipping(this.deliveryNoteId, formData).subscribe(
      {
        next: () => {
          this.submittingLabel = false;
          this.router.navigate(['/', 'lieferscheine', this.deliveryNote.id]);
        },
        error: (error) => {
          alert(`Errors: ${error}`);
          this.submittingLabel = false;
        }
      }
    )
  }

  ngOnInit(): void {
    this.deliveryNoteId = parseInt(this.route.snapshot.paramMap.get('id'));
    this.loadDeliveryNote();
  }
  private loadDeliveryNote() {
    this.deliveryNoteService.show(this.deliveryNoteId).subscribe(
      {
        next: (response: DeliveryNote) => {
          this.deliveryNote = response;
          this.deliveryNoteShippingForm = new FormGroup({
            width: new FormControl(response.parcel_width),
            height: new FormControl(response.parcel_height),
            length: new FormControl(response.parcel_length),
            weight: new FormControl(response.parcel_weight),
            carrier: new FormControl(),
            service: new FormControl(),
            sender_phone: new FormControl(response.sender_phone),
            receiver_phone: new FormControl(response.receiver_phone),
            email: new FormControl(response.shipping_notification_email),
            description: new FormControl(response.parcel_description),
            direct_pickup: new FormControl(false),
            pickup_day: new FormControl(new Date()),
            pickup_start: new FormControl('09:00'),
            pickup_end: new FormControl('17:00'),
            saturday_delivery: new FormControl(false),
            test: new FormControl(false)
          });
        },
        error: (error) => {
          console.log('error', error);
        }
      }
    )
  }

}