<div class="marker_root" *ngIf="technicianLivePosition.active">
  <div class="marker hover_parent" [style.background]="conicBackground || 'rgb(68, 68, 68)'">
    <div class="icon_background">
      <fa-icon *ngIf="technicianLivePosition.current_action == 'driving'" [icon]="faCar"></fa-icon>
      <fa-icon *ngIf="technicianLivePosition.current_action == 'finished'" [icon]="faCheck"></fa-icon>
      <fa-icon *ngIf="technicianLivePosition.current_action == 'working'" [icon]="faWrench"></fa-icon>
      <fa-icon *ngIf="technicianLivePosition.current_action == 'home_end'" [icon]="faHome"></fa-icon>
      <fa-icon *ngIf="technicianLivePosition.current_action == 'home_start'" [icon]="faHome"></fa-icon>
    </div>
    <div class="marker_content black_gradient_background_no_hover show_on_parent_hover vertical_flex">
      <h6 class="horizontal_flex">
        Aktueller Status:
        <ng-container *ngIf="technicianLivePosition.current_action == 'home_start'">Tour Start</ng-container>
        <ng-container *ngIf="technicianLivePosition.current_action == 'working'">Auftrag bearbeiten</ng-container>
        <ng-container *ngIf="technicianLivePosition.current_action == 'home_end'">Tour Ende</ng-container>
        <ng-container *ngIf="technicianLivePosition.current_action == 'driving'">Fahrt</ng-container>
        <ng-container *ngIf="technicianLivePosition.current_action == 'finished'">Auftrag erledigt</ng-container>
      </h6>
      <div>
        <b>Ab:</b> {{PATimeControl.Instance.minutesToTimeString(technicianLivePosition.action_start.time_minutes)}}Uhr <i>({{technicianLivePosition.action_start.is_estimated ? 'Geschätzt' : 'Bestätigt'}})</i>
      </div>
      <div *ngIf="technicianLivePosition.action_end">
        <b>Bis:</b> {{PATimeControl.Instance.minutesToTimeString(technicianLivePosition.action_end.time_minutes)}}Uhr <i>(Geschätzt)</i>
      </div>
    </div>
  </div>
</div>
