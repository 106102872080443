import { Component, ElementRef, inject, OnInit, ViewChild } from '@angular/core';
import { APCustomer, APCustomerProject } from "../../_models/customer.interface";
import { CustomerService } from "../../_services";
import { ActivatedRoute, RouterLink } from "@angular/router";
import {
  faArrowRight,
  faDownload,
  faInfoCircle,
  faMailBulk,
  faPhone,
  faTrash
} from "@fortawesome/free-solid-svg-icons";
import * as XLSX from "xlsx";
import { AG_GRID_LOCALE_DE } from "../../_helpers/aggrid.locale.de";
import { ColDef, GridReadyEvent } from "ag-grid-community";
import {
  GridApi,
  RowClassRules,
  SideBarDef, SizeColumnsToContentStrategy,
  SizeColumnsToFitGridStrategy,
  SizeColumnsToFitProvidedWidthStrategy
} from "ag-grid-enterprise";
import { countryListAlpha3, countryListAlpha3German } from "../planning-assistant/data/iso-3-codes";
import { PAUtil } from "../planning-assistant/classes/util";
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from "@angular/forms";
import { APStore, StoreBulkImportParams } from "../../_models/store.interface";
import { PermittedOpeningParams } from "../../_models/opening.interface";
import { AgGridAngular } from "ag-grid-angular";
import { NgClass, NgForOf, NgIf } from "@angular/common";
import { MatError, MatFormField, MatLabel } from "@angular/material/form-field";
import { MatOption, MatSelect } from "@angular/material/select";
import { FaIconComponent } from "@fortawesome/angular-fontawesome";
import { MatProgressBar } from "@angular/material/progress-bar";
import { MatTooltip } from "@angular/material/tooltip";
import { MatButton } from "@angular/material/button";
import { MatInput } from "@angular/material/input";
import { AgGridThemeService } from "../../_services/ag-grid-theme.service";
import { RecordNavigationComponent } from "../_shared/record-navigation/record-navigation.component";

export interface StoreImportUnit {
  'Filiale': string
  'Name': string
  'Spalte1': string
  'Lastname': string
  'Straße': string
  'HausNr': string
  'PLZ': string
  'Ort': string
  'Land': string
  'Bundesland': string
  'Email': string
  'Bemerkung': string
  'Anzahl der Kassenplätze': string
  'Ip': string
  '-': string
  'phone1': string
  'phone2': string
  'Montag': string
  'Dienstag': string
  'Mittwoch': string
  'Donnerstag': string
  'Freitag': string
  'Samstag': string
  'Sonntag': string
  id?: number
}

@Component({
  selector: 'hawk-customer-import-store',
  templateUrl: './customer-import-store.component.html',
  standalone: true,
  imports: [
    AgGridAngular,
    NgClass,
    ReactiveFormsModule,
    MatFormField,
    RecordNavigationComponent,
    MatSelect,
    FormsModule,
    MatOption,
    FaIconComponent,
    RouterLink,
    MatProgressBar,
    MatTooltip,
    MatError,
    MatLabel,
    NgIf,
    MatButton,
    MatInput,
    NgForOf
  ],
  styleUrls: ['./customer-import-store.component.scss', '../_shared/styles/common-styles.scss']
})
export class CustomerImportStoreComponent implements OnInit {
  public hawkTheme = inject(AgGridThemeService).getTheme();

  protected readonly faPhone = faPhone;
  protected readonly faMailBulk = faMailBulk;
  protected readonly faTrash = faTrash;
  protected readonly faArrowRight = faArrowRight;
  protected readonly Object = Object;
  protected readonly locale = AG_GRID_LOCALE_DE;

  autoSizeStrategy: SizeColumnsToFitGridStrategy | SizeColumnsToFitProvidedWidthStrategy | SizeColumnsToContentStrategy = {
    type: "fitGridWidth",
  }

  headers = [
    'Filiale',
    'Name',
    'Spalte1',
    'Lastname',
    'Straße',
    'HausNr',
    'PLZ',
    'Ort',
    'Land',
    'Bundesland',
    'Email',
    'Bemerkung',
    'Anzahl der Kassenplätze',
    'Ip',
    '-',
    'phone1',
    'phone2',
    'Montag',
    'Dienstag',
    'Mittwoch',
    'Donnerstag',
    'Freitag',
    'Samstag',
    'Sonntag'
  ]

  countryErrorForm: FormGroup;

  selectedCountryFormat: '3_letter' | 'full_name' = '3_letter'
  headerFormat: boolean = true;
  addProjectRelations: number[];
  updateAlreadyImportedStores = true

  customer: APCustomer;
  projects: APCustomerProject[] = [];
  uploadProgress: number;
  showError: string
  showSuccess: string
  showFormatModal = false

  @ViewChild('fileInput') fileInput: ElementRef<HTMLInputElement>

  file: File;
  storeImportUnits: StoreImportUnit[] = []
  importableUnits: StoreImportUnit[] = []
  alreadyCreatedCustomerStores: APStore[] = []

  public storeColDefs: ColDef[] = [
    {
      field: "id",
      headerName: "DB-ID",
      width: 90,
      minWidth: 90,
      cellRenderer: this.idRenderer,
      cellClass: 'hover_link_cell'
    },
    {
      field: "Filiale",
      headerName: "Nr.",
      width: 130,
    },
    {
      field: "Spalte1",
      headerName: "Name",
      width: 220,
    },
    {
      field: "Name",
      headerName: "Firma",
      width: 200,
    },
    {
      field: "Lastname",
      headerName: "Nachname",
      width: 140,
    },
    {
      field: "Straße",
      headerName: "Straße",
      width: 140,
    },
    {
      field: "HausNr",
      width: 100,
      headerName: "HausNr",
    },
    {
      field: "PLZ",
      headerName: "PLZ",
      width: 120,
    },
    {
      field: "Ort",
      headerName: "Ort",
      width: 145,
    },
    {
      field: "Land",
      headerName: "Land",
      width: 90,
    },
    {
      field: "Email",
      headerName: "Email",
      width: 120,
    },
    {
      field: "Bemerkung",
      headerName: "Bemerkung",
      width: 120,
    },
    {
      field: "Anzahl der Kassenplätze",
      headerName: "Kassen",
      width: 110,
    },
    {
      field: "Ip",
      headerName: "Ip",
      width: 90,
    },
    {
      field: "phone1",
      headerName: "Telefon1",
      width: 110,
    },
    {
      field: "phone2",
      headerName: "Telefon2",
      width: 110,
    },
    {
      field: "Montag",
      headerName: "Montag",
      width: 120,
    },

    {
      field: "Dienstag",
      headerName: "Dienstag",
      width: 120,
    },

    {
      field: "Mittwoch",
      headerName: "Mittwoch",
      width: 120,
    },

    {
      field: "Donnerstag",
      headerName: "Donnerstag",
      width: 120,
    },

    {
      field: "Freitag",
      headerName: "Freitag",
      width: 120,
    },

    {
      field: "Samstag",
      headerName: "Samstag",
      width: 120,
    },

    {
      field: "Sonntag",
      headerName: "Sonntag",
      width: 120,
    },
  ]

  private storeGridApi!: GridApi;
  public rowClassRules: RowClassRules = {
    'already_imported': function(params) {
      return params.data.id >= 0
    },
  }

  public sideBarOptions: SideBarDef = {
    hiddenByDefault: false,
    toolPanels: ['columns', 'filters']
  }

  public defaultColDef: ColDef = {
    filter: true
  }

  public rowSelection: "single" | "multiple" = "single"

  constructor(
    private customerService: CustomerService,
    private route: ActivatedRoute
  ) {
  }

  incomingFile(event){
    this.file = event.target.files[0];
    this.upload()
  }

  upload() {
    let fileReader = new FileReader();
    fileReader.onload = (_) => {
      this.resetData()
      const array_buffer = fileReader.result as ArrayBuffer;
      const data = new Uint8Array(array_buffer);
      const arr = [];
      for (let i = 0; i != data.length; ++i) arr[i] = String.fromCharCode(data[i]);
      const bstr = arr.join("");
      const workbook = XLSX.read(bstr, {type:"binary"});
      const first_sheet_name = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[first_sheet_name];
      this.setStoreImportUnits(XLSX.utils.sheet_to_json(worksheet,{ raw: true, header: this.headers }).slice(this.headerFormat ? 1 : 0) as StoreImportUnit[])
    }
    if (this.file) fileReader.readAsArrayBuffer(this.file);
  }

  setStoreImportUnits(siu: StoreImportUnit[]): void {
    siu.forEach(unit => {
      const found_store = this.alreadyCreatedCustomerStores.find(store => store.nr == unit.Filiale)
      unit.id = found_store?.id
    })
    this.storeImportUnits = siu
    this.importableUnits = siu.filter(unit => !(unit.id >= 0))
    this.updateCountryErrors()
  }

  ngOnInit(): void {
    const id = this.route.snapshot.paramMap.get('id')
    this.customerService.loadAPCustomer(id).subscribe(
      data => {
        this.customer = data;
        this.customerService.loadAPCustomerStores(data.id).subscribe(
          data => {
            this.alreadyCreatedCustomerStores = data
          }
        )
      },
      error => {
        console.log(error)
      }
    )

    this.customerService.loadAPCustomerProjects(id).subscribe(
      data => {
        this.projects = data;
      },
      error => {
        console.log(error)
      }
    )
  }

  public storeQuickSearchChanged(e: Event) {
    const newValue = (e.target as HTMLInputElement).value
    this.storeGridApi.setGridOption(
      "quickFilterText",
      newValue,
    )
  }

  resetFile() {
    this.file = null
    this.fileInput.nativeElement.value = ""
    this.resetData()
  }

  resetData(): void {
    this.storeImportUnits = []
    this.countryErrorForm = null
    this.importableUnits = []
    this.uploadProgress = null;
    this.showError = null
    this.showSuccess = null
  }

  onStoreGridReady($event: GridReadyEvent<any>) {
    this.storeGridApi = $event.api
  }

  private updateCountryErrors() {

    const group = {}

    this.storeImportUnits.forEach(unit => {
      if (!group[unit.Land]) {
        const regex = '^[a-zA-Z]{3}$'
        const is_error = unit.Land && (this.selectedCountryFormat == '3_letter' && !unit.Land.match(regex)) || (this.selectedCountryFormat == 'full_name' && !(PAUtil.searchKeyByValue(countryListAlpha3German, unit.Land) || PAUtil.searchKeyByValue(countryListAlpha3, unit.Land)))
        if (is_error) group[unit.Land] = new FormControl('', [Validators.required, Validators.minLength(3), Validators.maxLength(3), Validators.pattern(regex)])
      }
    })

    this.countryErrorForm = Object.keys(group).length ? new FormGroup(group) : null
  }

  async bulkImportStores(): Promise<void> {
    const bulk_size = 32
    const total_bulk = this.generateBulkImportParams()
    const bulk_chunks = PAUtil.chunkArray<StoreBulkImportParams>(total_bulk, bulk_size)

    this.uploadProgress = 0
    for (const bulk_chunk of bulk_chunks) {
      const got_error = await new Promise<false | string>(resolve => {

        this.customerService.bulkImportStores(this.customer.id, bulk_chunk, this.addProjectRelations).subscribe(
          stores => {
            this.alreadyCreatedCustomerStores = stores
            this.storeImportUnits.forEach(unit => {
              const found_store = this.alreadyCreatedCustomerStores.find( store => store.nr == unit.Filiale)
              unit.id = found_store?.id
            })
            this.storeImportUnits = [...this.storeImportUnits]
            this.importableUnits = this.storeImportUnits.filter(unit => !(unit.id >= 0))
            resolve(false)
          },
          error => {
            console.log(error)
            resolve(error)
          }
        )
      })

      if (got_error) {
        this.showError = got_error
        break
      }

      this.uploadProgress = ((bulk_chunks.indexOf(bulk_chunk) + 1) / bulk_chunks.length) * 100
    }
    this.showSuccess = this.showError ? '' : 'Filialen erfolgreich importiert!'
  }

  private generateBulkImportParams(): StoreBulkImportParams[] {
    const used_stores = this.updateAlreadyImportedStores ? this.storeImportUnits : this.importableUnits
    return used_stores.map(unit => {
      let country: string
      if (this.countryErrorForm?.controls[unit['Land']]) {
        country = this.countryErrorForm?.controls[unit['Land']].value
      } else if (this.selectedCountryFormat == 'full_name') {
        country = PAUtil.searchKeyByValue(countryListAlpha3German, unit['Land']) || PAUtil.searchKeyByValue(countryListAlpha3, unit['Land'])
      } else {
        country = unit['Land']
      }
      country = country ? country.toLowerCase() : 'deu'

      const monday_openings = (unit['Montag']||'').split('#')
      const tuesday_openings = (unit['Dienstag']||'').split('#')
      const wednesday_openings = (unit['Mittwoch']||'').split('#')
      const thursday_openings = (unit['Donnerstag']||'').split('#')
      const friday_openings = (unit['Freitag']||'').split('#')
      const saturday_openings = (unit['Samstag']||'').split('#')
      const sunday_openings = (unit['Sonntag']||'').split('#')

      const openings: PermittedOpeningParams[] = []
      const all_openings = [monday_openings, tuesday_openings, wednesday_openings, thursday_openings, friday_openings, saturday_openings, sunday_openings]

      for (let weekday_openings of all_openings) {
        for (let weekday_opening of weekday_openings) {
          let open_close = weekday_opening.split('-')
          if (open_close.length == 2) {
            const open = open_close[0]
            const close = open_close[1]
            if (open && close) {
              openings.push({
                day: all_openings.indexOf(weekday_openings),
                close: close.trim(),
                open: open.trim()
              })
            }
          }
        }
      }

      const street = [unit['Straße'], unit['HausNr']].filter(v => v).join(' ')
      return {
        register: Number.parseInt(unit["Anzahl der Kassenplätze"]) || (unit.id ? undefined : -1),
        address_street: street || undefined,
        phone1: unit['phone1'] || undefined,
        phone2: unit['phone2'] || undefined,
        nr: unit['Filiale'],
        email: unit['Email'] || undefined,
        comment: unit['Bemerkung'] || undefined,
        name: unit['Spalte1'] || undefined,
        address_company: unit['Name'] || undefined,
        address_lastname: unit['Lastname'] || undefined,
        address_city: unit['Ort'] || undefined,
        address_state: unit['Bundesland'] || undefined,
        address_zip: unit['PLZ'] || undefined,
        address_country: country || undefined,
        openings: openings.length ? openings : undefined,
        address_used_google_maps_parameter: `${street}, ${unit['PLZ']} ${unit['Ort']}`,
        version: unit.id ? undefined : 0,
      }
    })
  }

  idRenderer(params: any): string {
    const id = params.value
    if (id >= 0) {
      return `<a class="hover_link" href="/a/filiale/${id}" rel="noopener">${params.value}</a>`
    } else {
      return 'NEU'
    }
  }

  protected readonly faInfoCircle = faInfoCircle;
  openingTooltip: string = 'Die Öffnungszeiten müssen nicht angegeben werden. Zu jedem Wochentag können ein oder mehrere Zeiträume angegeben (mit # getrennt) werden, z.B. 09:00-12:00#14:00-18:00';
  protected readonly faDownload = faDownload;
}
