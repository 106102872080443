import { Component } from '@angular/core';
import { faMailBulk, faPhone, faSms } from "@fortawesome/free-solid-svg-icons";
import { UntypedFormControl, UntypedFormGroup, Validators, ReactiveFormsModule, FormsModule } from "@angular/forms";
import { Customer, Technician } from "../../_models";
import { Role } from "../../_models/role.interface";
import { CustomerService, UserService } from "../../_services";
import { RoleService } from "../../_services/role.service";
import { ActivatedRoute, Router, RouterLink } from "@angular/router";
import { TechnicianUpdateHash } from "../../_models/technician.interface";
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import * as mapboxgl from "mapbox-gl";
import { PAUtil } from "../planning-assistant/classes/util";
import { MapBoxService } from "../../_services/mapbox.service";
import { GeocoderEvent } from "../../_models/mapbox.interface";
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { FaIconComponent, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MatSelect } from '@angular/material/select';
import { MatOption } from '@angular/material/core';
import { NgFor } from '@angular/common';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatButton } from '@angular/material/button';
import { CommonModule } from "@angular/common";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatSelectModule } from "@angular/material/select";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { RecordNavigationComponent } from "../_shared/record-navigation/record-navigation.component";

@Component({
  selector: 'hawk-user-create',
  templateUrl: './user-create.component.html',
  styleUrls: ['./user-create.component.scss', '../_shared/styles/common-styles.scss'],
  standalone: true,
  imports: [RecordNavigationComponent, ReactiveFormsModule, MatFormField, MatInput, FaIconComponent, MatSelect, MatOption, NgFor, MatCheckbox, FormsModule, MatLabel, MatButton, CommonModule, MatFormFieldModule, MatSelectModule, MatCheckboxModule, FontAwesomeModule, RouterLink]
})
export class UserCreateComponent {

  protected readonly faSms = faSms;
  protected readonly faPhone = faPhone;
  protected readonly faMailBulk = faMailBulk;

  customers: Customer[] = []
  roles: Role[] = []
  checkedRoles: {role: Role, checked: boolean}[] = []
  subcontractors: Technician[] = []
  sendAccount: boolean = false
  addressGeocoder?: MapboxGeocoder

  userForm: UntypedFormGroup = new UntypedFormGroup({
    first_name: new UntypedFormControl({value: ''}, Validators.required),
    last_name: new UntypedFormControl({value: ''}, Validators.required),
    company: new UntypedFormControl({value: ''}),
    repairer: new UntypedFormControl({value: ''}),
    email1: new UntypedFormControl({value: ''}, [Validators.email, Validators.required]),
    email2: new UntypedFormControl({value: ''}),
    email_moreapp: new UntypedFormControl({value: ''}, Validators.email),
    email_partner1: new UntypedFormControl({value: ''}, Validators.email),
    email_partner2: new UntypedFormControl({value: ''}),
    customer_id: new UntypedFormControl({value: ''}),
    employer_id: new UntypedFormControl({value: ''}),
    phone1: new UntypedFormControl('', Validators.pattern('[- +()0-9]+')),
    phone2: new UntypedFormControl('', Validators.pattern('[- +()0-9]+')),
    telephone_dispatching: new UntypedFormControl('', Validators.pattern('[- +()0-9]+')),
    sms: new UntypedFormControl('', Validators.pattern('[- +()0-9]+')),
    street: new UntypedFormControl({value: '', disabled: true}),
    zip: new UntypedFormControl({value: '', disabled: true}, [Validators.pattern('^([a-zA-Z]{2}-)?[0-9]{2,5}( [a-zA-Z]{0,2})?$')]),
    city: new UntypedFormControl({value: '', disabled: true}),
    country: new UntypedFormControl({value: '', disabled: true}),
    state: new UntypedFormControl({value: '', disabled: true}),
    lat: new UntypedFormControl({value: ''}),
    lng: new UntypedFormControl({value: ''}),
    card_nr_packstation: new UntypedFormControl({value: ''}),
    pickpoint_nr: new UntypedFormControl({value: ''}),
    xml_id: new UntypedFormControl({value: ''}),
    password: new UntypedFormControl({value: ''}, Validators.required)
  });

  constructor(
    public userService: UserService,
    public customerService: CustomerService,
    public roleService: RoleService,
    public route: ActivatedRoute,
    private router: Router,
    private mapBoxService: MapBoxService
  ) {
  }

  ngOnInit(): void {
    this.resetForm()
    this.loadCustomers()
    this.loadRoles()
    this.loadSubcontractors()
    this.setGeocoder()
  }

  public async setGeocoder(): Promise<void> {
    this.addressGeocoder = new MapboxGeocoder({
      accessToken: this.mapBoxService.accessToken,
      marker: false,
      language: 'de-DE',
      mapboxgl: mapboxgl,
      placeholder: 'Adresse suchen'
    })
    while (!document.getElementById('address_geocoder')) {
      await PAUtil.sleep(100)
    }
    const address_geocoder_elem = document.getElementById('address_geocoder')
    this.addressGeocoder.addTo(address_geocoder_elem);

    this.addressGeocoder.on('result', (event) => {
      this.onAddressGeocoderEvent(event)
    })
  }

  private onAddressGeocoderEvent(event: GeocoderEvent) {
    let {country, region, city, post_code, street, street_no, center, name} = PAUtil.getGeocoderEventAddress(event);

    let address_country_control = this.userForm.controls['country']
    address_country_control.setValue(country)
    address_country_control.markAsTouched();
    address_country_control.updateValueAndValidity()
    let address_state_control = this.userForm.controls['state']
    address_state_control.setValue(region)
    address_state_control.markAsTouched();
    address_state_control.updateValueAndValidity()
    let address_city_control = this.userForm.controls['city']
    address_city_control.setValue(city)
    address_city_control.markAsTouched();
    address_city_control.updateValueAndValidity()
    let address_zip_control = this.userForm.controls['zip']
    address_zip_control.setValue(post_code)
    address_zip_control.markAsTouched();
    address_zip_control.updateValueAndValidity()
    let address_street_control = this.userForm.controls['street']
    address_street_control.setValue(street + ' ' + street_no)
    address_street_control.markAsTouched();
    address_street_control.updateValueAndValidity()

    let latitude_control = this.userForm.controls['lat']
    latitude_control.setValue(center[1])
    latitude_control.markAsTouched();
    latitude_control.updateValueAndValidity()
    let longitude_control = this.userForm.controls['lng']
    longitude_control.setValue(center[0])
    longitude_control.markAsTouched();
    longitude_control.updateValueAndValidity()

    this.userForm.updateValueAndValidity()
  }

  loadCustomers(): void {
    this.customerService.loadCustomers().subscribe(
      customers => {
        this.customers = customers.sort((c1, c2) => c1.name.toLowerCase() < c2.name.toLowerCase() ? -1 : 1)
      },
      err => {
        console.log(err)
      }
    )
  }

  loadRoles(): void {
    this.roleService.getRoles().subscribe(
      roles => {
        this.roles = roles
        this.resetRoles()
      },
      err => {
        console.log(err)
      }
    )
  }

  loadSubcontractors(): void {
    this.userService.getSubcontractors().subscribe(
      subcontractors => {
        this.subcontractors = subcontractors
      },
      err => {
        console.log(err)
      }
    )
  }

  resetForm():void {
    this.userForm.setValue({
      first_name: '',
      last_name: '',
      company: '',
      repairer: '',
      email1: '',
      email2: '',
      email_moreapp: '',
      email_partner1: '',
      email_partner2: '',
      customer_id: null,
      employer_id: null,
      phone1: '',
      phone2: '',
      telephone_dispatching: '',
      sms: '',
      street: '',
      zip: '',
      city: '',
      country: '',
      state: '',
      password: '',
      lat: 0,
      lng: 0,
      card_nr_packstation: '',
      pickpoint_nr: '',
      xml_id: ''
    });

    this.resetRoles()
  }

  resetRoles(): void {
    this.checkedRoles = this.roles.filter(r => !r.name.includes('CRM')).map(
      role => {
        return { role: role, checked: role.name == 'USER' }
      }
    )
  }

  async onSubmit() {
    const user_id = await this.createUser()
    await this.createRoles(user_id)
    this.router.navigate(['/benutzer', user_id])
  }

  createUser(): Promise<number> {
    return new Promise<number>(resolve => {
      this.userService.createUser(this.getCreateHash(), this.sendAccount).subscribe(
        user => {
          resolve(user.id)
        },
        error => {
          console.log(error)
          resolve(0)
        }
      )
    })
  }

  createRoles(user_id: number): Promise<void> {
    return new Promise<void>(resolve => {
      const not_crm_roles = this.roles.filter(r => !r.name.includes('CRM'))
      const add_roles_with_ids: number[] = not_crm_roles.filter(r => this.roleIsChecked(r.id)).map(r => r.id)

      this.userService.updateUserRoles(user_id, add_roles_with_ids, []).subscribe(
        _ => {
          resolve()
        },
        error => {
          console.log(error)
          resolve()
        }
      )
    })
  }

  roleIsChecked(id: number): boolean  {
    return !!this.checkedRoles.find(cr => cr.role.id == id && cr.checked)
  }

  getCreateHash(): TechnicianUpdateHash {
    const controls = this.userForm.controls
    return {
      firstname: controls['first_name'].value,
      lastname: controls['last_name'].value,
      email: controls['email1'].value,
      email2: controls['email2'].value,
      email_moreapp: controls['email_moreapp'].value,
      email_partner: controls['email_partner1'].value,
      email_partner2: controls['email_partner2'].value,
      customer_id: controls['customer_id'].value,
      telephone1: controls['phone1'].value,
      telephone2: controls['phone2'].value,
      telephone_dispatching: controls['telephone_dispatching'].value,
      sms: controls['sms'].value,
      address_company: controls['company'].value,
      address_street: controls['street'].value,
      address_street_no: '',
      address_city: controls['city'].value,
      address_zip: controls['zip'].value,
      address_country: controls['country'].value,
      address_state: controls['state'].value,
      employer_id: controls['employer_id'].value,
      card_nr_packstation: controls['card_nr_packstation'].value,
      pickpoint_nr: controls['pickpoint_nr'].value,
      xml_id: controls['xml_id'].value,
      password: controls['password'].value,
      active: true,
      address_latitude: controls['lat'].value,
      address_longitude: controls['lng'].value
    }
  }

}