import { Material } from "src/app/_models";
import { MaterialContainer } from "./material-container.interface";
import { PAUtil } from "../util";

export class TicketMaterialContainer implements MaterialContainer {

  public materials: Material[] = [];

  saveMaterial(material: Material): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      this.materials.push(material)
      resolve(true)
    })
  }

  deleteMaterial(material: Material): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      PAUtil.removeElementFromList(this.materials, material)
      resolve(true)
    })
  }

  getMaterialString(): string {
    return this.materials.map(mat => `${mat.amount}x ${mat.title}`).join(' | ')
  }

}