import { CommonModule } from "@angular/common";
import { FaIconComponent, FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'

@Component({
  imports: [CommonModule, FontAwesomeModule],
    selector: 'hawk-shared-file-input',
    templateUrl: './file-input.component.html',
    styleUrls: ['./file-input.component.scss']
})
export class FileInputComponent implements OnInit {

  @Output() saveClicked = new EventEmitter<FileList>()
  @Output() cancelClicked = new EventEmitter<boolean>()
  @Input() acceptedFiletypes: string = "*"
  @Input() multiUpload: boolean = false

  public value?: string | number

  constructor(
  ) { }

  ngOnInit(): void {
//
  }

  public save(event: Event) {
    const target = event.target as HTMLInputElement;
    if (target.files) {
      this.saveClicked.emit(target.files)
      target.value = '';
    }
  }

  public cancel() {
    this.cancelClicked.emit(true)
  }
}