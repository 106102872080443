import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PAOperation } from '../../classes/operation';
import { PAProject } from '../../classes/project';
import { PATechnician } from '../../classes/technician';
import { PATourPlannerControl } from "../../singletons/pa-tourplanner-control";
import { PADataControl } from "../../singletons/pa-data-control";
import { NgFor, NgClass, NgIf } from '@angular/common';
import { TooltipModule } from '@cloudfactorydk/ng2-tooltip-directive';
import { MatButton } from '@angular/material/button';
import { MaterialSelectionComponent } from '../material-selection/material-selection.component';
import { CommonModule } from "@angular/common";

@Component({
  selector: 'hawk-store-operations',
  templateUrl: './store-operations.component.html',
  styleUrls: ['./store-operations.component.scss', './../../styles/common_styles.scss'],
  standalone: true,
  imports: [NgFor, NgClass, NgIf, TooltipModule, MatButton, MaterialSelectionComponent, CommonModule]
})
export class StoreOperationsComponent implements OnInit {

  Project = PAProject
  Technician = PATechnician

  @Input() operations: PAOperation[]
  @Input() selectedOperation: PAOperation

  @Output() selectStoreOperationEvent = new EventEmitter<PAOperation>()

  constructor(
  ) {
  }

  ngOnInit(): void {
  }

  clickAddStoreOperationToPlanning(event: MouseEvent, operation: PAOperation): void {
    event.stopPropagation()
    let operation_to_plan = PATourPlannerControl.Instance.tourPlannerPlanningMode == 'operation' && PATourPlannerControl.Instance.operationToPlanID
    let technician_to_plan = PATourPlannerControl.Instance.tourPlannerPlanningMode == 'technician' && PATourPlannerControl.Instance.technicianToPlanID
    if (operation_to_plan || technician_to_plan) {
      PATourPlannerControl.Instance.addOperationsToTourPlanning([operation])
    } else {
      PATourPlannerControl.Instance.tourPlannerPlanningMode = 'operation'
      PATourPlannerControl.Instance.operationToPlanID = operation.id
    }
  }

  clickRemoveStoreOperationFromPlanning(event: MouseEvent, operation: PAOperation): void {
    event.stopPropagation()
    if (!operation.isDone()) {
      PATourPlannerControl.Instance.removeOperationsFromTourPlanning([operation])
    }
  }

  clickPlanStoreOperation(event: MouseEvent, operation: PAOperation): void {
    event.stopPropagation()
    if (!operation.isDone()) {
      PATourPlannerControl.Instance.planOperation(operation)
    }
  }

  protected readonly PADataControl = PADataControl;
  protected readonly PATourPlannerControl = PATourPlannerControl;
}