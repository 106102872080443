import { Subject } from "rxjs";
import { PAOperation } from "./operation";
import { PATechnician } from "./technician";
import { PATechnicianDate } from "./technician-date";

export interface ChangedTechnicianTimestamps {
  changed_technician: PATechnician;
  changed_timestamps: number[] | 'all';
}

export class PAPlanningInstructions {

  operationConstraintMap = new Map<number, TechnicianConstraint[]>()

  hasChangedSubject = new Subject<ChangedTechnicianTimestamps[] | 'all'>()

  constructor() {
    this.hasChangedSubject.subscribe(() => {
      console.log('Updated Subject')
    })
  }

  addOperationsTechnicianConstraints(operations: PAOperation[], technicians: PATechnician[], timestamps?: number[]) {
    let changed_technician_timestamps: ChangedTechnicianTimestamps[] = []
    for (let operation of operations) {
      if (!this.operationConstraintMap.has(operation.id)) {
        this.operationConstraintMap.set(operation.id, [])
      }
      let t_constraints = this.operationConstraintMap.get(operation.id)
      let not_added_technicians = technicians.filter(t => !t_constraints.find(tc => tc.technician == t))
      this.operationConstraintMap.set(operation.id, t_constraints.concat(not_added_technicians.map(t => {
        return {technician: t, day_timestamps: null}
      })))

      not_added_technicians.map(t => changed_technician_timestamps.push({
        changed_technician: t,
        changed_timestamps: timestamps || 'all'
      }))

      if (timestamps) {
        for (let technician of technicians) {
          let t_constraint = this.getOperationsTechnicianConstraint(operation, technician)
          if (!t_constraint.day_timestamps) {
            t_constraint.day_timestamps = [...timestamps]
            if (!not_added_technicians.includes(technician)) {
              changed_technician_timestamps.push({changed_technician: technician, changed_timestamps: timestamps})
            }
          } else {
            let add_timestamps: number[] = []
            for (let timestamp of timestamps) {
              if (!t_constraint.day_timestamps.includes(timestamp)) {
                add_timestamps.push(timestamp)
              }
            }
            t_constraint.day_timestamps = t_constraint.day_timestamps.concat(add_timestamps)
            if (!not_added_technicians.includes(technician)) {
              changed_technician_timestamps.push({changed_technician: technician, changed_timestamps: add_timestamps})
            }
          }
        }
      }
    }
    this.update(changed_technician_timestamps)
  }

  removeOperationsTechnicianConstraints(operations: PAOperation[], technicians: PATechnician[], timestamps?: number[]) {
    let changed_technician_timestamps: ChangedTechnicianTimestamps[] = []
    for (let operation of operations) {
      if (this.operationConstraintMap.has(operation.id)) {
        let t_constraints = this.operationConstraintMap.get(operation.id)
        if (timestamps) {
          for (let technician of technicians) {
            let t_constraint = this.getOperationsTechnicianConstraint(operation, technician)
            if (t_constraint?.day_timestamps) {
              let filtered_timestamps = t_constraint.day_timestamps.filter(ts => timestamps.includes(ts))
              changed_technician_timestamps.push({
                changed_technician: technician,
                changed_timestamps: filtered_timestamps
              })
              t_constraint.day_timestamps = t_constraint.day_timestamps.filter(ts => !timestamps.includes(ts))
              if (!t_constraint.day_timestamps.length) {
                this.operationConstraintMap.set(operation.id, t_constraints.filter(c => c.technician != technician))
              }
            }
          }
        } else {
          let removed_technician_constraints = t_constraints.filter(tc => technicians.includes(tc.technician))
          removed_technician_constraints.map(tc => changed_technician_timestamps.push({
            changed_technician: tc.technician,
            changed_timestamps: tc.day_timestamps || 'all'
          }))
          this.operationConstraintMap.set(operation.id, t_constraints.filter(tc => !technicians.includes(tc.technician)))
        }
      }
    }
    this.update(changed_technician_timestamps)
  }

  getOperationsTechnicianConstraint(operation: PAOperation, technician: PATechnician): TechnicianConstraint | undefined {
    if (this.operationConstraintMap.has(operation.id)) {
      return this.operationConstraintMap.get(operation.id).find(tc => tc.technician == technician)
    }
  }

  update(changed_technician_timestamps?: ChangedTechnicianTimestamps[]) {
    if (changed_technician_timestamps) {
      this.hasChangedSubject.next(changed_technician_timestamps)
    } else {
      this.hasChangedSubject.next('all')
    }
  }

  resetPlanningInstructions(skip_update?: boolean) {
    this.operationConstraintMap = new Map<number, TechnicianConstraint[]>()
    if (!skip_update) this.update()
  }

  operationIsDoableOnTechnicianDate(operation: PAOperation, technician_date: PATechnicianDate): boolean {

    if (technician_date.isPastTechnicianDate()) {
      return false
    }

    if (!this.operationConstraintMap.has(operation.id) || !this.operationConstraintMap.get(operation.id).length) {
      return false
    } else {
      const technician_constraint = this.getOperationsTechnicianConstraint(operation, technician_date.technician)
      if (technician_constraint) {
        let cdts = technician_constraint.day_timestamps
        return !cdts || cdts.includes(technician_date.day.utc_timestamp);
      } else {
        return false
      }
    }
  }

  checkGeneralDoability(operation: PAOperation, technician: PATechnician): boolean {
    return operation.checkGeneralTechnicianDoability(technician)
  }
}

export interface TechnicianConstraint {
  technician: PATechnician,
  day_timestamps?: number[]
}