<hawk-record-navigation class="common_style" *ngIf="customer" [breadcrumbLinkList]="[{description: 'Kunden', link: '/a/kunde'}]" [root]="'customer'" [leafDescription]="customer.name">
  <div style="flex: 1;" class="card-body responsive_split">
    <div style="flex: 1;" class="flex_col">
      <section>
        <h2>Stammdaten</h2>
        <table class="section_table">
          <tbody>
          <tr>
            <th class="editable" (click)="editName = !editName">Name</th>
            <td *ngIf="!editName">{{customer.name}}</td>
            <td *ngIf="editName"><hawk-shared-edit-input [textValue]="customer.name" (saveClicked)="saveName($any($event))" (cancelClicked)="editName = false"></hawk-shared-edit-input></td>
          </tr>
          <tr>
            <th class="editable" (click)="editLetter = !editLetter">Abkürzung</th>
            <td *ngIf="!editLetter">{{customer.letter}}</td>
            <td *ngIf="editLetter"><hawk-shared-edit-input [upperCaseMode]="true" [textValue]="customer.letter" [maxInputLength]="3" (saveClicked)="saveLetter($any($event))" (cancelClicked)="editLetter = false"></hawk-shared-edit-input></td>
          </tr>
          <tr>
            <td class="editable" (click)="editURL = !editURL">URL</td>
            <td *ngIf="!editURL"><a [href]="customer.url" target="_blank">{{customer.url}}</a></td>
            <td *ngIf="editURL"><hawk-shared-edit-input [textValue]="customer.url" (saveClicked)="saveURL($any($event))" (cancelClicked)="editURL = false"></hawk-shared-edit-input></td>
          </tr>
          <tr>
            <td class="editable" (click)="editXMLID = !editXMLID">XML-ID</td>
            <td *ngIf="!editXMLID">{{customer.xml_id}}</td>
            <td *ngIf="editXMLID"><hawk-shared-edit-input [textValue]="customer.xml_id" (saveClicked)="saveXMLID($any($event))" (cancelClicked)="editXMLID = false"></hawk-shared-edit-input></td>
          </tr>
          </tbody>
        </table>
      </section>
      <section>
        <h2>Nutzer</h2>
        <table class="section_table" *ngIf="customer.users.length">
          <tbody>
          <tr *ngFor="let user of customer.users">
            <td>
              <a [routerLink]="'/user/' + user.id">
                <fa-icon style="margin-right: 4px;" [icon]="faUser"></fa-icon>
                {{user.name}}
              </a>
            </td>
            <td>{{user.restriction_project?.name || 'Zugriff auf alle Projekte'}}</td>
            <td style="text-align: right;">
              <button mat-raised-button (click)="showModal='user_project_access';userProjectAccessUser=user;selectedDefaultUserProjectId=user.restriction_project?.id||null;">Zugriff ändern</button>
            </td>
          </tr>
          </tbody>
        </table>
      </section>
      <section>
        <h2 style="margin-bottom: 0">Adresse <a [href]="'https://maps.google.de?q=' + [customer.street, customer.zip, customer.city].join(',')" target="_blank">Google Maps</a></h2>
        <table class="section_table">
          <tbody>
          <tr>
            <td>Firma</td>
            <td>{{customer.company}}</td>
            <td style="text-align: right;" rowspan="4">
              <button mat-raised-button (click)="showModal = 'address'">
                Adresse ändern
              </button>
            </td>
          </tr>
          <tr>
            <td>Straße</td>
            <td>{{customer.street}}</td>
          </tr>
          <tr>
            <td>PLZ und Ort</td>
            <td>{{customer.zip}} {{customer.city}}</td>
          </tr>
          <tr>
            <td>Land (Staat)</td>
            <td>{{(customer.country || '').toUpperCase()}}</td>
          </tr>
          </tbody>
        </table>
      </section>
      <section>
        <h2>Karte</h2>
        <div *ngIf="customer.lat && customer.lng" style="flex: 1; width: 100%; border: 1px solid gray;" class="flex_row">
          <mgl-map [style]="'mapbox://styles/mapbox/streets-v9'" [zoom]="[5]" [center]="[customer.lng, customer.lat]">
            <mgl-marker [lngLat]="[customer.lng, customer.lat]">
              <div class="marker" [style.borderColor]="'black'" [style.backgroundColor]="'#af0000'" title="{{customer.name}}"></div>
            </mgl-marker>
          </mgl-map>
        </div>
      </section>
    </div>
    <div class="flex_col" style="width: 65%;">
      <section>
        <h2>Navigation</h2>
        <mat-tab-group (selectedTabChange)="updateTableSizes($event)">
          <mat-tab [label]="'Filialen (' + stores.length + ')'">
            <div class="flex_row" style="text-align: right; width: 100%; margin-top: 8px;">
              <div style="flex: 1;"></div>
              <input type="text" style="margin: auto;" placeholder="Schnellfilter..." (input)="storeQuickSearchChanged($event)">
            </div>
            <ag-grid-angular class="ag-theme-quartz" style="height: 530px; width: 100%;" [rowData]="stores" [theme]="hawkTheme"
                             [defaultColDef]="defaultColDef" [columnDefs]="storeColDefs" [rowClassRules]="rowClassRules" [pagination]="true"
                             [rowSelection]="rowSelection" [autoSizeStrategy]="autoSizeStrategy"
                             [paginationPageSize]="18" [localeText]="locale" [sideBar]="sideBarOptions"
                             [paginationPageSizeSelector]="[18,50,250,1000]" (gridReady)="onStoreGridReady($event)">
            </ag-grid-angular>
            <button mat-raised-button style="position: absolute; top: 4px; left: 0;" class="primary_bg_color_button" color="primary" (click)="navigateToStoreImport()">
              <fa-icon style="margin-right: 4px;" [icon]="faUpload"></fa-icon> Filialen importieren
            </button>
          </mat-tab>
          <mat-tab [label]="'Projekte (' + projects.length + ')'">
            <div class="flex_row" style="text-align: right; width: 100%; margin-top: 8px;">
              <div style="flex: 1;"></div>
              <input type="text" style="margin: auto;" placeholder="Schnellfilter..." (input)="projectQuickSearchChanged($event)">
            </div>
            <ag-grid-angular class="ag-theme-quartz" style="height: 530px; width: 100%;" [rowData]="projects" [theme]="hawkTheme"
                             [defaultColDef]="defaultColDef" [columnDefs]="projectColDefs" [rowClassRules]="rowClassRules" [pagination]="true"
                             [rowSelection]="rowSelection" [autoSizeStrategy]="autoSizeStrategy"
                             [paginationPageSize]="18" [localeText]="locale" [sideBar]="sideBarOptions"
                             [paginationPageSizeSelector]="[18,50,250,1000]" (gridReady)="onProjectGridReady($event)">
            </ag-grid-angular>
          </mat-tab>
        </mat-tab-group>
      </section>
    </div>
  </div>
  <div style="flex-wrap: wrap;" class="flex_row footer_row">
    <a [routerLink]="'/kunde'">
      <button mat-button class="primary_color_button" color="primary">
        <fa-icon [icon]="faArrowLeft"></fa-icon>
        Zurück
      </button>
    </a>
    <div style="flex: 1;"></div>
    <div [matTooltip]="customerIsDeletable ? '' : 'Kunden können nur gelöscht werden, wenn keine Abhängigkeiten zu Stores, Projekten, Usern und Material vorhanden sind'">
      <button mat-raised-button [disabled]="!customerIsDeletable" (click)="deleteCustomer()">
        <fa-icon [icon]="faTrash"></fa-icon>
        Löschen
      </button>
    </div>
  </div>

  <div class="modal is-active" role="dialog" aria-modal="true" *ngIf="showModal === 'address'">
    <div class="modal-background"></div>
    <div class="modal-content">
      <div class="wrapper" style="padding: 2px;">
        <hawk-shared-edit-address [city]="customer.city" [company]="customer.company" [country]="customer.country" [lat]="customer.lat" [lng]="customer.lng"
                                  [state]="customer.state" [street]="customer.street" [zip]="customer.zip"
                                  (onSubmitEvent)="saveAddress($event)" (onAbortEvent)="showModal = null"
        ></hawk-shared-edit-address>
      </div>
    </div>
    <button class="modal-close" aria-label="close" (click)="showModal = null"></button>
  </div>

  <div class="modal is-active has-toolbar" role="dialog" aria-modal="true" *ngIf="showModal === 'user_project_access' && userProjectAccessUser">
    <div class="modal-background"></div>
    <div class="modal-content">
      <div class="wrapper" style="padding: 2px;">
        <h4 style="margin-bottom: 0">Projekt auswählen</h4>
        <div class="text-muted">
          Wählen Sie das Standardprojekt für <i>{{userProjectAccessUser.name}}</i> aus.
        </div>
        <mat-form-field style="margin-top: 4px;" class="outline-small no-subscript" appearance="outline">
          <mat-label>Projekt</mat-label>
          <mat-select [(ngModel)]="selectedDefaultUserProjectId">
            <mat-option [value]="null">-</mat-option>
            @for (project of projects; track project) {
              <mat-option [value]="project.id">{{project.name}}</mat-option>
            }
          </mat-select>
        </mat-form-field>
      </div>
      <div class="modal-toolbar">
        <div class="modal-toolbar-container-left">
        </div>
        <div class="modal-toolbar-container-right">
          <button mat-flat-button (click)="showModal=null;userProjectAccessUser=null">Abbruch</button>
          <button mat-raised-button color="primary" class="primary_bg_color_button" (click)="saveUserProjectAccessUser()">Ändern</button>
        </div>
      </div>
    </div>
    <button class="modal-close" aria-label="close" (click)="showModal = null"></button>
  </div>

</hawk-record-navigation>
